import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import Card from '../../../../../components/card/Card'
import Table from '../../../../../components/datatable/Table'
import { getMapStateToProps, getPropTypes } from '../../../../../utils/StoreUtils'
import { substringText } from '../../../../../utils/StringUtil'
import DtoBoreholeLithologyItem from '../dto/DtoBoreholeLithologyItem'

const storeProps = {
    sandreCodes: false,
}

class BoreholeLithologyDashboard extends ActionComponent {
    getFirstPart = (lithology) => {
        const firstPart = lithology.find(l => l.order === 0)
        if (firstPart) {
            return firstPart.comment.split('\n').map(txt => <h6>{txt}</h6>)
        }
        return null
    }

    getDepth = (lithology, index, lithologies) => {
        if (index === 0) {
            return `0-${lithology.thickness}${i18n.metersSymbol}`
        }
        return `${lithologies[index - 1].thickness}-${lithology.thickness}${i18n.metersSymbol}`
    }

    getSummary = (lithology) => {
        if (lithology && lithology.length) {
            const orderedLithology = orderBy(lithology.filter(l => l.order === 1), 'thickness')
            const data = orderedLithology.map((l, i) => new DtoBoreholeLithologyItem(Object.assign({}, l, {
                depth: this.getDepth(l, i, orderedLithology),
                comment: substringText(l.comment, 125),
            })))
            const table = data.length ? (
                <Table data={ data } sortable showTitle={ false } condensed
                    type={ new DtoBoreholeLithologyItem({}) }
                />
            ) : null
            return (
                <Card title={ i18n.lithology } smallCard>
                    <div className='padding-left-1'>{ this.getFirstPart(lithology) }</div>
                    { table }
                </Card>
            )
        }
        return null
    }

    render() {
        return this.getSummary(this.props.station.link_lithology)
    }
}

BoreholeLithologyDashboard.propTypes = getPropTypes(storeProps, {
    station: PropTypes.object,
})

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(BoreholeLithologyDashboard)

