import { every, isEqual, omit, template } from 'lodash'
import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { v4 as uuidv4 } from 'uuid'
import SieauAction from '../components/sieau/SieauAction'
import AppStore from '../store/AppStore'
import { getFullDate } from './DateUtil'
import { hasValue } from './NumberUtil'
import { getComponentWithId } from './StoreUtils'

const checkMandatoryFields = (mandatoryFieldList, object, callback) => {
    const unsetMandatoryFields = mandatoryFieldList.filter(field => !hasValue(object[field.field]))
    if (unsetMandatoryFields.length) {
        AppStore.dispatch(ToastrAction.error(template(i18n.fieldNotSet)({ field: unsetMandatoryFields[0].i18n ? i18n[unsetMandatoryFields[0].i18n] : i18n[unsetMandatoryFields[0].field] })))
    } else {
        callback()
    }
}

const getLocalStorageJson = (key) => {
    const stored = localStorage.getItem(key)
    if (stored) {
        return JSON.parse(decodeURIComponent(window.atob(localStorage.getItem(key))))
    }
    return null
}

const setLocalStorageJson = (key, obj) => {
    const encoded = window.btoa(encodeURIComponent(JSON.stringify(obj)))
    localStorage.setItem(key, encoded)
}

const onChangeHour = (v, callback = () => { }, bounds = {}, baseDate = moment().valueOf()) => {
    if (v.length >= 8) {
        const regex = /\d\d:\d\d:\d\d/g
        const date = moment(v, 'HH:mm:ss')
        if (v.length === 8 && v[2] === ':' && v[5] === ':' && date.isValid() && regex.test(v)) {
            const d = moment(baseDate).hour(date.hour()).minute(date.minute()).second(date.second()).millisecond(date.millisecond()).valueOf()
            if (bounds.max && d > bounds.max) {
                AppStore.dispatch(ToastrAction.error(template(i18n.dateBoundMaxError)({ date: getFullDate(bounds.max) })))
            } else if (bounds.min && d < bounds.min) {
                AppStore.dispatch(ToastrAction.error(template(i18n.midnightHourError)({ date: getFullDate(bounds.min) })))
            } else {
                callback(d)
            }
        } else {
            AppStore.dispatch(ToastrAction.error(i18n.incorrectHourInput))
        }
    } else if (v.length === 0) {
        onChangeHour('00:00:00', callback, bounds, baseDate)
    }
}

const onChangeMiniHour = (v, callback = () => { }, bounds = {}, baseDate = moment().valueOf()) => {
    if (v.length >= 5) {
        const regex = /\d\d:\d\d/g
        const date = moment(v, 'HH:mm')
        if (v.length === 5 && v[2] === ':' && date.isValid() && regex.test(v)) {
            const d = moment(baseDate).hour(date.hour()).minute(date.minute()).second(date.second()).millisecond(date.millisecond()).valueOf()
            if (bounds.max && d > bounds.max) {
                AppStore.dispatch(ToastrAction.error(template(i18n.dateBoundMaxError)({ date: getFullDate(bounds.max) })))
            } else if (bounds.min && d < bounds.min) {
                AppStore.dispatch(ToastrAction.error(template(i18n.midnightHourError)({ date: getFullDate(bounds.min) })))
            } else {
                callback(d)
            }
        } else {
            AppStore.dispatch(ToastrAction.error(i18n.incorrectMinHourInput))
        }
    }
}

const returnMiniHour = (v, bounds = {}, baseDate = moment().valueOf()) => {
    if (v.length >= 5) {
        const regex = /\d\d:\d\d/g
        const date = moment(v, 'HH:mm')
        if (v.length === 5 && v[2] === ':' && date.isValid() && regex.test(v)) {
            const d = moment(baseDate).hour(date.hour()).minute(date.minute()).second(date.second()).millisecond(date.millisecond()).valueOf()
            if (bounds.max && d > bounds.max) {
                return AppStore.dispatch(ToastrAction.error(template(i18n.dateBoundMaxError)({ date: getFullDate(bounds.max) })))
            } else if (bounds.min && d < bounds.min) {
                return AppStore.dispatch(ToastrAction.error(template(i18n.midnightHourError)({ date: getFullDate(bounds.min) })))
            }
            return d
        }
        return AppStore.dispatch(ToastrAction.error(i18n.incorrectMinHourInput))
    }
    return null
}

const setConfirmationModal = (onDelete, message = i18n.deletingConfirmation, params = {}) => {
    const usedParams = {
        closeMessage: i18n.close,
        confirmMessage: i18n.confirm,
        className: '',
        ...params,
    }
    const id = uuidv4()

    const actions = (
        <div>
            <a className='waves-effect waves-teal btn-flat modal-close'>{ usedParams.closeMessage }</a>
            <a className='waves-effect waves-teal btn-flat modal-close' id='confirm_message' data-cy='confirm_button' onClick={ onDelete }>{ usedParams.confirmMessage }</a>
        </div>
    )
    const popup = {
        id,
        header: message,
        actions,
        className: usedParams.className,
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const setMessageModal = (message = '') => {
    const id = uuidv4()

    const actions = (
        <div>
            <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
        </div>
    )
    const popup = {
        id,
        actions,
        content: (
            <div className='row no-margin'>
                <h5>{ message }</h5>
            </div>
        ),
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const setChoiceModal = (message = '', onChoice = {}) => {
    const id = uuidv4()
    const { yes = () => {}, no = () => {} } = onChoice
    const actions = (
        <div>
            <a className='waves-effect waves-teal btn-flat modal-close' onClick={ yes }>{ i18n.yes }</a>
            <a className='waves-effect waves-teal btn-flat modal-close' onClick={ no }>{ i18n.no }</a>
        </div>
    )
    const popup = {
        id,
        actions,
        content: (
            <div className='row no-margin'>
                <h5>{ message }</h5>
            </div>
        ),
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const onChangeDate = (v, callback = () => { }, bounds = {}, baseDate) => {
    if (v.length === 0) {
        callback(undefined)
        return
    }
    if (v.length >= 10) {
        const regex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
        const date = moment(v, 'DD/MM/YYYY')
        if (v.length === 10 && v[2] === '/' && v[5] === '/' && date.isValid() && regex.test(v)) {
            const d = moment(baseDate || moment().startOf('day').valueOf()).year(date.year()).month(date.month()).date(date.date()).valueOf()
            if (bounds.max && d > bounds.max) {
                AppStore.dispatch(ToastrAction.error(template(i18n.dateBoundMaxError)({ date: getFullDate(bounds.max) })))
            } else if (bounds.min && d < bounds.min) {
                AppStore.dispatch(ToastrAction.error(template(i18n.dateBoundMinError)({ date: getFullDate(bounds.min) })))
            } else {
                callback(d)
            }
        } else {
            AppStore.dispatch(ToastrAction.error(i18n.incorrectDateInput))
        }
    } else if (v.length === 0) {
        callback(undefined)
    }
}

const setModal = (props) => {
    /* props object is like {
        title,
        actions, (modal footer)
        className,
        content (react component)
     }
     */
    const popup = {
        id: 'genericModal',
        header: props.title,
        actions: props.actions,
        className: props.className,
        content: props.content,
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const setSieauTooltip = (id, content, position) => {
    getComponentWithId('#sieautooltip').setTooltip(id, content, position)
}

// if content is not a simple string, pass it as a function : const content = () => <myReactContent/>
// that would be more optimized (the react content would be loaded just on the tooltip time)
// position -> top, bottom
const sieauTooltip = (content, chosenId, position) => {
    const id = chosenId || uuidv4()
    return {
        onMouseOver: () => setSieauTooltip(id, typeof content === 'function' ? content() : content, position),
        onMouseOut: () => setSieauTooltip(null),
        id,
        onMouseDown: () => setSieauTooltip(null),
    }
}

const isEqualBy = (obj1, obj2, attrs) => every(attrs, att => isEqual(obj1[att], obj2[att]))

const isEqualOmit = (obj1, obj2, attrs) => isEqual(omit(obj1, attrs), omit(obj2, attrs))

const isObject = (object) => {
    return object !== null && typeof object === 'object'
}

const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false
        }
    }

    return true
}


const isValidEmail = (email) => (!!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))

const replace = (list, item, index) => list.map((cur, i) => {
    if (i === index) {
        return item
    }
    return cur
})

export {
    checkMandatoryFields, getLocalStorageJson, setLocalStorageJson, setConfirmationModal, replace,
    setMessageModal, setChoiceModal, onChangeHour, onChangeMiniHour, returnMiniHour, onChangeDate, setModal, setSieauTooltip, sieauTooltip, isEqualBy, isEqualOmit,
    isObject, deepEqual, isValidEmail,
}
