import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PluviometryAction from '../../../actions/PluviometryAction'
import moment from 'moment/moment'
import ProgressCard from '../../../../components/card/ProgressCard'
import MessageCard from '../../../../components/card/MessageCard'
import i18n from 'simple-react-i18n'
import Axis from '../../../../components/echart/Axis'
import { nFormatter, round } from '../../../../utils/NumberUtil'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisLabelInterval, histogramIcon, lineIcon,
    toEchartsData, yAutomaticScaleValues,
} from '../../../../components/echart/EChartUtils'
import { getDate, getMonthYear, getStartMonth } from '../../../../utils/DateUtil'
import { groupBy, orderBy } from 'lodash'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../../../utils/StatusUtil'
import EChart from '../../../../components/echart/EChart'
import Bar from '../../../../components/echart/series/Bar'
import { exportFile } from '../../../../utils/ExportDataUtil'
import PluviometerDto from '../../../dto/PluviometerDto'
import DtoPluviometerStats from '../../../dto/DtoPluviometerStats'
import PropTypes from 'prop-types'
import DtoPluvioMeasure from '../../../dto/measures/DtoPluvioMeasure'
import Line from '../../../../components/echart/series/Line'
import { genericPromise2 } from '../../../../utils/ActionUtils'
import ApplicationConf from '../../../../conf/ApplicationConf'

const SUM_MONTH = 'SUM_MONTH'
const NORMALE_CUMUL_MONTH = 'NORMALE_CUMUL_MONTH'
const NORMALE_MONTH = 'NORMALE_MONTH'
const LINE = 'line'
const BAR = 'bar'

const Graphic = ({
    pluviometer,
    data,
    minDate,
    maxDate,
}) => {
    const {
        pluviometerStatistics,
    } = useSelector(store => ({
        pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    }), shallowEqual)

    const unit = pluviometerStatistics.find(p => p.typeId === 1).unit


    const [typeLineValue, setTypeLineValue] = useState(BAR)


    const chartMaxDate = moment(maxDate)
    const axisLabelObj = getAxisLabelInterval(chartMaxDate, moment(minDate))

    const grids = [{
        top: 35,
        right: '2%',
        height: 415,
        left: 60,
    }]

    const allValues = [data[0].measures.map(m => m.value), data[1].measures.map(m => m.value)].flat()

    const yScale = yAutomaticScaleValues(allValues)

    const getLine = (measures, color = 'blue', name = '') => {
        const input = {
            name: `${pluviometer.name} | ${name}`,
            barWidth: 4,
            data: toEchartsData(measures),
            color,
            connectNulls: false,
            showSymbol: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
        }
        return Line(input)
    }

    const getBar = (measures, color = 'blue', name = '') => {
        const input = {
            name: `${pluviometer.name} | ${name}`,
            barWidth: 4,
            data: toEchartsData(measures),
            color,
            connectNulls: false,
            showSymbol: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
        }
        return Bar(input)
    }

    const sumMonthMeasures = typeLineValue === LINE ? getLine(data[0].measures, 'blue', i18n.monthlyRainfall) : getBar(data[0].measures, 'blue', i18n.monthlyRainfall)
    const normaleCumulMonthMeasures = getLine(data[1].measures, 'red', i18n.cumulativeDifferencesMm)

    const xAxis = [
        Axis({
            type: 'time',
            position: 'bottom',
            min: getStartMonth(minDate).valueOf(),
            max: maxDate ?? moment().valueOf(),
            gridIndex: 0,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            showSplitLine: true,
            axisLine: { onZero: true },
        }),

    ]
    const yAxis = [
        Axis({
            type: 'value',
            nameLocation: 'middle',
            name: i18n.comparisonNormal,
            gridIndex: 0,
            showSplitLine: true,
            axisLabel: { formatter: nFormatter },
            nameGap: 40,
            ...yScale,
            axisLine: {
                onZero: true,
            },
        }),
    ]

    const funcExport = () => {
        const options = {
            method: 'POST',
            body: {
                stationId: pluviometer.id,
                dataType: 1,
                groupFunc: NORMALE_MONTH,
                chartMode: false,
                chartStartDate: minDate,
            },
        }

        genericPromise2(ApplicationConf.pluviometry.chartMeasures(), options).then(r => {
            const normalesByMonths = groupBy(r, m => moment(m[2]).month())
            let cumul = 0
            let ecartPoucentValue = 0
            const result = data[0].measures.map(d => {
                const normales = normalesByMonths[moment(d.date).month()][0]
                const diff = d.value - normales[4]
                const ecartPoucent = ((d.value-normales[4])/normales[4])*100
                cumul = cumul + diff
                ecartPoucentValue = ecartPoucentValue + ecartPoucent
                return {
                    stationCode: { value: pluviometer.code },
                    stationName: { value: pluviometer.name },
                    date: { value: getMonthYear(d.date), format: 'MM/yyyy', cellType: 'date' },
                    [i18n.monthlyRainfall]: { value: round(d.value), format: '0.00', cellType: 'number' },
                    [i18n.normalMonths]: { value: round(normales[4]), format: '0.00', cellType: 'number' },
                    [i18n.deviationNormalsMm]: { value: round(diff), format: '0.00', cellType: 'number' },
                    [i18n.deviationNormalsPourcent]: { value: round(ecartPoucent), format: '0.00', cellType: 'number' },
                    [i18n.cumulativeDifferencesMm]: { value: round(cumul), format: '0.00', cellType: 'number' },
                    [i18n.cumulativeDifferencesPourcent]: { value: round(ecartPoucentValue), format: '0.00', cellType: 'number' },
                    headers: ['stationCode', 'stationName', 'date', [i18n.monthlyRainfall], [i18n.normalMonths], [i18n.deviationNormalsMm], [i18n.deviationNormalsPourcent], [i18n.cumulativeDifferencesMm], [i18n.cumulativeDifferencesPourcent]],
                }
            })
            exportFile({
                data: result,
                exportType: 'xlsx',
                titleFile: i18n.overview,
            })
        })
    }

    const options = {
        series: [sumMonthMeasures, normaleCumulMonthMeasures],
        title: `${pluviometer.code} - ${pluviometer.name}`,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getDate(params[0].value[2].date)
                const paramsOrder = orderBy(params.map(o => {
                    return ({
                        seriesIndex: o.seriesIndex,
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.value[2].value,
                        status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                        axisIndex: o.axisIndex,
                    })
                }), 'seriesIndex', 'asc')
                const result = paramsOrder.filter(p => p.axisIndex === 0).map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${round(o.value, 2)} ${unit ? `[${unit}]` : ''}</span>`)).join('')
                return `${date} <br /> ${result} `
            },
        },
        grid: grids,
        xAxis,
        yAxis,
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 500,
        legend: {
            bottom: 60,
            type: 'scroll',
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                myToolExport: {
                    show: true,
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: funcExport,
                },
                myToolLine: {
                    show: true,
                    title: i18n.line,
                    icon: lineIcon,
                    onclick: () => {
                        setTypeLineValue(LINE)
                    },
                },
                myToolBar: {
                    show: true,
                    title: i18n.histogram,
                    icon: histogramIcon,
                    onclick: () => {
                        setTypeLineValue(BAR)
                    },
                },
            },
            right: 65,
        },
    }
    return (
        <div className={'padding-top-1'}>
            <EChart options={options} id='comparaisonGraphNormals' scrollable={true} />
        </div>
    )
}
Graphic.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    data: PropTypes.arrayOf(PropTypes.shape({
        measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasure)),
    })),
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    pluvioGroupedMode: PropTypes.string,
}

const GraphComparisonNormal = ({
    pluviometer,
    stat,
    dateComparedNormal,
}) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)

    const dispatch = useDispatch()

    useEffect(()=> {
        setDataLoaded(false)
        const sumMonthInput = {
            stationId: pluviometer.id,
            dataType: 1,
            groupFunc: SUM_MONTH,
            chartMode: false,
            startDate: dateComparedNormal || moment().valueOf(),
        }
        const normalCumulMonthInput = {
            stationId: pluviometer.id,
            dataType: 1,
            groupFunc: NORMALE_CUMUL_MONTH,
            chartMode: false,
            chartStartDate: dateComparedNormal || moment().valueOf(),

        }
        dispatch(PluviometryAction.loadPluvioChartChronicMeasures([sumMonthInput, normalCumulMonthInput], p => {
            setProgress(p)
        })).then(() => {
            setDataLoaded(true)
        })
    }, [dispatch, pluviometer, stat, dateComparedNormal ])

    const {
        pluvioMeasures,
    } = useSelector(store => ({
        pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    }), shallowEqual)

    const data = [{
        measures: pluvioMeasures[0]?.measures,
        typeMeasure: SUM_MONTH,
    }, {
        measures: pluvioMeasures[1]?.measures,
        typeMeasure: NORMALE_CUMUL_MONTH,
    },
    ]

    const allMeasures = [...pluvioMeasures[0]?.measures, ...pluvioMeasures[1]?.measures]


    if (!pluvioMeasures.length) {
        return (
            <div style={{ paddingTop: 45 }}>
                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
            </div>
        )
    }
    return (
        <div className='col s12 row no-margin'>
            <div className={'card'} style={{ marginTop: 15 }}>
                { !dataLoaded && (
                    <div style={{ marginTop: 20 }}>
                        <ProgressCard
                            progress={progress}
                        />
                    </div>
                )}
                { (allMeasures.length < 1) && (
                    <div style={{ paddingTop: 45 }}>
                        <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                    </div>
                )}
                { (dataLoaded && !(allMeasures.length < 1)) && (
                    <Graphic
                        pluviometer={ pluviometer }
                        data={data}
                        minDate={dateComparedNormal || moment().valueOf()}
                        maxDate={moment().valueOf()}
                    />
                )}
            </div>
        </div>
    )
}
GraphComparisonNormal.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    stat: PropTypes.instanceOf(DtoPluviometerStats),
    dateComparedNormal: PropTypes.number,
}
export default GraphComparisonNormal