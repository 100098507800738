import { AccordionDetails, Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import Icon from 'components/icon/Icon'
import TabList from 'components/list/TabList'
import ProgressBar from 'components/progress/ProgressBar'
import { AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import DtoHydrometryObservation from 'hydrometry/dto/DtoHydrometryObservation'
import { groupBy, maxBy, omit, orderBy, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { getDateWithHour, getFullDate, getHour, getYearOrString } from 'utils/DateUtil'
import { onChangeHour } from 'utils/FormUtils'
import { getModalite } from 'utils/HydroUtils'
import { getLinks, getStationTitle } from 'utils/StationUtils'
import { getI18nTitleDataLength } from 'utils/StringUtil'
import ObservationFilter from './ObservationFilter'
import ObservationsCard from './ObservationsCard'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from 'connected-react-router'
import useTitle from 'utils/customHook/useTitle'
import { getUser } from 'utils/SettingUtils'
import LastObservationsPanel from './LastObservationsPanel'

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 'bold',
        minHeight: 40,
        maxHeight: 40,
        '&.Mui-expanded': {
            minHeight: 40,
            maxHeight: 40,
        },
    },
}))

const HydrometerObservationsApp = () => {
    const classes = useStyles()
    const {
        hydrometryObservations,
        hydrometricStation,
        hydrometricStations,
    } = useSelector(store => ({
        hydrometryObservations: store.HydrometryReducer.hydrometryObservations,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const observationTypes = useSandreList(SANDRE.HYDRO_OBSERVATIONS)
    const observationModalites4 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_4)
    const observationModalites5 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_5)

    const initialObservation = {
        date: moment().valueOf(),
        hour: moment().valueOf(),
        siteCode: hydrometricStation.id,
        observationType: observationTypes.length ? observationTypes[0].code : 1,
    }

    const [observationsLoaded, setObservationLoaded] = useState(false)
    const [newObservation, onSetObservation] = useState(initialObservation)
    const [openPopup, setPopupAddObservation] = useState(false)
    const [onGroupBy, setGroupBy] = useState('date')
    const [observationFilter, setObservationFilter] = useState()

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometryObservations(hydrometricStation.id)).then(() => setObservationLoaded(true))
    }, [])

    const exportObservations = () => {
        const headers = ['identifier', 'station', 'date', 'typeCode', 'observationType', 'valueCode', 'value', 'comment', 'updateLogin', 'updateDate']
        const observationsToExport = hydrometryObservations.map((obsv, i) => {
            const observationType = observationTypes.find(sandreCode => sandreCode.code === obsv.observationType)?.name
            const modalite = getModalite(obsv.observationType, observationModalites4, observationModalites5)
            const value = modalite.find(sandreCode => sandreCode.code === parseInt(obsv.observationCode))?.name
            const observationFormated = {
                ...obsv,
                observationType,
                typeCode: obsv.observationType,
                value,
                valueCode: obsv.observationCode,
                identifier: hydrometricStation.code,
                station: hydrometricStation.name,
                updateDate: getFullDate(obsv.updateDate),
                date: getFullDate(obsv.observationDate),
                code: obsv.observationCode,
            }
            return i === 0 ? { headers, ...observationFormated } : observationFormated
        })
        return {
            data: observationsToExport,
            exportType: 'xlsx',
            titleFile: `${i18n.observations} - ${getStationTitle(hydrometricStation)}`,
        }
    }

    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: getStationTitle(hydrometricStation),
        href: `station/hydrometry/${hydrometricStation.id}`,
    }, {
        title: i18n.observations,
        href: `station/hydrometry/${hydrometricStation.id}/observations`,
    }], [hydrometricStation])

    useActions(() => {
        const actions = {
            new: () => setPopupAddObservation(true),
            export: () => exportObservations(),
            links: getLinks(hydrometricStation, this),
            arrowNav: getStationArrowNav('hydrometry', hydrometricStations, hydrometricStation.id, s => dispatch(push(`/station/hydrometry/${s.id}/observations`))),
        }
        return getUser().consultant === '1' ? omit(actions, 'new') : actions
    }, [hydrometricStation, hydrometricStations, hydrometryObservations])

    const lastObservation = maxBy(hydrometryObservations, observation => observation.observationDate ? observation.observationDate : 0)

    const observationsHistoric = useMemo(() => {
        return lastObservation ? orderBy(hydrometryObservations, ['observationDate', 'updateDate'], ['desc', 'desc'])
            .filter(observation => observation.observationDate !== lastObservation.observationDate) : []
    }, [lastObservation, hydrometryObservations])

    const observationFiltered = useMemo(() => {
        if (!observationFilter) {
            return observationsHistoric
        }
        const filterByStartDate = observationFilter.startDate ? observationsHistoric.filter(o => o.observationDate > observationFilter.startDate) : observationsHistoric
        const filterByEndDate = observationFilter.endDate ? filterByStartDate.filter(o => o.observationDate < observationFilter.endDate) : filterByStartDate
        const filterByValue = observationFilter.observationValues?.length ? filterByEndDate.filter(o => {
            const modalites = getModalite(o.observationType, observationModalites4, observationModalites5)
            const modalite = modalites.find(sandreCode => `${sandreCode.code}` === o.observationCode)
            return observationFilter.observationValues.includes(modalite?.reference)
        }) : filterByEndDate
        if ([1, 2].includes(observationFilter.modalitesFilter)) {
            return filterByValue.filter(t => t.observationType === observationFilter.modalitesFilter)
        }
        return filterByValue
    }, [observationsHistoric, observationFilter, observationModalites4, observationModalites5])

    const dataGroupBy = useMemo(() => {
        switch (onGroupBy) {
            case 'date': default: return { groups: uniq(observationFiltered.map(o => getYearOrString(o.observationDate, i18n.thisYear, '').toString())), dataGroup: groupBy(observationFiltered, observation => getYearOrString(observation.observationDate, i18n.thisYear, '')) }
            case 'modalite': return { groups: uniq(observationFiltered.map(o => observationTypes.find(sandreCode => sandreCode.code === o.observationType)?.name)), dataGroup: groupBy(observationFiltered, observation => observationTypes.find(sandreCode => sandreCode.code === observation.observationType)?.name) }
        }
    }, [observationFiltered, onGroupBy, observationTypes])

    const isConditionValidated = !!newObservation.observationType && !!newObservation.date && !!newObservation.hour && !!newObservation.observationCode
    const observationValeurs = getModalite(newObservation.observationType, observationModalites4, observationModalites5)

    const onChangeObservation = (changes) => {
        onSetObservation({ ...newObservation, ...changes })
    }
    const addObservation = () => {
        const observationDate = getDateWithHour(newObservation.date, newObservation.hour).valueOf()
        const observationFormated = { ...newObservation, observationDate }

        dispatch(HydrometryAction.createHydrometerObservation(observationFormated)).then(() => {
            setPopupAddObservation(false)
            onSetObservation(initialObservation)
            dispatch(HydrometryAction.fetchHydrometryObservations(hydrometricStation.id))
        })
    }
    return (
        <div className='padding-1'>
            {
                observationsLoaded ?
                    <div>
                        <ObservationFilter onValidateFilter={setObservationFilter}/>
                        <LastObservationsPanel />
                        <TabList
                            onChangeTab={(v) => setGroupBy(v)}
                            tabs={[
                                {
                                    value: 'date',
                                    label: i18n.date,
                                    icon: 'insert_invitation',
                                },
                                {
                                    value: 'modalite',
                                    label: i18n.modality,
                                    icon: 'remove_red_eye',
                                },
                            ]}
                        >
                            { observationFiltered.length ?
                                <>
                                    { dataGroupBy.groups.map((group, i) => {
                                        const data = dataGroupBy.dataGroup[group]
                                        return (
                                            <div className='padding-1' key={i}>
                                                <AccordionMUI
                                                    defaultExpanded={i === 0}
                                                    TransitionProps={{ unmountOnExit: true }}
                                                >
                                                    <AccordionSummaryMUI
                                                        classes={classes}
                                                    >
                                                        {`${group} (${data.length} ${getI18nTitleDataLength(i18n.element, i18n.elements, data.length)})`}
                                                    </AccordionSummaryMUI>
                                                    <AccordionDetails style={{ padding: '0px' }}>
                                                        {data.map(observation => <ObservationsCard observation={observation}/>)}
                                                    </AccordionDetails>
                                                </AccordionMUI>
                                            </div>
                                        )
                                    })}
                                </> :
                                <div style={ { padding: '5%' } }>
                                    <div className='col s12 text-align-center'>
                                        <Icon size='Medium' style={{ color: 'black' }} icon='remove_red_eye'/>
                                    </div>
                                    <div className='center font-size-20'>{ i18n.noResults }</div>
                                </div>}
                        </TabList>
                    </div>
                    :
                    <div className='padding-top-7 padding-left-2 padding-right-2 padding-bottom-2'>
                        <ProgressBar indeterminate withMessage/>
                    </div>
            }
            <Dialog
                onClose={() => {
                    setPopupAddObservation(false)
                    onSetObservation(initialObservation)
                }}
                fullWidth
                maxWidth='lg'
                open={openPopup}
            >
                <DialogContent>
                    <div className='row no-margin'>
                        <SimpleDatePicker
                            col={12}
                            obligatory
                            style={{ paddingBottom: '10px' }}
                            label={i18n.date}
                            value={newObservation.date}
                            max={moment().valueOf()}
                            onChange={ v => onChangeObservation({ date: v })}
                        />
                        <Input
                            col={12}
                            obligatory
                            value={getHour(newObservation.hour)}
                            title={i18n.startHour}
                            onChange={v => onChangeHour(v, v2 => onChangeObservation({ hour: v2 }))}
                        />
                        <Select
                            noNullValue
                            obligatory
                            className='padding-bottom-1'
                            style={{ paddingTop: '10px' }}
                            col={ 12 }
                            noSort={true}
                            options={ observationTypes }
                            label={ i18n.observationType }
                            value={ newObservation.observationType }
                            nullLabel='&nbsp;'
                            onChange={ v => onChangeObservation({ observationType: v }) }
                        />
                        <Select
                            className='padding-bottom-1'
                            obligatory
                            col={ 12 }
                            noSort={true}
                            options={ observationValeurs }
                            label={ i18n.value }
                            value={ newObservation.observationCode }
                            nullLabel='&nbsp;'
                            onChange={ v => onChangeObservation({ observationCode: v?.toString() }) }
                        />
                        <Textarea col={12} value={newObservation.comment} title={i18n.comment} onChange={v => onChangeObservation({ comment: v })} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setPopupAddObservation(false)
                        onSetObservation(initialObservation)
                    }} variant='outlined'
                    >
                        {i18n.close}
                    </Button>
                    <Button disabled={!isConditionValidated} onClick={() => addObservation()} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

HydrometerObservationsApp.propTypes = {
    observation: PropTypes.instanceOf(DtoHydrometryObservation),
}

export default HydrometerObservationsApp