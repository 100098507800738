import { isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getI18nOrLabel } from 'utils/StringUtil'
import Table from '../../../../../../components/datatable/Table'
import { getFullDateMini } from '../../../../../../utils/DateUtil'
import DtoUser from '../../../dto/DtoUser'
import DtoUserUsageStatistic from '../../../dto/DtoUserUsageStatistic'
import DtoUserUsageStatisticItem from '../../../dto/DtoUserUsageStatisticItem'

class UsageStatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.userUsageStatistics, this.props.userUsageStatistics)
            || !isEqual(nextProps.user, this.props.user)
    }

    getReduceStatistics = () => {
        return reduce(this.props.userUsageStatistics, (a, b) => {
            if (!a[`${b.module}_${b.value}`]) {
                a[`${b.module}_${b.value}`] = {
                    module: b.module,
                    value: b.value,
                    datas: [],
                }
            }
            a[`${b.module}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getDatas = () => {
        const reduceValues = this.getReduceStatistics()
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            return new DtoUserUsageStatisticItem({
                stationType: getI18nOrLabel(statisticObject.module.toLowerCase()),
                functionnality: getI18nOrLabel(statisticObject.value),
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                nbAccess: listOfStatistics.length,
            })
        }), 'nbAccess', 'desc')
    }

    render() {
        const login = this.props.user.login || i18n.users
        if (this.props.userUsageStatistics.length) {
            const data = this.getDatas()
            return (
                <div className='row no-margin card'>
                    <div className='col s12 no-padding'>
                        <Table title={ `10 ${i18n.userUsageStatistics}` }
                            type={ new DtoUserUsageStatisticItem({}) } data={ take(data, 10) }
                            exportButtonOnHeader exportData={ data }
                            showNbElements={ false }
                            exportName={ i18n.userUsageStatisticsExport + login }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

UsageStatisticsPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userUsageStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserUsageStatistic)),
}
const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
    }
}
export default connect(mapStateToProps)(UsageStatisticsPanel)