/* eslint-disable react/forbid-prop-types */
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { nbPerPageLabel } from '../../referencial/constants/ReferencialConstants'
import Table from './Table'

const SelectionTable = ({
    maxHeight,
    height,
    componentClassName = '',

    selectedData = [],
    selectedHeaders = [],
    selectedCustomHeaders = {},
    selectedTitle = '',
    selectedActions = [],

    listData = [],
    listHeaders = [],
    listCustomHeaders = {},
    listTitle = '',

    onAdd = () => { },
    onDelete = () => { },
    addAll,
    deleteAll,
    searchable,
    nbPerPage,
}) => {
    const actionAddAll = addAll ? [{
        onClick: () => addAll(listData),
        iconName: 'add',
        tooltip: i18n.addAll,
    }] : []

    const actionDeleteAll = deleteAll ? [{
        onClick: deleteAll,
        iconName: 'close',
        tooltip: i18n.deleteAll,
    }, ...selectedActions] : selectedActions

    const listDataWithIcon = listData.map(data => ({
        ...data,
        nullValue3: <i className='material-icons'>add</i>,
    }))

    const selectedDataWithIcon = selectedData.map(data => ({
        ...data,
        nullValue3: <i className='material-icons'>close</i>,
    }))

    return (
        <Grid container spacing={1} className={componentClassName} style={{ height }}>
            <Grid item xs={6}>
                <Table
                    title={listTitle}
                    condensed
                    data={listDataWithIcon}
                    type={{ headers: [...listHeaders, 'nullValue3'] }}
                    customHeaders={listCustomHeaders}
                    sortable
                    maxHeight={maxHeight}
                    onClick={onAdd}
                    paging
                    nbPerPageLabel={nbPerPage || nbPerPageLabel}
                    searchable={searchable}
                    actions={actionAddAll}
                />
            </Grid>
            <Grid item xs={6}>
                <Table
                    title={selectedTitle}
                    condensed
                    data={selectedDataWithIcon}
                    type={{ headers: ['nullValue3', ...selectedHeaders] }}
                    customHeaders={selectedCustomHeaders}
                    sortable
                    maxHeight={maxHeight}
                    onClick={onDelete}
                    paging
                    nbPerPageLabel={nbPerPage || nbPerPageLabel}
                    searchable={searchable}
                    actions={actionDeleteAll}
                />
            </Grid>
        </Grid>
    )
}

SelectionTable.propTypes = {
    maxHeight: PropTypes.string,
    height: PropTypes.string,
    componentClassName: PropTypes.string,

    selectedData: PropTypes.arrayOf(PropTypes.object),
    selectedHeaders: PropTypes.arrayOf(PropTypes.string),
    selectedCustomHeaders: PropTypes.object,
    selectedTitle: PropTypes.string,
    selectedActions: PropTypes.arrayOf(PropTypes.shape({
        component: PropTypes.element,
        onClick: PropTypes.func,
        iconName: PropTypes.string,
        tooltip: PropTypes.string,
    })),

    listData: PropTypes.arrayOf(PropTypes.object),
    listHeaders: PropTypes.arrayOf(PropTypes.string),
    listCustomHeaders: PropTypes.object,
    listTitle: PropTypes.string,

    onAdd: PropTypes.func,
    addAll: PropTypes.func,
    onDelete: PropTypes.func,
    deleteAll: PropTypes.func,
    searchable: PropTypes.bool,
    nbPerPage: PropTypes.arrayOf(PropTypes.object),
}

export default SelectionTable