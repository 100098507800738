import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { add, groupBy, isEqual, maxBy, minBy, some, template, zipWith } from 'lodash'

import OlMap from '../../utils/mapUtils/Map'
import DtoStation from '../../station/dto/DtoStation'
import OSM from '../../utils/mapUtils/layers/OSM'
import OSMSatellite from '../../utils/mapUtils/layers/OSMSatellite'
import {
    filterStationCoordinates,
    filterStationsCoordinates,
    getDefaultCoordinates,
    getWGS84Coordinate,
    isProjectionValid,
} from '../../utils/mapUtils/CoordinateUtils'
import DtoSite from '../../station/dto/sites/DtoSite'
import DtoSiteType from '../../station/dto/sites/DtoSiteType'
import { addNewLayers } from './mapTreatments/AddingLayers'
import { getSetting, getSettingJson } from '../../utils/SettingUtils'
import { arrayOf, getMapStateToProps, getPropTypes, instanceOf, objectOf } from '../../utils/StoreUtils'
import { STATION_NAME_ASSOCIATION } from '../../station/constants/StationConstants'
import { getMapPointsScale } from '../../utils/mapUtils/SiteTypes'
import WatershedDto from '../../referencial/components/watershed/dto/WatershedDto'
import CityDto from '../../referencial/components/city/dto/CityDto'
import DtoQualitometer from '../../quality/dto/DtoQualitometer'
import DtoSearchAnalysis from '../../quality/dto/DtoSearchAnalysis'
import OSMRelief from '../../utils/mapUtils/layers/OSMRelief'
import OSMRoadMap from '../../utils/mapUtils/layers/OSMRoadMap'
import DtoQualitometerLight from '../../quality/dto/DtoQualitometerLight'
import IGNMap from '../../utils/mapUtils/layers/IGNMap'
import WHITE from '../../utils/mapUtils/layers/WHITE'
import { homeListOfLayersOpacity, homeSelectedBackground } from 'carto/constants/CartoConstants'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import SettingDto from 'administration/dto/SettingDto'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'

const storeProps = {
    themeLayers: false,
    qualitometers: false,
    settings: false,
    cities: false,
}

class CartographyMapPanel extends Component {
    constructor(props) {
        super(props)
        const layers = this.getCartographyBackground()
        this.state = {
            mapConf: this.getDefaultMapConf(),
            layers: [layers],
            layerIndexes: {},
        }
    }

    getDefaultMapConf = () => {
        const { town, city } = this.props
        const defaultX = parseFloat(getSetting(this.props.settings, 'defaultMapXCoordinate')) || parseFloat(getSetting(this.props.applicationSettings, 'defaultMapXCoordinate')) || getDefaultCoordinates()[0]
        const defaultY = parseFloat(getSetting(this.props.settings, 'defaultMapYCoordinate')) || parseFloat(getSetting(this.props.applicationSettings, 'defaultMapYCoordinate')) || getDefaultCoordinates()[1]
        const defaultZoom = parseFloat(getSetting(this.props.settings, 'defaultMapZoom')) || parseFloat(getSetting(this.props.applicationSettings, 'defaultMapZoom')) || 9
        const useCity = town && town.length > 0 && city.x && city.y
        return useCity ? { center: [city.x, city.y], zoom: defaultZoom } : { center: [defaultX, defaultY], zoom: defaultZoom }
    }

    getNewBackgroundLayer = (backgroundType) => {
        switch (backgroundType) {
            case 'SATELLITE':
                return OSMSatellite()
            case 'RELIEF':
                return OSMRelief()
            case 'ROADMAP':
                return OSMRoadMap()
            case 'IGN':
                return IGNMap()
            case 'WHITE':
                return WHITE()
            default:
                return OSM()
        }
    }

    setCartographyBackground = (backgroundType) => {
        const newLayer = this.getNewBackgroundLayer(backgroundType)
        newLayer.getLayer().setZIndex(-1)
        const newLayers = this.state.layers.map((elem, i) => i === 0 ? newLayer : elem)
        this.setState({ layers: newLayers })
    }

    getSelectedBackgroundParameter = () => {
        const { settings } = this.props
        const selectedBackgroundParameter = settings.find(s => s.parameter === homeSelectedBackground)?.value
        return selectedBackgroundParameter
    }

    getSelectedCartographyBackground = () => {
        const selectedBackgroundParameter = this.getSelectedBackgroundParameter()
        if (selectedBackgroundParameter) {
            return selectedBackgroundParameter
        }
        return 'OSM'
    }

    getCartographyBackground = () => {
        const cartoBackground = this.getSelectedCartographyBackground()
        if (this.props.satelliteMode) {
            return OSMSatellite()
        }
        switch (cartoBackground) {
            case 'SATELLITE':
                return OSMSatellite()
            case 'RELIEF':
                return OSMRelief()
            case 'ROADMAP':
                return OSMRoadMap()
            case 'IGN':
                return IGNMap()
            case 'WHITE':
                return WHITE()
            default:
                return OSM()
        }
    }

    componentWillMount() {
        if (this.state.layers.length === 1) {
            this.setState({
                ...addNewLayers(this.props, this.state.layerIndexes, this.getCartographyBackground(), getSetting(this.props.applicationSettings, 'myMaps')),
                ...this.centerMap(),
            }, () => this.changeLayersVisibility(this.props))
        }
    }

    getExtent = (props, sites) => {
        if (props.stationsPoints.length !== 0) {
            const wgs84Sites = sites ? sites : filterStationsCoordinates(props.stationsPoints, props.citiesIndex).map(s => getWGS84Coordinate(s))
            const minX = minBy(wgs84Sites, s => s[0])[0]
            const maxX = maxBy(wgs84Sites, s => s[0])[0]
            const minY = minBy(wgs84Sites, s => s[1])[1]
            const maxY = maxBy(wgs84Sites, s => s[1])[1]
            return {
                extent: [minX, minY, maxX, maxY],
            }
        }
        return {}
    }

    getStationCenter = (station, props) => {
        const stationWithCoordinates = filterStationCoordinates(station, props.citiesIndex)
        return stationWithCoordinates ? getWGS84Coordinate(stationWithCoordinates) : null
    }

    centerMap = (props = this.props, state = this.state, size = null) => {
        const { station } = props
        const newSize = size || state.mapConf.size
        if (props.componentType !== 'homepage') {
            if (station && station.id) {
                if (station.x && station.y && station.projection) {
                    const center = this.getStationCenter(station, props)
                    if (center) {
                        return {
                            mapConf: {
                                size: newSize,
                                center,
                                zoom: props.defaultZoom,
                            },
                        }
                    }
                } else if (props.stationsPoints.length) {
                    const center = this.getStationCenter(props.stationsPoints[0], props)
                    if (center) {
                        return {
                            mapConf: {
                                size: newSize,
                                center,
                                zoom: props.defaultZoom,
                            },
                        }
                    }
                }
            } else if (props.stationsPoints.length !== 0) {
                if (props.stationsPoints.length === 1 || props.forceZoom) {
                    const center = this.getStationCenter(props.stationsPoints[0], props)
                    if (center) {
                        return {
                            mapConf: {
                                size: newSize,
                                center,
                                zoom: props.defaultZoom,
                            },
                        }
                    }
                }
                const localizedSites = filterStationsCoordinates(props.stationsPoints, props.citiesIndex)
                const wgs84Sites = localizedSites.map(s => getWGS84Coordinate(s))
                const sum = wgs84Sites.reduce((acc, value) => zipWith(acc, value, add), [0, 0])
                const extent = props.componentType !== 'alerts' ? this.getExtent(props, wgs84Sites) : {}
                return {
                    mapConf: {
                        ...extent,
                        size: newSize,
                        center: [sum[0] / localizedSites.length, sum[1] / localizedSites.length],
                        zoom: props.defaultZoom,
                    },
                }
            } else {
                return {
                    mapConf: {
                        size: newSize,
                        center: this.state.mapConf.center,
                        zoom: props.defaultZoom,
                        wfscenter: true,
                    },
                }
            }
        }
        return {
            mapConf: {
                ...state.mapConf,
                size: newSize,
            },
        }
    }

    newLayerExists = (props, nextProps, layerNames) => {
        return some(layerNames, layerName => (nextProps.exists[layerName] && !this.props.exists[layerName]))
    }

    getStationsHash = (stations) => {
        return stations.map(s => (s.color || '') + (s.name || s.stationName || '') + (s.markerIcon || '') + (s.markerText || '') + (s.x || '') + (s.y || '')).join()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.height !== nextProps.height) {
            this.setHeight(nextProps.height)
        }
        if (this.newLayerExists(this.props, nextProps, Object.keys(nextProps.exists)) || !isEqual(this.props.oneThemeLayer, nextProps.oneThemeLayer)
            || this.getStationsHash(this.props.stationsPoints) !== this.getStationsHash(nextProps.stationsPoints)) {
            this.setState(addNewLayers(nextProps, this.state.layerIndexes, this.getCartographyBackground(), getSetting(this.props.applicationSettings, 'myMaps')), () => this.changeLayersVisibility(nextProps))
        } else if (this.state.layers.length > 1) {
            this.changeLayersVisibility(nextProps)
        }
        if (nextProps.stationsPoints.length !== this.props.stationsPoints.length) {
            this.setState({ layers: [this.getCartographyBackground()] }, () => this.componentWillMount())
        }
    }

    changeLayersVisibility = (props) => {
        const tmpLayers = this.state.layers.slice()
        this.applyAllVisible(props, tmpLayers)
        this.setState({ layers: tmpLayers })
    }

    applyAllVisible = (props, tmpLayers) => {
        const stationGroup = groupBy(props.stationsPoints, 'typeName')
        const qualitoGroup = groupBy((stationGroup.quality || []), 'stationType')
        const installationGroup = groupBy((stationGroup.installation || []), 'installationType')
        Object.keys(props.exists).map(layerName => {
            const index = this.state.layerIndexes[layerName]
            const layer = index && this.state.layers[index]
            if (layer && layer.layer) {
                const opacitySaved = (getSettingJson(this.props.settings, homeListOfLayersOpacity) || []).find((v) => v.layerName === layerName)
                tmpLayers[index].layer.setVisible(props.visible[layerName])
                if (opacitySaved) {
                    tmpLayers[index].layer.setOpacity(opacitySaved?.opacity ? opacitySaved.opacity : 1)
                }
            }
            if (layerName.startsWith('qualitometerType')) {
                const qualitos = qualitoGroup[layerName.split('qualitometerType')[1]] || []
                if (qualitos.length) {
                    this.setPointsOpacity(qualitos, props.visible[layerName] ? 1 : 0)
                }
            }
            if (layerName.startsWith('installationType')) {
                const installations = installationGroup[layerName.split('installationType')[1]] || []
                if (installations.length) {
                    this.setPointsOpacity(installations, props.visible[layerName] ? 1 : 0)
                }
            }
        })
    }

    getLayers = () => this.state.layers

    render() {
        const map = this.state.mapConf.size ?
            (<OlMap mapConf={ this.state.mapConf }
                layers={ this.state.layers }
                station={ this.props.station }
                analysis={ this.props.analysis }
                stationsPoints={ this.props.stationsPoints }
                popupProps={ { filters: ['Point'] } }
                popupStyle={ this.props.popupStyle }
                onMoveEnd={ this.props.onMoveEnd }
                fullScreen={ this.props.fullScreen }
                tabs={ this.props.tabs }
                onChangeTab={ this.props.onChangeTab }
                openByDefault={ this.props.openByDefault }
                stationsPointsExport={ this.props.stationsPointsExport }
                layersExport={ this.props.layersExport }
                stationExport={ this.props.stationExport }
                analysisExport={ this.props.analysisExport }
                onClickMap={ this.props.onClickMap }
                tabsLoadOnClick={ this.props.tabsLoadOnClick }
                noMarkerTooltip={ this.props.noMarkerTooltip }
                noSearchBar={ this.props.noSearchBar }
                noTownsOnClick={ this.props.noTownsOnClick }
                getTownObjects={ this.props.getTownObjects }
                getStation={ this.props.getStation }
                visible={ this.props.visible }
                onToggleFullScreen={this.props.onToggleFullScreen}
                styleContainer={this.props.styleContainer}
            />) :
            <div id='sieau-map' />
        return (
            <div id='sieau-map' className='row card no-margin' style={this.props.styleContainer}>
                <div className='col l12 s12 m12 no-padding card-content' style={this.props.styleContainer}>
                    { map }
                </div>
            </div>
        )
    }

    setPointScale = (station, huge, layerName) => {
        const layerIndex = this.state.layerIndexes[layerName ? layerName : STATION_NAME_ASSOCIATION[station.typeName] + (this.props.colorMode && station.color ? `_${station.color.toUpperCase()}` : '')]
        const layer = this.state.layers[layerIndex]
        const foundLayerPoint = layer ? layer.uuid.find(layerUuid => (layerUuid.obj.code || '') + layerUuid.obj.id === (station.code || '') + station.id) : null
        if (foundLayerPoint) {
            const feature = layer.getLayer().getSource().getFeatureById(foundLayerPoint.uuid)
            const baseScale = getMapPointsScale(layer.objPoints.typeParams[1])
            const newScale = station.typeName === 'productionUnit' && baseScale <= 0.8 ? baseScale + 0.2 : baseScale
            feature.getStyle().getImage().setScale(huge ? 1 : (foundLayerPoint.obj.markerText ? 1 : (station.scale ? station.scale : newScale)))
            feature.getStyle().setZIndex(huge ? Number.POSITIVE_INFINITY : 100)
            feature.changed()
        }
    }

    setAndReturnLayerOpacity = (layerName, opacity) => {
        const layerIndex = this.state.layerIndexes[layerName]
        const layer = this.state.layers[layerIndex]
        if (!layer) {
            return opacity
        }
        layer.getLayer().setOpacity(opacity)
        return layer.getLayer().getOpacity()
    }

    setPointsOpacity = (stations, opacity) => {
        const layerIndex = this.state.layerIndexes[STATION_NAME_ASSOCIATION[stations[0].typeName]]
        const layer = this.state.layers[layerIndex]
        stations.map(station => this.setPointOpacity(station, opacity, layer))
        if (layer) {
            layer.getLayer().changed()
        }
    }

    setPointOpacity = (station, opacity, usedLayer) => {
        const layer = usedLayer || this.state.layers[this.state.layerIndexes[STATION_NAME_ASSOCIATION[station.typeName]]]
        const foundLayerPoint = layer ? layer.uuid.find(layerUuid => layerUuid.obj.id === station.id) : null
        if (foundLayerPoint) {
            const feature = layer.getLayer().getSource().getFeatureById(foundLayerPoint.uuid)
            feature.getStyle().getImage().setOpacity(opacity)
            if (!layer) {
                feature.changed()
            }
        }
    }

    setHeight(propsHeight) {
        const height = window.innerHeight - $('#sieau-map').offset().top
        const size = propsHeight ? propsHeight : (height > this.props.minHeight ? height : this.props.minHeight)
        if (this.props.componentType === 'homepage') {
            this.setState(prevState => ({
                mapConf: {
                    ...prevState.mapConf,
                    size,
                },
            }))
        } else {
            this.setState(this.centerMap(this.props, this.state, size))
        }
    }

    componentDidMount() {
        this.setHeight(this.props.height)
        const wrongProjection = this.props.stationsPoints.filter(s => !isProjectionValid(s))
        if (wrongProjection.length) {
            this.props.logInfo(template(i18n.unknownProjectionOnStations)({ nb: wrongProjection.length }))
        }
    }
}

CartographyMapPanel.propTypes = getPropTypes(storeProps, {
    layers: PropTypes.arrayOf(PropTypes.string),
    satelliteMode: PropTypes.bool,
    station: PropTypes.instanceOf(DtoStation),
    oneThemeLayer: PropTypes.object,
    visible: PropTypes.shape({
        DISTRIBUTION_UNIT: PropTypes.bool,
        HYDROMETRIC_STATION: PropTypes.bool,
        INSTALLATION: PropTypes.bool,
        PIEZOMETER: PropTypes.bool,
        PLUVIOMETER: PropTypes.bool,
        PRODUCTION_UNIT: PropTypes.bool,
        QUALITOMETER: PropTypes.bool,
        installationType0: PropTypes.bool,
        installationType1: PropTypes.bool,
        installationType2: PropTypes.bool,
        installationType3: PropTypes.bool,
        installationType4: PropTypes.bool,
        installationType5: PropTypes.bool,
        installationType6: PropTypes.bool,
        installationType7: PropTypes.bool,
        installationType8: PropTypes.bool,
        installationType9: PropTypes.bool,
        installationType10: PropTypes.bool,
        installationType11: PropTypes.bool,
        installationType12: PropTypes.bool,
        installationType13: PropTypes.bool,
        installationType14: PropTypes.bool,
        installationType16: PropTypes.bool,
        installationType17: PropTypes.bool,
        installationType18: PropTypes.bool,
        installationType20: PropTypes.bool,
        'installationType-1': PropTypes.bool,
        qualitometerType0: PropTypes.bool,
        qualitometerType1: PropTypes.bool,
        qualitometerType2: PropTypes.bool,
        qualitometerType3: PropTypes.bool,
        qualitometerType4: PropTypes.bool,
        qualitometerType5: PropTypes.bool,
        qualitometerType6: PropTypes.bool,
        qualitometerType7: PropTypes.bool,
        qualitometerType8: PropTypes.bool,
    }),
    exists: PropTypes.object,
    watermass: PropTypes.string,
    town: PropTypes.string,
    stationsPoints: PropTypes.arrayOf(DtoStation),
    sitesTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSiteType)),
    externalSites: PropTypes.arrayOf(PropTypes.instanceOf(DtoSite)),
    defaultZoom: PropTypes.number,
    minHeight: PropTypes.number,
    height: PropTypes.number,
    onMoveEnd: PropTypes.func,
    colorMode: PropTypes.bool,
    componentType: PropTypes.string,
    stationWatershed: PropTypes.instanceOf(WatershedDto),
    watershed1: instanceOf(WatershedDto),
    watershed2: instanceOf(WatershedDto),
    watershed3: instanceOf(WatershedDto),
    watershed4: instanceOf(WatershedDto),
    citiesIndex: objectOf(CityDto),
    tabs: PropTypes.arrayOf(PropTypes.object),
    openByDefault: PropTypes.bool,
    stationsPointsExport: arrayOf(DtoQualitometerLight),
    layersExport: arrayOf(PropTypes.string),
    stationExport: instanceOf(DtoQualitometer),
    analysisExport: arrayOf(DtoSearchAnalysis),
    onChangeTab: PropTypes.func,
    onClickMap: PropTypes.func,
    tabsLoadOnClick: PropTypes.bool,
    noMarkerTooltip: PropTypes.bool,
    noSearchBar: PropTypes.bool,
    noTownsOnClick: PropTypes.bool,
    getTownObjects: PropTypes.func,
    getStation: PropTypes.func,
    onToggleFullScreen: PropTypes.func,
    forceZoom: PropTypes.bool,
    popupStyle: PropTypes.shape({}),
    styleContainer: PropTypes.shape({}),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    analysisMode: PropTypes.bool,
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    city: PropTypes.instanceOf(CityDto),
    logInfo: PropTypes.func,
})

CartographyMapPanel.defaultProps = {
    minHeight: 350,
}

const mapStateToProps = store => getMapStateToProps(storeProps, {
    sitesTypes: store.StationReducer.sitesTypes,
    externalSites: store.StationReducer.externalSites,
    citiesIndex: store.CityReducer.citiesIndex,
    stationWatershed: store.WatershedReducer.stationWatershed,
    watershed1: store.WatershedReducer.watershed1,
    watershed2: store.WatershedReducer.watershed2,
    watershed3: store.WatershedReducer.watershed3,
    watershed4: store.WatershedReducer.watershed4,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    settings: store.AdministrationReducer.settings,
    city: store.CityReducer.city,
})

const mapDispatchToProps = {
    logInfo: LogAction.logInfo,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CartographyMapPanel)
