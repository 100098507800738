/* eslint-disable max-nested-callbacks */
import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import PropTypes from 'prop-types'
import Table from 'components/datatable/Table'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import Icon from 'components/icon/Icon'
import Tabs from 'components/Tabs'
import ReactEcharts from 'echarts-for-react'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useBoolean from 'utils/customHook/useBoolean'
import { getDate } from 'utils/DateUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import { difference, orderBy, uniq } from 'lodash'
import { SPECIFIC_SUPPORT_CODES } from 'quality/constants/HydrobioConstant'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'

const TABLE = 'TABLE'
const GRAPH = 'GRAPH'

const TablePanel = () => {
    const {
        hydrobioList,
        taxons,
        hydrobioOperation,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
        taxons: store.TaxonReducer.taxons,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const taxonsDevelopment = useSandreList('TAXONS.DEVELOPPEMENT')
    const taxonsSize = useSandreList(`TAXONS.${hydrobioOperation.support}.TAILLE`)

    console.log({ taxonsDevelopment, taxonsSize, hydrobioOperation, label: `TAXONS.${hydrobioOperation.support}.TAILLE` })

    const isSpecific = SPECIFIC_SUPPORT_CODES.includes(`${hydrobioOperation.support}`)

    const specificHeaders = isSpecific ? ['total'] : ['phaseA', 'phaseB', 'phaseC', 'phaceCbis']

    const [searchValue, setSearchValue] = useState('')
    // const [threshold, setThreshold] = useState()
    // const [selection, setSelection] = useState(-1)
    // const [selectionResults, setSelectionResults] = useState([])

    const listFormated = useMemo(() => {
        return hydrobioList.map(list => {
            const {
                taxonCode,
                enumerationA = 0,
                enumerationB = 0,
                enumerationC = 0,
                enumerationC2 = 0,
            } = list
            const taxon = taxons.find(t => t.code === taxonCode)
            return {
                taxon: { value: taxon?.labelWithCode || taxonCode },
                omnidiaCode: { value: taxon?.omnidiaCode },
                phaseA: { value: enumerationA },
                phaseB: { value: enumerationB },
                phaseC: { value: enumerationC },
                phaceCbis: { value: enumerationC2 },
                total: { value: enumerationA + enumerationB + enumerationC + enumerationC2 },
                plenty: { value: '' },
                development: { value: taxonsDevelopment.find(s => s.code === list.developCode)?.name },
                size: { value: taxonsSize.find(s => s.code === list.sizeCode)?.name },
                searchValue: searchAllCharacters(`${taxon?.labelWithCode || taxonCode} ${taxon?.omnidiaCode}`),
            }
        })
    }, [hydrobioList, taxons, taxonsDevelopment, taxonsSize])

    const listFiltered = useMemo(() => {
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValueFormat ? listFormated.filter(l => l.searchValue.includes(searchValueFormat)) : listFormated
    }, [listFormated, searchValue])

    return (
        <>
            <Grid container spacing={1} style={{ padding: '10 10 5 10' }}>
                <Grid item xs={4}>
                    <Input
                        title={i18n.search}
                        value={searchValue}
                        onChange={setSearchValue}
                    />
                </Grid>
                {/* <Grid item xs={4}>
                    <ThresholdSelect
                        selected={threshold}
                        onChange={setThreshold}
                        nullLabel='&nbsp;'
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectionSelect
                        onChange={(results, value) => {
                            setSelection(value)
                            setSelectionResults(results)
                        }}
                    />
                </Grid> */}
            </Grid>
            <Table
                showTitle={false}
                withCard={false}
                data={listFiltered}
                color
                paging
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers: ['taxon', 'omnidiaCode', ...specificHeaders, 'plenty', 'development', 'size'] }}
                condensed
                sortable
            />
        </>
    )
}

const Graph = ({
    selectedTaxon = [],
}) => {
    const {
        hydrobioList,
        taxons,
        hydrobioOperation,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
        taxons: store.TaxonReducer.taxons,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const listFormated = useMemo(() => {
        const listData = hydrobioList.map(({ enumerationA = 0, enumerationB = 0, enumerationC = 0, enumerationC2 = 0, taxonCode }) => {
            const taxon = taxons.find(({ code }) => taxonCode === code)
            return {
                value: enumerationA + enumerationB + enumerationC + enumerationC2,
                taxon: taxon?.labelWithCode || `<${taxonCode}>`,
                taxonShortLabel: taxon?.latinName || `<${taxonCode}>`,
                taxonCode,
            }
        })
        return orderBy(listData, 'taxonShortLabel')
    }, [hydrobioList, taxons])

    const listFiltered = useMemo(() => {
        return listFormated.filter(l => selectedTaxon.includes(l.taxonCode))
    }, [listFormated, selectedTaxon])

    const options = {
        series: [{
            type: 'bar',
            data: listFiltered,
            itemStyle: {
                normal: {
                    color: 'blue',
                    opacity: 1,
                },
            },
        }],
        xAxis: [{
            type: 'category',
            data: listFiltered.map(({ taxonShortLabel }) => taxonShortLabel),
            axisTick: {
                alignWithLabel: true,
            },
            axisLabel: {
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
        }],
        tooltip: {
            formatter: ([{ data: { taxon, value } }]) => `${taxon}</br>${value} ${value > 1 ? i18n.enumerations : i18n.enumeration}`,
            trigger: 'axis',
            axisPointer: {
                show: true,
                type: 'shadow',
            },
        },
        grid: {
            top: '8%',
            left: '3%',
            right: '4%',
            bottom: '80',
            containLabel: true,
            height: 550,
        },
        toolbox: {
            right: '4%',
            top: '2%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: `hydrobio_operation_${getDate(hydrobioOperation.dateStart)}`,
                },
            },
        },
    }
    return (
        <ReactEcharts
            option={options}
            style={{ height: 630 }}
            notMerge
        />
    )
}

Graph.propTypes = {
    selectedTaxon: PropTypes.arrayOf(PropTypes.string),
}

const FilterTab = ({
    selectedTaxon = [],
    setSelectedTaxon = () => {},
}) => {
    const {
        hydrobioList,
        taxons,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')
    // const [dataType, setDataType] = useState()
    // const [selection, setSelection] = useState(-1)
    // const [selectionResults, setSelectionResults] = useState([])

    const listFormated = useMemo(() => {
        return hydrobioList.map(list => {
            const taxon = taxons.find(t => t.code === list.taxonCode)
            const isSelected = selectedTaxon.includes(list.taxonCode)
            return {
                code: { value: list.taxonCode },
                name: { value: taxon?.latinName },
                checkbox: {
                    value: (
                        <Icon
                            size='small'
                            icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
                        />
                    ),
                },
                searchValue: searchAllCharacters(`${taxon?.latinName || ''} ${list.taxonCode}`),
                id: list.taxonCode,
                isSelected,
                lineColor: isSelected ? '#b8d2ff' : '#fff',
            }
        })
    }, [hydrobioList, selectedTaxon, taxons])

    const listFiltered = useMemo(() => {
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValueFormat ? listFormated.filter(l => l.searchValue.includes(searchValueFormat)) : listFormated
    }, [listFormated, searchValue])

    const isAllCheck = useMemo(() => listFiltered.every(l => l.isSelected), [listFiltered])

    const onClickAllCheck = () => {
        const taxonCodes = listFiltered.map(l => l.id)
        const newSelectedTaxon = isAllCheck ? difference(selectedTaxon, taxonCodes) : uniq([...selectedTaxon, ...taxonCodes])
        setSelectedTaxon(newSelectedTaxon)
    }

    return (
        <>
            {/* <Select
                label={i18n.dataTypes}
                options={[]}
                value={dataType}
                onChange={setDataType}
            />
            <SelectionSelect
                onChange={(results, value) => {
                    setSelection(value)
                    setSelectionResults(results)
                }}
            /> */}
            <Input
                title={i18n.search}
                value={searchValue}
                onChange={setSearchValue}
            />
            <Table
                showTitle={false}
                withCard={false}
                data={listFiltered}
                color
                paging
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers: ['code', 'name', 'checkbox'] }}
                maxHeight={'53vh'}
                onClick={({ isSelected, id }) => {
                    if (isSelected) {
                        setSelectedTaxon(prev => prev.filter(c => c !== id))
                    } else {
                        setSelectedTaxon(prev => [...prev, id])
                    }
                }}
                customHeaders={{
                    checkbox: (
                        <Icon size='small' icon={isAllCheck ? 'check_box_outline_blank' : 'check_box'} onClick={onClickAllCheck} />
                    ),
                }}
                condensed
                sortable
            />
        </>
    )
}

FilterTab.propTypes = {
    selectedTaxon: PropTypes.arrayOf(PropTypes.string),
    setSelectedTaxon: PropTypes.func,
}

const GraphPanel = () => {
    const {
        hydrobioList,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
    }), shallowEqual)

    const [selectedTaxon, setSelectedTaxon] = useState(() => hydrobioList.map(l => l.taxonCode))

    return (
        <Grid container spacing={1} style={{ padding: '10' }}>
            <Grid item xs={3}>
                <FilterTab
                    selectedTaxon={selectedTaxon}
                    setSelectedTaxon={setSelectedTaxon}
                />
            </Grid>
            <Grid item xs={9}>
                <Graph selectedTaxon={selectedTaxon} />
            </Grid>
        </Grid>
    )
}

const HydrobioOperationFaunalList = () => {
    const {
        accountUser,
        hydrobioOperation,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const { value: editMode } = useBoolean(false)
    const [comment, setComment] = useState(hydrobioOperation.resultComment || '')

    useActions(() => {
        if (![4, 10, 11, 13, 27].includes(hydrobioOperation.support) || accountUser.consultant === '1') return {}
        return {
            calculateIndexes: {
                qualitometer: hydrobioOperation.qualitometer,
                operations: [hydrobioOperation.id],
                support: hydrobioOperation.support,
            },
        }
    })

    return (
        <Card className='no-box-shadow' cardStyle={{ paddingTop: '10' }}>
            <div style={{ margin: '0 10 10 10' }}>
                <Textarea
                    title={i18n.comment}
                    value={comment}
                    onChange={setComment}
                    disabled={!editMode}
                    active={editMode}
                />
            </div>
            <Tabs
                defaultTab={TABLE}
                tabs={[
                    {
                        constant: TABLE,
                        label: i18n.table,
                    },
                    {
                        constant: GRAPH,
                        label: i18n.graph,
                    },
                ]}
            >
                {tab => (
                    <>
                        {tab === TABLE && <TablePanel />}
                        {tab === GRAPH && <GraphPanel />}
                    </>
                )}
            </Tabs>
        </Card>
    )
}

HydrobioOperationFaunalList.propTypes = {

}

export default HydrobioOperationFaunalList