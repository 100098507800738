import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoAssociatedStation from '../../station/dto/DtoAssociatedStation'
import DtoStation from '../../station/dto/DtoStation'
import { canShowStationTypeData, getSetting } from '../../utils/SettingUtils'
import { arrayOf } from '../../utils/StoreUtils'
import UnitPiezometryPanel from './UnitPiezometryPanel'
import UnitQualityPanel from './UnitQualityPanel'
import DtoProductionUnit from 'productionUnit/dto/DtoProductionUnit'
import DtoProductionUnitLink from 'productionUnit/dto/DroProductionUnitLink'
import UnitHydrometryPanel from './UnitHydrometryPanel'
import UnitSamplingPanel from './UnitSamplingPanel'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { Grid } from '@mui/material'
import { getLocalStorageJson, setLocalStorageJson } from 'utils/FormUtils'
import UnitPluviometryPanel from './UnitPluviometryPanel'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'

class UnitChartPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dashboardDisplay: getLocalStorageJson('DASHBOARD_DISPLAY') || {},
        }
    }

    hasSampler = () => {
        const { unit, applicationSettings } = this.props
        return !(unit?.typeName === 'installation'
            && getSetting(applicationSettings, `PANEL_${unit.installationType}_SAMPLERS`) === 'hidden')
    }

    hasType = (as, type) => as?.filter(ass => ass.stationLinkedType === type)?.length

    setAccordion = (panelName, e) => {
        const { fromStationType } = this.props
        const actualLocalStorage = getLocalStorageJson('DASHBOARD_DISPLAY') || {}
        const newDisplay = {
            ...actualLocalStorage,
            [fromStationType || 'DEFAULT']: {
                ...actualLocalStorage?.[fromStationType || 'DEFAULT'],
                [panelName]: e,
            },
        }
        setLocalStorageJson('DASHBOARD_DISPLAY', newDisplay)
        this.setState({ dashboardDisplay: newDisplay })
    }

    render() {
        const {
            productionUnit,
            prodUnitLinks,
            associatedSites,
            fromStationType,
            pluviometers,
        } = this.props
        const links = productionUnit ?
            prodUnitLinks.map(pul => ({
                code: productionUnit.code,
                id: pul.id,
                name: productionUnit.name,
                stationLinkedCode: pul.code,
                stationLinkedId: pul.siteCode,
                stationLinkedName: pul.name,
                stationLinkedType: pul.siteType,
                stationType: 5,
            })) : associatedSites

        const { dashboardDisplay } = this.state
        const stationTypeDisplay = dashboardDisplay?.[fromStationType]

        const pluviometersLinked = associatedSites?.filter(a => a?.typeName === STATION_TYPE_NAME.pluviometry)?.map(link => {
            return pluviometers.find(p => p.id === link.stationLinkedId)
        }).filter(q => !!q) || []

        return (this.hasType(links, 1) || this.hasType(links, 2) || this.hasType(links, 3) || this.hasType(links, 4) || this.hasSampler()) && (
            <Grid className='margin-top-1'>
                {
                    this.hasType(links, 1) && canShowStationTypeData(fromStationType, 'PIEZO') && (
                        <UnitPiezometryPanel
                            {...this.props}
                            associations={links}
                            accordionDisplay
                            expanded={stationTypeDisplay?.PIEZO}
                            setExpanded={(_, v) => this.setAccordion('PIEZO', v)}
                        />
                    ) || null
                }
                {
                    !!(this.hasType(links, 4) && canShowStationTypeData(fromStationType, 'HYDRO')) && (
                        <UnitHydrometryPanel
                            associations={links}
                            expanded={stationTypeDisplay?.HYDRO}
                            setExpanded={(_, v) => this.setAccordion('HYDRO', v)}
                        />
                    ) || null
                }
                {
                    !!pluviometersLinked.length && canShowStationTypeData(fromStationType, 'PLUVIO') && (
                        <Grid item xs={12}>
                            <UnitPluviometryPanel
                                pluviometersLinked={pluviometersLinked}
                                expanded={stationTypeDisplay?.PLUVIO}
                                setExpanded={(_, v) => this.setAccordion('PLUVIO', v)}
                            />
                        </Grid>
                    )
                }
                {
                    this.hasSampler() && fromStationType !== 'RESOURCE' && (
                        <UnitSamplingPanel
                            associations={links}
                            expanded={stationTypeDisplay?.SAMPLE}
                            setExpanded={(_, v) => this.setAccordion('SAMPLE', v)}
                        />
                    ) || null
                }
                {
                    this.hasType(links, 3) && canShowStationTypeData(fromStationType, 'QUALITO') && (
                        <UnitQualityPanel
                            {...this.props}
                            associations={links}
                            expanded={stationTypeDisplay?.QUALI}
                            setExpanded={(_, v) => this.setAccordion('QUALI', v)}
                        />
                    ) || null
                }
            </Grid>
        ) || null
    }
}

UnitChartPanel.propTypes = {
    id: PropTypes.string,
    associatedSites: arrayOf(DtoAssociatedStation),
    uncheckedStations: arrayOf(DtoStation),
    setDataLoaded: PropTypes.func,
    fetchAssociations: PropTypes.func,
    fromStationType: PropTypes.string,
    dontShowEmptyCharts: PropTypes.bool,
    productionUnit: PropTypes.instanceOf(DtoProductionUnit),
    prodUnitLinks: arrayOf(DtoProductionUnitLink),
    unit: PropTypes.object,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
}

const mapStateToProps = store => {
    return {
        associatedSites: store.StationReducer.associatedSites,
        prodUnitLinks: store.ProductionUnitReducer.prodUnitLinks,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        pluviometers: store.PluviometryReducer.pluviometers,
    }
}

export default connect(mapStateToProps)(UnitChartPanel)