import PropTypes from 'prop-types'
import React from 'react'
import { getFullDate } from 'utils/DateUtil'
import CardList from '../../../../components/list/cardList/CardList'
import AlertSitePanel from '../../map/AlertSitePanel'


const PointPanel = ({
    title = '',
    sites = [],
    onToggle = () => {},
    onClick = () => {},
}) => (
    <div>
        <CardList
            collapsible
            data={[
                {
                    title,
                    titleColor: '',
                    cards: sites.map(s => ({
                        content: (
                            <div>
                                <div className='row no-margin no-padding valign-wrapper'>
                                    <div className={'col s12 no-padding'}>
                                        <AlertSitePanel
                                            onClick={onClick}
                                            divider={false}
                                            site={s}
                                            measureDate={getFullDate(s.measureDate)}
                                            onToggle={() => onToggle(s)}
                                        />
                                    </div>
                                </div>
                                <div className='divider' />
                            </div>
                        ),
                        color: s.headband ? s.headband : 'WHITE',
                    })),
                },
            ]}
        />
    </div>
)

PointPanel.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    titleColor: PropTypes.string,
    sites: PropTypes.arrayOf(PropTypes.object),
    showSymbol: PropTypes.bool,
    onToggle: PropTypes.func,
    onClick: PropTypes.func,
    maxHeight: PropTypes.string,
}

export default PointPanel
