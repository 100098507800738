import {
    callPromisesSequentially,
    checkAuth,
    checkError,
    genericCreate,
    genericDelete,
    genericFetch,
    genericPromise,
    genericUpdate,
    getAbortSignal,
    getAuthorization,
    getJson,
} from '../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import fetch from 'isomorphic-fetch'
import ApplicationConf from '../../conf/ApplicationConf'
import download from 'downloadjs'
import LogAction from '../../log/actions/LogAction'
import WaitAction from 'wait/WaitAction'
import moment from 'moment'
import { chunk, flatten, sumBy, zip } from 'lodash'
import { downloadURI } from 'utils/ExportDataUtil'
import { AgriActionConstant } from 'agriAdministration/reducers/AgriReducer'
import { CHANGE_EXPLOITATION_ADMINISTRATOR, CREATE_EXPLOITATIONS_EVENTS, CREATE_SURVEYS_EVENTS,
    DELETE_SERVICE, DELETE_TANKS_TYPE, DELETE_WATER_TURN, EDITION_DECLARATION, EDITION_NOTIFICATION,
    UPDATE_RESTRICTIONS, UPDATE_SERVICE, UPDATE_SURVEY, UPDATE_TANKS_TYPE, UPDATE_WATER_TURNS,
    VALIDATE_INSTALLATIONS_SURVEY } from 'agriAdministration/constants/AgriConstants'
import { getDateExport } from 'utils/DateUtil'

const AgriAction = {

    promiseSurveys: () => fetch(ApplicationConf.agri.surveys(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchSurveys: () => dispatch => AgriAction.promiseSurveys()
        .then(json => dispatch(AgriActionConstant.receiveSurveys(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.surveys} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.surveys))
        }),

    promiseSurveysWithStats() {
        return genericPromise(ApplicationConf.agri.surveysWithStats())
    },

    fetchSurveysWithStats: () => dispatch => AgriAction.promiseSurveysWithStats()
        .then(json => dispatch(AgriActionConstant.receiveSurveysWithStats(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.surveys} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.surveys))
        }),

    promiseExploitationsLight() {
        return genericPromise(ApplicationConf.agri.exploitationsLight())
    },

    fetchExploitationsLight: () => dispatch => AgriAction.promiseExploitationsLight()
        .then(json => dispatch(AgriActionConstant.receiveExploitationsLight(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.exploitations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.exploitations))
        }),

    promiseExploitationsExportFullData() {
        return genericPromise(ApplicationConf.agri.exploitationsExportFull())
    },

    fetchExploitationsExportFullData: () => dispatch => AgriAction.promiseExploitationsExportFullData()
        .then(json => dispatch(AgriActionConstant.receiveExploitationsExportFullData(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.exportName} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.exportName))
        }),

    fetchExploitationsAllData: () => dispatch => Promise.all([
        AgriAction.promiseExploitations(),
        AgriAction.promiseExploitationsExportFullData(),
    ]).then(jsonTab => {
        dispatch(AgriActionConstant.receiveExploitations(jsonTab[0]))
        dispatch(AgriActionConstant.receiveExploitationsExportFullData(jsonTab[1]))
    }),

    updateDeclarationContributor(idEnquete, contributor) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declarationContributor(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contributor),
            })
                .then(checkAuth)
                .then(checkError)
                .then(getJson)
                .then((id) => {
                    dispatch(AgriAction.fetchDeclarationContributors(idEnquete))
                    return id
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.contributor} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.contributor)
                })
        }
    },

    promiseExploitationVolumes(idExploitation) {
        return genericPromise(ApplicationConf.agri.exploitationVolumes(idExploitation))
    },

    fetchExploitationVolumes: (idExploitation) => {
        return (dispatch) => {
            return AgriAction.promiseExploitationVolumes(idExploitation)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveExploitationVolumes(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    updateExploitationVolumes(idExploitation, volumes) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.exploitationVolumes(idExploitation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(volumes),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.volumesSuccessUpdated))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.volumes} : ${err}`))
                    ToastrAction.error(i18n.updateError + i18n.volumes)
                })
        }
    },

    promiseSurvey: id => fetch(ApplicationConf.agri.survey(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchSurvey: id => dispatch => AgriAction.promiseSurvey(id)
        .then(json => {
            dispatch(WaitAction.waitStop())
            dispatch(AgriActionConstant.receiveSurvey(json))
        })
        .catch(err => {
            dispatch(WaitAction.waitStop())
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.survey} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.survey))
        }),

    deleteSurvey(id) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.survey(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError}${i18n.survey} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.survey))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    deleteExploitation(id, params) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.exploitationId(id), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(params),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError}${i18n.exploitation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.exploitation))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    promiseExploitations: () => fetch(ApplicationConf.agri.exploitations(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchExploitations: () => dispatch => AgriAction.promiseExploitations()
        .then(json => dispatch(AgriActionConstant.receiveExploitations(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.folders} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.folders))
        }),

    updateExploitation: (exploitation, callback = () => { }) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.exploitationId(exploitation.idExploitation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(exploitation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.folder} : ${err}`))
                    ToastrAction.error(i18n.updateError + i18n.folder)
                })
        }
    },

    createExploitation: (exploitation) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.exploitation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(exploitation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    return json.id || null
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.exploitation} : ${err}`))
                    ToastrAction.error(i18n.createError + i18n.exploitation)
                })
        }
    },

    updateExploitationFolder: (folder, idExploitation) => {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.folder(idExploitation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(folder),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.dossierSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.folder} : ${err}`))
                    ToastrAction.error(i18n.updateError + i18n.folder)
                })
        }
    },

    updateSurveyParams: (idSurvey, params) => {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.surveyParams(idSurvey), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(params),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.updateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.parameters} : ${err}`))
                    ToastrAction.error(i18n.updateError + i18n.parameters)
                })
        }
    },

    promiseExploitation: id => fetch(ApplicationConf.agri.exploitationId(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchExploitation: id => dispatch => AgriAction.promiseExploitation(id)
        .then(json => dispatch(AgriActionConstant.receiveExploitation(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.folderNotFound} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.folderNotFound))
        }),

    fetchExploitationUser() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.exploitation(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveExploitation(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.exploitation} : ${err}`))
                })
        }
    },

    fetchAllMatsRepartitions() {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.matsRepartition(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveAllMatsRepartitions(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.division} : ${err}`))
                })
        }
    },

    promiseCreateSurvey: survey => fetch(ApplicationConf.agri.createSurvey(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({ survey }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    createSurvey: survey => dispatch => AgriAction.promiseCreateSurvey(survey)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.surveys} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.surveys))
        }),

    updateSurvey: survey => genericUpdate(ApplicationConf.agri.survey(survey.idSurvey), UPDATE_SURVEY, { survey }),

    promiseEditionDeclaration: id => genericPromise(ApplicationConf.export.editionDeclaration(id)),

    getEditionDeclaration: id => genericFetch(AgriAction.promiseEditionDeclaration(id), EDITION_DECLARATION),

    promiseEditionSynthesis: id => genericPromise(ApplicationConf.export.editionSynthesis(id)),

    getSynthesisDeclaration: id => genericFetch(AgriAction.promiseEditionSynthesis(id), EDITION_DECLARATION),

    promiseEditionNotification: (idExploitation, year) => genericPromise(ApplicationConf.export.editionNotification(idExploitation, year)),

    getNotifEdition: (idExploitation, year) => genericFetch(AgriAction.promiseEditionNotification(idExploitation, year), EDITION_NOTIFICATION),

    exportContactsSurvey: (titleFile, idSurvey, typeFile, idsExploitations) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportContactsSurvey(idSurvey), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ typeFile, idsExploitations }),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}.${typeFile}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportSurveyNoUses: (titleFile, idSurvey, typeFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportSurveyNoUses(idSurvey, typeFile), {
                method: 'POST',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}_${getDateExport()}.${typeFile}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportConsosSurvey: (titleFile, idSurvey, typeFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportConsosSurvey(idSurvey, typeFile), {
                method: 'POST',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}.${typeFile}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportUsagesRealSurvey: (titleFile, idSurvey, typeFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportUsagesRealSurvey(idSurvey, typeFile), {
                method: 'POST',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}.${typeFile}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportConsosAllSurvey: (titleFile, idSurvey, typeFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportConsosAllSurvey(idSurvey, typeFile), {
                method: 'POST',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}.${typeFile}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportOasis: ({ idSurvey, idDeclaration, idExploitation, codification }, senderCode, recipientCode) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportOasis(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    surveyId: idSurvey,
                    declarationId: idDeclaration,
                    exploitationId: idExploitation,
                    fileName: `Export_OASIS_${codification || idExploitation}`,
                    senderCode,
                    recipientCode,
                }),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `Export_OASIS_${codification || idExploitation}.xml`, blob.type)
                    return true
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                    return false
                })
        }
    },

    exportOasisDeclarations: (surveyId, exploitationIds, senderCode, recipientCode) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportOasisDeclarations(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    surveyId,
                    exploitationIds,
                    fileName: `Export_OASIS_${moment().valueOf()}`,
                    senderCode,
                    recipientCode,
                }),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `Export_OASIS_${moment().valueOf()}.xml`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    fetchSurveyAllExportData: (id) => dispatch => Promise.all([
        AgriAction.promiseDataExportFullSurvey(id),
    ]).then(jsonTab => {
        dispatch(AgriActionConstant.receiveSurveyDataExportFull(jsonTab[0]))
    }),

    exportAgencySurvey: (titleFile, idSurvey, typeFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportAgencySurvey(idSurvey, typeFile), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}.${typeFile}`, blob.type)
                    return true
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                    return false
                })
        }
    },

    promiseDataExportFullSurvey: id => fetch(ApplicationConf.agri.surveyExportFull(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchDataExportFullSurvey: (id) => dispatch => AgriAction.promiseDataExportFullSurvey(id)
        .then(json => dispatch(AgriActionConstant.receiveSurveyDataExportFull(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.survey} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.survey))
        }),

    exportEditionJson: id => {
        return (dispatch) => {
            return fetch(ApplicationConf.export.exportEditionJson(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, 'exportEdition.json', blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportEditionFullJson: id => {
        return (dispatch) => {
            return fetch(ApplicationConf.export.exportEditionFullJson(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, 'exportEdition-full.json', blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportEditionsMultiple(idsDeclarations, exportType) {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportEditionsMultiple(exportType), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(idsDeclarations),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    if (json.name) {
                        downloadURI(`EXPORT/tmp/${json.name}`)
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },


    changeExploitationAdministrator: (id, showToaster) => {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.changeAdministrator(id), {
                method: 'PUT',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    if (showToaster) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.folder} : ${err}`))
                    ToastrAction.error(i18n.updateError + i18n.folder)
                })
        }
    },

    deleteExploitationAdministrator: id => genericDelete(ApplicationConf.agri.changeAdministrator(id), CHANGE_EXPLOITATION_ADMINISTRATOR),

    fetchLastDeclarationByExploitationId(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.lastDeclarationExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then((response) => checkAuth(response, { 404: () => {} }))
                .then(getJson)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.declaration} : ${err}`))
                })
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveLastDeclarationStats(json))
                })
        }
    },

    fetchDeclarationsByExploitationId(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declarationsExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then((response) => checkAuth(response, { 404: () => {} }))
                .then(getJson)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.declarations} : ${err}`))
                })
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveDeclarationsStatsExploitation(json))
                })
        }
    },

    fetchDeclarationByExploitationId(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declarationExploitation(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then((response) => checkAuth(response, { 404: () => {} }))
                .then(getJson)
                .then((json = {}) => {
                    if (json && json.idDeclaration) {
                        dispatch(AgriActionConstant.receiveDeclaration(json))
                        return json
                    }
                    return false
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.declaration} : ${err}`))
                })
        }
    },

    fetchDeclaration(idDeclaration) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declaration(idDeclaration), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json && json.idDeclaration) {
                        dispatch(AgriActionConstant.receiveDeclaration(json))
                        return true
                    }
                    return false
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.declaration} : ${err}`))
                })
        }
    },

    promiseDeclarationContributors(idEnquete) {
        return fetch(ApplicationConf.agri.getDeclarationContributors(idEnquete), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchDeclarationContributors(idEnquete) {
        return (dispatch) => {
            return AgriAction.promiseDeclarationContributors(idEnquete)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveDeclarationContributors(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contributors)
                })
        }
    },

    updateDeclaration(declaration, noFetch) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.declaration(declaration.idDeclaration), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(declaration),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    if (!noFetch) {
                        dispatch(AgriAction.fetchDeclaration(declaration.idDeclaration))
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.declaration} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.declaration)
                })
        }
    },

    recalculateVolumesSurvey(idSurvey) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.surveyRecalculateVolumes(idSurvey), {
                method: 'PUT',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    dispatch(AgriAction.fetchSurvey(idSurvey))
                    dispatch(ToastrAction.success(i18n.updateSuccess))
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    LogAction.logError(`${i18n.updateError + i18n.volumes} : ${err}`)
                    dispatch(ToastrAction.error(i18n.updateError + i18n.volumes))
                })
        }
    },

    validateVolumesSurvey(idSurvey, authorisationDate) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.surveyValidateVolumes(idSurvey, authorisationDate), {
                method: 'PUT',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    dispatch(AgriAction.fetchSurvey(idSurvey))
                    dispatch(ToastrAction.success(i18n.updateSuccess))
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    LogAction.logError(`${i18n.updateError + i18n.volumes} : ${err}`)
                    dispatch(ToastrAction.error(i18n.updateError + i18n.volumes))
                })
        }
    },

    promiseModesIrrigations() {
        return fetch(ApplicationConf.agri.modesIrrigations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchModesIrrigations() {
        return (dispatch) => {
            return AgriAction.promiseModesIrrigations()
                .then((json = []) => {
                    // use as modes irrigations in front
                    dispatch(AgriActionConstant.receiveModesIrrigations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.modesIrrigation} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.modesIrrigation)
                })
        }
    },

    calculVolumeUsage(usage) {
        return () => {
            return fetch(ApplicationConf.agri.calculVolume(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(usage),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    return json || 0
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.errorCalculVolume} : ${err}`)
                    ToastrAction.error(i18n.errorCalculVolume)
                })
        }
    },

    calculPointVolumeReal(idPoint, idExploitation, startDate, endDate) {
        return () => {
            return fetch(ApplicationConf.agri.calculPointVolumeReal(idPoint, idExploitation, startDate, endDate), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    return json || 0
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.errorCalculVolume} : ${err}`)
                    ToastrAction.error(i18n.errorCalculVolume)
                })
        }
    },

    calculSurveyExploitationVolumes(idSurvey, idExploitation) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.calculSurveyExploitationVolumes(idSurvey, idExploitation), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    dispatch(AgriActionConstant.receiveSurveyVolumesExploitation(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.errorCalculVolume} : ${err}`)
                    ToastrAction.error(i18n.errorCalculVolume)
                })
        }
    },

    fetchManagementUnitsVolumesByYear(type, date, endDate) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.getManagementUnitsVolumesByDate(type, date, endDate), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.volumes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.volumes))
                })
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveSurveysVolumes(json))
                })
        }
    },

    fetchManagementUnitsDetailsVolumesByDate(id, date, endDate, params) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.getManagementUnitsDetailsVolumesByDate(id, date, endDate), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(params),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    console.error(`${i18n.fetchError + i18n.volumes} : ${err}`)
                })
                .then((json = {}) => {
                    dispatch(WaitAction.waitStop())
                    return json
                })
        }
    },

    fetchBreakdownSurvey(idSurvey) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.getBreakdownSurvey(idSurvey), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.BreakdownRequirementsSubUnitsManagement} : ${err}`))
                })
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveBreakdownsSurvey(json))
                })
        }
    },

    fetchForecastsSurvey(ids) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.getForecastSurvey(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ ids }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.forecastSurveyDemandBySamplingPoint} : ${err}`))
                })
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveForecastsSurvey(json))
                })
        }
    },

    promiseForecastsUge: (idUge, year) => genericPromise(ApplicationConf.agri.getForecastUge(idUge, year)),

    fetchForecastsUge(idUge, year) {
        return (dispatch) => {
            return AgriAction.promiseForecastsUge(idUge, year)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveForecastsUge(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchActualUsesSurvey(idSurvey) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.getActualUseSurvey(idSurvey), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.actualUseOnSamplePointSurveys} : ${err}`))
                })
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveActualusesSurvey(json))
                })
        }
    },

    fetchActualConsumptionsSurvey(idSurvey) {
        return (dispatch) => {
            return fetch(ApplicationConf.agri.getActualConsumptionSurvey(idSurvey), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.actualConsumptionDuringSurveyPhasesSamplingPoint} : ${err}`))
                })
                .then((json = {}) => {
                    dispatch(AgriActionConstant.receiveConsumptionsSurvey(json))
                })
        }
    },

    validateDeclarations: (idSurvey, declarationIds, declarationVolumes) => genericUpdate(
        ApplicationConf.agri.validateDeclarations(),
        VALIDATE_INSTALLATIONS_SURVEY,
        { declarationVolumes, declarationIds, idSurvey },
        'POST'
    ),

    duplicateSurvey(params) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.duplicateSurvey(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(params),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(ToastrAction.success(i18n.successDuplicateSurvey))
                    dispatch(WaitAction.waitStop())
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.survey} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.survey))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    promiseAllExploitationsEvents: () => genericPromise(ApplicationConf.agri.allExploitationsEvents()),

    fetchAllExploitationsEvents() {
        return (dispatch) => {
            return AgriAction.promiseAllExploitationsEvents()
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveAllExploitationsEvents(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.events)
                })
        }
    },

    promiseExploitationEvents: (id) => genericPromise(ApplicationConf.agri.exploitationEvents(id)),

    fetchExploitationEvents(id) {
        return (dispatch) => {
            return AgriAction.promiseExploitationEvents(id)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveExploitationEvents(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.events)
                })
        }
    },

    promisePAREvents: (id) => genericPromise(ApplicationConf.agri.parEvents(id)),

    fetchPAREvents(id) {
        return (dispatch) => {
            return AgriAction.promisePAREvents(id)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receivePAREvents(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.events)
                })
        }
    },

    createExploitationsEvents: (events) => genericCreate(ApplicationConf.agri.exploitationsEvents(), CREATE_EXPLOITATIONS_EVENTS, events),

    createSurveysEvents: (events) => genericCreate(ApplicationConf.agri.surveysEvents(), CREATE_SURVEYS_EVENTS, events),

    promiseServices: () => genericPromise(ApplicationConf.agri.services()),

    fetchServices() {
        return (dispatch) => {
            return AgriAction.promiseServices()
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveServices(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    deleteService: (id) => genericDelete(ApplicationConf.agri.service(id), DELETE_SERVICE),

    updateService: (id, service) => genericUpdate(ApplicationConf.agri.service(id), UPDATE_SERVICE, service),

    promiseTanksTypes: () => genericPromise(ApplicationConf.referencial.tanksTypes()),

    fetchTanksTypes() {
        return (dispatch) => {
            return AgriAction.promiseTanksTypes()
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveTanksTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    promiseTanksType: (id) => genericPromise(ApplicationConf.referencial.tanksTypeId(id)),

    fetchTanksType(id) {
        return (dispatch) => {
            return AgriAction.promiseTanksType(id)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveTanksType(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    deleteTanksType: (id) => genericDelete(ApplicationConf.referencial.tanksTypeId(id), DELETE_TANKS_TYPE),

    updateTanksType: (id, tanksType) => genericUpdate(ApplicationConf.referencial.tanksTypeId(id), UPDATE_TANKS_TYPE, tanksType),

    createTanksType: (tanksType) => {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.tanksType(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(tanksType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    dispatch(WaitAction.waitStop())
                    if (json.id) {
                        return json.id
                    }
                    throw new Error()
                })
        }
    },

    promiseRestrictions: () => genericPromise(ApplicationConf.agri.restrictions()),

    fetchRestrictions() {
        return (dispatch) => {
            return AgriAction.promiseRestrictions()
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveRestrictions(json))
                    return json
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    promiseSlots: () => genericPromise(ApplicationConf.agri.slots()),

    fetchSlots() {
        return (dispatch) => {
            return AgriAction.promiseSlots()
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveSlots(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    updateRestrictions: (restrictions) => genericUpdate(ApplicationConf.agri.restrictions(), UPDATE_RESTRICTIONS, restrictions),

    promiseWaterTurns: (params) => genericPromise(ApplicationConf.agri.waterTurns(), 'POST', params),

    fetchWaterTurns(params) {
        return (dispatch) => {
            return AgriAction.promiseWaterTurns(params)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveWaterTurns(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    updateWaterTurns: (waterTurns) => genericUpdate(ApplicationConf.agri.waterTurns(), UPDATE_WATER_TURNS, waterTurns),

    deleteWaterTurn: (ids) => genericDelete(ApplicationConf.agri.waterTurns(), DELETE_WATER_TURN, ids),

    addWaterTurns: (data) => genericPromise(ApplicationConf.agri.addWaterTurns(), 'POST', data),

    controlDeclarations(criterias, idExploitations, progressCallback = () => { }, cb = () => { }) {
        return (dispatch) => {
            const groupsSize = idExploitations.length < 1000 ? 10 : idExploitations.length / 100
            const [promises, params] = zip(...chunk(idExploitations, groupsSize).map(stationsGroup => [AgriAction.promiseDeclarationsWarnings, [criterias, stationsGroup, dispatch]]))
            return callPromisesSequentially(promises, params, progressCallback, json => {
                dispatch(AgriActionConstant.receiveDeclarationsWarnings(flatten(json)))
                cb()
            })
        }
    },

    promiseDeclarationsWarnings(criterias, stations, dispatch) {
        return fetch(ApplicationConf.agri.controlSurveyDeclarations(), {
            method: 'POST',
            headers: getAuthorization(),
            signal: getAbortSignal(),
            body: JSON.stringify({ ...criterias, idExploitations: stations }),
        })
            .then(checkAuth).then(getJson).then(checkError).catch(err => {
                if (err.name !== 'AbortError') {
                    dispatch(ToastrAction.error(i18n.dataError))
                    dispatch(LogAction.logError(`${i18n.dataError}: ${err}`))
                }
                return []
            })
    },

    promiseServicesSituations: (body) => genericPromise(ApplicationConf.agri.servicesSituations(), 'POST', body),

    fetchServicesSituations(body) {
        return (dispatch) => {
            return AgriAction.promiseServicesSituations(body)
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveServicesSituations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },

    promiseCalculateServicesSituation(dateCalculus, idExploitations, dispatch) {
        return fetch(ApplicationConf.agri.calculateServicesSituation(), {
            method: 'POST',
            headers: getAuthorization(),
            signal: getAbortSignal(),
            body: JSON.stringify({ dateCalculus, idExploitations }),
        })
            .then(checkAuth).then(getJson).then(checkError).catch(err => {
                if (err.name !== 'AbortError') {
                    dispatch(ToastrAction.error(i18n.dataError))
                    dispatch(LogAction.logError(`${i18n.dataError}: ${err}`))
                }
                return []
            })
    },

    calculateServicesSituation(dateCalculus, data, progressCallback = () => { }, cb = () => { }) {
        return (dispatch) => {
            const groupsSize = data.length < 1000 ? 10 : data.length / 100
            const [promises, params] = zip(...chunk(data, groupsSize).map(stationsGroup => [AgriAction.promiseCalculateServicesSituation, [dateCalculus, stationsGroup, dispatch]]))
            return callPromisesSequentially(promises, params, progressCallback, json => {
                const nbExploitationsTest = sumBy(flatten(json), 'update')
                ToastrAction.warning(nbExploitationsTest)
                cb()
            })
        }
    },

    promiseSummarySurveys: () => genericPromise(ApplicationConf.agri.summarySurveys()),

    fetchSummarySurveys() {
        return (dispatch) => {
            return AgriAction.promiseSummarySurveys()
                .then((json = []) => {
                    dispatch(AgriActionConstant.receiveSummarySurveys(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                    ToastrAction.error(i18n.fetchError)
                })
        }
    },
    fetchDroughtSectorsRestrictions: () => dispatch => {
        return fetch(ApplicationConf.agri.droughtSectorsRestrictions(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(AgriActionConstant.receiveDroughtSectorsRestrictions(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.droughtSectors} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.droughtSectors))
            })
    },

    updateDroughtSectorsRestrictions: (droughtSectorsRestrictions) => dispatch => {
        return fetch(ApplicationConf.agri.droughtSectorsRestrictions(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(droughtSectorsRestrictions),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.update >= 1) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                } else {
                    throw new Error()
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.restrictions} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.restrictions))
            })
    },

    fetchPARs: () => dispatch => {
        return fetch(ApplicationConf.agri.pars(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(AgriActionConstant.receivePARs(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} PARs : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} PARs`))
            })
    },

    fetchPAR: (id) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.agri.par(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(AgriActionConstant.receivePAR(json))
                dispatch(WaitAction.waitStop())
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} PAR : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} PAR`))
                dispatch(WaitAction.waitStop())
            })
    },

    createPAR: (par) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.createPar(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(par),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.id) {
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.createError} PAR : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.createError} PAR`))
                })
        }
    },

    updatePAR: (par) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.par(par.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(par),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.update > 0) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(AgriAction.fetchPAR(par.id))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError} PAR : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.updateError} PAR`))
                })
        }
    },

    deletePAR(id) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.par(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError} PAR : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.deleteError} PAR`))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    validatePAR(id, attributionDate, changeStatus) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.validatePar(id, attributionDate), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ changeStatus }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.allocatedVolumes))
                    dispatch(AgriAction.fetchPAR(id))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError} PAR : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.updateError} PAR`))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchScenario: (id) => dispatch => {
        return fetch(ApplicationConf.agri.scenario(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(AgriActionConstant.receiveScenario(json.scenario))
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.scenarios} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.scenarios}`))
            })
    },

    createMultipleScenarios: (scenarios) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.scenarios(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(scenarios),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.id) {
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.createError} ${i18n.scenarios} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.createError} ${i18n.scenarios}`))
                })
        }
    },

    updateScenario: (scenario) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.scenario(scenario.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(scenario),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.update > 0) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(AgriAction.fetchScenario(scenario.id))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError} ${i18n.scenarios} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.updateError} ${i18n.scenarios}`))
                })
        }
    },

    deleteScenario(id) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.scenario(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError} ${i18n.scenarios} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.deleteError} ${i18n.scenarios}`))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchRulesScenarios: () => dispatch => {
        return fetch(ApplicationConf.agri.rulesScenarios(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(AgriActionConstant.receiveRulesScenarios(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.rules} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.rules}`))
            })
    },

    createRuleScenarios: (rule) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.rulesScenarios(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(rule),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.id) {
                        dispatch(ToastrAction.success(i18n.ruleScenarioSaveSuccessDescr))
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.createError} ${i18n.rule} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.createError} ${i18n.rule}`))
                })
        }
    },

    updateRuleScenarios: (rule) => {
        return dispatch => {
            return fetch(ApplicationConf.agri.ruleScenarios(rule.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(rule),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.update > 0) {
                        dispatch(ToastrAction.success(i18n.ruleScenarioSaveSuccessDescr))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError} ${i18n.rule} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.updateError} ${i18n.rule}`))
                })
        }
    },

    deleteRuleScenarios(id) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.agri.ruleScenarios(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError} ${i18n.rule} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.deleteError} ${i18n.rule}`))
                    dispatch(WaitAction.waitStop())
                })
        }
    },
}

export default AgriAction
