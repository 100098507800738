import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PluviometryAction from '../../../actions/PluviometryAction'
import moment from 'moment/moment'
import ProgressCard from '../../../../components/card/ProgressCard'
import MessageCard from '../../../../components/card/MessageCard'
import i18n from 'simple-react-i18n'
import Axis from '../../../../components/echart/Axis'
import { nFormatter } from '../../../../utils/NumberUtil'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisLabelInterval, histogramIcon, lineIcon,
    toEchartsData, yAutomaticScaleValues,
} from '../../../../components/echart/EChartUtils'
import { getFullDate, getMonthYear, getYearDate } from '../../../../utils/DateUtil'
import { orderBy } from 'lodash'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../../../utils/StatusUtil'
import EChart from '../../../../components/echart/EChart'
import { PluvioCumulOptionIcon } from '../../pluviometryComponents/PluvioOption'
import Bar from '../../../../components/echart/series/Bar'
import useUpdateEffect from '../../../../utils/customHook/useUpdateEffect'
import ChartTabsSuivi from '../../../../components/echart/ChartTabsSuivi'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { getLabel } from '../../../../utils/StoreUtils'
import { getQualifications, getStatuses } from '../../../../utils/QualityUtils'
import PluviometerDto from '../../../dto/PluviometerDto'
import DtoPluviometerStats from '../../../dto/DtoPluviometerStats'
import PropTypes from 'prop-types'
import DtoPluvioMeasure from '../../../dto/measures/DtoPluvioMeasure'
import { SUM_DAY, SUM_DECADE, SUM_HOUR, SUM_MONTH, SUM_WEEK, SUM_YEAR } from '../../../constants/PluvioOptionConstant'
import MultiBand from '../../../../components/echart/series/MultiBand'
import Line from '../../../../components/echart/series/Line'

const SUM_AUTO = 'SUM_AUTO'
const BAR = 'bar'
const LINE = 'line'
const ENVELOPPE_MIN = 'ENVELOPPE_MIN'
const ENVELOPPE_MAX = 'ENVELOPPE_MAX'
const Graphic = ({
    pluviometer,
    data,
    showEnveloppe = false,
    minDate,
    maxDate,
    pluvioGroupedMode,
}) => {
    const chartMinDate = data[0].measures[0].date
    const unit = data[0].infoStat?.unit

    const [typeLineValue, setTypeLineValue] = useState(BAR)


    const chartMaxDate = moment(maxDate)
    const axisLabelObj = getAxisLabelInterval(chartMaxDate, moment(minDate || chartMinDate))

    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const grids = [{
        top: 35,
        right: '2%',
        height: 415,
        left: 60,
    }]

    const allValues = [data[0].measures.map(m => m.value), data[1].measures.map(m => m.value), data[2].measures.map(m => m.value)].flat()

    const yScale = yAutomaticScaleValues(allValues)

    const xAxis = [
        Axis({
            type: 'time',
            position: 'bottom',
            min: minDate ?? chartMinDate,
            max: maxDate ?? moment().valueOf(),
            gridIndex: 0,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }),
    ]

    const yAxis = [
        Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${data[0].infoStat?.label} ${unit ? `[${unit}]` : ''}`,
            gridIndex: 0,
            showSplitLine: true,
            axisLabel: { formatter: nFormatter },
            nameGap: 40,
            isPiezo: true,
            ...yScale,
        }),
    ]

    const getLine = (pluviometerInfos, measures) => {
        const input = {
            name: `${pluviometerInfos.name}`,
            barWidth: 4,
            data: toEchartsData(measures),
            color: data[0].infoStat?.color || 'blue',
            connectNulls: false,
            showSymbol: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
            isPiezo: true,
        }
        switch (typeLineValue) {
            case LINE:
                return Line(input)
            case BAR: default :
                return Bar(input)
        }
    }
    const getEnveloppe = (measures, name) => {
        const jsonMap = measures.map(m => [m.date, m.value, m.status, m.qualification, m.initialPoint])
        return {
            name: `${name} : ${data[0].infoStat?.label}`,
            barWidth: 4,
            data: toEchartsData(measures),
            json: jsonMap,
            color: data[0].infoStat?.color || 'blue',
            connectNulls: false,
            showSymbol: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
        }
    }

    const tooltipDate = (params) => {
        switch (pluvioGroupedMode) {
            case SUM_HOUR :
            case SUM_DAY :
            case SUM_WEEK :
            case SUM_DECADE :
                return getFullDate(params)
            case SUM_MONTH :
                return getMonthYear(params)
            case SUM_YEAR :
                return getYearDate(params)
            default :
                return getFullDate(params)
        }
    }

    const getExportData = () => {
        const dataExport = data[0].measures.map(d => ({
            stationCode: { value: pluviometer.code },
            stationName: { value: pluviometer.name },
            date: { value: getFullDate(d.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: d.value, format: '0.00', cellType: 'number' },
            type: { value: data[0].infoStat.label },
            status: { value: getLabel(getStatuses(), d.status), cellType: 'string' },
            qualification: { value: getLabel(getQualifications(), d.qualification), cellType: 'string' },
            producer: { value: getLabel(contributors, d.producer, 'mnemonique'), cellType: 'string' },
        }))
        if (dataExport.length) {
            dataExport[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type', 'status', 'qualification', 'producer']
        }
        return dataExport
    }

    const seriesHeight = getLine(pluviometer, data[0].measures)
    const seriesEnveloppeMin = getEnveloppe(data[1].measures, i18n.enveloppeMinMax)
    const seriesEnveloppeMax = getEnveloppe(data[2].measures, i18n.enveloppeMinMax)

    const multiBands = MultiBand({ bands: [ seriesEnveloppeMin, seriesEnveloppeMax ], noSort: true })

    const options = {
        series: showEnveloppe ? [seriesHeight, multiBands] : [seriesHeight],
        title: `${pluviometer.code} - ${pluviometer.name}`,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = tooltipDate(params[0].value[2].date)
                const paramsOrder = orderBy(params.map(o => {
                    return ({
                        seriesIndex: o.seriesIndex,
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.value[2].value,
                        status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                        axisIndex: o.axisIndex,
                    })
                }), 'seriesIndex', 'asc')
                const result = paramsOrder.filter(p => p.axisIndex === 0).map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${o.value} ${unit ? `[${unit}]` : ''}<span style="padding-left:5px">${o.status}</span></span>`)).join('')
                return `${date} <br /> ${result} `
            },
        },
        grid: grids,
        xAxis,
        yAxis,
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 500,
        legend: {
            bottom: 60,
            type: 'scroll',
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                myToolExport: {
                    show: true,
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        exportFile({
                            data: getExportData(),
                            exportType: 'xlsx',
                            titleFile: i18n.overview,
                        })
                    },
                },
                myToolLine: {
                    show: true,
                    title: i18n.line,
                    icon: lineIcon,
                    onclick: () => {
                        setTypeLineValue(LINE)
                    },
                },
                myToolBar: {
                    show: true,
                    title: i18n.histogram,
                    icon: histogramIcon,
                    onclick: () => {
                        setTypeLineValue(BAR)
                    },
                },
            },
            right: 65,
        },
    }
    return (
        <div className={'padding-top-1'}>
            <EChart options={options} id='graphGenericPluvioChart' bandCorrection scrollable={true} />
        </div>
    )
}
Graphic.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    data: PropTypes.arrayOf(PropTypes.shape({
        measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasure)),
        infoStat: PropTypes.instanceOf(DtoPluviometerStats),
    })),
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    pluvioGroupedMode: PropTypes.string,
    showEnveloppe: PropTypes.bool,
}

const GraphGenericPluvio = ({
    pluviometer,
    stat,
    showEnveloppe = false,
    pluviometerStatistics,
}) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)

    const [pluvioGroupedMode, setPluvioGroupedMode] = useState(SUM_AUTO)

    // dates des bordures du graphique
    const [minDate, setMinDate] = useState(0)
    const [maxDate, setMaxDate] = useState(moment().valueOf())

    const infoStat = pluviometerStatistics.find(s => s.typeId === stat)

    const dispatch = useDispatch()

    useUpdateEffect(()=> {
        if (stat) {
            setDataLoaded(false)
            const input = {
                stationId: pluviometer.id,
                dataType: stat || 1,
                groupFunc: pluvioGroupedMode,
                chartMode: false,
                startDate: minDate - 1,
                endDate: maxDate,
            }
            const eveloppeMinInput = {
                stationId: pluviometer.id,
                dataType: stat || 1,
                groupFunc: pluvioGroupedMode,
                enveloppe: ENVELOPPE_MIN,
                chartMode: false,
                startDate: minDate - 1,
                endDate: maxDate,
            }
            const eveloppeMaxInput = {
                stationId: pluviometer.id,
                dataType: stat || 1,
                groupFunc: pluvioGroupedMode,
                enveloppe: ENVELOPPE_MAX,
                chartMode: false,
                startDate: minDate - 1,
                endDate: maxDate,
            }
            dispatch(PluviometryAction.loadPluvioChartChronicMeasures([input, eveloppeMinInput, eveloppeMaxInput], p => {
                setProgress(p)
            })).then(() => {
                setDataLoaded(true)
            })
        }
    }, [dispatch, maxDate, minDate, pluvioGroupedMode, pluviometer, stat])

    const {
        pluvioMeasures,
    } = useSelector(store => ({
        pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    }), shallowEqual)


    const onChangeDates = (dates, forced) => {
        if (minDate !== dates.minDate || maxDate !== dates.maxDate || forced) {
            setMinDate(dates?.minDate)
            setMaxDate(dates?.maxDate)
        }
    }

    const measures = pluvioMeasures[0]?.measures
    const measuresEnveloppeMin = pluvioMeasures[1]?.measures
    const measuresEnveloppeMax = pluvioMeasures[2]?.measures


    const data = [{
        measures,
        infoStat,
    },
    {
        measures: measuresEnveloppeMin,
        infoStat,
    },
    {
        measures: measuresEnveloppeMax,
        infoStat,
    },
    ]


    if (!pluvioMeasures.length) {
        return (
            <div style={{ paddingTop: 45 }}>
                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
            </div>
        )
    }
    return (
        <div className='col s12 row no-margin'>
            <div className={'card'} style={{ marginTop: 15 }}>
                <div style={{ position: 'relative', paddingTop: 5, marginTop: 0, marginLeft: 10 }}>
                    <ChartTabsSuivi
                        onChangeDate={ (dates, forced) => onChangeDates(dates, forced) }
                    />
                </div>
                <PluvioCumulOptionIcon
                    style={{ paddingTop: 15, paddingLeft: 420 }}
                    defaultGroupType={pluvioGroupedMode}
                    onChangeGroupType={setPluvioGroupedMode}
                />
                { !dataLoaded && (
                    <div style={{ marginTop: 20 }}>
                        <ProgressCard
                            progress={progress}
                        />
                    </div>
                )}
                { (measures.length < 1) && (
                    <div style={{ paddingTop: 45 }}>
                        <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                    </div>
                )}
                { (dataLoaded && !(measures.length < 1)) && (
                    <Graphic
                        pluviometer={ pluviometer }
                        data={data}
                        showEnveloppe={showEnveloppe}
                        minDate={minDate}
                        maxDate={maxDate}
                        pluvioGrouped={pluvioGroupedMode}
                    />
                )}
            </div>
        </div>
    )
}
GraphGenericPluvio.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    stat: PropTypes.instanceOf(DtoPluviometerStats),
    pluviometerStatistics: PropTypes.arrayOf(DtoPluviometerStats),
    showEnveloppe: PropTypes.bool,
}
export default GraphGenericPluvio