import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PiezometerStationAction from '../../../../../station/actions/PiezometerStationAction'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import { Card, CardContent, Grid } from '@mui/material'
import Input from '../../../../../components/forms/Input'
import Button from '../../../../../components/forms/Button'
import ColorPicker from '../../../../../components/forms/ColorPicker'
import Checkbox from '../../../../../components/forms/Checkbox'
import NumberField from '../../../../../components/forms/NumberField'
import Icon from '../../../../../components/icon/Icon'
import { range, chunk, zip, round, padStart } from 'lodash'
import { getMonthList } from '../../../../../utils/DateUtil'
import SwapComponent from '../../../../../components/card/SwapComponent'
import { hasValue } from '../../../../../utils/NumberUtil'
import { getThresholdColorHtml } from '../../../../../utils/ColorUtil'
import Line from '../../../../../components/echart/series/Line'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import Row from '../../../../../components/react/Row'
import { getMonthThreshold } from '../../../../../components/echart/EChartUtils'
import { getUser } from 'utils/SettingUtils'

const getMonthKey = (m) => `month${padStart(m, 2, '0')}`

const PiezoSuiviThresholds2 = ({
    id,
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    tab, // tab sélectionné en haut à gauche
    minDate,
    maxDate,
    unit,
}) => {
    const dispatch = useDispatch()

    const [swap, setSwap] = useState({})
    const changeSwap = (key, v) => setSwap(({ ...swap, [key]: v }))

    const [baseThresholds, setBaseThresholds] = useState([]) // data at start
    const [tmpThresholds, setTmpThresholds] = useState([]) // data during updates in this form
    const [thresholds, setThresholds] = useState([]) // data applied (visible on chart) but not saved yet

    const [readyApply, setReadyApply] = useState(false)
    const [readySave, setReadySave] = useState(false)

    const change = (idx, changes) => {
        const newThresholds = tmpThresholds.slice()
        newThresholds[idx] = { ...newThresholds[idx], ...changes }
        setTmpThresholds(newThresholds)
        setReadyApply(true)
    }
    const changeIsMonth = (idx, threshold, v) => {
        if (v) {
            change(idx, { value: null, isMonth: true, month01: threshold.value || 1 })
        } else {
            const nullMonth = range(1, 13).reduce((acc, m) => ({ ...acc, [getMonthKey(m)]: null }), {})
            change(idx, { value: threshold.month01, ...nullMonth, isMonth: false })
        }
    }

    const saveChanges = () => {
        dispatch(PiezometerStationAction.updatePiezometerThresholds(id, thresholds, () => {
            setBaseThresholds(thresholds)
            setTmpThresholds(thresholds)
        }, displayCote))
        setReadyApply(false)
        setReadySave(false)
    }
    const applyChanges = () => {
        setThresholds(tmpThresholds)
        setReadyApply(false)
        setReadySave(true)
    }
    const onCancel = () => {
        setTmpThresholds(baseThresholds)
        setThresholds(baseThresholds)
        setReadyApply(false)
        setReadySave(false)
    }

    // fetching thresholds
    useEffect(() => {
        PiezometerStationAction.promisePiezometerThresholds(id, MEASURE_COTE.NGF).then(list => {
            const newList = list.map(th => {
                const isMonth = range(1, 13).some(m => hasValue(th[getMonthKey(m)]))
                const thWithMonth = isMonth ? { ...th, value: null, isMonth } : th
                return thWithMonth
            })
            const listWithDepth = newList.map(th => {
                if (th.isMonth) {
                    return range(1, 13).reduce((acc, m) => {
                        const key = getMonthKey(m)
                        return { ...acc, [`${key}Depth`]: hasValue(th[key]) ? round(landmarkValue - th[key], 2) : null }
                    }, th)
                }
                return { ...th, valueDepth: round(landmarkValue - th.value, 2) }
            })
            setTmpThresholds(listWithDepth)
            setThresholds(listWithDepth)
            setBaseThresholds(listWithDepth)
        })
    }, [])

    // recalculate thresholds on chart
    useEffect(() => {
        const thresholdLines = thresholds.filter(t => !t.dataType || t.dataType == -1).map(t => {
            const thresholdValue = displayCote === MEASURE_COTE.NGF ? t.value : t.valueDepth
            if (t.isMonth) {
                return getMonthThreshold(t, minDate, maxDate, {}, true, true, { isNGF: displayCote === MEASURE_COTE.NGF, landmarkValue })
            }
            return Line({
                name: '',
                data: [],
                isPiezo: true,
                connectNulls: false,
                markLine: { silent: false, symbol: 'none', data: [{
                    yAxis: thresholdValue + 1000, // bandCorrection
                    lineStyle: {
                        color: getThresholdColorHtml(t),
                    },
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: () => `${t.name ? `${t.name} : ${round(thresholdValue, 2)}` : ''} ${unit}`,
                    },
                }] },
            })
        })
        changeParent({ thresholds: thresholdLines })
    }, [thresholds, displayCote, tab])


    return (
        <div>
            <StyledFieldSet>
                <StyledLegend>{ i18n.thresholds }</StyledLegend>
                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                    <Grid item xs={1}/>
                    <Grid item xs={7} >
                        { `${thresholds.length} ${thresholds.length > 1 ? i18n.thresholds : i18n.threshold}` }
                    </Grid>
                    {getUser().consultant !== '1' && (
                        <Grid item xs={4}>
                            <Button
                                title={i18n.add}
                                onClick={() => {
                                    setTmpThresholds([...thresholds, {}])
                                    setReadyApply(true)
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
                {
                    tmpThresholds.filter(t => !t.dataType || t.dataType == -1).map((threshold, idx) => {
                        const left = (
                            <Grid container justifyContent='center' alignItems='center' spacing={1} className='margin-top-1'>
                                <Card sx={{ width: '90%' }} elevation={10}>
                                    <CardContent elevation={10}>
                                        <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                            <Grid item xs={11}>
                                                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                                    <Grid item xs={7}>
                                                        <Input title={i18n.name} value={threshold.name} onChange={v => change(idx, { name: v })}/>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <ColorPicker label={i18n.color} value={getThresholdColorHtml(threshold)} onChange={v => change(idx, { htmlColor: v, color: null })}/>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Icon icon='clear' tooltip={i18n.delete} onClick={() => {
                                                            setTmpThresholds(tmpThresholds.filter((_, idx2) => idx !== idx2))
                                                            setReadyApply(true)
                                                        }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                                    <Grid item xs={3}>
                                                        <NumberField
                                                            title={i18n.valueLabel}
                                                            value={round(displayCote === MEASURE_COTE.NGF ? threshold.value : threshold.valueDepth, 2)}
                                                            onChange={v => {
                                                                if (displayCote === MEASURE_COTE.NGF) {
                                                                    change(idx, { value: v, valueDepth: round(landmarkValue - v, 2) })
                                                                } else {
                                                                    change(idx, { valueDepth: v, value: round(landmarkValue - v, 2) })
                                                                }
                                                            }}
                                                            disabled={threshold.isMonth}
                                                            floatValue
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Checkbox checked={threshold.isOverrunThreshold === '1'} label={i18n.thresholdOverrun} onChange={v => change(idx, { isOverrunThreshold: v ? '1' : '0' })}/>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Checkbox checked={threshold.isMonth} label={i18n.isMonthThreshold} onChange={v => changeIsMonth(idx, threshold, v)}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Icon icon='chevron_right' onClick={() => changeSwap(idx, true)} tooltip={i18n.seeMore}/>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                        const months = range(1, 13).map(m => {
                            const monthKey = getMonthKey(m)
                            return (
                                <Grid item xs={3}>
                                    <NumberField title={getMonthList()[m-1].name}
                                        value={threshold[displayCote === MEASURE_COTE.NGF ? monthKey : `${monthKey}Depth`]}
                                        onChange={v => {
                                            if (displayCote === MEASURE_COTE.NGF) {
                                                change(idx, { [monthKey]: v, [`${monthKey}Depth`]: round(landmarkValue - v, 2) })
                                            } else {
                                                change(idx, { [monthKey]: round((v*-1) + landmarkValue, 2), [`${monthKey}Depth`]: v })
                                            }
                                        }}
                                        disabled={!threshold.isMonth}
                                        floatValue
                                    />
                                </Grid>
                            )
                        })
                        const groups = chunk(months, 3)
                        const values = Reflect.apply(zip, {}, groups) // transpose matrix !
                        const right = (
                            <Grid container justifyContent='center' alignItems='center' spacing={1} className='margin-top-1'>
                                <Card sx={{ width: '90%' }} elevation={10}>
                                    <CardContent elevation={10}>
                                        <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                            <Grid item xs={1}>
                                                <Icon icon='chevron_left' onClick={() => changeSwap(idx, false)} tooltip={i18n.seeMore}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                                    <Grid item xs={7}>
                                                        <Input title={i18n.name} value={threshold.name} onChange={v => change(idx, { name: v })}/>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <ColorPicker label={i18n.color} value={threshold.htmlColor} onChange={v => change(idx, { htmlColor: v })}/>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Icon icon='clear' tooltip={i18n.delete} onClick={() => {
                                                            setTmpThresholds(tmpThresholds.filter((_, idx2) => idx !== idx2))
                                                            setReadyApply(true)
                                                        }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                                    { values[0]}
                                                </Grid>
                                                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                                    { values[1]}
                                                </Grid>
                                                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                                    { values[2]}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                        return (
                            <div>
                                <SwapComponent left={ left } right={ right } isSwapped={swap[idx]}/>
                            </div>
                        )
                    })
                }
                <Row className='padding-bottom-1 padding-top-1 center-align'>
                    <Button tooltip={ i18n.apply } onClick={ applyChanges } icon='border_color' className={`btn-floating btn-large ${readyApply ? 'pulse' : ''}`} disabled={ !readyApply }/>
                    <Button tooltip={ i18n.cancel } onClick={ onCancel } icon='cancel' className='red btn-floating btn-large margin-left-2 margin-right-2' disabled={ !readySave }/>
                    <Button tooltip={ i18n.save } onClick={ saveChanges } icon='save' className={`btn-floating btn-large ${readySave ? 'pulse' : ''}`} disabled={ !readySave }/>
                </Row>
            </StyledFieldSet>
        </div>
    )
}

PiezoSuiviThresholds2.propTypes = {
    displayCote: PropTypes.number,
    id: PropTypes.number,
    unit: PropTypes.string,
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
    tab: PropTypes.string,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
}

export default PiezoSuiviThresholds2