import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import useTitle from '../../../utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import {
    getStateColor,
    tempPerimetersFolder,
    tempPerimetersStates,
    tempPerimetersStatus, tempPerimeterStation,
    tempPerimeterTypes,
} from '../../temp/TempPerimetersData'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import { useDispatch, useSelector } from 'react-redux'
import { getFullDate } from '../../../utils/DateUtil'
import { getSortedTableData } from '../../../components/datatable/TableUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PerimeterDto from '../../dto/PerimeterDto'
import useActions from '../../../utils/customHook/useActions'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import PerimetersStepper from './components/PerimetersStepper'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import { isNil } from 'lodash'

const PERIMETERS_HEADER = ['code', 'name', 'type', 'status', 'parcels', 'owners', 'operators', 'startDate', 'endDate', 'state']

const PerimetersTable = ({
    perimeters = [],
    setPerimeter,
    readMode,
}) => {
    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.perimeters,
        href: 'perimeters',
    }], [])

    const {
        selectedSearchValues,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    }))

    const perimetersFormated = perimeters.map(perimeter => {
        return {
            ...perimeter,
            type: tempPerimeterTypes[perimeter.type] || '',
            status: tempPerimetersStatus[perimeter.status] || '',
            startDate: getFullDate(perimeter.startDate),
            endDate: getFullDate(perimeter.endDate),
            state: (tempPerimetersStates[perimeter.state] &&
                <Grid container item xs={'auto'} alignItems={'center'}>
                    <Grid item paddingRight={'5px'}>
                        <Grid item padding={'8px'} backgroundColor={getStateColor(perimeter.state)} borderRadius={'100%'} border={'2px solid black'}/>
                    </Grid>
                    <Grid item>
                        {tempPerimetersStates[perimeter.state]}
                    </Grid>
                </Grid>) || '',
        }
    })

    const { perimeter: searchValues } = selectedSearchValues
    const initialSort = searchValues?.columnName ? { column: searchValues.columnName, sort: searchValues.sort } : null

    const stationsHash = stations => stations.map(s => s.code).join('')

    const onTableSort = (columnName, sort) => {
        const sortedStations = getSortedTableData(perimetersFormated, { column: columnName, sort }, false)
        if (!searchValues || !searchValues.stations || stationsHash(searchValues.stations) !== stationsHash(sortedStations)) {
            dispatch(SieauAction.update('selectedSearchValues', 'perimeter', {
                stations: sortedStations,
                columnName,
                sort,
            }))
        }
    }

    return (
        <Grid item border={'2px solid #00359F'} borderRadius={'5px'}>
            <Table
                title={'Périmètres'}
                data={perimetersFormated}
                paging
                actions={!readMode ?[{
                    iconName: 'note_add',
                    tooltip: i18n.add,
                    color: 'black',
                    onClick: () => setPerimeter({}),
                }] : []}
                initialSort={initialSort}
                nbPerPageLabel={nbPerPageLabel}
                onSort={onTableSort}
                type={{ headers: PERIMETERS_HEADER }}
                onClick={setPerimeter}
                condensed
                sortable
                id='perimetersFolder_table'
                invertedHeaderStyle
            />
        </Grid>
    )
}

PerimetersTable.propTypes = {
    perimeters: PropTypes.arrayOf(PerimeterDto),
    setPerimeter: PropTypes.func,
    readMode: PropTypes.bool,
}

const PerimeterDashboard = ({ match }) => {
    const dispatch = useDispatch()
    const { id } = match.params

    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }))

    const [code, setCode] = useState('')
    const [name, setName] = useState('')
    const [city, setCity] = useState()
    const [collectivity, setCollectivity] = useState()
    const [status, setStatus] = useState()
    const [state, setState] = useState()

    const [readMode, setReadMode] = useState(true)
    const [exportOpen, setExportOpen] = useState(false)
    const [perimeter, setPerimeter] = useState()

    useEffect(() => {
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
    }, [cities, dispatch])

    useEffect(() => {
        const perimeterFolder = tempPerimetersFolder.find(pf => pf.code === parseInt(id))

        if (isNil(perimeterFolder)) return

        setCode(perimeterFolder?.code)
        setName(perimeterFolder?.name)
        setCity(perimeterFolder?.city)
        setCollectivity(perimeterFolder?.collectivity)
        setStatus(perimeterFolder?.status)
        setState(perimeterFolder?.state)
    }, [id])

    useTitle(() => [{
        title: i18n.perimeters,
        href: 'perimeters',
    }, {
        title: tempPerimetersFolder.find(pf => pf.code === parseInt(id))?.name || i18n.unknown,
        href: `perimeters/${id}`,
    }], [id])

    useActions(() => (!readMode ? {
        save: () => setReadMode(true),
        cancel: () => setReadMode(true),
        delete: () => {},
    } : {
        export: () => setExportOpen(true),
        edit: () => setReadMode(false),
    }), [readMode])

    const exportFormat = [{
        type: i18n.excelFile,
        callback: () => {},
    }, {
        type: i18n.csvFile,
        callback: () => {},
    }]

    return (
        <>
            <ExportFileModal data={[{
                name: 'Liste des parcelles',
                formats: exportFormat,
            },
            {
                name: 'Liste des propriétaires',
                formats: exportFormat,
            },
            {
                name: 'Liste des exploitants',
                formats: exportFormat,
            }]} onClose={() => setExportOpen(false)} open={exportOpen}
            />
            <PerimetersStepper perimeter={perimeter} setPerimeter={setPerimeter}/>
            <Grid container item spacing={2} width={'100%'} alignItems={'flex-start'}>
                <Grid container item xs={9}>
                    <Grid container paddingBottom={'15px'}>
                        <Card round noMargin={false} cardStyle={{ width: '100%' }}>
                            <Grid container alignItems='center'>
                                <Grid container spacing={2} padding={'10px'} alignItems='center'>
                                    <Grid container item xs={4}>
                                        <Grid item width={'100%'}>
                                            <Input
                                                title={i18n.code}
                                                value={code}
                                                onChange={setCode}
                                                disabled={readMode}
                                                clearFunction
                                                id='perimeterCode_dashboard'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={8}>
                                        <Grid item width={'100%'}>
                                            <Input
                                                title={i18n.name}
                                                value={name}
                                                onChange={setName}
                                                disabled={readMode}
                                                clearFunction
                                                id='perimeterName_dashboard'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Select
                                                options={cities ? cities : []}
                                                label={i18n.cities}
                                                nullLabel='&nbsp;'
                                                value={city}
                                                disabled={readMode}
                                                noSort
                                                onChange={setCity}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Select
                                                options={[]}
                                                label={i18n.collectivity}
                                                nullLabel='&nbsp;'
                                                value={collectivity}
                                                disabled={readMode}
                                                noSort
                                                onChange={setCollectivity}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Select
                                                options={Object.values(tempPerimetersStatus)?.map((c, index) => {
                                                    return { code: index, name: c }
                                                })}
                                                label={i18n.status}
                                                nullLabel='&nbsp;'
                                                value={status}
                                                disabled={readMode}
                                                noSort
                                                onChange={setStatus}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Select
                                                options={Object.values(tempPerimetersStates)?.map((c, index) => {
                                                    return { code: index, name: c }
                                                })}
                                                label={i18n.state}
                                                nullLabel='&nbsp;'
                                                value={state}
                                                disabled={readMode}
                                                noSort
                                                onChange={setState}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item width={'100%'}>
                        <PerimetersTable perimeters={tempPerimetersFolder.find(pf => pf.code === parseInt(id))?.perimeters ?? []} setPerimeter={setPerimeter} readMode={readMode}/>
                    </Grid>
                </Grid>
                <Grid container item xs={3} direction={'column'} spacing={1}>
                    <Grid container item>
                        <Card round noMargin={false} cardStyle={{ width: '100%' }}>
                            <Grid container item padding={'10px'} fontSize={'16px'} fontWeight={'bold'} justifyContent={'center'}>
                                {'Mise à jour le 09/04/2024 par ADMIN'}
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid container item>
                        <Card round noMargin={false} cardStyle={{ width: '100%' }}>
                            <Grid container item padding={'10px'} justifyContent={'center'}>
                                <Grid container item fontSize={'16px'} fontWeight={'bold'}>
                                    {'09/04/2024 - ADMIN'}
                                </Grid>
                                <Grid container item paddingTop={'10px'} fontSize={'16px'} fontWeight={'bold'}>
                                    {'Création du périmètre éloigné'}
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item>
                        <StationMapDashboardPanel
                            noMarkerTooltip
                            station={tempPerimeterStation}
                            type={'piezometry'}
                            className='margin-bottom-1 no-margin-top'
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

PerimeterDashboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default PerimeterDashboard