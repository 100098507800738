export default class DtoPowerSupply {
    constructor(obj) {
        this.id = obj.id
        this.reference = obj.reference
        this.comment = obj.comment
        this.powerSupplyType = obj.powerSupplyType
        this.materielType = obj.powerSupplyType
        this.purchaseDate = obj.purchaseDate
        this.warrantyTime = obj.warrantyTime
        this.manufactureDate = obj.manufactureDate
        this.withdrawalDate = obj.withdrawalDate
        this.providerId = obj.providerId
        this.lifeTime = obj.lifeTime
        this.voltage = obj.voltage
        this.serialNumber = obj.serialNumber
    }
}