import AdministrationAction from 'administration/actions/AdministrationAction'
import { parametresCSV } from 'administration/constants/RSEauAdminConstants'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import ActionComponent from 'components/ActionComponent'
import Card from 'components/card/Card'
import Button from 'components/forms/Button'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Textarea from 'components/forms/Textarea'
import Row from 'components/react/Row'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { MEASURES_TYPES, OBJECT_TYPES } from 'iaeau/constants/IAEauConstants'
import IAEauAction from 'iaeau/IAEauAction'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getDate } from 'utils/DateUtil'
import { onChangeDate, setModal } from 'utils/FormUtils'
import { hasValue } from 'utils/NumberUtil'
import {
    getSetting,
    getSettingBool,
    getSettingFloat,
    getSettingInt,
    getUser,
    isDeveloppmentEnv,
} from 'utils/SettingUtils'
import { arrayOf } from 'utils/StoreUtils'
import Select from '../../components/forms/Select'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_TERRITORY_RSEAU } from 'account/constants/AccessRulesConstants'

const meritList = [
    { value: '', label: '' },
    { value: 'Faible', label: 'Faible' },
    { value: 'Moyen', label: 'Moyen' },
    { value: 'Fort', label: 'Fort' },
]

class RSEauApplication extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            applicationSettings: this.props.applicationSettings,
            disabledState: true,
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_TERRITORY_RSEAU)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setTitle([{
            title: i18n.steering,
            href: 'territory',
        }, {
            title: i18n.settings,
            href: 'territory/settings',
        }, {
            title: 'RSEau',
            href: 'territory/settings/rseau',
        }])
        this.setReadOnlyMode()
    }

    onSaveSettings = () => {
        const applicationSettingsToCheck = this.state.applicationSettings.filter(e => e.value !== getSetting(this.props.applicationSettings, e.parameter))
        if (applicationSettingsToCheck.length) {
            AppStore.dispatch(AdministrationAction.updateSieauParameters(applicationSettingsToCheck))
        } else if (!applicationSettingsToCheck.length && !this.state.files) {
            AppStore.dispatch(ToastrAction.info(i18n.nothingToChange))
        }
        this.setReadOnlyMode()
    }

    setEditMode = () => {
        const actions = {
            cancel: () => {
                $('.tooltipped').tooltip('remove')
                this.setState({ applicationSettings: this.props.applicationSettings })
                this.setReadOnlyMode()
            },
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => {
                $('.tooltipped').tooltip('remove')
                this.onSaveSettings()
            }
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ disabled: true })
        if (!(getUser().consultant === '1')) {
            const actions = {
                edit: () => {
                    $('.tooltipped').tooltip('remove')
                    this.setState({ disabled: false })
                    this.setEditMode()
                },
            }
            this.setActions(actions)
        } else {
            this.setActions({})
        }
    }

    onChangeSetting = (setting, value) => {
        const stateObj = this.state.applicationSettings.filter(e => e.parameter !== setting)
        this.setState({ applicationSettings: [...stateObj, { parameter: setting, value }] })
    }

    importChronoTension = e => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => {
            const split = upload.target.result.split(',')
            if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                const buf = new Buffer(split[1], 'base64').toString()
                const [ , ...lines ] = buf.split('\n').map(b => b.trim().split(';'))
                const tensions = lines.map(l => ({
                    objectType: OBJECT_TYPES.GLOBAL,
                    objectId: 0,
                    measureType: MEASURES_TYPES.RSEau_Chrono_Tension,
                    date: moment(l[0], 'DD/MM/YYYY').valueOf(),
                    value: parseInt(l[1]),
                })).filter(l => hasValue(l.date))
                this.props.setGenericMeasures(OBJECT_TYPES.GLOBAL, 0, MEASURES_TYPES.RSEau_Chrono_Tension, tensions)
            } else {
                AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
            }
            this.refs.importFile.value = ''
        }
        reader.readAsDataURL(file)
    }

    setRSEauParametresModal = () => {
        const change = this.onChangeSetting
        const makeFileds = (fields) => fields.map(param => {
            if (param.type === 'float') {
                const f = getSettingFloat(this.state.applicationSettings, `RSEau_parametres_${param.field}`)
                return (<NumberField col={ 4 } title={ `${param.field} (defaut: ${param.default})` }
                    value={ hasValue(f) ? f : param.default }
                    onChange={ (v) => change(`RSEau_parametres_${param.field}`, v.toString()) } floatValue
                />)
            }
            if (param.type === 'date') {
                const d = getDate(getSettingInt(this.state.applicationSettings, `RSEau_parametres_${param.field}`))
                return (<Input col={ 4 } title={ `${param.field} (defaut: ${getDate(param.default)})` }
                    value={ hasValue(d) ? d : getDate(param.default) }
                    onChange={ (v) => onChangeDate(v, v2 => change(`RSEau_parametres_${param.field}`, v2.toString())) }
                />)
            }
            if (param.type === 'text') {
                const t = getSetting(this.state.applicationSettings, `RSEau_parametres_${param.field}`)
                return (<Input col={ 4 } title={ `${param.field} (defaut: ${param.default})` }
                    value={ hasValue(t) ? t : param.default }
                    onChange={ (v) => change(`RSEau_parametres_${param.field}`, v.toString()) }
                />)
            }
            const i = getSettingInt(this.state.applicationSettings, `RSEau_parametres_${param.field}`)
            return (<NumberField col={ 4 } title={ `${param.field} (defaut: ${param.default})` }
                value={ hasValue(i) ? i : param.default }
                onChange={ (v) => change(`RSEau_parametres_${param.field}`, v.toString()) }
            />)
        })

        setModal({
            title: 'RSEau Optirrig parametres',
            content: (<Row>
                <Card title={ 'general' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'general')) }</Row></Card>
                <Card title={ 'parametres.csv' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'parametres.csv')) }</Row></Card>
                <Card title={ 'irrigmaker.txt' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'irrigmaker.txt')) }</Row></Card>
                <Card title={ 'param_EE.csv' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'param_EE.csv')) }</Row></Card>
            </Row>),
        })
    }

    render() {
        const { applicationSettings, disabled: disabledState } = this.state
        const disabled = { disabled: disabledState, active: !disabledState }
        const change = this.onChangeSetting
        return (
            <div className='padding-1'>
                <Card title={ 'Application RSEau Serious Game' } round displayIf={ isDeveloppmentEnv() || componentHasHabilitations(H_TERRITORY_RSEAU) }>
                    <Row className='padding-top-1'>
                        <Checkbox col={ 3 } label={ 'Utiliser la date de simulation' } checked={ getSettingBool(applicationSettings, 'RSEau_Use_Simulation_Date') }
                            onChange={ (v) => change('RSEau_Use_Simulation_Date', v.toString()) } { ...disabled }
                        />
                        <Input col={ 3 } title={ 'Date de simulation' } value={ getDate(getSettingInt(applicationSettings, 'RSEau_Date_Simulation')) }
                            onChange={ (v) => onChangeDate(v, v2 => change('RSEau_Date_Simulation', v2.toString())) } disabled={ disabled.disabled || !getSettingBool(applicationSettings, 'RSEau_Use_Simulation_Date') }
                        />
                        <Input col={ 6 } title={ 'Date de début de campagne' } value={ getDate(getSettingInt(applicationSettings, 'RSEau_Date_Deb_Camp')) }
                            onChange={ (v) => onChangeDate(v, v2 => change('RSEau_Date_Deb_Camp', v2.toString())) } { ...disabled }
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <Input col={ 6 } title={ 'Date début de restriction' } value={ getDate(getSettingInt(applicationSettings, 'RSEau_Date_debut_restriction')) }
                            onChange={ (v) => onChangeDate(v, v2 => change('RSEau_Date_debut_restriction', v2.toString())) } { ...disabled }
                        />
                        <Input col={ 6 } title={ 'Date fin de restriction' } value={ getDate(getSettingInt(applicationSettings, 'RSEau_Date_fin_restriction')) }
                            onChange={ (v) => onChangeDate(v, v2 => change('RSEau_Date_fin_restriction', v2.toString())) } { ...disabled }
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <Input col={ 6 } title={ 'Date DD1' } value={ getDate(getSettingInt(applicationSettings, 'RSEau_Date_DD1')) }
                            onChange={ (v) => onChangeDate(v, v2 => change('RSEau_Date_DD1', v2.toString())) } { ...disabled }
                        />
                        <Input col={ 6 } title={ 'Date DD2' } value={ getDate(getSettingInt(applicationSettings, 'RSEau_Date_DD2')) }
                            onChange={ (v) => onChangeDate(v, v2 => change('RSEau_Date_DD2', v2.toString())) } { ...disabled }
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <div className='col s2'/>
                        <Button col={ 3 } onClick={ this.setRSEauParametresModal } title='parametres' { ...disabled }/>
                        <div className='col s2'/>
                        <div className='file-field col s3 no-padding'>
                            <div className='col s12 no-padding btn'>
                                <span>{ 'Chrono Tension' }</span>
                                <input
                                    type='file'
                                    ref='importFile'
                                    accept='.csv'
                                    onChange={ this.importChronoTension }
                                    {...disabled}
                                />
                            </div>
                        </div>
                        <div className='col s2'/>
                    </Row>
                    <Row className='padding-top-1'>
                        <NumberField col={ 3 } title={ 'SRU (% Rumax)' } value={ getSettingFloat(applicationSettings, 'RSEau_SRU') }
                            onChange={ (v) => change('RSEau_SRU', v.toString()) } { ...disabled } floatValue dispatchOnNull
                        />
                        <NumberField col={ 3 } title={ 'Ru min (% Rumax)' } value={ getSettingFloat(applicationSettings, 'RSEau_RUmin') }
                            onChange={ (v) => change('RSEau_RUmin', v.toString()) } { ...disabled } floatValue dispatchOnNull
                        />
                        <NumberField col={ 3 } title={ 'Seuil PSE (EEE)' } value={ getSettingFloat(applicationSettings, 'RSEau_Seuil_PSE') }
                            onChange={ (v) => change('RSEau_Seuil_PSE', v.toString()) } { ...disabled } floatValue dispatchOnNull
                        />
                        <NumberField col={ 3 } title={ 'PSE (€/ha)' } value={ getSettingFloat(applicationSettings, 'RSEau_PSE') }
                            onChange={ (v) => change('RSEau_PSE', v.toString()) } { ...disabled } floatValue dispatchOnNull
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <NumberField col={ 3 } title={ 'Mérite N1' } value={ getSettingFloat(applicationSettings, 'RSEau_Merite_n1') }
                            onChange={ (v) => change('RSEau_Merite_n1', v.toString()) } { ...disabled } floatValue dispatchOnNull
                        />
                        <NumberField col={ 3 } title={ 'Mérite N2' } value={ getSettingFloat(applicationSettings, 'RSEau_Merite_n2') }
                            onChange={ (v) => change('RSEau_Merite_n2', v.toString()) } { ...disabled } floatValue dispatchOnNull
                        />
                        <NumberField col={ 3 } title={ 'Mérite N3' } value={ getSettingFloat(applicationSettings, 'RSEau_Merite_n3') }
                            onChange={ (v) => change('RSEau_Merite_n3', v.toString()) } { ...disabled } floatValue dispatchOnNull
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <NumberField col={ 3 } title={ 'Max EE (Contreparties)' } value={ getSettingInt(applicationSettings, 'RSEau_Max_EE') }
                            onChange={ (v) => change('RSEau_Max_EE', v.toString()) } { ...disabled } dispatchOnNull floatValue
                        />
                        <div className='col s1'/>
                        <Button col={ 3 } title='Exporter les données' { ...disabled } onClick={ () => AdministrationAction.exportRSEauHistory() } />
                        <div className='col s1'/>
                        <Button col={ 3 } title='Purger les données' { ...disabled } onClick={ () => AdministrationAction.purgeRSEauHistory() } />
                    </Row>
                    <Row className='padding-top-1 padding-bottom-2'>
                        <Select
                            col={3}
                            label={'Mérite'}
                            options={meritList}
                            noNullValue
                            keyLabel='label'
                            value={getSetting(applicationSettings, 'RSEau_Merit') || ''}
                            onChange={v => change('RSEau_Merit', v)}
                            {...disabled}
                        />
                        <Textarea
                            col={4}
                            title={i18n.meritCommentary}
                            value={(getSetting(applicationSettings, 'RSEau_Merit_comment') || '')}
                            onChange={v => change('RSEau_Merit_comment', v)}
                            maxLength={100}
                            {...disabled}
                        />
                    </Row>
                </Card>
            </div>
        )
    }
}

RSEauApplication.propTypes = {
    applicationSettingsProps: arrayOf(SieauParameterDto),
    setTitle: PropTypes.func,
    setGenericMeasures: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    setGenericMeasures: IAEauAction.setGenericMeasures,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(RSEauApplication)
