import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from './FormUtils'
import { hasValue, round } from './NumberUtil'
import { QUALITO_IMPORT_TYPE } from '../quality/components/qualitometers/addQualito/AddQualitoConstants'
import cronstrue from 'cronstrue/i18n'
import { getCurrentLang } from './LangUtils'
import { getFullDate } from './DateUtil'
import { PIEZO_IMPORT_TYPE } from '../piezometry/components/piezometers/addPiezo/AddPiezoConstants'
import Icon from '../components/icon/Icon'
import { getObjectLabel } from './StoreUtils'

const getExecStatus = status => {
    if (status) {
        return i18n.success
    } else if (status === false) {
        return i18n.error
    }
    return i18n.running
}

const getImportMode = value => {
    switch (value) {
        case 'dataIntegration' :
            return 'DATA_ADD_LAST_DATA'
        case 'dataFullIntegration' :
            return 'DATA_ADD_NEW_DATA'
        case 'dataNothing' :
            return ''
        default:
            return value
    }
}

const getExecStatusColor = (execution) => {
    switch (execution.status) {
        case 0:
            return 'red'
        case 1:
            return 'green'
        case 2:
            return 'green'
        case 3:
            return 'orange'
        case 4:
            return 'grey'
        case 5:
            return 'orange'
        case 6:
            return 'blue'
        case 7:
            return 'blue'
        case 8:
            return 'orange'
        default:
            if (execution.date < moment().subtract(3, 'days').valueOf()) {
                return 'red'
            }
            return 'blue'
    }
}

const getExecStatusIcon = (execution, size = 50, tooltipContent = null) => {
    const icon = (() => {
        switch (execution.status) {
            case 0:
                return 'error'
            case 1:
                return 'done'
            case 2:
                return 'done'
            case 3:
                return 'pan_tool'
            case 4:
                return 'local_cafe'
            case 5:
                return 'live_help'
            case 6:
                return 'more_horiz'
            case 7:
                return 'pan_tool'
            case 8:
                return 'hourglass_full'
            default:
                if (execution.date < moment().subtract(3, 'days').valueOf()) {
                    return 'error'
                }
                return 'sync'
        }
    })()
    const tooltip = (tooltipContent ? sieauTooltip(() => tooltipContent) : null)
    const color = getExecStatusColor(execution)

    if (execution.status === 2) {
        return (
            <div style={{ position: 'relative', width: `${size}px` }} {...tooltip} >
                <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px`, position: 'relative' }}>{ icon }</i>
                <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color: 'orange', fontSize: `${round(size/2, 0)}px`, width: `${round(size/2, 0)}px`, height: `${round(size/2, 0)}px`, position: 'absolute', right: 0, bottom: 0, zIndex: 10 }}>warning</i>
            </div>
        )
    }

    return (
        <i
            className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` }
            id = {execution.status === 1 && 'success' || hasValue(execution.status) && 'error' || 'jobInProgress'}
            style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px` }} {...tooltip}
        >
            { icon }
        </i>
    )
}

const getExecStatusLabel = (execution) => {
    switch (execution.status) {
        case 0:
            return i18n.error
        case 1:
            return i18n.success
        case 2:
            return i18n.successWithWarnings
        case 3:
            return i18n.abortedManually
        case 4:
            return i18n.nothingToDoOrProcess
        case 5:
            return i18n.noInformation
        case 6:
            return i18n.unknownJobStatus
        case 7:
            return (i18n.interruptServerMaintenance)
        case 8:
            return i18n.jobNotExecuted
        default:
            if (execution.date < moment().subtract(3, 'days').valueOf()) {
                return i18n.error
            }
            return i18n.inProgress
    }
}

const getExecTypeIcon = (cron, size = 25) => {
    if (cron) {
        return <i className='material-icons ' style={{ fontSize: `${size}px` }}>android</i>
    }
    return null
}

const getMeasureCoteList = () => [
    { code: 2, name: i18n.depth },
    { code: 1, name: 'NGF' },
]

const getIntegrationModes = () => [
    {
        value: '',
        label: i18n.noDataIntegration,
    },
    {
        value: 'DATA_ADD_LAST_DATA',
        label: i18n.lastDataIntegration,
    },
    {
        value: 'DATA_ADD_NEW_DATA',
        label: i18n.addOnlyNewsDataIntegration,
    },
    {
        value: 'DATA_REPLACE_HISTO',
        label: i18n.replaceAllHistory,
    },
    {
        value: 'DATA_REPLACE_DATA',
        label: i18n.replaceDataIntegration,
    },
]

const getJobStatus = () => [
    {
        header: 'statut-0',
        label: i18n.error,
    },
    {
        header: 'statut-1',
        label: i18n.success,
        id: 'succesLogo',
    },
    {
        header: 'statut-2',
        label: i18n.successWithWarnings,
    },
    {
        header: 'statut-3',
        label: i18n.abortedManually,
    },
    {
        header: 'statut-4',
        label: i18n.nothingToDoOrProcess,
    },
    {
        header: 'statut-5',
        label: i18n.noInformation,
    },
    {
        header: 'statut-6',
        label: i18n.unknownJobStatus,
    },
    {
        header: 'statut-7',
        label: i18n.interruptServerMaintenance,
    },
    {
        header: 'statut-8',
        label: i18n.jobNotExecuted,
    },
]

const getManualJobId = stationType => {
    switch (stationType) {
        case QUALITO_IMPORT_TYPE.ADES_QUALITO:
            return -1000
        case QUALITO_IMPORT_TYPE.NAIADES:
            return -1001
        case PIEZO_IMPORT_TYPE.ADES_PIEZO:
            return -1002
        default:
            return 0
    }
}

const getJobList = (jobs, contributorsIndex = {}) => {
    const headers = ['jobId', 'jobExecutionId', 'jobType', 'jobName', 'status', 'date', 'user', 'administrator', 'programmation', 'nullValue', 'nullValue2', 'nullValue3']
    const tableData = jobs.map(job => {
        const statusIcon = getExecStatusIcon(job, 30)
        return {
            id: job.jobId,
            nullValue: {
                value: getExecTypeIcon(job.cron, 30),
                color: 'white',
                setTooltip: () => i18n.jobScheduled,
            },
            nullValue2: {
                value: job.jobPreviousId ? <Icon icon='arrow_back'/> : null,
                setTooltip: job.jobPreviousId ? (() => {
                    const found = jobs.find(j => j.jobId === job.jobPreviousId)
                    return found ? `${i18n.previous} : ${found.jobName}` : ''
                }) : null,
            },
            nullValue3: {
                value: job.jobNextId ? <Icon icon='arrow_forward'/> : null,
                setTooltip: job.jobNextId ? (() => {
                    const found = jobs.find(j => j.jobId === job.jobNextId)
                    return found ? `${i18n.next} : ${found.jobName}` : ''
                }) : null,
            },
            programmation: {
                value: job.cron ? (() => {
                    try {
                        return cronstrue.toString(job.cron, { locale: getCurrentLang()[0] })
                    } catch (e) {
                        return ''
                    }
                })() : '',
            },
            jobId: {
                value: job.jobId,
                color: 'white',
            },
            jobExecutionId: {
                value: job.jobExecutionId,
                color: 'white',
            },
            jobType: {
                value: job.jobType,
                color: 'white',
            },
            jobName: {
                value: job.jobName,
                color: 'white',
            },
            administrator: {
                value: hasValue(job.managers) ? getObjectLabel(contributorsIndex[job.managers?.split(';')?.[0]] ?? {}, 'mnemonique') : undefined,
                color: 'white',
            },
            status: {
                value: statusIcon,
                color: 'white',
                setTooltip: () => (
                    <div className='padding-left-1 padding-right-1 valign-wrapper'>
                        {statusIcon}
                        <span className='margin-left-1'>{getExecStatusLabel(job)}</span>
                    </div>
                ),
            },
            date: {
                value: getFullDate(job.date),
                color: 'white',
            },
            user: {
                value: job.login,
                color: 'white',
            },
            job,
        }
    })
    return {
        type: {
            headers,
        },
        jobs: tableData,
    }
}

export { getExecStatusIcon, getImportMode, getExecStatusColor, getExecStatus, getExecTypeIcon, getMeasureCoteList, getIntegrationModes, getExecStatusLabel, getJobStatus, getManualJobId, getJobList }

