import { groupBy, orderBy, range, sumBy } from 'lodash'
import moment from 'moment'
import { getDate, getFullDate, getMonthYear, getYearDate } from './DateUtil'

const formatPluvioMeasures = listAllMeasure => {
    if (listAllMeasure && listAllMeasure.length) {
        const measures = listAllMeasure.map(m => ({ value: [m.date || m.measureDate, m.value] }))
        return orderBy(measures, m => m.value[0])
    }
    return []
}


const offsetAndCumulPluvio = (measures, offset, cumul) => {
    const offseted = offset ? measures.map(m => ({ ...m, date: moment(m[0]).add('days', offset).valueOf() })) : measures
    if (!cumul) {
        return offseted
    }
    const grouped = groupBy(offseted, d => getDate(d[0]))
    return Object.keys(grouped).map(dateStr => {
        const date = grouped[dateStr][0][0]
        const cumulRain = range(0, cumul+1).reduce((acc, n) => {
            const nStr = getDate(moment(date).subtract('days', n))
            return acc + sumBy(grouped[nStr], m => m[1])
        }, 0)
        return { ...grouped[dateStr][0], rain: cumulRain }
    })
}

const tooltipDatePluvio = (dateToFormat, sumType) => {
    switch (sumType) {
        case 'SUM_HOUR' :
        case 'SUM_DECADE' :
            return getFullDate(dateToFormat)
        case 'SUM_WEEK' :
        case 'SUM_DAY' :
            return getDate(dateToFormat)
        case 'SUM_MONTH' :
            return getMonthYear(dateToFormat)
        case 'SUM_YEAR' :
            return getYearDate(dateToFormat)
        default :
            return getFullDate(dateToFormat)
    }
}

export { formatPluvioMeasures, offsetAndCumulPluvio, tooltipDatePluvio }

