import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import DtoQualification from '../../../quality/dto/DtoQualification'
import DtoStatus from '../../../quality/dto/DtoStatus'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import FractionDto from '../../../referencial/components/fraction/dto/FractionDto'
import SupportDto from '../../../referencial/components/support/dto/SupportDto'
import { isUndefined } from 'lodash'
import ToastrAction from 'toastr/actions/ToastrAction'
import MultiContributorsAutocomplete
    from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'

const ImportQualityDefaultValue = ({
    defaultValue = {},
    isEditMode = false,
    onChange = () => { },
    contributors = [],
    status = [],
    qualifications = [],
    fractions = [],
    supports = [],
    toastError = () => {},
}) => {
    useEffect(() => {
        const sampler = contributors.find(c => c.id === defaultValue.sampler)
        if (isUndefined(sampler)) {
            return
        }
        if (isUndefined(sampler.siret) && isUndefined(sampler.sandre)) {
            toastError(i18n.siretOrSandre)
            onChange({ sampler: undefined })
        }
    }, [defaultValue.sampler, contributors])

    useEffect(() => {
        const producer = contributors.find(c => c.id === defaultValue.producer)
        if (isUndefined(producer)) {
            return
        }
        if (isUndefined(producer.siret) && isUndefined(producer.sandre)) {
            toastError(i18n.siretOrSandre)
            onChange({ sampler: undefined })
        }
    }, [defaultValue.producer, contributors])

    useEffect(() => {
        const labo = contributors.find(c => c.id === defaultValue.labo)
        if (isUndefined(labo)) {
            return
        }
        if (isUndefined(labo.siret) && isUndefined(labo.sandre)) {
            toastError(i18n.siretOrSandre)
            onChange({ sampler: undefined })
        }
    }, [defaultValue.labo, contributors])
    return (
        <fieldset>
            <legend>{i18n.defaultValue}</legend>
            <div>
                <div className='row no-margin padding-top-1'>
                    {/*      <Input
                    col={3}
                    title={i18n.stationCodePrefix}
                    value={defaultValue.stationCodePrefix}
                    onChange={(id) => onChange({ stationCodePrefix: id })}
                    disabled={!isEditMode}
                /> */}
                    <MultiContributorsAutocomplete
                        options={contributors}
                        label={i18n.sampler}
                        col={3}
                        onChange={(id) => onChange({ sampler: id })}
                        values={defaultValue.sampler}
                        disabled={!isEditMode}
                    />
                    <MultiContributorsAutocomplete
                        options={contributors}
                        label={i18n.producer}
                        col={3}
                        onChange={(id) => onChange({ producer: id }) }
                        values={defaultValue.producer}
                        disabled={!isEditMode}
                    />
                    <MultiContributorsAutocomplete
                        options={contributors}
                        label={i18n.laboratory}
                        col={3}
                        onChange={(id) => onChange({ labo: id }) }
                        values={defaultValue.labo}
                        disabled={!isEditMode}
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={3}
                        label={i18n.support}
                        options={supports}
                        value={defaultValue.support}
                        onChange={(code) => onChange({ support: parseInt(code) || undefined })}
                        clearFunction
                        displayWithCode
                        active={isEditMode}
                        disabled={!isEditMode}
                    />
                    <Select
                        col={3}
                        label={i18n.fractions}
                        options={fractions}
                        value={defaultValue.fraction}
                        onChange={(code) => onChange({ fraction: code })}
                        clearFunction
                        displayWithCode
                        active={isEditMode}
                        disabled={!isEditMode}
                    />
                    <Select
                        col={3}
                        label={i18n.status}
                        options={status}
                        value={defaultValue.status}
                        onChange={(v) => onChange({ status: v })}
                        nullLabel='&nbsp;'
                        active={isEditMode}
                        disabled={!isEditMode}
                    />
                    <Select
                        col={3}
                        label={i18n.qualification}
                        options={qualifications}
                        value={defaultValue.qualification}
                        onChange={(v) => onChange({ qualification: v })}
                        nullLabel='&nbsp;'
                        active={isEditMode}
                        disabled={!isEditMode}
                    />
                </div>
            </div>
        </fieldset>
    )
}

ImportQualityDefaultValue.propTypes = {
    defaultValue: PropTypes.shape({
        sampler: PropTypes.number,
        producer: PropTypes.number,
        labo: PropTypes.number,
        support: PropTypes.number,
        fraction: PropTypes.number,
        status: PropTypes.number,
        qualification: PropTypes.number,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    qualifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualification)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    toastError: PropTypes.func,
}
const mapDispatchToProps = {
    toastError: ToastrAction.error,
}
const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    qualifications: store.QualityReducer.qualifications,
    status: store.QualityReducer.status,
    fractions: store.FractionReducer.fractions,
    supports: store.SupportReducer.supports,
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportQualityDefaultValue)