/* eslint-disable camelcase */
import DtoVariousMatCounter from './DtoVariousMatCounter'
import DtoVariousMatPump from './DtoVariousMatPump'

export default class VariousMaterielDto {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.id
        this.name = obj.name
        this.reference = obj.reference
        this.serialNumber = obj.serialNumber
        this.materielType = obj.materielType
        this.manufacturerId = obj.manufacturerId
        this.providerId = obj.providerId
        this.purchaseDate = obj.purchaseDate
        this.withdrawalDate = obj.withdrawalDate
        this.manufactureDate = obj.manufactureDate
        this.warrantyTime = obj.warrantyTime
        this.comment = obj.comment
        this.state = obj.state
        this.situationDate = obj.situationDate
        this.department = obj.department
        this.city = obj.city
        this.stationCode = obj.stationCode
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.administrator = obj.administrator
        this.networkCode = obj.networkCode
        this.loginReferent = obj.loginReferent
        this.replacementDate = obj.replacementDate
        this.supplyTime = obj.supplyTime
        this.isShared = obj.isShared
        this.rent = obj.rent
        this.mobilityCode = obj.mobilityCode
        this.isEquipment = obj.isEquipment
        this.administrator = obj.administrator
        this.pump = obj.pump ? new DtoVariousMatPump(obj.pump) : null
        this.counter = obj.counter ? new DtoVariousMatCounter(obj.counter) : null

        this.link_codifications = obj.link_codifications || [] // Option[Seq[MaterielCodification]]
    }
}