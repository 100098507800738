import React, { useEffect, useMemo, useState } from 'react'
import { nbPerPageLabel } from '../constants/ReferencialConstants'
import Table from '../../components/datatable/Table'
import { getFullDate } from '../../utils/DateUtil'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import HomeAction from 'home/actions/HomeAction'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import Input from 'components/forms/Input'
import { ButtonMUI } from 'components/styled/Buttons'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import { filterAllCharacters } from 'utils/StringUtil'

const SimpleSearchPanel = ({
    defaultSearchValue = '',
    setSearchValue = () => {},
}) => {
    const {
        globalResearch,
    } = useSelector(store => ({
        globalResearch: store.HomeReducer.globalResearch,
    }), shallowEqual)

    const [searchValueTmp, setSearchValueTmp] = useState(defaultSearchValue)

    const dispatch = useDispatch()

    useEffect(() => {
        if (globalResearch) {
            setSearchValue(globalResearch)
            dispatch(HomeAction.updateGlobalResearch(''))
        }
    }, [globalResearch])

    return (
        <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
            <Row className='padding-top-1'>
                <Input
                    col={6}
                    title={i18n.search}
                    value={searchValueTmp}
                    onChange={v => setSearchValueTmp(v)}
                    onEnterKeyPress={() => setSearchValue(searchValueTmp)}
                />
            </Row>
            <Row className='padding-bottom-5-px'>
                <ButtonMUI className='right' style={{ margin: '0 10px' }} onClick={() => setSearchValue(searchValueTmp)} variant='contained'>
                    {i18n.search}
                </ButtonMUI>
                <ButtonMUI
                    className='right'
                    onClick={() => {
                        setSearchValue('')
                        setSearchValueTmp('')
                    }}
                    variant='outlined'
                >
                    {i18n.reinit}
                </ButtonMUI>
            </Row>
        </Card>
    )
}

SimpleSearchPanel.propTypes = {
    defaultSearchValue: PropTypes.string,
    setSearchValue: PropTypes.func,
}

const SearchTable = ({
    sessionKey = 'searchTable',
    lastUpdate = {},
    title = '',
    data = [],
    setExportData = () => {},
    color = false,
    headers = [],
    headersFilter = headers,
    customHeaders = {},
    customWidthHeaders = {},
    onClick = () => {},
}) => {
    const [searchValue, setSearchValue] = useSessionStorage(`referencial_${sessionKey}`, '')

    const filteredData = useMemo(() => filterAllCharacters(data, headersFilter, searchValue), [data, searchValue])

    useEffect(() => {
        setExportData(filteredData)
    }, [filteredData])

    return (
        <>
            <SimpleSearchPanel
                defaultSearchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            {lastUpdate?.updateUse && lastUpdate?.updateDate && (
                <div className='row no-margin-bottom'>
                    <div className='col s4 center offset-s8 card'>
                        <div className='col s12'>
                            { i18n.lastUpdateOn } { getFullDate(lastUpdate.updateDate) } { i18n.by } { lastUpdate.updateUser }
                        </div>
                    </div>
                </div>
            )}
            <div className='margin-1 padding-top-5-px referencial-table'>
                <Table
                    title={title}
                    showTitle={title?.length > 0}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabel}
                    data={filteredData}
                    color={color}
                    type={{ headers }}
                    customHeaders={customHeaders}
                    customWidthHeaders={customWidthHeaders}
                    sortable={!!filteredData.length}
                    onClick={onClick}
                />
            </div>
        </>
    )
}

SearchTable.propTypes = {
    sessionKey: PropTypes.string,
    lastUpdate: PropTypes.shape({
        updateUser: PropTypes.string,
        updateDate: PropTypes.string,
    }),
    title: PropTypes.string,
    data: PropTypes.array,
    setExportData: PropTypes.func,
    color: PropTypes.bool,
    headers: PropTypes.arrayOf(PropTypes.string),
    headersFilter: PropTypes.arrayOf(PropTypes.string),
    customHeaders: PropTypes.object,
    customWidthHeaders: PropTypes.object,
    onClick: PropTypes.func,
}

export default SearchTable
