import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import RadioButtons from '../forms/RadioButtons'
import Row from '../react/Row'
import { groupBy } from 'lodash'
import { hasValue } from 'utils/NumberUtil'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Icon from 'components/icon/Icon'

const ExportFileModal = ({
    open = false,
    data = [],
    title = '',
    maxWidth = 'lg',
    groups = [],
    onClose = () => { },
    closeOnExport = false,
    settingsStyle = {},
}) => {
    const [dataChoice, setDataChoice] = useState(0)
    const [formatChoice, setFormatChoice] = useState(data?.[0]?.formats?.[0]?.type)

    const exportObj = data[dataChoice]

    const getCallback = () => {
        exportObj.formats.find(d => d.type === formatChoice)?.callback()
        if (closeOnExport) {
            onClose()
        }
    }

    const dataOptions = data.map((d, i) => ({
        ...d,
        code: i,
        name: d.name,
        group: d.group,
    }))
    const groupByDatas = groupBy(data, 'group')

    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item >
                        {title || i18n.chooseExportFormat}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container justifyContent='space-between'>
                    <Grid item xs={6}>
                        {
                            Object.keys(groupByDatas).sort().map((key) => {
                                const groupFind = groups.find((g) => g.value === parseInt(key))
                                return (
                                    <StyledFieldSet>
                                        <StyledLegend className='padding-left-1 padding-right-1'>{(groupFind?.name || i18n.export)}</StyledLegend>
                                        <RadioButtons
                                            col={12}
                                            colOption={12}
                                            className='no-padding no-margin'
                                            elements={dataOptions.filter((d) => groupFind ? d.group === parseInt(key) : !hasValue(d.group))}
                                            selected={dataChoice}
                                            onChange={(v) => {
                                                setDataChoice(v)
                                                setFormatChoice(dataOptions[v]?.formats?.[0]?.type)
                                            }}
                                        />
                                    </StyledFieldSet>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={6} className='padding-left-1' style={settingsStyle}>
                        {
                            exportObj && exportObj.settingsContent && (
                                <Row>
                                    <StyledFieldSet>
                                        <StyledLegend className='padding-left-1 padding-right-1'>{i18n.settings}</StyledLegend>
                                        {exportObj.settingsContent}
                                    </StyledFieldSet>
                                </Row>
                            )
                        }
                        <StyledFieldSet>
                            <StyledLegend>{i18n.type}</StyledLegend>
                            <RadioButtons
                                col={12}
                                colOption={6}
                                elements={exportObj?.formats?.map((d) => ({ ...d, code: d.type, name: d.type }))}
                                selected={formatChoice}
                                onChange={setFormatChoice}
                            />
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={getCallback} variant='contained' color='primary'>{i18n.export}</Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

ExportFileModal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    closeOnExport: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        group: PropTypes.number,
        settingsContent: PropTypes.element,
        formats: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            callback: PropTypes.func,
        })),
    })).isRequired,
    grouped: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
    })),
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    settingsStyle: PropTypes.shape({}),
}

export default ExportFileModal