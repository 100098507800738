import { CatchmentActionConstant } from 'catchment/reducers/CatchmentReducer'
import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise, genericPromise2 } from 'utils/ActionUtils'
import CatchmentThunk from './CatchmentThunk'

const CatchmentAction = {
    getCatchment: id => dispatch => genericPromise(ApplicationConf.catchment.catchment(id), 'GET').catch(err => {
        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.catchment}`))
    }),
    fetchCatchment: id => dispatch => genericPromise(ApplicationConf.catchment.catchment(id), 'GET').then(result => {
        dispatch(CatchmentActionConstant.catchment(result))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.catchment}`))
    }),
    insertCatchment: catchment => dispatch => genericPromise(ApplicationConf.catchment.catchments(), 'POST', catchment).then(result => {
        if (result?.insert) {
            dispatch(ToastrAction.success(i18n.elementCreateSuccess))
            dispatch(CatchmentThunk.fetchCatchments({ forceFetch: true }))
            dispatch(CatchmentThunk.fetchCatchmentPoints({ forceFetch: true }))
        } else {
            dispatch(ToastrAction.error(`${i18n.createError} ${i18n.catchment}`))
        }
        return result
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.createError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.createError} ${i18n.catchment}`))
    }),
    updateCatchment: (id, catchment) => dispatch => genericPromise(ApplicationConf.catchment.catchment(id), 'PUT', catchment).then(result => {
        if (result?.update) {
            dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            dispatch(CatchmentThunk.fetchCatchments({ forceFetch: true }))
            dispatch(CatchmentThunk.fetchCatchmentPoints({ forceFetch: true }))
        } else {
            dispatch(ToastrAction.error(`${i18n.updateError} ${i18n.catchment}`))
        }
        return result
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.createError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.createError} ${i18n.catchment}`))
    }),
    deleteCatchment: id => dispatch => genericPromise(ApplicationConf.catchment.catchment(id), 'DELETE').then(result => {
        if (result?.delete) {
            dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            dispatch(CatchmentThunk.fetchCatchments({ forceFetch: true }))
            dispatch(CatchmentThunk.fetchCatchmentPoints({ forceFetch: true }))
        } else {
            dispatch(ToastrAction.error(`${i18n.deleteError} ${i18n.catchment}`))
        }
        return result
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.deleteError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.deleteError} ${i18n.catchment}`))
    }),

    getSumAnalysis: (filters, signal) => genericPromise2(ApplicationConf.catchment.sumAnalysis(), { method: 'POST', body: filters, signal }),
    countGroupParameters: (filters, signal) => genericPromise2(ApplicationConf.catchment.countGroupParameters(), { method: 'POST', body: filters, signal }),
}

export default CatchmentAction