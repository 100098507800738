/* eslint-disable camelcase */
import LocalisationMapPanel from 'installation/components/installations/components/LocalisationMapPanel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

class MapEventPanel extends Component {
    state = {
        readMode: false,
        x: null,
        y: null,
    }

    onChangeLocation = (newGeoData) => {
        if (newGeoData) {
            this.props.onChangeLocation(newGeoData)
        }
    }

    render() {
        const { long, lat, readMode } = this.props
        const x_coordinate = long
        const y_coordinate = lat
        if (!readMode) {
            return (
                <LocalisationMapPanel
                    installation={{ typeName: 'other', x: x_coordinate, y: y_coordinate, projection: 16 }}
                    onChangeLocation={this.onChangeLocation}
                    onChangeCity={() => {}}
                    disabled={readMode}
                    mapHeight='450px'
                    dontFetchParcel
                />
            )
        }
        return (
            <LocalisationMapPanel
                installation={{ typeName: 'other', x: x_coordinate, y: y_coordinate, projection: 16 }}
                disabled={readMode}
                mapHeight='300px'
                dontFetchParcel
            />
        )
    }
}

MapEventPanel.propTypes = {
    onChangeLocation: PropTypes.func,
    lat: PropTypes.number,
    long: PropTypes.number,
    readMode: PropTypes.bool,
}

MapEventPanel.defaultProps = {
    readMode: false,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
    }
}

export default connect(mapStateToProps)(MapEventPanel)
