/* eslint-disable camelcase */
import { Grid } from '@mui/material'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { isInstallationType } from '../../../../utils/CampaignUtil'
import { getDate } from '../../../../utils/DateUtil'
import { onChangeDate } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import VisitPicture from '../components/VisitPicture'
import DtoVisit from '../dto/DtoVisit'
import AddPicture from '../components/AddPicture'

const InstVisitActions = ({
    visit = new DtoVisit({}),
    readMode = true,
    onChange = () => {},
    operatorFeedback = false,
}) => {
    const {
        sandreCodes,
        installation,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        installation: store.InstallationReducer.installation,
    }))

    const onChangeAction = (action, value) => {
        onChange({ link_actions: [
            ...visit.link_actions.filter(d => d.idAction !== action.idAction),
            { ...action, ...value },
        ] })
    }

    const onDeleteAction = action => {
        onChange({ link_actions: [ ...visit.link_actions.filter(d => d.idAction !== action.idAction)] })
    }

    const onChangeDocument = (action, doc, value) => {
        onChangeAction(action, {
            link_documents: action.link_documents.map(d => {
                if (d.index === doc.index) {
                    return { ...doc, ...value }
                }
                return d
            }),
        })
    }

    const onDeleteDocument = (action, doc) => {
        onChangeAction(action, { link_documents: [ ...action.link_documents.filter(d => d.index !== doc.index)] })
    }

    const addNewDocument = (action, doc) => {
        onChangeAction(action, {
            link_documents: [
                ...action.link_documents,
                doc,
            ],
        })
    }

    return (
        <Grid>
            {orderBy(visit.link_actions, 'idAction').map((action, index) => {
                const actionWithIndex = { ...action, link_documents: action.link_documents.map((d, i) => ({ ...d, index: i })) }
                return (
                    <StyledFieldSet className={`margin-bottom-1 padding-bottom-1 ${!operatorFeedback && !readMode && 'padding-top-1' || ''}`} key={`action_${index}`}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Select
                                    value={action.risk}
                                    label={i18n.risk}
                                    options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_RISQUE)}
                                    keyvalue='code'
                                    onChange={v => onChangeAction(action, { risk: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    value={action.localisation}
                                    label={i18n.localisation}
                                    options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_SECTEUR)}
                                    keyvalue='code'
                                    onChange={v => onChangeAction(action, { localisation: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Select
                                    value={action.equipmentType}
                                    label={i18n.equipmentType}
                                    options={orderBy(getSandreList(sandreCodes, isInstallationType(installation.installationType) && SANDRE.EQUIPEMENTS_TYPE_AEP || SANDRE.EQUIPEMENTS_TYPE_ASST), sandreCode => searchAllCharacters(sandreCode.name))}
                                    keyvalue='code'
                                    displayWithCode
                                    onChange={v => onChangeAction(action, { equipmentType: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                            {
                                !operatorFeedback && !readMode && (
                                    <Grid item xs={1}>
                                        <Icon
                                            size='small'
                                            icon='delete'
                                            className='right'
                                            onClick={() => onDeleteAction(action)}
                                            tooltip={i18n.delete}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Select
                                    value={action.responsible}
                                    label={i18n.responsible}
                                    options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_RESPONSABILITE)}
                                    keyvalue='code'
                                    onChange={v => onChangeAction(action, { responsible: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    value={action.category}
                                    label={i18n.category}
                                    options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_CATEGORIE)}
                                    keyvalue='code'
                                    onChange={v => onChangeAction(action, { category: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    value={action.deadline}
                                    label={i18n.deadline}
                                    options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_ECHEANCE)}
                                    keyvalue='code'
                                    onChange={v => onChangeAction(action, { deadline: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Select
                                    value={action.interventionType}
                                    label={i18n.interventionType}
                                    options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_TYPE_INTERVENTION)}
                                    keyvalue='code'
                                    onChange={v => onChangeAction(action, { interventionType: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Input
                                    title={i18n.comment}
                                    value={action.comment}
                                    onChange={v => onChangeAction(action, { comment: v })}
                                    readMode={operatorFeedback || readMode}
                                />
                            </Grid>
                        </Grid>
                        <StyledFieldSet style={{ marginBottom: 10 }}>
                            <StyledLegend style={{ color: readMode && 'grey' }}>{i18n.operatorFeedback}</StyledLegend>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Input
                                        col={12}
                                        value={getDate(action.correctionDate)}
                                        title={i18n.correctionDate}
                                        onChange={v => onChangeDate(v, v2 => onChangeAction(action, { correctionDate: v2 }))}
                                        readMode={readMode}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <SimpleTextArea
                                        col={12}
                                        title={i18n.commentCorrection}
                                        value={action.commentCorrection}
                                        onChange={v => onChangeAction(action, { commentCorrection: v })}
                                        readMode={readMode}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                        <Grid container item spacing={2}>
                            {actionWithIndex.link_documents.map(doc => {
                                return (
                                    <VisitPicture
                                        doc={doc}
                                        onDeleteDocument={() => onDeleteDocument(actionWithIndex, doc)}
                                        onChangeDocument={value => onChangeDocument(actionWithIndex, doc, { description: value })}
                                        readMode={readMode}
                                        smallPicture
                                    />
                                )
                            })}
                            {!readMode && (
                                <AddPicture
                                    onUploadFile={newFileName => {
                                        addNewDocument(action, {
                                            idInstallation: visit.idInstallation,
                                            idCampaign: visit.idCampaign,
                                            idVisit: visit.idVisit,
                                            idAction: action.idAction,
                                            docName: newFileName,
                                        })
                                    }}
                                />
                            )}
                        </Grid>
                    </StyledFieldSet>
                )
            })}
        </Grid>
    )
}

InstVisitActions.propTypes = {
    visit: PropTypes.instanceOf(DtoVisit),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    operatorFeedback: PropTypes.bool,
}


export default InstVisitActions