import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import Card from '../../../../components/card/Card'
import Table from '../../../../components/datatable/Table'
import Select from '../../../../components/forms/Select'
import FractionDto from '../../../../referencial/components/fraction/dto/FractionDto'
import ParameterDto from '../../../../referencial/components/parameter/dto/ParameterDto'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import UnitDto from '../../../../referencial/components/unit/dto/UnitDto'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import { getLocalizationPicto } from '../../../../utils/AnalyseUtils'
import { getLabel, getSandreList } from '../../../../utils/StoreUtils'
import { getI18nTitleDataLength } from '../../../../utils/StringUtil'
import { formatParameterSelectedPopup } from '../../../utils/CampaignUtils'


class SelectParameter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parameters: formatParameterSelectedPopup(props.parameters.filter(p => p.active)),
            tmpSelectedParameters: [],
            fraction: undefined,
            support: undefined,
            place: undefined,
        }
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(prevProps.parameters, this.props.parameters)) {
            this.setState({ parameters: formatParameterSelectedPopup(this.props.parameters.filter(p => p.active)) })
        }
    }

    onClickParameter = (parameterCode, isSelected) => {
        const tmpSelectedParameters = isSelected ? this.state.tmpSelectedParameters.filter(code => code !== parameterCode) : [...this.state.tmpSelectedParameters, parameterCode]
        this.setState({ tmpSelectedParameters })
    }

    checkAll = () => {
        this.setState({ tmpSelectedParameters: this.props.selectedParameters.map(({ parameterCode }) => parameterCode) })
    }

    uncheckAll = () => {
        this.setState({ tmpSelectedParameters: [] })
    }

    applyDefaultValue = () => {
        const {
            tmpSelectedParameters,
            fraction,
            support,
            place,
        } = this.state
        const parameters = this.props.selectedParameters.map(param => {
            if (tmpSelectedParameters.find(code => param.parameterCode === code)) {
                return {
                    ...param,
                    fractionCode: fraction,
                    supportCode: support,
                    placeCode: place,
                }
            }
            return param
        })
        this.setState({ fraction: undefined, support: undefined, place: undefined, tmpSelectedParameters: [] })
        this.props.onChange({ selectedParameters: parameters })
    }

    getAction = () => {
        if (this.state.tmpSelectedParameters.length === this.props.selectedParameters.length) {
            return [{
                onClick: () => this.uncheckAll(),
                iconName: 'check_box_outline_blank',
                tooltip: i18n.unselectAll,
            }]
        }
        return [{
            onClick: () => this.checkAll(),
            iconName: 'check_box',
            tooltip: i18n.selectAll,
        }]
    }

    render = () => {
        const {
            selectedParameters,
            fractions,
            supports,
            units,
        } = this.props
        const {
            parameters,
            tmpSelectedParameters,
            fraction,
            support,
            place,
        } = this.state
        const parametersFormat = selectedParameters.map(({ parameterCode, fractionCode, supportCode, placeCode, unitCode }) => {
            const isSelected = !!tmpSelectedParameters.find(code => code === parameterCode)
            return {
                name: getLabel(parameters, parameterCode, 'name'),
                unit: getLabel(units, unitCode, 'symbolWithCode'),
                fraction: getLabel(fractions, fractionCode, 'labelWithCode'),
                support: getLabel(supports, supportCode, 'labelWithCode'),
                place: getLocalizationPicto(placeCode),
                nullValue: <i className={'material-icons'} >{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>,
                color: isSelected ? '#b8d2ff' : '#fff',
                code: parameterCode,
                isSelected,
            }
        })
        const nbParameterSelected = selectedParameters.length

        return (
            <>
                <Card
                    title={`${i18n.parameters} (${nbParameterSelected} ${getI18nTitleDataLength(i18n.element, i18n.elements, nbParameterSelected)})`}
                    actions={this.getAction()}
                >
                    <div className='row no-margin'>
                        <Table
                            showTitle={false}
                            data={parametersFormat}
                            type={{ headers: ['name', 'unit', 'fraction', 'support', 'place', 'nullValue'] }}
                            coloredLine
                            maxHeight='45vh'
                            onClick={({ code, isSelected }) => this.onClickParameter(code, isSelected)}
                            sortable
                            condensed
                            paging
                            nbPerPageLabel={nbPerPageLabel}
                        />
                    </div>
                </Card>

                <div className='row no-margin padding-top-1 padding-bottom-1'>
                    <Select
                        col={3}
                        label={i18n.fractions}
                        value={fraction}
                        options={fractions}
                        onChange={v => this.setState({ fraction: v })}
                        keyValue='id'
                        keyLabel='labelWithCode'
                    />
                    <Select
                        col={3}
                        label={i18n.support}
                        value={support}
                        options={supports}
                        onChange={v => this.setState({ support: v })}
                        keyValue='id'
                        keyLabel='labelWithCode'
                    />
                    <Select
                        col={3}
                        options={getSandreList(this.props.sandreCodes, 'ANALYSES.LIEU')}
                        value={place}
                        label={i18n.place}
                        returnNull
                        onChange={v => this.setState({ place: hasValue(v) && `${v}` || '' })}
                    />
                    <div className='col s2 offset-s1'>
                        <a className='col s12 waves-effect waves-teal btn font-white' onClick={this.applyDefaultValue}>
                            {i18n.apply}
                        </a>
                    </div>
                </div>
            </>
        )
    }
}

SelectParameter.propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    onChange: PropTypes.func,
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
    fractions: store.FractionReducer.fractions,
    supports: store.SupportReducer.supports,
    units: store.UnitReducer.units,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(SelectParameter)