import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../components/forms/Select'
import ToastrAction from 'toastr/actions/ToastrAction'
import AppStore from '../../store/AppStore'
import ReferencialDto from '../dto/ReferencialDto'
import { connect } from 'react-redux'
import ReferencialAction from '../action/ReferencialAction'

class ReplaceModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
        }
    }

    componentWillUnmount() {
        AppStore.dispatch(ReferencialAction.resetCheckDelete())
    }

    onChange = (_, value) => {
        this.setState({ selected: value })
    }

    onSubmit = () => {
        if (this.state.selected) {
            this.props.onReplace(this.state.selected)
        } else {
            AppStore.dispatch(ToastrAction.error(i18n.chooseExistingElement))
        }
    }

    getLoad = () => {
        if (this.props.checkDelete !== null) {
            return (
                <div>
                    <div className='row'>
                        <h5>{ i18n.numberOfUse + this.props.checkDelete }</h5>
                    </div>
                    <div className='row no-margin padding-bottom-120-px'>
                        <Select options={ this.props.elements } label={ `${this.props.label} :` } col={ 8 } onChange={ this.onChange }
                            value={ null } clearFunction displayWithCode
                        />
                    </div>
                    <div className='modal-footer white'>
                        <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                        <a className='waves-effect waves-teal btn-flat modal-close' onClick={ this.onSubmit }>{ i18n.replace }</a>
                    </div>
                </div>
            )
        } return (
            <div>
                <h5>Chargement ...</h5>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div className='modal-header-assignment'>
                    <div className='row no-margin'>
                        <h4 className='left no-margin black-text'>{ `${i18n.replacement} ${this.props.title} ${i18n.by} :` }</h4>
                    </div>
                </div>
                { this.getLoad() }
            </div>
        )
    }
}

ReplaceModal.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    elements: PropTypes.instanceOf(ReferencialDto),
    onReplace: PropTypes.func,
    checkDelete: PropTypes.number,
}

ReplaceModal.defaultProps = {
    title: '',
    label: '',
    elements: [],
    onReplace: () => {},
    checkDelete: null,
}

const mapStateToProps = store => {
    return {
        checkDelete: store.ReferencialReducer.checkDelete,
    }
}

export default connect(mapStateToProps)(ReplaceModal)
