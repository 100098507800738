import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import { getDate } from '../../../utils/DateUtil'
import PluviometryAction from '../../actions/PluviometryAction'
import DtoPluviometerStats from '../../dto/DtoPluviometerStats'
import Icon from '../../../components/icon/Icon'
import { Grid } from '@mui/material'
import { orderBy } from 'lodash'

class PluviometerStatisticPanel extends Component {
    componentDidMount() {
        this.props.fetchPluviometerMeasuresStats(this.props.id)
    }

    getMeasureFormat = () => {
        const { pluviometerStatistics } = this.props
        return orderBy(pluviometerStatistics, ['order', 'typeId'], 'asc').map(p => {
            const comment = p?.comment ? (<Icon
                icon='info'
                style={{ fontSize: 18, color: 'grey' } }
                tooltip={ p.comment }
            />) : ''
            return [
                <>{p.label} {comment}</>,
                getDate(p.startDate),
                getDate(p.endDate),
                p.countTotal === -1 ? i18n.calculatedMeasures : p.countTotal,
            ]
        })
    }

    getHeader = () => {
        const headers = [ i18n.statistics, i18n.begin, i18n.end, i18n.nbMeasures ]
        const result = headers.map((o, i) => {
            return (<th className={`${i === 0 ? 'left' : 'center'}`} style={{ lineHeight: '25px', padding: '5px 0 5px 25px', fontSize: '15px' }}>{o}</th>)
        })
        return (
            <thead>
                <tr>{result}</tr>
            </thead>
        )
    }

    getBody = () => {
        const statistics = this.getMeasureFormat()
        return statistics.map(stat => {
            const result = stat.map((o, i) => {
                return (<td className={`${i === 0 ? 'right right-align bold' : 'center'}`}> {o}</td>)
            })
            return (
                <tr style={{ fontSize: '14.3px', lineHeight: '20px' }}>{result}</tr>
            )
        })
    }

    render() {
        const content = (
            <div className='row no-margin'>
                <div className='col s12 no-padding'>
                    <table className='table condensed'>
                        {this.getHeader()}
                        <tbody>
                            {this.getBody()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
        return (
            <Grid container item xs={12} justifyContent='center'>
                <Card round noBoxShadow cardStyle={{ border: '1px solid grey', borderRadius: '5px', width: '80%' }}>
                    { content }
                </Card>
            </Grid>
        )
    }
}

PluviometerStatisticPanel.propTypes = {
    pluviometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
    fetchPluviometerMeasuresStats: PropTypes.func,
    id: PropTypes.string.isRequired,
}

const mapStateToProps = store => ({
    pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
})

const mapDispatchToProps = {
    fetchPluviometerMeasuresStats: PluviometryAction.fetchPluviometerMeasuresStats,
}

export default connect(mapStateToProps, mapDispatchToProps)(PluviometerStatisticPanel)