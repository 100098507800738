export default class PowerSupplyDto {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.id
        this.reference = obj.reference
        this.powerSupplyType = obj.powerSupplyType
        this.materielType = obj.powerSupplyType
        this.serialNumber = obj.serialNumber
        this.purchaseDate = obj.purchaseDate
        this.withdrawalDate = obj.withdrawalDate
        this.providerId = obj.providerId
        this.manufactureDate = obj.manufactureDate
        this.warrantyTime = obj.warrantyTime
        this.lifeTime = obj.lifeTime
        this.voltage = obj.voltage
        this.comment = obj.comment
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.administrator = obj.administrator
        this.manufacturerId = obj.manufacturerId
        this.networkCode = obj.networkCode
        this.referentLogin = obj.referentLogin
        this.isEquipment = obj.isEquipment
    }
}