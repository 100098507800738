import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import RefJobAction from '../../../../../domain/job/RefJobAction'
import JobAction from '../../../../../import/actions/JobAction'
import Table from '../../../../../components/datatable/Table'
import { orderBy } from 'lodash'
import { nbPerPageLabelShorty } from '../../../../../referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getFullDate } from '../../../../../utils/DateUtil'
import loading from 'assets/pictures/loading.gif'
import Icon from '../../../../../components/icon/Icon'
import QualityAction from '../../../../actions/QualityAction'
import DtoJobLogLight from 'import/dto/DtoJobLogLight'
import useDebounce from 'utils/customHook/useDebounce'
import { useDispatch } from 'react-redux'

const AddQualitoStepExecution = ({
    station,
    changeStation,
    nextStep,
    setFilters,
    setJobDataTypes,
}) => {
    const [logs, setLogs] = useState([])
    const [count, setCount] = useState(0)

    const dispatch = useDispatch()

    const getcolorLog = (status) => {
        if (status === 'success') {
            return 'green-200'
        } else if (status === 'error') {
            return 'red-200'
        } else if (status === 'warning') {
            return 'yellow-200'
        }
        return null
    }

    const automaticCallLogs = () => {
        RefJobAction.fetchJobExecutions(station.job.id, 10).then(jobExecutions => {
            JobAction.requestJobLogs(station.job.id, station.jobExecutionId).then(logsJob => {
                const jobLogs = logsJob.data.map(el => new DtoJobLogLight(el, station.jobExecutionId))
                const jobExecution = jobExecutions.find(e => e.id === station.jobExecutionId)
                changeStation({ jobExecution })
                const formattedJobLogs = jobLogs.map(l => ({
                    ...l,
                    color: getcolorLog(l.status),
                    date: getFullDate(l.date),
                }))
                setLogs(formattedJobLogs)
                if (jobExecution?.statusCode === undefined) {
                    setCount(count+1)
                } else if ([1, 2].includes(jobExecution?.statusCode)) {
                    const ids = (jobLogs.find(l => l.status === 'station_ids').value || '').split(',').map(s => parseInt(s))
                    changeStation({ jobExecution, ids })
                    dispatch(QualityAction.fetchQualitometersListSpecific())
                }
            })
        })
    }

    useEffect(() => {
        automaticCallLogs(station, changeStation, setLogs)
    }, [])

    useDebounce(() => {
        if (count !== 0) {
            return automaticCallLogs(station, changeStation, setLogs)
        }
        return null
    }, 2000, [count])

    return (
        <div className='padding-top-2'>
            {
                (() => {
                    switch (station.jobExecution?.statusCode) {
                        case 0:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobFailedMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobFailedDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <Icon icon='error' style={{ color: 'red' }} size='large' clickable={false} />
                                    </Grid>
                                </>
                            )
                        case 1:
                        case 2:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobSuccessMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobSuccessDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <Icon icon='check_circle' style={{ color: 'green' }} size='large'/>
                                    </Grid>
                                </>
                            )
                        case undefined:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobProgressMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobProgressDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <img src={ loading } />
                                    </Grid>
                                </>
                            )
                        default:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobUnexpectedMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobUnexpectedDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <Icon icon='error' style={{ color: 'red' }} size='large' clickable={false} />
                                    </Grid>
                                </>
                            )
                    }
                })()
            }
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                <Grid item xs={11}>
                    <Table
                        className='padding-left-1'
                        condensed
                        sortable
                        data={orderBy(logs, 'id', 'desc')}
                        paging
                        nbPerPageLabel={nbPerPageLabelShorty}
                        type={{ headers: ['station', 'date', 'value', 'status'] }}
                        customHeaders={ { station: i18n.elementLabel, value: i18n.valueLabel } }
                        maxHeight='62vh'
                        overflow='auto'
                        inverseDisplay
                        showTitle={false}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

AddQualitoStepExecution.propTypes = {
    nextStep: PropTypes.func,
    changeStation: PropTypes.func,
}

export default AddQualitoStepExecution