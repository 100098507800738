import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Card from '../../../../components/card/Card'
import Row from '../../../../components/react/Row'
import Input from '../../../../components/forms/Input'
import { checkMandatoryFields, onChangeDate, onChangeHour } from '../../../../utils/FormUtils'
import Button from '../../../../components/forms/Button'
import { getDate, getHour } from '../../../../utils/DateUtil'
import { connect } from 'react-redux'
import { arrayOf, instanceOf } from '../../../../utils/StoreUtils'
import AppStore from '../../../../store/AppStore'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import DtoPiezometryStationMeasure from '../../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import { round } from '../../../../utils/NumberUtil'
import SelectedMeasurePanel from './SelectedMeasurePanel'
import { minBy, orderBy } from 'lodash'
import NumberField from '../../../../components/forms/NumberField'
import DtoEvent from '../../../../events/dto/DtoEvent'
import TechniqueEventCard from '../../../../station/components/event/TechniqueEventCard'
import { MEASURE_COTE } from '../../../constants/PiezometryConstants'
import PiezometryAction from '../../../actions/PiezometryAction'
import { getMeasureValue } from '../../../../utils/PiezometryUtils'
import { WhiteCard } from '../../../../components/styled/Card'

class CorrectionToolPanel extends Component {
    constructor() {
        super()
        this.state = {
            validateActive: false,
            newValue: 0,
            selectedEvent: null,
        }
    }

    onValidate = (childState = {}, parentState = {}) => {
        checkMandatoryFields([{ field: 'newValue' }], this.state, () => {
            const dates = parentState.startDate ? parentState : this.props.dates
            const filtered = this.props.initialMeasures.filter(m => m.date >= dates.startDate && m.date <= dates.endDate)
            const valueKey = parentState.startDate || this.props.replayingCorrection ? 'initialValue' : 'value'
            const NGFKey = parentState.startDate || this.props.replayingCorrection ? 'initialNGF' : 'NGF'

            const funcNGF = this.props.displayCote === MEASURE_COTE.NGF ? (a, b) => a+b : (a, b) => a-b
            const funcDepth = this.props.displayCote === MEASURE_COTE.NGF ? (a, b) => a-b : (a, b) => a+b
            if (filtered.length) {
                const len = filtered.length
                const newValue = parentState.startDate ? childState.newValue : this.state.newValue
                const lastMeasureValue = getMeasureValue(filtered[len-1], this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti, parentState.startDate || this.props.replayingCorrection)


                const diff = newValue - lastMeasureValue
                const lastDate = filtered[len-1].date
                const timeDiff = lastDate - filtered[0].date
                const getDiff = (measure) => diff * (1 - ((lastDate - measure.date) / timeDiff))

                const changes = filtered.reduce((acc, measure) => ({ ...acc, [measure.measureIndex]: { ...measure, value: funcDepth(measure[valueKey], getDiff(measure)), NGF: funcNGF(measure[NGFKey], getDiff(measure)), updated: true } }), {})
                const newMeasures = Object.assign([], this.props.measures, changes)
                this.setState({ validateActive: true, ...childState })
                this.props.changeParent({ measures: newMeasures, ...parentState, changedValue: newValue })
            }
        })
    }

    onReplayOldCorrection = (event) => {
        const eventData = JSON.parse(event.problem)
        const childState = { newValue: this.props.displayCote === MEASURE_COTE.NGF ? eventData.NGFValue : eventData.depthValue }
        const parentState = { startDate: event.startDate, endDate: event.endDate, selectionMode: null, replayingCorrection: true }
        this.onValidate(childState, parentState)
        this.setState({ selectedEvent: event })
    }

    onSave = () => {
        const updatedMeasures = this.props.measures.filter(m => m.updated)
        AppStore.dispatch(PiezometerStationAction.updatePiezometerRawMeasures(
            this.props.piezometer.id, updatedMeasures, this.props.measures, measures => {
                if (this.props.replayingCorrection) {
                    this.props.updateTechnicalEvent(updatedMeasures, this.state.selectedEvent)
                } else {
                    this.props.createTechnicalEvent(updatedMeasures)
                }
                this.props.changeParent({ measures, initialMeasures: measures, replayingCorrection: false })
                this.setState({ validateActive: false })
                AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, updatedMeasures[0]?.dataType ?? -1))
            }))
    }

    onCancel = () => {
        this.props.onCancel()
        this.setState({ validateActive: false, newValue: 0 })
    }

    getSelectedPanel = () => {
        const { dates } = this.props
        if (dates.startDate && dates.endDate) {
            const measures = this.props.measures.filter(m => m.date >= this.props.dates.startDate && m.date <= this.props.dates.endDate)
            if (measures.length) {
                return <SelectedMeasurePanel measure={ minBy(measures, 'date') } message={ i18n.firstMeasureDetails } displayCote={ this.props.displayCote } lastLandmark={ this.props.lastLandmark } groundRefAlti={ this.props.groundRefAlti }/>
            }
        }
        return null
    }

    getEventsPanel = () => {
        const eventCards = orderBy(this.props.stationEvents.filter(e => e.eventType === 'T' && e.problem && JSON.parse(e.problem).tool === 'correction'), 'startDate', 'desc').map(event => (
            <TechniqueEventCard
                event={ event }
                shortDate clickable
                onClick={ ev => this.onReplayOldCorrection(ev) }
            />
        ))
        return (
            <div className='padding-left-1 padding-right-1'>
                <Card
                    className='transparent no-box-shadow'
                    maxHeight={300} smallCard
                    title={ i18n.appliedCorrections }
                >
                    { eventCards }
                </Card>
            </div>
        )
    }

    render() {
        const { changeParent, dates } = this.props
        return (
            <WhiteCard title={i18n.driftingCorrection} round>
                <div className='padding-top-1' id='correctionToolPanel'/>
                <Row className='padding-bottom-1 center-align'>
                    <Button tooltip='Sélectionner une plage' onClick={ () => this.props.changeParent({ selectionMode: 'start', startDate: null, endDate: null }) } disabled={ this.state.validateActive }
                        icon='settings_ethernet' className={ `margin-left-1 validToolBtn ${['start', 'end'].includes(this.props.selectionMode) ? 'activated' : ''}` }
                    />
                    <Button tooltip='Modifier une correction de dérive' onClick={ () => this.props.changeParent({ selectionMode: 'correctionEvent', startDate: null, endDate: null }) } disabled={ this.state.validateActive }
                        icon='autorenew' className={ `margin-left-1 validToolBtn ${this.props.selectionMode === 'correctionEvent' ? 'activated' : ''}` }
                    />
                </Row>
                <Row className='padding-left-2 padding-bottom-1'>
                    <h6 className='padding-left-1'>{
                        this.props.selectionMode === 'correctionEvent' ? 'Sélectionnez un événement de correction de dérive sur le graphique ou parmi la liste ci dessous.' : (
                            this.props.selectionMode === 'start' ? 'Sélectionnez la date de début sur le graphique.' : (
                                this.props.selectionMode === 'end' ? 'Sélectionnez la date de fin sur le graphique.' : (
                                    this.props.draggable ? 'Vous pouvez déplacer les points sélectionnés sur le graphique' : ''
                                )
                            )
                        )
                    }</h6>
                </Row>
                {
                    this.props.selectionMode !== 'correctionEvent' ? (
                        <div>
                            <Row>
                                <Input col={ 6 } title={ i18n.startDate } value={ getDate(dates.startDate) }
                                    onChange={ v => onChangeDate(v, v2 => changeParent({ startDate: v2 }), { max: dates.endDate }, dates.startDate) }
                                />
                                <Input col={ 6 } title={ i18n.startHour } value={ getHour(dates.startDate) }
                                    onChange={ v => onChangeHour(v, v2 => changeParent({ startDate: v2 }), { max: dates.endDate }, dates.startDate) }
                                />
                            </Row>
                            <Row>
                                <Input col={ 6 } title={ i18n.endDate } value={ getDate(dates.endDate) }
                                    onChange={ v => onChangeDate(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) }
                                />
                                <Input col={ 6 } title={ i18n.endHour } value={ getHour(dates.endDate) }
                                    onChange={ v => onChangeHour(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) }
                                />
                            </Row>
                            <Row><NumberField col={ 12 } title={ i18n.valueLabel } value={ this.state.newValue } onChange={ v => this.setState({ newValue: v }) } floatValue/></Row>
                        </div>
                    ) : null
                }
                { this.props.selectionMode === 'correctionEvent' ? this.getEventsPanel() : null }
                { this.getSelectedPanel() }
                <Row className='padding-bottom-1 padding-top-1 center-align'>
                    <Button tooltip={ i18n.apply } onClick={ this.onValidate } icon='border_color' className='btn-floating btn-large'/>
                    <Button tooltip={ i18n.cancel } onClick={ this.onCancel } icon='cancel' className='red btn-floating btn-large margin-left-2 margin-right-2' disabled={ !this.state.validateActive }/>
                    <Button tooltip={ i18n.register } onClick={ this.onSave } icon='save' disabled={ !this.state.validateActive } className={ `btn-floating btn-large ${this.state.validateActive ? 'pulse' : ''}` }/>
                </Row>
            </WhiteCard>
        )
    }

    componentDidMount() {
        this.setDragabble()
    }

    componentDidUpdate(prevProps) {
        if (!this.state.validateActive && this.props.measures.find(m => m.updated)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ validateActive: true })
        }
        this.setDragabble()
        if (this.props.changedValue !== prevProps.changedValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ newValue: round(this.props.changedValue) })
        }
    }

    setDragabble = () => {
        const newDraggable = (!this.props.selectionMode) && (this.props.dates.startDate && this.props.dates.endDate)
        if (newDraggable !== this.props.draggable) {
            this.props.changeParent({ draggable: newDraggable })
        }
    }

    componentWillUnmount() {
        if (this.props.draggable) {
            this.props.changeParent({ draggable: false })
        }
    }
}

CorrectionToolPanel.propTypes = {
    changeParent: PropTypes.func,
    piezometer: instanceOf(DtoPiezometer),
    dates: PropTypes.objectOf(PropTypes.number),
    selectionMode: PropTypes.string,
    measures: arrayOf(DtoPiezometryStationMeasure),
    onCancel: PropTypes.func,
    draggable: PropTypes.bool,
    changedValue: PropTypes.number,
    createTechnicalEvent: PropTypes.func,
    updateTechnicalEvent: PropTypes.func,
    stationEvents: arrayOf(DtoEvent),
    displayCote: PropTypes.string,
    replayingCorrection: PropTypes.bool,
    initialMeasures: arrayOf(DtoPiezometryStationMeasure),
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    stationEvents: store.EventsReducer.stationEvents,
})

export default connect(mapStateToProps)(CorrectionToolPanel)