import { difference, intersection, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SelectionTable from '../../../../components/datatable/SelectionTable'
import Input from '../../../../components/forms/Input'
import SelectionSelect from '../../../../components/forms/specific/SelectionSelect'
import ParameterDto from '../../../../referencial/components/parameter/dto/ParameterDto'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import { formatParameterSelectedPopup } from '../../../utils/CampaignUtils'

const DEFAULT_SELECTION = '-1'

class SelectParameter extends Component {
    constructor(props) {
        super(props)
        const parameters = formatParameterSelectedPopup(props.parameters.filter(p => p.active))
        const selectedParameters = props.selectedParameters.map(({ parameterCode }) => parameterCode)
        const listParameters = difference(parameters.map(({ code }) => code), selectedParameters)
        this.state = {
            parameters,
            selectedParameters,
            listParameters,
            selection: DEFAULT_SELECTION,
            selectionListParam: [],
            searchValue: '',
        }
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(prevProps.parameters, this.props.parameters)) {
            this.setState({ parameters: formatParameterSelectedPopup(this.props.parameters.filter(p => p.active)) })
        }
    }

    getFilterParameters = () => {
        const {
            searchValue,
            parameters,
            listParameters,
            selection,
            selectionListParam,
        } = this.state
        const listParam = selection !== DEFAULT_SELECTION ? intersection(listParameters, selectionListParam) : listParameters
        const params = listParam.map(paramCode => parameters.find(({ code: c }) => c === paramCode)).filter(p => !!p)
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? params.filter(p => p.labelSearch.includes(searchValueFormat)) : params
    }

    setParameterSelection = (selectionListParam, selection) => {
        this.setState({ selection, selectionListParam })
    }

    addAll = listParam => {
        const {
            selectedParameters,
            listParameters,
        } = this.state
        const {
            selectedParameters: propsSelectedParameters,
        } = this.props
        const listParamCode = listParam.map(({ code }) => code)
        const newParametersCode = [...selectedParameters, ...listParamCode]
        const newSelectedParameters = listParam.map(({ code, unitCode }) => {
            return {
                parameterCode: code,
                unitCode: unitCode || '0',
                fractionCode: '0',
                placeCode: '0',
                supportCode: '0',
            }
        })
        const newListParameters = difference(listParameters, listParamCode)
        this.setState({ selectedParameters: newParametersCode, listParameters: newListParameters })
        this.props.onChange({ selectedParameters: [...propsSelectedParameters, ...newSelectedParameters] })
    }

    deleteAll = () => {
        const {
            parameters,
        } = this.state
        this.setState({ selectedParameters: [], listParameters: parameters.map(({ code }) => code) })
        this.props.onChange({ selectedParameters: [] })
    }

    onAdd = ({ code, unitCode }) => {
        const {
            selectedParameters,
            listParameters,
        } = this.state
        const {
            selectedParameters: propsSelectedParameters,
        } = this.props
        const newParametersCode = [...selectedParameters, code]
        const newListParameters = listParameters.filter(paramCode => paramCode !== code)
        this.setState({ selectedParameters: newParametersCode, listParameters: newListParameters })
        this.props.onChange({ selectedParameters: [...propsSelectedParameters, { parameterCode: code, unitCode: unitCode || '0', fractionCode: '0', placeCode: '0', supportCode: '0' }] })
    }

    onDelete = ({ code }) => {
        const {
            selectedParameters,
            listParameters,
        } = this.state
        const {
            selectedParameters: propsSelectedParameters,
        } = this.props
        const newParametersCode = selectedParameters.filter(paramCode => paramCode !== code)
        const newListParameters = [...listParameters, code]
        this.setState({ selectedParameters: newParametersCode, listParameters: newListParameters })
        this.props.onChange({ selectedParameters: propsSelectedParameters.filter(({ parameterCode }) => code !== parameterCode) })
    }

    render = () => {
        const {
            searchValue,
            selection,
            parameters,
            selectedParameters,
        } = this.state
        const parametersFormat = this.getFilterParameters()

        const selectedParametersFormat = selectedParameters.map(paramCode => parameters.find(({ code }) => code === paramCode)).filter(p => !!p)
        return (
            <>
                <div className='row no-margin padding-top-1'>
                    <SelectionSelect
                        col={6}
                        value={selection}
                        onChange={this.setParameterSelection}
                    />
                </div>
                <div className='row no-margin padding-bottom-1'>
                    <Input
                        col={6}
                        title={i18n.search}
                        value={searchValue}
                        onChange={v => this.setState({ searchValue: v })}
                    />
                </div>
                <SelectionTable
                    maxHeight='43vh'

                    listData={parametersFormat}
                    listHeaders={['name', 'type', 'nature', 'status']}
                    listTitle={i18n.nonSelectedParameters}

                    selectedData={selectedParametersFormat}
                    selectedHeaders={['name', 'type', 'nature', 'status']}
                    selectedTitle={i18n.selectedParameters}

                    onAdd={this.onAdd}
                    addAll={this.addAll}
                    onDelete={this.onDelete}
                    deleteAll={this.deleteAll}
                />
            </>
        )
    }
}

SelectParameter.propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
    })),
    onChange: PropTypes.func,
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
})

export default connect(mapStateToProps)(SelectParameter)