import DtoInstallation from '../dto/installation/DtoInstallation'
import InstallationAction from '../actions/InstallationAction'
import { arrayOf, createIndex, instanceOf } from '../../utils/StoreUtils'
import AppStore from 'store/AppStore'
import DtoInstallationBorehole from '../dto/borehole/DtoInstallationBorehole'
import DtoInstallationEvent from '../dto/installation/DtoInstallationEvent'
import DtoInstallationAnalysis from '../dto/installation/DtoInstallationAnalysis'
import DtoInstallationMeasures from '../dto/installation/DtoInstallationMeasures'
import DtoBoreholeTableLine from '../dto/borehole/DtoBoreholeTableLine'
import DtoInstallationType from '../dto/installation/DtoInstallationType'
import DtoCavity from '../components/cavity/dto/DtoCavity'
import DtoInstallationActivity from '../dto/industrialSite/DtoInstallationActivity'
import DtoPollutedSoil from '../components/pollutedSoil/dto/DtoPollutedSoil'
import DtoIndustrialSite from '../components/industrialSite/dto/DtoIndustrialSite'
import DtoFlowObstruction from '../components/flowObstruction/dto/DtoFlowObstruction'
import DtoCaptureStation from '../components/capture/dto/DtoCaptureStation'
import DtoLiftingStation from '../components/liftingStation/dto/DtoLiftingStation'
import DtoPumpingStation from '../components/pumping/dto/DtoPumpingStation'
import DtoTankStation from '../components/tank/dto/DtoTankStation'
import DtoSTEP from '../components/step/dto/DtoSTEP'
import DtoTreatmentStation from '../components/treatment/dto/DtoTreatmentStation'
import DtoCaptureAlimArea from '../components/capture/dto/DtoCaptureAlimArea'
import DtoPiezometerContributorLink from '../../piezometry/dto/DtoPiezometerContributorLink'
import DtoStationCampaign from '../../campaign/dto/DtoStationCampaign'
import DtoStationAssociation from '../../station/dto/DtoStationAssociation'
import DtoVisit from '../components/campaign/dto/DtoVisit'
import DtoInstallationWithGeoItem from '../components/installations/dto/DtoInstallationWithGeoItem'
import DtoInstallationIndustrialSite from '../dto/industrialSite/DtoInstallationIndustrialSite'
import DtoInstallationLight from '../dto/installation/DtoInstallationLight'
import DtoInstallationTableLine from '../dto/installation/DtoInstallationTableLine'
import DtoTerritoryActivitie from '../dto/installation/DtoTerritoryActivitie'
import DtoTerritoryPollutants from '../dto/installation/DtoTerritoryPollutants'
import DtoTerritoryPollutantsEmissions from '../dto/installation/DtoTerritoryPollutantsEmissions'
import DtoInstallationTank from '../dto/installation/DtoInstallationTank'
import DtoTerritoryEmissionsActivity from '../dto/installation/DtoTerritoryEmissionsActivity'
import DtoTerritoryEmissionsDeclaration from '../dto/installation/DtoTerritoryEmissionsDeclaration'
import DtoIndustrialSiteActivityStat from '../dto/installation/DtoIndustrialSiteActivityStat'
import DtoConventionAnalysis from '../dto/installation/DtoConventionAnalysis'
import DtoSTEPTerritoryEmissions from '../components/step/dto/DtoSTEPTerritoryEmissions'
import DtoIndustrialSiteTableLine from '../dto/industrialSite/DtoIndustrialSiteTableLine'
import DtoEstablishmentDischarge from '../dto/installation/DtoEstablishmentDischarge'
import DtoDangerWithType from 'pgsse/dto/DtoDangerWithType'
import DtoEntityWithLocation from 'pgsse/dto/DtoEntityWithLocation'
import DtoDanger from 'installation/components/campaign/dto/DtoDanger'
import DtoBasin from 'installation/components/step/dto/DtoBasin'
import DtoSTEPLine from 'installation/components/step/dto/DtoSTEPLine'
import DtoTankLine from 'installation/components/tank/dto/DtoTankLine'
import DtoNetwork from 'station/dto/DtoNetwork'
import { createSlice } from '@reduxjs/toolkit'
import InstallationThunk from 'installation/actions/InstallationThunk'
import DtoInstallationSituation from 'installation/dto/installation/DtoInstallationSituation'
import DtoInstallationsAssociatedEvents from 'installation/dto/installation/DtoInstallationsAssociatedEvents'
import DtoFlowObstructionLine from 'installation/components/tank/dto/DtoFlowObstructionLine'
import DtoInstallationAnalysisStats from 'installation/components/installations/dto/DtoInstallationAnalysisStats'
import DtoInstallationAnalysisTable from 'installation/dto/installation/DtoInstallationAnalysisTable'
import DtoInstallationPiezoMeasures from 'installation/components/installationDefault/dto/DtoInstallationPiezoMeasures'
import DtoInstallationHydroMeasures from 'installation/components/installationDefault/dto/DtoInstallationHydroMeasures'

export const initialState = {
    installation: new DtoInstallation({}),
    installationEvent: {},
    installations: [],
    installationDataTypes: [],
    installationsLight: [],
    installationsWithGeo: [],
    installationsExploitationGeo: [],
    installationsIndex: {},
    installationAssociations: [],
    installationBorehole: new DtoInstallationBorehole({}),
    installationCapture: new DtoCaptureStation({}),
    installationLifting: new DtoLiftingStation({}),
    installationPumping: new DtoPumpingStation({}),
    installationSTEP: new DtoSTEP({}),
    installationTank: new DtoTankStation({}),
    installationAgriTanks: [],
    installationTreatment: {},
    installationEvents: [],
    installationAnalysis: [],
    installationsAnalysis: [],
    installationMeasures: [],
    installationTable: [],
    boreholeTable: [],
    industrialSiteTable: [],
    installationTableCount: 0,
    installationsTypes: [],
    cavity: {},
    industrialSite: {},
    industrialSites: [],
    installationIndustrialSites: [],
    pollutedSoil: {},
    pollutedSoils: [],
    flowObstruction: {},
    flowObstructions: [],
    activities: [],
    activitiesIndex: {},
    captureAlimAreas: [],
    visit: new DtoVisit({}),
    installationCampaigns: [],
    installationCampaignStations: [],
    installationCampaignsProgress: [],
    territoryActivities: [],
    territoryDiagnosis: [],
    pollutants: [],
    pollutantsEmissions: [],
    installationVisits: [],
    emissionsActivity: [],
    emissionsDeclarations: [],
    distributionEstablishmentsActivitySection: [],
    conventionsAnalysis: [],
    STEPTerritoryArtisans: [],
    STEPTerritoryIndustries: [],
    STEPTerritorySubscribers: [],
    STEPTerritoryDiagnosis: [],
    establishmentDischarges: [],
    specificExportTerritoryActivities: [],
    dangersWithType: [],
    entitiesWithLocation: [],
    danger: [],
    basins: [],
    STEPTable: [],
    tankTable: [],
    flowObstructionTable: [],
    installationNetwork: {},
    installationsSituation: [],
    installationsAssociatedEvents: {},
    installationAnalysisStats: [],
    installationAnalysisParameters: [],
    installationAnalysisTableCount: 0,
    installationAnalysisCount: 0,
    linkedPiezoMeasures: [],
    linkedHydroMeasures: [],
}

const store = createSlice({
    name: 'installation',
    initialState,
    reducers: {
        INSTALLATION_TABLE: (state, action) => {
            state.installationTableCount = action.payload.count
            state.installationTable = action.payload.data.map(d => new DtoInstallationTableLine(d))
        },
        ALL_INSTALLATIONS_LIGHT: (state, action) => {
            const installationsLight = action.payload.map(installation => new DtoInstallationLight(installation))
            state.installationsLight = installationsLight
            state.installationsIndex = createIndex(installationsLight)
        },
        ALL_INSTALLATIONS: (state, action) => {
            state.installations = action.payload.map(installation => new DtoInstallation(installation))
        },
        ALL_INSTALLATIONS_GEO: (state, action) => {
            state.installationsWithGeo = action.payload.map((installation) => new DtoInstallationWithGeoItem(installation))
        },
        EXPLOITATION_INSTALLATIONS_GEO: (state, action) => {
            state.installationsExploitationGeo = action.payload.map((installation) => new DtoInstallationWithGeoItem(installation))
        },
        INSTALLATION: (state, action) => {
            state.installation = new DtoInstallation(action.payload)
        },
        DELETE_INSTALLATION: (state, action) => {
            const installations2 = state.installations.filter(i => i.id !== action.payload)
            state.installation = {}
            state.installationBorehole = {}
            state.installations = installations2
            state.installationsIndex = createIndex(installations2)
        },
        CREATE_INSTALLATION: (state, action) => {
            const installations3 = [...state.installations, new DtoInstallation(action.payload) ]
            state.installations = installations3
            state.installationsIndex = createIndex(installations3)
        },
        INSTALLATION_ASSOCIATIONS: (state, action) => {
            state.installationAssociations = action.payload.map(a => new DtoStationAssociation(a))
        },
        INSTALLATION_BOREHOLE: (state, action) => {
            state.installationBorehole = new DtoInstallationBorehole(action.payload)
        },
        BOREHOLE_TABLE: (state, action) => {
            state.installationTableCount = action.payload.count
            state.boreholeTable = action.payload.data.map(borehole => new DtoBoreholeTableLine(borehole))
        },
        INDUSTRIAL_SITE_TABLE: (state, action) => {
            state.installationTableCount = action.payload.count
            state.industrialSiteTable = action.payload.data.map(borehole => new DtoIndustrialSiteTableLine(borehole))
        },
        INSTALLATION_CAPTURE: (state, action) => {
            state.installationCapture = new DtoCaptureStation(action.payload)
        },
        INSTALLATION_LIFTING: (state, action) => {
            state.installationLifting = new DtoLiftingStation(action.payload)
        },
        INSTALLATION_PUMPING: (state, action) => {
            state.installationPumping = new DtoPumpingStation(action.payload)
        },
        INSTALLATION_STEP: (state, action) => {
            state.installationSTEP = new DtoSTEP(action.payload)
        },
        INSTALLATION_AGRI_TANKS: (state, action) => {
            state.installationAgriTanks = action.payload.map((t) => new DtoInstallationTank(t))
        },
        INSTALLATION_TANK: (state, action) => {
            state.installationTank = new DtoTankStation(action.payload)
        },
        INSTALLATION_TREATMENT: (state, action) => {
            state.installationTreatment = new DtoTreatmentStation(action.payload)
        },
        INSTALLATION_EVENTS: (state, action) => {
            state.installationEvents = action.payload.map(e => new DtoInstallationEvent(e))
        },
        INSTALLATION_EVENT: (state, action) => {
            state.installationEvent = new DtoInstallationEvent(action.payload)
        },
        INSTALLATIONS_TYPES: (state, action) => {
            state.installationsTypes = action.payload.map(t => new DtoInstallationType(t))
        },
        CREATE_INSTALLATION_EVENT: (state, action) => {
            state.installationEvent = new DtoInstallationEvent(action.payload)
            state.installationEvents = state.installationEvents.concat([action.payload])
        },
        UPDATE_INSTALLATION_EVENT: (state, action) => {
            state.installationEvent = new DtoInstallationEvent(action.payload)
            state.installationEvents = state.installationEvents.filter(e => e.number !== action.payload.number).concat([action.payload])
        },
        DELETE_INSTALLATION_EVENT: (state, action) => {
            state.installationEvent = {}
            state.installationEvents = state.installationEvents.filter(e => e.number !== action.payload)
        },
        INSTALLATION_ANALYSIS: (state, action) => {
            state.installationAnalysisCount = action.payload.count
            state.installationAnalysis = action.payload.data.map(e => new DtoInstallationAnalysisTable(e))
        },
        INSTALLATIONS_ANALYSIS: (state, action) => {
            state.installationAnalysisTableCount = action.payload.count
            state.installationsAnalysis = action.payload.data.map(d => new DtoInstallationAnalysisTable(d))
        },
        INSTALLATION_ASSOCIATED_PIEZOMETER_MEASURES: (state, action) => {
            state.installationMeasures = action.payload.map(r => new DtoInstallationMeasures(r))
        },
        INSTALLATION_ARRANGEMENTS: (state, action) => {
            state.installation = new DtoInstallation({
                ...state.installation,
                arrangements: action.payload,
            })
        },
        UPDATE_INSTALLATION: (state, action) => {
            const otherInstallations = state.installations.filter(i => i.id !== action.payload.id)
            const newInstallation = new DtoInstallation(action.payload)
            const newInstallations = otherInstallations.concat([newInstallation])
            state.installation = newInstallation
            state.installations = newInstallations
            state.installationsIndex = createIndex(newInstallations)
        },
        UPDATE_INSTALLATION_BOREHOLE: (state, action) => {
            state.installationBorehole = new DtoInstallationBorehole(action.payload)
        },
        updateInstallationFlowObstruction: (state, action) => {
            state.flowObstruction = new DtoFlowObstruction(action.payload)
        },
        UPDATE_INSTALLATION_CAPTURE: (state, action) => {
            state.installationCapture = new DtoCaptureStation(action.payload)
        },
        UPDATE_INSTALLATION_LIFTING: (state, action) => {
            state.installationLifting = new DtoLiftingStation(action.payload)
        },
        UPDATE_INSTALLATION_PUMPING: (state, action) => {
            state.installationPumping = new DtoPumpingStation(action.payload)
        },
        UPDATE_INSTALLATION_STEP: (state, action) => {
            state.installationSTEP = new DtoSTEP(action.payload)
        },
        UPDATE_INSTALLATION_TANK: (state, action) => {
            state.installationTank = new DtoTankStation(action.payload)
        },
        UPDATE_INSTALLATION_TREATMENT: (state, action) => {
            state.installationTreatment = new DtoTreatmentStation(action.payload)
        },
        CAVITY: (state, action) => {
            state.cavity = new DtoCavity(action.payload)
        },
        INDUSTRIAL_SITE: (state, action) => {
            state.industrialSite = new DtoIndustrialSite(action.payload)
        },
        INDUSTRIAL_SITES: (state, action) => {
            state.industrialSites = action.payload.map(d => new DtoIndustrialSite(d))
        },
        POLLUTED_SOIL: (state, action) => {
            state.pollutedSoil = new DtoPollutedSoil(action.payload)
        },
        POLLUTED_SOILS: (state, action) => {
            state.pollutedSoils = action.payload.map(d => new DtoPollutedSoil(d))
        },
        receiveFlowObstructions: (state, action) => {
            state.flowObstructions = action.payload.map(a => new DtoFlowObstruction(a))
        },
        FLOW_OBSTRUCTION: (state, action) => {
            state.flowObstruction = new DtoFlowObstruction(action.payload)
        },
        INSTALLATION_ACTIVITIES: (state, action) => {
            const activities = action.payload.map(d => new DtoInstallationActivity(d))
            state.activities = activities
            state.activitiesIndex = createIndex(activities, 'code')
        },
        RESET_INSTALLATION_EVENT: (state) => {
            state.installationEvent = {}
        },
        RESET_INSTALLATION: (state) => {
            state.installation = {}
            state.installationEvent = {}
            state.installationAssociations = []
            state.installationBorehole = {}
            state.installationEvents = []
            state.installationAnalysis = []
            state.installationMeasures = []
        },
        INSTALLATION_CAMPAIGNS: (state, action) => {
            state.installationCampaigns = action.payload
        },
        INSTALLATION_CAMPAIGNS_PROGRESS: (state, action) => {
            state.installationCampaignsProgress = action.payload
        },
        INSTALLATION_CAMPAIGN_STATIONS: (state, action) => {
            state.installationCampaignStations = action.payload.map(cs => new DtoStationCampaign(cs))
        },
        CAPTURE_ALIM_AREAS: (state, action) => {
            state.captureAlimAreas = action.payload.map(aac => DtoCaptureAlimArea(aac))
        },
        CONTRIBUTOR_LINKS: (state, action) => {
            state.contributorLinks = action.payload.map(link => new DtoPiezometerContributorLink(link))
        },
        VISIT: (state, action) => {
            state.visit = new DtoVisit(action.payload)
        },
        INSTALLATION_INDUSTRIAL_SITES: (state, action) => {
            state.installationIndustrialSites = action.payload.map(indusSite => new DtoInstallationIndustrialSite(indusSite))
        },
        TERRITORY_ACTIVITIES: (state, action) => {
            state.territoryActivities = action.payload.map(activite => new DtoTerritoryActivitie(activite))
        },
        TERRITORY_POLLUTANTS: (state, action) => {
            state.pollutants = action.payload.map(pollutant => new DtoTerritoryPollutants(pollutant))
        },
        TERRITORY_POLLUTANTS_EMISSIONS: (state, action) => {
            state.pollutantsEmissions = action.payload.map(pollutantEmission => new DtoTerritoryPollutantsEmissions(pollutantEmission))
        },
        VISIT_MODE: (state, action) => {
            state.installationVisits = action.payload
        },
        TERRITORY_EMISSIONS_ACTIVITY: (state, action) => {
            state.emissionsActivity = action.payload.map(emissionActivity => new DtoTerritoryEmissionsActivity(emissionActivity))
        },
        TERRITORY_EMISSIONS_DECLARATIONS: (state, action) => {
            state.emissionsDeclarations = action.payload.map(emissionsDeclaration => new DtoTerritoryEmissionsDeclaration(emissionsDeclaration))
        },
        DISTRIBUTION_ETABLISHMENTS_ACTIVITY_SECTION: (state, action) => {
            state.distributionEstablishmentsActivitySection = action.payload.map(distributionEstablishmentActivitySection => new DtoIndustrialSiteActivityStat(distributionEstablishmentActivitySection))
        },
        TERRITORY_CONVENTIONS_ANALYSIS: (state, action) => {
            state.conventionsAnalysis = action.payload.map(conventionAnalysis => new DtoConventionAnalysis(conventionAnalysis))
        },
        TERRITORY_DIAGNOSIS: (state, action) => {
            state.territoryDiagnosis = action.payload
        },

        STEP_TERRITORY_ARTISANS: (state, action) => {
            state.STEPTerritoryArtisans = action.payload.map(d => new DtoSTEPTerritoryEmissions(d))
        },
        STEP_TERRITORY_INDUSTRIES: (state, action) => {
            state.STEPTerritoryIndustries = action.payload.map(d => new DtoSTEPTerritoryEmissions(d))
        },
        STEP_TERRITORY_SUBSCRIBERS: (state, action) => {
            state.STEPTerritorySubscribers = action.payload
        },
        STEP_TERRITORY_DIAGNOSIS: (state, action) => {
            state.STEPTerritoryDiagnosis = action.payload
        },
        ESTABLISHMENT_DISCHARGES: (state, action) => {
            state.establishmentDischarges = action.payload.map(d => new DtoEstablishmentDischarge(d))
        },
        DANGERS_TYPE: (state, action) => {
            state.dangersWithType = action.payload.map(d => new DtoDangerWithType(d))
        },
        DANGERS_LOCATION: (state, action) => {
            state.entitiesWithLocation = action.payload.map(d => new DtoEntityWithLocation(d))
        },
        DANGER: (state, action) => {
            state.danger = new DtoDanger(action.payload)
        },
        BASINS: (state, action) => {
            state.basins = action.payload.map(basin => new DtoBasin(basin))
        },
        STEP_TABLE: (state, action) => {
            state.installationTableCount = action.payload.count
            state.STEPTable = action.payload.data.map(step => new DtoSTEPLine(step))
        },
        TANK_TABLE: (state, action) => {
            state.installationTableCount = action.payload.count
            state.tankTable = action.payload.data.map(tank => new DtoTankLine(tank))
        },
        FLOW_OBSTRUCTION_TABLE: (state, action) => {
            state.installationTableCount = action.payload.count
            state.flowObstructionTable = action.payload.data.map(tank => new DtoFlowObstructionLine(tank))
        },
        INSTALLATION_NETWORK: (state, action) => {
            state.installationNetwork = new DtoNetwork(action.payload)
        },
        UPDATE_INSTALLATION_NETWORK: (state, action) => {
            state.installationNetwork = new DtoSTEP(action.payload)
        },
        receiveInstallationsSituation: (state, action) => {
            state.installationsSituation = action.payload.map(s => new DtoInstallationSituation(s))
        },
        receiveInstallationsAssociatedEvents: (state, action) => {
            state.installationsAssociatedEvents = new DtoInstallationsAssociatedEvents(action.payload)
        },
        receiveInstallationAnalysisStats: (state, action) => {
            state.installationAnalysisStats = action.payload.map(s => new DtoInstallationAnalysisStats(s))
        },
        receiveInstallationAnalysisParameters: (state, action) => {
            state.installationAnalysisParameters = action.payload
        },
        resetInstallationDashboard: (state) => {
            state.installationAnalysisParameters = []
            state.installationAnalysisStats = []
        },
        receiveInstallationLinkedPiezoMeasures: (state, action) => {
            state.linkedPiezoMeasures = action.payload.map(p => new DtoInstallationPiezoMeasures(p))
        },
        receiveInstallationLinkedHydroMeasures: (state, action) => {
            state.linkedHydroMeasures = action.payload.map(p => new DtoInstallationHydroMeasures(p))
        },
    },
    extraReducers: {
        [InstallationThunk.fetchInstallationsLight.fulfilled]: (state, action) => {
            state.installationsLight = action.payload.map(installation => new DtoInstallationLight(installation))
        },
    },
})

export const InstallationActionConstant = store.actions
export default store.reducer

export const InstallationStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        installation: {
            fetch: InstallationAction.fetchInstallation,
            store: appStore.InstallationReducer.installation,
            propType: instanceOf(DtoInstallation),
            reset: InstallationActionConstant.RESET_INSTALLATION,
            delete: InstallationAction.deleteInstallation,
        },
        installations: {
            fetch: InstallationAction.fetchInstallationsLight,
            store: appStore.InstallationReducer.installations,
            propType: arrayOf(DtoInstallation),
        },
        installationAssociations: {
            fetch: InstallationAction.fetchInstallationAssociations,
            store: appStore.InstallationReducer.installationAssociations,
            propType: arrayOf(DtoStationAssociation),
        },
        installationBorehole: {
            fetch: InstallationAction.fetchInstallationBorehole,
            update: InstallationAction.updateInstallationBorehole,
            store: appStore.InstallationReducer.installationBorehole,
            propType: arrayOf(DtoInstallationBorehole),
        },
        installationEvents: {
            fetch: InstallationAction.fetchInstallationEvents,
            store: appStore.InstallationReducer.installationEvents,
            propType: arrayOf(DtoInstallationEvent),
        },
        installationEvent: {
            fetch: InstallationAction.fetchInstallationEvent,
            store: appStore.InstallationReducer.installationEvent,
            propType: instanceOf(DtoInstallationEvent),
        },
        installationAnalysis: {
            fetch: InstallationAction.fetchInstallationAnalysis,
            store: appStore.InstallationReducer.installationAnalysis,
            propType: arrayOf(DtoInstallationAnalysis),
        },
        installationMeasures: {
            fetch: InstallationAction.fetchInstallationAssociatedPiezometerMeasures,
            store: appStore.InstallationReducer.installationMeasures,
            propType: arrayOf(DtoInstallationMeasures),
        },
    }
}
