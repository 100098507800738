import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getFullDate } from '../../utils/DateUtil'
import DtoMessage from '../dto/DtoMessage'


const GlobalMessageCard = ({
    lastMessage = {},
    redirect = () => { },
}) => {
    return (
        <div className={'collection-item clickable row no-margin card'} style={{ display: 'flex' }} onClick={() => redirect(`/messages/${lastMessage.login}`)}>
            <div className={'col s3 no-margin white'} style={{ width: '20%', alignItems: 'center' }}>
                <div className={'valign-wrapper '} style={{ marginLeft: '10px', height: '100%' }}>
                    <i className='material-icons margin-right-1' style={{ fontSize: '3rem' }}>{lastMessage.updateLogin === lastMessage.login ? 'help' : 'message'}</i>
                    <h5 style={{ margin: '0' }}>{lastMessage.login}</h5>
                </div>
            </div>
            <div className='col s9 no-margin white no-padding'>
                <div className='col s12'>
                    <h5 className='thin' style={{ margin: '0.82rem 0 0.323rem 0' }}>{getFullDate(lastMessage.updateDate)}</h5>
                    <p style={{ margin: '0.41rem 0 0.656rem 3px', fontSize: '16px', fontWeight: '100' }}>{lastMessage.message}</p>
                </div>
            </div>
        </div>
    )
}

GlobalMessageCard.propTypes = {
    lastMessage: PropTypes.instanceOf(DtoMessage),
    redirect: PropTypes.func,
}

const mapDispatchToProps = {
    redirect: push,
}

export default connect(null, mapDispatchToProps)(GlobalMessageCard)