import Icon from 'components/icon/Icon'
import { round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import { getFullDate, getFullDateMini } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { CAMPAIGNS, DESCRIPTION, EVENTS } from '../../constants/AlertConstants'
import { sieauTooltip } from 'utils/FormUtils'
import { useDispatch, useSelector } from 'react-redux'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'

const AlertSitePanel = ({
    site = {},
    measureDate = '',
    onClick = () => {},
    onToggle,
    divider = true,
    onHover = () => {},
    withoutIcon = false,
    title = '',
}) => {
    const dispatch = useDispatch()

    const {
        markerIcon = '',
        markerName = '',
        icon = '',
        tooltip = '',
        name = '',
        code = '',
        cityName = '',
        lastMeasure = [],
        typeName = '',
    } = site

    const lastMeasuresFiltered = lastMeasure.filter(m => hasValue(m?.value))

    const { piezometryDataTypes, pluviometryDataTypes, hydrometryDataTypes } = useSelector(store => ({
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }))

    useEffect(() => {
        if (!piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }

        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }

        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
    }, [])

    const labelStation = {
        quality: i18n.quality,
        piezometry: i18n.piezometry,
        pluviometry: i18n.pluviometry,
        hydrometry: i18n.hydrometry,
        installation: i18n.installation,
        productionUnit: i18n.productionUnit,
        contact: i18n.contact,
        contributor: i18n.contributor,
    }

    const getLastMeasureUnit = (type, lastMeasureValue) => {
        const units = {
            pluviometry: pluviometryDataTypes,
            hydrometry: hydrometryDataTypes,
            piezometry: piezometryDataTypes,
        }

        const typeUnit = units[type]
        const dataType = type === 'piezometry' ? -1 : parseInt(lastMeasureValue?.dataType)

        return typeUnit?.find(u => parseInt(u.id) === dataType)?.unit ?? ''
    }

    const isContact = ['contact', 'contributor'].includes(typeName)

    return (
        <div className='row no-margin clickable' style={{ maxHeight: '50px !important' }} onMouseOver={() => onHover(site, true)} onMouseOut={() => onHover(site, false)}>
            <div className='col s12 no-padding' style={{ maxHeight: '50px !important' }}>
                <div className='row' onClick={() => onToggle ? onToggle() : onClick(site, DESCRIPTION)} style={{ maxHeight: '50px !important', margin: '3px 0px 0px 0px' }}>
                    {
                        !withoutIcon && (
                            <div className='col s2 no-padding width-4 margin-left-1 margin-right-1' style={{ height: '100px !important', width: '80px !important', maxHeight: '50px !important' }}>
                                {markerIcon.startsWith('<svg') ? (
                                    <img className='responsive-img' src={`data:image/svg+xml;utf8,${markerIcon}`} { ...sieauTooltip(markerName, null, 'top') } />
                                ) : (
                                    <img className='responsive-img' src={markerIcon} { ...sieauTooltip(markerName, null, 'top') } />
                                )}
                            </div>
                        )
                    }
                    <div className={`col ${withoutIcon ? 's12' : 's10'} no-padding`} style={{ maxHeight: '50px !important' }}>
                        <div className='row no-margin'>
                            <div className='col s12'>
                                <div>
                                    <span className='font-size-15 no-padding'>
                                        <b>{labelStation[typeName] ?? ''}</b>
                                    </span>
                                </div>
                                {
                                    isContact && (
                                        <div>
                                            <span className='font-size-15 no-padding'>
                                                <p>{site.name ?? ''}</p>
                                            </span>
                                        </div>
                                    )
                                }
                                <div>
                                    <span className='font-size-14 no-padding'>
                                        {
                                            isContact ? (
                                                <p>{site.road ?? ''}{site.postalCode ? `, ${site.postalCode}` : ''}{site.city ? `, ${site.city}` : ''}</p>
                                            ) : (
                                                <p>{code} {name ? `- ${name}` : ''} {cityName ? `- ${cityName}` : ''}</p>
                                            )
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            !isContact && (
                                <div className='row valign-wrapper no-margin'>
                                    <div className='col s12'>
                                        <div style={{ padding: '10px 0 5px 0' }}>
                                            <span className='font-size-15 no-padding'>
                                                <b>{i18n.latestData}</b>
                                            </span>
                                        </div>
                                        {
                                            typeName === 'quality' ? (
                                                site.historyStart && site.historyEnd ? (
                                                    <>
                                                        <div className={`col s12`}>
                                                            <span className='font-size-14 no-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <b>{i18n.historyDate}</b>
                                                            </span>
                                                        </div>
                                                        <div className={`col s5`}>
                                                            <span className='font-size-14 no-padding'>
                                                                {getFullDateMini(site.historyStart)}
                                                            </span>
                                                        </div>
                                                        <div className='col s2'>
                                                            <span className='font-size-14 no-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {i18n.to}
                                                            </span>
                                                        </div>
                                                        <div className='col s5'>
                                                            <span className='font-size-14 no-padding' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                {getFullDateMini(site.historyEnd)}
                                                            </span>
                                                        </div>
                                                        <div className={`col s12`}>
                                                            <span className='font-size-14 no-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <b>{i18n.lastOperation2}</b>
                                                            </span>
                                                        </div>
                                                        <div className={`col s6`}>
                                                            <span className='font-size-14 no-padding'>
                                                                {getFullDateMini(site.historyEnd)}
                                                            </span>
                                                        </div>
                                                        <div className='col s6'>
                                                            <span className='font-size-14 no-padding' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                {site.operationCount ?? 0} {site.operationCount && site.operationCount.length > 1 ? i18n.analyses : i18n.analysis}
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className='col s12 no-padding'>
                                                        <span className='font-size-14'>
                                                            {i18n.noValue}
                                                        </span>
                                                    </div>
                                                )
                                            ) : (
                                                lastMeasuresFiltered.length ? lastMeasuresFiltered.map(m => (
                                                    <>
                                                        <div className={`col s${(m.title || title) ? 5 : 8}`}>
                                                            <span className='font-size-14 no-padding'>
                                                                {getFullDate(moment(m.measureDate || m.date)) || measureDate}
                                                            </span>
                                                        </div>
                                                        {(m.title || title) && (
                                                            <div className='col s3'>
                                                                <span className='font-size-14 no-padding'>
                                                                    {m.title || title}
                                                                </span>
                                                            </div>
                                                        )}
                                                        <div className='col s4'>
                                                            <span className='font-size-14 no-padding right'>
                                                                {`${round(m.value, 3)} ${m?.unit || getLastMeasureUnit(typeName, m)}`}
                                                            </span>
                                                        </div>
                                                    </>
                                                )) : (
                                                    <div className='col s12 no-padding'>
                                                        <span className='font-size-14'>
                                                            {i18n.noValue}
                                                        </span>
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='row no-margin'>
                    <div className='col s1 no-padding'>
                        <i className={'material-icons small tooltipped'} data-tooltip={tooltip} style={{ color: icon.leftIconColor }} onClick={icon.leftIconClick}>{icon.leftIcon}</i>
                    </div>
                </div>
                <div className='event-card'>
                    <div className='row no-margin no-padding event-footer'>
                        <div className='left margin-left-1'>
                            {
                                hasValue(site.jobExecutionId) && (
                                    <Icon
                                        size='small'
                                        icon='wifi'
                                        tooltip={'Open Data'}
                                    />
                                )
                            }
                        </div>
                        <div className='left' style={{ paddingLeft: '7px' }}>
                            {
                                site.bookmarked && (
                                    <Icon
                                        size='small'
                                        icon='grade'
                                        tooltip={i18n.bookmark}
                                    />
                                )
                            }
                        </div>
                        <div className='right'>
                            {
                                (site.currentComments || site.currentMonitoring) && (
                                    <Icon
                                        size='small'
                                        icon='event'
                                        onClick={() => onClick(site, EVENTS, 'date')}
                                        tooltip={i18n.currentEvents}
                                    />
                                )
                            }
                            {
                                hasValue(site.currentMonitoring) && (
                                    <Icon
                                        size='small'
                                        icon='notifications'
                                        onClick={() => onClick(site, EVENTS, 'eventType')}
                                        tooltip={i18n.oversight}
                                    />
                                )
                            }
                            {
                                hasValue(site.currentCampaign) && (
                                    <Icon
                                        size='small'
                                        icon='nature_people'
                                        onClick={() => onClick(site, CAMPAIGNS)}
                                        tooltip={i18n.currentCampaign}
                                    />
                                )
                            }
                            <Icon
                                size='small'
                                icon='info'
                                onClick={() => onClick(site, DESCRIPTION)}
                                tooltip={i18n.description}
                            />
                        </div>
                    </div>
                </div>
                {divider && <div className='divider' />}
            </div>
        </div>
    )
}

AlertSitePanel.propTypes = {
    measureDate: PropTypes.string,
    divider: PropTypes.bool,
    onClick: PropTypes.func,
    onToggle: PropTypes.func,
    site: PropTypes.shape({}),
    onHover: PropTypes.func,
    message: PropTypes.string,
    withoutIcon: PropTypes.boolean,
    unit: PropTypes.string,
    title: PropTypes.string,
}

export default AlertSitePanel