import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import SelectedElementsModal from 'components/modal/SelectedElementsModal'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import DatePicker from '../../../../components/forms/DatePicker'
import Input from '../../../../components/forms/Input'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import SieauAction from '../../../../components/sieau/SieauAction'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import NetworkAction from '../../../../referencial/components/network/actions/NetworkAction'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../store/AppStore'
import { arrayOf } from '../../../../utils/StoreUtils'
import Job from '../../../dto/DtoJob'
import Checkbox from '../../../../components/forms/Checkbox'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import SuperMultiAutocomplete from '../../../../components/forms/SuperMultiAutocomplete'

class ExportQesoutPanelQualite extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 50,
        }
    }

    componentDidMount() {
        if (!this.props.qualitometers.length) {
            AppStore.dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!this.props.contributors.length) {
            AppStore.dispatch(ContributorAction.fetchContributors())
        }
        if (!this.props.networks.length) {
            AppStore.dispatch(NetworkAction.fetchNetworks())
        }
        if (!this.props.cities.length) {
            AppStore.dispatch(CityAction.fetchCities())
        }
        if (!this.props.qualityCampaigns.length) {
            AppStore.dispatch(CampaignAction.fetchQualityCampaigns())
        }
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}

    getModal = (selectedStations) => {
        const id = uuidv4()
        const actions = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
            </div>
        )
        const popup = {
            id,
            header: 'Sélectionnez les stations à exporter',
            actions,
            minHeight: '65vh',
            className: 'hugeModal',
            content: <SelectedElementsModal
                selectedList={selectedStations}
                onChange={(list) => this.onChangeFilters({ stations: list.map(p => p.id) })}
                list={this.props.qualitometers}
            />,
        }

        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    getContributorsFormatted = () => {
        const { contributors } = this.props
        return contributors.filter(c => c.siret?.length > 0).map((c) => ({
            ...c,
            nameFormatted: `${c?.siret} - ${c?.name || ''}`,
        }))
    }

    render() {
        if (this.state.dataLoaded && this.props.qualitometers.length) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const exportModes = [
                { code: 'ALL', name: 'Exporter tout l\'historique' },
                { code: 'CUSTOM', name: 'Choisir les dates' },
                { code: 'DIFF', name: 'Exporter en fonction du dernier export' },
            ]

            const selectStationsMode = [
                { code: 'FILTERS', name: 'Choisir par critères' },
                { code: 'STATIONS', name: 'Choisir les stations' },
            ]
            const selectedStations = this.props.qualitometers.filter(p => (filters.stations || []).includes(p.id))
            return (
                <div>
                    <div className='row no-margin padding-top-1 valign-wrapper'>
                        <Input col={ 2 } title={ i18n.directory } value={ this.props.job.parameters.path } onChange={ v => this.onChangeJobParameter({ path: v }) } disabled={ disabled } obligatory />
                        <Select col={2} options={exportModes} label={'Mode d\'export'} onChange={v => this.onChangeFilters({ mode: v })} value={filters.mode} disabled={disabled} obligatory />
                        <SuperMultiAutocomplete
                            label={i18n.campaign}
                            options={this.props.qualityCampaigns}
                            values={filters.campaignIds}
                            onChange={v => this.onChangeFilters({ campaignIds: v })}
                            multiple
                            col={4}
                            disabled={ disabled }
                        />
                        {
                            filters.mode === 'CUSTOM' ? [
                                <DatePicker col={ 2 } title={ i18n.startDate } value={ filters.startDate } onChange={ (_, v) => this.onChangeFilters({ startDate: v }) } disabled={ disabled } />,
                                <DatePicker col={ 2 } title={ i18n.endDate } value={ filters.endDate } onChange={ (_, v) => this.onChangeFilters({ endDate: v }) } disabled={ disabled } />,
                            ] : <div className='col s4' />
                        }
                    </div>
                    <div className='row no-margin padding-top-1 valign-wrapper'>
                        <MultiContributorsAutocomplete
                            col={4}
                            label={ i18n.sender }
                            values={ filters.siret }
                            onChange={ c => this.onChangeFilters({ siret: c }) }
                            options={ this.getContributorsFormatted() }
                            keyLabel='nameFormatted'
                            keyValue='siret'
                            disabled={ disabled }
                            obligatory
                        />
                        <MultiContributorsAutocomplete col={ 4 } options={ this.props.contributors } label={ i18n.producer } values={ filters.producer } onChange={ c => this.onChangeFilters({ producer: c }) } keyLabel='mnemonique' disabled={ disabled }/>
                        <Checkbox
                            col={4}
                            label={i18n.bruteMeasuresExport}
                            checked={filters.bruteMode}
                            onChange={c => this.onChangeFilters({ bruteMode: c })}
                            disabled={ disabled }
                        />
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <RadioButtons col={ 4 } title='Sélection des stations' obligatory elements={ selectStationsMode } selected={ filters.selectStationsMode || 'FILTERS' } onChange={ v => this.onChangeFilters({ selectStationsMode: v }) } disabled={ disabled }/>
                    </div>
                    {
                        !filters.selectStationsMode || filters.selectStationsMode === 'FILTERS' ? (
                            <div className='row no-margin padding-top-1'>
                                <Select col={ 4 } options={ this.props.networks } label={ i18n.network } value={ filters.network } onChange={ c => this.onChangeFilters({ network: c }) } disabled={ disabled }/>
                                <Select col={ 4 } options={ this.props.cities } label={ i18n.city } value={ filters.city } onChange={ c => this.onChangeFilters({ city: c }) } disabled={ disabled } keyLabel='labelWithCode'/>
                                <SimpleFilterSelect
                                    col={4}
                                    onChange={(_, f) => this.onChangeFilters({ stationsFilter: f && f !== -1 ? f : null })}
                                    stations={this.props.qualitometers}
                                    stationType={'quality'}
                                    disabled={ disabled }
                                    value= { filters.stationsFilter }
                                />
                            </div>
                        ) : (
                            <div className='row no-margin padding-top-1'>
                                <Table
                                    title={ i18n.stations }
                                    data={ selectedStations }
                                    nbPerPageLabel={ nbPerPageLabelShort }
                                    type={ { headers: ['code', 'name'] } }
                                    sortable
                                    condensed activeHeader
                                    actions={ [{ iconName: 'edit', onClick: () => this.getModal(selectedStations) }] }
                                    paging
                                />
                            </div>
                        )
                    }
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}


ExportQesoutPanelQualite.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    qualitometers: arrayOf(DtoQualitometerLight),
    contributors: arrayOf(ContributorDto),
    networks: arrayOf(NetworkDto),
    isEditMode: PropTypes.bool,
    cities: arrayOf(CityDto),
    qualityCampaigns: arrayOf(CampaignDto),
}

const mapStateToProps = store => ({
    qualitometers: store.QualityReducer.qualitometersLight,
    contributors: store.ContributorReducer.contributors,
    networks: store.NetworkReducer.networks,
    cities: store.CityReducer.cities,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
})

export default connect(mapStateToProps)(ExportQesoutPanelQualite)