'use strict'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { groupBy } from 'lodash'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'

import DtoUserLayerAssignment from '../../administration/components/user/dto/DtoUserLayerAssignment'
import UserAction from '../../administration/components/user/actions/UserAction'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import User from '../../account/dto/User'
import AppStore from '../../store/AppStore'
import { arrayOf, getMapStateToProps, getPropTypes, instanceOf } from '../../utils/StoreUtils'
import { allLayersProps } from './constants/MapLayersConstants'
import ListComponent from '../../components/list/tableList/ListComponent'
import WatershedDto from '../../referencial/components/watershed/dto/WatershedDto'
import { setAndReturnLayerOpacity } from './mapTreatments/AddingLayers'
import { Slider, Tooltip } from '@mui/material'
import Checkbox from '../forms/Checkbox'
import LayerDto from 'home/dto/LayerDto'
import { parseNowVariableURL } from '../../utils/UrlUtils'
import { getSetting, getSettingJson } from '../../utils/SettingUtils'
import SieauParameterDto from '../../administration/dto/SieauParameterDto'
import SettingDto from '../../administration/dto/SettingDto'
import { homeListOfLayersOpacity, homeListOfSelectedLayers } from 'carto/constants/CartoConstants'
import { hasValue } from 'utils/NumberUtil'

const storeProps = {
    themeLayers: false,
    cartographyThemes: false,
}

class CartographyControlPanel extends Component {
    state = {
        layersOpacity: {},
        dataLoaded: false,
        layersId: [],
    }

    componentDidMount() {
        const { settings, userThemes, cartographyThemes } = this.props
        if (!settings.length) {
            this.props.fetchSettings().then(() => {
                this.setLayersState()
            })
        } else {
            this.setLayersState()
        }
        if (!userThemes.length) {
            AppStore.dispatch(UserAction.fetchUserThemesAssignment())
        }
        if (!cartographyThemes.length) {
            AppStore.dispatch(AdministrationAction.fetchCartographyThemes())
        }
    }

    changeVisible = (layerName, id, save = false) => {
        const newValue = !(this.props.visible[layerName] || false)
        if (this.props.componentType === 'homepage') {
            const obj = { [layerName]: { enabled: newValue } }
            AppStore.dispatch(AdministrationAction.setSelectedSearchValues('dashboardCheckboxes', obj))
        }
        this.props.onChangeVisible([layerName])
        if (this.state.layersOpacity[layerName]) {
            setAndReturnLayerOpacity(layerName, this.state.layersOpacity[layerName])
            this.setState({ layersId: [
                ...this.state.layersId,
                id,
            ] }, () => save && this.saveCartographyLayers(id))
        } else if (this.props.visible[layerName]) {
            this.setState({
                layersOpacity: {
                    ...this.state.layersOpacity,
                    [layerName]: setAndReturnLayerOpacity(layerName, 0),
                },
                layersId: [
                    ...this.state.layersId,
                    id,
                ],
            }, () => save && this.saveCartographyLayers(id))
        } else if (!this.props.visible[layerName]) {
            const opacitySaved = (getSettingJson(this.props.settings, homeListOfLayersOpacity) || []).find((v) => v.layerName === layerName)
            this.setState({
                layersOpacity: {
                    ...this.state.layersOpacity,
                    [layerName]: setAndReturnLayerOpacity(layerName, opacitySaved?.opacity ? opacitySaved.opacity : 1),
                },
                layersId: [
                    ...this.state.layersId,
                    id,
                ],
            }, () => save && this.saveCartographyLayers())
        }
    }

    getLegend = (url, layerName) => {
        if (url.includes('#MYMAPS/?')) {
            const test = `${parseNowVariableURL(url, getSetting(this.props.applicationSettings, 'myMaps'))}&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&format=image/jpeg&layer=${layerName}`
            return test
        }
        const urlLower = url.toLowerCase()
        return `${url}${urlLower.indexOf('?') >= 0 ? urlLower.indexOf('version') >= 0 ? '' : 'version=1.3.0' : '?version=1.3.0'}&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&format=image/jpeg&layer=${layerName}`
    }

    getSwitch = (layer, label) => {
        const { layer: layerName, name, id } = layer
        const legend = layer.url ? this.getLegend(layer.url, layerName) : null
        const opacitySaved = (getSettingJson(this.props.settings, homeListOfLayersOpacity) || []).find((v) => v.layerName === layerName)
        return (
            <div className='switch row'>
                <label>
                    <div className='row no-margin valign-wrapper'>
                        <div className={`col s${legend ? 1 : 2}`}>
                            <div onClick={e => {
                                e.preventDefault()
                                this.changeVisible(layerName, id, true)
                            }}
                            >
                                <Checkbox
                                    type='checkbox'
                                    checked={ this.props.visible[layerName] }
                                />
                            </div>
                        </div>
                        <div className='col s8'>
                            { label }
                        </div>
                        <Slider
                            className='col s2 no-margin'
                            sliderStyle={{ margin: 0 }}
                            style={{ margin: 0 }}
                            min={0}
                            max={1}
                            step={0.1}
                            value={ this.state.layersOpacity[layerName] || (this.props.visible[layerName] ? (opacitySaved?.opacity || 1) : 0) }
                            onChange={(event, value) => {
                                this.setState({
                                    layersOpacity: {
                                        ...this.state.layersOpacity,
                                        [layerName]: setAndReturnLayerOpacity(layerName, value),
                                    },
                                })
                            }}
                            onChangeCommitted={(e, v) => this.saveLayersOpacity(layerName, v)}
                        />
                        { legend ? <div className='col s1'>
                            <Tooltip
                                placement='left-start'
                                arrow
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: 'fit-content',
                                            width: 'fit-content',
                                        },
                                    },
                                }}
                                title={
                                    <>
                                        <img src={legend} alt={layerName} />
                                    </>}
                            >
                                <div id='fancy' className='material-icons'>
                                    info_outline
                                </div>
                            </Tooltip>
                        </div> : null }
                    </div>
                    {!!layer.source && (
                        <div className='row no-margin valign-wrapper'>
                            <div className={`col s${legend ? 1 : 2}`} />
                            <div className='col s11'>
                                <i style={{ fontSize: '11px' }}>{i18n.source}: { layer.source }</i>
                            </div>
                        </div>
                    )}
                </label>
            </div>
        )
    }

    getThemeLayersSwichs = () => {
        const themeGroups = groupBy(this.props.themeLayers, 'theme')
        if (themeGroups && this.props.themeLayers.length) {
            const themeList = this.props.cartographyThemes.filter(theme =>
                (['all', 'showAll', 'alerts', 'territory'].includes(this.props.componentType) || this.props.userThemes.filter(th => th.themeId == theme.id && th[this.props.componentType] === true).length)
                && this.props.userThemes.find(th => th.themeId == theme.id && th.visibility)
            )
                .filter(theme => themeGroups[theme.id])
                .map(theme => {
                    return {
                        title: (
                            <div className='col s12' style={{ backgroundColor: theme.color }}>
                                <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                                    <div className='col s12 no-padding'>
                                        <h6>
                                            <b className='font-white'>{ theme.name }</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ),
                        component: themeGroups[theme.id].map(layer => this.getSwitch(layer, layer.name)),
                    }
                })
            return <ListComponent tables={ themeList } displayOnClick />
        }
        return null
    }

    saveCartographyLayers = (layerId) => {
        const { layersId } = this.state
        const layers = hasValue(layerId) ? layersId.filter(l => l !== layerId) : layersId
        const layersIdString = layers.join() || ''
        this.setState({ layersId: layers })
        this.props.updateSetting(homeListOfSelectedLayers, layersIdString)
    }

    saveLayersOpacity = (layerName, opacity) => {
        const opacitySettings = getSettingJson(this.props.settings, homeListOfLayersOpacity) || []
        const layersOpacityJson = JSON.stringify([
            ...opacitySettings.filter((s) => s.layerName !== layerName),
            { layerName, opacity },
        ])
        this.props.updateSetting(homeListOfLayersOpacity, layersOpacityJson)
    }

    getSelectedLayersParameter = () => {
        const { settings } = this.props
        const selectedLayersParameter = settings.find(s => s.parameter === homeListOfSelectedLayers)?.value
        return selectedLayersParameter
    }

    getCartographyLayers = () => {
        const selectedLayersParameter = this.getSelectedLayersParameter()
        if (selectedLayersParameter?.length) {
            return selectedLayersParameter
        }
        return ''
    }

    setLayersState = () => {
        const stringLayers = this.getCartographyLayers()
        const layers = stringLayers.length > 0 ? stringLayers.split(',').map(item => parseInt(item, 10)) : []
        const obj = layers.reduce((a, v) => {
            const layerName = this.props.themeLayers.find(l => l && l.id === parseInt(v))?.layer
            const opacitySaved = (getSettingJson(this.props.settings, homeListOfLayersOpacity) || []).find((s) => s.layerName === layerName)
            return ({ ...a, [layerName]: setAndReturnLayerOpacity(layerName, opacitySaved?.opacity ? opacitySaved.opacity : 1) })
        }, {})
        this.setState({ layersOpacity: obj, dataLoaded: true, layersId: layers })
    }

    getBaseLayers = () => {
        const { layers, exists } = this.props
        return layers
            .filter(layer => exists[layer] && !['STATIONS_POINTS', 'STATION'].includes(layer))
            .map(layer => this.getSwitch(new LayerDto({ layer }), allLayersProps(this.props)[layer].name))
    }

    render() {
        const { dataLoaded } = this.state
        if (dataLoaded) {
            const baseLayers = this.getBaseLayers()
            const themeLayers = this.props.withThemeLayers ? this.getThemeLayersSwichs() : null
            return (
                <div>
                    <div className='card'>
                        <div className='card-title activator'>
                            { i18n.mapLayers }
                        </div>
                        <div className='card-content padding-left-0'>
                            { baseLayers }
                        </div>
                    </div>
                    { themeLayers }
                </div>

            )
        }
        return null
    }
}

CartographyControlPanel.propTypes = getPropTypes(storeProps, {
    station: PropTypes.object,
    componentType: PropTypes.string.isRequired,
    visible: PropTypes.shape({
        DISTRIBUTION_UNIT: PropTypes.bool,
        HYDROMETRIC_STATION: PropTypes.bool,
        INSTALLATION: PropTypes.bool,
        PIEZOMETER: PropTypes.bool,
        PLUVIOMETER: PropTypes.bool,
        PRODUCTION_UNIT: PropTypes.bool,
        QUALITOMETER: PropTypes.bool,
        installationType0: PropTypes.bool,
        installationType1: PropTypes.bool,
        installationType2: PropTypes.bool,
        installationType3: PropTypes.bool,
        installationType4: PropTypes.bool,
        installationType5: PropTypes.bool,
        installationType6: PropTypes.bool,
        installationType7: PropTypes.bool,
        installationType8: PropTypes.bool,
        installationType9: PropTypes.bool,
        installationType10: PropTypes.bool,
        installationType11: PropTypes.bool,
        installationType12: PropTypes.bool,
        installationType13: PropTypes.bool,
        installationType14: PropTypes.bool,
        installationType16: PropTypes.bool,
        installationType17: PropTypes.bool,
        installationType18: PropTypes.bool,
        installationType20: PropTypes.bool,
        'installationType-1': PropTypes.bool,
        qualitometerType0: PropTypes.bool,
        qualitometerType1: PropTypes.bool,
        qualitometerType2: PropTypes.bool,
        qualitometerType3: PropTypes.bool,
        qualitometerType4: PropTypes.bool,
        qualitometerType5: PropTypes.bool,
        qualitometerType6: PropTypes.bool,
        qualitometerType7: PropTypes.bool,
        qualitometerType8: PropTypes.bool,
    }),
    exists: PropTypes.object,
    onChangeStationKMLExists: PropTypes.func,
    onChangeVisible: PropTypes.func,
    oneThemeLayer: PropTypes.object,
    watermass: PropTypes.string,
    layers: PropTypes.arrayOf(PropTypes.object),
    withThemeLayers: PropTypes.bool,
    stationWatershed: PropTypes.instanceOf(WatershedDto),
    watershed1: instanceOf(WatershedDto),
    watershed2: instanceOf(WatershedDto),
    watershed3: instanceOf(WatershedDto),
    watershed4: instanceOf(WatershedDto),
    userThemes: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserLayerAssignment)),
    accountUser: PropTypes.instanceOf(User),
    applicationSettings: arrayOf(SieauParameterDto),
    fetchSettings: PropTypes.func,
    updateSetting: PropTypes.func,
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    stationWatershed: store.WatershedReducer.stationWatershed,
    userThemes: store.UserReducer.userThemes,
    accountUser: store.AccountReducer.accountUser,
    watershed1: store.WatershedReducer.watershed1,
    watershed2: store.WatershedReducer.watershed2,
    watershed3: store.WatershedReducer.watershed3,
    watershed4: store.WatershedReducer.watershed4,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    settings: store.AdministrationReducer.settings,
})

const mapDispatchToProps = {
    fetchSettings: AdministrationAction.fetchSettings,
    updateSetting: AdministrationAction.updateSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartographyControlPanel)
