import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import SelectionTable from 'components/datatable/SelectionTable'
import React, { useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { difference, isNil } from 'lodash'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import useListIndexed from 'utils/customHook/useListIndexed'
import Input from 'components/forms/Input'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import { shallowEqual, useSelector } from 'react-redux'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import Select from 'components/forms/Select'
import useDebounce from 'utils/customHook/useDebounce'
import { SELECTION } from 'quality/constants/QualityConstants'
import { searchAllCharacters } from 'utils/StringUtil'

const SimpleSelectionTable = ({
    onChange = () => { },

    listData = [],
    selectedList = [],

    listHeaders = [],
    listTitle = '',
    selectedListHeaders,
    selectedListTitle = '',
    maxHeightTable = '55vh',

    filterField,
    filterFunction = list => list,
    defaultFilter = {},
}) => {
    const [filter, setFilter] = useState(defaultFilter)

    const listDataIndexed = useListIndexed(listData)

    const addAll = listElem => {
        const listId = listElem.map(({ id }) => id)
        onChange([...selectedList, ...listId])
    }

    const deleteAll = () => {
        onChange([])
    }

    const onAdd = elem => {
        onChange([...selectedList, elem.id])
    }

    const onDelete = elem => {
        onChange(selectedList.filter(id => id !== elem.id))
    }

    const unselectedListFormated = useMemo(() => {
        const unselectedList = difference(listData.map(({ id }) => id), selectedList)
        return unselectedList.map(id => listDataIndexed[id])
    }, [listData, listDataIndexed, selectedList])
    const unselectedListFiltered = useMemo(() => filterFunction(unselectedListFormated, filter), [filter, unselectedListFormated])
    const selectedListFormated = useMemo(() => selectedList.map(id => listDataIndexed[id]), [listDataIndexed, selectedList])

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12}>
                {
                    !isNil(filterField) && (
                        <StyledFieldSet>
                            <StyledLegend>{i18n.filter}</StyledLegend>
                            {filterField({ filter, setFilter })}
                        </StyledFieldSet>
                    )
                }
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '10px' }}>
                <SelectionTable
                    maxHeight={maxHeightTable}

                    listData={unselectedListFiltered}
                    listHeaders={listHeaders}
                    listTitle={listTitle}

                    selectedData={selectedListFormated}
                    selectedHeaders={selectedListHeaders || listHeaders}
                    selectedTitle={selectedListTitle}

                    onAdd={onAdd}
                    addAll={addAll}
                    onDelete={onDelete}
                    deleteAll={deleteAll}
                />
            </Grid>
        </Grid>
    )
}

SimpleSelectionTable.propTypes = {
    onChange: PropTypes.func.isRequired,

    listData: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            // every key from listHeaders and selectedListHeaders should exist in this object
        })),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            // every key from listHeaders and selectedListHeaders should exist in this object
        })),
    ]).isRequired,
    selectedList: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,

    listHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
    listTitle: PropTypes.string.isRequired,
    selectedListHeaders: PropTypes.arrayOf(PropTypes.string),
    selectedListTitle: PropTypes.string.isRequired,
    maxHeightTable: PropTypes.string,

    filterField: PropTypes.func, // can be a function or a component, filter and setFilter are passed in props
    filterFunction: PropTypes.func, // (list, filter) => return filteredList
    defaultFilter: PropTypes.shape({}),
}

const SimpleFilterFunction = (list, { searchValue = '' }) => {
    const searchValueFormat = searchAllCharacters(searchValue)
    return searchValue ? list.filter(p => p.labelSearch.includes(searchValueFormat)) : list
}

const SimpleFilterField = ({
    filter = {},
    setFilter = () => { },
}) => {
    const [stateFilter, setStateFilter] = useState(filter)

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
            <Grid item xs={6}>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid>
        </Grid>
    )
}

SimpleFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
    }),
    setFilter: PropTypes.func,
}

const ParameterFilterField = ({
    filter = {},
    setFilter = () => { },
}) => {
    const [stateFilter, setStateFilter] = useState(filter)

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
            <Grid item xs={6}>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectionSelect
                    value={stateFilter.selection}
                    type={SELECTION.PC}
                    onChange={(listParam, selection) => setStateFilter(prev => ({ ...prev, selection, listParam }))}
                />
            </Grid>
        </Grid>
    )
}

ParameterFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        selection: PropTypes.string,
        listParam: PropTypes.arrayOf(PropTypes.string),
    }),
    setFilter: PropTypes.func,
}

const TaxonFilterField = ({
    filter = {},
    setFilter = () => { },
}) => {
    const [stateFilter, setStateFilter] = useState(filter)

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
            <Grid item xs={6}>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectionSelect
                    value={stateFilter.selection}
                    type={SELECTION.TAXON}
                    onChange={(listTaxon, selection) => setStateFilter(prev => ({ ...prev, selection, listTaxon }))}
                />
            </Grid>
        </Grid>
    )
}

TaxonFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        selection: PropTypes.string,
        listTaxon: PropTypes.arrayOf(PropTypes.string),
    }),
    setFilter: PropTypes.func,
}

const QualitometerFilterField = ({
    filter = {},
    setFilter = () => { },
}) => {
    const {
        qualitometers,
        cities,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [stateFilter, setStateFilter] = useState(filter)

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid container columnSpacing={2} rowSpacing={1} style={{ paddingTop: '5px' }}>
            <Grid item xs={6}>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleFilterSelect
                    stationType={STATION_TYPE_NAME.quality}
                    onChange={(resultFilter, newFilter) => {
                        setStateFilter(prev => ({ ...prev, resultFilter, filter: newFilter }))
                    }}
                    stations={qualitometers}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    label={i18n.city}
                    value={stateFilter.city}
                    options={cities}
                    onChange={city => setStateFilter(prev => ({ ...prev, city }))}
                    keyLabel='labelWithCode'
                />
            </Grid>
        </Grid>
    )
}

QualitometerFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        resultFilter: PropTypes.arrayOf(PropTypes.number),
        filter: PropTypes.number,
        city: PropTypes.string,
    }),
    setFilter: PropTypes.func,
}

const PiezometerFilterField = ({
    filter = {},
    setFilter = () => { },
}) => {
    const {
        piezometers,
        cities,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [stateFilter, setStateFilter] = useState(filter)

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid container columnSpacing={2} rowSpacing={1} style={{ paddingTop: '5px' }}>
            <Grid item xs={6}>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleFilterSelect
                    stationType={STATION_TYPE_NAME.piezometry}
                    onChange={(resultFilter, newFilter) => {
                        setStateFilter(prev => ({ ...prev, resultFilter, filter: newFilter }))
                    }}
                    stations={piezometers}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    label={i18n.city}
                    value={stateFilter.city}
                    options={cities}
                    onChange={city => setStateFilter(prev => ({ ...prev, city }))}
                    keyLabel='labelWithCode'
                />
            </Grid>
        </Grid>
    )
}

PiezometerFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        resultFilter: PropTypes.arrayOf(PropTypes.number),
        filter: PropTypes.number,
        city: PropTypes.string,
    }),
    setFilter: PropTypes.func,
}

export default SimpleSelectionTable
export {
    SimpleFilterFunction,
    SimpleFilterField,

    ParameterFilterField,
    TaxonFilterField,
    QualitometerFilterField,
    PiezometerFilterField,
}