import { userActions } from 'administration/components/user/reducers/UserReducer'
import ApplicationConf from 'conf/ApplicationConf'
import { chunk, flatten, uniqBy } from 'lodash'
import DtoPluviometerStats from 'pluviometry/dto/DtoPluviometerStats'
import { PluviometryActionConstant } from 'pluviometry/reducers/PluviometryReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import CampaignAction from '../../campaign/actions/CampaignAction'
import LogAction from '../../log/actions/LogAction'
import QualityAction from '../../quality/actions/QualityAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import NetworkAction from '../../referencial/components/network/actions/NetworkAction'
import StationAction from '../../station/actions/StationAction'
import AppStore from '../../store/AppStore'
import {
    checkAuth,
    checkError,
    genericPromise,
    getAbortSignal,
    getAuthorization,
    getJson,
    promiseAllProgress,
    promiseAllWithCancel,
} from '../../utils/ActionUtils'
import DtoPluvioMeasureLight from '../dto/measures/DtoPluvioMeasureLight'
import DtoPluvioMeasures from '../dto/measures/DtoPluvioMeasures'
import DtoPluvioMeasureValidation from '../dto/measures/DtoPluvioMeasureValidation'
import DtoPluvioMeasure from '../dto/measures/DtoPluvioMeasure'

const PluviometryAction = {

    promisePluviometers() {
        return fetch(ApplicationConf.pluviometry.pluviometers(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometers() {
        return (dispatch) => {
            return PluviometryAction.promisePluviometers()
                .then((json = []) => {
                    dispatch(PluviometryActionConstant.receivePluviometers(json))
                    dispatch(UserAction.fetchBookmarks())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pluviometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pluviometers))
                })
        }
    },

    promisePluviometersWithCampaignsAndEvents() {
        return fetch(ApplicationConf.pluviometry.pluviometersWithCampaignsAndEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometersWithCampaignsAndEvents() {
        return (dispatch) => {
            return PluviometryAction.promisePluviometersWithCampaignsAndEvents()
                .then((json = []) => {
                    dispatch(PluviometryActionConstant.receivePluviometersWithCampaignsAndEvents(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pluviometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pluviometers))
                })
        }
    },

    promisePluviometer(id) {
        return fetch(ApplicationConf.pluviometry.pluviometer(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometer(id) {
        return (dispatch) => PluviometryAction.promisePluviometer(id)
            .then((json = {}) => {
                dispatch(PluviometryActionConstant.receivePluviometer(json))
                if (json.id) {
                    dispatch(PluviometryAction.fetchPluviometerNormales(json.id))
                }
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.pluviometer} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.pluviometer))
            })
    },
    createPluviometer(newPluviometer, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.pluviometry.pluviometers(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newPluviometer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(PluviometryActionConstant.createPluviometer({ ...newPluviometer, id: json.id }))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.installation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.installation))
                })
        }
    },
    updatePluviometer: pluviometer => dispatch => {
        return fetch(ApplicationConf.pluviometry.pluviometers(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(pluviometer),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(PluviometryAction.fetchPluviometer(pluviometer.id))
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.pluviometer} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.pluviometer))
            })
    },

    updateMeasures: (list) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return genericPromise(ApplicationConf.pluviometry.chronicMeasures(), 'PUT', list).then(result => {
                if (result?.update) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                } else {
                    dispatch(ToastrAction.error(`${i18n.updateError} ${i18n.measures}`))
                }
                return result
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.errorChangeData} ${i18n.measures} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.errorChangeData} ${i18n.measures}`))
            }).finally(() => {
                dispatch(WaitAction.waitStop())
            })
        }
    },

    promisePluviometerMeasuresStats(id) {
        return fetch(ApplicationConf.pluviometry.measuresStats(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometerMeasuresStats(id) {
        return (dispatch) => PluviometryAction.promisePluviometerMeasuresStats(id)
            .then((json = []) => {
                dispatch(PluviometryActionConstant.receivePluviometerStatistics(json))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
            })
    },

    fetchSeveralPluvioStatistics(ids, progressCallback = () => {}) {
        return (dispatch) => {
            const promises = ids.map(id => PluviometryAction.promisePluviometerMeasuresStats(id))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                return uniqBy(flatten(jsonResults), 'typeId').map(p => new DtoPluviometerStats(p))
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },

    promisePluviometerLastMeasures(pluviometerIds) {
        return fetch(ApplicationConf.pluviometry.lastMeasures(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(pluviometerIds),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometerLastMeasures(pluviometerIds) {
        return (dispatch) => PluviometryAction.promisePluviometerLastMeasures(pluviometerIds)
            .then((json = []) => {
                dispatch(PluviometryActionConstant.receivePluviometerLastMeasures(json))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
            })
    },

    promisePluvioSituationLastMeasures(ids) {
        return genericPromise(ApplicationConf.pluviometry.situationLastMeasures(), 'POST', { ids })
    },
    fetchPluvioSituationLastMeasures(ids) {
        return (dispatch) => {
            return PluviometryAction.promisePluvioSituationLastMeasures(ids)
                .then(json => {
                    dispatch(PluviometryActionConstant.receivePluvioSituationLastMeasures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promisePluvioChronicMeasures(input) {
        return genericPromise(ApplicationConf.pluviometry.chartMeasures(), 'POST', input)
            .catch((err) => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
            })
    },
    promisePluvioChronicMeasuresLight(input) {
        return genericPromise(ApplicationConf.pluviometry.chartMeasures(), 'POST', input)
            .then(json => json.map(j => new DtoPluvioMeasureLight(j)))
            .catch((err) => {
                AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
            })
    },
    loadPluvioChronicMeasures(
        listOfInputs, // liste d'objets correspondants à PluvioMeasuresInput dans le service pluvio
        progressCallback = () => {}) {
        return (dispatch) => {
            const promises = listOfInputs.map(input => PluviometryAction.promisePluvioChronicMeasures(input))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                const data = jsonResults.map((json, idx) => {
                    return new DtoPluvioMeasures({ ...listOfInputs[idx], measures: json.map(j => new DtoPluvioMeasureLight(j)) })
                })
                dispatch(PluviometryActionConstant.receivePluvioChronicMeasures(data))
                return data
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },

    loadPluvioChartChronicMeasures(
        listOfInputs, // liste d'objets correspondants à PluvioMeasuresInput dans le service pluvio
        progressCallback = () => {}) {
        return (dispatch) => {
            const promises = listOfInputs.map(input => PluviometryAction.promisePluvioChronicMeasures(input))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                const data = jsonResults.map((json, idx) => {
                    return new DtoPluvioMeasures({ ...listOfInputs[idx], measures: json.map(j => new DtoPluvioMeasure(j)) })
                })
                dispatch(PluviometryActionConstant.receivePluvioChronicMeasures(data))
                return data
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },

    loadPluvioChronicMeasuresValidation(
        listOfInputs, // liste d'objets correspondants à PluvioMeasuresInput dans le service pluvio
        progressCallback = () => {}) {
        return (dispatch) => {
            const promises = listOfInputs.map(input => PluviometryAction.promisePluvioChronicMeasures(input))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                const data = jsonResults.map((json, idx) => {
                    return new DtoPluvioMeasures({ ...listOfInputs[idx], measures: json.map(j => new DtoPluvioMeasureValidation(j)) })
                })
                return dispatch(PluviometryActionConstant.receivePluvioChronicMeasures(data))
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },

    getPluvioChronicMeasures: dataInput => dispatch => PluviometryAction.promisePluvioChronicMeasures(dataInput)
        .then(json => {
            return new DtoPluvioMeasures({ ...dataInput, measures: json.map(j => new DtoPluvioMeasureLight(j)) })
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
            dispatch(ToastrAction.error(i18n.measures))
        }),

    promisePluviometerNormales(id) {
        return fetch(ApplicationConf.pluviometry.normale(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometerNormales(id) {
        return (dispatch) => PluviometryAction.promisePluviometerNormales(id)
            .then((json = []) => {
                dispatch(PluviometryActionConstant.receiveNormales(json))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.normal} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.normal))
            })
    },
    updatePluviometerNormales: (id, normales) => dispatch => {
        return fetch(ApplicationConf.pluviometry.normale(id), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(normales),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(PluviometryAction.fetchPluviometerNormales(id))
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.normal} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.normal))
            })
    },

    promisePluviometerThresholds(id) {
        return fetch(ApplicationConf.pluviometry.threshold(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometerThresholds(id) {
        return (dispatch) => PluviometryAction.promisePluviometerThresholds(id)
            .then((json = []) => {
                dispatch(PluviometryActionConstant.receivePluviometerThresholds(json))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.threshold} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.threshold))
            })
    },

    promisePluviometerAllThresholds() {
        return fetch(ApplicationConf.pluviometry.thresholds(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometerAllThresholds() {
        return (dispatch) => PluviometryAction.promisePluviometerAllThresholds()
            .then((json = []) => {
                dispatch(PluviometryActionConstant.receiveAllPluviometerThresholds(json))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.threshold} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.threshold))
            })
    },

    fetchKeyFigures() {
        return (dispatch) => {
            return fetch(ApplicationConf.pluviometry.keyFigure(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(PluviometryActionConstant.receivePluviometerKeyFigures(json))
                })
        }
    },

    deletePluviometer(id) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.pluviometry.pluviometer(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.pluviometry} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.pluviometry))
                })
        }
    },

    promisePluviometryDataTypes() {
        return genericPromise(ApplicationConf.pluviometry.dataTypes())
    },

    fetchPluviometryDataTypes() {
        return (dispatch) =>
            PluviometryAction.promisePluviometryDataTypes()
                .then((json = []) => {
                    dispatch(PluviometryActionConstant.receivePluviometryDataTypes(json))
                })
    },

    promisePluviometrySituation(criterias, stations, type) {
        return fetch(ApplicationConf.pluviometry.situation(type), {
            method: 'POST',
            headers: getAuthorization(),
            signal: getAbortSignal(),
            body: JSON.stringify({ ...criterias, pluvioIds: stations }),
        }).then(checkAuth).then(getJson).then(checkError).catch(err => {
            if (err.name !== 'AbortError') {
                AppStore.dispatch(ToastrAction.error(i18n.dataError))
                AppStore.dispatch(LogAction.logError(`${i18n.dataError}: ${err}`))
            }
            return []
        })
    },
    fetchPluviometryTypeSituationWithDays(criterias, pluviometersIds, progressCallback = () => {}) {
        return (dispatch) => {
            const groupsSize = pluviometersIds.length / 50 > 20 ? pluviometersIds.length / 20 : 50
            const promises = chunk(pluviometersIds, groupsSize).map(stationsGroup => PluviometryAction.promisePluviometrySituation(criterias, stationsGroup, 'type'))
            const allPromises = [...promises, PluviometryAction.promisePluviometrySituation(criterias, pluviometersIds, 'days')]
            const count = allPromises.length
            return promiseAllWithCancel(allPromises, progressCallback, dispatch)
                .then(json => {
                    if (json.length === count) {
                        const days = json.pop()
                        dispatch(PluviometryActionConstant.receivePluviometerSituations(flatten(json)))
                        dispatch(PluviometryActionConstant.receivePluviometryDaysSituation(flatten(days)))
                    } else {
                        dispatch(PluviometryActionConstant.receivePluviometerSituations([]))
                    }
                })
        }
    },
    loadPluviometrySituation: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        PluviometryAction.promisePluviometers(),
        StationAction.promiseFilters(),
        UserAction.promiseBookmarks(),
        ContributorAction.promiseContributors(),
        ContactAction.promiseContacts(),
        CampaignAction.promiseCampaigns('pluviometry'),
        CampaignAction.promiseCampaignsProgress('pluviometry'),
        CampaignAction.promiseCampaignStations('pluviometry'),
        CityAction.promiseCities(),
        NetworkAction.promiseNetworks(),
        ReferencialAction.promiseSandreCodes(),
        QualityAction.promiseStatus(),
        PluviometryAction.promiseNetworkPluvioLink(),
        PluviometryAction.promisePluviometersContributors(),
    ], progressCallback).then(jsonTab => {
        dispatch(PluviometryActionConstant.receivePluviometers(jsonTab[0]))
        dispatch(StationAction.receiveFilters(jsonTab[1]))
        dispatch(userActions.receiveAllBookmarks(jsonTab[2]))
        dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[3]))
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[4]))
        dispatch(CampaignAction.receivePluviometryCampaigns(jsonTab[5]))
        dispatch(CampaignAction.receivePluviometryCampaignsProgress(jsonTab[6]))
        dispatch(CampaignAction.receivePluviometryCampaignStations(jsonTab[7]))
        dispatch(CityAction.receiveCities(jsonTab[8]))
        dispatch(NetworkAction.receiveNetworks(jsonTab[9]))
        dispatch(ReferencialAction.receiveSandreCodes(jsonTab[10]))
        dispatch(QualityActionConstant.receiveStatus(jsonTab[11]))
        dispatch(PluviometryActionConstant.receiveNetworkPluvioLink(jsonTab[12]))
        dispatch(PluviometryActionConstant.receiveAllPluviometersContributors(jsonTab[13]))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    promiseNetworkPluvioLink() {
        return genericPromise(ApplicationConf.pluviometry.getAllNetworkPluvioLink())
    },
    fetchNetworkPluvioLink() {
        return dispatch => {
            return PluviometryAction.promiseNetworkPluvioLink().then((json = []) => {
                dispatch(PluviometryActionConstant.receiveNetworkPluvioLink(json))
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.NetworkPluvioLink} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.NetworkPluvioLink))
            })
        }
    },

    promisePluviometersContributors() {
        return fetch(ApplicationConf.pluviometry.getAllContributorLinks(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPluviometersContributors() {
        return dispatch => {
            return PluviometryAction.promisePluviometersContributors()
                .then((json = []) => {
                    dispatch(PluviometryActionConstant.receiveAllPluviometersContributors(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributors))
                })
        }
    },

}

export default PluviometryAction
