import { push } from 'connected-react-router'
import { omit, orderBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ActionComponent from '../../../components/ActionComponent'
import Table from '../../../components/datatable/Table'
import QualityAction from '../../../quality/actions/QualityAction'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../utils/DateUtil'
import { getUser } from '../../../utils/SettingUtils'
import { statusIcon } from '../../../utils/StatusUtil'
import { getLabel, getMapStateToProps, getPropType, getPropTypes } from '../../../utils/StoreUtils'
import { STATION_QUALITOMETER_NAMES } from '../../constants/StationConstants'

const propsToFetch = {
    situationOperations: false,
    qualifications: false,
    status: false,
    contributors: false,
}

const tableHeaders = ['nullValue', 'code', 'name', 'stationType', 'date', 'producer', 'laboratory']

const exportHeaders = ['qualification', 'code', 'name', 'stationType', 'date', 'producer', 'laboratory', 'status']

class StationsDashboard extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = { dataLoaded: false }
    }

    componentWillMount() {
        AppStore.dispatch(QualityAction.loadSampleTable(() => this.setState({ dataLoaded: true })))
    }

    getData = () => {
        const stationIds = this.props.stations.map(s => s.id)
        const filteredOperations = this.props.situationOperations.filter(o => stationIds.includes(parseInt(o.codeQualitometer)))
        return orderBy(filteredOperations, 'dateBegin', ['desc']).map(op => {
            const station = this.props.stations.find(q => q.id == op.codeQualitometer)
            return {
                nullValue: { value: statusIcon({ ...op, status: op.status || op.state }, 18, true) },
                qualification: getLabel(this.props.qualifications, op.qualification),
                code: station.code,
                name: station.name,
                date: { value: getDate(op.dateBegin) },
                producer: { value: getLabel(this.props.contributors, op.producer, 'mnemonique') },
                status: { value: getLabel(this.props.status, op.status || op.state) },
                idStation: op.codeQualitometer,
                stationType: { value: getLabel(STATION_QUALITOMETER_NAMES, station.stationType) },
                laboratory: { value: getLabel(this.props.contributors, op.laboratoryId) },
                idOperation: op.id,
            }
        })
    }

    render() {
        if (this.state.dataLoaded) {
            return (<Table title={ i18n.nbSample } data={ this.getData() } paging color condensed sortable
                nbPerPageLabel={ nbPerPageLabel } type={ { headers: tableHeaders } }
                onClick={ (e) => AppStore.dispatch(push(`/station/quality/${e.idStation}/operation/${e.idOperation}`)) }
            />)
        }
        return null
    }

    componentDidUpdate(_, nextState) {
        if (this.state.dataLoaded !== nextState.dataLoaded) {
            const actions = {
                export: () => {
                    return {
                        data: this.getData().map(d => Object.assign({}, d, { headers: exportHeaders })),
                        exportType: 'xlsx',
                        titleFile: i18n.samples,
                    }
                },
                new: () => {
                    AppStore.dispatch(push('/station/quality/new/description'))
                },
            }
            if (getUser().consultant === '1') {
                this.setActions(omit(actions, ['new']))
            } else {
                this.setActions(actions)
            }
        }
    }
}

StationsDashboard.propTypes = getPropTypes(propsToFetch, {
    stations: getPropType('qualitometers'),
})


const mapStateToProps = () => getMapStateToProps(propsToFetch)

export default connect(mapStateToProps)(StationsDashboard)