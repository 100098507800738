import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { flatten, groupBy, isEqual, isUndefined, maxBy, orderBy, pick, take, template, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { v4 as uuidv4 } from 'uuid'
import { H_PIEZO_INTEGRATION } from '../../../../account/constants/AccessRulesConstants'
import AdministrationAction from '../../../../administration/actions/AdministrationAction'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import DtoStationCampaign from '../../../../campaign/dto/DtoStationCampaign'
import ActionComponent from '../../../../components/ActionComponent'
import Card from '../../../../components/card/Card'
import MessageCard from '../../../../components/card/MessageCard'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import { getSortedTableData } from '../../../../components/datatable/TableUtils'
import Axis from '../../../../components/echart/Axis'
import EChart from '../../../../components/echart/EChart'
import { yAutomaticScale } from '../../../../components/echart/EChartUtils'
import Line from '../../../../components/echart/series/Line'
import Button from '../../../../components/forms/Button'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import EventsAction from '../../../../events/actions/EventsAction'
import DtoEventPiezometer from '../../../../events/dto/DtoEventPiezometer'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { nbPerPageLabel, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import DtoContributor from '../../../../station/dto/DtoContributor'
import DtoMeasureMethod from '../../../../station/dto/DtoMeasureMethod'
import { getEventsClosureModal } from '../../../../utils/CampaignUtil'
import { enumerateBetweenDates, getDate, getDateWithHourString, getFullDate } from '../../../../utils/DateUtil'
import { onChangeDate, setModal, sieauTooltip } from '../../../../utils/FormUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { hasValue, round } from '../../../../utils/NumberUtil'
import {
    getControlAlert,
    getHardPiezoDataTypes,
    getMeasureModePeriodicityIcon,
} from '../../../../utils/PiezometryUtils'
import { statusIcon } from '../../../../utils/StatusUtil'
import { arrayOf, getLabel, getObjectLabel, objectOf } from '../../../../utils/StoreUtils'
import { getSandreComment, getSandreLabel, i18nize, searchAllCharacters } from '../../../../utils/StringUtil'
import PiezometryAction from '../../../actions/PiezometryAction'
import { MEASURE_COTE } from '../../../constants/PiezometryConstants'
import DtoParametrageDataType from '../../../dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../dto/DtoPiezometerLight'
import DtoPiezometerReferent from '../../../dto/DtoPiezometerRefrent'
import NetworkPiezoLinkDto from '../../../dto/NetworkPiezoLinkDto'
import DtoPiezometrySituations from '../../../dto/situation/DtoPiezometrySituations'
import DtoPiezoSituationDays from '../../../dto/situation/DtoPiezoSituationDays'
import EventFormModal from '../../event/EventFormModal'
import PiezometryAddToCampaignModal from './PiezometryAddToCampaignModal'
import PiezometryNewCampaignModal from './PiezometryNewCampaignModal'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import Autocomplete from 'components/forms/Autocomplete'
import SimpleFilterSelect from '../../../../components/forms/specific/SimpleFilterSelect'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import useBoolean from 'utils/customHook/useBoolean'
import CardToMuchData from 'components/card/CardToMuchData'
import UserDto from '../../../../administration/components/user/dto/UserDto'
import StationAction from '../../../../station/actions/StationAction'
import DtoUserBookmark from '../../../../administration/components/user/dto/DtoUserBookmark'
import { getBookmarksIds } from '../../../../utils/UserUtil'
import { getUser } from 'utils/SettingUtils'
import { EVENT_TYPES } from 'events/constants/EventsConstants'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'

const tableHeaders = ['icon', 'code', 'name', 'city', 'startDate', 'date', 'value', 'measuresCount', 'measureMode', 'toExport', 'nullValue', 'nullValue3', 'nullValue2']
const tableHeadersRestriction = ['code', 'name', 'city', 'startDate', 'date', 'value', 'measuresCount', 'measureMode', 'toExport', 'nullValue', 'nullValue3']
const exportHeaders = ['code', 'name', 'city', 'startDate', 'date', 'value', 'measuresCount', 'measureMode', 'toExport']

const LIST = 'list'
const DAYS = 'days'
const MAP = 'map'

const MIN_DISPLAY_NUMBER = 1000

const LegendPanel = ({
    filter = {},
}) => (
    <div>
        <span><span className={'green darken-1 arrests-level-panel green-text'}>__</span>{template(i18n.infDaysData)({ days: filter.nbDaysUndetected })}</span>
        <br />
        <span><span className={'yellow darken-1 arrests-level-panel yellow-text'}>__</span>{template(i18n.infBetweenDaysData)({ days1: filter.nbDaysUndetected, days2: filter.nbDaysAlert })}</span>
        <br />
        <span><span className={'red darken-1 arrests-level-panel red-text'}>__</span>{template(i18n.supDaysData)({ days: filter.nbDaysAlert })}</span>
    </div>
)

LegendPanel.propTypes = {
    filter: PropTypes.shape({}),
}

const EventIcon = ({
    events = [],
    label,
    icon,
    fetchAllEvents = () => {},
}) => {
    return !!events.length && (
        <Icon
            icon={icon}
            className='blue-text'
            tooltip={label}
            onClick={() => getEventsClosureModal(events, `${i18n.events} "${label}"`, fetchAllEvents)}
        />
    )
}

EventIcon.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventPiezometer)),
    label: PropTypes.string,
    icon: PropTypes.string,
    fetchAllEvents: PropTypes.func,
}

const CampaignIcon = ({
    stationId,
}) => {
    const {
        piezometryCampaignStations,
        piezometryCampaigns,
    } = useSelector(store => ({
        piezometryCampaignStations: store.PiezometryReducer.piezometryCampaignStations,
        piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    }))
    const campaignStations = piezometryCampaignStations.filter(p => p.stationId === stationId)
    return !!campaignStations.length && (
        <Icon
            icon='nature_people'
            tooltip={campaignStations.map(p => getLabel(piezometryCampaigns, p.campaignId, 'name', 'id')).join('\n')}
            clickable={false}
        />
    )
}

CampaignIcon.propTypes = {
    stationId: PropTypes.number,
}

const Form = ({
    defaultFilter = {},
    coloredStations = [],

    onValidate = () => {},

    displayMode = LIST,
    setDisplayMode = () => {},

    displayCardToMuchData = false,
    nbAllStation = 0,
    forceDisplay = () => { },
}) => {
    const {
        piezometers,
        piezometryCampaigns,
        piezometryDataTypes,
        piezometersReferents,
        networkPiezoLink,
        networks,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        piezometersReferents: store.PiezometryReducer.piezometersReferents,
        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
        networks: store.NetworkReducer.networks,
    }), shallowEqual)

    const uniqReferents = useMemo(() => uniqBy(piezometersReferents, 'code'), [piezometersReferents])

    const [date, setDate] = useState(defaultFilter.date)
    const [nbDays, setNbDays] = useState(defaultFilter.nbDays)
    const [searchValue, setSearchValue] = useState(defaultFilter.searchValue)
    const [filter, setFilter] = useState(defaultFilter.filter)
    const [filterResults, setFilterResults] = useState(defaultFilter.filterResults)
    const [campaign, setCampaign] = useState(defaultFilter.campaign)
    const [network, setNetwork] = useState(defaultFilter.network)
    const [dataType, setDataType] = useState(defaultFilter.dataType)
    const [referentId, setReferentId] = useState(defaultFilter.referentId)
    const [nbDaysUndetected, setNbDaysUndetected] = useState(defaultFilter.nbDaysUndetected)
    const [nbDaysAlert, setNbDaysAlert] = useState(defaultFilter.nbDaysAlert)
    const [color, setColor] = useState(defaultFilter.color)
    const [lastMeasureBrute, setLastMeasureBrute] = useState(defaultFilter.lastMeasureBrute)
    const [eventTypes, setEventTypes] = useState(defaultFilter.eventTypes)
    const { value: checkControlErrors, setValue: setCheckControlErrors } = useBoolean(defaultFilter.checkControlErrors)

    const networkList = useMemo(() => {
        const networkGroup = groupBy(networkPiezoLink, 'idNetwork')
        return Object.keys(networkGroup).map(nId => networks.find(n => n.id == nId)).filter(n => !!n)
    }, [networkPiezoLink, networks])

    const dataTypes = piezometryDataTypes.map(t => ({ code: t.id, name: t.label, unit: t.unit, order: t.order }))
    const colorStats = (!coloredStations.length) ? null : {
        red: coloredStations.filter(s => s.date.classNameColor === 'red').length,
        orange: coloredStations.filter(s => s.date.classNameColor === 'orange').length,
        green: coloredStations.filter(s => s.date.classNameColor === 'green').length,
        white: coloredStations.filter(s => !s.date.classNameColor || s.date.color === 'white').length,
    }
    const getStat = c => !colorStats ? '' : ` (${colorStats[c]})`

    const onValidateForm = () => {
        onValidate({
            date,
            nbDays,
            searchValue,
            filter,
            filterResults,
            campaign,
            network,
            dataType,
            referentId,
            nbDaysUndetected,
            nbDaysAlert,
            color,
            lastMeasureBrute,
            checkControlErrors,
            eventTypes,
        })
    }

    return (
        <Card className='padding-top-1'>
            <div className='row no-margin'>
                <Input
                    col={1}
                    title={i18n.endDate}
                    value={getDate(date)}
                    onChange={v => onChangeDate(v, setDate)}
                />
                <NumberField
                    col={2}
                    value={nbDays}
                    onChange={setNbDays}
                    title={i18n.nbDays}
                />
                <Input
                    col={2}
                    title={i18n.search}
                    value={searchValue}
                    onChange={setSearchValue}
                />
                <SimpleFilterSelect
                    col={2}
                    stationType={STATION_TYPE_NAME.piezometry}
                    onChange={(results, newFilter) => {
                        setFilterResults(results)
                        setFilter(newFilter)
                    }}
                    stations={piezometers}
                />
                <Select
                    col={2}
                    label={i18n.campaigns}
                    options={piezometryCampaigns}
                    value={campaign}
                    onChange={setCampaign} // null ?
                    nullLabel='&nbsp;'
                    clearFunction
                />
                <Autocomplete
                    col={3}
                    label={i18n.network}
                    options={networkList}
                    keyLabel='labelWithSandre'
                    keyFilter={['sandreCode', 'name', 'mnemonic']}
                    onChange={setNetwork}
                    value={network}
                />
            </div>
            <div className='row no-margin'>
                <Select
                    col={3}
                    label={i18n.chronicType}
                    options={orderBy(uniqBy(dataTypes, 'code'), 'order')}
                    noSort
                    value={dataType}
                    onChange={setDataType}
                    clearFunction
                />
                <MultiContributorsAutocomplete
                    options={uniqReferents}
                    col={2}
                    label={i18n.administrator}
                    keyValue='code'
                    onChange={setReferentId}
                    values={referentId}
                />
                <SuperMultiAutocomplete
                    col={2}
                    label={i18n.eventType}
                    options={i18nize(EVENT_TYPES.filter((t) => ['S', 'P'].includes(t.code))).map((t) => ({ ...t, icon: undefined }))}
                    values={eventTypes}
                    onChange={setEventTypes}
                    keyValue='code'
                    keyLabel='label'
                    clearFunction
                    multiple
                    limit={2}
                />
                <NumberField
                    col={2}
                    title={i18n.nbDaysUndetected}
                    onChange={setNbDaysUndetected}
                    value={nbDaysUndetected}
                    max={nbDaysAlert}
                    min={0}
                />
                <NumberField
                    col={3}
                    title={i18n.nbDaysAlert}
                    onChange={setNbDaysAlert}
                    value={nbDaysAlert}
                    min={nbDaysUndetected}
                />
            </div>
            <div className='row no-margin'>
                <Checkbox
                    col={1}
                    checked={color === 'all'}
                    label={i18n.all}
                    onChange={() => setColor('all')}
                />
                <Checkbox
                    col={1}
                    checked={color === 'green'}
                    label={<span><span className={'green arrests-level-panel green-text'} {...sieauTooltip(template(i18n.infDaysData)({ days: defaultFilter.nbDaysUndetected }))}>__</span>{getStat('green')}</span>}
                    onChange={v => setColor(v ? 'green' : 'all')}
                    tooltip={template(i18n.infDaysData)({ days: defaultFilter.nbDaysUndetected })}
                />
                <Checkbox
                    col={1}
                    checked={color === 'orange'}
                    label={<span><span className={'yellow darken-1 arrests-level-panel yellow-text'} {...sieauTooltip(template(i18n.infBetweenDaysData)({ days1: defaultFilter.nbDaysUndetected, days2: defaultFilter.nbDaysAlert }))}>__</span>{getStat('orange')}</span>}
                    onChange={v => setColor(v ? 'orange' : 'all')}
                    tooltip={template(i18n.infBetweenDaysData)({ days1: defaultFilter.nbDaysUndetected, days2: defaultFilter.nbDaysAlert })}
                />
                <Checkbox
                    col={1}
                    checked={color === 'red'}
                    label={<span><span className={'red arrests-level-panel red-text'} {...sieauTooltip(template(i18n.supDaysData)({ days: defaultFilter.nbDaysAlert }))}>__</span>{getStat('red')}</span>}
                    onChange={v => setColor(v ? 'red' : 'all')}
                    tooltip={template(i18n.supDaysData)({ days: defaultFilter.nbDaysAlert })}
                />
                <Checkbox
                    col={1}
                    checked={color === 'white'}
                    label={i18n.empty + getStat('white')}
                    onChange={v => setColor(v ? 'white' : 'all')}
                />
                <Checkbox
                    col={3}
                    checked={checkControlErrors}
                    label={i18n.checkControlErrors}
                    onChange={setCheckControlErrors}
                />
                <Checkbox
                    col={1}
                    checked={lastMeasureBrute}
                    label={ i18n.toValidate }
                    onChange={() => setLastMeasureBrute(!lastMeasureBrute)}
                />
                <div className='col s3 padding-bottom-1'>
                    <a className='btn col s12' onClick={onValidateForm}>
                        {i18n.search}
                    </a>
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s9'>
                    {
                        displayCardToMuchData && (
                            <CardToMuchData
                                nbElem={MIN_DISPLAY_NUMBER}
                                maxNbElements={nbAllStation}
                                forceDisplay={forceDisplay}
                            />
                        )
                    }
                </div>
                <div className='col s3 no-padding'>
                    <ul className='tabs'>
                        <li className='tab col s4' id={LIST}
                            onClick={() => setDisplayMode(LIST)} {...sieauTooltip(i18n.table)}
                        >
                            <a className={displayMode === LIST ? 'active' : ''}><i
                                className='material-icons inherited-line-height'
                            >list</i></a>
                        </li>
                        <li className='tab col s4' id={DAYS}
                            onClick={() => setDisplayMode(DAYS)} {...sieauTooltip(i18n.days)}
                        >
                            <a className={displayMode === DAYS ? 'active' : ''}><i
                                className='material-icons inherited-line-height'
                            >event</i></a>
                        </li>
                        <li className='tab col s4' id={MAP}
                            onClick={() => setDisplayMode(MAP)} {...sieauTooltip(i18n.map)}
                        >
                            <a className={displayMode === MAP ? 'active' : ''}><i
                                className='material-icons inherited-line-height'
                            >map</i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </Card>
    )
}

Form.propTypes = {
    defaultFilter: PropTypes.shape({}),
    coloredStations: PropTypes.arrayOf(PropTypes.shape({})),
    onValidate: PropTypes.func,
    displayMode: PropTypes.string,
    setDisplayMode: PropTypes.func,
    displayCardToMuchData: PropTypes.bool,
    nbAllStation: PropTypes.number,
    forceDisplay: PropTypes.func,
}

const Carto = ({
    stations = [],
}) => {
    const formattedStations = stations.map(station => {
        return {
            date: '',
            value: '',
            ...station,
            code: station.code.value,
            name: station.name.value,
            city: station.city.value,
            markerIcon: `pictures/markers/map_piezo_${station.date.classNameColor || 'white'}.png`,
            color: null,
        }
    })
    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            componentType={'piezometry'}
            stationsPoints={formattedStations}
            stationsPanelTitle={i18n.stations}
            colorMode
        />
    )
}

Carto.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.shape({})),
}

const CheckTable = ({
    isCheck = false,
    onCheck = () => {},
}) => {
    const [check, setCheck] = useState(isCheck)
    useEffect(() => {
        setCheck(isCheck)
    }, [isCheck])
    return (
        <i
            className='material-icons clickable table-checkbox'
            onClick={() => {
                onCheck()
                setCheck(p => !p)
            }}
        >
            {check ? 'check_box' : 'check_box_outline_blank'}
        </i>
    )
}

CheckTable.propTypes = {
    isCheck: PropTypes.bool,
    onCheck: PropTypes.func,
}

const now = moment().valueOf()

class PiezometryIntegrationApp extends ActionComponent {
    constructor(props) {
        super(props)
        const cacheFilter = pick(AdministrationAction.getCache('piezometry'), ['filter', 'filterResults'])
        const cache = this.getCache()
        const baseState = cache ?? this.getInitialState()
        this.state = { ...baseState, filter: { ...baseState.filter, ...cacheFilter } }
    }

    getCache = () => AppStore.getState().PiezometryReducer.cachePiezometrySituation

    getInitialState = () => ({
        filter: {
            date: moment().valueOf(),
            campaign: null,
            nbDaysUndetected: 3,
            nbDaysAlert: 5,
            searchValue: '',
            dataType: -1,
            referentId: null,
            network: null,
            nbDays: 30,
            filterResults: [],
            filter: null,
            color: 'all',
            lastMeasureBrute: false,
        },
        displayMode: LIST,
        event: {},
        selected: {},
        selectAll: false,
        isOpenNewCampaign: false,
        isOpenAddToCampaign: false,
        campaignStations: [],
        groupedEvents: {},
        groupedMeasureModes: {},
        networkGroup: {},
        networkList: [],
        filteredStations: [],
        forceDisplayAll: false,
        dataLoaded: false,
        hasCanceled: false,
        usedSort: { column: 'date', sort: 'asc' },
        initialPage: null,
    })

    fetchAllEvents = () => this.props.fetchAllEvents(['P', 'S']).then(() => this.setState({ groupedEvents: groupBy(this.props.piezometryAllEvents, 'stationId') }, () => {
        AppStore.dispatch(PiezometryActionConstant.cachePiezoSituation(this.state))
    }))

    onExportData = () => {
        if (this.state.displayMode === 'list') {
            const data = this.getStations(this.state.filteredStations).map(s => ({ ...s, measureMode: s.measureModeExport, value: { value: s.value.exportValue, color: s.value.color } }))
            if (data.length) {
                data[0].headers = exportHeaders
            }
            return data
        }
        const displayedStations = (this.state.forceDisplayAll ? this.state.filteredStations : take(this.state.filteredStations, MIN_DISPLAY_NUMBER))
        return this.getDaysData(displayedStations)
    }

    loadData = (state=this.state, filteredStations = this.state.filteredStations) => {
        const type = state.filter.dataType
        const criterias = {
            date: state.filter.date,
            nbDays: state.filter.nbDays,
            dataType: type,
            nbDaysUndetected: state.filter.nbDaysUndetected,
            nbDaysAlert: state.filter.nbDaysAlert,
            checkControlErrors: state.filter.checkControlErrors,
        }
        const piezometerIds = (state.forceDisplayAll ? filteredStations : take(filteredStations, MIN_DISPLAY_NUMBER)).map(p => p.id)
        this.setState({ dataLoaded: false, dataLoading: true, progress: 0, initialPage: null })
        AppStore.dispatch(PiezometryAction.fetchPiezometryTypeSituationWithDays(criterias, piezometerIds, p => this.setState({ progress: p }), () => {
            this.setState({ dataLoaded: true, dataLoading: false })
            AppStore.dispatch(PiezometryActionConstant.cachePiezoSituation({ ...this.state, dataLoaded: true, dataLoading: false }))
        }))
    }

    createEvent = station => {
        AppStore.dispatch(EventsAction.addEvent('piezometry', station.id, this.state.event, this.fetchAllEvents))
    }

    onChangeEvent = event => {
        this.setState({ event })
    }

    openPopup = (station) => {
        const id = uuidv4()
        const code = station && station.code ? `${station.code} : ` : ''
        const popup = {
            id,
            header: `${code}${i18n.addOneEvent}`,
            actions: (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
                    <a className='waves-effect waves-teal btn-flat modal-close btn font-white'
                        onClick={() => this.createEvent(station)}
                    >{i18n.validate}</a>
                </div>
            ),
            content: <EventFormModal onChange={this.onChangeEvent} initialDate={moment().valueOf()} />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    getActive = (panel) => {
        return panel === this.state.displayMode ? 'active' : ''
    }

    onValidate = (newFilter) => {
        const filteredStations = this.getStationsFirstFilter(newFilter)
        const usedState = { filter: newFilter, filteredStations, hasCanceled: false }
        this.setState(usedState)
        this.loadData({ ...this.state, ...usedState }, filteredStations)
    }

    changeDisplayMode = (displayMode) => {
        this.setState({ displayMode })
        AppStore.dispatch(PiezometryActionConstant.cachePiezoSituation({ ...this.state, displayMode }))
    }

    getDateState = (date) => {
        if (moment(date).add(this.state.filter.nbDaysUndetected, 'days').isSameOrAfter(moment(this.state.filter.date))) {
            return { color: 'green', tooltip: template(i18n.infDaysData)({ days: this.state.filter.nbDaysUndetected }) }
        }
        if (moment(date).add(this.state.filter.nbDaysAlert, 'days').isSameOrAfter(moment(this.state.filter.date))) {
            return { color: 'orange', tooltip: template(i18n.infBetweenDaysData)({ days1: this.state.filter.nbDaysUndetected, days2: this.state.filter.nbDaysAlert }) }
        }
        return { color: 'red', tooltip: template(i18n.supDaysData)({ days: this.state.filter.nbDaysAlert }) }
    }

    getFirstFilterResult = (stations, filter = -1, filterResults) => {
        if (hasValue(filter) && filter !== -1 && filterResults.length) {
            return flatten(filterResults.map(stationId => stations.find(station => station.id === stationId) || []))
        }
        return stations
    }

    getStationsFirstFilter = (stateFilter = this.state.filter, networkGroup=this.state.networkGroup) => {
        const campaignGroups = groupBy(this.props.piezometryCampaignStations, 'stationId')
        const piezos = this.props.piezometers.map(piezometer => {
            const city = `${getObjectLabel(this.props.citiesIndex[piezometer.townCode])} ${piezometer.townCode ? ` [${piezometer.townCode}]` : ''}`
            const referentLink = this.props.piezometersReferents.find(link => link.idStation === piezometer.id)
            return {
                id: piezometer.id,
                code: piezometer.code,
                name: piezometer.name,
                city,
                campaigns: (campaignGroups[piezometer.id] || []).map(c => c.campaignId),
                hash: searchAllCharacters([piezometer.code, piezometer.name, city].join('   ')),
                x: piezometer.x,
                y: piezometer.y,
                projection: piezometer.projection,
                typeName: piezometer.typeName,
                scale: 0.5,
                referentId: referentLink?.code,
            }
        })

        const filterResults = stateFilter.filterStations || this.getFirstFilterResult(piezos, stateFilter.filter, stateFilter.filterResults.map(s => s.id))

        const searchValue = hasValue(stateFilter.searchValue) ? searchAllCharacters(stateFilter.searchValue) : null
        // si la searchValue est une date, on ne filtre que sur la date d'export (retournée après le call vers le back). Le filtre est fait plus loin.
        const filter1 = hasValue(searchValue) && !(new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')).test(stateFilter.searchValue) ? p => p.hash.includes(searchValue) : () => true

        const filterIds = stateFilter.filter && stateFilter.filter != -1 ? filterResults.map(f => f.id) : null
        const filter2 = filterIds ? s => filterIds.includes(s.id) : () => true

        const filter3 = hasValue(stateFilter.campaign) ? s => s.campaigns.includes(stateFilter.campaign) : () => true

        const filter4 = (() => {
            if (hasValue(stateFilter.network)) {
                const ids = (networkGroup[stateFilter.network] || []).map(n => n.idPiezometer)
                return s => ids.includes(s.id)
            }
            return () => true
        })()

        const filter5 = hasValue(stateFilter.referentId) ? s => s.referentId === stateFilter.referentId : () => true

        const filter6 = stateFilter?.eventTypes?.length ? s => {
            const events = (this.state.groupedEvents[s.id] || []).filter(e => (!e.date || e.date < now) && (!e.closeDate || e.closeDate > now))
            return events.some((e) => stateFilter.eventTypes.includes(e.eventType))
        } : () => true

        return orderBy(piezos.filter(p => filter1(p) && filter2(p) && filter3(p) && filter4(p) && filter5(p) && filter6(p)), o => o.name ? o.name.toUpperCase() : '}')
    }

    getColor = (color) => {
        if (color) {
            if (['black', '#000000'].includes(color)) {
                return 'gray'
            }
            return color
        }
        return 'white'
    }

    getStations = (filteredStations) => {
        return filteredStations.map(piezometer => {
            const events = (this.state.groupedEvents[piezometer.id] || []).filter(e => (!e.date || e.date < now) && (!e.closeDate || e.closeDate > now))
            const monitoringEvents = events.filter(e => e.eventType === 'S')
            const toPlanEvents = events.filter(e => e.eventType === 'P')
            const referentLinks = this.props.piezometersReferents.find(link => link.idStation === piezometer.id) // 4: Référent

            const foundMeasureMode = maxBy((this.state.groupedMeasureModes[piezometer.id] || []).filter(m => m.startDate <= this.state.filter.date && (!m.endDate || m.endDate >= this.state.filter.date)), 'startDate')
            const periodicityIcon = getMeasureModePeriodicityIcon(foundMeasureMode ? foundMeasureMode.method : 0)
            const measureModeIcon = { leftIcon: periodicityIcon.icon, leftIconColor: periodicityIcon.color, leftIconTip: () => getSandreLabel(this.props.sandreCodes, SANDRE.MEASURE_MODE, foundMeasureMode ? foundMeasureMode.method : 0) }
            const measureMode = {
                ...measureModeIcon,
                value: foundMeasureMode ? getSandreLabel(this.props.sandreCodes, SANDRE.MEASURE_PERIODICITY, foundMeasureMode.periodicity) : '',
                contentTip: foundMeasureMode ? () => getSandreComment(this.props.sandreCodes, SANDRE.MEASURE_PERIODICITY, foundMeasureMode.periodicity) : null,
            }
            const baseObj = {
                ...piezometer,
                icon: { leftIcon: 'border_color', leftIconColor: 'dodgerblue', leftIconClick: () => AppStore.dispatch(push(`/station/piezometry/${piezometer.id}/validation?dataType=${this.state.filter.dataType}`)), leftIconTip: () => i18n.goToValidation },
                code: { value: piezometer.code, onClick: () => AppStore.dispatch(push(`/station/piezometry/${piezometer.id}`)) },
                name: { value: piezometer.name, onClick: () => AppStore.dispatch(push(`/station/piezometry/${piezometer.id}`)) },
                city: { value: piezometer.city },
                color: { value: 'white' },
                referentId: referentLinks ? referentLinks.code : null,
                id: { value: piezometer.id },
                measureMode,
                measureModeExport: foundMeasureMode ? foundMeasureMode.periodicity : '',
                nullValue: {
                    value: [
                        <CampaignIcon stationId={piezometer.id} />,
                        <EventIcon events={monitoringEvents} label={i18n.toMonitor} icon='notifications' fetchAllEvents={this.fetchAllEvents} />,
                        <EventIcon events={toPlanEvents} label={i18n.toPlan} icon='assignment' fetchAllEvents={this.fetchAllEvents} />,
                    ],
                },
                nullValue2: {
                    value: (
                        <div {...sieauTooltip(() => i18n.selectToAddToACampaign)}>
                            <CheckTable
                                isCheck={this.state.selected[piezometer.id]}
                                onCheck={() => this.setState(prev => ({ selected: { ...prev.selected, [piezometer.id]: !prev.selected[piezometer.id] } }))}
                            />
                        </div>
                    ),
                },
            }
            const found = this.props.piezometryTypeSituation.find(s => s.piezometerId == piezometer.id)
            if (found) {
                const state = this.getDateState(found.lastMeasureDate)

                const controlErrorFound = found.controlErrors.length

                const color = this.getColor(found.thresholdColor)

                return {
                    ...baseObj,
                    startDate: { value: getDate(found.startDate) },
                    date: {
                        value: getDateWithHourString(found.lastMeasureDate, found.lastMeasureHour),
                        classNameColor: state.color,
                        color: state.color,
                        setTooltip: () => state.tooltip,
                        onClick: this.props.user.consultant !== '1' && (() => this.openPopup(piezometer)),
                    },
                    value: {
                        value: (
                            <span className='valign-wrapper'>
                                { statusIcon(found, undefined, true) }
                                <span className='padding-left-1'/>
                                { round(found.value) }
                            </span>),
                        classNameColor: color,
                        status: found.status,
                        qualification: found.qualification,
                        color,
                        setTooltip: found.thresholdColor ? () => found.threshold : null,
                        exportValue: round(found.value),
                    },
                    min: { value: round(found.min) },
                    max: { value: round(found.max) },
                    measuresCount: { value: found.measuresCount },
                    color: { value: state.color },
                    toExport: { value: found.adesExportDate ? getDate(found.adesExportDate) : i18n.nothingToExport },
                    nullValue3: controlErrorFound ? { leftIcon: 'warning', leftIconColor: 'red', setTooltip: () => (
                        <div>
                            <div>{ `${found.controlErrors.length} ${i18n.errors}` }</div>
                            { found.controlErrors.map(err => getControlAlert(err)) }
                        </div>
                    ) } : null,
                }
            }
            return {
                ...baseObj,
                startDate: '',
                date: '',
                value: '',
                min: '',
                max: '',
                measuresCount: '',
                color: 'white',
            }
        })
    }

    getFilteredColoredStations = () => {
        const { filteredStations, filter } = this.state
        const stationsColored = this.getStations(filteredStations)
        const filtered1 = filter.color === 'all' ? stationsColored : (
            filter.color === 'white' ? stationsColored.filter(s => !s.date.classNameColor || s.date.classNameColor === 'white') : stationsColored.filter(s => s.date.classNameColor === filter.color))
        const filtered2 = filter.checkControlErrors ? filteredStations.filter(s => s.nullValue3) : filtered1
        const filtered3 = hasValue(filter.searchValue) && (new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')).test(filter.searchValue) ? filtered2.filter(s => s.toExport?.value === filter.searchValue) : filtered2
        return filteredStations.filter((s) => filtered3.some(p => p.id.value === s.id))
    }

    openModalNewCampaign = () => {
        const { selected } = this.state
        const filtered = this.getFilteredColoredStations().map(s => this.props.piezometers.find(p => p.id === s.id))
        const campaignStations = Object.keys(selected).filter(key => !!selected[key]).map(key => filtered.find(p => p.id == key)).filter(s => !!s)
        if (campaignStations.length) {
            this.setState({ isOpenNewCampaign: true, campaignStations })
        } else {
            this.props.toastInfo(i18n.selectStation)
        }
    }

    openModalAddToCampaign = () => {
        const { selected } = this.state
        const filtered = this.getFilteredColoredStations().map(s => this.props.piezometers.find(p => p.id === s.id))
        const campaignStations = Object.keys(selected).filter(key => !!selected[key]).map(key => filtered.find(p => p.id == key)).filter(s => !!s)
        if (campaignStations.length) {
            this.setState({ isOpenAddToCampaign: true, campaignStations })
        } else {
            this.props.toastInfo(i18n.selectStation)
        }
    }

    getCampaignDropDown = () => (
        <div className='dropdown-content blue-arrow row no-margin' id='campaignDropdown'>
            <div style={{ height: '10px' }} />
            <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1' >
                <div className='collection-item row no-margin valign-wrapper clickable' onClick={this.openModalNewCampaign}>
                    <div className='col s2'>
                        <i className='material-icons left no-margin'>note_add</i>
                    </div>
                    <div className='col s10'>
                        <h6>{i18n.newCampaign}</h6>
                    </div>
                </div>
            </div>
            <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1' >
                <div className='collection-item row no-margin valign-wrapper clickable' onClick={this.openModalAddToCampaign}>
                    <div className='col s2'>
                        <i className='material-icons left no-margin'>edit</i>
                    </div>
                    <div className='col s10'>
                        <h6>{i18n.addToCampaign}</h6>
                    </div>
                </div>
            </div>
        </div>
    )

    checkAll = () => {
        this.setState(prev => ({
            selectAll: !prev.selectAll,
            selected: !prev.selectAll ? this.state.filteredStations.reduce((acc, p) => {
                acc[p.id] = true
                return acc
            }, {}) : {},
        }))
    }

    getDayTooltip = (rgCode, color) => {
        switch (rgCode) {
            case 1:
                return 'La station est fermée, or elle reçoit toujours des données'
            case 2:
                return 'Station fermée'
            case 3:
                return 'Trou dans les données sans point initial'
            case 6:
                return 'Alerte sur l\'absence de données'
            default:
                switch (color) {
                    case 'white':
                        return 'Pas de données'
                    case 'lightgreen':
                        return 'Controlée Nv1'
                    case 'green':
                        return 'Controlée Nv2'
                    case 'blue':
                        return 'Donnée interprétée'
                    default:
                        return 'Donnée brute'
                }
        }
    }

    getDayWarnTip = (warningCode) => {
        switch (warningCode) {
            case 4:
                return 'Repère, système altimétrique ou mode de mesure non défini pour cette date'
            case 5:
                return 'Le statut n\'est pas cohérent avec la qualification'
            default:
                return ''
        }
    }

    getDaysData = (displayedStations) => {
        const dates = enumerateBetweenDates(moment(this.state.filter.date).subtract(this.state.filter.nbDays - 1, 'days').valueOf(), this.state.filter.date, 'days')
            .map(m => {
                const format = moment(this.state.filter.date).month() !== m.month() ? m.format(' DD/MM') : m.format('DD/MM')
                return format.startsWith('01') || this.state.filter.nbDays > 40 ? format : format.split('/')[0]
            })
        return displayedStations.map(s => {
            const daysSituation = this.props.piezometryDaysSituation.find(situ => situ.piezoId === s.id.value)
            const daysData = {}
            if (daysSituation) {
                daysSituation.days.forEach(d => {
                    const warningIcon = d.warningCode ? { leftIcon: 'warning', leftIconColor: 'black', leftIconSize: 15, leftIconTip: () => this.getDayWarnTip(d.warningCode) } : {}
                    const format = moment(this.state.filter.date).month() !== moment(d.day).month() ? moment(d.day).format(' DD/MM') : moment(d.day).format('DD/MM')
                    daysData[format.startsWith('01') || this.state.filter.nbDays > 40 ? format: format.split('/')[0]] = {
                        value: d.label,
                        classNameColor: d.color,
                        color: d.color,
                        [d.warningCode ? 'contentTip' : 'setTooltip']: () => this.getDayTooltip(d.rgCode, d.color),
                        ...warningIcon,
                        className: 'clickable',
                        positionCell: `centerText${warningIcon.leftIcon ? '' : ' block'}`,
                        onClick: () => this.setDayMeasuresPopup(s.id.value, d),
                    }
                })
            }
            return {
                headers: ['icon', 'code', 'name', 'city', ...dates],
                icon: { leftIcon: 'border_color', leftIconColor: 'dodgerblue', leftIconClick: () => AppStore.dispatch(push(`/station/piezometry/${s.id.value}/validation?dataType=${this.state.filter.dataType}`)), leftIconTip: () => i18n.goToValidation },
                code: { value: s.code.value, onClick: () => AppStore.dispatch(push(`/station/piezometry/${s.id.value}`)) },
                name: { value: s.name.value, onClick: () => AppStore.dispatch(push(`/station/piezometry/${s.id.value}`)) },
                city: { value: s.city.value },
                ...daysData,
            }
        })
    }

    filteredStations = (stations) => {
        const { filter } = this.state
        const filteredStations = filter.color === 'all' ? stations : (
            filter.color === 'white' ? stations.filter(s => !s.date.classNameColor || s.date.classNameColor === 'white') : stations.filter(s => s.date.classNameColor === filter.color))
        const filtered2 = filter.checkControlErrors ? filteredStations.filter(s => s.nullValue3) : filteredStations
        const filter3 = hasValue(filter.searchValue) && (new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')).test(filter.searchValue) ? filtered2.filter(s => s.toExport?.value === filter.searchValue) : filtered2
        return !filter.lastMeasureBrute ? filter3 : filter3.filter(s => (isUndefined(s.value.status) || s.value.status === 1) && !(s.value === ""))
    }

    getDaysPanel = (displayedStations) => {
        const data = this.getDaysData(this.filteredStations(displayedStations))
        if (data.length) {
            return (
                <div className='padding-top-1 padding-left-1 padding-right-1'>
                    <Table data={data}
                        sortable noHightlight
                        orderable
                        color
                        title={ i18n.piezometers }
                        paging
                        nbPerPageLabel={nbPerPageLabel}
                        type={{ headers: data[0].headers }}
                        initialPage={ this.state.initialPage }
                        onChangePage={ p => {
                            AppStore.dispatch(PiezometryActionConstant.cachePiezoSituation({ ...this.state, initialPage: p }))
                        } }
                        customHeaders={ { icon: ' ' } }
                        centerNumberHeaders
                        onSort={ (column, sort) => {
                            const usedSort = { column, sort }
                            this.setState({ usedSort })
                            AppStore.dispatch(PiezometryActionConstant.cachePiezoSituation({ ...this.state, usedSort }))
                        } }
                        initialSort={ this.state.usedSort }
                        condensed
                        legendPanel={<LegendPanel filter={this.state.filter} />}
                    />
                </div>
            )
        }
        return <MessageCard>{ i18n.noResults }</MessageCard>
    }

    setDayMeasuresPopup = (stationId, dayData) => {
        const startDate = moment(this.state.filter.date).subtract(this.state.filter.nbDays, 'days').startOf('day').valueOf()
        const endDate = moment(this.state.filter.date).endOf('day').valueOf()
        PiezometerStationAction.promisePiezoChartMeasures({
            stationId,
            startDate,
            endDate,
            displayCote: MEASURE_COTE.DEPTH,
            dataType: -1,
        }).then(measures => {
            const minDate = moment(dayData.day).startOf('day').valueOf()
            const maxDate = moment(dayData.day).endOf('day').valueOf()
            const data = orderBy(measures.map(m => ({ value: [m[0], m[1]] })), m => m.value[0])
            const series = [Line({
                data,
                name: i18n.chronic,
                connectNulls: false,
                showSymbol: true,
                color: '#222',
            })]
            const yScale = yAutomaticScale(data)
            const options = {
                series,
                setDataZoom: true,
                // height: 800,
                xAxis: [Axis({
                    type: 'time',
                    position: 'bottom',
                    min: startDate,
                    max: endDate,
                })],
                yAxis: [Axis({
                    type: 'value',
                    nameLocation: 'middle',
                    name: `${i18n.chronic} (m)`,
                    nameGap: 40,
                    inverse: dayData.length && dayData[0].cote !== MEASURE_COTE.NGF,
                    showSplitLine: true,
                    ...yScale,
                })],
                tooltip: {
                    trigger: 'axis',
                    formatter: params => {
                        const date = getFullDate(moment(params[0].value[0]))
                        const paramsOrder = params.map(o => ({
                            marker: o.marker,
                            seriesName: o.seriesName,
                            value: o.value[1],
                        }))
                        const result = paramsOrder.map(o => `${o.marker} ${o.seriesName} : ${o.value} m`).join('<br/>')
                        return `${date}  <br /> ${result}`
                    },
                },
            }
            const chart = (<div>
                <EChart options={options} id='piezometryDayChart' initialZoomValueStart={ minDate } initialZoomValueEnd={ maxDate }/>
                <Row>
                    <Button className='modal-close right' title={ i18n.close }/>
                </Row>
            </div>)
            setModal({ title: `Mesures du ${getDate(dayData.day)}`, content: chart })
        })
    }

    getPanel = (stations) => {
        const { dataLoaded, dataLoading, hasCanceled, filter, displayMode, usedSort, initialPage, selectAll, progress } = this.state
        if (dataLoaded && !dataLoading && !hasCanceled) {
            const sortedStations = getSortedTableData(this.filteredStations(stations), usedSort, true)
            AppStore.dispatch(AdministrationAction.setSelectedSearchValues('piezometry', { stations: sortedStations.map(s => ({ id: s.id.value, name: s.name.value, code: s.code.value })) }))
            switch (displayMode) {
                case 'list':
                    const dataTypes = [ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes].map(t => ({ code: t.id, name: t.label, unit: t.unit }))
                    const unit = (dataTypes.find(d => d.code === filter.dataType) || {}).unit
                    return (
                        <div className='padding-top-1 padding-left-1 padding-right-1'>
                            <Table data={sortedStations}
                                sortable noHightlight
                                orderable
                                color
                                title={ i18n.piezometers }
                                paging
                                nbPerPageLabel={nbPerPageLabel}
                                initialPage={ initialPage }
                                onChangePage={ p => {
                                    AppStore.dispatch(PiezometryActionConstant.cachePiezoSituation({ ...this.state, initialPage: p }))
                                } }
                                type={{ headers: this.props.user.consultant === '1' ? tableHeadersRestriction : tableHeaders }}
                                onSort={ (column, sort) => {
                                    const newSort = { column, sort }
                                    this.setState({ usedSort: newSort })
                                    AppStore.dispatch(PiezometryActionConstant.cachePiezoSituation({ ...this.state, usedSort: newSort }))
                                }}
                                initialSort={usedSort}
                                customHeaders={{
                                    nullValue2: (
                                        <i
                                            className='material-icons table-checkbox'
                                            onClick={e => {
                                                e.stopPropagation()
                                                this.checkAll()
                                            }}
                                        >
                                            {selectAll ? 'check_box' : 'check_box_outline_blank'}
                                        </i>
                                    ),
                                    value: i18n.valueLabel + (unit ? ` (${unit})` : ''), icon: ' ',
                                }}
                                headersTip={{ toExport: 'Date de début des mesures qui seront exportées lors du prochain export vers ADES.' }}
                                headersIcon={{ toExport: { icon: 'help', color: '#259cff' } }}
                                condensed
                                legendPanel={<LegendPanel filter={filter} />}
                            />
                        </div>
                    )
                case 'days':
                    return this.getDaysPanel(this.filteredStations(stations))
                default:
                    return (
                        <Carto stations={this.filteredStations(stations)} />
                    )
            }
        }
        if (hasCanceled) {
            return <div className='padding-top-1 padding-left-1 padding-right-1'><MessageCard >{ i18n.noDataToDisplay }</MessageCard></div>
        }
        if (dataLoading) {
            return (
                <div className='padding-top-1 padding-left-1 padding-right-1'>
                    <ProgressCard progress={progress} withCancel whenCancel={ () => this.setState({ hasCanceled: true })}/>
                </div>
            )
        }
        return <div className='padding-top-1 padding-left-1 padding-right-1'><MessageCard >{ i18n.dataPreparing }</MessageCard></div>
    }

    render() {
        const {
            campaignStations,
            isOpenNewCampaign,
            isOpenAddToCampaign,
        } = this.state

        const displayedStations = this.state.dataLoaded ? (this.state.forceDisplayAll ? this.state.filteredStations : take(this.state.filteredStations, MIN_DISPLAY_NUMBER)) : []
        const coloredStations = this.getStations(displayedStations)

        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s12 m12'>
                        <Form
                            defaultFilter={this.state.filter}
                            coloredStations={coloredStations}

                            onValidate={this.onValidate}

                            displayMode={this.state.displayMode}
                            setDisplayMode={mode => this.setState(({ displayMode: mode }))}

                            displayCardToMuchData={this.state.dataLoaded && !this.state.forceDisplayAll && !this.state.hasCanceled && this.state.filteredStations.length > MIN_DISPLAY_NUMBER}
                            nbAllStation={this.state.filteredStations.length}
                            forceDisplay={() => {
                                this.setState({ forceDisplayAll: true, dataLoaded: false }, this.loadData)
                            }}
                        />
                    </div>
                </div>
                <div className='row no-margin' style={{ paddingBottom: '50px' }}>
                    {this.getPanel(coloredStations)}
                </div>
                { this.getCampaignDropDown()}
                <PiezometryNewCampaignModal
                    stations={campaignStations}
                    isOpen={isOpenNewCampaign}
                    onClose={() => this.setState({ isOpenNewCampaign: false })}
                    onValidate={() => {
                        this.props.fetchPiezometryCampaigns()
                        this.props.fetchPiezometryCampaignStations()
                        this.setState({ isOpenNewCampaign: false, selected: {}, selectAll: false })
                    }}
                />
                <PiezometryAddToCampaignModal
                    stations={campaignStations}
                    isOpen={isOpenAddToCampaign}
                    onClose={() => this.setState({ isOpenAddToCampaign: false })}
                    onValidate={() => {
                        this.props.fetchPiezometryCampaigns()
                        this.props.fetchPiezometryCampaignStations()
                        this.setState({ isOpenAddToCampaign: false, selected: {}, selectAll: false })
                    }}
                />
            </div>
        )
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PIEZO_INTEGRATION)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        $('ul.tabs').tabs()
        $('.dropdown-button').dropdown()
        AppStore.dispatch(HomeAction.setHelpLink('piezometrie', ''))
        // AppStore.dispatch(StatisticApplicationAction.putUsagePiezometry('situation'))
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: i18n.integrationOverview,
            href: 'piezometry/situation',
        }]))
        // si il y a un cache, et que le filtre n'a pas changé, on ne charge pas (tout est dans le cache)
        // sinon, on chope les résultats du filtre (si y'en a) et ensuite on charge
        const { filter } = this.state
        const cache = this.getCache()
        if (cache && cache.filter.filter === filter.filter) {
            // nothing to load
        } else if (filter.filter > 0 || filter.filter === -5) {
            // charger le filtre
            if (filter.filter === -5) {
                const res = getBookmarksIds(this.props.userBookmarks, 'piezometry', this.props.piezosIndexCode)
                this.setState(stateFilter => ({ filter: { ...(stateFilter.filter), filterResults: res.map(line => ({ id: line.id })) } }), this.loadFirstTime)
            } else {
                StationAction.promiseFilterResults({ module: 'SIES', code: filter.filter }).then(res => {
                    this.setState(stateFilter => ({ filter: { ...(stateFilter.filter), filterResults: res.map(line => ({ id: line.id })) } }), this.loadFirstTime)
                })
            }
        } else {
            this.loadFirstTime()
        }
        // if (!this.getCache() || ((hasValue(this.state.filter.filter) && this.state.filter.filter !== -1) && this.props.filterResults.length < this.state.filteredStations.length)) {
        //
        // }
        // this.loadFirstTime()
        this.setActionsComponent()
    }

    loadFirstTime = () => {
        this.setState({ dataLoaded: false, dataLoading: true, progress: 0, initialPage: null })
        AppStore.dispatch(PiezometryAction.loadPiezometrySituation(() => {}, () => {
            const networkGroup = groupBy(this.props.networkPiezoLink, 'idNetwork')
            const networkList = Object.keys(networkGroup).map(nId => this.props.networks.find(n => n.id == nId)).filter(n => !!n)
            const filteredStations = this.getStationsFirstFilter(this.state.filter, networkGroup)
            this.setState({
                groupedEvents: groupBy(this.props.piezometryAllEvents, 'stationId'),
                groupedMeasureModes: groupBy(this.props.piezometryMeasureModes, 'idStation'),
                networkGroup,
                networkList,
                filteredStations,
            })
            this.loadData(this.state, filteredStations)
        }))
    }

    setActionsComponent = () => {
        const dropDown = getUser().consultant !== '1' && {
            dropdown: {
                icon: 'nature_people',
                tooltip: i18n.campaign,
                listItem: [
                    { icon: 'note_add', label: i18n.newCampaign, onClick: this.openModalNewCampaign },
                    { icon: 'edit', label: i18n.addToCampaign, onClick: this.openModalAddToCampaign },
                ],
            },
        } || {}
        const actions = {
            export: () => ({
                data: this.onExportData(),
                exportType: 'xlsx',
                titleFile: i18n.situation,
            }),
            ...dropDown,
        }
        setTimeout(() => {
            this.setActions(actions)
        }, 100)
    }

    shouldComponentUpdate(_, nextState) {
        if (this.state.selectAll !== nextState.selectAll) {
            return true
        }
        if (!isEqual(this.state.selected, nextState.selected) || !isEqual(this.state.event, nextState.event)) {
            return false
        }
        return true
    }
}

PiezometryIntegrationApp.propTypes = ({
    user: PropTypes.instanceOf(UserDto),
    params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
    }),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    piezosIndexCode: objectOf(DtoPiezometerLight),
    piezometryTypeSituation: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometrySituations)),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    piezometryAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventPiezometer)),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    piezometryCampaignStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    piezometersReferents: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerReferent)),
    toastInfo: PropTypes.func,
    fetchAllEvents: PropTypes.func,
    citiesIndex: objectOf(CityDto),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributor)),
    networkPiezoLink: arrayOf(NetworkPiezoLinkDto),
    networks: arrayOf(NetworkDto),
    piezometryMeasureModes: arrayOf(DtoMeasureMethod),
    sandreCodes: arrayOf(DtoSandreCode),
    piezometryDaysSituation: arrayOf(DtoPiezoSituationDays),
    status: arrayOf(DtoStatus),
    push: PropTypes.func,
    filterResults: PropTypes.arrayOf(PropTypes.number),
    userBookmarks: arrayOf(DtoUserBookmark),
})

const mapStateToProps = store => ({
    user: store.AccountReducer.accountUser,
    piezometers: store.PiezometryReducer.piezometersLight,
    piezosIndexCode: store.PiezometryReducer.piezosIndexCode,
    piezometryTypeSituation: store.PiezometryReducer.piezometryTypeSituation,
    piezometryDaysSituation: store.PiezometryReducer.piezometryDaysSituation,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometryAllEvents: store.EventsReducer.piezometryAllEvents,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometryCampaignStations: store.PiezometryReducer.piezometryCampaignStations,
    piezometersReferents: store.PiezometryReducer.piezometersReferents,
    citiesIndex: store.CityReducer.citiesIndex,
    contributors: store.ContributorReducer.contributors,
    networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
    networks: store.NetworkReducer.networks,
    piezometryMeasureModes: store.PiezometryReducer.piezometryMeasureModes,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    status: store.QualityReducer.status,
    filterResults: store.StationReducer.filterResults,
    userBookmarks: store.UserReducer.userBookmarks,
})

const mapDispatchToProps = {
    toastInfo: ToastrAction.info,
    fetchPiezometryCampaigns: CampaignAction.fetchPiezometryCampaigns,
    fetchPiezometryCampaignStations: CampaignAction.fetchPiezometryCampaignStations,
    fetchAllEvents: EventsAction.fetchPiezometryAllEvents,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryIntegrationApp)
