import { every, intersectionWith, isNil, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../../../components/card/Card'
import Table from '../../../../../components/datatable/Table'
import DtoQualitometerLight from '../../../../../quality/dto/DtoQualitometerLight'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import { getI18nTitleDataLength, searchAllCharacters } from '../../../../../utils/StringUtil'
import { QualitometerFilterField } from 'components/datatable/SimpleSelectionTable'
import { StyledFieldSet } from 'components/StyledElements'
import { MenuItem, Select as MuiSelect } from '@mui/material'

const codificationsList = [
    { id: 0, label: 'Origine du code inconnue' },
    { id: 1, label: 'Code national attribué par les Agences de l\'eau pour les Eaux de Surface' },
    { id: 2, label: 'Code national attribué pour SISE\'EAU' },
    { id: 3, label: 'Code national attribué par le BRGM pour la BSS et ADES' },
    { id: 4, label: 'Code national attribué par l\'IFREMER pour QUADRIGE' },
    { id: 5, label: 'Code national attribué aux stations d\'épuration par les Agences de l\'Eau, dans le domaine de l\'assainissement urbain' },
    { id: 10, label: 'Code local attribué par le(s) maître(s) d\'ouvrage(s) ou propriétaire(s) de la station de prélèvement' },
    { id: 11, label: 'Code local attribué par le commanditaire de la demande' },
    { id: 12, label: 'Code local attribué par l\'intervenant chargé du prélèvement' },
    { id: 13, label: 'Code local attribué par le laboratoire d\'analyses' },
]

const CodificationSelect = ({
    codification,
    onChange = () => {},
}) => {
    return (
        <MuiSelect
            value={codification}
            onChange={e => {
                e.stopPropagation()
                onChange(e.target.value)
            }}
            onClick={e => e.stopPropagation()}
            blurOnSelect
            disableUnderline
            style={{ width: '250px', height: '24px', backgroundColor: 'white', border: '1px solid #666' }}
            sx={{ '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}
        >
            {
                codificationsList.map((obj, i) => (<MenuItem key={i} value={obj.id}>{obj.label}</MenuItem>))
            }
        </MuiSelect>
    )
}

CodificationSelect.propTypes = {
    codification: PropTypes.number,
    onChange: PropTypes.func,
}

const ValidateStationTab = ({
    selectedStations = [],
    stations = [],
    codifications = [],
    onChangeCodifications = () => { },
    maxHeight = '62vh',
    onChangeSelectedStation = () => { },
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [filter, setFilter] = useState({})

    const onClickStation = (id, isSelected) => {
        const newSelectedStations = isSelected ? selectedStations.filter(stationId => stationId !== id) : [...selectedStations, id]
        onChangeSelectedStation(newSelectedStations)
    }

    const formattedStations = useMemo(() => {
        return stations.map(({ id, code, name, townCode }) => {
            const isSelected = !!selectedStations.find(stationId => stationId === id)
            const stationCodi = codifications.find(c => c.stationId === id)

            const cityLabel = cities.find(c => c.id === townCode)?.labelWithCode ?? ''
            return {
                name: `${code ? `[${code}] ` : ''}${name || ''}`,
                city: cityLabel,
                codification: (
                    <CodificationSelect
                        codification={stationCodi?.codification}
                        onChange={codi => onChangeCodifications({ stationId: id, codification: codi })}
                    />
                ),
                nullValue: <i className={'material-icons'} >{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>,
                color: isSelected ? '#b8d2ff' : '#fff',
                id,
                isSelected,
                townCode,
                labelSearch: searchAllCharacters(`${name} ${code} ${townCode} ${cityLabel}`),
            }
        })
    }, [cities, selectedStations, stations])

    const filteredStations = useMemo(() => {
        const {
            filter: filterId = -1,
            resultFilter,
            searchValue,
            city,
        } = filter

        const filterCity = !isNil(city) ? formattedStations.filter(s => s.townCode === city) : formattedStations
        const filterFilter = filterId !== -1 ? intersectionWith(filterCity, resultFilter, (elem, res) => elem.id === res.id) : filterCity
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? filterFilter.filter(p => p.labelSearch.includes(searchValueFormat)) : filterFilter
    }, [formattedStations, filter])

    const checkAll = filterStations => {
        onChangeSelectedStation(uniq([...selectedStations, ...filterStations.map(({ id }) => id)]))
    }

    const uncheckAll = filterStations => {
        onChangeSelectedStation(selectedStations.filter(stationId => !filterStations.find(({ id }) => stationId === id)))
    }

    const actionCard = every(filteredStations, s => selectedStations.find(stationId => stationId === s.id)) ? [{
        onClick: () => uncheckAll(filteredStations),
        iconName: 'check_box_outline_blank',
        tooltip: i18n.unselectAll,
    }] : [{
        onClick: () => checkAll(filteredStations),
        iconName: 'check_box',
        tooltip: i18n.selectAll,
    }]

    const nbStationSelected = selectedStations.length
    return (
        <>
            <StyledFieldSet>
                <QualitometerFilterField
                    filter={filter}
                    setFilter={setFilter}
                />
            </StyledFieldSet>
            <Card
                title={`${i18n.stations} (${nbStationSelected} ${getI18nTitleDataLength(i18n.selectedElement, i18n.selectedElements, nbStationSelected)})`}
                actions={actionCard}
                cardStyle={{ marginTop: '10px' }}
                noMargin={false}
            >
                <Table
                    showTitle={false}
                    data={filteredStations}
                    type={{ headers: ['name', 'city', 'codification', 'nullValue'] }}
                    coloredLine
                    maxHeight={maxHeight}
                    onClick={({ id, isSelected }) => onClickStation(id, isSelected)}
                    sortable
                    condensed
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Card>
        </>
    )
}

ValidateStationTab.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    selectedStations: PropTypes.arrayOf(PropTypes.number),
    codifications: PropTypes.arrayOf(PropTypes.shape({
        stationId: PropTypes.number,
        codification: PropTypes.number,
    })),
    onChangeCodifications: PropTypes.func,
    onChangeSelectedStation: PropTypes.func,
    maxHeight: PropTypes.String,
}

export default ValidateStationTab