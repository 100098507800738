import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

const ConfirmModal = ({
    isOpen = false,
    title = '',
    onValidate = () => {},
    onReject = () => {},
    onClose = () => { },
}) => {
    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={() => {
                    onReject()
                    onClose()
                }} variant='outlined' >
                    {i18n.no}
                </Button>
                <Button onClick={onValidate} variant='contained' color='primary'>
                    {i18n.yes}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReject: PropTypes.func,
}

export default ConfirmModal