import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import Row from '../../../../components/react/Row'
import Button from '../../../../components/forms/Button'
import Select from '../../../../components/forms/Select'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import NumberField from '../../../../components/forms/NumberField'
import Checkbox from '../../../../components/forms/Checkbox'
import { hasValue } from '../../../../utils/NumberUtil'
import Input from '../../../../components/forms/Input'
import PropTypes from 'prop-types'
import { getSandreList, removeNullKeys } from '../../../../utils/StoreUtils'
import { getDate, getDateWithHour, getHour } from '../../../../utils/DateUtil'
import HydrometryAction from '../../../actions/HydrometryAction'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { isNull, last } from 'lodash'
import DtoHydroMeasure from '../../../dto/chronicMeasures/DtoHydroMeasure'
import { WhiteCard } from '../../../../components/styled/Card'
import {
    HISTORIC,
    MOVING,
    NEWPOINT,
    POINT,
    VALIDATION,
    WINDOW,
} from '../../../constants/HydrometryConstants'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import MultiContributorsAutocomplete from '../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import DescriptionMeasure from '../validationUtils/DescriptionMeasure'
import useLocalStorage from '../../../../utils/customHook/useLocalStorage'
import useApplicationSetting from '../../../../utils/customHook/useApplicationSetting'
import { onChangeDate, onChangeHour } from '../../../../utils/FormUtils'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import { Collapse } from '@mui/material'

const ValidationHydroToolPanel = ({
    selection,
    setSelection,
    setSelectionData,
    dates,
    value,
    setValue,
    initialPoint,
    setInitialPoint,
    setStartDate,
    setEndDate,
    setIsChange,
    isChange,
    createTechnicalEvent,
    setIsPreview,
    hydroMeasuresFormat,
    setMeasuresChangesValidation,
}) => {
    const dispatch = useDispatch()
    const [subTool, setSubTool] = useState(VALIDATION)
    const [isValidateActive, setIsValidateActive] = useState(false)

    const [seeMoreCriterias, setSeeMoreCriterias] = useState(false)
    const [filterStatus, setFilterStatus] = useState(undefined)
    const [filterQualification, setFilterQualification] = useState(undefined)
    const [filterProducer, setfilterProducer] = useState(undefined)
    const [filterManager, setFilterManager] = useState(undefined)
    const [filterValidator, setFilterValidator] = useState(undefined)
    const [filterSuperior, setFilterSuperior] = useState(undefined)
    const [filterInferior, setFilterInferior] = useState(undefined)


    const [validationFilter, setValidationFilter] = useLocalStorage('HYDRO_VALIDATION_FILTERS', {})
    const {
        status: defaultStatus,
        qualification: defaultQualification,
        nature: defaultNature,
        producer: defaultProducer,
        manager: defaultManager,
        validator: defaultValidator,
        emptyFieldsDontClear: defaultEmptyFieldsDontClear,
    } = validationFilter

    const [status, setStatus] = useState(defaultStatus || undefined)
    const [qualification, setQualification] = useState(defaultQualification || undefined)
    const [nature, setNature] = useState(defaultNature || undefined)
    const [emptyFieldsDontClear, setEmptyFieldsDontClear] = useState(defaultEmptyFieldsDontClear || false)

    const applicationSettingsDefaultProducer = useApplicationSetting('validationDefaultProducerHydro', setting => setting ? parseInt(setting) : undefined)
    const applicationSettingsDefaultManager = useApplicationSetting('validationDefaultManagerHydro', setting => setting ? parseInt(setting) : undefined)
    const applicationSettingsDefaultValidator = useApplicationSetting('validationDefaultValidatorHydro', setting => setting ? parseInt(setting) : undefined)
    const [producer, setProducer] = useState(defaultProducer || applicationSettingsDefaultProducer || undefined)
    const [manager, setManager] = useState(defaultManager || applicationSettingsDefaultManager || undefined)
    const [validator, setValidator] = useState(defaultValidator || applicationSettingsDefaultValidator || undefined)

    const {
        sandreCodes,
        contributors,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)


    useEffect(() => {
        setValidationFilter(changes => ({ ...changes, status, qualification, nature, producer, manager, validator, emptyFieldsDontClear }))
    }, [status, qualification, nature, producer, manager, validator, emptyFieldsDontClear])


    const withStatus = (m) => hasValue(filterStatus) ? m.status === parseInt(filterStatus) : true
    const withQualification = (m) => hasValue(filterQualification) ? m.qualification === filterQualification : true
    const withSuperior = (m) => hasValue(filterSuperior) ? m.value > filterSuperior : true
    const withInferior = (m) => hasValue(filterInferior) ? m.value < filterInferior : true
    const withProducer = (m) => hasValue(filterProducer) ? m.producer === filterProducer : true
    const withManager = (m) => hasValue(filterManager) ? m.manager === filterManager : true
    const withValidator = (m) => hasValue(filterValidator) ? m.validator === filterValidator : true

    const withSuperiorAndInferior = (m) => {
        if (hasValue(filterInferior) && hasValue(filterSuperior)) {
            return m.value < filterInferior || m.value > filterSuperior
        }
        return withInferior(m) && withSuperior(m)
    }

    const filterMeasure = (m) => withStatus(m) && withQualification(m) && withSuperiorAndInferior(m) && withProducer(m) && withManager(m) && withValidator(m)


    const measureToUpdate = useMemo(() => {
        const filterFields = emptyFieldsDontClear ? removeNullKeys : obj => obj
        const obj = filterFields({ status, qualification, producer, manager, nature, validator, initialPoint })
        const measureToChanges = hydroMeasuresFormat.filter(p => p.date >= dates.startDate && p.date <= dates.endDate || p.date === dates.startDate) || []

        const filteredMeasureToChanges = measureToChanges.filter(m => filterMeasure(m))
        const measuresUpdated = filteredMeasureToChanges.map(m => ({
            ...m,
            ...obj,
            value: [POINT, MOVING, NEWPOINT].includes(selection) ? value : m.value,
            updated: true,
        }))
        return measuresUpdated
    }, [hydroMeasuresFormat, dates, initialPoint, manager, nature, producer, qualification, status, validator, value, emptyFieldsDontClear, filterStatus, filterQualification, filterInferior, filterSuperior, filterProducer, filterManager, filterManager])

    const onCancel = () => {
        setIsValidateActive(false)
        setIsPreview(false)
    }

    const onSave = () => {
        createTechnicalEvent({ mode: 'validation' })
        setIsValidateActive(false)
        setIsPreview(false)
        dispatch(HydrometryAction.updateMeasures(measureToUpdate)).then(() => setIsChange(!isChange))
    }

    const onCheckStatusQualification = () => {
        if (isNull(dates.startDate) || isNull(dates.endDate)) {
            if (selection === 'point') {
                dispatch(ToastrAction.warning(i18n.dateNoSelected))
            } else {
                dispatch(ToastrAction.warning(i18n.datesNoSelected))
            }
            return
        }
        setMeasuresChangesValidation(measureToUpdate)
        setIsValidateActive(!isValidateActive)
        setIsPreview(true)
    }

    const selectHisto = () => {
        const m = hydroMeasuresFormat
        setStartDate(m[0].date)
        setEndDate(last(m).date)
    }


    const point = selection === POINT || selection === MOVING && dates.startDate ? hydroMeasuresFormat.find(m => m.date === dates.startDate) : null
    const disabled = { disabled: selection !== POINT && isValidateActive }
    const resetToolValues = () => {
        setStartDate(null)
        setEndDate(null)
    }

    const measureStartDate = hydroMeasuresFormat.find(p => p.date === dates.startDate)
    const selectedMeasures = hydroMeasuresFormat.filter(p => p.date >= dates.startDate && p.date <= dates.endDate).length


    return (
        <WhiteCard title={ i18n.validation } round>
            <div className='padding-top-1' id='validationToolPanel'/>
            <Row className='padding-bottom-1 center-align'>
                <Button
                    tooltip={ i18n.selectRange }
                    className={ `validToolBtn margin-right-1 ${subTool === VALIDATION && selection === WINDOW ? 'activated' : ''}` }
                    onClick={ () => {
                        // this.props.changeParent({ selectionMode: 'start', startDate: null, endDate: null, draggable: false, selectedPoint: null })
                        setSubTool(VALIDATION)
                        setSelection(WINDOW)
                        resetToolValues()
                        setSelectionData('end')
                    } }
                    icon='settings_ethernet'
                    {...disabled}
                />
                <Button
                    tooltip={ i18n.selectHistory }
                    className={ `validToolBtn margin-right-1 ${subTool === VALIDATION && selection === HISTORIC ? 'activated' : ''}` }
                    onClick={ () => {
                        // his.props.changeParent({ draggable: false, selectionMode: null, selectedPoint: null })
                        setSubTool(VALIDATION)
                        setSelection((HISTORIC))
                        resetToolValues()
                        selectHisto()
                    } }
                    icon='insert_chart'
                    {...disabled}
                />
                <Button
                    tooltip={ i18n.selectMeasure }
                    className={ `validToolBtn margin-right-1 ${subTool === VALIDATION && selection === POINT ? 'activated' : ''}` }
                    onClick={ () => {
                        // this.props.changeParent({ selectionMode: 'point', startDate: null, endDate: null, draggable: false, selectedPoint: null })
                        setSubTool(VALIDATION)
                        setSelection(POINT)
                        resetToolValues()
                    }}
                    icon='edit_location'
                    {...disabled}
                />
                {/* <Button tooltip={ i18n.addMeasure } onClick={ () => {
                    // const lastMeasure = last(this.props.measures)
                    // const newDate = lastMeasure ? moment(lastMeasure.date).add(1, 'day').valueOf() : null
                    // this.props.changeParent({ selectionMode: null, startDate: newDate, endDate: newDate, draggable: false, selectedPoint: null })
                    setSubTool('newPoint')
                    setSelection('newPoint')
                    resetToolValues()
                } } icon='add' className={ `validToolBtn margin-right-1 ${subTool === 'newPoint' ? 'activated' : ''}` }
                />*/}
            </Row>

            <Row className='padding-left-1 bold padding-bottom-1'>{ selectedMeasures } { selectedMeasures > 1 ? i18n.selectedMeasures : i18n.selectedMeasure }</Row>
            <StyledFieldSet style={{ margin: 10, backgroundColor: '#d9dfe4' }}>
                <StyledLegend>{i18n.selectionCriteria}</StyledLegend>
                <Row>
                    <Input
                        col={ 6 }
                        title={ [WINDOW, HISTORIC].includes(selection) ? i18n.startDate : i18n.date }
                        id='startDate'
                        value={ getDate(dates.startDate)}
                        onChange={ v => {
                            onChangeDate(v, v2 => {
                                return setStartDate(getDateWithHour(v2, dates.startDate).valueOf())
                            })
                        } }
                        disabled={ isValidateActive }
                    />
                    <Input
                        id='startDateHour'
                        col={ 6 }
                        title={ [WINDOW, HISTORIC].includes(selection) ? i18n.startHour : i18n.hour }
                        value={ getHour(dates.startDate) }
                        onChange={ v => {
                            onChangeHour(v, v2 => {
                                return setStartDate(getDateWithHour(dates.startDate, v2).valueOf())
                            })
                        } }
                        disabled={ isValidateActive }
                    />
                </Row>
                {
                    [WINDOW, HISTORIC].includes(selection) && (
                        <Row >
                            <Input
                                id='endDate'
                                col={ 6 }
                                value={ getDate(dates.endDate) }
                                title={ i18n.endDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => {
                                        return setEndDate(getDateWithHour(v2, dates.endDate).valueOf())
                                    })
                                } }
                                disabled={ isValidateActive }
                            />
                            <Input
                                id='endDateHour'
                                col={ 6 }
                                title={ i18n.endHour }
                                value={ getHour(dates.endDate) }
                                onChange={ v => {
                                    onChangeHour(v, v2 => {
                                        return setEndDate(getDateWithHour(dates.endDate, v2).valueOf())
                                    })
                                } }
                                disabled={ isValidateActive }
                            />
                        </Row>
                    )
                }
                <Collapse in={seeMoreCriterias}>
                    <Row>
                        <Select
                            col={ 6 }
                            label={ i18n.status }
                            value={ filterStatus }
                            noSort
                            onChange={ setFilterStatus }
                            options={ getStatusSelectOptions() }
                            disabled={ isValidateActive }
                        />
                        <Select
                            col={ 6 }
                            label={ i18n.qualification }
                            value={ filterQualification }
                            noSort
                            onChange={ setFilterQualification }
                            options={ getQualificationSelectOptions() }
                            disabled={ isValidateActive }
                        />
                    </Row>
                    <Row>
                        <NumberField
                            col={ 6 }
                            title={i18n.valeurSup}
                            value={ filterSuperior }
                            noSort
                            onChange={ v => setFilterSuperior(v) }
                            floatValue
                            disabled={ isValidateActive }
                        />
                        <NumberField
                            col={ 6 }
                            title={i18n.valeurInf}
                            value={ filterInferior }
                            noSort
                            onChange={ v => setFilterInferior(v) }
                            floatValue
                            disabled={ isValidateActive }
                        />
                    </Row>
                    <Row>
                        <MultiContributorsAutocomplete
                            col={12}
                            label={i18n.producer}
                            values={ filterProducer }
                            onChange={ setfilterProducer }
                            options={ contributors }
                            {...disabled}
                        />
                    </Row>
                    <Row>
                        <MultiContributorsAutocomplete
                            col={6}
                            label={i18n.administrator}
                            onChange={ v => setFilterManager(v) }
                            values={ filterManager }
                            options={ contributors }
                            {...disabled}
                        />
                        <MultiContributorsAutocomplete
                            col={6}
                            label={i18n.validator}
                            values={ filterValidator }
                            onChange={ v => setFilterValidator(v) }
                            options={ contributors }
                            {...disabled}
                        />
                    </Row>
                </Collapse>
                <div style={{ height: 0 }}>
                    <i className='material-icons clickable' style={{
                        border: '1px solid #000',
                        borderRadius: '20px',
                        top: -13,
                        position: 'relative',
                        left: -14,
                        backgroundColor: '#d9dfe4',
                    }} onClick={() => setSeeMoreCriterias(prev => !prev)}
                    >
                        {seeMoreCriterias ? 'expand_less' : 'expand_more'}
                    </i>
                </div>
            </StyledFieldSet>
            <div>
                <Row className='padding-top-1'>
                    <Select
                        col={ 6 }
                        label={ i18n.status }
                        value={ status }
                        noSort
                        onChange={ setStatus }
                        options={ getStatusSelectOptions() }
                        nullLabel={ emptyFieldsDontClear ? i18n.unchanged : undefined }
                        disabled={ isValidateActive }
                    />
                    <Select
                        col={ 6 }
                        label={ i18n.qualification }
                        value={ qualification }
                        noSort
                        onChange={ setQualification }
                        options={ getQualificationSelectOptions() }
                        nullLabel={ emptyFieldsDontClear ? i18n.unchanged : undefined }
                        disabled={ isValidateActive }
                    />
                </Row>
                <Row>
                    <Select
                        col={ 6 }
                        label={ i18n.measureNature }
                        value={ nature }
                        onChange={ setNature }
                        options={ getSandreList(sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true) }
                        nullLabel={ emptyFieldsDontClear ? i18n.unchanged : undefined }
                        disabled={ isValidateActive }
                    />
                    {/* <Select col={ 6 } label={ i18n.obtainningMode } value={ measureMode }
                            onChange={ setMeasureMode }  énullLabel='&nbsp;'
                    /> */}
                </Row>
                <Row>
                    <MultiContributorsAutocomplete
                        col={ 12 }
                        label={ i18n.producer }
                        values={ producer }
                        onChange={ setProducer }
                        options={ contributors }
                        disabled={isValidateActive}
                        placeholder={ emptyFieldsDontClear ? i18n.unchanged : undefined }
                    />
                </Row>
                <Row>
                    <MultiContributorsAutocomplete
                        col={ 6 }
                        label={ i18n.administrator }
                        values={ manager }
                        onChange={ setManager }
                        options={ contributors }
                        disabled={ isValidateActive }
                        placeholder={ emptyFieldsDontClear ? i18n.unchanged : undefined }
                    />
                    <MultiContributorsAutocomplete
                        col={ 6 }
                        label={ i18n.validator }
                        values={ validator }
                        onChange={ setValidator }
                        options={ contributors }
                        disabled={ isValidateActive }
                        placeholder={ emptyFieldsDontClear ? i18n.unchanged : undefined }
                    />
                </Row>
                <Row>
                    <Checkbox
                        col={12}
                        checked={emptyFieldsDontClear}
                        onChange={setEmptyFieldsDontClear}
                        label={i18n.emptyFieldsDontClear}
                        disabled={ isValidateActive }
                    />
                </Row>
                { [POINT, MOVING, NEWPOINT].includes(selection) && (
                    <>
                        <Row className='valign-wrapper'>
                            <NumberField
                                col={ 6 }
                                title={ i18n.valueLabel }
                                value={ value }
                                onChange={ setValue }
                                disabled={ isValidateActive }
                                floatValue
                            />
                            <div className='col s6 padding-top-1'>
                                <Checkbox
                                    label={ i18n.initialPoint }
                                    checked={ hasValue(initialPoint) ? initialPoint === 1 : (point && point.initialPoint === 1) }
                                    onChange={ v => setInitialPoint(v ? 1 : 2) }
                                    disabled={ isValidateActive }
                                />
                            </div>
                        </Row>
                    </>
                ) }
            </div>
            { measureStartDate && (
                <DescriptionMeasure selection={selection} measure={measureStartDate}/>
            )}

            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button
                    tooltip={ i18n.apply }
                    onClick={ onCheckStatusQualification }
                    icon='border_color'
                    className='btn-floating btn-large'
                    disabled={isValidateActive}
                />
                <Button
                    tooltip={ i18n.cancel }
                    onClick={ onCancel }
                    icon='cancel'
                    className='red btn-floating btn-large margin-left-2 margin-right-2'
                    disabled={ !isValidateActive }
                />
                <Button
                    tooltip={ i18n.register }
                    onClick={ onSave }
                    icon='save'
                    disabled={ !isValidateActive }
                    className={ `btn-floating btn-large ${isValidateActive ? 'pulse' : ''}` }
                />
            </Row>
        </WhiteCard>
    )
}
ValidationHydroToolPanel.propTypes = {
    selection: PropTypes.string,
    setSelection: PropTypes.func,
    setSelectionData: PropTypes.func,
    objetHydroStation: PropTypes.instanceOf(DtoHydroMeasure),
    dates: PropTypes.objectOf(PropTypes.number),
    value: PropTypes.number,
    setValue: PropTypes.func,
    initialPoint: PropTypes.number,
    setInitialPoint: PropTypes.func,
    status: PropTypes.number,
    setStatus: PropTypes.func,
    qualification: PropTypes.number,
    setQualification: PropTypes.func,
    nature: PropTypes.string,
    setNature: PropTypes.func,
    // measureMode,
    // setMeasureMode,
    producer: PropTypes.number,
    setProducer: PropTypes.func,
    manager: PropTypes.number,
    setManager: PropTypes.func,
    validator: PropTypes.number,
    setValidator: PropTypes.func,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    setIsChange: PropTypes.func,
    isChange: PropTypes.bool,
    measureToUpdate: PropTypes.instanceOf(DtoHydroMeasure),
    createTechnicalEvent: PropTypes.func,
    setIsPreview: PropTypes.func,
    setValidationFilter: PropTypes.func,
    setMeasuresChangesValidation: PropTypes.func,
}
export default ValidationHydroToolPanel