import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../components/ActionComponent'
import Card from '../../../../components/card/Card'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import { getMapStateToProps, getPropTypes } from '../../../../utils/StoreUtils'
import { getSandreLabel } from '../../../../utils/StringUtil'


const storeProps = {
    sandreCodes: false,
}

class InstallationArrangementDashboard extends ActionComponent {
    getSummary = (arrangements) => {
        if (arrangements && arrangements.length) {
            const space = <span className='padding-left-3'/>
            const data = arrangements.map(a => (<h6>{ getDate(a.startDate) } { space }
                { getSandreLabel(this.props.sandreCodes, SANDRE.ARRANGEMENT_TYPES, a.arrangementType) } { space } { a.descriptive }</h6>))
            return (
                <Card title={ i18n.arrangements } smallCard>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        { data }
                    </div>
                </Card>
            )
        }
        return null
    }

    render() {
        return this.getSummary(this.props.station.link_arrangements)
    }
}

InstallationArrangementDashboard.propTypes = getPropTypes(storeProps, {
    station: PropTypes.object,
})

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(InstallationArrangementDashboard)

