import { groupBy, maxBy, orderBy, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../../../components/datatable/Table'
import CMSCategoryDto from '../../../../../../events/dto/CMSCategoryDto'
import { getDate } from '../../../../../../utils/DateUtil'
import DtoUser from '../../../dto/DtoUser'
import DtoUserCmsStatistic from '../../../dto/DtoUserCmsStatistic'
import DtoUserCmsStatisticItem from '../../../dto/DtoUserCmsStatisticItem'

class CmsStatisticsPanel extends Component {
    render() {
        const login = this.props.user.login || i18n.users
        if (this.props.userCmsStatistics.length) {
            const group = groupBy(this.props.userCmsStatistics, 'value')
            const visits = take(orderBy(Object.keys(group), key => group[key].length, ['desc']), 10).map(key => {
                const allCms = group[key]
                const lastOne = maxBy(allCms, 'statisticDate')
                return new DtoUserCmsStatisticItem({
                    category: this.props.cmsCategories.find(c => c.id == lastOne.module)?.title,
                    title: lastOne.value,
                    visitsCount: allCms.length,
                    lastAccess: getDate(lastOne.statisticDate),
                })
            })
            return (
                <div className='row no-margin card'>
                    <div className='col s12 no-padding'>
                        <Table title={ `10 ${i18n.userCmsStatistics}` } sortable
                            type={ new DtoUserCmsStatisticItem({}) } data={ visits }
                            exportButtonOnHeader exportData={ visits }
                            showNbElements={ false }
                            exportName={ i18n.userCmsStatisticsExport + login }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

CmsStatisticsPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userCmsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserCmsStatistic)),
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
}
const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
        cmsCategories: store.EventsReducer.cmsCategories,
    }
}
export default connect(mapStateToProps)(CmsStatisticsPanel)