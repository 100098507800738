module.exports = {
    RESET_ALL_MATERIELS: 'RESET_ALL_MATERIELS',
    MAP_PANEL: 'MAP_PANEL',
    LIST_PANEL: 'LIST_PANEL',
    MATERIEL_TYPES: [
        { code: 'CENTRAL', id: 1, link: 'central', labelKey: 'central' },
        { code: 'SENSOR', id: 2, link: 'sensor', labelKey: 'sensor' },
        { code: 'POWER_SUPPLY', id: 3, link: 'powerSupply', labelKey: 'powerSupply' },
        { code: 'TELECOM', id: 6, link: 'telecom', labelKey: 'telecom' },
        { code: 'SIM', id: 4, link: 'sim', labelKey: 'sim' },
        { code: 'VARIOUS', id: 5, link: 'variousMateriel', labelKey: 'variousMateriel' },
        { code: 'EQUIPMENT', id: 7, link: 'equipment', labelKey: 'equipment' },
        { code: 'SUBSCRIPTION', id: 8, link: 'subscription', labelKey: 'subscription' },
    ],
    MATERIEL_ASSIGNMENT_STATES: [
        { code: 1, label: 'onSite' },
        { code: 2, label: 'inStock' },
        { code: 3, label: 'maintenance' },
    ],
    MAT_CENTRAL: 'central',
    MAT_POWER_SUPPLY: 'powerSupply',
    MAT_SENSOR: 'sensor',
    MAT_TELECOM: 'telecom',
    MAT_SIM: 'sim',
    MAT_DIVERS: 'variousMateriel',
    MAT_EQUIPMENT: 'equipment',
    MAT_SUBSCRIPTION: 'subscription',
    CENTRAL: 'CENTRAL',
    SIM: 'SIM',
    TELECOM: 'TELECOM',
    SENSOR: 'SENSOR',
    POWER_SUPPLY: 'POWER_SUPPLY',
    VARIOUS: 'VARIOUS',
    EQUIPMENT: 'EQUIPMENT',
    SUBSCRIPTION: 'SUBSCRIPTION',
    POWER_SUPPLY_PROBLEM: 'POWER_SUPPLY_PROBLEM',
    CENTRAL_ID: 1,
    SENSOR_ID: 2,
    POWER_SUPPLY_ID: 3,
    SIM_ID: 4,
    VARIOUS_ID: 5,
    TELECOM_ID: 6,
    EQUIPMENT_ID: 7,
    SUBSCRIPTION_ID: 8,
    MAT_HEADERS: ['type', 'manufacturer', 'serialNumber', 'immoNum', 'purchaseDate', 'state', 'stateDate', 'assignment', 'administrator'],
}
