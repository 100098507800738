export default class DtoPluvioMeasureObj {
    constructor(obj) {
        this.pluvioId = obj.pluvioId // Int,
        this.dataType = obj.dataType // Int,
        this.date = obj.date // DateTime,
        this.initialValue = obj.initialValue // Option[Double],
        this.value = obj.value // Option[Double],
        this.measureMode = obj.measureMode // Option[Int],
        this.status = obj.status // Option[Int],
        this.qualification = obj.qualification // Option[Int],
        this.nature = obj.nature // Option[String],
        this.initialPoint = obj.initialPoint // Option[Int],
        this.validator = obj.validator // Option[Int],
        this.manager = obj.manager // Option[Int],
        this.producer = obj.producer // Option[Int],
        this.jobExecutionId = obj.jobExecutionId // Option[Int],
        this.importDate = obj.importDate // Option[DateTime],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String]
    }
}