import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Table from 'components/datatable/Table'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabelShort, SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useArray from 'utils/customHook/useArray'
import { hasValue } from 'utils/NumberUtil'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import FractionAction from '../../../../referencial/components/fraction/actions/FractionAction'
import FractionDto from '../../../../referencial/components/fraction/dto/FractionDto'
import SupportAction from '../../../../referencial/components/support/actions/SupportAction'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import { setModal } from '../../../../utils/FormUtils'
import Job from '../../../dto/DtoJob'
import ImportQualityDefaultValue from '../ImportQualityDefaultValue'
import ImportFileModal from './ImportFileModal'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { createIndex } from '../../../../utils/StoreUtils'
import { orderBy } from 'lodash'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import SimpleTextArea from '../../../../components/forms/SimpleTextArea'

const getRefType = (t) => {
    if (t.startsWith('STQ')) {
        return 'Station'
    }
    if (t.startsWith('OPP')) {
        return 'Opération'
    }
    if (t.startsWith('PRL')) {
        return 'Prélèvement'
    }
    if (t.startsWith('ALQ')) {
        return 'Analyse'
    }
    if (t.startsWith('SUP')) {
        return 'Support'
    }
    if (t.startsWith('POI')) {
        return 'Point de prélèvement'
    }
    if (t.startsWith('RES')) {
        return 'Réseau'
    }
    if (t.startsWith('IND')) {
        return 'Intervenant'
    }
    if (t.startsWith('Indices')) {
        return 'Intervenant'
    }
    return ''
}

const ImportPiveauPanel = ({
    job = {},
    isEditMode = false,
    handleChangeParameters = () => { },
}) => {
    const {
        contributors,
        qualifications,
        status,
        fractions,
        supports,
        qualityCampaigns,
        sandreCodes,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const parameter = job?.parameters?.parameters?.length ? JSON.parse(job.parameters.parameters[0]) : {}

    const [directory, setDirectory] = useState(parameter.directory || '')
    const [rename, setRename] = useState(parameter.rename || false)
    const [importMode, setImportMode] = useState(parameter.importMode || false)
    const [create, setCreate] = useState(parameter.create || false)
    const [campaignId, setCampaignId] = useState(parameter.campaignId)
    const [sheet, setSheet] = useState(parameter.sheet)
    const [filter, setFilter] = useState(parameter.filter || '')
    const [fileLink, { add, setArray, update }] = useArray(parameter.fileLink || [])
    const [nbHeaders, setNbHeaders] = useState(parameter.nbHeaders || undefined)
    const [separator, setSeparator] = useState(parameter.separator || undefined)
    const [lineCondition, setLineCondition] = useState(parameter.lineCondition || undefined)
    const [lineValue, setLineValue] = useState(parameter.lineValue?.join('|') || undefined)
    const [dontProcessFilesInLogs, setDontProcessFilesInLogs] = useState(parameter.dontProcessFilesInLogs || false)
    const [isOpen, setIsOpen] = useState(false)
    const [column, setColumn] = useState()
    const [reference, setReference] = useState('')
    const [currentId, setCurrentId] = useState()
    const [searchValue, setSearchValue] = useState()
    const [defaultValue, setDefaultValue] = useState({
        sampler: parameter.sampler,
        producer: parameter.producer,
        labo: parameter.labo,
        support: parameter.support,
        fraction: parameter.fraction,
        status: parameter.status,
        qualification: parameter.qualification,
        propTypes: parameter.propTypes,
        stationCodePrefix: parameter.stationCodePrefix,
    })

    useEffect(() => {
        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
        if (!qualifications.length) {
            dispatch(QualityAction.fetchQualifications())
        }
        if (!status.length) {
            dispatch(QualityAction.fetchStatus())
        }
        if (!fractions.length) {
            dispatch(FractionAction.fetchFractions())
        }
        if (!supports.length) {
            dispatch(SupportAction.fetchSupports())
        }
        dispatch(CampaignAction.fetchQualityCampaigns())
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
    }, [])

    const setAdvancedFtpModal = () => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={directory} onChange={setFilter} />,
        })
    }

    useEffect(() => {
        handleChangeParameters([JSON.stringify({
            directory,
            rename,
            importMode,
            create,
            campaignId,
            sheet,
            filter,
            fileLink,
            nbHeaders,
            separator,
            dontProcessFilesInLogs,
            lineCondition,
            lineValue: lineValue && lineValue.split('|') || undefined,
            ...defaultValue,
        })])
    }, [campaignId, sheet, create, defaultValue, directory, fileLink, filter, handleChangeParameters, importMode, rename, nbHeaders, separator, dontProcessFilesInLogs, lineCondition, lineValue])

    const separators = useMemo(() => [
        {
            code: 'comma',
            name: i18n.comma,
        },
        {
            code: 'semicolon',
            name: i18n.semicolon,
        },
        {
            code: 'tabulation',
            name: i18n.tabulation,
        },
        {
            code: 'pipe',
            name: i18n.pipe,
        },
        {
            code: 'space',
            name: i18n.space,
        },
    ], [])

    const allRefs = sandreCodes.filter(c => c.field === SANDRE.REFS_PIVEAU).map(sc => ({
        ...sc,
        code: sc.reference,
        name: `[${getRefType(sc.reference)}] ${sc.name}`,
        color: sc.reference === reference ? '#26a69a' : undefined,
    }))
    const refNames = createIndex(allRefs, 'code')
    const orederedFileLink = orderBy(fileLink, 'column')

    const fileLinkFormatted = orederedFileLink.map((f, idx) => ({
        column: { value: f.column, color: orederedFileLink.some((v, idx2) => v.column === f.column && idx !== idx2) ? 'red' : undefined },
        reference: { value: f.reference, color: orederedFileLink.some((v, idx2) => v.reference === f.reference && idx !== idx2) ? 'red' : undefined },
        name: { value: refNames[f.reference]?.name ?? '' },
    }))

    const allRefsFiltered = allRefs.filter(r => ['code', 'name'].some(key => searchAllCharacters(r[key] ?? '').includes(searchAllCharacters(searchValue ?? '')))).map(r => ({
        reference: { value: r.reference, color: r.color },
        name: { value: r.name, color: r.color },
    }))

    return (
        <div>
            <br />
            <div className='row no-margin valign-wrapper'>
                <Select
                    value={separator}
                    options={separators}
                    label={i18n.separatorType}
                    col={3}
                    onChange={setSeparator}
                    disabled={!isEditMode}
                />
                <NumberField
                    col={3}
                    title={i18n.nbHeaders}
                    min={0}
                    value={nbHeaders}
                    onChange={setNbHeaders}
                    disabled={!isEditMode}
                />
                <div className='col s2'>
                    <Checkbox
                        checked={create}
                        label={i18n.stationCreate}
                        onChange={setCreate}
                        disabled={!isEditMode}
                    />
                </div>
                <div className='col s4'>
                    <Select options={ getIntegrationModes() } obligatory={true} label={ i18n.dataIntegration } value={ importMode } labelSpan={ integrationModeHelpIcon(true) }
                        onChange={ setImportMode }
                        disabled={ !isEditMode }
                    />
                </div>
            </div>
            <div className='row no-margin padding-top-1 valign-wrapper'>
                <Input
                    col={3}
                    title={i18n.selectDirectory}
                    value={directory}
                    obligatory
                    onChange={setDirectory}
                    disabled={!isEditMode}
                />
                <div className='col s1'>
                    <a className={`btn btn-small secondary-color-back${isEditMode ? '' : ' disabled'}`} onClick={() => setAdvancedFtpModal()} ><span title={i18n.importLabelFtp}> <i className='material-icons'>input </i> </span></a>
                </div>
                <Input
                    col={2}
                    title={i18n.filter}
                    value={filter}
                    obligatory
                    onChange={setFilter}
                    disabled={!isEditMode}
                />
                <div className='col s2'>
                    <Checkbox
                        checked={rename}
                        label={i18n.fileRenaming}
                        onChange={setRename}
                        disabled={!isEditMode}
                    />
                </div>
                <div className='col s4'>
                    <Checkbox
                        checked={dontProcessFilesInLogs}
                        label={i18n.dontProcessFilesInLogs}
                        onChange={setDontProcessFilesInLogs}
                        disabled={!isEditMode}
                    />
                </div>
            </div>
            <div className='row no-margin padding-top-1'>
                <Select
                    value={campaignId}
                    keyvalue='id'
                    options={qualityCampaigns}
                    label={i18n.campaign}
                    col={4}
                    onChange={setCampaignId}
                    disabled={!isEditMode}
                />
                <Input
                    value={sheet}
                    title={i18n.excelTab}
                    col={2}
                    onChange={setSheet}
                    disabled={!isEditMode}
                />
            </div>
            <ImportQualityDefaultValue
                defaultValue={defaultValue}
                onChange={obj => setDefaultValue(prev => ({ ...prev, ...obj }))}
                isEditMode={isEditMode}
            />
            <fieldset>
                <legend>{'Lignes à intégrer'}</legend>
                <div>
                    <div className='row no-margin padding-top-1'>
                        <NumberField
                            col={1}
                            title={i18n.condition}
                            value={lineCondition}
                            onChange={setLineCondition}
                            disabled={!isEditMode}
                        />
                        <Input
                            col={5}
                            title={i18n.valueSeparateBy}
                            value={lineValue}
                            onChange={setLineValue}
                            disabled={!isEditMode}
                        />
                    </div>
                </div>
            </fieldset>
            <div className='row padding-top-1'>
                <Table
                    title={i18n.fileLink}
                    data={fileLinkFormatted}
                    type={{ headers: ['column', 'name', 'reference'] }}
                    nbPerPageLabel={nbPerPageLabelShort}
                    deletable={isEditMode}
                    onDelete={f => {
                        setArray(fileLink.filter(f2 => f.column.value !== f2.column || f.reference.value !== f2.reference))
                    }}
                    alterable={isEditMode}
                    onAlter={(elem, id) => {
                        setIsOpen(true)
                        setColumn(elem.column?.value)
                        setReference(elem.reference?.value)
                        setCurrentId(id)
                    }}
                    sortable
                    condensed
                    paging
                    overflow='visible'
                    actions={isEditMode ? [{
                        onClick: () => {
                            setIsOpen(true)
                            setColumn(undefined)
                            setReference(undefined)
                        },
                        iconName: 'add_box',
                        tooltip: `${i18n.addAlert}`,
                    }] : []}
                    activeHeader
                    color
                    noHightlight
                />
            </div>
            <Dialog
                onClose={() => setIsOpen(false)}
                fullWidth
                maxWidth='xl'
                open={isOpen}
            >
                <DialogTitle>{i18n.fileLink}</DialogTitle>
                <DialogContent>
                    <div className='row padding-top-1'>
                        <div className='col s8'>
                            <Table
                                title={i18n.fileLink}
                                data={allRefsFiltered}
                                type={{ headers: ['name', 'reference'] }}
                                nbPerPageLabel={nbPerPageLabelShort}
                                sortable
                                condensed
                                paging
                                overflow='visible'
                                onClick={r => setReference(r.reference?.value)}
                                color
                                noHightlight
                            />
                        </div>
                        <div className='col s4'>
                            <div className='row'>
                                <Input
                                    title={i18n.search}
                                    value={searchValue}
                                    onChange={setSearchValue}
                                />
                            </div>
                            <div className='row'>
                                <SimpleTextArea
                                    title={i18n.comment}
                                    value={allRefs.find(r => r.code === reference)?.comment?.replace('\\n', '\n')}
                                    disabled
                                />
                            </div>

                        </div>
                    </div>
                    <div className='row padding-top-1'>
                        <div className='col s2'/>
                        <NumberField
                            col={4}
                            title={i18n.column}
                            value={column}
                            onChange={setColumn}
                        />
                        <Input
                            col={4}
                            title={i18n.reference}
                            value={reference}
                            onChange={setReference}
                        />
                        <div className='col s2'/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)} variant='outlined'>
                        {i18n.cancel}
                    </Button>
                    <Button
                        onClick={() => {
                            if (hasValue(column) && reference) {
                                setIsOpen(false)
                                if (hasValue(currentId)) {
                                    update(currentId, { column, reference })
                                    setCurrentId(undefined)
                                } else {
                                    add({ column, reference })
                                }
                            } else {
                                dispatch(ToastrAction.warning(i18n.pleaseCompleteAllField))
                            }
                        }}
                        variant='contained'
                        color='primary'
                    >
                        {i18n.add}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

ImportPiveauPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeDataTypes: PropTypes.func.isRequired,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    qualifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualification)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    fetchContributors: PropTypes.func,
    fetchQualifications: PropTypes.func,
    fetchStatus: PropTypes.func,
    fetchFractions: PropTypes.func,
    fetchSupports: PropTypes.func,
    fetchQualityCampaigns: PropTypes.func
}

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchQualifications: QualityAction.fetchQualifications,
    fetchStatus: QualityAction.fetchStatus,
    fetchFractions: FractionAction.fetchFractions,
    fetchSupports: SupportAction.fetchSupports,
    fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
}

export default connect(null, mapDispatchToProps)(ImportPiveauPanel)
