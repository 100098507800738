import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { mainBlue } from 'utils/constants/ColorTheme'
import { sieauTooltip } from 'utils/FormUtils'

const SimpleTabList = ({
    defaultTab,
    tabs = [],
    children,

    style = {},
}) => {
    const [tab, setTab] = useState(defaultTab)

    return (
        <>
            <Grid container style={{ justifyContent: 'flex-end' }}>
                <Grid item style={{ marginRight: 4, marginTop: 10, padding: 0 }}>
                    <Grid container className='tabs round-tab' style={{ borderRadius: '5px 5px 0 0' }}>
                        {tabs.map(({ label, icon, constant }, i) => (
                            <Grid
                                item
                                className={`tab ${tabs.length - 1 !== i ? 'hash-tab' : ''}`}
                                onClick={() => setTab(constant)}
                                {...sieauTooltip(label)}
                                key={constant}
                            >
                                <a className={`${constant === tab ? 'active' : ''}`} style={{ color: constant === tab ? 'white' : 'black', backgroundColor: constant !== tab && 'white' }}>
                                    <i className='material-icons inherited-line-height'>{icon}</i>
                                </a>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <div
                style={{
                    border: `2px solid ${mainBlue}`,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    ...style,
                }}
            >
                {children(tab)}
            </div>
        </>
    )
}

SimpleTabList.propTypes = {
    defaultTab: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })).isRequired,
    children: PropTypes.func.isRequired,

    style: PropTypes.shape({}),
}

export default SimpleTabList
