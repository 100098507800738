import fetch from 'isomorphic-fetch'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import i18n from 'simple-react-i18n'
import { chunk, flatten } from 'lodash'

import { checkAuth, checkError, getAuthorization, getJson, promiseAllProgress } from '../../../../utils/ActionUtils'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { RECEIVE_QUALITOMETER_BY_TAXON, RECEIVE_TAXON, RECEIVE_TAXONS, RESET_TAXON } from '../constants/TaxonConstant'
import LogAction from '../../../../log/actions/LogAction'
import { push } from 'connected-react-router'
import { PATH_REFERENCIAL_TAXON_LINK } from 'home/constants/RouteConstants'

const TaxonAction = {

    receiveTaxons: taxons => ({ type: RECEIVE_TAXONS, taxons }),

    promiseTaxons: () => fetch(ApplicationConf.referencial.taxons(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchTaxons: () => dispatch => {
        return TaxonAction.promiseTaxons()
            .then((json = []) => {
                dispatch(TaxonAction.receiveTaxons(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError}${i18n.taxons} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.taxons))
            })
    },

    fetchSynchroSandreTaxon() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateTaxon(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(TaxonAction.fetchTaxons())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.taxons + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.taxons))
                })
        }
    },

    receiveTaxon: taxon => ({ type: RECEIVE_TAXON, taxon }),

    fetchTaxon: code => dispatch => fetch(ApplicationConf.referencial.taxon(code), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json = []) => {
            dispatch(TaxonAction.receiveTaxon(json))
            return json
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.taxons} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.taxons))
        }),

    createTaxon(taxon) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.taxons(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(taxon),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(TaxonAction.fetchTaxons())
                        dispatch(push(PATH_REFERENCIAL_TAXON_LINK + taxon.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.taxons} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.taxons))
                })
        }
    },

    updateTaxon(taxon) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.taxons(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(taxon),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(TaxonAction.fetchTaxon(taxon.code))
                        dispatch(TaxonAction.fetchTaxons())
                        dispatch(ToastrAction.success(i18n.taxonSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.taxons} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.taxons))
                })
        }
    },

    reset: () => ({ type: RESET_TAXON }),

    receiveQualitometersByTaxon: qualitometers => ({ type: RECEIVE_QUALITOMETER_BY_TAXON, qualitometers }),

    promiseQualitometersByTaxon: (codeTaxon, codeQualito) => fetch(ApplicationConf.qualitometer.qualitometerByTaxon(codeTaxon, codeQualito), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchQualitometersByTaxon: (codeTaxon, codeQualito, progressCallback = () => { }) => dispatch => {
        const group = codeQualito.length / 20 < 50 ? Math.floor(codeQualito.length / 20) : 50
        const promises = chunk(codeQualito, group > 0 ? group : 1).map(codeGroup =>
            TaxonAction.promiseQualitometersByTaxon(codeTaxon, codeGroup))

        return promiseAllProgress(promises, progressCallback).then(jsonResults =>
            dispatch(TaxonAction.receiveQualitometersByTaxon(flatten(jsonResults)))
        )
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    fetchImportTaxonXML(fichier, insertOrUpdate = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadXmlTaxon(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent: fichier, insertOrUpdate }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertTaxons > 0 || json.updateTaxons > 0) {
                        dispatch(ToastrAction.success(`${i18n.taxon}<br/> ${i18n.insertNumber} : ${json.insertTaxons}<br/>${i18n.updateNumber} : ${json.updateTaxons}`))
                        dispatch(TaxonAction.fetchTaxons())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.taxon + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchImportTaxonCSV(fichier, insertOrUpdate = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadCsvTaxon(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent: fichier, insertOrUpdate }),
            })
                .then(checkAuth)
                .then(checkError)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertTaxons > 0 || json.updateTaxons > 0) {
                        dispatch(ToastrAction.success(`${i18n.taxon}<br/> ${i18n.insertNumber} : ${json.insertTaxons}<br/>${i18n.updateNumber} : ${json.updateTaxons}`))
                        dispatch(TaxonAction.fetchTaxons())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.taxon + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },
}

export default TaxonAction
