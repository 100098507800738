import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import SelectParameter from '../SelectParameter'
import UpdateParameter from '../UpdateParameter'


const SELECT_PARAM = 1
const UPDATE_PARAM = 2

class ParameterStepper extends Component {
    state = {
        step: SELECT_PARAM,
    }

    prevStep = () => {
        if (this.state.step === SELECT_PARAM) {
            this.props.onClose()
        } else {
            this.setState({ step: SELECT_PARAM })
        }
    }

    nextStep = () => {
        if (this.state.step === UPDATE_PARAM) {
            this.setState({ step: SELECT_PARAM })
            this.props.onValidate()
        } else {
            this.setState({ step: UPDATE_PARAM })
        }
    }

    render = () => {
        const {
            step,
        } = this.state
        const {
            onChange,
            onClose,
            isOpen,
            selectedParameters,
        } = this.props

        return (
            <Dialog
                onClose={onClose}
                fullWidth
                maxWidth='xl'
                open={isOpen}
            >
                <DialogTitle>{`${i18n.step} ${step}: ${step === SELECT_PARAM ? i18n.parametersSelection : i18n.parametersModification}`}</DialogTitle>
                <DialogContent>
                    <div style={{ height: '70vh' }}>
                        {
                            step === SELECT_PARAM && (
                                <SelectParameter
                                    selectedParameters={selectedParameters}
                                    onChange={onChange}
                                />
                            )
                        }
                        {
                            step === UPDATE_PARAM && (
                                <UpdateParameter
                                    selectedParameters={selectedParameters}
                                    onChange={onChange}
                                />
                            )
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.prevStep} variant='outlined' >
                        {step === SELECT_PARAM ? i18n.close : i18n.previous}
                    </Button>
                    <Button onClick={this.nextStep} variant='contained' color='primary'>
                        {step === UPDATE_PARAM ? i18n.validate : i18n.next}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ParameterStepper.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onChange: PropTypes.func,

    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
})

export default connect(mapStateToProps)(ParameterStepper)