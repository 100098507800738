import React, { useEffect, useMemo, useState } from 'react'
import PluviometryAction from '../../../actions/PluviometryAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Bar from '../../../../components/echart/series/Bar'
import i18n from 'simple-react-i18n'
import Axis from '../../../../components/echart/Axis'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisLabelInterval,
    toEchartsData,
} from '../../../../components/echart/EChartUtils'
import { maxBy, minBy, partition, round } from 'lodash'
import EChart from '../../../../components/echart/EChart'
import MessageCard from '../../../../components/card/MessageCard'
import ProgressCard from '../../../../components/card/ProgressCard'
import { getFullDate, getMonthYear, getYear, getYearDate } from '../../../../utils/DateUtil'
import PropTypes from 'prop-types'
import PluviometerDto from '../../../dto/PluviometerDto'
import { WhiteCard } from '../../../../components/styled/Card'
import { exportFile } from '../../../../utils/ExportDataUtil'

const MONTHS = 'MONTHS'
const YEARS = 'YEARS'
const NORMALE_MONTH = 'NORMALE_TOZERO_MONTH'
const NORMALE_YEAR = 'NORMALE_TOZERO_YEAR'
const NormalsGraphPluvio = ({
    pluviometer,
    typeGroupFunc = MONTHS,
}) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)
    const maxDate = moment().valueOf()
    const height = 450

    const dispatch = useDispatch()

    const groupFuncSelection = () => {
        switch (typeGroupFunc) {
            case MONTHS:
                return NORMALE_MONTH
            case YEARS :
                return NORMALE_YEAR
            default:
                return NORMALE_MONTH
        }
    }

    useEffect(()=> {
        setDataLoaded(false)
        const inputsMonths = {
            stationId: pluviometer.id,
            dataType: 1,
            groupFunc: groupFuncSelection(),
            chartMode: true,
        }
        dispatch(PluviometryAction.loadPluvioChronicMeasures([inputsMonths], p => {
            setProgress(p)
        })).then(() => {
            setDataLoaded(true)
        })
    }, [typeGroupFunc])

    const {
        pluvioMeasures,
    } = useSelector(store => ({
        pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    }), shallowEqual)


    const getBarNormals = (data, color) => {
        return Bar({
            name: i18n.rain,
            barWidth: typeGroupFunc === YEARS ? 4 : 2,
            data: toEchartsData(data),
            areaStyle: {},
            color,
        }
        )
    }

    const tooltipDate = (params) => {
        switch (typeGroupFunc) {
            case YEARS :
                return getYearDate(params)
            case MONTHS :
                return getMonthYear(params)
            default :
                return getFullDate(params)
        }
    }
    const getTooltip = () => {
        return {
            trigger: 'axis',
            formatter: params => {
                const paramsFormat = params[0]
                const value = paramsFormat.data.value[1]
                const date = tooltipDate(params[0].data.value[0])
                const { marker, seriesName } = paramsFormat
                const result = `${marker} ${seriesName} : ${round(value, 2)} ${i18n.milimetersSymbol}`
                return `${date}<br />${result}`
            },
        }
    }

    const pluvioMeasuresFormat = pluvioMeasures[0]?.measures
    const pluvioMeasuresFormatExport = useMemo(() => {
        return pluvioMeasuresFormat.filter(d => d.date >= pluvioMeasuresFormat[0].date && d.date <= maxDate).map(d => ({
            stationCode: { value: pluviometer.code },
            stationName: { value: pluviometer.name },
            date: { value: getFullDate(d.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: round(d.value, 2), format: '0.00', cellType: 'number' },
            type: { value: `${typeGroupFunc === MONTHS ? i18n.normalMonths : i18n.normalYears}` },
            headers: ['stationCode', 'stationName', 'date', 'value', 'type'],
        }))
    }, [pluvioMeasuresFormat, maxDate, pluviometer.code, pluviometer.name, typeGroupFunc])

    const [tabDataPositive, tabDataNegative] = partition(pluvioMeasuresFormat, m => m.value > 0)
    const series = [getBarNormals(tabDataPositive, 'blue'), getBarNormals(tabDataNegative, 'red')]

    const seriesChart = series.filter(s => !!s).flat()
    const axisLabelObj = getAxisLabelInterval(moment(), 0)

    const options = {
        title: `[${pluviometer.code}] - ${pluviometer.name} `,
        series: seriesChart,
        tooltip: getTooltip(),
        grid: {
            top: 45,
            right: '10%',
            height: height - 100,
            left: 120,
        },
        xAxis: [Axis({
            type: 'time',
            position: 'bottom',
            min: pluvioMeasuresFormat[0].date,
            max: maxDate,
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
        })],
        yAxis: [Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.rain} [${i18n.milimetersSymbol}]`,
            nameGap: 40,
            showSplitLine: true,
            min: undefined,
        })],
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        toolbox: {
            show: true,
            feature: {
                restore: { title: i18n.restore },
                saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                myToolExport: {
                    show: true,
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        exportFile({
                            data: pluvioMeasuresFormatExport,
                            exportType: 'xlsx',
                            titleFile: i18n.overview,
                        })
                    },
                },
            },
            right: 65,
        },
        height,
    }

    if (!pluvioMeasuresFormat.length) {
        return (
            <div style={{ marginTop: 20 }}>
                <MessageCard>
                    { i18n.noData }
                </MessageCard>
            </div>
        )
    }
    const maxMeasure = maxBy(pluvioMeasuresFormat, 'value')
    const minMeasure = minBy(pluvioMeasuresFormat, 'value')
    const minV = minMeasure.value
    const maxV = maxMeasure.value
    const minVdate = minMeasure.date
    const maxVdate = maxMeasure.date
    return (
        <div className='col s12 row no-margin'>
            <div className={'card'} style={{ marginTop: 15 }}>
                { !pluvioMeasuresFormat.length && (
                    <div style={{ marginTop: 20 }}>
                        <MessageCard>
                            { i18n.noData }
                        </MessageCard>
                    </div>
                )}
                { !dataLoaded && (
                    <ProgressCard progress={progress} />
                )}
                { dataLoaded && pluvioMeasuresFormat.length && (
                    <>
                        <EChart options={options} id='graphNormalsPluvioChart' />
                        <div style={{ paddingTop: 20, paddingLeft: 20, paddingBottom: 20, width: 400 }}>
                            <WhiteCard
                                title={i18n.statistics}
                            >
                                <div style={{ padding: 5 }}>
                                    <div>{ typeGroupFunc === MONTHS ? i18n.monthlyMax : i18n.yearsMax } : {maxV} {i18n.milimetersSymbol}</div>
                                    <div>{i18n.date} : {getYear(maxVdate)}</div>
                                    <div className='divider'/>
                                    <div>{ typeGroupFunc === MONTHS ? i18n.monthlyMin : i18n.yearsMax } : {minV} {i18n.milimetersSymbol}</div>
                                    <div>{i18n.date} : {getYear(minVdate)}</div>
                                </div>
                            </WhiteCard>
                        </div>
                    </>
                )}

            </div>
        </div>
    )
}

NormalsGraphPluvio.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    typeGroupFunc: PropTypes.string,
}

export default NormalsGraphPluvio