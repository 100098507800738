import fetch from 'isomorphic-fetch'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import {
    DELETE_FILTER,
    DELETE_PIEZOMETER,
    INDUSTRIAL_SITE,
    POLLUTED_SOIL,
    RECEIVE_ASSOCIATED_SITES,
    RECEIVE_CONTRIBUTOR_LINKS,
    RECEIVE_EXPORT_JOBS_TMP,
    RECEIVE_EXTERNAL_SITES,
    RECEIVE_FILES_FTP,
    RECEIVE_FILTER_RESULTS,
    RECEIVE_FILTERS,
    RECEIVE_PIEZOMETER,
    RECEIVE_SITES_TYPES,
    RECEIVE_STATION_FILES,
    RECEIVE_STATION_PICTURES,
    RESET_STATION,
    SELECT_FILTER,
    STATION_TYPE_NAME,
    UPDATE_PIEZOMETER,
    RECEIVE_MAP_SITUATIONS,
    RECEIVE_MAP_SITUATIONS_RESULTS,
    RESET_ASSOCIATED_SITES,
} from '../constants/StationConstants'
import { RECEIVE_STATION_WATERMASS } from '../constants/WaterMassConstants'
import { RECEIVE_ALL_NATURE, RECEIVE_NATURE } from '../constants/NatureConstants'
import { RECEIVE_QUALITOMETER_NETWORK_ALL } from '../constants/NetworkConstants'
import {
    RECEIVE_ALTIMETRIC_SYSTEM_ALTIMETRIC_SYSTEM,
    RECEIVE_ALTIMETRIC_SYSTEM_NATURE,
    RECEIVE_ALTIMETRIC_SYSTEM_PRODUCTION_MODE,
} from '../constants/AltimetricSystemConstants'
import { RECEIVE_LANDMARKS_PRODUCTION_MODE } from '../constants/LandmarkConstants'
import { RECEIVE_ALL_QUALITOMETER_TYPE, RECEIVE_ALL_TYPE } from '../constants/QualitometerTypeConstants'
import { RECEIVE_ALL_STATE } from '../constants/StateConstants'
import { RECEIVE_ALL_QUALITY } from '../constants/QualityConstants'
import { RECEIVE_ALL_FIELD_MODE } from '../constants/FieldModeConstants'
import { RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES } from '../constants/HydrogeologicalEntityConstants'
import { RECEIVE_ALL_QUALITOMETER_REPRESENTIVENESS } from '../constants/QualitometerRepresentivenessConstants'
import {
    ADD_ALL_SELECTED_PROBLEM,
    ADD_ALL_SELECTED_SOLUTION,
    ADD_SELECTED_LINK,
    ADD_SELECTED_PROBLEM,
    RECEIVE_ALL_EVENT_PROBLEM_SOLUTION_LINKS,
    RECEIVE_ALL_EVENT_PROBLEMS,
    RECEIVE_ALL_EVENT_SOLUTIONS,
    REMOVE_SELECTED_PROBLEM,
    REMOVE_SELECTED_SOLUTION,
    RESET_SELECTED_PROBLEM,
    RESET_SELECTED_SOLUTION,
} from '../constants/ProblemSolutionEventConstants'

import ApplicationConf from '../../conf/ApplicationConf'
import DtoNetwork from 'station/dto/DtoNetwork'
import DtoNature from '../dto/DtoNature'
import DtoWaterMass from 'station/dto/DtoWaterMass'
import OperationAction from '../../quality/components/operation/actions/OperationAction'
import AppStore from 'store/AppStore'
import { chunk, flatten, isEmpty, reduce } from 'lodash'
import {
    checkAuth,
    checkError, genericCreate, genericDelete, genericFetch,
    genericPromise, genericUpdate,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import SieauAction from '../../components/sieau/SieauAction'
import {
    RECEIVE_STATION_CENTRAL,
    RECEIVE_STATION_CENTRAL_ASSIGNMENT,
    RECEIVE_STATION_CENTRAL_PATH,
    RECEIVE_STATION_CENTRAL_RANGE,
    RECEIVE_STATION_EQUIPMENT,
    RECEIVE_STATION_EQUIPMENT_ASSIGNMENT,
    RECEIVE_STATION_POWER_SUPPLY,
    RECEIVE_STATION_POWER_SUPPLY_ASSIGNMENT,
    RECEIVE_STATION_SENSOR,
    RECEIVE_STATION_SENSOR_ASSIGNMENT,
    RECEIVE_STATION_SIM,
    RECEIVE_STATION_SIM_ASSIGNMENT,
    RECEIVE_STATION_SUBSCRIPTION,
    RECEIVE_STATION_SUBSCRIPTION_ASSIGNMENT,
    RECEIVE_STATION_TELECOM,
    RECEIVE_STATION_TELECOM_ASSIGNMENT,
    RECEIVE_STATION_VARIOUS_MATERIEL,
    RECEIVE_STATION_VARIOUS_MATERIEL_ASSIGNMENT,
} from '../constants/StationMaterielConstant'
import WatershedAction from '../../referencial/components/watershed/actions/WatershedAction'

import { RECEIVE_ALL_DATATYPES } from '../constants/DataTypeConstants'
import LogAction from '../../log/actions/LogAction'
import DtoAssociatedStation from 'station/dto/DtoAssociatedStation'
import DtoObservatoryFollowResult from 'station/dto/DtoObservatoryFollowResult'

const MOCK_ALL_PROBLEMS = [
    {
        id: 1,
        name: 'Problème 1',
    },
    {
        id: 2,
        name: 'Problème 2',
    },
    {
        id: 3,
        name: 'Problème 3',
    },
]

const MOCK_PROBLEM_SOLUTIONS_LINK = [
    {
        id: 1,
        idProblem: 1,
        idSolution: 1,
    },
    {
        id: 2,
        idProblem: 1,
        idSolution: 2,
    },
    {
        id: 3,
        idProblem: 2,
        idSolution: 3,
    },
    {
        id: 4,
        idProblem: 3,
        idSolution: 4,
    },
]

const MOCK_ALL_SOLUTIONS = [
    {
        id: 1,
        name: 'Solution 1',
    },
    {
        id: 2,
        name: 'Solution 2',
    },
    {
        id: 3,
        name: 'Solution 3',
    },
    {
        id: 4,
        name: 'Solution 4',
    },
]
const StationAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().StationReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },
    selectFilter(filter) {
        return { type: SELECT_FILTER, filter }
    },
    receiveQualitometerNetworks: networks => ({ type: RECEIVE_QUALITOMETER_NETWORK_ALL, networks }),
    promiseQualitometerNetworks: id => fetch(ApplicationConf.qualitometer.getNetworks(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchQualitometerNetworks: id => dispatch => StationAction.promiseQualitometerNetworks(id)
        .then(json => {
            const networks = json.map(n => new DtoNetwork(n))
            dispatch(StationAction.receiveQualitometerNetworks(networks))
        }),
    receiveNature(nature) {
        return { type: RECEIVE_NATURE, nature }
    },
    fetchNature(code) {
        return function (dispatch) {
            return fetch(ApplicationConf.nature.get(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    const nature = new DtoNature(json.code, json.libelle)
                    dispatch(StationAction.receiveNature(nature))
                })
        }
    },
    receiveWaterMass(watermass) {
        return { type: RECEIVE_STATION_WATERMASS, watermass }
    },
    fetchQualitomerWaterMass(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.getWaterMass(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    const watermass = new DtoWaterMass(json.code, json.europeanCode, json.name)
                    dispatch(StationAction.receiveWaterMass(watermass))
                })
        }
    },
    receiveAltimetricSystemObtentionMode(altimetricSystemProductionMode) {
        return {
            type: RECEIVE_ALTIMETRIC_SYSTEM_PRODUCTION_MODE,
            altimetricSystemProductionMode,
        }
    },
    fetchAltimetricSystemProductionMode() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.altimetricSystemProductionMode(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(StationAction.receiveAltimetricSystemObtentionMode(json))
                })
        }
    },
    receiveAltimetricSystemNature(altimetricSystemNature) {
        return {
            type: RECEIVE_ALTIMETRIC_SYSTEM_NATURE,
            altimetricSystemNature,
        }
    },
    fetchAltimetricSystemNature() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.altimetricSystemNature(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(StationAction.receiveAltimetricSystemNature(json))
                })
        }
    },
    receiveAltimetricSystemAltimetricSystem(altimetricSystemAltimetricSystem) {
        return {
            type: RECEIVE_ALTIMETRIC_SYSTEM_ALTIMETRIC_SYSTEM,
            altimetricSystemAltimetricSystem,
        }
    },
    fetchAltimetricSystemAltimetricSystem() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.altimetricSystemAltimetricSystem(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(StationAction.receiveAltimetricSystemAltimetricSystem(json))
                })
        }
    },
    receiveLandmarkProductionMode(landmarks) {
        return {
            type: RECEIVE_LANDMARKS_PRODUCTION_MODE,
            landmarks,
        }
    },
    fetchLandmarkProductionMode() {
        return function (dispatch) {
            return fetch(ApplicationConf.referencial.landmark(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(StationAction.receiveLandmarkProductionMode(json))
                })
        }
    },
    receiveQualitometerType(qualitometerType) {
        return { type: RECEIVE_ALL_QUALITOMETER_TYPE, qualitometerType }
    },
    fetchQualitometerType() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.qualitometerType(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveQualitometerType(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometerType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometerType))
                })
        }
    },
    receiveTypes(types) {
        return { type: RECEIVE_ALL_TYPE, types }
    },
    fetchTypes() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.piezometerType(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveTypes(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerType))
                })
        }
    },
    receiveStates(states) {
        return { type: RECEIVE_ALL_STATE, states }
    },
    fetchStates() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.state(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveStates(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.states} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.states))
                })
        }
    },
    receiveQualities(qualities) {
        return { type: RECEIVE_ALL_QUALITY, qualities }
    },
    fetchQualities() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.quality(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveQualities(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualities))
                })
        }
    },
    receiveFieldModes(fieldModes) {
        return { type: RECEIVE_ALL_FIELD_MODE, fieldModes }
    },
    fetchFieldModes() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.fieldMode(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveFieldModes(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.fieldMode} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.fieldMode))
                })
        }
    },
    receiveHydrogeologicalEntities(hydrogeologicalEntities) {
        return { type: RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES, hydrogeologicalEntities }
    },
    fetchHydrogeologicalEntities() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.hydrogeologicalEntities(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveHydrogeologicalEntities(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrogeologicalEntities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrogeologicalEntities))
                })
        }
    },
    receiveQualitometerRepresentiveness(qualitometerRepresentiveness) {
        return {
            type: RECEIVE_ALL_QUALITOMETER_REPRESENTIVENESS,
            qualitometerRepresentiveness,
        }
    },
    fetchQualitometerRepresentiveness() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.qualitometerRepresentiveness(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveQualitometerRepresentiveness(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometerRepresentiveness} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometerRepresentiveness))
                })
        }
    },
    fetchEvent: (qualitometerId, eventId) => {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.event(qualitometerId, eventId), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(StationAction.receiveEvent(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.event))
                })
        }
    },
    addEvent(qualitometerId, event) {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.events(qualitometerId), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            })
                .then(response => {
                    if (response.status == 201) {
                        dispatch(ToastrAction.success(i18n.eventAddSuccessfully))
                    } else {
                        dispatch(LogAction.logError(i18n.addError + i18n.event))
                        dispatch(ToastrAction.error(i18n.addError + i18n.event))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.event))
                })
        }
    },
    updateEvent(qualitometerId, eventId, event) {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.event(qualitometerId, eventId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            })
                .then(response => {
                    if (response.status === 201) {
                        dispatch(ToastrAction.success(i18n.eventAddSuccessfully))
                    } else {
                        dispatch(LogAction.logError(i18n.updateError + i18n.event))
                        dispatch(ToastrAction.error(i18n.updateError + i18n.event))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.event))
                })
        }
    },
    receivePictures(pictures) {
        return {
            type: RECEIVE_STATION_PICTURES,
            pictures,
        }
    },
    fetchPictures(code, stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.picture.stationPicture(code, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receivePictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },
    receiveFiles(files) {
        return {
            type: RECEIVE_STATION_FILES,
            files,
        }
    },
    fetchFiles(code, stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.stationFile(code, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(StationAction.receiveFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },

    receiveFilesFtp: (filesFtp) => {
        return { type: RECEIVE_FILES_FTP, filesFtp }
    },

    fetchFilesFtp(path, filter) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.readFTP(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ path, filter }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    dispatch(StationAction.receiveFilesFtp(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.element))
                })
        }
    },

    deleteFile(file) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.delete(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(file),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    if (json.delete === 1) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.element))
                })
        }
    },

    updateFile(file) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.edit(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(file),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    if (json.update === 1) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.element))
                })
        }
    },

    deleteFiles(files) {
        return dispatch => {
            return Promise.all(files.map(file => {
                return fetch(ApplicationConf.files.delete(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(file),
                }).then(checkAuth).then(getJson)
            }))
                .then(json => {
                    if (json[0].delete === 1) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.element))
                })
        }
    },

    reset() {
        return {
            type: RESET_STATION,
        }
    },
    resetStation() {
        return (dispatch) => {
            dispatch(StationAction.reset())
            dispatch(SieauAction.reset('qualitometer'))
            dispatch(OperationAction.fetchReset())
        }
    },
    resetAssociatedSites() {
        return {
            type: RESET_ASSOCIATED_SITES,
        }
    },
    receivePiezometer(piezo) {
        return { type: RECEIVE_PIEZOMETER, piezo }
    },
    fetchPiezometer(stationId) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.get(stationId), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => AppStore.dispatch(ToastrAction.error(i18n.unreachableStation)),
                }))
                .then((json = []) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(StationAction.receivePiezometer(json))
                    dispatch(StationAction.fetchPiezometerCentralAssignment(json.id))
                    dispatch(StationAction.fetchPiezometerPowerSupplyAssignment(json.id))
                    dispatch(StationAction.fetchPiezometerSensorAssignment(json.id))
                    dispatch(StationAction.fetchPiezometerSimAssignment(json.id))
                    dispatch(StationAction.fetchPiezometerTelecomAssignment(json.id))
                    dispatch(StationAction.fetchPiezometerVariousMaterielAssignment(json.id))
                    dispatch(StationAction.fetchPiezometerEquipmentAssignment(json.id))
                    dispatch(StationAction.fetchPiezometerSubscriptionAssignment(json.id))
                })
        }
    },
    updatePiezometer(piezometer, callback = () => { }) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.getAll(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(piezometer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch({ type: UPDATE_PIEZOMETER, piezometer })
                        callback()
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.piezometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.piezometer))
                })
        }
    },
    deletePiezometer(piezometer, callback = () => { }) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.piezometer.getAll(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(piezometer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch({ type: DELETE_PIEZOMETER, piezometerId: piezometer.id })
                        callback()
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.piezometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.piezometer))
                })
        }
    },
    receiveAssociatedSites(sites) {
        return { type: RECEIVE_ASSOCIATED_SITES, sites }
    },
    promiseAssociatedSites: (stationCode, type) => fetch(ApplicationConf.station.associatedStations(stationCode, type), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchAssociatedSites(stationCode, type) {
        return (dispatch) => {
            return StationAction.promiseAssociatedSites(stationCode, type)
                .then((json = []) => {
                    checkError(json)
                    dispatch(StationAction.receiveAssociatedSites(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.associatedStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.associatedStations))
                })
        }
    },
    getAssociatedSites: (stationCode, type) => dispatch => StationAction.promiseAssociatedSites(stationCode, type)
        .then(asso => asso.map(a => new DtoAssociatedStation(a)))
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.associatedStations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.associatedStations))
            return []
        }),

    promiseStationsAssociatedSites: (ids, stationType) => fetch(ApplicationConf.station.getStationsLink(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({ ids, stationType }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchSpecificObservatoryFollowResult: (ids = [], stationType, progressCallback = () => {}) => dispatch => {
        const groupsSize = ids.length / 50 > 20 ? ids.length / 20 : 50
        const promises = chunk(ids, groupsSize).map(idsGroup => genericPromise(ApplicationConf.station.observatoryFollowResults(stationType), 'POST', { ids: idsGroup }))
        return promiseAllProgress(promises, progressCallback).then(jsonResults => flatten(jsonResults).map(r => new DtoObservatoryFollowResult(r)))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.follows}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.follows))
            })
    },

    getStationsAssociatedSites: (ids, stationType) => dispatch => StationAction.promiseStationsAssociatedSites(ids, stationType)
        .then(asso => asso.map(a => new DtoAssociatedStation(a)))
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.associatedStations} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.associatedStations))
            return []
        }),

    addAssociatedSite(link) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.station.associatedStations(link.code, link.stationType), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(link),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    if (json.insert === 1) {
                        StationAction.promiseAssociatedSites(link.code, link.stationType)
                            .then((json2 = []) => {
                                checkError(json)
                                dispatch(StationAction.receiveAssociatedSites(json2))
                                dispatch(WaitAction.waitStop())
                                dispatch(ToastrAction.success(i18n.elementAddSuccess))
                            })
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.addError + i18n.associatedStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.associatedStations))
                })
        }
    },
    deleteAssociatedSite(link) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.station.associatedStations(link.code, link.stationType), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(link),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    if (json.delete >= 1) {
                        StationAction.promiseAssociatedSites(link.code, link.stationType)
                            .then((json2 = []) => {
                                checkError(json)
                                dispatch(StationAction.receiveAssociatedSites(json2))
                                dispatch(WaitAction.waitStop())
                                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                            })
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.associatedStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.associatedStations))
                })
        }
    },
    promiseSitesByRadius(values, sitesToFetch) {
        return fetch(ApplicationConf.layers.getSitesByRadius(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    longi: values[0],
                    lati: values[1],
                    radius: parseFloat(values[2]),
                    parametertype: `{${sitesToFetch.join(',')}}`,
                }),
        })
            .then(getJson)
    },
    receiveSitesByRadius(sites) {
        return { type: RECEIVE_EXTERNAL_SITES, sites }
    },
    fetchSitesByRadius(values, sitesToFetch) {
        return (dispatch) => {
            StationAction.promiseSitesByRadius(values, sitesToFetch)
                .then((json = []) => {
                    dispatch(StationAction.receiveSitesByRadius(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.industrialSites} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.industrialSites))
                })
        }
    },
    promiseSitesByCity(inseeCode, sitesToFetch) {
        return fetch(ApplicationConf.layers.getSitesByInsee(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    inseecode: inseeCode,
                    parametertype: `{${sitesToFetch.join(',')}}`,
                }),
        })
            .then(getJson)
    },
    receiveSitesByCity(sites) {
        return { type: RECEIVE_EXTERNAL_SITES, sites }
    },
    fetchSitesByCity(inseeCode, sitesToFetch) {
        return function (dispatch) {
            StationAction.promiseSitesByCity(inseeCode, sitesToFetch)
                .then((json = []) => {
                    dispatch(StationAction.receiveSitesByCity(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.industrialSites} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.industrialSites))
                })
        }
    },
    promiseSitesTypes() {
        return fetch(ApplicationConf.layers.getSitesTypes(), {
            method: 'GET',
        })
            .then(checkAuth)
            .then(getJson)
    },
    receiveSitesTypes(types) {
        return { type: RECEIVE_SITES_TYPES, types }
    },
    fetchSitesTypes() {
        return function (dispatch) {
            StationAction.promiseSitesTypes()
                .then((json = []) => {
                    dispatch(StationAction.receiveSitesTypes(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.sitesTypes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.sitesTypes))
                })
        }
    },
    LoadStationNearbySituationByTownCode: (townCode, progressCallback = () => { }) => dispatch => {
        const allPromises = [
            StationAction.promiseSitesByCity(townCode, [POLLUTED_SOIL, INDUSTRIAL_SITE]),
            StationAction.promiseSitesTypes(),
        ]
        return promiseAllProgress(allPromises, progressCallback).then(jsonResults => {
            dispatch(StationAction.receiveSitesByCity(jsonResults[0]))
            dispatch(StationAction.receiveSitesTypes(jsonResults[1]))
        })
    },
    LoadStationNearbySituationByLocalization: (station, coordinates, progressCallback = () => { }) => dispatch => {
        const allPromises = [
            WatershedAction.promiseStationWatershed(coordinates[0], coordinates[1]),
            StationAction.promiseSitesByRadius([coordinates[0], coordinates[1], 5.0], [POLLUTED_SOIL, INDUSTRIAL_SITE]),
            StationAction.promiseSitesTypes(),
            ...WatershedAction.promiseStationWatersheds(station).filter(v => v !== null),
        ]
        return promiseAllProgress(allPromises, progressCallback).then(jsonResults => {
            const stationWaterSheds = jsonResults.slice(3).filter(v => !isEmpty(v))
            dispatch(WatershedAction.receiveStationWatershed(jsonResults[0]))
            dispatch(StationAction.receiveSitesByRadius(jsonResults[1]))
            dispatch(StationAction.receiveSitesTypes(jsonResults[2]))
            dispatch(WatershedAction.receiveStationWatersheds(...stationWaterSheds))
        })
    },
    receiveFilters(filters) {
        return { type: RECEIVE_FILTERS, filters }
    },
    promiseFilters() {
        return fetch(ApplicationConf.user.filters(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchFilters() {
        return function (dispatch) {
            return StationAction.promiseFilters()
                .then((json = []) => {
                    dispatch(StationAction.receiveFilters(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.filters} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.filters))
                })
        }
    },
    receiveFilterResults(filterResults) {
        return { type: RECEIVE_FILTER_RESULTS, filterResults }
    },
    fetchFilterResults(filter) {
        return function (dispatch) {
            return StationAction.promiseFilterResults(filter)
                .then((json = []) => {
                    dispatch(StationAction.receiveFilterResults(json))
                    return json
                })
        }
    },
    promiseFilterResults({ module, code, parameters }) {
        AppStore.dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.user.filterResults(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ module, code: `${code}`, parameters }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                AppStore.dispatch(WaitAction.waitStop())
                return json
            })
            .catch(err => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.filterResults} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.filterResults))
            })
    },
    fetchFilterResultsTmp(body) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.filterResultsTmp(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(body),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    checkError(json, {
                        405: () => dispatch(ToastrAction.error(i18n.nonConformRequest)),
                        401: () => dispatch(ToastrAction.error(i18n.unauthorized)),
                        500: () => dispatch(ToastrAction.error(i18n.requestError)),
                    })
                    return json
                })
                .then((json = []) => {
                    dispatch(WaitAction.waitStop())
                    return json
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.filterResults} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.filterResults))
                })
        }
    },
    createFilter(filter, callback = () => { }) {
        return (dispatch) => {
            AppStore.dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.filters(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(filter),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert >= 1) {
                        AppStore.dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    AppStore.dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.filter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.filter))
                })
        }
    },
    updateFilter(filter, callback = () => { }) {
        return (dispatch) => {
            AppStore.dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.filters(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(filter),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        AppStore.dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    AppStore.dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.filter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.filter))
                })
        }
    },
    deleteFilter(filter, callback = () => { }) {
        return (dispatch) => {
            AppStore.dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.filters(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(filter),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        AppStore.dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch({ type: DELETE_FILTER, filter })
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    AppStore.dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.filter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.filter))
                })
        }
    },
    fetchNatures() {
        return function (dispatch) {
            return fetch(ApplicationConf.nature.naturePath(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveNatures(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.natures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.natures))
                })
        }
    },
    receiveNatures(natures) {
        return { type: RECEIVE_ALL_NATURE, natures }
    },
    // Materiel
    // Materiel - Central
    fetchQualitometerCentralAssignment: stationId => dispatch => dispatch(StationAction.fetchStationCentralAssignement(ApplicationConf.materiel.getQualitometerCentralAssignment(stationId))),
    fetchPiezometerCentralAssignment: stationId => dispatch => dispatch(StationAction.fetchStationCentralAssignement(ApplicationConf.materiel.getPiezometerCentralAssignment(stationId))),
    fetchPluviometerCentralAssignment: stationId => dispatch => dispatch(StationAction.fetchStationCentralAssignement(ApplicationConf.materiel.getPluviometerCentralAssignment(stationId))),
    fetchHydrometryCentralAssignment: stationId => dispatch => dispatch(StationAction.fetchStationCentralAssignement(ApplicationConf.materiel.getHydrometryCentralAssignment(stationId))),
    fetchInstallationCentralAssignment: stationId => dispatch => dispatch(StationAction.fetchStationCentralAssignement(ApplicationConf.materiel.getInstallationCentralAssignment(stationId))),

    receiveStationCentralAssignement(stationCentralAssignments) {
        return { type: RECEIVE_STATION_CENTRAL_ASSIGNMENT, stationCentralAssignments }
    },

    fetchStationCentralAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveStationCentralAssignement(json))
                    return json
                }).then((jsonResult = []) => {
                    if (!jsonResult.error && jsonResult.length) {
                        const reduceIds = reduce(jsonResult, (a, b) => {
                            if (b.idCentral) {
                                (a[b.idCentral] || (a[b.idCentral] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceIds).map(o => {
                            return StationAction.fetchStationCentral(o)
                        })
                        const mapOfFetchCentralPath = Object.keys(reduceIds).map(o => {
                            return StationAction.fetchStationCentralPath(o)
                        })
                        const mapOfFetchCentralRange = Object.keys(reduceIds).map(o => {
                            return StationAction.fetchStationCentralRange(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationCentral(result))
                        })
                        Promise.all(mapOfFetchCentralPath).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationCentralPath(result))
                        })
                        Promise.all(mapOfFetchCentralRange).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationCentralRange(result))
                        })
                    }
                })
        }
    },
    receiveStationCentral(stationCentral) {
        return { type: RECEIVE_STATION_CENTRAL, stationCentral }
    },
    fetchStationCentral(idCentral) {
        return fetch(ApplicationConf.materiel.central(idCentral), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    receiveStationCentralPath(stationCentralPaths) {
        return { type: RECEIVE_STATION_CENTRAL_PATH, stationCentralPaths }
    },
    fetchStationCentralPath(idCentral) {
        return fetch(ApplicationConf.materiel.centralChannel(idCentral), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    receiveStationCentralRange(stationCentralRanges) {
        return { type: RECEIVE_STATION_CENTRAL_RANGE, stationCentralRanges }
    },
    fetchStationCentralRange(idCentral) {
        return fetch(ApplicationConf.materiel.centralRange(idCentral), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    addMaterielSituation: (matCat, situation) => fetch(ApplicationConf.materiel.situationGeneric(matCat), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(situation),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    addAllMaterielSituation: situations => () =>
        Promise.all(situations.map(s => StationAction.addMaterielSituation(s.materielCategory, s))),
    addCentralSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.addCentralSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationCentralAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    saveCentralSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.saveCentralSituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationCentralAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deleteCentralSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.deleteCentralSituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.deleteSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryCentralAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationCentralAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    // Materiel - PowerSupply
    fetchQualitometerPowerSupplyAssignment: stationId => dispatch => dispatch(StationAction.fetchStationPowerSupplyAssignement(ApplicationConf.materiel.getQualitometerPowerSupplyAssignment(stationId))),
    fetchPiezometerPowerSupplyAssignment: stationId => dispatch => dispatch(StationAction.fetchStationPowerSupplyAssignement(ApplicationConf.materiel.getPiezometerPowerSupplyAssignment(stationId))),
    fetchPluviometerPowerSupplyAssignment: stationId => dispatch => dispatch(StationAction.fetchStationPowerSupplyAssignement(ApplicationConf.materiel.getPluviometerPowerSupplyAssignment(stationId))),
    fetchHydrometryPowerSupplyAssignment: stationId => dispatch => dispatch(StationAction.fetchStationPowerSupplyAssignement(ApplicationConf.materiel.getHydrometryPowerSupplyAssignment(stationId))),
    fetchInstallationPowerSupplyAssignment: stationId => dispatch => dispatch(StationAction.fetchStationPowerSupplyAssignement(ApplicationConf.materiel.getInstallationPowerSupplyAssignment(stationId))),

    receiveStationPowerSupplyAssignement(stationPowerSupplyAssignments) {
        return {
            type: RECEIVE_STATION_POWER_SUPPLY_ASSIGNMENT,
            stationPowerSupplyAssignments,
        }
    },

    fetchStationPowerSupplyAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    if (!json.error && json.length) {
                        const reduceIds = reduce(json, (a, b) => {
                            if (b.idPowerSupply) {
                                (a[b.idPowerSupply] || (a[b.idPowerSupply] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceIds).map(o => {
                            return StationAction.fetchStationPowerSupply(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationPowerSupply(result))
                        })
                    }
                    dispatch(StationAction.receiveStationPowerSupplyAssignement(json))
                })
        }
    },
    receiveStationPowerSupply(stationPowerSupply) {
        return { type: RECEIVE_STATION_POWER_SUPPLY, stationPowerSupply }
    },
    fetchStationPowerSupply(idPowerSupply) {
        return fetch(ApplicationConf.materiel.powerSupply(idPowerSupply), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    addPowerSupplySituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.addPowerSupplySituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationPowerSupplyAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    savePowerSupplySituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.savePowerSupplySituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationPowerSupplyAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deletePowerSupplySituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.deletePowerSupplySituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryPowerSupplyAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationPowerSupplyAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    // Materiel - Sensor
    fetchQualitometerSensorAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSensorAssignement(ApplicationConf.materiel.getQualitometerSensorAssignment(stationId))),
    fetchPiezometerSensorAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSensorAssignement(ApplicationConf.materiel.getPiezometerSensorAssignment(stationId))),
    fetchPluviometerSensorAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSensorAssignement(ApplicationConf.materiel.getPluviometerSensorAssignment(stationId))),
    fetchHydrometrySensorAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSensorAssignement(ApplicationConf.materiel.getHydrometrySensorAssignment(stationId))),
    fetchInstallationSensorAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSensorAssignement(ApplicationConf.materiel.getInstallationSensorAssignment(stationId))),

    receiveStationSensorAssignement(stationSensorAssignments) {
        return {
            type: RECEIVE_STATION_SENSOR_ASSIGNMENT,
            stationSensorAssignments,
        }
    },

    fetchStationSensorAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveStationSensorAssignement(json))
                    return json
                }).then((jsonResult = []) => {
                    if (!jsonResult.error && jsonResult.length) {
                        const reduceId = reduce(jsonResult, (a, b) => {
                            if (b.idSensor) {
                                (a[b.idSensor] || (a[b.idSensor] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceId).map(o => {
                            return StationAction.fetchStationSensor(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationSensor(result))
                        })
                    }
                })
        }
    },
    receiveStationSensor(stationSensor) {
        return { type: RECEIVE_STATION_SENSOR, stationSensor }
    },
    fetchStationSensor(idSensor) {
        return fetch(ApplicationConf.materiel.sensor(idSensor), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    addSensorSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.sensorsSituations(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometrySensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationSensorAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    saveSensorSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.sensorSituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometrySensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationSensorAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deleteSensorSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.sensorSituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerSensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometrySensorAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationSensorAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    // Materiel - Sim
    fetchQualitometerSimAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSimAssignement(ApplicationConf.materiel.getQualitometerSimAssignment(stationId))),
    fetchPiezometerSimAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSimAssignement(ApplicationConf.materiel.getPiezometerSimAssignment(stationId))),
    fetchPluviometerSimAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSimAssignement(ApplicationConf.materiel.getPluviometerSimAssignment(stationId))),
    fetchHydrometrySimAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSimAssignement(ApplicationConf.materiel.getHydrometrySimAssignment(stationId))),
    fetchInstallationSimAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSimAssignement(ApplicationConf.materiel.getInstallationSimAssignment(stationId))),

    receiveStationSimAssignement(stationSimAssignments) {
        return {
            type: RECEIVE_STATION_SIM_ASSIGNMENT,
            stationSimAssignments,
        }
    },

    fetchStationSimAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveStationSimAssignement(json))
                    return json
                }).then((jsonResult = []) => {
                    if (!jsonResult.error && jsonResult.length) {
                        const reduceSimId = reduce(jsonResult, (a, b) => {
                            if (b.idSim) {
                                (a[b.idSim] || (a[b.idSim] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceSimId).map(o => {
                            return StationAction.fetchStationSim(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationSim(result))
                        })
                    }
                })
        }
    },
    receiveStationSim(stationSim) {
        return { type: RECEIVE_STATION_SIM, stationSim }
    },
    fetchStationSim(idSim) {
        return fetch(ApplicationConf.materiel.sim(idSim), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    addSimSituation: (situation, stationId, stationType) => dispatch => fetch(ApplicationConf.materiel.addSimSituation(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(situation),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(() => {
            dispatch(ToastrAction.success(i18n.saveSituationSucess))
        }).then(() => {
            switch (stationType) {
                case STATION_TYPE_NAME.piezometry:
                    dispatch(StationAction.fetchPiezometerSimAssignment(stationId))
                    dispatch(StationAction.fetchPiezometerSubscriptionAssignment(stationId))
                    break
                case STATION_TYPE_NAME.quality:
                    dispatch(StationAction.fetchQualitometerSimAssignment(stationId))
                    dispatch(StationAction.fetchQualitometerSubscriptionAssignment(stationId))
                    break
                case STATION_TYPE_NAME.pluviometry:
                    dispatch(StationAction.fetchPluviometerSimAssignment(stationId))
                    dispatch(StationAction.fetchPluviometerSubscriptionAssignment(stationId))
                    break
                case STATION_TYPE_NAME.hydrometry:
                    dispatch(StationAction.fetchHydrometrySimAssignment(stationId))
                    dispatch(StationAction.fetchHydrometrySubscriptionAssignment(stationId))
                    break
                case STATION_TYPE_NAME.installation:
                    dispatch(StationAction.fetchInstallationSimAssignment(stationId))
                    dispatch(StationAction.fetchInstallationSubscriptionAssignment(stationId))
                    break
                default:
                    break
            }
        }),
    saveSimSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.saveSimSituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerSimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerSimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerSimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometrySimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationSimAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deleteSimSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.deleteSimSituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerSimAssignment(stationId))
                            dispatch(StationAction.fetchPiezometerSubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerSimAssignment(stationId))
                            dispatch(StationAction.fetchQualitometerSubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerSimAssignment(stationId))
                            dispatch(StationAction.fetchPluviometerSubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometrySimAssignment(stationId))
                            dispatch(StationAction.fetchHydrometrySubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationSimAssignment(stationId))
                            dispatch(StationAction.fetchInstallationSubscriptionAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    // Materiel - Telecom
    fetchQualitometerTelecomAssignment: stationId => dispatch => dispatch(StationAction.fetchStationTelecomAssignement(ApplicationConf.materiel.getQualitometerTelecomAssignment(stationId))),
    fetchPiezometerTelecomAssignment: stationId => dispatch => dispatch(StationAction.fetchStationTelecomAssignement(ApplicationConf.materiel.getPiezometerTelecomAssignment(stationId))),
    fetchPluviometerTelecomAssignment: stationId => dispatch => dispatch(StationAction.fetchStationTelecomAssignement(ApplicationConf.materiel.getPluviometerTelecomAssignment(stationId))),
    fetchHydrometryTelecomAssignment: stationId => dispatch => dispatch(StationAction.fetchStationTelecomAssignement(ApplicationConf.materiel.getHydrometryTelecomAssignment(stationId))),
    fetchInstallationTelecomAssignment: stationId => dispatch => dispatch(StationAction.fetchStationTelecomAssignement(ApplicationConf.materiel.getInstallationTelecomAssignment(stationId))),

    receiveStationTelecomAssignement(stationTelecomAssignments) {
        return {
            type: RECEIVE_STATION_TELECOM_ASSIGNMENT,
            stationTelecomAssignments,
        }
    },
    fetchStationTelecomAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveStationTelecomAssignement(json))
                    return json
                }).then((jsonResult = []) => {
                    if (!jsonResult.error && jsonResult.length) {
                        const reduceId = reduce(jsonResult, (a, b) => {
                            if (b.idTelecom) {
                                (a[b.idTelecom] || (a[b.idTelecom] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceId).map(o => {
                            return StationAction.fetchStationTelecom(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationTelecom(result))
                        })
                    }
                })
        }
    },
    receiveStationTelecom(stationTelecom) {
        return { type: RECEIVE_STATION_TELECOM, stationTelecom }
    },
    fetchStationTelecom(idTelecom) {
        return fetch(ApplicationConf.materiel.telecom(idTelecom), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    addTelecomSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.addTelecomSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationTelecomAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    saveTelecomSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.saveTelecomSituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationTelecomAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deleteTelecomSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.deleteTelecomSituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryTelecomAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationTelecomAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    // Materiel - Various Material
    fetchQualitometerVariousMaterielAssignment: stationId => dispatch => dispatch(StationAction.fetchStationVariousMaterielAssignement(ApplicationConf.materiel.getQualitometerVariousMaterielAssignment(stationId))),
    fetchPiezometerVariousMaterielAssignment: stationId => dispatch => dispatch(StationAction.fetchStationVariousMaterielAssignement(ApplicationConf.materiel.getPiezometerVariousMaterielAssignment(stationId))),
    fetchPluviometerVariousMaterielAssignment: stationId => dispatch => dispatch(StationAction.fetchStationVariousMaterielAssignement(ApplicationConf.materiel.getPluviometerVariousMaterielAssignment(stationId))),
    fetchHydrometryVariousMaterielAssignment: stationId => dispatch => dispatch(StationAction.fetchStationVariousMaterielAssignement(ApplicationConf.materiel.getHydrometryVariousMaterielAssignment(stationId))),
    fetchInstallationVariousMaterielAssignment: stationId => dispatch => dispatch(StationAction.fetchStationVariousMaterielAssignement(ApplicationConf.materiel.getInstallationVariousMaterielAssignment(stationId))),

    receiveStationVariousMaterielAssignement(stationVariousMaterielAssignments) {
        return {
            type: RECEIVE_STATION_VARIOUS_MATERIEL_ASSIGNMENT,
            stationVariousMaterielAssignments,
        }
    },
    fetchStationVariousMaterielAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveStationVariousMaterielAssignement(json))
                    return json
                }).then((jsonResult = []) => {
                    if (!jsonResult.error && jsonResult.length) {
                        const reduceId = reduce(jsonResult, (a, b) => {
                            if (b.idVarious) {
                                (a[b.idVarious] || (a[b.idVarious] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceId).map(o => {
                            return StationAction.fetchStationVariousMateriel(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationVariousMateriel(result))
                        })
                    }
                })
        }
    },
    receiveStationVariousMateriel(stationVariousMateriel) {
        return { type: RECEIVE_STATION_VARIOUS_MATERIEL, stationVariousMateriel }
    },
    fetchStationVariousMateriel(idVariousMateriel) {
        return fetch(ApplicationConf.materiel.variousMateriel(idVariousMateriel), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    addVariousMaterielSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.addVariousMaterielSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationVariousMaterielAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    saveVariousMaterielSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.saveVariousMaterielSituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationVariousMaterielAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deleteVariousMaterielSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.deleteVariousMaterielSituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryVariousMaterielAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationVariousMaterielAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    // Materiel - Equipment
    fetchQualitometerEquipmentAssignment: stationId => dispatch => dispatch(StationAction.fetchStationEquipmentAssignement(ApplicationConf.materiel.getQualitometerEquipmentAssignment(stationId))),
    fetchPiezometerEquipmentAssignment: stationId => dispatch => dispatch(StationAction.fetchStationEquipmentAssignement(ApplicationConf.materiel.getPiezometerEquipmentAssignment(stationId))),
    fetchPluviometerEquipmentAssignment: stationId => dispatch => dispatch(StationAction.fetchStationEquipmentAssignement(ApplicationConf.materiel.getPluviometerEquipmentAssignment(stationId))),
    fetchHydrometryEquipmentAssignment: stationId => dispatch => dispatch(StationAction.fetchStationEquipmentAssignement(ApplicationConf.materiel.getHydrometryEquipmentAssignment(stationId))),
    fetchInstallationEquipmentAssignment: stationId => dispatch => dispatch(StationAction.fetchStationEquipmentAssignement(ApplicationConf.materiel.getInstallationEquipmentAssignment(stationId))),

    receiveStationEquipmentAssignement(stationEquipmentAssignments) {
        return {
            type: RECEIVE_STATION_EQUIPMENT_ASSIGNMENT,
            stationEquipmentAssignments,
        }
    },

    fetchStationEquipmentAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveStationEquipmentAssignement(json))
                    return json
                }).then((jsonResult = []) => {
                    if (!jsonResult.error && jsonResult.length) {
                        const reduceId = reduce(jsonResult, (a, b) => {
                            if (b.idEquipment) {
                                (a[b.idEquipment] || (a[b.idEquipment] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceId).map(o => {
                            return StationAction.fetchStationEquipment(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationEquipment(result))
                        })
                    }
                })
        }
    },
    receiveStationEquipment(stationEquipment) {
        return { type: RECEIVE_STATION_EQUIPMENT, stationEquipment }
    },
    fetchStationEquipment(idEquipment) {
        return fetch(ApplicationConf.materiel.equipment(idEquipment), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },
    addEquipmentSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.createEquipmentSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationEquipmentAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    saveEquipmentSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.equipmentSituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationEquipmentAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deleteEquipmentSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.equipmentSituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometryEquipmentAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationEquipmentAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },

    // Materiel - Subscription

    fetchQualitometerSubscriptionAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSubscriptionAssignement(ApplicationConf.materiel.getQualitometerSubscriptionAssignment(stationId))),
    fetchPiezometerSubscriptionAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSubscriptionAssignement(ApplicationConf.materiel.getPiezometerSubscriptionAssignment(stationId))),
    fetchPluviometerSubscriptionAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSubscriptionAssignement(ApplicationConf.materiel.getPluviometerSubscriptionAssignment(stationId))),
    fetchHydrometrySubscriptionAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSubscriptionAssignement(ApplicationConf.materiel.getHydrometrySubscriptionAssignment(stationId))),
    fetchInstallationSubscriptionAssignment: stationId => dispatch => dispatch(StationAction.fetchStationSubscriptionAssignement(ApplicationConf.materiel.getInstallationSubscriptionAssignment(stationId))),

    receiveStationSubscriptionAssignement(stationSubscriptionAssignments) {
        return {
            type: RECEIVE_STATION_SUBSCRIPTION_ASSIGNMENT,
            stationSubscriptionAssignments,
        }
    },

    fetchStationSubscriptionAssignement(route) {
        return function (dispatch) {
            return fetch(route, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(StationAction.receiveStationSubscriptionAssignement(json))
                    return json
                }).then((jsonResult = []) => {
                    if (!jsonResult.error && jsonResult.length) {
                        const reduceId = reduce(jsonResult, (a, b) => {
                            if (b.idSubscription) {
                                (a[b.idSubscription] || (a[b.idSubscription] = [])).push(b)
                            }
                            return a
                        }, {})
                        const mapOfFetch = Object.keys(reduceId).map(o => {
                            return StationAction.fetchStationSubscription(o)
                        })
                        Promise.all(mapOfFetch).then((ana) => {
                            const result = flatten(ana)
                            dispatch(StationAction.receiveStationSubscription(result))
                        })
                    }
                })
        }
    },

    receiveStationSubscription(stationSubscription) {
        return { type: RECEIVE_STATION_SUBSCRIPTION, stationSubscription }
    },

    fetchStationSubscription(idSubscription) {
        return fetch(ApplicationConf.materiel.subscription(idSubscription), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                return json
            })
    },

    addSubscriptionSituation: (situation, stationId, stationType) => dispatch => fetch(ApplicationConf.materiel.addSubscriptionSituation(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(situation),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(() => {
            dispatch(ToastrAction.success(i18n.saveSituationSucess))
        }).then(() => {
            switch (stationType) {
                case STATION_TYPE_NAME.piezometry:
                    dispatch(StationAction.fetchPiezometerSubscriptionAssignment(stationId))
                    dispatch(StationAction.fetchPiezometerSimAssignment(stationId))
                    break
                case STATION_TYPE_NAME.quality:
                    dispatch(StationAction.fetchQualitometerSubscriptionAssignment(stationId))
                    dispatch(StationAction.fetchQualitometerSimAssignment(stationId))
                    break
                case STATION_TYPE_NAME.pluviometry:
                    dispatch(StationAction.fetchPluviometerSubscriptionAssignment(stationId))
                    dispatch(StationAction.fetchPluviometerSimAssignment(stationId))
                    break
                case STATION_TYPE_NAME.hydrometry:
                    dispatch(StationAction.fetchHydrometrySubscriptionAssignment(stationId))
                    dispatch(StationAction.fetchHydrometrySimAssignment(stationId))
                    break
                case STATION_TYPE_NAME.installation:
                    dispatch(StationAction.fetchInstallationSubscriptionAssignment(stationId))
                    dispatch(StationAction.fetchInstallationSimAssignment(stationId))
                    break
                default:
                    break
            }
        }),

    saveSubscriptionSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.saveSubscriptionSituation(situation.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerSubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerSubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerSubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometrySubscriptionAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationSubscriptionAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },
    deleteSubscriptionSituation(situation, stationId, stationType) {
        return function (dispatch) {
            return fetch(ApplicationConf.materiel.deleteSubscriptionSituation(situation.id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.saveSituationSucess))
                }).then(() => {
                    switch (stationType) {
                        case STATION_TYPE_NAME.piezometry:
                            dispatch(StationAction.fetchPiezometerSubscriptionAssignment(stationId))
                            dispatch(StationAction.fetchPiezometerSimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.quality:
                            dispatch(StationAction.fetchQualitometerSubscriptionAssignment(stationId))
                            dispatch(StationAction.fetchQualitometerSimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.pluviometry:
                            dispatch(StationAction.fetchPluviometerSubscriptionAssignment(stationId))
                            dispatch(StationAction.fetchPluviometerSimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.hydrometry:
                            dispatch(StationAction.fetchHydrometrySubscriptionAssignment(stationId))
                            dispatch(StationAction.fetchHydrometrySimAssignment(stationId))
                            break
                        case STATION_TYPE_NAME.installation:
                            dispatch(StationAction.fetchInstallationSubscriptionAssignment(stationId))
                            dispatch(StationAction.fetchInstallationSimAssignment(stationId))
                            break
                        default:
                            break
                    }
                })
        }
    },

    // All problems
    receiveProblems(problems) {
        return { type: RECEIVE_ALL_EVENT_PROBLEMS, problems }
    },
    promiseProblems() {
        return fetch(ApplicationConf.station.problem(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchProblems() {
        return (dispatch) => {
            return StationAction.promiseProblems()
                .then(() => {
                    dispatch(StationAction.receiveProblems(MOCK_ALL_PROBLEMS))
                })
        }
    },
    // All solutions
    promiseSolutions() {
        return fetch(ApplicationConf.station.solution(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    receiveSolutions(solutions) {
        return { type: RECEIVE_ALL_EVENT_SOLUTIONS, solutions }
    },
    fetchSolutions() {
        return (dispatch) => {
            return StationAction.promiseSolutions()
                .then(() => {
                    dispatch(StationAction.receiveSolutions(MOCK_ALL_SOLUTIONS))
                })
        }
    },
    // Link Problem Solution
    promiseProblemSolutionLinks() {
        return fetch(ApplicationConf.station.problemSolutionLinks(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    receiveProblemSolutionLinks(problemSolutionLinks) {
        return { type: RECEIVE_ALL_EVENT_PROBLEM_SOLUTION_LINKS, problemSolutionLinks }
    },
    fetchProblemSolutionLinks() {
        return (dispatch) => {
            return StationAction.promiseProblemSolutionLinks()
                .then(() => {
                    dispatch(StationAction.receiveProblemSolutionLinks(MOCK_PROBLEM_SOLUTIONS_LINK))
                })
        }
    },
    // Selected problem
    addAllSelectedProblems(problems) {
        return { type: ADD_ALL_SELECTED_PROBLEM, problems }
    },
    addSelectedProblem(problem) {
        return { type: ADD_SELECTED_PROBLEM, problem }
    },
    removeSelectedProblem(problem) {
        return { type: REMOVE_SELECTED_PROBLEM, problem }
    },
    resetSelectedProblems() {
        return { type: RESET_SELECTED_PROBLEM }
    },
    // Selected solution
    addAllSelectedSolutions(solutionLinks) {
        return { type: ADD_ALL_SELECTED_SOLUTION, solutionLinks }
    },
    addSelectedLink(solutionLink) {
        return { type: ADD_SELECTED_LINK, solutionLink }
    },
    removeSelectedSolution(solutionLink) {
        return { type: REMOVE_SELECTED_SOLUTION, solutionLink }
    },
    resetSelectedSolutions() {
        return { type: RESET_SELECTED_SOLUTION }
    },
    updateThresholds: (stationId, thresholds = [], stationType, displayCote) => dispatch => {
        const url = (() => {
            switch (stationType) {
                case 'pluviometry': return ApplicationConf.pluviometry.threshold(stationId)
                case 'hydrometry': return ApplicationConf.hydrometricStation.hydrometricThreshold(stationId)
                default: return ApplicationConf.piezometer.thresholds(stationId, displayCote)
            }
        })()
        return fetch(url, {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(thresholds),
        }).then(checkAuth).then(getJson).then(checkError)
            .then(json => {
                if (json.update === thresholds.length) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                } else {
                    throw new Error(json)
                }
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.thresholds} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.thresholds))
            })
    },
    updateThresholdsWithDataTupe: (stationId, thresholds = [], stationType, dataType) => dispatch => {
        const url = (() => {
            switch (stationType) {
                // case 'pluviometry': return ApplicationConf.pluviometry.threshold(stationId)
                case 'hydrometry': return ApplicationConf.hydrometricStation.hydrometricThresholdWithDataType(stationId, dataType)
                default: throw Error(`Unknown stationType : ${stationType}`)
            }
        })()
        return fetch(url, {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(thresholds),
        }).then(checkAuth).then(getJson).then(checkError)
            .then(json => {
                if (json.update === thresholds.length) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                } else {
                    throw new Error(json)
                }
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.thresholds} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.thresholds))
            })
    },

    addStationFile(file, fileType) {
        return function (dispatch) {
            const url = fileType === 'doc' ? ApplicationConf.files.stationDocumentUpload() : ApplicationConf.files.stationPictureUpload()
            return fetch(url, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(file),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => checkError(json, {
                    415: () => dispatch(ToastrAction.error(i18n.fileTypeNotAuthorized)),
                }))
                .then(json => {
                    if (json.insert) {
                        dispatch(ToastrAction.success(i18n.elementAddSuccess))
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.element))
                })
        }
    },

    receiveAllContributors(json) {
        return { type: RECEIVE_CONTRIBUTOR_LINKS, contributorLinks: json }
    },

    fetchAllContributors(stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.station.allContributors(stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(StationAction.receiveAllContributors(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error('can\'t fetch contributors link'))
                })
        }
    },

    receiveAllDataTypes(piezo, hydro, pluvio, project) {
        const filteredDataTypes = project === 'SIES' ? piezo : (project === 'SIH' ? hydro : pluvio)
        return { type: RECEIVE_ALL_DATATYPES, piezo, hydro, pluvio, filteredDataTypes }
    },

    promiseDataTypesByProject(project) {
        return fetch(ApplicationConf.station.dataTypesByProject(project), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchDataTypes(project) {
        return (dispatch) => {
            return StationAction.promiseDataTypesByProject(project)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.additionalData))
                })
        }
    },

    fetchDataTypesByProject(project) {
        return (dispatch) => {
            return Promise.all([
                StationAction.promiseDataTypesByProject('SIES'),
                StationAction.promiseDataTypesByProject('SIH'),
                StationAction.promiseDataTypesByProject('SIP'),
            ])
                .then(jsonTab => {
                    dispatch(StationAction.receiveAllDataTypes(jsonTab[0], jsonTab[1], jsonTab[2], project))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.additionalData))
                })
        }
    },

    updateDataTypesByProject(project, dataTypes) {
        return (dispatch) => {
            return fetch(ApplicationConf.station.dataTypesByProject(project), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(dataTypes),
            })
                .then(response => {
                    if (response.status === 200) {
                        dispatch(ToastrAction.success(i18n.saveSuccess))
                    } else {
                        dispatch(LogAction.logError(i18n.updateError + i18n.additionalData))
                        dispatch(ToastrAction.error(i18n.updateError + i18n.additionalData))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.additionalData} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.additionalData))
                })
        }
    },

    receiveExportJobsTmp: exportJobsTmp => ({ type: RECEIVE_EXPORT_JOBS_TMP, exportJobsTmp }),
    promiseExportJobsTmp: (stationType) => fetch(ApplicationConf.export.exportJobsTmp(stationType), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchExportJobsTmp: (stationType) => dispatch => StationAction.promiseExportJobsTmp(stationType)
        .then(json => {
            dispatch(StationAction.receiveExportJobsTmp(json))
            return json
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.export} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.export))
        }),
    promiseMapSituations(stationType,) {
        return genericPromise(ApplicationConf.station.mapSituation(stationType))
    },
    fetchMapSituations(stationType) {
        return genericFetch(StationAction.promiseMapSituations(stationType,), RECEIVE_MAP_SITUATIONS)
    },

    promiseMapSituationResults(stationType, mapSituation, date) {
        return genericPromise(ApplicationConf.station.mapSituationResults(stationType, mapSituation.id, date), 'POST', mapSituation)
    },
    fetchMapSituationsResults(stationType, mapSituation, date) {
        return genericFetch(StationAction.promiseMapSituationResults(stationType, mapSituation, date), RECEIVE_MAP_SITUATIONS_RESULTS)
    },

    promiseMapSituationDates(stationType, mapId, month) {
        return genericPromise(ApplicationConf.station.mapSituationDates(stationType, mapId, month))
    },

    promiseMapSituationStation(stationType, mapId, stationId) {
        return genericPromise(ApplicationConf.station.mapSituationStation(stationType, mapId, stationId))
    },

    promiseMapSituationStationLast(stationType, stationId, maxDate = null) {
        return genericPromise(ApplicationConf.station.mapSituationStationLast(stationType, stationId, maxDate))
    },

    createMapSituation(stationType, mapSituation, launch = false) {
        return genericCreate(ApplicationConf.station.mapSituation(stationType), 'CREATE_MAP_SITUATION', { mapSituation, launch })
    },

    updateMapSituation(stationType, mapSituation, launch = false) {
        return genericUpdate(ApplicationConf.station.mapSituation(stationType), 'UPDATE_MAP_SITUATION', { mapSituation, launch })
    },

    launchMapSituation(stationType, mapSituationId) {
        return genericUpdate(ApplicationConf.station.mapSituationId(stationType, mapSituationId), 'LAUNCH_MAP_SITUATION', {}, 'POST')
    },

    deleteMapSituation(stationType, mapSituation) {
        return genericDelete(ApplicationConf.station.mapSituationId(stationType, mapSituation.id), 'DELETE_MAP_SITUATION', { mapSituation })
    },

    promiseMapSituationStats(stationType, id) {
        return genericPromise(ApplicationConf.station.mapSituationStats(stationType, id))
    },

    promiseWatermassLinksWithNames(stationType, ids) {
        return genericPromise(ApplicationConf.station.watermassLinksWithNames(stationType), 'POST', { ids })
    },

    promiseAquiferLinksWithNames(stationType, ids) {
        return genericPromise(ApplicationConf.station.aquiferLinksWithNames(stationType), 'POST', { ids })
    },

    promiseLevelMinMaxStats(stationType, ids, typeId) {
        return genericPromise(ApplicationConf.station.levelMinMaxStats(stationType), 'POST', { ids, typeId })
    },
}

export default StationAction
