import Select from '../../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getSetting } from '../../../../utils/SettingUtils'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SieauParameterDto from '../../../dto/SieauParameterDto'
import { Button, Dialog, Grid } from '@mui/material'
import AdministrationAction from '../../../actions/AdministrationAction'
import { useDispatch } from 'react-redux'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import Icon from '../../../../components/icon/Icon'
import Checkbox from 'components/forms/Checkbox'

const ModalTestSms = ({ api, params, openModal, setOpenModal }) => {
    const dispatch = useDispatch()

    const [message, setMessage] = useState(i18n.smsDefault)
    const [telephone, setTelephone] = useState('')

    const sendSms = (numero) => {
        const bodyUrl = (() => {
            switch (api) {
                case 'smsFactor' :
                    return {
                        message: {
                            number: numero,
                            message,
                        },
                        token: params.token,
                    }
                case 'sendEthic' :
                    return {
                        message: {
                            number: numero,
                            message,
                        },
                        numAccount: params.numAccount,
                        key: params.key,
                    }
                default :
                    return {}
            }
        })()
        dispatch(AdministrationAction.sensSms(api, bodyUrl))
    }

    const disableButton = (api) => {
        switch (api) {
            case 'sendEthic' :
                const regexSendEthic = /^\+33[1-9]\d{8}$/
                return regexSendEthic.test(telephone)
            case 'smsFactor' :
                const regexSmsFactor = /^33[1-9]\d{8}$/
                return regexSmsFactor.test(telephone)
            default :
                return true
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            open={openModal}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.smsTest}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpenModal(false)} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container spacing={1} alignItems='center' justifyContent='flex-start'>
                    <Grid item marginTop={2} xs={12}>
                        <Input
                            col={12}
                            title={i18n.yourMessage}
                            value={ message }
                            onChange={(v) => setMessage(v)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            col={12}
                            title={i18n.number}
                            value={telephone}
                            onChange={(v) => setTelephone(v)}
                        />
                    </Grid>
                    <Grid item marginTop={1} xs={7}>
                        {
                            api === 'smsFactor' && 'Format 33 (ex: 33644556677)'
                        }
                        {
                            api === 'sendEthic' && 'Format + 33 (ex: +33644556677)'
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {i18n.smsDelay}
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={() => {
                    setOpenModal(false)
                    sendSms(telephone)
                }} variant='contained' color='primary' disabled={!disableButton(api)}
                >
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

const SendEthic = ({
    applicationSettings,
    change,
    disabled,
})=> {
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <div className='row no-margin padding-top-10-px'>
                <Input
                    col={12}
                    title={i18n.identifier}
                    value={(getSetting(applicationSettings, 'smsId') || '')}
                    onChange={(v) => change('smsId', v)}
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-10-px'>
                <Textarea
                    col={12}
                    title={i18n.token}
                    value={(getSetting(applicationSettings, 'smsToken') || '')}
                    onChange={v => change('smsToken', v)}
                    {...disabled}
                />
            </div>
            {
                disabled.disabled && (
                    <Button style={{ top: 5, margin: 10 }} onClick={() => setOpenModal(true)} variant='outlined'>
                        {i18n.smsTest}
                    </Button>
                )
            }
            <ModalTestSms api={'sendEthic'} params={ { numAccount: getSetting(applicationSettings, 'smsId'), key: getSetting(applicationSettings, 'smsToken') } } openModal={openModal} setOpenModal={setOpenModal}/>
        </>
    )
}
SendEthic.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    change: PropTypes.func,
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
}

const SmsFactor = ({
    applicationSettings,
    change,
    disabled,
})=> {
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <div className='row no-margin padding-top-10-px'>
                <Textarea
                    col={12}
                    title={i18n.token}
                    value={(getSetting(applicationSettings, 'smsToken') || '')}
                    onChange={v => change('smsToken', v)}
                    {...disabled}
                />
                {
                    disabled.disabled && (
                        <Button style={{ top: 5, margin: 10 }} onClick={() => setOpenModal(true)} variant='outlined' >
                            {i18n.smsTest}
                        </Button>
                    )
                }
                <ModalTestSms api={'smsFactor'} params={ { token: getSetting(applicationSettings, 'smsToken') } } openModal={openModal} setOpenModal={setOpenModal}/>
            </div>
        </>
    )
}

SmsFactor.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    change: PropTypes.func,
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
}

const SettingSms = ({
    applicationSettings,
    change,
    disabled,
}) => {
    const smsSetting = applicationSettings.find(a=> a.parameter === 'smsSettings')?.value

    return (
        <>
            <div className='row no-margin padding-top-10-px'>
                <Select
                    col={6}
                    label={i18n.setting}
                    options={[
                        { id: 'sendEthic', label: 'sendEthic' },
                        { id: 'smsFactor', label: 'smsFactor' },
                    ]}
                    value={getSetting(applicationSettings, 'smsSettings')}
                    onChange={(v) => change('smsSettings', v)}
                    {...disabled}
                />
                { smsSetting === 'sendEthic' &&
                    <>
                        <Checkbox
                            col={3}
                            label={i18n.sendEthicUse}
                            checked={!!getSetting(applicationSettings, 'sendEthicMail')}
                            onChange={() => change('sendEthicMail', !getSetting(applicationSettings, 'sendEthicMail'))}
                            componentClassName='margin-top-1'
                            {...disabled}
                        />
                        {
                            !!getSetting(applicationSettings, 'sendEthicMail') &&
                                <Input
                                    col={3}
                                    title={i18n.emailUser}
                                    value={(getSetting(applicationSettings, 'userSendEthicMail') || '')}
                                    onChange={(v) => change('userSendEthicMail', v)}
                                    {...disabled}
                                />
                        }
                    </>
                }
            </div>
            { smsSetting === 'sendEthic' &&
                <SendEthic
                    applicationSettings={applicationSettings}
                    change={change}
                    disabled={disabled}
                />
            }
            { smsSetting === 'smsFactor' &&
                <SmsFactor
                    applicationSettings={applicationSettings}
                    change={change}
                    disabled={disabled}
                />
            }
        </>
    )
}
SettingSms.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    change: PropTypes.func,
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
}
export default SettingSms