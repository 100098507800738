import StepperDialog from 'components/modal/StepperDialog'
import React, { useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { ButtonMUI } from 'components/styled/Buttons'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isUndefined } from 'lodash'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import moment from 'moment'
import OperationAction from '../../actions/OperationAction'
import WaitAction from 'wait/WaitAction'
import { push } from 'connected-react-router'
import { ANALYSIS } from '../../constants/OperationDescriptionConstants'
import { AnalysisStep, OperationStep, ParameterStep } from './Step'
import ToastrAction from 'toastr/actions/ToastrAction'

const STEP_OPERATION = 0
const STEP_PARAMETER = 1
const STEP_ANALYSIS = 2

const OperationStepper = ({
    isOpen = false,
    onClose = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
        qualitometer,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        qualitometer: store.QualityReducer.qualitometer,
    }), shallowEqual)

    const defaultOperation = useMemo(() => {
        return {
            date: +moment(),
            producer: accountUser.contributorCode,
            sampler: accountUser.contributorCode,
            labo: accountUser.contributorCode,
            support: '3', // eau
        }
    }, [accountUser.contributorCode])

    const [operation, setOperation] = useState(defaultOperation) // support -> to int
    const [analysis, setAnalysis] = useState([]) // accreditation, trouble, localization -> to string

    useUpdateEffect(() => {
        setOperation(defaultOperation)
        setAnalysis([])
    }, [isOpen, defaultOperation])

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.sample,
                    constant: STEP_OPERATION,
                },
                {
                    label: `${i18n.selection} / ${i18n.parameter}`,
                    constant: STEP_PARAMETER,
                    // nextAvailable: !!selectedParameters.length,
                },
                {
                    label: i18n.oneAnalysis,
                    constant: STEP_ANALYSIS,
                },
            ]}
            open={isOpen}
            title={i18n.newSample}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP_ANALYSIS) && (
                <ButtonMUI
                    onClick={() => {
                        const newOperation = {
                            id: -1,
                            qualitometer: qualitometer.id,
                            ...operation,
                            support: operation.support ? parseInt(operation.support) : undefined,
                        }
                        const newSample = {
                            id: -1,
                            qualitometer: qualitometer.id,
                            ...operation,
                        }
                        dispatch(WaitAction.waitStart())
                        OperationAction.createOperation(qualitometer.id, newOperation, newSample).then(({ idOperation, numSample }) => {
                            if (isUndefined(idOperation)) {
                                dispatch(WaitAction.waitStop())
                                dispatch(ToastrAction.error(i18n.createError))
                                return
                            }
                            const newAnalysis = analysis.map(a => ({
                                id: -1,
                                ...a,

                                qualitometer: qualitometer.id,
                                sample: numSample,
                                operation: idOperation,
                                analysisDate: operation.date,
                                labo: operation.labo,
                                difficulty: !isUndefined(a.difficulty) ? `${a.difficulty}` : undefined,
                                accreditation: !isUndefined(a.accreditation) ? `${a.accreditation}` : undefined,
                            }))
                            dispatch(OperationAction.createAnalysisList(qualitometer.id, idOperation, newAnalysis)).then(() => {
                                dispatch(push(`/station/quality/${qualitometer.id}/operation/${idOperation}?tab=${ANALYSIS}`))
                            }).finally(() => dispatch(WaitAction.waitStop()))
                        })
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </ButtonMUI>
            )}
            contentStyle={{ padding: '0 12' }}
        >
            {
                step => (
                    <>
                        {step === STEP_OPERATION && (
                            <OperationStep
                                operation={operation}
                                setOperation={setOperation}
                            />
                        )}
                        {step === STEP_PARAMETER && (
                            <ParameterStep
                                analysis={analysis}
                                setAnalysis={setAnalysis}
                            />
                        )}
                        {step === STEP_ANALYSIS && (
                            <AnalysisStep
                                analysis={analysis}
                                setAnalysis={setAnalysis}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

OperationStepper.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default OperationStepper