import React from 'react'
import PropTypes from 'prop-types'
import { WhiteCard } from 'components/styled/Card'
import { hasLocalisationStation } from 'utils/StationUtils'
import CartographyPanel from 'components/map/CartographyPanel'
import i18n from 'simple-react-i18n'
import { getI18nTitleData } from 'utils/StringUtil'

const DashboardMapPanel = ({
    stationType,
    data = [],
}) => {
    const stationsWithCoordinates = data.stations.filter(s => s && (hasLocalisationStation(s) || s.townCode))
    return (
        <WhiteCard
            title={(
                <><span>{data?.title}</span><span style={{ fontWeight: 'normal' }}>{` (${data?.stations?.length} ${getI18nTitleData(i18n.element, i18n.elements, data?.stations)})`}</span></>
            )}
            round
        >
            <CartographyPanel
                layers={['STATIONS_POINTS']}
                componentType={stationType}
                stationsPoints={stationsWithCoordinates}
                stationsPanelTitle={i18n.stations}
                heightToSubstract={450}
            />
        </WhiteCard>
    )
}

DashboardMapPanel.propTypes = {
    stationType: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
}

export default DashboardMapPanel