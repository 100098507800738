import DtoLocation from '../../../../station/dto/DtoLocation'
import ReferencialDto from '../../../dto/ReferencialDto'

export default class ContactDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.code = Number(object.id)
        this.title = object.title
        this.socialReason = object.socialReason
        this.address = object.address
        this.cityCode = object.cityCode
        this.cedexCode = object.cedexCode
        this.phoneTel = object.phoneTel
        this.phoneTelSecond = object.phoneTelSecond
        this.fax = object.fax
        this.desktopTel = object.desktopTel
        this.mobile = object.mobile
        this.email = object.email
        this.bankCode = object.bankCode
        this.comment = object.comment
        this.postalBox = object.postalBox
        this.contributor = object.contributor
        this.birthdate = object.birthdate
        this.contactFunction = object.contactFunction
        this.noNewsLetterDate = object.noNewsLetterDate
        this.noNewLetterLogin = object.noNewLetterLogin
        this.additionalAddress = object.additionalAddress
        this.cedex = object.cedex
        this.identifier = object.identifier
        this.countryCode = object.countryCode
        // carto
        this.x = object.x // Option[Double] = None
        this.y = object.y // Option[Double] = None
        this.projection = object.projection // Option[Double] = None
        this.townCode = object.cityCode
        this.localisation = new DtoLocation(object)
        this.typeName = 'contact'
    }
}