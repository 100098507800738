import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import SelectedElementsModal from 'components/modal/SelectedElementsModal'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { v4 as uuidv4 } from 'uuid'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Input from '../../../../components/forms/Input'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import SieauAction from '../../../../components/sieau/SieauAction'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import NetworkAction from '../../../../referencial/components/network/actions/NetworkAction'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import Job from '../../../dto/DtoJob'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import Checkbox from '../../../../components/forms/Checkbox'

const ALL = 'ALL'
const CUSTOM = 'CUSTOM'
const DIFF = 'DIFF'
const UPDATE_DATE = 'UPDATE_DATE'
const FILTERS = 'FILTERS'
const STATIONS = 'STATIONS'

const ExportQesoutPanel = ({
    job = {},
    onChangeJob = () => {},
    isEditMode = false,
}) => {
    const {
        piezometers,
        contributors,
        networks,
        cities,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        contributors: store.ContributorReducer.contributors,
        networks: store.NetworkReducer.networks,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const exportModes = [
        { code: ALL, name: i18n.exportHistory },
        { code: CUSTOM, name: i18n.choiseDate },
        { code: DIFF, name: i18n.exportAccordingLastAutoExport },
        { code: UPDATE_DATE, name: i18n.exportWithUpdateDate },
    ]

    const selectStationsMode = [
        { code: FILTERS, name: i18n.selectByCriteria },
        { code: STATIONS, name: i18n.selectStations },
    ]

    const dispatch = useDispatch()

    const { progress, isLoaded } = useProgressDispatch(() => [
        dispatch(PiezometryAction.fetchPiezometersLight()),
        dispatch(ContributorAction.fetchContributors()),
        dispatch(NetworkAction.fetchNetworks()),
        dispatch(CityAction.fetchCities()),
    ], [])

    const jobFilters = useMemo(() => job.parameters.filters.length ? JSON.parse(job.parameters.filters[0]) : {}, [job.parameters.filters])

    const onChangeJobParameter = (changes) => onChangeJob({ parameters: { ...job.parameters, ...changes } })

    const onChangeFilters = (changes) => onChangeJob({ parameters: { ...job.parameters, filters: [JSON.stringify({ ...jobFilters, ...changes })] } })

    const getModal = (selectedStations) => {
        const id = uuidv4()
        const actions = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
            </div>
        )
        const popup = {
            id,
            header: i18n.selectStationsToBeExported,
            actions,
            minHeight: '65vh',
            className: 'hugeModal',
            content: <SelectedElementsModal
                selectedList={selectedStations}
                onChange={(list) => onChangeFilters({ stations: list.map(p => p.id) })}
                list={piezometers}
            />,
        }
        dispatch(SieauAction.setPopup(popup))
    }

    const contributorsFormatted = useMemo(() => contributors.filter(c => c.siret?.length > 0).map((c) => ({
        ...c,
        nameFormatted: `${c?.siret} - ${c?.name || ''}`,
    })), [contributors])

    const selectedStations = useMemo(() => piezometers.filter(p => (jobFilters.stations || []).includes(p.id)), [jobFilters.stations, piezometers])

    if (!isLoaded) {
        return <ProgressCard progress={ progress } />
    }

    return (
        <div>
            <div className='row no-margin padding-top-1 valign-wrapper'>
                <Input
                    col={4}
                    title={i18n.directory}
                    value={job.parameters.path}
                    onChange={v => onChangeJobParameter({ path: v })}
                    disabled={!isEditMode}
                />
                <Select
                    col={4}
                    options={exportModes}
                    label={i18n.exportMode}
                    onChange={v => onChangeFilters({ mode: v })}
                    value={jobFilters.mode}
                    disabled={!isEditMode}
                    tooltip={i18n.exportQesoutTip}
                />
                {(() => {
                    if (jobFilters.mode === CUSTOM) {
                        return [
                            <SimpleDatePicker
                                col={2}
                                label={i18n.startDate}
                                value={jobFilters.startDate}
                                onChange={v => onChangeFilters({ startDate: v })}
                                disabled={!isEditMode}
                            />,
                            <SimpleDatePicker
                                col={2}
                                label={i18n.endDate}
                                value={jobFilters.endDate}
                                onChange={v => onChangeFilters({ endDate: v })}
                                disabled={!isEditMode}
                            />,
                        ]
                    }
                    if (jobFilters.mode === UPDATE_DATE) {
                        return (
                            <SimpleDatePicker
                                col={4}
                                label={i18n.updateDate}
                                value={jobFilters.updateDateModeDate}
                                onChange={v => onChangeFilters({ updateDateModeDate: v })}
                                disabled={!isEditMode}
                            />
                        )
                    }
                    return <div className='col s4' />
                })()}
            </div>
            <div className='row no-margin padding-top-1 valign-wrapper'>
                <MultiContributorsAutocomplete
                    col={4}
                    label={i18n.siret}
                    values={jobFilters.siret}
                    onChange={c => onChangeFilters({ siret: c })}
                    options={contributorsFormatted}
                    keyLabel='nameFormatted'
                    keyValue='siret'
                    disabled={!isEditMode}
                />
                <MultiContributorsAutocomplete
                    col={4}
                    options={contributors}
                    label={i18n.producer}
                    values={jobFilters.producer}
                    onChange={c => onChangeFilters({ producer: c })}
                    keyLabel='mnemonique'
                    disabled={!isEditMode}
                />
                <Checkbox
                    col={4}
                    label={i18n.bruteMeasuresExport}
                    checked={jobFilters.bruteMode}
                    onChange={c => onChangeFilters({ bruteMode: c })}
                    disabled={!isEditMode}
                />
            </div>
            <div className='row no-margin padding-top-1'>
                <RadioButtons
                    col={4}
                    title={i18n.selectStations}
                    elements={selectStationsMode}
                    selected={jobFilters.selectStationsMode || FILTERS }
                    onChange={v => onChangeFilters({ selectStationsMode: v })}
                    disabled={!isEditMode}
                />
            </div>
            {!jobFilters.selectStationsMode || jobFilters.selectStationsMode === FILTERS ? (
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={4}
                        options={networks}
                        label={i18n.network}
                        value={jobFilters.network}
                        onChange={c => onChangeFilters({ network: c })}
                        disabled={!isEditMode}
                    />
                    <Select
                        col={4}
                        options={cities}
                        label={i18n.city}
                        value={jobFilters.city}
                        onChange={c => onChangeFilters({ city: c })}
                        disabled={!isEditMode}
                        keyLabel='labelWithCode'
                    />
                    <SimpleFilterSelect
                        col={4}
                        onChange={(_, f) => onChangeFilters({ stationsFilter: f && f !== -1 ? f : null })}
                        stations={piezometers}
                        stationType={STATION_TYPE_NAME.piezometry}
                        disabled={!isEditMode}
                        value= {jobFilters.stationsFilter}
                    />
                </div>
            ) : (
                <div className='row no-margin padding-top-1'>
                    <Table
                        title={i18n.stations}
                        data={selectedStations}
                        nbPerPageLabel={nbPerPageLabelShort}
                        type={{ headers: ['code', 'name'] }}
                        actions={[{ iconName: 'edit', onClick: () => getModal(selectedStations) }]}
                        sortable condensed activeHeader paging
                    />
                </div>
            )}
        </div>
    )
}

ExportQesoutPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
}

export default ExportQesoutPanel