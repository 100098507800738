import { maxBy, min, minBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ChartTabs from '../../../components/echart/ChartTabs'
import {
    getMaxProvisionalDate,
} from '../../../components/echart/EChartUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import HydrometryAction from '../../actions/HydrometryAction'
import DtoHydroMeasures from '../../dto/chronicMeasures/DtoHydroMeasures'
import DtoHydroStats from '../../dto/chronicMeasures/DtoHydroStats'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import DtoHydrometryThreshold from '../../dto/DtoHydrometryThreshold'
import Row from '../../../components/react/Row'
import { WhiteCard } from 'components/styled/Card'
import DtoEvent from '../../../events/dto/DtoEvent'
import EventsAction from '../../../events/actions/EventsAction'
import GroupedHydroPanel from './GroupedHydroPanel'
import ChartReviewHydro from './ChartReviewHydro'
import ProgressCard from '../../../components/card/ProgressCard'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { getLocalStorageJson } from '../../../utils/FormUtils'
import { Grid } from '@mui/material'
import IAEauAction from '../../../iaeau/IAEauAction'

// TODO ATTENTION ! Composant réutilisé dans plusieurs écrans
const HydroChartPanel = ({
    id,
    showLegend,
    isPopin = true,
}) => {
    const [fullScreen, setFullScreen] = useState(false)
    const [minDate, setMinDate] = useState(undefined)
    const [maxDate, setMaxDate] = useState(moment().valueOf())
    const [dataLoaded, setDataLoaded] = useState(false)
    const [hydroStatistics, setHydroStatistics] = useState([])
    const [progress, setProgress] = useState(0)
    const [chartTab, setChartTab] = useState(null)
    const [hydroGrouped, setHydroGrouped] = useState('AUTO')
    const [modelData, setModelData] = useState({ predMeasures: [], selectedPred: undefined, predDate: undefined, predStats: [] })

    const {
        hydroMeasures,
    } = useSelector(store => ({
        hydroMeasures: store.HydrometryReducer.hydroMeasures,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
        dispatch(HydrometryAction.fetchHydrometryDataTypes())
        dispatch(HydrometryAction.fetchHydrometricStation(id))
        dispatch(HydrometryAction.fetchHydroStatistics(id)).then((params = []) => setHydroStatistics(params.map(stats => new DtoHydroStats(stats))))
        dispatch(EventsAction.fetchStationEvents('hydrometry', id))
        dispatch(IAEauAction.getModels('hydrometry', parseInt(id)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadData = (dateMin, dateMax) => {
        setDataLoaded(false)
        const defaultGroupMode = dateMin && dateMin > moment(dateMax).subtract('91', 'day').valueOf() ? 'all' : 'MAX'
        const inputs = hydroStatistics.map(stat => {
            const groupFunc = hydroGrouped === 'AUTO' ? defaultGroupMode : hydroGrouped
            return {
                stationId: id,
                dataType: stat.typeId,
                groupFunc: stat.typeId === -2 ? 'SUM_DAY' : groupFunc,
                chartMode: true,
                startDate: getLocalStorageJson('CHART_TABS')?.active === 'HISTO' ? undefined : moment(dateMin).subtract(1, 'day').valueOf(),
                endDate: moment(dateMax).add(1, 'day').valueOf(),
            }
        })
        dispatch(HydrometryAction.loadHydroChronicMeasures(inputs, p => setProgress(p))).then(() => setDataLoaded(true))
    }

    const minDateFilterPreview = (date) => {
        const allFirstMeasures = hydroMeasures.flatMap(obj => obj.measures.length ? [obj.measures[0].date] : [])
        return date ?? min(allFirstMeasures) ?? minBy(hydroStatistics, 'startDate')?.startDate ?? moment().subtract(1, 'years').valueOf()
    }

    const maxDateFilterPreview = (date) => {
        if (date) {
            return date
        }
        const lastMeasureDate = maxBy([...hydroStatistics, ...hydroMeasures], 'endDate')?.endDate
        const todaysDay = moment().valueOf()
        return lastMeasureDate && lastMeasureDate > todaysDay ? getMaxProvisionalDate(chartTab, minDate, lastMeasureDate) : todaysDay
    }

    useUpdateEffect(() => {
        loadData(minDateFilterPreview(minDate), maxDateFilterPreview(maxDate))
    }, [hydroGrouped])

    const onChangeDates = (dates, forced) => {
        if (minDate !== dates.minDate || maxDate !== dates.maxDate || forced) {
            setMinDate(dates?.minDate)
            setMaxDate(dates?.maxDate)
            setChartTab(dates?.chartTab)
            loadData(minDateFilterPreview(dates?.minDate), maxDateFilterPreview(dates?.maxDate))
        }
    }

    const fullScreenStyle = (isPopin && fullScreen) ? {
        position: 'fixed',
        paddingTop: 5,
        top: 0,
        left: 0,
        height: !isPopin ? `calc(${window.innerHeight}px - 5rem)` : `calc(${window.innerHeight}px)`,
        width: `calc(${window.innerWidth}px)`,
        zIndex: 9,
        marginTop: !isPopin ? '5rem' : '0',
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    if (!hydroStatistics.length) {
        return null
    }

    return (
        <WhiteCard noBoxShadow round>
            <Grid container sx={fullScreenStyle}>
                <Grid item xs={12}>
                    <Row className={(!isPopin && fullScreen) ? 'fullscreen-chart' : ''}>
                        <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                            <div>
                                <ChartTabs
                                    onChangeDate={ (dates, forced) => onChangeDates(dates, forced) }
                                    stationType='hydrometry'
                                    changeParentSimple={ setModelData }
                                    withModels
                                    id={id}
                                />
                            </div>
                            <GroupedHydroPanel
                                hydroGrouped={hydroGrouped}
                                setHydroGrouped={setHydroGrouped}
                                left={modelData?.predStats?.length ? 530 : 480}
                            />
                        </div>
                        <div className='col s12 row no-margin'>
                            <div>
                                {
                                    dataLoaded ? (
                                        <ChartReviewHydro
                                            id={id}
                                            hydroStatistics={hydroStatistics}
                                            fullScreen={fullScreen}
                                            setFullScreen={setFullScreen}
                                            hydroMeasures={hydroMeasures}
                                            minDate={minDateFilterPreview(minDate)}
                                            maxDate={ chartTab === 'OTHER' ? maxDate : maxDateFilterPreview(maxDate)}
                                            showLegend={showLegend}
                                            isPopin={isPopin}
                                            modelData={modelData}
                                        />
                                    ) : <ProgressCard progress={progress} />
                                }
                            </div>
                        </div>
                    </Row>
                </Grid>
            </Grid>
        </WhiteCard >
    )
}

HydroChartPanel.propTypes = {
    id: PropTypes.number,
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    loadHydroChronicMeasures: PropTypes.func,
    hydroStatistics: arrayOf(DtoHydroStats),
    hydroMeasures: arrayOf(DtoHydroMeasures),
    hydrometryThresholds: arrayOf(DtoHydrometryThreshold),
    fetchHydrometricThresholds: PropTypes.func,
    stationEvents: arrayOf(DtoEvent),
    fetchHydrometryDataTypes: PropTypes.func,
    showLegend: PropTypes.bool,
    isPopin: PropTypes.bool,
}

export default HydroChartPanel