export default class PerimeterFolderDto {
    constructor(obj) {
        this.code = obj.code
        this.name = obj.name
        this.city = obj.city
        this.collectivity = obj.collectivity
        this.perimeters = obj.perimeters
        this.status = obj.status
        this.startDate = obj.startDate
        this.endDate = obj.endDate ?? ''
        this.state = obj.state
    }
}