import { uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import NetworkAction from '../../../../referencial/components/network/actions/NetworkAction'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../store/AppStore'
import { hasBooleanValue } from '../../../../utils/NumberUtil'
import { arrayOf } from '../../../../utils/StoreUtils'
import AdesStationDto from '../../../dto/AdesStationDto'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import MultiContributorsAutocomplete from '../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import { getIntegrationModes, getImportMode } from '../../../../utils/JobUtils'

class AdesQualitoWebServicePanel extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const filters = this.getFilters()
        this.onChangeFilters({ selectStationsMode: filters.selectStationsMode || 'codesMode', stationCodes: filters.stationCodes || (this.props.job.parameters.parameters && this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters : []) })
        AppStore.dispatch(NetworkAction.fetchNetworks())
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
    }

    onDeleteBssCode = ({ bsscode }) => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            this.onChangeFilters({ stationCodes: filters.stationCodes.filter(p => p !== bsscode) })
        }
    }


    addBssCode = () => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            const code = this.refs.txtAddBssCode.value
            const exist = filters.stationCodes.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                AppStore.dispatch(ToastrAction.error(i18n.youMustEnterAtLeastOneBssCode))
            } else if (exist) {
                AppStore.dispatch(ToastrAction.error(i18n.youHaveAlreadyEnteredThisBssCode))
            } else {
                this.onChangeFilters({ stationCodes: [ code, ...filters.stationCodes ] })
                this.refs.txtAddBssCode.value = ''
            }
        }
    }

    onChangeBssFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = new Buffer(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '').split('\n').map(b => b.trim())
                    const filteredCodes = codes.filter((c) => c !== '')
                    const filters = this.getFilters()
                    this.onChangeFilters({ stationCodes: uniq([ ...filters.stationCodes, ...filteredCodes ]) })
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    getStations = () => {
        const filters = this.getFilters()
        return filters.stationCodes.map(bsscode => new AdesStationDto({ bsscode }))
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = parameters.dataTypes.filter(d => !d.includes('DATA'))
        if (integration) {
            dataTypes.push(integration)
        }
        this.props.onChangeDataTypes(dataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = parameters.dataTypes.filter(d => !d.includes('station'))
        if (integration) {
            dataTypes.push('stationIntegration')
        }
        this.props.onChangeDataTypes(dataTypes)
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { descriptionImportMode: 'all', addCodesToADESNetwork: true, ...newFilters }
    }

    getStationsPanel = () => {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.importFile }</label>
                    </div>
                    <div className='file-field col s9 no-padding input-field'>
                        <div className='col s3 no-padding btn'>
                            <span>{ i18n.importLabel }</span>
                            <input
                                type='file'
                                ref='importFile'
                                accept='.csv'
                                onChange={ this.onChangeBssFile }
                                {...disabled}
                            />
                        </div>
                        <div className='file-path-wrapper col s9'>
                            <input
                                className='file-path'
                                ref='fileInput'
                                type='text'
                                placeholder={ i18n.selectFile }
                                {...disabled}
                            />
                        </div>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.addCredential }</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddBssCode' type='text' placeholder={ i18n.BSSExample } ref='txtAddBssCode'/>
                            <label className='tinyLabel'>{ i18n.theCodeMustBePresentInTheRepositoryAndDefinedByTheCode }</label>
                        </div>
                        <a
                            className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={ this.addBssCode }
                        >
                            { i18n.add }
                        </a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={ new AdesStationDto() }
                            data={ this.getStations() }
                            title={ i18n.credentials }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteBssCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            activeHeader
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }

    getFiltersPanel = (filters) => {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <Row>
                    <Select options={ this.props.networks } keyLabel='mnemonic' col={ 5 } label={ i18n.network } nullLabel='&nbsp;' onChange={ value => this.onChangeFilters({ network: value }) } value={ filters.network } {...disabled} keyValue='id'/>
                    <Input col={ 4 } title={ i18n.departmentNumber } onChange={ v => this.onChangeFilters({ departmentCodes: v }) } value={ filters.departmentCodes } placeholder='Ex: 44,85,49' {...disabled}/>
                    <NumberField col={ 3 } title={ i18n.regrouping } onChange={ v => this.onChangeFilters({ nbGroupCall: v }) } value={ filters.nbGroupCall } min={ 0 } {...disabled}/>
                </Row>
            </div>
        )
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const selectModes = [
            { code: 'codesMode', name: i18n.stations },
            { code: 'filtersMode', name: i18n.filters },
        ]

        const descriptionImportModes = [
            { code: 'all', name: i18n.waterPointAndHisto },
            { code: 'PointEau', name: i18n.pointeauOnly },
        ]
        const filters = this.getFilters()
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <Checkbox
                            checked={ !!this.getDataTypes('station') }
                            label={ i18n.integrateStationInfo }
                            onChange={ this.handleChangeStationIntegration }
                            { ...disabled }
                        />
                    </div>
                    <div className='col s2'>
                        <Checkbox
                            checked={ hasBooleanValue(filters.syncEvents) ? filters.syncEvents : true }
                            label={ i18n.syncEvents }
                            onChange={ v => this.onChangeFilters({ syncEvents: v }) }
                            disabled={ disabled.disabled || !this.getDataTypes('station') }
                        />
                    </div>
                    <div className='col s2'>
                        <Checkbox
                            checked={ hasBooleanValue(filters.syncNetworks) ? filters.syncNetworks : true }
                            label={ i18n.syncNetworks }
                            onChange={ v => this.onChangeFilters({ syncNetworks: v }) }
                            disabled={ disabled.disabled || !this.getDataTypes('station') }
                        />
                    </div>
                    <div className='col s4'>
                        <Select
                            value={getImportMode(this.getDataTypes('DATA'))}
                            options={getIntegrationModes()}
                            label={ i18n.dataIntegration }
                            labelSpan={ integrationModeHelpIcon() }
                            onChange={this.handleChangeDataIntegration}
                            {...disabled}
                        />
                    </div>
                    <div className='col s1'/>
                </div>
                <Row>
                    <RadioButtons col={ 3 } title={ i18n.selectionMode } elements={ selectModes } onChange={ v => this.onChangeFilters({ selectStationsMode: v }) } selected={ filters.selectStationsMode } {...disabled}/>
                    <Checkbox
                        col={4}
                        checked={ filters.dontCheckInternalReferences }
                        label={ i18n.dontCheckInternalReferences }
                        onChange={ v => this.onChangeFilters({ dontCheckInternalReferences: v }) } {...disabled}
                    />
                    <MultiContributorsAutocomplete
                        col={5}
                        multiple
                        options={this.props.contributors}
                        values={filters.excludedProducers}
                        label={ i18n.producersExcluded }
                        onChange={values => {
                            this.onChangeFilters({ excludedProducers: values })
                        }}
                        {...disabled}
                    />
                </Row>
                <div className='row no-margin valign-wrapper'>
                    <Select col={ 4 } label={ i18n.importMode } options={ descriptionImportModes } value={ filters.descriptionImportMode } onChange={ v => this.onChangeFilters({ descriptionImportMode: v }) } {...disabled}/>
                    <Checkbox col={ 3 } checked={ filters.addCodesToADESNetwork } label={ i18n.addCodesToADESNetwork } onChange={ v => this.onChangeFilters({ addCodesToADESNetwork: v }) } {...disabled}/>
                    <Select options={ this.props.networks } keyLabel='mnemonic' col={ 5 } label={ i18n.synchNetwork } nullLabel='&nbsp;' onChange={ value => this.onChangeFilters({ selectedNetwork: value }) } value={ filters.selectedNetwork } disabled={ disabled.disabled || !filters.addCodesToADESNetwork } keyValue='id'/>
                </div>
                { filters.selectStationsMode === 'codesMode' ? this.getStationsPanel() : this.getFiltersPanel(filters) }
            </div>
        )
    }
}

AdesQualitoWebServicePanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addBssCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    onChangeFilters: PropTypes.func,
    onChangeJob: PropTypes.func,
    networks: arrayOf(NetworkDto),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    fetchContributor: PropTypes.func,
}

const mapStateToProps = store => ({
    networks: store.NetworkReducer.networks,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdesQualitoWebServicePanel)
