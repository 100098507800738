import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoCasingDeclaration from 'survey/dto/DtoCasingDeclaration'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { hasValue } from 'utils/NumberUtil'
import { formatMilliers } from 'utils/StringUtil'

class RetenueCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            retenue: props.retenue,
        }
    }

    getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    render() {
        const { title, actions, sandreCodes, agriTanksTypes } = this.props
        const { retenue } = this.state
        const tankType = agriTanksTypes.find((t) => t.id === retenue.tankType) || {}
        const fillingMode = sandreCodes.find((c) => c.field === 'PREL_AGRI.MODE_REMPLISSAGE' && c.code === retenue.fillingMode) || {}
        const saisonnal = (retenue.link_periodes || []).find((p) => p.idPeriode === 2)
        const annuel = (retenue.link_periodes || []).find((p) => p.idPeriode === 1)
        const unknown = (retenue.link_periodes || []).find((p) => p.idPeriode === 0)
        const fillingPeriod = saisonnal ? 2 : (annuel ? 1 : (hasValue(unknown) ? 0 : null))
        const fillingPeriode = sandreCodes.find((c) => c.field === 'PREL_AGRI.PERIODE_REMPLISSAGE' && c.code === fillingPeriod) || {}
        const periodesDetails = sandreCodes.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')

        let saisons = ''
        if (saisonnal) {
            periodesDetails.map((p) => {
                if (this.getSelectedPeriod(p.code, retenue.link_periodes)) {
                    saisons = `${saisons}${p.name}, `
                }
            })
        }
        return (
            <Card
                title={title}
                headerStyle={{ backgroundColor: secondaryBlue }}
                actions={actions}
            >
                <div className='row no-margin padding-bottom-1' style={{
                    border: `1px solid ${secondaryBlue}`,
                    borderWidth: '0 2 2 2',
                }}
                >
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.detentionType}
                        value={tankType.name}
                        readMode
                    />
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.capability}
                        value={`${formatMilliers(retenue.capacity) || 0} m3` || ''}
                        readMode
                    />
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.recoveryFlow}
                        value={retenue.recoveryFlow}
                        readMode
                    />
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.reserveFlow}
                        value={retenue.reserveFlow}
                        readMode
                    />
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.fillingMode}
                        value={fillingMode.name}
                        readMode
                    />
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.fillingPeriod}
                        value={`${fillingPeriode?.name || ''}${saisons.length ? ` (${saisons.slice(0, -2)})` : ''}`}
                        readMode
                    />
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.shared}
                        value={retenue.sharedRetention ? i18n.yes : i18n.no}
                        readMode
                    />
                    <Input
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.usedPercentage}
                        value={retenue.usedPercentage}
                        readMode
                    />
                    <Textarea
                        col={12}
                        className='padding-left-1 padding-top-1'
                        title={i18n.comment}
                        value={retenue.comment}
                        readMode
                    />
                </div>
            </Card>
        )
    }
}

RetenueCard.propTypes = {
    title: PropTypes.string.isRequired,
    retenue: PropTypes.instanceOf(DtoCasingDeclaration).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
}

RetenueCard.defaultProps = {
    editable: false,
    retenue: {},
}

const mapStateToProps = (store) => {
    return {
        sandreCodes: store.ReferencialReducer.sandreCodes,
        agriTanksTypes: store.AgriReducer.agriTanksTypes,
    }
}

export default connect(mapStateToProps)(RetenueCard)
