import React, { Component } from 'react'
import { arrayOf } from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import Checkbox from '../../../../components/forms/Checkbox'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import PropTypes from 'prop-types'
import Job from '../../../dto/DtoJob'
import { connect } from 'react-redux'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import Button from '../../../../components/forms/Button'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import SelectionTableModal from '../../../../components/modal/SelectionTableModal'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../../store/AppStore'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import Table from '../../../../components/datatable/Table'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import RadioButtons from '../../../../components/forms/RadioButtons'
import AdesStationDto from '../../../dto/AdesStationDto'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import { uniq } from 'lodash'
import ToastrAction from '../../../../toastr/actions/ToastrAction'

class MeteoFrancePanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cityPopupOpen: false,
        }
    }

    componentDidMount() {
        if (!this.props.cities.length) {
            AppStore.dispatch(CityAction.fetchCities())
        }
        if (!this.props.pluviometryDataTypes.length) {
            AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return {
            cities: [],
            daily: true,
            rain: true,
            importMode: 'DATA_ADD_LAST_DATA',
            takePrincipal: true,
            codeMode: 'stations',
            ...newFilters }
    }

    onImportFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = new Buffer(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '').split('\n').map(b => b.trim())
                    const filteredCodes = codes.filter((c) => c !== '')
                    const filters = this.getFilters()
                    if (filters.codeMode === 'departments') {
                        this.onChangeFilters({ departmentCodes: uniq([ ...(filters.departmentCodes ?? []), ...filteredCodes ]) })
                    } else {
                        this.onChangeFilters({ stationCodes: uniq([ ...(filters.stationCodes ?? []), ...filteredCodes ]) })
                    }
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            const previousList = filters.codeMode === 'departments' ? filters.departmentCodes ?? [] : filters.stationCodes ?? []
            const code = this.refs.txtAddBssCode.value
            const exist = previousList.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                AppStore.dispatch(ToastrAction.error(i18n.youMustEnterAtLeastOneCode))
            } else if (exist) {
                AppStore.dispatch(ToastrAction.error(i18n.youHaveAlreadyEnteredThisCode))
            } else {
                if (filters.codeMode === 'departments') {
                    this.onChangeFilters({ departmentCodes: [ code, ...(filters.departmentCodes ?? []) ] })
                } else {
                    this.onChangeFilters({ stationCodes: [ code, ...(filters.stationCodes ?? []) ] })
                }
                this.refs.txtAddBssCode.value = ''
            }
        }
    }

    getStations = () => {
        const filters = this.getFilters()
        if (filters.codeMode === 'departments') {
            return (filters.departmentCodes ?? []).map(bsscode => new AdesStationDto({ bsscode }))
        }
        return (filters.stationCodes ?? []).map(bsscode => new AdesStationDto({ bsscode }))
    }

    onDeleteBssCode = ({ bsscode }) => {
        const filters = this.getFilters()
        this.onChangeFilters({ stationCodes: filters.stationCodes.filter(p => p !== bsscode) })
    }

    render() {
        const disabled = !this.props.isEditMode
        const filters = this.getFilters()
        const mfDataTypes = ['rain', 'radiation', 'temperature', 'etp']
        const dataTypes = mfDataTypes.map(eraDt => {
            return {
                nullValue: <Checkbox checked={ filters[eraDt] } onChange={ v => this.onChangeFilters({ [eraDt]: v }) } disabled={ disabled } />,
                name: i18n[eraDt],
                dataType: <Select value={ filters[`${eraDt}Dt`] } options={ this.props.pluviometryDataTypes } onChange={ v => this.onChangeFilters({ [`${eraDt}Dt`]: v })} disabled={ disabled || !filters[eraDt] }/>,
            }
        })
        const codesTypes = [
            { code: 'stations', name: i18n.stations },
            { code: 'cities', name: i18n.cities },
            { code: 'departments', name: i18n.departments },
        ]
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <fieldset>
                        <legend>&nbsp;{i18n.dataTypes}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Row className='padding-top-1'>
                                <Table data={ dataTypes } showTitle={false} type={ { headers: ['nullValue', 'name', 'dataType'] }}
                                    condensed sortable showNbElements={ false }
                                />
                            </Row>
                        </div>
                    </fieldset>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <fieldset className='width-100'>
                        <legend>&nbsp;{ i18n.parameters }&nbsp;</legend>
                        <Row className='valign-wrapper'>
                            <Select col={ 3 } options={ getIntegrationModes() } obligatory={true} label={ i18n.dataIntegration } value={ filters.importMode } labelSpan={ integrationModeHelpIcon() }
                                onChange={ v => this.onChangeFilters({ importMode: v }) }
                                disabled={ disabled }
                            />
                            <RadioButtons col={3} elements={codesTypes} selected={filters.codeMode}
                                onChange={v => this.onChangeFilters({ codeMode: v })}
                                title={i18n.schemeAgencyID} disabled={disabled}
                            />
                            <SimpleDatePicker
                                label={i18n.startDate}
                                value={filters.startDate}
                                onChange={date => this.onChangeFilters({ startDate: date })}
                                col={2}
                                disabled={ disabled }
                            />
                            <div className='col s4'/>
                        </Row>
                    </fieldset>
                </div>
                {
                    filters.codeMode === 'stations' || filters.codeMode === 'departments' ? (
                        <div>
                            <div className='row no-margin valign-wrapper'>
                                <div className='col s3'>
                                    <label>{ i18n.importFile }</label>
                                </div>
                                <div className='file-field col s9 no-padding input-field'>
                                    <div className='col s3 no-padding btn'>
                                        <span>{ i18n.importLabel }</span>
                                        <input
                                            type='file'
                                            ref='importFile'
                                            accept='.csv'
                                            onChange={ this.onImportFile }
                                            {...disabled}
                                        />
                                    </div>
                                    <div className='file-path-wrapper col s9'>
                                        <input
                                            className='file-path'
                                            ref='fileInput'
                                            type='text'
                                            placeholder={ i18n.selectFile }
                                            {...disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row valign-wrapper'>
                                <div className='col s3'>
                                    <label>{ i18n.addCredential }</label>
                                </div>
                                <div className='input-field col s9 no-padding'>
                                    <div className='col s8'>
                                        <input id='txtAddBssCode' type='text' placeholder={ i18n.BSSExample } ref='txtAddBssCode'/>
                                        <label className='tinyLabel'>{ i18n.theCodeMustBePresentInTheRepositoryAndDefinedByTheCode }</label>
                                    </div>
                                    <a
                                        className='waves-effect waves-light btn col offset-s1 s3'
                                        onClick={ this.addCode }
                                    >
                                        { i18n.add }
                                    </a>
                                </div>
                            </div>
                            <div className='row valign-wrapper'>
                                <div className='col s12'>
                                    <Table
                                        type={ new AdesStationDto() }
                                        data={ this.getStations() }
                                        title={ i18n.credentials }
                                        nbPerPageLabel={ nbPerPageLabelTiny }
                                        onDelete={ this.onDeleteBssCode }
                                        deletable={ this.props.isEditMode }
                                        showNbElements
                                        activeHeader
                                        paging
                                        exportButtonOnHeader
                                    />
                                </div>
                            </div>
                        </div>
                    ) : undefined
                }
                {
                    filters.codeMode === 'cities' ? (
                        <>
                            <div className='row no-margin valign-wrapper'>
                                <fieldset className='width-100'>
                                    <legend>&nbsp;{ i18n.cities }&nbsp;</legend>
                                    <Row>
                                        <Button col={ 4 } title={ `${(filters.cityCodes ?? []).length} ${i18n.cities}` } onClick={ () => this.setState({ cityPopupOpen: true })}/>
                                    </Row>
                                </fieldset>
                            </div>
                            <SelectionTableModal
                                isOpen={this.state.cityPopupOpen}
                                onClose={() => this.setState({ cityPopupOpen: false })}
                                onValidate={list => {
                                    this.onChangeFilters({ cityCodes: list })
                                    this.setState({ cityPopupOpen: false })
                                }}
                                title={i18n.cities}

                                listData={this.props.cities}
                                defaultSelectionList={filters.cityCodes ?? []}

                                listHeaders={['code', 'name']}
                                listTitle={i18n.cities}
                                selectionListTitle={i18n.selectedElements}
                                maxHeightTable={'48vh'}

                                filterField={({
                                    filter,
                                    setFilter,
                                }) => (
                                    <div className='row no-margin' style={{ padding: '10px 0px' }}>
                                        <Input
                                            col={6}
                                            title={i18n.search}
                                            value={filter.searchValue}
                                            onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                                        />
                                    </div>
                                )}
                                filterFunction={(list, { searchValue }) => {
                                    const searchValueFormat = searchAllCharacters(searchValue)
                                    return searchValue ? list.filter(p => searchAllCharacters([p.code, p.name].join('   ')).includes(searchValueFormat)) : list
                                }}
                            />
                        </>
                    ) : undefined
                }
            </div>
        )
    }
}

MeteoFrancePanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    cities: arrayOf(CityDto),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
})

export default connect(mapStateToProps)(MeteoFrancePanel)