import ActionComponent from 'components/ActionComponent'
import download from 'downloadjs'
import { PATH_ADMINISTRATION, PATH_ADMINISTRATION_MODELS } from 'home/constants/RouteConstants'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import { contentsPath } from '../../../conf/basepath'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import StationAction from '../../../station/actions/StationAction'
import DtoPublicPicture from '../../../station/dto/DtoPublicPicture'
import { getUser } from '../../../utils/SettingUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import DtoModelUpload from './DtoModelUpload'
import { MODELS_TYPES } from './ModelConstants'
import PopupAddModel from './PopupAddModel'

class ModelApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            models: [],
            modelsToDelete: [],
            open: false,
            stationType: 0,
            modelToAdd: new DtoModelUpload({}),
        }
    }

    componentDidMount() {
        if (!this.props.allModelsByType.length) {
            const stationsTypes = MODELS_TYPES.map(model => model.stationType)
            this.props.fetchAllModelsByType(stationsTypes).then(() => {
                this.getModelsDataTable()
            })
        } else {
            this.getModelsDataTable()
        }
        this.props.forceFetch('title', [{
            title: i18n.administration,
            href: PATH_ADMINISTRATION,
        }, {
            title: i18n.models,
            href: PATH_ADMINISTRATION_MODELS,
        }])
        this.setReadOnlyMode()
    }

    setEditMode = () => {
        const actions = {
            new: () => {
                $('.tooltipped').tooltip('remove')
                this.setState({ open: true })
            },
            cancel: () => {
                $('.tooltipped').tooltip('remove')
                this.setState({ models: this.formatModels(), modelsToDelete: [] })
                this.setReadOnlyMode()
            },
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => {
                $('.tooltipped').tooltip('remove')
                this.deleteModels()
                this.setReadOnlyMode()
            }
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ disabled: true })
        if (!(getUser().consultant === '1')) {
            const actions = {
                new: () => {
                    $('.tooltipped').tooltip('remove')
                    this.setState({ open: true })
                },
                edit: () => {
                    $('.tooltipped').tooltip('remove')
                    this.setState({ disabled: false })
                    this.setEditMode()
                },
            }
            this.setActions(actions)
        } else {
            this.setActions({})
        }
    }

    addModel = () => {
        const { modelToAdd } = this.state
        this.props.addModelFile(modelToAdd).then(() => {
            const stationsTypes = MODELS_TYPES.map(model => model.stationType)
            this.props.fetchAllModelsByType(stationsTypes).then(() => {
                this.getModelsDataTable()
                this.setState({ open: false, modelToAdd: new DtoModelUpload({}) })
            })
        })
    }

    onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            const name = file.name.replace(/ /g, '_')
            reader.onload = (e) => {
                const modelType = MODELS_TYPES.find(m => m.code === this.state.stationType)
                this.setState({
                    modelToAdd:
                    {
                        stationType: modelType.stationType,
                        name,
                        content: e.target.result,
                    },
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    deleteModels = () => {
        const { modelsToDelete } = this.state
        if (modelsToDelete?.length > 0) {
            const modelsToDeleteFormatted = modelsToDelete.map((model) => ({
                name: model.name,
                fileType: 'model',
                stationType: model.stationType,
            }))
            this.props.deleteFiles(modelsToDeleteFormatted).then(() => {
                const stationsTypes = MODELS_TYPES.map(model => model.stationType)
                this.props.fetchAllModelsByType(stationsTypes).then(() => {
                    this.getModelsDataTable()
                    this.setState({ modelsToDelete: [] })
                })
            })
        }
    }

    removeModel = (model) => {
        const { models, modelsToDelete } = this.state
        const newModels = models.filter((m) => (m.name !== model.name || m.url !== model.url))
        this.setState({ models: newModels, modelsToDelete: [...modelsToDelete, model] })
    }

    getUrlModel = (model) => {
        return `${contentsPath.substring(0, contentsPath.length-1)}${model.url}${model.name}`
    }

    formatModels = () => {
        const { allModelsByType } = this.props
        return allModelsByType.map((model) => ({
            ...model,
            directory: model.url,
            model: (<span style={{ cursor: 'pointer' }} onClick={() => download(this.getUrlModel(model))}>{model.name}</span>),
            nullValue: (<i className='material-icons clickable' onClick={() => this.removeModel(model)}>close</i>),
        }))
    }

    getModelsDataTable = () => {
        const models = this.formatModels()
        this.setState({ models })
    }

    render() {
        const { models, disabled, open, stationType } = this.state
        const headers = disabled ? ['directory', 'model'] : ['directory', 'model', 'nullValue']
        return (
            <div className='row no-margin'>
                <div className='col s12 padding-top-1'>
                    <Table
                        title={i18n.models}
                        condensed sortable paging
                        nbPerPageLabel={nbPerPageLabel}
                        filter={this.state.searchValue}
                        data={models}
                        type={{ headers }}
                    />
                </div>
                {open && (
                    <PopupAddModel
                        openDialog={open}
                        stationType={stationType}
                        changeStationType={(value) => this.setState({ stationType: value })}
                        onUploadFile={event => this.onUploadFile(event)}
                        addModel={(model) => this.addModel(model)}
                        closeDialog={() => this.setState({ open: false })}
                    />
                )}
            </div>
        )
    }
}

ModelApp.propTypes = {
    forceFetch: PropTypes.func,
    allModelsByType: PropTypes.arrayOf(PropTypes.instanceOf(DtoPublicPicture)),
    fetchAllModelsByType: PropTypes.func,
    addModelFile: PropTypes.func,
    deleteFile: PropTypes.func,
}

const mapStateToProps = store => ({
    allModelsByType: store.AdministrationReducer.allModelsByType,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchAllModelsByType: AdministrationAction.fetchAllModelsByType,
    addModelFile: AdministrationAction.addModelFile,
    deleteFiles: StationAction.deleteFiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelApp)