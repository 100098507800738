import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { ONE_MONTH, ONE_WEEK, ONE_YEAR, OTHER, CUSTOM } from 'alerting/constants/ChartFollowContants'
import { Grid } from '@mui/material'
import Input from 'components/forms/Input'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Button from 'components/forms/Button'
import Checkbox from 'components/forms/Checkbox'
import { WhiteCard } from '../../../../components/styled/Card'

const AUTO_Y_SCALE = 0
const MANUAL_Y_SCALE = 1

const SimpleOptionsToolPanel = ({
    showTitle = true,
    updateState = () => {},
    startDate = null,
    endDate = null
}) => {
    const [graphicTitle, setGraphicTitle] = useState('')
    const [minY, setMinY] = useState()
    const [maxY, setMaxY] = useState()
    const [intervalChoice, setIntervalChoice] = useState(OTHER)
    const [intervalNb, setIntervalNb] = useState(5)
    const [yChoice, setYChoice] = useState(AUTO_Y_SCALE)
    const [showXSplitLines, setShowXSplitLines] = useState(true)
    const [showYSplitLines, setShowYSplitLines] = useState(true)

    useEffect(() => {
        setIntervalChoice(OTHER)
    }, [startDate, endDate])

    const onCancel = () => {
        setGraphicTitle('')
        setMinY()
        setMaxY()
        setIntervalChoice(OTHER)
        setIntervalNb(5)
        setYChoice(AUTO_Y_SCALE)
        setShowXSplitLines(true)
        setShowYSplitLines(true)
        updateState(prevState => ({
            ...prevState,
            graphicTitle: '',
            minY: null,
            maxY: null,
            intervalChoice: OTHER,
            showXSplitLines: true,
            showYSplitLines: true,
        }))
    }

    const onValidate = () => updateState(prevState => ({
        ...prevState,
        graphicTitle,
        minY,
        maxY,
        intervalChoice,
        showXSplitLines,
        showYSplitLines,
        intervalNb
    }))

    const intervalChoices = useMemo(() => {
        if (startDate && endDate && (endDate - startDate > 3600*1000*24*365.5*2)) { // si supérieur à 2 ans
            return [
                { code: OTHER, name: i18n.automatic },
                { code: ONE_YEAR, name: i18n.perYear },
                { code: CUSTOM, name: i18n.choice },
            ]
        }
        return [
            { code: OTHER, name: i18n.automatic },
            { code: ONE_WEEK, name: i18n.perWeek },
            { code: ONE_MONTH, name: i18n.perMonth },
            { code: ONE_YEAR, name: i18n.perYear },
            { code: CUSTOM, name: i18n.choice },
        ]
    }, [startDate, endDate])

    const yMode = useMemo(() => [
        {
            code: AUTO_Y_SCALE,
            name: i18n.automatic,
        }, {
            code: MANUAL_Y_SCALE,
            name: i18n.manual,
        },
    ], [])

    const minMaxDisabled = yChoice !== MANUAL_Y_SCALE

    useEffect(() => {
        if (yChoice === AUTO_Y_SCALE) {
            setMinY(undefined)
            setMaxY(undefined)
        }
    }, [yChoice])

    return (
        <WhiteCard className='margin-top-1' title={i18n.chartOptions}>
            <Grid container sx={{ padding: '10px' }}>
                {showTitle && (
                    <Grid item xs={12}>
                        <Input
                            value={graphicTitle}
                            title={i18n.title}
                            onChange={setGraphicTitle}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <StyledFieldSet className='padding-1'>
                        <StyledLegend>{i18n.yScale}</StyledLegend>
                        <Checkbox
                            componentClassName='margin-left-1 padding-top-1 padding-bottom-1'
                            checked={showYSplitLines}
                            label={`${i18n.show} / ${i18n.toggle} ${i18n.Ygrid}`}
                            onChange={setShowYSplitLines}
                        />
                        <RadioButtons
                            title={i18n.chartScale}
                            elements={yMode}
                            selected={yChoice}
                            onChange={setYChoice}
                            className='padding-bottom-1'
                        />
                        <NumberField
                            title={i18n.min}
                            value={minY}
                            onChange={setMinY}
                            disabled={minMaxDisabled}
                            floatValue
                        />
                        <NumberField
                            title={i18n.max}
                            value={maxY}
                            onChange={setMaxY}
                            disabled={minMaxDisabled}
                            floatValue
                        />
                    </StyledFieldSet>
                </Grid>
                <Grid item xs={12}>
                    <StyledFieldSet className='padding-1'>
                        <StyledLegend>{i18n.xScale}</StyledLegend>
                        <Checkbox
                            componentClassName='margin-left-1 padding-top-1 padding-bottom-1'
                            checked={showXSplitLines}
                            label={`${i18n.show} / ${i18n.toggle} ${i18n.Xgrid}`}
                            onChange={setShowXSplitLines}
                        />
                        <RadioButtons
                            elements={intervalChoices}
                            selected={intervalChoice}
                            onChange={setIntervalChoice}
                            colOption={6}
                        />
                        <NumberField value={intervalNb} onChange={setIntervalNb} title={i18n.intervalCount} disabled={intervalChoice !== CUSTOM} min={1}/>
                    </StyledFieldSet>
                </Grid>
                <Grid container item xs={12} justifyContent='center' sx={{ marginTop: '10px' }}>
                    <Button
                        tooltip={i18n.cancel}
                        onClick={onCancel}
                        icon='cancel'
                        className='red btn-floating btn-large margin-right-2 z-index-10'
                    />
                    <Button
                        tooltip={i18n.register}
                        onClick={onValidate}
                        icon='save'
                        className='btn-floating btn-large z-index-10'
                    />
                </Grid>
            </Grid>
        </WhiteCard>
    )
}

SimpleOptionsToolPanel.propTypes = {
    showTitle: PropTypes.bool,
    updateState: PropTypes.func,
}

export default SimpleOptionsToolPanel