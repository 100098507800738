import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_HABILITATION, RECEIVE_ACCOUNT_STATIONS, RECEIVE_ACCOUNT_STATION_STATISTICS, RECEIVE_ACCOUNT_STATISTICS,
    RECEIVE_ACCOUNT_USAGE_STATISTICS,
    RECEIVE_ACCOUNT_USER_KEYFIGURES,
    RECEIVE_ACCOUNT_USER_SETTINGS,
    SAVED_USER,
} from 'account/constants/AccountConstants'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { checkAuth, getJson } from 'utils/ActionUtils'
import { getValidStatsByModules } from 'utils/StatisticUtil'
import WaitAction from 'wait/WaitAction'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import ApplicationConf from '../../conf/ApplicationConf'
import LogAction from '../../log/actions/LogAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import { checkError, getAuthorization } from '../../utils/ActionUtils'

const AccountAction = {
    receiveUser(accountUser) {
        return {
            type: RECEIVE_ACCOUNT,
            accountUser,
        }
    },
    savedUser(user) {
        return {
            type: SAVED_USER,
            user,
        }
    },

    fetchUser(login, onLoginCallback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.get(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(AccountAction.receiveUser(json))
                    dispatch(ContactAction.fetchContacts())
                    dispatch(AccountAction.fetchAccountUserKeyfigure(json.login))
                    dispatch(AccountAction.fetchAccountStatistics(json.login))
                    dispatch(AccountAction.fetchAccountStationStatistics(json.login))
                    dispatch(AccountAction.fetchAccountUsageStatistics(json.login))
                    dispatch(AccountAction.fetchAccountStations(json.login))
                    Promise.all([
                        AccountAction.promiseAccountHabilitations(json.login),
                        AccountAction.promiseAccountUserSettings(),
                        AdministrationAction.promiseUserSettings(login),
                    ]).then(jsonTab => {
                        dispatch(AccountAction.receiveAccountHabilitation(jsonTab[0]))
                        dispatch(AccountAction.receiveAccountUserSettings(jsonTab[1]))
                        dispatch(AdministrationAction.receiveSettings(jsonTab[1]))
                        dispatch(AdministrationAction.receiveUserSettings(jsonTab[2]))
                        dispatch(WaitAction.waitStop())
                        onLoginCallback()
                    })
                })
        }
    },
    receiveAccountUserKeyfigures(accountUserKeyFigures) {
        return { type: RECEIVE_ACCOUNT_USER_KEYFIGURES, accountUserKeyFigures }
    },

    promiseAccountUserKeyfigure(login) {
        return fetch(ApplicationConf.user.getKeyFigures(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .catch(() => [])
    },

    fetchAccountUserKeyfigure(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountUserKeyfigure(login)
                .then(json => {
                    if (!json.error) {
                        dispatch(AccountAction.receiveAccountUserKeyfigures(json))
                    }
                })
        }
    },
    receiveAccountHabilitation(accountHabilitations) {
        return { type: RECEIVE_ACCOUNT_HABILITATION, accountHabilitations }
    },
    promiseAccountHabilitations(login) {
        return fetch(ApplicationConf.user.habilitations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchAccountHabilitations(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountHabilitations(login)
                .then(json => {
                    if (!json.error) {
                        dispatch(AccountAction.receiveAccountHabilitation(json))
                    }
                })
        }
    },
    receiveAccountStatistics(accountStatistics) {
        return { type: RECEIVE_ACCOUNT_STATISTICS, accountStatistics }
    },
    promiseAccountStatistics(login) {
        return fetch(ApplicationConf.user.getStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .catch(() => [])
    },

    fetchAccountStatistics(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountStatistics(login)
                .then(json => {
                    if (!json.error) {
                        dispatch(AccountAction.receiveAccountStatistics(getValidStatsByModules(json)))
                    }
                })
        }
    },
    receiveAccountStationStatistics(accountStationStatistics) {
        return { type: RECEIVE_ACCOUNT_STATION_STATISTICS, accountStationStatistics }
    },

    fetchAccountStationStatistics(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getStationStatistics(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(AccountAction.receiveAccountStationStatistics(json))
                })
        }
    },
    receiveAccountUsageStatistics(accountUsageStatistics) {
        return { type: RECEIVE_ACCOUNT_USAGE_STATISTICS, accountUsageStatistics }
    },

    fetchAccountUsageStatistics(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getUsageStatistics(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(AccountAction.receiveAccountUsageStatistics(json))
                })
        }
    },
    receiveAccountStations(accountStations) {
        return { type: RECEIVE_ACCOUNT_STATIONS, accountStations }
    },
    fetchAccountStations(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.userStations(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(AccountAction.receiveAccountStations(json))
                })
        }
    },
    updateContact(contact, isNew) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveContact(), {
                method: isNew ? 'POST' : 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contact),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1 || json.insert === 1) {
                        dispatch(ToastrAction.success(i18n.saveSuccess))
                        dispatch(ContactAction.fetchContacts())
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contact} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.contact))
                })
        }
    },
    updateSettings(login, settings, refreshUserSetting = false) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.saveSettings(login), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(settings),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.insert || json.update) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    }
                    if (!refreshUserSetting) {
                        dispatch(AccountAction.fetchAccountUserSettings())
                    } else {
                        dispatch(AdministrationAction.fetchUserSettings(login))
                    }
                })
                .then(() => {
                    dispatch(AdministrationAction.fetchSettings())
                })
        }
    },
    updateOrCreateSetting(login, field, value) {
        return dispatch => {
            return fetch(ApplicationConf.user.setting(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({ login, parameter: field, value }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert || json.update) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    }
                    dispatch(AccountAction.fetchAccountUserSettings())
                })
        }
    },
    receiveAccountUserSettings(accountUserSettings) {
        return { type: RECEIVE_ACCOUNT_USER_SETTINGS, accountUserSettings }
    },
    promiseAccountUserSettings() {
        return fetch(ApplicationConf.user.settings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchAccountUserSettings() {
        return (dispatch) => {
            return AccountAction.promiseAccountUserSettings()
                .then((json) => {
                    dispatch(AccountAction.receiveAccountUserSettings(json))
                })
        }
    },
}

export default AccountAction
