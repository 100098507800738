import React, { forwardRef } from 'react'
import { Button, Icon, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import { sieauTooltip } from 'utils/FormUtils'

export const ButtonMUI = styled(forwardRef((props, ref) => (
    <Button
        sx={{
            // eslint-disable-next-line react/prop-types
            '&:focus': { backgroundColor: (theme) => `${props.variant === 'outlined' ? 'white' : theme.palette.primary.main} !important` },
        }}
        {...props}
        ref={ref}
    />)))({
})

// Just set position: 'relative' on the parent component
export const FileIconMUI = styled((props) => (
    <IconButton
        aria-label='change picture'
        component='label'
        {...(props?.tooltip && sieauTooltip(props.tooltip))}
        sx={{
            position: 'absolute',
            top: '0px',
            right: '25%',
            backgroundColor: '#35609f',
            color: 'white',
            borderRadius: '50%',
            fontSize: '1.5rem',
            width: '3rem',
            height: '3rem',
            padding: '0.75rem',
            '&:hover': {
                backgroundColor: '#6dafff',
            },
            '&:focus': {
                backgroundColor: '#35609f',
            },
        }}
        {...props}
    >
        <input hidden accept='image/*' type='file' multiple />
        <Icon>{props?.icon || 'edit'}</Icon>
    </IconButton>))({
})
