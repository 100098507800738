import Icon from '../../components/icon/Icon'
import React from 'react'
import { setModal } from '../../utils/FormUtils'
import Row from '../../components/react/Row'
import Button from '../../components/forms/Button'
import Card from '../../components/card/Card'
import i18n from 'simple-react-i18n'

const setIntegrationModesPopup = (quality) => {
    setModal({
        title: 'Modes d\'intégration',
        content: (
            <div>
                <Card title={ 'Ne pas intégrer de données' }>
                    <Row className='padding-1'>N'intègre aucune données. C'est utilisé par exemple sur l'export Ades afin d'importer uniquement la description de la station, sans les mesures.</Row>
                </Card>
                <div className='padding-top-1'/>
                <Card title={i18n.lastDataIntegration}>
                    <Row className='padding-1'>N'intègre que les données dont la date/heure se situe après la dernière mesure de la chronique.</Row>
                </Card>
                <div className='padding-top-1'/>
                <Card title={i18n.replaceHistoIntegration}>
                    <Row className='padding-1'>Supprime tout l'historique et intègre tout le fichier. A utiliser avec parcimonie ! (dans le cas de tests par exemple)</Row>
                </Card>
                <div className='padding-top-1'/>
                <Card title={i18n.addOnlyNewsDataIntegration}>
                    <Row className='padding-1'>N'ajoute que les mesures dont la date/heure n'est pas déjà présente en base. (C'est le mode par défaut, qui est utilisé si aucun mode d'import n'est sélectionné)</Row>
                </Card>
                <div className='padding-top-1'/>
                { quality ? (
                    <Card title={i18n.replaceDataIntegration}>
                        <Row className='padding-1'>Remplace les opérations déjà existantes par celles qui sont dans le fichier. (Ne supprime pas celles qui ne sont pas dans le fichier)</Row>
                    </Card>
                ) : (
                    <Card title={i18n.replaceDataIntegration}>
                        <Row className='padding-1'>Supprime en base les données dont la date/heure se situe entre la date/heure de la première mesure du fichier et la date/heure de la dernière mesure du fichier. Cela peut servir pour repasser des fichiers qui ont été corrigés.</Row>
                    </Card>
                ) }

                <div className='padding-top-1'/>
                <Row className='padding-1'>
                    La méthode choisie sera appliquée une fois par type de donnée. Par exemple, si dans le fichier il y a à la fois de la profondeur et de la température, et que le mode "Remplacer les données" a été choisi, l'import va remplacer les données de profondeur en base en regardant les dates de la première mesure de profondeur du fichier et la dernière mesure de profondeur. Ensuite il va remplacer les données de température en base en regardant les dates de la première mesure de température du fichier et la dernière mesure de température du fichier, etc...
                </Row>
                <Row>
                    <Button className='right modal-close' title={ 'OK' }/>
                </Row>
            </div>
        ),
    })
}

const integrationModeHelpIcon = (quality) => (<Icon icon='help' style={ {
    paddingLeft: 5,
    fontSize: 14,
    position: 'relative',
    top: '-49px',
    left: '214px',
} } tooltip={'Description des options'} onClick={ () => setIntegrationModesPopup(quality) }
/>)

const schemeAgencyIDHelpIcon = () => (<Icon icon='help' style={ {
    paddingLeft: 5,
    fontSize: 14,
    position: 'relative',
    top: '-49px',
    left: '164px',
} } tooltip={i18n.schemeAgencyIDTooltyp}
/>)

export { integrationModeHelpIcon, schemeAgencyIDHelpIcon }