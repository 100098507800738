import AdministrationAction from 'administration/actions/AdministrationAction'
import { formatParameterSelectedPopup } from 'campaign/utils/CampaignUtils'
import Card from 'components/card/Card'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import PropTypes from 'prop-types'
import Select from 'components/forms/Select'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import { intersectionWith } from 'lodash'
import QualityAction from 'quality/actions/QualityAction'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getSetting } from 'utils/SettingUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import { Grid } from '@mui/material'
import useBoolean from 'utils/customHook/useBoolean'
import { OPTIONS } from 'resource/constants/ResourceConstants'

const parameterFilterFunction = (list, { selection = '-1', listParam, searchValue }) => {
    const filterSelection = selection !== '-1' ? intersectionWith(list, listParam, (elem, id) => elem.id === id) : list
    const searchValueFormat = searchAllCharacters(searchValue)
    return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
}

const ParameterFilterField = ({
    filter,
    setFilter,
}) => (
    <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
        <Grid item xs={6}>
            <Input
                title={i18n.search}
                value={filter.searchValue}
                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
            />
        </Grid>
        <Grid item xs={6}>
            <SelectionSelect
                value={filter.selection}
                onChange={(listParam, selection) => setFilter(prev => ({ ...prev, selection, listParam }))}
            />
        </Grid>
    </Grid>
)

ParameterFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        selection: PropTypes.string,
        listParam: PropTypes.arrayOf(PropTypes.string),
    }),
    setFilter: PropTypes.func,
}

const ResourcesOptionApp = () => {
    const {
        thresholds,
        parameters,
        applicationSettings,
        contributors,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
        parameters: store.ParameterReducer.parameters,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(QualityAction.fetchThresholds())
        dispatch(ParameterAction.fetchParameters())
    }, [])

    const parseSetting = constant => {
        const setting = getSetting(applicationSettings, constant)
        return setting ? parseFloat(setting) : undefined
    }

    const {
        value: isReadMode,
        setTrue: setReadMode,
        setFalse: setEditMode,
    } = useBoolean(true)
    const {
        value: isPesticidePopinOpen,
        setTrue: openPesticidesPopin,
        setFalse: closePesticidesPopin,
    } = useBoolean(false)
    const {
        value: isListSumPopinOpen,
        setTrue: openListSumPopin,
        setFalse: closeListSumPopin,
    } = useBoolean(false)
    const {
        value: isProducerPopinOpen,
        setTrue: openProducerPopinOpen,
        setFalse: closeProducerPopinOpen,
    } = useBoolean(false)

    const [startPeriod, setStartPeriod] = useState(() => parseSetting(OPTIONS.RESOURCE_START_PERIOD))
    const [endPeriod, setEndPeriod] = useState(() => parseSetting(OPTIONS.RESOURCE_END_PERIOD))
    const [nitratesThreshold, setNitratesThreshold] = useState(() => parseSetting(OPTIONS.RESOURCE_NITRATES_THRESHOLD))
    const [pesticideThreshold1, setPesticideThreshold1] = useState(() => parseSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_1))
    const [pesticideThreshold2, setPesticideThreshold2] = useState(() => parseSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_2))
    const [specificThreshold, setSpecificThreshold] = useState(() => parseSetting(OPTIONS.RESOURCE_SPECIFIC_THRESHOLD))
    const [pesticides, setPesticides] = useState(() => getSetting(applicationSettings, OPTIONS.RESOURCE_PESTICIDES)?.split(',').filter(c => !!c) || [])
    const [listSumPesticides, setListSumPesticides] = useState(() => getSetting(applicationSettings, OPTIONS.RESOURCE_LIST_SUM_PESTICIDES)?.split(',').filter(c => !!c) || [])
    const [producers, setProducers] = useState(() => getSetting(applicationSettings, OPTIONS.RESOURCE_PRODUCERS_EXCLUDED)?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])

    const setAllSettings = useCallback(() => {
        setStartPeriod(parseSetting(OPTIONS.RESOURCE_START_PERIOD))
        setEndPeriod(parseSetting(OPTIONS.RESOURCE_END_PERIOD))
        setNitratesThreshold(parseSetting(OPTIONS.RESOURCE_NITRATES_THRESHOLD))
        setPesticideThreshold1(parseSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_1))
        setPesticideThreshold2(parseSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_2))
        setSpecificThreshold(parseSetting(OPTIONS.RESOURCE_SPECIFIC_THRESHOLD))
        setPesticides(getSetting(applicationSettings, OPTIONS.RESOURCE_PESTICIDES)?.split(',').filter(c => !!c) || [])
        setListSumPesticides(getSetting(applicationSettings, OPTIONS.RESOURCE_LIST_SUM_PESTICIDES)?.split(',').filter(c => !!c) || [])
        setProducers(getSetting(applicationSettings, OPTIONS.RESOURCE_PRODUCERS_EXCLUDED)?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])
    }, [applicationSettings])

    useEffect(() => {
        setAllSettings()
    }, [setAllSettings])

    useTitle(() => {
        return [{
            title: i18n.resources,
            href: 'resource',
        }, {
            title: i18n.settings,
            href: 'resource/settings',
        }]
    }, [])

    useActions(() => {
        if (isReadMode) {
            return {
                edit: () => setEditMode(),
            }
        }
        return {
            cancel: () => {
                setAllSettings()
                setReadMode()
            },
            save: () => {
                const settings = [
                    { parameter: OPTIONS.RESOURCE_START_PERIOD, value: startPeriod && `${startPeriod}` },
                    { parameter: OPTIONS.RESOURCE_END_PERIOD, value: endPeriod && `${endPeriod}` },
                    { parameter: OPTIONS.RESOURCE_NITRATES_THRESHOLD, value: nitratesThreshold && `${nitratesThreshold}` },
                    { parameter: OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_1, value: pesticideThreshold1 && `${pesticideThreshold1}` },
                    { parameter: OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_2, value: pesticideThreshold2 && `${pesticideThreshold2}` },
                    { parameter: OPTIONS.RESOURCE_SPECIFIC_THRESHOLD, value: specificThreshold && `${specificThreshold}` },
                    { parameter: OPTIONS.RESOURCE_PESTICIDES, value: pesticides.join(',') },
                    { parameter: OPTIONS.RESOURCE_LIST_SUM_PESTICIDES, value: listSumPesticides.join(',') },
                    { parameter: OPTIONS.RESOURCE_PRODUCERS_EXCLUDED, value: producers.join(',') },
                ].filter(p => getSetting(applicationSettings, p.parameter) !== p.value)
                if (settings.length) {
                    dispatch(AdministrationAction.updateSieauParameters(settings)).then(() => setReadMode())
                } else {
                    setReadMode()
                }
            },
        }
    }, [isReadMode, setAllSettings, startPeriod, endPeriod, nitratesThreshold, pesticideThreshold1, pesticideThreshold2, specificThreshold, pesticides, listSumPesticides, producers])

    const pesticidesFormated = useMemo(() => pesticides.map(code => parameters.find(p => p.code === code)), [parameters, pesticides])
    const listSumFormated = useMemo(() => listSumPesticides.map(code => parameters.find(p => p.code === code)), [parameters, listSumPesticides])

    const producersFormated = useMemo(() => producers.map(id => contributors.find(c => c.id === id)), [contributors, producers])

    const activeParameters = useMemo(() => formatParameterSelectedPopup(parameters.filter(p => p.active)), [parameters])

    return (
        <div style={{ padding: '5px 5px 100px 0' }}>
            <Card cardContentStyle={{ margin: '10px' }}>
                <Grid container spacing={1} alignItems='flex-start'>
                    <Grid container item xs={6} rowSpacing={0.5} columnSpacing={1}>
                        <Grid item xs={6}>
                            <NumberField
                                title={i18n.referencePeriod}
                                value={startPeriod}
                                onChange={setStartPeriod}
                                disabled={isReadMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberField
                                value={endPeriod}
                                onChange={setEndPeriod}
                                disabled={isReadMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberField
                                title={`${i18n.nitratesThreshold} (mg/L)`}
                                floatValue
                                value={nitratesThreshold}
                                onChange={setNitratesThreshold}
                                disabled={isReadMode}
                            />
                        </Grid>
                        <Grid xs={6} />
                        <Grid item xs={6}>
                            <NumberField
                                title={`${i18n.pesticideThreshold} (mg/L)`}
                                floatValue
                                value={pesticideThreshold1}
                                onChange={setPesticideThreshold1}
                                disabled={isReadMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberField
                                floatValue
                                value={pesticideThreshold2}
                                onChange={setPesticideThreshold2}
                                disabled={isReadMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                label={i18n.specificThresholdToBeUsed}
                                onChange={setSpecificThreshold}
                                value={specificThreshold}
                                options={thresholds}
                                nullLabel='&nbsp;'
                                keyLabel='name'
                                keyValue='code'
                                disabled={isReadMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Table
                                title={i18n.producersExcluded}
                                data={producersFormated}
                                paging
                                actions={isReadMode ? [] : [{
                                    iconName: 'edit',
                                    tooltip: i18n.edit,
                                    onClick: openProducerPopinOpen,
                                }]}
                                nbPerPageLabel={nbPerPageLabel}
                                type={{ headers: ['name'] }}
                                condensed
                                sortable
                                id='producers_table'
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Table
                            title={i18n.pesticides}
                            data={pesticidesFormated}
                            paging
                            actions={isReadMode ? [] : [{
                                iconName: 'edit',
                                tooltip: i18n.edit,
                                onClick: openPesticidesPopin,
                            }]}
                            nbPerPageLabel={nbPerPageLabel}
                            type={{ headers: ['code', 'name'] }}
                            condensed
                            sortable
                            maxHeight={'25vh'}
                            id='pesticides_table'
                        />
                        <Table
                            title={i18n.listSumPesticides}
                            data={listSumFormated}
                            paging
                            actions={isReadMode ? [] : [{
                                iconName: 'edit',
                                tooltip: i18n.edit,
                                onClick: openListSumPopin,
                            }]}
                            nbPerPageLabel={nbPerPageLabel}
                            type={{ headers: ['code', 'name'] }}
                            condensed
                            sortable
                            maxHeight={'25vh'}
                            id='list_sum_pesticides_table'
                        />
                    </Grid>
                </Grid>
            </Card>
            <SelectionTableModal
                isOpen={isProducerPopinOpen}
                onClose={closeProducerPopinOpen}
                onValidate={list => {
                    closeProducerPopinOpen()
                    setProducers(list)
                }}
                title={i18n.producersExcludedSelection}

                listData={contributors}
                defaultSelectionList={producers}

                listHeaders={['name']}
                listTitle={i18n.nonSelectedProducers}
                selectionListTitle={i18n.selectedProducers}
                maxHeightTable={'53vh'}

                filterField={({
                    filter,
                    setFilter,
                }) => (
                    <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.search}
                                value={filter.searchValue}
                                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                            />
                        </Grid>
                    </Grid>
                )}
                filterFunction={(list, { searchValue }) => {
                    const searchValueFormat = searchAllCharacters(searchValue)
                    return searchValue ? list.filter(p => searchAllCharacters(p.name).includes(searchValueFormat)) : list
                }}
            />
            <SelectionTableModal
                isOpen={isPesticidePopinOpen}
                onClose={closePesticidesPopin}
                onValidate={list => {
                    closePesticidesPopin()
                    setPesticides(list)
                }}
                title={i18n.pesticidesSelection}

                listData={activeParameters}
                defaultSelectionList={pesticides}

                listHeaders={['name', 'type', 'nature', 'status']}
                listTitle={i18n.nonSelectedParameters}
                selectionListTitle={i18n.selectedParameters}
                maxHeightTable={'53vh'}

                filterField={ParameterFilterField}
                filterFunction={parameterFilterFunction}
            />
            <SelectionTableModal
                isOpen={isListSumPopinOpen}
                onClose={closeListSumPopin}
                onValidate={list => {
                    closeListSumPopin()
                    setListSumPesticides(list)
                }}
                title={i18n.listSumPesticidesSelection}

                listData={activeParameters}
                defaultSelectionList={listSumPesticides}

                listHeaders={['name', 'type', 'nature', 'status']}
                listTitle={i18n.nonSelectedParameters}
                selectionListTitle={i18n.selectedParameters}
                maxHeightTable={'53vh'}

                filterField={ParameterFilterField}
                filterFunction={parameterFilterFunction}
            />
        </div>
    )
}

export default ResourcesOptionApp