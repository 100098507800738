import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import Job from '../../../dto/DtoJob'
import SurfaceQualitometerStationDto from '../../../dto/SurfaceQualitometerStationDto'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import MultiContributorsAutocomplete from '../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import ImportQualityDefaultValue from '../ImportQualityDefaultValue'
import FractionAction from '../../../../referencial/components/fraction/actions/FractionAction'
import FractionDto from '../../../../referencial/components/fraction/dto/FractionDto'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import Supportaction from '../../../../referencial/components/support/actions/SupportAction'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import { getIntegrationModes, getImportMode } from '../../../../utils/JobUtils'
import { hasValue } from '../../../../utils/NumberUtil'


class SurfaceQualitometerPanel extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        if (!this.props.qualifications.length) {
            this.props.fetchQualifications()
        }
        if (!this.props.status.length) {
            this.props.fetchStatus()
        }
        if (!this.props.fractions.length) {
            this.props.fetchFractions()
        }
        if (!this.props.supports.length) {
            this.props.fetchSupports()
        }
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        if (!this.props.qualityCampaigns.length) {
            this.props.fetchQualityCampaigns()
        }
        this.onChangeFilters({})
    }

    onDeleteCode = ({ code }) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter(p => p != code)
            this.props.addCode(codes)
        }
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                AppStore.dispatch(ToastrAction.error(i18n.youMustEnterAtLeastOneBssCode))
            } else if (exist) {
                AppStore.dispatch(ToastrAction.error(i18n.youHaveAlreadyEnteredThisBssCode))
            } else {
                this.props.addCode([code, ...this.props.job.parameters.parameters])
                this.refs.txtAddCode.value = ''
            }
        }
    }

    onChangeFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = new Buffer(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '').split('\n').map(b => b.trim())
                    const codesToAdd = [...this.props.job.parameters.parameters, ...codes]
                    this.props.addCode(codesToAdd)
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    getStations = () => {
        return this.props.job.parameters.parameters.map(code => new SurfaceQualitometerStationDto({ code }))
    }

    getFilters = () => {
        return this.props.job.parameters?.filters.length && hasValue(this.props.job.parameters?.filters[0]) ? JSON.parse(this.props.job.parameters?.filters[0]) : {}
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type))
        }
        return ''
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = parameters.dataTypes.filter(d => !d.includes('station'))
        if (integration) {
            dataTypes.push('stationIntegration')
        }
        this.props.onChangeDataTypes(dataTypes)
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const filters = this.getFilters()
        return (
            <div>
                <br />
                <div className='row no-margin valign-wrapper'>
                    <div className='col s4'>
                        <Select
                            value={getImportMode(this.getDataTypes('DATA'))}
                            options={getIntegrationModes()}
                            label={ i18n.dataIntegration }
                            labelSpan={ integrationModeHelpIcon() }
                            onChange={this.handleChangeDataIntegration}
                            {...disabled}
                        />
                    </div>
                    <div className='col s4'>
                        <Checkbox
                            checked={ !!this.getDataTypes('station') }
                            label={ i18n.integrateStationInfo }
                            onChange={ this.handleChangeStationIntegration }
                            { ...disabled }
                        />
                    </div>
                    <div className='col s4' />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s4'>
                        <Select
                            value={filters.campaignId}
                            keyValue='id'
                            options={this.props.qualityCampaigns.filter(cs => cs.campaignType === 3)}
                            label={i18n.campaign}
                            onChange={v => this.onChangeFilters({ campaignId: v })}
                            {...disabled}
                        />
                    </div>
                    <div className='col s4'>
                        <Input
                            value={ filters.parameterCodes }
                            title={ i18n.qualityParamters }
                            onChange={values => {
                                this.onChangeFilters({ parameterCodes: values })
                            }}
                            { ...disabled }
                        />
                    </div>
                    <MultiContributorsAutocomplete
                        col={4}
                        multiple
                        options={this.props.contributors}
                        values={filters.excludedProducers}
                        label={ i18n.producersExcluded }
                        onChange={values => {
                            this.onChangeFilters({ excludedProducers: values })
                        }}
                        {...disabled}
                    />
                </div>
                <ImportQualityDefaultValue
                    defaultValue={filters}
                    onChange={obj => this.onChangeFilters(obj) }
                    isEditMode={this.props.isEditMode}
                />
                <br />
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.importFile }</label>
                    </div>
                    <div className='file-field col s9 no-padding input-field'>
                        <div className='col s3 no-padding btn'>
                            <span>{ i18n.importLabel }</span>
                            <input
                                type='file'
                                ref='importFile'
                                accept='.csv'
                                onChange={ this.onChangeFile }
                                disabled={ !this.props.isEditMode }
                            />
                        </div>
                        <div className='file-path-wrapper col s9'>
                            <input
                                className='file-path'
                                ref='fileInput'
                                type='text'
                                placeholder={ i18n.selectFile }
                                disabled={ !this.props.isEditMode }
                            />
                        </div>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.addCredential }</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddCode' type='text' placeholder={ i18n.surfaceQualitometerExample }
                                ref='txtAddCode'
                            />
                            <label
                                className='tinyLabel'
                            >{ i18n.code }</label>
                        </div>
                        <a
                            className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={ this.addCode }
                        >
                            { i18n.add }
                        </a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={ new SurfaceQualitometerStationDto() }
                            data={ this.getStations() }
                            title={ i18n.credentials }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }
}

SurfaceQualitometerPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    onChangeFilters: PropTypes.func,
    onChangeJob: PropTypes.func,
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    qualifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualification)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    fetchContributor: PropTypes.func,
    fetchSupports: PropTypes.func,
    fetchNetworks: PropTypes.func,
    fetchWatermasses: PropTypes.func,
    fetchQualifications: PropTypes.func,
    fetchStatus: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchFractions: PropTypes.func,
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    fetchQualityCampaigns: PropTypes.func,
}

const mapStateToProps = store => ({
    networks: store.NetworkReducer.networks,
    contributors: store.ContributorReducer.contributors,
    supports: store.SupportReducer.supports,
    qualifications: store.QualityReducer.qualifications,
    status: store.QualityReducer.status,
    fractions: store.FractionReducer.fractions,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchStatus: QualityAction.fetchStatus,
    fetchQualifications: QualityAction.fetchQualifications,
    fetchFractions: FractionAction.fetchFractions,
    fetchSupports: Supportaction.fetchSupports,
    fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurfaceQualitometerPanel)
