import ades from 'assets/pictures/logos/ades.jpg'
import atlasante from 'assets/pictures/logos/atlasante.png'
import basol from 'assets/pictures/logos/basol.png'
import bdlisa from 'assets/pictures/logos/bdlisa.png'
import eaufrance from 'assets/pictures/logos/eaufrance.jpg'
import georisques from 'assets/pictures/logos/georisques.png'
import googleMap from 'assets/pictures/logos/googleMap.png'
import infoterre from 'assets/pictures/logos/infoterre.jpg'
import mtes from 'assets/pictures/logos/mtes.png'
import naiades from 'assets/pictures/logos/naiades.png'
import sandre from 'assets/pictures/logos/sandre.png'
import favorit from 'assets/pictures/markers/favori.gif'
import unfavori from 'assets/pictures/markers/unfavori.gif'
import mariaRoe from 'assets/pictures/logos/maria_roe.png'
import shom from 'assets/pictures/logos/shom.png'
import { flatten, isNil, isNumber } from 'lodash'
import React from 'react'
import {
    CARTO,
    CATCHMENT,
    DISTRIBUTION,
    HYDRO,
    INSTALLATION,
    MAT, PERIMETERS, PGSSE, PIEZO,
    PLUVIO,
    PRODUCTION,
    QUALITO, RESOURCE, SITUATION,
} from '../administration/components/user/constants/ApplicationHabilitationConstants'
import { AGRI, AQUADB, OBSERVATORY, QAPTYS } from '../administration/components/user/constants/HabilitationConstants'
import {
    adesHydrogeologicalEntityLink,
    adesLink,
    adesWatermassLink,
    basolLink,
    cavityGeorisquesLink,
    eauFranceLink,
    flowObstructionSandreLink,
    googleMapsPath,
    infoterreLink,
    mtesLink,
    naiadesLink,
    roeInstallationLink,
    STEULink,
} from '../conf/SieauConstants'
import {
    DISTRIBUTION_LINK,
    HYDROMETRY_LINK,
    INSTALLATION_LINK,
    PATH_EVENT_LINK,
    PATH_STATION_LINK,
    PIEZOMETRY_LINK,
    PLUVIOMETRY_LINK,
    PRODUCTION_LINK,
    QUALITY_LINK,
    RESOURCE_LINK,
} from '../home/constants/RouteConstants'
import { INSTALLATION_TYPE } from '../installation/constants/InstallationConstants'
import LogAction from '../log/actions/LogAction'
import { STATION_NAME, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from '../station/constants/StationConstants'
import AppStore from '../store/AppStore'
import { getWGS84Coordinate } from './mapUtils/CoordinateUtils'
import { hasValue } from './NumberUtil'
import { getLabel } from './StoreUtils'


const typeStation = [
    {
        code: 0,
        libelle: 'Point eau souterraine',
    }, {
        code: 1,
        libelle: 'Cours d\'eau',
    }, {
        code: 2,
        libelle: 'Plan d\'eau',
    }, {
        code: 3,
        libelle: 'Grand cours d\'eau',
    }, {
        code: 4,
        libelle: 'Milieu marin',
    }, {
        code: 5,
        libelle: 'Autre cours d\'eau et ravines',
    }, {
        code: 6,
        libelle: 'Source',
    }, {
        code: 7,
        libelle: 'Eau distribuée',
    }, {
        code: 8,
        libelle: 'Eau traitée',
    }, {
        code: 9,
        libelle: 'Unité de distribution',
    }, {
        code: 10,
        libelle: 'Unité de production',
    }, {
        code: 11,
        libelle: 'Réservoir',
    },
]

const stationTypeUrl = [
    {
        code: 1,
        type: 'piezometry',
        url: PIEZOMETRY_LINK,
    }, {
        code: 2,
        type: 'pluviometry',
        url: PLUVIOMETRY_LINK,
    }, {
        code: 3,
        type: 'quality',
        url: QUALITY_LINK,
    }, {
        code: 4,
        type: 'hydrometry',
        url: HYDROMETRY_LINK,
    }, {
        code: 5,
        type: 'productionUnit',
        url: PRODUCTION_LINK,
    }, {
        code: 6,
        type: 'distributionUnit',
        url: DISTRIBUTION_LINK,
    }, {
        code: 7,
        type: 'installation',
        url: INSTALLATION_LINK,
    }, {
        code: 9,
        type: 'resource',
        url: RESOURCE_LINK,
    },
]

const getBookmarks = (code, list, detail = false, setBookmark = null) => {
    const classname = detail ? 'favorit-img' : 'small-favorit-img'
    if (list) {
        const findBookmark = list.find((o) => o === code)
        if (findBookmark) {
            if (detail) {
                return (
                    <img className={ `${classname} clickable` } id='favorit_img' src={ favorit } onClick={ () => setBookmark(true) }/>)
            }
            return <i className='tiny material-icons orange-text'>star</i>
        }
        if (detail) {
            return (<img className={ `${classname} clickable` } id='unfavorit_img' src={ unfavori } onClick={ () => setBookmark() }/>)
        }
    }
    return ''
}

const getStationType = (idType) => {
    return typeStation.find((o) => {
        return o.code === idType
    }) || {}
}

const findStationType = (stationType) => {
    return stationType ? stationTypeUrl.find(s => s.code === stationType || s.type === stationType) : null
}

const findStationTypeUrl = (stationType, eventCode, number) => {
    const urlStation = stationTypeUrl.find(o => o.code === stationType)
    return `${PATH_STATION_LINK}${urlStation.url}${eventCode}${PATH_EVENT_LINK}${number}`
}

const execByType = (type, treatments) => {
    if (type === 'quality' || type === 'QUALITO' || type == 3 || type === STATION_NAME.QUALITOMETER) {
        return treatments.quality ? treatments.quality() : (treatments.stations ? treatments.stations() : treatments.default())
    }
    if (type === 'piezometry' || type === 'PIEZO' || type == 1 || type === STATION_NAME.PIEZOMETER) {
        return treatments.piezometry ? treatments.piezometry() : (treatments.stations ? treatments.stations() : treatments.default())
    }
    if (type === 'hydrometry' || type === 'HYDRO' || type == 4) {
        if (treatments.hydrometry) {
            return treatments.hydrometry()
        } else if (treatments.stations) {
            return treatments.stations()
        } else if (treatments.default) {
            return treatments.default()
        }
        return null
    }
    if (type === 'pluviometry' || type === 'PLUVIO' || type == 2) {
        return treatments.pluviometry ? treatments.pluviometry() : (treatments.stations ? treatments.stations() : treatments.default())
    }
    if (type === 'productionUnit' || type === 'PROD' || type == 5) {
        return treatments.productionUnit ? treatments.productionUnit() : (treatments.units ? treatments.units() : treatments.default())
    }
    if (type === 'distributionUnit' || type === 'DISTR' || type == 6) {
        return treatments.distributionUnit ? treatments.distributionUnit() : (treatments.units ? treatments.units() : treatments.default())
    }
    if (type === 'installation' || type === 'INST' || type == 7) {
        return treatments.installation ? treatments.installation() : (treatments.units ? treatments.units() : treatments.default())
    }
    if (type === 'resource' || type == 9) {
        return treatments.resource ? treatments.resource() : (treatments.units ? treatments.units() : treatments.default())
    }
    if (type === 'contact') {
        return treatments.contact ? treatments.contact() : treatments.default()
    }
    if (type === 'contributor') {
        return treatments.contributor ? treatments.contributor() : treatments.default()
    }
    if (type === 'agriculture' || type === 10) {
        return treatments.agriculture ? treatments.agriculture() : treatments.default()
    }
    if (type === 'catchment' || type === 11) {
        return treatments.catchment ? treatments.catchment() : treatments.default()
    }
    return treatments.default ? treatments.default() : () => {}
}

const execByTypes = (types, treatments) => {
    return types.map(type => execByType(type, treatments))
}

const getStation = (props, type) => {
    switch (type) {
        case 'quality':
            return props.qualitometer
        case 'piezometry':
            return props.piezometer
        case 'pluviometry':
            return props.pluviometer
        case 'hydrometry':
            return props.hydrometricStation
        case 'productionUnit':
            return props.productionUnit
        case 'distributionUnit':
            return props.distributionUnit
        case 'installation':
            return props.installation
        case 'resource':
            return props.resource
        case 'agriculture':
            return props.exploitation
        default:
            return null
    }
}

const getStationTypeFromName = (stationName) => {
    switch (stationName) {
        case STATION_TYPE_NAME.qualitometer:
            return STATION_TYPE_NAME.quality
        case STATION_TYPE_NAME.piezometer:
            return STATION_TYPE_NAME.piezometry
        case STATION_TYPE_NAME.pluviometer:
            return STATION_TYPE_NAME.pluviometry
        case STATION_TYPE_NAME.hydrometricStation:
            return STATION_TYPE_NAME.hydrometry
        case 'productionUnit':
            return 'productionUnit'
        case 'distributionUnit':
            return 'distributionUnit'
        case 'installation':
            return 'installation'
        default:
            return null
    }
}
const getStationTypeNameFromType = (stationName) => {
    switch (stationName) {
        case STATION_TYPE_NAME.quality:
            return STATION_TYPE_NAME.qualitometer
        case STATION_TYPE_NAME.piezometry:
            return STATION_TYPE_NAME.piezometer
        case STATION_TYPE_NAME.hydrometry:
            return STATION_TYPE_NAME.hydrometricStation
        case STATION_TYPE_NAME.pluviometry:
            return STATION_TYPE_NAME.pluviometer
        case STATION_TYPE_NAME.cce:
            return STATION_TYPE_NAME.cce
        default:
            return null
    }
}
const getStationTypeFromUserConstants = (userStationType) => {
    switch (userStationType) {
        case QUALITO:
            return STATION_TYPE_NAME.quality
        case PIEZO:
            return STATION_TYPE_NAME.piezometry
        case HYDRO:
            return STATION_TYPE_NAME.hydrometry
        case PLUVIO:
            return STATION_TYPE_NAME.pluviometry
        case PRODUCTION:
            return STATION_TYPE_NAME.productionUnit
        case DISTRIBUTION:
            return STATION_TYPE_NAME.distributionUnit
        case INSTALLATION:
            return STATION_TYPE_NAME.installation
        case PERIMETERS:
            return STATION_TYPE_NAME.perimeters
        case CATCHMENT:
            return STATION_TYPE_NAME.catchment
        case MAT:
            return STATION_TYPE_NAME.materiel
        case CARTO:
            return STATION_TYPE_NAME.carto
        case SITUATION:
            return STATION_TYPE_NAME.situation
        case AGRI:
            return STATION_TYPE_NAME.agri
        case QAPTYS:
            return STATION_TYPE_NAME.qaptys
        case OBSERVATORY:
            return STATION_TYPE_NAME.observatory
        case AQUADB:
            return STATION_TYPE_NAME.aquadb
        case PGSSE:
            return STATION_TYPE_NAME.pgsse
        case RESOURCE:
            return STATION_TYPE_NAME.resource
        default:
            return null
    }
}
const getStationTypeNameFromTypeCode = (stationTypeCode) => {
    switch (stationTypeCode) {
        case 1:
            return STATION_TYPE_NAME.piezometer
        case 2:
            return STATION_TYPE_NAME.pluviometer
        case 3:
            return STATION_TYPE_NAME.qualitometer
        case 4:
            return STATION_TYPE_NAME.hydrometricStation
        case 5:
            return STATION_TYPE_NAME.productionUnit
        case 6:
            return STATION_TYPE_NAME.distributionUnit
        case 7:
            return STATION_TYPE_NAME.installation
        default:
            return null
    }
}

const getNewStationTypeNameFromTypeCode = (stationTypeCode) => {
    switch (stationTypeCode) {
        case 1:
            return STATION_TYPE_NAME.piezometry
        case 2:
            return STATION_TYPE_NAME.pluviometry
        case 3:
            return STATION_TYPE_NAME.quality
        case 4:
            return STATION_TYPE_NAME.hydrometry
        case 5:
            return STATION_TYPE_NAME.productionUnit
        case 6:
            return STATION_TYPE_NAME.distributionUnit
        case 7:
            return STATION_TYPE_NAME.installation
        case 9:
            return STATION_TYPE_NAME.resource
        default:
            return null
    }
}

const getStationTypeCodeFromTypeName = (stationTypeName) => {
    switch (stationTypeName) {
        case STATION_TYPE_NAME.piezometer:
            return 1
        case STATION_TYPE_NAME.pluviometer:
            return 2
        case STATION_TYPE_NAME.qualitometer:
            return 3
        case STATION_TYPE_NAME.hydrometry:
            return 4
        case STATION_TYPE_NAME.productionUnit:
            return 5
        case STATION_TYPE_NAME.distributionUnit:
            return 6
        case STATION_TYPE_NAME.installation:
            return 7
        case STATION_TYPE_NAME.resource:
            return 9
        default:
            return null
    }
}
const getStationTypeCodeFromType = (type) => {
    switch (type) {
        case STATION_TYPE_NAME.piezometry:
            return 1
        case STATION_TYPE_NAME.pluviometry:
            return 2
        case STATION_TYPE_NAME.quality:
            return 3
        case STATION_TYPE_NAME.hydrometry:
            return 4
        case STATION_TYPE_NAME.productionUnit:
            return 5
        case STATION_TYPE_NAME.distributionUnit:
            return 6
        case STATION_TYPE_NAME.installation:
            return 7
        case STATION_TYPE_NAME.resource:
            return 9
        default:
            return null
    }
}

const hasLocalisationStation = (station) => station && !isNil(station.x) && !isNil(station.y) && (station.projection || station.projectionType)

const hasLocalisation = (station) => {
    return station && station.localisation &&
        station.localisation.x && station.localisation.x !== 0 &&
        station.localisation.y && station.localisation.y !== 0 &&
        station.localisation.projection && station.localisation.projection !== 0
}

const analysisHasLocalisation = analysis => {
    return analysis && analysis.x && analysis.x !== 0 &&
        analysis.y && analysis.y !== 0 &&
        analysis.projection && analysis.projection !== 0
}

const getStations = (props, type) =>{
    return execByType(type, {
        quality: () => props.qualitometers || props.qualitometersListSpecific,
        piezometry: () => props.piezometers || props.piezometersLight || props.piezometerListSpecific,
        hydrometry: () => props.hydrometricStations,
        pluviometry: () => props.pluviometers,
        productionUnit: () => props.productionUnits,
        distributionUnit: () => props.distributionUnits,
        installation: () => props.installations,
        default: () => [],
    })
}

const getTypeModule = type => execByType(type, {
    quality: () => 'SIQ',
    piezometry: () => 'SIES',
    pluviometry: () => 'SIP',
    hydrometry: () => 'SIH',
    productionUnit: () => 'SIUP',
    distributionUnit: () => 'SIUDI',
    installation: () => 'SIB',
    resource: () => 'SIR',
    default: () => 'SIQ',
})

const getStationFilterByModule = (filters, type) => execByType(type, {
    quality: () => filters.filter(f => f.module === 'SIQ'),
    piezometry: () => filters.filter(f => f.module === 'SIES'),
    hydrometry: () => filters.filter(f => f.module === 'SIH'),
    pluviometry: () => filters.filter(f => f.module === 'SIP'),
    productionUnit: () => filters.filter(f => f.module === 'SIUP'),
    distributionUnit: () => filters.filter(f => f.module === 'SIUDI'),
    installation: () => filters.filter(f => f.module === 'INST'),
    resource: () => filters.filter(f => f.module === 'SIR'),
    catchment: () => filters.filter(f => f.module === 'SIC'),
    default: () => filters.filter(f => f.module === 'SIQ'),
})

const getAssociatedStations = props => {
    if (props.filteredStations && props.filteredStations.length) {
        return props.associatedSites.map(link => {
            const station = getStations(props, link.stationLinkedType).find(s => s.code === link.stationLinkedCode)
            if (!station) {
                AppStore.dispatch(LogAction.logError(`la station de type ${link.stationLinkedType} ayant le code ${link.stationLinkedCode} semble ne plus exister en base`))
            }
            return station || null
        }).filter(s => !!s && props.filteredStations.includes(s.typeName))
    }
    return props.associatedSites.map(link => {
        const stationLinkedType = isNumber(link.stationLinkedType) ? getNewStationTypeNameFromTypeCode(link.stationLinkedType) : link.stationLinkedType
        const stations = getStations(props, stationLinkedType)
        const station = stations?.find(s => s.code === link.stationLinkedCode)
        if (!station) {
            AppStore.dispatch(LogAction.logError(`la station de type ${stationLinkedType} ayant le code ${link.stationLinkedCode} semble ne plus exister en base`))
        }
        return station || null
    }).filter(s => !!s)
}

const getGoogleMapLink = (localisation) => {
    const center = getWGS84Coordinate(localisation)
    return {
        img: googleMap,
        href: `${googleMapsPath + center[1]},${center[0]}`,
        label: 'Google Maps',
    }
}

const getLinks = async (station, props = {}) => {
    if (station && station.code) {
        const googleLink = station.localisation && station.localisation.x && station.localisation.y && station.localisation.projection ?
            getGoogleMapLink(station.localisation) : []

        const otherLinks = station.stationType !== 7 && station.stationType !== 8 ? [] : [{
            img: eaufrance,
            href: `${eauFranceLink + station.code}:::referentiel:3.1:html`,
            label: 'eaufrance',
        }]

        if ((station.stationType === '0' || station.typeName === 'piezometry' || station.typeName === 'quality') && station.code && ((station.code.length === 10 && (station.code[5].toUpperCase() === 'X' || station.code[5].toUpperCase() === 'C')) || station.code.toUpperCase().includes('BSS'))) {
            otherLinks.push({
                img: ades,
                href: station.code.startsWith('BSS') ? `${adesLink}${station.code}` : `${adesLink}${station.code}${station.designation ? `/${station.designation}` : ''}`,
                label: 'ADES',
            }, {
                img: infoterre,
                href: `${infoterreLink}${station.code.split('/')[0]}${station.designation && !station.code.toUpperCase().includes('BSS') ? `/${station.designation}` : ''}`,
                label: 'infoterre',
            })
        }
        if (station.stationType === 4) {
            otherLinks.push({
                img: shom,
                href: `https://data.shom.fr/donnees/refmar/${station.code}`,
                label: 'shom',
            },
            {
                img: shom,
                href: ` https://data.shom.fr/oceanogramme/spot/${station.code}`,
                label: 'oceanogramme',
            })
        }

        if (station.hydrogeologicalEntities && station.hydrogeologicalEntities.length && props.hydrogeologicalEntities.length) {
            otherLinks.push({
                img: sandre,
                href: `${adesHydrogeologicalEntityLink + getLabel(props.hydrogeologicalEntities, station.hydrogeologicalEntities[0], 'sandreCode')}.pdf`,
                label: 'sandre',
            })
        }

        if (station.stationType === '1' || station.stationType === '2' || station.stationType === '3' || station.stationType === '4' || station.stationType === '5' || station.stationType === '6') {
            otherLinks.push({
                img: sandre,
                href: `https://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:STQ:FRA:code:${station.code}:::referentiel:3.1:html`,
                label: 'sandre',
            }, {
                img: naiades,
                href: `${naiadesLink}${station.code}`,
                label: 'naiades',
            })
        }

        if (props.stationTypes && props.stationTypes.length) {
            if (props.stationTypes[0] === 'distributionUnit' && props.distributionUnitLink?.length > 0) {
                otherLinks.push({
                    img: atlasante,
                    href: props.distributionUnitLink,
                    label: 'Atlasanté',
                })
            }

            if (props.stationTypes[0] === 'installation') {
                if (props.installation.installationType === INSTALLATION_TYPE.STEP) {
                    otherLinks.push({
                        img: mtes,
                        href: mtesLink + station.code,
                        label: 'MTES France',
                    })
                    otherLinks.push({
                        img: mtes,
                        href: STEULink + station.code,
                        label: 'Fiche STEU',
                    })
                }
                if (props.installation.installationType === INSTALLATION_TYPE.POLLUTED_SOIL) {
                    otherLinks.push({
                        img: basol,
                        href: basolLink + station.code,
                        label: 'BASOL',
                    })
                }
                if (props.installation.installationType === INSTALLATION_TYPE.CAVITY) {
                    otherLinks.push({
                        img: georisques,
                        href: cavityGeorisquesLink + station.code,
                        label: 'Georisques',
                    })
                }
                if (props.installation.installationType === INSTALLATION_TYPE.FLOW_OBSTRUCTION) {
                    otherLinks.push({
                        img: sandre,
                        href: flowObstructionSandreLink + station.code,
                        label: 'sandre',
                    })
                }
                if (props.installation.installationType === INSTALLATION_TYPE.BOREHOLE && hasValue(station.code)) {
                    otherLinks.push({
                        img: infoterre,
                        href: `${infoterreLink}${station.code.split('/')[0]}${station.designation && !station.code.toUpperCase().includes('BSS') ? `/${station.designation}` : ''}`,
                        label: 'infoterre',
                    })
                }
            }
        }
        if (props.stationWatermass && props.stationWatermass.europeanCode) {
            const watermassCode = props.stationWatermass.europeanCode.includes('FR') ? props.stationWatermass.europeanCode : `FR${props.stationWatermass.europeanCode}`
            return flatten([googleLink, otherLinks, [{
                img: bdlisa,
                href: `${adesWatermassLink + watermassCode}.pdf`,
                label: 'bdlisa',
            }]])
        }

        if ((station.stationType === `${STATION_TYPE_CONSTANT.INSTALLATION}` || station.typeName === STATION_TYPE_NAME.installation) && station.code.startsWith('ROE')) {
            otherLinks.push({
                img: mariaRoe,
                href: `${roeInstallationLink + station.code}`,
                label: 'Fiche roe',
            })
        }

        return flatten([googleLink, otherLinks])
    }
    return []
}

const isUndergroundWater = (station) => {
    return parseInt(station.stationType) === 0
}

const getStationTitle = (station = {}) => {
    const code = (() => {
        if (station.code && station.code !== '') {
            if (station.code.toUpperCase().startsWith('BSS')) {
                return station.code
            }
            return station.code + (station.designation && station.designation.trim() !== '' ? `/${station.designation}` : '')
        }
        return `[${station.id}]`
    })()
    const name = station.name && station.name.trim() !== '' ? station.name : null
    return name ? `${code} - ${name}` : code
}

const getStationsHash = stations => stations.map(s => s.code).join('')

const filterLinkedStations = (linkedStations) => linkedStations.filter(s => ![null, STATION_TYPE_NAME.resource, STATION_TYPE_NAME.installation, STATION_TYPE_NAME.distributionUnit].includes(s.typeName))

export {
    findStationType,
    findStationTypeUrl,
    getStationType,
    getBookmarks,
    execByType,
    execByTypes,
    getStation,
    getStations,
    getStationTypeFromName,
    hasLocalisation,
    analysisHasLocalisation,
    hasLocalisationStation,
    getAssociatedStations,
    getStationTypeNameFromTypeCode,
    getNewStationTypeNameFromTypeCode,
    getStationTypeNameFromType,
    getStationTypeCodeFromTypeName,
    getStationTypeCodeFromType,
    getStationTypeFromUserConstants,
    getTypeModule,
    getStationFilterByModule,
    getLinks,
    isUndergroundWater,
    getStationTitle,
    getStationsHash,
    filterLinkedStations,
}
