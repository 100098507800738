import JobAction from 'import/actions/JobAction'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import FileUploadInput from '../../../dto/FileUploadInput'

class ImportFileModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: 'nom',
            url: props.url,
            content: '',
        }
    }

    onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        this.onChangeFile({ name: file.name })
        reader.onload = upload => {
            this.onChangeFile({ content: upload.target.result })
        }
        reader.readAsDataURL(file)
    }

    onChangeFile = (changes) => {
        this.setState({ ...this.state, ...changes })
    }

    uploadFile= () =>{
        const parameters = new FileUploadInput({
            stationType: 0,
            stationId: 0,
            path: this.state.url || '',
            name: this.state.name,
            content: this.state.content,
        })
        this.props.uploadFile(parameters).then(res => res && this.props.onChange(res.name))
    }

    render() {
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col  s3'>
                        <label>{i18n.importFile}</label>
                    </div>
                    <div className='file-field col s9 no-padding'>
                        <div className='input-field col'>
                            <a className='waves-effect waves-light btn col s12'>
                                {i18n.chooseFile}
                                <input type='file'
                                    onChange={ this.onImportFile }
                                />
                            </a>
                        </div>
                        <div className='input-field col s9 file-path-wrapper'>
                            <input
                                className='file-path validate'
                                type='textes'
                                disabled
                                placeholder={i18n.selectFile}
                            />
                        </div>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.directory}</label>
                    </div>
                    <Input
                        col={ 9 }
                        value= { this.state.url }
                        onChange={ v => this.setState({ url: v }) }
                    />
                </div>
                <div>
                    <a className='right btn btn-small margin-bottom-3 modal-close' onClick={this.uploadFile} >{ i18n.importLabelFtp }</a>
                    <button className='right waves-effect  margin-bottom-3 waves-teal btn-flat secondary-button margin-right-1 modal-close'>{i18n.cancel}</button>
                </div>
            </div>


        )
    }
}

ImportFileModal.propTypes = {
    onChange: PropTypes.func,
    dataTypes: PropTypes.arrayOf(PropTypes.object),
    toastrError: PropTypes.func,
    url: PropTypes.string,
    uploadFile: PropTypes.func,
}

ImportFileModal.defaultProps = { onChange: () => {} }

const mapDispatchToProps = {
    uploadFile: JobAction.uploadFile,
}

export default connect(null, mapDispatchToProps)(ImportFileModal)
