import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Textarea from '../../../components/forms/Textarea'

class ContactMailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: this.props.email,
            contentEmail: '',
        }
    }

    onChange = (setting, value) => {
        this.setState({ ...this.state, [setting]: value })
        this.props.onChangeContentModal(setting, value)
    }

    render() {
        return (
            <div>
                <div className='row no-margin'>
                    <Input col={ 12 } title={ i18n.email } value={ this.state.email }
                        onChange={ v => this.onChange('email', v) }
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Textarea col={ 12 } title={ i18n.content } value={ this.state.contentEmail }
                        onChange={ v => this.onChange('contentEmail', v) }
                    />
                </div>
            </div>
        )
    }
}

ContactMailModal.propTypes = {
    email: PropTypes.string,
    onChangeContentModal: PropTypes.func,
}

export default ContactMailModal
