import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { StyledFieldSet } from '../../../../components/StyledElements'
import { Grid, Button, DialogActions } from '@mui/material'
import PropTypes from 'prop-types'
import Input from '../../../../components/forms/Input'
import PerimeterDto from '../../../dto/PerimeterDto'
import Select from '../../../../components/forms/Select'
import {
    tempPerimetersStatus, tempPerimeterStation,
    tempPerimeterTypes,
} from '../../../temp/TempPerimetersData'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import Textarea from '../../../../components/forms/Textarea'
import StepperDialog from '../../../../components/modal/StepperDialog'
import { ButtonMUI } from '../../../../components/styled/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import StationMapDashboardPanel from '../../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import Icon from '../../../../components/icon/Icon'

const STEP = {
    DESCRIPTION: 0,
    PARCELS: 1,
    EVENTS: 2,
    DOCUMENTS: 3,
    MAP: 4,
}

const PARCELS_HEADER = ['code', 'owners', 'hypothecaryState', 'status']
const OWNERS_HEADER = ['type', 'name', 'firstname', 'birthdate', 'placeOfBirth', 'address']
const OPERATORS_HEADER = ['name', 'firstname', 'birthdate', 'placeOfBirth', 'address', 'sauHa']
const EVENTS_HEADER = []
const DOCUMENTS_HEADER = []

const ParcelsTable = ({
    parcels = [],
    parcel,
    setParcel,
}) => {
    const [addParcelPopupOpen, setAddParcelPopupOpen] = useState(false)

    const parcelsFormated = parcels.map(p => {
        return {
            ...p,
            code: (
                <Grid container item xs={'auto'} alignItems={'center'}>
                    <Grid item paddingRight={'5px'}>
                        <Grid item padding={'8px'} backgroundColor={p.owners/* .length*/ ? 'green' : 'orange'} borderRadius={'100%'} border={'2px solid black'}/>
                    </Grid>
                    <Grid item>
                        {p.code}
                    </Grid>
                </Grid>
            ),
        }
    })

    return (
        <>
            <DialogMUI
                fullWidth
                maxWidth='lg'
                open={ addParcelPopupOpen }
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                        <Grid item>
                            {'Ajouter une parcelle'}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setAddParcelPopupOpen(false)} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    <StyledFieldSet style={{ padding: '30px 50px' }}>
                        <Grid container>
                            <Grid container item paddingTop={'10px'} spacing={2}>
                                <Grid container item xs={4}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.code}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={Object.values(tempPerimetersStatus)?.map((c, index) => {
                                                return { code: index, name: c }
                                            })}
                                            label={i18n.hypothecaryState}
                                            nullLabel='&nbsp;'
                                            value={''}
                                            noSort
                                            // onChange={setStatus}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={Object.values(tempPerimetersStatus)?.map((c, index) => {
                                                return { code: index, name: c }
                                            })}
                                            label={i18n.status}
                                            nullLabel='&nbsp;'
                                            value={''}
                                            noSort
                                            // onChange={setStatus}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActions>
                    <Button onClick={() => setAddParcelPopupOpen(false)} variant='outlined'>
                        { i18n.cancel }
                    </Button>
                    <Button onClick={() => {}} variant='contained' color='primary'>
                        { i18n.save }
                    </Button>
                </DialogActions>
            </DialogMUI>
            <Grid item border={'2px solid #00359F'} borderRadius={'5px'}>
                <Table
                    title={i18n.parcels}
                    data={parcelsFormated}
                    actions={[{
                        iconName: 'note_add',
                        tooltip: i18n.add,
                        color: 'black',
                        onClick: () => setAddParcelPopupOpen(true),
                    }]}
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    type={{ headers: PARCELS_HEADER }}
                    onClick={p => setParcel(p)}
                    condensed
                    sortable
                    id='perimetersFolder_table'
                    invertedHeaderStyle
                />
            </Grid>
        </>
    )
}

ParcelsTable.propTypes = {
    parcels: PropTypes.arrayOf(PropTypes.object),
    parcel: PropTypes.object,
    setParcel: PropTypes.func,
}

const PerimetersStepper = ({
    perimeter,
    setPerimeter = () => { },
    previousPerimeter = () => { },
    nextPerimeter = () => { },
}) => {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [status, setStatus] = useState()
    const [type, setType] = useState()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const [parcel, setParcel] = useState()

    const [addOwnerPopupOpen, setAddOwnerPopupOpen] = useState(false)
    const [addOperatorPopupOpen, setAddOperatorPopupOpen] = useState(false)

    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }))

    const parcels = [{
        code: 'AC-223',
        owners: 10,
        hypothecaryState: '',
        status: '',
    }, {
        code: 'Z-42',
        owners: 0,
        hypothecaryState: '',
        status: '',
    }, {
        code: 'AC-224',
        owners: 2,
        hypothecaryState: 'En cours',
        status: '',
    }]

    const owners = [{
        type: 'Usufruit',
        name: 'Dupont',
        firstname: 'Allain',
        birthdate: '05/04/19080',
        placeOfBirth: 'AMSTERDAM - PAYS BAS',
        address: '2 Rue de Nantes Port Saint Père',
    }]

    const operators = [{
        name: 'Dupont',
        firstname: 'Allain',
        birthdate: '05/04/19080',
        placeOfBirth: 'AMSTERDAM - PAYS BAS',
        address: '2 Rue de Nantes Port Saint Père',
        sauHa: '10',
    }]

    useEffect(() => {
        setParcel()

        setName(perimeter?.name ?? '')
        setStatus(perimeter?.status)
        setType(perimeter?.type)
        setStartDate(perimeter?.startDate ?? '')
        setEndDate(perimeter?.endDate ?? '')
    }, [perimeter])

    useEffect(() => {
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
    }, [cities, dispatch])

    const dataIsDifferent =
        name !== perimeter?.name ||
        status !== perimeter?.status ||
        type !== perimeter?.type ||
        startDate !== perimeter?.startDate ||
        endDate !== perimeter?.endDate

    return (
        <>
            <DialogMUI
                fullWidth
                maxWidth='lg'
                open={ addOwnerPopupOpen }
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                        <Grid item>
                            {'Ajouter un propriétaire'}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setAddOwnerPopupOpen(false)} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    <StyledFieldSet style={{ padding: '30px 50px' }}>
                        <Grid container>
                            <Grid container item paddingTop={'10px'} spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.name}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.firstname}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.type}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.birthdate}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={4}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.placeOfBirth}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={8}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.address}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActions>
                    <Button onClick={() => setAddOwnerPopupOpen(false)} variant='outlined'>
                        { i18n.cancel }
                    </Button>
                    <Button onClick={() => {}} variant='contained' color='primary'>
                        { i18n.save }
                    </Button>
                </DialogActions>
            </DialogMUI>
            <DialogMUI
                fullWidth
                maxWidth='lg'
                open={ addOperatorPopupOpen }
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                        <Grid item>
                            {'Ajouter un exploitant'}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setAddOperatorPopupOpen(false)} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    <StyledFieldSet style={{ padding: '30px 50px' }}>
                        <Grid container>
                            <Grid container item paddingTop={'10px'} spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.name}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.firstname}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.sauHa}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.birthdate}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={4}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.placeOfBirth}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={8}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.address}
                                            value={''}
                                            // onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActions>
                    <Button onClick={() => setAddOperatorPopupOpen(false)} variant='outlined'>
                        { i18n.cancel }
                    </Button>
                    <Button onClick={() => {}} variant='contained' color='primary'>
                        { i18n.save }
                    </Button>
                </DialogActions>
            </DialogMUI>
            <StepperDialog
                steps={[
                    {
                        label: 'Descriptif',
                        constant: STEP.DESCRIPTION,
                    },
                    {
                        label: (
                            <Grid container justifyContent={'center'}>
                                <Grid item backgroundColor={/* !owners.length &&*/ 'orange'} padding={'0 5px'}>
                                    {'Parcelles'}
                                </Grid>
                            </Grid>
                        ),
                        constant: STEP.PARCELS,
                    },
                    {
                        label: 'Evénements',
                        constant: STEP.EVENTS,
                    },
                    {
                        label: 'Documents',
                        constant: STEP.DOCUMENTS,
                    },
                    {
                        label: 'Carte',
                        constant: STEP.MAP,
                        // nextAvailable: qualitySelection.selections?.length > 0
                    },
                ].filter(s => s)}
                open={!!perimeter}
                title={'Périmètre immédiat'}
                closeDialog={setPerimeter}
                renderSaveButton={() => (
                    <ButtonMUI
                        onClick={() => {}}
                        variant='contained'
                        disabled={!dataIsDifferent}
                    >
                        {i18n.register}
                    </ButtonMUI>
                )}
            >
                {step => (
                    <>
                        {step === STEP.DESCRIPTION && (
                            <StyledFieldSet style={{ padding: '30px 50px' }}>
                                <Grid container item justifyContent={'center'} spacing={2}>
                                    <Grid container item>
                                        <Grid item width={'100%'}>
                                            <Input
                                                title={i18n.name}
                                                value={name}
                                                onChange={(v) => setName(v)}
                                                clearFunction
                                                id='perimeter_name'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Select
                                                options={Object.values(tempPerimeterTypes)?.map((c, index) => {
                                                    return { code: index, name: c }
                                                })}
                                                label={i18n.type}
                                                nullLabel='&nbsp;'
                                                value={type}
                                                noSort
                                                onChange={setType}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Select
                                                options={Object.values(tempPerimetersStatus)?.map((c, index) => {
                                                    return { code: index, name: c }
                                                })}
                                                label={i18n.status}
                                                nullLabel='&nbsp;'
                                                value={status}
                                                noSort
                                                onChange={setStatus}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Input
                                                title={i18n.startDate}
                                                value={startDate}
                                                onChange={(v) => setStartDate(v)}
                                                clearFunction
                                                id='perimeter_startDate'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item width={'100%'}>
                                            <Input
                                                title={i18n.endDate}
                                                value={endDate}
                                                onChange={(v) => setEndDate(v)}
                                                clearFunction
                                                id='perimeter_endDate'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        )}
                        {step === STEP.PARCELS && (
                            <Grid container padding={'30px 50px'}>
                                {
                                    parcel ? (
                                        <>
                                            <Grid container justifyContent={'space-evenly'} alignItems={'center'}>
                                                <Grid container item xs={'auto'}>
                                                    <Grid item>
                                                        <Button
                                                            variant='contained'
                                                            disabled={false}
                                                            onClick={previousPerimeter}
                                                        >
                                                            {i18n.previous}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={'auto'}>
                                                    <Grid item>
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => {
                                                                setParcel(undefined)
                                                            }}
                                                        >
                                                            {'Retour à la liste'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={'auto'}>
                                                    <Grid item>
                                                        <Button
                                                            variant='contained'
                                                            disabled={false}
                                                            onClick={nextPerimeter}
                                                        >
                                                            {'Parcelle suivante'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container item marginTop={'20px'} maxHeight={'60vh'} padding={'0 20px'} overflow={'auto'} spacing={3}>
                                                <Grid container item>
                                                    <StyledFieldSet style={{ width: '100%', padding: '30px 50px' }}>
                                                        <Grid container item justifyContent={'center'} spacing={2}>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.section}
                                                                        value={''}
                                                                        // onChange={(v) => setName(v)}
                                                                        clearFunction
                                                                        id='parcel_section'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.number}
                                                                        value={''}
                                                                        // onChange={(v) => setName(v)}
                                                                        clearFunction
                                                                        id='parcel_number'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.subdivision}
                                                                        value={''}
                                                                        // onChange={(v) => setName(v)}
                                                                        clearFunction
                                                                        id='parcel_subdivision'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.lieuDit}
                                                                        value={''}
                                                                        // onChange={(v) => setStartDate(v)}
                                                                        clearFunction
                                                                        id='parcel_lieuDit'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item spacing={2}>
                                                                <Grid container item xs={4}>
                                                                    <Grid item width={'100%'}>
                                                                        <Input
                                                                            title={i18n.area}
                                                                            value={''}
                                                                            // onChange={(v) => setEndDate(v)}
                                                                            clearFunction
                                                                            id='parcel_area'
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container item xs={4}>
                                                                    <Grid item width={'100%'}>
                                                                        <Input
                                                                            title={i18n.classOfDirt}
                                                                            value={''}
                                                                            // onChange={(v) => setEndDate(v)}
                                                                            clearFunction
                                                                            id='parcel_classOfDirt'
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledFieldSet>
                                                </Grid>
                                                <Grid container item>
                                                    <StyledFieldSet style={{ width: '100%', padding: '30px 50px' }}>
                                                        <Grid container item justifyContent={'center'} spacing={2}>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.requestNumber}
                                                                        value={''}
                                                                        // onChange={(v) => setName(v)}
                                                                        clearFunction
                                                                        id='parcel_requestNumber'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.formalityNumber}
                                                                        value={''}
                                                                        // onChange={(v) => setName(v)}
                                                                        clearFunction
                                                                        id='parcel_requestNumber'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.dateOfDeed}
                                                                        value={''}
                                                                        // onChange={(v) => setName(v)}
                                                                        clearFunction
                                                                        id='parcel_requestNumber'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item>
                                                                <Grid item width={'100%'}>
                                                                    <Textarea value={''} title={i18n.comment}/>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.publicationDate}
                                                                        value={''}
                                                                        // onChange={(v) => setStartDate(v)}
                                                                        clearFunction
                                                                        id='parcel_publicationDate'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.volume}
                                                                        value={''}
                                                                        // onChange={(v) => setEndDate(v)}
                                                                        clearFunction
                                                                        id='parcel_volume'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={4}>
                                                                <Grid item width={'100%'}>
                                                                    <Input
                                                                        title={i18n.publicationNumber}
                                                                        value={''}
                                                                        // onChange={(v) => setEndDate(v)}
                                                                        clearFunction
                                                                        id='parcel_publicationNumber'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledFieldSet>
                                                </Grid>
                                                <Grid container item>
                                                    <Grid item width={'100%'}>
                                                        <Grid item border={'2px solid #00359F'} borderRadius={'5px'}>
                                                            <Table
                                                                title={i18n.owners}
                                                                data={owners}
                                                                actions={[{
                                                                    iconName: 'note_add',
                                                                    tooltip: i18n.add,
                                                                    color: 'black',
                                                                    onClick: () => {
                                                                        setAddOwnerPopupOpen(true)
                                                                    },
                                                                }]}
                                                                paging
                                                                nbPerPageLabel={nbPerPageLabel}
                                                                type={{ headers: OWNERS_HEADER }}
                                                                condensed
                                                                sortable
                                                                id='perimeter_owners'
                                                                invertedHeaderStyle
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item>
                                                    <Grid item width={'100%'}>
                                                        <Grid item border={'2px solid #00359F'} borderRadius={'5px'}>
                                                            <Table
                                                                title={i18n.operators}
                                                                data={operators}
                                                                actions={[{
                                                                    iconName: 'note_add',
                                                                    tooltip: i18n.add,
                                                                    color: 'black',
                                                                    onClick: () => {
                                                                        setAddOperatorPopupOpen(true)
                                                                    },
                                                                }]}
                                                                paging
                                                                nbPerPageLabel={nbPerPageLabel}
                                                                type={{ headers: OPERATORS_HEADER }}
                                                                condensed
                                                                sortable
                                                                id='perimeter_operators'
                                                                invertedHeaderStyle
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item width={'100%'}>
                                            <ParcelsTable parcels={perimeter?.name ? parcels : []} parcel={parcel} setParcel={setParcel}/>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )}
                        {step === STEP.EVENTS && (
                            <Grid item width={'100%'}>
                                <Grid item border={'2px solid #00359F'} borderRadius={'5px'}>
                                    <Table
                                        title={i18n.events}
                                        data={[]}
                                        actions={[{
                                            iconName: 'note_add',
                                            tooltip: i18n.add,
                                            color: 'black',
                                            onClick: () => {},
                                        }]}
                                        paging
                                        nbPerPageLabel={nbPerPageLabel}
                                        type={{ headers: EVENTS_HEADER }}
                                        onClick={p => setParcel(p)}
                                        condensed
                                        sortable
                                        id='perimetersEvents_table'
                                        invertedHeaderStyle
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {step === STEP.DOCUMENTS && (
                            <Grid item width={'100%'}>
                                <Grid item border={'2px solid #00359F'} borderRadius={'5px'}>
                                    <Table
                                        title={i18n.documents}
                                        data={[]}
                                        actions={[{
                                            iconName: 'note_add',
                                            tooltip: i18n.add,
                                            color: 'black',
                                            onClick: () => {},
                                        }]}
                                        paging
                                        nbPerPageLabel={nbPerPageLabel}
                                        type={{ headers: DOCUMENTS_HEADER }}
                                        onClick={p => setParcel(p)}
                                        condensed
                                        sortable
                                        id='perimetersEvents_table'
                                        invertedHeaderStyle
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {step === STEP.MAP && (
                            <Grid item width={'100%'}>
                                <StationMapDashboardPanel
                                    noMarkerTooltip
                                    station={tempPerimeterStation}
                                    type={'piezometry'}
                                    className='margin-bottom-1 no-margin-top'
                                />
                            </Grid>
                        )}
                    </>
                )}
            </StepperDialog>
        </>
    )
}

PerimetersStepper.propTypes = {
    perimeter: PropTypes.shape(PerimeterDto),
    setPerimeter: PropTypes.func,
    previousPerimeter: PropTypes.func,
    nextPerimeter: PropTypes.func,
}

export default PerimetersStepper