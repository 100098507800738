import { Button, Grid } from '@mui/material'
import User from 'account/dto/User'
import ActionComponent from 'components/ActionComponent'
import Card from 'components/card/Card'
import ColorfulCard from 'components/card/ColorfulCard'
import Input from 'components/forms/Input'
import RadioButtons from 'components/forms/RadioButtons'
import Select from 'components/forms/Select'
import TabList from 'components/list/TabList'
import ProgressCard from 'components/card/ProgressCard'
import SieauAction from 'components/sieau/SieauAction'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { push } from 'connected-react-router'
import CmsAction from 'events/actions/CmsAction'
import CmsCard from 'events/components/CmsCard'
import HomeAction from 'home/actions/HomeAction'
import { PATH_CONTENTS } from 'home/constants/RouteConstants'
import { groupBy, isEqual, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { getNewsColor } from 'utils/ColorUtil'
import { getDate, getYearDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getUser } from 'utils/SettingUtils'
import { deleteKeys, getLabel, getMapStateToProps, getPropTypes } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'

const propsToFetch = {
    cmsEvents: false,
    cmsCategories: false,
    selectedSearchValues: false,
    sandreCodes: true,
}

const initFilter = {
    level: -1,
    status: -1,
    searchValue: '',
    category: null,
}

class ContentsApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                ...initFilter,
            },
            tmpFilter: {
                ...initFilter,
            },
            sortBy: 'updateDate',
            idWP: -1,
            dataLoaded: false,
        }
    }

    componentDidMount() {
        this.setActions({})
        this.props.fetchCMSEvents().then(() => {
            const { cmsCategories } = this.props
            if (!cmsCategories.length) {
                this.props.fetchCMSCategories().then(() => {
                    this.initComponent()
                })
            } else {
                this.initComponent()
            }
        })
    }

    initComponent = () => {
        const { cmsCategories, globalResearch, match } = this.props
        const { tmpFilter, filter } = this.state
        this.setState({ dataLoaded: true })
        this.setComponentActions(match.params.categoryId)
        if (cmsCategories.length) {
            this.setTitle(this.props, filter)
        }
        if (globalResearch) {
            this.setState({ filter: { ...filter, searchValue: globalResearch }, tmpFilter: { ...tmpFilter, searchValue: globalResearch } })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.categoryId !== nextProps.match.params.categoryId) {
            this.setState({
                category: nextProps.match.params.categoryId,
                sortBy: 'updateDate',
            })
            this.setComponentActions(nextProps.match.params.categoryId)
        }
    }

    setTitle(props, filter) {
        const categoryMenu = props.cmsCategories.find(c => c.id == this.props.match.params.categoryId)
        const category = filter.category ? (props.cmsCategories.find(c => c.id == filter.category) || {}).title || i18n.unknown : 'TOUTES'
        this.props.setTitle([{
            title: categoryMenu.menu,
            href: `${PATH_CONTENTS}/${props.match.params.categoryId}`,
        }, {
            title: category,
            href: `${PATH_CONTENTS}/${props.match.params.categoryId}`,
        }])
    }

    setComponentActions = (categoryId) => {
        const { cmsCategories, accountUser } = this.props
        const events = this.getEvents()
        const data = events.map(e => ({
            ...e,
            startDate: getDate(e.dateDebut),
            endDate: getDate(e.dateFin),
            updateDate: getDate(e.updateDate),
            category: e.categoryWPName,
            headers: ['title', 'comment', 'author', 'link', 'status', 'updateDate', 'startDate', 'endDate', 'x', 'y', 'category'],
        }))
        const category = cmsCategories.find(c => c.id == categoryId)
        const categoriesMenu = cmsCategories.filter(c => c.displayOnMenu && c.menu === category.menu)
        const categoriesManagers = accountUser.contributorCode ? categoriesMenu.filter(c => !c.managers || (c.managers.split(';').map(s => parseInt(s))).includes(accountUser.contributorCode)) : categoriesMenu.filter(c => !c.managers)
        if (!(getUser().consultant === '1') && categoriesManagers.length) {
            this.setActions({
                new: this.redirectToContent(0),
                export: () => {
                    return {
                        data,
                        exportType: 'xlsx',
                        titleFile: i18n.communication,
                    }
                },
            })
        }
    }

    onChangeSortBy = sortById => {
        this.setState({ sortBy: sortById })
        this.props.sieauUpdate('selectedSearchValues', 'content', { sortBy: sortById })
    }

    redirectToContent = (contentId) => () => {
        const { match } = this.props
        const { filter } = this.state
        const categoryId = filter.category && filter.category != -1 ? filter.category : match.params.categoryId
        this.props.push(`${PATH_CONTENTS}/${categoryId}/${contentId}`)
    }

    getSortByFunction = () => {
        const { sandreCodes, cmsCategories } = this.props
        const { sortBy } = this.state
        const statusOptions = [
            { value: -1, label: i18n.all },
            ...sandreCodes.filter((c) => c.field === 'CMS.STATUS'),
        ]
        switch (sortBy) {
            case 'updateDate':
                return content => getYearDate(content.dateDebut || content.updateDate)
            case 'status':
                return content => getLabel(statusOptions, content.status, null, 'value')
            case 'category':
                return content => getLabel(cmsCategories, content.idCategory, 'title', 'id')
            default:
                return sortBy
        }
    }

    getResult = filteredEvents => {
        const { sortBy } = this.state
        if (!filteredEvents.length) {
            return (
                <h4 className='center-align padding-top-1 padding-bottom-1'>{i18n.noContentToDisplay}</h4>
            )
        }
        const orderedEvents = orderBy(filteredEvents.map((e) => ({ ...e, sortDate: e.dateDebut || e.updateDate })), ['sortDate'], ['desc'])
        const groups = groupBy(orderedEvents, this.getSortByFunction())
        return orderBy(Object.keys(groups), [], sortBy === 'updateDate' ? 'desc' : 'asc').map((key, i) => {
            const size = groups[key].length
            const label = size > 1 ? i18n.elements : i18n.element
            const cards = groups[key].map(e => (
                <ColorfulCard key={e.id} color={hasValue(e.level) ? getNewsColor(e.level) : 'null'}>
                    <CmsCard cms={e} />
                </ColorfulCard>
            ))
            return (
                <div className='padding-1'>
                    <AccordionMUI defaultExpanded={i === 0} key={i} round>
                        <AccordionSummaryMUI round>
                            {`${key} (${size} ${label})`}
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI nopadding>
                            {cards}
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                </div>
            )
        })
    }

    getCategoriesSelect = (categories) => {
        const category = categories.find(c => c.id == this.props.match.params.categoryId)
        if (category && category.displayOnMenu) {
            const menuCategories = categories.filter(c => c.displayOnMenu && c.menu === category.menu).map(c => deleteKeys(c, ['icon']))
            return (
                <Select
                    col={6}
                    options={menuCategories}
                    label={i18n.category}
                    value={this.state.tmpFilter.category}
                    keyvalue='id'
                    keyLabel='title'
                    nullLabel='TOUTES'
                    onChange={v => this.onChangeFilter('category', hasValue(v) ? parseInt(v) : null)}
                />
            )
        }
        return null
    }

    filterCMSByAuth = (events, user) => {
        if (user.admin === '1') {
            return events
        }
        if (user.metadata === '1') {
            return events.filter((e) => e.authorization !== 'admin')
        }
        if (user.labo === '1') {
            return events.filter((e) => !e.authorization || ['anim', 'all'].includes(e.authorization))
        }
        return events.filter((e) => !e.authorization || e.authorization === 'all')
    }

    getFilteredCMSEvents = events => {
        const {
            category,
            status,
            level,
            searchValue,
        } = this.state.filter
        const { cmsCategories, match } = this.props
        const user = getUser()
        const cmsCategory = cmsCategories.find((c) => c.id === parseInt(match.params.categoryId))
        const categoriesIds = cmsCategories.filter((c) => c.displayOnMenu && c.menu === cmsCategory.menu).map((c) => c.id)
        const filterAuthorization = this.filterCMSByAuth(events, user)
        const filterLevel = level !== -1 ? filterAuthorization.filter(({ levelContent }) => level === levelContent) : filterAuthorization
        const filterCategory = hasValue(category) ? filterLevel.filter(({ idCategory }) => idCategory === category) : filterLevel.filter(({ idCategory }) => categoriesIds.includes(idCategory))
        const filterStatus = status !== -1 ? filterCategory.filter(e => e.status === status) : filterCategory

        const filterUser = user.admin !== '1' ? filterStatus.filter(e => e.status !== 2 || (e.status === 2 && e.login === user.login)) : filterStatus

        const searchValueFormat = searchAllCharacters(searchValue)
        return hasValue(searchValueFormat) ? filterUser.filter(({ title, subtitle, comment, author }) => searchAllCharacters(`${title}${subtitle}${comment}${author}`).includes(searchValueFormat)) : filterUser
    }

    getCMSFromCategory = events => {
        return this.getResult(this.getFilteredCMSEvents(events))
    }

    getStatusList = () => {
        const { sandreCodes } = this.props
        const statusOptions = [
            { value: -1, label: i18n.all },
            ...sandreCodes.filter((c) => c.field === 'CMS.STATUS'),
        ]
        return (
            <Select
                col={3}
                options={statusOptions}
                label={i18n.status}
                value={this.state.tmpFilter.status}
                keyLabel='label'
                noNullValue
                integerValue
                onChange={v => this.onChangeFilter('status', v)}
            />
        )
    }

    getLevelList = () => {
        const levelOptions = ['blue', 'green', 'orange', 'red'].map((c, i) => ({
            label: <span className={`${c} arrests-level-panel ${c}-text`}>O</span>,
            value: i + 1,
        }))
        const allLevelOptions = [
            {
                value: -1,
                label: i18n.everybody,
            },
            ...levelOptions,
        ]
        return (
            <RadioButtons
                col={6}
                elements={allLevelOptions}
                selected={this.state.tmpFilter.level}
                title={i18n.level}
                onChange={v => this.onChangeFilter('level', parseInt(v))}
            />
        )
    }

    getActive = panel => {
        return panel === this.state.sortBy ? 'active' : ''
    }

    onChangeFilter = (filter, value) => {
        const newObj = {}
        newObj[filter] = value
        if (filter === 'category') {
            newObj.sortBy = 'updateDate'
        }
        this.setState({ tmpFilter: { ...this.state.tmpFilter, ...newObj } })
    }

    onValidate = () => {
        const { cmsCategories, match } = this.props
        const { tmpFilter } = this.state
        this.setState({ filter: { ...tmpFilter } })
        this.props.sieauUpdate('selectedSearchValues', 'content', { category: tmpFilter.category })
        const category = cmsCategories.find(c => c.id == tmpFilter.category || c.id == match.params.categoryId)
        const name = category.displayOnMenu && category.menu ? category.menu : category.title
        const categorySelected = cmsCategories.find(c => c.id == tmpFilter.category)
        this.props.setTitle([{
            title: name,
            href: `${PATH_CONTENTS}/${match.params.categoryId}`,
        }, {
            title: categorySelected ? categorySelected.title : 'TOUTES',
            href: `${PATH_CONTENTS}/${match.params.categoryId}`,
        }])
    }

    componentDidUpdate(prevProps) {
        const { zeroResult } = this.state
        const { cmsCategories, cmsEvents, match } = this.props
        const results = this.getFilteredCMSEvents(cmsEvents).length
        if (results && zeroResult) {
            this.setState({ zeroResult: false })
        } else if (!results && !zeroResult) {
            this.setState({ zeroResult: true })
        }
        if ((cmsCategories.length && !isEqual(prevProps.cmsCategories, cmsCategories)) ||
            match.params.categoryId !== prevProps.match.params.categoryId) {
            this.setState({ filter: initFilter, tmpFilter: initFilter })
            this.setTitle(this.props, initFilter)
        }
    }

    getEvents = () => {
        return this.props.cmsEvents.map((o) => {
            if (o.categoryWPName) {
                return { ...o, idCategory: -1 }
            }
            return o
        })
    }

    onReinit = () => {
        this.setState({ filter: initFilter, tmpFilter: initFilter })
        this.setTitle(this.props, initFilter)
    }

    render() {
        const { tmpFilter, dataLoaded } = this.state
        const { cmsCategories } = this.props
        const events = this.getEvents()

        return (
            <>
                <div className='row no-margin'>
                    <Card maxWidth={1100} className='margin-top-1' round>
                        <div className='row no-margin padding-top-10-px'>
                            {this.getCategoriesSelect(cmsCategories)}
                            {this.getStatusList()}
                        </div>
                        <div className='row no-margin'>
                            <Input
                                col={6}
                                title={i18n.search}
                                value={tmpFilter.searchValue}
                                onChange={v => this.onChangeFilter('searchValue', v)}
                                onEnterKeyPress={this.onValidate}
                            />
                            {this.getLevelList()}
                        </div>
                        <Grid container className='padding-1' justifyContent='flex-end'>
                            <Grid item>
                                <Button variant='outlined' onClick={this.onReinit}>
                                    {i18n.reinit}
                                </Button>
                            </Grid>
                            <Grid item className='padding-left-1'>
                                <Button variant='contained' onClick={this.onValidate}>
                                    {i18n.search}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
                <div className='row no-margin'>
                    <Card maxWidth={1100} className='margin-top-1 transparent no-box-shadow' contentClassName='transparent'>
                        {dataLoaded ? (
                            <TabList
                                onChangeTab={(v) => this.onChangeSortBy(v)}
                                tabs={[
                                    {
                                        value: 'updateDate',
                                        label: i18n.perYear,
                                        icon: 'insert_invitation',
                                    },
                                    {
                                        value: 'author',
                                        label: i18n.byAuthor,
                                        icon: 'person',
                                    },
                                    {
                                        value: 'category',
                                        label: i18n.byCategory,
                                        icon: 'fiber_new',
                                    },
                                    {
                                        value: 'status',
                                        label: i18n.byStatus,
                                        icon: 'edit',
                                    },
                                ]}
                            >
                                {this.getCMSFromCategory(events)}
                            </TabList>
                        ) : <ProgressCard indeterminate round />}
                    </Card>
                </div>
            </>
        )
    }
}

ContentsApp.propTypes = getPropTypes(propsToFetch, {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        globalResearch: PropTypes.string,
    }),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    accountUser: PropTypes.instanceOf(User),
})

const mapStateToProps = (store) => getMapStateToProps(propsToFetch, {
    globalResearch: store.HomeReducer.globalResearch,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    accountUser: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
    fetchCMSCategories: CmsAction.fetchCMSCategories,
    sieauUpdate: SieauAction.update,
    sieauFetch: SieauAction.fetch,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentsApp)
