import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import ObservationsCard from './ObservationsCard'
import { WhiteCard } from '../../../components/styled/Card'
import { maxBy, orderBy } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'

const LastObservationsPanel = ({
}) => {
    const {
        hydrometryObservations,
    } = useSelector(store => ({
        hydrometryObservations: store.HydrometryReducer.hydrometryObservations,
    }), shallowEqual)

    const lastObservation = maxBy(hydrometryObservations, observation => observation?.observationDate || 0)
    const observationLast = useMemo(() => {
        return lastObservation ? orderBy(hydrometryObservations, ['observationDate', 'updateDate'], ['desc', 'desc'])
            .filter(observation => observation.observationDate == lastObservation.observationDate) : []
    }, [hydrometryObservations, lastObservation])

    return !!observationLast.length && (
        <WhiteCard title={i18n.lastObservations} round sx={{ marginTop: '10px' }} noMargin={false} >
            {observationLast.map(observation => (
                <ObservationsCard observation={observation} />
            ))}
        </WhiteCard>
    )
}

LastObservationsPanel.propTypes = {
    hydroId: PropTypes.number.isRequired,
}

export default LastObservationsPanel