import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PcMonitoringFilter from './PcMonitoringFilter'
import PropTypes from 'prop-types'
import { calculateThresholdResult } from 'utils/AnalyseUtils'
import { hasValue } from 'utils/NumberUtil'
import ProgressCard from 'components/card/ProgressCard'
import useActions from 'utils/customHook/useActions'
import i18n from 'simple-react-i18n'
import { getLocalStorageJson } from 'utils/FormUtils'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import QualityAction from 'quality/actions/QualityAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import OldOperationAction from '../operation/actions/OperationAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import useBoolean from 'utils/customHook/useBoolean'
import useIsMounted from 'utils/customHook/useIsMounted'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import { GRAPH, INDICE, RESULT, SELECTION } from 'quality/constants/QualityConstants'
import PcMonitoringGraph from './tab/PcMonitoringGraph'
import Tabs from 'components/Tabs'
import PcMonitoringTable from './tab/PcMonitoringTable'
import { isNil } from 'lodash'
import IndiceMonitoringTable from '../hydrobio/monitoring/IndiceMonitoringTable'

const DEFAULT_SELECTION = '-1'

const useMonitoringFetch = (ids = []) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const isMounted = useIsMounted()

    const [progress, setprogress] = useState(0)
    const {
        value: isLoaded,
        setTrue: setLoadedToTrue,
        setFalse: setLoadedToFalse,
    } = useBoolean(false)

    const [operations, setOperations] = useState([])
    const [analysis, setAnalysis] = useState([])
    const [indices, setIndices] = useState([])

    useEffect(() => {
        setOperations([])
        setAnalysis([])
        setLoadedToFalse()
        setprogress(0)

        const controller = new AbortController()
        if (ids.length) {
            dispatch(QualityAction.fetchOperationAndAnalysis(ids, controller.signal, setprogress)).then(result => {
                if (isMounted()) {
                    setLoadedToTrue(true)
                    setOperations(result.operations.map(o => new DtoOperation(o)))
                    setAnalysis(result.analysis.map(a => new DtoAnalysisLight(a)))
                }
            })
        }
        return () => {
            controller.abort()
        }
    }, [ids])

    useEffect(() => {
        const codes = ids.map(id => qualitometers.find(q => q.id === id)?.code).filter(c => !!c)
        if (codes.length) {
            QualityAction.promiseSearchIndices({ stations: codes }).catch(() => []).then((res = []) => setIndices(res))
        }
    }, [ids, qualitometers])

    return {
        analysis,
        operations,
        indices,
        analysisProgress: progress,
        isAnalysisLoaded: isLoaded,
    }
}

const PcMonitoring = ({
    ids = [],
}) => {
    const dispatch = useDispatch()

    const {
        qualityThresholds,
        parameterGroupUsage,
        accountUserSettings,
    } = useSelector(store => ({
        qualityThresholds: store.QualityReducer.qualityThresholds,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        accountUserSettings: store.AccountReducer.accountUserSettings,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    const defaultFilter = useMemo(() => {
        const groupEquivalences = getLocalStorageJson('PC_MONITORING_GROUP_EQUIVALENCES')
        return {
            groupEquivalences,
        }
    }, [])

    const [filter, setFilter] = useState(defaultFilter)
    /*
    filter = {
        startDate, // number
        endDate, // number
        parameters, // array[string]
        selection, // number
        selectionResults, // array of string
        group, // number
        threshold, // number
        qualitometer, // number

        producers, // array[number]
        laboratories, // array[number]
        point,
        support, // string
        fraction, // string
        quantificationControl, // boolean
        detectionControl, // boolean
        groupEquivalences, // boolean
        status, // string
        qualification, // string
    }
    */

    useEffect(() => {
        const SELECTION_FILTERS = `SELECTION_FILTERS_${SELECTION.PC}`
        const settingSelection = accountUserSettings.find(s => s.parameter === SELECTION_FILTERS)?.value || DEFAULT_SELECTION
        if (!isNil(settingSelection) && settingSelection !== DEFAULT_SELECTION) {
            setFilter(prev => ({
                ...prev,
                selection: settingSelection,
            }))
            dispatch(ParameterAction.fetchSelectionParameter(settingSelection)).then(resultsSelection => {
                const selectionResultsFormatted = resultsSelection.map(r => r.parameterCode)
                setFilter(prev => ({
                    ...prev,
                    selectionResults: selectionResultsFormatted,
                }))
            })
        }
    }, [accountUserSettings])

    useActions(() => ({
        exportList: [{
            onClick: openExportModal,
            label: i18n.excel,
        }],
    }), [])

    const {
        progress,
        isLoaded,
    } = useProgressDispatch(() => [
        dispatch(QualityAction.fetchQualifications()),
        dispatch(QualityAction.fetchStatus()),
        dispatch(ContributorAction.fetchContributors()),
        dispatch(ParameterAction.fetchParameters()),
        dispatch(UnitAction.fetchUnits()),
        dispatch(AdministrationAction.fetchSettings()),
        dispatch(SupportAction.fetchSupports()),
        dispatch(OldOperationAction.fetchRemarks()),
        dispatch(FractionAction.fetchFractions()),
        dispatch(ParameterAction.fetchParameterGroupUsage()),
        dispatch(QualityAction.fetchThresholds()),
    ])

    const {
        analysis,
        operations,
        indices,
        isAnalysisLoaded,
        analysisProgress,
    } = useMonitoringFetch(ids)

    const threshold = useMemo(() => hasValue(filter.threshold) ? qualityThresholds.find(t => t.thresholdCode === `${filter.threshold}`) : null, [filter.threshold, qualityThresholds])

    const analysisFormated = useMemo(() => {
        const thresholds = threshold?.thresholds || []
        return analysis.map(a => {
            const format = calculateThresholdResult(a, thresholds)
            const operation = operations.find(o => o.id === a.operation && o.qualitometer === a.qualitometer)
            return {
                ...a,
                ...format,
                producer: operation?.producer,
            }
        })
    }, [analysis, operations, threshold?.thresholds])

    const idsFiltered = useMemo(() => {
        return filter.qualitometer ? ids.filter(id => id === filter.qualitometer) : ids
    }, [filter.qualitometer, ids])

    const operationsFiltered = useMemo(() => {
        const qualitometerFilter = operations.filter(o => idsFiltered.includes(o.qualitometer))
        const startDateFilter = filter.startDate ? qualitometerFilter.filter(o => o.date >= filter.startDate) : qualitometerFilter
        const endDateFilter = filter.endDate ? startDateFilter.filter(o => o.date <= filter.endDate) : startDateFilter

        const producerFilter = filter.producers?.length ? endDateFilter.filter(o => filter.producers.includes(o.producer)) : endDateFilter
        const pointFilter = hasValue(filter.point) ? producerFilter.filter(o => o.point === filter.point) : producerFilter
        return hasValue(filter.support) ? pointFilter.filter(o => `${o.support}` === filter.support) : pointFilter
    }, [filter.endDate, filter.point, filter.producers, filter.startDate, filter.support, idsFiltered, operations])

    const analysisFiltered = useMemo(() => {
        const operationFilter = analysisFormated.filter(a => operationsFiltered.some(o => o.id === a.operation && o.qualitometer === a.qualitometer))

        const quantificationFilter = filter.quantificationControl ? operationFilter.filter(a => a.remark === '1') : operationFilter
        const detectionFilter = filter.detectionControl ? quantificationFilter.filter(a => a.remark !== '2') : quantificationFilter

        const laboratoryFilter = filter.laboratories?.length ? detectionFilter.filter(a => filter.laboratories.includes(a.labo)) : detectionFilter

        const fractionFilter = hasValue(filter.fraction) ? laboratoryFilter.filter(a => a.fractionCode === filter.fraction) : laboratoryFilter
        const statusFilter = hasValue(filter.status) ? fractionFilter.filter(a => a.status === `${filter.status}`) : fractionFilter
        const qualificationFilter = hasValue(filter.qualification) ? statusFilter.filter(a => a.qualification === `${filter.qualification}`) : statusFilter

        const selectionFilter = filter.selection !== '-1' && filter.selectionResults ? qualificationFilter.filter(a => filter.selectionResults.some(c => c === a.parameter)) : qualificationFilter
        const parameterFilter = filter.parameters?.length ? selectionFilter.filter(a => filter.parameters.includes(a.parameter)) : selectionFilter

        return filter.group ? parameterFilter.filter(a => parameterGroupUsage.some(p => p.groupCode === filter.group && p.parameter === a.parameter)) : parameterFilter
    }, [analysisFormated, filter.detectionControl, filter.fraction, filter.group, filter.laboratories, filter.parameters, filter.qualification, filter.quantificationControl, filter.selection, filter.selectionResults, filter.status, operationsFiltered, parameterGroupUsage])

    const filteredIndices = useMemo(() => {
        return indices.filter(i => operationsFiltered.some(o => o.id === i.idOperation && o.qualitometer === i.stationId))
    }, [indices, operationsFiltered])

    return (
        <div className='padding-left-2 padding-right-1'>
            {
                !isLoaded && <ProgressCard progress={progress} />
            }
            {
                !isAnalysisLoaded && <ProgressCard progress={analysisProgress} message={i18n.analysisLoading} />
            }
            {
                isLoaded && isAnalysisLoaded && (
                    <div className='row no-margin'>
                        <PcMonitoringFilter
                            onValidate={setFilter}
                            operations={operations}
                            analysis={analysis}
                            defaultFilter={filter}
                            ids={ids}
                        />

                        <Tabs
                            defaultTab={RESULT}
                            tabs={[
                                {
                                    constant: RESULT,
                                    label: i18n.result,
                                },
                                {
                                    constant: GRAPH,
                                    label: i18n.graph,
                                },
                                {
                                    constant: INDICE,
                                    label: i18n.indices,
                                },
                            ]}
                        >
                            {tab => (
                                <>
                                    {
                                        tab === RESULT && (
                                            <PcMonitoringTable
                                                analysis={analysisFiltered}
                                                operations={operationsFiltered}
                                                threshold={threshold}

                                                groupEquivalences={filter.groupEquivalences}

                                                isExportModalOpen={isExportModalOpen}
                                                closeExportModal={closeExportModal}
                                            />
                                        )
                                    }
                                    {
                                        tab === GRAPH && (
                                            <PcMonitoringGraph
                                                analysis={analysisFiltered}
                                                operations={operationsFiltered}
                                                thresholds={threshold?.thresholds}

                                                filter={filter}
                                            />
                                        )
                                    }
                                    {
                                        tab === INDICE && (
                                            <IndiceMonitoringTable
                                                indices={filteredIndices}
                                                operations={operations}
                                            />
                                        )
                                    }
                                </>
                            )}
                        </Tabs>
                    </div>
                )
            }
        </div>
    )
}

PcMonitoring.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
}

export default PcMonitoring