import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import { countBy, groupBy } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import { hasValue } from 'utils/NumberUtil'
import PropTypes from 'prop-types'
import { getYear } from 'utils/DateUtil'
import CatchmentDto from 'catchment/dto/CatchmentDto'
import { getContaminationState } from 'catchment/utils/CatchmentUtil'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import CatchmentPointDto from 'catchment/dto/CatchmentPointDto'
import moment from 'moment'
import { NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import DtoAnalysisUltraLight from 'quality/dto/analyse/DtoAnalysisUltraLight'
// import { getSandreLabel } from 'utils/StringUtil'

const ContaminationStatusChart = ({
    catchments = [],
    catchmentPoints = [],
    analysis = [],
    useP90 = false,
    referenceSdages = [],
}) => {
    const {
        qualitometers,
        qualityThresholds,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometers,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const thresholdNitrates = useApplicationSetting('CATCHMENT_NITRATES_THRESHOLD', setting => setting ? parseFloat(setting) : 50)
    const thresholdPesticides1 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_1', setting => setting ? parseFloat(setting) : 0.1)
    const thresholdPesticides2 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_2', setting => setting ? parseFloat(setting) : 0.5)
    const specificThreshold = useApplicationSetting('CATCHMENT_SPECIFIC_THRESHOLD')

    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)

    const threshold = qualityThresholds.find(t => t.thresholdCode === specificThreshold)

    const endPeriod = useApplicationSetting('CATCHMENT_END_PERIOD', setting => setting ? parseInt(setting) : undefined)

    const regroupment = useApplicationSetting('CATCHMENT_REGROUPING', setting => setting ? parseInt(setting) : 2)
    const calculationMethod = useApplicationSetting('CATCHMENT_OVERVIEW_CALCULATION_METHOD', listStringParser)

    const optionsCalculState = {
        thresholdNitrates,
        thresholdPesticides1,
        thresholdPesticides2,
        useP90,
        threshold,
        listSumPesticides,
        calculationMethod,
    }

    const currentYear = useMemo(() => moment(endPeriod, 'YYYY').endOf('year').valueOf(), [endPeriod])
    const lastYear = useMemo(() => moment(endPeriod, 'YYYY').subtract(regroupment - 1, 'years').startOf('year').valueOf(), [endPeriod, regroupment])

    const nitratesAnalysisGroup = useMemo(() => {
        const nitratesAnalysis = analysis.filter(({ parameter }) => parameter === NITRATES_CODE)
        return groupBy(nitratesAnalysis, 'qualitometer')
    }, [analysis])

    const pesticidesAnalysisGroup = useMemo(() => {
        const pesticidesAnalysis = analysis.filter(({ parameter }) => parameter !== NITRATES_CODE)
        return groupBy(pesticidesAnalysis, 'qualitometer')
    }, [analysis])

    const createSeries = (key, group = [], color, opacColor, name) => {
        const {
            reference,
            other,
        } = countBy(group, c => referenceSdages.includes(c.usedSdage) ? 'reference' : 'other')
        return [{
            type: 'bar',
            label: {
                show: !!other,
                position: 'inside',
                color: '#000',
            },
            data: [{
                value: [other, key],
                name: 'other',
            }],
            name,
            stack: true,
            barMaxWidth: 30,
            itemStyle: {
                color,
            },
        }, {
            type: 'bar',
            label: {
                show: !!reference,
                position: 'inside',
                color: '#000',
            },
            data: [{
                value: [reference, key],
                name: 'reference',
            }],
            name,
            stack: true,
            barMaxWidth: 30,
            itemStyle: {
                color: opacColor,
            },
        }]
    }

    const groupCatchment = groupBy(catchments, c => {
        const points = catchmentPoints.filter(p => p.id === c.id)
        const ids = points.map(p => qualitometers.find(q => q.code === p.codeWithoutDesignation)?.id).filter(id => hasValue(id))

        const nitrates = ids.flatMap(id => nitratesAnalysisGroup[id] || [])
        const pesticides = ids.flatMap(id => pesticidesAnalysisGroup[id] || [])
        return getContaminationState(nitrates, pesticides, optionsCalculState)
    })

    const series = [
        ...createSeries('missingData', groupCatchment.missingData, '#DDDDDD', '#EEEEEE', i18n.missingData),
        ...createSeries('nitratesAndPesticides', groupCatchment.nitratesAndPesticides, '#E40B00', '#F57B76', i18n.nitratesAndPesticides),
        ...createSeries('pesticides', groupCatchment.pesticides, '#FF6619', '#FFA77A', i18n.pesticides),
        ...createSeries('nitrates', groupCatchment.nitrates, '#FFD500', '#FFEA80', i18n.nitrates),
        ...createSeries('noContaminationHigherThanThreshold', groupCatchment.noContaminationHigherThanThreshold, '#3355FF', '#889CFC', i18n.noContaminationHigherThanThreshold),
    ]

    const title = useMemo(() => {
        const startYear = getYear(lastYear)
        const endYear = getYear(currentYear)
        if (startYear === endYear) {
            return `${i18n.stateContaminationNitratesPesticides} - ${i18n.year} ${startYear}`
        }
        return `${i18n.stateContaminationNitratesPesticides} - ${i18n.periode} ${startYear}-${endYear}`
    }, [currentYear, lastYear])

    const options = {
        title: {
            text: title,
            left: 'center',
        },
        series,
        xAxis: [{
            type: 'value',
            name: i18n.nbCatchment,
            nameLocation: 'middle',
            nameGap: 25,
        }],
        yAxis: [{
            type: 'category',
            data: ['noContaminationHigherThanThreshold', 'nitrates', 'pesticides', 'nitratesAndPesticides', 'missingData'],
            axisLabel: {
                formatter: name => i18n[name],
            },
        }],
        legend: {
            data: [{ name: i18n.missingData }, { name: i18n.nitratesAndPesticides }, { name: i18n.pesticides }, { name: i18n.nitrates }, { name: i18n.noContaminationHigherThanThreshold }],
            top: '7%',
            left: '10%',
            right: '20%',
            type: 'scroll',
        },
        grid: {
            top: '15%',
            left: '6%',
            right: '3%',
            bottom: '80',
            containLabel: true,
            height: 200,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                // getSandreLabel(sandreCodes, 'CAPTAGES.SDAGE', referenceSDAGE)
                const sdageResult = params.find(p => p.data.name === 'reference').data.value[0] || 0
                const sdageTooltip = sdageResult ? `${i18n.referenceSDAGE}: ${sdageResult} ${sdageResult > 1 ? i18n.catchments : i18n.catchment}</br>` : ''
                const otherResult = params.find(p => p.data.name === 'other').data.value[0] || 0
                if (sdageTooltip) {
                    return `${sdageTooltip}${i18n.otherCatchment}: ${otherResult} ${otherResult > 1 ? i18n.catchments : i18n.catchment}`
                }
                return `${params[0].seriesName}: ${otherResult} ${otherResult > 1 ? i18n.catchments : i18n.catchment}`
            },
        },
        toolbox: {
            right: '3%',
            top: '5%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: `${i18n.stateContaminationNitratesPesticides} - ${i18n.periode} ${getYear(lastYear)}-${getYear(currentYear)}`,
                },
            },
        },
    }
    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 300 }}
        />
    )
}

ContaminationStatusChart.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentDto)),
    catchmentPoints: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentPointDto)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight)),
    useP90: PropTypes.bool,
    referenceSdages: PropTypes.arrayOf(PropTypes.number),
}

export default ContaminationStatusChart