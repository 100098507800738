import { Button, Grid } from '@mui/material'
import Autocomplete from 'components/forms/Autocomplete'
import Textarea from 'components/forms/Textarea'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useSandreList from 'utils/customHook/useSandreList'
import { hasValue } from 'utils/NumberUtil'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import OperationAction from '../../actions/OperationAction'
import Input from '../../../../../components/forms/Input'
import { genericUpdatePromise } from '../../../../../utils/ActionUtils'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet } from 'components/StyledElements'
import { isUndefined } from 'lodash'
import useSandreParameters from 'utils/customHook/useSandreParameters'

const AnalysisPopin = ({
    stationId,
    operationId,
    sampleId,
    analysisId,
    isOpen = false,
    onClose = () => { },
    onValidate = () => { },

    additionalButtons,
}) => {
    const dispatch = useDispatch()
    const {
        qualifications,
        statusProps,
        parameters,
        contributors,
        units,
        remarks,
        methodProps,
        fractions,
        contacts,
    } = useSelector(store => ({
        qualifications: store.QualityReducer.qualifications,
        statusProps: store.QualityReducer.status,
        parameters: store.ParameterReducer.parameters,
        contributors: store.ContributorReducer.contributors,
        units: store.UnitReducer.units,
        remarks: store.OperationReducer.remarks,
        methodProps: store.MethodReducer.methods,
        fractions: store.FractionReducer.fractions,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const parametersSandre = useSandreParameters()

    const analysisPlace = useSandreList('ANALYSES.LIEU')
    const analysisAccreditation = useSandreList(SANDRE.ANALYSES_ACCREDITATION)
    const analysisDifficulty = useSandreList(SANDRE.ANALYSES_DIFFICULTE)

    const [analysis, setAnalysis] = useState({})

    useEffect(() => {
        if (!isOpen) {
            return
        }
        if (!isUndefined(analysisId)) {
            OperationAction.promiseSingleAnalysis(stationId, operationId, analysisId).then(([ana = {}]) => {
                const tmpAna = { ...ana, date: ana.analysisDate }
                setAnalysis(tmpAna)
            })
        } else {
            const tmpaAna = {
                id: 0,
                analysisId: 0,
                qualitometer: stationId,
                operation: operationId,
                sample: sampleId,
            }
            setAnalysis(tmpaAna)
        }
    }, [analysisId, isOpen, operationId, sampleId, stationId])

    const onSave = () => {
        if (!hasValue(analysis.parameter) || !hasValue(analysis.remark) || !hasValue(analysis.unit) || (analysis.remark && analysis.remark !== '0' && !hasValue(analysis.result))) {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
            return
        }
        const newAnalysis = {
            ...analysis,
            qualitometer: stationId,
            sampleDate: analysis.date,
            analysisDate: analysis.date,
            accreditation: hasValue(analysis.accreditation) ? `${analysis.accreditation}` : undefined,
            localization: hasValue(analysis.localization) ? `${analysis.localization}` : undefined,
            difficulty: hasValue(analysis.difficulty) ? `${analysis.difficulty}` : undefined,
        }
        if (analysisId) {
            genericUpdatePromise(OperationAction.updateAnalysis(newAnalysis)).then(res => onValidate(res, newAnalysis))
        } else {
            genericUpdatePromise(OperationAction.createAnalysis(newAnalysis)).then(res => onValidate(res, newAnalysis))
        }
    }

    const parameterFiltered = useMemo(() => {
        return parameters.filter(p => p.status !== '3' || p.code === analysis.parameter)
    }, [analysis.parameter, parameters])

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.updateAnalysis}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <StyledFieldSet style={{ padding: 10 }}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={2}>
                            <SimpleDatePicker
                                id='date'
                                label={i18n.analysisDate}
                                value={analysis.date}
                                onChange={date => setAnalysis(prev => ({ ...prev, date }))}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Select
                                label={i18n.laboratory}
                                options={contributors}
                                value={analysis.labo}
                                onChange={labo => setAnalysis(prev => ({ ...prev, labo }))}
                                clearFunction
                                keyLabel='mnemonique'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                title={i18n.refLabo}
                                value={analysis.refLabo}
                                onChange={refLabo => setAnalysis(prev => ({ ...prev, refLabo }))}
                            />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={6}>
                            <Select
                                label={i18n.parameter}
                                options={parameterFiltered}
                                value={analysis.parameter}
                                onChange={parameter => setAnalysis(prev => ({ ...prev, parameter }))}
                                clearFunction
                                keyLabel='labelWithCode'
                                obligatory
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.place}
                                options={analysisPlace}
                                value={analysis.localization}
                                onChange={localization => setAnalysis(prev => ({ ...prev, localization }))}
                                clearFunction
                                keyLabel='labelWithCode'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {
                                parametersSandre[analysis.parameter] && (
                                    <Select
                                        options={parametersSandre[analysis.parameter]}
                                        value={analysis.result}
                                        label={i18n.result}
                                        keyLabel='name'
                                        keyValue='code'
                                        returnNull
                                        onChange={result => setAnalysis(prev => ({ ...prev, result }))}
                                    />
                                )
                            }
                            {
                                !parametersSandre[analysis.parameter] && (
                                    <NumberField
                                        col={3}
                                        title={i18n.result}
                                        value={analysis.result}
                                        floatValue
                                        onChange={result => setAnalysis(prev => ({ ...prev, result }))}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                label={i18n.unit}
                                options={units}
                                value={analysis.unit}
                                onChange={unit => setAnalysis(prev => ({ ...prev, unit }))}
                                keyLabel='symbolWithName'
                                keyFilter={['name', 'symbol', 'code']}
                                obligatory
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={remarks}
                                value={analysis.remark}
                                label={i18n.remark}
                                keyLabel='name'
                                keyValue='code'
                                returnNull
                                onChange={remark => setAnalysis(prev => ({ ...prev, remark }))}
                                obligatory
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                col={3}
                                title={i18n.resultRef}
                                value={analysis.resultRef}
                                onChange={resultRef => setAnalysis(prev => ({ ...prev, resultRef }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.fraction}
                                options={fractions}
                                value={analysis.fraction}
                                keyValue='id'
                                onChange={fraction => setAnalysis(prev => ({ ...prev, fraction }))}
                                clearFunction
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                label={i18n.method}
                                options={methodProps}
                                value={analysis.method}
                                onChange={method => setAnalysis(prev => ({ ...prev, method }))}
                                keyLabel='name'
                                keyValue='id'
                                keyFilter={['name', 'code']}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.numSample}
                                value={analysis.numSample}
                                onChange={numSample => setAnalysis(prev => ({ ...prev, numSample }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={statusProps}
                                value={analysis.status}
                                label={i18n.status}
                                keyLabel='name'
                                keyValue='code'
                                stringValue={true}
                                returnNull
                                onChange={status => setAnalysis(prev => ({ ...prev, status }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={qualifications}
                                value={analysis.qualification}
                                label={i18n.qualification}
                                keyLabel='name'
                                keyValue='code'
                                stringValue={true}
                                returnNull
                                onChange={qualification => setAnalysis(prev => ({ ...prev, qualification }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.detectionLimit}
                                value={analysis.ld}
                                floatValue
                                onChange={ld => setAnalysis(prev => ({ ...prev, ld }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.quantificationLimit}
                                value={analysis.lq}
                                floatValue
                                onChange={lq => setAnalysis(prev => ({ ...prev, lq }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.saturationThreshold}
                                value={analysis.saturationThreshold}
                                floatValue
                                onChange={saturationThreshold => setAnalysis(prev => ({ ...prev, saturationThreshold }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={analysisAccreditation}
                                value={analysis.accreditation}
                                label={i18n.accreditation}
                                // keyLabel='name'
                                // keyValue='code'
                                // stringValue={true}
                                returnNull
                                onChange={accreditation => setAnalysis(prev => ({ ...prev, accreditation }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={analysisDifficulty}
                                value={analysis.difficulty}
                                label={i18n.difficulty}
                                // keyLabel='name'
                                // keyValue='code'
                                // stringValue={true}
                                returnNull
                                onChange={difficulty => setAnalysis(prev => ({ ...prev, difficulty }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                title={i18n.comment}
                                value={analysis.analysisComment}
                                onChange={analysisComment => setAnalysis(prev => ({ ...prev, analysisComment }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                title={i18n.resultComment}
                                value={analysis.resultComment}
                                onChange={resultComment => setAnalysis(prev => ({ ...prev, resultComment }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.analysisDoubt}
                                value={analysis.analysisDoubt}
                                floatValue
                                onChange={analysisDoubt => setAnalysis(prev => ({ ...prev, analysisDoubt }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                title={i18n.solvent}
                                value={analysis.solvent}
                                onChange={solvent => setAnalysis(prev => ({ ...prev, solvent }))}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.extractionMethod}
                                options={methodProps}
                                value={analysis.extractionMethod}
                                onChange={extractionMethod => setAnalysis(prev => ({ ...prev, extractionMethod }))}
                                keyLabel='name'
                                keyValue='id'
                            />

                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.extractionEfficiency}
                                value={analysis.extractionEfficiency}
                                floatValue
                                onChange={extractionEfficiency => setAnalysis(prev => ({ ...prev, extractionEfficiency }))}
                            />

                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.filterVolume}
                                value={analysis.filterVolume}
                                floatValue
                                onChange={filterVolume => setAnalysis(prev => ({ ...prev, filterVolume }))}
                            />

                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.manager}
                                options={contacts}
                                value={analysis.manager}
                                onChange={manager => setAnalysis(prev => ({ ...prev, manager }))}
                                keyLabel='name'
                                keyValue='id'
                            />

                        </Grid>

                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='space-between'>
                    <Grid item xs={3} />
                    {additionalButtons ?? <Grid item xs={6} />}
                    <Grid container item xs={3} justifyContent='flex-end'>
                        <Button onClick={onSave} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}


AnalysisPopin.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,

    stationId: PropTypes.number.isRequired,
    operationId: PropTypes.number,
    sampleId: PropTypes.number,
    analysisId: PropTypes.number,

    additionalButtons: PropTypes.element,
}

export default AnalysisPopin