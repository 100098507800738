import 'echarts/lib/chart/bar'
import { filter, isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../../../components/datatable/Table'
import { getDate, getFullDateMini } from '../../../../../../utils/DateUtil'
import DtoUser from '../../../dto/DtoUser'
import DtoUserStatistic from '../../../dto/DtoUserStatistic'
import DtoUserStatisticItem from '../../../dto/DtoUserStatisticItem'

class StatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.userStatistics, this.props.userStatistics)
            || !isEqual(nextProps.user, this.props.user)
    }

    getReduceStatistics = (statistics) => {
        return reduce(statistics, (a, b) => {
            if (!a[`${getDate(b.statisticDate)}_${b.value}`]) {
                a[`${getDate(b.statisticDate)}_${b.value}`] = {
                    module: b.module,
                    value: b.value,
                    datas: [],
                }
            }
            a[`${getDate(b.statisticDate)}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getDatas = (statistics) => {
        const reduceValues = this.getReduceStatistics(statistics)
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            return new DtoUserStatisticItem({
                login: statisticObject.login,
                module: statisticObject.module,
                value: statisticObject.value,
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                statisticDate: lastVisit.statisticDate,
                nbAccess: listOfStatistics.length,
            })
        }), 'statisticDate', 'desc')
    }

    render() {
        const login = this.props.user.login || i18n.users
        const statistics = filter(this.props.userStatistics, o => o.value)
        if (statistics.length) {
            const data = this.getDatas(statistics)
            return (
                <div className='row no-margin card'>
                    <div className='col s12 no-padding'>
                        <Table title={ `5 ${i18n.userStatistics}` }
                            type={ new DtoUserStatisticItem({}) } data={ take(data, 5) }
                            exportButtonOnHeader exportData={ data }
                            showNbElements={ false }
                            exportName={ i18n.userStatisticsExport + login }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

StatisticsPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
}
const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
    }
}
export default connect(mapStateToProps)(StatisticsPanel)