import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import EChart from 'components/echart/EChart'
import Graph from 'components/echart/series/Graph'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { BASE64_IMAGE, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { LINKS, PRODUCTION_UNIT_LINK_TYPES } from 'productionUnit/constants/ProductionUnitConstants'
import { exportPictureIcon, fullScreenIcon } from 'components/echart/EChartUtils'
import { groupBy, keys, orderBy } from 'lodash'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { push } from 'connected-react-router'
import { getNewStationTypeNameFromTypeCode } from 'utils/StationUtils'
import { convertSvgToBase64, getUnitProdMarker } from 'utils/mapUtils/Markers'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import StationAction from 'station/actions/StationAction'
import PropTypes from 'prop-types'
import ProgressCard from 'components/card/ProgressCard'
import { THRESHOLD_COLORS_CODES } from 'utils/constants/ColorTheme'

const typeSize = 35
const gap = 125
const column1 = 150
const column2 = 500
const column3 = 850
const defaultSymbolSize = 20

const AssociatedChart = ({
    indicatorsColor = [],
}) => {
    const ref = useRef(null)

    const {
        prodUnitLinks,
        productionUnit,
        contributors,
    } = useSelector(store => ({
        prodUnitLinks: store.ProductionUnitReducer.prodUnitLinks,
        productionUnit: store.ProductionUnitReducer.productionUnit,
        contributors: store.ContributorReducer.contributors,
    }))

    const [deployed, setDeployed] = useState([])
    const [width, setWidth] = useState([])
    const [fullScreen, setFullScreen] = useState(false)

    const operatorType = useApplicationSetting('contributorTypeOperator', d => parseInt(d) || 4)

    const dispatch = useDispatch()

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth)
    }, [fullScreen])

    const POINT = {
        1: { code: i18n.resources, name: i18n.resources, x: column1, y: 100, symbolSize: typeSize, symbol: BASE64_IMAGE[9], linkType: 1, source: i18n.resources, target: productionUnit.code, direction: 'left' },
        2: { code: i18n.monitoring, name: i18n.monitoring, x: column1, y: 250, symbolSize: typeSize, linkType: 2, source: i18n.monitoring, target: productionUnit.code, direction: 'left' },
        3: { code: i18n.production, name: i18n.production, x: column1, y: 400, symbolSize: typeSize, linkType: 3, source: i18n.production, target: productionUnit.code, direction: 'left' },
        4: { code: i18n.job, name: i18n.job, x: column2, y: 100, symbolSize: typeSize, linkType: 4, source: i18n.job, target: productionUnit.code, direction: 'top' },
        5: { code: i18n.distributionUnit, name: i18n.distributionUnit, x: column3, y: 100, symbol: BASE64_IMAGE[3], symbolSize: typeSize, linkType: 5, source: productionUnit.code, target: i18n.distributionUnit, direction: 'right' },
        6: { code: i18n.distributionUnitName, name: i18n.distributionUnitName, x: column3, y: 400, symbol: BASE64_IMAGE[6], symbolSize: typeSize, linkType: 6, source: productionUnit.code, target: i18n.distributionUnitName, direction: 'right' },
    }

    const contributor = useMemo(() => {
        const operator = orderBy(productionUnit.contributors, 'startDate').reverse().find(d => d.contributorType === operatorType && !d.endDate)
        const operatorData = contributors.find(d => d.id === operator?.idContributor)
        return operatorData ? {
            ...operatorData,
            code: `${operatorData?.code}`,
            source: productionUnit.code,
            target: `${operatorData?.code}`,
            x: column2,
            y: 400,
            symbol: BASE64_IMAGE[8],
            symbolSize: typeSize,
            linkType: 7,
        } : null
    }, [contributors, productionUnit, operatorType])

    const groupsTest = useMemo(() => ({
        ...groupBy(prodUnitLinks, 'linkType'),
        7: [],
    }), [prodUnitLinks])

    const position = (point, index) => {
        switch (point.direction) {
            case 'left':
                return {
                    x: point.x - gap - gap * (Math.trunc(index / 5)),
                    y: point.y + (typeSize * (index % 5)),
                }
            case 'right':
                return {
                    x: point.x + gap + gap * (Math.trunc(index / 5)),
                    y: point.y + (typeSize * (index % 5)),
                }
            default:
                return {
                    x: point.x + (typeSize * (index % 10)),
                    y: point.y - gap - gap * (Math.trunc(index / 10)) + (typeSize * (index % 2)),
                }
        }
    }

    const pointsInfos = useMemo(() => keys(groupsTest).flatMap(key => {
        const groupPoints = groupsTest[key]
        const mainPoint = (parseInt(key) !== PRODUCTION_UNIT_LINK_TYPES.OPERATOR) ? { ...POINT[key], value: groupPoints.length } : contributor

        const points = groupPoints.length < 11 || deployed.includes(mainPoint.linkType) ? groupPoints.map((gp, i) => ({
            ...gp,
            code: `${gp.code} (${gp.linkType}/${i})`,
            name: gp.code,
            symbol: BASE64_IMAGE[gp.siteType] || 'circle',
            source: POINT[key]?.code,
            target: `${gp.code} (${gp.linkType}/${i})`,
            ...position(POINT[key], i),
        })) : []

        return [mainPoint, ...points].filter(d => !!d)
    }), [groupsTest, deployed, contributor])
    const links = useMemo(() => pointsInfos.map(d => ({ source: d.source, target: d.target })), [pointsInfos])

    const onClick = ({ data }) => {
        const { category, symbolSize, siteType, siteCode } = data
        if (symbolSize === typeSize) {
            if (deployed.includes(category)) {
                setDeployed(deployed.filter(d => d !== category))
            } else {
                setDeployed([...deployed, category])
            }
        } else if (symbolSize === defaultSymbolSize) {
            dispatch(push(`/station/${getNewStationTypeNameFromTypeCode(siteType)}/${siteCode}`))
        }
    }

    const pointsAndUnit = [{
        ...productionUnit,
        x: 500,
        y: 250,
        symbol: convertSvgToBase64(getUnitProdMarker(indicatorsColor.color)),
        symbolSize: 150,
        linkType: 0,
        indicator: indicatorsColor.value,
    }, ...pointsInfos]

    const categories = [{
        name: '',
    }, ...keys(LINKS).map(key => groupsTest[key]?.length ? { name: i18n[LINKS[key]] } : { name: '' })]

    const options = {
        series: [Graph({
            categories,
            zoom: 0.7,
            data: pointsAndUnit.map(p => ({
                symbol: p.symbol || 'circle',
                symbolSize: p.symbolSize || defaultSymbolSize,
                name: p.name,
                city: p.city,
                id: p.code,
                code: p.code,
                category: p.linkType,
                value: p.value,
                x: p.x,
                y: p.y,
                siteCode: p.siteCode,
                siteType: p.siteType,
                fixed: true,
                indicator: p.indicator,
                label: {
                    position: 'bottom',
                    color: 'black',
                    formatter: ({ data }) => {
                        return `${data.name} ${data.value && `(${data.value})` || ''}`
                    },
                },
            })),
            links,
            layout: 'force',
            roam: true,
            cursor: 'pointer',
            tooltip: {
                trigger: 'item',
                formatter: ({ data }) => {
                    return `${data.name || ''}${data.city && ` / ${data.city}` || ''}${data.indicator && ` - ${data.indicator}` || ''}`
                },
            },
            label: {
                show: true,
            },
        })],
        height: fullScreen ? width / 2 : 500,
        toolbox: {
            show: true,
            feature: {
                restore: { title: i18n.restore },
                saveAsImage: {
                    title: i18n.pictureExport,
                    icon: exportPictureIcon,
                },
                myToolFullScreen: {
                    show: true,
                    title: i18n.fullScreen,
                    icon: fullScreenIcon,
                    onclick: () => setFullScreen(!fullScreen),
                },
            },
            right: 50,
            top: 10,
        },
        legend: [
            {
                data: categories.map(d => d.name),
            },
        ],
    }

    return (
        <Grid ref={ref} className={fullScreen ? 'fullscreen-chart' : ''}>
            <Card>
                <EChart options={options} id='prodLinks' onClick={onClick}/>
            </Card>
        </Grid>
    )
}

AssociatedChart.propTypes = {
    indicatorsColor: PropTypes.arrayOf(PropTypes.number),
}

const ProdGraphPanel = ({}) => {
    const dispatch = useDispatch()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [indicators, setIndicators] = useState([])

    const {
        associatedSites,
        productionUnit,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
        productionUnit: store.ProductionUnitReducer.productionUnit,
    }))

    const { isLoaded, progress } = useProgressDispatch(() => {
        const associatedPiezos = associatedSites.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.piezometer).map(({ stationLinkedId }) => stationLinkedId)
        const associatedHydros = associatedSites.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry).map(({ stationLinkedId }) => stationLinkedId)
        return [
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedPiezos, STATION_TYPE_NAME.piezometer)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators.flatMap(i => i.data)])),
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedHydros, STATION_TYPE_NAME.hydrologicalStation)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators.flatMap(i => i.data)])),
        ]
    }, [associatedSites])

    useEffect(() => {
        if (productionUnit) {
            setDataLoaded(false)
            dispatch(ProductionUnitAction.fetchProductionUnitLinks(productionUnit.id)).then(() => {
                setDataLoaded(true)
            })
        }
    }, [productionUnit])

    const indicatorsColor = useMemo(() => {
        if (indicators.some(d => ['red', 'indianred', 'darkmagenta'].includes(d.color) || (d?.value?.toLowerCase() || '')?.includes('alerte'))) {
            return { value: i18n.crisis, color: THRESHOLD_COLORS_CODES.RED }
        } else if (indicators.some(d => (d?.value?.toLowerCase() || '')?.includes('vigilance'))) {
            return { value: i18n.vigilance, color: THRESHOLD_COLORS_CODES.YELLOW }
        } else if (indicators.some(d => ['grey', 'gray'].includes(d.color))) {
            return { value: i18n.noData, color: THRESHOLD_COLORS_CODES.GREY }
        }
        return { value: i18n.normal, color: THRESHOLD_COLORS_CODES.BLUE }
    }, [indicators])

    const progressFormatted = !dataLoaded ? (progress * 0.9) : progress

    return (dataLoaded && isLoaded) ? (
        <AssociatedChart
            indicatorsColor={indicatorsColor}
        />
    ) : (
        <Grid container>
            <Grid item xs={12}>
                <ProgressCard progress={progressFormatted} withMessage />
            </Grid>
        </Grid>
    )
}

ProdGraphPanel.propTypes = {}

export default ProdGraphPanel