import { createSlice } from '@reduxjs/toolkit'
import AppStore from 'store/AppStore'
import AccountAction from '../../../../account/actions/AccountAction'
import DtoAccountHabilitation from '../../../../account/dto/DtoAccountHabilitation'
import DtoUsersOfFilters from '../../../../station/dto/DtoUsersFilterRights'
import { arrayOf, instanceOf } from '../../../../utils/StoreUtils'
import UserAction from '../actions/UserAction'
import UserThunk from '../actions/UserThunk'
import DtoApplicationHabilitation from '../dto/DtoApplicationHabilitation'
import DtoMobileNotification from '../dto/DtoMobileNotification'
import DtoUser from '../dto/DtoUser'
import DtoUserBookmark from '../dto/DtoUserBookmark'
import DtoUserCmsStatistic from '../dto/DtoUserCmsStatistic'
import DtoUserHabilitation from '../dto/DtoUserHabilitation'
import DtoUserLayerAssignment from '../dto/DtoUserLayerAssignment'
import DtoUserLight from '../dto/DtoUserLight'
import DtoUserStation from '../dto/DtoUserStation'
import DtoUserStationStatistic from '../dto/DtoUserStationStatistic'
import DtoUserStatistic from '../dto/DtoUserStatistic'
import DtoUserUsageStatistic from '../dto/DtoUserUsageStatistic'
import KeyFigureDto from '../dto/KeyFigureDto'
import UserDto from '../dto/UserDto'
import UserViewDto from '../dto/UserViewDto'

export const initialState = {
    user: {},
    users: [],
    usersLight: [],
    usersThemes: [],
    userThemes: [],
    bookmarks: [],
    mobileNotifications: [],
    userKeyfiguresAll: [],
    habilitations: [],
    userStatistics: [],
    userAllStatistics: [],
    userKeyFigures: [],
    userStationStatistics: [],
    userAllStationStatistics: [],
    userUsageStatistics: [],
    userAllUsageStatistics: [],
    userCmsStatistics: [],
    userAllCmsStatistics: [],
    userStations: [],
    userHabilitations: [],
    applicationHabilitations: [],
    userView: [],
    genericUserView: [],
    userBookmarks: [],
    applicativeUsers: [],
    usersOfFilter: [],
    userStation: {
        exists: false,
        hasRights: false,
    },
}

const store = createSlice({
    name: 'user',
    initialState,
    reducers: {
        receiveUsers: (state, action) => {
            state.users = action.payload.map(user => new UserDto(user))
        },
        receiveUsersLight: (state, action) => {
            state.usersLight = action.payload.map(user => new DtoUserLight(user))
        },
        receiveApplicativeUsers: (state, action) => {
            state.applicativeUsers = action.payload.map(user => new UserDto(user))
        },
        receiveUsersThemesAssignement: (state, action) => {
            state.usersThemes = action.payload.map(user => new DtoUserLayerAssignment(user))
        },
        receiveUserThemesAssignement: (state, action) => {
            state.userThemes = action.payload.map(user => new DtoUserLayerAssignment(user))
        },
        receiveUser: (state, action) => {
            state.user = new DtoUser(action.payload)
        },
        resetUserAttempts: (state) => {
            state.user = new DtoUser({ ...state.user, connexionAttempts: 0 })
        },
        receiveAllMobileNotifications: (state, action) => {
            state.mobileNotifications = action.payload.map(mn => new DtoMobileNotification(mn))
        },
        receiveUserStatistic: (state, action) => {
            state.userStatistics = action.payload.map(el => new DtoUserStatistic(el))
        },
        receiveAllUserStatistic: (state, action) => {
            state.userAllStatistics = action.payload.map(el => new DtoUserStatistic(el))
        },
        receiveUserKeyFigures: (state, action) => {
            state.userKeyFigures = action.payload.map(el => new KeyFigureDto(el))
        },
        receiveUserStationStatistics: (state, action) => {
            state.userStationStatistics = action.payload.map(el => new DtoUserStationStatistic(el))
        },
        receiveAllUserStationStatistics: (state, action) => {
            state.userAllStationStatistics = action.payload.map(el => new DtoUserStationStatistic(el))
        },
        receiveUserUsageStatistics: (state, action) => {
            state.userUsageStatistics = action.payload.map(el => new DtoUserUsageStatistic(el))
        },
        receiveAllUserUsageStatistics: (state, action) => {
            state.userAllUsageStatistics = action.payload.map(el => new DtoUserUsageStatistic(el))
        },
        receiveUserCmsStatistics: (state, action) => {
            state.userCmsStatistics = action.payload.map(el => new DtoUserCmsStatistic(el))
        },
        receiveAllUserCmsStatistics: (state, action) => {
            state.userAllCmsStatistics = action.payload.map(el => new DtoUserCmsStatistic(el))
        },
        receiveUserHabilitations: (state, action) => {
            state.userHabilitations = action.payload.map(el => new DtoUserHabilitation(el))
        },
        receiveAllBookmarks: (state, action) => {
            state.userBookmarks = action.payload.map(el => new DtoUserBookmark(el))
        },
        receiveAllUserKeyFigures: (state, action) => {
            state.userKeyfiguresAll = action.payload.map(el => new KeyFigureDto(el))
        },
        receiveUserLoginHabilitations: (state, action) => {
            state.habilitations = action.payload.map(el => new DtoUserHabilitation(el))
        },
        receiveUserStations: (state, action) => {
            state.userStations = action.payload.map(el => new DtoUserStation(el))
        },
        receiveAllApplicationHabilitation: (state, action) => {
            state.applicationHabilitations = action.payload.map(el => new DtoApplicationHabilitation(el))
        },
        receiveUserView: (state, action) => {
            state.userView = action.payload.map(el => new UserViewDto(el))
        },
        receiveGenericUserView: (state, action) => {
            state.genericUserView = action.payload.map(el => new UserViewDto(el))
        },
        receiveFilterUsers: (state, action) => {
            state.usersOfFilter = action.payload.map(el => new DtoUsersOfFilters(el))
        },
        resetUser: (state) => {
            state.user = []
            state.userBookmarks = []
            state.habilitations = []
            state.userCmsStatistics = []
            state.userView = []
            state.genericUserView = []
        },
        receiveStationRights: (state, action) => {
            state.userStation = action.payload
        },
    },
    extraReducers: {
        [UserThunk.fetchBookmarks.fulfilled]: (state, action) => {
            state.userBookmarks = action.payload.map(el => new DtoUserBookmark(el))
        },
    },
})

export const userActions = store.actions
export default store.reducer

export const UserStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        userKeyfigures: {
            fetch: UserAction.fetchUserKeyFigures,
            store: appStore.UserReducer.userKeyfiguresAll,
            propType: arrayOf(KeyFigureDto),
        },
        user: {
            fetch: UserAction.fetchUser,
            store: appStore.UserReducer.user,
            propType: instanceOf(DtoUser),
            reset: userActions.resetUser,
        },
        users: {
            fetch: UserAction.fetchUsers,
            store: appStore.UserReducer.users,
            propType: arrayOf(UserDto),
        },
        habilitations: {
            fetch: AccountAction.fetchAccountHabilitations,
            store: appStore.AccountReducer.accountHabilitations,
            propType: arrayOf(DtoAccountHabilitation),
        },
    }
}
