import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import DtoOperation from 'station/components/suivipc/qualitometer/dto/DtoOperation'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { maxBy, orderBy, uniq } from 'lodash'
import { getLabel } from 'utils/StoreUtils'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { exportExcelIcon, exportPictureIcon } from 'components/echart/EChartUtils'
import { exportFile } from 'utils/ExportDataUtil'

const PcMonitoringOperationGraph = ({
    analysis = [],
    operations = [],
}) => {
    const {
        parameters,
        units,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const lastOperation = maxBy(operations, 'date')

    const analysisFiltered = useMemo(() => {
        return analysis.filter(a => a.operation === lastOperation?.id)
            .filter(a => a.remark && !(['0', '2', '3', '5', '7', '10'].includes(a.remark)))
            .filter(a => {
                const parameter = parameters.find(p => p.code === a.parameter)
                return parameter?.typeParam === '0' || parameter?.typeParam === '1'
            })
    }, [analysis, lastOperation?.id, parameters])

    const data = analysisFiltered.map(a => [a.parameter, a.result, a.unit])

    const listParam = uniq(analysisFiltered.filter(a => hasValue(a.result)).map(a => a.parameter))

    const listParamOrdered = orderBy(listParam, p => p)

    const options = {
        title: {
            text: i18n.resultLastOperation,
            x: 'center',
            top: '1%',
        },
        series: [{
            type: 'bar',
            data,
            name: `${i18n.operation} ${i18n.fromDate} ${getDate(lastOperation?.date)}`,
            barMaxWidth: 30,
            itemStyle: {
                color: 'blue',
            },
        }],
        xAxis: [{
            type: 'category',
            data: listParamOrdered,
            boundaryGap: true,
            axisLabel: {
                formatter: v => getLabel(parameters, v, 'displayLabel'),
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
            position: 'left',
            nameLocation: 'center',
            nameGap: 50,
            boundaryGap: true,
        }],
        legend: {
            top: '5%',
            left: '10%',
            right: '20%',
            type: 'scroll',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                const paramName = getLabel(parameters, params[0].axisValue, 'labelWithCode')
                return params.map(p => `${paramName}: ${p.data[1]} ${getLabel(units, p.data[2], 'symbol')}`).join('<br/>')
            },
        },
        grid: {
            top: '15%',
            left: '6%',
            right: '3%',
            bottom: '80',
            containLabel: true,
            height: 500,
        },
        toolbox: {
            right: '5%',
            top: '5%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: i18n.resultLastOperation,
                },
                myToolExport: {
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        const exportData = analysisFiltered.map(a => {
                            return {
                                parameterCode: { value: a.parameter, cellType: 'right' },
                                parameter: getLabel(parameters, a.parameter, 'name'),
                                unitCode: { value: a.unit, cellType: 'right' },
                                unit: getLabel(units, a.unit, 'symbol'),
                                result: { value: a.value, cellType: 'right' },
                            }
                        })
                        const sortedData = orderBy(exportData, 'parameterCode.value')
                        exportFile({
                            data: sortedData.length ? [
                                {
                                    ...sortedData[0],
                                    headers: ['parameterCode', 'parameter', 'unitCode', 'unit', 'result'],
                                },
                                ...sortedData.slice(1),
                            ] : [],
                            exportType: 'xlsx',
                            titleFile: i18n.resultLastOperation,
                        }, true)
                    },
                },
            },
        },
    }
    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 700 }}
        />
    )
}

PcMonitoringOperationGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...getObjectFromAnalysis */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

export default PcMonitoringOperationGraph