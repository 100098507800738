export default class SimDto {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.id
        this.serialNumber = obj.serialNumber
        this.simType = obj.simType
        this.materielType = obj.simType
        this.providerId = obj.providerId
        this.purchaseDate = obj.purchaseDate
        this.enableDate = obj.enableDate
        this.disableDate = obj.disableDate
        this.comment = obj.comment
        this.callNumber = obj.callNumber
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.administrator = obj.administrator
        this.manufacturerId = obj.manufacturerId
        this.explanationDisable = obj.explanationDisable
        this.networkCode = obj.networkCode
        this.loginReferent = obj.loginReferent
        this.isEquipment = obj.isEquipment
    }
}