import User from 'account/dto/User'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { difference, intersection, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { authorizeFilter } from 'utils/HabilitationUtil'
import { hasValue } from 'utils/NumberUtil'
import SelectionTable from '../../../components/datatable/SelectionTable'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import DtoEvent from '../../../events/dto/DtoEvent'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import InstallationAction from '../../../installation/actions/InstallationAction'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoInstallationType from '../../../installation/dto/installation/DtoInstallationType'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import NetworkDto from '../../../referencial/components/network/dto/NetworkDto'
import NetworkLinkDto from '../../../referencial/components/network/dto/NetworkLinkDto'
import { searchAllCharacters } from '../../../utils/StringUtil'
import { formatStationSelectedPopup } from '../../utils/CampaignUtils'


class SelectStation extends Component {
    constructor(props) {
        super(props)
        const stations = formatStationSelectedPopup(props.stations, props.campaignEvents)
        const selectedStations = props.selectedStations.map(({ id }) => id)
        const listStations = difference(stations.map(({ id }) => id), selectedStations)
        this.state = {
            stations,
            selectedStations,
            listStations,
            searchValue: '',
            installationTypeSelected: null,
            townCodeSelected: undefined,
            filter: -1,
            network: null,
            filterResults: formatStationSelectedPopup([], props.campaignEvents),
        }
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(prevProps.stations, this.props.stations) || !isEqual(prevProps.campaignEvents, this.props.campaignEvents)) {
            this.setState({ station: formatStationSelectedPopup(this.props.stations, this.props.campaignEvents) })
        }
    }

    componentDidMount = () => {
        if (!this.props.installationsTypes.length) {
            this.props.fetchInstallationTypes()
        }
    }

    getFilterStations = () => {
        const {
            stations,
            listStations,
            searchValue,
            townCodeSelected,
            filterResults,
            filter,
            network,
            installationTypeSelected,
        } = this.state

        const { stationType } = this.props

        const availableStations = filter !== -1 ? intersection(listStations, filterResults.map(({ id }) => id)) : listStations
        const stationsData = availableStations.map(stationId => stations.find(({ id }) => id === stationId)).filter(s => !!s)

        const searchValueFormat = searchAllCharacters(searchValue)
        const filterNetwork = network ? stationsData.filter(s => this.props.networkLinks.find(n => n.idNetwork === network && s.id === n.idStation)) : stationsData
        const filterType = stationType === 'installation' && hasValue(installationTypeSelected) ? filterNetwork.filter(d => d.installationType === installationTypeSelected) : filterNetwork
        const filterSearchValue = searchValue ? filterType.filter(s => s.labelSearch.includes(searchValueFormat)) : filterType
        return townCodeSelected ? filterSearchValue.filter(p => p.townCode === townCodeSelected) : filterSearchValue
    }

    addAll = list => {
        const {
            selectedStations,
            listStations,
        } = this.state
        const {
            selectedStations: propsSelectedStations,
        } = this.props
        const listId = list.map(({ id }) => id)
        const newStationsCode = [...selectedStations, ...listId]
        const newSelectedStations = list.map(({ id }) => ({ id }))

        const newListStations = difference(listStations, listId)
        this.setState({ selectedStations: newStationsCode, listStations: newListStations })
        this.props.onChangeSelectedStation([...propsSelectedStations, ...newSelectedStations])
    }

    deleteAll = () => {
        const {
            stations,
            selectedStations,
        } = this.state
        const stationsWithFinalizedVisit = selectedStations.filter(ss => {
            const station = stations.find(s => ss === s.id)
            return station && station.visitStatus === 2
        })
        this.setState({
            selectedStations: stationsWithFinalizedVisit,
            listStations: stations.map(({ id }) => id).filter(sId => !stationsWithFinalizedVisit.includes(sId)),
        })
        this.props.onChangeSelectedStation([])
    }

    onAdd = ({ id }) => {
        const {
            selectedStations,
            listStations,
        } = this.state
        const {
            selectedStations: propsSelectedStations,
        } = this.props
        const newStationsCode = [...selectedStations, id]
        const newListStations = listStations.filter(stationId => stationId !== id)
        this.setState({ selectedStations: newStationsCode, listStations: newListStations })
        this.props.onChangeSelectedStation([...propsSelectedStations, { id }])
    }

    onDelete = ({ id }) => {
        const {
            selectedStations,
            listStations,
        } = this.state
        const {
            selectedStations: propsSelectedStations,
            stations,
        } = this.props
        const selectedStation = stations.find(station => station.id === id)
        if (selectedStation.visitStatus === 2) {
            this.props.warning(i18n.visitFinalized)
        } else {
            const newStationsCode = selectedStations.filter(stationId => stationId !== id)
            const newListStations = [...listStations, id]
            this.setState({ selectedStations: newStationsCode, listStations: newListStations })
            this.props.onChangeSelectedStation(propsSelectedStations.filter(({ id: stationId }) => id !== stationId))
        }
    }

    formatStation = ({ id, code = '', name = '', townCode, monitoring, toPlanEvents, installationType, visitStatus, idVisit }) => ({
        name: `${code ? `[${code}] ` : ''}${name}`,
        city: (this.props.cities.find(c => c.id === townCode) || {}).labelWithCode || '',
        nullValue: [
            monitoring !== 0 && <i className={'material-icons'} >notifications</i>,
            toPlanEvents !== 0 && <i className={'material-icons'} >assignment</i>,
        ],
        id,
        installationType,
        visitStatus,
        idVisit,
    })

    render = () => {
        const {
            cities,
            campaignEvents,
            stationType,
            networks,
            installationsTypes,
            accountUser,
        } = this.props
        const {
            searchValue,
            stations,
            selectedStations,
            townCodeSelected,
            network,
            installationTypeSelected,
        } = this.state

        const stationsFormat = this.getFilterStations().map(this.formatStation)

        const selectedStationsFormat = selectedStations.map(stationId => stations.find(({ id }) => id === stationId)).filter(p => !!p).map(this.formatStation)

        return (
            <div style={{ height: '70vh' }}>
                <div className='row no-margin'>
                    <SimpleFilterSelect
                        col={6}
                        onChange={(s, f) => this.setState({
                            filterResults: formatStationSelectedPopup(s, campaignEvents),
                            filter: f,
                        })}
                        stations={this.props.stations}
                        stationType={stationType}
                    />
                    <Input
                        col={6}
                        title={i18n.search}
                        value={searchValue}
                        onChange={v => this.setState({ searchValue: v })}
                    />
                </div>
                <div className='row no-margin padding-bottom-2'>
                    <Select
                        col={6}
                        label={i18n.city}
                        value={townCodeSelected}
                        options={cities}
                        onChange={c => this.setState({ townCodeSelected: c })}
                        keyLabel='labelWithCode'
                    />
                    {
                        stationType === 'quality' && (
                            <Select
                                col={6}
                                label={i18n.network}
                                options={authorizeFilter(networks, accountUser, network)}
                                value={network}
                                onChange={id => this.setState({ network: id })}
                                clearFunction
                            />
                        )
                    }
                    {
                        stationType === 'installation' && (
                            <Select
                                col={6}
                                options={installationsTypes.filter(type => type.display)}
                                value={installationTypeSelected}
                                label={i18n.installationType}
                                keyLabel='name'
                                keyValue='id'
                                integerValue
                                returnNull
                                onChange={v => this.setState({ installationTypeSelected: v })}
                            />
                        )
                    }
                </div>
                <SelectionTable
                    maxHeight='45vh'

                    listData={stationsFormat}
                    listHeaders={['name', 'city', 'nullValue']}
                    listTitle={stationType === 'installation' && i18n.nonSelectedInstallations || i18n.nonSelectedStations}

                    selectedData={selectedStationsFormat}
                    selectedHeaders={['name', 'city', 'nullValue']}
                    selectedTitle={stationType === 'installation' && i18n.selectedInstallations || i18n.selectedStations}

                    onAdd={this.onAdd}
                    addAll={this.addAll}
                    onDelete={this.onDelete}
                    deleteAll={this.deleteAll}
                />
            </div>
        )
    }
}

SelectStation.propTypes = {
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    campaignEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    onChangeSelectedStation: PropTypes.func,
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'quality', 'installation']),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    networkLinks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkLinkDto)),

    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    accountUser: PropTypes.instanceOf(User),
    installationsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationType)),
    fetchInstallationTypes: PropTypes.func,
    warning: PropTypes.func,
    filterResults: PropTypes.arrayOf(PropTypes.number),
}

SelectStation.defaultProps = {
    networkLinks: [],
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    networks: store.NetworkReducer.networks,
    installationsTypes: store.InstallationReducer.installationsTypes,
    accountUser: store.AccountReducer.accountUser,
    filterResults: store.StationReducer.filterResults,
})

const mapDispatchToProps = {
    fetchInstallationTypes: InstallationAction.fetchInstallationTypes,
    warning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectStation)
