import React from 'react'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import PopupExportFile from '../components/modal/PopupExportFile'
import { contentsPath } from '../conf/basepath'
import ExportAction from '../export/actions/ExportAction'
import { setModal } from './FormUtils'

const formatData = (body, exportType) => {
    const header = {}
    const headers = body[0].customHeaders ? body[0].customHeaders : body[0].headers
    body[0].headers.map((e, i) => {
        header[e] = {
            value: (() => {
                try {
                    return i18n[headers[i]]
                } catch (err) {
                    return headers[i]
                }
            })(),
        }
    })

    const data = [header].concat(body).map(line => {
        return body[0].headers.map(key => {
            if (line[key] || line[key] === 0) {
                if (line[key].value || line[key].value === 0) {
                    return {
                        ...line[key],
                        value: typeof line[key].value === 'boolean' && line[key].value ? i18n.yes : (line[key].cellType === 'number' && exportType === 'csv' ? line[key].value.toString().replace('.', ',') : line[key].value.toString()),
                    }
                }
                return { value: typeof line[key] === 'boolean' && line[key] ? i18n.yes : line[key].toString() }
            }
            return { value: '' }
        })
    })
    return body[0].tableTitle ? [[{ value: body[0].tableTitle }], ...data] : data
}

const exportFile = (exportData, useProvidedType = false, exportAdditionalData = {}, useAdditionalData = false, exportAgencyData = {},
    agencyOption = false, researchCriterias = {}, modelsExport = [], industrialSiteExport = {}) => {
    if (exportData.data && exportData.data.length) {
        if (useProvidedType) {
            AppStore.dispatch(ExportAction.export(formatData(exportData.data, exportData.exportType), exportData.exportType, exportData.titleFile))
        } else {
            const onExport = (data) => {
                const dataToExport = exportData.doNotFormat ? data.data : formatData(data.data, data.exportType)
                AppStore.dispatch(ExportAction.export(dataToExport, data.exportType, data.titleFile))
            }

            const exportFunc = (format, additionalDataChecked, code, radioChoiceIndustrial, industrialSite) => {
                if (additionalDataChecked) {
                    return onExport({ ...exportAdditionalData, exportType: format })
                } else if (radioChoiceIndustrial === 1) {
                    return onExport({ ...industrialSite, exportType: format })
                } else if (code === 2) {
                    return onExport({ ...exportAgencyData, exportType: format })
                }
                return onExport({ ...exportData, exportType: format })
            }

            const exportFuncModel = (filenameModelExport) => {
                AppStore.dispatch(ExportAction.exportResearchModel(researchCriterias, filenameModelExport))
            }

            setModal({
                title: i18n.export,
                content: (
                    <PopupExportFile
                        useAdditionalData={useAdditionalData}
                        agencyOption={agencyOption}
                        exportFunc={(format, additionalDataChecked, code, radioChoiceIndustrial, industrialSite) => exportFunc(format, additionalDataChecked, code, radioChoiceIndustrial, industrialSite)}
                        exportFuncModel={(filenameModelExport) => exportFuncModel(filenameModelExport)}
                        modelsExport={modelsExport}
                        industrialSiteExport={industrialSiteExport}
                    />
                ),
            })
        }
    } else {
        AppStore.dispatch(ToastrAction.error(i18n.noData))
    }
}

const exportFileManyTable = (exportData) => {
    if (exportData.data && exportData.data.length) {
        const data = exportData.data.map(t => formatData(t, exportData.exportType))
        const dataExport = data.reduce((acc, table) => [...acc, [], [], ...table]) // space between two tables
        exportFile({
            data: dataExport,
            exportType: exportData.exportType,
            titleFile: exportData.titleFile,
            doNotFormat: true,
        })
        // AppStore.dispatch(ExportAction.export(dataExport, exportData.exportType, exportData.titleFile))
    } else {
        AppStore.dispatch(ToastrAction.error(i18n.noData))
    }
}

const exportZip = (exportData, titleFile) => {
    if (exportData && exportData.length) {
        const formattedExportData = exportData.map(e => ({ ...e, data: e.data.length > 0 ? formatData(e.data, exportData.exportType) : [] }))
        AppStore.dispatch(ExportAction.zipExport(formattedExportData, titleFile))
    } else {
        AppStore.dispatch(ToastrAction.error(i18n.noData))
    }
}

const exportModelFile = (exportModelObj) => {
    AppStore.dispatch(ExportAction.exportModel(exportModelObj))
}


const exportAEPFile = ({ exportModelObj }) => {
    AppStore.dispatch(ExportAction.exportAEP(exportModelObj))
}

const downloadURI = (uri) => {
    const link = document.createElement('a')
    link.href = contentsPath + uri
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const exportJasper = (stationId, stationType, modelFileName) => {
    ExportAction.exportJasper(stationId, stationType, modelFileName).then(res => {
        if (res.targetPath) {
            downloadURI(res.targetPath)
        } else {
            AppStore.dispatch(ToastrAction.error(res.message))
            if (res.message.includes('_')) {
                AppStore.dispatch(ToastrAction.error('Attention a ne pas mettre d\'espaces dans le nom du fichier zip.'))
            }
        }
    })
}
const getModelFileType = (ext) => {
    switch (ext) {
        case 'xlsx':
        case 'xls':
        case 'xlsm':
            return i18n.excelFile
        case 'doc':
        case 'docx':
            return i18n.wordFile
        default:
            return i18n.unknownType
    }
}

const getDataWithHeaders = (list, headers) => list.length ? [{ ...list[0], headers }, ...list.slice(1)] : []

export { formatData, exportFile, exportFileManyTable, exportModelFile, exportAEPFile, downloadURI, getModelFileType, exportZip, getDataWithHeaders, exportJasper }
