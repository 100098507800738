import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import Row from '../../../../components/react/Row'
import ImageCard from '../../../../components/card/ImageCard'
import { MODEL_STATUS, MODEL_TYPES } from '../../../../iaeau/constants/IAEauConstants'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import { Grid } from '@mui/material'
import ToastrAction from '../../../../toastr/actions/ToastrAction'


const ModelStepModel = ({
    stationType,
    id, // station id
    selectedModel,
    changeModel,
}) => {
    const dispatch = useDispatch()

    const {

    } = useSelector(store => ({

    }), shallowEqual)

    // const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {

    }, [])

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h5>Choisissez votre modèle.</h5>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <ImageCard
                        img='pictures/iaeauModels/RN.png'
                        alt='Perceptron Multi Couches'
                        title='Perceptron Multi Couches'
                        description={'Le Perceptron Multi Couches est un modèle d\'intelligence artificielle de type "réseau de neurones"'}
                        maxWidth={345}
                        imgHeight={180}
                        active={selectedModel.typeModel === MODEL_TYPES.PERCEPTRON}
                        onClick={() => changeModel({ typeModel: MODEL_TYPES.PERCEPTRON, statusCode: MODEL_STATUS.DEMAND_SENT })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        img='pictures/iaeauModels/arima.png'
                        alt='ARIMA'
                        title='ARIMA'
                        description={'Le modèle ARIMA est un modèle de prévision basé sur les caractéristiques statistique d\'une série de donnée'}
                        maxWidth={345}
                        imgHeight={180}
                        active={selectedModel.typeModel === MODEL_TYPES.ARIMA}
                        onClick={() => changeModel({ typeModel: MODEL_TYPES.ARIMA, statusCode: MODEL_STATUS.DEMAND_SENT })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        img='pictures/iaeauModels/augures.png'
                        alt='AUGURES'
                        title={ 'Courbes d\'augures' }
                        description={'Les courbes d\'augures sont un modèle de prévision statistique basé sur les résultats des années passées'}
                        maxWidth={345}
                        imgHeight={180}
                        active={selectedModel.typeModel === MODEL_TYPES.AUGURES}
                        onClick={() => {
                            if (selectedModel.horizon > 170) {
                                dispatch(ToastrAction.warning('L\'horizon maximum des courbes d\'augures est de 170 jours.'))
                            } else {
                                changeModel({ typeModel: MODEL_TYPES.AUGURES, statusCode: MODEL_STATUS.DEMAND_SENT })
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        img='pictures/iaeauModels/etiage.jpg'
                        alt='TARRISSEMENT'
                        title={ 'Tarrisement' }
                        description={'Modèle de tarissement logarithmique proposé par le BRGM pour simuler un tarissement en l\'absence de recharge.'}
                        maxWidth={345}
                        imgHeight={180}
                        active={selectedModel.typeModel === MODEL_TYPES.TARRI_BRGM}
                        onClick={() => changeModel({ typeModel: MODEL_TYPES.TARRI_BRGM, statusCode: MODEL_STATUS.DEMAND_SENT })}
                    />
                </Grid>
                {
                    selectedModel.meteoNappesExists && (
                        <Grid item xs={4}>
                            <ImageCard
                                img='pictures/iaeauModels/meteonappes.png'
                                alt='Météo Nappes'
                                title='Météo Nappes'
                                description={'Le BRGM a développé le site internet MétéEAU Nappes qui permet, pour des points de surveillance associés à un modèle hydrologique global, la visualisation en temps réel des données issues des mesures effectuées sur le réseau piézométrique national.\n' +
                                    'Ces données sont mises à disposition sous forme de cartes et de courbes dynamiques basées sur des travaux de modélisation et de prévision des niveaux des nappes en basses et hautes eaux.'}
                                maxWidth={345}
                                imgHeight={180}
                                active={selectedModel.typeModel === MODEL_TYPES.METEO_NAPPES}
                                onClick={() => changeModel({ typeModel: MODEL_TYPES.METEO_NAPPES, statusCode: MODEL_STATUS.DEMAND_SENT })}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </div>
    )
}

ModelStepModel.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
}

export default ModelStepModel