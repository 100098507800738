import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ActionComponent from '../../../components/ActionComponent'
import i18n from 'simple-react-i18n'
import HomeAction from '../../../home/actions/HomeAction'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'
import Select from '../../../components/forms/Select'
import Card from '../../../components/card/Card'
import ExportAction from '../../../export/actions/ExportAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { getDateExport, getFullDate, shortenHumanize } from '../../../utils/DateUtil'
import PiezometryAction from '../../actions/PiezometryAction'
import DtoPiezometerChartLandmarks from '../../dto/situation/DtoPiezometerChartLandmarks'
import { getHardPiezoDataTypes } from '../../../utils/PiezometryUtils'
import {
    CSV_EXPORT,
    EXCEL_EXPORT,
    EXPORT_JOB_STATUS,
    EXPORT_STATIONS_MEASURES,
    EXPORT_STATIONS_MODELS,
    EXPORT_TYPE_OPTIONS,
    MODEL_EXPORT,
} from '../../../export/constants/ExportConstants'
import StationsModelExportPanel from '../../../station/components/StationsModelExportPanel'
import StationsCSVExportPanel from '../../../station/components/StationsCSVExportPanel'
import StationsExcelExportPanel from '../../../station/components/StationsExcelExportPanel'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'
import Table from '../../../components/datatable/Table'
import StationAction from '../../../station/actions/StationAction'
import DtoExportJob from '../../../station/dto/DtoExportJob'
import { intersectionWith, isUndefined, keyBy, orderBy, pick, uniqBy } from 'lodash'
import { getLogin } from '../../../utils/SettingUtils'
import { getCurrentLang } from '../../../utils/LangUtils'
import { nbPerPageLabelTiny } from '../../../referencial/constants/ReferencialConstants'
import { getDuration, getLoadingBar, getStatusIcon, getStatusLabel } from '../../../utils/ExportJobUtils'
import moment from 'moment'
import { nFormatter } from '../../../utils/NumberUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_PIEZO_EXPORT } from '../../../account/constants/AccessRulesConstants'
import { push } from 'connected-react-router'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import { PiezometerFilterField } from 'components/datatable/SimpleSelectionTable'
import { searchAllCharacters } from 'utils/StringUtil'
import CityAction from 'referencial/components/city/actions/CityAction'
import CityDto from 'referencial/components/city/dto/CityDto'


class PiezometersExportApp extends ActionComponent {
    isCheckingProgress = false

    state = {
        exportType: null,
        model: 0,
        modelStartDate: null,
        modelEndDate: null,
        displayCote: 2,
        tmpFilter: {
            startDate: null,
            endDate: null,
        },
        selectStationIsOpen: false,
        selectedStations: [],
        dataType: 0,
    }

    setTitle = () => {
        this.props.setTitle([{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: i18n.export,
            href: 'piezometry',
        }])
    }

    recursiveFetchExportJob = () => {
        if (!this.isCheckingProgress) {
            return
        }
        this.props.fetchExportJobsTmp('piezometry').then(response => {
            if (response.some(e => e.status === EXPORT_JOB_STATUS.WAITING || e.status === EXPORT_JOB_STATUS.IN_PROGRESS)) {
                setTimeout(() => this.recursiveFetchExportJob(), 5000)
            } else {
                this.isCheckingProgress = false
            }
        })
    }

    regularProgressUpdate = () => {
        this.isCheckingProgress = true
        this.recursiveFetchExportJob()
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PIEZO_EXPORT)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.setTitle()
        this.props.fetchEnvironmentModelsByType('piezometry')
        if (!this.props.piezometers.length) {
            this.props.fetchPiezometersLight()
        }
        if (!this.props.cities.length) {
            this.props.fetchCities()
        }
        this.props.fetchPiezometryDataTypes()
        this.regularProgressUpdate()
    }

    componentWillUnmount() {
        this.isCheckingProgress = false
    }

    onChangeFilter = (value) => {
        this.setState(({ tmpFilter }) => ({ tmpFilter: { ...tmpFilter, ...value } }))
    }

    onChange = (value) => {
        this.setState(value)
    }

    onCloseModal = () => {
        this.setState({ selectStationIsOpen: false })
    }

    onApplyExport = (type) => {
        const { tmpFilter, dataType, selectedStations } = this.state
        const { waitStart, waitStop } = this.props
        waitStart()
        const availableDataTypes = uniqBy([ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes], 'id')
        const selectedDataTypes = availableDataTypes.filter(d => !dataType || d.id === dataType)
        const exportData = {
            exportData: selectedStations.map(id => {
                const piezo = this.props.piezometers.find(p => p.id === id)
                return {
                    station: pick({ ...piezo, stationType: piezo.typeName }, ['id', 'code', 'name', 'stationType']),
                    exportType: type,
                    filename: `${piezo.code}_${getDateExport()}`,
                }
            }),
            startDate: tmpFilter.startDate,
            endDate: tmpFilter.endDate,
            dataTypes: selectedDataTypes.map(({ id, label }) => ({ id, name: label })),
            displayCote: this.state.displayCote,
        }
        if (exportData.exportData.length === 0) {
            this.props.toastrWarning(i18n.noDataOverSelectedStationsAndPeriod)
            waitStop()
            return
        }
        const exportTmpObj = {
            id: 0,
            exportType: EXPORT_STATIONS_MEASURES,
            login: getLogin(),
            creationDate: 0,
            status: EXPORT_JOB_STATUS.WAITING,
            stationType: 'piezometry',
            params: JSON.stringify(exportData),
        }
        this.props.runExportTmp(exportTmpObj).then(() => {
            if (!this.isCheckingProgress) {
                this.regularProgressUpdate()
            }
        })
        waitStop()
    }
    onApplyExportModel = (modelFileName) => {
        const { selectedStations } = this.state
        const { waitStart, waitStop } = this.props
        const fileNameSplit = modelFileName.split('.')
        const type = fileNameSplit[fileNameSplit.length - 1]
        waitStart()
        const exportData = {
            exportData: selectedStations.map(id => {
                const piezo = this.props.piezometers.find(p => p.id === id)
                return {
                    station: pick({ ...piezo, stationType: piezo.typeName }, ['id', 'code', 'name', 'stationType']),
                    exportType: type,
                    filename: `${piezo.code}_${getDateExport()}`,
                }
            }),
            model: modelFileName,
            startDate: this.state.modelStartDate,
            endDate: this.state.modelEndDate,
            displayCote: this.state.displayCote,
        }

        if (exportData.exportData.length === 0) {
            this.props.toastrWarning(i18n.noDataOverSelectedStationsAndModel)
            waitStop()
            return
        }
        const exportTmpObj = {
            id: 0,
            exportType: EXPORT_STATIONS_MODELS,
            login: getLogin(),
            creationDate: 0,
            status: EXPORT_JOB_STATUS.WAITING,
            stationType: 'piezometry',
            params: JSON.stringify(exportData),
        }
        this.props.runExportTmp(exportTmpObj).then(() => {
            if (!this.isCheckingProgress) {
                this.regularProgressUpdate()
            }
        })
        waitStop()
    }
    getExportPanel = () => {
        const { exportType, tmpFilter, selectedStations, model, modelStartDate, modelEndDate, displayCote } = this.state
        const { typeEnvironmentModels } = this.props
        const modelTypesOptions = typeEnvironmentModels.filter(t => !t.includes('.xls') || !t.includes('.doc')).map((t, i) => ({ value: i, name: t }))
        switch (exportType) {
            case MODEL_EXPORT:
                return (
                    <StationsModelExportPanel
                        onApplyExportModel = {this.onApplyExportModel}
                        selectedStations={selectedStations}
                        model={model}
                        modelTypesOptions={modelTypesOptions}
                        onChange={this.onChange}
                        startDate={modelStartDate}
                        endDate={modelEndDate}
                        displayCote={displayCote}
                        stationType='piezometry'
                    />
                )
            case CSV_EXPORT:
                return (
                    <StationsCSVExportPanel
                        onChangeFilter={this.onChangeFilter}
                        onChange={this.onChange}
                        onApplyExport={this.onApplyExport}
                        selectedStations={selectedStations}
                        tmpFilter={tmpFilter}
                        displayCote={displayCote}
                        dataTypes={uniqBy([...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes], 'id')}
                        selectedDataType={this.state.dataType}
                    />
                )
            case EXCEL_EXPORT:
                return (
                    <StationsExcelExportPanel
                        onChangeFilter={this.onChangeFilter}
                        onChange={this.onChange}
                        onApplyExport={this.onApplyExport}
                        selectedStations={selectedStations}
                        tmpFilter={tmpFilter}
                        displayCote={displayCote}
                        dataTypes={uniqBy([...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes], 'id')}
                        selectedDataType={this.state.dataType}
                    />
                )
            default:
                return null
        }
    }

    render() {
        const data = orderBy(this.props.exportJobsTmp, 'updateDate', 'desc').map(e => ({
            ...e,
            status: { color: 'white', value: getStatusIcon(e.status, 50), setTooltip: () => (<div className='row no-margin valign-wrapper'>{getStatusIcon(e.status, 20)}<div className='padding-left-1'/>{getStatusLabel(e.status)}</div>) },
            type: { value: e.fileType },
            creationDate: { value: getFullDate(e.creationDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            updateDate: { value: getFullDate(e.updateDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            author: { value: e.login },
            exportName: { value: i18n[e.exportType] },
            fileLabel: { value: e.filePath },
            progression: { value: getLoadingBar(e.status, e.progress, e.filePath) },
            size: { value: nFormatter(e.fileSize) },
            duration: { value: e.status === EXPORT_JOB_STATUS.FINISHED || e.status === EXPORT_JOB_STATUS.ERROR ? shortenHumanize((moment.utc(moment(e.updateDate).diff(moment(e.creationDate))).valueOf() || 0), { language: getCurrentLang()[0] }) : getDuration(e.creationDate) },
        }))
        const groupedCities = keyBy(this.props.cities, 'code')
        const formattedPiezometers = this.props.piezometers.map(piezo => ({
            id: piezo.id,
            code: piezo.code,
            name: piezo.name,
            city: groupedCities[piezo.townCode]?.labelWithCode ?? '',
            townCode: piezo.townCode,
            labelSearch: searchAllCharacters(`${piezo.code}#${piezo.name}`),
        }))
        return (
            <div className='row no-margin padding-1'>
                <Card title={ i18n.export }>
                    <div className='card-content'>
                        <div className='row valign-wrapper padding-top-1 no-margin'>
                            <div className='col s12'>
                                <Select
                                    col={3}
                                    label={i18n.exportName}
                                    options={EXPORT_TYPE_OPTIONS}
                                    onChange={v => this.setState({ exportType: v })}
                                    value={this.state.exportType}
                                />
                            </div>
                        </div>
                        { this.getExportPanel() }

                        <SelectionTableModal
                            isOpen={this.state.selectStationIsOpen}
                            onClose={this.onCloseModal}
                            onValidate={list => {
                                this.setState({ selectedStations: list, selectStationIsOpen: false })
                                this.onCloseModal()
                            }}
                            title={i18n.listSumPesticidesSelection}

                            listData={formattedPiezometers}
                            defaultSelectionList={this.state.selectedStations}

                            listHeaders={['code', 'name', 'city']}
                            listTitle={i18n.nonSelectedStations}
                            selectionListTitle={i18n.selectedStations}
                            maxHeightTable={'53vh'}

                            filterField={PiezometerFilterField}
                            filterFunction={(list, filter) => {
                                const {
                                    filter: filterId = -1,
                                    resultFilter,
                                    searchValue,
                                    city,
                                } = filter

                                const filterCity = !isUndefined(city) ? list.filter(s => s.townCode === city) : list
                                const filterFilter = filterId !== -1 ? intersectionWith(filterCity, resultFilter, (elem, res) => elem.id === res.id) : filterCity
                                const searchValueFormat = searchAllCharacters(searchValue)
                                return searchValue ? filterFilter.filter(p => p.labelSearch.includes(searchValueFormat)) : filterFilter
                            }}
                        />
                    </div>
                </Card>
                <Table
                    title={ i18n.exportsInProgress }
                    data={data}
                    type={ { headers: ['status', 'fileLabel', 'duration', 'size', 'creationDate', 'updateDate', 'exportName', 'progression'] } }
                    color
                    sortable
                    paging
                    nbPerPageLabel={ nbPerPageLabelTiny }
                />
            </div>
        )
    }
}

PiezometersExportApp.propTypes = ({
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    setTitle: PropTypes.func,
    fetchEnvironmentModelsByType: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    exportModel: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    toastrWarning: PropTypes.func,
    fetchPiezometerChartLandmarks: PropTypes.func,
    piezometerChartLandmarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerChartLandmarks)),
    fetchPiezometryDataTypes: PropTypes.func,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    exportJobsTmp: PropTypes.arrayOf(PropTypes.instanceOf(DtoExportJob)),
    fetchExportJobsTmp: PropTypes.func,
    runExportTmp: PropTypes.func,
    push: PropTypes.func,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
})

const mapStateToProps = store => ({
    piezometers: store.PiezometryReducer.piezometersLight,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    piezometerChartLandmarks: store.PiezometryReducer.piezometerChartLandmarks,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    exportJobsTmp: store.StationReducer.exportJobsTmp,
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchEnvironmentModelsByType: ExportAction.fetchEnvironmentModelsByType,
    exportModel: ExportAction.exportModel,
    toastrWarning: ToastrAction.warning,
    fetchPiezometerChartLandmarks: PiezometryAction.fetchPiezometerChartLandmarks,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    fetchExportJobsTmp: StationAction.fetchExportJobsTmp,
    runExportTmp: ExportAction.runExportTmp,
    fetchPiezometersLight: PiezometryAction.fetchPiezometersLight,
    fetchCities: CityAction.fetchCities,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometersExportApp)
