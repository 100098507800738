import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../../components/forms/Checkbox'
import Job from '../../../dto/DtoJob'
import NumberField from '../../../../components/forms/NumberField'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import DtoContributor from '../../../../station/dto/DtoContributor'
import { arrayOf } from '../../../../utils/StoreUtils'

class MaterielAccounting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: props.job.parameters.filters.length ? JSON.parse(props.job.parameters.filters[0]) : {},
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.state.filter,
                ...changes,
            })],
        }
        this.setState({ filter: { ...this.state.filter, ...changes } })
        this.props.onChangeJob({ parameters })
    }


    onImportFile = (e) => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('base64') > 0) {
                    this.onChangeFilters({ file: split[1] })
                } else {
                    this.props.toastrError(i18n.theSelectedFileMustBeInCsvFormat)
                }
            }
            reader.readAsDataURL(file)
        }
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return newFilters
    }


    render() {
        const {
            rename,
            dataIntegration,
        } = this.state.filter
        const disabled = !this.props.isEditMode
        const filters = this.getFilters()
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <Checkbox col={ 2 } label={ i18n.hasHeader } onChange={ v => this.onChangeFilters({ hasHeader: v }) } disabled={ disabled } checked={ filters.hasHeader } />
                    <NumberField col={ 2 } title={ i18n.nbLine } value={ filters.nbHeaders ? filters.nbHeaders : 6 } onChange={ v => this.onChangeFilters({ nbHeaders: v }) } disabled={ disabled } floatValue />

                    <div className='col s7' />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <MultiContributorsAutocomplete label={ 'D.R.'} col={ 4 } values={ filters.validator } onChange={ v => this.onChangeFilters({ validator: v }) } options={ this.props.contributors } disabled={ disabled }/>
                    <div className='col s7' />
                </div>
                <div className='row no-margin valign-wrapper padding-top-1'>
                    <Checkbox
                        checked={!!rename}
                        col={ 2 }
                        label={i18n.fileRenaming}
                        onChange={v => this.onChangeFilters({ rename: v })}
                        disabled={ disabled }
                    />

                    <Checkbox
                        checked={!!dataIntegration}
                        col={ 4 }
                        label={ i18n.noDataIntegrationIfError }
                        onChange={v => this.onChangeFilters({ dataIntegration: v })}
                        disabled={ disabled }
                    />
                    <div className='col s5' />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.importFile}</label>
                    </div>
                    <div className='file-field col s9 no-padding'>
                        <div className='input-field col s3'>
                            <a className='waves-effect waves-light btn col s12'>
                                {i18n.importLabel}
                                <input type='file'
                                    disabled={!this.props.isEditMode}
                                    accept='.xlsx, .XLSX'
                                    onChange={ this.onImportFile }
                                />
                            </a>
                        </div>
                        <div className='input-field col s9 file-path-wrapper'>
                            <input
                                className='file-path validate'
                                type='text'
                                disabled
                                placeholder={i18n.selectFile}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MaterielAccounting.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    contributors: arrayOf(DtoContributor),
    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
})

export default connect(mapStateToProps, mapDispatchToProps)(MaterielAccounting)
