import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { getFullDate } from '../../../../../utils/DateUtil'
import { getArrowDown, getArrowRight, getArrowUp } from '../../../../../utils/mapUtils/Markers'

const ThresholdPreviCard = ({
    date,
    upperRightTxt,
    text,
    color,
    trend,
    smallCard = false,
    containerStyle = {},
}) => (
    <Grid
        container
        item xs={12}
        className={`white ${smallCard ? 'border-bottom-light' : ''}`}
        sx={!smallCard ? {
            width: '80%',
            margin: 'auto',
            marginTop: 0,
            marginBottom: 10,
            borderRadius: '2px',
            boxShadow: '0px 5px 8px rgb(0 0 0 / 8%) !important',
            transition: 'transform 0.25s ease-in-out !important',
            '&:hover': {
                transform: 'scale(1.05)',
            },
            ...containerStyle,
        } : containerStyle}
    >
        <>
            <Grid container item xs={2} alignItems='center'>
                <Grid container item xs={12} justifyContent='center'>
                    <Grid item>
                        {
                            (() => {
                                switch (trend) {
                                    case 'up': return getArrowUp(color ?? 'gray', true, 25)
                                    case 'down': return getArrowDown(color ?? 'gray', true, 25)
                                    default: return getArrowRight(color ?? 'gray', true, 25)
                                }
                            })()
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={10} className={`white ${smallCard ? 'padding-left-2' : ''}`} sx={{ padding: `${!smallCard ? '0 0.75rem' : ''}` }}>
                <Grid item xs={7}>
                    {!smallCard && (
                        <h6 className='clickable bold' style={{ marginTop: '1rem', fontSize: 12 }} >{getFullDate(date)}</h6>
                    )}
                    {smallCard && (
                        <b className='bold'>{getFullDate(date)}</b>
                    )}
                </Grid>
                <Grid item xs={5}>
                    {!smallCard && (
                        <h6 className='clickable bold' style={{ marginTop: '1rem', fontSize: 10 }} >{upperRightTxt}</h6>
                    )}
                    {smallCard && (
                        <b className='bold'>{upperRightTxt}</b>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {!smallCard && (
                        <h6 className='clickable' style={{ marginBottom: '1rem' }} >{text}</h6>
                    )}
                    {smallCard && (
                        <span>{text}</span>
                    )}
                </Grid>
            </Grid>
        </>
    </Grid>
)

ThresholdPreviCard.propTypes = {
    date: PropTypes.number,
    upperRightTxt: PropTypes.string,
    text: PropTypes.string,
    smallCard: PropTypes.bool,
    containerStyle: PropTypes.shape({}),
    color: PropTypes.string,
    trend: PropTypes.string,
}

export default ThresholdPreviCard
