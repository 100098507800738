import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getExport } from 'utils/linkUtils'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'
import DtoDistributionUnit from 'distributionUnit/dto/DtoDistributionUnit'

const headers = ['network', 'code', 'name']

const DistributionUnitNetworksPanel = ({
    station = [],
}) => {
    const distriNetworks = station.link_networks.map((c, index) => ({
        ...c,
        index,
    }))

    const tableData = distriNetworks.map((dn) => {
        return {
            ...dn,
            network: dn.idNetwork,
        }
    })

    const actions = [getExport(tableData, i18n.networks, headers)]

    return (
        <>
            <Table
                title={i18n.networks}
                condensed
                data={tableData}
                type={{ headers }}
                sortable={!!tableData.length}
                actions={actions}
                round paging
                nbPerPageLabel={nbPerPageLabel}
            />
        </>
    )
}

DistributionUnitNetworksPanel.propTypes = {
    station: PropTypes.instanceOf(DtoDistributionUnit),
}

export default DistributionUnitNetworksPanel