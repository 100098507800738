import moment from 'moment'
import React, { Component } from 'react'
import { max, minBy, orderBy, range } from 'lodash'
import { getYear } from '../../../utils/DateUtil'
import Select from '../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import { i18nize } from '../../../utils/StringUtil'
import { getPiezometrySeriesParams } from '../../../utils/PiezometryUtils'
import PiezometerSuiviTable from './PiezometerSuiviTable'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import PropTypes from 'prop-types'
import DtoPiezometryStationMeasure from '../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezometerAdditionalMeasures from '../../../station/dto/piezometer/DtoPiezometerAdditionalMeasures'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'
import { connect } from 'react-redux'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_STATION_PIEZO_FOLLOW_UP } from '../../../account/constants/AccessRulesConstants'
import { hasValue } from '../../../utils/NumberUtil'
import AppStore from '../../../store/AppStore'
import SieauAction from '../../../components/sieau/SieauAction'
import { getStationTitle } from '../../../utils/StationUtils'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import { push } from 'connected-react-router'
import { MEASURE_COTE } from '../../constants/PiezometryConstants'
import DtoPiezoMeasureLight from '../../dto/chart/DtoPiezoMeasureLight'

moment.locale('fr')

const propsToFetch = {
    piezometer: false,
}

class PiezometerSuiviTableApp extends Component {
    constructor(props) {
        super(props)
        const chronicType = 'measures'
        this.state = {
            filter: {
                horizon: 'year',
                state: null,
                qualification: null,
                year: moment().year(),
                chronicType,
            },
            tmpFilter: {
                horizon: 'year',
                state: null,
                qualification: null,
                year: moment().year(),
                chronicType,
            },
            measures: [],
        }
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_STATION_PIEZO_FOLLOW_UP)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (hasValue(this.props.piezometer.id)) {
            this.fetchData(this.props.piezometer.id)
            this.props.fetchPiezoMeasuresStats(this.props.piezometer.id)
        }
        if (this.props.piezometer.id) {
            this.setTitle(this.props.piezometer)
        }
    }

    setTitle = (station) => {
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: getStationTitle(station),
            href: `station/piezometry/${station.id}`,
        }, {
            title: i18n.followUpTable,
            href: `station/piezometry/${station.id}/piezoSuiviTable`,
        }]))
    }

    fetchData = (piezometerId) => {
        AppStore.dispatch(PiezometerStationAction.loadPiezometerAdditionalMeasures(piezometerId))
        AppStore.dispatch(PiezometerStationAction.fetchPiezometerSamples(piezometerId))
        PiezometerStationAction.promisePiezoChartMeasures({ dataType: -1, displayCote: MEASURE_COTE.NGF, groupFunc: 'MAX', stationId: piezometerId }).then(measures => this.setState({ measures: measures.map(m => new DtoPiezoMeasureLight(m)) }))
        // this.props.fetchMeasures(piezometerId).then(() => this.setState({ dataLoaded: true }))
    }

    onChangeFilter = (filterName, value) => {
        this.setState({ tmpFilter: { ...this.state.tmpFilter, [filterName]: value || null } })
    }

    onValidate = () => {
        this.setState({ filter: { ...this.state.tmpFilter } })
    }

    getRadio = (name, id, checked) => {
        return (
            <div className='col s12 no-padding'>
                <input name='horizon' type='radio' id={ id } onChange={ e => this.onChangeFilter('horizon', e.target.id) }
                    checked={ checked }
                />
                <label htmlFor={ id }>{ name }</label>
            </div>
        )
    }

    getYearsSelectComponent() {
        const { tmpFilter, measures } = this.state
        if (tmpFilter.horizon === 'year') {
            const minMeasure = minBy(measures, 'date')
            if (minMeasure) {
                const minYear = getYear(minMeasure.date)
                const years = range(parseInt(minYear), moment().year() + 1).map(y => ({ code: y, name: y })).reverse()
                return (
                    <div className='col s5'>
                        <Select options={ years } noSort onChange={ e => this.onChangeFilter('year', e) } label={ i18n.year } value={ tmpFilter.year } />
                    </div>
                )
            }
            return (
                <div className='col s5'>
                    <Select label={ i18n.year } disabled/>
                </div>
            )
        }
        return (
            <div className='col s5'>
                <Select label={ i18n.year } disabled/>
            </div>
        )
    }

    getCriteriaPanel = (chronicTypeOptions) => {
        const { tmpFilter } = this.state
        return (
            <div className='row no-margin'>
                <div className='card'>
                    <div className='card-content'>
                        <div className='row no-margin'>
                            <div className='col s4 padding-top-10-px'>
                                <Select
                                    options={ chronicTypeOptions }
                                    noSort
                                    label={ i18n.chronicType }
                                    onChange={ e => this.onChangeFilter('chronicType', e) }
                                    value={ tmpFilter.chronicType }
                                />
                            </div>
                            <div className='col s2 padding-top-10-px'>
                                <Select options={ getStatusSelectOptions() } label={ i18n.status } nullLabel='&nbsp;'
                                    onChange={ e => this.onChangeFilter('state', e) } value={ tmpFilter.state }
                                />
                            </div>
                            <div className='col s2 padding-top-10-px'>
                                <Select options={ getQualificationSelectOptions() } label={ i18n.qualification } nullLabel='&nbsp;'
                                    onChange={ e => this.onChangeFilter('qualification', e) } value={ tmpFilter.qualification }
                                />
                            </div>
                            <div className='col s2 no-margin'>
                                { i18n.horizon }<br/>
                                <form className='row no-margin'>
                                    <div className='col s12 no-padding valign-wrapper'>
                                        <div className='no-padding col s7'>{ this.getRadio(i18n.perYear, 'year', tmpFilter.horizon === 'year') }</div>
                                        { this.getYearsSelectComponent() }
                                    </div>
                                    <div className='no-padding col s12'>{ this.getRadio(i18n.perHistoric, 'historic', tmpFilter.horizon === 'historic') }</div>
                                </form>
                            </div>
                            <div className='col s2 no-margin padding-top-10-px'>
                                <a className='col s12 btn' onClick={ this.onValidate }>
                                    { i18n.search }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { filter, measures } = this.state
        const { piezometer, piezometerAdditionalMeasures, piezometerStatistics } = this.props
        const additionnalSeries = piezometerAdditionalMeasures.reduce((acc, typeObj) => {
            const dataType = piezometerStatistics.find(t => t.typeId == typeObj.type)
            if (dataType) {
                return [
                    ...acc,
                    {
                        code: `additional${typeObj.type}`,
                        label: dataType.label,
                        dateKey: 'date',
                        hourKey: 'hour',
                        groupFunc: max,
                        type: typeObj.type,
                        order: dataType.order,
                    },
                ]
            }
            return acc
        }, [])
        const chronicTypeOptions = orderBy(i18nize(getPiezometrySeriesParams()).concat(additionnalSeries), 'order')

        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s12 m12'>
                        { this.getCriteriaPanel(chronicTypeOptions) }
                    </div>
                </div>
                <div className='row padding-bottom-10'>
                    <div className='col s12 m12'>
                        <PiezometerSuiviTable
                            piezometer = { piezometer }
                            filter={ filter }
                            historic={ filter.horizon === 'historic' }
                            measures={ measures }
                            chronicTypes={ chronicTypeOptions }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

PiezometerSuiviTableApp.propTypes = getPropTypes(propsToFetch, {
    piezometerAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerAdditionalMeasures)),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    piezometerMeasureBrute: arrayOf(DtoPiezometryStationMeasure),
    fetchPiezoMeasuresStats: PropTypes.func,
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    piezometerAdditionalMeasures: store.PiezometerStationReducer.piezometerAdditionalMeasures,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometerMeasureBrute: store.PiezometerStationReducer.piezometerMeasureBrute,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,

})

const mapDispatchToProps = {
    fetchPiezoMeasuresStats: PiezometerStationAction.fetchPiezoMeasuresStats,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometerSuiviTableApp)
