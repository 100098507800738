module.exports = {
    MODELS_TYPES: [
        { code: 0, name: 'Modèle installation', stationType: 'installation' },
        { code: 1, name: 'Fiches piézométrique', stationType: 'piezometry' },
        { code: 2, name: 'Bilans piézométrique', stationType: 'piezometryResearch' },
        { code: 3, name: 'Fiches qualité', stationType: 'quality' },
        { code: 4, name: 'Bilans qualité', stationType: 'qualityResearch' },
        { code: 5, name: 'Modèles mail', stationType: 'mail' },
        { code: 6, name: 'XSD', stationType: 'XSD' },
    ],
}