import { groupBy, maxBy, omit, result } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import MessageCard from '../../components/card/MessageCard'
import Table from '../../components/datatable/Table'
import PanelList from '../../components/list/panelList/PanelList'
import ProgressBar from '../../components/progress/ProgressBar'
import SliderPanel from '../../components/slider/SliderPanel'
import DtoQualitometerLight from '../../quality/dto/DtoQualitometerLight'
import DtoQualityIndicators from '../../quality/dto/QualityIndicator/DtoQualityIndicators'
import DtoQualityIndicatorsResults from '../../quality/dto/QualityIndicator/DtoQualityIndicatorsResults'
import DtoQualityThresholds from '../../quality/dto/QualityThreshold/DtoQualityThresholds'
import ParameterDto from '../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../referencial/components/unit/dto/UnitDto'
import { STATION_QUALITOMETER_NAMES } from '../../station/constants/StationConstants'
import DtoStationAssociation from '../../station/dto/DtoStationAssociation'
import AppStore from '../../store/AppStore'
import { calculateThresholdResult, getSliderPanelIndiceValue } from '../../utils/AnalyseUtils'
import { getMiniDate } from '../../utils/DateUtil'
import { exportFileManyTable } from '../../utils/ExportDataUtil'
import { hasValue } from '../../utils/NumberUtil'
import { arrayOf, getLabel } from '../../utils/StoreUtils'
import ProductionUnitAction from '../actions/ProductionUnitAction'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import Icon from 'components/icon/Icon'
import { Grid } from '@mui/material'

class UnitQualityPanel extends Component {
    constructor(props) {
        super(props)
        this.state = { dataLoaded: false, progress: 0 }
    }

    loadData = (qualitometers) => {
        AppStore.dispatch(ProductionUnitAction.loadProductionUnitQualityAssociations(this.props.id, this.props.associations, qualitometers, obj => this.setState(obj)))
    }

    componentWillMount() {
        if (this.props.qualitometers.length) {
            this.loadData(this.props.qualitometers)
        }
        this.props.setDataLoaded()
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.qualitometers.length && nextProps.qualitometers.length) {
            this.loadData(nextProps.qualitometers)
        }
    }

    expandAll = () => {
        $('.collapsible-header').map(index => {
            const elem = $($('.collapsible-header')[index])
            if (!elem.hasClass('active')) {
                $('.sieau-collapsible').collapsible('open', index)
            }
        })
    }

    getPanel = () => {
        const typeGroup = groupBy(this.props.associations.filter(ass => ass.stationLinkedType === 3), a => {
            const found = this.props.qualitometers.find(q => q.code === a.stationLinkedCode)
            return found ? found.stationType : 0
        })
        const tables = Object.keys(typeGroup).map(key => {
            const indicator = this.props.qualityIndicators.find(q => q.stationType == key)
            if (indicator) {
                const stations = typeGroup[key].map(ass => this.props.qualitometers.find(q => q.code === ass.stationLinkedCode))
                const results = this.props.qualityIndicatorsResults.filter(r => stations.find(s => s.id == r.qualitometer) && hasValue(r.result))
                const parameterGroup = groupBy(results, 'parameter')
                const parameterTableData = Object.keys(parameterGroup).map(parameter => {
                    const foundIndicator = indicator.indicators.find(i => i.id == parameter)
                    const qualityThreshold = this.props.qualityThresholds.find(t => foundIndicator ? t.thresholdCode == foundIndicator.threshold : false)
                    const thresholds = qualityThreshold ? qualityThreshold.thresholds : []
                    const threshold = thresholds.find(t => t.parameterCode == parameter)
                    const parameterResults = parameterGroup[parameter]
                    const stationResults = parameterResults.reduce((acc, result) => {
                        const analysedResult = calculateThresholdResult(result, thresholds)
                        const color = analysedResult.color === 'white' ? '' : analysedResult.color
                        if (!acc[`station${result.qualitometer}`] || acc[`station${result.qualitometer}`].result < result.result) {
                            acc[`station${result.qualitometer}`] = {
                                value: <span className={ `padding-top-2 padding-left-2 padding-right-2 padding-bottom-2 ${color === 'blue' ? '' : color}` }>{ analysedResult.value }</span>,
                                className: 'productionUnitSliderElement',
                                result: result.result,
                            }
                        }
                        return acc
                    }, {})
                    const worst = maxBy(parameterResults, o => o.result || 0)
                    return Object.assign({}, {
                        nullValue: {
                            className: 'qualitySliderPanel',
                            value: <SliderPanel data={ {
                                title: getLabel(this.props.parameters, parameter),
                                subtitle: `${hasValue(worst.result) ? worst.result : ''} ${getLabel(this.props.units, worst.unit, 'symbol')} ${i18n.atDate} ${getMiniDate(worst.sampleDate)}`,
                                indices: threshold && worst.result ? getSliderPanelIndiceValue(worst.result, threshold) : null,
                                threshold: threshold && worst.result ? threshold : null,
                            } } className='productionUnitSlider'
                            />,
                        },
                        worstColor: worst ? calculateThresholdResult(worst, thresholds).color : 'white',
                    }, stationResults)
                })
                const customHeaders = stations.reduce((acc, s) => {
                    acc[`station${s.id}`] = <a href={ `/#/station/quality/${s.id}` }><span className='tooltipped' data-tooltip={ s.name }>{ s.code }</span></a>
                    return acc
                }, {})
                const allStationsWorstColor = maxBy(parameterTableData.map(d => d.worstColor), color => ['blue', 'green', 'yellow', 'orange', 'red'].findIndex(c => c === color)) || 'blue'
                return [
                    <Table showTitle={ false } data={ parameterTableData } type={ { headers: ['nullValue'].concat(stations.map(s => `station${s.id}`)).concat(['nullValue2']) } } color
                        customHeaders={ customHeaders } sortable condensed className='productionUnitQualityTable' tableClassName='margin-bottom-2'
                    />,
                    allStationsWorstColor,
                ]
            }
            return [
                <MessageCard>{ i18n.noQualityIndicatorsFoundError }</MessageCard>, '',
            ]
        })

        const lists = Object.keys(typeGroup).map((key, index) => (
            <PanelList smallTitle title={ getLabel(STATION_QUALITOMETER_NAMES, key) } collapsible dontOpenFirst cardClassName={ `padding-bottom-0 padding-top-0 colorful-card card-${tables[index][1]}` }>
                { tables[index][0] }
            </PanelList>
        ))
        return <ul className='sieau-collapsible popout' data-collapsible='expandable'>{ lists }</ul>
    }

    getResult = (worst) => {
        return `${hasValue(worst.result) ? worst.result : ''} ${getLabel(this.props.units, worst.unit, 'symbol')}`
    }

    exportData = () => {
        const typeGroup = groupBy(this.props.associations.filter(ass => ass.stationLinkedType === 3), a => {
            const found = this.props.qualitometers.find(q => q.code === a.stationLinkedCode)
            return found ? found.stationType : 0
        })
        const tables = (Object.keys(typeGroup).map(key => {
            const indicator = this.props.qualityIndicators.find(q => q.stationType == key)
            if (indicator) {
                const stations = typeGroup[key].map(ass => this.props.qualitometers.find(q => q.code == ass.stationLinkedCode))
                const results = this.props.qualityIndicatorsResults.filter(r => stations.find(s => s.id == r.qualitometer) && hasValue(r.result))
                const parameterGroup = groupBy(results, 'parameter')
                const codeStations = stations.map(s => s.code)
                const parameterTableData = Object.keys(parameterGroup).map(parameter => {
                    const foundIndicator = indicator.indicators.find(i => i.id == parameter)
                    const qualityThreshold = this.props.qualityThresholds.find(t => foundIndicator ? t.thresholdCode == foundIndicator.threshold : false)
                    const thresholds = qualityThreshold ? qualityThreshold.thresholds : []
                    const parameterResults = parameterGroup[parameter]
                    const stationResults = parameterResults.reduce((acc, result) => {
                        const station = stations.find(s => result.qualitometer === s.id)
                        const analysedResult = calculateThresholdResult(result, thresholds)
                        if (!acc[station.code] || acc[station.code].result < result.result) {
                            acc[station.code] = {
                                value: analysedResult.value,
                                result: result.result,
                            }
                        }
                        return acc
                    }, {})
                    const stationListResult = codeStations.reduce((acc, code) => ({
                        ...acc,
                        [code]: stationResults[code] ? stationResults[code].value : '',
                    }), {})
                    const worst = maxBy(parameterResults, o => o.result || 0)
                    return {
                        dataTypes: getLabel(this.props.parameters, parameter),
                        result: this.getResult(worst),
                        date: getMiniDate(worst.sampleDate),
                        tableTitle: getLabel(STATION_QUALITOMETER_NAMES, key),
                        ...stationListResult,
                    }
                })
                return parameterTableData
            }
            return []
        })).filter(t => t.length !== 0)

        const data = (tables.length !== 0 ? tables.map((table) => {
            table[0].headers = Object.keys(omit(table[0], ['tableTitle']))
            return table
        }) : [])

        exportFileManyTable({ data, exportType: 'xlsx', titleFile: i18n.qualityIndicators + (this.props.station ? ` ${this.props.station.name}` : '') })
    }

    fileDlEvent(event) {
        event.stopPropagation()
        this.exportData()
    }

    render() {
        return (
            <AccordionMUI round style={{ marginTop: 10 }} expanded={this.props.expanded} onChange={this.props.setExpanded}>
                <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>
                    <Grid container justifyContent='space-between'>
                        <Grid item >{i18n.quality}</Grid>
                        <Grid item ><Icon icon='file_download' color='black' onClick={(e) => this.fileDlEvent(e)} /></Grid>
                    </Grid>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI>
                    { this.state.dataLoaded ? this.getPanel() : (
                        <div className='padding-top-2 padding-left-2 padding-right-2 padding-bottom-2'>
                            <ProgressBar progress={ this.state.progress } withMessage/>
                        </div>
                    )}
                </AccordionDetailsMUI>
            </AccordionMUI >
        )
    }

    componentDidUpdate() {
        this.expandAll()
    }
}

UnitQualityPanel.propTypes = {
    id: PropTypes.number,
    qualitometers: arrayOf(DtoQualitometerLight),
    qualityIndicatorsResults: arrayOf(DtoQualityIndicatorsResults),
    qualityThresholds: arrayOf(DtoQualityThresholds),
    associations: arrayOf(DtoStationAssociation),
    qualityIndicators: arrayOf(DtoQualityIndicators),
    parameters: arrayOf(ParameterDto),
    units: arrayOf(UnitDto),
    setDataLoaded: PropTypes.func,
    station: PropTypes.object,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        qualitometers: store.QualityReducer.qualitometersLight,
        qualityIndicatorsResults: store.QualityReducer.qualityIndicatorsResults,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        qualityIndicators: store.QualityReducer.qualityIndicators,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }
}

export default connect(mapStateToProps)(UnitQualityPanel)
