import ActionComponent from 'components/ActionComponent'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isEqual, omit } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_MAT_SENSOR } from '../../../../account/constants/AccessRulesConstants'
import User from '../../../../account/dto/User'
import DtoFile from '../../../../components/file/dto/DtoFile'
import FilePanel from '../../../../components/file/FilePanel'
import DtoPicture from '../../../../components/picture/dto/DtoPicture'
import SmallPicturePanel from '../../../../components/picture/SmallPicturePanel'
import SieauAction from '../../../../components/sieau/SieauAction'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import DtoMaterielSettingRule from '../../../dto/DtoMaterielSettingRule'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import { isSaveBlocked } from '../../../utils/MaterielUtils'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import PowerSupplyAction from '../../powerSupply/actions/PowerSupplyAction'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import SituationPanel from '../../situation/SituationPanel'
import SensorAction from '../actions/SensorAction'
import DtoSensorSituation from '../dto/DtoSensorSituation'
import DtoSensorType from '../dto/DtoSensorType'
import SensorDto from '../dto/SensorDto'
import SensorPanel from './SensorPanel'
import { SENSOR_ID } from 'materiel/constants/MaterielConstants'

class SensorApp extends ActionComponent {
    state = {
        sensor: {},
        administrator: undefined,
        isEditMode: false,
    }

    changeAdministrator = administrator => this.setState(({ sensor }) => ({ sensor: { ...sensor, administrator } }))

    changeStateAttribute = changes => this.setState({ sensor: { ...this.state.sensor, ...changes } })

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentWillUnmount = () => {
        this.props.resetSensor()
        this.props.resetAllActions()
    }

    fetchSensor = id => this.props.fetchSensor(id).then(json => {
        if (json.id) {
            this.props.fetchSensorSituations(json.id)
            this.props.fetchSensorPictures(json.id)
            this.props.fetchSensorFiles(json.serialNumber)
            this.setState({ sensor: { ...json } })
        }
    })

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_SENSOR)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.match.params.id !== 'new') {
            this.fetchSensor(this.props.match.params.id)
            this.changeEditMode(false)
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.sensors.length) {
            this.props.fetchSensors(true)
        }
        if (!this.props.sensorTypes.length) {
            this.props.fetchSensorTypes()
        }
        if (!this.props.powerSupplyTypes.length) {
            this.props.fetchPowerSupplyTypes()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        this.applyActions()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchSensor(this.props.match.params.id)
        }
        if (!isEqual(prevProps.sensor, this.props.sensor) || !isEqual(prevProps.sensorTypes, this.props.sensorTypes)) {
            const {
                reference = '',
                serialNumber = '',
                sensorType,
                id,
            } = this.props.sensor
            const title = reference || serialNumber || id
            const type = (this.props.sensorTypes.find(t => t.code === parseInt(sensorType)) || {}).label || i18n.notDefined
            this.props.forceFetch('title', [
                {
                    title: i18n.materiels,
                    href: 'materiel',
                },
                {
                    title: i18n.sensors,
                    href: 'materiel/sensor',
                },
                {
                    title: type,
                    href: `materiel/sensor/${this.props.match.params.id}`,
                },
                {
                    title,
                    href: `materiel/sensor/${this.props.match.params.id}`,
                },
            ])
        }
        this.applyActions()
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.match.params.id === 'new' && !this.props.sensor.id) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SENSOR_ID)
                        if (isSaveBlocked(this.props.sensors, this.state.sensor, rules, 'sensorType')) return

                        this.props.createSensor(this.state.sensor).then(id => {
                            if (id) {
                                const situation = {
                                    idSensor: id,
                                    situationDate: this.state.sensor.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.saveSensorSituation(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push('/materiel/sensor')
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SENSOR_ID)
                        if (isSaveBlocked(this.props.sensors, this.state.sensor, rules, 'sensorType')) return

                        this.props.updateSensor(this.state.sensor).then(success => success && this.changeEditMode(false))
                    },
                    cancel: () => {
                        this.setState({ sensor: this.props.sensor })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deleteSensor(this.props.match.params.id)
                },
                replace: () => {
                    this.props.info(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    deleteSituation = id => this.props.deleteSensorSituation(id, this.props.match.params.id)

    getMaterielNumber = ({ serialNumber, reference }) => serialNumber || reference || ''

    getSensorsFormat = () => {
        const { sensors, sensorTypes } = this.props
        return sensors.filter(({ isEquipment }) => !isEquipment).map(sensor => {
            const label = (() => {
                const number = this.getMaterielNumber(sensor)
                if (sensor.sensorType) {
                    const type = sensorTypes.find(t => t.id === sensor.sensorType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: sensor.id,
                label,
            }
        })
    }

    render = () => {
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }
        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel materielSituation={ this.props.sensorSituations } />
                                    </div>
                                    <div className='col s11'>
                                        <SensorPanel
                                            sensor={ this.state.sensor }
                                            onChange={ this.changeStateAttribute }
                                            { ...disabled }
                                        />
                                        {
                                            !this.props.sensor.isEquipment && (
                                                <div className='col s12'>
                                                    <SituationPanel
                                                        materielSituation={this.props.sensorSituations}
                                                        active={this.state.isEditMode}
                                                        idMateriel={this.props.sensor.id}
                                                        serialNumber={this.props.sensor.serialNumber}
                                                        purchaseDate={this.props.sensor.purchaseDate}
                                                        idKey={'idSensor'}
                                                        lastSituations={this.props.sensorsLastSituations}
                                                        materials={this.getSensorsFormat()}
                                                        saveFunction={this.props.saveSensorSituation}
                                                        deleteFunction={this.deleteSituation}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.props.sensor.updateDate}
                                                updateLogin={this.props.sensor.updateLogin}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.sensor.administrator}
                                                        isActive={!this.props.sensor.isEquipment && this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel materielSituation={ this.props.sensorSituations } />
                                            <SmallPicturePanel element={ 'sensor' }
                                                pictures={ this.props.sensorPictures }
                                            />
                                            <FilePanel files={ this.props.sensorFiles } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SensorApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
    }),
    sensor: PropTypes.instanceOf(SensorDto),
    getLink: PropTypes.func,
    changeEditMode: PropTypes.func,
    resetAllActions: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    sensorTypes: PropTypes.instanceOf(DtoSensorType),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    sensorSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorSituation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    sensorPictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    sensorFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    sensors: PropTypes.arrayOf(PropTypes.instanceOf(SensorDto)),
    sensorsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorSituation)),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    sensor: store.SensorReducer.sensor,
    sensorTypes: store.SensorReducer.sensorTypes,
    sensorSituations: store.SensorReducer.sensorSituations,
    materielStates: store.MaterielReducer.materielStates,
    contributors: store.ContributorReducer.contributors,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sensorPictures: store.SensorReducer.sensorPictures,
    sensorFiles: store.SensorReducer.sensorFiles,
    accountUser: store.AccountReducer.accountUser,
    sensors: store.SensorReducer.sensors,
    sensorsLastSituations: store.SensorReducer.sensorsLastSituations,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProps = {
    push,
    fetchSensors: SensorAction.fetchSensors,
    fetchSensorTypes: SensorAction.fetchSensorTypes,
    resetSensor: SensorAction.resetSensor,
    fetchSensor: SensorAction.fetchSensor,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    forceFetch: SieauAction.forceFetch,
    createSensor: SensorAction.createSensor,
    updateSensor: SensorAction.updateSensor,
    deleteSensor: SensorAction.deleteSensor,
    toastrInfo: ToastrAction.info,
    deleteSensorSituation: SensorAction.deleteSensorSituation,
    fetchSensorSituations: SensorAction.fetchSensorSituations,
    fetchSensorPictures: SensorAction.fetchSensorPictures,
    fetchSensorFiles: SensorAction.fetchSensorFiles,
    saveSensorSituation: SensorAction.saveSensorSituation,
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorApp)
