import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import { groupBy, orderBy, uniqBy } from 'lodash'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../../utils/StatusUtil'
// import useUpdateEffect from '../../../../../utils/customHook/useUpdateEffect'
import CollapseTopBar from '../../../../../components/topBar/CollapseToBar'
import SimpleFilterSelect from '../../../../../components/forms/specific/SimpleFilterSelect'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from '../../../../../station/constants/StationConstants'
import Input from '../../../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import MultiContributorsAutocomplete
    from '../../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import MultiContactsAutocomplete
    from '../../../../../referencial/components/contact/components/MultiContactsAutocomplete'
import SuperMultiAutocomplete from '../../../../../components/forms/SuperMultiAutocomplete'
import ThresholdSelect from '../../../../../components/forms/specific/ThresholdSelect'
import Checkbox from '../../../../../components/forms/Checkbox'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import PropTypes from 'prop-types'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import AdministrationAction from '../../../../../administration/actions/AdministrationAction'
import { Grid } from '@mui/material'
import AssociatedSectorSelect from 'components/forms/specific/AssociatedSectorSelect'
import AssociatedStationSelect from 'components/forms/specific/AssociatedStationSelect'


const QualityIntegrationOverviewCriterias = ({
    defaultFilter = {},
    onValidate = () => { },
}) => {
    const {
        contactsProps,
        parametersOptions,
        qualityCampaigns,
        qualityJobs,
        qualitometers,
        contributors,
        networks,
        networkLinks,
        installations,
        distributionUnits,
        productionUnits,
        accountUser,
    } = useSelector(store => ({
        contactsProps: store.ContactReducer.contacts,
        parametersOptions: store.ParameterReducer.parameters,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        qualityJobs: store.JobReducer.qualityJobs,
        qualitometers: store.QualityReducer.qualitometersLight,
        contributors: store.ContributorReducer.contributors,
        networks: store.NetworkReducer.networks,
        networkLinks: store.QualityReducer.networkLinks,
        installations: store.InstallationReducer.installationsLight,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    // let shouldUpdateOnLoad = useRef(defaultFilter.filter !== -1)

    const uniqQualityJobs = uniqBy(qualityJobs, 'id')


    const [sector, setSector] = useState(defaultFilter.sector)
    const [sectorAssociatedStations, setSectorAssociatedStations] = useState([])
    const [installation, setInstallation] = useState(defaultFilter.installation)
    const [installationAssociatedStations, setInstallationAssociatedStations] = useState([])
    const [productionUnit, setProductionUnit] = useState(defaultFilter.productionUnit)
    const [prodAssociatedStations, setProdAssociatedStations] = useState([])
    const [distributionUnit, setDistributionUnit] = useState(defaultFilter.distributionUnit)
    const [distriAssociatedStations, setDistriAssociatedStations] = useState([])

    const [searchValue, setSearchValue] = useState(defaultFilter.searchValue)
    const [startDate, setStartDate] = useState(defaultFilter.startDate)
    const [endDate, setEndDate] = useState(defaultFilter.endDate)
    const [producers, setProducers] = useState(defaultFilter.producers)
    const [contacts, setContacts] = useState(defaultFilter.contacts)
    const [parameters, setParameters] = useState(defaultFilter.parameters)
    const [status, setStatus] = useState(defaultFilter.status)
    const [qualification, setQualification] = useState(defaultFilter.qualification)
    const [campaign, setCampaign] = useState(defaultFilter.campaign)
    const [jobId, setJobId] = useState(defaultFilter.jobId)
    const [jobExecutionId, setJobExecutionId] = useState(defaultFilter.jobExecutionId)
    const [jobStartDate, setJobStartDate] = useState(defaultFilter.jobStartDate)
    const [jobEndDate, setJobEndDate] = useState(defaultFilter.jobEndDate)
    const [threshold, setThreshold] = useState(defaultFilter.threshold)
    const [conformitySandre, setConformitySandre] = useState(defaultFilter.conformitySandre)
    const [outliers, setOutliers] = useState(defaultFilter.outliers)
    const [filter, setFilter] = useState(defaultFilter.filter)
    const [filterResults, setFilterResults] = useState(defaultFilter.filterResults)
    const [applyCriteriasOnStats/* , setApplyCriteriasOnStats*/] = useState(false)

    // network Filter
    const [network, setNetwork] = useState(defaultFilter.network)
    const [networkGroup, setNetworkGroup] = useState({})
    const [networkList, setNetworkList] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        // network filter
        const networkGroupTmp = groupBy(networkLinks, 'idNetwork')
        const networkListTmp = Object.keys(networkGroupTmp).map(nId => networks.find(n => n.id == nId)).filter(n => !!n)
        setNetworkList(networkListTmp)
        setNetworkGroup(networkGroupTmp)
    }, [])

    const statusSelectOptions = useMemo(() => getStatusSelectOptions(), [])
    const qualificationSelectOptions = useMemo(() => getQualificationSelectOptions(), [])

    const onValidateForm = () => {
        dispatch(AdministrationAction.setCache('quality', { searchValue }))
        onValidate({
            searchValue,
            startDate,
            endDate,
            producers,
            contacts,
            parameters,
            status,
            qualification,
            campaign,
            jobId,
            jobExecutionId,
            jobStartDate,
            jobEndDate,
            threshold,
            conformitySandre,
            outliers,
            filter,
            filterResults,
            sector,
            sectorAssociatedStations,
            installation,
            installationAssociatedStations,
            productionUnit,
            prodAssociatedStations,
            distributionUnit,
            distriAssociatedStations,
            applyCriteriasOnStats,
            networkGroup,
            network,
        })
    }

    // useUpdateEffect(() => {
    //     if (shouldUpdateOnLoad.current && filterResults.length) {
    //         shouldUpdateOnLoad.current = false
    //         onValidateForm()
    //     }
    // }, [filter, filterResults])

    return (
        <div className='row no-margin' >
            <CollapseTopBar>
                <div style={{ padding: '10 15', backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832' }}>
                    <Grid container columnSpacing={1} rowSpacing={0.5}>
                        <Grid item xs={4}>
                            <SimpleFilterSelect
                                stationType={STATION_TYPE_NAME.quality}
                                onChange={(result, code) => {
                                    setFilter(code)
                                    setFilterResults(code === -1 ? [] : result)
                                }}
                                stations={qualitometers}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Input
                                tooltip={i18n.searchThenEnter}
                                title={i18n.search}
                                value={searchValue}
                                onChange={setSearchValue}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                label={i18n.network}
                                value={network}
                                options={networkList}
                                onChange={setNetwork}
                                keyLabel='labelWithSandre'
                                keyValue='id'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AssociatedSectorSelect
                                value={sector}
                                onChange={(list, value) => {
                                    setSector(value)
                                    setSectorAssociatedStations(list)
                                }}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AssociatedStationSelect
                                label={i18n.installation}
                                options={installations}
                                value={installation}
                                onChange={(list, value) => {
                                    setInstallation(value)
                                    setInstallationAssociatedStations(list)
                                }}
                                stationType={STATION_TYPE_CONSTANT.INSTALLATION}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                keyValue='code'
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AssociatedStationSelect
                                label={i18n.productionUnit}
                                options={productionUnits}
                                value={productionUnit}
                                onChange={(list, value) => {
                                    setProductionUnit(value)
                                    setProdAssociatedStations(list)
                                }}
                                stationType={STATION_TYPE_CONSTANT.PRODUCTION}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                keyValue='code'
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AssociatedStationSelect
                                label={i18n.distributionUnit}
                                options={distributionUnits}
                                value={distributionUnit}
                                onChange={(list, value) => {
                                    setDistributionUnit(value)
                                    setDistriAssociatedStations(list)
                                }}
                                stationType={STATION_TYPE_CONSTANT.DISTRIBUTION}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                keyValue='code'
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SimpleDatePicker
                                value={startDate}
                                label={i18n.startDate}
                                onChange={setStartDate}
                                max={endDate}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SimpleDatePicker
                                value={endDate}
                                label={i18n.endDate}
                                onChange={setEndDate}
                                min={startDate}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiContributorsAutocomplete
                                options={contributors}
                                values={producers}
                                label={i18n.producer}
                                onChange={setProducers}
                                multiple
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiContactsAutocomplete
                                label={i18n.referent}
                                options={contactsProps}
                                onChange={setContacts}
                                values={contacts}
                                multiple
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SuperMultiAutocomplete
                                label={i18n.parameters}
                                options={parametersOptions}
                                limit={1}
                                onChange={setParameters}
                                values={parameters}
                                keyValue='code'
                                keyLabel='labelWithCode'
                                multiple
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SuperMultiAutocomplete
                                options={statusSelectOptions}
                                label={i18n.status}
                                values={status}
                                onChange={setStatus}
                                noSort
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SuperMultiAutocomplete
                                options={qualificationSelectOptions}
                                label={i18n.qualification}
                                values={qualification}
                                onChange={setQualification}
                                noSort
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SuperMultiAutocomplete
                                options={qualityCampaigns}
                                label={i18n.campaign}
                                values={campaign}
                                onChange={setCampaign}
                            />
                        </Grid>
                        {
                            accountUser.isAdmin === '1' && (
                                <Grid item xs={12}>
                                    <StyledFieldSet className='margin-1 padding-1'>
                                        <StyledLegend>Recherche par traitement</StyledLegend>
                                        <div className='row no-margin'>
                                            <Select
                                                options={orderBy(uniqQualityJobs, 'name')}
                                                label={i18n.job}
                                                value={jobId}
                                                keyValue='id'
                                                onChange={setJobId}
                                                col={4}
                                            />
                                            <NumberField
                                                col={2}
                                                title={i18n.jobExecutionId}
                                                value={jobExecutionId}
                                                onChange={setJobExecutionId}
                                            />
                                            <SimpleDatePicker
                                                col={2}
                                                value={jobStartDate}
                                                label={`${i18n.startDate} (${i18n.executions})`}
                                                onChange={setJobStartDate}
                                                max={jobEndDate}
                                            />
                                            <SimpleDatePicker
                                                col={2}
                                                value={jobEndDate}
                                                label={`${i18n.endDate} (${i18n.executions})`}
                                                onChange={setJobEndDate}
                                                min={jobStartDate}
                                            />
                                        </div>
                                        <div className='row no-margin valign-wrapper'>
                                            <ThresholdSelect
                                                col={4}
                                                onChange={setThreshold}
                                                selected={threshold}
                                            />
                                            <Checkbox
                                                col={2}
                                                label={i18n.sandreConformity}
                                                checked={conformitySandre}
                                                onChange={setConformitySandre}
                                            />
                                            <Checkbox
                                                col={2}
                                                label={i18n.outliers}
                                                checked={outliers}
                                                onChange={setOutliers}
                                            />
                                            <div className='col s2 offset-s2'>
                                                <a className='col s12 btn' onClick={onValidateForm}>
                                                    {i18n.search}
                                                </a>
                                            </div>
                                        </div>
                                    </StyledFieldSet>
                                </Grid>
                            )
                        }
                        {
                            accountUser.isAdmin !== '1' && (
                                <>
                                    <Grid item xs={8} />
                                    <Grid item xs={12}>
                                        <div className='row no-margin'>
                                            <div className='col s2 offset-s10'>
                                                <a className='col s12 btn' onClick={onValidateForm}>
                                                    {i18n.search}
                                                </a>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                </div>
            </CollapseTopBar>
        </div >
    )
}

QualityIntegrationOverviewCriterias.propTypes = {
    onValidate: PropTypes.func,
    defaultFilter: PropTypes.shape({}),
}

export default QualityIntegrationOverviewCriterias
