import { Button, Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Select from 'components/forms/Select'
import { groupBy, intersectionWith, isUndefined, orderBy, uniqBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import NumberField from 'components/forms/NumberField'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import Card from 'components/card/Card'
import useSandreList from 'utils/customHook/useSandreList'
import { nbPerPageLabel, nbPerPageLabelMedium } from 'referencial/constants/ReferencialConstants'
import { ParameterFilterField } from 'components/datatable/SimpleSelectionTable'
import Table from 'components/datatable/Table'
import { searchAllCharacters } from 'utils/StringUtil'
import useBoolean from 'utils/customHook/useBoolean'
import useListIndexed from 'utils/customHook/useListIndexed'

const PopinParameterThreshold = ({
    isOpen = false,
    fullyUpdatable = false,
    parameterThreshold = {},
    validate = () => {},
    close = () => {},
}) => {
    const {
        units,
        parameters,
        qualitometers,
    } = useSelector(store => ({
        units: store.UnitReducer.units,
        parameters: store.ParameterReducer.parameters,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const lexiconCalcium = useSandreList('CALCIUM')
    const lexiconFRType = useSandreList('QUALITOMETRES.TYPE_FR.PLAN')
    const lexiconFishContext = useSandreList('QUALITOMETRES.CONTEXTEPISCICOLE')

    const [parameterCode, setParameterCode] = useState()
    const [unit, setUnit] = useState()
    const [station, setStation] = useState()

    const [threshold1, setThreshold1] = useState()
    const [threshold2, setThreshold2] = useState()
    const [threshold3, setThreshold3] = useState()
    const [threshold4, setThreshold4] = useState()

    const [calciumClass, setCalciumClass] = useState()
    const [frType, setFrType] = useState()
    const [fishCode, setFishCode] = useState()

    useEffect(() => {
        if (isOpen) {
            setParameterCode(parameterThreshold.parameterCode)
            setUnit(parameterThreshold.unit)
            setStation(parameterThreshold.station)

            setThreshold1(parameterThreshold.threshold1)
            setThreshold2(parameterThreshold.threshold2)
            setThreshold3(parameterThreshold.threshold3)
            setThreshold4(parameterThreshold.threshold4)

            setCalciumClass(parameterThreshold.calciumClass)
            setFrType(parameterThreshold.frType)
            setFishCode(parameterThreshold.fishCode)
        }
    }, [isOpen, parameterThreshold.calciumClass, parameterThreshold.fishCode, parameterThreshold.frType, parameterThreshold.parameterCode, parameterThreshold.station, parameterThreshold.threshold1, parameterThreshold.threshold2, parameterThreshold.threshold3, parameterThreshold.threshold4, parameterThreshold.unit])

    useEffect(() => {
        if (isUndefined(threshold1)) {
            setThreshold2(undefined)
            setThreshold3(undefined)
            setThreshold4(undefined)
        }
    }, [threshold1])

    useEffect(() => {
        if (isUndefined(threshold2)) {
            setThreshold3(undefined)
            setThreshold4(undefined)
        }
    }, [threshold2])

    useEffect(() => {
        if (isUndefined(threshold3)) {
            setThreshold4(undefined)
        }
    }, [threshold3])

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.threshold}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={close} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <StyledFieldSet>
                    <Grid container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.parameter}
                                value={parameterCode}
                                onChange={setParameterCode}
                                options={parameters}
                                disabled={!fullyUpdatable}
                                keyLabel='labelWithCode'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.unit}
                                value={unit}
                                onChange={setUnit}
                                options={units}
                                disabled={!fullyUpdatable}
                                keyLabel='symbolWithName'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                label={i18n.station}
                                value={station}
                                onChange={setStation}
                                options={qualitometers}
                                disabled={!fullyUpdatable}
                                keyValue='id'
                                keyLabel='displayLabel'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberField
                                title={i18n.threshold1}
                                value={threshold1}
                                floatValue
                                onChange={setThreshold1}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {
                                !isUndefined(threshold1) && (
                                    <NumberField
                                        title={i18n.threshold2}
                                        value={threshold2}
                                        floatValue
                                        onChange={setThreshold2}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                !isUndefined(threshold2) && (
                                    <NumberField
                                        title={i18n.threshold3}
                                        value={threshold3}
                                        floatValue
                                        onChange={setThreshold3}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                !isUndefined(threshold3) && (
                                    <NumberField
                                        title={i18n.threshold4}
                                        value={threshold4}
                                        floatValue
                                        onChange={setThreshold4}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.hardness}
                                value={calciumClass}
                                options={lexiconCalcium}
                                onChange={setCalciumClass}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.frType}
                                value={frType}
                                options={lexiconFRType}
                                onChange={setFrType}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.fishContext}
                                value={fishCode}
                                options={lexiconFishContext}
                                onChange={setFishCode}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container alignItems='center' justifyContent='right' columnSpacing={2} style={{ padding: '0 10' }}>
                    <Grid container item xs={3} justifyContent='flex-end'>
                        <Button
                            onClick={() => {
                                validate({
                                    parameterCode,
                                    unit,
                                    station,
                                    threshold1,
                                    threshold2,
                                    threshold3,
                                    threshold4,
                                    calciumClass,
                                    frType,
                                    fishCode,
                                })
                            }}
                            variant='contained'
                            color='primary'
                            disabled={isUndefined(threshold1)}
                        >
                            {i18n.validate}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PopinParameterThreshold.propTypes = {
    isOpen: PropTypes.bool,
    fullyUpdatable: PropTypes.bool,
    threshold: PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    }),
    parameterThreshold: PropTypes.shape({
        parameterCode: PropTypes.string,
        unit: PropTypes.string,
        station: PropTypes.number,
        threshold1: PropTypes.number,
        threshold2: PropTypes.number,
        threshold3: PropTypes.number,
        threshold4: PropTypes.number,
        calciumClass: PropTypes.number,
        frType: PropTypes.number,
        fishCode: PropTypes.number,
    }),
    validate: PropTypes.func,
    close: PropTypes.func,
}

const StepParameterThresholds = ({
    qualityThresholds = [],
    setQualityThresholds = () => {},
}) => {
    const {
        parameters,
        units,
        qualitometers,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedUnits = useListIndexed(units, 'code')
    const indexedQualitometers = useListIndexed(qualitometers, 'id')

    const lexiconCalcium = useSandreList('CALCIUM')
    const lexiconFRType = useSandreList('QUALITOMETRES.TYPE_FR.PLAN')
    const lexiconFishContext = useSandreList('QUALITOMETRES.CONTEXTEPISCICOLE')

    const [filter, setFilter] = useState({})
    const [selectedIndex, setSelectedIndex] = useState()
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)

    const selectedThreshold = useMemo(() => {
        return qualityThresholds[selectedIndex]
    }, [qualityThresholds, selectedIndex])

    const groupThresholds= groupBy(qualityThresholds, th => `${th.parameterCode}:${th.unit}:${th.station}`)

    const formattedThresholds = useMemo(() => {
        return qualityThresholds.map((th, index) => {
            const key = `${th.parameterCode}:${th.unit}:${th.station}`
            const parameter = indexedParameters[th.parameterCode]

            return {
                code: th.parameterCode,
                parameter: isUndefined(th.parameterCode) ? i18n.defaultThreshold : parameter?.displayLabel,
                unit: indexedUnits[th.unit]?.symbolWithCode ?? '',
                station: indexedQualitometers[th.station]?.displayLabel,
                threshold1: th.threshold1,
                threshold2: th.threshold2,
                threshold3: th.threshold3,
                threshold4: th.threshold4,
                hardness: lexiconCalcium.find(l => l.code === th.calciumClass)?.name,
                frType: lexiconFRType.find(l => l.code === th.frType)?.name,
                fishContext: lexiconFishContext.find(l => l.code === th.fishCode)?.name,
                color: groupThresholds[key].length > 1 ? '#ff8c86' : '#fff',
                index,
                searchValue: [parameter?.name, parameter?.shortLabel, parameter?.code].map((label = '') => searchAllCharacters(label)),
            }
        })
    }, [groupThresholds, indexedParameters, indexedQualitometers, indexedUnits, lexiconCalcium, lexiconFRType, lexiconFishContext, qualityThresholds])


    const filteredThresholds = useMemo(() => {
        const { selection = '-1', listParam, searchValue } = filter
        const filterSelection = selection !== '-1' ? intersectionWith(formattedThresholds, listParam, (a, code) => a.code === code) : formattedThresholds
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? filterSelection.filter(p => p.searchValue.some(v => v.includes(searchValueFormat))) : filterSelection
    }, [filter, formattedThresholds])

    const actions = [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => {
            setSelectedIndex()
            open()
        },
    }, {
        iconName: 'info',
        tooltip: i18n.redLineMeanDuplicate,
    }]

    const nbThresholds = qualityThresholds.length
    const nbParameters = uniqBy(qualityThresholds, 'parameterCode').length

    return (
        <Grid container justifyContent='center' rowSpacing={1}>
            <Grid item xs={12}>
                <StyledFieldSet>
                    <StyledLegend>{i18n.filter}</StyledLegend>
                    <ParameterFilterField
                        filter={filter}
                        setFilter={setFilter}
                    />
                </StyledFieldSet>
            </Grid>
            <Grid item xs={12}>
                <Card
                    title={`${nbThresholds} ${nbThresholds > 1 ? i18n.thresholds : i18n.threshold}, ${nbParameters} ${nbParameters > 1 ? i18n.parameters : i18n.parameter}`}
                    actions={actions}
                >
                    <Table
                        showTitle={false}
                        data={orderBy(filteredThresholds, 'parameter')}
                        type={{ headers: ['parameter', 'unit', 'station', 'threshold1', 'threshold2', 'threshold3', 'threshold4', 'hardness', 'frType', 'fishContext'] }}
                        coloredLine
                        maxHeight='43vh'
                        sortable
                        condensed
                        paging
                        duplicable
                        onDuplicate={({ index }) => setQualityThresholds(list => [...list, qualityThresholds[index]])}
                        deletable
                        onDelete={({ index }) => setQualityThresholds(list => list.filter((_, i) => i !== index))}
                        alterable
                        onAlter={({ index }) => {
                            setSelectedIndex(index)
                            open()
                        }}
                        nbPerPageLabel={nbPerPageLabel}
                    />
                </Card>
                <PopinParameterThreshold
                    isOpen={isOpen}
                    fullyUpdatable
                    parameterThreshold={selectedThreshold}
                    validate={newThreshold => {
                        setQualityThresholds(list => {
                            if (isUndefined(selectedIndex)) {
                                return [...list, newThreshold]
                            }
                            return list.map((th, i) => i === selectedIndex ? newThreshold : th)
                        })
                        close()
                    }}
                    close={close}
                />
            </Grid>
        </Grid>
    )
}

StepParameterThresholds.propTypes = {
    threshold: PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    }),
    qualityThresholds: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        unit: PropTypes.string,
        station: PropTypes.number,
        threshold1: PropTypes.number,
        threshold2: PropTypes.number,
        threshold3: PropTypes.number,
        threshold4: PropTypes.number,
        calciumClass: PropTypes.number,
        frType: PropTypes.number,
        fishCode: PropTypes.number,
    })),
    setQualityThresholds: PropTypes.func,
}

const TableParameterThreshold = ({
    threshold = {},
    qualityThresholds = [],
    setQualityThresholds = () => {},
    isEditMode = false,
}) => {
    const {
        parameters,
        units,
        qualitometers,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const parametersIndexed = useListIndexed(parameters, 'code')
    const unitsIndexed = useListIndexed(units, 'code')
    const indexedQualitometers = useListIndexed(qualitometers, 'id')

    const lexiconCalcium = useSandreList('CALCIUM')
    const lexiconFRType = useSandreList('QUALITOMETRES.TYPE_FR.PLAN')
    const lexiconFishContext = useSandreList('QUALITOMETRES.CONTEXTEPISCICOLE')

    const [selectedIndex, setSelectedIndex] = useState()
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)

    const selectedThreshold = useMemo(() => {
        return qualityThresholds[selectedIndex]
    }, [qualityThresholds, selectedIndex])

    const thresholdsFormatted = useMemo(() => {
        return qualityThresholds?.map((th, index) => ({
            code: th.parameterCode,
            parameter: parametersIndexed[th.parameterCode]?.labelWithCode ?? '',
            unit: unitsIndexed[th.unit]?.symbol ?? '',
            station: indexedQualitometers[th.station]?.displayLabel,
            threshold1: th.threshold1,
            threshold2: th.threshold2,
            threshold3: th.threshold3,
            threshold4: th.threshold4,
            hardness: lexiconCalcium.find(l => l.code === th.calciumClass)?.name,
            frType: lexiconFRType.find(l => l.code === th.frType)?.name,
            fishContext: lexiconFishContext.find(l => l.code === th.fishCode)?.name,
            index,
        }))
    }, [indexedQualitometers, lexiconCalcium, lexiconFRType, lexiconFishContext, parametersIndexed, qualityThresholds, unitsIndexed])

    return (
        <>
            <Table
                showTitle={false}
                data={thresholdsFormatted}
                type={{ headers: ['parameter', 'unit', 'station', 'threshold1', 'threshold2', 'threshold3', 'threshold4', 'hardness', 'frType', 'fishContext'] }}

                sortable
                condensed
                paging
                nbPerPageLabel={nbPerPageLabelMedium}

                alterable={isEditMode}
                onAlter={({ index }) => {
                    setSelectedIndex(index)
                    open()
                }}
                deletable={isEditMode}
                onDelete={({ index }) => setQualityThresholds(threshold.code, qualityThresholds.filter((_, i) => i !== index))}
            />
            <PopinParameterThreshold
                isOpen={isOpen}
                parameterThreshold={selectedThreshold}
                validate={newThreshold => {
                    const newQualityThresholds = qualityThresholds.map((th, i) => i === selectedIndex ? newThreshold : th)
                    setQualityThresholds(threshold.code, newQualityThresholds)
                    close()
                }}
                close={close}
            />
        </>
    )
}

TableParameterThreshold.propTypes = {
    threshold: PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    }),
    qualityThresholds: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        unit: PropTypes.string,
        station: PropTypes.number,
        threshold1: PropTypes.number,
        threshold2: PropTypes.number,
        threshold3: PropTypes.number,
        threshold4: PropTypes.number,
        calciumClass: PropTypes.number,
        frType: PropTypes.number,
        fishCode: PropTypes.number,
    })),
    setQualityThresholds: PropTypes.func,
    isEditMode: PropTypes.bool,
}

export {
    PopinParameterThreshold,
    TableParameterThreshold,
    StepParameterThresholds,
}