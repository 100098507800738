import { Button, Grid } from '@mui/material'
import { StyledFieldSet } from 'components/StyledElements'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import { groupBy, isUndefined, keys } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import Card from 'components/card/Card'
import { greyBlue, mainBlue } from 'utils/constants/ColorTheme'
import { THRESHOLD } from 'quality/constants/QualityConstants'
import { StepTaxonThresholds, TableTaxonThreshold } from './TaxonThreshold'
import { StepParameterThresholds, TableParameterThreshold } from './ParameterThreshold'
import UsersStep from 'components/modal/userFilter/UsersStep'
import UsersDisplay from 'components/modal/UsersDisplay'

const STEP_DESCRIPTION = 0
const STEP_SELECT_PARAMETER = 1
const STEP_USER_RIGHT = 2

const Threshold = ({
    threshold = {},
    qualityThreshold = {},
    childrenThresholds = [],
    isEditMode = false,

    toogleActive = () => { },
    onUpdate = () => { },
    onDelete = () => { },
    setQualityThresholds = () => {},
}) => (
    <>
        <Grid item xs={12}>
            <AccordionMUI>
                <AccordionSummaryMUI sx={{ height: undefined }} color={isEditMode ? mainBlue : greyBlue}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item xs={0.5}>
                            <Icon
                                icon={threshold.status === 1 ? 'check_box' : 'check_box_outline_blank'}
                                onClick={e => {
                                    if (!isEditMode) {
                                        return
                                    }
                                    e.stopPropagation()
                                    toogleActive(threshold.code)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <span style={{ fontSize: '1.3rem' }}>
                                {threshold.name}
                            </span>
                        </Grid>
                        <Grid item xs={5.5}>
                            <span style={{ fontSize: '1.3rem' }}>
                                {threshold.comment}
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            <span style={{ fontSize: '1.3rem' }}>
                                {qualityThreshold.thresholds?.length ?? 0} {qualityThreshold.thresholds?.length > 1 ? i18n.thresholds : i18n.threshold}
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            {
                                isEditMode && (
                                    <>
                                        <Icon
                                            icon='edit'
                                            tooltip={i18n.edit}
                                            onClick={e => {
                                                e.stopPropagation()
                                                onUpdate(threshold.code)
                                            }}
                                        />
                                        <Icon
                                            icon='delete'
                                            tooltip={i18n.delete}
                                            onClick={e => {
                                                e.stopPropagation()
                                                onDelete(threshold.code)
                                            }}
                                        />
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI sx={{ padding: 0 }}>
                    {[THRESHOLD.PC, THRESHOLD.INDICE].includes(threshold.thresholdType) && (
                        <TableParameterThreshold
                            threshold={threshold}
                            qualityThresholds={qualityThreshold.thresholds}
                            setQualityThresholds={setQualityThresholds}
                            isEditMode={isEditMode}
                        />
                    )}
                    {threshold.thresholdType === THRESHOLD.TAXON && (
                        <TableTaxonThreshold
                            threshold={threshold}
                            qualityThresholds={qualityThreshold.thresholds}
                            setQualityThresholds={setQualityThresholds}
                            isEditMode={isEditMode}
                        />
                    )}
                </AccordionDetailsMUI>
            </AccordionMUI>
        </Grid>
        {
            childrenThresholds.map(child => (
                <Grid container spacing={1} item xs={12} sx={{ marginLeft: '20px' }} key={child.threshold.code}>
                    <Threshold
                        threshold={child.threshold}
                        qualityThreshold={child.qualityThreshold}
                        childrenThresholds={child.childrens}
                        isEditMode={isEditMode}

                        toogleActive={toogleActive}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        setQualityThresholds={setQualityThresholds}
                    />
                </Grid>
            ))
        }
    </>
)

Threshold.propTypes = {
    threshold: PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    }),
    qualityThreshold: PropTypes.shape({
        thresholdCode: PropTypes.number,
        thresholdType: PropTypes.string,
        thresholds: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
            unit: PropTypes.string,
            threshold1: PropTypes.number,
            threshold2: PropTypes.number,
            threshold3: PropTypes.number,
            threshold4: PropTypes.number,
        })),
    }),
    // eslint-disable-next-line react/forbid-prop-types
    childrenThresholds: PropTypes.array,
    /*
    ----- recursive props -----
    childrenThresholds = [
        {
            threshold: {},
            qualityThreshold: {},
            childrenThresholds: [],
        },
        ...
    ]
    */
    isEditMode: PropTypes.bool,

    toogleActive: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    setQualityThresholds: PropTypes.func,
}

const StepDescription = ({
    threshold = {},
    setThreshold = () => {},
}) => {
    const {
        thresholds,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
    }), shallowEqual)

    const thresholdTypes = useMemo(() => {
        return [
            { id: THRESHOLD.PC, label: i18n.PCparameters },
            { id: THRESHOLD.TAXON, label: i18n.taxons },
            { id: THRESHOLD.INDICE, label: i18n.indices },
            // { id: SELECTION.ENVIRONMENTAL_PARAMETER, label: i18n.environmentalParameters },
            // { id: SELECTION.STATE, label: i18n.states },
        ]
    }, [])

    return (
        <StyledFieldSet>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Input
                        title={i18n.name}
                        value={threshold.name}
                        onChange={v => setThreshold(th => ({ ...th, name: v || undefined }))}
                        obligatory
                    />
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        label={i18n.active}
                        checked={threshold.status === 1}
                        componentClassName='padding-top-1'
                        onChange={v => setThreshold(th => ({ ...th, status: v ? 1 : 0 }))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        options={thresholdTypes}
                        value={threshold.thresholdType}
                        noNullValue
                        label={i18n.type}
                        onChange={v => setThreshold(th => ({ ...th, thresholdType: isUndefined(v) ? v : `${v}` }))}
                        disabled={!isUndefined(threshold.code)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        title={i18n.comment}
                        value={threshold.comment}
                        onChange={v => setThreshold(th => ({ ...th, comment: v }))}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Select
                        options={thresholds.filter(t => !t.referenceCode && t.code !== threshold.code)}
                        label={i18n.parentThreshold}
                        value={threshold.referenceCode}
                        nullLabel='&nbsp;'
                        onChange={v => setThreshold(th => ({ ...th, referenceCode: isUndefined(v) ? v : `${v}` }))}
                    />
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

StepDescription.propTypes = {
    threshold: PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    }),
    setThreshold: PropTypes.func,
}

const StepUserRight = ({
    threshold = {},
    setThreshold = () => {},
}) => {
    const {
        users,
    } = useSelector(store => ({
        users: store.UserReducer.users,
    }), shallowEqual)

    const [userOption, setUserOption] = useState(threshold.users.length === 0 && threshold.administrators.length === 0 ? 0 : 1)

    const filteredUsers = users.filter(u => threshold.users.some(l => l === u.login))

    return (
        <UsersStep
            stateUsers={filteredUsers}
            setStateUsers={newUser => setThreshold(th => ({ ...th, users: newUser.map(u => u.login) }))}

            stateAdministrators={threshold.administrators}
            setStateAdministrators={newAdministrators => setThreshold(th => ({ ...th, administrators: newAdministrators }))}

            userOption={userOption}
            setUserOption={(v) => {
                setUserOption(v)
                setThreshold(th => ({ ...th, users: [], administrators: [] }))
            }}
        >
            <UsersDisplay
                userOption={userOption}
                stateAdministrators={threshold.administrators}
                stateUsers={filteredUsers}
            />
        </UsersStep>
    )
}

StepUserRight.propTypes = {
    threshold: PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
        user: PropTypes.arrayOf(PropTypes.string),
        administrators: PropTypes.arrayOf(PropTypes.number),
    }),
    setThreshold: PropTypes.func,
}

const StepperThreshold = ({
    isOpen = false,
    onClose = () => { },
    onSave = () => { },

    selectedThreshold = {},
    selectedQualityThreshold = {},
}) => {
    const [threshold, setThreshold] = useState({})
    const [qualityThresholds, setQualityThresholds] = useState([])

    useEffect(() => {
        if (isOpen) {
            setThreshold(selectedThreshold)
            setQualityThresholds(selectedQualityThreshold.thresholds ?? [])
        }
    }, [selectedQualityThreshold, selectedThreshold, isOpen])

    const isThresholdComplete = useMemo(() => {
        if (isUndefined(qualityThresholds)) {
            return false
        }
        const groupThresholds = groupBy(qualityThresholds, th => `${th.parameterCode}:${th.unit}:${th.station}`)
        const hasDuplicate = keys(groupThresholds).some(key => groupThresholds[key].length > 1)
        const isThresholdMissing = qualityThresholds.some(t => isUndefined(t.threshold1))
        return !hasDuplicate && !isThresholdMissing && qualityThresholds.length > 0
    }, [qualityThresholds])

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.description,
                    constant: STEP_DESCRIPTION,
                    nextAvailable: !isUndefined(threshold.name),
                },
                {
                    label: i18n.thresholds,
                    constant: STEP_SELECT_PARAMETER,
                    nextAvailable: isThresholdComplete,
                },
                {
                    label: i18n.users,
                    constant: STEP_USER_RIGHT,
                },
            ]}
            open={isOpen}
            title={isUndefined(threshold.code) ? i18n.newThreshold : i18n.updateThreshold}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP_USER_RIGHT) && (
                <ButtonMUI
                    onClick={() => {
                        const qualityThreshold = {
                            thresholdCode: threshold.code,
                            thresholdType: threshold.thresholdType,
                            thresholds: qualityThresholds,
                        }
                        onSave(threshold, qualityThreshold)
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </ButtonMUI>
            )}
            contentStyle={{ padding: '0 12' }}
            maxWidth='xl'
        >
            {
                step => (
                    <>
                        {step === STEP_DESCRIPTION && (
                            <StepDescription
                                threshold={threshold}
                                setThreshold={setThreshold}
                            />
                        )}
                        {step === STEP_SELECT_PARAMETER && [THRESHOLD.PC, THRESHOLD.INDICE].includes(threshold.thresholdType) && (
                            <StepParameterThresholds
                                qualityThresholds={qualityThresholds}
                                setQualityThresholds={setQualityThresholds}
                            />
                        )}
                        {step === STEP_SELECT_PARAMETER && threshold.thresholdType === THRESHOLD.TAXON && (
                            <StepTaxonThresholds
                                qualityThresholds={qualityThresholds}
                                setQualityThresholds={setQualityThresholds}
                            />
                        )}
                        {step === STEP_USER_RIGHT && (
                            <StepUserRight
                                threshold={threshold}
                                setThreshold={setThreshold}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

StepperThreshold.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    selectedThreshold: PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    }),
    selectedQualityThreshold: PropTypes.shape({
        thresholdCode: PropTypes.number,
        thresholdType: PropTypes.string,
        thresholds: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
            unit: PropTypes.string,
            station: PropTypes.number,
            threshold1: PropTypes.number,
            threshold2: PropTypes.number,
            threshold3: PropTypes.number,
            threshold4: PropTypes.number,
            calciumClass: PropTypes.number,
            frType: PropTypes.number,
            fishCode: PropTypes.number,
        })),
    }),
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    })),
}

const HelpTooltipImport = () => (
    <div>
        {i18n.csvFileMustContainFollowingColumns}:
        <br />
        {i18n.threshomdCode}, {i18n.thresholdName}, {i18n.thresholdType}, {i18n.codeParameter}, {i18n.codeUnit}, {i18n.stationBssCode}, {i18n.threshold1}, {i18n.threshold2}, {i18n.threshold3} et {i18n.threshold4}
        <br />
        {i18n.inTheSameOrderToWorkCorrectly}
        <br />
        <br />
        {i18n.thresholdTypeAvailableAre}:
        <br />
        - '0' {i18n.forThe} {i18n.PCparameters}
        <br />
        - '2' {i18n.forThe} {i18n.indices}
        <br />
         - '4' {i18n.forThe} {i18n.states}
    </div>
)

const PopinImportThresholds = ({
    isOpen = false,
    close = () => {},
    newId = 1,
    onValidate = () => {},
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [fileName, setFileName] = useState()

    const [thresholds, setThresholds] = useState([])
    const [qualityThresholds, setQualityThresholds] = useState([])

    useEffect(() => {
        if (isOpen) {
            setThresholds([])
            setQualityThresholds([])
        }
    }, [isOpen])

    const openFile = e => {
        const reader = new FileReader()
        const fileOpened = e.target.files[0]

        if (isUndefined(fileOpened)) {
            return
        }

        const listKeyHeaders = ['name', 'comment', 'thresholdType', 'parameterCode', 'unit', 'station', 'threshold1', 'threshold2', 'threshold3', 'threshold4']

        reader.onload = upload => {
            const split = upload.target.result.split(',')
            if (split[0].includes('text/csv') || split[0].includes('vnd.ms-excel')) {
                const buf = Buffer.from(split[1], 'base64').toString()
                const [, ...datas] = buf.split('\n').map(line => line.replace(/(\r|")/g, ''))

                const datasFormatted = datas.map(line => {
                    const lineSplit = line.split(';')
                    return listKeyHeaders.reduce((acc, key, i) => {
                        acc[key] = lineSplit[i]
                        return acc
                    }, {})
                }).filter(obj => obj.name).map(obj => ({
                    ...obj,
                    station: qualitometers.find(q => q.code === obj.station)?.id,
                    threshold1: obj.threshold1 ? parseFloat(obj.threshold1.replace(',', '.')) : undefined,
                    threshold2: obj.threshold1 && obj.threshold2 ? parseFloat(obj.threshold2.replace(',', '.')) : undefined,
                    threshold3: obj.threshold1 && obj.threshold2 && obj.threshold3 ? parseFloat(obj.threshold3.replace(',', '.')) : undefined,
                    threshold4: obj.threshold1 && obj.threshold2 && obj.threshold3 && obj.threshold4 ? parseFloat(obj.threshold4.replace(',', '.')) : undefined,
                }))

                const datasGrouped = groupBy(datasFormatted, 'name')

                const newThresholds = keys(datasGrouped).map((key, i) => ({
                    code: newId + i,
                    comment: datasGrouped[key][0].comment,
                    name: datasGrouped[key][0].name,
                    thresholdType: datasGrouped[key][0].thresholdType,
                    status: 1,
                    users: [],
                    administrators: [],
                }))
                const newQualityThreshold = keys(datasGrouped).map((key, i) => ({
                    thresholdCode: newId + i,
                    thresholdType: datasGrouped[key][0].thresholdType,
                    thresholds: datasGrouped[key].map(t => ({
                        parameterCode: t.parameterCode,
                        unit: t.unit,
                        station: t.station,
                        threshold1: t.threshold1,
                        threshold2: t.threshold2,
                        threshold3: t.threshold3,
                        threshold4: t.threshold4,
                        listType: t.thresholdType,
                    })),
                }))

                setFileName(fileOpened.name)
                setThresholds(newThresholds)
                setQualityThresholds(newQualityThreshold)
            } else {
                this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat)
            }
        }
        reader.readAsDataURL(fileOpened)
    }

    const thresholdsFormatted = useMemo(() => {
        const parentsThresholds = thresholds.filter(th => isUndefined(th.referenceCode))
        const formatThreshold = th => {
            const qualityThreshold = qualityThresholds.find(qt => qt.thresholdCode === th.code)
            const childrens = thresholds.filter(child => child.referenceCode === `${th.code}`)
            return {
                threshold: th,
                childrens: childrens.map(formatThreshold),
                qualityThreshold,
            }
        }
        return parentsThresholds.map(formatThreshold)
    }, [qualityThresholds, thresholds])

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.threshold}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={close} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <StyledFieldSet>
                    <Grid container columnSpacing={2} rowSpacing={1} alignItems='center'>
                        <Grid item xs={3}>
                            <Button
                                variant='contained'
                                color='primary'
                                component='label'
                                fullWidth
                            >
                                <span>{i18n.importFile} {i18n.csv}</span>
                                <input
                                    type='file'
                                    accept='.csv, .CSV'
                                    hidden
                                    onChange={openFile}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={8.5}>
                            <label style={{ fontSize: '1em' }}>{fileName}</label>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Icon
                                icon='help_outline'
                                style={{ color: '#BBB' }}
                                tooltip={<HelpTooltipImport />}
                                tooltipPosition='bottom'
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                <StyledFieldSet>
                    <Grid container spacing={1}>
                        {
                            !thresholds.length && (
                                <Grid item xs={12}>
                                    <Card><h5 className='padding-1'>{i18n.noThresholds}</h5></Card>
                                </Grid>
                            )
                        }
                        {
                            thresholdsFormatted.map(({ threshold, childrens, qualityThreshold }) => (
                                <Threshold
                                    threshold={threshold}
                                    qualityThreshold={qualityThreshold}
                                    childrenThresholds={childrens}

                                    key={threshold.code}
                                />
                            ))
                        }
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container alignItems='center' justifyContent='right' columnSpacing={2} style={{ padding: '0 10' }}>
                    <Grid container item xs={3} justifyContent='flex-end'>
                        <Button
                            onClick={() => {
                                onValidate(thresholds, qualityThresholds)
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PopinImportThresholds.propTypes = {
    isOpen: PropTypes.bool,
    newId: PropTypes.number,
    close: PropTypes.func,
    onValidate: PropTypes.func,
}

export {
    Threshold,
    StepperThreshold,
    PopinImportThresholds,
}