import React from 'react'
/*
POUR REACT ROUTER V6
import { Routes } from 'react-router-dom'*/
import { Route, Switch } from 'react-router-dom'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import SieauApp from './home/components/SieauApp'
import AppStore, { history } from 'store/AppStore'
import fr from 'assets/i18n/properties_fr.json'
import en from 'assets/i18n/properties_en.json'
import envFile from '.env'
import i18n from 'simple-react-i18n'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import BubbleHelp from './home/components/BubbleHelp'
import SitePlanApp from './home/components/SitePlanApp'
import ResetPasswordApp from './home/components/ResetPasswordApp'
import Login from './home/components/Login'
import BoundaryError from 'log/components/BoundaryError'
import AuthenticatedRoute from 'AuthenticatedRoute'
import UnauthorizedApp from 'unAuthorized/components/UnauthorizedApp'
import { darkBlue, greyBlue, mainBlue, secondaryBlue, lightBlue } from 'utils/constants/ColorTheme'
import 'material-icons/iconfont/material-icons.css'

i18n.addLang(fr, ['fr', 'FR'])
i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

process.envs = {}

fetch(envFile)
    .then(response => response.text())
    .then(text => {
        text.split('\n')
            .filter((env) => env && env.length && env.includes('='))
            .forEach((val) => {
                process.envs[val.split('=')[0]] = val.split('=')[1]
            })
    }).catch(() => {})

const MAIN_BOX_SHADOW = '0px 2px 16px 0px rgb(0 0 0 / 28%)'

const customTheme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
            grey: greyBlue,
            light: lightBlue,
        },
        secondary: {
            main: secondaryBlue,
        },
        third: {
            main: darkBlue,
        },
    },
    checkbox: {},
    svgIcon: {},
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: MAIN_BOX_SHADOW,
                },
            },
        },
    },
})

render(
    <ThemeProvider theme={customTheme}>
        <Provider store={AppStore}>
            <ConnectedRouter history={history}>
                <BoundaryError>
                    <>
                        <BubbleHelp />
                        <Switch>
                            <Route path='/login' render={(props) => <Login {...props} />} />
                            <Route path='/reset' render={(props) => <ResetPasswordApp {...props} />} />
                            <Route path='/sitePlan' render={(props) => <SitePlanApp {...props} />} />
                            <Route path='/unauthorized' render={(props) => <UnauthorizedApp {...props} />} />
                            <AuthenticatedRoute render={(props) => <SieauApp {...props} />} />
                        </Switch>
                    </>
                </BoundaryError>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app')
)

/*
IMPORTANT :
Pour Maj de React Router v6, code suivant à remplacer dans chaque composant avec un Switch et des Route:
{
    this.props.children && React.cloneElement(this.props.children, {
        getLink: this.props.getLink
    })
}

Code de render() pour React Router v6:
render(
    <MuiThemeProvider muiTheme={ customTheme }>
        <Provider store={ AppStore }>
            <ConnectedRouter history={ history }>
                <Routes>
                    <Route component={ BubbleHelp }>
                            <Route path='/' component={ SieauApp } onChange={ onChange } onEnter={ onEnter }>
                                    <Route exact path='/' component={ DashboardApp } />
                                    <Route path='/job' component={ JobList }>
                                        <Redirect to='/job' />
                                    </Route>
                                    <Route path='/import/:id' component={ JobContentApp }>
                                        <Route exact path='/import/:id' component={ ImportCard } />
                                    </Route>

                                    <Route path='/account' component={ AccountApp }>
                                        <Route exact path='/account' component={ AccountUserApp } />
                                        <Route path='update' component={ AccountUserApp } />
                                    </Route>

                                    <Route path='/quality' component={ QualitometersApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ QualitometersDashboardApp } />
                                        <Route path='research' component={ QualityResearchApp } />
                                        <Route path='campaign' component={ QualityCampaigns } />
                                        <Route path='integrationOverview' component={ QualityIntegrationOverviewApp } />
                                        <Route path='conformityOverview' component={ AEPoverviewApp } />
                                        <Route path='graphic' component={ QualityGraphic } />
                                        <Route path='export' component={ ExportApp } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                    </Route>

                                    <Route path='/quality/qualityOptions' component={ QualityOptionsApp }>
                                        <Redirect to='generalOptions' />
                                        <Route path='generalOptions' component={ AdminQualitometerApp } />
                                        <Route path='indicators' component={ QualityIndicatorsApp } />
                                        <Route path='selection' component={ QualitySelectionApp } />
                                        <Route path='threshold' component={ QualityThresholdApp } />
                                    </Route>

                                    <Route path='/piezometry' component={ PiezometersApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ PiezometersDashboardApp } />
                                        <Route path='export' component={ PiezometersExportApp } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                        <Route path='integrationOverview' component={ PiezometrySituationApp } />
                                        <Route path='exportOverview' component={ PiezoExportOverviewApp } />
                                        <Route path='campaign' component={ PiezometryCampaigns } />
                                        <Route path='piezometryOptions' component={ AdminPiezometerApp } />
                                        <Route path='graphic' component={ PiezometryGraphic } />
                                    </Route>

                                    <Route path='/pluviometry' component={ StationsApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={StationsDashboard} />
                                        <Route path='export' component={ PluviometersExportApp } />
                                        <Route path='develop' component={DevelopmentApp} />
                                        <Route path='integrationOverview' component={PluviometrySituationApp} />
                                        <Route path='pluviometryOptions' component={AdminPluviometerApp} />
                                        <Route path='campaign' component={PluviometryCampaigns} />
                                        <Route path='graphic' component={PluviometryGraphicApp} />
                                    </Route>

                                    <Route path='/hydrometry' component={ StationsApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ StationsDashboard } />
                                        <Route path='export' component={ HydrometricStationsExportApp } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                        <Route path='integrationOverview' component={ HydrometrySituationApp } />
                                        <Route path='hydrometryOptions' component={ AdminHydrometricStationApp } />
                                        <Route path='campaign' component={ HydrometryCampaigns } />
                                    </Route>

                                    <Route path='/productionUnit' component={ StationsApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ StationsDashboard } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                    </Route>

                                    <Route path='/distributionUnit' component={ StationsApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ StationsDashboard } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                    </Route>

                                    <Route path='/installation' component={ InstallationsApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ InstallationsDashboardApp } />
                                        <Route path='counter' component={ InstallationCounterApp } />
                                        <Route path='campaign' component={ InstallationCampaigns } />
                                        <Route path='installationOptions' component={ InstallationsOptions } />
                                        <Route path='territoryActivities' component={ TerritoryActivities } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                    </Route>

                                    <Route path='/station/:type/:id' component={ StationApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ StationDashboardApp } />
                                        <Route path='description' component={ StationDescriptionApp } />
                                        <Route path='operation' component={ StationOperationsApp } />
                                        <Route path='operation/:code' component={ StationOperationApp } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                        <Route path='suiviPC' component={ SuiviPCApp2 } />
                                        <Route path='qualityStates' component={ QualitometerStatesApp } />
                                        <Route path='piezometricFollowUp' component={ PiezometerSuiviApp } />
                                        <Route path='hydrometricFollowUp' component={ HydrometryFollowUpApp } />
                                        <Route path='installationFollowUp' component={ InstallationFollowUpApp } />
                                        <Route path='installationAEPIndicators' component={ InstallationAEPIndicatorsApp } />
                                        <Route path='picture' component={ PictureStationApp } />
                                        <Route path='associated' component={ StationAssociatedStationApp } />
                                        <Route path='event' component={ StationEventsApp } />
                                        <Route path='event/:code' component={ StationEventTypeDispatcher } />
                                        <Route path='materiel' component={ MaterielStationApp } />
                                        <Route path='validationData' component={ PluviometerMeasuresValidationApp } />
                                        <Route path='materiel/:materialType' component={ SituationDetailApp } />
                                        <Route path='validation' component={ PiezometryValidationApp } />
                                        <Route path='STEPTerritory' component={ STEPTerritoryApp } />
                                    </Route>

                                    <Route path='quality/campaign/:id' component={ QualityCampaignApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ QualityCampaignDashboard } />
                                        <Route path='tracking' component={ QualityCampaignTrackingApp } />
                                    </Route>

                                    <Route path='piezometry/campaign/:id' component={ PiezometryCampaignApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ PiezometryCampaignDashboard } />
                                        <Route path='tracking' component={ PiezometryCampaignTrackingApp } />
                                        <Route path='materiel' component={ PiezometryCampaignMaterielsApp } />
                                    </Route>

                                    <Route path='pluviometry/campaign/:id' component={ PluviometryCampaignApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ PluviometryCampaignDashboard } />
                                        <Route path='tracking' component={ PluviometryCampaignTrackingApp } />
                                    </Route>

                                    <Route path='/hydrometry/campaign/:id' component={ HydrometryCampaignApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ HydrometryCampaignDashboard } />
                                        <Route path='tracking' component={ HydrometryCampaignTrackingApp } />
                                    </Route>

                                    <Route path='/installation/campaign/:id' component={ InstallationCampaignApp } >
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ InstallationCampaignDashboard } />
                                        <Route path='tracking' component={ InstallationCampaignTrackingApp } />
                                        <Route path='validation' component={ InstallationCampaignValidationApp } />
                                        <Route path='visit/summary/:idInstallation' component={ InstallationVisitSummary } />
                                        <Route path='visit/:idInstallation/:step' component={ InstallationVisitApp } />
                                    </Route>

                                    <Route path='/referencial' component={ ReferencialApp }>
                                        <Redirect to='/referencial/dashboard' />
                                        <Route path='/referencial/dashboard' component={ ReferencialDashboardApp } />
                                        <Route path='parameter' component={ ParametersApp } key='parameter' />
                                        <Route path='taxon' component={ TaxonsApp } />
                                        <Route path='taxon/:code' component={ TaxonApp } />
                                        <Route path='method' component={ MethodsApp } />
                                        <Route path='method/:code' component={ MethodApp } />
                                        <Route path='support' component={ SupportsApp } />
                                        <Route path='support/:code' component={ SupportApp } />
                                        <Route path='contributor' component={ ContributorsApp } />
                                        <Route path='contributor/:code' component={ ContributorApp } />
                                        <Route path='city' component={ CitiesApp } />
                                        <Route path='city/:code' component={ CityApp } />
                                        <Route path='aquifer' component={ AquifersApp } />
                                        <Route path='aquifer/:code' component={ AquiferApp } />
                                        <Route path='contact' component={ ContactsApp } />
                                        <Route path='contact/:code' component={ ContactApp } />
                                        <Route path='hydrogeologicalEntity' component={ HydrogeologicalEntitiesApp } />
                                        <Route path='hydrogeologicalEntity/:code' component={ HydrogeologicalEntityApp } />
                                        <Route path='network' component={ NetworksApp } />
                                        <Route path='network/:code' component={ NetworkApp } />
                                        <Route path='fraction' component={ FractionsApp } />
                                        <Route path='fraction/:code' component={ FractionApp } />
                                        <Route path='unit' component={ UnitsApp } />
                                        <Route path='unit/:code' component={ UnitApp } />
                                        <Route path='watermass' component={ WatermassesApp } />
                                        <Route path='watershed' component={ WatershedsApp } />
                                        <Route path='sandreCodes' component={ SandreCodesApp } />
                                        <Route path='sandreCode/:field/:code' component={ SandreCodeApp } />
                                        <Route path='lexicons' component={ LexiconsApp } />
                                        <Route path='lexicon/:field/:code' component={ LexiconApp } />
                                        <Route path='materielTypes' component={ MatTypesApp } />
                                        <Route path='materielType/:field/:code' component={ MatTypeApp } />
                                        <Route path='managementUnit' component={ ManagementUnitsApp } />
                                        <Route path='managementUnit/:id' component={ ManagementUnitApp } />
                                        <Route path='culture' component={ CulturesApp } />
                                        <Route path='culture/:id' component={ CultureApp } />
                                        <Route path='culturesFamily' component={ CulturesFamiliesApp } />
                                        <Route path='culturesFamily/:id' component={ CulturesFamilyApp } />
                                        <Route path='activity' component={ ActivitiesApp } />
                                        <Route path='activity/:code' component={ ActivityApp } />
                                        <Route path='develop' component={ DevelopmentApp } />
                                    </Route>

                                    <Route path='/referencialItem' component={ ReferencialItemApp }>
                                        <Route path='watershed/:code' component={ WatershedApp } />
                                        <Route path='watershed/:code/import' component={ DevelopmentApp } />
                                        <Route path='watermass/:code' component={ WatermassApp } />
                                        <Route path='watermass/:code/import' component={ DevelopmentApp } />
                                        <Route path='parameter/:code' component={ ParameterApp } key='parameterId' />
                                        <Route path='parameter/:code/import' component={ DevelopmentApp } />
                                    </Route>

                                    <Route path='/materiel' component={ MaterielApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ MaterielDashboardApp } />
                                        <Route path='inventory' component={ InventoryApp } />
                                        <Route path='central' component={ CentralsApp } />
                                        <Route path='central/:id' component={ CentralApp } />
                                        <Route path='telecom' component={ TelecomsApp } />
                                        <Route path='telecom/:id' component={ TelecomApp } />
                                        <Route path='powerSupply' component={ PowerSuppliesApp } />
                                        <Route path='powerSupply/:id' component={ PowerSupplyApp } />
                                        <Route path='sensor' component={ SensorsApp } />
                                        <Route path='sensor/:id' component={ SensorApp } />
                                        <Route path='sim' component={ SimsApp } />
                                        <Route path='sim/:id' component={ SimApp } />
                                        <Route path='variousMateriel' component={ VariousMaterielsApp } />
                                        <Route path='variousMateriel/:id' component={ VariousMaterielApp } />
                                        <Route path='equipment' component={ EquipmentsApp } />
                                        <Route path='equipment/:id' component={EquipmentApp} />
                                        <Route path='subscription' component={SubscriptionsApp} />
                                        <Route path='subscription/:id' component={SubscriptionApp} />
                                    </Route>

                                    <Route path='/materiel/administration' component={ MaterielOptionsApp }>
                                        <Redirect to='materiel' />
                                        <Route path='type' component={ MaterielTypeApp } />
                                        <Route path='materiel' component={ AdministrationMaterielApp } />
                                    </Route>

                                    <Route path='/globalEvents' component={ GlobalEventsApp } />

                                    <Route path='/administration' component={ AdministrationApp }>
                                        <Redirect to='settings' />
                                        <Route path='settings' component={ SettingApp } />
                                        <Route path='stats' component={ AdminStatsApp } />
                                        <Route path='user' component={ UsersApp } />
                                        <Route path='user/:login' component={ UserApp } />
                                        <Route path='cartography' component={ CartographyApp } />
                                        <Route path='cartography/:id' component={ ThemeApp } />
                                        <Route path='homepage' component={ AdministrationHomePageApp } />
                                        <Route path='contents' component={ AdministrationContentsApp } />
                                        <Route path='problemSolution' component={ ProblemSolutionApp } />
                                    </Route>

                                    <Route path='/alert' component={ AlertApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ AlertMapApp } />
                                        <Route path='/alert/follow/:id/:type' component={ FollowApp } />
                                        <Route path='/alert/config' component={ AlertDashboardPanel } />
                                        <Route path='/alert/config/:id' component={ AlertConfigurationPanel } />
                                        <Route path='/alert/newCms' component={ AlertNewCmsModal } />
                                    </Route>

                                    <Route path='/dossiers' component={ ExploitationsApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ ExploitationsDashboardApp } />
                                        <Route path=':id/dashboard' component={ ExploitationDashboardPanel } />
                                        <Route path=':id/volumes' component={ ExploitationVolumesPanel } />
                                        <Route path=':id/materiels' component={ ExploitationMaterielsPanel } />
                                        <Route path=':id/documents' component={ ExploitationDocumentsPanel } />
                                    </Route>

                                    <Route path='/contents/:categoryId' component={ ContentsApp } />

                                    <Route path='/contents/:categoryId/:contentId' component={ CmsEventForm } />

                                    <Route path='/arrests' component={ ArrestsApp } />
                                    <Route path='/vigilances' component={ VigilancesApp } />
                                    <Route path='/documents' component={ DocumentsApp } />

                                    <Route path='/messages' component={ DashboardMessages } />

                                    <Route path='/surveys' component={ SurveysApp } >
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ SurveysDashboardApp } />
                                    </Route>

                                    <Route path='/survey/:id' component={ SurveyApp } >
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ SurveyDashboardApp } />
                                        <Route path='stats' component={ SurveyStatsApp } />
                                        <Route path='validation' component={ SurveyValidationApp } />
                                    </Route>

                                    <Route path='territory' component={ TerritoriesApp }>
                                        <Redirect to='dashboard' />
                                        <Route path='dashboard' component={ TerritoryDashboardApp } />
                                        <Route path='scenarios' component={ DevelopmentApp } />
                                        <Route path='settings' component={ DevelopmentApp } />
                                    </Route>

                                    <Route path='/messages/:login' component={ ConversationMessages } />
                            </Route>
                            <Route path='/login' component={ Login } />
                            <Route path='/reset' component={ ResetPasswordApp } />
                    </Route>
                    <Route path='/sitePlan' component={ SitePlanApp } />
                </Routes>
            </ConnectedRouter>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('app'),
)*/
