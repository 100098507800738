import Card from 'components/card/Card'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class AlertPanel extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Card cardStyle={{ borderRadius: 0 }} round>
                <div className={ `row no-margin valign-wrapper ${this.props.color}` }>
                    <div className='col s3 no-padding'>
                        <p className='center-align no-margin bold'><i
                            className='material-icons'
                        >{ this.props.icon }</i></p>
                    </div>
                    <div className='col s9 white padding-left-1'>
                        <div className='row no-margin'>
                            <b className='bold'>{ this.props.title }</b>
                        </div>
                        <div className='row no-margin'>
                            { this.props.comment }
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
}

AlertPanel.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    comment: PropTypes.string,
    round: PropTypes.bool,
}
export default AlertPanel
