import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { setLocalStorageJson, sieauTooltip } from '../../../utils/FormUtils'
import Card from '../../../components/card/Card'
import Row from '../../../components/react/Row'
import Select from '../../../components/forms/Select'
import Icon from '../../../components/icon/Icon'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import validateImg from '../../../assets/pictures/pictos/validate.png'
import purgeImg from '../../../assets/pictures/pictos/purge.png'
import importImg from '../../../assets/pictures/pictos/import.png'
import compensationImg from '../../../assets/pictures/pictos/compensation.png'
import correctionImg from '../../../assets/pictures/pictos/correction.png'
import optionsImg from '../../../assets/pictures/pictos/options.png'
import thresholdImg from '../../../assets/pictures/pictos/threshold.png'
import eventImg from '../../../assets/pictures/pictos/event.png'
import ChartTabs from '../../../components/echart/ChartTabs'
import { orderBy, uniqBy } from 'lodash'
import { getStationTitle } from '../../../utils/StationUtils'
import HydrometryAction from '../../actions/HydrometryAction'
import ValidationHydroToolPanel2 from './tools/ValidationHydroToolPanel2'
import TableViewValidationHydroMode2 from './displayMode/TableViewValidationHydroMode2'
import GraphicValidationHydroMode from './displayMode/GraphicValidationHydroMode'
import useTitle from '../../../utils/customHook/useTitle'
import { EVENT, IMPORT } from '../../../home/constants/RouteConstants'
import { THRESHOLDS } from '../../../alerting/constants/AlertConstants'
import MessageCard from '../../../components/card/MessageCard'
import PropTypes from 'prop-types'
import SimpleOptionsToolPanel from 'piezometry/components/validation/tools/SimpleOptionsToolPanel'
import moment from 'moment'
import DeleteHydroToolPanel from './tools/DeleteHydroToolPanel'
import EventsToolPanelHydro from './tools/EventsToolPanelHydro'
import {
    WINDOW,
    COMPENSATION,
    CORRECTION,
    OPTIONS,
    PURGE,
    VALIDATION,
    RESET,
    DELETE,
} from '../../constants/HydrometryConstants'
import { getFullDate } from '../../../utils/DateUtil'
import EventsAction from '../../../events/actions/EventsAction'
import CompensationHydroToolPanel from './tools/CompensationHydroToolPanel'
import CorrectionHydroToolPanel from './tools/CorrectionHydroToolPanel'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import ThresholdsTab from '../../../alerting/components/dashboard/tabPopin/ThresholdsTab'
import Checkbox from '../../../components/forms/Checkbox'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import ImportHydroToolPanel from './tools/ImportHydroToolPanel'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_STATION_HYDRO_VALIDATION } from '../../../account/constants/AccessRulesConstants'
import { push } from 'connected-react-router'
import { setActions } from '../../../components/ActionUtil'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import useUpdateEffect from '../../../utils/customHook/useUpdateEffect'
import GroupedHydroPanel from '../stationDashboard/GroupedHydroPanel'
import { Grid } from '@mui/material'

const IconTool = ({
    icon,
    toolIcon,
    height,
    width,
    tooltip,
    setTool,
    iconSelected,
    isPreview,
}) => {
    const dispatch = useDispatch()
    return (
        <li style={{ display: 'inline-block' }}>
            <img
                className={`validationIcon clickable no-margin ${toolIcon === iconSelected && 'activated'}`}
                style={{ height, width }}
                onClick={() => isPreview ? dispatch(ToastrAction.warning(i18n.pleaseSaveModifs)) : setTool(toolIcon)}
                src={icon} {...sieauTooltip(tooltip)}
            />
        </li>
    )
}
IconTool.propTypes = {
    icon: PropTypes.string,
    toolIcon: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    tooltip: PropTypes.string,
    setTool: PropTypes.func,
    iconSelected: PropTypes.string,
    isPreview: PropTypes.bool,
}

const listView = [VALIDATION, PURGE, EVENT, THRESHOLDS]

const HydrometryValidationApp = ({}) => {
    const {
        hydrometricStation,
        hydrometricStations,
        hydroMeasures,
        hydrometryDataTypes,
        hydroStatistics,
        hydrometryThresholds,
        applicationSettings,
        contributors,
        lastMeasureHydro,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydroMeasures: store.HydrometryReducer.hydroMeasures,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        contributors: store.ContributorReducer.contributors,
        lastMeasureHydro: store.HydrometryReducer.hydroLastMeasures,
    }), shallowEqual)

    const [dataType, setDataType] = useState()
    const [tableView, setTableView] = useState(false)
    const [groupFunc, setGroupFunc] = useState('all')
    const [tool, setTool] = useState(VALIDATION)
    const [selection, setSelection] = useState(WINDOW)
    const [selectionData, setSelectionData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [minDate, setMinDate] = useState(moment().subtract('91', 'day').valueOf())
    const [maxDate, setMaxDate] = useState()
    const [value, setValue] = useState()
    const [initialPoint, setInitialPoint] = useState()
    const [isChange, setIsChange] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [isThresholShow, setIsThresholShow] = useState(true)
    const [serieLinePrevious, setSerieLinePrevious] = useState([])
    const [graphicOptions, setGraphicOptions] = useState({})
    const [valueCorrection, setValueCorrection] = useState(1)
    const [measuresChangesValidation, setMeasuresChangesValidation] = useState([])

    const dispatch = useDispatch()


    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: getStationTitle(hydrometricStation),
        href: `station/hydrometry/${hydrometricStation.id}`,
    }, {
        title: i18n.validation,
        href: `station/hydrometry/${hydrometricStation.id}/validationHydro`,
    }], [hydrometricStation.id])

    useEffect(() => {
        if (!componentHasHabilitations(H_STATION_HYDRO_VALIDATION)) {
            dispatch(push('/unauthorized'))
        }
    }, [dispatch])

    useEffect(() => {
        if (!hydrometricStations.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        setActions({
            arrowNav: getStationArrowNav('hydrometry', hydrometricStations, hydrometricStation.id, s => dispatch(push(`/station/hydrometry/${s.id}/validationHydro`))),
        })
    }, [dispatch, hydrometricStation, hydrometricStations])

    useEffect(() => {
        setLocalStorageJson('CHART_TABS', { active: 90 })
        dispatch(HydrometryAction.fetchHydrometricStations())
        dispatch(HydrometryAction.fetchHydrometryDataTypes())
        dispatch(HydrometryAction.fetchHydrometricThresholds())
        dispatch(HydrometryAction.fetchHydroStatistics(hydrometricStation.id)).then(stats => setDataType(stats[0]?.typeId))
        dispatch(AdministrationAction.fetchApplicationSettings())
        dispatch(ContributorAction.fetchContributors())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hydrometricStation.id])

    const fetchThresholds = () => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
    }

    useUpdateEffect(() => {
        if (startDate > endDate && startDate && tableView) {
            setStartDate(endDate)
            setEndDate(startDate)
        }
    }, [startDate, endDate])

    useEffect(() => {
        setDataLoaded(false)
        dispatch(HydrometryAction.fetchHydroLastMeasures([hydrometricStation.id]))
        if (dataType) {
            dispatch(HydrometryAction.loadHydroChronicMeasures([{
                stationId: hydrometricStation.id,
                dataType,
                groupFunc,
                startDate: moment(minDate).subtract(1, 'day').valueOf(),
                endDate: moment(maxDate).add(1, 'day').valueOf(),
            }])).then(() => setDataLoaded(true))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hydrometricStation.id, dataType, minDate, maxDate, isChange, groupFunc])

    const hydroMeasuresFormat = useMemo(() => {
        return hydroMeasures[0]?.measures || []
    }, [hydroMeasures])

    const hydroDataTypes = useMemo(() => {
        const allHydroDataTypes = hydrometryDataTypes.map(v => ({
            id: v.id,
            label: v.label,
        }))
        return uniqBy([
            ...allHydroDataTypes,
            { id: 4, label: i18n.height, unit: 'm' },
            { id: 5, label: i18n.flow, unit: 'm3' },
        ], 'id')
    }, [hydrometryDataTypes])

    const createTechnicalEvent = (toolState) => {
        const getDates = () => `${i18n.fromDate} ${getFullDate(startDate)} ${i18n.to} ${getFullDate(endDate)}`
        const eventProps = (() => {
            switch (toolState.mode) {
                case PURGE:
                    return {
                        comment: `${i18n.purge} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case RESET:
                    return {
                        comment: `${i18n.reseting} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case DELETE:
                    return {
                        comment: `${i18n.deleteAction} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case VALIDATION:
                    return {
                        comment: `${i18n.validation} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case COMPENSATION:
                    return {
                        comment: `${i18n.compensation} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case CORRECTION:
                    return {
                        comment: `${i18n.appliedCorrections} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case IMPORT:
                    return {
                        comment: `${i18n.importName} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                default:
                    return { comment: '' }
            }
        })()

        const newEvent = {
            eventType: 'T',
            date: moment().valueOf(),
            eventHour: moment().valueOf(),
            startDate,
            endDate,
            graph: '1',
            ...eventProps,
        }
        dispatch(EventsAction.addEvent('hydrometry', hydrometricStation.id, newEvent))
    }

    const measureBetweenStartEndDates = useMemo(() => {
        return hydroMeasuresFormat.filter(p => p.date >= startDate && p.date <= endDate) || []
    }, [hydroMeasuresFormat, startDate, endDate])

    const statsUsed = orderBy(hydroStatistics, ['order', 'typeId'], 'asc').map(h => ({
        id: h.typeId,
        label: h.label,
    }))

    if (!hydroStatistics.length) {
        return <MessageCard>{i18n.noDataToDisplay}</MessageCard>
    }

    return (
        <div className='row no-margin validationPiezoApp padding-left-2 padding-top-1'>
            <div className='col s9 no-padding'>
                <div>
                    <Card>
                        <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                            <Grid item>
                                <ChartTabs
                                    style={{ top: 10, left: 50 }}
                                    onChangeDate={(changes, forced) => {
                                        if (minDate !== changes.minDate || maxDate !== changes.maxDate || forced) {
                                            setMinDate(changes.minDate)
                                            setMaxDate(changes.maxDate)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item paddingTop={1.5}>
                                <GroupedHydroPanel
                                    hydroGrouped={ groupFunc }
                                    setHydroGrouped={v => setGroupFunc(v)}
                                    left={530}
                                />
                            </Grid>
                        </Grid>
                        {!hydroMeasuresFormat.length ? (
                            <div style={{ paddingTop: 50 }}>
                                <MessageCard>{i18n.notExistDataPeriod}</MessageCard>
                            </div>
                        ) : tableView ? (
                            <TableViewValidationHydroMode2
                                selection={selection}
                                selectionData={selectionData}
                                setSelectionData={setSelectionData}
                                measuresData={hydroMeasures}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                setValue={setValue}
                                setInitialPoint={setInitialPoint}
                            />
                        ) : (
                            <GraphicValidationHydroMode
                                tool={tool}
                                dataLoaded={dataLoaded}
                                selection={selection}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                hydroMeasuresFormat={hydroMeasuresFormat}
                                measureToUpdate={measureBetweenStartEndDates}
                                graphicOptions={graphicOptions}
                                startDate={startDate}
                                endDate={endDate}
                                dataType={dataType}
                                hydroDataTypes={hydroDataTypes}
                                minDate={minDate}
                                maxDate={maxDate}
                                addLinePrevious={isPreview}
                                setAddLinePrevious={setIsPreview}
                                isPreview={isPreview}
                                isThresholShow={isThresholShow}
                                serieLinePrevious={serieLinePrevious}
                                setSerieLinePrevious={setSerieLinePrevious}
                                hydrometryThresholds={hydrometryThresholds}
                                contributors={contributors}
                                setValueCorrection={setValueCorrection}
                                measuresChangesValidation={measuresChangesValidation}
                            />
                        )}
                    </Card>
                </div>
            </div>
            <div className='col s3'>
                <Card>
                    <Row className='padding-top-1 valign-wrapper'>
                        <Select
                            col={ 10 }
                            value={ dataType }
                            options={ statsUsed }
                            label={ i18n.dataType }
                            onChange={ setDataType }
                            noNullValue
                            noSort
                        />
                        <div className='col s2'>
                            <Icon
                                icon={ tableView ? 'list' : 'multiline_chart' }
                                onClick={ () => listView.includes(tool) && setTableView(!tableView) }
                                style={{
                                    fontSize: '2.5rem',
                                    color: !listView.includes(tool) ? 'grey' : '#2196F3',
                                }}
                                tooltip={tableView ? i18n.graphicMode : i18n.tableMode}
                            />
                        </div>
                    </Row>
                </Card>
                <Card>
                    <ul style={{ textAlign: 'center', 'margin-top': 5, 'margin-bottom': 5, 'padding-top': 5, 'padding-bottom': 5 }}>
                        <IconTool
                            icon={validateImg}
                            toolIcon={VALIDATION}
                            height={35} width={35}
                            tooltip={i18n.validation}
                            setTool={setTool}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                        <IconTool
                            icon={purgeImg}
                            toolIcon={PURGE}
                            height={35}
                            width={30}
                            tooltip={i18n.deleting}
                            setTool={setTool}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                        {!tableView && (
                            <>
                                <IconTool
                                    icon={importImg}
                                    toolIcon={IMPORT}
                                    height={35}
                                    width={30}
                                    tooltip={i18n.manualImport}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                                <IconTool
                                    icon={compensationImg}
                                    toolIcon={COMPENSATION}
                                    height={35} width={50}
                                    tooltip={i18n.compensation}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                                <IconTool
                                    icon={correctionImg}
                                    toolIcon={CORRECTION}
                                    height={35}
                                    width={45}
                                    tooltip={i18n.driftingCorrection}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                                <IconTool
                                    icon={optionsImg}
                                    toolIcon={OPTIONS}
                                    height={35}
                                    width={35}
                                    tooltip={i18n.chartOptions}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                            </>
                        )}
                        <IconTool
                            icon={thresholdImg}
                            toolIcon={THRESHOLDS}
                            setTool={setTool}
                            height={35}
                            width={35}
                            tooltip={i18n.thresholds}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                        <IconTool
                            icon={eventImg}
                            toolIcon={EVENT}
                            height={35}
                            width={35}
                            tooltip={i18n.events}
                            setTool={setTool}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                    </ul>
                </Card>
                <div className='padding-top-1' />
                {tool === VALIDATION && (
                    <ValidationHydroToolPanel2
                        selection={selection}
                        setSelection={setSelection}
                        setSelectionData={setSelectionData}
                        dates={{ startDate, endDate }}
                        value={value}
                        setValue={setValue}
                        initialPoint={initialPoint}
                        setInitialPoint={setInitialPoint}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        createTechnicalEvent={createTechnicalEvent}
                        setIsPreview={setIsPreview}
                        hydroMeasuresFormat={hydroMeasuresFormat}
                        setMeasuresChangesValidation={setMeasuresChangesValidation}
                    />
                )}
                {tool === PURGE && (
                    <DeleteHydroToolPanel
                        id={hydrometricStation.id}
                        dataType={dataType}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        isPreview={isPreview}
                        setIsPreview={setIsPreview}
                        dataToReset={measureBetweenStartEndDates}
                        createTechnicalEvent={createTechnicalEvent}
                    />
                )}
                {tool === COMPENSATION && (
                    <CompensationHydroToolPanel
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        createTechnicalEvent={createTechnicalEvent}
                        measures={measureBetweenStartEndDates}
                        serieLinePrevious={serieLinePrevious}
                        setSerieLinePrevious={setSerieLinePrevious}
                        setIsPreview={setIsPreview}
                        measuresData={hydroMeasures}
                    />
                )}
                {tool === CORRECTION && (
                    <CorrectionHydroToolPanel
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        createTechnicalEvent={createTechnicalEvent}
                        measures={measureBetweenStartEndDates}
                        setSerieLinePrevious={setSerieLinePrevious}
                        isPreview={isPreview}
                        setIsPreview={setIsPreview}
                        valueCorrection={valueCorrection}
                        setValueCorrection={setValueCorrection}
                        measuresData={hydroMeasures}
                        serieLinePrevious={serieLinePrevious}
                    />
                )}
                {tool === EVENT && (
                    <EventsToolPanelHydro
                        hydrometricStation={hydrometricStation}
                    />
                )}
                {tool === THRESHOLDS && (
                    <>
                        <ThresholdsTab
                            thresholds={hydrometryThresholds.filter(f => parseInt(f.dataType) == dataType && parseInt(f.stationId) == hydrometricStation.id)}
                            station={hydrometricStation}
                            onReload={() => fetchThresholds()}
                            whiteCard
                            withoutDataTypeAnnotation
                            selectedDataType={dataType.toString()}
                        />
                        <div style={{ marginTop: 10, backgroundColor: 'white', padding: 5 }}>
                            <Checkbox
                                label={ i18n.displayThresholds }
                                checked={isThresholShow}
                                onChange={() => setIsThresholShow(!isThresholShow)}
                            />
                        </div>
                    </>
                )}
                {tool === IMPORT && (
                    <ImportHydroToolPanel
                        startDate={ startDate }
                        setStartDate={ setStartDate }
                        endDate={ endDate }
                        setEndDate={ setEndDate }
                        applicationSettings={ applicationSettings }
                        selectedDataType={ dataType }
                        id={ hydrometricStation.id }
                        hydrometricStation={ hydrometricStation }
                        hydroMeasuresFormat={ hydroMeasuresFormat }
                        isChange={ isChange }
                        setIsChange={ setIsChange }
                        hydroLastMeasures={ lastMeasureHydro[0] }
                        createTechnicalEvent={createTechnicalEvent}
                    />
                )}
                {tool === OPTIONS && (
                    <SimpleOptionsToolPanel
                        showTitle={false}
                        updateState={setGraphicOptions}
                    />
                )}
            </div>
        </div>
    )
}

export default HydrometryValidationApp