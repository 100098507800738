import QualityChart from 'components/echart/QualityChart'
import { groupBy, isUndefined, keys, uniqBy } from 'lodash'
import AnalysisAction from 'quality/actions/AnalysisAction'
import QualityAction from 'quality/actions/QualityAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import { THRESHOLD_TYPE, calculateThresholdResult, getRemarks, getTabColorsBySize, getThreshold, getThresholdType } from 'utils/AnalyseUtils'
import useListIndexed from 'utils/customHook/useListIndexed'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
// import useBoolean from 'utils/customHook/useBoolean'
// import { SuperParameterGraphModal } from 'quality/components/qualityComponents/ParameterGraph'

const TestApp = () => {
    const dispatch = useDispatch()
    const {
        parameters,
        units,
        qualityThresholds,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    // const {
    //     value: isOpen,
    //     setTrue: open,
    //     setFalse: close,
    // } = useBoolean(false)

    const thresholds = qualityThresholds.find(th => th.thresholdCode === '17')?.thresholds ?? []

    const parametersIndexed = useListIndexed(parameters, 'code')
    const unitsIndexed = useListIndexed(units, 'code')

    const [analysis, setAnalysis] = useState([])

    useEffect(() => {
        dispatch(ParameterAction.fetchParameters())
        dispatch(UnitAction.fetchUnits())
        dispatch(QualityAction.fetchQualitometersLight())
        dispatch(QualityAction.fetchQualityThresholds([{ code: 17, thresholdType: '0' }]))

        const analysisFilter = {
            lightMode: true,

            parameters: ['1107', '1108', '1101', '1340'],
            stations: [33],
        }

        AnalysisAction.getAnalysis(analysisFilter)
            .then(list => setAnalysis(list.map(a => new DtoAnalysisLight(a))))
    }, [])

    const formattedAnalysis = analysis.map(a => ({
        ...a,
        ...calculateThresholdResult(a, thresholds),
    }))

    const groupAnalysis = groupBy(formattedAnalysis, a => `${a.parameter}#${a.unit || 'unknown'}`)
    const keysAnalysis = keys(groupAnalysis)
    const series = keysAnalysis.flatMap(key => {
        const [paramCode, unitCode] = key.split('#')
        // const stationOptions = stationsOptions.find(o => o.id === parseStationId)
        const {
            [key]: analysisFiltered = [],
        } = groupAnalysis
        // const parameter = parameters.find(p => p.code === paramCode)
        // const qualitometer = qualitometers.find(q => q.id === parseStationId)
        return {
            uniqId: key,
            name: parametersIndexed[paramCode]?.displayLabel,
            unit: unitsIndexed[unitCode]?.symbolWithCode,
            yAxisId: key,
            xAxisId: paramCode,
            dataList: analysisFiltered.map(a => ({
                date: a.sampleDate,
                value: a.result,
                unit: unitsIndexed[a.unit]?.symbolWithCode ?? i18n.unknownUnit,
                marker: getRemarks(a.remark)?.plot,
                color: a.color,
            })).filter(a => !isUndefined(a.value) && !isUndefined(a.date)),
        }
    })

    const yAxis = keysAnalysis.map(key => {
        const [paramCode, unitCode] = key.split('#')
        return {
            name: unitsIndexed[unitCode]?.symbolWithCode ?? i18n.unknownUnit,
            yAxisId: key,
            gridId: paramCode,
            // min: minY,
            // max: maxY,
            // showSplitLine: showYSplitLines,
        }
    })

    const parametersCode = uniqBy(formattedAnalysis, 'parameter').map(a => a.parameter || 'unknown')

    const grids = parametersCode.map((code) => ({
        top: 50,
        height: 200,
        gridId: code,
    }))

    const xAxis = parametersCode.map(code => ({
        gridId: code,
        xAxisId: code,
    }))

    const getColorTab = (paramThreshold, thresholdType) => {
        if (!paramThreshold) {
            return []
        }
        if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
            return ['red', 'blue', 'red']
        }
        const allColorTab = getTabColorsBySize()
        const nbThresholds = [1, 2, 3, 4].filter(nb => hasValue(paramThreshold[`threshold${nb}`])).length + 1
        return allColorTab[nbThresholds]
    }

    const thresholdFormated = keysAnalysis.map(key => {
        const [paramCode, unitCode] = key.split('#')
        const threshold = getThreshold(thresholds, paramCode, unitCode, 33)
        if (isUndefined(threshold)) return []

        const thresholdType = getThresholdType(threshold)
        const colorList = getColorTab(threshold, thresholdType)

        if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
            return {
                xAxisId: paramCode,
                yAxisId: key,
                colorList,
                dataList: [
                    {
                        value: threshold.threshold1,
                        color: 'red',
                    },
                    {
                        value: threshold.threshold2,
                        color: 'red',
                    },
                ],
            }
        }


        return {
            xAxisId: paramCode,
            yAxisId: key,
            colorList,
            dataList: [1, 2, 3, 4].map(nb => ({
                value: threshold[`threshold${nb}`],
                color: colorList[nb],
            })).filter(t => !isUndefined(t.value)),
        }
    })

    const markLines = [{
        xAxisId: '1340',
        yAxisId: '1340#162',
        dataList: [{
            value: 60,
            label: 'test',
        }],
    }]

    return (
        <div>
            nb analyse: {analysis.length ?? 0}
            {
                analysis.length > 0 && (
                    <div style={{ width: '90%', backgroundColor: 'white' }}>
                        <QualityChart
                            title='Nitrates'
                            series={series}
                            grids={grids}
                            xAxis={xAxis}
                            yAxis={yAxis}
                            thresholds={thresholdFormated}
                            markLines={markLines}

                            withToolTypeLine
                            withToolTypeBar
                            withToolTypeStack
                            withToolLog
                            withToolThreshold
                            withToolLegend
                            withToolMarker
                            withToolLine
                            withArea
                            withDataZoom
                            withHidingGrid
                            withHidingYAxis
                        />
                    </div>
                )
            }
            bas graphique
        </div>
    )

    // return (
    //     <>
    //         <div style={{ cursor: 'pointer', height: '400px', width: '400px', backgroundColor: 'pink' }} onClick={open}>
    //             CLICK
    //         </div>
    //         <SuperParameterGraphModal
    //             isOpen={isOpen}
    //             closeGraph={close}

    //             parameter='1340'
    //             qualitometer={33}
    //             thresholds={thresholds}
    //             additionalData={[]}

    //             graphOptions={{}}
    //         />
    //     </>
    // )
}

export default TestApp