import fetch from 'isomorphic-fetch'
import { flatten, isArray, isFunction, isNumber, isString, keys, orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import EventsAction from '../../events/actions/EventsAction'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import InstallationAction from '../../installation/actions/InstallationAction'
import LogAction from '../../log/actions/LogAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import PluviometryAction from '../../pluviometry/actions/PluviometryAction'
import ProductionUnitAction from '../../productionUnit/actions/ProductionUnitAction'
import QualityAction from '../../quality/actions/QualityAction'
import PiezometerStationAction from '../../station/actions/PiezometerStationAction'
import {
    checkAuth,
    checkError,
    genericFetch,
    genericPromise,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import {
    ADD_FOLLOW_MEASURES,
    ADD_FOLLOW_SITE,
    RECEIVE_POLLUTION_SOURCE_ACTIVITIES,
    RECEIVE_POLLUTION_SOURCE_PARAMETERS,
    RECEIVE_POLLUTION_SOURCE_PARAMETERS_FULL_DATA,
    RECEIVE_TEMP_FORMATTED_SITES,
    RESET_ALERT_STORE,
    RESET_FOLLOW,
    RESET_POLLUTION_SOURCE_ACTIVITES,
    RESET_POLLUTION_SOURCE_PARAMETERS, SET_THRESHOLD_PREVI_DATA,
} from '../constants/AlertConstants'
import { HYDRO } from '../constants/MeasureTypeContants'
import { MEASURE_COTE } from '../../piezometry/constants/PiezometryConstants'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import { ProductionUnitActionConstant } from 'productionUnit/reducers/ProductionUnitReducer'
import { PluviometryActionConstant } from 'pluviometry/reducers/PluviometryReducer'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'

const DEPTH = -1

const AlertAction = {
    testMail: (emails, testType) => dispatch => fetch(ApplicationConf.alert.testMail(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({
            emails,
            testType,
        }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(() => {
            dispatch(ToastrAction.success(i18n.mailSent))
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.error + i18n.email} : ${err}`))
            dispatch(ToastrAction.error(i18n.error + i18n.hydrometricStations))
        }),

    receiveFollowSite: followSite => ({
        type: ADD_FOLLOW_SITE,
        followSite,
    }),
    receiveFollowMeasures: followMeasures => ({
        type: ADD_FOLLOW_MEASURES,
        followMeasures,
    }),
    addFollowSite: (followSite, startDate, endDate) => dispatch => {
        dispatch(AlertAction.receiveFollowSite(followSite))
        if (followSite.measures) {
            dispatch(AlertAction.receiveFollowMeasures(followSite.measures))
        } else if (`${followSite.stationType}` === HYDRO) {
            HydrometryAction.promiseHydrometerMeasures(followSite.id, startDate, endDate)
                .then((json = []) => dispatch(AlertAction.receiveFollowMeasures(json)))
        }
    },
    resetFollowSite: () => ({
        type: RESET_FOLLOW,
    }),
    getMeasures: () => dispatch => {
        dispatch(HydrometryAction.fetchHydrometrySituation())
    },

    resetPollutionSourceActivities() {
        return { type: RESET_POLLUTION_SOURCE_ACTIVITES }
    },

    receivePollutionSourceActivities(pollutionSourceActivities) {
        return { type: RECEIVE_POLLUTION_SOURCE_ACTIVITIES, payload: pollutionSourceActivities }
    },
    fetchPollutionActivities(parameters) {
        return (dispatch) => {
            return fetch(ApplicationConf.pollution.activitiesParams(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ parameters }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    const pollutions = flatten(json)
                    const pollutionsUniq = uniqBy(pollutions, 'activityCode')
                    const pollutionsSort = orderBy(pollutionsUniq, 'rate', 'desc')
                    dispatch(AlertAction.receivePollutionSourceActivities(pollutionsSort))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    resetPollutionSourceParameters() {
        return { type: RESET_POLLUTION_SOURCE_PARAMETERS }
    },
    promisePollutionSourceParameters(activity) {
        return genericPromise(ApplicationConf.pollution.parameters(activity))
    },
    fetchPollutionSourceParameters(activity) {
        return genericFetch(AlertAction.promisePollutionSourceParameters(activity), RECEIVE_POLLUTION_SOURCE_PARAMETERS)
    },
    fetchPollutionSourceParametersWithActivities(activities) {
        return dispatch => {
            const promises = activities.map(a => AlertAction.promisePollutionSourceParameters(a))
            Promise.all(promises).then(jsonTab => {
                const data = flatten(jsonTab.map((res, index) => res.map(p => ({ ...p, activityCode: activities[index] }))))
                dispatch({ type: RECEIVE_POLLUTION_SOURCE_PARAMETERS, data })
            })
        }
    },

    reset() {
        return {
            type: RESET_ALERT_STORE,
        }
    },

    receiveTempFormattedSites: tempFormattedSites => ({
        type: RECEIVE_TEMP_FORMATTED_SITES,
        tempFormattedSites,
    }),

    loadStationData: (fetchUrls) => () =>
        Promise.all([
            EventsAction.promiseGeneric(fetchUrls[0]),
            EventsAction.promiseGeneric(fetchUrls[1]),
            EventsAction.promiseGeneric(fetchUrls[2]),
        ]).then((json = [[], [], []]) => json),

    fetchStationMeasures: (station, startDate, endDate) => dispatch => {
        dispatch(WaitAction.waitStart())
        if (station.typeName === 'pluviometry') {
            const input = {
                stationId: station.id,
                dataType: 1,
                groupFunc: 'SUM_DAY',
                chartMode: true,
                startDate,
                endDate,
            }
            return PluviometryAction.promisePluvioChronicMeasures(input).then(json => {
                dispatch(WaitAction.waitStop())
                return [json]
            })
        }
        if (station.typeName === 'hydrometry') {
            const input = {
                stationId: station.id,
                chartMode: true,
                startDate,
                endDate,
            }
            return Promise.all([
                HydrometryAction.promiseHydroChronicMeasures({ ...input, dataType: 4 }),
                HydrometryAction.promiseHydroChronicMeasures({ ...input, dataType: 5 }),
            ]).then(jsonTab => {
                dispatch(WaitAction.waitStop())
                return jsonTab
            })
        }
        const input = {
            stationId: station.id,
            startDate,
            endDate,
            dataType: -1,
            displayCote: MEASURE_COTE.DEPTH,
            groupFunc: 'MAX',
        }
        return PiezometerStationAction.promisePiezoChartMeasures(input).then(json => {
            dispatch(WaitAction.waitStop())
            return [json]
        })
    },

    loadStationMeasures: (station, startDate, endDate, groupFunc, displayCote) => {
        if (station.typeName === 'pluviometry' || station.stationType === 2) {
            const input = {
                stationId: station.id,
                dataType: 1,
                groupFunc,
                chartMode: true,
                startDate,
                endDate,
            }
            return PluviometryAction.promisePluvioChronicMeasures(input).then(json => {
                return json
            })
        }
        if (station.typeName === 'hydrometry' || station.stationType === 4) {
            const input = {
                stationId: station.id,
                groupFunc,
                chartMode: true,
                startDate,
                endDate,
            }
            return Promise.all([
                HydrometryAction.promiseHydroChronicMeasures({ ...input, dataType: 4 }),
                HydrometryAction.promiseHydroChronicMeasures({ ...input, dataType: 5 }),
            ]).then(([heights, flow]) => [
                ...heights.map(m => [m[0], m[1], 4]),
                ...flow.map(m => [m[0], m[1], 5]),
            ])
        }
        const input = {
            stationId: station.id,
            startDate,
            endDate,
            displayCote,
            dataType: -1,
        }
        return PiezometerStationAction.promisePiezoChartMeasures(input)
    },

    loadSituationSuperposition: (notFetchedData, progressCallback = () => { }) => dispatch => {
        const firstPromises = [
            PiezometryAction.promisePiezometersWithCampaignsAndEvents(),
            PluviometryAction.promisePluviometersWithCampaignsAndEvents(),
            HydrometryAction.promiseHydrometricStations(),
        ]
        return Promise.all(firstPromises)
            .then(jsonTab => {
                const piezo = jsonTab[0]
                const pluvio = jsonTab[1]
                const hydro = jsonTab[2]
                const criterias = { date: moment().valueOf(), nbDays: 30, dataType: DEPTH }
                dispatch(PiezometryActionConstant.receivePiezometerStationDataCampaignsEventsRead(piezo))
                dispatch(PluviometryActionConstant.receivePluviometersWithCampaignsAndEvents(pluvio))
                dispatch(HydrometryActionConstant.receiveAllHydrometricStations(hydro))
                const promises = notFetchedData.reduce((acc, value) => {
                    switch (value) {
                        case 'hydrometryThresholds':
                            acc.promises.push(HydrometryAction.promiseHydrometricThresholds())
                            acc.receives.push(HydrometryActionConstant.receiveHydrometryThresholds)
                            return acc
                        case 'hydroLastMeasures':
                            acc.promises.push(HydrometryAction.promiseHydroLastMeasures(hydro.map(p => (p.id))))
                            acc.receives.push(HydrometryActionConstant.receiveHydroLastMeasures)
                            return acc
                        case 'pluviometerLastMeasures':
                            acc.promises.push(PluviometryAction.promisePluviometerLastMeasures(pluvio.map(p => (p.id))))
                            acc.receives.push(PluviometryActionConstant.receivePluviometerLastMeasures)
                            return acc
                        case 'pluviometerAllThresholds':
                            acc.promises.push(PluviometryAction.promisePluviometerAllThresholds())
                            acc.receives.push(PluviometryActionConstant.receiveAllPluviometerThresholds)
                            return acc
                        case 'piezometerThresholds':
                            acc.promises.push(PiezometerStationAction.promiseAllPiezometerThreshold(MEASURE_COTE.DEPTH))
                            acc.receives.push(PiezometerStationAction.receiveAllPiezometerThresholds)
                            return acc
                        case 'piezometryMaxSituation':
                            acc.promises.push(PiezometryAction.promisePiezometrySituation(criterias, piezo.map(p => (p.id)), 'type'))
                            acc.receives.push(PiezometryActionConstant.receivePiezometryMaxSituation)
                            return acc
                        case 'piezometersWithCampaignsAndEvents':
                            acc.promises.push(PiezometryAction.promisePiezometrySituation(criterias, piezo.map(p => (p.id)), 'type'))
                            acc.promises.push(PiezometerStationAction.promiseAllPiezometerThreshold(MEASURE_COTE.DEPTH))
                            acc.receives.push(PiezometryActionConstant.receivePiezometryMaxSituation)
                            acc.receives.push(PiezometerStationAction.receiveAllPiezometerThresholds)
                            return acc
                        default:
                            return acc
                    }
                }, { promises: [], receives: [] })
                return promiseAllProgress(promises.promises, progressCallback).then(json => {
                    json.forEach((result, index) => dispatch(promises.receives[index](result)))
                })
            })
    },

    loadSituationDashboard: (progressCallback = () => { }, selectedFiltersObj) => dispatch => { // selectedFiltersObj is specif object for situation dashboard
        const filterFormatted = keys(selectedFiltersObj).filter(key => isFunction(selectedFiltersObj[key])).map(key => [selectedFiltersObj[key], key])
        const filterPromises = filterFormatted.map(f => f[0])
        const filterStationTypes = filterFormatted.map(f => f[1])

        const firstPromises = [
            PiezometryAction.promisePiezometersWithCampaignsAndEvents(),
            PluviometryAction.promisePluviometersWithCampaignsAndEvents(),
            PiezometryAction.promisePiezometersLight(),
            HydrometryAction.promiseHydrometricStations(),
            PluviometryAction.promisePluviometers(),
            InstallationAction.promiseInstallationsLight(),
            QualityAction.promiseQualitometersLight(),
            ProductionUnitAction.promiseProductionUnits(),
        ]
        const firstPromisesWithFilter = [...firstPromises, ...filterPromises.map(f => f())]

        const getStationsFilteredByFilterObj = (stations, stationType, jsonTab = []) => {
            const resultsFilter = selectedFiltersObj[stationType]
            if (!resultsFilter) {
                return stationType !== STATION_TYPE_NAME.installation ? stations.map(s => s.id) : []
            } else if (isArray(resultsFilter) && !resultsFilter.length) {
                return []
            } else if (isArray(resultsFilter) && isString(resultsFilter[0])) {
                return resultsFilter.map(b => stations.find(q => q.code?.toUpperCase() === b)).filter(s => !!s).map(s => s.id)
            } else if (isArray(resultsFilter) && isNumber(resultsFilter[0])) {
                return stations.filter(s => resultsFilter.includes(s.id)).map(s => s.id)
            }
            return jsonTab[(firstPromises.length + filterPromises.indexOf(filterPromises[filterStationTypes.indexOf(stationType)]))] || []
        }

        return Promise.all(firstPromisesWithFilter)
            .then(jsonTab => {
                const piezoIds = getStationsFilteredByFilterObj(jsonTab[0], STATION_TYPE_NAME.piezometry, jsonTab)
                const pluvioIds = getStationsFilteredByFilterObj(jsonTab[1], STATION_TYPE_NAME.pluviometry, jsonTab)
                const hydroIds = getStationsFilteredByFilterObj(jsonTab[3], STATION_TYPE_NAME.hydrometry, jsonTab)
                const instIds = getStationsFilteredByFilterObj(jsonTab[5], STATION_TYPE_NAME.installation, jsonTab)

                dispatch(PiezometryActionConstant.receivePiezometerStationDataCampaignsEventsRead(jsonTab[0]))
                dispatch(PluviometryActionConstant.receivePluviometersWithCampaignsAndEvents(jsonTab[1]))
                dispatch(PiezometryActionConstant.receiveAllPiezometersLight(jsonTab[2]))
                dispatch(HydrometryActionConstant.receiveAllHydrometricStations(jsonTab[3]))
                dispatch(PluviometryActionConstant.receivePluviometers(jsonTab[4]))
                dispatch(InstallationActionConstant.ALL_INSTALLATIONS_LIGHT(jsonTab[5]))
                dispatch(QualityActionConstant.receiveQualitometersLight(jsonTab[6]))
                dispatch(ProductionUnitActionConstant.receiveAllProductionUnits(jsonTab[7]))
                const promises = [
                    HydrometryAction.promiseHydrometricThresholds(),
                    HydrometryAction.promiseHydroSituationLastMeasures(hydroIds),
                    PluviometryAction.promisePluviometerAllThresholds(),
                    PluviometryAction.promisePluvioSituationLastMeasures(pluvioIds),
                    PiezometryAction.promisePiezoSituationLastMeasures(piezoIds),
                    PiezometerStationAction.promiseAllPiezometerThreshold(MEASURE_COTE.DEPTH),
                    InstallationAction.promiseSituationInstallations(instIds),
                ]
                return promiseAllProgress(promises, progressCallback).then(json => {
                    dispatch(HydrometryActionConstant.receiveHydrometryThresholds(json[0]))
                    dispatch(HydrometryActionConstant.receiveHydroSituationLastMeasures(json[1]))
                    dispatch(PluviometryActionConstant.receiveAllPluviometerThresholds(json[2]))
                    dispatch(PluviometryActionConstant.receivePluvioSituationLastMeasures(json[3]))
                    dispatch(PiezometryActionConstant.receivePiezoSituationLastMeasures(json[4]))
                    dispatch(PiezometerStationAction.receiveAllPiezometerThresholds(json[5]))
                    dispatch(InstallationActionConstant.receiveInstallationsSituation(json[6]))
                    return {
                        [STATION_TYPE_NAME.piezometry]: piezoIds,
                        [STATION_TYPE_NAME.pluviometry]: pluvioIds,
                        [STATION_TYPE_NAME.hydrometry]: hydroIds,
                        [STATION_TYPE_NAME.installation]: instIds,
                    }
                })
            })
    },

    promisePollutionSourceParams: (params) => genericPromise(ApplicationConf.pollution.params(), 'POST', params),

    fetchPollutionSourceParams: (params) => genericFetch(AlertAction.promisePollutionSourceParams(params), RECEIVE_POLLUTION_SOURCE_PARAMETERS_FULL_DATA),

    setThresholdPreviListData(data) {
        return {
            type: SET_THRESHOLD_PREVI_DATA,
            data,
        }
    },
}

export default AlertAction
