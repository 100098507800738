import React, {useMemo, useState} from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import NumberField from '../../../../components/forms/NumberField'
import Row from '../../../../components/react/Row'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { WhiteCard } from '../../../../components/styled/Card'
import {
    AUTO,
    MANUAL,
} from '../../../constants/HydrometryConstants'
import Checkbox from '../../../../components/forms/Checkbox'
import Button from '../../../../components/forms/Button'
import { isEqual } from 'lodash'

const OptionsToolPanelHydro = ({
    yAxisMin,
    setYaxisMin,
    yAxisMax,
    setYaxisMax,
    showSplitLineY,
    setShowSplitLineY,
    displayLog = false,
}) => {
    const [mode, setMode] = useState(AUTO)

    const defaultValue = useMemo(() => ({
        showSplitLineY,
        yAxisMin,
        yAxisMax,
    }), [showSplitLineY, yAxisMin, yAxisMax])

    const scales = [
        { value: AUTO, label: i18n.automatic },
        { value: MANUAL, label: i18n.manual },
    ]

    const [tmpValue, setTmpValue] = useState({
        showSplitLineY,
        yAxisMin,
        yAxisMax,
    })

    const onSave = () =>{
        setShowSplitLineY(tmpValue.showSplitLineY)
        setYaxisMax(tmpValue.yAxisMax)
        setYaxisMin(tmpValue.yAxisMin)
    }


    return (
        <WhiteCard className='margin-top-1' title={ i18n.chartOptions } round>
            <div className='row no-margin padding-1'>
                <fieldset>
                    <legend>&nbsp;{ i18n.yScale }&nbsp;</legend>
                    <Row>
                        <Checkbox
                            col={ 12 }
                            checked={ tmpValue.showSplitLineY }
                            label={ i18n.Ygrid }
                            onChange={() => setTmpValue(prev => ({ ...prev, showSplitLineY: !prev.showSplitLineY }))}
                            disabled={displayLog}
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <RadioButtons
                            title={ i18n.chartScale }
                            elements={ scales }
                            col={ 12 }
                            selected={ mode }
                            onChange={ v => {
                                setMode(v)
                                setTmpValue(prev => ({ ...prev, yAxisMax: undefined, yAxisMin: undefined }))
                            }
                            }
                            disabled={displayLog}
                        />
                    </Row>
                    <Row>
                        <NumberField
                            col={ 12 }
                            disabled={ mode === AUTO || displayLog }
                            value={ tmpValue.yAxisMax }
                            title={ i18n.max }
                            onChange={ v => setTmpValue(prev => ({ ...prev, yAxisMax: v })) }
                            floatValue
                        />
                    </Row>
                    <Row>
                        <NumberField
                            col={ 12 }
                            disabled={ mode === AUTO || displayLog }
                            value={ tmpValue.yAxisMin }
                            title={ i18n.min }
                            onChange={ v => setTmpValue(prev => ({ ...prev, yAxisMin: v })) }
                            floatValue
                        />
                    </Row>
                </fieldset>
            </div>
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={ i18n.apply } onClick={ onSave } icon='border_color' className={`btn-floating btn-large ${!isEqual(tmpValue, defaultValue) ? 'pulse' : ''}`}/>
            </Row>
        </WhiteCard>
    )
}

OptionsToolPanelHydro.propTypes = {
    yAxisMin: PropTypes.number,
    setYaxisMin: PropTypes.func,
    yAxisMax: PropTypes.number,
    setYaxisMax: PropTypes.func,
    showSplitLineY: PropTypes.bool,
    setShowSplitLineY: PropTypes.func,
    displayLog: PropTypes.bool,
}

export default OptionsToolPanelHydro