module.exports = {
    RECEIVE_SAVED_RESEARCH_CRITERIAS: 'RECEIVE_SAVED_RESEARCH_CRITERIAS',
    ADD_SAVED_RESEARCH_CRITERIAS: 'ADD_SAVED_RESEARCH_CRITERIAS',
    RECEIVE_SETTINGS: 'RECEIVE_SETTINGS',
    RECEIVE_SETTING: 'RECEIVE_SETTING',
    RESET_ADMINISTRATION: 'RESET_ADMINISTRATION',
    RECEIVE_PICTOS: 'RECEIVE_PICTOS',
    RECEIVE_SELECTED_SEARCH_VALUES: 'RECEIVE_SELECTED_SEARCH_VALUES',
    RESET_MAP_CHECBOXES: 'RESET_MAP_CHECBOXES',
    RECEIVE_CARTOGRAPHY_THEMES: 'RECEIVE_CARTOGRAPHY_THEMES',
    RECEIVE_ADMIN_THEMES: 'RECEIVE_ADMIN_THEMES',
    RECEIVE_THEME: 'RECEIVE_THEME',
    RECEIVE_ADMIN_THEME_LAYERS: 'RECEIVE_ADMIN_THEME_LAYERS',
    RECEIVE_TOWNS_INFO: 'RECEIVE_TOWNS_INFO',
    RESET_THEME: 'RESET_THEME',
    RESET_CACHE: 'RESET_CACHE',
    UPDATE_STATE_PARAMETERS: 'UPDATE_STATE_PARAMETERS',
    RECEIVE_APPLICATION_SETTINGS: 'RECEIVE_APPLICATION_SETTINGS',
    RECEIVE_USER_SETTINGS: 'RECEIVE_USER_SETTINGS',
    RECEIVE_APPLICATIVE_USER_SETTINGS: 'RECEIVE_APPLICATIVE_USER_SETTINGS',
    RESET_USER_SETTINGS: 'RESET_USER_SETTINGS',
    RECEIVE_LOGIN_PICTURE: 'RECEIVE_LOGIN_PICTURE',
    RECEIVE_MENU_PICTURE: 'RECEIVE_MENU_PICTURE',
    RECEIVE_ALL_MODELS_BY_TYPE: 'RECEIVE_ALL_MODELS_BY_TYPE',
    SET_CACHE: 'SET_CACHE',
    RELOAD_SELECTED_SEARCH_VALUES: 'RELOAD_SELECTED_SEARCH_VALUES',
    LIST_TYPE: {
        0: 'Paramètres physico-chimiques',
        2: 'Indices',
        1: 'Taxons',
        3: 'Etats',
    },
    SELECTION_LIST_TYPE: {
        0: 'PCparameters',
        1: 'taxons',
        2: 'indices',
        3: 'environmentalParameters',
        4: 'states',
    },
    SELECTION_LIST_TYPE_LABELS: [
        { code: 0, label: 'PCparameters' },
        { code: 1, label: 'taxons' },
        { code: 2, label: 'indices' },
        { code: 3, label: 'environmentalParameters' },
        { code: 4, label: 'states' },
    ],
    SELECTION: 'SELECTION',
    SELECTION_PARAMETER: 'SELECTION_PARAMETER',
    SELECTION_FULL: 'SELECTION_FULL',
    THRESHOLD: 'THRESHOLD',
    PARAMETER_THRESHOLD: 'PARAMETER_THRESHOLD',
    THRESHOLD_FULL: 'THRESHOLD_FULL',
    ICONS_PRESELECTION: ['account_balance', 'assistant_photo', 'attachment', 'attach_file', 'business', 'business_center', 'card_travel', 'cast_connected', 'contact_mail', 'event_note', 'drafts', 'email', 'error', 'favorite', 'feedback', 'fiber_new', 'file_download', 'flash_on', 'grade', 'home', 'info', 'insert_link', 'language', 'location_on', 'map', 'notifications', 'people', 'pie_chart', 'report_problem', 'school'],
    LAYER_TYPES: [
        { code: 0, name: 'WMS' },
        { code: 1, name: 'KML' },
    ],
    PICTURES_EXTENSIONS: ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.gif', '.GIF', '.img', '.IMG', '.jpe', '.JPE', '.bmp', '.BMP', '.tif', '.TIF'],
    DOCUMENTS_EXTENSIONS: ['.xsd', '.doc', '.DOC', '.docx', '.DOCX', '.pdf', '.PDF', '.xls', '.XLS', '.xlsx', '.XLSX', '.xlsm', '.XLSM', '.ods', '.ODS', '.odt', '.ODT', '.zip', '.ZIP', '.xml', '.XML', '.odp', '.ODP', '.ppt', '.PPT', '.pptx', '.PPTX', '.txt', '.TXT', '.sql', '.SQL', '.bat', '.BAT', '.html', '.HTML', '.jasper'],
    NAME_MENU_PICTURE: 'menu_header',
    NAME_LOGIN_PICTURE: 'login_header',
    RECEIVE_PROFILE_PICTURE: 'RECEIVE_PROFILE_PICTURE',
    RECEIVE_PROFILE_LOGIN_PICTURE: 'RECEIVE_PROFILE_LOGIN_PICTURE',
    RESET_PROFILE_PICTURE: 'RESET_PROFILE_PICTURE',
    RESET_PROFILE_LOGIN_PICTURE: 'RESET_PROFILE_LOGIN_PICTURE',
    RECEIVE_GLOBAL_PARAMETERS: 'RECEIVE_GLOBAL_PARAMETERS:',
    UPDATE_GLOBAL_PARAMETER: 'UPDATE_GLOBAL_PARAMETER:',
    DELETE_GLOBAL_PARAMETER: 'DELETE_GLOBAL_PARAMETER',
    CREATE_GLOBAL_PARAMETER: 'CREATE_GLOBAL_PARAMETER',
}
