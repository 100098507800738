import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoPiezometer from '../../dto/DtoPiezometer'
import SieauAction from '../../../components/sieau/SieauAction'
import AppStore from '../../../store/AppStore'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import ChartTabs from '../../../components/echart/ChartTabs'
import DtoPiezometryStationMeasure from '../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import { arrayOf, getComponentWithId, getLabel, getObjectLabel, instanceOf, objectOf } from '../../../utils/StoreUtils'
import moment from 'moment'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import DtoEvent from '../../../events/dto/DtoEvent'
import {
    findIndex,
    findLastIndex,
    groupBy,
    isEqual,
    last,
    maxBy,
    meanBy,
    minBy,
    orderBy,
    pick,
    some,
    sum,
    template,
    uniqBy,
} from 'lodash'
import { getDate, getDateWithHour, getDateWithHourString, getFullDate } from '../../../utils/DateUtil'
import correctionImg from 'assets/pictures/pictos/correction.png'
import validateImg from 'assets/pictures/pictos/validate.png'
import compensationImg from 'assets/pictures/pictos/compensation.png'
import purgeImg from 'assets/pictures/pictos/purge.png'
import importImg from 'assets/pictures/pictos/import.png'
import thresholdImg from 'assets/pictures/pictos/threshold.png'
import eventImg from 'assets/pictures/pictos/event.png'
import optionsImg from 'assets/pictures/pictos/options.png'
import {
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    getAxisLabelInterval,
    setYOptionsPiezo,
    yAutomaticScale,
} from '../../../components/echart/EChartUtils'
import { getColorCircleEvent, getColorFromPalette } from '../../../utils/ColorUtil'
import {
    getDisplayCote,
    getEventGraph,
    getEventsBar,
    getHardPiezoDataTypes,
    getMeasureStatusColor,
    getMeasureStatusColorTable,
    getMeasureValue,
    getMeasureValueFromDepth,
    getNGFValue,
    getPiezometerRawMeasures,
    getStaticDepthEvents,
    getThresholdsMarkLine,
} from '../../../utils/PiezometryUtils'
import Axis from '../../../components/echart/Axis'
import { groupSameSuccessiveValues, hasValue, round } from '../../../utils/NumberUtil'
import DtoPiezometerChartOptions from '../../../station/dto/piezometer/DtoPiezometerChartOptions'
import DtoPiezoThreshold from '../../../station/dto/piezometer/DtoPiezoThreshold'
import { exportFile } from '../../../utils/ExportDataUtil'
import EChart from '../../../components/echart/EChart'
import Card from '../../../components/card/Card'
import ValidationToolPanel from './tools/ValidationToolPanel'
import QualityAction from '../../../quality/actions/QualityAction'
import DtoStatus from '../../../quality/dto/DtoStatus'
import DtoQualification from '../../../quality/dto/DtoQualification'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import ReactDOMServer from 'react-dom/server'
import { getSandreIndexLabel } from '../../../utils/StringUtil'
import { nbPerPageLabel, SANDRE } from '../../../referencial/constants/ReferencialConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import DeleteToolPanel from './tools/DeleteToolPanel'
import CompensationToolPanel from './tools/CompensationToolPanel'
import CorrectionToolPanel from './tools/CorrectionToolPanel'
import Line from '../../../components/echart/series/Line'
import DtoContributor from '../../../station/dto/DtoContributor'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import EventsToolPanel from './tools/EventsToolPanel'
import EventsAction from '../../../events/actions/EventsAction'
import DtoJob from '../../../import/dto/DtoJob'
import JobAction from '../../../import/actions/JobAction'
import { MEASURE_COTE } from '../../constants/PiezometryConstants'
import { getLocalStorageJson, sieauTooltip } from '../../../utils/FormUtils'
import ThresholdsToolPanel from './tools/ThresholdsToolPanel'
import ImportToolPanel from './tools/ImportToolPanel'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'
import { push } from 'connected-react-router'
import { getLinks, getStationTitle } from '../../../utils/StationUtils'
import OptionsToolPanel from './tools/OptionsToolPanel'
import { getLisibleComment, getMaintenance1, getMaintenance2 } from './ValidationEventUtils'
import Other from '../../../components/actions/Other'
import Select from '../../../components/forms/Select'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'
import PiezometryAction from '../../actions/PiezometryAction'
import Row from '../../../components/react/Row'
import MessageCard from '../../../components/card/MessageCard'
import Icon from '../../../components/icon/Icon'
import { getQualifications, getStatuses } from '../../../utils/QualityUtils'
import Table from '../../../components/datatable/Table'
import queryString from 'query-string'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_STATION_PIEZO_VALIDATION } from '../../../account/constants/AccessRulesConstants'
import { statusIcon, statusLabel } from '../../../utils/StatusUtil'
import { setActions } from '../../../components/ActionUtil'
import DtoMeasureStats from '../../../station/dto/piezometer/DtoMeasureStats'
import { parseJsonObj } from 'utils/JsonUtils'

class PiezometryValidationApp extends Component {
    constructor(props) {
        super(props)
        const { dataType, startDate, endDate, tool } = queryString.parse(props.location.search)
        const selectedDataType = dataType ? parseInt(dataType) : -1
        this.state = {
            dataLoaded: false,
            locked: false,
            progress: 50,
            selectedDataType, // type profondeur par défaut
            piezoMode: selectedDataType === -1, // true si on affiche la piézo, false pour les donénes complémentaires
            minDate: null, // dates de sélection de ChartTabs
            maxDate: null, // dates de sélection de ChartTabs
            startDate: startDate ? parseInt(startDate) : null, // intervale de sélection de la validation (composants à droite)
            endDate: endDate ? parseInt(endDate) : null, // intervale de sélection de la validation (composants à droite)
            tool: tool || 'validation',
            measures: [],
            initialMeasures: [],
            draggable: false,
            selectedPoint: null,
            changedValue: null, // offset pour la compensation, valeur pour la correction de dérive
            displayCote: getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH, // cote de calcul de la hauteur d'eau affichée
            lastLandmark: null, // hauteur du dernier repère
            groundRefAlti: null, // dernier repère défini pour le sol, sert à calculer les mesures par rapport au sol
            piezometerThresholds: [],
            piezometerImportedMeasure: [],
            display: 'cumul',
            startDateImport: 0,
            endDateImport: 0,
            piezometerChartOptions: [],
            replayingCorrection: false,
            compensationCoeff: null,
            tableView: false, // mode tableau
            displayModes: {
                auto: true,
                brute: false,
                min: false,
                max: false,
                average: false,
            },
        }
    }

    setTitle = (station) => {
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: getStationTitle(station),
            href: `station/piezometry/${station.id}`,
        }, {
            title: i18n.validation,
            href: `station/piezometry/${station.id}/validation`,
        }]))
        this.updateActions()
    }

    updateActions = () => {
        setActions({
            other: this.getIntegrationOverviewLink(),
            links: getLinks(this.props.piezometer, this.props),
            arrowNav: getStationArrowNav('piezometry', this.props.piezometers, this.props.piezometer.id, s => AppStore.dispatch(push(`/station/piezometry/${s.id}/validation`))),
            exportmodel: () => ({
                stationId: this.props.piezometer.id.toString(),
                stationCode: this.props.piezometer.code,
                stationType: 'piezometry',
                environmentModels: this.props.typeEnvironmentModels,
            }),
        })
    }

    getIntegrationOverviewLink = () => ({ other: <Other className='clickable' onClick={ () => AppStore.dispatch(push('/piezometry/integrationOverview')) } tooltip={ i18n.integrationOverview } icon='traffic'/> })

    componentDidUpdate = (prevProps) => {
        if (this.props.piezometers.length !== prevProps.piezometers.length) {
            this.updateActions()
        }

        if (!isEqual(this.props.piezometerThresholds, prevProps.piezometerThresholds)) {
            this.setState({ piezometerThresholds: this.props.piezometerThresholds })
        }
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_STATION_PIEZO_VALIDATION)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (!isEqual(this.props.piezometerThresholds, this.state.piezometerThresholds)) {
            this.setState({ piezometerThresholds: this.props.piezometerThresholds })
        }
        if (this.props.piezometer.id) {
            this.setTitle(this.props.piezometer)
        }
        this.props.fetchPiezoMeasuresStats(parseInt(this.props.piezometer.id))
    }

    calculateLastLandmarks = (piezo) => {
        const lastLandmark = maxBy(piezo.link_landmarks, 'startDate')
        const lastRefAlti = lastLandmark ? piezo.link_altimetrySystems.find(alt => alt.natureCode === lastLandmark.altimetrySystemNature && alt.startDate === lastLandmark.altimetrySystemDate) : null

        const groundRefALtis = piezo.link_altimetrySystems.filter(alt => alt.natureCode == 3)
        const groundRefAlti = groundRefALtis.length ? maxBy(groundRefALtis, 'startDate') : null
        this.setState({ lastLandmark: lastLandmark && lastRefAlti ? lastLandmark.height + lastRefAlti.altitude : null, groundRefAlti: groundRefAlti && groundRefAlti.altitude })
    }

    componentWillMount() {
        if (this.props.piezometer.id) {
            this.calculateLastLandmarks(this.props.piezometer)
        }
        AppStore.dispatch(PiezometerStationAction.fetchPiezometerChartOptions(this.props.match.params.id, () => this.setState({ piezometerChartOptions: this.props.piezometerChartOptions })))
        if (!this.props.status.length) {
            AppStore.dispatch(QualityAction.fetchStatus())
        }
        if (!this.props.qualifications.length) {
            AppStore.dispatch(QualityAction.fetchQualifications())
        }
        if (!this.props.sandreCodes.length) {
            AppStore.dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!this.props.contributors.length) {
            AppStore.dispatch(ContributorAction.fetchContributors())
        }
        if (!this.props.jobs.length) {
            AppStore.dispatch(JobAction.fetchFullJobs())
        }
        if (!this.props.piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes().then(this.checkPiezoMode)
        } else {
            this.checkPiezoMode()
        }
        AppStore.dispatch(PiezometerStationAction.fetchPiezoMeasuresStats(parseInt(this.props.match.params.id)))
        this.props.fetchPiezometerThresholds(parseInt(this.props.match.params.id), MEASURE_COTE.NGF)
    }

    checkPiezoMode = () => {
        const currentDt = this.props.piezometryDataTypes.find(dt => dt.id == this.state.selectedDataType)
        if (currentDt && currentDt.isPiezo) {
            this.setState({ piezoMode: true })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.piezometer.id && nextProps.piezometer.id) {
            this.setTitle(nextProps.piezometer)
            this.calculateLastLandmarks(nextProps.piezometer)
        }
    }

    getNewValidationWindow = (measures) => {
        const lastIndexValidated = findLastIndex(measures, m => m.status === 2 || m.status === 3)
        if (lastIndexValidated) {
            const firstNotValidated = measures[lastIndexValidated + 1]
            if (firstNotValidated) {
                const lastMeasure = last(measures)
                if (lastMeasure.measureIndex !== firstNotValidated.measureIndex) {
                    return { startDate: firstNotValidated.date, endDate: lastMeasure.date, endHour: lastMeasure.date }
                }
            }
        }
        return {}
    }

    loadData = (dates) => {
        const fetchDates = { startDate: dates.minDate ? moment(dates.minDate).subtract(6, 'hours').valueOf() : null, endDate: dates.maxDate }
        this.setState({ ...dates, dataLoaded: false, progress: 50 })
        this.props.fetchMeasures(this.props.piezometer.id, this.state.selectedDataType, fetchDates).then(() => {
            const measures = orderBy(this.props.measures, 'date').map((m, i) => ({ ...m, measureIndex: i }))
            this.setState({ measures, initialMeasures: measures, dataLoaded: true, ...this.getNewValidationWindow(measures) })
        })
    }

    getExportData = () => {
        const chartMinDate = this.getMinDate()
        const chartMaxDate = this.state.maxDate || moment().valueOf()
        const modes = this.state.displayModes
        const rawMeasures = this.getSerieData(this.state.measures, chartMinDate, chartMaxDate, 'max', modes.brute)
        const series = []
        series.push({ data: rawMeasures, name: getLabel(this.getDataTypes(), this.state.selectedDataType, 'label', 'id') })
        const statuses = getStatuses()
        const qualifications = getQualifications()

        const data = series.reduce((acc, chart) => [...acc, ...chart.data.map(d => ({
            stationCode: this.props.piezometer.code,
            stationName: this.props.piezometer.name,
            date: getFullDate(d.value[0]),
            value: round(d.value[1]),
            type: chart.name,
            status: getLabel(statuses, d.value[2].status),
            qualification: getLabel(qualifications, d.value[2].qualification),
        }))], [])

        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type', 'status', 'qualification']
        }
        return data
    }

    getGroupedMeasures = (measures, chartMinDate, chartMaxDate, groupFunc = 'max', forceBrute = false, forceDaily = false, initialMode = false) => {
        if (forceBrute) {
            return measures
        }
        const key = this.state.piezoMode ? (initialMode ? 'initialNGF' : 'NGF') : (initialMode ? 'initialValue' : 'value')
        const duration = moment.duration(moment(chartMaxDate).diff(chartMinDate))
        if (duration.months() > 3 || duration.years() >= 1 || forceDaily) {
            const measuresObj = groupBy(measures, m => getDate(m.date))
            return Object.keys(measuresObj).map(date => {
                if (measuresObj[date].length === 1) {
                    return measuresObj[date][0]
                }
                if (groupFunc === 'max') {
                    const found = maxBy(measuresObj[date], key)
                    return { ...found, initialPoint: some(measuresObj[date], m => m.initialPoint === 1) ? 1 : 0 }
                }
                if (groupFunc === 'min') {
                    const found = minBy(measuresObj[date], key)
                    return { ...found, initialPoint: some(measuresObj[date], m => m.initialPoint === 1) ? 1 : 0 }
                }
                if (groupFunc === 'mean') {
                    return {
                        ...measuresObj[date][0],
                        initialPoint: some(measuresObj[date], m => m.initialPoint === 1) ? 1 : 0,
                        NGF: meanBy(measuresObj[date], key),
                        value: meanBy(measuresObj[date], 'value'),
                        date: moment(measuresObj[date][0].date).hour(12).valueOf(),
                    }
                }
                return measuresObj[date][0]
            })
        }
        return measures
    }

    getSerieData = (measures, chartMinDate, chartMaxDate, groupFunc = 'max', forceBrute = false, forceDaily = false, initialMode = false, unit = undefined) => {
        const start = this.state.startDate
        const end = this.state.endDate
        const results = this.getGroupedMeasures(measures, chartMinDate, chartMaxDate, groupFunc, forceBrute, forceDaily, initialMode).flatMap(obj => {
            const date = obj.date
            const symbolSize =
                obj.initialPoint === 1 ? { symbolSize: 5 } :
                    ((this.state.tool === 'validation' && this.state.draggable && (!this.state.selectedPoint || this.state.selectedPoint.measureIndex === obj.measureIndex)) ? { symbolSize: 5 } :
                        (this.state.tool === 'correction' && start && end ? { symbolSize: date === end ? 10 : 0 } :
                            this.state.draggable && start && end ? { symbolSize: date >= start && date <= end ? 5 : 0 } : {}
                        )
                    )
            const calculated = this.state.piezoMode ? getMeasureValue(obj, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti, initialMode) : obj.value
            if (obj.initialPoint === 1) {
                return [
                    { value: [moment(date).subtract(1, 'second').valueOf(), null, { ...obj, calculated }, unit] },
                    { value: [date, calculated, { ...obj, calculated }], symbolSize: 1, ...symbolSize, unit },
                ]
            }
            return [{ value: [date, calculated, { ...obj, calculated }], symbolSize: 1, ...symbolSize, unit }]
        })
        return orderBy(results, m => m.value[0])
    }


    addEvents = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, events) => {
        const eventOptions = {
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    const eventsList = events.filter(e => {
                        return e.graph === '1' && (e.startDate && e.endDate ? (
                            e.startDate <= params.value[0] && e.endDate >= params.value[0]
                        ) : getDateWithHour(e.date, e.eventHour).isSame(params.value[0], 'day'))
                    })
                    const date = eventsList.length ? getFullDate(getDateWithHour(eventsList[0].date, eventsList[0].eventHour)) : ''
                    return `${date}<br />${eventsList.reduce((acc, v) => {
                        const comment = v.comment ? getLisibleComment(v.comment) : i18n.event
                        const ns = (v.ns ? `<br />${i18n.staticLevel} : ${getMeasureValueFromDepth({ value: v.ns }, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti)}` : '')
                        return `${acc}<br />${getColorCircleEvent(v.eventType)}${comment}${ns}`
                    }, i18n.events)}`
                },
            },
            itemStyle: {
                normal: {
                    opacity: 0.5,
                },
            },
            yAxisIndex: 1,
        }
        series.push(getEventGraph(events, eventOptions))
        series.push(getEventsBar(events, { tooltip: eventOptions.tooltip }))
        grids.push({
            top: sum(gridsHeights),
            right: '2%',
            height: 40,
            left: 100,
        })
        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }))
        yAxis.push(Axis({
            type: 'value',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'right',
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }))
        yAxis.push(Axis({
            type: 'category',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'left',
        }))
        gridsHeights.push(60)
    }

    getPiezometerOption = (type) => {
        if (type == 0) {
            return this.state.piezometerChartOptions.find(opt => !opt.dataType || parseInt(opt.dataType) <= 0) || {}
        }
        return this.state.piezometerChartOptions.find(opt => type == opt.dataType) || {}
    }

    getMarkAreas = () => {
        const groups = groupSameSuccessiveValues(this.state.measures, 'status')
        const areas = groups.filter(g => g.length && g[0].status && g[0].status != 1).map(group => {
            const start = last(group)
            const end = group[0]
            return [{ xAxis: start.date, itemStyle: { color: getMeasureStatusColor(end.status), opacity: 0.5 } },
                { xAxis: end.date, itemStyle: { color: getMeasureStatusColor(end.status), opacity: 0.5 } }]
        })
        return areas.length ? { markArea: { data: areas } } : {}
    }

    getSelectedInterval = (otherlines) => {
        if (!(this.state.draggable && this.state.tool === 'validation')) {
            const data = [
                this.state.startDate,
                this.state.endDate,
            ].filter(d => !!d)
            if (data.length) {
                return {
                    markLine: {
                        symbol: 'none',
                        silent: false,
                        label: {
                            show: true,
                            position: 'middle',
                            formatter(v) {
                                return getDate(v.value)
                            },
                        },
                        data: [...data.map(d => ({ xAxis: d })), ...otherlines.data],
                        lineStyle: {
                            color: 'grey',
                        },
                    },
                }
            }
            return {
                markLine: {
                    symbol: 'none',
                    label: {
                        show: true,
                        position: 'middle',
                        formatter(v) {
                            return getDate(v.date)
                        },
                    },
                    data: [...otherlines.data],
                    lineStyle: {
                        color: 'grey',
                    },
                },
            }
        }
        return {}
    }

    getManualImportMeasures = (chartMinDate, chartMaxDate) => {
        const { startDateImport, endDateImport, piezometerImportedMeasure, display, measures } = this.state
        if (piezometerImportedMeasure.length) {
            const filteredImportedMeasure = piezometerImportedMeasure.filter(measure => measure.date >= startDateImport && measure.date <= endDateImport)
            const importedMeasures = (() => {
                switch (display) {
                    case 'addEnd':
                        const lastDate = measures.length ? last(measures).date : null
                        return lastDate ? filteredImportedMeasure.filter(measure => measure.date >= lastDate) : filteredImportedMeasure
                    case 'replace':
                        return filteredImportedMeasure
                    default:
                        return uniqBy([
                            ...this.state.measures,
                            ...filteredImportedMeasure,
                        ], m => getFullDate(m.date)).filter(measure => measure.date >= startDateImport && measure.date <= endDateImport)
                }
            })()
            return this.getSerieData(importedMeasures, chartMinDate, chartMaxDate)
        }
        return []
    }

    addMeasures = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, chronicMeasures, initialMeasures) => {
        const unit = this.props.piezometerStatistics.find(t => t.typeId == this.state.selectedDataType)?.unit ?? ''
        const label = this.props.piezometerStatistics.find(t => t.typeId == this.state.selectedDataType)?.label ?? ''

        grids.push({
            top: sum(gridsHeights),
            right: '2%',
            height: 550,
            left: 100,
        })

        if (chronicMeasures.initial.length) {
            series.push(getPiezometerRawMeasures(chronicMeasures.initial, this.props.piezometer, grids.length - 1, [], [], {
                color: 'grey',
                name: i18n.initialMeasure,
            }, true))
        }

        // static level serie (from events)
        const eventsStaticDepth = this.props.stationEvents.filter(e => {
            const d = getDateWithHour(e.date, e.eventHour).valueOf()
            return hasValue(e.ns) && e.eventType !== 'T' && (!this.props.maxDate || d <= this.props.maxDate) && d >= this.state.minDate
        }).map(e => ({ value: [getDateWithHour(e.date, e.eventHour).valueOf(), getMeasureValueFromDepth({ value: e.ns }, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti)] }))
        if (eventsStaticDepth.length && this.state.piezoMode) {
            series.push(getStaticDepthEvents(eventsStaticDepth, grids.length - 1, {}, true))
        }

        // manual import measures
        const manualImportMeasures = this.getManualImportMeasures(chartMinDate, chartMaxDate)
        if (manualImportMeasures) {
            series.push(getPiezometerRawMeasures(manualImportMeasures, this.props.piezometer, grids.length - 1, [], [], { color: 'red', name: i18n.newValue }, true))
        }

        // thresholds
        const thresholds = this.state.piezometerThresholds
            .filter(t => {
                if (t.isDeleted || !hasValue(t.name)) {
                    return false
                }
                return this.state.selectedDataType === parseInt(t.dataType)
            })
            .map(t => ({
                ...t,
                lastLandmark: this.state.lastLandmark,
                groundRefAlti: this.state.groundRefAlti,
                displayCote: this.state.displayCote,
                unit,
            }))
        const thresholdValues = thresholds.map(t => ({ ...t, value: [null, this.state.piezoMode ? (getMeasureValue({ NGF: t.value, landmark: t.lastLandmark, refAlti: 0 }, t.displayCote, t.lastLandmark, t.groundRefAlti) || 0) : t.value] }))
        const showSymbol = { showSymbol: true, showAllSymbol: true }

        // initial measures (before saving changes)
        series.push(getPiezometerRawMeasures(initialMeasures, this.props.piezometer, grids.length - 1, [], [], { color: 'grey', name: i18n.initialMeasure }, true))

        // imported measures (values that can't be changed after the import)
        if (this.state.replayingCorrection) {
            series.push(getPiezometerRawMeasures(this.getSerieData(
                this.state.measures.filter(m => m.date >= this.state.startDate && m.date <= this.state.endDate).map(m => ({ ...m, NGF: m.initialNGF, value: m.initialValue })), chartMinDate, chartMaxDate
            ), this.props.piezometer, grids.length - 1, [], [], { color: 'grey', name: i18n.importedMeasure }, true))
        }

        // raw measures
        series.push(getPiezometerRawMeasures(chronicMeasures.raw, this.props.piezometer, grids.length - 1, [], [], {
            ...this.getMarkAreas(),
            ...this.getSelectedInterval(
                getThresholdsMarkLine(this.props.piezometer, thresholds, this.state.piezoMode)
            ),
            ...showSymbol,
            ...(this.state.piezoMode ? {} : { name: getLabel(this.getDataTypes(), this.state.selectedDataType, 'label', 'id') }),
        }, true))

        // other display modes
        if (chronicMeasures.max.length) {
            series.push(getPiezometerRawMeasures(chronicMeasures.max, this.props.piezometer, grids.length - 1, [], [], {
                color: '#2196F3',
                name: i18n.max,
            }, true))
        }
        if (chronicMeasures.min.length) {
            series.push(getPiezometerRawMeasures(chronicMeasures.min, this.props.piezometer, grids.length - 1, [], [], {
                color: '#F44336',
                name: i18n.min,
            }, true))
        }
        if (chronicMeasures.average.length) {
            series.push(getPiezometerRawMeasures(chronicMeasures.average, this.props.piezometer, grids.length - 1, [], [], {
                color: '#4CAF50',
                name: i18n.average,
            }, true))
        }

        const option = this.getPiezometerOption(this.state.selectedDataType)

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            gridIndex: grids.length - 1,
            showSplitLine: option && hasValue(option.displayXIntervalYear) ? option.displayXIntervalYear === '1' : true,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
        }))
        const yScale = yAutomaticScale(eventsStaticDepth, thresholdValues, chronicMeasures.raw, chronicMeasures.max, chronicMeasures.min, chronicMeasures.average, initialMeasures, manualImportMeasures)
        this.yScale = yScale // please do not remove
        yAxis.push(Axis(Object.assign({}, {
            type: 'value',
            nameLocation: 'middle',
            name: this.state.piezoMode ? `${label} ${unit ? `[${unit}]` : ''}` : `${getLabel(this.getDataTypes(), this.state.selectedDataType, 'label', 'id')} ${unit ? `[${unit}]` : ''}`,
            gridIndex: grids.length - 1,
            nameGap: 40,
            inverse: this.state.piezoMode ? this.state.displayCote !== MEASURE_COTE.NGF : false,
            showSplitLine: option && hasValue(option.displayYIntervalYear) ? option.displayYIntervalYear === '1' : true,
        },
        yScale,
        setYOptionsPiezo(option, -1, yScale, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti),
        )))
        gridsHeights.push(600)
    }

    addProducers = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, rawMeasures) => {
        const groups = groupSameSuccessiveValues(rawMeasures, 'producer')
        const byProducer = groupBy(groups.filter(g => g.length && hasValue(g[0].producer)), group => group[0].producer)
        Object.keys(byProducer).forEach((producer, idx) => {
            const producerGroups = byProducer[producer]
            const producerName = getLabel(this.props.contributors, producer, 'mnemonique')
            grids.push({
                top: sum(gridsHeights),
                right: '2%',
                height: 30,
                left: 100,
            })

            const areas = producerGroups.map(group => {
                const start = last(group)
                const end = group[0]
                return [{ xAxis: start.date, itemStyle: { color: getColorFromPalette(idx), opacity: 0.5 } },
                    { xAxis: end.date, itemStyle: { color: getColorFromPalette(idx), opacity: 0.5 } }]
            })
            series.push(Line({
                data: [],
                name: producerName,
                yAxisIndex: grids.length,
                xAxisIndex: grids.length - 1,
                connectNulls: false,
                showSymbol: false,
                color: getColorFromPalette(idx),
                serieId: producerName,
                markArea: { data: areas },
            }))
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: chartMinDate,
                max: chartMaxDate,
                interval: axisLabelObj.interval,
                gridIndex: grids.length - 1,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                showSplitLine: true,
            }))
            yAxis.push(Axis({
                type: 'value',
                nameLocation: 'center',
                name: producerName,
                gridIndex: grids.length - 1,
                nameGap: 5,
                nameRotate: 0,
                showSplitLine: true,
            }))
            gridsHeights.push(30)
        })
    }

    getMinDate = () => {
        if (this.state.minDate) {
            return this.state.minDate
        }
        const minAllData = minBy(this.props.measures, 'date')
        return minAllData ? minAllData.date : moment().subtract(1, 'days').valueOf()
    }

    getTooltip() {
        return {
            trigger: 'axis',
            formatter: params => {
                const date = params[0].value[2] ? getFullDate(moment(params[0].value[2].date)) : ''
                const otherSeriesNames = [i18n.initialMeasure, i18n.importedMeasure, i18n.min, i18n.max, i18n.average]
                const paramsOrder = uniqBy(params.filter(o => hasValue(o.value[2]) && o.seriesName !== i18n.events).map(o => ({
                    marker: o.marker,
                    seriesName: o.seriesName,
                    value: o.value[2].calculated,
                    unit: o.data.unit,
                    status: hasValue(o.value[2].validity) ? ReactDOMServer.renderToString(<Row className='valign-wrapper'>{statusIcon(o.value[2], 20)}&nbsp;{statusLabel(o.value[2])}</Row>) : '',
                    otherData: !otherSeriesNames.includes(o.seriesName) && o.value[2] ? [
                        hasValue(o.value[2].nature) && (`Nature : ${getSandreIndexLabel(this.props.sandreIndex, SANDRE.PIEZOMETER_MEASURE_NATURE, o.value[2].nature)}`),
                        hasValue(o.value[2].producer) && (`${i18n.producer} : ${getLabel(this.props.contributors, o.value[2].producer, 'mnemonique')}`),
                    ].filter(s => !!s).join('<br/>') : '',
                })).reverse(), 'value')
                const result = paramsOrder.map(o => `${o.marker} ${o.seriesName} : ${o.value} ${o.unit ?? ''} <br/> ${o.status}${o.otherData}`).join('<br/>')
                const cote = `${i18n.display} : ${this.state.piezoMode ? getDisplayCote(this.state.displayCote) : 'Valeur du capteur'}`
                return `${date} <br /> ${cote} <br /> ${result}`
            },
        }
    }

    onCancel = () => this.setState({
        piezometerThresholds: this.props.piezometerThresholds,
        piezometerImportedMeasure: [],
        measures: orderBy(this.props.measures, 'date').map((m, i) => ({ ...m, measureIndex: i })),
        selectedPoint: null,
        replayingCorrection: false,
        changedValue: null,
        compensationCoeff: null,
    })

    getChart = () => {
        const chartMinDate = this.getMinDate()
        const chartMaxDate = this.state.maxDate || moment().valueOf()
        const unit = this.props.piezometerStatistics.find(t => t.typeId === this.state.selectedDataType)?.unit ?? ''

        const modes = this.state.displayModes
        const chronicMeasures = {
            raw: modes.brute || modes.auto ? this.getSerieData(this.state.measures, chartMinDate, chartMaxDate, 'max', modes.brute, false, false, unit) : [],
            min: modes.min ? this.getSerieData(this.state.measures, chartMinDate, chartMaxDate, 'min', false, true) : [],
            max: modes.max ? this.getSerieData(this.state.measures, chartMinDate, chartMaxDate, 'max', false, true) : [],
            average: modes.average ? this.getSerieData(this.state.measures, chartMinDate, chartMaxDate, 'mean', false, true) : [],
            initial: modes.initialBrute ? this.getSerieData(this.state.measures, chartMinDate, chartMaxDate, 'max', modes.brute, false, true) : [],
        }
        const rawMeasures = chronicMeasures.raw
        const initialMeasures = modes.brute || modes.auto ? this.getSerieData(this.state.initialMeasures, chartMinDate, chartMaxDate, 'max', modes.brute, false, false, unit) : []

        // get events
        const events = this.props.stationEvents.filter(e => hasValue(e.date)).filter(e => {
            const eventDate = getDateWithHour(e.date, e.eventHour).valueOf()
            const problem = e.problem?.startsWith('{') ? parseJsonObj(e.problem) : undefined
            return eventDate >= chartMinDate && eventDate <= chartMaxDate && e.graph === '1' && (e.eventType !== 'T' || (
                (this.state.tool === 'correction' && problem?.tool === 'correction' && e.startDate >= chartMinDate) ||
                (this.state.tool === 'compensation' && problem?.tool === 'compensation' && e.startDate >= chartMinDate) ||
                (problem?.landmarkChange)
            ))
        })

        // construct echart options
        const axisLabelObj = getAxisLabelInterval(this.state.maxDate ? moment(this.state.maxDate) : moment(), moment(this.state.minDate || chartMinDate))
        const series = []
        const grids = []
        const xAxis = []
        const yAxis = []
        const gridsHeights = [60]

        this.addEvents(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, events)
        this.addMeasures(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, chronicMeasures, initialMeasures)
        this.addProducers(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, this.state.measures)

        const fullScreenFeature = this.props.isSubComponent ? {
            myTool2: {
                show: true,
                title: i18n.fullScreen,
                icon: fullScreenIcon,
                onclick: () => { }, // this.props.onChangeFullScreen()
            },
        } : {}
        const options = {
            series,
            tooltip: this.getTooltip(),
            grid: grids,
            xAxis,
            yAxis,
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            setDataZoom: !this.state.draggable,
            height: sum(gridsHeights),
            toolbox: {
                show: true,
                feature: {
                    ...fullScreenFeature,
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                    },
                },
                right: 50,
            },
            graphic: !this.state.draggable ? {
                type: 'rect',
                position: [0, 0],
                shape: {
                    x: 100,
                    y: 120,
                    width: 10000,
                    height: 550,
                },
                invisible: true,
                z: 500,
                onclick: this.selectOnChart,
            } : undefined,
            // legend: this.getLegend()
        }
        setTimeout(() => this.setDraggable(rawMeasures), 100) // Dont remove ! (gestion des mesures draggables sur le graphique)
        return (
            <EChart options={options} id='piezometryDashboardChart' onClick={ this.onEventOnChartSelected }/>
        )
    }

    onEventOnChartSelected = (params) => {
        if (this.state.selectionMode === 'correctionEvent' && params.value[2] && params.value[2].eventType === 'T') {
            const event = params.value[2]
            if (event.problem && event.problem.startsWith('{')) {
                getComponentWithId('#correctionToolPanel').onReplayOldCorrection(event)
            }
        }
        if (this.state.selectionMode === 'compensationEvent' && params.value[2] && params.value[2].eventType === 'T') {
            const event = params.value[2]
            if (event.problem && event.problem.startsWith('{')) {
                getComponentWithId('#compensationToolPanel').onReplayOldCompensation(event)
            }
        }
    }

    selectOnChart = (item) => {
        if (this.state.selectionMode && this.state.measures.length) {
            const [selectedDate ] = getComponentWithId('.sieauChart').getInstance().convertFromPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [item.offsetX, item.offsetY])
            const nextOneIdx = findIndex(this.state.measures, m => m.date > selectedDate)
            const next = nextOneIdx !== -1 ? this.state.measures[nextOneIdx] : null
            const previous = nextOneIdx !== -1 ? this.state.measures[nextOneIdx - 1] : last(this.state.measures)
            const foundMeasure = minBy([previous, next].filter(m => !!m), m => m.date > selectedDate ? m.date - selectedDate : selectedDate - m.date)
            this.selectPoint(foundMeasure)
        }
    }

    selectPoint = (point) => {
        if (this.state.selectionMode && this.state.measures.length) {
            const found = point.date
            if (this.state.selectionMode === 'start') {
                this.setState({ selectionMode: 'end', startDate: found })
            } else if (this.state.selectionMode === 'end') {
                if (found < this.state.startDate) {
                    AppStore.dispatch(ToastrAction.error(template(i18n.dateBoundMinError)({ date: getFullDate(this.state.startDate) })))
                } else {
                    this.setState({ selectionMode: null, endDate: found })
                }
            } else if (this.state.selectionMode === 'point') {
                this.setState({ selectionMode: 'point', startDate: found, endDate: found, selectedPoint: point })
            }
        }
    }

    updateTechnicalEvent = (measures, event) => {
        const { tool, startDate, endDate, changedValue, compensationCoeff } = this.state
        const getDates = () => measures.length > 1 ? `${i18n.fromDate} ${getFullDate(startDate)} ${i18n.to} ${getFullDate(endDate)}` : `${i18n.fromDate} ${getFullDate(startDate)}`
        const eventProps = (() => {
            switch (tool) {
                case 'compensation':
                    const m1 = getMaintenance1(this.props.stationEvents, startDate, this.state)
                    const m2 = getMaintenance2(this.props.stationEvents, endDate, this.state)
                    const NGFoffset = this.state.displayCote === MEASURE_COTE.NGF ? changedValue : -changedValue
                    const NGFcoeff = compensationCoeff || 1
                    return {
                        comment: `${i18n.compensation} ${getDates()}\n
                    ${`Coeff : ${NGFcoeff}m NGF`}\n${`Offset : ${NGFoffset}m NGF`}${
                            m1 ? `\n\n${m1}` : ''}${m2 ? `\n\n${m2}` : ''}`,
                        problem: JSON.stringify({
                            tool,
                            coeff: NGFcoeff,
                            offset: NGFoffset,
                        }),
                    }
                case 'correction':
                    const mcorr1 = getMaintenance1(this.props.stationEvents, startDate, this.state)
                    const mcorr2 = getMaintenance2(this.props.stationEvents, endDate, this.state)
                    const NGFValue = this.state.displayCote === MEASURE_COTE.NGF ? changedValue : getNGFValue({ value: changedValue }, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti)
                    const depthValue = this.state.displayCote === MEASURE_COTE.NGF ? getMeasureValue({ NGF: changedValue }, MEASURE_COTE.DEPTH, this.state.lastLandmark, this.state.groundRefAlti) : changedValue
                    return {
                        comment: `${i18n.driftingCorrection} ${getDates()}\n
                            ${i18n.valueLabel} : ${depthValue} m (${NGFValue} m NGF)${
                            mcorr1 ? `\n\n${mcorr1}` : ''}${mcorr2 ? `\n\n${mcorr2}` : ''}`,
                        problem: JSON.stringify({ tool, NGFValue, depthValue }),
                    }
                default:
                    return { comment: '' }
            }
        })()

        const newEvent = {
            ...event,
            eventType: 'T',
            date: moment().valueOf(),
            eventHour: moment().valueOf(),
            graph: '1',
            ...eventProps,
        }

        this.props.updateEvent('piezometry', this.props.id, event.number, newEvent)
            .then(() => this.setState({ open: false }))
    }

    createTechnicalEvent = (measures, toolState) => {
        if (!this.state.piezoMode) {
            return
        }
        const { tool, startDate, endDate, changedValue, compensationCoeff, piezometerThresholds } = this.state
        if (tool === 'validation') {
            return
        }
        const getDates = () => measures.length > 1 ? `${i18n.fromDate} ${getFullDate(startDate)} ${i18n.to} ${getFullDate(endDate)}` : `${i18n.fromDate} ${getFullDate(startDate)}`
        const eventProps = (() => {
            switch (tool) {
                case 'compensation':
                    const m1 = getMaintenance1(this.props.stationEvents, startDate, this.state)
                    const m2 = getMaintenance2(this.props.stationEvents, endDate, this.state)
                    const NGFoffset = this.state.displayCote === MEASURE_COTE.NGF ? changedValue : -changedValue
                    const NGFcoeff = compensationCoeff || 1
                    return {
                        comment: `${i18n.compensation} ${getDates()}\n
                    ${`Coeff : ${NGFcoeff}m NGF`}\n${`Offset : ${NGFoffset}m NGF`}${
                            m1 ? `\n\n${m1}` : ''}${m2 ? `\n\n${m2}` : ''}`,
                        problem: JSON.stringify({
                            tool,
                            coeff: NGFcoeff,
                            offset: NGFoffset,
                        }),
                    }
                case 'purge':
                    const labelDelete = toolState.deleteMode === 'reset' ? i18n.reseting : (toolState.deleteMode === 'delete' ? i18n.deleteAction : i18n.purge)
                    return {
                        comment: `${labelDelete} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                // case 'validation':
                //     return {
                //         comment: `${i18n.validation} ${getDates()}\n
                //             ${toolState.status ? `${i18n.status} : ${getLabel(this.props.status, toolState.status)}\n` : ''}
                //             ${toolState.qualification ? `${i18n.qualification} : ${getLabel(this.props.qualifications, toolState.qualification)}` : ''}`,
                //         problem: JSON.stringify({
                //             tool,
                //         }),
                //     }
                case 'correction':
                    const mcorr1 = getMaintenance1(this.props.stationEvents, startDate, this.state)
                    const mcorr2 = getMaintenance2(this.props.stationEvents, endDate, this.state)
                    const NGFValue = this.state.displayCote === MEASURE_COTE.NGF ? changedValue : getNGFValue({ value: changedValue }, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti)
                    const depthValue = this.state.displayCote === MEASURE_COTE.NGF ? getMeasureValue({ NGF: changedValue }, MEASURE_COTE.DEPTH, this.state.lastLandmark, this.state.groundRefAlti) : changedValue
                    return {
                        comment: `${i18n.driftingCorrection} ${getDates()}\n
                            ${i18n.valueLabel} : ${depthValue} m (${NGFValue} m NGF)${
                            mcorr1 ? `\n\n${mcorr1}` : ''}${mcorr2 ? `\n\n${mcorr2}` : ''}`,
                        problem: JSON.stringify({ tool, NGFValue, depthValue }),
                    }
                case 'thresholds':
                    return {
                        comment: piezometerThresholds.filter(m => m.isNew).map(t => `${i18n.newThreshold} : ${t.value}\n`).join()
                            + piezometerThresholds.filter(m => m.isUpdated).map(t => `${i18n.updatedThreshold} : ${t.oldValue} => ${t.value}\n`).join()
                            + piezometerThresholds.filter(m => m.isDeleted).map(t => `${i18n.deletedThreshold} : ${t.value}\n`).join(),
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case 'import':
                    return {
                        comment: this.getImportTechnicalEvent(measures),
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                default:
                    return { comment: '' }
            }
        })()

        const newEvent = {
            eventType: 'T',
            date: moment().valueOf(),
            eventHour: moment().valueOf(),
            startDate,
            endDate,
            graph: '1',
            ...eventProps,
        }

        this.props.addEvent('piezometry', this.props.id, newEvent)
            .then(() => this.setState({ open: false }))
    }

    getImportTechnicalEvent = (measures) => {
        const { startDateImport, endDateImport, display } = this.state
        const type = display === 'cumul' ? 'Cumul' : display === 'addEnd' ? 'Ajout Fin' : 'Remplacement'
        const description = `${i18n.manualImport}\n
            ${i18n.fromDate} ${getDateWithHourString(startDateImport)} ${i18n.to} ${getDateWithHourString(endDateImport)}\n
            ${i18n.type} : ${type}\n`
        switch (display) {
            case 'cumul':
            case 'addEnd':
                return `${description} ${i18n.numberImported} : ${measures.length}`
            case 'replace':
                return `${description} ${i18n.numberReplaced} : ${measures.insert}\n
                                    ${i18n.numberDeleted} : ${measures.delete}\n`
            default:
                return null
        }
    }

    getMaxDate = () => this.state.measures.length ? last(this.state.measures).date : null

    getToolContent = () => {
        const state = this.state
        const dates = pick(state, ['startDate', 'endDate'])
        const datesImport = pick(state, ['startDateImport', 'endDateImport'])
        const params = {
            ...pick(state, ['selectionMode', 'measures', 'initialMeasures', 'replayingCorrection', 'draggable', 'changedValue', 'selectedPoint', 'displayCote', 'lastLandmark', 'groundRefAlti', 'display', 'piezoMode', 'selectedDataType', 'tableView']),
            dates,
            datesImport,
            changeParent: changes => this.setState(changes),
            createTechnicalEvent: this.createTechnicalEvent,
            updateTechnicalEvent: this.updateTechnicalEvent,
            onCancel: this.onCancel,
            id: this.props.match.params.id,
            piezometerThresholds: state.piezometerThresholds,
            piezometerImportedMeasure: state.piezometerImportedMeasure,
            reloadData: () => this.loadData({ minDate: this.state.minDate, maxDate: this.state.maxDate }),
        }
        switch (state.tool) {
            case 'validation':
                return <ValidationToolPanel {...params} selectHisto={() => this.setState({ startDate: (state.measures[0] || {}).date || state.minDate, endDate: state.maxDate || this.getMaxDate() })} />
            case 'purge':
                return <DeleteToolPanel {...params} />
            case 'compensation':
                return <CompensationToolPanel {...params} />
            case 'correction':
                return <CorrectionToolPanel {...params} />
            case 'event':
                return <EventsToolPanel {...params} />
            case 'thresholds':
                return <ThresholdsToolPanel {...params} />
            case 'import':
                return <ImportToolPanel {...params}/>
            case 'options':
                return <OptionsToolPanel {...params} piezometerChartOptions={this.state.piezometerChartOptions} dataType={ this.state.selectedDataType } yScale={ this.yScale }/>
            default:
                return (
                    <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                )
        }
    }

    setDraggable = (rawMeasures) => {
        if (!this.state.dragging && this.state.draggable) {
            const chart = getComponentWithId('.sieauChart').getInstance()
            // chart.on('dataZoom', () => this.updatePosition(chart, rawMeasures))
            const start = this.state.startDate
            const end = this.state.endDate
            const filtered = (start && end && rawMeasures.filter(m => m.value[2] && m.value[0] >= start && m.value[0] <= end) || [])
            const lastMeasure = filtered && last(filtered)
            const toolObj = (() => {
                switch (this.state.tool) {
                    case 'validation':
                        return { measures: rawMeasures, onDrag: this.onPointDragging }
                    case 'compensation':
                        return { measures: filtered, onDrag: this.onPointDraggingCompensation, processedMeasures: filtered.map(m => m.value[2]) }
                    case 'correction':
                        return { measures: [lastMeasure], onDrag: this.onPointDraggingCorrection, processedMeasures: filtered.map(m => m.value[2]) }
                    default:
                        return null
                }
            })()
            if (toolObj) {
                chart.setOption({
                    graphic: toolObj.measures.map((item) => ({
                        type: 'circle',
                        position: chart.convertToPixel({ xAxisIndex: 1, yAxisIndex: 2 }, item.value),
                        shape: {
                            cx: 0,
                            cy: 0,
                            r: 5,
                        },
                        invisible: true,
                        draggable: true,
                        ondrag: (elem) => {
                            const newValue = chart.convertFromPixel({ xAxisIndex: 1, yAxisIndex: 2 }, [elem.offsetX, elem.offsetY])
                            toolObj.onDrag(newValue, item, toolObj.processedMeasures)
                        },
                        ondragstart: () => this.setState({ dragging: true }),
                        ondragend: () => this.setState({ dragging: false }),
                        onmousedown: () => {
                            if (this.state.selectionMode === 'point') {
                                this.setState({ selectedPoint: item.value[2] })
                            }
                        },
                        z: 100,
                        measure: item.value[2],
                    })),
                })
            }
        }
        return {}
    }

    onPointDragging = (newItem, item) => {
        const measure = item.value[2]
        if (!this.state.selectedPoint || this.state.selectedPoint.measureIndex === measure.measureIndex) {
            const diff = round(newItem[1] - measure.calculated)
            const funcNGF = this.state.displayCote === MEASURE_COTE.NGF ? (a, b) => round(a+b) : (a, b) => round(a-b)
            const funcDepth = this.state.displayCote === MEASURE_COTE.NGF ? (a, b) => round(a-b) : (a, b) => round(a+b)
            const newMeasure = { ...this.state.measures[measure.measureIndex], value: funcDepth(measure.value, diff), NGF: funcNGF(measure.NGF, diff), updated: true, calculated: newItem[1] }
            const newMeasures = Object.assign([], this.state.measures, {
                [measure.measureIndex]: newMeasure,
            })
            this.setState({ measures: newMeasures, selectedPoint: newMeasure, startDate: newMeasure.date, endDate: newMeasure.date })
            getComponentWithId('#validationToolPanel').setCancelButtonWhenMoving()
        }
    }

    onPointDraggingCompensation = (newItem, item, processedMeasures) => {
        const measure = item.value[2]

        // diff: différence avec la modif en cours
        const diff = round(newItem[1] - measure.calculated)

        // previousDiff: différence avec la mesure avant la modif en state (!= entre this.state.measures et this.state.initialMeasures)
        const previousCalculated = getMeasureValue(this.state.initialMeasures[measure.measureIndex], this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti)
        const previousDiff = round(newItem[1] - previousCalculated)

        // initialDiff: différence avec la mesure initiale (champs initialNGF et initialValue, stockés direct en base et qui ne changent jamais après l'import)
        const initialCalculated = getMeasureValue(measure, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti, true)
        const initialDiff = round(newItem[1] - initialCalculated)

        const usedChangedValue = this.state.replayingCorrection ? initialDiff : previousDiff

        const funcNGF = this.state.displayCote === MEASURE_COTE.NGF ? (a, b) => round(a+b) : (a, b) => round(a-b)
        const funcDepth = this.state.displayCote === MEASURE_COTE.NGF ? (a, b) => round(a-b) : (a, b) => round(a+b)
        const changes = processedMeasures.reduce((acc, m) => ({ ...acc, [m.measureIndex]: { ...m, value: funcDepth(m.value, diff), NGF: funcNGF(m.NGF, diff), updated: true } }), {})
        const newMeasures = Object.assign([], this.state.measures, changes)
        this.setState({ measures: newMeasures, changedValue: usedChangedValue })
    }

    onPointDraggingCorrection = (newItem, item, processedMeasures) => {
        const measure = item.value[2]

        // diff: différence avec la modif en cours
        const diff = round(newItem[1] - measure.calculated)

        // previousDiff: différence avec la mesure avant la modif en state (!= entre this.state.measures et this.state.initialMeasures)
        // const previousCalculated = getMeasureValue(this.state.initialMeasures[measure.measureIndex], this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti)
        // const previousDiff = round(newItem[1] - previousCalculated)

        // initialDiff: différence avec la mesure initiale (champs initialNGF et initialValue, stockés direct en base et qui ne changent jamais après l'import)
        // const initialCalculated = getMeasureValue(measure, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti, true)
        // const initialDiff = round(newItem[1] - initialCalculated)

        // const usedChangedValue = this.state.replayingCorrection ? initialDiff : previousDiff

        const len = processedMeasures.length
        const lastDate = processedMeasures[len-1].date
        const timeDiff = lastDate - processedMeasures[0].date
        const getDiff = (m) => diff * (1 - ((lastDate - m.date) / timeDiff))
        const funcNGF = this.state.displayCote === MEASURE_COTE.NGF ? (a, b) => round(a+b) : (a, b) => round(a-b)
        const funcDepth = this.state.displayCote === MEASURE_COTE.NGF ? (a, b) => round(a-b) : (a, b) => round(a+b)
        const changes = processedMeasures.reduce((acc, m) => ({ ...acc, [m.measureIndex]: { ...m, value: funcDepth(m.value, getDiff(m)), NGF: funcNGF(m.NGF, getDiff(m)), updated: true } }), {})
        const newMeasures = Object.assign([], this.state.measures, changes)

        this.setState({ measures: newMeasures, changedValue: round(newItem[1]) })
    }

    getDataTypes = () => {
        return this.props.piezometerStatistics.map(t => this.props.piezometryDataTypes.find(t2 => t2.id === t.typeId))
    }

    getTable = () => {
        const data = this.state.measures.map(d => {
            const color = d.date === this.state.startDate || (d.date >= this.state.startDate && d.date <= this.state.endDate) ? '#7fdde9' : 'white'
            return {
                measure: d,
                date: { value: getFullDate(d.date), color },
                value: { value: this.state.piezoMode ? getMeasureValue(d, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti) : d.value, color },
                sensorValue: { value: d.initialValue + (d.cote === MEASURE_COTE.NGF ? ' [NGF]' : ''), color },
                initialValue: { value: this.state.piezoMode ? getMeasureValue(d, this.state.displayCote, this.state.lastLandmark, this.state.groundRefAlti, true) : d.initialValue, color },
                status: { value: getLabel(getStatuses(), d.status), color: getMeasureStatusColorTable(d.status) },
                qualification: { value: getLabel(getQualifications(), d.qualification), color },
                nature: { value: getSandreIndexLabel(this.props.sandreIndex, SANDRE.PIEZOMETER_MEASURE_NATURE, d.nature), color },
                producer: { value: getObjectLabel(this.props.contributorsIndex[d.producer], 'mnemonique'), color },
                ptInitial: { value: d.initialPoint === 1 ? i18n.yes : '', color },
            }
        })
        return (
            <div className='padding-top-5'>
                <Table
                    title={ i18n.measures }
                    data={ data }
                    paging
                    nbPerPageLabel={ nbPerPageLabel }
                    type={{ headers: ['date', 'value', 'initialValue', 'sensorValue', 'status', 'qualification', 'nature', 'producer', 'ptInitial'] }}
                    headersTip={ {
                        value: 'Valeur actuelle. Peut être modifiée par les utilisateurs via l\'écran de validation',
                        sensorValue: 'Valeur brute récupérée lors de l\'import. Elle ne peut jamais être modifiée.\n Elle est affichée ici sans conversion à l\'affichage.',
                        initialValue: 'Valeur brute récupérée lors de l\'import. Elle ne peut pas être modifiée, sauf lors des opérations de changements de repère.',
                    } }
                    headersIcon={{ value: { icon: 'help', color: '#259cff' }, sensorValue: { icon: 'help', color: '#259cff' }, initialValue: { icon: 'help', color: '#259cff' } }}
                    onClick={ m => this.selectPoint(m.measure) }
                    condensed
                    sortable
                    color
                    actions={ [{ iconName: 'file_download', onClick: () => {
                        exportFile({
                            data: this.getExportData(),
                            exportType: 'xlsx',
                            titleFile: i18n.overview,
                        })
                    } }] }
                />
            </div>
        )
    }

    render() {
        if (this.props.piezometer.id) {
            const changeDisabled = this.state.measures.filter(m => m.updated).length || this.state.locked || this.state.piezometerImportedMeasure.length
            const chronicDataTypes = [-1, ...this.props.piezometryDataTypes.filter(d => d.isPiezo).map(d => d.id)]
            const tools = [
                { icon: validateImg, tool: 'validation', height: 35, width: 35, tooltip: i18n.validation },
                { icon: purgeImg, tool: 'purge', height: 35, width: 30, tooltip: i18n.deleting },
                { icon: importImg, tool: 'import', height: 35, width: 30, tooltip: i18n.manualImport, hide: this.state.tableView },
                { icon: compensationImg, tool: 'compensation', height: 35, width: 50, tooltip: i18n.compensation, hide: !this.state.piezoMode || this.state.tableView || !chronicDataTypes.includes(this.state.selectedDataType) },
                { icon: correctionImg, tool: 'correction', height: 35, width: 45, tooltip: i18n.driftingCorrection, hide: !this.state.piezoMode || this.state.tableView || !chronicDataTypes.includes(this.state.selectedDataType) },
                { icon: optionsImg, tool: 'options', height: 35, width: 35, tooltip: i18n.chartOptions, hide: this.state.tableView },
                { icon: thresholdImg, tool: 'thresholds', height: 35, width: 35, tooltip: i18n.thresholds, hide: this.state.tableView },
                { icon: eventImg, tool: 'event', height: 35, width: 35, tooltip: i18n.events, hide: !this.state.piezoMode || this.state.tableView || !chronicDataTypes.includes(this.state.selectedDataType) },
            ].filter(t => !t.hide)
            const changeDataType = v => {
                const newDt = this.props.piezometryDataTypes.find(dt => dt.id == v)
                const toolChange = ['validation', 'purge', 'options', 'thresholds', 'import'].includes(this.state.tool) ? {} : { tool: 'validation' }
                this.setState(
                    { selectedDataType: v, dataLoaded: false, piezoMode: v === -1 || (newDt && newDt.isPiezo), startDate: null, endDate: null, ...toolChange },
                    () => this.loadData({ minDate: this.state.minDate, maxDate: this.state.maxDate })
                )
            }
            const changeView = v => {
                if (!changeDisabled || ['validation', 'purge'].includes(this.state.tool)) {
                    const toolChange = ['validation', 'purge'].includes(this.state.tool) ? {} : { tool: 'validation' }
                    this.setState({ tableView: v, ...toolChange })
                }
            }
            const getIcon = (icon) => (
                <li style={{ display: 'inline-block' }}>
                    <img
                        className={`validationIcon clickable no-margin ${icon.tool === this.state.tool && 'activated'}`} style={ { height: icon.height, width: icon.width }}
                        onClick={ () => {
                            if (changeDisabled) {
                                this.props.warning(i18n.pleaseSaveModifs)
                            } else if (icon.tool === 'import' && (!this.props.piezometer?.link_altimetrySystems?.length || !this.props.piezometer?.link_landmarks.length)) {
                                this.props.warning('Les repères ou systèmes altimétriques ne sont pas renseignés')
                            } else {
                                this.setState({ tool: icon.tool })
                            }
                        } }
                        src={icon.icon} {...sieauTooltip(icon.tooltip)}
                    />
                </li>
            )
            return (
                <div className='row no-margin validationPiezoApp padding-left-2 padding-top-1'>
                    <div className='col s9 no-padding'>
                        <div>
                            <Card>
                                <ChartTabs
                                    onChangeDate={(changes, forced) => {
                                        if (this.state.minDate !== changes.minDate || this.state.maxDate !== changes.maxDate || forced) {
                                            this.loadData(changes)
                                        }
                                    }}
                                    withPiezoCote withInitialDisplay location={ this.props.location }
                                    changeParent={ changes => {
                                        this.setState(changes)
                                    } }
                                    simpleMode={ !this.state.piezoMode }
                                />
                                { this.state.dataLoaded ? (this.state.tableView ? this.getTable() : this.getChart()) : <MessageCard className='padding-top-7 padding-left-2 padding-right-2 padding-bottom-2'>{ i18n.loading }</MessageCard> }
                            </Card>
                        </div>
                    </div>
                    <div className='col s3'>
                        <Card>
                            <Row className='padding-top-1 valign-wrapper'>
                                <Select col={ 10 } value={ this.state.selectedDataType } options={ orderBy(this.getDataTypes(), 'order', 'asc') } noSort label={ i18n.dataType } onChange={ changeDataType } disabled={ changeDisabled } noNullValue/>
                                <div className='col s2'>
                                    <Icon icon={ this.state.tableView ? 'multiline_chart' : 'list' } onClick={ () => changeView(!this.state.tableView) }
                                        style={{ fontSize: '2.5rem', color: changeDisabled && !['validation', 'purge'].includes(this.state.tool) ? 'grey' : '#2196F3' } } tooltip={this.state.tableView ? i18n.graphicMode : i18n.tableMode}
                                    />
                                </div>
                            </Row>
                        </Card>
                        <Card>
                            <ul style={{ textAlign: 'center', 'margin-top': 5, 'margin-bottom': 5, 'padding-top': 5, 'padding-bottom': 5 }}>{tools.map(icon => getIcon(icon))}</ul>
                        </Card>
                        <div className='padding-top-1' />
                        {this.getToolContent()}
                    </div>
                </div>
            )
        }
        return null
    }
}

PiezometryValidationApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    piezometer: instanceOf(DtoPiezometer),
    measures: arrayOf(DtoPiezometryStationMeasure),
    piezometerImportedMeasure: arrayOf(DtoPiezometryStationMeasure),
    stationEvents: arrayOf(DtoEvent),
    piezometerChartOptions: arrayOf(DtoPiezometerChartOptions),
    piezometerThresholds: arrayOf(DtoPiezoThreshold),
    status: arrayOf(DtoStatus),
    qualifications: arrayOf(DtoQualification),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoContributor),
    contributorsIndex: objectOf(DtoContributor),
    sandreIndex: objectOf(objectOf(DtoSandreCode)),
    jobs: arrayOf(DtoJob),
    piezometers: arrayOf(DtoPiezometerLight),
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.object,
    }),
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    fetchMeasures: PropTypes.func,
    push: PropTypes.func,
    addEvent: PropTypes.func,
    updateEvent: PropTypes.func,
    fetchPiezometryDataTypes: PropTypes.func,
    fetchPiezometerThresholds: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    piezometerStatistics: arrayOf(DtoMeasureStats),
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    piezometers: store.PiezometryReducer.piezometersLight,
    measures: store.PiezometerStationReducer.piezoValidationChartMeasures,
    stationEvents: store.EventsReducer.stationEvents,
    piezometerChartOptions: store.PiezometerStationReducer.piezometerChartOptions,
    piezometerThresholds: store.PiezometerStationReducer.piezometerThresholds,
    status: store.QualityReducer.status,
    qualifications: store.QualityReducer.qualifications,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    sandreIndex: store.ReferencialReducer.sandreIndex,
    contributors: store.ContributorReducer.contributors,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
    jobs: store.JobReducer.jobs,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
})

const mapDispatchToProps = {
    addEvent: EventsAction.addEvent,
    updateEvent: EventsAction.updateEvent,
    warning: ToastrAction.warning,
    fetchMeasures: PiezometerStationAction.fetchPiezoValidationMeasures,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    fetchPiezometerThresholds: PiezometerStationAction.fetchPiezometerThresholds,
    fetchPiezoMeasuresStats: PiezometerStationAction.fetchPiezoMeasuresStats,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryValidationApp)
