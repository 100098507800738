/* eslint-disable max-nested-callbacks */
import ExportFileModal from 'components/modal/ExportFileModal'
import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'
import { countBy, groupBy, keyBy, keys, max, maxBy, mean, meanBy, range, round, sumBy, uniqBy } from 'lodash'
import CatchmentDto from 'catchment/dto/CatchmentDto'
import { getYear } from 'utils/DateUtil'
import { getContaminationState, getLastYearWithAnalysis, isExceedAverageSubstance, isExceedNitrates, isExceedPesticides, isExceedMaxSumSubstance, isExceedAverageSumSubstance, isExceedMaxSubstance } from 'catchment/utils/CatchmentUtil'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import { getLabel } from 'utils/StoreUtils'
import CatchmentPointDto from 'catchment/dto/CatchmentPointDto'
import { AVERAGE_SUBSTANCE, AVERAGE_SUM, MAX_SUBSTANCE, MAX_SUM, NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import DtoAnalysisUltraLight from 'quality/dto/analyse/DtoAnalysisUltraLight'
import { calculateAverage, searchMaxResult, searchMinResult, searchP90Result } from 'utils/AnalyseUtils'

const CatchmentsOverviewExportModal = ({
    catchments = [],
    catchmentPoints = [],
    analysis = [],
    lastPeriodAnalysis = [],
    useP90 = false,

    isExportModalOpen = false,
    setIsExportModalOpen = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        parameters,
        cities,
        qualityThresholds,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
        cities: store.CityReducer.cities,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const thresholdNitrates = useApplicationSetting('CATCHMENT_NITRATES_THRESHOLD', setting => setting ? parseFloat(setting) : 50)
    const thresholdPesticides1 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_1', setting => setting ? parseFloat(setting) : 0.1)
    const thresholdPesticides2 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_2', setting => setting ? parseFloat(setting) : 0.5)
    const startPeriod = useApplicationSetting('CATCHMENT_START_PERIOD', setting => setting ? parseInt(setting) : undefined)
    const endPeriod = useApplicationSetting('CATCHMENT_END_PERIOD', setting => setting ? parseInt(setting) : undefined)
    const specificThreshold = useApplicationSetting('CATCHMENT_SPECIFIC_THRESHOLD')

    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)
    const calculationMethod = useApplicationSetting('CATCHMENT_OVERVIEW_CALCULATION_METHOD', listStringParser)

    const threshold = qualityThresholds.find(t => t.thresholdCode === specificThreshold)

    const optionsCalculState = {
        thresholdNitrates,
        thresholdPesticides1,
        thresholdPesticides2,
        useP90,
        threshold,
        listSumPesticides,
        calculationMethod,
    }

    const rangeDate = range(startPeriod, endPeriod + 1, 1)

    const exportEvolutionContamination = (type) => {
        const quantifiedLastPeriodAnalysis = lastPeriodAnalysis.filter(a => a.remark === '1')
        const nitratesAnalysis = quantifiedLastPeriodAnalysis.filter(({ parameter }) => parameter === NITRATES_CODE)
        const nitratesAnalysisGroup = groupBy(nitratesAnalysis, 'qualitometer')

        const pesticidesAnalysis = quantifiedLastPeriodAnalysis.filter(({ parameter }) => parameter !== NITRATES_CODE)
        const pesticidesAnalysisGroup = groupBy(pesticidesAnalysis, 'qualitometer')

        const data = catchmentPoints.map(point => {
            const catchment = catchments.find(c => c.id === point.id)
            const qualitometer = qualitometers.find(q => q.code === point.codeWithoutDesignation)

            const nitrates = qualitometer?.id && nitratesAnalysisGroup[qualitometer.id] || []

            const pesticides = qualitometer?.id && pesticidesAnalysisGroup[qualitometer.id] || []
            const pesticidesYearGroup = groupBy(pesticides, a => getYear(a.sampleDate))

            const sumPesticides = listSumPesticides.length ? pesticides.filter(a => listSumPesticides.includes(a.parameter)) : pesticides
            const sumPesticidesYearGroup = groupBy(sumPesticides, a => getYear(a.sampleDate))

            const percentile90 = searchP90Result(nitrates)
            const minResult = searchMinResult(nitrates)
            const average = calculateAverage(nitrates)
            const maxResult = searchMaxResult(nitrates)


            const averageTotal = mean(Object.keys(pesticidesYearGroup).map(year => {
                const listSum = listSumPesticides.length ? pesticidesYearGroup[year].filter(a => listSumPesticides.includes(a.parameter)) : pesticidesYearGroup[year]
                const nbOperation = uniqBy(pesticidesYearGroup[year], a => `${a.qualitometer}#${a.operation}`).length
                return sumBy(listSum, 'result') / nbOperation
            }))

            const paramGroup = groupBy(pesticides, a => a.parameter)
            const averageMax = max(Object.keys(paramGroup).map(param => {
                const paramYearGroup = groupBy(paramGroup[param], a => getYear(a.sampleDate))
                // return meanBy(Object.keys(paramYearGroup), y => meanBy(paramYearGroup[y], 'result'))
                return meanBy(Object.keys(paramYearGroup), y => sumBy(paramYearGroup[y], 'result') / uniqBy(paramYearGroup[y], a => `${a.qualitometer}#${a.operation}`).length)
            }))

            // const annualAverageMax = max(Object.keys(yearGroup).map(year => meanBy(yearGroup[year], 'result')))
            const annualAverageMax = max(Object.keys(sumPesticidesYearGroup).map(year => sumBy(sumPesticidesYearGroup[year], 'result') / uniqBy(sumPesticidesYearGroup[year], a => `${a.qualitometer}#${a.operation}`).length))

            const sumGroupByOperation = groupBy(sumPesticides, 'operation')
            const maxSumPesticides = max(keys(sumGroupByOperation).map(key => sumBy(sumGroupByOperation[key], 'result')))

            const nitratesGroupOperation = groupBy(nitrates, 'operation')
            const nbNitratesOperation = keys(nitratesGroupOperation).length
            const nbExceedNitrates = keys(nitratesGroupOperation).filter(opeId => {
                const {
                    [opeId]: nitratesByOperation = [],
                } = nitratesGroupOperation
                return isExceedNitrates(nitratesByOperation, thresholdNitrates, useP90)
            }).length


            const pesticidesGroupOperation = groupBy(pesticides, 'operation')
            const nbPesticidesOperation = keys(pesticidesGroupOperation).length
            const nbExceedSumPesticides = keys(pesticidesGroupOperation).filter(opeId => {
                const {
                    [opeId]: pesticidesByOperation = [],
                } = pesticidesGroupOperation
                const methodsSum = calculationMethod.filter(method => [AVERAGE_SUM, MAX_SUM].includes(method))
                if (methodsSum.length === 0) {
                    return isExceedMaxSumSubstance(pesticidesByOperation, thresholdPesticides2, listSumPesticides)
                }
                const results = methodsSum.map(method => {
                    switch (method) {
                        case AVERAGE_SUM: return isExceedAverageSumSubstance(pesticidesByOperation, thresholdPesticides2, listSumPesticides)
                        case MAX_SUM: return isExceedMaxSumSubstance(pesticidesByOperation, thresholdPesticides2, listSumPesticides)
                        default: return false
                    }
                })
                return results.some(r => r)
            }).length
            const nbExceedAveragePesticides = keys(pesticidesGroupOperation).filter(opeId => {
                const {
                    [opeId]: pesticidesByOperation = [],
                } = pesticidesGroupOperation
                const methodsSubstance = calculationMethod.filter(method => [AVERAGE_SUBSTANCE, MAX_SUBSTANCE].includes(method))
                if (methodsSubstance.length === 0) {
                    return isExceedAverageSubstance(pesticidesByOperation, thresholdPesticides1, threshold)
                }
                const results = methodsSubstance.map(method => {
                    switch (method) {
                        case AVERAGE_SUBSTANCE: return isExceedAverageSubstance(pesticidesByOperation, thresholdPesticides1, threshold)
                        case MAX_SUBSTANCE: return isExceedMaxSubstance(pesticidesByOperation, thresholdPesticides1, threshold)
                        default: return false
                    }
                })
                return results.some(r => r)
            }).length
            const nbExceedPesticides = keys(pesticidesGroupOperation).filter(opeId => {
                const {
                    [opeId]: pesticidesByOperation = [],
                } = pesticidesGroupOperation
                return isExceedPesticides(pesticidesByOperation, optionsCalculState)
            }).length

            return {
                code: catchment.code,
                name: catchment.name,
                x: { value: qualitometer?.x, cellType: 'number' },
                y: { value: qualitometer?.y, cellType: 'number' },
                projection: { value: qualitometer?.projection, cellType: 'number' },
                mainPointLabel: point.mainPoint ? i18n.yes : i18n.no,
                state: i18n[getContaminationState(nitrates, pesticides, optionsCalculState)],
                nitratesThreshold: { value: thresholdNitrates, cellType: 'number' },
                percentile90: { value: percentile90, format: '0.00000', cellType: 'number' },
                min: { value: minResult, format: '0.00000', cellType: 'number' },
                average: { value: average, format: '0.00000', cellType: 'number' },
                max: { value: maxResult, format: '0.00000', cellType: 'number' },
                pesticideThreshold: { value: thresholdPesticides2, cellType: 'number' },
                averageAnnualAverageTotalPesticides: { value: round(averageTotal, 5), format: '0.00000', cellType: 'number' },
                maxAAAByParam: { value: round(averageMax, 5), format: '0.00000', cellType: 'number' },
                annualAverageMax: { value: round(annualAverageMax, 5), format: '0.00000', cellType: 'number' },

                maxSubstance: { value: searchMaxResult(pesticides), format: '0.0000', cellType: 'number' },
                maxSumPesticides: { value: maxSumPesticides, format: '0.0000', cellType: 'number' },

                nitratesNonConformityPercentage: { value: nitrates.length && nbNitratesOperation ? (nbExceedNitrates / nbNitratesOperation) * 100 : undefined, format: '0.00', cellType: 'number' },
                substanceNonConformityPercentage: { value: pesticides.length && nbPesticidesOperation ? (nbExceedAveragePesticides / nbPesticidesOperation) * 100 : undefined, format: '0.00', cellType: 'number' },
                sumPesticidesNonConformityPercentage: { value: pesticides.length && nbPesticidesOperation ? (nbExceedSumPesticides / nbPesticidesOperation) * 100 : undefined, format: '0.00', cellType: 'number' },
                pesticidesNonConformityPercentage: { value: pesticides.length && nbPesticidesOperation ? (nbExceedPesticides / nbPesticidesOperation) * 100 : undefined, format: '0.00', cellType: 'number' },
            }
        })
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: Object.keys(data[0]) },
            ...data.slice(1),
        ] : []

        dispatch(ExportAction.export(formatData(dataWithHeaders), type, i18n.evolutionContaminationNitratesPesticides))
    }

    const exportQualityReportSummary = (type) => {
        const nitratesAnalysis = analysis.filter(({ parameter }) => parameter === NITRATES_CODE)
        const nitratesAnalysisGroup = groupBy(nitratesAnalysis, 'qualitometer')

        const pesticidesAnalysis = analysis.filter(({ parameter }) => parameter !== NITRATES_CODE)
        const pesticidesAnalysisGroup = groupBy(pesticidesAnalysis, 'qualitometer')

        const thresholdsIndexed = keyBy(threshold?.thresholds ?? [], 'parameterCode')

        const data = catchmentPoints.map(point => {
            const catchment = catchments.find(c => c.id === point.id)
            const qualito = qualitometers.find(q => q.code === point?.codeWithoutDesignation)
            const city = qualito?.townCode && cities.find(c => c.id === qualito.townCode)

            const nitrates = qualito?.id && nitratesAnalysisGroup[qualito.id] || []
            const nitratesGroupYear = groupBy(nitrates, a => getYear(a.sampleDate))
            const nitratesData = rangeDate.reduce((acc, year) => {
                acc[`${useP90 ? 'P90' : 'Max'} NO3 ${year}`] = {
                    value: useP90 ? searchP90Result(nitratesGroupYear[year]) : searchMaxResult(nitratesGroupYear[year]),
                    format: '0.00000',
                    cellType: 'number',
                }
                return acc
            }, {})

            const percentile90 = searchP90Result(nitrates)
            const minResult = searchMinResult(nitrates)
            const average = calculateAverage(nitrates)
            const maxResult = searchMaxResult(nitrates)
            const { above: nbNitratesAbove } = countBy(nitrates, a => a.result > thresholdNitrates ? 'above' : 'below')

            const pesticides = qualito?.id && pesticidesAnalysisGroup[qualito.id] || []
            const quantifiedPesticides = pesticides.filter(a => a.remark === '1')
            const pesticidesGroupYear = groupBy(quantifiedPesticides, a => getYear(a.sampleDate))
            // const pesticidesGroupOperation = groupBy(quantifiedPesticides, 'operation')
            const pesticidesGroupParam = groupBy(quantifiedPesticides, 'parameter')

            const sumPesticides = listSumPesticides.length ? quantifiedPesticides.filter(a => listSumPesticides.includes(a.parameter)) : quantifiedPesticides
            const sumPesticidesGroupOperation = groupBy(sumPesticides, 'operation')
            const sumPesticidesGroupYear = groupBy(sumPesticides, a => getYear(a.sampleDate))

            const maxSumPesticidesByYear = rangeDate.reduce((acc, year) => {
                const {
                    [year]: sumList = [],
                } = sumPesticidesGroupYear
                const groupOperation = groupBy(sumList, 'operation')
                const listSum = Object.keys(groupOperation).map(op => sumBy(groupOperation[op], 'result'))
                acc[`${i18n.maxSumPesticides} ${year}`] = {
                    value: max(listSum),
                    format: '0.0000',
                    cellType: 'number',
                }
                return acc
            }, {})


            const averageData = rangeDate.reduce((acc, year) => {
                const {
                    [year]: sumList = [],
                } = sumPesticidesGroupYear
                const groupOperation = groupBy(sumList, 'operation')
                const listTotalPesticides = Object.keys(groupOperation).map(op => sumBy(groupOperation[op], 'result'))
                acc[`${i18n.averageShort} ${i18n.sum} ${i18n.pesticides} ${year}`] = {
                    value: mean(listTotalPesticides),
                    format: '0.0000',
                    cellType: 'number',
                }
                return acc
            }, {})

            const maxData = rangeDate.reduce((acc, year) => {
                acc[`${i18n.maxSubstance} ${year}`] = {
                    value: maxBy(pesticidesGroupYear[year] || [], 'result')?.result,
                    format: '0.0000',
                    cellType: 'number',
                }
                return acc
            }, {})

            const maxAnnualAverage = rangeDate.reduce((acc, year) => {
                const group = groupBy(pesticidesGroupYear[year] || [], 'parameter')
                const listAnnualAverage = Object.keys(group).map(param => meanBy(group[param], 'result'))
                acc[`${i18n.annualAverageMax} ${year}`] = {
                    value: max(listAnnualAverage),
                    format: '0.0000',
                    cellType: 'number',
                }
                return acc
            }, {})

            const lastYearWithAnalysis = getLastYearWithAnalysis(endPeriod, sumPesticidesGroupYear, startPeriod)

            const paramGroup = groupBy(lastYearWithAnalysis, 'parameter')
            const { paramAverage, paramMax } = Object.keys(paramGroup).reduce((acc, param) => {
                const th = thresholdsIndexed[param]?.threshold1 ?? thresholdPesticides1
                const maxParam = maxBy(paramGroup[param], 'result')?.result
                // const averageParam = meanBy(paramGroup[param], 'result')
                const averageParam = sumBy(paramGroup[param], 'result') / uniqBy(paramGroup[param], 'operation').length
                const label = getLabel(parameters, param, 'name')
                if (maxParam > th) {
                    acc.paramMax.push(`${label} (${round(maxParam, 4)})`)
                }
                if (averageParam > th) {
                    acc.paramAverage.push(`${label} (${round(averageParam, 4)})`)
                }
                return acc
            }, { paramAverage: [], paramMax: [] })

            const nbSample = uniqBy(pesticides, 'operation').length

            const totalAAA = mean(rangeDate.filter(year => sumPesticidesGroupYear[year]?.length).map(year => {
                const pesticidesByYear = sumPesticidesGroupYear[year]
                const groupByOperation = groupBy(pesticidesByYear, 'operation')
                const listSumTotal = Object.keys(groupByOperation).map(opeId => sumBy(groupByOperation[opeId], 'result'))
                return mean(listSumTotal)
            }))

            const nbSubstanceOverflow = quantifiedPesticides.map(a => {
                const th = thresholdsIndexed[a.parameter]?.threshold1 ?? thresholdPesticides1
                return a.result > th
            }).filter(isOverflow => isOverflow).length

            const nbSumOverflow = Object.keys(sumPesticidesGroupOperation).map(opeId => {
                const sumTotal = sumBy(sumPesticidesGroupOperation[opeId], 'result')
                return sumTotal > thresholdPesticides2
            }).filter(isOverflow => isOverflow).length

            const maxSumPesticides = max(Object.keys(sumPesticidesGroupOperation).map(opeId => sumBy(sumPesticidesGroupOperation[opeId], 'result')))

            const maxAAAByParam = max(Object.keys(pesticidesGroupParam).map(param => {
                const pesticidesByParam = pesticidesGroupParam[param]
                const groupByYear = groupBy(pesticidesByParam, a => getYear(a.sampleDate))
                const listMean = Object.keys(groupByYear).map(year => meanBy(groupByYear[year], 'result'))
                return mean(listMean)
            }))

            const maxMaxParam = maxBy(quantifiedPesticides, 'result')?.result

            const paramConcernedByMaxAAA = Object.keys(pesticidesGroupParam).map(param => {
                const th = thresholdsIndexed[param]?.threshold1 ?? thresholdPesticides1
                const pesticidesByParam = pesticidesGroupParam[param]
                const groupByYear = groupBy(pesticidesByParam, a => getYear(a.sampleDate))
                const listMean = Object.keys(groupByYear).map(year => meanBy(groupByYear[year], 'result'))
                const aaa = mean(listMean)
                if (aaa > th) {
                    const label = getLabel(parameters, param, 'name')
                    return `${label} (${round(aaa, 4)})`
                }
                return ''
            }).filter(l => !!l).join(', ')

            const paramConcernedByMaxMax = Object.keys(pesticidesGroupParam).map(param => {
                const th = thresholdsIndexed[param]?.threshold1 ?? thresholdPesticides1
                const pesticidesByParam = pesticidesGroupParam[param]
                const maxByParam = maxBy(pesticidesByParam, 'result')?.result
                if (maxByParam > th) {
                    const label = getLabel(parameters, param, 'name')
                    return `${label} (${round(maxByParam, 4)})`
                }
                return ''
            }).filter(l => !!l).join(', ')

            const quantifiedParameterList = uniqBy(quantifiedPesticides, 'parameter').map(a => {
                return getLabel(parameters, a.parameter, 'name')
            }).filter(l => !!l).join(', ')

            return {
                catchment: { value: catchment?.code, cellType: 'string' },
                label: catchment?.name,
                mainPointLabel: point.mainPoint ? i18n.yes : i18n.no,
                bsscode: { value: qualito?.code, cellType: 'string' },
                name: qualito?.name,
                department: catchment?.department,
                city: city?.name,
                state: i18n[getContaminationState(nitrates, pesticides, optionsCalculState)],
                ...nitratesData,
                nbAnalysisNitrates: { value: nitrates.length, format: '0', cellType: 'number' },
                percentile90: { value: percentile90, format: '0.00000', cellType: 'number' },
                min: { value: minResult, format: '0.00000', cellType: 'number' },
                average: { value: average, format: '0.00000', cellType: 'number' },
                max: { value: maxResult, format: '0.00000', cellType: 'number' },
                overrunNb: { value: nbNitratesAbove, cellType: 'number' },
                ...averageData,
                paramConcernedByAnnualAverageAboveThreshold: paramAverage.join(', '),
                ...maxData,
                paramConcernedByMaxAboveThreshold: paramMax.join(', '),
                ...maxAnnualAverage,
                ...maxSumPesticidesByYear,
                nbAnalysisPesticides: { value: pesticides.length, format: '0', cellType: 'number' },
                nbQuantificationsPesticides: { value: quantifiedPesticides.length, format: '0', cellType: 'number' },
                totalAAA: { value: totalAAA, format: '0.00000', cellType: 'number' },
                maxSumPesticides: { value: maxSumPesticides, format: '0.00000', cellType: 'number' },
                nbSample: { value: nbSample, format: '0', cellType: 'number' },
                nbSubstanceOverflow: { value: nbSubstanceOverflow, format: '0', cellType: 'number' },
                nbSumOverflow: { value: nbSumOverflow, format: '0', cellType: 'number' },
                maxAAAByParam: { value: maxAAAByParam, format: '0.00000', cellType: 'number' },
                paramConcernedByMaxAAA,
                maxMaxParam: { value: maxMaxParam, format: '0.00000', cellType: 'number' },
                paramConcernedByMaxMax,
                quantifiedParameterList,
            }
        })
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: Object.keys(data[0]) },
            ...data.slice(1),
        ] : []
        dispatch(ExportAction.export(formatData(dataWithHeaders), type, i18n.qualityReportSummary))
    }

    return (
        <ExportFileModal
            open={isExportModalOpen}
            onClose={() => setIsExportModalOpen(false)}
            data={[{
                name: i18n.qualityReportSummary,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportQualityReportSummary('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportQualityReportSummary('csv'),
                }],
            }, {
                name: i18n.evolutionOfContaminations,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => exportEvolutionContamination('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => exportEvolutionContamination('csv'),
                }],
            }]}
        />
    )
}

CatchmentsOverviewExportModal.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentDto)),
    catchmentPoints: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentPointDto)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight)),
    lastPeriodAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight)),
    useP90: PropTypes.bool,

    isExportModalOpen: PropTypes.bool,
    setIsExportModalOpen: PropTypes.func,
}

export default CatchmentsOverviewExportModal