/* eslint-disable camelcase */
import { Button, Divider, Grid } from '@mui/material'
import { styled } from '@mui/styles'
import AgriAction from 'agriAdministration/actions/AgriAction'
import Icon from 'components/icon/Icon'
import SectionList from 'components/list/section/SectionList'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import MatEventModal from 'exploitations/components/modal/MatEventModal'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import HomeAction from 'home/actions/HomeAction'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import { compact, groupBy, keys, orderBy, uniq, uniqBy } from 'lodash'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoMatEvent from 'materiel/components/variousMateriel/dto/DtoMatEvent'
import DtoMatEventsType from 'materiel/components/variousMateriel/dto/DtoMatEventsType'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CultureAction from 'referencial/components/cultures/actions/CultureAction'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import CulturesFamilyAction from 'referencial/components/culturesFamilies/actions/CulturesFamilyAction'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import i18n from 'simple-react-i18n'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoSamplingPointDecla from 'survey/dto/DtoSamplingPointDecla'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { getDate } from 'utils/DateUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { hasValue } from 'utils/NumberUtil'
import { getSetting } from 'utils/SettingUtils'
import { formatMilliers } from 'utils/StringUtil'
import ModalIndex from './modals/ModalIndex'
import ModalUsage from './modals/ModalUsage'
import moment from 'moment'

const GridItem = styled(Grid)({
    padding: 10,
})

const ChronicleItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
})

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

class DeclarationPointUsages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            real: props?.match?.params?.type === 'reel',
            idInstallation: parseInt(props?.match?.params?.idPoint),
            declaration: {},
            openModalUsage: false,
            usageToUpdate: false,
            point: {},
            installation: {},
            usages: [],
            usagesCurrentYear: [],
            dataLoaded: false,
            declarationLoaded: false,
            openModalIndex: false,
            selectedMaterial: null,
            consoType: null,
            openModalEvent: false,
            selectedEvent: null,
            readMode: props.readMode,
            showAllConso: false,
        }
    }

    componentDidMount() {
        this.props.fetchCulturesFamilies().then(() => {
            this.props.fetchCultures().then(() => {
                this.props.fetchModesIrrigations().then(() => {
                    // eslint-disable-next-line max-nested-callbacks
                    this.props.fetchUsages().then(() => {
                        this.setState({ dataLoaded: true })
                    })
                })
            })
        })
        this.props.fetchExploitationUser().then(() => {
            this.setDeclaration()
        })
        window.scrollTo(0, 0)
    }

    getMateriels = () => {
        const { declaration, variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation).link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps =
            declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 && m.siteCode === idInstallation && !deletedMats.find((mLink) => m.idVarious === mLink.idMat))
            || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat),
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === 8 && situ.siteCode === pumpId && !deletedCounters.find((mLink) => pumpId === mLink.idMat))
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment),
        ], obj => obj)
        const materiels = variousMateriels.filter((m) => [...mergedPumpsIds, ...mergedCountersIds].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        return { pumpsIds: mergedPumpsIds, countersIds: mergedCountersIds, materiels }
    }

    setDeclaration = () => {
        const { real, idInstallation } = this.state
        const { match, survey, exploitation, readMode } = this.props
        this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
        this.props.fetchDeclaration(match.params.idDeclaration).then(() => {
            const { declaration } = this.props
            this.props.fetchInstallation(match.params.idPoint).then(() => {
                const { installation } = this.props
                const point = declaration ? declaration.link_declarationInstallation.find((p) => p.idInstallation === idInstallation) : new DtoSamplingPointDecla({})
                this.setState({
                    installation,
                    point,
                    usages: point.link_usages,
                    usagesCurrentYear: point.link_usagesCurrent || [],
                    declarationLoaded: true,
                    declaration,
                    readMode: declaration.statusCode >= 4 || readMode,
                })
                if (real) {
                    this.props.calculSurveyExploitationVolumes(survey.idSurvey, declaration.idExploitation)
                }
                this.props.setTitle([{
                    title: i18n.planning,
                    href: 'planning',
                }, {
                    title: i18n.surveys,
                    href: 'planning/surveys',
                }, {
                    title: `${survey.name || ''} [${survey.year || ''}]`,
                    href: `survey/${survey.idSurvey}`,
                }, {
                    title: `${i18n.declaration} ${exploitation.codification}`,
                    href: `survey/${survey.idSurvey}/declaration/${match.params.idDeclaration}/stepper`,
                }, {
                    title: `${i18n.pointPrelevement} ${installation.code}${installation.location ? ` - ${installation.location}` : ''}`,
                    href: `survey/${survey.idSurvey}/declaration/${match.params.idDeclaration}/point/${match.params.idPoint}`,
                }])
            })
        })
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    onSaveUsage = (usages) => {
        const { declaration, point, real } = this.state
        const newPoints = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        if (real) {
            newPoints.push({
                ...point,
                link_usagesCurrent: usages,
            })
        } else {
            newPoints.push({
                ...point,
                link_usages: usages,
            })
        }
        const updateDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: newPoints,
        }
        this.setState({
            usages,
            declaration: updateDeclaration,
            declarationLoaded: false,
        })
        this.props.updateDeclaration(updateDeclaration).then(() => {
            this.setDeclaration()
        })
        this.toggleState('openModalUsage')
    }

    onCancelUsage = () => {
        this.toggleState('openModalUsage')
    }

    getListUsages = (current, agri) => {
        const { usages: usagesCat, cultures } = this.props
        const { usagesCurrentYear, usages, readMode, real } = this.state
        const usagesSelected = current ? usagesCurrentYear : usages
        const filteredUsages = compact(usagesSelected.map((u) => {
            const usageCat = usagesCat.find((uCat) => uCat.idUsage === u.idUsage)
            if (usageCat && (agri ? usageCat.category === 1 : usageCat.category !== 1)) {
                return u
            }
            return null
        }))

        if (filteredUsages.length) {
            const total = filteredUsages.reduce((acc, u) => acc + (isNaN(u.requestedYearVolume) ? 0 : u.requestedYearVolume), 0)
            return (
                <GridItem item xs={6}>
                    <SectionList
                        title={!real ? `${agri ? i18n.agri : i18n.nonAgri} : ${formatMilliers(total) || 0} m3` : agri ? i18n.agri : i18n.nonAgri}
                        color={secondaryBlue}
                        openFirst
                    >
                        <Grid
                            container
                            direction='column'
                            justifyContent='center'
                        >
                            {filteredUsages.map((u) => {
                                const culture = cultures.find((c) => c.id === u.idCulture)
                                const usageCat = usagesCat.find((uCat) => uCat.idUsage === u.idUsage)
                                return (
                                    <Grid
                                        key={u.id}
                                        container
                                        direction='column'
                                        justifyContent='center'
                                        style={{ padding: '15px', borderBottom: 'solid black 1px' }}
                                        className={readMode ? '' : 'clickable'}
                                        onClick={readMode ? () => {} : () =>
                                            this.setState({
                                                usageToUpdate: u,
                                                openModalUsage: true,
                                            })
                                        }
                                    >
                                        <>
                                            {agri ? (
                                                <Grid item style={{ fontWeight: 'bold' }}>
                                                    {`${usageCat.description} ${usageCat.category === 1 && culture ? `/ ${culture.name}` : ''}`}
                                                </Grid>
                                            ) : (
                                                <Grid item style={{ fontWeight: 'bold' }}>{usageCat.description}</Grid>
                                            )}
                                            {!real && (
                                                <Grid item>
                                                    {`${usageCat.isCulture ? i18n.indicativeSimu : i18n.askedLabel} : ${formatMilliers(u.requestedYearVolume) || 0} m3${hasValue(u.area) ? ` - ${u.area} ha` : ''} ${hasValue(u.lowWaterVolume) ? `(${i18n.volumeLowWater} : ${formatMilliers(u.lowWaterVolume)} m3)` : ''}`}
                                                </Grid>
                                            )}
                                            {real && (hasValue(u.requestedYearVolume) || hasValue(u.area))&& (
                                                <Grid item>
                                                    {hasValue(u.requestedYearVolume) ?
                                                        `${i18n.volume} : ${formatMilliers(u.requestedYearVolume) || 0} m3${hasValue(u.area) ? ` - ${u.area} ha` : ''} ${hasValue(u.lowWaterVolume) ? `(${i18n.volumeLowWater} : ${formatMilliers(u.lowWaterVolume)} m3)` : ''}`
                                                        : hasValue(u.area) ? `${u.area} ha` : ''}
                                                </Grid>
                                            )}
                                        </>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </SectionList>
                </GridItem>
            )
        }
        return (
            <GridItem item xs={6}>
                <SectionList
                    title={!real ? `${agri ? i18n.agri : i18n.nonAgri} : 0 m3` : agri ? i18n.agri : i18n.nonAgri}
                    color={secondaryBlue}
                    openFirst
                >
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                    />
                </SectionList>
            </GridItem>
        )
    }

    onChangeUsage = (usage) => {
        this.setState({ usageToUpdate: usage, openModalUsage: true })
    }

    onValideUsage = () => {
        const { match } = this.props
        this.props.push(`/survey/${match.params.id}/declaration/${match.params.idDeclaration}/point/${match.params.idPoint}`)
    }

    editModalIndex = (type, id) => {
        this.setState({ selectedMaterial: id, consoType: type, openModalIndex: true })
    }

    getEvolValue = (type, chronicles, chronicle, index) => {
        if (type === TYPE_INDEX) {
            return chronicle.value - chronicles[index + 1].value
        }
        return Math.round(chronicles.slice(index).reduce((acc, d) => acc + d.value, 0))
    }

    getChroniclesByType = (chroniclesByType, type, materiel, readingCoefficient = 1) => {
        const { matEventsTypes } = this.props
        const { readMode, showAllConso } = this.state
        if (chroniclesByType?.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const props = {
                onClick: !readMode ? () => this.editModalIndex(type, materiel.id) : () => {},
            }
            return (
                <>
                    <ChronicleItem item className='italic-label' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.endDate : ''}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3}>
                        {type === TYPE_ESTIM ? i18n.stack : i18n.evolution}
                    </ChronicleItem>
                    {orderedChronicles.map((chronicle, i) => {
                        if (!showAllConso && i >= 10) {
                            return null
                        }
                        if (chronicle.event) {
                            const onClickEvent = {
                                onClick: () => !readMode ? this.setState({ selectedEvent: chronicle, openModalEvent: true }) : () => {},
                            }
                            return (
                                <>
                                    <ChronicleItem item xs={3} {...onClickEvent}>
                                        {getDate(chronicle.measureDate)}
                                    </ChronicleItem>
                                    <ChronicleItem item xs={3} {...onClickEvent}>
                                        {(matEventsTypes.find(({ id }) => id === chronicle.idType) || {}).name || ''}
                                    </ChronicleItem>
                                    <ChronicleItem item xs={3} {...onClickEvent} {...sieauTooltip(chronicle.comment, null, 'bottom')}><Icon>warning</Icon></ChronicleItem>
                                    <ChronicleItem item xs={3} {...onClickEvent} />
                                </>
                            )
                        }
                        const valueEvol = i !== orderedChronicles.length - 1 ? this.getEvolValue(type, orderedChronicles, chronicle, i) : null
                        return (
                            <>
                                <ChronicleItem item xs={3} {...props}>
                                    {getDate(chronicle.measureDate)}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} {...props}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} {...props}>
                                    {`${formatMilliers(chronicle.value) || 0}${chronicle.measureType === 1 ? ' m3' : ''}`}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} style={ valueEvol < 0 ? { color: 'orange' } : {}}>
                                    {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers(Math.abs((valueEvol) * readingCoefficient))} m3` : ''}
                                </ChronicleItem>
                            </>
                        )
                    })}
                    <ChronicleItem item xs={11}>
                        <Divider />
                    </ChronicleItem>
                </>
            )
        }
        return null
    }

    getChronicles = (pointEventsFormatted) => {
        const { declaration, variousMateriels, exploitation } = this.props
        const { readMode, point } = this.state
        const { pumpsIds, countersIds } = this.getMateriels()
        const allIds = [...pumpsIds, ...countersIds]
        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === point.idInstallation) || {}
        const groupedByMat = Object.values(groupBy([...declaration.link_chronicles, ...pointEventsFormatted].filter((c) => allIds.includes(c.idMat) && (!linkPoint.startDate || c.measureDate >= linkPoint.startDate) && (!linkPoint.endDate || c.measureDate < linkPoint.endDate)), 'idMat'))
        return compact(groupedByMat.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === TYPE_INDEX || c.event)
            const estimateChronicles = chronicles.filter((c) => c.measureType === TYPE_ESTIM)
            const counterUpdated = declaration.link_counters.find((c) => c.id === variousMat.id) || {}
            const readingCoefficient = counterUpdated.readingCoefficient || variousMat?.counter?.readingCoefficient
            const ordered = orderBy(indexChronicles.filter((c) => !c.event), ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const evol = ordered.length > 1 ? (ordered[0].value - ordered[ordered.length - 1].value) * (readingCoefficient || 1) : 0
            return {
                evol,
                content: (
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justifyContent='center'
                        className={readMode ? '' : 'clickable'}
                    >
                        <ChronicleItem
                            item
                            xs={11}
                            className='bold'
                            style={{ textAlign: 'start' }}
                            onClick={readMode ? () => {} : () => this.editModalIndex(chronicles[0].measureType, variousMat.id)}
                        >
                            {variousMat.reference}{readingCoefficient ? ` - ${i18n.readingCoefficient} ${readingCoefficient}` : ''}{variousMat?.counter?.informative && ` (${i18n.informative})`}
                        </ChronicleItem>
                        {this.getChroniclesByType(indexChronicles, TYPE_INDEX, variousMat, readingCoefficient)}
                        {this.getChroniclesByType(estimateChronicles, TYPE_ESTIM, variousMat)}
                    </Grid>
                ),
            }
        }))
    }

    getAccordeon = (pointEventsFormatted, pumpsIds, countersIds) => {
        const { survey, surveyVolumesExploitation } = this.props
        const { readMode, showAllConso, idInstallation } = this.state
        const titleConsos = `${i18n.consos} ${survey.surveyType === 2 ? survey?.year : survey?.year - 1}`
        const chronicles = this.getChronicles(pointEventsFormatted)
        const evolTotal = surveyVolumesExploitation.find((v) => v.idInstallation === idInstallation)?.total
        return (
            <GridItem item xs={12}>
                <SectionList
                    title={(
                        <Grid container justifyContent='space-between'>
                            {titleConsos}
                            <Grid item>
                                {i18n.totalRealVolume} : {formatMilliers(evolTotal)} m3
                            </Grid>
                        </Grid>
                    )}
                    openFirst
                    color={secondaryBlue}
                >
                    <Grid
                        container
                        direction='row'
                        justifyContent='center'
                        style={{ padding: '8px' }}
                        spacing={1}
                    >
                        {chronicles.map(c => c.content)}
                        {![...pumpsIds, ...countersIds].length ? (
                            <p>Aucun équipement n'est affecté sur  ce point. Vous ne pouvez pas saisir de consommation.</p>
                        ) : (
                            <Grid item xs={2}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.toggleState('openModalIndex')}
                                    disabled={readMode}
                                    style={{ height: '100%', width: '100%' }}
                                    endIcon={<Icon>invert_colors</Icon>}
                                >
                                    {i18n.addConso}
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={2}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => this.toggleState('openModalEvent')}
                                disabled={readMode}
                                style={{ height: '100%', width: '100%' }}
                                endIcon={<Icon>warning</Icon>}
                            >
                                {i18n.addOneEvent}
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => this.setState({ showAllConso: !showAllConso })}
                                style={{ height: '100%', width: '100%' }}
                                endIcon={<Icon>history</Icon>}
                            >
                                {showAllConso ? i18n.showLessHistory : i18n.showMoreHistory}
                            </Button>
                        </Grid>
                    </Grid>
                </SectionList>
            </GridItem>
        )
    }

    getContent = () => {
        const { survey = {}, matEventsExploitation } = this.props
        const { real } = this.state
        const { pumpsIds, countersIds } = this.getMateriels()
        const eventsMat = matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel))
        const pointEvents = eventsMat.filter(({ eventDate }) => {
            const yearEvent = new Date(eventDate).getFullYear()
            return yearEvent === survey.year || yearEvent === survey.year - 1
        })
        const pointEventsFormatted = pointEvents.map((e) => {
            return {
                ...e,
                idMat: e.idMateriel,
                measureDate: e.eventDate,
                event: true,
            }
        })

        if (!real) {
            return (
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent='center'
                    >
                        {this.getListUsages(false, true)}
                        {this.getListUsages(false, false)}
                    </Grid>
                </Grid>
            )
        }
        return (
            <>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent='center'
                    >
                        {this.getListUsages(true, true)}
                        {this.getListUsages(true, false)}
                    </Grid>
                </Grid>
                {(getSetting(survey.link_params, 'consos') || '1') !== '0' && this.getAccordeon(pointEventsFormatted, pumpsIds, countersIds)}
            </>
        )
    }

    onCancelIndex = () => {
        this.setState({
            consoType: null,
            selectedMaterial: null,
            openModalIndex: false,
        })
    }

    onSaveIndex = (link_chronicles) => {
        const { declaration } = this.props
        const updateDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_chronicles,
        }
        this.setState({
            declarationLoaded: false,
            consoType: null,
            selectedMaterial: null,
            openModalIndex: false,
        })
        this.props.updateDeclaration(updateDeclaration).then(() => {
            this.setDeclaration()
        })
    }

    onCancelEvent = () => {
        this.setState({ openModalEvent: false, selectedEvent: null })
    }

    onValidateEvent = (event) => {
        const { exploitation } = this.props
        this.onCancelEvent()
        if (event.id) {
            this.props.updateMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            )
        } else {
            this.props.createMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            )
        }
    }

    onDeleteEvent = (event) => {
        const { exploitation } = this.props
        this.onCancelEvent()
        this.props.deleteMatEvent(event.id,
            () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
        )
    }

    onClickNoConso = () => {
        const { declaration, match } = this.props
        const { point } = this.state
        const { materiels } = this.getMateriels()
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        points.push({
            ...pointToRemove,
            link_usagesCurrent: [],
        })
        const date = moment().valueOf()
        const matsIds = materiels.map((m) => m.id)
        const chroniclesByMat = groupBy(declaration.link_chronicles.filter((c) => c.measureType === TYPE_INDEX && matsIds.includes(c.idMat)), 'idMat')
        const indexs = compact(keys(chroniclesByMat).map((matId) => {
            const lastIndex = orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]
            if (lastIndex && lastIndex.measureDate < date) {
                return { matId, value: orderBy(chroniclesByMat[matId], 'measureDate', 'desc')[0]?.value }
            }
            return undefined
        }))
        const newIndexs = indexs.map((i) => ({
            matType: 'divers',
            idMat: Number(i.matId),
            measureType: TYPE_INDEX,
            measureDate: date,
            value: i.value,
            new: true,
        }))
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
            link_chronicles: [
                ...declaration.link_chronicles,
                ...newIndexs,
            ],
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.props.push(`/survey/${match.params.id}/declaration/${match.params.idDeclaration}/point/${match.params.idPoint}`)
        })
    }

    getEventModal = () => {
        const { openModalEvent, selectedEvent } = this.state
        if (openModalEvent) {
            return (
                <MatEventModal
                    open={openModalEvent}
                    event={selectedEvent}
                    materiels={this.getMateriels().materiels}
                    onCancel={this.onCancelEvent}
                    onValidate={this.onValidateEvent}
                    onDelete={this.onDeleteEvent}
                />
            )
        }
        return null
    }

    render() {
        const {
            openModalUsage,
            openModalIndex,
            usageToUpdate,
            usages,
            usagesCurrentYear,
            point,
            dataLoaded,
            declarationLoaded,
            consoType,
            selectedMaterial,
            readMode,
            real,
        } = this.state
        const { survey } = this.props
        const title = !real ? i18n.nbPtPrevi : i18n.nbPtReal
        if (declarationLoaded) {
            const { pumpsIds, countersIds, materiels } = this.getMateriels()
            const exploitationMatIds = materiels.map(({ id }) => id)
            const showButtonAddUse = (real && (getSetting(survey.link_params, 'usagesReal') || '1') !== '0') || (!real && (getSetting(survey.link_params, 'usagesPrevi') || '1') !== '0')
            return (
                <>
                    <Grid
                        container
                        alignItems='center'
                        justifyContent='center'
                        style={{
                            padding: '0 10px',
                            backgroundColor: 'white',
                            height: 60,
                            borderBottom: '1px solid grey',
                        }}
                    >
                        <Grid item xs={10}>
                            <h5 className='italic-label'>{title}</h5>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent='space-between'
                        alignItems='stretch'
                        style={{
                            height: 'calc(100% - 184px)',
                            overflow: 'none',
                        }}
                    >
                        <Grid
                            item
                            container
                            direction='column'
                            justifyContent='flex-start'
                            alignItems='stretch'
                            style={{
                                backgroundColor: 'white',
                            }}
                        >
                            <Grid container alignItems='stretch' justifyContent='flex-start' style={{ paddingBottom: '60px' }}>
                                {dataLoaded && declarationLoaded ? (
                                    this.getContent()
                                ) :
                                    <GridItem item xs={12}>
                                        <ProgressBar indeterminate />
                                    </GridItem>
                                }
                            </Grid>
                            <Grid
                                item
                                container
                                direction='row'
                                justifyContent='space-around'
                                alignItems='center'
                                style={{
                                    position: 'fixed',
                                    bottom: 0,
                                    height: 60,
                                    backgroundColor: 'white',
                                    borderTop: '1px solid grey',
                                    width: 'calc(100% - 60px)',
                                }}
                            >
                                {showButtonAddUse && (
                                    <>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                onClick={() => this.setState({ openModalUsage: !openModalUsage, usageToUpdate: false })}
                                                disabled={readMode}
                                            >
                                                {!real ? i18n.addPrevUse : i18n.addUse}
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                                {real && (
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            onClick={this.onClickNoConso}
                                            disabled={readMode}
                                        >
                                            {i18n.noSampling}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        onClick={this.onValideUsage}
                                    >
                                        {!real ? i18n.validPrevUses : i18n.validUses}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {openModalUsage && (
                        <ModalUsage
                            open={openModalUsage}
                            point={point}
                            usage={usageToUpdate}
                            usagesDeclaration={real ? usagesCurrentYear : usages}
                            onSave={this.onSaveUsage}
                            onDelete={this.onSaveUsage}
                            onCancel={this.onCancelUsage}
                            currentYear={real}
                            countersId={countersIds}
                        />
                    )}
                    {openModalIndex && (
                        <ModalIndex
                            open={openModalIndex}
                            point={point}
                            pumpsId={uniq(pumpsIds.filter((id) => exploitationMatIds.includes(id)))}
                            countersId={uniq(countersIds.filter((id) => exploitationMatIds.includes(id)))}
                            selectedMaterial={selectedMaterial}
                            consoType={consoType}
                            onSave={this.onSaveIndex}
                            onCancel={this.onCancelIndex}
                        />
                    )}
                    {this.getEventModal()}
                </>
            )
        }
        return <ProgressBar indeterminate />
    }
}

DeclarationPointUsages.propTypes = {
    match: PropTypes.instanceOf(PropTypes.object),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchModesIrrigations: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateDeclaration: PropTypes.func,
    fetchDeclaration: PropTypes.func,
    fetchUsages: PropTypes.func,
    fetchExploitationUser: PropTypes.func,
    readMode: PropTypes.bool,
    fetchMatEventsByExploitation: PropTypes.bool,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    survey: PropTypes.arrayOf(PropTypes.object),
    updateMatEvent: PropTypes.func,
    createMatEvent: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    calculSurveyExploitationVolumes: PropTypes.func,
    surveyVolumesExploitation: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (store) => {
    return {
        cultures: store.CultureReducer.cultures,
        usages: store.UsageReducer.usages,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        installation: store.InstallationReducer.installation,
        declaration: store.AgriReducer.declaration,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        exploitation: store.AgriReducer.exploitation,
        survey: store.AgriReducer.survey,
        matEventsExploitation: store.VariousMaterielReducer.matEventsExploitation,
        matEventsTypes: store.VariousMaterielReducer.matEventsTypes,
        surveyVolumesExploitation: store.AgriReducer.surveyVolumesExploitation,
    }
}

const mapDispatchToProps = {
    fetchCultures: CultureAction.fetchCultures,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    fetchUsages: UsageAction.fetchUsages,
    fetchExploitationUser: AgriAction.fetchExploitationUser,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchDeclaration: AgriAction.fetchDeclaration,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchInstallation: InstallationAction.fetchInstallation,
    setTitle: HomeAction.setTitle,
    fetchMatEventsByExploitation: VariousMaterielAction.fetchMatEventsByExploitation,
    updateMatEvent: VariousMaterielAction.updateMatEvent,
    createMatEvent: VariousMaterielAction.createMatEvent,
    deleteMatEvent: VariousMaterielAction.deleteMatEvent,
    calculSurveyExploitationVolumes: AgriAction.calculSurveyExploitationVolumes,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationPointUsages)
