import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import Row from '../../../components/react/Row'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import DtoDeclaration from '../../../survey/dto/DtoDeclaration'
import { round } from '../../../utils/NumberUtil'
import { formatMilliers } from '../../../utils/StringUtil'

const DeclarationStatsCard = ({
    declaration,
    title,
    className,
    actions = [],
    sandreCodes = [],
    key,
}) => {
    const status = sandreCodes.find((c) => c.field === 'DECLARATIONS.STATUT' && c.code === declaration.statusCode) || {}
    const volumes = declaration.link_volumes.length ? declaration.link_volumes : []
    const allocatedVolumeFiltered = volumes.filter((v) => hasValue(v.attributedVolume)).map((v) => v.attributedVolume)
    const allocatedVolume = allocatedVolumeFiltered.length ? Math.round(allocatedVolumeFiltered.reduce((a, b) => a + b)) : null
    const authorizedVolumeFiltered = volumes.filter((v) => hasValue(v.authorizedVolume)).map((v) => v.authorizedVolume)
    const authorizedVolume = authorizedVolumeFiltered.length ? Math.round(authorizedVolumeFiltered.reduce((a, b) => a + b)) : null
    const advancement = (declaration.lastStep * 100) / 4
    return (
        <Card key={key} title={title || i18n.lastDeclaration} className={className} noMargin={false} actions={actions} round>
            <Row className='padding-top-1'>
                <Input
                    col={12}
                    title={i18n.survey}
                    value={declaration.surveyName}
                    readMode
                />
                <Input
                    col={6}
                    title={i18n.status}
                    value={status.name}
                    readMode
                />
                <Input
                    col={6}
                    title={i18n.progression}
                    value={`${declaration.lastStep && (advancement > 100 ? 100 : advancement) || 0}%`}
                    readMode
                />
                <Input
                    col={12}
                    title={i18n.nbPtsDeclared}
                    value={declaration.nbPts}
                    readMode
                />
                <Input
                    col={12}
                    title={i18n.realConsumption}
                    value={`${formatMilliers(declaration.realVolume) || 0} m3`}
                    readMode
                />
                <Input
                    col={12}
                    title={i18n.requestedVolume}
                    value={`${formatMilliers(declaration.prevVolume) || 0} m3`}
                    readMode
                />
                <Input
                    col={12}
                    title={i18n.detentionsEligibles}
                    value={hasValue(declaration.eligibleTankVolume) ? `${formatMilliers(round(declaration.eligibleTankVolume, 0))} m3` : i18n.unknown}
                    readMode
                />
                <Input
                    col={12}
                    title={i18n.allocatedVolume}
                    value={hasValue(allocatedVolume) ? `${formatMilliers(allocatedVolume)} m3` : i18n.unknown}
                    readMode
                />
                <Input
                    col={12}
                    title={i18n.authorizedVolume}
                    value={hasValue(authorizedVolume) ? `${formatMilliers(authorizedVolume)} m3` : i18n.unknown}
                    readMode
                />
            </Row>
        </Card>
    )
}

DeclarationStatsCard.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    key: PropTypes.number,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = (store) => {
    return {
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

export default connect(mapStateToProps)(DeclarationStatsCard)
