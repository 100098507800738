import 'echarts/lib/chart/bar'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/visualMap'
import { groupBy, indexOf, isNil, isUndefined, last, max, minBy, orderBy, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import Axis from '../../../../components/echart/Axis'
import ChartTabsSuiviGlobal from '../../../../components/echart/ChartTabsSuiviGlobal'
import EChart from '../../../../components/echart/EChart'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisLabelInterval,
    logIcon,
    setYOptions, toEchartsData,
    yAutomaticScaleTab,
    yAutomaticScaleValues,
} from '../../../../components/echart/EChartUtils'
import Band from '../../../../components/echart/series/Band'
import Line from '../../../../components/echart/series/Line'
import ProgressBar from '../../../../components/progress/ProgressBar'
import Row from '../../../../components/react/Row'
import HomeAction from '../../../../home/actions/HomeAction'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import { HYPE_TRENDS_CONSTANTS } from '../../../../quality/constants/QualityConstants'
import AppStore from '../../../../store/AppStore'
import { getDate, getDateWithHour, getFullDate } from '../../../../utils/DateUtil'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { getEventGraph, getEventsBar, piezoMeasureIsValid } from '../../../../utils/PiezometryUtils'
import { arrayOf, removeNullKeys } from '../../../../utils/StoreUtils'
import HydrometryAction from '../../../actions/HydrometryAction'
import DtoHydroMeasures from '../../../dto/chronicMeasures/DtoHydroMeasures'
import DtoHydroStats from '../../../dto/chronicMeasures/DtoHydroStats'
import DtoHydrometricBruteMeasure from '../../../dto/DtoHydrometricBruteMeasure'
import DtoHydrometricStation from '../../../dto/DtoHydrometricStation'
import DtoHydrometryThreshold from '../../../dto/DtoHydrometryThreshold'
import { getHydroFrequencyStats, getHydroSuiviStats } from '../utils/SuiviStatsUtilsHydro'
import HydroSuiviStatTab from './chartTabs/HydroSuiviStatTab'
import {
    getColorCircleElement,
    getColorCircleEvent,
    getColorFromPalette, getEventColor, getRGBColor,
    getThresholdColor,
} from '../../../../utils/ColorUtil'
import HydroSuiviPastYearsTab from './chartTabs/HydroSuiviPastYearsTab'
import MessageCard from '../../../../components/card/MessageCard'
import { setActions } from '../../../../components/ActionUtil'
import DtoEvent from '../../../../events/dto/DtoEvent'
import { Dialog, Grid } from '@mui/material'
import { DialogContentMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import Icon from '../../../../components/icon/Icon'
import { chunkWithWords } from '../../../../utils/StringUtil'
import ProgressCard from '../../../../components/card/ProgressCard'
import EventsToolPanelHydro from '../../validation2/tools/EventsToolPanelHydro'
import SimpleTabSideList from '../../../../components/navbar/SimpleTabSideList'
import OptionsToolPanelHydro from '../../validation2/tools/OptionsToolPanelHydro'
import ThresholdsTab from '../../../../alerting/components/dashboard/tabPopin/ThresholdsTab'
import Checkbox from '../../../../components/forms/Checkbox'
import HydroModelsTab from './chartTabs/models/HydroModelsTab'
import DtoHydroMeasureLight from '../../../dto/chronicMeasures/DtoHydroMeasureLight'
import { getMeasureJplus } from '../../../../iaeau/utils/IAEauUtils'
import { getLinks, getStationTitle } from 'utils/StationUtils'
import { getStationArrowNav, promiseAllProgress } from 'utils/ActionUtils'
import { push } from 'connected-react-router'
import GroupedHydroPanel from '../../stationDashboard/GroupedHydroPanel'
import HydrometerStationAction from '../../../../station/actions/HydrometerStationAction'
import DtoHydrometerChartOptions from '../../../../station/dto/hydrometricStation/DtoHydrometerChartOptions'
import { MEASURE_COTE } from '../../../../piezometry/constants/PiezometryConstants'
import AssociatedStationsToolPanelHydro from './chartTabs/AssociatedStationsToolPanelHydro'

const CIVIL_YEAR = 'CIVIL_YEAR'
const HYDRO_YEAR = 'HYDRO_YEAR'

const STATISTICS = 'statistics'
const PREVIOUSYEARS = 'previousYears'
const CHARTOPTIONS = 'chartOptions'
const THRESHOLDS = 'thresholds'
const LINKEDSTATIONS = 'linkedStations'
const EVENTS = 'events'
const MODELS = 'models'

class HydrometerSuiviChart extends Component {
    constructor(props) {
        super(props)
        const validMeasures = props.hydrometryBruteMeasures.filter(m => piezoMeasureIsValid(m))
        this.state = {
            dataLoaded: false,
            typeData: '',
            dataType: 4,
            groupFunc: 'AUTO',
            progress: 0,
            fullScreen: true,
            minDate: null,
            maxDate: null,
            statsSeries: {
                enveloppe: true, // enveloppe mini-maxi,
                average: true, // Moyenne
                mediane: false, // Médiane,
                trieS: true,
                trieH: true,
            },

            statsMeasures: {
                enveloppe: [],
                average: [],
                mediane: [],
                trieS: [],
                trieH: [],
            },

            enveloppe: [],
            enveloppeMin: [],
            enveloppeMax: [],
            average: [],

            mesures: [], // toutes les mesures
            series: {
                [HYPE_TRENDS_CONSTANTS.TREND_RUPTURE]: true,
                [HYPE_TRENDS_CONSTANTS.MANN_KENDALL]: true,
                [HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE]: true,
                [HYPE_TRENDS_CONSTANTS.LINEAR_REGRESSION]: true,
                displayHypeTrends: false,
                displayMeasures: true,
                displayMinMax: true,
                displayAverage: true,
                omenTrends: false,
                dryingTrends: false,
            },
            modelSeries: [],

            tab: CIVIL_YEAR,
            validMeasures,
            hasValidMeasures: !!validMeasures.length,
            statsWithValidDataOnly: !!validMeasures.length,
            thresholdsSelected: [],
            isThresholShow: true,
            displayLog: false,
            openDialog: false,
            messageModal: '',
            showSplitLineY: true,
            yAxisMin: null,
            yAxisMax: null,
            intervalChange: 5,
            frequencyStats: [],


            associatedSeries: [],
            associatedAxis: [],
            hydrometerChartOptions: [],
            startDateChartOptions: undefined,
            endDateChartOptions: undefined,
            linearMode: false,
            validOnly: false,
        }
    }

    componentDidMount() {
        this.onChangeTab('')
        this.props.fetchHydroStatistics(this.props.hydrometricStation.id).then(() => {
            this.setState({ dataType: this.props.hydroStatistics[0]?.typeId }, () =>
                this.props.fetchHydrometerChartOptions(this.props.hydrometricStation.id, () => this.setState({
                    hydrometerChartOptions: this.props.hydrometerChartOptions,
                    startDateChartOptions: this.props.hydrometerChartOptions.find(opt => parseInt(opt.dataType || -1) === this.state.dataType)?.statsCalculationStartDate,
                    endDateChartOptions: this.props.hydrometerChartOptions.find(opt => parseInt(opt.dataType || -1) === this.state.dataType)?.statsCalculationEndDate,
                }))
                    .then(this.dataLoading)
            )
        })

        this.props.fetchHydrometricThresholds().then(() => {
            this.setState({ thresholdsSelected: this.props.hydrometryThresholds.filter(t => t.stationId === this.props.hydrometricStation.id) })
        })
        AppStore.dispatch(HomeAction.setTitle([{
            title: i18n.hydrometry,
            href: 'hydrometry',
        }, {
            title: getStationTitle(this.props.hydrometricStation),
            href: `station/hydrometry/${this.props.hydrometricStation.id}`,
        }, {
            title: i18n.hydrometricFollowUp,
            href: `station/hydrometry/${this.props.hydrometricStation.id}/hydrometryFollowUp`,
        }]))
    }

    fetchThresholds = () => {
        this.props.fetchHydrometricThresholds()
    }

    dataLoading = (validOnly = false) => {
        const { dataType, startDateChartOptions, endDateChartOptions, groupFunc, minDate, maxDate } = this.state
        if (dataType===4) {
            this.setState({ typeData: i18n.height })
        } else {
            this.setState({ typeData: this.props.hydroStatistics.find(l => l.typeId === dataType)?.label })
        }

        if (this.state.dataType) {
            const promises = [
                'ENVELOPPE_MIN',
                'ENVELOPPE_MAX',
                'ENVELOPPE_AVERAGE',
            ].map(stat => HydrometryAction.promiseHydroChronicMeasures({
                stationId: this.props.hydrometricStation.id,
                dataType,
                groupFunc: stat,
                chartMode: true,
                validOnly,
                calculationStartDate: startDateChartOptions,
                calculationEndDate: endDateChartOptions,
                startEnveloppe: startDateChartOptions,
                endEnveloppe: endDateChartOptions,
            }))

            // Renvoie un tableau de tableau, une measure correspond à :
            // [date,valeur,?,?,?,idStation]
            const promisesMeasure = HydrometryAction.promiseHydroChronicMeasures({
                stationId: this.props.hydrometricStation.id,
                dataType,
                chartMode: true,
                groupFunc,
            })

            promiseAllProgress([...promises, promisesMeasure], (progressVal) => this.setState({ progress: progressVal })).then(result => {
                this.setState({
                    enveloppeMin: result[0],
                    enveloppeMax: result[1],
                    average: result[2],
                    mesures: result[3],
                }, () => this.loadData())
            })
        } else {
            this.loadData()
        }
    }

    loadData = () => {
        this.setState({
            dataLoaded: true,
        })
    }

    getGroupedMeasures = (measures, chartMinDate, chartMaxDate, dateKey, hourKey, groupFunc = max) => {
        const duration = moment.duration(moment(chartMaxDate).diff(chartMinDate))
        if (duration.months() > 3 || duration.years() >= 1) {
            const measuresObj = measures.reduce((acc, measure) => {
                const date = getDate(measure[dateKey])
                if (acc[date]) {
                    acc[date] = Object.assign({}, acc[date], {
                        value: groupFunc([measure.value, acc[date].value]),
                        initialPoint: measure.initialPoint === 1 || acc[date].initialPoint === 1 ? 1 : 0,
                    })
                } else {
                    const measureObj = Object.assign({}, measure)
                    measureObj[hourKey] = null
                    acc[date] = measureObj
                }
                return acc
            }, {})
            return Object.keys(measuresObj).map(date => measuresObj[date])
        } else if (duration.days() >= 28) {
            const measuresObj = measures.reduce((acc, measure) => {
                const dateFormat = getDateWithHour(measure[dateKey], measure[hourKey])
                const date = dateFormat.format('DD/MM/YYYY:HH')
                if (acc[date]) {
                    acc[date] = Object.assign({}, acc[date], {
                        value: groupFunc([measure.value, acc[date].value]),
                        initialPoint: measure.initialPoint === 1 || acc[date].initialPoint === 1 ? 1 : 0,
                    })
                } else {
                    const measureObj = Object.assign({}, measure)
                    measureObj[hourKey] = dateFormat.minutes(0).seconds(0).valueOf()
                    acc[date] = measureObj
                }
                return acc
            }, {})
            return Object.keys(measuresObj).map(date => measuresObj[date])
        }
        return measures
    }

    getSerieData = (measures, chartMinDate, chartMaxDate, dateKey = 'date', hourKey = 'hour', groupFunc) => {
        const results = this.getGroupedMeasures(measures, chartMinDate, chartMaxDate, dateKey, hourKey, groupFunc).reduce((acc, obj) => {
            const date = obj[dateKey]
            if (obj.initialPoint === 1) {
                acc.push({ value: [moment(date).subtract(1, 'minute').valueOf(), null] })
            }
            acc.push({ value: [date, obj.value] })
            return acc
        }, [])
        return orderBy(results, m => m.value[0])
    }

    addEnvelope = (series, grids) => {
        const { statsSeries, displayLog, enveloppeMin, enveloppeMax } = this.state
        if (statsSeries.enveloppe) {
            if (this.state.linearMode) {
                const inputMin = {
                    data: displayLog ? enveloppeMin.map(([date, value]) => [date, value - 1000]) : enveloppeMin,
                    name: `${i18n.enveloppeMin}`,
                    connectNulls: false,
                    showSymbol: false,
                    xAxisIndex: grids.length - 1,
                    yAxisIndex: grids.length,
                    color: 'grey',
                    isPiezo: !displayLog,
                    lineStyle: {
                        normal: {
                            width: 1,
                        },
                    },
                }
                const inputMax = {
                    data: displayLog ? enveloppeMax.map(([date, value]) => [date, value - 1000]) : enveloppeMax,
                    name: `${i18n.enveloppeMax}`,
                    connectNulls: false,
                    showSymbol: false,
                    xAxisIndex: grids.length - 1,
                    yAxisIndex: grids.length,
                    color: 'grey',
                    isPiezo: !displayLog,
                    lineStyle: {
                        normal: {
                            width: 1,
                        },
                    },
                }
                series.push(Line(inputMin))
                series.push(Line(inputMax))
            } else {
                const bandEnveloppe = Band({
                    name: `${i18n.enveloppeMinMax}`,
                    color: 'grey',
                    showSymbol: false,
                    xAxisIndex: grids.length - 1,
                    yAxisIndex: grids.length,
                    isPiezo: !this.state.displayLog,
                    min: {
                        name: `${i18n.enveloppeMin}`,
                        values: displayLog ? enveloppeMin.map(([date, value]) => [date, value - 1000]) : enveloppeMin,
                    },
                    max: {
                        name: `${i18n.enveloppeMax}`,
                        values: displayLog ? enveloppeMax.map(([date, value]) => [date, value - 1000]) : enveloppeMax,
                    },
                })
                series.push(bandEnveloppe)
            }
        }
    }

    addAverage = (series, grids) => {
        if (this.state.statsSeries.average) {
            const lineAverage =
                Line({
                    data: this.state.average,
                    name: `${i18n.enveloppeAvg}`,
                    connectNulls: false,
                    showSymbol: false,
                    xAxisIndex: grids.length - 1,
                    yAxisIndex: grids.length,
                    color: 'green',
                    isPiezo: !this.state.displayLog,
                    lineStyle: {
                        normal: {
                            color: 'green',
                            type: 'dashed',
                            width: 1,
                        },
                    },
                })
            series.push(lineAverage)
        }
    }

    getFrenquencySeries = (series) => {
        getHydroFrequencyStats().flatMap(fre => {
            if (this.state.statsSeries[fre.code] && this.state.statsMeasures[fre.code] && this.state.statsMeasures[fre.code].length) {
                series.push(Line({
                    data: this.state.statsMeasures[fre.code],
                    name: fre.name,
                    connectNulls: false,
                    showSymbol: false,
                    xAxisIndex: 1,
                    yAxisIndex: 2,
                    color: fre.color,
                    isPiezo: !this.state.displayLog,
                    lineStyle: {
                        normal: {
                            color: fre.color,
                            width: 1,
                            type: 'dashed',
                        },
                    },
                }))
            }
        })
    }

    getMinDate = () => {
        if (this.state.minDate) {
            return this.state.minDate
        }
        const minAllData = minBy(this.state.mesures, (m) => m[0])
        return minAllData ? minAllData[0] : moment().subtract(1, 'days').valueOf()
    }

    getTooltip() {
        return {
            trigger: 'axis',
            formatter: params => {
                const date = getDate(moment(params[0].value[0]))
                const isPeriod = params.some(p => p.data.isPeriod)
                const paramsOrder = uniqBy(params.filter(p=> hasValue(p.value[1]) && p.seriesName!== i18n.events).map((param, idx) => {
                    const unit = !isNil(param.data.unit) ? param.data.unit : ''
                    return {
                        unit,
                        marker: param.marker,
                        seriesName: param.seriesName,
                        value: (() => {
                            if (isPeriod && this.state.displayCote === MEASURE_COTE.DEPTH && hasValue(param.data.depthHackValue)) {
                                return param.data.depthHackValue
                            }
                            if (isPeriod && this.state.displayCote === MEASURE_COTE.NGF && param.seriesName.startsWith('>')) {
                                return paramsOrder[idx - 1].data.realValue
                            }
                            return (param.data.realValue || param.value[1]) + (param.data.isPiezo && !hasValue(param.data.realValue) ? -1000 : 0)
                        })(),
                    }
                }).reverse(), 'value')
                const stationEventsFiltered = this.props.stationEvents.filter(o => moment(o.date).isSame(params[0].value[0], 'day') && o.graph == '1' && o.eventType !== 'T')
                const labelEvents = stationEventsFiltered.length ? stationEventsFiltered.reduce((acc, v) => {
                    return `${acc} ${
                        getColorCircleElement(getRGBColor(getEventColor(v.eventType)))}${v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event}<br />`
                }, '<br />') : ''
                const result = paramsOrder.map(o => {
                    return `${o.marker} ${o.seriesName} : ${o.value} ${o.unit} ${getMeasureJplus(o.value)}`
                }).join('<br/>')
                return `${date} ${labelEvents}<br />${result}<br />`
            },

        }
    }

    exportSerie = (serie, chartMinDate, chartMaxDate) => serie.data.filter(d => d[0] >= chartMinDate && d[0] <= chartMaxDate).map(d => ({
        stationCode: { value: this.props.hydrometricStation.code },
        stationName: { value: this.props.hydrometricStation.name },
        date: { value: getFullDate(d[0]), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
        value: { value: d[1], format: '0.00', cellType: 'number' },
        type: { value: serie.name },
    }))

    exportSerieBand = (serie, chartMinDate, chartMaxDate) => serie.values.filter(d => d[0] >= chartMinDate && d[0] <= chartMaxDate).map(d => ({
        stationCode: { value: this.props.hydrometricStation.code },
        stationName: { value: this.props.hydrometricStation.name },
        date: { value: getFullDate(d[0]), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
        value: { value: d[1], format: '0.00', cellType: 'number' },
        type: { value: serie.name },
    }))

    getExportData = (series, chartMinDate, chartMaxDate) => {
        const seriesToExport = series.filter((_, i) => i >= 2)
        const data = seriesToExport.flatMap(chart => {
            if (chart.obj?.min?.name) {
                return [ ...this.exportSerieBand(chart.obj.min, chartMinDate, chartMaxDate), ...this.exportSerieBand(chart.obj.max, chartMinDate, chartMaxDate)]
            }
            return this.exportSerie(chart.obj, chartMinDate, chartMaxDate)
        })
        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }
        return data
    }

    addChart = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, rawMeasures) => {
        const formatThresholds = this.props.hydrometryThresholds.filter(t => t.stationId == this.props.hydrometricStation.id && t.dataType == this.state.dataType)
        const thresholds = formatThresholds.map(t => ({
            yAxis: this.state.displayLog ? t.value : t.value +1000,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${t.value}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor ?? getThresholdColor(t.color),
                    type: 'dashed',
                },
            },
        }))

        const thresholdsValues = formatThresholds.map(t => [null, t.value])

        grids.push({
            top: 100,
            height: 430,
            left: '6%',
        })
        series.push(
            Line({
                data: toEchartsData(rawMeasures.map(m => new DtoHydroMeasureLight(m))),
                name: this.state.typeData,
                xAxisIndex: grids.length - 1,
                yAxisIndex: grids.length,
                connectNulls: false,
                showSymbol: true,
                color: '#2799fa',
                isPiezo: !this.state.displayLog,
                serieId: this.state.typeData,
                markLine: { silent: false, data: this.state.isThresholShow ? thresholds : [] },
            }))

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            gridIndex: grids.length - 1,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            showSplitLine: true,
        }))

        const yScale = yAutomaticScaleTab(rawMeasures, thresholdsValues, this.state.enveloppeMax)

        this.yScale = yScale

        const dataType = this.props.hydroStatistics.find(v => v.typeId === this.state.dataType)
        const unit = dataType?.unit ? `[${dataType.unit}]` : ''

        yAxis.push(Axis({
            type: this.state.displayLog ? 'log' : 'value',
            nameLocation: 'middle',
            name: `${this.state.typeData} ${unit}`,
            gridIndex: grids.length - 1,
            nameGap: 40,
            isPiezo: !this.state.displayLog,
            showSplitLine: this.state.showSplitLineY ?? true,
            ...setYOptions({ min: this.state.displayLog ? undefined : this.state.yAxisMin, max: this.state.displayLog ? undefined : this.state.yAxisMax }, this.state.displayLog ? {} : yScale),
        }
        ))
        gridsHeights.push(235)
    }

    addModelSeries = (series) => {
        this.state.modelSeries.forEach(s => series.push(s))
    }

    addAssociatedStations = (series, yAxis, grids) => {
        const baseLen = yAxis.length
        const newAxis = []
        this.state.associatedAxis.forEach((a, idx) => {
            const yScale = yAutomaticScaleValues([
                ...this.state.associatedSeries.filter(s => s.obj.axisName === a.name).flatMap(s => s.obj.data).flat().map(v => v.value[1]),
                ...this.state.associatedSeries.filter(s => s.obj.axisName === a.name).flatMap(s => s.obj.markLine?.data?.map(d => d.yAxis) || []),
            ])
            if (!(a.name in newAxis)) {
                yAxis.push(Axis({
                    type: 'value',
                    nameLocation: 'middle',
                    nameGap: 40,
                    position: 'right',
                    inverse: a.isPluvio,
                    offset: idx*60,
                    ...yScale,
                    showSplitLine: true,
                    ...(removeNullKeys(a)),
                    name: `${a.name} ${a?.unit ? `[${a?.unit}]` : ''}`,
                    gridIndex: grids.length - 1,
                    unit: a.unit,
                }))
                newAxis.push(a.name)
            }
        })

        this.state.associatedSeries.forEach((serie) => {
            serie.updateObj({ yAxisIndex: indexOf(newAxis, serie.obj.axisName)+baseLen, xAxisIndex: 1 })
            series.push(serie)
        })
    }

    addEvents = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, events) => {
        const eventOptions = {
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    const startDate = getFullDate(params.value[2].date)
                    const eventsList = events.filter(e => {
                        const startDate = getDateWithHour(e.date, e.eventHour).valueOf()
                        const endDate = e.endDate && e.endDate - startDate > 20000000 ? e.endDate : startDate + 20000000
                        return startDate >= params.value[0] && endDate <= params.value[1] && e.graph == '1'
                    })
                    const labelEvents = eventsList.reduce((acc, v) => {
                        const comment = v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event
                        if (params.value[2].endDate) {
                            const endDate = getFullDate(params.value[2].endDate)
                            return `${acc}<br />${getColorCircleEvent(v.eventType)} ${startDate}-${endDate} : ${comment}`
                        }
                        return `${acc}<br />${getColorCircleEvent(v.eventType)} ${startDate} : ${comment}`
                    }, i18n.events)
                    return labelEvents
                },
            },
            itemStyle: {
                normal: {
                    opacity: 0.5,
                },
            },
            yAxisIndex: 1,
        }
        grids.push({
            top: 55,
            height: 40,
            left: '6%',
            name: 'eventGrid',
        })
        series.push(getEventGraph(events, eventOptions))
        series.push(getEventsBar(events, {
            yAxisIndex: grids.length - 1,
            xAxisIndex: grids.length - 1,
        }))
        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }))
        yAxis.push(Axis({
            type: 'value',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'right',
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }))
        yAxis.push(Axis({
            type: 'category',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'left',
        }))
        gridsHeights.push(60)
    }

    dataToLogModal = () => {
        if (!this.state.displayLog) {
            this.setState({ displayLog: !this.state.displayLog, openDialog: true, messageModal: i18n.logarithmicScaleActivated })
        } else if (this.state.displayLog) {
            this.setState({ displayLog: !this.state.displayLog, openDialog: true, messageModal: i18n.logarithmicScaleOff })
        }
    }

    getChart = (chartMinDate, chartMaxDate) => {
        const measuresFiltered = this.state.mesures
        // construct echart options
        const [series, grids, xAxis, yAxis, gridsHeights] = [[], [], [], [], [60]]
        const axisLabelObj = getAxisLabelInterval(this.state.maxDate ? moment(this.state.maxDate) : moment(), moment(this.state.minDate || chartMinDate))

        const eventsValue = this.props.stationEvents.filter(e => {
            if (e.eventType === 'T' || isUndefined(e.date) || e.graph !== '1') {
                return false
            }
            const eventDate = getDateWithHour(e.date, e.eventHour).valueOf()
            return eventDate >= chartMinDate && eventDate <= chartMaxDate
        })

        this.addEvents(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, eventsValue)
        this.addChart(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, measuresFiltered)
        this.addEnvelope(series, grids)
        this.addAverage(series, grids)
        this.getPreviousYears(series, grids)
        this.addAssociatedStations(series, yAxis, grids)
        this.addModelSeries(series)

        this.getFrenquencySeries(series)

        xAxis[xAxis.length - 1].obj = Object.assign({}, xAxis[xAxis.length - 1].obj, {
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
        })

        const legendFormatSeries = series.map(s => {
            if (s.obj.name === i18n.enveloppeMinMax) {
                return Band({ ...s, bands: [
                    s.obj.min,
                    s.obj.max,
                ] })
            } return s
        }
        ).filter(s => s.obj?.name !== i18n.events)

        grids.map((g) => (g.right = `${2.8*yAxis.length}%`))

        const options = {
            title: `[${this.props.hydrometricStation.code}] - ${this.props.hydrometricStation.name}`,
            series,
            tooltip: this.getTooltip(),
            grid: grids,
            xAxis,
            yAxis,
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            height: 550,
            setDataZoom: true,
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(series, chartMinDate, chartMaxDate),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                    },
                    myLog: {
                        show: true,
                        title: i18n.logarithm,
                        icon: logIcon,
                        onclick: () => this.dataToLogModal(),
                    },
                },
                right: 65,
            },
            legend: {
                bottom: 45,
                show: true,
                data: legendFormatSeries.flatMap(s => s.obj.bands ? s.obj.bands : [s.obj]).filter(s => !s.hidden)
                    .map(s => (
                        {
                            name: s.name,
                            icon: ['fre', 'Enveloppe'].some(l => (s?.name || '').includes(l)) ? 'roundRect' : 'circle',
                            itemStyle: { color: s.color },
                        })),
            },
        }
        return (
            <EChart options={options} id='hydroChart' bandCorrection scrollable={this.state.fullScreen}/>
        )
    }

    loadFrequencyStats = (dataType, validOnly = false) => {
        if (dataType) {
            this.dataLoading(validOnly)

            this.state.frequencyStats.map(m => {
                this.props.fetchHydroChronicMeasures({
                    stationId: this.props.hydrometricStation.id,
                    dataType,
                    groupFunc: m.toUpperCase().replace('FRE', 'PERIOD'),
                    chartMode: true,
                    validOnly,
                    calculationStartDate: this.state.startDateChartOptions,
                    calculationEndDate: this.state.endDateChartOptions,
                    startEnveloppe: this.state.startDateChartOptions,
                    endEnveloppe: this.state.endDateChartOptions,
                }).then(result => {
                    this.setState(prevState => ({
                        statsMeasures: { ...prevState.statsMeasures, [m]: result },
                    }))
                })
            })
        }
    }

    getDataMeasures = () => {
        const result = this.state.mesures.map(mesure => ({
            date: mesure[0],
            valeur: mesure[1],
        }))
        return result
    }


    getPreviousYears = (series, grids) => {
        if ((this.state.tab || '').includes('YEAR')) {
            const years = Object.keys(this.state.series).filter(key => key.startsWith('N-') && this.state.series[key]) // Good
            if (years.length > 0) {
                const getWithGroup = (group) => {
                    return years.map((y, i) => {
                        const numYear = parseInt(y.split('N-')[1])
                        const band = Line({
                            data: (group[y] || []).map(m => ([moment(m.date).add(numYear, 'years').valueOf(), m.valeur])),
                            name: `${i18n.year} ${moment().year() - numYear}`,
                            connectNulls: false,
                            showSymbol: false,
                            isPiezo: !this.state.displayLog,
                            xAxisIndex: grids.length -1,
                            yAxisIndex: grids.length,
                            color: getColorFromPalette(i),
                            serieId: 'height',
                        })
                        series.push(band)
                    })
                }
                if (this.state.tab === CIVIL_YEAR) {
                    const endYear = moment().endOf('year')
                    const groupedData = groupBy(this.getDataMeasures().filter(m => hasValue(m.date)), m => `N-${moment.duration(endYear.diff(moment(m.date))).years()}`)
                    getWithGroup(groupedData)
                }
                if (this.state.tab === HYDRO_YEAR) {
                    const nextHydroYear = moment().month() >= 9 ? moment().month(9).startOf('month') : moment().add(1, 'year').month(9).startOf('month')
                    const groupedData = groupBy(this.getDataMeasures().filter(m => hasValue(m.date)), m => {
                        const mDuration = moment.duration(nextHydroYear.diff(moment(m.date)))
                        return `N-${mDuration.years() - 1}`
                    })
                    getWithGroup(groupedData)
                }
            }
        }
        return null
    }

    calculateStats = () => {
        const filteredValidMeasures = this.state.statsWithValidDataOnly ? this.state.validMeasures : this.props.hydrometryBruteMeasures
        if (filteredValidMeasures.length > 0) {
            this.setState({
                statsMeasures: {
                    ...this.state.statsMeasures,
                    ...getHydroSuiviStats(
                        this.state.statsSeries,
                        filteredValidMeasures,
                        this.state.tab,
                        this.props.hydrometryBruteMeasures.length ? last(this.props.hydrometryBruteMeasures) : null,
                        this.state.minDate || moment().year(1950).valueOf(),
                        this.state.maxDate
                    ),
                },
            })
        } else {
            this.setState({
                statsMeasures: {
                    enveloppe: [],
                    average: [],
                    trieS: [],
                    trieH: [],
                },
            })
        }
    }

    onChangeTab = () => {
        const { hydrometricStation, typeEnvironmentModels, hydrometricStations } = this.props
        setActions({
            exportmodel: () => ({
                stationId: hydrometricStation.id.toString(),
                stationCode: hydrometricStation.code,
                stationType: 'hydrometry',
                environmentModels: typeEnvironmentModels,
            }),
            links: getLinks(hydrometricStation, this.props),
            arrowNav: getStationArrowNav('hydrometry', hydrometricStations, hydrometricStation.id, s => this.props.push(`/station/hydrometry/${s.id}/hydrometryFollowUp`)),

        })
    }

    onClose = () => {
        this.setState({ openDialog: false })
    }

    render() {
        if (!this.state.dataLoaded) {
            return <ProgressCard progress={this.state.progress} />
        }
        if (!this.state.mesures.length) {
            return <MessageCard>{i18n.noDataToDisplay}</MessageCard>
        }
        const chartMinDate = this.getMinDate()
        const chartMaxDate = this.state.maxDate || moment().valueOf()
        const firstMeasure = this.state.mesures[0]
        const dateFirstMeasure = firstMeasure[0]
        const histoYears = moment.duration(moment(last(dateFirstMeasure)).diff(moment(dateFirstMeasure))).years() - 1

        const foundMinAllMeasures = minBy(this.state.mesures, 0) || []
        const minDateAllMeasures = foundMinAllMeasures[0]

        const tabs = [{
            icon: 'multiline_chart',
            constant: STATISTICS,
            label: i18n.statistics,
        }, {
            icon: 'fast_rewind',
            constant: PREVIOUSYEARS,
            label: i18n.showAdditionalData,
        },
        {
            icon: 'list',
            constant: LINKEDSTATIONS,
            label: `${i18n.associatedStations} ${i18n.and} ${i18n.additionalData}`,
        }, {
            icon: 'aspect_ratio',
            constant: CHARTOPTIONS,
            label: i18n.chartOptions,
        },
        {
            icon: 'report_problem',
            constant: THRESHOLDS,
            label: i18n.thresholds,
        },
        {
            icon: 'event_note',
            constant: EVENTS,
            label: i18n.events,
        },
        {
            icon: 'extension',
            constant: MODELS,
            label: i18n.models,
        },
            /* {
                icon: 'info',
                constant: 'infos',
                label: i18n.events,
                content: (
                    <div>
                        <StyledFieldSet>
                            <StyledLegend>{ i18n.dataType }</StyledLegend>
                            <div>
                                {'Type de Measures :'} {this.state.typeData}</div>
                            <div>{'Mode daffichage :'} {this.state.typeData}</div>
                            <div>{'Logarithm :'} <Checkbox col={ 12 } label={ 'Logarithm' } checked={ this.state.displayLog } onChange={ () => this.setState({displayLog: !this.state.displayLog}) } /></div>
                            <div>{'Taille du Graph :'}
                                <ul>
                                    <li>x</li>
                                    <li>y</li>
                                </ul></div>
                            <div>{'Seuils Afficher :'} <Checkbox col={ 12 } label={ 'Seuils Afficher' } checked={ this.state.displayLog } onChange={ () => this.setState({displayLog: !this.state.displayLog}) } /></div>
                        </StyledFieldSet>
                    </div>
                ),
            },*/
        ]

        return (
            <div className='card'>
                <Row className={`padding-top-1 ${this.state.fullScreen ? 'fullscreen-chart' : ''}`}>
                    <SimpleTabSideList
                        position='right'
                        defaultTab={STATISTICS}
                        tabs={tabs}
                    >
                        {
                            tab => (
                                <>
                                    {
                                        tab === STATISTICS && (
                                            <HydroSuiviStatTab
                                                hydrometer={this.props.hydrometricStation}
                                                statsSeries={this.state.statsSeries}
                                                changeStatsSeries={v => this.setState({ statsSeries: { ...this.state.statsSeries, ...v } })}
                                                histoYears={histoYears}
                                                dataTypeSelected={this.state.dataType}
                                                changeDataType={ v => this.setState({ dataType: v, dataLoaded: false }, this.dataLoading) }
                                                hydroStatistics={this.props.hydroStatistics}
                                                setFrequencyStats={(stat) => this.setState({
                                                    frequencyStats: uniq([...this.state.frequencyStats, stat]) })}
                                                loadFrequencyStats={this.loadFrequencyStats}
                                                hydrometerChartOptions={this.props.hydrometerChartOptions}
                                                datesChartOptions={{
                                                    startDate: this.state.startDateChartOptions,
                                                    setStartDate: (v) => this.setState({ startDateChartOptions: v }),
                                                    endDate: this.state.endDateChartOptions,
                                                    setEndDate: (v) => this.setState({ endDateChartOptions: v }),
                                                }}
                                                linearMode={this.state.linearMode}
                                                setLinearMode={() => this.setState({ linearMode: !this.state.linearMode })}
                                                validOnly ={this.state.linearMode}
                                                setValidOnly={() => this.setState({ validOnly: !this.state.validOnly })}
                                            />
                                        )
                                    }
                                    {
                                        tab === PREVIOUSYEARS && (
                                            <HydroSuiviPastYearsTab
                                                series={ this.state.series }
                                                onChangeState={ v => this.setState(v) }
                                                minDate={ minDateAllMeasures }
                                            />
                                        )
                                    }
                                    {
                                        tab === CHARTOPTIONS && (
                                            <OptionsToolPanelHydro
                                                yAxisMin={this.state.yAxisMin}
                                                setYaxisMin={v => this.setState({ yAxisMin: v })}
                                                yAxisMax={this.state.yAxisMax}
                                                setYaxisMax={v => this.setState({ yAxisMax: v })}
                                                showSplitLineY={this.state.showSplitLineY}
                                                setShowSplitLineY={v => this.setState({ showSplitLineY: v })}
                                                displayLog={this.state.displayLog}
                                            />
                                        )
                                    }
                                    {
                                        tab === THRESHOLDS && (
                                            <>
                                                <ThresholdsTab
                                                    thresholds={this.props.hydrometryThresholds.filter(f => f.dataType == this.state.dataType && f.stationId==this.props.hydrometricStation.id)}
                                                    station={this.props.hydrometricStation}
                                                    onReload={() => this.fetchThresholds()}
                                                    whiteCard
                                                    withoutDataTypeAnnotation
                                                    selectedDataType={this.state.dataType.toString()}
                                                />
                                                <div style={{ marginTop: 10, backgroundColor: 'white', padding: 5 }}>
                                                    <Checkbox
                                                        label={ i18n.displayThresholds }
                                                        checked={ this.state.isThresholShow }
                                                        onChange={(v) => this.setState({ isThresholShow: v })}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        tab === LINKEDSTATIONS && (
                                            <AssociatedStationsToolPanelHydro
                                                hydrometricStation={this.props.hydrometricStation}
                                                changeParent={changes => this.setState(changes)}
                                                typeId={this.state.dataType}
                                                minDate={chartMinDate}
                                                maxDate={chartMaxDate}
                                            />
                                        )
                                    }
                                    {
                                        tab === EVENTS && (
                                            <EventsToolPanelHydro
                                                hydrometricStation={this.props.hydrometricStation}
                                            />
                                        )
                                    }
                                    {
                                        tab === MODELS && (
                                            <HydroModelsTab
                                                // measures={this.state.mesures}
                                                // tab={this.state.tab}
                                                // chartMinDate={chartMinDate}
                                                // chartMaxDate={chartMaxDate}
                                                changeParent={changes => this.setState(changes)}
                                                hidden={tab !== MODELS}
                                                hydrometricStation={this.props.hydrometricStation}
                                                gridsLength={2}
                                                typeId={this.state.dataType}
                                            />
                                        )
                                    }
                                </>
                            )
                        }

                    </SimpleTabSideList>
                    <div id='chart'>
                        <Card>
                            <ChartTabsSuiviGlobal onChangeDate={(changes, forced) => {
                                this.setState(changes)
                                if (this.state.minDate !== changes.minDate || this.state.maxDate !== changes.maxDate || forced) {
                                    this.loadData(removeNullKeys({ startDate: changes.minDate, endDate: changes.maxDate }))
                                }
                            }} changeParent={changes => this.setState(changes)} default={{ active: 90 }}
                            />
                            <Grid paddingTop={1.5} >
                                <GroupedHydroPanel
                                    hydroGrouped={ this.state.groupFunc }
                                    setHydroGrouped={v => this.setState({ groupFunc: v, dataLoaded: false }, this.dataLoading)}
                                    left={400}
                                />
                            </Grid>
                            {this.state.dataLoaded ? this.getChart(chartMinDate, chartMaxDate):
                                <div className='padding-top-7 padding-left-2 padding-right-2 padding-bottom-2'> <ProgressBar
                                    progress={this.state.progress} withMessage
                                /></div>}
                        </Card>
                    </div>
                </Row>
                <Dialog
                    open={this.state.openDialog}
                >
                    <DialogTitleMUI>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item >
                                {i18n.generalInfos}
                            </Grid>
                            <Grid item>
                                <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={this.onClose} />
                            </Grid>
                        </Grid>
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        <div style={{ padding: '20px 10px 5px 0px' }}>
                            {this.state.messageModal}
                        </div>
                    </DialogContentMUI>
                </Dialog>
            </div>
        )
    }
}

HydrometerSuiviChart.propTypes = {
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    hydrometryBruteMeasures: arrayOf(DtoHydrometricBruteMeasure),
    hydrometryThresholds: arrayOf(DtoHydrometryThreshold),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    fetchHydrometricThresholds: PropTypes.func,
    fetchHydroStatistics: PropTypes.func,
    fetchHydrometerChartOptions: PropTypes.func,
    hydroStatistics: arrayOf(DtoHydroStats),
    hydroMeasures: arrayOf(DtoHydroMeasures),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    stationEvents: arrayOf(DtoEvent),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    hydrometerChartOptions: arrayOf(DtoHydrometerChartOptions),
    fetchHydroChronicMeasures: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometryBruteMeasures: store.HydrometryReducer.hydrometryBruteMeasures,
        hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        hydroMeasures: store.HydrometryReducer.hydroMeasures,
        fetchHydrometricThresholds: PropTypes.func,
        fetchHydroStatistics: PropTypes.func,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        stationEvents: store.EventsReducer.stationEvents,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometerChartOptions: store.HydrometerStationReducer.chartOptions,
    }
}

const mapDispatchToProps = {
    fetchHydrometricThresholds: HydrometryAction.fetchHydrometricThresholds,
    fetchHydroStatistics: HydrometryAction.fetchHydroStatistics,
    fetchHydrometerChartOptions: HydrometerStationAction.fetchHydrometerChartOptions,
    fetchHydroChronicMeasures: HydrometryAction.fetchHydroChronicMeasures,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometerSuiviChart)