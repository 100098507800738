export default class DtoJobParameters {
    constructor(obj = {}) {
        this.routingKey = obj.routingKey
        this.jobType = obj.jobType
        this.message = obj.message
        this.path = obj.path
        this.pathType = obj.pathType
        this.propertiesFile = obj.propertiesFile
        this.parameters = obj.parameters || []
        this.filters = obj.filters || []
        this.dataTypes = obj.dataTypes || []
        this.alertTypes = obj.alertTypes || []
        this.emails = obj.emails
        this.contactsIds = obj.contactsIds
        this.nextJobId = obj.nextJobId
        this.isManualImport = obj.isManualImport
        this.returnsData = obj.returnsData
        this.fileContent = obj.fileContent
        this.user = obj.user
        this.jobExecutionId = obj.jobExecutionId
        this.import = obj.import || {}
    }
}
