/* eslint-disable max-nested-callbacks */
import CatchmentThunk from 'catchment/actions/CatchmentThunk'
import { NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import Card from 'components/card/Card'
import MessageCard from 'components/card/MessageCard'
import ProgressCard from 'components/card/ProgressCard'
import Checkbox from 'components/forms/Checkbox'
import { groupBy, isUndefined, keys, uniq } from 'lodash'
import moment from 'moment'
import QualityAction from 'quality/actions/QualityAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import WatermassAction from 'referencial/components/watermass/actions/WatermassAction'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useSandreList from 'utils/customHook/useSandreList'
import useTitle from 'utils/customHook/useTitle'
import { hasValue } from 'utils/NumberUtil'
import ContaminationEvolutionChart from './ContaminationEvolutionChart'
import ContaminationStatusChart from './ContaminationStatusChart'
import CatchmentsOverviewExportModal from './CatchmentsOverviewExportModal'
import ContaminationTableSummary from './ContaminationTableSummary'
import { FrequencyPesticides } from 'quality/components/qualityComponents/FollowUpPesticides'
import { Grid } from '@mui/material'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'
import DistributionChart from './DistributionChart'

const CatchmentsOverviewApp = () => {
    const dispatch = useDispatch()

    const {
        catchments,
        catchmentsAnalysisStatus,
        catchmentPoints,
        catchmentsAnalysis,
        departments,
        qualitosIndexCode,
        watermasses,
        qualitometers,
        thresholds,
        qualityThresholds,
    } = useSelector(store => ({
        catchments: store.CatchmentReducer.catchments,
        catchmentsAnalysisStatus: store.DataManagerReducer.catchment.catchmentsAnalysisStatus,
        catchmentPoints: store.CatchmentReducer.catchmentPoints,
        catchmentsAnalysis: store.CatchmentReducer.catchmentsAnalysis,
        departments: store.CityReducer.departments,
        qualitosIndexCode: store.QualityReducer.qualitosIndexCode,
        watermasses: store.WatermassReducer.watermasses,
        qualitometers: store.QualityReducer.qualitometersLight,
        thresholds: store.QualityReducer.thresholds,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const [progress, setProgress] = useState(0)

    const [selectedDepartments, setSelectedDepartments] = useState([])
    const [selectedSdages, setSelectedSdages] = useState([])
    const [selectedReferenceSdages, setSelectedReferenceSdages] = useState([])
    const [selectedWatermass, setSelectedWatermass] = useState([])

    const [useP90, setUseP90] = useState(false)
    const [displayActiveSDAGE, setdisplayActiveSDAGE] = useState(false)
    const [useOnlyMainPoint, setUseOnlyMainPoint] = useState(true)
    const [useLastPeriod, setUseLastPeriod] = useState(true)

    const [isExportModalOpen, setIsExportModalOpen] = useState(false)

    const sdages = useSandreList('CAPTAGES.SDAGE')

    const thresholdPesticides1 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_1', setting => setting ? parseFloat(setting) : 0.1)
    const specificThreshold = useApplicationSetting('CATCHMENT_SPECIFIC_THRESHOLD')

    const pesticides = useApplicationSetting('CATCHMENT_PESTICIDES', setting => setting?.split(',').filter(c => !!c) || [])

    const excludeProducers = useApplicationSetting('CATCHMENT_PRODUCERS_EXCLUDED', setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])

    const startPeriod = useApplicationSetting('CATCHMENT_START_PERIOD', setting => setting ? parseInt(setting) : undefined)
    const endPeriod = useApplicationSetting('CATCHMENT_END_PERIOD', setting => setting ? parseInt(setting) : undefined)
    const regroupment = useApplicationSetting('CATCHMENT_REGROUPING', setting => setting ? parseInt(setting) : 2)

    const currentYear = useMemo(() => moment(endPeriod, 'YYYY').endOf('year').valueOf(), [endPeriod])
    const lastYear = useMemo(() => moment(endPeriod, 'YYYY').subtract(regroupment - 1, 'years').startOf('year').valueOf(), [endPeriod, regroupment])

    useTitle(() => [{
        title: i18n.catchments,
        href: 'catchment',
    }, {
        title: i18n.overview,
        href: 'catchment/overview',
    }], [])

    useEffect(() => {
        dispatch(WatermassAction.fetchWatermasses())
        dispatch(QualityAction.fetchThresholds())
        dispatch(QualityAction.fetchQualitometersLight())
    }, [])

    const thresholdList = qualityThresholds.find(t => t.thresholdCode === specificThreshold)?.thresholds ?? []

    useEffect(() => {
        if (specificThreshold && thresholds.length) {
            const parseId = parseInt(specificThreshold)
            const th = thresholds.find(t => t.code === parseId)
            if (th) {
                dispatch(QualityAction.fetchQualityThresholds([{ code: th.code, thresholdType: th.thresholdType }]))
            }
        }
    }, [dispatch, specificThreshold, thresholds])

    const formattedCatchmentPoints = useMemo(() => {
        const filteredPoints = catchmentPoints.filter(c => qualitometers.some(q => q.code === c.codeWithoutDesignation))
        const groupByCatchment = groupBy(filteredPoints, 'id')
        return keys(groupByCatchment).flatMap(key => {
            if (groupByCatchment[key].some(p => p.mainPoint)) return groupByCatchment[key]

            return groupByCatchment[key].map((p, i) => {
                if (i === 0) {
                    return {
                        ...p,
                        mainPoint: true,
                    }
                }
                return p
            })
        })
    }, [catchmentPoints, qualitometers])

    useEffect(() => {
        // const codes = catchmentPoints.map(p => p.codeWithoutDesignation).filter(c => !!c)
        const qualitoIds = catchmentPoints?.map(point => {
            return qualitometers.find(q => q.code === point.codeWithoutDesignation)?.id
        }).filter(q => !isUndefined(q)) || []
        if (qualitoIds.length) {
            const filter = {
                ultraLightMode: true,
                stations: uniq(qualitoIds),
                startDate: getBeginingOfTheYear(startPeriod),
                endDate: getEndOfTheYear(endPeriod),
                excludedProducers: excludeProducers,
                parameters: [NITRATES_CODE, ...pesticides],
                support: '3', // support 'eau'
            }
            dispatch(CatchmentThunk.fetchCatchmentsAnalysis({ filter, onProgress: prog => setProgress(p => p + prog) }))
        }
    }, [dispatch, catchmentPoints, qualitometers, startPeriod, endPeriod, excludeProducers, pesticides])

    const catchmentFiltered = useMemo(() => {
        const searchActiveSDAGE = displayActiveSDAGE ? catchments.filter(({ activeSdage }) => activeSdage) : catchments
        const watermassFilter = selectedWatermass.length ? searchActiveSDAGE.filter(c => selectedWatermass.includes(c.meCode)) : searchActiveSDAGE
        const departmentFilter = selectedDepartments.length ? watermassFilter.filter(c => selectedDepartments.includes(c.department)) : watermassFilter
        return selectedSdages.length ? departmentFilter.filter(({ usedSdage }) => selectedSdages.includes(usedSdage) || (selectedReferenceSdages.length && selectedReferenceSdages.includes(usedSdage))) : departmentFilter
    }, [displayActiveSDAGE, catchments, selectedWatermass, selectedDepartments, selectedSdages, selectedReferenceSdages])

    const catchmentPointFiltered = useMemo(() => {
        return catchmentFiltered.flatMap(c => {
            const points = formattedCatchmentPoints.filter(p => c.id === p.id)
            if (useOnlyMainPoint) {
                return points.filter(p => p.mainPoint)
            }
            return points
        })
    }, [catchmentFiltered, formattedCatchmentPoints, useOnlyMainPoint])

    const analysis = useMemo(() => {
        const filteredAnalysis = catchmentsAnalysis.filter(({ analysisDate, result }) => hasValue(analysisDate) && hasValue(result))

        const ids = catchmentFiltered.flatMap(catchment => {
            const points = catchmentPointFiltered.filter(point => point.id === catchment.id)
            return points.map(point => qualitosIndexCode[point.codeWithoutDesignation]?.id).filter(id => hasValue(id))
        })

        return filteredAnalysis.filter(a => ids.includes(a.qualitometer))
    }, [catchmentFiltered, catchmentPointFiltered, catchmentsAnalysis, qualitosIndexCode])

    const lastPeriodAnalysis = useMemo(() => {
        return analysis
            .filter(a => a.analysisDate < currentYear && a.analysisDate > lastYear)
    }, [analysis, currentYear, lastYear])

    const pesticidesAnalysis = useMemo(() => {
        return analysis.filter(({ parameter }) => parameter !== NITRATES_CODE)
    }, [analysis])

    useActions(() => ({
        exportList: [{
            onClick: () => setIsExportModalOpen(true),
            label: i18n.excel,
        }],
    }), [])

    const watermassList = useMemo(() => {
        return watermasses.filter(w => w.euroCode && catchments.some(c => c.meCode === w.euroCode)).map(w => ({
            code: w.euroCode,
            name: `[${w.euroCode}] ${w.name}`,
        }))
    }, [catchments, watermasses])

    return (
        <Card cardContentStyle={{ marginBottom: '100px' }}>
            {
                (catchmentsAnalysisStatus === 'loading' || catchmentsAnalysisStatus === 'idle') && (
                    <ProgressCard progress={progress} />
                )
            }
            {
                catchmentsAnalysisStatus === 'succeeded' && (
                    <>
                        <div style={{ backgroundColor: '#DDD', borderRadius: '0px 0px 10px 10px' }}>
                            <Grid container rowSpacing={1} columnSpacing={2} style={{ padding: '10' }}>
                                <Grid item xs={3}>
                                    <SuperMultiAutocomplete
                                        label={i18n.department}
                                        options={departments}
                                        keyValue='code'
                                        onChange={setSelectedDepartments}
                                        values={selectedDepartments}
                                        multiple
                                        limit={2}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SuperMultiAutocomplete
                                        label={i18n.sdage}
                                        options={sdages}
                                        keyValue='code'
                                        onChange={setSelectedSdages}
                                        values={selectedSdages}
                                        multiple
                                        limit={1}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SuperMultiAutocomplete
                                        label={i18n.referenceSDAGE}
                                        options={sdages}
                                        keyValue='code'
                                        onChange={setSelectedReferenceSdages}
                                        values={selectedReferenceSdages}
                                        multiple
                                        limit={1}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SuperMultiAutocomplete
                                        label={i18n.watermass}
                                        options={watermassList}
                                        keyValue='code'
                                        onChange={setSelectedWatermass}
                                        values={selectedWatermass}
                                        multiple
                                        limit={1}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Checkbox
                                        label={i18n.useP90ForNitrates}
                                        checked={useP90}
                                        onChange={setUseP90}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Checkbox
                                        label={i18n.displayActiveSDAGE}
                                        checked={displayActiveSDAGE}
                                        onChange={setdisplayActiveSDAGE}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Checkbox
                                        label={i18n.useOnlyDataMainPoint}
                                        checked={useOnlyMainPoint}
                                        onChange={setUseOnlyMainPoint}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Checkbox
                                        label={'Utiliser la dernière période'}
                                        checked={useLastPeriod}
                                        onChange={setUseLastPeriod}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        {
                            !!analysis.length && (
                                <>
                                    <ContaminationStatusChart
                                        catchments={catchmentFiltered}
                                        catchmentPoints={catchmentPointFiltered}
                                        analysis={lastPeriodAnalysis}
                                        useP90={useP90}
                                        referenceSdages={selectedReferenceSdages}
                                    />
                                    <ContaminationEvolutionChart
                                        catchments={catchmentFiltered}
                                        catchmentPoints={catchmentPointFiltered}
                                        analysis={analysis}
                                        useP90={useP90}
                                        referenceSdages={selectedReferenceSdages}
                                    />
                                    <ContaminationTableSummary
                                        catchments={catchmentFiltered}
                                        catchmentPoints={catchmentPointFiltered}
                                        analysis={useLastPeriod ? lastPeriodAnalysis : analysis}
                                        useP90={useP90}
                                    />
                                    <FrequencyPesticides
                                        pesticidesAnalysis={pesticidesAnalysis}
                                        threshold={thresholdPesticides1}
                                        specificThresholds={thresholdList}
                                        pesticides={pesticides}
                                    />
                                    <DistributionChart
                                        catchments={catchmentFiltered}
                                        catchmentPoints={catchmentPointFiltered}
                                        analysis={analysis}
                                        useP90={useP90}
                                    />

                                    <CatchmentsOverviewExportModal
                                        catchments={catchmentFiltered}
                                        catchmentPoints={catchmentPointFiltered}
                                        analysis={analysis}
                                        lastPeriodAnalysis={useLastPeriod ? lastPeriodAnalysis : analysis}
                                        useP90={useP90}

                                        isExportModalOpen={isExportModalOpen}
                                        setIsExportModalOpen={setIsExportModalOpen}
                                    />
                                </>
                            )
                        }
                        {
                            !analysis.length && (
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            )
                        }
                    </>
                )
            }
        </Card>
    )
}

export default CatchmentsOverviewApp