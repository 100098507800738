import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getI18nTitleDataLength } from 'utils/StringUtil'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import { getCampaignProgress } from '../../../campaign/utils/CampaignUtils'
import Card from '../../../components/card/Card'
import { getDate } from '../../../utils/DateUtil'
import DtoSurvey from '../../dto/DtoSurvey'
import { Icon } from '@mui/material'
import { sieauTooltip } from 'utils/FormUtils'

const SurveyCard = ({
    survey = {},
    progress = {},
    redirect = () => { },
}) => {
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const {
        idSurvey,
        name,
        statusCode,
        startDate,
        endDate,
        updateDate,
        updateLogin,
        year,
        contributorId,
    } = survey
    const {
        nbDeclarations = 0,
        nbDeclarationsValidated = 0,
    } = progress
    const progressValue = getCampaignProgress(nbDeclarationsValidated, nbDeclarations)

    const getStatusIcon = () => {
        switch (statusCode) {
            case 1:
                const tooltip = `${nbDeclarationsValidated} ${getI18nTitleDataLength(i18n.declaration, i18n.declarations, nbDeclarationsValidated)} ${i18n.of} ${nbDeclarations} (${Math.round(progressValue)}%)`
                return (
                    <div className='col s4'>
                        <div style={{ width: '100px' }}>
                            <ProgressBar progress={progressValue} tooltip={tooltip} />
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className='col s4 center-align'>
                        <i className='material-icons medium'>folder</i>
                    </div>
                )
            default:
                return (
                    <div className='col s4 center-align'>
                        <i className='material-icons medium'>create</i>
                    </div>
                )
        }
    }

    const contributor = useMemo(() => contributors.find((c) => c.id === contributorId), [contributors, contributorId])

    return (
        <Card>
            <div className={ 'row no-margin valign-wrapper clickable' }>
                <div className='col s11 valign-wrapper' onClick={ () => redirect(`/survey/${idSurvey}`) }>
                    <div className='col s4'>
                        <p className='collection-title' style={ { fontWeight: 'bold' } }>{ `${year} - ${name || ''}` }</p>
                        <div className='collection-content'>{updateDate && updateLogin ? `${i18n.updatedAt} ${getDate(updateDate)} ${i18n.by} ${updateLogin}` : ''}</div>
                    </div>
                    <div className='col s3'>
                        <div className='collection-content no-margin'>{ `${i18n.periode}: ${getDate(startDate)} - ${getDate(endDate)}`}</div>
                        <div className='collection-content no-margin'>{ `${i18n.finalizedDeclarations}: ${nbDeclarationsValidated} / ${nbDeclarations}` }</div>
                    </div>
                    {getStatusIcon()}
                </div>
                <div className='col s1'>
                    {contributorId ? <Icon {...sieauTooltip(contributor?.name, null, 'left')} style={{ fontSize: 52 }}>account_circle</Icon> : <div style={{ height: 52 }} />}
                </div>
            </div>
        </Card>
    )
}

SurveyCard.propTypes = {
    survey: PropTypes.instanceOf(DtoSurvey),
    progress: PropTypes.instanceOf(DtoCampaignProgress),
    redirect: PropTypes.func,
}

const mapDispatchToProps = {
    redirect: push,
}

export default connect(null, mapDispatchToProps)(SurveyCard)
