import { BLUE, GREEN, GREY, ORANGE } from '../components/constants/ColorConstant'
import { getColor } from './ColorUtil'


const getControlColor = (control) => {
    return control && [1, 2, 3, 4].includes(parseInt(control)) ? [GREY, GREEN, BLUE, ORANGE][parseInt(control) - 1] : GREY
}

const getControlObject = (control, content) => {
    const color = getControlColor(control)
    return {
        className: `width-0 colorful-card card-${getColor(color)}`,
        color: 'white',
        // tooltip: getLabel(orderBy(statusList, 'code)'), control || 1),
        value: content,
    }
}

export { getControlColor, getControlObject }

