import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import ProgressCard from '../../../../../components/card/ProgressCard'
import useTitle from '../../../../../utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import QualityIntegrationOverviewAction from '../../actions/QualityIntegrationOverviewAction'
import { searchAllCharacters } from '../../../../../utils/StringUtil'
import { hasValue } from '../../../../../utils/NumberUtil'
import QualityIntegrationOverviewCriterias from './QualityIntegrationOverviewCriterias'
import QualityOverviewTableDto from './QualityOverviewTableDto'
import QualityOverviewTableStatsDto from './QualityOverviewTableStatsDto'
import QualityIntegrationOverviewTable from './QualityIntegrationOverviewTable'
import moment from 'moment'
import AdministrationAction from '../../../../../administration/actions/AdministrationAction'
import QualityAction from '../../../../actions/QualityAction'
import useProgressDispatch from '../../../../../utils/customHook/useProgressDispatch'
import ContributorAction from '../../../../../referencial/components/contributor/actions/ContributorAction'
import NetworkAction from '../../../../../referencial/components/network/actions/NetworkAction'
import CityAction from '../../../../../referencial/components/city/actions/CityAction'
import ParameterAction from '../../../../../referencial/components/parameter/actions/ParameterAction'
import UnitAction from '../../../../../referencial/components/unit/actions/UnitAction'
import JobAction from '../../../../../import/actions/JobAction'
import CampaignAction from '../../../../../campaign/actions/CampaignAction'
import Row from '../../../../../components/react/Row'
import { isUndefined, pick } from 'lodash'
import { QualityActionConstant } from '../../../../reducers/QualityReducer'
import AppStore from '../../../../../store/AppStore'
import { STATION_TYPE_NAME } from '../../../../../station/constants/StationConstants'
import SimpleFilterSelect from '../../../../../components/forms/specific/SimpleFilterSelect'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { ANALYSIS_TAB, JOB_TAB, STATIONS_TAB } from '../../constants/IntegrationOverviewConstants'
import AnalysisTab from '../AnalysisTab'
import JobTab from '../JobTab'
import Tabs from '../../../../../components/Tabs'
import { promiseAllProgress } from '../../../../../utils/ActionUtils'
import OperationAction from 'quality/components/operation/actions/OperationAction'

// RG1 : Les valeurs 'Filtre' et 'rechercher' enregistrés depuis la liste des qualitomètres doivent être conservés en arriavant à l'écran
// RG2 : Les données ne sont pas rechargées si il a été rechargé au moins une fois, et qu'on reviens sur cet écran (gestion du cache)

const QualityIntegrationOverviewApp = ({ location }) => {
    const {
        qualitometers,
        citiesIndex,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const dispatch = useDispatch()

    const cacheSituation = useMemo(() => { // RG2
        // je ne le met pas dans le useSelector pour éviter le rechargement du composant lorsque je change le cache
        return AppStore.getState().QualityReducer.cacheQualitySituation
    }, [])

    const previousSelectedFilter = useMemo(() => { // voir RG1
        return pick(AdministrationAction.getCache('quality'), ['filter', 'searchValue'])
    }, [])

    const [dataLoaded, setDataLoaded] = useState(!!cacheSituation)
    const [firstLoadWithCache, setFirstLoadWithCache] = useState(!!cacheSituation) // RG2
    const [progress, setProgress] = useState(0)
    const [operationResults, setOperationResults] = useState(cacheSituation?.operationResults || [])
    const [operationStats, setOperationStats] = useState(cacheSituation?.operationStats || [])

    const [criterias, setCriterias] = useState(() => {
        if (cacheSituation?.criterias) {
            return cacheSituation.criterias
        }
        const queryUrl = queryString.parse(location.search)
        const jobExecutionId = queryUrl?.jobExecutionId ? parseInt(queryUrl?.jobExecutionId) : undefined
        return {
            startDate: moment().subtract(20, 'years').startOf('year').valueOf(),
            applyCriteriasOnStats: false,
            searchValue: previousSelectedFilter?.searchValue,
            jobExecutionId,
            conformitySandre: true,
            outliers: true,
        }
    })
    const [needPreviousFilterResults, setNeedPreviousFilterResults] = useState(() => { // RG1
        if (cacheSituation) {
            return false
        }
        return previousSelectedFilter?.filter !== -1
    })

    const [analysisDataLoaded, setAnalysisDataLoaded] = useState(true)
    const [showAnalysisPanel, setShowAnalysisPanel] = useState(false)
    const [progressAnalysis, setProgressAnalysis] = useState(0)


    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: i18n.integrationOverview,
        href: 'quality/integrationOverview',
    }]
    , [])

    useEffect(() => {
        if (!qualitometers.length) {
            // je laisse mon fetch des qualitos ici car j'en ai besoin pour mon simpleFilterSelect plus bas
            dispatch(QualityAction.fetchQualitometersLight())
        }
    }, [])

    const { progress: progressReferentials, isLoaded: isReferentialsLoaded } = useProgressDispatch(() => {
        if (cacheSituation) {
            return []
        }
        return [
            dispatch(ContributorAction.fetchContributors()),
            dispatch(ParameterAction.fetchParameters()),
            // dispatch(ContactAction.fetchContacts()),
            dispatch(NetworkAction.fetchNetworks()),
            dispatch(CityAction.fetchCities()),
            dispatch(QualityAction.fetchThresholds()),
            dispatch(UnitAction.fetchUnits()),
            // dispatch(SupportAction.fetchSupports()),
            dispatch(OperationAction.fetchRemarks()),
            dispatch(JobAction.fetchQualityJobs()),
            dispatch(CampaignAction.fetchQualityCampaigns()),
            dispatch(CampaignAction.fetchQualityCampaignStations()),
            dispatch(QualityAction.fetchQualityNetworkLinks()),
            dispatch(NetworkAction.fetchNetworks()),
        ]
    }, [])

    // onValidate sur les critères + 1er chargement
    const filteredStations = useMemo(() => {
        if (firstLoadWithCache) {
            return cacheSituation?.filteredStations
        }
        if (!isReferentialsLoaded || needPreviousFilterResults) {
            return []
        }
        const {
            filter,
            filterResults = [],
            installation,
            instAssociatedStations = [],
            productionUnit,
            prodAssociatedStations = [],
            distributionUnit,
            distriAssociatedStations = [],
            sector,
            sectorAssociatedStations = [],
        } = criterias
        const filterList = filter && filter !== -1 ? filterResults : qualitometers
        const filterInstallation = !isUndefined(installation) ? filterList.filter(s => instAssociatedStations.includes(s.id)) : filterList
        const filterProduction = !isUndefined(productionUnit) ? filterInstallation.filter(s => prodAssociatedStations.includes(s.id)) : filterInstallation
        const filterDistribution = !isUndefined(distributionUnit) ? filterProduction.filter(s => distriAssociatedStations.includes(s.id)) : filterProduction
        const filterSector = !isUndefined(sector) ? filterDistribution.filter(s => sectorAssociatedStations.includes(s.id)) : filterDistribution

        const stationsWithCities = filterSector.map(s => ({ ...s, city: citiesIndex[s.townCode]?.name }))
        const searchValue = hasValue(criterias.searchValue) ? searchAllCharacters(criterias.searchValue) : null
        const filterSearchValue = hasValue(searchValue) ? stationsWithCities.filter(s => searchAllCharacters(['code', 'name', 'city'].map(key => s[key]).join('   ')).includes(searchValue)) : stationsWithCities
        return hasValue(criterias.network) ? (() => {
            const ids = (criterias.networkGroup[criterias.network] || []).map(n => n.idStation)
            return filterSearchValue.filter(s => ids.includes(s.id))
        })() : filterSearchValue
    }, [criterias, citiesIndex, qualitometers, isReferentialsLoaded, needPreviousFilterResults])

    useEffect(() => {
        if (!filteredStations.length) {
            return
        }
        if (firstLoadWithCache) {
            setFirstLoadWithCache(false) // RG2 : empêche le 1er chargement des données si le cache est présent
            return
        }
        const ids = filteredStations.map(s => s.id)
        setDataLoaded(false)
        const criteriasWithStations = { ...criterias, stations: ids, network: undefined, networkGroup: undefined }
        dispatch(QualityIntegrationOverviewAction.loadQualityIntegrationOverview(criteriasWithStations, setProgress, res => {
            setDataLoaded(true)
            const results = (res[0] || []).map(obj => new QualityOverviewTableDto(obj))
            const stats = (res[1] || []).map(obj => new QualityOverviewTableStatsDto(obj))
            setOperationResults(results)
            setOperationStats(stats)
            dispatch(QualityActionConstant.cacheQualitySituation({ operationResults: results, operationStats: stats, criterias, filteredStations })) // RG2
        }))
        if (criterias.jobId || criterias.jobExecutionId) {
            setShowAnalysisPanel(true)
            setAnalysisDataLoaded(false)
            const jobFilter = {
                jobId: criterias.jobId,
                jobExecutionId: criterias.jobExecutionId,
                startDate: criterias.jobStartDate,
                endDate: criterias.jobEndDate,
            }
            const usedCriterias = pick(criterias, ['jobId', 'startDate', 'endDate', 'jobStartDate', 'jobEndDate', 'jobExecutionId', 'threshold', 'producers', 'campaign', 'conformitySandre', 'outliers', 'stationId'])
            promiseAllProgress([
                dispatch(JobAction.fetchQualityExecutions(jobFilter)),
                dispatch(QualityIntegrationOverviewAction.fetchAnalysis(usedCriterias, ids)),
            ], setProgressAnalysis).then(() => setAnalysisDataLoaded(true))
        } else {
            setShowAnalysisPanel(false)
        }
    }, [filteredStations])

    if (!dataLoaded || !analysisDataLoaded) {
        return (
            <>
                { !isReferentialsLoaded && (
                    <Row>
                        <ProgressCard progress={progressReferentials} message={i18n.referentialLoading} />
                    </Row>
                )}
                <Row>
                    <ProgressCard progress={progress} />
                </Row>
                { showAnalysisPanel && (
                    <Row>
                        <ProgressCard progress={progressAnalysis} message={i18n.analysisLoading} />
                    </Row>
                )}
                {
                    needPreviousFilterResults && qualitometers.length ? ( // simpleFilterSelect en mode "hidden" ici pour RG1
                        <SimpleFilterSelect
                            stationType={STATION_TYPE_NAME.quality}
                            col={4}
                            hidden
                            onChange={(result, code) => {
                                setCriterias(cr => ({ ...cr, filter: code, filterResults: result }))
                                setNeedPreviousFilterResults(false)
                            }}
                            stations={qualitometers}
                        />
                    ) : undefined
                }
            </>
        )
    }
    return (
        <div>
            <QualityIntegrationOverviewCriterias defaultFilter={criterias} onValidate={setCriterias} />
            <div className='padding-top-2'>
                <Tabs
                    defaultTab={STATIONS_TAB}
                    tabs={[
                        {
                            constant: STATIONS_TAB,
                            label: i18n.stations,
                        },
                        {
                            constant: ANALYSIS_TAB,
                            label: i18n.analysis,
                            disabled: !showAnalysisPanel,
                        },
                        {
                            constant: JOB_TAB,
                            label: i18n.treatments,
                            disabled: !showAnalysisPanel,
                        },
                    ]}
                >
                    {tab => (
                        <>
                            {
                                tab === STATIONS_TAB && (
                                    <QualityIntegrationOverviewTable operationResults={operationResults} operationStats={operationStats} stations={filteredStations}/>
                                )
                            }
                            {
                                tab === ANALYSIS_TAB && (
                                    <AnalysisTab
                                        className='padding-top-1'
                                        filters={criterias}
                                        search={criterias.searchValue}
                                    />
                                )
                            }
                            {
                                tab === JOB_TAB && (
                                    <JobTab
                                        filter={criterias}
                                    />
                                )
                            }
                        </>
                    )
                    }
                </Tabs>
            </div>
        </div>
    )
}

QualityIntegrationOverviewApp.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
}

export default QualityIntegrationOverviewApp
