import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import ToastrAction from 'toastr/actions/ToastrAction'
import { isEqual, omit, orderBy, pickBy } from 'lodash'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import CentralEquipmentPanel from '../../central/components/CentralEquipmentPanel'
import RangePanel from '../../central/components/range/RangePanel'
import ChannelPanel from '../../central/components/channel/ChannelPanel'
import PowerSupplyEquipmentPanel from '../../powerSupply/components/PowerSupplyEquipmentPanel'
import SensorEquipmentPanel from '../../sensor/components/SensorEquipmentPanel'
import TelecomEquipmentPanel from '../../telecom/components/TelecomEquipmentPanel'
import VariousMaterielEquipmentPanel from '../../variousMateriel/components/VariousMaterielEquipmentPanel'
import SimEquipmentPanel from '../../sim/components/SimEquipmentPanel'
import EquipmentPanel from './EquipmentPanel'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import SituationPanel from '../../situation/SituationPanel'
import ActionComponent from '../../../../components/ActionComponent'
import Icon from '../../../../components/icon/Icon'
import Card from '../../../../components/card/Card'
import FilePanel from '../../../../components/file/FilePanel'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import SmallPicturePanel from '../../../../components/picture/SmallPicturePanel'

import EquipmentAction from '../actions/EquipmentAction'
import CentralAction from '../../central/actions/CentralAction'
import PowerSupplyAction from '../../powerSupply/actions/PowerSupplyAction'
import TelecomAction from '../../telecom/actions/TelecomAction'
import SensorAction from '../../sensor/actions/SensorAction'
import VariousMaterielAction from '../../variousMateriel/actions/VariousMaterielAction'
import SimAction from '../../sim/actions/SimAction'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import SieauAction from '../../../../components/sieau/SieauAction'

import DtoMaterielState from '../../../dto/DtoMaterielState'
import EquipmentDto from '../dto/EquipmentDto'
import EquipmentTypeDto from '../dto/EquipmentTypeDto'
import DtoEquipmentSituation from '../dto/DtoEquipmentSituation'
import DtoCentralRange from '../../central/dto/DtoCentralRange'
import DtoCentralChannel from '../../central/dto/DtoCentralChannel'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import DtoPicture from '../../../../components/picture/dto/DtoPicture'
import DtoFile from '../../../../components/file/dto/DtoFile'
import User from '../../../../account/dto/User'

import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser, hasHabilitation } from '../../../../utils/SettingUtils'
import { getMaterielIconValue } from '../../../../utils/MaterielUtils'
import {
    MAT_BATTERIES,
    MAT_CAPTEURS,
    MAT_CENTRALES,
    MAT_DIVERS,
    MAT_SIMS,
    MAT_TELECOMS,
} from '../../../../administration/components/user/constants/HabilitationConstants'
import NumberField from '../../../../components/forms/NumberField'
import AppStore from 'store/AppStore'
import HomeAction from 'home/actions/HomeAction'
import VariousMaterielDto from '../../variousMateriel/dto/VariousMaterielDto'
import DtoMaterielSettingRule from '../../../dto/DtoMaterielSettingRule'
import { isSaveBlocked } from '../../../utils/MaterielUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_MAT_EQUIPMENT } from '../../../../account/constants/AccessRulesConstants'
import { EQUIPMENT_ID } from 'materiel/constants/MaterielConstants'

class EquipmentApp extends ActionComponent {
    state = {
        equipment: {
            administrator: this.props.accountUser.contributorCode,
        },
        central: [],
        powerSupply: [],
        sensor: [],
        sim: [],
        telecom: [],
        variousMateriel: [],
        isOpen: false,
        isDuplicatePopinOpen: false,
        equipmentNumber: 1,
        isEditMode: false,
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentWillUnmount = () => {
        this.props.resetEquipment()
        this.props.resetAllActions()
    }

    fetchEquipment = id => this.props.fetchEquipment(id).then(json => {
        if (json?.equipment?.equipmentId) {
            this.props.fetchEquipmentSituations(json.equipment.equipmentId)
            this.props.fetchEquipmentPictures(json.equipment.equipmentId)
            if (json.equipment.serialNumber) {
                this.props.fetchEquipmentFiles(json.equipment.serialNumber)
            }
            this.setState(json)
        }
    })

    componentDidMount() {
        if (!componentHasHabilitations(H_MAT_EQUIPMENT)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.match.params.id !== 'new') {
            this.fetchEquipment(this.props.match.params.id)
            this.changeEditMode(false)
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.equipmentTypes.length) {
            this.props.fetchEquipmentTypes()
        }
        if (!this.props.equipments.length) {
            this.props.fetchEquipments()
        }
        this.props.fetchCentralTypes(true)
        this.props.fetchPowerSupplyTypes(true)
        this.props.fetchSensorTypes(true)
        this.props.fetchSimTypes(true)
        this.props.fetchTelecomTypes(true)
        if (!this.props.variousMaterielTypes.length) {
            this.props.fetchVariousMaterielTypes()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        this.applyActions()
    }

    defineArianne = (type, title) => {
        this.props.forceFetch('title', [
            {
                title: i18n.materiels,
                href: 'materiel',
            },
            {
                title: i18n.equipments,
                href: 'materiel/equipment',
            },
            {
                title: type,
                href: `materiel/equipment/${this.props.match.params.id}`,
            },
            {
                title,
                href: `materiel/equipment/${this.props.match.params.id}`,
            },
        ])
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchEquipment(this.props.match.params.id)
        }
        if (this.state.isEditMode !== prevProps.isEditMode || this.state.equipment.equipmentId !== prevProps.equipment.equipmentId) {
            this.applyActions()
        }
        if (!isEqual(prevProps.equipment, this.props.equipment) || !isEqual(prevProps.equipmentTypes, this.props.equipmentTypes)) {
            const {
                reference = '',
                serialNumber = '',
                equipmentType,
                id,
            } = this.props.equipment
            const title = reference || serialNumber || id
            const type = (this.props.equipmentTypes.find(t => t.code === parseInt(equipmentType)) || {}).label || i18n.notDefined
            this.defineArianne(type, title)
        }
    }

    getEquipmentInformation = materials => {
        const {
            serialNumber,
            reference,
            manufacturerId,
            providerId,
            networkCode,
            manufactureDate,
            purchaseDate,
            withdrawalDate,
            administrator,
        } = this.state.equipment
        return materials.map(m => ({
            ...m,
            serialNumber,
            reference,
            manufacturerId,
            providerId,
            networkCode,
            manufactureDate,
            purchaseDate,
            withdrawalDate,
            administrator,
        }))
    }

    getEquipmentWithMateriels = () => {
        const {
            equipment,
            central,
            sensor,
            powerSupply,
            sim,
            telecom,
            variousMateriel,
        } = this.state
        const {
            centralIntegrated,
            sensorIntegrated,
            powerSupplyIntegrated,
            simIntegrated,
            telecomIntegrated,
            variousMaterielIntegrated,
        } = equipment
        return pickBy({
            equipment,
            central: centralIntegrated ? this.getEquipmentInformation(central) : [],
            sensor: sensorIntegrated ? this.getEquipmentInformation(sensor) : [],
            powerSupply: powerSupplyIntegrated ? this.getEquipmentInformation(powerSupply) : [],
            sim: simIntegrated ? this.getEquipmentInformation(sim) : [],
            telecom: telecomIntegrated ? this.getEquipmentInformation(telecom) : [],
            variousMateriel: variousMaterielIntegrated ? this.getEquipmentInformation(variousMateriel) : [],
        }, e => e)
    }

    refreshEquipment = () => {
        this.changeEditMode(false)
        this.fetchEquipment(this.props.match.params.id)
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.match.params.id === 'new' && (!this.props.equipment || !this.props.equipment.equipmentId)) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === EQUIPMENT_ID)
                        if (isSaveBlocked(this.props.equipments, this.state.equipment, rules, 'equipmentType')) return

                        this.props.createEquipment(this.getEquipmentWithMateriels()).then(id => {
                            if (id) {
                                this.refreshEquipment()
                                const situation = {
                                    idEquipment: id,
                                    situationDate: this.state.equipment.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.saveEquipmentSituation(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push('/materiel/equipment')
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === EQUIPMENT_ID)
                        if (isSaveBlocked(this.props.equipments, { ...this.state.equipment, id: this.state.equipment.equipmentId }, rules, 'equipmentType')) return

                        this.props.updateEquipment(this.getEquipmentWithMateriels()).then(success => success && this.refreshEquipment())
                    },
                    cancel: () => {
                        this.setState({ equipment: this.props.equipment })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                duplicate: () => this.setState({ isDuplicatePopinOpen: true }),
                delete: () => {
                    this.props.deleteEquipment(this.props.match.params.id)
                },
                replace: () => {
                    this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    changeAdministrator = administrator => this.setState(({ equipment }) => ({ equipment: { ...equipment, administrator } }))

    getRangeAndChannelValues = (central) => {
        const lastSituationOnSite = (() => {
            if (this.props.equipmentSituations.length) {
                const lastSituation = orderBy(this.props.equipmentSituations, 'id', 'desc')[0]
                if (lastSituation && lastSituation.statusCode === 1) {
                    return true
                }
            }
            return false
        })()
        if (lastSituationOnSite) {
            const centralChannels = central.centralChannels.length ? central.centralChannels : central.centralChannelsDefault
            return (
                <div>
                    <RangePanel
                        active={this.state.isEditMode}
                        centralRanges={this.props.centralRanges}
                    />
                    <ChannelPanel
                        central={central}
                        active={this.state.isEditMode}
                        centralChannels={centralChannels}
                        refreshDataFunction={() => this.fetchEquipment(this.props.match.params.id)}
                    />
                </div>)
        }
        return null
    }

    changeStateAttributeEquipment = (changes) => this.setState({ equipment: { ...this.state.equipment, ...changes } })

    changeStateAttributeMat = (mat, index, changes) => {
        const materiel = this.state[mat][index]
        const materielChange = { ...materiel, ...changes }
        const objectChange = this.state[mat].map((d, i) => index === i ? materielChange : d)
        this.setState({ [mat]: objectChange })
    }

    onDeleteMaterial = (mat, index) => {
        const newMat = [...this.state[mat].filter((_, i) => i !== index)]
        this.setState({ [mat]: newMat, equipment: { ...this.state.equipment, [`${mat}Integrated`]: !!newMat.length } })
    }

    getPanelCentral = (disabled, displayDesiccant) => this.state.equipment.centralIntegrated && (
        <div>
            {this.state.central.map((c, i) =>
                (<div className='row no-margin card'>
                    <div className={`col s12 card-title ${this.state.isEditMode ? 'active' : 'card-disabled'}`}>
                        <div className='col s9'>
                            {i18n.central}
                        </div>
                        <div className='col s3 text-align-right'>
                            <Icon icon={'reply'} onClick={() => this.props.push(`/materiel/central/${c.id}`)} />
                            {
                                this.state.isEditMode && <Icon icon={'close'} onClick={() => this.onDeleteMaterial('central', i)} />
                            }
                        </div>
                    </div>
                    <div className={'card-content margin-top-1'}>
                        <CentralEquipmentPanel
                            central={c}
                            onChange={changes => this.changeStateAttributeMat('central', i, changes)}
                            displayDesiccant={displayDesiccant}
                            {...disabled}
                        />
                        {this.getRangeAndChannelValues(c)}
                    </div>
                </div>)
            )}
        </div>
    )

    getPanelPowerSupply = disabled => this.state.equipment.powerSupplyIntegrated && (
        <div>
            {this.state.powerSupply.map((p, i) =>
                (<div className='row no-margin card'>
                    <div className={`col s12 card-title ${this.state.isEditMode ? 'active' : 'card-disabled'}`}>
                        <div className='col s9'>
                            {i18n.powerSupply}
                        </div>
                        <div className='col s3 text-align-right'>
                            <Icon icon={'reply'} onClick={() => this.props.push(`/materiel/powerSupply/${p.id}`)} />
                            {
                                this.state.isEditMode && <Icon icon={'close'} onClick={() => this.onDeleteMaterial('powerSupply', i)} />
                            }
                        </div>
                    </div>
                    <div className={'card-content margin-top-1'}>
                        <PowerSupplyEquipmentPanel
                            powerSupply={p}
                            onChange={changes => this.changeStateAttributeMat('powerSupply', i, changes)}
                            {...disabled}
                        />
                    </div>
                </div>)
            )}
        </div>
    )

    getPanelSensor = (disabled, displaySupply) => this.state.equipment.sensorIntegrated && (
        <div>
            {this.state.sensor.map((s, i) =>
                (<div className='row no-margin card'>
                    <div className={`col s12 card-title ${this.state.isEditMode ? 'active' : 'card-disabled'}`}>
                        <div className='col s9'>
                            {i18n.sensor}
                        </div>
                        <div className='col s3 text-align-right'>
                            <Icon icon={'reply'} onClick={() => this.props.push(`/materiel/sensor/${s.id}`)} />
                            {
                                this.state.isEditMode && <Icon icon={'close'} onClick={() => this.onDeleteMaterial('sensor', i)} />
                            }
                        </div>
                    </div>
                    <div className={'card-content margin-top-1'}>
                        <SensorEquipmentPanel
                            sensor={s}
                            displaySupply={displaySupply}
                            onChange={changes => this.changeStateAttributeMat('sensor', i, changes)}
                            {...disabled}
                        />
                    </div>
                </div>)
            )}
        </div>
    )

    getPanelSim = disabled => this.state.equipment.simIntegrated && (
        <div>
            {this.state.sim.map((s, i) =>
                (<div className='row no-margin card'>
                    <div className={`col s12 card-title ${this.state.isEditMode ? 'active' : 'card-disabled'}`}>
                        <div className='col s9'>
                            {i18n.sim}
                        </div>
                        <div className='col s3 text-align-right'>
                            <Icon icon={'reply'} onClick={() => this.props.push(`/materiel/sim/${s.id}`)} />
                            {
                                this.state.isEditMode && <Icon icon={'close'} onClick={() => this.onDeleteMaterial('sim', i)} />
                            }
                        </div>
                    </div>
                    <div className={'card-content margin-top-1'}>
                        <SimEquipmentPanel
                            sim={s}
                            onChange={changes => this.changeStateAttributeMat('sim', i, changes)}
                            {...disabled}
                        />
                    </div>
                </div>)
            )}
        </div>
    )

    getPanelTelecom = (disabled, displayDesiccant, displaySupply) => this.state.equipment.telecomIntegrated && (
        <div>
            {this.state.telecom.map((t, i) =>
                (<div className='row no-margin card'>
                    <div className={`col s12 card-title ${this.state.isEditMode ? 'active' : 'card-disabled'}`}>
                        <div className='col s9'>
                            {i18n.telecom}
                        </div>
                        <div className='col s3 text-align-right'>
                            <Icon icon={'reply'} onClick={() => this.props.push(`/materiel/telecom/${t.id}`)} />
                            {
                                this.state.isEditMode && <Icon icon={'close'} onClick={() => this.onDeleteMaterial('telecom', i)} />
                            }
                        </div>
                    </div>
                    <div className={'card-content margin-top-1'}>
                        <TelecomEquipmentPanel
                            telecom={t}
                            displaySupply={displaySupply}
                            displayDesiccant={displayDesiccant}
                            onChange={changes => this.changeStateAttributeMat('telecom', i, changes)}
                            {...disabled}
                        />
                    </div>
                </div>)
            )}
        </div>
    )

    getPanelVariousMateriel = disabled => this.state.equipment.variousMaterielIntegrated && (
        <div>
            {this.state.variousMateriel.map((v, i) =>
                (<div className='row no-margin card'>
                    <div className={`col s12 card-title ${this.state.isEditMode ? 'active' : 'card-disabled'}`}>
                        <div className='col s9'>
                            {i18n.variousMateriel}
                        </div>
                        <div className='col s3 text-align-right'>
                            <Icon icon={'reply'} onClick={() => this.props.push(`/materiel/variousMateriel/${v.id}`)} />
                            {
                                this.state.isEditMode && <Icon icon={'close'} onClick={() => this.onDeleteMaterial('variousMateriel', i)} />
                            }
                        </div>
                    </div>
                    <div className={'card-content margin-top-1'}>
                        <VariousMaterielEquipmentPanel
                            variousMateriel={v}
                            onChange={changes => this.changeStateAttributeMat('variousMateriel', i, changes)}
                            {...disabled}
                        />
                    </div>
                </div>)
            )}
        </div>
    )

    deleteSituation = id => this.props.deleteEquipmentSituation(id, this.props.match.params.id)

    getMaterielNumber = ({ serialNumber, reference }) => serialNumber || reference || ''

    getEquipmentsFormat = () => {
        const { equipments, equipmentTypes } = this.props
        return equipments.map(equipment => {
            const label = (() => {
                const number = this.getMaterielNumber(equipment)
                if (equipment.equipmentType) {
                    const type = equipmentTypes.find(t => t.id === equipment.equipmentType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: equipment.id,
                label,
            }
        })
    }

    onCloseDuplicatePopin = () => this.setState({ isDuplicatePopinOpen: false, equipmentNumber: 1 })

    onDuplicate = () => {
        this.props.duplicateEquipment(this.props.match.params.id, this.state.equipmentNumber).then(this.onCloseDuplicatePopin)
    }

    render() {
        const {
            isOpen,
            isDuplicatePopinOpen,
            equipmentNumber,
        } = this.state
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }
        const actions = [
            {
                iconName: 'add',
                onClick: () => this.setState({ isOpen: true }),
            },
        ]
        const displaySupply = !this.state.central.length
        const displayDesiccant = !this.state.sensor.length

        const materialType = [
            hasHabilitation(MAT_CENTRALES, false) && 'central',
            hasHabilitation(MAT_BATTERIES, false) && 'powerSupply',
            hasHabilitation(MAT_CAPTEURS, false) && 'sensor',
            hasHabilitation(MAT_SIMS, false) && 'sim',
            hasHabilitation(MAT_TELECOMS, false) && 'telecom',
            hasHabilitation(MAT_DIVERS, false) && 'variousMateriel',
        ].filter(t => !!t)
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel materielSituation={this.props.equipmentSituations} />
                                    </div>
                                    <div className='col s11'>
                                        <div className='row no-margin padding-top-3-px'>
                                            <EquipmentPanel id={this.props.match.params.id}
                                                equipment={this.state.equipment}
                                                onChange={changes => this.changeStateAttributeEquipment(changes)}
                                                onChangeState={({ equipment: newEquipment, central, powerSupply, sensor, sim, telecom, variousMateriel }) => this.setState(({ equipment }) => ({
                                                    equipment: {
                                                        ...equipment,
                                                        ...newEquipment,
                                                    },
                                                    central,
                                                    powerSupply,
                                                    sensor,
                                                    sim,
                                                    telecom,
                                                    variousMateriel,
                                                }))}
                                                {...disabled}
                                            />
                                        </div>
                                        <div className='row no-margin padding-top-3-px'>
                                            <div className='col s12'>
                                                <SituationPanel
                                                    materielSituation={this.props.equipmentSituations}
                                                    active={this.state.isEditMode}
                                                    idMateriel={this.props.equipment.equipmentId}
                                                    serialNumber={this.props.equipment.serialNumber}
                                                    purchaseDate={this.props.equipment.purchaseDate}
                                                    idKey={'idEquipment'}
                                                    lastSituations={this.props.equipmentsLastSituations}
                                                    materials={this.getEquipmentsFormat()}
                                                    saveFunction={this.props.saveEquipmentSituation}
                                                    deleteFunction={this.deleteSituation}
                                                />
                                            </div>
                                        </div>
                                        <Card title={i18n.materiels} active={this.state.isEditMode} actions={actions}>
                                            <div className='card-content margin-top-1'>
                                                {this.getPanelCentral(disabled, displayDesiccant)}
                                                {this.getPanelPowerSupply(disabled)}
                                                {this.getPanelSensor(disabled, displaySupply)}
                                                {this.getPanelSim(disabled)}
                                                {this.getPanelTelecom(disabled, displayDesiccant, displaySupply)}
                                                {this.getPanelVariousMateriel(disabled)}
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.state.equipment.updateDate}
                                                updateLogin={this.state.equipment.updateLogin}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content no-padding'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.equipment.administrator}
                                                        isActive={this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel materielSituation={this.props.equipmentSituations} />
                                            <SmallPicturePanel
                                                element={'equipment'}
                                                pictures={this.props.equipmentPictures}
                                            />
                                            <FilePanel files={this.props.equipmentFiles} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    onClose={() => this.setState({ isOpen: false })}
                    fullWidth
                    maxWidth='md'
                    open={isOpen}
                >
                    <DialogTitle>{materialType.length ? i18n.pleaseChooseMaterielType : i18n.noTypeOfMaterialAvailable}</DialogTitle>
                    <DialogContent>
                        <div style={{ padding: '20px' }}>
                            {materialType.map(type => (
                                <div className='padding-bottom-2'>
                                    <div className='col s12 chip blue white-text flex justify-center align-center clickable'
                                        onClick={() => {
                                            const newMat = type === 'central' ? { centralChannels: [], centralChannelsDefault: [] } : {}
                                            this.setState({
                                                [type]: [...this.state[type], newMat],
                                                equipment: { ...this.state.equipment, [`${type}Integrated`]: true },
                                                isOpen: false,
                                            })
                                        }}
                                    >
                                        <Icon icon={getMaterielIconValue(type)} />
                                        <h5 className='no-margin align-center' style={{ paddingLeft: '1%' }}>
                                            {i18n[type]}
                                        </h5>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={this.onCloseDuplicatePopin}
                    maxWidth='md'
                    open={isDuplicatePopinOpen}
                >
                    <DialogTitle>{i18n.howMuchEquipmentDoYouWantToCreate}</DialogTitle>
                    <DialogContent>
                        <div style={{ padding: '20px' }}>
                            <NumberField
                                col={6}
                                value={equipmentNumber}
                                min={1}
                                onChange={v => this.setState({ equipmentNumber: v })}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onCloseDuplicatePopin} variant='outlined' >
                            {i18n.cancel}
                        </Button>
                        <Button onClick={this.onDuplicate} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

EquipmentApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
    equipment: PropTypes.instanceOf(EquipmentDto),
    equipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    equipmentsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipmentSituation)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    getLink: PropTypes.func,
    resetAllActions: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    equipmentSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipmentSituation)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    equipmentPictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    equipmentFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    centralRanges: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralRange)),
    centralChannels: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralChannel)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    equipment: store.EquipmentReducer.equipment,
    equipments: store.EquipmentReducer.equipments,
    equipmentsLastSituations: store.EquipmentReducer.equipmentsLastSituations,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    equipmentSituations: store.EquipmentReducer.equipmentSituations,
    materielStates: store.MaterielReducer.materielStates,
    contributors: store.ContributorReducer.contributors,
    equipmentPictures: store.EquipmentReducer.equipmentPictures,
    equipmentFiles: store.EquipmentReducer.equipmentFiles,
    accountUser: store.AccountReducer.accountUser,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProps = {
    push,
    resetEquipment: EquipmentAction.resetEquipment,
    fetchEquipment: EquipmentAction.fetchEquipment,
    fetchEquipments: EquipmentAction.fetchEquipments,
    fetchEquipmentTypes: EquipmentAction.fetchEquipmentTypes,
    fetchCentralTypes: CentralAction.fetchCentralTypes,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    fetchSensorTypes: SensorAction.fetchSensorTypes,
    fetchSimTypes: SimAction.fetchSimTypes,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    forceFetch: SieauAction.forceFetch,
    createEquipment: EquipmentAction.createEquipment,
    duplicateEquipment: EquipmentAction.duplicateEquipment,
    updateEquipment: EquipmentAction.updateEquipment,
    deleteEquipment: EquipmentAction.deleteEquipment,
    deleteEquipmentSituation: EquipmentAction.deleteEquipmentSituation,
    toastrInfo: ToastrAction.info,
    fetchEquipmentSituations: EquipmentAction.fetchEquipmentSituations,
    fetchEquipmentPictures: EquipmentAction.fetchEquipmentPictures,
    fetchEquipmentFiles: EquipmentAction.fetchEquipmentFiles,
    fetchCentralSituations: CentralAction.fetchCentralSituations,
    fetchCentralChannels: CentralAction.fetchCentralChannels,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    saveEquipmentSituation: EquipmentAction.saveEquipmentSituation,
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentApp)
