/* eslint-disable camelcase */
import damStation from 'assets/pictures/markers/barrage.png'
import seaport from 'assets/pictures/markers/mapmarker_blue_default.gif'
import treatment from 'assets/pictures/markers/mapmarker_treatment_station.gif'
import notGood from 'assets/pictures/markers/map_arrow_down_yellow.png'
import good from 'assets/pictures/markers/map_arrow_right_green.png'
import veryGood from 'assets/pictures/markers/map_arrow_up_blue.png'
import map_contact from 'assets/pictures/markers/map_contact.png'
import hydro from 'assets/pictures/markers/map_hydro.png'
import installation_assainissement from 'assets/pictures/markers/map_installation_assainissement.png'
import installation_capture from 'assets/pictures/markers/map_installation_captages.png'
import installation_cavity from 'assets/pictures/markers/map_installation_cavity.png'
import installation from 'assets/pictures/markers/map_installation_defaut.png'
import installation_fish from 'assets/pictures/markers/map_installation_fishing_spot.png'
import installation_forage from 'assets/pictures/markers/map_installation_forage.png'
import installation_obstacle from 'assets/pictures/markers/map_installation_obstacle.png'
import installation_lifting from 'assets/pictures/markers/map_installation_poste_relevage.png'
import installation_treatment from 'assets/pictures/markers/map_installation_poste_traitement.png'
import installation_sample from 'assets/pictures/markers/map_installation_prelevements.png'
import installation_production from 'assets/pictures/markers/map_installation_production_aep.png'
import installation_tank from 'assets/pictures/markers/map_installation_reservoir.png'
import installation_site_industriel from 'assets/pictures/markers/map_installation_site_industriel.png'
import installation_sols from 'assets/pictures/markers/map_installation_sols.png'
import piezometer from 'assets/pictures/markers/map_piezo.png'
import piezometer_green from 'assets/pictures/markers/map_piezo_green.png'
import piezometer_orange from 'assets/pictures/markers/map_piezo_orange.png'
import piezometer_red from 'assets/pictures/markers/map_piezo_red.png'
import piezometer_white from 'assets/pictures/markers/map_piezo_white.png'
import pluviometer from 'assets/pictures/markers/map_pluvio.png'
import map_point_gray from 'assets/pictures/markers/map_point_gray.png'
import map_point_green from 'assets/pictures/markers/map_point_green.png'
import map_point_orange from 'assets/pictures/markers/map_point_orange.png'
import map_point_red from 'assets/pictures/markers/map_point_red.png'
import qualito from 'assets/pictures/markers/map_qualite.png'
import qualito_ac from 'assets/pictures/markers/map_qualite_ac.png'
import qualito_blue from 'assets/pictures/markers/map_qualite_blue.png'
import qualito_c from 'assets/pictures/markers/map_qualite_c.png'
import qualito_ed from 'assets/pictures/markers/map_qualite_ed.png'
import qualito_es from 'assets/pictures/markers/map_qualite_es.png'
import qualito_et from 'assets/pictures/markers/map_qualite_et.png'
import qualito_gc from 'assets/pictures/markers/map_qualite_gc.png'
import qualito_green from 'assets/pictures/markers/map_qualite_green.png'
import qualito_m from 'assets/pictures/markers/map_qualite_m.png'
import qualito_orange from 'assets/pictures/markers/map_qualite_orange.png'
import qualito_pe from 'assets/pictures/markers/map_qualite_pe.png'
import qualito_red from 'assets/pictures/markers/map_qualite_red.png'
import qualito_s from 'assets/pictures/markers/map_qualite_s.png'
import undefinedStation from 'assets/pictures/markers/map_qualite_white.png'
import qualito_yellow from 'assets/pictures/markers/map_qualite_yellow.png'
import samplePoint from 'assets/pictures/markers/map_samplePoint.png'
import samplePoint_ac from 'assets/pictures/markers/map_samplePoint_ac.png'
import samplePoint_c from 'assets/pictures/markers/map_samplePoint_c.png'
import samplePoint_ed from 'assets/pictures/markers/map_samplePoint_ed.png'
import samplePoint_es from 'assets/pictures/markers/map_samplePoint_es.png'
import samplePoint_et from 'assets/pictures/markers/map_samplePoint_et.png'
import samplePoint_gc from 'assets/pictures/markers/map_samplePoint_gc.png'
import samplePoint_m from 'assets/pictures/markers/map_samplePoint_m.png'
import samplePoint_pe from 'assets/pictures/markers/map_samplePoint_pe.png'
import samplePoint_s from 'assets/pictures/markers/map_samplePoint_s.png'
import industrialSite from 'assets/pictures/markers/map_sites.png'
import pollutedSoil from 'assets/pictures/markers/map_sols.png'
import unit from 'assets/pictures/markers/map_unites.png'
import productionFactory from 'assets/pictures/markers/usine.png'
import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import AppStore from 'store/AppStore'
import LogAction from '../../log/actions/LogAction'
import { STATION_NAME_ASSOCIATION, STATION_TYPE } from '../../station/constants/StationConstants'
import { hasValue } from '../NumberUtil'


const createIconStyle = (imgsrc, scale, text) => {
    const textObj = hasValue(text) ? new Text({
        text,
        offsetY: -35,
        font: 'bold 22px Arial',
        scale,
    }) : null
    if (imgsrc.startsWith('<svg')) {
        return new Style({
            image: new Icon(({
                scale,
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: `data:image/svg+xml;utf8,${imgsrc}`,
            })),
            text: textObj,
        })
    }
    return new Style({
        image: new Icon(({
            scale,
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: imgsrc,
        })),
        text: textObj,
    })
}

const typeList = {
    QUALITOMETER_WHITE: {
        type: 'QUALITOMETER_WHITE',
        img: qualito,
        name: 'Qualitomètre',
        url: 'pictures/markers/map_qualite.png',
        fileName: 'map_qualite.png',
    },
    QUALITOMETER_RED: {
        type: 'QUALITOMETER_RED',
        img: qualito_red,
        name: 'Qualitomètre',
        url: 'pictures/markers/map_qualite_red.png',
        fileName: 'map_qualite_red.png',
    },
    QUALITOMETER_ORANGE: {
        type: 'QUALITOMETER_ORANGE',
        img: qualito_orange,
        name: 'Qualitomètre',
        url: 'pictures/markers/map_qualite_orange.png',
        fileName: 'map_qualite_orange.png',
    },
    QUALITOMETER_BLUE: {
        type: 'QUALITOMETER_BLUE',
        img: qualito_blue,
        name: 'Qualitomètre',
        url: 'pictures/markers/map_qualite_blue.png',
        fileName: 'map_qualite_blue.png',
    },
    QUALITOMETER_YELLOW: {
        type: 'QUALITOMETER_YELLOW',
        img: qualito_yellow,
        name: 'Qualitomètre',
        url: 'pictures/markers/map_qualite_yellow.png',
        fileName: 'map_qualite_yellow.png',
    },
    QUALITOMETER_GREEN: {
        type: 'QUALITOMETER_GREEN',
        img: qualito_green,
        name: 'Qualitomètre',
        url: 'pictures/markers/map_qualite_green.png',
        fileName: 'map_qualite_green.png',
    },
    QUALITOMETER: {
        type: 'QUALITOMETER',
        img: qualito,
        name: 'Qualitomètre',
        url: 'pictures/markers/map_qualite.png',
        fileName: 'map_qualite.png',
    },
    SAMPLE_POINT: {
        type: 'SAMPLE_POINT',
        img: samplePoint,
        name: 'Point de prélèvement',
        url: 'pictures/markers/map_samplePoint.png',
        fileName: 'map_samplePoint.png',
    },
    QUALITOMETER_SURFACE: {
        type: 'QUALITOMETER_SURFACE',
        img: qualito,
        name: 'Qualitomètre de surface',
        url: 'pictures/markers/map_qualite.png',
        fileName: 'map_qualite.png',
    },
    UNIT: {
        type: 'UNIT',
        img: unit,
        name: 'Unité',
        url: 'pictures/markers/map_unites.png',
        fileName: 'map_unites.png',
    },
    PRODUCTION_UNIT: {
        type: 'PRODUCTION_UNIT',
        img: unit,
        name: 'Unité de production',
        url: 'pictures/markers/map_unites.png',
        fileName: 'map_unites.png',
    },
    DISTRIBUTION_UNIT: {
        type: 'DISTRIBUTION_UNIT',
        img: unit,
        name: 'Unité de distribution',
        url: 'pictures/markers/map_unites_distribution.png',
        fileName: 'map_unites_distribution.png',
    },
    INSTALLATION: {
        type: 'INSTALLATION',
        img: installation,
        name: 'Installation',
        url: 'pictures/markers/map_installation_defaut.png',
        fileName: 'map_installations.png',
    },
    INSTALLATION_OTHERS: {
        type: 'INSTALLATION',
        img: installation,
        name: 'Installation',
        url: 'pictures/markers/map_installation_defaut.png',
        fileName: 'map_installations.png',
    },
    INSTALLATION_BOREHOLE: {
        type: 'INSTALLATION_BOREHOLE',
        img: installation_forage,
        name: 'Forage',
        url: 'pictures/markers/map_installation_forage.png',
        fileName: 'map_installation_forage.png',
    },
    INSTALLATION_INDUSTRIAL_SITE: {
        type: 'INSTALLATION_INDUSTRIAL_SITE',
        img: installation_site_industriel,
        name: 'Site industriel',
        url: 'pictures/markers/map_installation_site_industriel.png',
        fileName: 'map_installation_site_industriel.png',
    },
    INSTALLATION_STEP: {
        type: 'INSTALLATION_STEP',
        img: installation_assainissement,
        name: 'STEP',
        url: 'pictures/markers/map_installation_assainissement.png',
        fileName: 'map_installation_assainissement.png',
    },
    INSTALLATION_FLOW_OBSTRUCTION: {
        type: 'INSTALLATION_FLOW_OBSTRUCTION',
        img: installation_obstacle,
        name: 'Obstacle à l\'écoulement',
        url: 'pictures/markers/map_installation_obstacle.png',
        fileName: 'map_installation_obstacle.png',
    },
    INSTALLATION_POLLUTED_SOIL: {
        type: 'INSTALLATION_POLLUTED_SOIL',
        img: installation_sols,
        name: 'Sol pollué',
        url: 'pictures/markers/map_installation_sols.png',
        fileName: 'map_installation_sols.png',
    },
    INSTALLATION_CAVITY: {
        type: 'INSTALLATION_CAVITY',
        img: installation_cavity,
        name: 'Cavité',
        url: 'pictures/markers/map_installation_cavity.png',
        fileName: 'map_installation_cavity.png',
    },
    INSTALLATION_FISHING_SPOT: {
        type: 'INSTALLATION_FISHING_SPOT',
        img: installation_fish,
        name: 'Lieu de pêche',
        url: 'pictures/markers/map_installation_fishing_spot.png',
        fileName: 'map_installation_fishing_spot.png',
    },
    INSTALLATION_SAMPLE_WORK: {
        type: 'INSTALLATION_SAMPLE_WORK',
        img: installation_sample,
        name: 'Ouvrage de prélèvement',
        url: 'pictures/markers/map_installation_prelevements.png',
        fileName: 'map_installation_prelevements.png',
    },
    INSTALLATION_CAPTURE: {
        type: 'INSTALLATION_CAPTURE',
        img: installation_capture,
        name: 'Poste de relevage',
        url: 'pictures/markers/map_installation_captages.png',
        fileName: 'map_installation_captages.png',
    },
    INSTALLATION_LIFTING_STATION: {
        type: 'INSTALLATION_LIFTING_STATION',
        img: installation_lifting,
        name: 'Poste de relevage',
        url: 'pictures/markers/map_installation_poste_relevage.png',
        fileName: 'map_installation_poste_relevage.png',
    },
    INSTALLATION_TREATMENT: {
        type: 'INSTALLATION_TREATMENT',
        img: installation_treatment,
        name: 'Station de traitement',
        url: 'pictures/markers/map_installation_poste_traitement.png',
        fileName: 'map_installation_poste_traitement.png',
    },
    INSTALLATION_PRODUCTION: {
        type: 'INSTALLATION_PRODUCTION',
        img: installation_production,
        name: 'Productuions aep',
        url: 'pictures/markers/map_installation_production_aep.png',
        fileName: 'map_installation_production_aep.png',
    },
    INSTALLATION_PUMPING: {
        type: 'INSTALLATION_PUMPING',
        img: installation_sample,
        name: 'Ouvrage de prélèvement',
        url: 'pictures/markers/map_installation_prelevements.png',
        fileName: 'map_installation_prelevements.png',
    },
    INSTALLATION_TANK: {
        type: 'INSTALLATION_TANK',
        img: installation_tank,
        name: 'Réservoir',
        url: 'pictures/markers/map_installation_reservoir.png',
        fileName: 'map_installation_reservoir.png',
    },
    INSTALLATION_AGRI_PREL: {
        type: 'INSTALLATION_AGRI_PREL',
        img: installation_sample,
        name: 'Point de prélèvement',
        url: 'pictures/markers/map_installation_prelevements.png',
        fileName: 'map_installation_prelevements.png',
    },
    INSTALLATION_SECTOR_COUNT: {
        type: 'INSTALLATION',
        img: installation,
        name: 'Installation',
        url: 'pictures/markers/map_installation_defaut.png',
        fileName: 'map_installations.png',
    },
    INSTALLATION_ELEC_COUNTER: {
        type: 'INSTALLATION',
        img: installation,
        name: 'Installation',
        url: 'pictures/markers/map_installation_defaut.png',
        fileName: 'map_installations.png',
    },
    INSTALLATION_STRAIT: {
        type: 'INSTALLATION',
        img: installation,
        name: 'Installation',
        url: 'pictures/markers/map_installation_pertuis.png',
        fileName: 'map_installations.png',
    },
    INSTALLATION_BRIDGE: {
        type: 'INSTALLATION',
        img: installation,
        name: 'Installation',
        url: 'pictures/markers/map_installation_pont.png',
        fileName: 'map_installations.png',
    },
    INSTALLATION_SLUICE: {
        type: 'INSTALLATION',
        img: installation,
        name: 'Installation',
        url: 'pictures/markers/map_installation_ecluse.png',
        fileName: 'map_installations.png',
    },
    PLUVIOMETER: {
        type: 'PLUVIOMETER',
        img: pluviometer,
        name: 'Pluviomètre',
        url: 'pictures/markers/map_pluvio.png',
        fileName: 'map_pluvio.png',
    },
    PIEZOMETER: {
        type: 'PIEZOMETER',
        img: piezometer,
        name: 'Piézomètre',
        url: 'pictures/markers/map_piezo.png',
        fileName: 'map_piezo.png',
    },
    PIEZOMETER_WHITE: {
        type: 'PIEZOMETER_WHITE',
        img: piezometer_white,
        name: 'Piézomètre',
        url: 'pictures/markers/map_piezo_white.png',
        fileName: 'map_piezo_white.png',
    },
    PIEZOMETER_RED: {
        type: 'PIEZOMETER_RED',
        img: piezometer_red,
        name: 'Piézomètre',
        url: 'pictures/markers/map_piezo_red.png',
        fileName: 'map_piezo_red.png',
    },
    PIEZOMETER_GREEN: {
        type: 'PIEZOMETER_GREEN',
        img: piezometer_green,
        name: 'Piézomètre',
        url: 'pictures/markers/map_piezo_green.png',
        fileName: 'map_piezo_green.png',
    },
    PIEZOMETER_ORANGE: {
        type: 'PIEZOMETER_ORANGE',
        img: piezometer_orange,
        name: 'Piézomètre',
        url: 'pictures/markers/map_piezo_orange.png',
        fileName: 'map_piezo_orange.png',
    },
    HYDROMETRIC_STATION: {
        type: 'HYDROMETRIC_STATION',
        img: hydro,
        name: 'Station hydrométrique',
        url: 'pictures/markers/map_hydro.png',
        fileName: 'map_hydro.png',
    },
    HYDROMETRIC_STATION_GREEN: {
        type: 'HYDROMETRIC_STATION_GREEN',
        img: qualito_green,
        name: 'Station hydrométrique',
        url: 'pictures/markers/map_qualite_green.png',
        fileName: 'map_qualite_green.png',
    },
    HYDROMETRIC_STATION_ORANGE: {
        type: 'HYDROMETRIC_STATION_ORANGE',
        img: qualito_orange,
        name: 'Station hydrométrique',
        url: 'pictures/markers/map_qualite_orange.png',
        fileName: 'map_qualite_orange.png',
    },
    HYDROMETRIC_STATION_RED: {
        type: 'HYDROMETRIC_STATION_RED',
        img: qualito_red,
        name: 'Station hydrométrique',
        url: 'pictures/markers/map_qualite_red.png',
        fileName: 'map_qualite_red.png',
    },
    HYDROMETRIC_STATION_WHITE: {
        type: 'HYDROMETRIC_STATION_WHITE',
        img: undefinedStation,
        name: 'Station hydrométrique',
        url: 'pictures/markers/map_qualite_white.png',
        fileName: 'map_qualite_white.png',
    },
    SEAPORT: {
        type: 'SEAPORT',
        img: seaport,
        name: 'Marégraphes',
        url: 'pictures/markers/mapmarker_blue_default.gif',
        fileName: 'mapmarker_blue_default.gif',
    },
    INDUSTRIAL_SITE: {
        type: 'INDUSTRIAL_SITE',
        img: industrialSite,
        name: 'Site industriel',
        url: 'pictures/markers/map_sites.png',
        fileName: 'map_sites.png',
    },
    OBSTACLE_FLOW: {
        type: 'OBSTACLE_FLOW',
        img: damStation,
        name: 'Obstacle à l\'écoulement',
        url: 'pictures/markers/barrage.png',
        fileName: 'barrage.png',
    },
    TREATMENT_STATION: {
        type: 'TREATMENT_STATION',
        img: treatment,
        name: 'Station d\'épuration',
        url: 'pictures/markers/mapmarker_treatment_station.gif',
        fileName: 'mapmarker_treatment_station.gif',
    },
    POLLUTED_SOIL: {
        type: 'POLLUTED_SOIL',
        img: pollutedSoil,
        name: 'Sols pollués',
        url: 'pictures/markers/map_sols.png',
        fileName: 'map_sols.png',
    },
    VERY_GOOD: {
        type: 'VERY_GOOD',
        img: veryGood,
        name: 'En hausse',
        url: 'pictures/markers/map_arrow_up_blue.png',
        fileName: 'map_arrow_up_blue.png',
    },
    GOOD: {
        type: 'GOOD',
        img: good,
        name: 'En hausse',
        url: 'pictures/markers/map_arrow_right_green.png',
        fileName: 'map_arrow_right_green.png',
    },
    NOT_GOOD: {
        type: 'NOT_GOOD',
        img: notGood,
        name: 'En baisse',
        url: 'pictures/markers/map_arrow_down_yellow.png',
        fileName: 'map_arrow_down_yellow.png',
    },
    PRODUCTION_FACTORY: {
        type: 'PRODUCTION_FACTORY',
        img: productionFactory,
        name: 'En baisse',
        url: 'pictures/markers/usine.png',
        fileName: 'usine.png',
    },
    DAM: {
        type: 'DAM',
        img: damStation,
        name: 'En baisse',
        url: 'pictures/markers/barrage.png',
        fileName: 'barrage.png',
    },
    UNDEFINED: {
        type: 'UNDEFINED',
        img: undefinedStation,
        name: 'Site associé',
        url: 'pictures/markers/map_qualite_white.png',
        fileName: 'map_qualite_white.png',
    },
    CITY: {
        type: 'CITY',
        img: undefinedStation,
        name: 'Commune',
        url: 'pictures/markers/map_qualite_white.png',
        fileName: 'map_qualite_white.png',
    },
    POINT_RED: {
        type: 'POINT_RED',
        img: map_point_red,
        name: 'Point rouge',
        url: 'pictures/markers/map_point_red.png',
        fileName: 'map_point_red.png',
    },
    POINT_ORANGE: {
        type: 'POINT_ORANGE',
        img: map_point_orange,
        name: 'Point orange',
        url: 'pictures/markers/map_point_orange.png',
        fileName: 'map_point_orange.png',
    },
    POINT_GREEN: {
        type: 'POINT_GREEN',
        img: map_point_green,
        name: 'Point vert',
        url: 'pictures/markers/map_point_green.png',
        fileName: 'map_point_green.png',
    },
    POINT_GRAY: {
        type: 'POINT_GRAY',
        img: map_point_gray,
        name: 'Point gris',
        url: 'pictures/markers/map_point_gray.png',
        fileName: 'map_point_gray.png',
    },
    QUALITOMETER_POINT_EAU_SOUTERRAINE: {
        type: 'QUALITOMETER_POINT_EAU_SOUTERRAINE',
        img: qualito_es,
        name: 'Qualitomètre eau souterraine',
        url: 'pictures/markers/map_qualite_es.png',
        filename: 'map_qualite_es.png',
    },
    QUALITOMETER_COURS_D_EAU: {
        type: 'QUALITOMETER_COURS_D_EAU',
        img: qualito_c,
        name: 'Qualitomètre cours d\'eau',
        url: 'pictures/markers/map_qualite_c.png',
        filename: 'map_qualite_c.png',
    },
    QUALITOMETER_PLAN_D_EAU: {
        type: 'QUALITOMETER_PLAN_D_EAU',
        img: qualito_pe,
        name: 'Qualitomètre plan d\'eau',
        url: 'pictures/markers/map_qualite_pe.png',
        filename: 'map_qualite_pe.png',
    },
    QUALITOMETER_GRAND_COURS_D_EAU: {
        type: 'QUALITOMETER_GRAND_COURS_D_EAU',
        img: qualito_gc,
        name: 'Qualitomètre grand cours d\'eau',
        url: 'pictures/markers/map_qualite_gc.png',
        filename: 'map_qualite_gc.png',
    },
    QUALITOMETER_MILIEU_MARIN: {
        type: 'QUALITOMETER_MILIEU_MARIN',
        img: qualito_m,
        name: 'Qualitomètre milieau marin',
        url: 'pictures/markers/map_qualite_m.png',
        filename: 'map_qualite_m.png',
    },
    QUALITOMETER_AUTRE_COURS_D_EAU: {
        type: 'QUALITOMETER_AUTRE_COURS_D_EAU',
        img: qualito_ac,
        name: 'Qualitomètre autre cours d\'eau',
        url: 'pictures/markers/map_qualite_ac.png',
        filename: 'map_qualite_ac.png',
    },
    QUALITOMETER_SOURCE: {
        type: 'QUALITOMETER_SOURCE',
        img: qualito_s,
        name: 'Qualitomètre source',
        url: 'pictures/markers/map_qualite_s.png',
        filename: 'map_qualite_s.png',
    },
    QUALITOMETER_EAU_DISTRIBUEE: {
        type: 'QUALITOMETER_EAU_DISTRIBUEE',
        img: qualito_ed,
        name: 'Qualitomètre eau distribuée',
        url: 'pictures/markers/map_qualite_ed.png',
        filename: 'map_qualite_ed.png',
    },
    QUALITOMETER_EAU_TRAITEE: {
        type: 'QUALITOMETER_EAU_TRAITEE',
        img: qualito_et,
        name: 'Qualitomètre eau traitée',
        url: 'pictures/markers/map_qualite_et.png',
        filename: 'map_qualite_et.png',
    },
    SAMPLE_POINT_POINT_EAU_SOUTERRAINE: {
        type: 'SAMPLE_POINT_POINT_EAU_SOUTERRAINE',
        img: samplePoint_es,
        name: 'Point de prélevement eau souterraine',
        url: 'pictures/markers/map_samplePoint_es.png',
        filename: 'map_samplePoint_es.png',
    },
    SAMPLE_POINT_COURS_D_EAU: {
        type: 'SAMPLE_POINT_COURS_D_EAU',
        img: samplePoint_c,
        name: 'Point de prélevement cours d\'eau',
        url: 'pictures/markers/map_samplePoint_c.png',
        filename: 'map_samplePoint_c.png',
    },
    SAMPLE_POINT_PLAN_D_EAU: {
        type: 'SAMPLE_POINT_PLAN_D_EAU',
        img: samplePoint_pe,
        name: 'Point de prélevement plan d\'eau',
        url: 'pictures/markers/map_samplePoint_pe.png',
        filename: 'map_samplePoint_pe.png',
    },
    SAMPLE_POINT_GRAND_COURS_D_EAU: {
        type: 'SAMPLE_POINT_GRAND_COURS_D_EAU',
        img: samplePoint_gc,
        name: 'Point de prélevement grand cours d\'eau',
        url: 'pictures/markers/map_samplePoint_gc.png',
        filename: 'map_samplePoint_gc.png',
    },
    SAMPLE_POINT_MILIEU_MARIN: {
        type: 'SAMPLE_POINT_MILIEU_MARIN',
        img: samplePoint_m,
        name: 'Point de prélevement milieau marin',
        url: 'pictures/markers/map_samplePoint_m.png',
        filename: 'map_qualite_m.png',
    },
    SAMPLE_POINT_AUTRE_COURS_D_EAU: {
        type: 'SAMPLE_POINT_AUTRE_COURS_D_EAU',
        img: samplePoint_ac,
        name: 'Point de prélevement autre cours d\'eau',
        url: 'pictures/markers/map_samplePoint_ac.png',
        filename: 'map_samplePoint_ac.png',
    },
    SAMPLE_POINT_SOURCE: {
        type: 'SAMPLE_POINT_SOURCE',
        img: samplePoint_s,
        name: 'Point de prélevement source',
        url: 'pictures/markers/map_samplePoint_s.png',
        filename: 'map_samplePoint_s.png',
    },
    SAMPLE_POINT_EAU_DISTRIBUEE: {
        type: 'SAMPLE_POINT_EAU_DISTRIBUEE',
        img: samplePoint_ed,
        name: 'Point de prélevement eau distribuée',
        url: 'pictures/markers/map_samplePoint_ed.png',
        filename: 'map_samplePoint_ed.png',
    },
    SAMPLE_POINT_EAU_TRAITEE: {
        type: 'SAMPLE_POINT_EAU_TRAITEE',
        img: samplePoint_et,
        name: 'Point de prélevement eau traitée',
        url: 'pictures/markers/map_samplePoint_et.png',
        filename: 'map_samplePoint_et.png',
    },
    CONTACT: {
        type: 'CONTACT',
        img: map_contact,
        name: 'Contact',
        url: 'pictures/markers/map_contact.png',
        filename: 'map_contact.png',
    },
    CONTRIBUTOR: {
        type: 'CONTRIBUTOR',
        img: map_contact,
        name: 'Intervenant',
        url: 'pictures/markers/map_contact.png',
        filename: 'map_contact.png',
    },
    RESOURCE: {
        type: 'RESOURCE',
        img: map_contact,
        name: 'Resource',
        url: 'pictures/markers/map_point_green.png',
        filename: 'map_point_green.png',
    },
}

const getMapPointsScale = (numberStations) => {
    if (numberStations && numberStations === 1) {
        return 1
    }
    if (!numberStations || numberStations < 40) {
        return 0.7
    }
    return 0.5
}

const getSiteType = (typeName, numberStations, text, scale) => {
    const size = scale || getMapPointsScale(numberStations)
    if (!typeList[typeName]) {
        AppStore.dispatch(LogAction.logInfo(`cannot find station typeName : ${typeName}`))
        return Object.assign({}, typeList.UNDEFINED, {
            img: createIconStyle(typeList.UNDEFINED.img, size, text),
        })
    }
    let state = AppStore.getState()
    const reducer = state.AdministrationReducer
    const ftpPictos = reducer.pictos
    const foundPicto = ftpPictos.find(picto => picto.name === typeList[typeName].fileName)
    return Object.assign({}, typeList[typeName], {
        img: createIconStyle(foundPicto ? foundPicto.url : typeList[typeName].img, typeName === 'PRODUCTION_UNIT' && size <= 0.8 ? size + 0.2 : size, text),
    })
}

const getIconType = (station) => {
    if (station.type) {
        const icon = typeList[station.type]
        return icon ? icon.url : null
    }
    return null
}

const getSiteName = (typeName) => {
    if (!typeList[typeName]) {
        AppStore.dispatch(LogAction.logInfo(`cannot find station typeName : ${typeName}`))
        return typeName
    }
    return typeList[typeName].name
}

const getSiteUrl = (typeName) => {
    if (!typeList[typeName]) {
        AppStore.dispatch(LogAction.logInfo(`cannot find station typeName : ${typeName}`))
        return 'assets/pictures/markers/map_qualite_white.png'
    }
    const ftpPictos = AppStore.getState().AdministrationReducer.pictos
    const foundPicto = ftpPictos.find(picto => picto.name === typeList[typeName].fileName)
    return foundPicto ? foundPicto.url : typeList[typeName].url
}

const getImgQuality = (stationType) => {
    const foundType = Object.keys(STATION_TYPE).find(t => STATION_TYPE[t] == stationType)
    const imgObj = typeList[`QUALITOMETER_${foundType}`]
    return imgObj?.url && imgObj || typeList.QUALITOMETER
}

const getImgInstallation = (installationType) => {
    const installationKey = Object.keys(INSTALLATION_TYPE).find(key => (INSTALLATION_TYPE[key] === installationType))
    const imgObj = typeList[installationKey]
    return imgObj?.url && imgObj || typeList.INSTALLATION
}

const getSiteMarker = (obj) => {
    if (!obj.typeName) {
        AppStore.dispatch(LogAction.logInfo(`cannot find station typeName in obj : ${obj}`))
        return 'assets/pictures/markers/map_qualite_white.png'
    }
    if (obj.typeName === 'quality' && hasValue(obj.stationType)) {
        const q = getImgQuality(obj.stationType)
        return q?.url || 'assets/pictures/markers/map_qualite_white.png'
    }
    if (obj.typeName === 'installation' && hasValue(obj.installationType)) {
        const q = getImgInstallation(obj.installationType)
        return q?.url || 'assets/pictures/markers/map_qualite_white.png'
    }
    return getSiteUrl(STATION_NAME_ASSOCIATION[obj.typeName])
}

export { getIconType, getSiteType, getSiteUrl, getMapPointsScale, getSiteName, createIconStyle, getImgInstallation, getImgQuality, getSiteMarker }

export default typeList
