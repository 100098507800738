/* eslint-disable max-nested-callbacks */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { difference, groupBy, maxBy, minBy, orderBy, sumBy, uniq, uniqBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import { searchAllCharacters } from 'utils/StringUtil'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import Table from 'components/datatable/Table'
import SimpleTabSideList from 'components/navbar/SimpleTabSideList'
import PcMonitoringOperationGraph from 'quality/components/pcMonitoring/graph/PcMonitoringOperationGraph'
import DtoOperation from 'station/components/suivipc/qualitometer/dto/DtoOperation'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { Grid } from '@mui/material'
import { ParameterGraph } from 'quality/components/qualityComponents/ParameterGraph'
import { RemarkLegend, ThresholdLegend } from 'quality/components/qualityComponents/Legend'
import { getTabColorsBySize } from 'utils/AnalyseUtils'
import { hasValue } from 'utils/NumberUtil'
import DtoDataType from 'referencial/dto/DtoDataType'
import DtoPiezometerLight from 'piezometry/dto/DtoPiezometerLight'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import { getDate, getEndYear, getStartYear } from 'utils/DateUtil'
import IAEauAction from 'iaeau/IAEauAction'
import WaitAction from 'wait/WaitAction'
import RadioButtons from 'components/forms/RadioButtons'
import ToastrAction from 'toastr/actions/ToastrAction'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import moment from 'moment'
import { HYDRO, PIEZO, PLUVIO } from 'quality/constants/ChartConstant'

const PARAMETER = 'parameter'
const STATION = 'station'
const STATISTIC = 'statistic'

const DISPLAY_ALL = 1
const DISPLAY_SELECTED = 2
const DISPLAY_NON_SELECTED = 3

const movingAveragePoints = [
    { value: 1, label: '3' },
    { value: 2, label: '5' },
    { value: 3, label: '7' },
    { value: 4, label: '9' },
]

const TabParameter = ({
    filter = {},
    analysis = [],
    selectedParameter = [],
    setSelectedParameter = () => { },
}) => {
    const {
        parameters,
        units,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')
    const [displayValue, setDisplayValue] = useState(DISPLAY_ALL)

    const listParam = useMemo(() => uniq(analysis.map(a => filter.groupEquivalences ? a.parameter : `${a.parameter}#${a.unit}`)), [analysis, filter.groupEquivalences])

    const groupAnalysis = useMemo(() => groupBy(analysis, a => filter.groupEquivalences ? a.parameter : `${a.parameter}#${a.unit}`), [analysis, filter.groupEquivalences])

    const parameterFormated = useMemo(() => listParam.map(key => {
        const [parameter] = key.split('#')
        const isSelected = selectedParameter.includes(key)

        const parameterObj = parameters.find(p => p.code === parameter)
        const unitsCode = uniqBy(groupAnalysis[key], 'unit').map(a => a.unit)
        const unitsObj = unitsCode.map(code => units.find(u => u.code === code))
        const unitsLabel = unitsObj.map(u => u?.symbolWithCode || '').join(', ')
        return {
            nullValue: (
                <i className='material-icons tiny table-checkbox'>{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>
            ),
            parameter: parameterObj?.displayLabel || '',
            unit: unitsLabel,
            searchValue: searchAllCharacters(`${parameter}${parameterObj?.name || ''}${parameterObj?.shortLabel || ''}${unitsLabel}`),
            key,
            isSelected,
        }
    }), [groupAnalysis, listParam, parameters, selectedParameter, units])

    const parameterFiltered = useMemo(() => {
        const searchCharacter = searchAllCharacters(searchValue)
        const filtreSearchCharacter = searchCharacter ? parameterFormated.filter(p => p.searchValue.includes(searchCharacter)) : parameterFormated
        const filterSelected = displayValue === DISPLAY_SELECTED ? filtreSearchCharacter.filter(p => p.isSelected) : filtreSearchCharacter
        return displayValue === DISPLAY_NON_SELECTED ? filterSelected.filter(p => !p.isSelected) : filterSelected
    }, [displayValue, parameterFormated, searchValue])

    const parameterOrdered = useMemo(() => orderBy(parameterFiltered, 'parameter'), [parameterFiltered])

    const isAllSelected = parameterFiltered.every(p => p.isSelected)

    const onSelectAll = () => {
        const selectListParam = parameterFiltered.map(p => p.key)
        if (isAllSelected) {
            setSelectedParameter(prev => difference(prev, selectListParam))
        } else {
            setSelectedParameter(prev => uniq([...prev, ...selectListParam]))
        }
    }

    const listType = useMemo(() => [
        { label: i18n.allParameters, value: DISPLAY_ALL },
        { label: i18n.selectedParameters, value: DISPLAY_SELECTED },
        { label: i18n.nonSelectedParameters, value: DISPLAY_NON_SELECTED },
    ], [])

    return (
        <div style={{ paddingTop: 10 }}>
            <Input
                title={i18n.search}
                value={searchValue}
                onChange={setSearchValue}
            />
            <Select
                options={listType}
                value={displayValue}
                label={i18n.type}
                onChange={setDisplayValue}
                noNullValue
            />
            <Table
                showTitle={false}
                data={parameterOrdered}
                sortable
                onClick={({ key, isSelected }) => setSelectedParameter(prev => {
                    if (isSelected) {
                        return prev.filter(p => p !== key)
                    }
                    return [...prev, key]
                })}
                maxHeight={'55vh'}
                customHeaders={{ nullValue: (<i className='material-icons tiny table-checkbox' onClick={onSelectAll}>{isAllSelected ? 'check_box' : 'check_box_outline_blank'}</i>) }}
                type={{ headers: ['nullValue', 'parameter', 'unit'] }}
                condensed
                className='no-margin'
                noHightlight
            />
        </div>
    )
}

TabParameter.propTypes = {
    filter: PropTypes.shape({
        groupEquivalences: PropTypes.bool,
    }),
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),
    selectedParameter: PropTypes.arrayOf(PropTypes.string),
    setSelectedParameter: PropTypes.func,
}

const StationFieldSet = ({
    title = '',
    listStations = [],
    stationsWithMeasures = [],
    dataTypes = [],

    selectedStations = [],
    setSelectedStations = () => { },
}) => {
    return (
        <StyledFieldSet>
            <StyledLegend>{title}</StyledLegend>
            <Grid container justifyContent='left' columnSpacing={2}>
                {
                    stationsWithMeasures.map(({ id, type }, i) => {
                        const {
                            name,
                            code,
                        } = listStations.find(s => s.id === id)
                        const {
                            label,
                        } = dataTypes.find(t => t.id === type) || {}
                        const isSelected = selectedStations.find(selectedId => id === selectedId)
                        return (
                            <Grid container item key={`name-${i}`} >
                                <Grid item xs={1}>
                                    <Checkbox
                                        checked={isSelected}
                                        onChange={() => {
                                            setSelectedStations(prev => {
                                                if (isSelected) {
                                                    return prev.filter(selectedId => selectedId !== id)
                                                }
                                                return [...prev, id]
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <h6>{`${name || ''} ${code ? `[${code}]` : ''}`}</h6>
                                </Grid>
                                <Grid item xs={5}>
                                    <h6>{label}</h6>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </StyledFieldSet>
    )
}

StationFieldSet.propTypes = {
    title: PropTypes.string,
    listStations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    ]),
    stationsWithMeasures: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    })),
    dataTypes: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(DtoDataType),
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
        }),
    ])),
    selectedStations: PropTypes.arrayOf(PropTypes.number),
    setSelectedStations: PropTypes.func,
}

const TabStation = ({
    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    selectedPiezo,
    setSelectedPiezo,

    selectedPluvio,
    setSelectedPluvio,

    selectedHydro,
    setSelectedHydro,
}) => {
    const {
        piezometersProps,
        pluviometersProps,
        hydrometricStations,
        hydrometryDataTypes,
        piezometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        piezometersProps: store.PiezometryReducer.piezometersLight,
        pluviometersProps: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const piezoTypes = useMemo(() => {
        return [
            { id: 'piezometryChronic', label: i18n.chronic },
            ...getHardPiezoDataTypes(),
            ...piezometryDataTypes,
        ]
    }, [piezometryDataTypes])

    const hydroTypes = useMemo(() => uniqBy([...hydrometryDataTypes, ...getHardHydroDataTypes()], 'id'), [hydrometryDataTypes])

    return (
        <div style={{ paddingTop: 10 }}>
            {
                !!piezoDatas.length && (
                    <StationFieldSet
                        title={i18n.piezometers}
                        listStations={piezometersProps}
                        stationsWithMeasures={piezoDatas}
                        dataTypes={piezoTypes}
                        selectedStations={selectedPiezo}
                        setSelectedStations={setSelectedPiezo}
                    />
                )
            }
            {
                !!hydroDatas.length && (
                    <StationFieldSet
                        title={i18n.hydrometricStations}
                        listStations={hydrometricStations}
                        stationsWithMeasures={hydroDatas}
                        dataTypes={hydroTypes}
                        selectedStations={selectedHydro}
                        setSelectedStations={setSelectedHydro}
                    />
                )
            }
            {
                !!pluvioDatas.length && (
                    <StationFieldSet
                        title={i18n.pluviometers}
                        listStations={pluviometersProps}
                        stationsWithMeasures={pluvioDatas}
                        dataTypes={pluviometryDataTypes}
                        selectedStations={selectedPluvio}
                        setSelectedStations={setSelectedPluvio}
                    />
                )
            }
        </div>
    )
}

TabStation.propTypes = {
    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    selectedPiezo: PropTypes.arrayOf(PropTypes.number),
    setSelectedPiezo: PropTypes.func,

    selectedPluvio: PropTypes.arrayOf(PropTypes.number),
    setSelectedPluvio: PropTypes.func,

    selectedHydro: PropTypes.arrayOf(PropTypes.number),
    setSelectedHydro: PropTypes.func,
}

const TabStatistic = ({
    displayHype = false,
    setDisplayHype = () => { },

    displayStatistics = false,
    setDisplayStatistics = () => { },

    displayAverages = false,
    setDisplayAverages = () => { },

    nbPointMovingAverage = 1,
    setNbPointMovingAverage = () => { },
}) => {
    return (
        <Grid container alignItems='center' justifyContent='left' rowSpacing={2} style={{ padding: 10 }}>
            <Grid item xs={12}>
                <Checkbox
                    checked={displayStatistics}
                    label={i18n.displayStatistics}
                    onChange={setDisplayStatistics}
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    checked={displayAverages}
                    label={i18n.displayAverages}
                    onChange={setDisplayAverages}
                />
            </Grid>
            <Grid item xs={12}>
                <RadioButtons
                    selected={nbPointMovingAverage}
                    elements={movingAveragePoints}
                    title={i18n.numberOfPoints}
                    onChange={setNbPointMovingAverage}
                    disabled={!displayAverages}
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    checked={displayHype}
                    label={i18n.displayTrends}
                    onChange={setDisplayHype}
                />
            </Grid>
        </Grid>
    )
}

TabStatistic.propTypes = {
    displayStatistics: PropTypes.bool,
    setDisplayStatistics: PropTypes.func,
    displayAverages: PropTypes.bool,
    setDisplayAverages: PropTypes.func,
    nbPointMovingAverage: PropTypes.number,
    setNbPointMovingAverage: PropTypes.func,
    displayHype: PropTypes.bool,
    setDisplayHype: PropTypes.func,
}

const OverlayGraph = ({
    analysis = [],
    thresholds = [],

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    filter = {},

    selectedParameter = [],
    selectedPiezo = [],
    selectedPluvio = [],
    selectedHydro = [],

    displayStatistics = false,
    displayAverages = false,
    nbPointMovingAverage = 1,
    displayHype = false,
    hypeRuptures = [],
    hypeTrends = [],
}) => {
    const {
        piezometryDataTypes,
        pluviometryDataTypes,
        piezosIndex,
        hydrosIndex,
        pluviosIndex,
    } = useSelector(store => ({
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        piezosIndex: store.PiezometryReducer.piezosIndex,
        hydrosIndex: store.HydrometryReducer.hydrosIndex,
        pluviosIndex: store.PluviometryReducer.pluviosIndex,
    }))

    const dataTypePiezo = useMemo(() => [
        { id: 'piezometryChronic', label: i18n.chronic },
        ...getHardPiezoDataTypes(),
        ...piezometryDataTypes,
    ], [piezometryDataTypes])

    const filteredAnalysis = useMemo(() => {
        return selectedParameter.flatMap(selectedParam => {
            const [parameter, unit] = selectedParam.split('#')
            if (filter.groupEquivalences) {
                return analysis.filter(a => a.parameter === parameter)
            }
            return analysis.filter(a => a.parameter === parameter && a.unit === unit)
        })
    }, [analysis, filter.groupEquivalences, selectedParameter])

    const startDate = useMemo(() => {
        const start = minBy(filteredAnalysis, 'analysisDate')?.analysisDate
        return getStartYear(start)
    }, [filteredAnalysis])
    const endDate = useMemo(() => {
        const end = maxBy(filteredAnalysis, 'analysisDate')?.analysisDate
        return getEndYear(end)
    }, [filteredAnalysis])

    const paramThreshold = useMemo(() => {
        if (selectedParameter.length > 1) {
            return
        }
        const [code] = selectedParameter[0].split('#')
        return thresholds?.find(t => t.parameterCode === code)
    }, [selectedParameter, thresholds])

    const colorTab = useMemo(() => {
        if (!paramThreshold) {
            return {}
        }
        const allColorTab = getTabColorsBySize()
        const nbThresholds = [1, 2, 3, 4].filter(nb => hasValue(paramThreshold[`threshold${nb}`])).length + 1
        return allColorTab[nbThresholds]
    }, [paramThreshold])

    const piezoData = useMemo(() => {
        const filteredData = piezoDatas.filter(({ id, type }) => hasValue(id) && hasValue(type) && piezosIndex[id])
        return filteredData.map(({ id, type, measures = [] }) => {
            const piezo = piezosIndex[id]

            const keyPiezo = type === -1 ? 'NGF' : 'value'
            const measuresFiltered = measures.filter(m => m.date > startDate && m.date < endDate)
            const { label, unit } = dataTypePiezo.find(t => t.id === type) ?? {}
            return {
                id,
                name: piezo.name,
                unit: `${label ?? ''} ${unit ? `[${unit}]` : ''}`,
                showSymbol: false,
                dataType: PIEZO,
                dataList: measuresFiltered.map(m => ({
                    date: m.date,
                    value: m[keyPiezo],
                })),
            }
        }).filter(p => p.dataList.length)
    }, [dataTypePiezo, endDate, piezoDatas, piezosIndex, startDate])

    const pluvioData = useMemo(() => {
        const filteredData = pluvioDatas.filter(({ id, type }) => hasValue(id) && hasValue(type) && pluviosIndex[id])
        return filteredData.map(({ id, type, offset = 0, measures = [] }) => {
            const pluvio = pluviosIndex[id]

            const measuresOffset = measures.map(m => ({ value: m.value, date: moment(m.date).add(offset, 'day').valueOf() }))

            const measuresFiltered = measuresOffset.filter(m => m.date > startDate && m.date < endDate)
            const groupedMeasures = groupBy(measuresFiltered.filter(({ value }) => hasValue(value)), m => getDate(m.date, 'DD/MM/YYYY'))
            const measuresFormated = Object.keys(groupedMeasures).map(key => {
                return {
                    ...groupedMeasures[key][0],
                    value: sumBy(groupedMeasures[key], 'value'),
                }
            })
            const { label, unit } = pluviometryDataTypes.find(t => t.id === type) ?? {}
            return {
                id,
                name: pluvio.name,
                unit: `${label ?? ''} ${unit ? `[${unit}]` : ''}`,
                dataType: PLUVIO,
                dataList: measuresFormated,
                type: 'bar',
            }
        }).filter(p => p.dataList.length)
    }, [endDate, pluvioDatas, pluviometryDataTypes, pluviosIndex, startDate])

    const hydroData = useMemo(() => {
        const filteredData = hydroDatas.filter(({ id, type }) => hasValue(id) && hasValue(type) && hydrosIndex[id])
        return filteredData.map(({ id, type, measures = [], calculateFlux, coeffFlux = 1 }) => {
            const hydro = hydrosIndex[id]

            const measuresFiltered = measures.filter(m => m.date > startDate && m.date < endDate)

            return {
                id,
                name: hydro.name,
                unit: type,
                showSymbol: false,
                dataType: HYDRO,
                dataList: measuresFiltered.map(m => ({
                    date: m.date,
                    value: calculateFlux ? m.value * coeffFlux : m.value,
                })),
            }
        }).filter(h => h.dataList.length)
    }, [endDate, hydroDatas, hydrosIndex, startDate])

    const piezoFiltered = useMemo(() => {
        const selected = piezoData.filter(p => selectedPiezo.includes(p.id))
        return selected.filter(p => p.dataList)
    }, [piezoData, selectedPiezo])

    const hydroFiltered = useMemo(() => {
        const selected = hydroData.filter(p => selectedHydro.includes(p.id))
        return selected.filter(p => p.dataList)
    }, [hydroData, selectedHydro])

    const pluvioFiltered = useMemo(() => {
        const selected = pluvioData.filter(p => selectedPluvio.includes(p.id))
        return selected.filter(p => p.dataList)
    }, [pluvioData, selectedPluvio])

    return (
        <Grid container style={{ padding: '0 10' }} columnSpacing={2}>
            <Grid item xs={12}>
                <ParameterGraph
                    analysis={filteredAnalysis}
                    additionalData={[...piezoFiltered, ...hydroFiltered, ...pluvioFiltered]}
                    thresholds={thresholds}

                    hypeRuptures={hypeRuptures}
                    hypeTrends={hypeTrends}

                    graphOptions={{
                        regroupAxis: filter.groupEquivalences,
                        displayStatistics,
                        displayAverages,
                        nbPointMovingAverage,
                        displayHype,
                    }}

                    exportName={i18n.comparingGraph}

                    toolboxPosition={{ top: 10, right: 60 }}
                />
            </Grid>
            <Grid item xs={colorTab.length ? 8 : 12}>
                <RemarkLegend
                    list={filteredAnalysis}
                    col={colorTab.length ? 4 : 3}
                />
            </Grid>
            {
                !!colorTab.length && (
                    <Grid item xs={4}>
                        <ThresholdLegend
                            threshold={paramThreshold}
                            colorTab={colorTab}
                        />
                    </Grid>
                )
            }
        </Grid>
    )
}

OverlayGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    filter: PropTypes.shape({
        groupEquivalences: PropTypes.bool,
    }),

    selectedParameter: PropTypes.arrayOf(PropTypes.string),
    selectedPiezo: PropTypes.arrayOf(PropTypes.number),
    selectedPluvio: PropTypes.arrayOf(PropTypes.number),
    selectedHydro: PropTypes.arrayOf(PropTypes.number),

    displayStatistics: PropTypes.bool,
    displayAverages: PropTypes.bool,
    nbPointMovingAverage: PropTypes.number,
    displayHype: PropTypes.bool,
    hypeRuptures: PropTypes.arrayOf(PropTypes.shape({})),
    hypeTrends: PropTypes.arrayOf(PropTypes.shape({})),
}

const PcMonitoringGraph = ({
    analysis = [],
    operations = [],
    thresholds = [],

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    filter = {},
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [selectedParameter, setSelectedParameter] = useState([])
    const [selectedPiezo, setSelectedPiezo] = useState([])
    const [selectedPluvio, setSelectedPluvio] = useState([])
    const [selectedHydro, setSelectedHydro] = useState([])

    const [displayStatistics, setDisplayStatistics] = useState(false)
    const [displayAverages, setDisplayAverages] = useState(false)
    const [nbPointMovingAverage, setNbPointMovingAverage] = useState(1)

    const [displayHype, setDisplayHype] = useState(false)

    const [hypeRuptures, setHypeRuptures] = useState([])
    const [hypeTrends, setHypeTrends] = useState([])

    useEffect(() => {
        const ids = uniqBy(analysis, 'qualitometer').map(a => a.qualitometer)
        if (displayHype && ids.length === 1 && selectedParameter.length === 1) {
            const [id] = ids
            const [parameterKey] = selectedParameter
            const [parameter] = parameterKey.split('#')
            const qualitometer = qualitometers.find(q => q.id === id)
            if (!qualitometer) {
                return
            }

            const hypeData = analysis.filter(a => a.parameter === parameter && hasValue(a.value)).map((a, index) => ({
                stationCode: qualitometer.code,
                stationTypeCode: qualitometer.stationType,
                parameterCode: parseInt(a.parameter) || 0,
                analysisNumber: index,
                measureDate: a.analysisDate,
                measure: a.result,
                unitCode: 0,
                remarkCode: 1,
            }))
            if (hypeData.length) {
                dispatch(WaitAction.waitStart())
                dispatch(IAEauAction.getHypeTrend(hypeData, qualitometer.code))
                    .then(({ trends, breaks }) => {
                        if (!trends.length && !breaks.length) {
                            dispatch(ToastrAction.warning(i18n.hypeNoTrendsDetected))
                            setDisplayHype(false)
                        }
                        setHypeTrends(trends)
                        setHypeRuptures(breaks)
                    })
                    .finally(() => {
                        dispatch(WaitAction.waitStop())
                    })
            }
        }
    }, [analysis, dispatch, displayHype, qualitometers, selectedParameter, setHypeRuptures, setHypeTrends])

    const tabs = useMemo(() => [
        {
            icon: 'edit',
            constant: PARAMETER,
            label: i18n.parameters,
        },
        {
            icon: 'list',
            constant: STATION,
            displayed: !!piezoDatas.length || !!hydroDatas.length || !!pluvioDatas.length,
            label: i18n.stations,
        },
        {
            icon: 'multiline_chart',
            constant: STATISTIC,
            displayed: selectedParameter.length === 1,
            label: i18n.statistics,
        },
    ], [hydroDatas, piezoDatas, pluvioDatas, selectedParameter.length])

    return (
        <Card height={700}>
            <div className='row no-margin relative no-overflow'>
                <SimpleTabSideList
                    position='right'
                    defaultTab={PARAMETER}
                    tabs={tabs}
                >
                    {
                        tab => (
                            <>
                                {
                                    tab === PARAMETER && (
                                        <TabParameter
                                            filter={filter}
                                            analysis={analysis}
                                            selectedParameter={selectedParameter}
                                            setSelectedParameter={selectedParams => {
                                                setSelectedParameter(selectedParams)
                                                setHypeTrends([])
                                                setHypeRuptures([])
                                                setDisplayHype(false)
                                            }}
                                        />
                                    )
                                }
                                {
                                    tab === STATION && (
                                        <TabStation
                                            hydroDatas={hydroDatas}
                                            piezoDatas={piezoDatas}
                                            pluvioDatas={pluvioDatas}

                                            selectedPiezo={selectedPiezo}
                                            setSelectedPiezo={setSelectedPiezo}

                                            selectedPluvio={selectedPluvio}
                                            setSelectedPluvio={setSelectedPluvio}

                                            selectedHydro={selectedHydro}
                                            setSelectedHydro={setSelectedHydro}
                                        />
                                    )
                                }
                                {
                                    tab === STATISTIC && (
                                        <TabStatistic
                                            displayStatistics={displayStatistics}
                                            setDisplayStatistics={setDisplayStatistics}
                                            displayAverages={displayAverages}
                                            setDisplayAverages={setDisplayAverages}
                                            nbPointMovingAverage={nbPointMovingAverage}
                                            setNbPointMovingAverage={setNbPointMovingAverage}
                                            displayHype={displayHype}
                                            setDisplayHype={setDisplayHype}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </SimpleTabSideList>
                <div>
                    {
                        !selectedParameter.length && (
                            <PcMonitoringOperationGraph
                                analysis={analysis}
                                operations={operations}
                            />
                        )
                    }
                    {
                        !!selectedParameter.length && (
                            <OverlayGraph
                                analysis={analysis}
                                thresholds={thresholds}
                                filter={filter}

                                hydroDatas={hydroDatas}
                                piezoDatas={piezoDatas}
                                pluvioDatas={pluvioDatas}

                                selectedParameter={selectedParameter}
                                selectedPiezo={selectedPiezo}
                                selectedPluvio={selectedPluvio}
                                selectedHydro={selectedHydro}

                                displayStatistics={displayStatistics}
                                displayAverages={displayAverages}
                                nbPointMovingAverage={nbPointMovingAverage}
                                displayHype={displayHype}
                                hypeRuptures={hypeRuptures}
                                hypeTrends={hypeTrends}
                            />
                        )
                    }
                </div>
            </div>
        </Card>
    )
}

PcMonitoringGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    filter: PropTypes.shape({
        groupEquivalences: PropTypes.bool,
    }),
}

export default PcMonitoringGraph