import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import { filter, keys } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getModuleColor, getReduceStatistics } from '../../../../utils/StatisticUtil'
import DtoAccountStatistic from '../../../dto/DtoAccountStatistic'

class AccountAccessApplicationChartPanel extends Component {
    constructor(props) {
        super(props)
    }

    getOptions = (filterStatistics) => {
        const reduceDatas = getReduceStatistics(filterStatistics)
        const dataSeries = keys(reduceDatas).map((o) => {
            return {
                value: reduceDatas[o].length,
                name: o?.toUpperCase(),
                color: getModuleColor(o),
            }
        })
        return {
            color: dataSeries.map(o => o.color),
            title: {
                text: i18n.usedApplication,
                x: 'center',
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                right: 10,
                top: 'center',
                type: 'scroll',
                height: '140px',
            },
            series: [
                {
                    type: 'pie',
                    data: dataSeries,
                    avoidLabelOverlap: false,
                    radius: ['50%', '70%'],
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '20',
                                fontWeight: 'bold',
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                },
            ],
        }
    }

    render() {
        const filterStatistics = filter(this.props.accountStatistics, o => o.value)
        if (filterStatistics.length) {
            return (
                <div className='row card'>
                    <div className='col s12 no-padding'>
                        <ReactEchartsCore
                            echarts={ echarts }
                            option={ this.getOptions(filterStatistics) }
                            notMerge={ true }
                            lazyUpdate={ true }
                            className={ 'row no-margin' }
                            style={ { height: 200 } }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

AccountAccessApplicationChartPanel.propTypes = {
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
}
const mapStateToProps = (store) => {
    return {
        accountStatistics: store.AccountReducer.accountStatistics,
    }
}
export default connect(mapStateToProps)(AccountAccessApplicationChartPanel)