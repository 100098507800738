import React, { useEffect, useMemo } from 'react'
import ContactAction from '../../../../referencial/components/contact/actions/ContactAction'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import SelectionTableModal from '../../../../components/modal/SelectionTableModal'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import i18n from 'simple-react-i18n'
import {
    SimpleFilterField, SimpleFilterFunction,
} from '../../../../components/datatable/SimpleSelectionTable'

const HEADERS = ['name', 'mobile']

const SmsModalContact = ({
    contactsIdsParameters,
    isOpen,
    onClose,
    setContactsIdsParameters,
}) => {
    useEffect(() => {
        ContactAction.fetchContacts()
    }, [])

    const {
        contacts,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const listContact = useMemo(() =>
        contacts.filter(c => c.name && c.mobile).map(l => ({
            id: l.id,
            name: l.name,
            mobile: l.mobile,
            labelSearch: HEADERS.map(key => searchAllCharacters(l[key])).join(''),
        }))
    , [contacts])


    const onValidate = (list) => {
        setContactsIdsParameters(list)
        onClose()
    }

    return (
        <SelectionTableModal
            isOpen={isOpen}
            onClose={onClose}
            onValidate={onValidate}
            listHeaders={HEADERS}
            selectionListHeaders={HEADERS}
            title={i18n.contacts}
            selectionListTitle={i18n.contactsSelected}
            listTitle={i18n.contacts}
            listData={listContact}
            defaultSelectionList={contactsIdsParameters ?? []}
            filterField={SimpleFilterField}
            filterFunction={SimpleFilterFunction}
        />
    )
}

SmsModalContact.propTypes = {
    contactsIdsParameters: PropTypes.arrayOf(PropTypes.number),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    setContactsIdsParameters: PropTypes.func,
}
export default SmsModalContact