import ActionComponent from 'components/ActionComponent'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isEqual, omit, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_MAT_SIM } from '../../../../account/constants/AccessRulesConstants'
import User from '../../../../account/dto/User'
import DtoFile from '../../../../components/file/dto/DtoFile'
import FilePanel from '../../../../components/file/FilePanel'
import DtoPicture from '../../../../components/picture/dto/DtoPicture'
import SmallPicturePanel from '../../../../components/picture/SmallPicturePanel'
import SieauAction from '../../../../components/sieau/SieauAction'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { getDate } from '../../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import DtoMaterielSettingRule from '../../../dto/DtoMaterielSettingRule'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import { isSaveBlocked } from '../../../utils/MaterielUtils'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import SituationPanel from '../../situation/SituationPanel'
import SubscriptionAction from '../../subscription/actions/SubscriptionAction'
import SimSubscriptionTable from '../../subscription/components/SimSubscriptionTable'
import SubscriptionDto from '../../subscription/dto/SubscriptionDto'
import SubscriptionTypeDto from '../../subscription/dto/SubscriptionTypeDto'
import SimAction from '../actions/SimAction'
import DtoSimSituation from '../dto/DtoSimSituation'
import SimDto from '../dto/SimDto'
import SimSubscriptionDto from '../dto/SimSubscriptionDto'
import SimPanel from './SimPanel'
import { SIM_ID } from 'materiel/constants/MaterielConstants'

class SimApp extends ActionComponent {
    state = {
        sim: {},
        isEditMode: false,
    }

    changeAdministrator = administrator => this.setState(({ sim }) => ({ sim: { ...sim, administrator } }))

    changeStateAttribute = changes => this.setState({ sim: { ...this.state.sim, ...changes } })

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentWillUnmount = () => {
        this.props.resetSim()
        this.props.resetAllActions()
    }

    fetchSim = id => this.props.fetchSim(id).then(json => {
        if (json.id) {
            this.props.fetchSimSituations(json.id)
            this.props.fetchSubscriptionsLink(json.id)
            this.props.fetchSimPictures(json.id)
            this.props.fetchSimFiles(json.serialNumber)
            this.setState({ sim: new SimDto(json) })
        }
    })

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_SIM)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.match.params.id !== 'new') {
            this.fetchSim(this.props.match.params.id)
            this.changeEditMode(false)
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.sims.length) {
            this.props.fetchSims(true)
        }
        if (!this.props.simTypes.length) {
            this.props.fetchSimTypes()
        }
        if (!this.props.subscriptions.length) {
            this.props.fetchSubscriptions()
        }
        if (!this.props.subscriptionTypes.length) {
            this.props.fetchSubscriptionTypes()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        this.applyActions()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchSim(this.props.match.params.id)
        }
        if (!isEqual(prevProps.sim, this.props.sim) || !isEqual(prevProps.simTypes, this.props.simTypes)) {
            const {
                reference = '',
                serialNumber = '',
                simType,
                id,
            } = this.props.sim
            const title = reference || serialNumber || id
            const type = (this.props.simTypes.find(t => t.code === parseInt(simType)) || {}).label || i18n.notDefined
            this.props.forceFetch('title', [
                {
                    title: i18n.materiels,
                    href: 'materiel',
                },
                {
                    title: i18n.sims,
                    href: 'materiel/sim',
                },
                {
                    title: type,
                    href: `materiel/sim/${this.props.match.params.id}`,
                },
                {
                    title,
                    href: `materiel/sim/${this.props.match.params.id}`,
                },
            ])
        }
        this.applyActions()
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.match.params.id === 'new' && !this.props.sim.id) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SIM_ID)
                        if (isSaveBlocked(this.props.sims, this.state.sim, rules, 'simType')) return

                        this.props.createSim(this.state.sim).then(id => {
                            if (id) {
                                const situation = {
                                    idSim: id,
                                    situationDate: this.state.sim.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.saveSimSituation(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push('/materiel/sim')
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SIM_ID)
                        if (isSaveBlocked(this.props.sims, this.state.sim, rules, 'simType')) return

                        this.props.updateSim(this.state.sim).then(() => this.changeEditMode(false))
                    },
                    cancel: () => {
                        this.setState({ sim: { ...this.props.sim } })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deleteSim(this.props.match.params.id)
                },
                replace: () => {
                    this.props.info(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    deleteSituation = id => this.props.deleteSimSituation(id, this.props.match.params.id)

    getMaterielNumber = ({ serialNumber, reference }) => serialNumber || reference || ''

    getSimsFormat = () => {
        const { sims, simTypes } = this.props
        return sims.filter(({ isEquipment }) => !isEquipment).map(sim => {
            const label = (() => {
                const number = this.getMaterielNumber(sim)
                if (sim.simType) {
                    const type = simTypes.find(t => t.id === sim.simType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: sim.id,
                label,
            }
        })
    }

    render = () => {
        const {
            subscriptionsLink,
            subscriptions,
            subscriptionTypes,
        } = this.props
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }

        const formatedLinks = orderBy(subscriptionsLink, [({ startDate }) => startDate ? startDate : 0, ({ endDate }) => endDate ? endDate : 0], ['desc', 'desc']).map(({ idSim, idSubscription, startDate, endDate, comment }) => {
            const sim = subscriptions.find(({ id }) => idSubscription === id)
            if (sim) {
                const type = subscriptionTypes.find(({ id }) => id === sim.subscriptionType)
                return {
                    subscription: `${type ? `${type.name}:` : ''} ${sim.numLine || ''}`,
                    startDate: getDate(startDate),
                    endDate: getDate(endDate),
                    comment,
                    idSim,
                    idSubscription,
                }
            }
            return {
                startDate: getDate(startDate),
                endDate: getDate(endDate),
                comment,
                idSim,
                idSubscription,
            }
        })

        const formatedSubscriptions = subscriptions.map(({ numLine = '', id, subscriptionType }) => {
            const type = subscriptionTypes.find(({ code }) => code === subscriptionType)
            return {
                code: id,
                name: `${type ? `${type.name}:` : ''} ${numLine}`,
            }
        })

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel materielSituation={ this.props.simSituations } />
                                    </div>
                                    <div className='col s11'>
                                        <SimPanel
                                            sim={ this.state.sim }
                                            onChange={ this.changeStateAttribute }
                                            { ...disabled }
                                        />
                                        <div className='col s12'>
                                            <SimSubscriptionTable
                                                links={formatedLinks}
                                                editMode={this.state.isEditMode }
                                                headers={['subscription', 'startDate', 'endDate', 'comment']}
                                                title={i18n.subscriptions}
                                                associatedMateriels={formatedSubscriptions}
                                                onDelete={({ idSim, idSubscription }) => {
                                                    this.props.deleteSimSubscriptionLink(idSim, idSubscription).then(() => this.props.fetchSubscriptionsLink(this.props.match.params.id))
                                                }}
                                                idKeyToRedirect={'idSubscription'}
                                                redirectTo={'subscription'}
                                                onAdd={({ materielLink, startDate, endDate, comment }) => {
                                                    const simSubscription = {
                                                        idSubscription: materielLink,
                                                        idSim: this.props.sim.id,
                                                        startDate,
                                                        endDate,
                                                        comment,
                                                    }
                                                    this.props.createSimSubscription(simSubscription).then(() =>
                                                        this.props.fetchSubscriptionsLink(this.props.match.params.id))
                                                }}
                                            />
                                        </div>
                                        {
                                            !this.state.sim.isEquipment && (
                                                <div className='col s12'>
                                                    <SituationPanel
                                                        materielSituation={this.props.simSituations}
                                                        active={this.state.isEditMode}
                                                        idMateriel={this.props.sim.id}
                                                        serialNumber={this.props.sim.serialNumber}
                                                        purchaseDate={this.props.sim.purchaseDate}
                                                        idKey={'idSim'}
                                                        lastSituations={this.props.simsLastSituations}
                                                        materials={this.getSimsFormat()}
                                                        saveFunction={this.props.saveSimSituation}
                                                        deleteFunction={this.deleteSituation}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.props.sim.updateDate}
                                                updateLogin={this.props.sim.updateLogin}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.sim.administrator}
                                                        isActive={!this.state.sim.isEquipment && this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel materielSituation={ this.props.simSituations } />
                                            <SmallPicturePanel element={ 'sim' } pictures={ this.props.simPictures } />
                                            <FilePanel files={ this.props.simFiles } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SimApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
    }),
    sim: PropTypes.instanceOf(SimDto),
    getLink: PropTypes.func,
    changeEditMode: PropTypes.func,
    resetAllActions: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    simTypes: PropTypes.instanceOf(GenericReferencialDto),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    simSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimSituation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    simPictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    simFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
    simsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimSituation)),
    subscriptionsLink: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    subscriptions: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionDto)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    sim: store.SimReducer.sim,
    sims: store.SimReducer.sims,
    simsLastSituations: store.SimReducer.simsLastSituations,
    simTypes: store.SimReducer.simTypes,
    simSituations: store.SimReducer.simSituations,
    materielStates: store.MaterielReducer.materielStates,
    contributors: store.ContributorReducer.contributors,
    simPictures: store.SimReducer.simPictures,
    simFiles: store.SimReducer.simFiles,
    accountUser: store.AccountReducer.accountUser,
    subscriptionsLink: store.SimReducer.subscriptionsLink,
    subscriptions: store.SubscriptionReducer.subscriptions,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProps = {
    push,
    resetSim: SimAction.resetSim,
    fetchSim: SimAction.fetchSim,
    fetchSims: SimAction.fetchSims,
    fetchSimTypes: SimAction.fetchSimTypes,
    forceFetch: SieauAction.forceFetch,
    createSim: SimAction.createSim,
    updateSim: SimAction.updateSim,
    deleteSim: SimAction.deleteSim,
    toastrInfo: ToastrAction.info,
    deleteSimSituation: SimAction.deleteSimSituation,
    fetchSimSituations: SimAction.fetchSimSituations,
    fetchSimPictures: SimAction.fetchSimPictures,
    fetchSimFiles: SimAction.fetchSimFiles,
    saveSimSituation: SimAction.saveSimSituation,
    fetchSubscriptionsLink: SimAction.fetchSubscriptionsLink,
    fetchSubscriptions: SubscriptionAction.fetchSubscriptions,
    fetchSubscriptionTypes: SubscriptionAction.fetchSubscriptionTypes,
    createSimSubscription: SimAction.createSimSubscription,
    deleteSimSubscriptionLink: SubscriptionAction.deleteSimSubscriptionLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(SimApp)
