import download from 'downloadjs'
import fetch from 'isomorphic-fetch'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { checkAuth, genericPromise, genericPromiseFile } from 'utils/ActionUtils'
import { getDateExport } from 'utils/DateUtil'
import WaitAction from 'wait/WaitAction'
import CampaignAction from '../../campaign/actions/CampaignAction'
import ApplicationConf from '../../conf/ApplicationConf'
import LogAction from '../../log/actions/LogAction'
import { checkError, getAuthorization, getJson } from '../../utils/ActionUtils'
import { downloadURI } from '../../utils/ExportDataUtil'
import {
    EXPORT_JOB_STATUS,
    EXPORT_TMP_QUALITY_REASEARCH,
} from '../constants/ExportConstants'
import { push } from 'connected-react-router'
import { getLogin } from '../../utils/SettingUtils'
import { ExportActionConstant } from 'export/reducers/ExportReducer'

const ExportAction = {
    export: (dataPass, type, titleFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportData(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    data: dataPass,
                    type,
                }),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}_${getDateExport()}.${type}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },
    zipExport: (exportData, titleFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportZipData(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    exportData,
                    zipName: `${titleFile}_${getDateExport()}`,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    downloadURI(`EXPORT/tmp/${json.name}`)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },
    kmlExport: (exportData) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.export.kmlExport(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    data: window.btoa(unescape(encodeURIComponent(JSON.stringify(exportData)))),
                }),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, 'exportSieau.kml', blob.type)
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(WaitAction.waitStop())
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },
    exportModel: (exportModelObj) => {
        const {
            stationCode,
            ...exportObj
        } = exportModelObj
        const fileNameSplit = exportModelObj.filenameModelExport.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        const resultFileName = `${name}_${stationCode}_${getDateExport()}`
        return dispatch => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportModel(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    ...exportObj,
                    resultFileName,
                }),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                })
                .then((blob) => {
                    download(blob, `${resultFileName}.${ext}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },
    exportResearchFullCSV: (researchCriterias, fileType = 'csv') => {
        return dispatch => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportResearchFullCSV(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    researchCriterias: { ...researchCriterias, user: getLogin() },
                    filenameModelExport: 'nepastoucher',
                    resultFileName: `${i18n.sampleDetail}_${moment().format('YYYYMMDDHHmmss')}`,
                    fileType
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((res) => {
                    if (res.targetPath) {
                        downloadURI(res.targetPath)
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },
    exportResearchModelWithTmpExport: (researchCriterias, filenameModelExport) => {
        return (dispatch) => {
            const fileNameSplit = filenameModelExport.split('.')
            const name = fileNameSplit.slice(0, -1).join('')
            // const ext = fileNameSplit[fileNameSplit.length - 1]
            const resultFileName = `${name}_${getDateExport()}`
            const exportTmpObj = {
                id: 0,
                exportType: EXPORT_TMP_QUALITY_REASEARCH,
                login: getLogin(),
                creationDate: 0,
                status: EXPORT_JOB_STATUS.WAITING,
                stationType: 'quality',
                params: JSON.stringify({ researchCriterias: { ...researchCriterias, user: getLogin() }, filenameModelExport, resultFileName }),
            }
            dispatch(ExportAction.runExportTmp(exportTmpObj)).then(() => {
                dispatch(ToastrAction.info(i18n.loadingDocumentConsultExportMenu))
                dispatch(push('/quality/export'))
            })
        }
    },
    exportResearchModel: (researchCriterias, filenameModelExport) => {
        const fileNameSplit = filenameModelExport.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        // const ext = fileNameSplit[fileNameSplit.length - 1]
        const resultFileName = `${name}_${getDateExport()}`
        return dispatch => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportResearchModel(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    researchCriterias,
                    filenameModelExport,
                    resultFileName,
                }),
            })
                .then(checkAuth)
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((res) => {
                    if (res.targetPath) {
                        downloadURI(res.targetPath)
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
                // .then((resp) => {
                //     return resp.blob()
                // })
                // .then((blob) => {
                //     download(blob, `${resultFileName}.${ext}`, blob.type)
                // })
                // .catch(err => {
                //     dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                //     dispatch(ToastrAction.error(i18n.exportError))
                // })
        }
    },
    exportAEP: (exportModelObj) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exportAEP(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(exportModelObj),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                })
                .then((blob) => {
                    download(blob, 'AEP_exported.xlsx', blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },
    runExportTmp: (exportTmpObj, toastrLabel = i18n.loadingDocument) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(toastrLabel))
            return fetch(ApplicationConf.export.runExportTmp(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(exportTmpObj),
            })
                .then(checkAuth)
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },
    promiseEnvironmentModels: () => fetch(ApplicationConf.export.environmentModels(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchEnvironmentModels: () => dispatch => ExportAction.promiseEnvironmentModels()
        .then(json => dispatch(ExportActionConstant.receiveEnvironmentModels(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.export} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.export))
        }),

    promiseEnvironmentModelsByType: (stationType) => fetch(ApplicationConf.export.environmentModelsByType(stationType), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchEnvironmentModelsByType: (stationType) => dispatch => ExportAction.promiseEnvironmentModelsByType(stationType)
        .then(json => dispatch(ExportActionConstant.receiveTypeEnvironmentModels(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.export} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.export))
        }),
    getEnvironmentModelsByType: (stationType) => dispatch => ExportAction.promiseEnvironmentModelsByType(stationType)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.export} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.export))
            return []
        }),

    exportEDILABOPlanning: param => dispatch => fetch(ApplicationConf.export.exportEDILABOPlanning(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(param),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => {
            if (json.success) {
                dispatch(ToastrAction.success(i18n.fileCreatedSuccessfully))
                dispatch(CampaignAction.fetchEDILABOFile(param.campaignId))
            } else {
                dispatch(LogAction.logError(`${i18n.exportError} : ${json.message}`))
                dispatch(ToastrAction.error(json.message))
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
            dispatch(ToastrAction.error(i18n.exportError))
        }),

    exportEDILABORai: param => dispatch => fetch(ApplicationConf.export.exportEDILABORai(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(param),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => {
            if (json.success) {
                dispatch(ToastrAction.success(i18n.fileCreatedSuccessfully))
            } else {
                dispatch(LogAction.logError(`${i18n.exportError} : ${json.message}`))
                dispatch(ToastrAction.error(json.message))
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
            dispatch(ToastrAction.error(i18n.exportError))
        }),

    exportInstallationsFull: (type, fileName = 'installations', filter) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.installationsFull(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(filter),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${fileName}_${getDateExport()}.${type}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportExploitationsServices: (data) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exploitationsServices(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(data),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `FACTEXT_${getDateExport(moment.now())}`)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportExploitationsConsos: (params = {}, typeFile, titleFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.exploitationsConsos(typeFile), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(params),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}.${typeFile}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportPARFull: (id, typeFile, titleFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            return fetch(ApplicationConf.export.parFull(id, typeFile), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${titleFile}.${typeFile}`, blob.type)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.exportError))
                })
        }
    },

    exportZipJobFiles: (filesPath, titleFile) => {
        return (dispatch) => {
            dispatch(ToastrAction.info(i18n.loadingDocument))
            const zipName = `${titleFile}_${getDateExport()}`.replaceAll(' ', '')
            return fetch(ApplicationConf.export.exportZipJobFiles(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    filesPath,
                    zipName,
                }),
            })
                .then(checkAuth)
                .then((resp) => {
                    return resp.blob()
                }).then((blob) => {
                    download(blob, `${zipName}.zip`, blob.type)
                })
                .catch(err => {
                    if (err?.toString()?.includes('404')) {
                        dispatch(ToastrAction.warning(i18n.noFilesFound))
                    } else {
                        dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                        dispatch(ToastrAction.error(i18n.exportError))
                    }
                })
        }
    },

    exportJasper: (stationId, stationType, modelFileName) => genericPromise(ApplicationConf.export.jasperExport(), 'POST', { stationId, stationType, modelFileName }),

    fetchStepBasinsExport: (id) => genericPromise(ApplicationConf.export.fetchStepBasinsExport(id)),

    exportCatchmentEvolutions: options => dispatch => {
        dispatch(ToastrAction.info(i18n.loadingDocument))
        return genericPromiseFile(ApplicationConf.export.catchmentEvolutions(), { body: options })
            .then(blob => download(blob, `${i18n.evolutionOfContaminations}.${options.exportType}`, options.exportType))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.exportError} : ${err}`))
                dispatch(ToastrAction.error(i18n.exportError))
            })
    },
}

export default ExportAction
