import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import React, { useEffect, useMemo, useState } from 'react'
import { hasValue } from 'utils/NumberUtil'
import { getLogin } from 'utils/SettingUtils'
import moment from 'moment'
import { findIndex, isNil, maxBy, orderBy, uniqBy } from 'lodash'
import Input from 'components/forms/Input'
import CmsAction from 'events/actions/CmsAction'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import DtoCmsModel from 'events/dto/DtoCmsModel'
import { Grid } from '@mui/material'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import Icon from 'components/icon/Icon'
import MultiContactsAutocomplete from 'referencial/components/contact/components/MultiContactsAutocomplete'
import { getMiniHour } from 'utils/DateUtil'
import { onChangeMiniHour } from 'utils/FormUtils'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import Table from 'components/datatable/Table'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import InstallationAction from 'installation/actions/InstallationAction'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { getI18nOrLabel, searchAllCharacters } from 'utils/StringUtil'
import CityAction from 'referencial/components/city/actions/CityAction'
import DtoCmsModelStation from 'events/dto/DtoCmsModelStation'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import NumberField from 'components/forms/NumberField'
import DtoCmsModelObstacle from 'events/dto/DtoCmsModelObstacle'
import Checkbox from 'components/forms/Checkbox'
import { getNewStationTypeNameFromTypeCode } from 'utils/StationUtils'
import DtoCmsModelTideGauge from 'events/dto/DtoCmsModelTideGauge'
import StationAction from 'station/actions/StationAction'
import DtoAssociatedStation from 'station/dto/DtoAssociatedStation'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { MEASURE_COTES, MEASURE_FREQUENCY, MEASURE_MODES } from 'piezometry/constants/PiezometryConstants'

const DEPTH_ID = -1
const RAIN_ID = 1

const SelectStationModal = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    selectedStations = [],
    defaultStationType,
}) => {
    const {
        installationsLight,
        hydrometers,
        piezometersLight,
        pluviometers,
    } = useSelector(store => ({
        installationsLight: store.InstallationReducer.installationsLight,
        hydrometers: store.HydrometryReducer.hydrometricStations,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const [stationType, setStationType] = useState(defaultStationType)

    useEffect(() => {
        if (!isNil(defaultStationType)) {
            setStationType(defaultStationType)
        }
    }, [defaultStationType])

    const listData = useMemo(() => {
        switch (stationType) {
            case STATION_TYPE_CONSTANT.hydrometry:
                return hydrometers
            case STATION_TYPE_CONSTANT.installation:
                return installationsLight
            case STATION_TYPE_CONSTANT.piezometry:
                return piezometersLight
            case STATION_TYPE_CONSTANT.pluviometry:
                return pluviometers
            default:
                return []
        }
    }, [hydrometers, installationsLight, piezometersLight, pluviometers, stationType])

    const stationTypeOptions = useMemo(() => [{
        id: STATION_TYPE_CONSTANT.hydrometry,
        name: getI18nOrLabel(STATION_TYPE_NAME.hydrometry),
    }, {
        id: STATION_TYPE_CONSTANT.installation,
        name: getI18nOrLabel(STATION_TYPE_NAME.installation),
    }, {
        id: STATION_TYPE_CONSTANT.piezometry,
        name: getI18nOrLabel(STATION_TYPE_NAME.piezometry),
    }, {
        id: STATION_TYPE_CONSTANT.pluviometry,
        name: getI18nOrLabel(STATION_TYPE_NAME.pluviometry),
    }], [])

    const selectedStationsFiltered = useMemo(() => {
        if (!defaultStationType) {
            return selectedStations.filter(am => am.typeName === getNewStationTypeNameFromTypeCode(stationType)).map(s => s.id)
        }
        return selectedStations.map(s => s.id)
    }, [defaultStationType, selectedStations, stationType])

    return (
        <SelectionTableModal
            isOpen={isOpen}
            onClose={onClose}
            onValidate={ids => onValidate(ids, stationType)}
            title={i18n.selectStations}

            listData={listData}
            listHeaders={['code', 'townCode', 'name']}
            listTitle={i18n.nonSelectedStations}

            defaultSelectionList={selectedStationsFiltered}
            selectionListHeaders={['code', 'townCode', 'name']}
            selectionListTitle={i18n.selectedStations}

            maxHeightTable={'45vh'}
            filterField={({
                filter,
                setFilter,
            }) => (
                <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
                    {!defaultStationType ? (
                        <Grid item xs={12}>
                            <SuperMultiAutocomplete
                                options={stationTypeOptions}
                                onChange={setStationType}
                                label={i18n.stationType}
                                values={stationType}
                                obligatory
                            />
                        </Grid>
                    ) : ''}
                    <Grid item xs={6}>
                        <SimpleFilterSelect
                            stationType={stationType}
                            onChange={(resultFilter, newFilter) => {
                                setFilter(prev => ({ ...prev, resultFilter, filter: newFilter }))
                            }}
                            stations={listData}
                            disabled={!stationType}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.search}
                            value={filter.searchValue}
                            onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                            disabled={!stationType}
                        />
                    </Grid>
                </Grid>
            )}
            filterFunction={(list, { searchValue, resultFilter = [], filter }) => {
                const searchValueFormat = searchAllCharacters(searchValue)
                const filterSearchValue = searchValue ? list.filter(p => searchAllCharacters(['code', 'townCode', 'name'].map(key => p[key])).includes(searchValueFormat)) : list
                return filter !== -1 ? resultFilter.map(s => filterSearchValue.find(e => e.id === s.id)).filter(e => !!e) : filterSearchValue
            }}
        />
    )
}

SelectStationModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    selectedStations: PropTypes.arrayOf(PropTypes.number),
    defaultStationType: PropTypes.number,
}

const StepModelDefinition = ({
    model,
    setModel = () => {},
}) => {
    const {
        cmsCategories,
        contacts,
    } = useSelector(store => ({
        cmsCategories: store.EventsReducer.cmsCategories,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const onChangeCms = (changes) => setModel(prevCms => ({ ...prevCms, ...changes }))

    const cmsCategoriesFormatted = useMemo(() => cmsCategories.map(categ => ({
        ...categ,
        icon: (
            <Icon
                size='small'
                icon={categ.icon}
            />
        ),
    })), [cmsCategories])

    const daysOptions = useMemo(() => [
        { value: '*', label: i18n.allDays },
        { value: '1', label: i18n.monday },
        { value: '2', label: i18n.tuesday },
        { value: '3', label: i18n.wednesday, disabled: true },
        { value: '4', label: i18n.thursday },
        { value: '5', label: i18n.friday },
        { value: '6', label: i18n.saturday },
        { value: '0', label: i18n.sunday },
    ], [])

    const contactsFiltered = useMemo(() => uniqBy(contacts, 'email'), [contacts])

    return (
        <Grid container justifyContent='center'>
            <Grid container item xs={9} spacing={2}>
                <Grid item xs={12}>
                    <SuperMultiAutocomplete
                        options={cmsCategoriesFormatted}
                        onChange={categoryId=> onChangeCms({ categoryId })}
                        keyValue='id'
                        keyLabel='title'
                        label={i18n.category}
                        values={model.categoryId}
                        obligatory
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        title={i18n.name}
                        value={model.title}
                        onChange={title => onChangeCms({ title })}
                        obligatory
                    />
                </Grid>
                <Grid item xs={12}>
                    <SuperMultiAutocomplete
                        options={daysOptions}
                        onChange={planification=> onChangeCms({ planification })}
                        keyValue='value'
                        keyLabel='label'
                        label={i18n.frequency}
                        values={model.planification}
                    />
                </Grid>
                <Grid container item xs={12} justifyContent='space-between'>
                    <Grid item xs={5}>
                        <Input
                            title={i18n.hourGeneration}
                            value={getMiniHour(model.hourGeneration)}
                            onChange={ v => onChangeMiniHour(v, v2 => onChangeCms({ hourGeneration: v2 }), { max: model.hourPublication }, model.hourGeneration) }
                            placeholder='08:00'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            title={i18n.hourPublication}
                            value={getMiniHour(model.hourPublication)}
                            onChange={ v => onChangeMiniHour(v, v2 => onChangeCms({ hourPublication: v2 }), { min: model.hourGeneration }, model.hourPublication) }
                            placeholder='11:00'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MultiContactsAutocomplete
                        label={i18n.recipients}
                        options={contactsFiltered}
                        onChange={v => onChangeCms({ recipients: v?.join() || '' })}
                        keyValue='email'
                        values={model.recipients?.length ? model.recipients.split(',') : []}
                        multiple
                        limit={3}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        title={i18n.documentModel}
                        value={model.documentModel}
                        onChange={documentModel => onChangeCms({ documentModel })}
                        placeholder='BULLETINS/modele.jasper'
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

StepModelDefinition.propTypes = {
    model: PropTypes.shape({
        categoryId: PropTypes.number,
        title: PropTypes.string,
        planification: PropTypes.string,
        hourGeneration: PropTypes.string,
        hourPublication: PropTypes.string,
        recipients: PropTypes.string,
        documentModel: PropTypes.string,
    }),
    setModel: PropTypes.func,
}

const associatedMonitoringHeaders = ['nullValue', 'nullValue2', 'siteType', 'code', 'city', 'name', 'dataTypeId', 'timeAbsence', 'order', 'installationCode', 'reference', 'regrouping', 'frequency', 'limit', 'displayMode']

const DEFAULT_REGROUPING = 'ALL'
const DEFAULT_REGROUPING_FREQUENCY = '1'
const DEFAULT_LIMIT = 1

const StepAssociatedMonitoring = ({
    associatedMonitoring = [],
    setAssociatedMonitoring = () => {},
    associatedStation = [],
}) => {
    const {
        cities,
        hydrometryDataTypes,
        piezometryDataTypes,
        pluviometryDataTypes,
        installationsLight,
        hydrometers,
        piezometersLight,
        pluviometers,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        installationsLight: store.InstallationReducer.installationsLight,
        hydrometers: store.HydrometryReducer.hydrometricStations,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)

    const tableActions = [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setIsOpen(true),
    }]

    const getMaxStationId = (index) => (maxBy(associatedMonitoring, 'stationId')?.stationId + 1 + index) || (index + 1)

    const updateMonitoring = (stationId, newObj) => {
        const objIndex = findIndex(associatedMonitoring, am => am.stationId === stationId)
        setAssociatedMonitoring([...associatedMonitoring.slice(0, objIndex), newObj, ...associatedMonitoring.slice(objIndex + 1)])
    }

    const getEditIcon = (obj) => {
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateMonitoring(obj.stationId, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateMonitoring(obj.stationId, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const piezoDataTypes = useMemo(() => uniqBy([...getHardPiezoDataTypes(), ...piezometryDataTypes], 'id'), [piezometryDataTypes])
    const hydroDataTypes = useMemo(() => uniqBy([...getHardHydroDataTypes(), ...hydrometryDataTypes], 'id'), [hydrometryDataTypes])

    const stationTypeOptions = useMemo(() => [{
        id: STATION_TYPE_CONSTANT.hydrometry,
        name: STATION_TYPE_NAME.hydrometry,
    }, {
        id: STATION_TYPE_CONSTANT.installation,
        name: STATION_TYPE_NAME.installation,
    }, {
        id: STATION_TYPE_CONSTANT.piezometry,
        name: STATION_TYPE_NAME.piezometry,
    }, {
        id: STATION_TYPE_CONSTANT.pluviometry,
        name: STATION_TYPE_NAME.pluviometry,
    }], [])

    const formatHydro = (hydros) => hydros.map((h, i) => ({
        ...h,
        siteType: STATION_TYPE_CONSTANT.hydrometry,
        siteCode: h.id,
        typeName: STATION_TYPE_NAME.hydrometry,
        dataTypeId: h.dataType,
        order: 1,
        readMode: true,
        installationCode: undefined,
        stationId: getMaxStationId(i),
        regrouping: DEFAULT_REGROUPING,
        frequency: DEFAULT_REGROUPING_FREQUENCY,
        limit: DEFAULT_LIMIT,
    }))

    const formatInst = (insts) => insts.map((h, i) => ({
        ...h,
        siteType: STATION_TYPE_CONSTANT.installation,
        siteCode: h.id,
        typeName: STATION_TYPE_NAME.installation,
        dataTypeId: h.dataType,
        order: 1,
        readMode: true,
        stationId: getMaxStationId(i),
        regrouping: DEFAULT_REGROUPING,
        frequency: DEFAULT_REGROUPING_FREQUENCY,
        limit: DEFAULT_LIMIT,
    }))

    const formatPiezo = (piezos) => piezos.map((h, i) => ({
        ...h,
        siteType: STATION_TYPE_CONSTANT.piezometry,
        siteCode: h.id,
        typeName: STATION_TYPE_NAME.piezometry,
        dataTypeId: h.dataType || DEPTH_ID,
        order: 1,
        timeAbsence: 24,
        reference: true,
        readMode: true,
        installationCode: undefined,
        stationId: getMaxStationId(i),
        regrouping: DEFAULT_REGROUPING,
        frequency: DEFAULT_REGROUPING_FREQUENCY,
        limit: DEFAULT_LIMIT,
    }))

    const formatPluvio = (pluvios) => pluvios.map((h, i) => ({
        ...h,
        siteType: STATION_TYPE_CONSTANT.pluviometry,
        siteCode: h.id,
        typeName: STATION_TYPE_NAME.pluviometry,
        dataTypeId: h.dataType || RAIN_ID,
        order: 1,
        timeAbsence: 24,
        reference: true,
        readMode: true,
        installationCode: undefined,
        stationId: getMaxStationId(i),
        regrouping: DEFAULT_REGROUPING,
        frequency: DEFAULT_REGROUPING_FREQUENCY,
        limit: DEFAULT_LIMIT,
    }))

    const getDataTypesByStationType = (siteType) => {
        switch (siteType) {
            case STATION_TYPE_CONSTANT.hydrometry:
                return hydroDataTypes
            case STATION_TYPE_CONSTANT.installation:
                return hydroDataTypes
            case STATION_TYPE_CONSTANT.piezometry:
                return piezoDataTypes
            case STATION_TYPE_CONSTANT.pluviometry:
                return pluviometryDataTypes
            default:
                return []
        }
    }

    const getSiteType = (siteId) => getI18nOrLabel(stationTypeOptions.find(st => st.id === siteId)?.name || '')

    const getCity = (city) => getI18nOrLabel(cities.find(st => st.id === city)?.name || '')

    const associatedMonitoringFormatted = useMemo(() => orderBy(associatedMonitoring, ['siteType', 'stationId']).map(am => {
        const associatedInst = associatedStation.filter(ast => ast.code === am.code)
        const accessHydroChoices = [STATION_TYPE_CONSTANT.hydrometry, STATION_TYPE_CONSTANT.installation].includes(am.siteType)
        const datatypes = getDataTypesByStationType(am.siteType)
        return {
            ...am,
            nullValue: {
                value: (
                    <Icon
                        size='small'
                        icon='close'
                        onClick={() => setAssociatedMonitoring(associatedMonitoring.filter(a => a.stationId !== am.stationId))}
                        tooltip={i18n.delete}
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            nullValue2: {
                value: getEditIcon(am),
                style: {
                    width: '100%',
                },
            },
            siteType: {
                value: (
                    <span>{getSiteType(am.siteType)}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            code: {
                value: (
                    <span>{am.code}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            city: {
                value: (
                    <span>{getCity(am.townCode)}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            name: {
                value: (
                    <span>{am.name}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            dataTypeId: {
                value: (
                    <SuperMultiAutocomplete
                        options={datatypes}
                        onChange={dataTypeId => updateMonitoring(am.stationId, { ...am, dataTypeId })}
                        label=''
                        values={am.dataTypeId}
                        disabled={am.readMode}
                        inputWidth='100%'
                        obligatory
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            timeAbsence: {
                value: (
                    <NumberField
                        title=''
                        value={am.timeAbsence}
                        onChange={timeAbsence => updateMonitoring(am.stationId, { ...am, timeAbsence })}
                        disabled={am.readMode}
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            order: {
                value: accessHydroChoices ? (
                    <Input
                        title=''
                        value={am.order}
                        onChange={newOrder => updateMonitoring(am.stationId, { ...am, order: parseInt(newOrder) }, true)}
                        disabled={am.readMode}
                        style={{ width: '100%' }}
                    />
                ) : (
                    <span>{am.order}</span>
                ),
                style: {
                    width: '100%',
                },
            },
            installationCode: {
                value: (accessHydroChoices && associatedInst.length) ? (
                    <SuperMultiAutocomplete
                        options={associatedInst}
                        onChange={installationCode => updateMonitoring(am.stationId, { ...am, installationCode })}
                        label=''
                        values={am.installationCode}
                        disabled={am.readMode}
                        keyValue='stationLinkedId'
                        keyLabel='stationLinkedName'
                        displayWithCode
                        inputWidth='100%'
                    />
                ) : null,
                style: {
                    width: '100%',
                },
            },
            reference: {
                value: (
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <Checkbox
                                checked={am.reference}
                                onChange={reference => updateMonitoring(am.stationId, { ...am, reference })}
                                disabled={!accessHydroChoices || am.readMode}
                            />
                        </Grid>
                    </Grid>
                ),
                style: {
                    width: '100%',
                },
            },
            regrouping: {
                value: !!am.reference && (
                    <SuperMultiAutocomplete
                        options={MEASURE_MODES}
                        onChange={regrouping => updateMonitoring(am.stationId, { ...am, regrouping })}
                        label=''
                        values={am.regrouping}
                        disabled={am.readMode}
                        keyValue='key'
                        keyLabel='label'
                        inputWidth='100%'
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            frequency: {
                value: !!(am.reference && am.regrouping !== DEFAULT_REGROUPING) && (
                    <SuperMultiAutocomplete
                        options={MEASURE_FREQUENCY}
                        onChange={frequency => updateMonitoring(am.stationId, { ...am, frequency })}
                        label=''
                        values={am.frequency}
                        disabled={am.readMode}
                        keyValue='key'
                        keyLabel='label'
                        inputWidth='100%'
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            limit: {
                value: !!am.reference && (
                    <NumberField
                        title=''
                        value={am.limit}
                        onChange={limit => updateMonitoring(am.stationId, { ...am, limit })}
                        disabled={am.readMode}
                    />
                ),
                style: {
                    width: '100%',
                },
            },
            displayMode: {
                value: !!(am.reference && am.siteType === STATION_TYPE_CONSTANT.piezometry && datatypes.find(dt => dt.id === am.dataTypeId)?.isPiezo) && (
                    <SuperMultiAutocomplete
                        options={MEASURE_COTES}
                        onChange={displayMode => updateMonitoring(am.stationId, { ...am, displayMode })}
                        label=''
                        values={am.displayMode}
                        disabled={am.readMode}
                        keyValue='key'
                        keyLabel='label'
                        inputWidth='100%'
                    />
                ),
                style: {
                    width: '100%',
                },
            },
        }
    }), [associatedMonitoring, cities, stationTypeOptions, hydroDataTypes, associatedStation])

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12}>
                <Table
                    condensed paging
                    actions={tableActions}
                    nbPerPageLabel={nbPerPageLabel}
                    data={associatedMonitoringFormatted}
                    type={{ headers: associatedMonitoringHeaders }}
                    customHeaders={{
                        siteType: i18n.type,
                        dataTypeId: i18n.dataType,
                        timeAbsence: i18n.timeLimitAbsenceData,
                        installationCode: i18n.installation,
                        limit: i18n.limitindays,
                    }}
                    customWidthHeaders={{
                        nullValue: '0%',
                        nullValue2: '5%',
                        siteType: '10%',
                        code: '10%',
                        city: '10%',
                        name: '11%',
                        dataTypeId: '10%',
                        timeAbsence: '5%',
                        order: '3%',
                        installationCode: '15%',
                        reference: '3%',
                        regrouping: '5%',
                        frequency: '5%',
                        limit: '3%',
                        displayMode: '5%',
                    }}
                    sortable={!!associatedMonitoring.length}
                    color
                />
                <SelectStationModal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onValidate={(ids, stationType) => {
                        if (stationType === STATION_TYPE_CONSTANT.hydrometry) {
                            setAssociatedMonitoring(prev => [...prev, ...formatHydro(hydrometers.filter(h => ids.includes(h.id)))])
                        } else if (stationType === STATION_TYPE_CONSTANT.installation) {
                            setAssociatedMonitoring(prev => [...prev, ...formatInst(installationsLight.filter(h => ids.includes(h.id)))])
                        } else if (stationType === STATION_TYPE_CONSTANT.piezometry) {
                            setAssociatedMonitoring(prev => [...prev, ...formatPiezo(piezometersLight.filter(h => ids.includes(h.id)))])
                        } else if (stationType === STATION_TYPE_CONSTANT.pluviometry) {
                            setAssociatedMonitoring(prev => [...prev, ...formatPluvio(pluviometers.filter(h => ids.includes(h.id)))])
                        }
                        setIsOpen(false)
                    }}
                    selectedStations={[]}
                />
            </Grid>
        </Grid>
    )
}

StepAssociatedMonitoring.propTypes = {
    associatedMonitoring: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsModelStation)),
    setAssociatedMonitoring: PropTypes.func,
    associatedStation: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
}

const obstaclesHeaders = ['nullValue', 'nullValue2', 'obstacle', 'defaultValue', 'order']

const StepObstacles = ({
    obstacles = [],
    setObstacles = () => {},
}) => {
    const {
        installationsLight,
    } = useSelector(store => ({
        installationsLight: store.InstallationReducer.installationsLight,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)

    const tableActions = [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setIsOpen(true),
    }]

    const getMaxObstacleId = (index) => (maxBy(obstacles, 'obstacleId')?.obstacleId + 1 + index) || (index + 1)

    const updateObstacle = (id, newObj, updateOrder = false) => {
        const objIndex = findIndex(obstacles, am => am.id === id)
        const oldStation = obstacles.find(t => t.id === id)
        const alreadyStation = obstacles.find(tg => tg.order == newObj.order)
        if (alreadyStation && updateOrder) {
            const alreadyObjIndex = findIndex(obstacles, am => am.id === alreadyStation.id)
            if (alreadyObjIndex < objIndex) {
                return setObstacles([...obstacles.slice(0, alreadyObjIndex), { ...alreadyStation, order: oldStation?.order || '' }, ...obstacles.slice(alreadyObjIndex + 1, objIndex), newObj, ...obstacles.slice(objIndex + 1)])
            }
            return setObstacles([...obstacles.slice(0, objIndex), newObj, ...obstacles.slice(objIndex + 1, alreadyObjIndex), { ...alreadyStation, order: oldStation?.order || '' }, ...obstacles.slice(alreadyObjIndex + 1)])
        }
        return setObstacles([...obstacles.slice(0, objIndex), newObj, ...obstacles.slice(objIndex + 1)])
    }

    const getEditIcon = (obj) => {
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateObstacle(obj.id, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateObstacle(obj.id, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const formatInst = (insts) => insts.map((h, index) => ({
        ...h,
        obstacle: h.name,
        installationCode: h.id,
        order: (maxBy(obstacles, 'order')?.order || 0) + index + 1,
        readMode: true,
        obstacleId: getMaxObstacleId(index),
    }))

    const obstaclesFormatted = useMemo(() => obstacles.map(ob => ({
        ...ob,
        nullValue: {
            value: (
                <Icon
                    size='small'
                    icon='close'
                    onClick={() => {
                        const newObstacles = obstacles.filter(a => a.id !== ob.id).map((o, index) => ({
                            ...o,
                            order: o.order < ob.order ? o.order : (ob.order + (index + 1 - ob.order)),
                        }))
                        setObstacles(newObstacles)
                    }}
                    tooltip={i18n.delete}
                />
            ),
            style: {
                width: '100%',
            },
        },
        nullValue2: {
            value: getEditIcon(ob),
            style: {
                width: '100%',
            },
        },
        obstacle: {
            value: (
                <span>{ob.obstacle}</span>
            ),
            style: {
                width: '100%',
            },
        },
        defaultValue: {
            value: (
                <Input
                    title=''
                    value={ob.defaultValue}
                    onChange={defaultValue => updateObstacle(ob.id, { ...ob, defaultValue })}
                    disabled={ob.readMode}
                />
            ),
            style: {
                width: '100%',
            },
        },
        order: {
            value: (
                <Input
                    title=''
                    value={ob.order}
                    onChange={newOrder => updateObstacle(ob.id, { ...ob, order: parseInt(newOrder) }, true)}
                    disabled={ob.readMode}
                    style={{ width: '100%' }}
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [obstacles])

    return (
        <Grid container justifyContent='center'>
            <Grid container item xs={11} spacing={2}>
                <Grid item xs={12}>
                    <Table
                        condensed paging
                        actions={tableActions}
                        nbPerPageLabel={nbPerPageLabel}
                        data={obstaclesFormatted}
                        type={{ headers: obstaclesHeaders }}
                        customWidthHeaders={{
                            nullValue: '0%',
                            nullValue2: '5%',
                            obstacle: '40%',
                            defaultValue: '30%',
                            order: '25%',
                        }}
                        sortable={!!obstacles.length}
                        color
                    />
                </Grid>
                <SelectStationModal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onValidate={ids => {
                        const idsFiltered = ids.filter(id => !obstacles.map(o => o.id).includes(id))
                        setObstacles(prev => [...prev, ...formatInst(installationsLight.filter(h => idsFiltered.includes(h.id)))])
                        setIsOpen(false)
                    }}
                    selectedStations={obstacles}
                    defaultStationType={STATION_TYPE_CONSTANT.installation}
                />
            </Grid>
        </Grid>
    )
}

StepObstacles.propTypes = {
    obstacles: PropTypes.array,
    setObstacles: PropTypes.func,
}

const tideGaugesHeaders = ['nullValue', 'nullValue2', 'tidegauge', 'order']

const StepTideGauges = ({
    tideGauges = [],
    setTideGauges = () => {},
}) => {
    const {
        hydrometers,
    } = useSelector(store => ({
        hydrometers: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)

    const tableActions = [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setIsOpen(true),
    }]

    const updateTideGauge = (id, newObj, updateOrder = false) => {
        const objIndex = findIndex(tideGauges, am => am.id === id)
        const oldStation = tideGauges.find(t => t.id === id)
        const alreadyStation = tideGauges.find(tg => tg.order == newObj.order)
        if (alreadyStation && updateOrder) {
            const alreadyObjIndex = findIndex(tideGauges, am => am.id === alreadyStation.id)
            if (alreadyObjIndex < objIndex) {
                return setTideGauges([...tideGauges.slice(0, alreadyObjIndex), { ...alreadyStation, order: oldStation?.order || '' }, ...tideGauges.slice(alreadyObjIndex + 1, objIndex), newObj, ...tideGauges.slice(objIndex + 1)])
            }
            return setTideGauges([...tideGauges.slice(0, objIndex), newObj, ...tideGauges.slice(objIndex + 1, alreadyObjIndex), { ...alreadyStation, order: oldStation?.order || '' }, ...tideGauges.slice(alreadyObjIndex + 1)])
        }
        return setTideGauges([...tideGauges.slice(0, objIndex), newObj, ...tideGauges.slice(objIndex + 1)])
    }

    const getEditIcon = (obj) => {
        if (obj.readMode) {
            return (
                <Icon
                    size='small'
                    icon='edit'
                    onClick={() => updateTideGauge(obj.id, { ...obj, readMode: !obj.readMode })}
                    tooltip={i18n.change}
                />
            )
        }
        return (
            <Icon
                size='small'
                icon='undo'
                onClick={() => updateTideGauge(obj.id, { ...obj, readMode: !obj.readMode })}
                tooltip={i18n.change}
            />
        )
    }

    const formatHydro = (hydros) => hydros.map((h, index) => ({
        ...h,
        tidegauge: h.name,
        stationCode: h.id,
        order: (maxBy(tideGauges, 'order')?.order || 0) + index + 1,
        readMode: true,
    }))

    const tideGaugesFormatted = useMemo(() => tideGauges.map(ob => ({
        ...ob,
        nullValue: {
            value: (
                <Icon
                    size='small'
                    icon='close'
                    onClick={() => {
                        const newTideGauges = tideGauges.filter(a => a.id !== ob.id).map((o, index) => ({
                            ...o,
                            order: o.order < ob.order ? o.order : (ob.order + (index + 1 - ob.order)),
                        }))
                        setTideGauges(newTideGauges)
                    }}
                    tooltip={i18n.delete}
                />
            ),
            style: {
                width: '100%',
            },
        },
        nullValue2: {
            value: getEditIcon(ob),
            style: {
                width: '100%',
            },
        },
        tidegauge: {
            value: (
                <span>{ob.tidegauge}</span>
            ),
            style: {
                width: '100%',
            },
        },
        order: {
            value: (
                <Input
                    title=''
                    value={ob.order}
                    onChange={newOrder => updateTideGauge(ob.id, { ...ob, order: parseInt(newOrder) }, true)}
                    disabled={ob.readMode}
                    style={{ width: '100%' }}
                />
            ),
            style: {
                width: '100%',
            },
        },
    })), [tideGauges])

    return (
        <Grid container justifyContent='center'>
            <Grid container item xs={11} spacing={2}>
                <Grid item xs={12}>
                    <Table
                        condensed paging
                        actions={tableActions}
                        nbPerPageLabel={nbPerPageLabel}
                        data={tideGaugesFormatted}
                        type={{ headers: tideGaugesHeaders }}
                        customWidthHeaders={{
                            nullValue: '0%',
                            nullValue2: '5%',
                            tidegauge: '60%',
                            order: '35%',
                        }}
                        sortable={!!tideGauges.length}
                        color
                    />
                </Grid>
                <SelectStationModal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onValidate={ids => {
                        const idsFiltered = ids.filter(id => !tideGauges.map(o => o.id).includes(id))
                        setTideGauges(prev => [...prev, ...formatHydro(hydrometers.filter(h => idsFiltered.includes(h.id)))])
                        setIsOpen(false)
                    }}
                    selectedStations={tideGauges}
                    defaultStationType={STATION_TYPE_CONSTANT.hydrometry}
                />
            </Grid>
        </Grid>
    )
}

StepTideGauges.propTypes = {
    tideGauges: PropTypes.array,
    setTideGauges: PropTypes.func,
}

const DEFINITION_STEP = 0
const ASSOCIATED_MONITORING_STEP = 1
const OBSTACLES_STEP = 2
const TIDE_GAUGES_STEP = 3

const AlertModelStepper = ({
    modelToOpen,
    isOpen = false,
    setIsOpen = () => {},
}) => {
    const {
        hydrometryDataTypes,
        piezometryDataTypes,
        pluviometryDataTypes,
        installationsLight,
        hydrometers,
        installationsTypes,
        cities,
        cmsModelStations,
        cmsModels,
        cmsModelObstacles,
        cmsModelTideGauges,
        piezometersLight,
        pluviometers,
        sandreCodes,
    } = useSelector(store => ({
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        installationsLight: store.InstallationReducer.installationsLight,
        hydrometers: store.HydrometryReducer.hydrometricStations,
        installationsTypes: store.InstallationReducer.installationsTypes,
        cities: store.CityReducer.cities,
        cmsModelStations: store.EventsReducer.cmsModelStations,
        cmsModels: store.EventsReducer.cmsModels,
        cmsModelObstacles: store.EventsReducer.cmsModelObstacles,
        cmsModelTideGauges: store.EventsReducer.cmsModelTideGauges,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const defaultModel = {
        categoryId: null,
        title: null,
        subTitle: null,
        planification: null,
        hourGeneration: null,
        hourPublication: null,
        recipients: null,
        documentModel: null,
        startDate: moment().valueOf(),
        endDate: null,
        loginMaj: getLogin(),
        dateMaj: moment().valueOf(),
    }

    const [model, setModel] = useState(defaultModel)
    const [associatedMonitoring, setAssociatedMonitoring] = useState([])
    const [obstacles, setObstacles] = useState([])
    const [tideGauges, setTideGauges] = useState([])
    const [associatedStation, setAssociatedStation] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(ContactAction.fetchContactsGroups())
        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (!piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
        if (!hydrometers.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        if (!installationsLight.length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }
        if (!installationsTypes.length) {
            dispatch(InstallationAction.fetchInstallationTypes())
        }
        if (!piezometersLight.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!pluviometers.length) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
    }, [])

    useEffect(() => {
        if (!isNil(modelToOpen)) {
            setModel(modelToOpen)
        }
    }, [modelToOpen])

    useEffect(() => {
        if (isOpen && !isNil(model.modelId)) {
            dispatch(CmsAction.fetchCMSModel(model.modelId))
            dispatch(CmsAction.fetchCMSModelStations(model.modelId))
            dispatch(CmsAction.fetchCMSModelObstacles(model.modelId))
            dispatch(CmsAction.fetchCMSModelTideGauges(model.modelId))
        }
    }, [model.modelId, isOpen])

    const getStations = (modelStation) => {
        if (modelStation.siteType === STATION_TYPE_CONSTANT.hydrometry) {
            return hydrometers
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.installation) {
            return installationsLight
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.piezometry) {
            return piezometersLight
        } else if (modelStation.siteType === STATION_TYPE_CONSTANT.pluviometry) {
            return pluviometers
        }
        return []
    }

    const getFormattedCmsModelStations = () => cmsModelStations.map(modelStation => {
        const stations = getStations(modelStation)
        const station = stations.find(s => s.id === modelStation.siteCode)
        return {
            ...modelStation,
            id: station?.id,
            code: station?.code,
            townCode: station?.townCode,
            name: station?.name,
            typeName: getNewStationTypeNameFromTypeCode(modelStation.siteType),
            readMode: true,
            regrouping: modelStation.regrouping?.split('_')?.[2] || DEFAULT_REGROUPING,
            frequency: modelStation.regrouping?.split('_')?.[3] || DEFAULT_REGROUPING_FREQUENCY,
            limit: modelStation.limit || DEFAULT_LIMIT,
        }
    })

    useEffect(() => {
        const cmsModelStationsFormatted = getFormattedCmsModelStations()

        const modelStationsIds = cmsModelStationsFormatted.filter(modelStation => (modelStation.siteType === STATION_TYPE_CONSTANT.hydrometry) && !!modelStation.id).map(ms => ms.id)
        if (modelStationsIds.length) {
            dispatch(StationAction.getStationsAssociatedSites(modelStationsIds, STATION_TYPE_CONSTANT.HYDROMETRIC_STATION))
                .then(asso => setAssociatedStation(asso.filter(a => a.stationLinkedType === STATION_TYPE_CONSTANT.INSTALLATION)))
        }

        setAssociatedMonitoring(cmsModelStationsFormatted)
    }, [cmsModelStations, hydrometers, installationsLight])

    const getFormattedCmsModelObstacles = () => cmsModelObstacles.map(modelStation => {
        const station = installationsLight.find(s => s.id === modelStation.installationCode)
        return {
            ...modelStation,
            id: station?.id,
            obstacle: station?.name,
            typeName: getNewStationTypeNameFromTypeCode(modelStation.siteType),
            readMode: true,
        }
    })

    useEffect(() => {
        const cmsModelObstaclesFormatted = getFormattedCmsModelObstacles()
        setObstacles(cmsModelObstaclesFormatted)
    }, [cmsModelObstacles, installationsLight])

    const getFormattedCmsModelTideGauge = () => cmsModelTideGauges.map(modelStation => {
        const station = hydrometers.find(s => s.id === modelStation.stationCode)
        return {
            ...modelStation,
            id: station?.id,
            tidegauge: station?.name,
            typeName: getNewStationTypeNameFromTypeCode(modelStation.siteType),
            readMode: true,
        }
    })

    useEffect(() => {
        const cmsModelTideGaugesFormatted = getFormattedCmsModelTideGauge()
        setTideGauges(cmsModelTideGaugesFormatted)
    }, [cmsModelTideGauges, hydrometers])

    const closeDialog = (resetStep = () => {}) => {
        resetStep()
        setModel(modelToOpen)
        setIsOpen(false)

        const cmsModelStationsFormatted = getFormattedCmsModelStations()
        setAssociatedMonitoring(cmsModelStationsFormatted)

        const cmsModelObstaclesFormatted = getFormattedCmsModelObstacles()
        setObstacles(cmsModelObstaclesFormatted)

        const cmsModelTideGaugesFormatted = getFormattedCmsModelTideGauge()
        setTideGauges(cmsModelTideGaugesFormatted)

        dispatch(CmsAction.resetCMSModel())
        dispatch(CmsAction.resetCMSModelStations())
        dispatch(CmsAction.resetCMSModelObstacles())
        dispatch(CmsAction.resetCMSModelTideGauges())
    }

    const onValidate = (resetStep = () => {}) => {
        dispatch(CmsAction.updateCmsModel(model))

        const maxModelId = maxBy(cmsModels, 'modelId')?.modelId + 1

        const associatedMonitoringFormatted = associatedMonitoring.map((am, index) => ({
            ...new DtoCmsModelStation(am),
            modelId: model.modelId || maxModelId,
            stationId: am.stationId || (index + 1),
            regrouping: `CUMUL_PERSO_${am.regrouping}_${am.frequency}`,
        }))
        if (associatedMonitoringFormatted.length) {
            dispatch(CmsAction.updateCmsModelStations(associatedMonitoringFormatted))
        }

        const obstaclesFormatted = obstacles.map((o, index) => ({
            ...new DtoCmsModelObstacle(o),
            modelId: model.modelId || maxModelId,
            obstacleId: o.obstacleId || (index + 1),
        }))
        if (obstaclesFormatted.length) {
            dispatch(CmsAction.updateCmsModelObstacles(obstaclesFormatted))
        }

        const tideGaugesFormatted = tideGauges.map((tg, index) => ({
            ...new DtoCmsModelTideGauge(tg),
            modelId: model.modelId || maxModelId,
            tideGaugeId: tg.tideGaugeId || (index + 1),
        }))
        if (tideGaugesFormatted.length) {
            dispatch(CmsAction.updateCmsModelTideGauges(tideGaugesFormatted))
        }

        closeDialog(resetStep)
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.definition,
                    constant: DEFINITION_STEP,
                    nextAvailable: hasValue(model.categoryId) && hasValue(model.title),
                },
                {
                    label: i18n.associatedMonitoring,
                    constant: ASSOCIATED_MONITORING_STEP,
                    nextAvailable: !associatedMonitoring.some(am => !am.dataTypeId),
                },
                {
                    label: i18n.obstacles,
                    constant: OBSTACLES_STEP,
                },
                {
                    label: i18n.tideGauges,
                    constant: TIDE_GAUGES_STEP,
                },
            ]}
            open={isOpen}
            maxWidth='xl'
            sx={{
                maxWidth: 'none',
            }}
            title={i18n.addOrUpdateCms}
            closeDialog={closeDialog}
            renderSaveButton={(step, resetStep) => (step === TIDE_GAUGES_STEP) && (
                <ButtonMUI
                    onClick={() => onValidate(resetStep)}
                    variant='contained'
                >
                    {i18n.save}
                </ButtonMUI>
            )}
        >
            {(step) => {
                switch (step) {
                    case DEFINITION_STEP:
                        return (
                            <StepModelDefinition
                                model={model}
                                setModel={setModel}
                            />
                        )
                    case ASSOCIATED_MONITORING_STEP:
                        return (
                            <StepAssociatedMonitoring
                                associatedMonitoring={associatedMonitoring}
                                setAssociatedMonitoring={setAssociatedMonitoring}
                                associatedStation={associatedStation}
                            />
                        )
                    case OBSTACLES_STEP:
                        return (
                            <StepObstacles
                                obstacles={obstacles}
                                setObstacles={setObstacles}
                            />
                        )
                    case TIDE_GAUGES_STEP:
                        return (
                            <StepTideGauges
                                tideGauges={tideGauges}
                                setTideGauges={setTideGauges}
                            />
                        )
                    default:
                        return null
                }
            }}
        </StepperDialog>
    )
}

AlertModelStepper.propTypes = {
    modelToOpen: PropTypes.instanceOf(DtoCmsModel),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default AlertModelStepper