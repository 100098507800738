import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import Select from '../../../../components/forms/Select'
import { Grid, IconButton } from '@mui/material'
import { pulse } from '../../../../utils/AnimationsUtils'
import PropTypes from 'prop-types'
import DtoPluviometerStats from '../../../dto/DtoPluviometerStats'
import { isNil, orderBy } from 'lodash'
import Checkbox from 'components/forms/Checkbox'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'

const GENERIC = 'generic'
const NORMALS_YEARS= 'normalsYears'
const NORMALS_MONTHS= 'normalsMonths'
const MONTHS_STACK= 'monthsStack'
const EFFICIENT_RAIN = 'efficientRain'
const NORMAL_COMPARISON = 'comparisonNormal'

const ChoiseTypeChart = ({
    typeChart,
    setTypeChart,
    pluviometerStatistics,
    stat,
    setStat,
    dateComparedNormal,
    setDateComparedNormal,
    showEnveloppe,
    setShowEnveloppe,
}) => {
    const [newTypeChart, setNewTypeChart] = useState(typeChart)
    const [newStat, setNewStat] = useState(stat)
    const [tmpDateComparedNormal, setTmpDateComparedNormal] = useState(dateComparedNormal)
    const [isValidateActive, setIsValidateActive] = useState(false)


    const typesGraph= [
        { value: NORMALS_MONTHS, label: i18n.normalMonths },
        { value: NORMALS_YEARS, label: i18n.normalYears },
        { value: GENERIC, label: i18n.graphicTracking },
        { value: MONTHS_STACK, label: i18n.monthsStacked },
        { value: EFFICIENT_RAIN, label: i18n.effectiveRain },
        { value: NORMAL_COMPARISON, label: i18n.comparisonNormal },
    ]

    const onSave = () => {
        setTypeChart(newTypeChart)
        setStat(newStat)
        setDateComparedNormal(tmpDateComparedNormal)
        setIsValidateActive(false)
    }

    return (
        <>
            <StyledFieldSet>
                <StyledLegend>{i18n.chartOptions}</StyledLegend>
                <Select
                    col={ 4 }
                    label={ i18n.graphicType }
                    options={ typesGraph }
                    value={ newTypeChart }
                    onChange={ t => {
                        setNewTypeChart(t)
                        setIsValidateActive(true)
                    } }
                    noNullValue
                />
                { newTypeChart === 'generic' && (
                    <Select
                        col={ 4 }
                        label={ i18n.measureType }
                        options={ orderBy(pluviometerStatistics, ['order', 'typeId'], 'asc').map(m => ({ value: m.typeId, label: m.label })) }
                        value={ newStat }
                        onChange={ t => {
                            setNewStat(t)
                            setIsValidateActive(true)
                        } }
                        noSort
                        noNullValue
                    />
                )}
                { newTypeChart === NORMAL_COMPARISON && (
                    <SimpleDatePicker
                        col={ 4 }
                        value={ tmpDateComparedNormal }
                        onChange={ t => {
                            setTmpDateComparedNormal(t)
                            setIsValidateActive(true)
                        } }
                        noSort
                        noNullValue
                    />
                )}
            </StyledFieldSet>
            <Grid container justifyContent={'center'} alignItems='center' style={{ paddingTop: 8 }}>
                <IconButton
                    onClick={onSave}
                    disabled={(!isValidateActive || (newTypeChart === GENERIC && isNil(newStat)))}
                    sx={{
                        animation: `${!(!isValidateActive || (newTypeChart === GENERIC && isNil(newStat))) ? pulse : ''} 800ms infinite`,
                        backgroundColor: '#159cde',
                        color: 'white',
                        height: '60px',
                        width: '60px',
                    }}
                >
                    <BorderColorIcon sx={{ fontSize: '30px' }} />
                </IconButton>
            </Grid>
            { newTypeChart === 'generic' && (
                <StyledFieldSet>
                    <StyledLegend>{i18n.advancedOptions}</StyledLegend>
                    <Checkbox
                        col={6}
                        label={i18n.enveloppeMinMax}
                        checked={showEnveloppe}
                        onChange={() => setShowEnveloppe(!showEnveloppe)}
                    />
                </StyledFieldSet>
            )}
        </>
    )
}
ChoiseTypeChart.propTypes = {
    typeChart: PropTypes.string,
    setTypeChart: PropTypes.func,
    pluviometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
    stat: PropTypes.number,
    setStat: PropTypes.func,
    dateComparedNormal: PropTypes.number,
    setDateComparedNormal: PropTypes.func,
    showEnveloppe: PropTypes.bool,
    setShowEnveloppe: PropTypes.func,
}

export default ChoiseTypeChart