import { chunk, fill, isNil, min, round } from 'lodash'
import React from 'react'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import {
    GREEN,
    GREY,
    RED,
    COLORS_CONSTANTS,
} from '../components/constants/ColorConstant'
import SieauAction from '../components/sieau/SieauAction'
import AppStore from '../store/AppStore'
import { THRESHOLD_COLORS_CODES, darkBlue, mainWhite } from './constants/ColorTheme'
import colors from 'color-name'

const createLightColor = color => {
    switch (color) {
        case 'blue':
            return '#2196F3'
        case 'green':
            return '#4CAF50'
        case 'yellow':
            return '#ffeb3b'
        case 'orange':
            return '#ff9800'
        case 'red':
            return '#F44336'
        case 'grey':
            return '#9e9e9e'
        default:
            return 'white'
    }
}

const getColor = (constant) => {
    const color = COLORS_CONSTANTS.find((c) => c.constant == constant || c.color === constant)
    return color ? color.color : 'white'
}

const addBlankTint = (hexaColor) => {
    const hexaParts = [hexaColor.substring(1, 3), hexaColor.substring(3, 5), hexaColor.substring(5)]
    const addedTints = hexaParts.map(hexa => min([parseInt(hexa, 16) + 35, 255]).toString(16))
    return `#${addedTints.join('')}`
}

const getRGBColor = (constant) => {
    const color = COLORS_CONSTANTS.find((c) => c.constant == constant)
    return color ? color.rgb : 'white'
}

const getRGBColorFromColor = (color) => {
    const found = COLORS_CONSTANTS.find((c) => c.color == color)
    return found ? found.rgb : 'white'
}

/* When bool=true => res=GREEN | bool=false => res=RED | bool=undefined => res=GREY  */
const getBooleanColor = (bool) => {
    if (!bool) {
        return (bool===false) ? RED : GREY
    }
    return GREEN
}

const getColorCircleElement = (rgbColor, JSX, obj) => {
    if (JSX) {
        return <span style={ { display: 'inline-block', marginRight: '5px', borderRadius: '10px', width: '9px', height: '9px', backgroundColor: rgbColor } } {...obj}/>
    }
    return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${rgbColor};"></span>`
}

const getColorFromPalette = (index) => {
    return ['#0000ff', '#c23531', '#2f4554', '#61a0a8', '#d48265', '#66c722', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#f775d2'][index % 11]
}

const getColorFromPalette2 = (index) => {
    return ['#2799fa', '#191954', '#127520', '#a93dd4', '#c75c22', '#9f1f1f', '#ca8622', '#26aabd', '#6e7074', '#f775d2', '#66c457'][index % 11]
}

const getColorFromPalettePluvio = (index) => {
    return ['#0000ff', '#009dbd', '#6641d5', '#006776', '#6f7aff', '#00ba8b', '#231f6d', '#007202', '#84b2ff', '#422a78', '#9a3f79'][index % 11]
}

// const getArrestToken = (typeArrest) => {
//     if (typeArrest === i18n.vigilance) {
//         return (<span className='grey black-text arrests-level-panel'>{ i18n.vigilance }</span>)
//     }
//     if(typeArrest === i18n.crisis){
//         return <span className='red black-text arrests-level-panel'>{ i18n.crisis }</span>
//     }
//     if(typeArrest === i18n.reinforcedAlert){
//         return <span className='orange black-text arrests-level-panel'>{ i18n.reinforcedAlert }</span>
//     }
//     if(typeArrest === i18n.warning){
//         return <span className='yellow black-text arrests-level-panel'>{ i18n.warning }</span>
//     }
//     return null
// }

const getArrestColor = typeArrest => {
    switch (typeArrest) {
        case i18n.crisis || 3:
            return 'red'
        case i18n.reinforcedAlert || 2:
            return 'orange'
        case i18n.warning || 1:
            return 'yellow'
        case i18n.vigilance || 0:
            return 'grey'
        default:
            return 'grey'
    }
}

const getVigilanceColor = v => {
    switch (v.trim()) {
        case 'Rouge':
            return 'red'
        case 'Orange':
            return 'orange'
        case 'Jaune':
            return 'yellow'
        default:
            return 'grey'
    }
}

const pickColorModal = (colorList = [], onSelectColor = () => {}, message = i18n.color) => {
    const id = uuidv4()
    const elements = colorList.map((c, i) => (
        <div className='col s4'>
            <span className='arrests-level-panel modal-close' style={ { color: c, 'background-color': c } } onClick={ () => onSelectColor(i) }>O</span>
        </div>
    ))
    const groups = chunk(elements, 3).map(g => (
        <div className='row no-margin'>
            { g }
        </div>
    ))
    const popup = {
        id,
        header: message,
        content: groups,
        className: 'pickColorModal',
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const getThresholdColorCodes = () => [
    THRESHOLD_COLORS_CODES.BLACK,
    THRESHOLD_COLORS_CODES.BLUE,
    THRESHOLD_COLORS_CODES.GREEN,
    THRESHOLD_COLORS_CODES.CYAN,
    THRESHOLD_COLORS_CODES.RED,
    THRESHOLD_COLORS_CODES.DARK_MAGENTA,
    THRESHOLD_COLORS_CODES.YELLOW,
    THRESHOLD_COLORS_CODES.WHITE,
    THRESHOLD_COLORS_CODES.GREY,
    THRESHOLD_COLORS_CODES.LIGHT_BLUE,
    THRESHOLD_COLORS_CODES.LIGHT_GREEN,
    THRESHOLD_COLORS_CODES.LIGHT_CYAN,
    THRESHOLD_COLORS_CODES.INDIANRED,
    THRESHOLD_COLORS_CODES.MAGENTA,
    THRESHOLD_COLORS_CODES.LIGHT_YELLOW,
    THRESHOLD_COLORS_CODES.WHITE_SMOKE,
]

const getThresholdColors = () => [
    'black',
    'blue',
    'green',
    'cyan',
    'red',
    'darkmagenta',
    'yellow',
    'white',
    'grey',
    'lightblue',
    'lightgreen',
    'lightcyan',
    'indianred',
    'magenta',
    'lightyellow',
    'whitesmoke',
]

const getThresholdColor = color => {
    const clrs = getThresholdColors()
    return color < clrs.length ? clrs[color] : clrs[0]
}

const getThresholdColorHtml = threshold => {
    if (threshold.htmlColor) {
        return threshold.htmlColor
    }
    const clrs = [
        '#000000',
        '#0000ff',
        '#008000',
        '#00ffff',
        '#ff0000',
        '#8b008b',
        '#ffff00',
        '#ffffff',
        '#808080',
        '#add8e6',
        '#90ee90',
        '#e0ffff',
        '#cd5c5c',
        '#ff00ff',
        '#ffffe0',
        '#f5f5f5',
    ]
    return threshold.color < clrs.length ? clrs[threshold.color] : clrs[0]
}

const getThresholdColorCode = color => {
    const clrs = getThresholdColorCodes()
    return color < clrs.length ? clrs[color] : clrs[0]
}

const getEventColor = eventType => {
    switch (eventType) {
        case 'M':
            return 'RED'
        case 'G':
            return 'BLUE'
        case 'P':
            return 'YELLOW'
        case 'S':
            return 'ORANGE'
        case 'C':
            return 'DARKGREEN'
        case 'T':
            return 'BROWN'
        default:
            return 'GREY'
    }
}

const getColorCircleEvent = eventType => {
    const rgbColor = (() => {
        switch (eventType) {
            case 'M':
                return 'rgb(255,0,0)'
            case 'G':
                return 'rgb(38,145,255)'
            case 'P':
                return 'rgb(255,236,19)'
            case 'S':
                return 'rgb(255,173,29)'
            case 'C':
                return 'rgb(24,115,17)'
            case 'T':
                return 'rgb(165,42,42)'
            default:
                return 'rgb(136,136,136)'
        }
    })()
    return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${rgbColor};"></span>`
}

const getNewsColor = (newsLevel) => {
    switch (newsLevel) {
        case -1:
            return 'null'
        case 1:
            return 'BLUE'
        case 2:
            return 'GREEN'
        case 3:
            return 'ORANGE'
        case 4:
            return 'RED'
        default:
            return 'null'
    }
}

const hexToRGBColor = hexColor => {
    if (hexColor) {
        const rgb = hexColor.substring(1).match(/.{2}/g).map(x => parseInt(x, 16))
        return `rgb(${rgb.join(',')})`
    }
    return null
}

const RGBToHexColor = (color = '') => {
    const rgb = color.replace('rgb(', '').replace(')', '').split(',')
    return `#${rgb.map(c => {
        const hex = Number(c).toString(16)
        if (hex.length < 2) {
            return `0${hex}`
        }
        return hex
    }).join('')}`
}

const toHex = c => {
    const hex = c.toString(16)
    if (hex.length === 2) {
        return hex
    }
    return `0${hex}`
}

/* Convert an RGB triplet to a hex string */
const convertToHex = (rgb) => {
    return `#${toHex(rgb[0])}${toHex(rgb[1])}${toHex(rgb[2])}`
}

/* Convert a hex string to an RGB triplet */
const convertToRGB = (hex) => {
    const hexPart = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex
    const formattedHex = hexPart.length === 3 ? `${hexPart[0]}${hexPart[0]}${hexPart[1]}${hexPart[1]}${hexPart[2]}${hexPart[2]}` : hexPart
    return [
        parseInt(formattedHex.substring(0, 2), 16),
        parseInt(formattedHex.substring(2, 4), 16),
        parseInt(formattedHex.substring(4, 6), 16),
    ]
}

/* colorStart and colorEnd are hex string */
const generateGradient = (colorStart = '#FFF', colorEnd = '#000', colorCount = 2) => {
    if (colorStart === colorEnd) {
        return new Array(colorCount).fill(colorStart)
    }
    const start = convertToRGB(colorStart)
    const end = convertToRGB(colorEnd)

    const emptyList = new Array(colorCount).fill(undefined)

    return emptyList.map((_, i) => {
        const alpha = i * (1.0 / (colorCount - 1))
        const c = [
            round(end[0] * alpha + (1 - alpha) * start[0]),
            round(end[1] * alpha + (1 - alpha) * start[1]),
            round(end[2] * alpha + (1 - alpha) * start[2]),
        ]
        return convertToHex(c)
    })
}

// listOptions = [[colorStart1, colorEnd1, colorCount1], [colorStart2, colorEnd2, colorCount2], ...]
const generateMultiGradient = (listOptions, shouldSlice = true) => {
    return listOptions.flatMap(([s, e, c], i) => {
        const gradient = generateGradient(s, e, c)
        if (i === 0 || !shouldSlice) {
            return gradient
        }
        return gradient.slice(1)
    })
}

const generateRandomColor = (nbColor) => {
    const getRandom = () => Math.floor(Math.random() * 255)
    return fill(Array(nbColor), 0).map(() => convertToHex([getRandom(), getRandom(), getRandom()]))
}

const defaultContrast = 255

const getContrast = color => {
    const rgb = color.startsWith('#') ? convertToRGB(color) : colors[color]
    if (rgb.some(c => isNil(c) || isNaN(c))) {
        return defaultContrast
    }

    return ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000
}

const getTextColorByBackground = (color) => {
    const yiq = getContrast(color)

    return (yiq > 128) ? darkBlue : mainWhite
}

export {
    hexToRGBColor,
    RGBToHexColor,
    getColor,
    getThresholdColor,
    getEventColor,
    getBooleanColor,
    getRGBColor,
    getColorCircleElement,
    getColorCircleEvent,
    getColorFromPalette,
    getColorFromPalette2,
    getColorFromPalettePluvio,
    getRGBColorFromColor,
    addBlankTint,
    getArrestColor,
    getVigilanceColor,
    getNewsColor,
    getThresholdColors,
    getThresholdColorCodes,
    getThresholdColorCode,
    pickColorModal,
    createLightColor,
    getThresholdColorHtml,
    convertToHex,
    convertToRGB,
    getTextColorByBackground,
    generateGradient,
    generateMultiGradient,
    THRESHOLD_COLORS_CODES,
    generateRandomColor,
}
