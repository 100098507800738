import UserAction from 'administration/components/user/actions/UserAction'
import ProgressBar from 'components/progress/ProgressBar'
import { partition } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import i18n from 'simple-react-i18n'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import ActionComponent from '../../components/ActionComponent'
import Card from '../../components/card/Card'
import HomeAction from '../../home/actions/HomeAction'
import { getFullDate } from '../../utils/DateUtil'
import MessageAction from '../actions/MessageAction'
import GlobalMessageCard from '../components/GlobalMessageCard'
import DtoMessage from '../dto/DtoMessage'

const exportHeaders = ['login', 'updateDate', 'message']

class DashboardMessages extends ActionComponent {
    state = {
        messagesLoaded: false,
        showAnsweredMessages: false,
        showUnansweredMessages: true,
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.messages,
                href: '/messages',
            },
        ])
        const { contacts, users } = this.props
        if (!contacts.length) {
            this.props.fetchContacts()
        }
        if (!users.length) {
            this.props.fetchUsers()
        }
        this.props.fetchLastMessages().then(() => {
            const { listOfLastMessages } = this.props
            this.setState({
                messagesLoaded: true,
            })
            const actions = {
                export: () => {
                    const lastMessagesFiltered = listOfLastMessages.map(obj => {
                        return {
                            ...obj,
                            updateDate: getFullDate(obj.updateDate),
                        }
                    })
                    return {
                        data: lastMessagesFiltered.map(obj => {
                            return {
                                ...obj,
                                headers: exportHeaders,
                            }
                        }),
                        exportType: 'xlsx',
                        titleFile: i18n.messages,
                    }
                },
            }
            this.setActions(actions)
        })
    }

    handleChangeShowAnsweredMessages = () => {
        const { showAnsweredMessages } = this.state
        this.setState({ showAnsweredMessages: !showAnsweredMessages })
    }

    handleChangeShowUnansweredMessages = () => {
        const { showUnansweredMessages } = this.state
        this.setState({ showUnansweredMessages: !showUnansweredMessages })
    }

    getListOfMessages = (listOfMessages) => {
        return listOfMessages.map(message => (
            <GlobalMessageCard
                lastMessage={message}
            />
        ))
    }

    getListOfMessagesByStatus = () => {
        const allLastMessages = this.props.listOfLastMessages
        return partition(allLastMessages, (message) => message.login === message.updateLogin)
    }

    getScrollingBanners = (listMessages, propertieElement, unfoldedMessages, handleChangeShowMessages) => {
        return (
            <div style={{ maxWidth: '1100px', margin: 'auto' }}>
                <div className='card-panel card-list-title margin-top-1 margin-bottom-0 row padding-1 collapsible-header active' style={{ backgroundColor: secondaryBlue, color: 'white' }} onClick={() => handleChangeShowMessages()}>
                    <div className='col s12 no-padding'>
                        <div className='valign-wrapper'>
                            <div className='col s11'>
                                <h6>{`${propertieElement} (${listMessages.length} ${listMessages.length < 2 ? i18n.element : i18n.elements})`}</h6>
                            </div>
                            <div className='col s1'>
                                <i className='material-icons right medium' >{unfoldedMessages ? 'expand_less' : 'expand_more'}</i>
                            </div>
                        </div>
                    </div>
                </div>
                {unfoldedMessages ? (
                    <Card maxWidth={1100} className='transparent no-box-shadow' contentClassName={'transparent'}>
                        {this.getListOfMessages(listMessages)}
                    </Card>
                ):(
                    ''
                )}
            </div>
        )
    }

    render = () => {
        const [unansweredMessages, answeredMessages] = this.getListOfMessagesByStatus()
        const { messagesLoaded, showAnsweredMessages, showUnansweredMessages } = this.state
        return (
            <div id='contents-app' className='margin-top-1'>
                {messagesLoaded ? (
                    <div maxWidth={1100} className='row no-margin'>
                        {this.getScrollingBanners(unansweredMessages, i18n.unansweredMessages, showUnansweredMessages, this.handleChangeShowUnansweredMessages)}
                        {this.getScrollingBanners(answeredMessages, i18n.answeredMessages, showAnsweredMessages, this.handleChangeShowAnsweredMessages)}
                    </div>
                ) : (
                    <div className='row padding-1'>
                        <ProgressBar withMessage message={i18n.messagesWait} indeterminate />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        listOfLastMessages: store.MessagesReducer.listOfLastMessages,
        users: store.UserReducer.users,
        contacts: store.ContactReducer.contacts,
    }
}

const mapDispatchToProps = {
    fetchLastMessages: MessageAction.fetchLastMessages,
    setTitle: HomeAction.setTitle,
    fetchUsers: UserAction.fetchUsers,
    fetchContacts: ContactAction.fetchContacts,
}

DashboardMessages.propTypes = {
    fetchLastMessages: PropTypes.func,
    listOfLastMessages: PropTypes.arrayOf(PropTypes.instanceOf(DtoMessage)),
    setTitle: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMessages)
