import React, { useState } from 'react'
import { floor } from 'lodash'
import PropTypes from 'prop-types'

const PaginatedList = ({
    nbElements,
    nbElementsByPage = 20,
    children,
}) => {
    const [page, setPage] = useState(0)

    const lastPageIndex = floor(nbElements / nbElementsByPage)

    const startId = page * nbElementsByPage
    const endId = (page + 1) * nbElementsByPage

    return (
        <>
            {children(startId, endId)}
            <div className='paging_simple_numbers padding-top-1 padding-left-1 padding-bottom-1'>
                <div className='pagination'>
                    {
                        page !== 0 && (
                            <a className='btn waves-effect waves-light' onClick={() => setPage(0)}>
                                {1}
                            </a>
                        )
                    }
                    {
                        page - 2 > 0 && (
                            <a className='btn waves-effect waves-light'>
                                ...
                            </a>
                        )
                    }
                    {
                        page - 1 > 0 && (
                            <a className='btn waves-effect waves-light' onClick={() => setPage(p => p - 1)}>
                                {page}
                            </a>
                        )
                    }
                    <a className='btn waves-effect waves-light disabled' disabled>
                        {page + 1}
                    </a>
                    {
                        page + 1 < lastPageIndex && (
                            <a className='btn waves-effect waves-light' onClick={() => setPage(p => p + 1)}>
                                {page + 2}
                            </a>
                        )
                    }
                    {
                        page + 2 < lastPageIndex && (
                            <a className='btn waves-effect waves-light'>
                                ...
                            </a>
                        )
                    }
                    {
                        page !== lastPageIndex && (
                            <a className='btn waves-effect waves-light' onClick={() => setPage(lastPageIndex)}>
                                {lastPageIndex + 1}
                            </a>
                        )
                    }
                </div>
            </div>
        </>
    )
}

PaginatedList.propTypes = {
    nbElements: PropTypes.number,
    nbElementsByPage: PropTypes.number,
    children: PropTypes.func,
}

export default PaginatedList