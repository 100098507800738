import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../../../../components/react/Row'
import RadioButtons from '../../../../../components/forms/RadioButtons'
import Checkbox from '../../../../../components/forms/Checkbox'
import { chunk, partition, orderBy, take } from 'lodash'
import { getFrequencyStats, getFrequencyStatsSimple } from '../../utils/SuiviStatsUtils'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import moment from 'moment'
import Button from '../../../../../components/forms/Button'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import Line from '../../../../../components/echart/series/Line'
import PiezometerStationAction from '../../../../../station/actions/PiezometerStationAction'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import WaitAction from '../../../../../wait/WaitAction'
import { arrayOf } from '../../../../../utils/StoreUtils'
import DtoPiezometerChartOptions from '../../../../../station/dto/piezometer/DtoPiezometerChartOptions'
import MultiBand from '../../../../../components/echart/series/MultiBand'
import { round } from '../../../../../utils/NumberUtil'
import { MAP_SITUATION_CLASS } from '../../../../../station/constants/piezo/PiezometerStationConstants'
import { getUser } from 'utils/SettingUtils'

const seriesProps = () => [
    { code: 'ENVELOPPE_AVERAGE', name: i18n.enveloppeAvg, color: 'green', nb: 0.5 },
    { code: 'ENVELOPPE_MEDIAN', name: i18n.enveloppeMedian, color: 'orange' },
    { code: 'ENVELOPPE_MIN', name: i18n.enveloppeMin, color: 'grey', nb: 0 },
    { code: 'ENVELOPPE_MAX', name: i18n.enveloppeMax, color: 'grey', nb: 1 },
]

const PiezoSuiviStatTab2 = ({
    id,
    displayCote,
    histoYears,
    hasValidMeasures, // true si il a des données valides
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent = () => {}, // met à jour les state du parent (dont les séries liées à cette tab)
    piezometerChartOptions,
}) => {
    const dispatch = useDispatch()

    const piezoOption = piezometerChartOptions.find(opt => parseInt(opt.dataType || -1) === -1)

    const [statSeries, setStatSeries] = useState(['ENVELOPPE', 'ENVELOPPE_AVERAGE'])
    const [readyApply, setReadyApply] = useState(false)
    const [validOnly, setValidOnly] = useState(hasValidMeasures)
    const [linearMode, setLinearMode] = useState(false)
    const [startDate, setStartDate] = useState(piezoOption?.statsCalculationStartDate)
    const [endDate, setEndDate] = useState(piezoOption?.statsCalculationEndDate)
    const changeStat = (bool, stat) => {
        if (bool) {
            setStatSeries([...statSeries, stat])
        } else {
            setStatSeries(statSeries.filter(s => s !== stat))
        }
        setReadyApply(true)
    }

    const change = (v, func) => {
        func(v)
        setReadyApply(true)
    }

    const saveOptions = () => {
        const newOptions = [...piezometerChartOptions.filter(opt => parseInt(opt.dataType || -1) !== -1), { ...piezoOption, statsCalculationStartDate: startDate, statsCalculationEndDate: endDate }]
        dispatch(PiezometerStationAction.updatePiezometerChartOptions(id, newOptions))
    }

    const toEchartMeasure = (m) => ({ value: [moment(m[0]).hour(12).valueOf(), displayCote === MEASURE_COTE.NGF ? m[1] : round(landmarkValue - m[1]), { NGF: m[1], depth: round(landmarkValue - m[1]) }], isPiezo: true })

    const recalculateStats = () => {
        dispatch(WaitAction.waitStart())
        const baseInput = {
            stationId: id,
            displayCote: MEASURE_COTE.NGF,
            dataType: -1,
            validOnly,
            calculationStartDate: startDate,
            calculationEndDate: endDate,
            chartMode: true,
        }
        const endEnveloppe = moment().endOf('year').add(6, 'month').valueOf()
        const allInputs = statSeries.reduce((acc, stat) => {
            if (stat === 'ENVELOPPE') {
                return [...acc, { ...baseInput, groupFunc: 'ENVELOPPE_MIN', endEnveloppe }, { ...baseInput, groupFunc: 'ENVELOPPE_MAX', endEnveloppe }]
            }
            if (stat.includes('PERIOD')) {
                return [...acc, { ...baseInput, groupFunc: `${stat}_H`, endEnveloppe }, { ...baseInput, groupFunc: `${stat}_S`, endEnveloppe }]
            }
            return [...acc, { ...baseInput, groupFunc: stat }]
        }, [])
        const promises = allInputs.map(p => PiezometerStationAction.promisePiezoChartMeasures(p))
        return Promise.all(promises).then(jsonTab => {
            const allSeries = allInputs.map((s, idx) => ({ stat: s.groupFunc, json: jsonTab[idx] }))
            const [bandSeries, others] = partition(allSeries, s => {
                if (linearMode) {
                    return false
                }
                return !s.stat.includes('AVERAGE') && !s.stat.includes('MEDIAN') && (s.stat.includes('ENVELOPPE') || s.stat.includes('PERIOD'))
            })
            const periods = allSeries.filter(s => s.stat.includes('PERIOD')).map(s => s.stat.split('_')[1])
            const key = displayCote === MEASURE_COTE.NGF ? 'NGF' : 'depth'

            const bandsWithProps = bandSeries.map(b => ({ ...b, ...[...getFrequencyStats(histoYears, periods), ...seriesProps()].find(p => b.stat.replace('PERIOD', 'fre') === p.code) }))

            const hasPeriods = bandsWithProps.some(s => s.stat.includes('PERIOD'))

            const bands = (() => {
                if (!bandsWithProps.length) {
                    return []
                } else if (!hasPeriods) {
                    // afficher les enveloppes min max uniquement
                    return bandsWithProps.map((b, idx) => ({
                        color: 'grey',
                        isPiezo: true,
                        showSymbol: false,
                        ...b,
                        data: b.json.map(m => toEchartMeasure(m)),
                        idx,
                    }))
                }
                // afficher les mêmes couleurs que sur les cartes de situation
                const statsColors = orderBy(take(MAP_SITUATION_CLASS, bandsWithProps.length + 1), 'order', 'asc')
                const ordered = orderBy(bandsWithProps, 'nb', 'asc')
                const lowerBand = { ...ordered[0], json: ordered[0].json.map(d => [d[0], -1000, d[2], d[3]]), noYScale: true, otherProps: { NGF: { noLegend: true, noTooltip: true }, depth: {} } }
                const upperBand = { ...ordered[ordered.length - 1], json: ordered[0].json.map(d => [d[0], 600, d[2], d[3]]), noYScale: true, otherProps: { NGF: {}, depth: { noLegend: true, noTooltip: true } } }
                const baseTab = ([lowerBand, ...ordered, upperBand])
                const returned = baseTab.map((b, idx) => ({
                    isPiezo: true,
                    showSymbol: false,
                    ...b,
                    initialName: b.name,
                    NGFName: idx === baseTab.length -1 ? `> ${b.name}` : `< ${b.name}`,
                    depthName: idx === baseTab.length -1 ? `> ${baseTab[idx+1]?.name}` : `< ${baseTab[idx+1]?.name}`,
                    depthColor: statsColors[idx]?.chartColor || '#00008b66',
                    NGFColor: statsColors[idx - 1]?.chartColor || '#ff000066',
                    data: b.json.map(m => toEchartMeasure(m)),
                    idx,
                    ...((b.otherProps ?? {})[key] ?? {}),
                    isPeriod: true,
                }))
                return returned.map(b => ({ ...b, name: displayCote === MEASURE_COTE.NGF ? b.NGFName : b.depthName, color: displayCote === MEASURE_COTE.NGF ? b.NGFColor : b.depthColor }))
            })()

            const sortedBands = orderBy(bands, 'idx', displayCote === MEASURE_COTE.NGF ? 'asc' : 'desc')

            if (displayCote === MEASURE_COTE.DEPTH && hasPeriods) {
                sortedBands[1].data = sortedBands[1].data.map(d => ({ ...d, noDepthHackValue: true }))
                sortedBands[1].name = sortedBands[1].name.replace('<', '>')
            }

            const multiBands = MultiBand({ bands: sortedBands, noSort: true, noOpacity: true })


            const otherSeries = others.map(serie => {
                const { stat, json } = serie
                const serieData = json
                const serieParams = [...getFrequencyStats(histoYears, periods), ...seriesProps()].find(p => stat.replace('PERIOD', 'fre') === p.code)
                return Line({
                    data: serieData.map(m => toEchartMeasure(m)),
                    name: serieParams.name,
                    isPiezo: true,
                    connectNulls: false,
                    showSymbol: false,
                    color: serieParams.color,
                    lineStyle: {
                        normal: {
                            color: serieParams.color,
                            width: 1,
                        },
                    },
                })
            })
            changeParent({ statsSeries: [ multiBands, ...otherSeries] })
            setReadyApply(false)
            dispatch(WaitAction.waitStop())
        })
        //     .catch((err) => {
        //     dispatch(LogAction.logError(`Error on suivi piézo stat tab : ${err}`))
        //     dispatch(ToastrAction.error(i18n.loadError))
        //     dispatch(WaitAction.waitStop())
        // })
    }

    useEffect(() => {
        recalculateStats()
    }, [])

    const freChecks = chunk(getFrequencyStatsSimple(histoYears), 2).map(group => (
        <Row>
            <Checkbox col={ 6 } label={ group[0].name } checked={ statSeries.includes(group[0].code.replace('fre', 'PERIOD')) } onChange={ v => changeStat(v, group[0].code.replace('fre', 'PERIOD')) }/>
            { group[1] && <Checkbox col={ 6 } label={ group[1].name } checked={ statSeries.includes(group[1].code.replace('fre', 'PERIOD')) } onChange={ v => changeStat(v, group[1].code.replace('fre', 'PERIOD')) }/> }
        </Row>
    ))
    const validRadioElements = [
        { code: true, name: i18n.yes },
        { code: false, name: i18n.no },
    ]
    const disabled = getUser().consultant === '1'
    return (
        <div>
            <StyledFieldSet>
                <StyledLegend>{ i18n.options }</StyledLegend>
                <Row className='padding-top-1'>
                    <RadioButtons
                        col={ 12 }
                        elements={ validRadioElements }
                        selected={ validOnly }
                        onChange={ v => change(v, setValidOnly) }
                        disabled={ !hasValidMeasures }
                        title={ i18n.calcValidDataOnly }
                    />
                </Row>
                <Row><h5>Dates des données à utiliser pour calculer les statistiques</h5></Row>
                <Row><h6>Si la date de fin est vide, l'année en cours ne sera pas prise en compte pour les calculs des statistiques.</h6></Row>
                <Row>
                    <SimpleDatePicker col={4} value={startDate} label={i18n.startDate} onChange={v => change(v, setStartDate)} disabled={disabled} />
                    <SimpleDatePicker col={4} value={endDate} label={i18n.endDate} onChange={v => change(v, setEndDate)} max={moment().startOf('year').valueOf()} disabled={disabled} />
                    { getUser().consultant !== '1' && <Button col={4} title={i18n.register} onClick={saveOptions} />}
                </Row>
                <Row className='padding-left-1'>
                    <Checkbox checked={ linearMode } onChange={ setLinearMode } label='Mode linéaire' />
                </Row>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>{ i18n.statistics }</StyledLegend>
                <Row>
                    <Checkbox col={ 12 } label={ i18n.enveloppeMinMax } checked={ statSeries.includes('ENVELOPPE') } onChange={ v => changeStat(v, 'ENVELOPPE') }/>
                </Row>
                <Row>
                    <Checkbox col={ 12 } label={ i18n.averageStatistics } checked={ statSeries.includes('ENVELOPPE_AVERAGE') } onChange={ v => changeStat(v, 'ENVELOPPE_AVERAGE') }/>
                </Row>
                <Row>
                    <Checkbox col={ 12 } label={ i18n.median } checked={ statSeries.includes('ENVELOPPE_MEDIAN') } onChange={ v => changeStat(v, 'ENVELOPPE_MEDIAN') }/>
                </Row>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>{ i18n.frequencyStats }</StyledLegend>
                { freChecks }
            </StyledFieldSet>
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={ i18n.apply } onClick={ recalculateStats } icon='border_color' className={`btn-floating btn-large ${readyApply ? 'pulse' : ''}`}/>
            </Row>
        </div>
    )
}

PiezoSuiviStatTab2.propTypes = {
    displayCote: PropTypes.number,
    id: PropTypes.number,
    piezometerChartOptions: arrayOf(DtoPiezometerChartOptions),
    histoYears: PropTypes.number,
    hasValidMeasures: PropTypes.bool,
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
}

export default PiezoSuiviStatTab2