import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../components/forms/Checkbox'
import Job from '../../../import/dto/DtoJob'
import { DEFAULT_TIME_BEFORE_ALERT } from '../../constants/AlertConstants'
import TabSelectThreshold from './TabSelectThreshold'
import SmsModalContact from '../../../administration/components/setting/smsModal/SmsModalContact'
import { Button, Grid } from '@mui/material'
import { getSetting } from '../../../utils/SettingUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import { connect } from 'react-redux'


class JobAlert extends Component {
    state = {
        selected: [],
        emailsString: '',
        contactsIds: undefined,
        isOpenSmsModal: false,
        alertTypes: {
            alertEmail: false,
            alertSms: false,
            alertFacebook: false,
        },
    }

    componentDidMount() {
        const { emails = [], contactsIds = [], parameters = [], alertTypes = [] } = this.props.job.parameters
        const selected = parameters.reduce((acc, param) => {
            const parse = JSON.parse(param)
            const station = this.props.stations.find(s => s.id === parse.stationId) || {}
            return [
                ...acc,
                {
                    code: station.code,
                    id: parse.stationId,
                    threshold: parse.thresholdId,
                    timeLinitMissingData: parse.timeLinitMissingData,
                },
            ]
        }, [])
        const emailsString = emails.join(';')
        const contactsIdsFormat = contactsIds.join(';')
        const alert = alertTypes.reduce((acc, a) => ({ ...acc, [a]: true }), {})
        this.setState({ selected, emailsString, contactsIds: contactsIdsFormat, alertTypes: alert })
    }

    componentDidUpdate = prevProps => {
        if (this.props.stations.length !== prevProps.stations.length) {
            this.setState(({ selected }) => ({
                selected: selected.map(station => {
                    const stationFound = this.props.stations.find(s => s.id === station.id) || {}
                    return {
                        ...station,
                        code: stationFound.code,
                    }
                }),
            }))
        }
    }

    handleChangeEmails = event => {
        const emailsString = event.target.value
        this.setState({ emailsString })
        const emailsList = emailsString ? emailsString.split(';') : []
        this.props.onChangeEmails(emailsList.filter(e => e))
    }

    handleChangeStation = ({ id, code }, index) => {
        const selected = this.state.selected.map((s, i) => {
            if (i === index) {
                return { id, code, timeLinitMissingData: DEFAULT_TIME_BEFORE_ALERT }
            }
            return s
        })
        this.setState({ selected })
    }

    handleChange = (key, value, index) => {
        const selected = this.state.selected.map((s, i) => {
            if (i === index) {
                return { ...s, [key]: value }
            }
            return s
        })
        this.setState({ selected })
        this.changeJobStations(selected)
    }

    changeJobStations = stationsSelected => {
        const stationSelected = stationsSelected.filter(e => e.id).map(({ id, threshold, timeLinitMissingData }) => JSON.stringify({
            stationId: id,
            thresholdId: threshold,
            timeLinitMissingData,
        }))
        this.props.onChangeStation(stationSelected)
    }

    handleDeleteStation = (_, index) => {
        const selected = this.state.selected.filter((__, i) => i !== index)
        this.setState({ selected })
        this.changeJobStations(selected)
    }

    handleDuplicateStation = (_, index) => {
        const { id, code, timeLinitMissingData } = this.state.selected[index]
        const selected = [...this.state.selected, { id, code, timeLinitMissingData }]
        this.setState({ selected })
    }

    handleChangeAlertMail = alert => {
        const alertTypes = { ...this.state.alertTypes, [alert]: !this.state.alertTypes[alert] }
        this.setState({ alertTypes, emailsString: '' })
        this.props.onChangeEmails([])
        this.props.onChangeAlertTypes(Object.keys(alertTypes).filter(a => alertTypes[a]))
    }

    handleChangeAlertSms = alert => {
        const alertTypes = { ...this.state.alertTypes, [alert]: !this.state.alertTypes[alert] }
        this.setState({ alertTypes, contactsIds: undefined })
        this.props.onChangeContactsIds(undefined)
        this.props.onChangeAlertTypes(Object.keys(alertTypes).filter(a => alertTypes[a]))
    }

    handleChangeAlertFacebook = alert => {
        const alertTypes = { ...this.state.alertTypes, [alert]: !this.state.alertTypes[alert] }
        this.setState({ alertTypes })
        this.props.onChangeAlertTypes(Object.keys(alertTypes).filter(a => alertTypes[a]))
    }

    getInputEmail = () => (
        <div className='col s12 no-margin'>
            <div className='col s2 no-padding'>
                <div className='right'>
                    <label htmlFor='emailSelection'>{i18n.emailSelection}</label>
                </div>
            </div>
            <div className='input-field col s12 no-margin no-padding'>
                <div className='col s12'>
                    <input
                        type='text'
                        value={this.state.emailsString}
                        onChange={this.handleChangeEmails}
                        disabled={!this.props.isEditMode}
                    />
                </div>
            </div>
        </div>
    )

    getInputSms = () => (
        <div className='col s12 no-margin padding-bottom-1'>
            <div className='input-field col s12 no-margin no-padding'>
                {
                    ['smsFactor', 'sendEthic'].includes(getSetting(this.props.applicationSettings, 'smsSettings')) ?
                        <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                            <Grid item xs={1.5}>
                                <Button
                                    onClick={() => this.setState({ isOpenSmsModal: true })}
                                    variant='contained'
                                    color='primary'
                                    disabled={!this.props.isEditMode}
                                >
                                    { i18n.smsSelection }
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                {i18n.contacts} : {this.props.job?.parameters?.contactsIds?.length ?? 0}
                            </Grid>
                        </Grid> : i18n.noParameter
                }
                {
                    <SmsModalContact
                        contactsIdsParameters={this.props.job.parameters.contactsIds}
                        isOpen={this.state.isOpenSmsModal}
                        onClose={() => this.setState({ isOpenSmsModal: false })}
                        setContactsIdsParameters={ this.props.onChangeContactsIds }
                    />
                }
            </div>
        </div>
    )


    render() {
        const addAction = this.props.isEditMode ? [{
            onClick: () => this.setState({ selected: [...this.state.selected, {}] }),
            iconName: 'add_box',
            tooltip: `${i18n.addAlert}`,
        }] : []

        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s12 no-margin padding-bottom-2'>
                        <div className='col s3 no-padding'>
                            <Checkbox
                                checked={this.state.alertTypes.alertEmail}
                                label={i18n.emailAlert}
                                onChange={() => this.handleChangeAlertMail('alertEmail')}
                                disabled={!this.props.isEditMode}
                            />
                        </div>
                        <div className='col s3 no-padding'>
                            <Checkbox
                                checked={this.state.alertTypes.alertSms}
                                label={'SMS'}
                                onChange={() => this.handleChangeAlertSms('alertSms')}
                                disabled={!this.props.isEditMode}
                            />
                        </div>
                        <div className='col s3 no-padding'>
                            <Checkbox
                                checked={this.state.alertTypes.alertFacebook}
                                label={i18n.postFacebook}
                                onChange={() => this.handleChangeAlertFacebook('alertFacebook')}
                                disabled={!this.props.isEditMode}
                            />
                        </div>
                    </div>
                    {
                        !!this.state.alertTypes.alertEmail && this.getInputEmail()
                    }
                    {
                        !!this.state.alertTypes.alertSms && this.getInputSms()
                    }
                </div>
                <div className='row no-margin'>
                    <TabSelectThreshold
                        stations={this.props.stations}
                        thresholds={this.props.thresholds}
                        stationsSelected={this.state.selected}
                        onChangeStation={this.handleChangeStation}
                        onChange={this.handleChange}
                        onDeleteStation={this.handleDeleteStation}
                        onDuplicateStation={this.handleDuplicateStation}
                        disabled={!this.props.isEditMode}
                        actions={ addAction }
                        exportButtonOnHeader
                    />
                </div>
            </div>
        )
    }
}

JobAlert.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    stations: PropTypes.arrayOf(PropTypes.object),
    thresholds: PropTypes.arrayOf(PropTypes.object),
    applicationSettings: arrayOf(SieauParameterDto),
}
const mapStateToProps = store => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps)(JobAlert)