import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SieauAction from '../../../../components/sieau/SieauAction'
import TelecomAction from '../actions/TelecomAction'
import TelecomPanel from './TelecomPanel'
import TelecomDto from '../dto/TelecomDto'
import i18n from 'simple-react-i18n'
import { find, isEqual, omit } from 'lodash'
import TelecomTypeDto from '../dto/TelecomTypeDto'
import SimTablePanel from './tables/SimTablePanel'
import ToastrAction from 'toastr/actions/ToastrAction'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import ActionComponent from '../../../../components/ActionComponent'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import DtoTelecomSituation from '../dto/DtoTelecomSituation'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import SituationPanel from '../../situation/SituationPanel'
import DtoPicture from '../../../../components/picture/dto/DtoPicture'
import SmallPicturePanel from '../../../../components/picture/SmallPicturePanel'
import FilePanel from '../../../../components/file/FilePanel'
import DtoFile from '../../../../components/file/dto/DtoFile'
import { push } from 'connected-react-router'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import User from '../../../../account/dto/User'
import { getUser } from '../../../../utils/SettingUtils'
import PowerSupplyAction from '../../powerSupply/actions/PowerSupplyAction'
import AppStore from 'store/AppStore'
import HomeAction from 'home/actions/HomeAction'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import { isSaveBlocked } from '../../../utils/MaterielUtils'
import DtoMaterielSettingRule from '../../../dto/DtoMaterielSettingRule'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_MAT_TELECOM } from '../../../../account/constants/AccessRulesConstants'
import { TELECOM_ID } from 'materiel/constants/MaterielConstants'

class TelecomApp extends ActionComponent {
    state = {
        telecom: {},
        isEditMode: false,
    }

    changeAdministrator = administrator => this.setState(({ telecom }) => ({ telecom: { ...telecom, administrator } }))

    fetchTelecom = id => {
        this.props.fetchTelecom(id).then(json => {
            if (json.id) {
                this.props.fetchTelecomSituations(json.id)
                this.props.fetchTelecomPictures(json.id)
                this.props.fetchTelecomFiles(json.imei)
                this.setState({ telecom: { ...json } })
            }
        })
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_TELECOM)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.match.params.id !== 'new') {
            this.fetchTelecom(this.props.match.params.id)
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.telecoms.length) {
            this.props.fetchTelecoms(true)
        }
        if (!this.props.telecomTypes.length) {
            this.props.fetchTelecomTypes()
        }
        if (!this.props.powerSupplyTypes.length) {
            this.props.fetchPowerSupplyTypes()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        this.applyActions()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchTelecom(this.props.match.params.id)
        }
        if (!isEqual(prevProps.telecom, this.props.telecom) || !isEqual(prevProps.telecomTypes, this.props.telecomTypes)) {
            const {
                imei,
                reference = '',
                serialNumber = '',
                telecomType,
                id,
            } = this.props.telecom

            const title = imei || serialNumber || reference || id
            const type = (this.props.telecomTypes.find(t => t.code === parseInt(telecomType)) || {}).label || i18n.notDefined
            this.props.forceFetch('title', [
                {
                    title: i18n.materiels,
                    href: 'materiel',
                },
                {
                    title: i18n.telecoms,
                    href: 'materiel/telecom',
                },
                {
                    title: type,
                    href: `materiel/telecom/${this.props.match.params.id}`,
                },
                {
                    title,
                    href: `materiel/telecom/${this.props.match.params.id}`,
                },
            ])
        }
        this.applyActions()
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.match.params.id === 'new' && (!this.props.telecom || !this.props.telecom.id)) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === TELECOM_ID)
                        if (isSaveBlocked(this.props.telecoms, this.state.telecom, rules, 'telecomType')) return

                        this.props.createTelecom(this.state.telecom).then(id => {
                            if (id) {
                                const situation = {
                                    idTelecom: id,
                                    situationDate: this.state.telecom.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.saveTelecomSituation(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push('/materiel/telecom')
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === TELECOM_ID)
                        if (isSaveBlocked(this.props.telecoms, this.state.telecom, rules, 'telecomType')) return

                        this.props.updateTelecom(this.state.telecom).then(success => success && this.changeEditMode(false))
                    },
                    cancel: () => {
                        this.setState({ central: this.props.central })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deleteTelecom(this.props.match.params.id)
                },
                replace: () => {
                    this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentWillUnmount = () => {
        this.props.resetTelecom()
        this.props.resetAllActions()
    }

    changeTelecomValue = changes => this.setState({ telecom: { ...this.state.telecom, ...changes } })

    initSimTable = () => (
        <div>
            <div className='row no-margin padding-top-12-px'>
                <div className='col s12'>
                    <SimTablePanel active={ this.state.isEditMode } />
                </div>
            </div>
        </div>
    )

    getSimTable = () => {
        if (this.state.telecom.telecomType) {
            const telecomType = find(this.props.telecomTypes, (o) => {
                return o.id === this.state.telecom.telecomType
            })
            if (telecomType) {
                switch (telecomType.code) {
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        return this.initSimTable()
                    default:
                        return null
                }
            }
            return null
        }
        return null
    }

    deleteSituation = id => this.props.deleteTelecomSituation(id, this.props.match.params.id)

    getMaterielNumber = ({ serialNumber, reference, imei }) => imei || serialNumber || reference || ''

    getTelecomsFormat = () => {
        const { telecoms, telecomTypes } = this.props
        return telecoms.filter(({ isEquipment }) => !isEquipment).map(telecom => {
            const label = (() => {
                const number = this.getMaterielNumber(telecom)
                if (telecom.telecomType) {
                    const type = telecomTypes.find(t => t.id === telecom.telecomType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: telecom.id,
                label,
            }
        })
    }

    render() {
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }
        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel materielSituation={ this.props.telecomSituations } />
                                    </div>
                                    <div className='col s11'>
                                        <TelecomPanel
                                            telecom={ this.state.telecom }
                                            onChange={ this.changeTelecomValue }
                                            { ...disabled }
                                        />
                                        {
                                            !this.props.telecom.isEquipment && (
                                                <div className='col s12'>
                                                    {this.getSimTable()}
                                                    <SituationPanel
                                                        materielSituation={this.props.telecomSituations}
                                                        active={this.state.isEditMode}
                                                        idMateriel={this.props.telecom.id}
                                                        serialNumber={this.props.telecom.serialNumber}
                                                        purchaseDate={this.props.telecom.purchaseDate}
                                                        idKey={'idTelecom'}
                                                        lastSituations={this.props.telecomsLastSituations}
                                                        materials={this.getTelecomsFormat()}
                                                        saveFunction={this.props.saveTelecomSituation}
                                                        deleteFunction={this.deleteSituation}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.props.telecom.updateDate}
                                                updateLogin={this.props.telecom.updateLogin}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.telecom.administrator}
                                                        isActive={!this.props.telecom.isEquipment && this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel materielSituation={ this.props.telecomSituations } />
                                            <SmallPicturePanel element={ 'telecom' }
                                                pictures={ this.props.telecomPictures }
                                            />
                                            <FilePanel files={ this.props.telecomFiles } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

TelecomApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    push: PropTypes.func,
    getLink: PropTypes.func,
    resetAllActions: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    telecom: PropTypes.instanceOf(TelecomDto),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    telecoms: PropTypes.arrayOf(PropTypes.instanceOf(TelecomDto)),
    telecomsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecomSituation)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    telecomSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecomSituation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    telecomPictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    telecomFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
}

const mapStateToProps = store => ({
    telecomTypes: store.TelecomReducer.telecomTypes,
    telecom: store.TelecomReducer.telecom,
    telecomSituations: store.TelecomReducer.telecomSituations,
    materielStates: store.MaterielReducer.materielStates,
    contributors: store.ContributorReducer.contributors,
    telecomPictures: store.TelecomReducer.telecomPictures,
    telecomFiles: store.TelecomReducer.telecomFiles,
    accountUser: store.AccountReducer.accountUser,
    telecoms: store.TelecomReducer.telecoms,
    telecomsLastSituations: store.TelecomReducer.telecomsLastSituations,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProps = {
    push,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    fetchTelecom: TelecomAction.fetchTelecom,
    forceFetch: SieauAction.forceFetch,
    createTelecom: TelecomAction.createTelecom,
    updateTelecom: TelecomAction.updateTelecom,
    deleteTelecom: TelecomAction.deleteTelecom,
    toastrInfo: ToastrAction.info,
    resetTelecom: TelecomAction.resetTelecom,
    deleteTelecomSituation: TelecomAction.deleteTelecomSituation,
    fetchTelecomSituations: TelecomAction.fetchTelecomSituations,
    fetchTelecomPictures: TelecomAction.fetchTelecomPictures,
    fetchTelecomFiles: TelecomAction.fetchTelecomFiles,
    saveTelecomSituation: TelecomAction.saveTelecomSituation,
    fetchTelecoms: TelecomAction.fetchTelecoms,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(TelecomApp)
