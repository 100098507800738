import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { styled } from '@mui/styles'
import Icon from 'components/icon/Icon'

export const AccordionMUI = styled((props) => (
    <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: !props.noUnmount, timeout: 250 }}
        {...props}
    />))({
    borderRadius: (props) => props.round ? '5px' : 0,
    boxShadow: (props) => props.boxShadow && '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
})

export const AccordionSummaryMUI = styled(({ sx, iconColor, ...othenProps }) => (
    <AccordionSummary
        expandIcon={
            <Icon
                size='small'
                icon='expand_more'
                style={{
                    borderRadius: '50%',
                    border: `solid ${iconColor || 'white'} 2px`,
                    color: iconColor || 'white',
                }}
            />
        }
        sx={{
            color: 'white',
            background: (theme) => othenProps.color || theme.palette.primary.main,
            borderBottom: 'solid 1px grey',
            minHeight: 40,
            height: 40,
            fontSize: '15px',
            fontWeight: 'bold',
            paddingLeft: '25',
            borderRadius: othenProps.round ? '5px' : 0,
            ...sx,
        }}
        {...othenProps}
    />))({
})

export const AccordionDetailsMUI = styled(AccordionDetails)({
    padding: (props) => (props.nopadding ? 0 : 'auto'), // nopadding create a warning, use this instead: sx={{ padding: 0 }}
})