import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isEqual, omit, orderBy } from 'lodash'
import DtoMaterielSettingRule from 'materiel/dto/DtoMaterielSettingRule'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_MAT_CENTRAL } from '../../../../account/constants/AccessRulesConstants'
import User from '../../../../account/dto/User'
import ActionComponent from '../../../../components/ActionComponent'
import DtoFile from '../../../../components/file/dto/DtoFile'
import FilePanel from '../../../../components/file/FilePanel'
import DtoPicture from '../../../../components/picture/dto/DtoPicture'
import SmallPicturePanel from '../../../../components/picture/SmallPicturePanel'
import SieauAction from '../../../../components/sieau/SieauAction'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import { PATH_MATERIEL_CENTRAL, PATH_MATERIEL_LINK } from '../../../../home/constants/RouteConstants'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import { getCentralSetting, isSaveBlocked } from '../../../utils/MaterielUtils'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import PowerSupplyAction from '../../powerSupply/actions/PowerSupplyAction'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import SituationPanel from '../../situation/SituationPanel'
import TelecomAction from '../../telecom/actions/TelecomAction'
import CentralAction from '../actions/CentralAction'
import CentralDto from '../dto/CentralDto'
import DtoCentralChannel from '../dto/DtoCentralChannel'
import DtoCentralRange from '../dto/DtoCentralRange'
import DtoCentralSituation from '../dto/DtoCentralSituation'
import CentralPanel from './CentralPanel'
import ChannelPanel from './channel/ChannelPanel'
import RangePanel from './range/RangePanel'
import { CENTRAL_ID } from 'materiel/constants/MaterielConstants'

class CentralApp extends ActionComponent {
    state = {
        central: {},
        previousCentralChannels: [],
        previousCentralSetting: {},
        isEditMode: false,
    }

    changeAdministrator = administrator => this.setState(({ central }) => ({ central: { ...central, administrator } }))

    changeStateAttribute = changes => this.setState(({ central }) => ({ central: { ...central, ...changes } }))

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentWillUnmount = () => {
        this.props.resetCentral()
        this.props.resetAllActions()
    }

    fetchCentral = id => this.props.fetchCentral(id).then(json => {
        if (json.id) {
            this.props.fetchCentralSituations(json.id)
            this.props.fetchCentralRanges(json.id)
            this.props.fetchCentralChannels(json.id)
            this.props.fetchCentralPictures(json.id)
            this.props.fetchCentralFiles(json.serialNumber)
            this.setState({ central: { ...json } })
        }
    })

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_CENTRAL)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.match.params.id !== 'new') {
            this.fetchCentral(this.props.match.params.id)
            this.changeEditMode(false)
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.centrals.length) {
            this.props.fetchCentrals(true)
        }
        if (!this.props.centralTypes.length) {
            this.props.fetchCentralTypes()
        }
        if (!this.props.powerSupplyTypes.length) {
            this.props.fetchPowerSupplyTypes()
        }
        if (!this.props.telecomTypes.length) {
            this.props.fetchTelecomTypes()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        this.applyActions()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchCentral(this.props.match.params.id)
        }
        if (!isEqual(prevProps.central, this.props.central) || !isEqual(prevProps.centralTypes, this.props.centralTypes)) {
            const {
                reference = '',
                serialNumber = '',
                centralType,
                id,
            } = this.props.central
            const title = reference || serialNumber || id
            const type = (this.props.centralTypes.find(t => t.code === parseInt(centralType)) || {}).label || i18n.notDefined
            this.props.forceFetch('title', [
                {
                    title: i18n.materiels,
                    href: 'materiel',
                },
                {
                    title: i18n.centrals,
                    href: 'materiel/central',
                },
                {
                    title: type,
                    href: `materiel/central/${this.props.match.params.id}`,
                },
                {
                    title,
                    href: `materiel/central/${this.props.match.params.id}`,
                },
            ])
        }
        this.applyActions()
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.match.params.id === 'new' && (!this.props.central || !this.props.central.id)) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === CENTRAL_ID)
                        if (isSaveBlocked(this.props.centrals, this.state.central, rules, 'centralType')) return

                        this.props.createCentral(this.state.central).then(id => {
                            if (id) {
                                const situation = {
                                    idCentral: id,
                                    situationDate: this.state.central.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.saveCentralSituations(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push(PATH_MATERIEL_LINK + PATH_MATERIEL_CENTRAL)
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === CENTRAL_ID)
                        if (isSaveBlocked(this.props.centrals, this.state.central, rules, 'centralType')) return

                        this.props.updateCentral(this.state.central).then(success => success && this.changeEditMode(false))
                    },
                    cancel: () => {
                        this.setState({ central: this.props.central })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deleteCentral(this.props.match.params.id)
                },
                replace: () => {
                    this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    getRangeAndChannelValues = () => {
        const lastSituationOnSite = (() => {
            if (this.props.centralSituations.length) {
                const lastSituation = orderBy(this.props.centralSituations, 'id', 'desc')[0]
                if (lastSituation && lastSituation.statusCode === 1) {
                    return true
                }
            }
            return false
        })()
        if (lastSituationOnSite) {
            return (
                <div>
                    <RangePanel
                        active={ this.state.isEditMode }
                        centralRanges={ this.props.centralRanges }
                    />
                    <ChannelPanel
                        central={ this.props.central }
                        active={ this.state.isEditMode }
                        centralChannels={ this.props.centralChannels }
                        refreshDataFunction={ () => null }
                    />
                </div>)
        }
        return null
    }

    deleteSituation = id => this.props.deleteCentralSituation(id, this.props.match.params.id)

    getMaterielNumber = ({ serialNumber, reference }) => serialNumber || reference || ''

    getCentralsFormat = () => {
        const { centrals, centralTypes } = this.props
        return centrals.filter(({ isEquipment }) => !isEquipment).map(central => {
            const label = (() => {
                const number = this.getMaterielNumber(central)
                if (central.centralType) {
                    const type = centralTypes.find(t => t.id === central.centralType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: central.id,
                label,
            }
        })
    }

    fetchPreviousParam = previousId => {
        this.props.fetchPreviousCentral(previousId).then(json => {
            this.props.fetchPreviousCentralChannels(json.id).then(channels =>
                this.setState({ previousCentralChannels: channels }))
            this.setState({ previousCentralSetting: getCentralSetting(json) })
        })
    }

    onSavePreviousParam = () => {
        this.props.updateCentral({ ...this.state.central, ...this.state.previousCentralSetting })
        this.props.updateAllCentralChannel(this.state.central.id, this.state.previousCentralChannels)
    }

    render = () => {
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }
        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel materielSituation={ this.props.centralSituations } />
                                    </div>
                                    <div className='col s11'>
                                        <div className='row no-margin padding-top-3-px'>
                                            <CentralPanel
                                                central={ this.state.central }
                                                onChange={ this.changeStateAttribute }
                                                { ...disabled }
                                            />
                                            <div className='col s12'>
                                                { this.getRangeAndChannelValues() }
                                                {
                                                    !this.state.central.isEquipment && (
                                                        <SituationPanel
                                                            materielSituation={this.props.centralSituations}
                                                            active={this.state.isEditMode}
                                                            idMateriel={this.props.central.id}
                                                            serialNumber={this.props.central.serialNumber}
                                                            purchaseDate={this.props.central.purchaseDate}
                                                            idKey={'idCentral'}
                                                            isCentral={true}
                                                            lastSituations={this.props.centralsLastSituations}
                                                            fetchPreviousParam={this.fetchPreviousParam}
                                                            materials={this.getCentralsFormat()}
                                                            saveFunction={this.props.saveCentralSituations}
                                                            savePreviousParam={this.onSavePreviousParam}
                                                            deleteFunction={this.deleteSituation}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.props.central.updateDate}
                                                updateLogin={this.props.central.updateLogin}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.central.administrator}
                                                        isActive={!this.state.central.isEquipment && this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel materielSituation={ this.props.centralSituations } />
                                            <SmallPicturePanel element={ 'central' }
                                                pictures={ this.props.centralPictures }
                                            />
                                            <FilePanel files={ this.props.centralFiles } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CentralApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
    }),
    central: PropTypes.instanceOf(CentralDto),
    getLink: PropTypes.func,
    resetAllActions: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(GenericReferencialDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    centralSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralSituation)),
    centralRanges: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralRange)),
    centralChannels: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralChannel)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    telecomTypes: PropTypes.arrayOf(GenericReferencialDto),
    centralPictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    centralFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    centralsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralSituation)),
    centrals: PropTypes.arrayOf(PropTypes.instanceOf(CentralDto)),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    central: store.CentralReducer.central,
    centralChannels: store.CentralReducer.centralChannels,
    centralTypes: store.CentralReducer.centralTypes,
    centralSituations: store.CentralReducer.centralSituations,
    materielStates: store.MaterielReducer.materielStates,
    centralRanges: store.CentralReducer.centralRanges,
    centrals: store.CentralReducer.centrals,
    centralsLastSituations: store.CentralReducer.centralsLastSituations,

    contributors: store.ContributorReducer.contributors,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    telecomTypes: store.TelecomReducer.telecomTypes,
    centralPictures: store.CentralReducer.centralPictures,
    centralFiles: store.CentralReducer.centralFiles,
    accountUser: store.AccountReducer.accountUser,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProps = {
    push,
    resetCentral: CentralAction.resetCentral,
    fetchCentral: CentralAction.fetchCentral,
    fetchPreviousCentral: CentralAction.fetchPreviousCentral,
    fetchCentralTypes: CentralAction.fetchCentralTypes,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    forceFetch: SieauAction.forceFetch,
    createCentral: CentralAction.createCentral,
    updateCentral: CentralAction.updateCentral,
    deleteCentral: CentralAction.deleteCentral,
    updateAllCentralChannel: CentralAction.updateAllCentralChannel,
    toastrInfo: ToastrAction.info,
    deleteCentralSituation: CentralAction.deleteCentralSituation,
    fetchCentralSituations: CentralAction.fetchCentralSituations,
    fetchCentralRanges: CentralAction.fetchCentralRanges,
    fetchCentralChannels: CentralAction.fetchCentralChannels,
    fetchPreviousCentralChannels: CentralAction.fetchPreviousCentralChannels,
    fetchCentralPictures: CentralAction.fetchCentralPictures,
    fetchCentralFiles: CentralAction.fetchCentralFiles,
    fetchCentrals: CentralAction.fetchCentrals,
    saveCentralSituations: CentralAction.saveCentralSituations,
}

export default connect(mapStateToProps, mapDispatchToProps)(CentralApp)
