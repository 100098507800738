import { difference } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SelectionTable from '../../../../components/datatable/SelectionTable'
import Input from '../../../../components/forms/Input'
import Icon from '../../../../components/icon/Icon'
import SelectionWithParamDto from '../../../../referencial/components/parameter/dto/SelectionWithParamDto'
import { searchAllCharacters } from '../../../../utils/StringUtil'


class SelectSelection extends Component {
    constructor(props) {
        super(props)
        const selectedSelections = [...props.selectedSelections]
        const listSelections = difference(props.selections.map(({ code }) => code), selectedSelections)
        this.state = {
            selectedSelections,
            listSelections,
            searchValue: '',
        }
    }

    addAll = listSelect => {
        const {
            selectedSelections,
            listSelections,
        } = this.state
        const {
            addAll,
        } = this.props
        const listSelectionsCode = listSelect.map(({ code }) => code)
        const newSelectionsCode = [...selectedSelections, ...listSelectionsCode]
        const newListSelections = difference(listSelections, listSelectionsCode)
        this.setState({ selectedSelections: newSelectionsCode, listSelections: newListSelections })
        addAll(listSelectionsCode)
    }

    deleteAll = () => {
        const {
            selections,
            deleteAll,
        } = this.props
        this.setState({ selectedSelections: [], listSelections: selections.map(({ code }) => code) })
        deleteAll()
    }

    onAdd = ({ code }) => {
        const {
            selectedSelections,
            listSelections,
        } = this.state
        const newSelectionsCode = [...selectedSelections, code]
        const newListSelections = listSelections.filter(selectionCode => selectionCode !== code)
        this.setState({ selectedSelections: newSelectionsCode, listSelections: newListSelections })
        this.props.onChange(code, false)
    }

    onDelete = ({ code }) => {
        const {
            selectedSelections,
            listSelections,
        } = this.state
        const newSelectionsCode = selectedSelections.filter(selectionCode => selectionCode !== code)
        const newListSelections = [...listSelections, code]
        this.setState({ selectedSelections: newSelectionsCode, listSelections: newListSelections })
        this.props.onChange(code, true)
    }

    render = () => {
        const {
            selectedSelections,
            listSelections,
            searchValue,
        } = this.state
        const {
            onCreate,
            selections,
        } = this.props

        const searchValueFormat = searchAllCharacters(searchValue)

        const selectedSelectionFormated = selectedSelections.map(selectionCode => {
            const {
                name = '',
                parameters = [],
            } = selections.find(({ code }) => selectionCode === code) || {}
            return {
                code: selectionCode,
                name,
                nbParam: `${parameters.length}`,
            }
        })

        const listSelectionsFormated = listSelections.map(selectionCode => {
            const {
                name = '',
                parameters = [],
            } = selections.find(({ code }) => selectionCode === code) || {}
            return {
                code: selectionCode,
                name,
                nbParam: `${parameters.length}`,
            }
        })

        const filteredListSelections = searchValue ? listSelectionsFormated.filter(({ name }) => searchAllCharacters(name).includes(searchValueFormat)) : listSelectionsFormated

        return (
            <>
                <div className='row no-margin padding-top-1 valign-wrapper'>
                    <Input
                        col={11}
                        title={i18n.search}
                        value={searchValue}
                        onChange={v => this.setState({ searchValue: v })}
                    />
                    <Icon
                        className='col s1'
                        size='small'
                        icon='note_add'
                        onClick={onCreate}
                        tooltip={i18n.createNewSelection}
                    />
                </div>

                <SelectionTable
                    maxHeight='50vh'

                    listData={filteredListSelections}
                    listHeaders={['name', 'nbParam']}
                    listTitle={i18n.nonSelectedSelections}

                    selectedData={selectedSelectionFormated}
                    selectedHeaders={['name', 'nbParam']}
                    selectedTitle={i18n.selectedSelections}

                    onAdd={this.onAdd}
                    addAll={this.addAll}
                    onDelete={this.onDelete}
                    deleteAll={this.deleteAll}
                />
            </>
        )
    }
}

SelectSelection.propTypes = {
    selectedSelections: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
    addAll: PropTypes.func,
    deleteAll: PropTypes.func,
    selections: PropTypes.arrayOf(PropTypes.instanceOf(SelectionWithParamDto)),
}

const mapStateToProps = store => ({
    selections: store.ParameterReducer.selectionsWithParam,
})

export default connect(mapStateToProps)(SelectSelection)