import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { H_PERIMETERS_DASHBOARD } from '../account/constants/AccessRulesConstants'
import { componentHasHabilitations } from '../utils/HabilitationUtil'
import FixedSlideNav from '../components/navbar/FixedSlideNav'
import BoundaryError from '../log/components/BoundaryError'
import { Redirect, Route, Switch } from 'react-router-dom'
import PerimetersDashboard from './components/perimeters/PerimetersDashboard'
import PerimeterDashboard from './components/perimeter/PerimeterDashboard'
import PropTypes from 'prop-types'

const PerimetersApp = ({ match }) => {
    const { id } = match.params

    const navLinks = useMemo(() => {
        const links = id ? [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: `/perimeters/perimeter/${id}/dashboard`,
            perimeters: H_PERIMETERS_DASHBOARD,
        }, {
            icons: 'map',
            name: i18n.map,
            href: `/perimeters/perimeter/${id}/map`,
            perimeters: H_PERIMETERS_DASHBOARD,
        }, {
            icons: 'events',
            name: i18n.events,
            href: `/perimeters/perimeter/${id}/events`,
            perimeters: H_PERIMETERS_DASHBOARD,
        }, {
            icons: 'perm_media',
            name: i18n.documents,
            href: `/perimeters/perimeter/${id}/documents`,
            perimeters: H_PERIMETERS_DASHBOARD,
        }] : [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: '/perimeters/dashboard',
            perimeters: H_PERIMETERS_DASHBOARD,
        }]

        return links.filter(l => componentHasHabilitations(l.perimeters))
    }, [id])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks}/>
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/perimeters' to='/perimeters/dashboard'/>
                                <Redirect exact from='/perimeters/perimeter/:id' to='/perimeters/perimeter/:id/dashboard'/>
                                <Route path='/perimeters/dashboard'
                                    render={(props) => <PerimetersDashboard {...props} />}
                                />
                                <Route path='/perimeters/perimeter/:id/dashboard'
                                    render={(props) => <PerimeterDashboard {...props} />}
                                />
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

PerimetersApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default PerimetersApp