import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { push } from 'connected-react-router'
import { flatten, some, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import ResourceAction from 'resource/actions/ResourceAction'
import i18n from 'simple-react-i18n'
import {
    H_RESOURCE_DASHBOARD,
} from '../../../account/constants/AccessRulesConstants'
import UserAction from '../../../administration/components/user/actions/UserAction'
import HomeAction from '../../../home/actions/HomeAction'
import QualityAction from '../../../quality/actions/QualityAction'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getUser } from '../../../utils/SettingUtils'
import {
    findStationType,
} from '../../../utils/StationUtils'

import { getSandreLabel, searchAllCharacters } from '../../../utils/StringUtil'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import StationAction from '../../../station/actions/StationAction'
import {
    MAP,
    SAMPLE_LIST,
    STATION_LIST,
    STATION_TYPE_NAME,
} from '../../../station/constants/StationConstants'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import TabList from 'components/list/TabList'
import { Grid } from '@mui/material'

import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'

import StationsSampleListPanel from '../../../station/components/dashboard/StationsSampleListPanel'
import DtoObservatoryFollowResult from 'station/dto/DtoObservatoryFollowResult'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import DashboardFilterPanel from 'station/components/dashboard/component/DashboardFilterPanel'
import DashboardAddStationDialog from 'station/components/dashboard/component/DashboardAddStationDialog'
import DashboardMapPanel from 'station/components/dashboard/component/DashboardMapPanel'
import DashboardStationsPanel from 'station/components/dashboard/component/DashboardStationsPanel'
import { FILTER_TYPE } from 'administration/components/user/constants/UserConstants'

const SEARCH_VALUE_HEADERS = ['number', 'code', 'name', 'city', 'SISEeaucode', 'creationDate', 'stationTypeLabel', 'operator', 'administrator', 'referent', 'department']
const RESOURCES_HEADERS = ['code', 'name', 'nature', 'resourceType']
const stationType = STATION_TYPE_NAME.resource

const ResourcesPanel = ({
}) => {
    const {
        userBookmarks,
        sandreCodes,
        resources,
        selectedSearchValues,
        globalResearch,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        resources: store.ResourceReducer.resources,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        globalResearch: store.HomeReducer.globalResearch,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [panel, setPanel] = useState(STATION_LIST)
    const [filter, setFilter] = useState(selectedSearchValues[stationType]?.filter || FILTER_TYPE.NOFILTER)
    const [filterResults, setFilterResults] = useState(selectedSearchValues[stationType]?.filterResults || [])
    const [searchValue, setSearchValue] = useState(globalResearch || selectedSearchValues[stationType]?.searchValue || '')

    const dispatch = useDispatch()

    useEffect(() => {
        if (globalResearch) {
            dispatch(HomeAction.updateGlobalResearch(''))
        }
    }, [])

    const onChangeSearchValue = (value) => {
        setSearchValue(value)
        dispatch(AdministrationAction.setSelectedSearchValues(stationType, { searchValue: value }))
    }

    const resourcesFormatted = useMemo(() => {
        if (resources.length) {
            return sortBy(resources, o => o.name ? o.name.toUpperCase() : '}').map(s => ({
                ...s,
                resourceType: getSandreLabel(sandreCodes, 'MILIEU_EAU', s.resourceType),
            }))
        }
        return []
    }, [resources, sandreCodes])

    const containsSearchValue = useCallback((station) =>
        some(SEARCH_VALUE_HEADERS, prop => station[prop]
            ? searchAllCharacters(station[prop].toString()).includes(searchAllCharacters(searchValue))
            : false
        ), [searchValue])

    const data = useMemo(() => {
        const searchFiltered = resourcesFormatted.filter(s => containsSearchValue(s))
        const title = (() => {
            switch (stationType) {
                case 'resource':
                    return i18n.resources
                default:
                    return i18n.watchpoints
            }
        })()

        const defaultResult = {
            title,
            type: { headers: RESOURCES_HEADERS },
        }

        if (!filter || `${filter}` === `${FILTER_TYPE.NOFILTER}`) {
            return { ...defaultResult, stations: searchFiltered }
        } else if (`${filter}` === `${FILTER_TYPE.BOOKMARK}`) {
            const bookmarks = getUserBookmarksByStationType(userBookmarks, stationType)
            return { ...defaultResult, stations: flatten(bookmarks.map(code => searchFiltered.find(s => s.code.includes(code)) || [])) }
        }
        if (filterResults.length) {
            return { ...defaultResult, stations: searchFiltered.filter(sf => filterResults.some(fr => fr.id === sf.id)) }
        }
        return { ...defaultResult, stations: [] }
    }, [resourcesFormatted, filter, filterResults, containsSearchValue, userBookmarks])

    useActions(() => {
        const defaultActions = {
            export: () => {
                return {
                    data: data.stations,
                    exportType: 'xlsx',
                    titleFile: data.title,
                }
            },
        }
        const currentUser = getUser()
        const newActions = (currentUser.admin === '1' || currentUser.metadata === '1') ? {
            ...defaultActions,
            new: () => setOpen(true),
        } : defaultActions
        return newActions
    }, [data])

    const closeDialog = () => setOpen(false)

    const onValidate = (newElement) => {
        dispatch(ResourceAction.createResource(newElement, id => {
            closeDialog()
            dispatch(push(`/station/${stationType}/${id}/description`))
        }))
    }

    return (
        <Grid container item sx={{ margin: '0px 15px 100px 20px', paddingTop: '10px' }}>
            <Grid container item xs={12} sx={{ paddingTop: '5px' }}>
                <DashboardFilterPanel
                    stationType={stationType}
                    data={ resourcesFormatted }
                    onChangeSearchValue={onChangeSearchValue}
                    setFilterResults={setFilterResults}
                    setFilter={setFilter}
                    defaultSearchValue={searchValue}
                    defaultFilter={filter}
                    defaultFilterResults={filterResults}
                />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '-50px', marginBottom: panel === STATION_LIST ? 0 : '60px' }}>
                <TabList
                    onChangeTab={setPanel}
                    tabs={[
                        {
                            value: STATION_LIST,
                            label: i18n.table,
                            icon: 'list',
                        },
                        {
                            value: MAP,
                            label: i18n.map,
                            icon: 'map',
                        },
                    ]}
                >
                    {panel === STATION_LIST && <DashboardStationsPanel stationType={stationType} data={data} />}
                    {panel === MAP && <DashboardMapPanel stationType={stationType} data={data} />}
                    {panel === SAMPLE_LIST && <StationsSampleListPanel stations={data.stations} />}
                </TabList>
            </Grid>
            <DashboardAddStationDialog
                open={open}
                closeDialog={closeDialog}
                stations={resources}
                title={i18n.newResource}
                onValidate={onValidate}
            />
        </Grid>
    )
}

ResourcesPanel.propTypes = {
    stationType: PropTypes.string,
    indicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoObservatoryFollowResult)),
}

const ResourcesDashboard = ({
}) => {
    const {
        cities,
        userBookmarks,
        contributorLinks,
        contributors,
        sandreCodes,
        status,
        resources,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        userBookmarks: store.UserReducer.userBookmarks,
        contributorLinks: store.StationReducer.contributorLinks,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        status: store.QualityReducer.status,
        resources: store.ResourceReducer.resources,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!componentHasHabilitations(H_RESOURCE_DASHBOARD)) {
            dispatch(push('/unauthorized'))
        }
    }, [dispatch])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !userBookmarks.length ? [UserAction.fetchBookmarks] : []
        const findedStationType = findStationType(stationType)
        const promisesContributorsLinks = (!contributorLinks.length && findedStationType.code != 9) ? [...promises, () => StationAction.fetchAllContributors(findedStationType.code)] : promises
        const promisesContributors = !contributors.length ? [...promisesContributorsLinks, ContributorAction.fetchContributors] : promisesContributorsLinks
        const promisesStatus = !status.length ? [...promisesContributors, QualityAction.fetchStatus] : promisesContributors
        const promisesSandreCodes = !sandreCodes.length ? [...promisesStatus, ReferencialAction.fetchSandreCodes] : promisesStatus
        const promisesCities = !cities.length ? [...promisesSandreCodes, CityAction.fetchCities] : promisesSandreCodes
        const promisesResources = (stationType === STATION_TYPE_NAME.resource && !resources.length) ? [...promisesCities, ResourceAction.fetchResources] : promisesCities
        return promisesResources.map(p => dispatch(p()))
    }, [])

    useTitle(() => [{
        title: i18n[stationType],
        href: stationType,
    }, {
        title: i18n.dashboard,
        href: stationType,
    }], [stationType])

    return isLoaded ? (
        <Grid container>
            <ResourcesPanel />
        </Grid>
    ) : (
        <Grid container sx={{ padding: '1rem' }}>
            <Grid item xs={12}>
                <ProgressCard progress={progress} withMessage />
            </Grid>
        </Grid>
    )
}

ResourcesDashboard.propTypes = {
    stationType: PropTypes.string.isRequired,
}

export default ResourcesDashboard
