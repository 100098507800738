import fetch from 'isomorphic-fetch'
import ApplicationConf from 'conf/ApplicationConf'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { checkAuth, checkError, genericPromise, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_PARAMETER,
    RECEIVE_PARAMETER_GROUP_LINKS,
    RECEIVE_PARAMETER_GROUP_USAGE,
    RECEIVE_PARAMETER_GROUPS,
    RECEIVE_PARAMETERS,
    RECEIVE_PARAMETERS_QUALITOMETERS,
    RECEIVE_SELECTION_RESULTS,
    RECEIVE_SELECTIONS,
    RECEIVE_SELECTIONS_WITH_PARAM,
    RESET_PARAMETER,
} from '../constants/ParameterConstants'
import { push } from 'connected-react-router'
import {
    PATH_REFERENCIAL_PARAMETER,
    PATH_REFERENCIAL_PARAMETER_ITEM_LINK,
} from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const ParameterAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().ParameterReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    receiveParameter(parameter) {
        return { type: RECEIVE_PARAMETER, parameter }
    },

    fetchParameter(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.parameterId(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(ParameterAction.receiveParameter(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.parameter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.parameter))
                })
        }
    },

    receiveParameters(parameters) {
        return { type: RECEIVE_PARAMETERS, parameters }
    },

    promiseParameters() {
        return fetch(ApplicationConf.referencial.parameter(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchParameters() {
        return (dispatch) => {
            return ParameterAction.promiseParameters()
                .then((json = []) => {
                    dispatch(ParameterAction.receiveParameters(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.parameters} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.parameters))
                })
        }
    },

    receiveQualitometers(qualitometers) {
        return { type: RECEIVE_PARAMETERS_QUALITOMETERS, objects: qualitometers }
    },

    fetchQualitometers(parameter) {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.qualitometerParameter(parameter), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(ParameterAction.receiveQualitometers(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.stationsByParameter + parameter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.stationsByParameter + parameter))
                })
        }
    },

    receiveSelections(selections) {
        return { type: RECEIVE_SELECTIONS, selections }
    },

    promiseSelections(type = 0) {
        return fetch(ApplicationConf.referencial.selection(type), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSelections(type) {
        return (dispatch) => {
            return ParameterAction.promiseSelections(type)
                .then((json = []) => {
                    dispatch(ParameterAction.receiveSelections(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.selections} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.selections))
                })
        }
    },

    receiveSelectionsWithParam(selectionsWithParam) {
        return { type: RECEIVE_SELECTIONS_WITH_PARAM, selectionsWithParam }
    },
    fetchSelectionsWithParam: () => dispatch => fetch(ApplicationConf.referencial.selections(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json = []) => {
            dispatch(ParameterAction.receiveSelectionsWithParam(json))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.selections} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.selections))
        }),

    createSelection: selection => dispatch => fetch(ApplicationConf.referencial.selections(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(selection),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.insert) {
                dispatch(ToastrAction.success(i18n.successfulCreation))
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.createError + i18n.selection} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError + i18n.parameter))
        }),

    receiveSelectionParameters(selectedParameters) {
        return { type: RECEIVE_SELECTION_RESULTS, selectionResults: selectedParameters }
    },

    fetchSelectionParameter(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.selectionParameter(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(ParameterAction.receiveSelectionParameters(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.parameterSelection} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.parameterSelection))
                })
        }
    },

    updateParameter(parameter, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveParameter(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(parameter),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(ParameterAction.fetchParameter(code))
                        dispatch(ToastrAction.success(i18n.parameterSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.parameter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.parameter))
                })
        }
    },

    createParameter(parameter) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.parameter(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(parameter),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_PARAMETER_ITEM_LINK + parameter.code))
                        dispatch(ParameterAction.fetchParameters())
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.parameter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.parameter))
                })
        }
    },

    deleteParameter(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteParameter(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(ParameterAction.fetchParameters())
                        dispatch(push(PATH_REFERENCIAL_PARAMETER))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.parameter} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.parameter))
                })
        }
    },

    purgeParameter() {
        return (dispatch) => {
            dispatch(ToastrAction.info(`${i18n.purgeInProgress} ...`))
            return fetch(ApplicationConf.referencial.purgeParameter(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ParameterAction.fetchParameters())
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.parameters} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.parameters))
                })
        }
    },

    replaceParameter(parameter, parameterReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceParameter(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: parameter.toString(),
                    replaceCode: parameterReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.parameterSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.parameter} : ${err}`))
                })
        }
    },

    reset() {
        return { type: RESET_PARAMETER }
    },

    fetchSynchroSandreParameter() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateParameter(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(ParameterAction.fetchParameter())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.parameter + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.parameter))
                    WaitAction.waitStop()
                })
        }
    },

    fetchSynchroSandreGroupParameter() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateGroupParameter(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(ParameterAction.fetchParameters())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.parameter + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.parameter))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchImportParameterCSV(fichier, insertOrUpdate, actif) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadCsvParameter(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent: fichier, insertOrUpdate, actifAuto: actif, usage: actif }),
            })
                .then(checkAuth)
                .then(checkError)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertParam > 0 || json.insertGroup > 0 || json.updateParam > 0 || json.updateGroup > 0) {
                        dispatch(ToastrAction.success(`${i18n.parameterLabel}<br/> ${i18n.insertNumber} : ${json.insertParam}<br/>${i18n.updateNumber} : ${json.updateParam}`))
                        dispatch(ToastrAction.success(`${i18n.parameterGroup}<br/> ${i18n.insertNumber} : ${json.insertGroup}<br/>${i18n.updateNumber} : ${json.updateGroup}`))
                        dispatch(this.fetchParameter())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.parameter + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchImportParameterXML(fichier, insertOrUpdate, actif, usage) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadXmlParameter(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent: fichier, insertOrUpdate, actifAuto: actif, usage }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertParam > 0 || json.insertGroup > 0 || json.updateParam > 0 || json.updateGroup > 0) {
                        dispatch(ToastrAction.success(`${i18n.parameterLabel}<br/> ${i18n.insertNumber} : ${json.insertParam}<br/>${i18n.updateNumber} : ${json.updateParam}`))
                        dispatch(ToastrAction.success(`${i18n.parameterGroup}<br/> ${i18n.insertNumber} : ${json.insertGroup}<br/>${i18n.updateNumber} : ${json.updateGroup}`))
                        dispatch(this.fetchParameter())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.parameter + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.parameter))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    receiveParameterGroups: parameterGroups => ({ type: RECEIVE_PARAMETER_GROUPS, parameterGroups }),

    fetchParameterGroups: () => dispatch => fetch(ApplicationConf.referencial.parameterGroup(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json = {}) => {
            dispatch(ParameterAction.receiveParameterGroups(json))
        })
        .catch((err) => {
            dispatch(LogAction.logError(i18n.fetchError + i18n.parameterGroup + err))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.parameterGroup))
        }),

    receiveParameterGroupLinks: parameterGroupLinks => ({ type: RECEIVE_PARAMETER_GROUP_LINKS, parameterGroupLinks }),

    fetchParameterGroupLinks: () => dispatch => fetch(ApplicationConf.referencial.parameterGroupLink(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json = {}) => {
            dispatch(ParameterAction.receiveParameterGroupLinks(json))
        })
        .catch((err) => {
            dispatch(LogAction.logError(i18n.fetchError + i18n.parameterGroup + err))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.parameterGroup))
        }),

    receiveParameterGroupUsage: parameterGroupUsage => ({ type: RECEIVE_PARAMETER_GROUP_USAGE, parameterGroupUsage }),

    promiseParameterGroupUsage: () => fetch(ApplicationConf.referencial.getParameterGroupUsage(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchParameterGroupUsage: () => dispatch => ParameterAction.promiseParameterGroupUsage()
        .then((json = []) => {
            dispatch(ParameterAction.receiveParameterGroupUsage(json))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.sandreCode} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.sandreCode))
        }),

    createParameterGroup: (body) => dispatch => genericPromise(ApplicationConf.referencial.parameterGroup(), 'POST', body)
        .then(json => {
            if (json.insert) {
                dispatch(ToastrAction.success(i18n.addSuccess))
                dispatch(ParameterAction.fetchParameterGroups())
                dispatch(ParameterAction.fetchParameterGroupLinks())
                return json.code
            }
            return dispatch(ToastrAction.error(i18n.createError + i18n.parameterGroup))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.createError + i18n.parameterGroup} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError + i18n.parameterGroup))
        }),

    updateParameterGroup: (body) => dispatch => genericPromise(ApplicationConf.referencial.parameterGroup(), 'PUT', body)
        .then(json => {
            if (json.update) {
                dispatch(ToastrAction.success(i18n.updateSuccess))
                dispatch(ParameterAction.fetchParameterGroups())
                dispatch(ParameterAction.fetchParameterGroupLinks())
                return json.code
            }
            return dispatch(ToastrAction.error(i18n.updateError + i18n.parameterGroup))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.parameterGroup} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.parameterGroup))
        }),
}

export default ParameterAction
