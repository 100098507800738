import fetch from 'isomorphic-fetch'
import { last, orderBy } from 'lodash'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import SieauAction from '../components/sieau/SieauAction'
import ApplicationConf from '../conf/ApplicationConf'
import { path } from '../conf/basepath'
import LogAction from '../log/actions/LogAction'
import { MEASURE_COTE } from '../piezometry/constants/PiezometryConstants'
import PluviometryAction from '../pluviometry/actions/PluviometryAction'
import PiezometerStationAction from '../station/actions/PiezometerStationAction'
import AppStore from '../store/AppStore'
import {
    checkAuth,
    checkError,
    genericCreatePromise,
    genericFetch,
    genericPromise,
    genericUpdate,
    genericUpdatePromise,
    getAuthorization,
    getJson,
} from '../utils/ActionUtils'
import { hasValue, round } from '../utils/NumberUtil'
import { getLogin, getSettingInt } from '../utils/SettingUtils'
import { RECEIVE_GENERIC_MEASURES, RECEIVE_IAEAU_LEARNINGS, UPDATE_GENERIC_MEASURES, RECEIVE_IAEAU_MODELS } from './constants/IAEauConstants'
import DtoIAEauLearning from './dto/DtoIAEauLearning'
import { execByType } from '../utils/StationUtils'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'

const IAEauAction = {
    getStationTypeConst(stationType) {
        return execByType(stationType, {
            piezometry: () => 'piezometer',
            hydrometry: () => 'hydrologicalStation',
            pluviometry: () => 'pluviometer',
        })
    },
    promisePredStats(stationType, stationId) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.station.getPredStats(stationTypeConst, stationId))
    },
    promiseModels(stationType, stationId) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.station.getIAEauModels(stationTypeConst, stationId))
    },
    promiseModel(stationType, stationId, method, model) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.station.IAEauModel(stationTypeConst, stationId), method, model)
    },
    deleteModel(stationType, stationId, model) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        AppStore.dispatch(WaitAction.waitStart())
        return genericPromise(ApplicationConf.station.IAEauModel(stationTypeConst, stationId), 'DELETE', model).then(json => {
            AppStore.dispatch(WaitAction.waitStop())
            if (json.delete <= 0) {
                throw new Error()
            } else {
                AppStore.dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            }
            return json
        })
    },
    getModels(stationType, stationId) {
        return genericFetch(IAEauAction.promiseModels(stationType, stationId), RECEIVE_IAEAU_MODELS)
    },
    updateModel(stationType, stationId, model) {
        return genericUpdatePromise(IAEauAction.promiseModel(stationType, stationId, 'PUT', model))
    },
    createModel(stationType, stationId, model) {
        return genericCreatePromise(IAEauAction.promiseModel(stationType, stationId, 'POST', model))
    },
    launchModel(stationType, stationId, model) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.station.launchIAEauModel(stationTypeConst, stationId), 'POST', model)
    },
    launchModelSync(model, simulationDate) {
        return genericPromise(ApplicationConf.iaeau.launchIAEauModelSync(), 'POST', { model, simulationDate })
    },
    launchModelHot(model, simulationDate) {
        AppStore.dispatch(WaitAction.waitStart())
        return genericPromise(ApplicationConf.iaeau.launchIAEauModelHot(), 'POST', { model, simulationDate }).then(res => {
            AppStore.dispatch(WaitAction.waitStop())
            return res
        })
    },
    launchModelSyncLocal(model, simulationDate) {
        return genericPromise(`http://localhost:8000/risk/iaeau/model/executeSync`, 'POST', { model, simulationDate })
    },
    fetchHypeTrendsFromIAEau(measures, callback = () => {}, stationCode = 'BSS', parameter = 1340, unit = 'unit') {
        return dispatch => {
            const iaeauInput = {
                chainCode: 'HYPE',
                overrideInputNodes: [1],
                outputsToTake: [2],
                inputs: [
                    {
                        id: 1,
                        dataColumns: [
                            { column: 1, name: 'BSS_CODE', dataFormat: 'text' },
                            { column: 3, name: 'SAMPLE_DATE', dataFormat: 'timestamp' },
                            { column: 10, name: 'REMARK_CODE', dataFormat: 'integer' },
                            { column: 8, name: 'ANALYSIS_RESULT', dataFormat: 'number' },
                            { column: 7, name: 'PARAMETER_CODE', dataFormat: 'integer' },
                            { column: 42, name: 'NAME_UNIT', dataFormat: 'text' },
                            { column: 41, name: 'NAME_PARAMETER', dataFormat: 'text' },
                        ],
                        data: orderBy(measures.filter(m => hasValue(m.measureDate) && hasValue(m.measure)).map(m => ['BSS', m.measureDate, '1', m.measure, 1340, 'unit', '1340']), d => d[1]),
                    },
                ],
            }
            return IAEauAction.executeChainSync(dispatch, iaeauInput, stationCode).then(result => {
                if (result.length && !result[0].error) {
                    const params = { stationCode, parameter, unit }
                    const values = result[0].data[0]
                    const trends = []
                    const breaks = []
                    if (values[0] !== 'NA') {
                        trends.push({
                            statisticsType: 'MANN_KENDALL',
                            value: values[0],
                            coefficient: values[1],
                            ...params,
                        })
                    }
                    if (values[2] !== 'NA') {
                        trends.push({
                            statisticsType: 'LINEAR_REGRESSION',
                            value: values[2],
                            coefficient: values[3],
                            ...params,
                        })
                    }
                    if (values[6] !== 'NA') {
                        breaks.push({
                            statisticsType: 'AVERAGE_RUPTURE',
                            preValue: values[4],
                            postValue: values[5],
                            breakDate: values[6],
                            ...params,
                        })
                    }
                    if (values[11] !== 'NA') {
                        breaks.push({
                            statisticsType: 'TREND_RUPTURE',
                            preValue: values[7],
                            preCoefficient: values[8],
                            postValue: values[9],
                            postCoefficient: values[10],
                            breakDate: values[11],
                            ...params,
                        })
                    }
                    const json = { trends, breaks }
                    if (!json.trends.length && !json.breaks.length) {
                        dispatch(ToastrAction.info(i18n.hypeNoTrendsDetected))
                    }
                    dispatch(SieauAction.receiveHypeTrends(json))
                    dispatch(QualityActionConstant.receiveHypeTrends(trends))
                    dispatch(QualityActionConstant.receiveHypeRuptures(breaks))
                    callback(json)
                } else {
                    dispatch(ToastrAction.error(i18n.hypeError))
                    dispatch(LogAction.logError(i18n.hypeError))
                }
            })
        }
    },
    getHypeTrend: (measures, stationCode = 'BSS') => () => {
        const iaeauInput = {
            chainCode: 'HYPE',
            overrideInputNodes: [1],
            outputsToTake: [2],
            inputs: [
                {
                    id: 1,
                    dataColumns: [
                        { column: 1, name: 'BSS_CODE', dataFormat: 'text' },
                        { column: 3, name: 'SAMPLE_DATE', dataFormat: 'timestamp' },
                        { column: 10, name: 'REMARK_CODE', dataFormat: 'integer' },
                        { column: 8, name: 'ANALYSIS_RESULT', dataFormat: 'number' },
                        { column: 7, name: 'PARAMETER_CODE', dataFormat: 'integer' },
                        { column: 42, name: 'NAME_UNIT', dataFormat: 'text' },
                        { column: 41, name: 'NAME_PARAMETER', dataFormat: 'text' },
                    ],
                    data: orderBy(measures.filter(m => hasValue(m.measureDate) && hasValue(m.measure)).map(m => ['BSS', m.measureDate, '1', m.measure, 1340, 'unit', '1340']), d => d[1]),
                },
            ],
        }
        return IAEauAction.getResultChainSync(iaeauInput, stationCode).then(result => {
            if (!result?.length) {
                return { trends: [], breaks: [] }
            }
            const values = result[0].data[0]
            if (!values?.length) {
                return { trends: [], breaks: [] }
            }
            const trends = []
            const breaks = []
            if (values[0] !== 'NA') {
                trends.push({
                    statisticsType: 'MANN_KENDALL',
                    value: values[0],
                    coefficient: values[1],
                    stationCode,
                })
            }
            if (values[2] !== 'NA') {
                trends.push({
                    statisticsType: 'LINEAR_REGRESSION',
                    value: values[2],
                    coefficient: values[3],
                    stationCode,
                })
            }
            if (values[6] !== 'NA') {
                breaks.push({
                    statisticsType: 'AVERAGE_RUPTURE',
                    preValue: values[4],
                    postValue: values[5],
                    breakDate: values[6],
                    stationCode,
                })
            }
            if (values[11] !== 'NA') {
                breaks.push({
                    statisticsType: 'TREND_RUPTURE',
                    preValue: values[7],
                    preCoefficient: values[8],
                    postValue: values[9],
                    postCoefficient: values[10],
                    breakDate: values[11],
                    stationCode,
                })
            }
            return { trends, breaks }
        }).catch(() => {
            // eslint-disable-next-line no-console
            // console.error(e)
            return { trends: [], breaks: [] }
        })
    },
    getAugures(measures, horizon = 60.0, mean=7.0, stationCode, cb = () => {}) {
        return (dispatch) => {
            const iaeauInput = {
                chainCode: 'AUGURE_SIEAU',
                overrideInputNodes: [2, 3],
                outputsToTake: [3, 4],
                inputs: [
                    {
                        id: 1,
                        idFormat: 'date',
                        dataColumns: [
                            { column: 1, name: 'Date', dataFormat: 'date' },
                            { column: 2, name: 'Data', dataFormat: 'number' },
                        ],
                        data: orderBy(measures.map(m => [m.date, m.NGF]), d => d[0]),
                    }, {
                        id: 2,
                        idFormat: 'ref',
                        dataColumns: [
                            { column: 1, name: 'Horizon', dataFormat: 'Number' },
                            { column: 2, name: 'Moyenne', dataFormat: 'Number' },
                        ],
                        data: [[horizon, mean]],
                    },
                ],
            }
            return IAEauAction.executeChainSync(dispatch, iaeauInput, stationCode).then(result => {
                const lastMeasure = last(measures)
                const l = [lastMeasure.date, lastMeasure.NGF]
                const augures = result[1].dataColumns.map((column) => {
                    const idxValues = column.column
                    const idxProbas = column.column - 1
                    return {
                        year: column.name,
                        proba: round(result[1].data[0][idxProbas], 3),
                        values: [ l, ...result[0].data.map(d => [d[0], d[idxValues]]) ],
                    }
                })
                dispatch({ type: 'RECEIVE_AUGURES', data: augures })
                cb(augures)
                return augures
            })
        }
    },
    getARIMAFromIAEau(measures, learningId, stationCode) {
        return (dispatch) => {
            const iaeauInput = {
                chainCode: 'ARIMA_RUN',
                overrideInputNodes: [1],
                outputsToTake: [3],
                inputs: [
                    {
                        id: 1,
                        idFormat: 'date',
                        dataColumns: [
                            { column: 1, name: 'Date', dataFormat: 'date' },
                            { column: 2, name: 'Data', dataFormat: 'number' },
                        ],
                        data: orderBy(measures.map(m => [m.date, m.NGF]), d => d[0]),
                    },
                ],
                learningId,
            }
            return IAEauAction.executeChainSync(dispatch, iaeauInput, stationCode).then(result => {
                const prediction = result
                dispatch({ type: 'RECEIVE_ARIMA', data: prediction })
                return prediction
            })
        }
    },
    learnARIMAFromIAEau(measures, horizon = 10.0, stationCode) {
        return (dispatch) => {
            const iaeauInput = {
                chainCode: 'ARIMA_LEARN',
                overrideInputNodes: [1, 2],
                outputsToTake: [3],
                inputs: [
                    {
                        id: 1,
                        idFormat: 'date',
                        dataColumns: [
                            { column: 1, name: 'Date', dataFormat: 'date' },
                            { column: 2, name: 'Data', dataFormat: 'number' },
                        ],
                        data: orderBy(measures.map(m => [m.date, m.NGF]), d => d[0]),
                    }, {
                        id: 2,
                        dataColumns: [
                            { column: 1, name: 'AR', dataFormat: 'Number' },
                            { column: 2, name: 'I', dataFormat: 'Number' },
                            { column: 3, name: 'MA', dataFormat: 'Number' },
                            { column: 4, name: 'pas', dataFormat: 'Number' },
                            { column: 5, name: 'h', dataFormat: 'Number' },
                        ],
                        // Actual parameters : 0 <= AR <= 2, 0 <= I <= 2, 0 <= MA <= 2, step = 1
                        data: [[0, 0, 0, 1, horizon], [2, 2, 2, 0, 0]],
                    },
                ],
            }
            return IAEauAction.executeChainSync(dispatch, iaeauInput, stationCode).then(result => {
                const learningParams = result
                dispatch({ type: 'RECEIVE_ARIMA', data: learningParams })
                return learningParams
            })
        }
    },

    launchDryingUpAprona(params, measures, stationCode) {
        const iaeauInput = {
            chainCode: 'TARRI_APRONA',
            overrideInputNodes: [2, 3],
            outputsToTake: [3, 4],
            inputs: [
                {
                    id: 1,
                    dataColumns: [
                        { column: 1, name: 'date_mesure', dataFormat: 'date' },
                        { column: 2, name: 'valeur', dataFormat: 'number' },
                    ],
                    data: orderBy(measures.filter(m => m.date <= params.dryingDate).map(m => [m.date, m.NGF]), d => d[0]),
                }, {
                    id: 2,
                    dataColumns: [
                        { column: 1, name: 'param', dataFormat: 'text' },
                        { column: 2, name: 'cote_max', dataFormat: 'integer' },
                        { column: 3, name: 'nb_jours_max', dataFormat: 'integer' },
                        { column: 4, name: 'pourcentage', dataFormat: 'number' },
                        { column: 5, name: 'horizon', dataFormat: 'integer' },
                        { column: 6, name: 'season', dataFormat: 'integer' },
                    ],
                    data: [['truc', params.dryingCote ? 1 : 0, params.dryingNbDays, params.dryingPercent*0.01, params.dryingHorizon, params.dryingSeason ? 1 : 0]],
                },
            ],
        }
        return IAEauAction.executeChainSync(AppStore.dispatch, iaeauInput, stationCode).then(result => {
            const error = result[0].data[0][0] !== 'NA' ? result[0].data[0][0] : null
            const values = result[1] && result[1].data.length ? result[1].data : null
            return { error, values }
        })
    },

    launchTfPoc(endDate, idPiezo, piezoCode) {
        const startDate = moment(endDate).subtract(34, 'days').valueOf()
        const promises = [PiezometerStationAction.promisePiezoChartMeasures({
            stationId: idPiezo,
            displayCote: MEASURE_COTE.DEPTH,
            groupFunc: 'MAX',
            dataType: -1,
            startDate,
            endDate,
        }), PluviometryAction.promisePluvioChronicMeasures({
            stationId: getSettingInt(AppStore.getState().AdministrationReducer.applicationSettings, 'tfModelPluvioId'),
            dataType: 1,
            groupFunc: 'SUM',
            chartMode: true,
            startDate,
            endDate,
        })]
        return Promise.all(promises).then(([piezoMeasures, pluvioMeasures]) => {
            const piezosFiltered = piezoMeasures.filter(m => pluvioMeasures.some(pl => moment(m[0]).isSame(pl[0], 'day')))
            const pluviosFiltered = pluvioMeasures.filter(m => piezoMeasures.some(pl => moment(m[0]).isSame(pl[0], 'day')))
            const lastMeasureDepth = piezosFiltered.length ? last(piezosFiltered)[1] : null
            const calculatedMeasures = piezosFiltered.map((m, i) => [
                moment(m[0]).year(),
                m[0],
                pluviosFiltered[i][1],
                m[1],
                m[1],
            ])
            const iaeauInput = {
                chainCode: 'POC_TENSORFLOW',
                overrideInputNodes: [1],
                outputsToTake: [2],
                inputs: [
                    {
                        id: 1,
                        dataColumns: [
                            { column: 1, name: 'Event', dataFormat: 'integer' },
                            { column: 2, name: 'Date', dataFormat: 'date' },
                            { column: 3, name: 'Pluie SYNOP', dataFormat: 'number' },
                            { column: 4, name: 'Piezo brut', dataFormat: 'number' },
                            { column: 5, name: 'Piezo', dataFormat: 'number' },
                        ],
                        data: orderBy(calculatedMeasures, d => d[0]),
                    },
                ],
            }
            return IAEauAction.executeChainSync(AppStore.dispatch, iaeauInput, piezoCode).then(result => {
                return [lastMeasureDepth, result[0].data.map(m => [m[0], m[1]]), result[0].data.map(m => [m[0], m[2]]), result[0].data.map(m => [m[0], m[3]])]
            })
        })
    },

    executeChainSync(dispatch, body, stationCode) {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.iaeau.executeChainSync(body.chainCode), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(IAEauAction.getInputUser(body, stationCode)),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(WaitAction.waitStop())
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.addError + i18n.savedResearchCriterias} : ${err}`))
                dispatch(ToastrAction.error(i18n.addError + i18n.savedResearchCriterias))
            })
    },
    getResultChainSync: (body, stationCode) => fetch(ApplicationConf.iaeau.executeChainSync(body.chainCode), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(IAEauAction.getInputUser(body, stationCode)),
    })
        .then(checkAuth)
        .then(getJson),
    getInputUser(input, stationCode) {
        return {
            ...input,
            batchCode: stationCode,
            sieauUser: getLogin(),
            sieauInstance: path.replace('https://', '').replace('/api/', '').replace('.fr', '').replace('.com', '').replace(/\./gi, '').replace('sieau', '').replace('aquasys', '').replace('recette', ''),
        }
    },

    receiveIAEauLearnings(json) {
        return { type: RECEIVE_IAEAU_LEARNINGS, iaeauLearnings: json }
    },
    fetchIAEauLearnings(stationId, stationType) {
        return dispatch => {
            return fetch(ApplicationConf.iaeau.stationIAEauLearning(stationId, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(IAEauAction.receiveIAEauLearnings(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError}IAEau Learning` + ` : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError}IAEau Learning`))
                })
        }
    },
    addIAEauLearning(learningId, typeStation, idStation, model, parameters) {
        const learning = new DtoIAEauLearning({
            idStation,
            typeStation,
            modele: model,
            apprentissageId: learningId,
            dateDebut: 0, // la date est initialisée automatiquement dans le back
            dateFin: 0, // idem
            statut: 0, // le statut est automatiquement mis à 0 dans le back
            parametres: JSON.stringify(parameters),
        })
        return (dispatch) => {
            return fetch(ApplicationConf.iaeau.iaeauLearning(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(learning),
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        dispatch(ToastrAction.success(i18n.learningAddSuccess))
                    } else {
                        dispatch(LogAction.logError(i18n.addError + i18n.learning))
                        dispatch(ToastrAction.error(i18n.addError + i18n.learning))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.learning} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.learning))
                })
        }
    },
    updateIAEauLearning(learningId, typeStation, idStation, model, parameters, status = 1, endDate = +new Date()) {
        const learning = new DtoIAEauLearning({
            idStation,
            typeStation,
            modele: model,
            apprentissageId: learningId,
            dateDebut: 0, // non pris en compte par le back
            dateFin: endDate,
            statut: status,
            parametres: JSON.stringify(parameters),
        })
        return (dispatch) => {
            return fetch(ApplicationConf.iaeau.iaeauLearning(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(learning),
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        dispatch(ToastrAction.success(i18n.learningUpdateSuccess))
                    } else {
                        dispatch(LogAction.logError(i18n.updateError + i18n.learning))
                        dispatch(ToastrAction.error(i18n.updateError + i18n.learning))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.learning} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.learning))
                })
        }
    },

    promiseGenericMeasures(objectType, objectId, measureType) {
        return genericPromise(ApplicationConf.iaeau.genericMeasures(), 'POST', { objectType, objectId, measureType })
    },
    fetchGenericMeasures(objectType, objectId, measureType) {
        return genericFetch(IAEauAction.promiseGenericMeasures(objectType, objectId, measureType), RECEIVE_GENERIC_MEASURES)
    },

    setGenericMeasures(objectType, objectId, measureType, measures) {
        return genericUpdate(ApplicationConf.iaeau.genericMeasures(), UPDATE_GENERIC_MEASURES, { objectType, objectId, measureType, measures })
    },
    promiseModelMeasures(stationType, stationId, modelId, source, simulationDate) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.iaeau.modelMeasures(stationTypeConst, stationId), 'POST', { id: stationId, modelId, source, simulationDate })
    },
    promiseModelMeasuresWithHorizon(stationType, stationId, modelId, source, horizon) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.station.getPredMeasuresWithHorizon(stationTypeConst, stationId), 'POST', { id: stationId, modelId, source, horizon })
    },
    promiseModelResultDates(stationType, stationId, modelId, source, month) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.iaeau.modelResultDates(stationTypeConst, stationId), 'POST', { piezoId: stationId, modelId, source, month })
    },
    promiseModelResultPrevSuccDates(stationType, stationId, modelId, source, date) {
        const stationTypeConst = IAEauAction.getStationTypeConst(stationType)
        return genericPromise(ApplicationConf.iaeau.modelResultDates(stationTypeConst, stationId), 'POST', { piezoId: stationId, modelId, source, prevSuccMode: true, currentDate: date })
    },
}

export default IAEauAction
