export default class DtoSim {
    constructor(obj) {
        this.id = obj.id
        this.comment = obj.comment
        this.simType = obj.simType
        this.materielType = obj.simType
        this.code = obj.serialNumber
        this.serialNumber = obj.serialNumber
        this.enableDate = obj.enableDate
        this.disableDate = obj.disableDate
        this.explanationDisable = obj.explanationDisable
        this.callNumber = obj.callNumber
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.loginReferent = obj.loginReferent
        this.manufacturerId = obj.manufacturerId
    }
}
