export default class DtoPluvioMeasureValidation {
    constructor(obj) {
        this.pluvioId = obj[0] // Int
        this.dataType = obj[1] // Int
        this.date = obj[2] // Int to Long
        this.initialValue = obj[3] // Int
        this.value = obj[4] // Int
        this.measureMode = obj[5] // String
        this.status = obj[6] // Int
        this.qualification = obj[7] // Int
        this.nature = obj[8] // String
        this.initialPoint = obj[9] // String
        this.validator = obj[10] // Int
        this.manager = obj[11] // Int
        this.producer = obj[12] // Int
        this.jobExecutionId = obj[13]
        this.importDate = obj[14] // Int to Long
        this.updateDate = obj[15] // String
        this.updateLogin = obj[16] // String
    }
}