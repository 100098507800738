export default class DtoTelecom {
    constructor(obj) {
        this.id = obj.id
        this.comment = obj.comment
        this.telecomType = obj.telecomType
        this.materielType = obj.telecomType
        this.code = obj.code
        this.openDate = obj.openDate
        this.closeDate = obj.closeDate
        this.closeCause = obj.closeCause
        this.lineNumber = obj.lineNumber
        this.providerId = obj.providerId
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.loginReferent = obj.loginReferent
        this.manufacturerId = obj.manufacturerId
        this.transmissionVolume = obj.transmissionVolume
        this.imei = obj.imei
    }
}