import { withStyles } from '@mui/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import i18n from 'simple-react-i18n'
import { extendMoment } from 'moment-range'
import { hasValue } from 'utils/NumberUtil'
import { sieauTooltip } from '../../../utils/FormUtils'
import Row from '../../../components/react/Row'
import { range } from 'lodash'
import { getArrowDown, getArrowRight, getArrowUp } from 'utils/mapUtils/Markers'

const nbSemaines = range(1, 53)

const getDaysYear = (year = moment().format('YYYY'), day = 0, format = 'DD/MM/YYYY') => {
    // day 0 is Monday
    const eMoment = extendMoment(moment)
    const lastSaturdayOfYear = eMoment([year]).week(52).day(day)
    const lastSaturdayOfYearFormat = lastSaturdayOfYear.format(format)
    return [ ...nbSemaines.map(week => {
        const lastWeekDay = eMoment([year]).week(week - 1).day(day)
        return lastWeekDay.format(format)
    }), lastSaturdayOfYearFormat]
}

const isPredict = d => d.isPredict ? '*' : null

const getPiezoCell = (p, d) => {
    const ipsValue = (() => {
        if (d.ipsTrend || d.ipsColor) {
            switch (d.ipsTrend) {
                case 'up':
                    return getArrowUp(d.ipsColor)
                case 'down':
                    return getArrowDown(d.ipsColor)
                case 'right': default:
                    return getArrowRight(d.ipsColor)
            }
        }
        return null
    })()
    const ips = ipsValue ? <img {...sieauTooltip(() => i18n[d.ipsTooltip])} style={{ width: '30px', height: '30px', left: 'auto', top: 'auto', margin: '2 5' }} src={`data:image/svg+xml;utf8,${ipsValue}`} /> : null
    return hasValue(d.thresholdColor) ? (
        <td key={`piezo${p.piezoId}_${d.week}`} className='center-align aepTableCell padding-1'>
            <Row>
                <span className={ 'arrests-level-panel white-text' } style={ { backgroundColor: d.thresholdColor } } {...sieauTooltip(() => d.thresholdTooltip)}>
                    { d.stringValue }{isPredict(d)}
                </span>
            </Row>
            <Row>
                {ips}
            </Row>

        </td>
    ) : <td key={`piezo${p.piezoId}_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}{ips}</td>
}

const styles = (theme) => ({
    sticky: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
    },
    stickyLeft: {
        position: 'sticky',
        left: 0,
        backgroundColor: 'white',
    },
    pastCell: {
        backgroundColor: '#F2F2F2',
    },
    actualCell: {
        backgroundColor: 'black',
        color: 'white',
    },
    futurCell: {
        backgroundColor: theme.palette.primary.light,
    },
})

const IrrigEfficiencyTable = ({
    data = {},
    classes,
}) => {
    const weekNum = moment().isoWeek()
    const allSaturdays = getDaysYear(2022, 5, 'DD-MM')

    const refs = useRef(nbSemaines.map(() => React.createRef()))
    useEffect(() => {
        refs.current[(weekNum <= 37 ? weekNum + 15 : 52) - 1].current.scrollIntoView()
    }, [])

    const { piezos = [], etpDatas = [], tempDatas = [], rainDatas = [], waterRequirement = [],
        irrigation = [], ETR = [], waterRequirementCumulative = [], accumulatedPrecipitation = [],
        accumulatedIrrigation = [], percentNeedCoveredByIrrigation = [],
        allowedVolume = [], chronotension = [] } = data

    return (
        <div style={{ maxHeight: 700, overflow: 'auto' }}>
            <table style={{ borderCollapse: 'separate' }}>
                <thead className={classes.sticky} style={{ backgroundColor: 'white' }}>
                    <tr>
                        <th style={{ backgroundColor: 'white' }} className={`aepTableCell padding-1 ${classes.sticky}`} />
                        <th style={{ backgroundColor: 'white', zIndex: 3 }} className={`aepTableCell padding-1 ${classes.sticky} ${classes.stickyLeft}`}>{i18n.weekNum}</th>
                        {nbSemaines.map(num => (
                            <th key={`s${num}`} ref={refs.current[num - 1]} style={{ position: 'sticky', top: 0 }}
                                className={`center-align aepTableCell padding-1 ${classes.sticky} ${num === weekNum ? classes.actualCell : (num> weekNum ? classes.futurCell : classes.pastCell)}`}
                            >
                                {num}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        <th style={{ backgroundColor: 'white' }} className={`aepTableCell padding-1 ${classes.sticky}`} />
                        <th style={{ backgroundColor: 'white', zIndex: 3 }} className={`aepTableCell padding-1 ${classes.sticky} ${classes.stickyLeft}`}>{i18n.weekEndDate}</th>
                        {nbSemaines.map(id => (
                            <td
                                key={`d${id}`}
                                className={`center-align aepTableCell padding-1 ${classes.sticky} ${id>= weekNum ? classes.futurCell : classes.pastCell}`}
                                style={{
                                    writingMode: 'tb-rl',
                                    transform: 'rotate(-180deg)',
                                }}
                            >
                                {allSaturdays[id]}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={3} className='aepTableCell padding-1'>{i18n.forecast}</td>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{'T (°C)'}</td>
                        {tempDatas.map(d => <td key={`T_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}{isPredict(d)}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{'ET0 (mm)'}</td>
                        {etpDatas.map(d => <td key={`ET0_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}{isPredict(d)}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{'P (mm)'}</td>
                        {rainDatas.map(d => <td key={`P_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}{isPredict(d)}</td>)}
                    </tr>
                    {/* add a white line*/}
                    <tr>
                        <td colSpan={2} className='aepTableCell padding-1' />
                        <td colSpan={nbSemaines.length} className='aepTableCell padding-1' style={{ height: 30 }} />
                    </tr>
                    <tr>
                        <td rowSpan={9} className='aepTableCell padding-1'>{i18n.agronomy}</td>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{`${i18n.waterRequirement} (mm)`}</td>
                        {waterRequirement.map(d => {
                            return <td key={`be_${d.week}`} className={`center-align aepTableCell padding-1${d.value > 0 ? ' red' : ''}`}>{d.stringValue}</td>
                        })}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{`${i18n.irrigation} (mm)`}</td>
                        {irrigation.map(d => <td key={`irr_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{'ETR (mm)'}</td>
                        {ETR.map(d => <td key={`ETR_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{`${i18n.waterRequirementCumulative} (mm)`}</td>
                        {waterRequirementCumulative.map(d => <td key={`bec_${d.week}`} className={`center-align aepTableCell padding-1 ${d.stringValue === '-' ? '' : (d.value > 0 ? 'red' : 'green')}`}>{d.stringValue}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{i18n.accumulatedPrecipitation}</td>
                        {accumulatedPrecipitation.map(d => <td key={`pc_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{`${i18n.accumulatedIrrigation} (mm)`}</td>
                        {accumulatedIrrigation.map(d => <td key={`irrc_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{i18n.percentNeedCoveredByIrrigation}</td>
                        {percentNeedCoveredByIrrigation.map(d => <td key={`per_irr_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{`% ${i18n.allowedVolume}`}</td>
                        {allowedVolume.map(d => <td key={`per_vol_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}</td>)}
                    </tr>
                    <tr>
                        <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{i18n.meritCoefficient}</td>
                        {chronotension.map(d => <td key={`per_vol_${d.week}`} className='center-align aepTableCell padding-1'>{d.stringValue}</td>)}
                    </tr>
                    {/* add a white line*/}
                    <tr>
                        <td colSpan={2} className='aepTableCell padding-1' />
                        <td colSpan={nbSemaines.length} className='aepTableCell padding-1' style={{ height: 30 }} />
                    </tr>
                    {!!piezos.length && (
                        <>
                            <tr>
                                <td rowSpan={piezos.length} className='aepTableCell padding-1'>{i18n.resource}</td>
                                <td className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{`Piezo ${piezos[0].piezoCode}`}</td>
                                {piezos[0].datas.map(d => getPiezoCell(piezos[0], d))}
                            </tr>
                            {piezos.slice(1).map((p) => (
                                <tr>
                                    <td key={`piezo${p.id}`} className={`aepTableCell padding-1 ${classes.stickyLeft}`}>{`Piezo ${p.piezoCode}`}</td>
                                    {p.datas.map(d => getPiezoCell(p, d))}
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    )
}

IrrigEfficiencyTable.propTypes = {
    label: PropTypes.string,
    producerIds: PropTypes.arrayOf(PropTypes.number),
    microbioData: PropTypes.objectOf(PropTypes.shape({
        nbData: PropTypes.number,
        nbNonConform: PropTypes.number,
        percent: PropTypes.string,
    })),
    classes: PropTypes.objectOf(PropTypes.shape({
        actualCell: PropTypes.instanceOf(PropTypes.object),
        futurCell: PropTypes.instanceOf(PropTypes.object),
    })),
    data: PropTypes.shape({
        piezos: PropTypes.arrayOf(PropTypes.shape({
            piezoId: PropTypes.number,
            piezoCode: PropTypes.string,
            datas: PropTypes.arrayOf(PropTypes.shape({
                week: PropTypes.number,
                stationType: PropTypes.number,
                dataType: PropTypes.number,
                value: PropTypes.number,
                ipsColor: PropTypes.string,
                ipsTrend: PropTypes.string,
                ipsTooltip: PropTypes.string,
                thresholdColor: PropTypes.string,
                thresholdTooltip: PropTypes.string,
            })),
        })),
        etpDatas: PropTypes.arrayOf(PropTypes.shape({
            week: PropTypes.number,
            stationType: PropTypes.number,
            dataType: PropTypes.number,
            value: PropTypes.number,
        })),
        tempDatas: PropTypes.arrayOf(PropTypes.shape({
            week: PropTypes.number,
            stationType: PropTypes.number,
            dataType: PropTypes.number,
            value: PropTypes.number,
        })),
        rainDatas: PropTypes.arrayOf(PropTypes.shape({
            week: PropTypes.number,
            stationType: PropTypes.number,
            dataType: PropTypes.number,
            value: PropTypes.number,
        })),
    }),
    referenceThreshold: PropTypes.number,
    limitThreshold: PropTypes.number,
}

export default withStyles(styles)(IrrigEfficiencyTable)