import {
    H_AGRI_FOLDERS_CONSOS,
    H_AGRI_FOLDERS_DASHBOARD,
    H_AGRI_FOLDERS_DOCUMENTS,
    H_AGRI_FOLDERS_EVENTS,
    H_AGRI_FOLDERS_MATERIELS,
    H_AGRI_FOLDERS_OPTIONS,
    H_AGRI_FOLDERS_VOLUMES,
    H_AGRI_SERVICES,
    H_AGRI_WATER_TURNS,
} from 'account/constants/AccessRulesConstants'
import UserAction from 'administration/components/user/actions/UserAction'
import AgriAction from 'agriAdministration/actions/AgriAction'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import { push } from 'connected-react-router'
import EventsAction from 'events/actions/EventsAction'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import DtoInstallationTank from 'installation/dto/installation/DtoInstallationTank'
import BoundaryError from 'log/components/BoundaryError'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoMatEventsType from 'materiel/components/variousMateriel/dto/DtoMatEventsType'
import DtoVariousMaterielSituation from 'materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from 'materiel/components/variousMateriel/dto/VariousMaterielDto'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import AquiferAction from 'referencial/components/aquifers/actions/AquiferAction'
import DtoAquifer from 'referencial/components/aquifers/dto/DtoAquifer'
import CityAction from 'referencial/components/city/actions/CityAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import HydrogeologicalEntityAction from 'referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedAction from 'referencial/components/watershed/actions/WatershedAction'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import CultureAction from 'referencial/components/cultures/actions/CultureAction'
import CulturesFamilyAction from 'referencial/components/culturesFamilies/actions/CulturesFamilyAction'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoSurvey from 'survey/dto/DtoSurvey'
import DtoWaterTurnsRestriction from 'territory/dto/DtoWaterTurnsRestriction'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import ExploitationConsumptionsApp from './ExploitationConsumptionsApp'
import ExploitationDashboardApp from './ExploitationDashboardApp'
import ExploitationDocumentsApp from './ExploitationDocumentsApp'
import ExploitationEventsApp from './ExploitationEventsApp'
import ExploitationMaterielsApp from './ExploitationMaterielsApp'
import ExploitationsListApp from './ExploitationsListApp'
import ExploitationsSettingsApp from './ExploitationsSettingsApp'
import ExploitationVolumesApp from './ExploitationVolumesApp'
import ExploitationWaterTurnsApp from './ExploitationWaterTurnsApp'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import CityDto from 'referencial/components/city/dto/CityDto'
import HydrogeologicalEntityItemDto from 'referencial/components/hydrogeologicalEntity/dto/HydrogeologicalEntityItemDto'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import ServicesManagerApp from './ServicesManagerApp'
import ExploitationServiceApp from './ExploitationServiceApp'
import HomeAction from 'home/actions/HomeAction'
import ExploitationsServicesSettingsApp from './ExploitationsServicesSettingsApp'
import DtoUserLight from 'administration/components/user/dto/DtoUserLight'
import DtoCityHisto from 'referencial/components/city/dto/DtoCityHisto'
import DtoExploitationMatRep from 'exploitations/dto/DtoExploitationMatRep'

class ExploitationsApp extends Component {
    componentDidMount() {
        if (!componentHasHabilitations(H_AGRI_FOLDERS_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setFormationPath('module-dossiers')
        this.props.setHelpLink('dossiers-irrigants', '')
        const { cities, sandreCodes, contributors, usersLight, surveys, watersheds, aquifers, hydrogeologicalEntities, managementUnits, installationsWithGeo, installationAgriTanks,
            variousMateriels, variousMaterielsLastSituations, matEventsTypes, variousMaterielTypes, cultures, culturesFamilies, usages, agriTanksTypes, citiesHisto, matsRepartitions,
        } = this.props
        if (!cities.length) {
            this.props.fetchCities()
        }
        if (!citiesHisto.length) {
            this.props.fetchCitiesHisto()
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!contributors.length) {
            this.props.fetchContributors()
        }
        if (!usersLight.length) {
            this.props.fetchUsersLight()
        }
        if (!surveys.length) {
            this.props.fetchSurveys()
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }
        if (!managementUnits.length) {
            this.props.fetchManagementUnits()
        }
        if (!installationsWithGeo.length) {
            this.props.fetchInstallationsWithGeo()
        }
        if (!installationAgriTanks.length) {
            this.props.fetchInstallationAgriTanks()
        }
        if (!variousMateriels.length || !variousMaterielsLastSituations.length || !matEventsTypes.length) {
            this.props.fetchAllVariousMatsAndSituations()
        }
        if (!variousMaterielTypes.length) {
            this.props.fetchVariousMaterielTypes()
        }
        if (!usages.length) {
            this.props.fetchUsages()
        }
        if (!cultures.length) {
            this.props.fetchCultures()
        }
        if (!culturesFamilies.length) {
            this.props.fetchCulturesFamilies()
        }
        if (!agriTanksTypes.length) {
            this.props.fetchTanksTypes()
        }
        if (!matsRepartitions.length) {
            this.props.fetchAllMatsRepartitions()
        }
        this.props.fetchBookmarks()
        this.props.fetchInstallationAllEvents()
    }

    componentWillUnmount() {
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
    }

    getLink = () => {
        const { match: { params }, location } = this.props
        if (location.pathname.includes('/dossiers/settings')) {
            const links = [
                {
                    href: '/dossiers/settings/parameters',
                    icons: 'build',
                    name: i18n.parameters,
                    habilitation: H_AGRI_FOLDERS_OPTIONS,
                },
                {
                    href: '/dossiers/settings/services',
                    icons: 'calculate',
                    name: i18n.services,
                    habilitation: H_AGRI_SERVICES,
                }, {
                    href: '/dossiers/settings',
                    icons: 'settings',
                    name: i18n.settings,
                    className: 'bottom-slide-nav-link',
                    habilitation: H_AGRI_FOLDERS_OPTIONS,
                },
            ]
            return links.filter(l => !!l && componentHasHabilitations(l.habilitation))
        }
        return parseInt(params.id) ?
            [
                {
                    href: `/dossiers/${params.id}/dashboard`,
                    icons: 'dashboard',
                    name: i18n.dashboard,
                    habilitation: H_AGRI_FOLDERS_DASHBOARD,
                }, {
                    href: `/dossiers/${params.id}/volumes`,
                    icons: 'opacity',
                    name: i18n.volumes,
                    habilitation: H_AGRI_FOLDERS_VOLUMES,
                }, {
                    href: `/dossiers/${params.id}/materiels`,
                    icons: 'router',
                    name: i18n.materiels,
                    habilitation: H_AGRI_FOLDERS_MATERIELS,
                }, {
                    href: `/dossiers/${params.id}/documents`,
                    icons: 'folder',
                    name: i18n.documents,
                    habilitation: H_AGRI_FOLDERS_DOCUMENTS,
                }, {
                    href: `/dossiers/${params.id}/consumptions`,
                    icons: 'format_color_fill',
                    name: i18n.consos,
                    habilitation: H_AGRI_FOLDERS_CONSOS,
                }, {
                    href: `/dossiers/${params.id}/events`,
                    icons: 'view_column',
                    name: i18n.journal,
                    habilitation: H_AGRI_FOLDERS_EVENTS,
                }, {
                    href: `/dossiers/${params.id}/waterTurns`,
                    icons: 'invert_colors',
                    name: i18n.waterTurns,
                    habilitation: H_AGRI_WATER_TURNS,
                }, {
                    href: `/dossiers/${params.id}/services`,
                    icons: 'calculate',
                    name: i18n.services,
                    habilitation: H_AGRI_WATER_TURNS,
                },
            ] : [
                {
                    href: '/dossiers/dashboard',
                    icons: 'dashboard',
                    name: i18n.dashboard,
                    habilitation: H_AGRI_FOLDERS_DASHBOARD,
                }, {
                    href: '/dossiers/services',
                    icons: 'calculate',
                    name: i18n.services,
                    habilitation: H_AGRI_SERVICES,
                }, {
                    href: '/dossiers/settings',
                    icons: 'settings',
                    name: i18n.settings,
                    className: 'bottom-slide-nav-link',
                    habilitation: H_AGRI_FOLDERS_OPTIONS,
                },
            ]
    }

    render() {
        const navBarLinks = this.getLink().filter(r => componentHasHabilitations(r.habilitation))

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks} />
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact from='/dossiers' to='/dossiers/dashboard' />
                                    <Route path='/dossiers/dashboard' render={(props) => <ExploitationsListApp {...props} />} />
                                    <Route path='/dossiers/services' render={(props) => <ServicesManagerApp {...props} />} />
                                    <Route path='/dossiers/:id/dashboard' render={(props) => <ExploitationDashboardApp {...props} />} />
                                    <Route path='/dossiers/:id/waterTurns' render={(props) => <ExploitationWaterTurnsApp {...props} />} />
                                    <Route exact path='/dossiers/settings/services' render={(props) => <ExploitationsServicesSettingsApp {...props} />} />
                                    <Route path='/dossiers/:id/services' render={(props) => <ExploitationServiceApp {...props} />} />
                                    <Route path='/dossiers/:id/volumes' render={(props) => <ExploitationVolumesApp {...props} />} />
                                    <Route path='/dossiers/:id/materiels' render={(props) => <ExploitationMaterielsApp {...props} />} />
                                    <Route path='/dossiers/:id/documents' render={(props) => <ExploitationDocumentsApp {...props} />} />
                                    <Route path='/dossiers/:id/consumptions' render={(props) => <ExploitationConsumptionsApp {...props} />} />
                                    <Route path='/dossiers/:id/events' render={(props) => <ExploitationEventsApp {...props} />} />
                                    <Redirect exact from='/dossiers/settings' to='/dossiers/settings/parameters' />
                                    <Route path='/dossiers/settings/parameters' render={(props) => <ExploitationsSettingsApp {...props} />} />
                                </Switch>
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

ExploitationsApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    fetchContributors: PropTypes.func,
    fetchCities: PropTypes.func,
    fetchCitiesHisto: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchUsersLight: PropTypes.func,
    fetchSurveys: PropTypes.func,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    citiesHisto: PropTypes.arrayOf(PropTypes.instanceOf(DtoCityHisto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    usersLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserLight)),
    surveys: PropTypes.arrayOf(PropTypes.instanceOf(DtoSurvey)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    aquifers: PropTypes.arrayOf(PropTypes.instanceOf(DtoAquifer)),
    hydrogeologicalEntities: PropTypes.arrayOf(PropTypes.instanceOf(HydrogeologicalEntityItemDto)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    installationAgriTanks: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationTank)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    variousMaterielsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    restrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsRestriction)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
    matsRepartitions: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationMatRep)),
    fetchWatersheds: PropTypes.func,
    fetchAquifers: PropTypes.func,
    fetchHydrogeologicalEntities: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchInstallationsWithGeo: PropTypes.func,
    fetchInstallationAgriTanks: PropTypes.func,
    fetchAllVariousMatsAndSituations: PropTypes.func,
    fetchVariousMaterielTypes: PropTypes.func,
    fetchInstallationAllEvents: PropTypes.func,
    fetchBookmarks: PropTypes.func,
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchUsages: PropTypes.func,
    fetchTanksTypes: PropTypes.func,
    setFormationPath: PropTypes.func,
    setHelpLink: PropTypes.func,
    fetchAllMatsRepartitions: PropTypes.func,
    push: PropTypes.func,
    children: PropTypes.element,
}

const mapStateToProps = (store) => {
    return {
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        usersLight: store.UserReducer.usersLight,
        surveys: store.AgriReducer.surveys,
        watersheds: store.WatershedReducer.watersheds,
        aquifers: store.AquiferReducer.aquifers,
        hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        installationAgriTanks: store.InstallationReducer.installationAgriTanks,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
        matEventsTypes: store.VariousMaterielReducer.matEventsTypes,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        restrictions: store.AgriReducer.restrictions,
        usages: store.UsageReducer.usages,
        cultures: store.CultureReducer.cultures,
        citiesHisto: store.CityReducer.citiesHisto,
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
        agriTanksTypes: store.AgriReducer.agriTanksTypes,
        matsRepartitions: store.AgriReducer.matsRepartitions,
    }
}

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchCities: CityAction.fetchCities,
    fetchCitiesHisto: CityAction.fetchCitiesHisto,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchUsersLight: UserAction.fetchUsersLight,
    fetchBookmarks: UserAction.fetchBookmarks,
    fetchSurveys: AgriAction.fetchSurveys,
    fetchTanksTypes: AgriAction.fetchTanksTypes,
    fetchAllMatsRepartitions: AgriAction.fetchAllMatsRepartitions,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchInstallationsWithGeo: InstallationAction.fetchInstallationsWithGeo,
    fetchInstallationAgriTanks: InstallationAction.fetchInstallationAgriTanks,
    fetchAllVariousMatsAndSituations: VariousMaterielAction.fetchAllVariousMatsAndSituations,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    fetchInstallationAllEvents: EventsAction.fetchInstallationAllEvents,
    fetchCultures: CultureAction.fetchCultures,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    fetchUsages: UsageAction.fetchUsages,
    setFormationPath: HomeAction.setFormationPath,
    setHelpLink: HomeAction.setHelpLink,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationsApp)
