import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../components/react/Row'
import Button from '../../components/forms/Button'
import SimpleDatePicker from '../../components/forms/SimpleDatePicker'
import DtoStation from '../dto/DtoStation'
import DtoParametrageDataType from '../../piezometry/dto/DtoParametrageDataType'
import Select from '../../components/forms/Select'
import { getMeasureCoteList } from '../../utils/JobUtils'
import RadioButtons from '../../components/forms/RadioButtons'

const StationsExcelExportPanel = ({
    onChange = () => {},
    onChangeFilter = () => {},
    onApplyExport = () => {},
    selectedStations = [],
    tmpFilter = {},
    dataTypes = [],
    selectedDataType = 0,
    displayCote,
}) => (
    <div className='row no-margin padding-1'>
        <fieldset>
            <legend className='italic-label padding-right-1 padding-left-1'>{i18n.exportCriterias}</legend>
            <Row>
                <Select
                    col={5}
                    label={i18n.dataTypes}
                    options={dataTypes}
                    onChange={v => onChange({ dataType: v })}
                    value={selectedDataType}
                    keyValue='id'
                    keyLabel='label'
                />
                <SimpleDatePicker
                    col={2}
                    value={tmpFilter.startDate}
                    label={i18n.startDate}
                    onChange={v => onChangeFilter({ startDate: v })}
                />
                <SimpleDatePicker
                    col={2}
                    value={tmpFilter.endDate}
                    label={i18n.endDate}
                    onChange={v => onChangeFilter({ endDate: v })}
                />
                <Button col={3} title={`${i18n.selectStations} (${selectedStations.length})`}
                    onClick={() => onChange({ selectStationIsOpen: true })}
                />
            </Row>
            <Row>
                <RadioButtons col={3} elements={getMeasureCoteList()} selected={displayCote}
                    onChange={v => onChange({ displayCote: v })}
                    title={i18n.ratingExpression} disabled={selectedDataType !== -1}
                />
                <div className='col s7'/>
                <Button col={2} title={i18n.apply} onClick={() => onApplyExport('xlsx')}/>
            </Row>
        </fieldset>
    </div>
)


StationsExcelExportPanel.propTypes = {
    onChange: PropTypes.func,
    onChangeFilter: PropTypes.func,
    selectedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    tmpFilter: PropTypes.shape({
        startDate: PropTypes.number,
        endDate: PropTypes.number,
    }),
    onApplyExport: PropTypes.func,
    dataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    selectedDataType: PropTypes.number,
    displayCote: PropTypes.number,
}

export default StationsExcelExportPanel
