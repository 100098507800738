/* eslint-disable react/prop-types */
'use strict'
import { groupBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResourceDto from 'resource/dto/ResourceDto'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import Input from '../../../components/forms/Input'
import ListComponent from '../../../components/list/tableList/ListComponent'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import HomeAction from '../../../home/actions/HomeAction'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallationLight from '../../../installation/dto/installation/DtoInstallationLight'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../store/AppStore'
import { getSiteUrl } from '../../../utils/mapUtils/SiteTypes'
import { arrayOf, getMapStateToProps, getPropType, getPropTypes } from '../../../utils/StoreUtils'
import StationAction from '../../actions/StationAction'
import { STATION_NAME_ASSOCIATION } from '../../constants/StationConstants'
import DtoAssociatedStation from '../../dto/DtoAssociatedStation'

const storeProps = {
    associatedSites: false,
    stationTypes: false,
}

class AssociatedStationsEditModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            dataLoaded: false,
        }
    }

    componentDidMount() {
        this.props.loadAllStations().then(() => this.setState({ dataLoaded: true }))
    }

    onChangeValue = (value) => {
        this.setState({ searchValue: value })
    }

    onSearchValue = (station) => {
        return ((station.code || station.id).toString().toLowerCase().includes(this.state.searchValue.toLowerCase())
            || (station.name && station.name.toLowerCase().includes(this.state.searchValue.toLowerCase())))
    }

    getStationType = (station) => {
        const found = this.props.stationTypes.find(t => t.name === STATION_NAME_ASSOCIATION[station.typeName])
        return found ? found.code : null
    }

    deleteLink = link => {
        AppStore.dispatch(StationAction.deleteAssociatedSite(link))
    }

    render() {
        if (!this.state.dataLoaded) {
            return <ProgressCard indeterminate={ true } />
        }
        const { filteredStations } = this.props
        const data = this.props.associatedSites.map(link => ({
            ...link,
            code: link.stationLinkedCode,
            name: link.stationLinkedName,
            nullValue: { leftIcon: 'clear', leftIconColor: 'black', color: 'white', onClick: () => this.deleteLink(link) },
            type: <img src={getSiteUrl(STATION_NAME_ASSOCIATION[link.typeName])} className='tooltipped' data-tooltip={i18n[link.typeName]} style={{ maxHeight: '30px' } } />,
        }))
        const allStations = [...this.props.qualitometers, ...this.props.piezometers, ...this.props.hydrometricStations, ...this.props.pluviometers, ...this.props.productionUnits, ...this.props.distributionUnits, ...this.props.installations, ...this.props.resources]
            .filter(s => this.onSearchValue(s) && !data.find(associated => associated.stationLinkedId === s.id && associated.typeName === s.typeName))
        const allFilteredStations = filteredStations && filteredStations.length ? allStations.filter(station => filteredStations.includes(station.typeName)) : allStations
        const groupedStations = groupBy(allFilteredStations, 'typeName')
        const groupTables = Object.keys(groupedStations).map(typeName => {
            return {
                title: (
                    <div className='col s12 valign-wrapper'>
                        <div className='col s2 no-margin'>
                            <img src={getSiteUrl(STATION_NAME_ASSOCIATION[typeName])} style={{ maxHeight: '30px' } } />
                        </div>
                        <div className='col s3 no-margin'>
                            <h5>{ i18n[typeName] }</h5>
                        </div>
                        <div className='col s5 no-margin'>
                            <h5>{ `${groupedStations[typeName].length} ${i18n.stations}` }</h5>
                        </div>
                    </div>
                ),
                component: (
                    <Table showTitle={ false } condensed data={ groupedStations[typeName] } sortable paging nbPerPageLabel={ nbPerPageLabelShort }
                        onClick={ s => {
                            const link = {
                                code: this.props.station.code,
                                stationType: this.getStationType(this.props.station),
                                id: this.props.station.id,
                                stationLinkedCode: s.code,
                                stationLinkedType: this.getStationType(s),
                                stationLinkedId: s.id,
                                typeName: s.typeName,
                            }
                            AppStore.dispatch(StationAction.addAssociatedSite(link))
                        } }
                        type={ { headers: ['code', 'name'] } }
                    />
                ),
            }
        })
        return (
            <div>
                <div className='row no-margin'>
                    <Table title={ i18n.associatedStations } condensed data={ data } sortable paging nbPerPageLabel={ nbPerPageLabelShort }
                        type={ { headers: ['nullValue', 'type', 'code', 'name'] } } onClick={ () => {} }
                    />
                </div>
                <div className='row no-margin'>
                    <Card title={ i18n.addStation }>
                        <div className='row padding-top-1 no-margin'>
                            <Input col={ 8 } tooltip={ i18n.searchThenEnter } title={ i18n.search } value={ this.state.searchValue }
                                onEnterKeyPress={ (value) => this.onChangeValue(value) }
                            />
                        </div>
                        <div className='row no-margin'>
                            <ListComponent tables={ groupTables } accordion />
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}

AssociatedStationsEditModal.propTypes = getPropTypes(storeProps, {
    associatedStations: arrayOf(DtoAssociatedStation),
    station: getPropType('qualitometer'),
    piezometers: arrayOf(DtoPiezometerLight),
    filteredStations: PropTypes.arrayOf(PropTypes.string),
    pluviometers: arrayOf(PluviometerDto),
    qualitometers: arrayOf(DtoQualitometerLight),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    installations: arrayOf(DtoInstallationLight),
    productionUnits: arrayOf(DtoProductionUnit),
    distributionUnits: arrayOf(DtoDistributionUnit),
    resources: PropTypes.arrayOf(PropTypes.instanceOf(ResourceDto)),
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    qualitometers: store.QualityReducer.qualitometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installationsLight,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    resources: store.ResourceReducer.resources,
})

const mapDispatchToProps = {
    loadAllStations: HomeAction.loadGlobalResearchStations,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedStationsEditModal)
