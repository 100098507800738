import { createSlice } from '@reduxjs/toolkit'
import UserThunk from 'administration/components/user/actions/UserThunk'
import AgriThunk from 'agriAdministration/actions/AgriThunk'
import CampaignThunk from 'campaign/actions/CampaignThunk'
import CatchmentThunk from 'catchment/actions/CatchmentThunk'
import DistributionUnitThunk from 'distributionUnit/actions/DistributionUnitThunk'
import HydrometryThunk from 'hydrometry/actions/HydrometryThunk'
import InstallationThunk from 'installation/actions/InstallationThunk'
import MaterielThunk from 'materiel/actions/MaterielThunk'
import PiezometryThunk from 'piezometry/actions/PiezometryThunk'
import PluviometryThunk from 'pluviometry/actions/PluviometryThunk'
import ProductionUnitThunk from 'productionUnit/actions/ProductionUnitThunk'
import QualityThunk from 'quality/actions/QualityThunk'
import ContactThunk from 'referencial/components/contact/actions/ContactThunk'
import ContributorThunk from 'referencial/components/contributor/actions/ContributorThunk'
import { IDLE, LOADING, REJECTED, SUCCEEDED } from './DataManagerConstants'


export const initialState = { // garder la même structure que les reducer
    materiel: {
        settingRulesStatus: IDLE,
    },
    catchment: {
        catchmentsStatus: IDLE,
        catchmentPointsStatus: IDLE,
        catchmentAnalysisStatus: IDLE,
        catchmentsAnalysisStatus: IDLE,
    },
    user: {
        userBookmarksStatus: IDLE,
    },
    quality: {
        qualitometersListSpecificStatus: IDLE,
    },
    campaign: {
        qualityCampaignsStatus: IDLE,
        qualityCampaignsProgressStatus: IDLE,
        piezometryCampaignsStatus: IDLE,
        piezometryCampaignsProgressStatus: IDLE,
    },
    piezometry: {
        piezometersLightStatus: IDLE,
        piezometryRawSituationStatus: IDLE,
    },
    hydrometry: {
        hydrometricStationsStatus: IDLE,
        hydroStatsStatus: IDLE,
    },
    productionUnit: {
        productionUnitsStatus: IDLE,
    },
    pluviometry: {
        pluviometersStatus: IDLE,
    },
    agri: {
        exploitationsLightStatus: IDLE,
        surveysWithStatsStatus: IDLE,
    },
    installation: {
        installationsLightStatus: IDLE,
    },
    distributionUnit: {
        distributionUnitsStatus: IDLE,
    },
    contact: {
        contactsStatus: IDLE,
    },
    contributor: {
        contributorsStatus: IDLE,
    },
}

const getThunk = (fetch, reducer, stateName) => {
    return {
        [fetch.pending]: state => {
            state[reducer][stateName] = LOADING
        },
        [fetch.fulfilled]: state => {
            state[reducer][stateName] = SUCCEEDED
        },
        [fetch.rejected]: (state, action) => {
            if (!action.meta.condition || state[reducer][stateName] === IDLE) {
                state[reducer][stateName] = REJECTED
            }
        },
    }
}

const store = createSlice({
    name: 'dataManager',
    initialState,
    reducers: {},
    extraReducers: {
        // add reset action here
        'catchment/reset': state => {
            state.catchment.catchmentAnalysisStatus = IDLE
        },
        'hydrometry/resetHydroStats': state => {
            state.hydrometry.hydroStatsStatus = IDLE
        },
        'piezometry/resetPiezometryRawSituation': state => {
            state.piezometry.piezometryRawSituationStatus = IDLE
        },
        ...getThunk(MaterielThunk.fetchSettingRules, 'materiel', 'settingRulesStatus'),
        ...getThunk(CatchmentThunk.fetchCatchments, 'catchment', 'catchmentsStatus'),
        ...getThunk(CatchmentThunk.fetchCatchmentPoints, 'catchment', 'catchmentPointsStatus'),
        ...getThunk(CatchmentThunk.fetchCatchmentsAnalysis, 'catchment', 'catchmentsAnalysisStatus'),
        ...getThunk(UserThunk.fetchBookmarks, 'user', 'userBookmarksStatus'),
        ...getThunk(QualityThunk.fetchQualitometersListSpecific, 'quality', 'qualitometersListSpecificStatus'),
        ...getThunk(CampaignThunk.fetchQualityCampaigns, 'campaign', 'qualityCampaignsStatus'),
        ...getThunk(CampaignThunk.fetchQualityCampaignsProgress, 'campaign', 'qualityCampaignsProgressStatus'),
        ...getThunk(PiezometryThunk.fetchPiezometersLight, 'piezometry', 'piezometersLightStatus'),
        ...getThunk(CampaignThunk.fetchPiezometryCampaigns, 'campaign', 'piezometryCampaignsStatus'),
        ...getThunk(CampaignThunk.fetchPiezometryCampaignsProgress, 'campaign', 'piezometryCampaignsProgressStatus'),
        ...getThunk(PiezometryThunk.fetchPiezometryRawSituation, 'piezometry', 'piezometryRawSituationStatus'),
        ...getThunk(HydrometryThunk.fetchHydrometricStations, 'hydrometry', 'hydrometricStationsStatus'),
        ...getThunk(HydrometryThunk.fetchHydroBookmarksStats, 'hydrometry', 'hydroBookmarksStatsStatus'),
        ...getThunk(ProductionUnitThunk.fetchProductionUnits, 'productionUnit', 'productionUnitsStatus'),
        ...getThunk(PluviometryThunk.fetchPluviometers, 'pluviometry', 'pluviometersStatus'),
        ...getThunk(AgriThunk.fetchExploitationsLight, 'agri', 'exploitationsLightStatus'),
        ...getThunk(AgriThunk.fetchSurveysWithStats, 'agri', 'surveysWithStatsStatus'),
        ...getThunk(InstallationThunk.fetchInstallationsLight, 'installation', 'installationsLightStatus'),
        ...getThunk(DistributionUnitThunk.fetchDistributionUnits, 'distributionUnit', 'distributionUnitsStatus'),
        ...getThunk(ContactThunk.fetchContacts, 'contact', 'contactsStatus'),
        ...getThunk(ContributorThunk.fetchContributors, 'contributor', 'contributorsStatus'),
    },
})

export default store.reducer