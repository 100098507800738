module.exports = {
    links: {
        dashboard: {
            icons: 'dashboard',
            i18n: 'dashboard',
            name: 'Dashboard',
            id: 'dashboard',
        },
        situation: {
            icons: 'view_column',
            i18n: 'situation',
        },
        integrationOverview: {
            icons: 'traffic',
            i18n: 'integrationOverview',
            hrefName: 'integrationOverview',
            id: 'integration_overview',
        },
        exportOverview: {
            icons: 'archive',
            i18n: 'exportOverview',
            hrefName: 'exportOverview',
            id: 'export_overview',
        },
        research: {
            icons: 'search',
            i18n: 'search',
            id: 'search',
        },
        campaign: {
            icons: 'nature_people',
            i18n: 'campaigns',
            name: 'Campagnes',
            id: 'campaigns',
        },
        import: {
            icons: 'file_upload',
            i18n: 'importName',
            href: '/import/0',
            id: 'import',
        },
        export: {
            icons: 'file_download',
            i18n: 'exportName',
            id: 'export',
        },
        conformityOverview: {
            icons: 'playlist_add_check',
            i18n: 'AEPoverview',
            id: 'conformity_overview',
        },
        graphic: {
            icons: 'insert_chart',
            i18n: 'overlayGraphic',
            id: 'overlay_graphic',
        },
        diagnostics: {
            icons: 'view_column',
            i18n: 'diagnosticsTable',
            id: 'diagnosticsTable',
        },
        mapSituations: {
            icons: 'map',
            i18n: 'mapSituations',
            id: 'map_situations',
        },
        piezometryOptions: {
            icons: 'settings',
            i18n: 'piezometryOptions',
            className: 'bottom-slide-nav-link',
        },
        pluviometryOptions: {
            icons: 'settings',
            i18n: 'pluviometryOptions',
            className: 'bottom-slide-nav-link',
        },
        qualityOptions: {
            icons: 'settings',
            i18n: 'qualityOptions',
            className: 'bottom-slide-nav-link',
            id: 'quality_options',
        },
        hydrometryOptions: {
            icons: 'settings',
            i18n: 'hydrometryOptions',
            className: 'bottom-slide-nav-link',
        },
        installationOptions: {
            icons: 'settings',
            i18n: 'test',
            className: 'bottom-slide-nav-link',
            name: 'Options d\'installation',
        },
        distributionUnitOptions: {
            icons: 'settings',
            i18n: 'distributionUnitOptions',
            className: 'bottom-slide-nav-link',
            href: '/distributionUnit/options',
        },
        territoryActivities: {
            icons: 'data_usage',
            i18n: 'territoryActivities',
            name: 'Activités du territoire',
        },
        counter: {
            icons: 'search',
            i18n: 'search',
            hrefName: 'search',
            name: 'Compteurs',
        },
        resourceOptions: {
            icons: 'settings',
            i18n: 'settings',
            className: 'bottom-slide-nav-link',
            id: 'resource_options',
            hrefName: 'settings',
        },
        counters: {
            icons: 'heat_pump',
            i18n: 'counters',
        },
    },
}
