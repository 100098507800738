/* eslint-disable consistent-return */
import { Button, Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { every, intersectionWith, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import Card from '../../../../../components/card/Card'
import Table from '../../../../../components/datatable/Table'
import Input from '../../../../../components/forms/Input'
import Select from '../../../../../components/forms/Select'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import { getParametersNature, getParametersType } from '../../../../../referencial/util/ReferencialUtils'
import { getStatusInformation } from '../../../../../referencial/util/StatusUtils'
import { getLocalizationPicto } from '../../../../../utils/AnalyseUtils'
import { hasValue } from '../../../../../utils/NumberUtil'
import { getI18nTitleDataLength, searchAllCharacters } from '../../../../../utils/StringUtil'


const ValidateParameterTab = ({
    parameterListCode = '',
    parameters = [],
    onChangeSelection = () => {},
    selectedParameters = [],
    canEdit = false,
    onChangeSelectedParameter = () => { },
    selections = [],
}) => {
    const {
        fractions,
        supports,
        units,
        selectionsWithParam,
    } = useSelector(store => ({
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        units: store.UnitReducer.units,
        selectionsWithParam: store.ParameterReducer.selectionsWithParam,
    }))

    const analysePlace = useSandreList('ANALYSES.LIEU')

    const [search, setSearch] = useState('')
    const [codeUnit, setCodeUnit] = useState(undefined)
    const [codeFraction, setCodeFraction] = useState(undefined)
    const [codeSupport, setCodeSupport] = useState(undefined)
    const [codePlace, setCodePlace] = useState(undefined)
    const [codeParam, setCodeParam] = useState(undefined)

    const types = getParametersType()
    const natures = getParametersNature()

    const formatedParameters = useMemo(() => {
        return parameters.map(({ code, name, typeParam, nature, status, labelSearch, ...defaultValue }) => {
            const param = selectedParameters.find(s => s.parameterCode === code)
            const isSelected = !!param
            const {
                unitCode,
                fractionCode,
                supportCode,
                placeCode,
            } = param || defaultValue
            const unit = unitCode && units.find(u => u.id === unitCode)
            return {
                name: name && `[${code}] ${name}` || '',
                type: types[typeParam] || '',
                nature: natures[nature] || '',
                status: status ? getStatusInformation(status) : i18n.validated,
                unit: unit ? `[${unit.code}] ${unit.symbol}` : '',
                fraction: fractionCode && fractions.find(f => f.id === fractionCode)?.labelWithCode,
                support: supportCode && supports.find(s => s.id === supportCode)?.labelWithCode,
                place: placeCode && getLocalizationPicto(placeCode),
                nullValue: <i className='material-icons clickable'>{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>,
                color: code === codeParam ? '#5c8fec' : isSelected ? '#b8d2ff' : '#fff',
                code,
                isSelected,
                unitCode,
                fractionCode,
                supportCode,
                placeCode,
                labelSearch,
            }
        })
    }, [codeParam, fractions, natures, parameters, selectedParameters, supports, types, units])

    const filteredParameters = useMemo(() => {
        const searchValueFormat = searchAllCharacters(search)
        const searchFilter = searchValueFormat ? formatedParameters.filter(p => p.labelSearch.includes(searchValueFormat)) : formatedParameters
        const {
            parameters: selectionParams = [],
        } = parameterListCode && selectionsWithParam.find(({ code }) => code === parameterListCode) || {}
        return parameterListCode ? intersectionWith(searchFilter, selectionParams, (p, code) => p.code === code) : searchFilter
    }, [formatedParameters, parameterListCode, search, selectionsWithParam])

    const onSelectionSelection = codeSelection => {
        if (hasValue(codeSelection)) {
            const {
                parameters: selectionParams = [],
            } = selectionsWithParam.find(({ code }) => code === codeSelection) || {}
            const newSelectedParameters = selectionParams.map(parameterCode => {
                const param = formatedParameters.find(({ code }) => code === parameterCode)
                if (!param) {
                    return
                }
                const {
                    unitCode,
                    fractionCode,
                    supportCode,
                    placeCode,
                } = param
                return {
                    parameterCode,
                    unitCode,
                    fractionCode,
                    supportCode,
                    placeCode,
                }
            })
            onChangeSelection(codeSelection, newSelectedParameters.filter(p => !!p))
        } else {
            const newSelectedParameters = formatedParameters.map(({ code, unitCode, fractionCode, supportCode, placeCode }) => {
                return {
                    parameterCode: code,
                    unitCode,
                    fractionCode,
                    supportCode,
                    placeCode,
                }
            })
            onChangeSelection(codeSelection, newSelectedParameters)
        }
    }

    const onClickParameter = ({ code, unitCode, fractionCode, supportCode, placeCode, isSelected }) => {
        const newSelectedParameters = isSelected ? selectedParameters.filter(s => s.parameterCode !== code) : [...selectedParameters, { parameterCode: code, unitCode, fractionCode, supportCode, placeCode }]
        onChangeSelectedParameter(newSelectedParameters)
    }

    const checkAll = filterParameters => {
        onChangeSelectedParameter(uniqBy([...selectedParameters, ...filterParameters.map(({ code, unitCode, fractionCode, supportCode, placeCode }) => ({ parameterCode: code, unitCode, fractionCode, supportCode, placeCode }))], 'parameterCode'))
    }

    const uncheckAll = filterParameters => {
        onChangeSelectedParameter(selectedParameters.filter(s => !filterParameters.find(({ code }) => s.parameterCode === code)))
    }

    const actionCard = every(parameters, s => selectedParameters.find(select => select.parameterCode === s.code)) ? [{
        onClick: () => uncheckAll(parameters),
        iconName: 'check_box_outline_blank',
        tooltip: i18n.unselectAll,
    }] : [{
        onClick: () => checkAll(parameters),
        iconName: 'check_box',
        tooltip: i18n.selectAll,
    }]

    const selectionList = selections.map(c => selectionsWithParam.find(({ code }) => code === c)).filter(s => !!s)

    return (
        <>
            <StyledFieldSet style={{ padding: '15px 10px' }}>
                <StyledLegend>{i18n.criterias}</StyledLegend>
                <Grid container alignItems='center' justifyContent='left' spacing={2}>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.research}
                            value={search}
                            onChange={setSearch}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            options={selectionList}
                            value={parameterListCode}
                            label={i18n.selection}
                            keyLabel='name'
                            keyValue='code'
                            returnNull
                            onChange={code => onSelectionSelection(code, parameters)}
                        />
                    </Grid>
                </Grid>
            </StyledFieldSet>
            <Card
                title={`${i18n.parameters} (${selectedParameters.length} ${getI18nTitleDataLength(i18n.selectedElement, i18n.selectedElements, selectedParameters.length)})`}
                actions={actionCard}
                cardStyle={{ marginTop: '10px' }}
                noMargin={false}
            >
                <Table
                    showTitle={false}
                    data={filteredParameters}
                    type={{ headers: ['name', 'type', 'nature', 'status', 'unit', 'fraction', 'support', 'place', 'nullValue'] }}
                    coloredLine
                    maxHeight={codeParam ? '35vh' : '53vh'}
                    onClick={onClickParameter}
                    alterable={canEdit}
                    onAlter={({ code, unitCode, fractionCode, supportCode, placeCode }) => {
                        setCodeParam(code)
                        setCodeUnit(unitCode)
                        setCodeFraction(fractionCode)
                        setCodeSupport(supportCode)
                        setCodePlace(placeCode)
                    }}
                    sortable
                    condensed
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Card>
            {
                !!codeParam && (
                    <StyledFieldSet style={{ padding: '15px 10px' }}>
                        <StyledLegend>{parameters.find(p => p.code === codeParam)?.displayLabel}</StyledLegend>
                        <Grid container alignItems='center' justifyContent='left' spacing={1} columns={9}>
                            <Grid item xs={3}>
                                <Select
                                    value={codeUnit}
                                    options={units}
                                    label={i18n.unit}
                                    onChange={setCodeUnit}
                                    keyLabel='labelWithCode'
                                    dropdownHeight={'180px'}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    value={codeFraction}
                                    options={fractions}
                                    label={i18n.fraction}
                                    onChange={setCodeFraction}
                                    keyLabel='labelWithCode'
                                    keyValue='id'
                                    dropdownHeight={'180px'}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    value={codeSupport}
                                    options={supports}
                                    label={i18n.support}
                                    onChange={setCodeSupport}
                                    keyLabel='labelWithCode'
                                    keyValue='id'
                                    dropdownHeight={'180px'}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    options={analysePlace}
                                    value={codePlace}
                                    label={i18n.place}
                                    keyLabel='label'
                                    keyValue='value'
                                    returnNull
                                    onChange={v => setCodePlace(hasValue(v) && `${v}` || '')}
                                    dropdownHeight={'180px'}
                                />
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={1}>
                                <Button
                                    variant='outlined'
                                    fullWidth
                                    onClick={() => {
                                        setCodeParam(undefined)
                                        setCodeUnit(undefined)
                                        setCodeFraction(undefined)
                                        setCodeSupport(undefined)
                                        setCodePlace(undefined)
                                    }}
                                >
                                    {i18n.cancel}
                                </Button>
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant='contained'
                                    fullWidth
                                    onClick={() => {
                                        if (codeParam) {
                                            const newParam = selectedParameters.map(s => s.parameterCode === codeParam ? { ...s, placeCode: codePlace, supportCode: codeSupport, fractionCode: codeFraction, unitCode: codeUnit } : s)
                                            onChangeSelectedParameter(newParam)
                                            setCodeParam(undefined)
                                            setCodeUnit(undefined)
                                            setCodeFraction(undefined)
                                            setCodeSupport(undefined)
                                            setCodePlace(undefined)
                                        }
                                    }}
                                >
                                    {i18n.change}
                                </Button>
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant='contained'
                                    fullWidth
                                    onClick={() => {
                                        if (codeParam) {
                                            const newParams = selectedParameters.map(s => ({ ...s, placeCode: codePlace, supportCode: codeSupport, fractionCode: codeFraction, unitCode: codeUnit }))
                                            onChangeSelectedParameter(newParams)
                                            setCodeParam(undefined)
                                            setCodeUnit(undefined)
                                            setCodeFraction(undefined)
                                            setCodeSupport(undefined)
                                            setCodePlace(undefined)
                                        }
                                    }}
                                >
                                    {i18n.changeAll}
                                </Button>

                            </Grid>
                        </Grid>
                    </StyledFieldSet>
                )
            }
        </>
    )
}

ValidateParameterTab.propTypes = {
    canEdit: PropTypes.bool,
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    onChangeSelectedParameter: PropTypes.func,
    onChangeSelection: PropTypes.func,
    parameterListCode: PropTypes.string,
    selections: PropTypes.arrayOf(PropTypes.string),
}

export default ValidateParameterTab