export default class DtoAssigmentItem {
    constructor(obj) {
        this.id = obj.id
        this.reference = obj.reference
        this.type = obj.type
        this.serialNumber = obj.serialNumber
        this.situationDate = obj.situationDate
        this.situationEndDate = obj.situationEndDate
        this.headers = ['reference', 'type', 'serialNumber', 'situationDate', 'situationEndDate']
    }
}