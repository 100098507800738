import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper } from '@mui/material'
import i18n from 'simple-react-i18n'
import React, { useState, useEffect, useCallback } from 'react'
import ModelStepObjective from './steps/ModelStepObjective'
import { MODEL_TYPES } from '../../../iaeau/constants/IAEauConstants'
import { uniq } from 'lodash'
import ModelStepHorizon from './steps/ModelStepHorizon'
import ModelStepModel from './steps/ModelStepModel'
import ModelStepStations from './steps/ModelStepStations'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ModelStepInputs from './steps/ModelStepInputs'
import ModelStepAccess from './steps/ModelStepAccess'
import ModelStepUse from './steps/ModelStepUse'
import ModelStepSettings from './steps/ModalStepSettings'
import IAEauAction from '../../../iaeau/IAEauAction'
import { genericFetch, genericPromise } from '../../../utils/ActionUtils'
import ApplicationConf from '../../../conf/ApplicationConf'
import { hasValue } from '../../../utils/NumberUtil'
import ToastrAction from '../../../toastr/actions/ToastrAction'

const STEP = {
    OBJECTIVE: 0,
    HORIZON: 1,
    MODEL: 2,
    STATIONS: 3,
    INPUTS: 4,
    ACCESS: 5,
    USE: 6,
    SETTINGS: 7,
}

const IAEauModelStepper = (props) => {
    const {
        stationType,
        id, // station id
        isOpen,
        setIsOpen,
        selectedModel,
        setSelectedModel,
    } = props
    const dispatch = useDispatch()
    const [step, setStep] = useState(STEP.OBJECTIVE)
    const [activatedSteps, setActivatedSteps] = useState([STEP.OBJECTIVE])
    const params = JSON.parse(selectedModel.params)

    const changeModel = useCallback((changes) => setSelectedModel({ ...selectedModel, ...changes }), [selectedModel])
    const changeParams = (changes) => changeModel({ params: JSON.stringify({ ...params, ...changes }) })
    const addActivatedStep = newStep => setActivatedSteps(uniq([...activatedSteps, newStep]))

    useEffect(() => {
        if (stationType == 'piezometry') {
            genericPromise(ApplicationConf.station.getMeteoNappesModelId(id)).then(json => {
                if (hasValue(json.modelId)) {
                    changeModel({ meteoNappesExists: true })
                }
            })
        }
    }, [])

    const getStep = () => {
        switch (step) {
            case STEP.OBJECTIVE:
                return <ModelStepObjective {...{ ...props, selectedModel, changeModel }}/>
            case STEP.HORIZON:
                return <ModelStepHorizon {...{ ...props, selectedModel, changeModel }}/>
            case STEP.MODEL:
                return <ModelStepModel {...{ ...props, selectedModel, changeModel, changeParams, params }}/>
            case STEP.STATIONS:
                return <ModelStepStations {...{ ...props, selectedModel, changeModel, changeParams, params }}/>
            case STEP.INPUTS:
                return <ModelStepInputs {...{ ...props, selectedModel, changeModel, changeParams, params }}/>
            case STEP.ACCESS:
                return <ModelStepAccess {...{ ...props, selectedModel, changeModel, changeParams, params }}/>
            case STEP.USE:
                return <ModelStepUse {...{ ...props, selectedModel, changeModel, changeParams, params }}/>
            case STEP.SETTINGS:
                return <ModelStepSettings {...{ ...props, selectedModel, changeModel, changeParams, params }}/>
        }
    }

    const getNextStep = () => {
        switch (step) {
            case STEP.OBJECTIVE:
                return STEP.HORIZON
            case STEP.HORIZON:
                return STEP.MODEL
            case STEP.MODEL:
                return selectedModel.typeModel === MODEL_TYPES.PERCEPTRON ? STEP.STATIONS : STEP.ACCESS
            case STEP.STATIONS:
                return STEP.INPUTS
            case STEP.INPUTS:
                return STEP.ACCESS
            case STEP.ACCESS:
                return STEP.USE
            case STEP.USE:
                return STEP.SETTINGS
        }
    }

    const canDoNext = () => {
        switch (step) {
            case STEP.OBJECTIVE:
                return !!selectedModel.objective
            case STEP.HORIZON:
                return !!selectedModel.horizon
            case STEP.MODEL:
                return !!selectedModel.typeModel
            case STEP.STATIONS:
                return true
            case STEP.INPUTS:
                return true
            case STEP.ACCESS:
                return true
            case STEP.USE:
                return true
        }
    }

    const isActive = testStep => activatedSteps.includes(testStep)

    const onSave = () => {
        if (!selectedModel.name) {
            dispatch(ToastrAction.warning('Veuillez choisir un nom.'))
        } else {
            const func = selectedModel.idModel === 0 ? IAEauAction.createModel : IAEauAction.updateModel
            func(stationType, parseInt(id), selectedModel).then(() => {
                dispatch(IAEauAction.getModels(stationType, parseInt(id)))
                setIsOpen(false)
            })
        }
    }

    return (
        <Dialog
            onClose={() => setIsOpen(false)}
            fullWidth
            maxWidth='lg'
            open={isOpen}
        >
            <DialogTitle>{'Nouveau modèle'}</DialogTitle>
            <DialogContent>
                <div className='row no-margin' style={{ height: '70vh', overflowY: 'auto' }}>
                    <Stepper
                        activeStep={step}
                        style={{ zoom: '1.2', height: '30px' }}
                    >
                        <Step
                            className={ isActive(STEP.OBJECTIVE) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.OBJECTIVE) ? setStep(STEP.OBJECTIVE) : null }
                        >
                            <StepLabel>{'Objectif'}</StepLabel>
                        </Step>
                        <Step
                            className={ activatedSteps.includes(STEP.HORIZON) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.HORIZON) ? setStep(STEP.HORIZON) : null }
                        >
                            <StepLabel>{'Horizon'}</StepLabel>
                        </Step>
                        <Step
                            className={ activatedSteps.includes(STEP.MODEL) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.MODEL) ? setStep(STEP.MODEL) : null }
                        >
                            <StepLabel>{'Modèle'}</StepLabel>
                        </Step>
                        <Step
                            className={ activatedSteps.includes(STEP.STATIONS) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.STATIONS) ? setStep(STEP.STATIONS) : null }
                        >
                            <StepLabel>{'Choix des stations'}</StepLabel>
                        </Step>
                        <Step
                            className={ activatedSteps.includes(STEP.INPUTS) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.INPUTS) ? setStep(STEP.INPUTS) : null }
                        >
                            <StepLabel>{'Entrées'}</StepLabel>
                        </Step>
                        <Step
                            className={ activatedSteps.includes(STEP.ACCESS) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.ACCESS) ? setStep(STEP.ACCESS) : null }
                        >
                            <StepLabel>{'Droits d\'accès'}</StepLabel>
                        </Step>
                        <Step
                            className={ activatedSteps.includes(STEP.USE) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.USE) ? setStep(STEP.USE) : null }
                        >
                            <StepLabel>{'Affichage'}</StepLabel>
                        </Step>
                        <Step
                            className={ activatedSteps.includes(STEP.SETTINGS) ? 'clickable' : 'stepDisabled'}
                            onClick={() => isActive(STEP.SETTINGS) ? setStep(STEP.SETTINGS) : null }
                        >
                            <StepLabel>{'Autres paramètres'}</StepLabel>
                        </Step>
                    </Stepper>
                    { getStep() }
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setIsOpen(false)} color='primary' variant='outlined' >
                    {i18n.cancel}
                </Button>
                {
                    step === STEP.SETTINGS ? (
                        <Button onClick={onSave} color='primary' variant='contained' >
                            {i18n.validate}
                        </Button>
                    ) : (
                        <Button onClick={() => {
                            const newStep = getNextStep()
                            addActivatedStep(newStep)
                            setStep(newStep)
                        }} color='primary' variant='contained' disabled={!canDoNext()}
                        >
                            {i18n.next}
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    )
}

IAEauModelStepper.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
}

export default IAEauModelStepper