import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Input from '../../../../components/forms/Input'
import { Button, Grid } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import DtoQualitometerLight from 'quality/dto/DtoQualitometerLight'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import DtoProductionUnit from 'productionUnit/dto/DtoProductionUnit'
import DtoDistributionUnit from 'distributionUnit/dto/DtoDistributionUnit'
import ResourceDto from 'resource/dto/ResourceDto'

const DashboardAddStationDialog = ({
    open = false,
    closeDialog = () => { },
    stations = [],
    title = '',
    onValidate = () => { },
}) => {
    const [code, setCode] = useState()
    const [name, setName] = useState()

    const dispatch = useDispatch()

    const onSave = () => {
        if (code || name) {
            const alreadyExist = stations.map(s => s.code?.toLowerCase()).includes(code?.toLowerCase())
            if (alreadyExist) {
                dispatch(ToastrAction.info(i18n.bssCodeAlreadyExists))
            } else {
                onValidate({ code, name })
            }
        } else {
            dispatch(ToastrAction.info(i18n.newInstallationCodeNameMessage))
        }
    }

    return (
        <DialogMUI
            open={open}
            PaperProps={{
                sx: {
                    minHeight: 'auto',
                    maxHeight: 'auto',
                },
            }}
        >
            <DialogTitleMUI sx={{ margin: '0px', padding: '0px' }}>
                <Grid container justifyContent='space-between' alignItems='center' sx={{ padding: '10px 20px' }}>
                    <Grid item >
                        {title}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeDialog} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container justifyContent='space-between' sx={{ padding: '1rem' }}>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.code}
                            value={code}
                            onChange={setCode}
                            clearFunction
                            noSpace
                        />
                    </Grid>
                    <Grid item xs={7.5}>
                        <Input
                            title={i18n.name}
                            value={name}
                            onChange={setName}
                            clearFunction
                        />
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='flex-end' alignItems='flex-end'>
                    <Button
                        variant='contained'
                        onClick={onSave}
                    >
                        {i18n.save}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

DashboardAddStationDialog.propTypes = {
    open: PropTypes.bool,
    closeDialog: PropTypes.func,
    stations: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(DtoQualitometerLight),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(PluviometerDto),
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(ResourceDto),
    ])),
    title: PropTypes.string,
    onValidate: PropTypes.func,
}

export default DashboardAddStationDialog