import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import Card from 'components/card/Card'
import { Button, Grid } from '@mui/material'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import Select from 'components/forms/Select'
import { INDICATORS_COLORS } from 'utils/constants/ColorTheme'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import SimpleCheckbox from 'components/forms/SimpleCheckbox'
import { execByType, getTypeModule } from 'utils/StationUtils'
import Input from 'components/forms/Input'
import { hasValue } from 'utils/NumberUtil'
import FilterInsertModal from 'components/modal/FilterInsertModal'
import { FILTER_TYPE } from 'administration/components/user/constants/UserConstants'

const NO_INDICATOR = -1
const NORMAL = 0
const VIGILANCE = 1
const CRISIS = 2
const NO_DATA = 3

const DashboardFilterPanel = ({
    stationType,
    data = [],

    onChangeSearchValue = () => { },
    setFilterResults = () => { },
    setFilter = () => { },
    setSector = () => { },
    setCity = () => { },
    setAdmin = () => { },
    setOperator = () => { },

    defaultSearchValue,
    defaultFilter,
    defaultFilterResults,
    defaultSector,
    defaultCity,
    defaultAdmin,
    defaultOperator,

    defaultIndicators = [NO_INDICATOR, NORMAL, VIGILANCE, CRISIS, NO_DATA],
    setFilterIndicators = () => { },
}) => {
    const {
        cities,
        distributionUnits,
        distributionUnitSectors,
        contributors,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        distributionUnitSectors: store.DistributionUnitReducer.distributionUnitSectors,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [tmpSearch, setTmpSearch] = useState(defaultSearchValue)
    const [tmpFilter, setTmpFilter] = useState(defaultFilter || FILTER_TYPE.NOFILTER)
    const [tmpFilterResults, setTmpFilterResults] = useState(defaultFilterResults || [])
    const [tmpSector, setTmpSector] = useState(defaultSector)
    const [tmpCity, setTmpCity] = useState(defaultCity)
    const [tmpIndicators, setTmpIndicators] = useState(defaultIndicators)
    const [tmpAdmin, setTmpAdmin] = useState(defaultAdmin || [])
    const [tmpOperator, setTmpOperator] = useState(defaultOperator || [])

    const typeModule = getTypeModule(stationType)

    const onValidate = (newSearch) => {
        onChangeSearchValue(newSearch || tmpSearch)
        setFilterResults(tmpFilterResults)
        setFilter(tmpFilter)
        setSector(tmpSector)
        setCity(tmpCity)
        setFilterIndicators(tmpIndicators)
        setAdmin(tmpAdmin)
        setOperator(tmpOperator)
    }

    return (
        <Grid container item xs={10.5}>
            <Card noMargin={false} round cardStyle={{ width: '100%' }}>
                <Grid container item xs={12} sx={{ padding: '1rem 1.5rem' }}>
                    <Grid item xs={3} sx={{ paddingRight: '1rem' }}>
                        <Input
                            tooltip={i18n.searchThenEnter}
                            title={i18n.search}
                            value={tmpSearch}
                            onChange={setTmpSearch}
                            onEnterKeyPress={(value) => {
                                setTmpSearch(value)
                                onValidate(value)
                            }}
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ paddingRight: '1rem' }}>
                        <SimpleFilterSelect
                            stationType={stationType}
                            onChange={(filterStations, newFilter) => {
                                setTmpFilterResults(filterStations)
                                setTmpFilter(newFilter)
                            }}
                            nullLabel={i18n.allStations}
                            stations={data}
                        />
                    </Grid>
                    <Grid item xs={0.5} onClick={() => setOpen(true)} sx={{ paddingRight: '1rem' }}>
                        <i className='material-icons qualityFilterAddIcon clickable'>
                            {hasValue(tmpFilter) && tmpFilter !== FILTER_TYPE.BOOKMARK && tmpFilter !== FILTER_TYPE.NOFILTER ? 'edit' : 'note_add'}
                        </i>
                    </Grid>
                    <FilterInsertModal
                        setOpen={setOpen}
                        open={open}
                        selectedFilter={tmpFilter}
                        module={typeModule}
                        isNew={!hasValue(tmpFilter) || tmpFilter === FILTER_TYPE.BOOKMARK || tmpFilter === FILTER_TYPE.NOFILTER}
                        onChange={setFilter}
                    />
                    {execByType(stationType, {
                        distributionUnit: () => {
                            const filteredTowns = distributionUnits?.flatMap(d => d.geographicSector?.split(','))
                            return (
                                <>
                                    <Grid item xs={2.5} sx={{ paddingRight: '1rem' }}>
                                        <SuperMultiAutocomplete
                                            options={uniqBy(distributionUnitSectors, 'name')}
                                            label={i18n.sector}
                                            onChange={setTmpSector}
                                            values={tmpSector}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5} sx={{ paddingRight: '1rem' }}>
                                        <Select
                                            options={cities.filter(c => filteredTowns.includes(c.code))}
                                            label={i18n.city}
                                            onChange={setTmpCity}
                                            value={tmpCity}
                                            clearFunction
                                            noNullValue
                                        />
                                    </Grid>
                                </>
                            )
                        },
                        productionUnit: () => {
                            return (
                                <>
                                    <Grid item xs={2.75} sx={{ paddingRight: '1rem' }}>
                                        <MultiAutocomplete
                                            elements={contributors}
                                            label={i18n.buildingOwner}
                                            onChange={setTmpAdmin}
                                            values={tmpAdmin}
                                            clearFunction
                                        />
                                    </Grid>
                                    <Grid item xs={2.75}>
                                        <MultiAutocomplete
                                            elements={contributors}
                                            label={i18n.operator}
                                            onChange={setTmpOperator}
                                            values={tmpOperator}
                                            clearFunction
                                        />
                                    </Grid>
                                </>
                            )
                        },
                        default: () => { },
                    })}
                </Grid>
                <Grid container item xs={12} sx={{ padding: '1rem 1.5rem' }} justifyContent='space-between'>
                    {execByType(stationType, {
                        productionUnit: () => (
                            <Grid item>
                                <Grid container alignItems='center'>
                                    <Grid item>
                                        <SimpleCheckbox
                                            label={i18n.normal}
                                            checked={tmpIndicators.includes(NORMAL)}
                                            onToggle={() => setTmpIndicators(prev => prev.includes(NORMAL) ? prev.filter(p => p !== NORMAL) : [NORMAL, ...prev])}
                                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.BLUE, color: 'white !important' }}
                                        />
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '3rem' }}>
                                        <SimpleCheckbox
                                            label={i18n.vigilance}
                                            checked={tmpIndicators.includes(VIGILANCE)}
                                            onToggle={() => setTmpIndicators(prev => prev.includes(VIGILANCE) ? prev.filter(p => p !== VIGILANCE) : [VIGILANCE, ...prev])}
                                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.YELLOW, color: 'black !important' }}
                                        />
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '3rem' }}>
                                        <SimpleCheckbox
                                            label={i18n.crisis}
                                            checked={tmpIndicators.includes(CRISIS)}
                                            onToggle={() => setTmpIndicators(prev => prev.includes(CRISIS) ? prev.filter(p => p !== CRISIS) : [CRISIS, ...prev])}
                                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.RED, color: 'white !important' }}
                                        />
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '3rem' }}>
                                        <SimpleCheckbox
                                            label={i18n.noData}
                                            checked={tmpIndicators.includes(NO_DATA)}
                                            onToggle={() => setTmpIndicators(prev => prev.includes(NO_DATA) ? prev.filter(p => p !== NO_DATA) : [NO_DATA, ...prev])}
                                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.GREY, color: 'white !important' }}
                                        />
                                    </Grid>
                                    <Grid item sx={{ paddingLeft: '3rem' }}>
                                        <SimpleCheckbox
                                            label={i18n.notDetermined}
                                            checked={tmpIndicators.includes(NO_INDICATOR)}
                                            onToggle={() => setTmpIndicators(prev => prev.includes(NO_INDICATOR) ? prev.filter(p => p !== NO_INDICATOR) : [NO_INDICATOR, ...prev])}
                                            labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.BLACK, color: 'white !important' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ),
                        default: () => (
                            <Grid item xs={1} />
                        ),
                    })}
                    <Grid container item xs={2} justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                            <Button variant='contained' onClick={() => onValidate()}>
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

DashboardFilterPanel.propTypes = {
    stationType: PropTypes.string,
    onChangeSearchValue: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    setFilterResults: PropTypes.func,
    setFilter: PropTypes.func,
    filter: PropTypes.number,
    setSector: PropTypes.func,
    sector: PropTypes.number,
    setCity: PropTypes.func,
    city: PropTypes.number,
    defaultSearchValue: PropTypes.string,
    defaultFilter: PropTypes.number,
    defaultFilterResults: PropTypes.arrayOf(PropTypes.number),
    defaultSector: PropTypes.number,
    defaultCity: PropTypes.number,
    defaultIndicators: PropTypes.arrayOf(PropTypes.number),
    setFilterIndicators: PropTypes.func,
    setAdmin: PropTypes.func,
    setOperator: PropTypes.func,
    defaultAdmin: PropTypes.arrayOf(PropTypes.number),
    defaultOperator: PropTypes.arrayOf(PropTypes.number),
}


export default DashboardFilterPanel