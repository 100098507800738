import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import UserAction from 'administration/components/user/actions/UserAction'
import emailIcon from 'assets/pictures/logos/email.png'
import notificationIcon from 'assets/pictures/logos/notification.png'
import facebookIcon from 'assets/pictures/socialNetworks/logo_facebook.png'
import RichTextEditor from 'components/forms/RichTextEditor'
import Icon from 'components/icon/Icon'
import SelectNotifCms from 'components/modal/SelectNotifCms'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { push } from 'connected-react-router'
import DtoCmsModel from 'events/dto/DtoCmsModel'
import { deburr, flatten, isEqual, maxBy, omit, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { v4 as uuidv4 } from 'uuid'
import DtoMobileNotification from '../../administration/components/user/dto/DtoMobileNotification'
import ActionComponent from '../../components/ActionComponent'
import Card from '../../components/card/Card'
import ColorfulCard from '../../components/card/ColorfulCard'
import Checkbox from '../../components/forms/Checkbox'
import Input from '../../components/forms/Input'
import RadioButtons from '../../components/forms/RadioButtons'
import Select from '../../components/forms/Select'
import Textarea from '../../components/forms/Textarea'
import CartographyPanel from '../../components/map/CartographyPanel'
import SelectEmailCms from '../../components/modal/SelectEmailCms'
import SieauAction from '../../components/sieau/SieauAction'
import ApplicationConf from '../../conf/ApplicationConf'
import CityDto from '../../referencial/components/city/dto/CityDto'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import ContactGroupDto from '../../referencial/components/contact/dto/ContactGroupDto'
import ContributorDto from '../../referencial/components/contributor/dto/ContributorDto'
import StationAction from '../../station/actions/StationAction'
import { getCmsMarker } from '../../utils/CmsUtils'
import { getNewsColor } from '../../utils/ColorUtil'
import { getDate, getDateWithHour, getFullDateMini, getHour } from '../../utils/DateUtil'
import { onChangeDate, onChangeHour, sieauTooltip } from '../../utils/FormUtils'
import { hasValue } from '../../utils/NumberUtil'
import { getLogin, getUser } from '../../utils/SettingUtils'
import { deleteKeys, getMapStateToProps, getPropTypes, removeNullKeys } from '../../utils/StoreUtils'
import CmsAction from '../actions/CmsAction'
import MapEventPanel from './map/MapEventPanel'
import User from 'account/dto/User'
import CmsMessages from './CmsMessages'
import DtoCMSMessage from 'events/dto/DtoCmsMessage'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { getSandreMnemonique } from 'utils/StringUtil'
import { mainBlue } from 'utils/constants/ColorTheme'

const storeProps = {
    cmsEvent: false,
    cmsCategories: true,
    users: true,
    sandreCodes: true,
}

class CmsEventForm extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            event: {
                link: 'https://',
                idCategory: this.props.match.params.categoryId,
                levelContent: null,
                status: 1,
                login: getLogin(),
                author: getLogin() || '',
                cmsDocument: [],
                dateDebut: moment().valueOf(),
                dateFin: null,
                authorization: 'all',
            },
            cms: {
                mailReceivers: [],
                notifReceivers: [],
                mailContacts: [],
                idContactsGroups: [],
                mailContributors: [],
                mailCities: [],
                mailUdi: [],
            },
            displayMode: false,
            dataLoaded: false,
            withLocation: false,
            searchValue: '',
            openMail: false,
            openNotif: false,
            openMailModel: false,
            cmsMessages: [],
        }
    }

    componentDidMount = () => {
        const { match: { params: { contentId, lang } } } = this.props
        this.props.fetch(storeProps)
        if (contentId != 0) {
            this.props.fetchCMSEvent(contentId, () => {
                this.setState({ dataLoaded: true })
                this.setTitle(this.props.cmsEvent)
            }, true, lang)
        } else {
            this.setState({ dataLoaded: true })
        }
        this.props.fetchContactsGroups()
        this.props.fetchAllMobileNotifications()
        this.props.fetchAllCMSModels()
        this.initializeComponent()
    }

    initializeComponent = () => {
        const { cmsCategories, cmsEvent, match: { params: { contentId } } } = this.props
        if (hasValue(cmsEvent.id) && contentId != 0) {
            const event = {
                ...cmsEvent,
                link: cmsEvent.link || 'https://',
            }
            this.setState({ event: event || {}, initialEvent: event || {} })
            if (cmsCategories.length) {
                this.setTitle(event)
            }
            this.setReadOnlyMode(event)
        }
        if (contentId == 0) {
            if (cmsCategories.length) {
                this.setTitle({})
            }
            this.setEditMode()
        }
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(this.props.cmsEvent, prevProps.cmsEvent) || !isEqual(this.props.cmsCategories, prevProps.cmsCategories)) {
            this.initializeComponent()
        }
        if (this.props.match.params.contentId !== prevProps.match.params.contentId) {
            this.props.fetchCMSEvent(this.props.match.params.contentId)
        }
    }

    componentWillUnmount() {
        this.props.resetCmsEvent()
    }

    setTitle = event => {
        const category = this.props.cmsCategories.find(c => c.id == this.state.event.idCategory) || {}
        const name = category.displayOnMenu && category.menu ? category.menu : category.title
        this.props.forceFetch('title', [{
            title: name,
            href: `contents/${category.id}`,
        }, {
            title: this.props.match.params.contentId == 0 ? i18n.new : (event.title || ''),
            href: `contents/${category.id}/${this.props.match.params.contentId}`,
        }])
    }

    handleShare = socialNetwork => {
        const event = {
            ...this.state.event,
            author: this.state.event.author ? this.state.event.author : getUser().name,
            idCategory: parseInt(this.state.event.idCategory),
        }
        const publish = () => {
            switch (socialNetwork.id) {
                case 'facebook':
                    this.props.postFacebookCMSEvent(event)
                    break
                default:
            }
        }
        const popup = {
            id: uuidv4(),
            header: i18n.sharingConfirmation,
            actions: (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                    <a className='waves-effect waves-teal btn-flat modal-close btn font-white' onClick={ publish }>{ i18n.validate }</a>
                </div>
            ),
        }
        this.props.setPopup(popup)
    }

    onChangeCms = (changes) => {
        this.setState({ cms: removeNullKeys({ ...this.state.cms, ...changes }) }, () => {
            this.handleMail()
            this.handleNotif()
        })
    }

    filterValidSearch = (list) => {
        const search = deburr(this.state.searchValue.toLowerCase())
        return uniqBy(list
            .filter(c => hasValue(c.name) && hasValue(c.email))
            .filter(c => !c.noNewsLetterDate && !c.noNewLetterLogin)
            .filter(c => deburr(`${c.name} (${c.email})`.toLowerCase()).includes(search)),
        'email')
    }

    openPopupMail = () => {
        this.setState({ openMail: true })
    }

    openPopupNotif = () => {
        this.setState({ openNotif: true })
    }

    closePopupMail = () => {
        this.setState({
            openMail: false,
            cms: {
                mailReceivers: [],
                mailContacts: [],
                notifReceivers: [],
                idContactsGroups: [],
                mailContributors: [],
                mailCities: [],
                mailUdi: [],
            },
        })
    }

    closePopupNotif = () => {
        this.setState({
            openNotif: false,
            cms: {
                mailReceivers: [],
                mailContacts: [],
                notifReceivers: [],
                idContactsGroups: [],
                mailContributors: [],
                mailCities: [],
                mailUdi: [],
            },
        })
    }

    handleMail = () => {
        const { cms, searchValue, openMail } = this.state
        return (
            <Dialog
                onClose={ this.closePopupMail }
                fullWidth
                maxWidth='lg'
                open={ openMail }
            >
                <DialogTitle>{ i18n.email }</DialogTitle>
                <DialogContent>
                    <div className='padding-top-1' style={{ maxHeight: '70%', overflowY: 'auto' }}>
                        <Input
                            style={ { width: '40%' } }
                            title={ i18n.search }
                            value={ searchValue }
                            onChange={ v => this.setState({ searchValue: v }) }
                        />
                        <SelectEmailCms
                            personnalizeEmail={ false }
                            mailContacts={ cms.mailContacts }
                            mailContributors={ cms.mailContributors }
                            mailCities={ cms.mailCities }
                            mailReceivers={ cms.mailReceivers }
                            idContactsGroups={ cms.idContactsGroups }
                            mailUdi={ cms.mailUdi }
                            onChangeEmails={ mails => this.onChangeCms(mails) }
                            contacts={ this.filterValidSearch(this.props.contacts) }
                            contactsGroups={ this.props.contactsGroups }
                            contributors={ this.filterValidSearch(this.props.contributors) }
                            cities={ this.filterValidSearch(this.props.cities) }
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ this.closePopupMail } variant='outlined' >
                        { i18n.cancel }
                    </Button>
                    <Button onClick={ this.sendMail } variant='contained' color='primary'>
                        { i18n.save }
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleMailModel = () => {
        const { cmsEvent, cmsModels } = this.props
        const { openMailModel } = this.state
        const model = cmsModels.find(cm => cm.modelId === cmsEvent.modelId)
        return (
            <DialogMUI
                maxWidth='md'
                open={openMailModel}
                PaperProps={{
                    sx: {
                        minHeight: '40vh',
                        maxHeight: '40vh',
                    },
                }}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.sendingEmail}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ openMailModel: false })} />
                </DialogTitleMUI>
                <DialogContentMUI style={{ paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid container alignItems='center' justifyContent='center' sx={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                            <h3>{i18n.templateLinkedCms}</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <h4>{i18n.sendDocumentCmsToRecipients}</h4>
                        </Grid>
                        <Grid container item xs={12} justifyContent='space-around'>
                            {model?.recipients?.split(',')?.map(r => (
                                <Grid item>
                                    <span style={{ fontSize: '16px' }}>{r}</span>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={() => this.props.sendCmsByModel(cmsEvent.id).then(() => this.setState({ openMailModel: false }))} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }


    handleNotif = () => {
        const { openNotif, cms } = this.state
        return (
            <Dialog
                onClose={ this.closePopupNotif }
                fullWidth
                maxWidth='lg'
                open={ openNotif }
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.notification}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.closePopupNotif} />
                </DialogTitleMUI>
                <DialogContent>
                    <div style={ { maxHeight: '70%', overflowY: 'auto' } }>
                        <SelectNotifCms
                            mobileNotifications={ this.props.mobileNotifications }
                            notifReceivers={ cms.notifReceivers }
                            app={ cms.app }
                            onChangeNotifs={ notifs => this.onChangeCms(notifs) }
                        />
                    </div>
                </DialogContent>
                <DialogActionsMUI>
                    <Button onClick={ this.sendNotif } variant='contained'>
                        { i18n.save }
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    sendMail = () => {
        const { event, cms } = this.state
        this.props.fetchContactsGroupsAlert(cms.idContactsGroups)
            .then(groups => {
                const mailContactsGroups = uniq(flatten(groups.map(g => g.contacts)).map(contact => contact.email).filter(mail => mail))
                const { mailReceivers, mailContacts, mailContributors, mailUdi, mailCities } = cms
                const mails = uniq([
                    ...mailContactsGroups,
                    ...mailReceivers,
                    ...mailContacts,
                    ...mailContributors,
                    ...mailUdi,
                    ...mailCities,
                ])
                this.props.postMailCMSEvent(event.id, mails)
                this.closePopupMail()
            })
    }

    sendNotif = () => {
        const { event, cms } = this.state
        if (cms.app) {
            this.props.postNotif({ body: event.comment, title: event.title }, cms.notifReceivers, cms.app)
            this.closePopupNotif()
        } else {
            this.props.toastrError(i18n.pleaseCompleteAllRequiredField)
        }
    }

    setEditMode = () => {
        this.setState({ displayMode: false })
        const actions = {
            languages: this.onSelectLanguage,
            save: this.onSaveEvent,
            cancel: () => {
                if (this.props.match.params.contentId == 0) {
                    this.props.push(`/contents/${this.state.event.idCategory}`)
                } else {
                    this.setState({ event: this.state.initialEvent })
                    this.setReadOnlyMode(this.state.initialEvent)
                }
            },
        }
        if (this.props.match.params.contentId != 0) {
            actions.delete = this.onDeleteEvent
        }
        if (!getUser().isAdmin === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    onSelectLanguage = (language) => {
        const { displayMode } = this.state
        const { cmsEvent, match } = this.props
        const tradFind = cmsEvent.cmsTrads?.find((l) => l.codeLanguage === language.code)
        const idParent = cmsEvent.cmsTrads[0]?.idCms || cmsEvent.id
        if (displayMode) {
            if (tradFind) {
                this.props.push(`/contents/${match.params.categoryId}/${tradFind.idCmsTrad}`)
            } else {
                this.props.push(`/contents/${match.params.categoryId}/${idParent}`)
                this.props.fetchCMSEvent(idParent, () => {
                    this.setState({ dataLoaded: true })
                    this.setTitle(this.props.cmsEvent)
                }, true).then(() => {
                    this.initializeComponent()
                })
                this.props.info(i18n.translationNotAvailable)
            }
        } else {
            this.onSaveEvent(tradFind?.idCmsTrad || 'new', idParent, language.code)
        }
    }

    getShareActions = categoryWPName => {
        const { accountUser, cmsCategories, match } = this.props
        const category = cmsCategories.find(c => c.id === parseInt(match.params.categoryId))
        const canEdit = !category.managers ? true : category.managers.split(';').map(s => parseInt(s)).includes(accountUser.contributorCode)
        if (!categoryWPName) {
            const editActions = canEdit ? {
                languages: this.onSelectLanguage,
                edit: this.setEditMode,
                delete: this.onDeleteEvent,
            } : {}
            return {
                share: [{
                    id: 'facebook',
                    onClick: this.handleShare,
                    img: facebookIcon,
                    label: 'Facebook',
                },
                {
                    id: 'mail',
                    onClick: this.openPopupMail,
                    img: emailIcon,
                    label: 'Mail',
                },
                {
                    id: 'notif',
                    onClick: this.openPopupNotif,
                    img: notificationIcon,
                    label: 'Notification',
                }],
                ...editActions,
            }
        }
        const editActions = canEdit ? {
            delete: this.onDeleteEvent,
        } : {}
        return {
            share: [{
                id: 'facebook',
                onClick: this.handleShare,
                img: facebookIcon,
                label: 'Facebook',
            }],
            ...editActions,
        }
    }

    setReadOnlyMode = event => {
        this.setState({ displayMode: true })
        const user = getUser()
        if (user.admin === '1' || user.metadata === '1' || user.login === event.login) {
            const { cmsEvent } = this.props
            const actions = this.getShareActions(cmsEvent.categoryWPName)
            if (hasValue(cmsEvent.modelId)) {
                this.setActions({
                    ...actions,
                    email: () => this.setState({ openMailModel: true }),
                })
            } else {
                this.setActions(actions)
            }
        } else if (this.props.actions.filter(a => !!a).length !== 0) {
            this.setActions({})
        }
    }

    onSaveEvent = (idTrad, idParent, codeLanguage) => {
        const { event, initialEvent, withLocation } = this.state
        if (hasValue(event.title) && hasValue(event.idCategory)) {
            if (!isEqual(event, initialEvent) || idTrad) {
                const newInitialEvent = {
                    ...event,
                    author: event.author || getUser().name,
                    idCategory: parseInt(event.idCategory),
                }
                this.setState({ initialEvent: newInitialEvent })
                const newEvent = {
                    ...newInitialEvent,
                    link: newInitialEvent.link === 'https://' ? '' : newInitialEvent.link,
                    x: withLocation ? newInitialEvent.x : undefined,
                    y: withLocation ? newInitialEvent.y : undefined,
                }
                this.props.updateCMSEvent(newEvent, !newEvent.id).then(id => {
                    if (idTrad) {
                        if (idTrad !== 'new') {
                            this.props.push(`/contents/${newInitialEvent.idCategory}/${idTrad}`)
                        } else {
                            this.props.updateCMSEvent({ ...newEvent, cmsTrad: { idCms: idParent, idCmsTrad: -1, codeLanguage } }, true).then((newId) => {
                                this.props.push(`/contents/${newInitialEvent.idCategory}/${newId}`)
                            })
                        }
                    } else if (id) {
                        this.props.fetchCMSEvent(id)
                        if (this.props.match.params.contentId === '0') {
                            this.props.push(`/contents/${newInitialEvent.idCategory}/${id}`)
                        }
                    }
                })
            } else {
                this.props.info(i18n.nothingToChange)
            }
        } else {
            this.props.warning(i18n.pleaseCompleteAllRequiredField)
        }
    }

    onDeleteEvent = () => {
        this.props.deleteCMSEvent(this.state.event).then(() =>
            this.props.push(`/contents/${this.state.event.idCategory}`)
        )
    }

    onChangeEvent = (key, value) => {
        this.setState({
            event: {
                ...this.state.event,
                [key]: value,
            },
        })
    }

    onChangeEventNew = changes => this.setState({ event: { ...this.state.event, ...changes } })

    getFileFTP = name => {
        const win = window.open(ApplicationConf.referencial.cmsFile(name), '_blank')
        win.focus()
    }

    onDeleteDocument = file => {
        this.setState({
            event: {
                ...this.state.event,
                cmsDocument: this.state.event.cmsDocument.filter(d => d.name != file.name),
            },
        })
    }

    onUploadFile = event => {
        // Read File
        let uploadFiles = event.target.files
        if (uploadFiles.length) {
            Object.keys(uploadFiles).map(index => {
                let reader = new FileReader()
                const file = uploadFiles[index]
                reader.readAsDataURL(file)
                let content = ''
                reader.onload = (function (func) {
                    return function (fileLoadedEvent) {
                        content = fileLoadedEvent.target.result
                        func([{
                            cmsId: 1,
                            name: file.name?.replace(' ', '_')?.replace('/', '_'),
                            path: '',
                            content,
                        }])
                    }
                }(this.onAddDocument))
            })
        }
    }

    onAddDocument = value => {
        this.setState({
            event: {
                ...this.state.event,
                cmsDocument: [...this.state.event.cmsDocument, ...value],
            },
        })
    }

    getDisplayCard = (cmsEvent = {}, defaultCmsMessages, little = false) => {
        const { sandreCodes } = this.props
        const trad = cmsEvent.cmsTrads?.find((t) => t.idCmsTrad === cmsEvent.id)
        const maxMessage = maxBy(defaultCmsMessages, 'dateMessage')
        return (
            <ColorfulCard color={ getNewsColor(cmsEvent.level) } >
                <div className={ `event-card ${(cmsEvent.y && cmsEvent ? 'col s6' : 'col s12')} ${(cmsEvent.status !== 1 ? 'grey lighten-2' : '')} no-padding` }>
                    <div className='row no-margin event-content'>
                        <div className='row no-margin padding-top-2'>
                            <div className='col s9 no-padding'>
                                <div className='valign-wrapper'>
                                    <h4 className='no-margin'>{ cmsEvent.title }</h4>
                                    { cmsEvent.status == 2 ? <i className='material-icons medium' {...sieauTooltip(i18n.draft, null, 'bottom')}>create</i> : null }
                                </div>
                            </div>
                            <div className='col s3' style={{ paddingRight: 20 }}>
                                <Grid container direction='column' alignItems='flex-end'>
                                    {!little && trad && (
                                        <Grid item
                                            sx={{
                                                textAlign: 'center',
                                                backgroundColor: mainBlue,
                                                color: 'white',
                                                borderRadius: 20,
                                                padding: '5px 20px',
                                            }}
                                        >
                                            {getSandreMnemonique(sandreCodes, 'LANGUES', trad.codeLanguage)}
                                        </Grid>
                                    )}
                                    <Grid item>{defaultCmsMessages.length} {i18n.publishedMessages}</Grid>
                                    {!!defaultCmsMessages.length && (
                                        <Grid item>{i18n.lastPostOn} {getFullDateMini(maxMessage?.dateMessage)}</Grid>
                                    )}
                                </Grid>
                            </div>
                        </div>
                        <div className='row no-margin'>
                            <h5 dangerouslySetInnerHTML={ { __html: cmsEvent.subtitle } } />
                        </div>
                        <div className='row no-margin'>
                            <i>{ `${i18n.writtenBy + (cmsEvent.author || i18n.unknown)} ${i18n.atDate} ${getDate(cmsEvent.updateDate)}, ${getDate(cmsEvent.updateDate, 'HH:mm')}` }</i>
                        </div>
                        <div className='row no-margin padding-top-2 padding-bottom-3' style={ { whiteSpace: 'pre-wrap' } }>
                            <RichTextEditor readMode value={cmsEvent.comment} />
                        </div>
                    </div>
                    <div className='row no-margin event-footer padding-left-2 padding-right-1'>
                        <div className='row no-margin'>
                            <h6>{ cmsEvent.footer }</h6>
                        </div>
                        <div className='row no-margin'>
                            { cmsEvent.footerContent }
                        </div>
                        {cmsEvent?.cmsDocument?.length !== 0 && cmsEvent?.cmsDocument?.map(d => (
                            <div className='row no-margin'>
                                <div className='col s12 no-padding left flex'>
                                    <button type='submit' className='clickable' onClick={ () => this.getFileFTP(d.name) }>
                                        { d.name }
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className='row no-margin' >
                            <h6 className='right'><a className='blue-text' href={ cmsEvent.link } target='_blank'>{ cmsEvent.link !== 'https://' ? cmsEvent.link : '' }</a></h6>
                        </div>
                    </div>
                </div>
                {cmsEvent.y && cmsEvent && !little && (
                    <div className='col s6 margin-bottom-1 margin-top-1'>
                        <b>{ `${i18n.latitude}: ${cmsEvent.y} ${i18n.longitude}: ${cmsEvent.x}` }</b>
                        <CartographyPanel
                            layers={ ['STATIONS_POINTS'] }
                            componentType='all'
                            fullScreenable={ false }
                            stationsPoints={ [{
                                ...cmsEvent,
                                projection: 16,
                                typeName: 'other',
                                markerIcon: getCmsMarker(
                                    cmsEvent,
                                    this.props.cmsCategories.find(c => cmsEvent.idCategory === c.id) || {}
                                ),
                            }] }
                            noPanels
                            noMarkerTooltip
                            height={ 300 }
                        />
                    </div>
                )}
            </ColorfulCard>
        )
    }

    render = () => {
        const { cmsEvent, accountUser, defaultCmsMessages } = this.props
        const { dataLoaded, displayMode, event } = this.state
        if (dataLoaded) {
            if (displayMode && hasValue(event.id)) {
                return (
                    <>
                        <Card maxWidth={ 1000 } className='row margin-top-1' round>
                            {this.getDisplayCard(cmsEvent, defaultCmsMessages)}
                            {this.handleMail()}
                            {this.handleNotif()}
                            {this.handleMailModel()}
                        </Card>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{ width: '1000px', padding: '1rem 0' }}>
                                <CmsMessages cms={cmsEvent} />
                            </Grid>
                        </Grid>
                    </>
                )
            }
            if (!displayMode) {
                const { sandreCodes, cmsCategories: cmsCategoriesProps } = this.props
                const { withLocation } = this.state
                const levelOptions = ['blue', 'green', 'orange', 'red'].map((color, index) => ({
                    label: <span className={ `${color} arrests-level-panel ${color}-text` }>O</span>,
                    value: index + 1,
                }))
                const allLevelOptions = [
                    {
                        label: i18n.none,
                        value: -1,
                    },
                    ...levelOptions,
                ]
                const statusOptions = sandreCodes.filter((c) => c.field === 'CMS.STATUS')
                const visibilityOptions = [
                    { value: 'admin', name: i18n.adminsOnly },
                    { value: 'metadata', name: i18n.managersAndMore },
                    { value: 'anim', name: i18n.techniciansAndMore },
                    { value: 'all', name: i18n.visibleByAllUsers },
                ]
                const cmsCategories = cmsCategoriesProps.map(c => deleteKeys(c, ['icon']))
                const categoriesManagers = accountUser.contributorCode ? cmsCategories.filter(c => !c.managers || (c.managers.split(';').map(s => parseInt(s))).includes(accountUser.contributorCode)) : cmsCategories.filter(c => !c.managers)
                const trad = cmsEvent.cmsTrads?.find((t) => t.idCmsTrad === cmsEvent.id)
                return (
                    <>
                        <Card maxWidth={ 1000 } className={ `margin-top-1 margin-bottom-1 padding-left-1 padding-right-1 padding-bottom-1 ${event.status != 1 ? 'grey lighten-2' : ''}` } round>
                            <div className={ `row no-margin ${event.status != 1 ? 'grey lighten-2' : ''}` }>
                                <div className='row no-margin padding-top-10-px' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        col={ trad ? 5 : 6}
                                        title={ i18n.author }
                                        value={ event.author }
                                        onChange={ v => this.onChangeEvent('author', v) }
                                    />
                                    <Select
                                        col={ trad ? 5 : 6}
                                        label={ i18n.category }
                                        options={ categoriesManagers }
                                        value={ event.idCategory }
                                        keyvalue='id'
                                        keyLabel='title'
                                        onChange={ v => this.onChangeEvent('idCategory', v) }
                                        noNullValue
                                        obligatory
                                    />
                                    {trad && (
                                        <div
                                            className='col s2'
                                            style={{
                                                textAlign: 'center',
                                                backgroundColor: mainBlue,
                                                color: 'white',
                                                borderRadius: 20,
                                                padding: '5px 0',
                                            }}
                                        >
                                            {getSandreMnemonique(sandreCodes, 'LANGUES', trad.codeLanguage)}
                                        </div>
                                    )}
                                </div>
                                <div className='row no-margin'>
                                    <Input
                                        col={ 12 }
                                        title={ i18n.title }
                                        value={ event.title }
                                        onChange={ v => this.onChangeEvent('title', v) }
                                        obligatory
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input
                                        col={ 12 }
                                        title={ i18n.subTitle }
                                        value={ event.subtitle }
                                        onChange={ v => this.onChangeEvent('subtitle', v) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={ 6 } value={ getDate(event.dateDebut) } title={ i18n.startDate } onChange={ v => onChangeDate(v, v2 => this.onChangeEvent('dateDebut', getDateWithHour(v2, event.dateDebut).valueOf())) } />
                                    <Input col={ 6 } value={ getHour(event.dateDebut) } title={ i18n.startHour } onChange={ v => onChangeHour(v, v2 => this.onChangeEvent('dateDebut', getDateWithHour(event.dateDebut, v2).valueOf())) } />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={ 6 } value={ getDate(event.dateFin) } title={ i18n.endDate } onChange={ v => onChangeDate(v, v2 => this.onChangeEvent('dateFin', getDateWithHour(v2, event.dateFin).valueOf())) } />
                                    <Input col={ 6 } value={ getHour(event.dateFin) } title={ i18n.endHour } onChange={ v => onChangeHour(v, v2 => this.onChangeEvent('dateFin', getDateWithHour(event.dateFin, v2).valueOf())) } />
                                </div>
                                <div className='row no-margin padding-bottom-1'>
                                    <RichTextEditor
                                        label={i18n.comment}
                                        value={event.comment}
                                        onChange={ v => this.onChangeEvent('comment', v) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Select
                                        col={ 6 }
                                        options={ statusOptions }
                                        label={ i18n.status }
                                        onChange={ v => this.onChangeEvent('status', parseInt(v)) }
                                        value={ event.status }
                                        keyLabel='label'
                                        noNullValue
                                    />
                                    <RadioButtons
                                        col={ 6 }
                                        elements={ allLevelOptions }
                                        selected={ hasValue(event.levelContent) ? event.levelContent : -1 }
                                        title={ i18n.level }
                                        onChange={ v => this.onChangeEvent('levelContent', v == -1 ? null : parseInt(v)) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Select
                                        col={ 12 }
                                        label={ i18n.visibleBy }
                                        value={ event.authorization }
                                        options={ visibilityOptions }
                                        onChange={ v => this.onChangeEvent('authorization', v) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input
                                        col={ 12 }
                                        title={ i18n.link }
                                        value={ event.link }
                                        onChange={ v => this.onChangeEvent('link', v) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input
                                        col={ 12 }
                                        title={ i18n.footerTitle }
                                        value={ event.footer }
                                        onChange={ v => this.onChangeEvent('footer', v) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.footerContent }
                                        value={ event.footerContent }
                                        onChange={ v => this.onChangeEvent('footerContent', v) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <div className='col s12 file-field input-field'>
                                        <div className='btn'>
                                            <span>{ i18n.importLabel }</span>
                                            <input
                                                accept='.pdf,image/*'
                                                id='uploadFile'
                                                type='file'
                                                multiple
                                                onChange={ this.onUploadFile }
                                            />
                                        </div>
                                        <div className='file-path-wrapper'>
                                            <input className='file-path validate' type='text' />
                                        </div>
                                    </div>
                                </div>
                                {event.cmsDocument.map(d => (
                                    <div className='row no-margin'>
                                        <div className='col s12 no-padding left flex'>
                                            <i className='material-icons clickable small' onClick={ () => this.onDeleteDocument(d) }>clear</i>
                                            <button type='submit' className='clickable' onClick={ () => d.id && this.getFileFTP(d.name) }>
                                                { d.name }
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className='row no-margin padding-top-1'>
                                    <Checkbox label={ i18n.addLocation } checked={ withLocation || (hasValue(event.x)) } onChange={ v => v ? this.setState({ withLocation: true, event: ({ ...event, x: -1.553621, y: 47.218371 }) }) :
                                        this.setState({ withLocation: false, event: removeNullKeys({ ...event, x: null, y: null }) }) }
                                    />
                                </div>
                                {withLocation || (event.x && event.y) ? (
                                    <div className='margin-top-1'>
                                        <MapEventPanel
                                            long={ event.x }
                                            lat={ event.y }
                                            onChangeLocation={(location) => this.setState({
                                                event: {
                                                    ...event,
                                                    x: location.x,
                                                    y: location.y,
                                                },
                                            })}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            { this.handleMail() }
                            { this.handleNotif() }
                            { this.handleMailModel() }
                        </Card>
                        <Grid container justifyContent='center'>
                            {cmsEvent.cmsTrads?.filter(t => t.idCmsTrad !== cmsEvent.id)?.map((t) => (
                                <Grid item sx={{ width: '1000px', padding: '1rem 0' }}>
                                    <AccordionMUI>
                                        <AccordionSummaryMUI sx={{ padding: '1rem', borderRadius: '5px' }}>
                                            {getSandreMnemonique(sandreCodes, 'LANGUES', t.codeLanguage)}
                                        </AccordionSummaryMUI>
                                        <AccordionDetailsMUI sx={{ padding: 0, background: 'transparent', maxHeight: '60vh', overflowY: 'auto' }}>
                                            {this.getDisplayCard(cmsEvent.cmsLinked?.find((c) => c.id === t.idCmsTrad), [], true)}
                                        </AccordionDetailsMUI>
                                    </AccordionMUI>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )
            }
        }
        return null
    }
}

CmsEventForm.propTypes = getPropTypes(storeProps, {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        contentId: PropTypes.number,
    }),
    document: PropTypes.shape({}),
    updateCMSEvent: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contactsGroups: PropTypes.arrayOf(PropTypes.instanceOf(ContactGroupDto)),
    mobileNotifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoMobileNotification)),
    fetchAllMobileNotifications: PropTypes.func,
    fetchContactsGroups: PropTypes.func,
    fetchContactsGroupsAlert: PropTypes.func,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    fetchAllCMSModels: PropTypes.func,
    cmsModels: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsModel)),
    sendCmsByModel: PropTypes.func,
    toastrError: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    defaultCmsMessages: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSMessage)),
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    document: store.EventsReducer.document,
    actions: store.HomeReducer.actions,
    contacts: store.ContactReducer.contacts,
    contactsGroups: store.ContactReducer.contactsGroups,
    mobileNotifications: store.UserReducer.mobileNotifications,
    contributors: store.ContributorReducer.contributors,
    cities: store.CityReducer.cities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    cmsModels: store.EventsReducer.cmsModels,
    accountUser: store.AccountReducer.accountUser,
    defaultCmsMessages: store.EventsReducer.cmsMessages,
})

const mapDispatchToProps = {
    updateCMSEvent: CmsAction.updateCMSEvent,
    deleteCMSEvent: CmsAction.deleteCMSEvent,
    info: ToastrAction.info,
    warning: ToastrAction.warning,
    push,
    resetCmsEvent: CmsAction.resetCmsEvent,
    forceFetch: SieauAction.forceFetch,
    postFacebookCMSEvent: CmsAction.postFacebookCMSEvent,
    setPopup: SieauAction.setPopup,
    fetch: SieauAction.fetch,
    fetchCMSEvent: CmsAction.fetchCMSEvent,
    deleteFile: StationAction.deleteFile,
    setPopupIsClosed: SieauAction.setPopupIsClosed,
    fetchContactsGroups: ContactAction.fetchContactsGroups,
    fetchAllMobileNotifications: UserAction.fetchAllMobileNotifications,
    fetchContactsGroupsAlert: ContactAction.fetchContactsGroupsAlert,
    postMailCMSEvent: CmsAction.postMailCMSEvent,
    postNotif: UserAction.postNotif,
    fetchAllCMSModels: CmsAction.fetchAllCMSModels,
    sendCmsByModel: CmsAction.sendCmsByModel,
    toastrError: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsEventForm)
