import { push } from 'connected-react-router'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { flatten, omit, sortBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ResourceDto from 'resource/dto/ResourceDto'
import i18n from 'simple-react-i18n'
import DtoPicture from 'station/dto/DtoPicture'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import {
    H_STATION_HYDRO_DASHBOARD,
    H_STATION_INSTALLATION_DASHBOARD,
    H_STATION_PIEZO_DASHBOARD,
    H_STATION_PLUVIO_DASHBOARD,
    H_STATION_QUALITO_DASHBOARD,
    H_STATION_RESOURCE_DASHBOARD,
    H_UNITS_DASHBOARD,
} from '../../../account/constants/AccessRulesConstants'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from '../../../campaign/dto/DtoStationCampaign'
import ActionComponent from '../../../components/ActionComponent'
import DtoFile from '../../../components/file/dto/DtoFile'
import { LAYERS } from '../../../components/map/constants/MapLayersConstants'
import SieauAction from '../../../components/sieau/SieauAction'
import HomeAction from '../../../home/actions/HomeAction'
import DtoHydroStats from '../../../hydrometry/dto/chronicMeasures/DtoHydroStats'
import InstallationAction from '../../../installation/actions/InstallationAction'
import { INSTALLATION_TYPE } from '../../../installation/constants/InstallationConstants'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import DtoPluviometerStats from '../../../pluviometry/dto/DtoPluviometerStats'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoProductionUnitEvent from '../../../productionUnit/dto/DtoProductionUnitEvent'
import QualityAction from '../../../quality/actions/QualityAction'
import DtoQualityIndicators from '../../../quality/dto/QualityIndicator/DtoQualityIndicators'
import DtoQualityThresholds from '../../../quality/dto/QualityThreshold/DtoQualityThresholds'
import UnitAction from '../../../referencial/components/unit/actions/UnitAction'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { execByType, execByTypes, getLinks, getStation, getStationTitle } from '../../../utils/StationUtils'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import StationAction from '../../actions/StationAction'
import { STATION_TYPE_NAME } from '../../constants/StationConstants'
import DtoStation from '../../dto/DtoStation'
import DtoPiezometryStationMeasure from '../../dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezoThreshold from '../../dto/piezometer/DtoPiezoThreshold'
import StationAssociatedStationApp from '../associatedStation/StationAssociatedStationApp'

const propsToFetch = {
    productionUnits: false,
    distributionUnits: false,
    installations: false,
    installation: false,
    installationAssociations: false,
    productionUnitAssociations: false,
    distributionUnitAssociations: false,
    stationWatermass: false,
    hydrogeologicalEntities: false,
    hydrometricStation: false,
    stationEvents: false,
    selectedSearchValues: false,
    settings: false,
}

class StationMapApp extends ActionComponent {
    constructor(props) {
        super(props)
        const dataLoaded = execByType(this.props.stationTypes[0], {
            piezometry: () => false,
            productionUnit: () => false,
            default: () => true,
        })
        this.state = { actions: {}, uncheckedStations: [], dataLoaded }
    }

    componentDidMount() {
        const isAuthorized = execByType(this.props.stationTypes[0], {
            quality: () => componentHasHabilitations(H_STATION_QUALITO_DASHBOARD),
            piezometry: () => componentHasHabilitations(H_STATION_PIEZO_DASHBOARD),
            pluviometry: () => componentHasHabilitations(H_STATION_PLUVIO_DASHBOARD),
            hydrometry: () => componentHasHabilitations(H_STATION_HYDRO_DASHBOARD),
            installation: () => componentHasHabilitations(H_STATION_INSTALLATION_DASHBOARD),
            units: () => componentHasHabilitations(H_UNITS_DASHBOARD),
            resource: () => componentHasHabilitations(H_STATION_RESOURCE_DASHBOARD),
        })
        if (!isAuthorized) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        setTimeout(() => $('.tooltipped').tooltip({ html: true }), 500)
        const station = getStation(this.props, this.props.stationTypes[0])
        if (station.id) {
            this.fetchWhenReceiveStation(station)
            if (!this.props.pictures.length && station?.code?.replaceAll(' ', '') !== '') {
                AppStore.dispatch(StationAction.fetchPictures(station.code, this.props.stationTypes[0]?.toUpperCase()))
            }
            if (!this.props.files.length && station?.code?.replaceAll(' ', '') !== '') {
                AppStore.dispatch(StationAction.fetchFiles(station.code, this.props.stationTypes[0]?.toUpperCase()))
            }
            if (!this.state.actions.links) {
                this.setState(prevState => ({
                    actions: {
                        ...prevState.actions,
                        links: getLinks(station, this.props),
                    },
                }))
            }
        } else {
            this.props.fetchThresholds()
            this.props.setBackPath(`/${this.props.stationTypes[0]}`)
            this.props.fetch('hydrogeologicalEntities')
            this.props.fetchPiezometryCampaignsProgress()
        }
        this.props.fetchUnits()
        if (this.props.stationTypes[0] === 'installation') {
            this.props.fetchInstallationVisits(station.id).then(() => {
                this.setState({
                    actions: {
                        ...this.state.actions,
                        visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
                    },
                })
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        const station = getStation(this.props, this.props.stationTypes[0])
        const nextStation = getStation(nextProps, this.props.stationTypes[0])
        const stations = sortBy(flatten(execByTypes(this.props.stationTypes, {
            quality: () => this.props.qualitometers,
            piezometry: () => this.props.piezometers,
            hydrometry: () => this.props.hydrometricStations,
            pluviometry: () => this.props.pluviometers,
            productionUnit: () => this.props.productionUnits,
            distributionUnit: () => this.props.distributionUnits,
            installation: () => {
                switch (this.props.installation.installationType) {
                    case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                        return this.props.industrialSiteTable
                    case INSTALLATION_TYPE.BOREHOLE:
                        return this.props.boreholeTable
                    case INSTALLATION_TYPE.STEP:
                        return this.props.STEPTable
                    case INSTALLATION_TYPE.TANK:
                        return this.props.tankTable
                    default:
                        return this.props.installationTable
                }
            },
            default: () => [],
        })), o => o.name ? o.name.toUpperCase() : '}')

        if (station.id && stations.length > 0) {
            this.setState(({ actions }) => ({
                actions: {
                    ...actions,
                    links: getLinks(station, this.props),
                    arrowNav: getStationArrowNav(this.props.stationTypes[0], stations, station.id, s => AppStore.dispatch(push(`/station/${this.props.stationTypes[0]}/${s.id}`))),
                    visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
                },
            }))
        }
        if ((!station.id && nextStation.id) || (!this.props.stationWatermass.code && nextProps.stationWatermass.code)) {
            this.fetchWhenReceiveStation(nextStation)
            this.setState(({ actions }) => ({
                actions: {
                    ...actions,
                    links: getLinks(nextStation, nextProps),
                },
            }))
        }
        if (getUser().consultant === '1') {
            if (station.typeName !== 'quality' && station.typeName !== 'piezometry' && station.typeName !== 'hydrometry'&& station.typeName !== 'pluviometry' && station.typeName !== 'resource') {
                this.setActions(omit(this.state.actions, ['save', 'edit']))
            } else {
                this.setActions({
                    ...omit(this.state.actions, ['save', 'edit']),
                    exportmodel: () => ({
                        stationId: station.id.toString(),
                        stationCode: station.code,
                        stationType: station.typeName,
                        environmentModels: this.props.typeEnvironmentModels,
                    }),
                })
            }
        } else if (station.typeName !== 'quality' && station.typeName !== 'piezometry' && station.typeName !== 'hydrometry'&& station.typeName !== 'pluviometry' && station.typeName !== 'resource') {
            this.setActions(this.state.actions)
        } else {
            this.setActions({
                ...this.state.actions,
                exportmodel: () => ({
                    stationId: station.id.toString(),
                    stationCode: station.code,
                    stationType: station.typeName,
                    environmentModels: this.props.typeEnvironmentModels,
                }),
            })
        }
    }

    fetchWhenReceiveStation = station => {
        this.setTitle({ ...station, code: station.code ? station.code.toString() : '' })
        if (station.stationType && this.props.qualityIndicators.length && this.props.stationTypes[0] === 'quality') {
            const indicators = this.props.qualityIndicators.find(i => i.stationType === parseInt(station.stationType))
            if (indicators && indicators.indicators && !this.props.qualityThresholds.length) {
                const thresholdIds = uniqBy(indicators.indicators.map(i => ({ code: i.threshold, thresholdType: '0' })), 'id')
                AppStore.dispatch(QualityAction.fetchQualityThresholds(thresholdIds))
            } else if (!indicators || !indicators.indicators) {
                AppStore.dispatch(ToastrAction.info(i18n.noQualityIndicatorsFoundError))
            }
        }
        const thresholdPC = getSetting(this.props.applicationSettings, 'SEUILS')
        const thresholdTaxon = getSetting(this.props.applicationSettings, 'SEUILS.TAXONS')
        const thresholdState = getSetting(this.props.applicationSettings, 'SEUILS.ETATS')
        const thresholds = [
            thresholdPC && { code: thresholdPC, thresholdType: '0' },
            thresholdTaxon && { code: thresholdTaxon, thresholdType: '1' },
            thresholdState && { code: thresholdState, thresholdType: '4' },
        ].filter(t => t)
        AppStore.dispatch(QualityAction.fetchQualityThresholds(thresholds))
    }

    setTitle = (station) => {
        this.props.forceFetch('title', [{
            title: i18n[this.props.stationTypes[0]],
            href: this.props.stationTypes[0],
        }, {
            title: getStationTitle(station),
            href: `station/${this.props.stationTypes[0]}/${station.id}`,
        }])
    }

    render() {
        const station = getStation(this.props, this.props.stationTypes[0])
        if (station.id) {
            return (
                <div className='row no-margin padding-left-2 padding-right-1 padding-top-1'>
                    <StationAssociatedStationApp urlStationTypes={this.props.stationTypes} stationDashboard
                        nearbyInstallations={this.props.stationTypes[0] === STATION_TYPE_NAME.installation}
                        getLink={this.props.getLink} persistantActions
                        layers={[LAYERS.WATERMASS, LAYERS.STATION_WATERSHED, LAYERS.STATION_WATERSHED_1, LAYERS.STATION_WATERSHED_2, LAYERS.STATION_WATERSHED_3, LAYERS.STATION_WATERSHED_4, LAYERS.TOWN, LAYERS.STATIONS_POINTS, LAYERS.STATION]}
                    />
                </div>
            )
        }
        return null
    }
}

StationMapApp.propTypes = getPropTypes(propsToFetch, {
    id: PropTypes.string.isRequired,
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    urlStationTypes: PropTypes.arrayOf(PropTypes.string),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    piezometers: arrayOf(DtoPiezometerLight),
    productionUnit: PropTypes.instanceOf(DtoProductionUnit),
    distributionUnit: PropTypes.instanceOf(DtoProductionUnit),
    qualitometer: PropTypes.instanceOf(DtoStation),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    qualityThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThresholds)),
    qualityIndicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityIndicators)),
    onReMount: PropTypes.func,
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    productionUnitEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitEvent)),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    piezometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    piezometryCampaignStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    resource: PropTypes.instanceOf(ResourceDto),
    fullScreen: PropTypes.bool,
    associatedSitesFetched: PropTypes.bool,
    push: PropTypes.func,
    pluviometerStatistics: arrayOf(DtoPluviometerStats),
    hydroStatistics: arrayOf(DtoHydroStats),
    installationTable: arrayOf(DtoInstallationTableLine),
})

StationMapApp.defaultProps = {
    fullScreen: false,
}

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    productionUnit: store.ProductionUnitReducer.productionUnit,
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    qualitometer: store.QualityReducer.qualitometer,
    qualitometers: store.QualityReducer.qualitometersLight,
    piezometer: store.StationReducer.piezometer,
    pluviometers: store.PluviometryReducer.pluviometers,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometer: store.PluviometryReducer.pluviometer,
    pictures: store.StationReducer.pictures,
    files: store.StationReducer.files,
    units: store.UnitReducer.units,
    qualityThresholds: store.QualityReducer.qualityThresholds,
    qualityIndicators: store.QualityReducer.qualityIndicators,
    measures: store.PiezometerStationReducer.measures,
    piezometerThresholds: store.PiezometerStationReducer.piezometerThresholds,
    productionUnitEvents: store.ProductionUnitReducer.productionUnitEvents,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,
    piezometryCampaignStations: store.PiezometryReducer.piezometryCampaignStations,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    associatedSites: store.StationReducer.associatedSites,
    installationVisits: store.InstallationReducer.installationVisits,
    resource: store.ResourceReducer.resource,
    pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    hydroStatistics: store.HydrometryReducer.hydroStatistics,
    installationTable: store.InstallationReducer.installationTable,
    industrialSiteTable: store.InstallationReducer.industrialSiteTable,
    boreholeTable: store.InstallationReducer.boreholeTable,
    STEPTable: store.InstallationReducer.STEPTable,
    tankTable: store.InstallationReducer.tankTable,
})

const mapDispatchToProps = {
    push,
    forceFetch: SieauAction.forceFetch,
    fetchThresholds: QualityAction.fetchThresholds,
    fetchUnits: UnitAction.fetchUnits,
    setBackPath: HomeAction.setBackPath,
    fetch: SieauAction.fetch,
    fetchPiezometryCampaignsProgress: CampaignAction.fetchPiezometryCampaignsProgress,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationMapApp)
