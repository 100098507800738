import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SelectedElementsModal from 'components/modal/SelectedElementsModal'
import { push } from 'connected-react-router'
import { every } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_PIEZO_GRAPHIC } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import ChartTabs from '../../../components/echart/ChartTabs'
import Select from '../../../components/forms/Select'
import SieauAction from '../../../components/sieau/SieauAction'
import CollapseTopBar from '../../../components/topBar/CollapseToBar'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'
import DtoStationType from '../../../referencial/dto/DtoStationType'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import DtoFilter from '../../../station/dto/DtoFilter'
import DtoPiezometerAdditionalMeasures from '../../../station/dto/piezometer/DtoPiezometerAdditionalMeasures'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { arrayOf, getComponentWithId } from '../../../utils/StoreUtils'
import PiezometryAction from '../../actions/PiezometryAction'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'
import PiezometryGraphicChart from './PiezometryGraphicChart'
import RadioButtons from '../../../components/forms/RadioButtons'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import SimpleOptionsToolPanel from '../validation/tools/SimpleOptionsToolPanel'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'
import { setLocalStorageJson } from '../../../utils/FormUtils'

class PiezometryGraphicApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                graphicType: 1,
                endDate: moment().valueOf(),
                threshold: null,
                displayMode: 'normal',
            },
            selectedStations: [],
            dataLoaded: false,
            dataLoading: false,
            progress: 0,
            isOpen: false,
            previousSelectedStations: [],
            graphicTitle: '',
            selectedDataType: -1,
            groupFunc: 'MAX',
            displayCote: 1,
        }
    }

    onChangeFilter = (selectedStations) => {
        this.setState({ previousSelectedStations: selectedStations })
    }

    onValidate = () => {
        this.setState({ isOpen: false, selectedStations: this.state.previousSelectedStations })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.dataLoaded !== this.state.dataLoaded && this.state.dataLoaded) {
            const filteredResults = this.getData()
            if (filteredResults.length && this.props.multiplePiezometersAdditionalMeasures.length > 0 &&
                filteredResults.length !== this.props.multiplePiezometersAdditionalMeasures.length) {
                this.props.toastrWarning(i18n.noSelectedStationDataOverThisPeriod)
            }
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PIEZO_GRAPHIC)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.piezometersLight.length) {
            getComponentWithId('#collapse-top-bar').collapse()
        } else {
            this.props.fetchPiezometersLight()
        }
        if (!this.props.dontSetTitle) {
            this.props.forceFetch('title', [{
                title: i18n.piezometry,
                href: 'piezometry',
            }, {
                title: i18n.graphics,
                href: 'piezometry/graphic',
            }])
        }
        this.setActions({})
        this.props.fetchPiezometryDataTypes()
        setLocalStorageJson('DISPLAY_COTE', 1)
        setLocalStorageJson('PIEZO_TAB_DISPLAY_MODES', { max: true, min: false, average: false, brute: false })
    }

    setChartTabsChanges = (obj) => {
        const groupFunc = Object.keys(obj.displayModes).find(mode => obj.displayModes[mode])
        this.setState({
            displayCote: obj.displayCote,
            groupFunc: groupFunc === 'brute' ? undefined : groupFunc.toUpperCase(),
        }, this.visualizeChart)
    }

    visualizeChart = () => {
        const { filter, selectedStations, groupFunc, displayCote } = this.state
        if (!filter.startDate || !filter.endDate) {
            this.props.toastrError(i18n.noDateSelectedError)
        } else if (every(Object.keys(this.state.selectedStations), c => !(this.state.selectedStations[c] || this.state.selectedStations[c] === 0))) {
            this.props.toastrError(i18n.noStationSelectedError)
        } else {
            getComponentWithId('#collapse-top-bar').collapse()
            if (selectedStations.length) {
                const selectedStationsIds = selectedStations.map(s => s.id)
                this.setState({ dataLoading: true, dataLoaded: false, progress: 0 })
                this.props.loadPiezometersAdditionalMeasuresByDataType(
                    selectedStationsIds,
                    {
                        groupFunc,
                        displayCote,
                        startDate: filter.startDate,
                        endDate: filter.endDate,
                    },
                    this.state.selectedDataType,
                    p => this.setState({ progress: p })
                ).then(() => {
                    this.setState({ dataLoaded: true })
                })
            }
        }
    }

    getData = () => {
        const { selectedStations } = this.state
        return selectedStations.filter(s => !this.props.piezometersLight.find(a => a.stationCode === s)).map(s => {
            return {
                stationCode: s,
                noData: true,
            }
        })
    }

    getChart = () => {
        if (this.state.dataLoaded) {
            const filteredResults = this.getData()
            const dataType = this.props.piezometryDataTypes.find(d => d.id === this.state.selectedDataType)
            if (filteredResults.length && this.props.multiplePiezometersAdditionalMeasures.length > 0) {
                return (
                    <div className='row padding-1 relative no-overflow'>
                        <div className='col s9'>
                            <Card className='padding-top-3 padding-bottom-1 padding-left-1'>
                                <ChartTabs
                                    style={ { right: 150 } }
                                    withPiezoCote
                                    withoutButtons
                                    displayModesUniqChoise
                                    changeParent={ this.setChartTabsChanges }
                                />
                                <PiezometryGraphicChart
                                    filter={this.state.filter}
                                    dataType={dataType}
                                    exportedData={filteredResults}
                                    selectedStations={filteredResults}
                                    colors={this.state.selectedStations}
                                    graphicOptions={this.state}
                                />
                            </Card>
                        </div>
                        <div className='col s3 no-margin'>
                            <SimpleOptionsToolPanel
                                updateState={ ch => this.setState(ch) }
                                startDate={this.state.filter.startDate}
                                endDate={this.state.filter.endDate}
                            />
                        </div>
                    </div>
                )
            }
            return (
                <Card className='padding-top-1 padding-bottom-1 padding-left-1'>
                    <h5>{i18n.noDataToDisplay}</h5>
                </Card>
            )
        }
        if (this.state.dataLoading) {
            return <ProgressCard progress={this.state.progress} />
        }
        return null
    }

    render() {
        const { filter } = this.state
        if (this.props.piezometersLight.length) {
            const graphicTypes = [
                { value: 1, label: i18n.lineGraph },
                { value: 2, label: i18n.histogram },
            ]
            const evolutionModes = [
                { value: 'normal', label: i18n.comparing },
                { value: 'relative', label: i18n.relativeEvolution },
                { value: 'percent', label: i18n.percent },
            ]
            const dataTypes = this.props.piezometryDataTypes.map(t => ({ code: t.id, name: t.label, unit: t.unit }))
            return (
                <div>
                    <div className='col s12 padding-left-1 padding-right-1'>
                        <div className='padding-left-2 padding-right-2'>
                            <CollapseTopBar>
                                <Card greyContent>
                                    <div className='row no-margin padding-top-1'>
                                        <SimpleDatePicker col={3} label={i18n.startDate} onChange={value => this.setState({
                                            filter: {
                                                ...this.state.filter,
                                                startDate: moment(value).valueOf(),
                                            },
                                        })} id='startDate'
                                        value={filter.startDate} max={filter.endDate}
                                        />
                                        <SimpleDatePicker col={3} label={i18n.endDate} onChange={value => this.setState({
                                            filter: {
                                                ...this.state.filter,
                                                endDate: moment(value).valueOf(),
                                            },
                                        })} id='endDate'
                                        value={filter.endDate} min={filter.startDate}
                                        />
                                        <Select
                                            col={3}
                                            label={i18n.chronicType}
                                            options={dataTypes}
                                            value={this.state.selectedDataType}
                                            onChange={ v => this.setState({ selectedDataType: v }) }
                                        />
                                        <div className='col s2 input-field'>
                                            <label className='sieau-input select-label' >{i18n.selectStations}</label>
                                            <i className='material-icons blue-text clickable qualityGraphicsStationIcon tooltipped' data-tooltip={i18n.stations} onClick={() => this.setState({ isOpen: true, previousSelectedStations: this.state.selectedStations })}>list</i>
                                        </div>
                                    </div>
                                    <div className='row no-margin valign-wrapper padding-bottom-1 padding-top-1'>
                                        <Select col={3} label={i18n.graphicType} options={graphicTypes} onChange={(v) => this.setState({
                                            filter: {
                                                ...this.state.filter,
                                                ['graphicType']: v,
                                            },
                                        })} value={filter.graphicType}
                                        />
                                        <RadioButtons col={4} elements={evolutionModes} selected={filter.displayMode} onChange={value => this.setState({
                                            filter: {
                                                ...this.state.filter,
                                                displayMode: value,
                                            },
                                        })} title={i18n.displayMode}
                                        />
                                        <div className='col s5 padding-top-1'>
                                            <a className='waves-effect waves-light btn right' onClick={this.visualizeChart}><i className='material-icons left'>search</i>{i18n.visualize}</a>
                                        </div>
                                    </div>
                                </Card>
                            </CollapseTopBar>
                        </div>
                    </div>
                    <div className='col s12'>
                        {this.getChart()}
                    </div>
                    <Dialog
                        onClose={() => this.setState({ isOpen: false, selectedStations: this.state.previousSelectedStations })}
                        fullWidth
                        maxWidth='lg'
                        scroll='body'
                        open={this.state.isOpen}
                    >
                        <DialogTitle>{i18n.selectStations}</DialogTitle>
                        <DialogContent>
                            <SelectedElementsModal
                                selectedList={this.state.previousSelectedStations}
                                onChange={this.onChangeFilter}
                                list={this.props.piezometersLight}
                                elementType={STATION_TYPE_NAME.piezometry}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ isOpen: false, selectedStations: this.state.previousSelectedStations })} >
                                {i18n.close}
                            </Button>
                            <Button onClick={this.onValidate} variant='contained' color='primary'>
                                {i18n.validate}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
        return (
            <div className='padding-top-1 padding-bottom-1 padding-left-2'>
                <Card className='padding-top-1 padding-bottom-1 padding-left-2'>
                    <h5>{i18n.noPiezometers}</h5>
                </Card>
            </div>
        )
    }
}

PiezometryGraphicApp.propTypes = {
    defaultSelectedData: PropTypes.object,
    dontSetTitle: PropTypes.bool,
    getLink: PropTypes.func,
    toastrWarning: PropTypes.func,
    toastrError: PropTypes.func,
    reset: PropTypes.func,
    fetch: PropTypes.func,
    forceFetch: PropTypes.func,
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    piezometersLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    stationTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationType)),
    filters: PropTypes.arrayOf(PropTypes.instanceOf(DtoFilter)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    fetchPiezometersLight: PropTypes.func,
    multiplePiezometersAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerAdditionalMeasures)),
    loadPiezometersAdditionalMeasuresByDataType: PropTypes.func,
    push: PropTypes.func,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
    piezometersLight: store.PiezometryReducer.piezometersLight,
    stationTypes: store.ReferencialReducer.stationTypes,
    filters: store.StationReducer.filters,
    units: store.UnitReducer.units,
    multiplePiezometersAdditionalMeasures: store.PiezometerStationReducer.multiplePiezometersAdditionalMeasures,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
    reset: SieauAction.reset,
    fetch: SieauAction.fetch,
    forceFetch: SieauAction.forceFetch,
    fetchPiezometersLight: PiezometryAction.fetchPiezometersLight,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    loadPiezometersAdditionalMeasuresByDataType: PiezometerStationAction.loadPiezometersAdditionalMeasuresByDataType,
    toastrWarning: ToastrAction.warning,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryGraphicApp)
