import { getCampaignIcon } from 'campaign/utils/CampaignUtils'
import { push } from 'connected-react-router'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import DtoHydrometryObservation from 'hydrometry/dto/DtoHydrometryObservation'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { flatten, isEqual, omit, sortBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResourceDto from 'resource/dto/ResourceDto'
import i18n from 'simple-react-i18n'
import DtoPicture from 'station/dto/DtoPicture'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import {
    H_MAT_MODULE,
    H_STATION_HYDRO_DASHBOARD,
    H_STATION_INSTALLATION_DASHBOARD,
    H_STATION_PIEZO_DASHBOARD,
    H_STATION_PLUVIO_DASHBOARD,
    H_STATION_QUALITO_DASHBOARD,
    H_STATION_RESOURCE_DASHBOARD,
    H_UNITS_DASHBOARD,
} from '../../../account/constants/AccessRulesConstants'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from '../../../campaign/dto/DtoStationCampaign'
import Card from '../../../components/card/Card'
import DtoFile from '../../../components/file/dto/DtoFile'
import FilePanel from '../../../components/file/FilePanel'
import CartographyExportPanel from '../../../components/map/CartographyExportPanel'
import SmallPicturePanel from '../../../components/picture/SmallPicturePanel'
import Row from '../../../components/react/Row'
import SieauAction from '../../../components/sieau/SieauAction'
import DistributionUnitPanel from '../../../distributionUnit/components/DistributionUnitPanel'
import HomeAction from '../../../home/actions/HomeAction'
import HydroChartPanel from '../../../hydrometry/components/stationDashboard/HydroChartPanel'
import HydoStatisticsPanel from '../../../hydrometry/components/stationDashboard/HydroStatisticsPanel'
import DtoHydroStats from '../../../hydrometry/dto/chronicMeasures/DtoHydroStats'
import InstallationAction from '../../../installation/actions/InstallationAction'
import BoreholeDashboardPanel from '../../../installation/components/borehole/BoreholeDashboardPanel'
import CavityDashboardPanel from '../../../installation/components/cavity/CavityDashboardPanel'
import FlowObstructionDashboardPanel from '../../../installation/components/flowObstruction/FlowObstructionDashboardPanel'
import IndustrialSiteDashboardPanel from '../../../installation/components/industrialSite/IndustrialSiteDashboardPanel'
import InstallationDashboardPanel from '../../../installation/components/installationDefault/InstallationDashboardPanel'
import PollutedSoilDashboardPanel from '../../../installation/components/pollutedSoil/PollutedSoilDashboardPanel'
import STEPDashboardPanel from '../../../installation/components/step/STEPDashboardPanel'
import { INSTALLATION_TYPE, INSTALLATION_TYPE_CRITERIA } from '../../../installation/constants/InstallationConstants'
import DatasAlertPanel from '../../../piezometry/components/alerts/datas/DatasAlertPanel'
import ThresholdAlertPanel from '../../../piezometry/components/alerts/thresholds/ThresholdAlertPanel'
import PiezometryExploitedPanel from '../../../piezometry/components/stationDashboard/PiezometryExploitedPanel'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerChartPanel from '../../../pluviometry/components/stationDashboard/PluviometerChartPanel'
import PluviometerStatisticPanel from '../../../pluviometry/components/stationDashboard/PluviometerStatisticPanel'
import DtoPluviometerStats from '../../../pluviometry/dto/DtoPluviometerStats'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import ProductionPiezometerAlertPanel from '../../../productionUnit/components/alerts/ProductionPiezometerAlertPanel'
import UnitChartPanel from '../../../productionUnit/components/UnitChartPanel'
import UnitPiezometryPanel from '../../../productionUnit/components/UnitPiezometryPanel'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoProductionUnitEvent from '../../../productionUnit/dto/DtoProductionUnitEvent'
import QualityAction from '../../../quality/actions/QualityAction'
import DashboardQualitySituationPanel from '../../../quality/components/dashboard/DashboardQualitySituationPanel'
import IndicatorsChart from '../../../quality/components/graph/IndicatorsChart'
import DtoQualityIndicators from '../../../quality/dto/QualityIndicator/DtoQualityIndicators'
import DtoQualityThresholds from '../../../quality/dto/QualityThreshold/DtoQualityThresholds'
import UnitAction from '../../../referencial/components/unit/actions/UnitAction'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getColorCampaign, getCurrentCampaign } from '../../../utils/CampaignUtil'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import { getDate, getFullDate, getHour } from '../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { canShowStationTypeData, getSetting, getUser } from '../../../utils/SettingUtils'
import { execByType, execByTypes, getLinks, getStation, getStationTitle } from '../../../utils/StationUtils'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import DtoStation from '../../dto/DtoStation'
import DtoPiezometryStationMeasure from '../../dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezoThreshold from '../../dto/piezometer/DtoPiezoThreshold'
import LinkedStationsPanel from '../associatedStation/LinkedStationsPanel'
import StationDescription from '../description/StationDescription'
import LastEventPanel from '../event/LastEventPanel'
import StationAssociatedTownsPanel from '../link/StationAssociatedTownsPanel'
import MaterielAssigned from '../materiel/MaterielAssigned'
import StationDashboardKeyFigurePanel from './component/keyfigure/StationDashboardKeyFigurePanel'
import StationMapDashboardPanel from './component/map/StationMapDashboardPanel'
import DtoMeasureStats from '../../dto/piezometer/DtoMeasureStats'
import ProdGraphPanel from 'productionUnit/components/ProdGraphPanel'
import ProgressBar from 'components/progress/ProgressBar'
import SituationHydrobioPanel from 'quality/components/dashboard/SituationHydrobioPanel'
import { setActions } from '../../../components/ActionUtil'
import StraitDashboardPanel from 'installation/components/strait/StraitDashboardPanel'
import { getLocalStorageJson, setLocalStorageJson } from 'utils/FormUtils'
import ProductionUnitLinkedPiezo from 'productionUnit/components/ProductionUnitLinkedPiezo'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import ThresholdIndicators from 'piezometry/components/alerts/thresholds/ThresholdIndicators'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { hasBooleanValue } from 'utils/NumberUtil'
import LastObservationsPanel from 'hydrometry/components/observation/LastObservationsPanel'

const propsToFetch = {
    productionUnits: false,
    distributionUnits: false,
    installations: false,
    installation: false,
    installationAssociations: false,
    productionUnitAssociations: false,
    distributionUnitAssociations: false,
    stationWatermass: false,
    hydrogeologicalEntities: false,
    hydrometricStation: false,
    stationEvents: false,
    selectedSearchValues: false,
    settings: false,
}

class StationDashboardApp extends Component {
    constructor(props) {
        super(props)
        const dataLoaded = execByType(this.props.stationTypes[0], {
            piezometry: () => false,
            default: () => true,
        })
        const display = getLocalStorageJson('DASHBOARD_DISPLAY')
        const stationType = this.getStationTypeDisplay(this.props.stationTypes[0])
        const overviewDisplay = display?.[stationType]?.OVERVIEW
        this.state = { actions: {}, uncheckedStations: [], dataLoaded, expanded: hasBooleanValue(overviewDisplay) ? overviewDisplay : true }
    }

    componentDidMount() {
        const isAuthorized = execByType(this.props.stationTypes[0], {
            quality: () => componentHasHabilitations(H_STATION_QUALITO_DASHBOARD),
            piezometry: () => componentHasHabilitations(H_STATION_PIEZO_DASHBOARD),
            pluviometry: () => componentHasHabilitations(H_STATION_PLUVIO_DASHBOARD),
            hydrometry: () => componentHasHabilitations(H_STATION_HYDRO_DASHBOARD),
            installation: () => componentHasHabilitations(H_STATION_INSTALLATION_DASHBOARD),
            units: () => componentHasHabilitations(H_UNITS_DASHBOARD),
            resource: () => componentHasHabilitations(H_STATION_RESOURCE_DASHBOARD),
        })
        if (!isAuthorized) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        setTimeout(() => $('.tooltipped').tooltip({ html: true }), 500)
        const station = getStation(this.props, this.props.stationTypes[0])
        if (station.id) {
            this.fetchWhenReceiveStation(station)
            if (!this.state.actions.links) {
                this.setState(prevState => ({
                    actions: {
                        ...prevState.actions,
                        links: getLinks(station, this.props),
                    },
                }))
            }
        } else {
            this.props.fetchThresholds()
            this.props.setBackPath(`/${this.props.stationTypes[0]}`)
            this.props.fetch('hydrogeologicalEntities')
            this.props.fetchPiezometryCampaignsProgress()
        }
        this.props.fetchUnits()
        if (this.props.stationTypes[0] === 'installation') {
            this.props.fetchInstallationVisits(station.id).then(() => {
                this.setState(prevState => ({
                    actions: {
                        ...prevState.actions,
                        visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
                    },
                }))
            })
        }
        if (this.props.stationTypes[0] === 'hydrometry') {
            this.props.fetchHydrometryObservations(station.id)
        }
    }

    componentWillReceiveProps(nextProps) {
        const station = getStation(this.props, this.props.stationTypes[0])
        const nextStation = getStation(nextProps, this.props.stationTypes[0])
        const stations = sortBy(flatten(execByTypes(this.props.stationTypes, {
            quality: () => this.props.qualitometers,
            piezometry: () => this.props.piezometers,
            hydrometry: () => this.props.hydrometricStations,
            pluviometry: () => this.props.pluviometers,
            productionUnit: () => this.props.productionUnits,
            distributionUnit: () => this.props.distributionUnits,
            installation: () => {
                const localFilter = getLocalStorageJson(INSTALLATION_TYPE_CRITERIA)?.filter
                switch (localFilter?.installationType) {
                    case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                        return this.props.industrialSiteTable
                    case INSTALLATION_TYPE.BOREHOLE:
                        return this.props.boreholeTable
                    case INSTALLATION_TYPE.STEP:
                        return this.props.STEPTable
                    case INSTALLATION_TYPE.TANK:
                        return this.props.tankTable
                    default:
                        return this.props.installationTable
                }
            },
            default: () => [],
        })), o => o.name ? o.name.toUpperCase() : '}')

        if (!isEqual(this.props.distributionUnitLink, nextProps.distributionUnitLink)) {
            this.setState(({ actions }) => ({
                actions: {
                    ...actions,
                    links: getLinks(station, nextProps),
                },
            }))
        }

        if (station.id && stations.length > 0) {
            this.setState(({ actions }) => ({
                actions: {
                    ...actions,
                    links: getLinks(station, this.props),
                    arrowNav: getStationArrowNav(this.props.stationTypes[0], stations, station.id, s => AppStore.dispatch(push(`/station/${this.props.stationTypes[0]}/${s.id}`))),
                    visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
                },
            }))
        }
        if ((!station.id && nextStation.id) || (!this.props.stationWatermass.code && nextProps.stationWatermass.code)) {
            this.fetchWhenReceiveStation(nextStation)
            this.setState(({ actions }) => ({
                actions: {
                    ...actions,
                    links: getLinks(nextStation, nextProps),
                },
            }))
        }
        if (getUser().consultant === '1') {
            if (station.typeName !== 'quality' && station.typeName !== 'piezometry' && station.typeName !== 'hydrometry'&& station.typeName !== 'pluviometry' && station.typeName !== 'resource') {
                setActions(omit(this.state.actions, ['save', 'edit']))
            } else {
                setActions({
                    ...omit(this.state.actions, ['save', 'edit']),
                    exportmodel: () => ({
                        stationId: station.id.toString(),
                        stationCode: station.code,
                        stationType: station.typeName,
                        environmentModels: this.props.typeEnvironmentModels,
                    }),
                })
            }
        } else if (station.typeName !== 'quality' && station.typeName !== 'piezometry' && station.typeName !== 'hydrometry'&& station.typeName !== 'pluviometry' && station.typeName !== 'resource') {
            setActions(this.state.actions)
        } else {
            setActions({
                ...this.state.actions,
                exportmodel: () => ({
                    stationId: station.id.toString(),
                    stationCode: station.code,
                    stationType: station.typeName,
                    environmentModels: this.props.typeEnvironmentModels,
                }),
            })
        }
    }

    fetchWhenReceiveStation = station => {
        this.setTitle({ ...station, code: station.code ? station.code.toString() : '' })
        if (station.stationType && this.props.qualityIndicators.length && this.props.stationTypes[0] === 'quality') {
            const indicators = this.props.qualityIndicators.find(i => i.stationType === parseInt(station.stationType))
            if (indicators && indicators.indicators && !this.props.qualityThresholds.length) {
                const thresholdIds = uniqBy(indicators.indicators.map(i => ({ code: i.threshold, thresholdType: '0' })), 'id')
                AppStore.dispatch(QualityAction.fetchQualityThresholds(thresholdIds))
            } else if (!indicators || !indicators.indicators) {
                AppStore.dispatch(ToastrAction.info(i18n.noQualityIndicatorsFoundError))
            }
        }
        const thresholdPC = getSetting(this.props.applicationSettings, 'SEUILS')
        const thresholdTaxon = getSetting(this.props.applicationSettings, 'SEUILS.TAXONS')
        const thresholdState = getSetting(this.props.applicationSettings, 'SEUILS.ETATS')
        const thresholds = [
            thresholdPC && { code: thresholdPC, thresholdType: '0' },
            thresholdTaxon && { code: thresholdTaxon, thresholdType: '1' },
            thresholdState && { code: thresholdState, thresholdType: '4' },
        ].filter(t => t)
        AppStore.dispatch(QualityAction.fetchQualityThresholds(thresholds))
    }

    setTitle = (station) => {
        this.props.forceFetch('title', [{
            title: i18n[this.props.stationTypes[0]],
            href: this.props.stationTypes[0],
        }, {
            title: getStationTitle(station),
            href: `station/${this.props.stationTypes[0]}/${station.id}`,
        }])
    }

    getAlertPanel = (station) => {
        return execByType(this.props.stationTypes[0], {
            piezometry: () => (
                <>
                    <DatasAlertPanel stats={this.props.piezometerStatistics} stationType='piezometry' station={this.props.piezometer} round/>
                    <ThresholdAlertPanel
                        piezometer={station}
                        piezometerThresholds={this.props.piezometerThresholds}
                    />
                </>
            ),
            hydrometry: () => <DatasAlertPanel stats={this.props.hydroStatistics} stationType='hydrometry' station={this.props.hydrometricStation} round/>,
            pluviometry: () => <DatasAlertPanel stats={this.props.pluviometerStatistics} stationType='pluviometry' station={this.props.pluviometer} round/>,
            productionUnit: () => <ProductionPiezometerAlertPanel />,
            default: () => {},
        })
    }

    getPiezometryChartPanel = () => {
        if (this.props.piezometer.id && this.props.id == this.props.piezometer.id) {
            return (
                <PiezometryExploitedPanel
                    id={this.props.id}
                    setDataLoaded={this.setDataLoaded}
                />)
        }
        return null
    }

    getPluviometryChartPanel = () => {
        if (this.props.pluviometerStatistics.length) {
            return <PluviometerChartPanel noBoxShadow id={parseInt(this.props.id)} />
        }
        return null
    }

    getHydroChartPanel = () => {
        return <HydroChartPanel id={parseInt(this.props.id)} />
    }

    setDataLoaded = () => {
        if (!this.state.dataLoaded) {
            this.setState({ dataLoaded: true })
        }
    }

    getCampaignsCard = () => {
        const { piezometryCampaignsProgress, piezometryCampaigns } = this.props
        const stationCampaigns = this.props.piezometryCampaignStations.filter(o => o.stationId == this.props.id)
        const currentCampaign = getCurrentCampaign(piezometryCampaignsProgress, piezometryCampaigns)
        const campaignsByStation = stationCampaigns.map(s => {
            return currentCampaign.find(o => o.id == s.campaignId) || {}
        }).filter(o => o.id)
        if (campaignsByStation.length) {
            return (
                <div className='padding-bottom-1'>
                    <Card>
                        {campaignsByStation.map(c => {
                            return (
                                <div className='row card clickable' onClick={() => this.props.push(`/piezometry/campaign/${c.id}`)} style={{ borderLeft: `solid 7px ${getColorCampaign(c.isArchived, 0, c.statut)}` }}>
                                    <div className='divider' />
                                    <div>
                                        <h6 className='padding-left-1 bold bigFont'>{c.name}</h6>
                                        <h6 className='padding-left-1 italic-label mediumFont'>{c.beginningApplication} {i18n.to} {c.endingApplication}</h6>
                                        {c.comment ? <h6 className='padding-left-1 little-padding mediumFont'>{c.comment}</h6> : ''}
                                    </div>
                                    <div className='no-padding col s12 background-grayed'>
                                        <div className='col s6 no-padding'>
                                            <div className='row no-margin valign-wrapper padding-left-2 font-size-10'>
                                                {this.props.shortDate ?
                                                    `${i18n.atDate}${getFullDate(c.updateDate)} ${i18n.by} ${c.updateLogin}` :
                                                    `${i18n.lastUpdateOn} ${getDate(c.updateDate)} ${i18n.atHour} ${getHour(c.updateDate)} ${i18n.by} ${c.updateLogin}`}
                                            </div>
                                        </div>
                                        <div className='col s6 center'>
                                            {getCampaignIcon(c.campaignType)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Card>
                </div>
            )
        }
        return null
    }

    getActionsButtons = (localisationStation) => {
        const actionButtons = []
        // export button
        actionButtons.push(
            <a className='waves-effect waves-light ol-control btn ol-control-style tooltipped dropdown-button'
                data-position='bottom' data-activates='exportDropdown' data-tooltip={i18n.export}
            >
                <i className='material-icons left no-margin ol-button-style'>file_download</i>
            </a>
        )
        actionButtons.push(
            <CartographyExportPanel
                layers={this.props.layers}
                station={localisationStation ? localisationStation.station : getStation(this.props, this.props.stationTypes[0])}
                analysis={this.props.analysis}
            />
        )
        if (actionButtons.length) {
            return (
                <div className='absolute padding-top-1 padding-left-4'>
                    {actionButtons}
                </div>
            )
        }
        return null
    }

    onShowMore = () => {
        const station = getStation(this.props, this.props.stationTypes[0])
        this.props.push(`/station/${this.props.stationTypes[0]}/${station.id}/picture`)
    }

    getStationTypeDisplay = (stationType) => execByType(stationType, {
        quality: () => 'QUALITY',
        piezometry: () => 'PIEZOMETRY',
        pluviometry: () => 'PLUVIOMETRY',
        hydrometry: () => 'HYDROMETRY',
        installation: () => 'INST',
        default: () => 'DEFAULT',
    })

    setAccordion = () => {
        const { expanded } = this.state
        const stationType = this.getStationTypeDisplay(this.props.stationTypes[0])
        const actualLocalStorage = getLocalStorageJson('DASHBOARD_DISPLAY') || {}
        const newDisplay = {
            ...actualLocalStorage,
            [stationType]: {
                ...actualLocalStorage?.[stationType],
                ['OVERVIEW']: !expanded,
            },
        }
        setLocalStorageJson('DASHBOARD_DISPLAY', newDisplay)
        this.setState({ expanded: !expanded })
    }

    getFilePanel = () => {
        const station = getStation(this.props, this.props.stationTypes[0])
        const situationPlots = execByTypes(this.props.stationTypes, {
            quality: () => this.props.qualitometer.id ? (
                <>
                    <DashboardQualitySituationPanel
                        stationType={this.props.qualitometer.stationType}
                        uncheckedStations={this.state.uncheckedStations}
                        showAll
                    />
                    <div className='margin-top-1'>
                        <SituationHydrobioPanel />
                    </div>
                </>
            ): null,
            piezometry: () => this.getPiezometryChartPanel(),
            pluviometry: () => this.getPluviometryChartPanel(),
            productionUnit: () => this.props.productionUnit.id ? (
                <Row>
                    <ProdGraphPanel />
                    <UnitChartPanel id={this.props.productionUnit.id} productionUnit={this.props.productionUnit} uncheckedStations={this.state.uncheckedStations} setDataLoaded={this.setDataLoaded} fromStationType='PROD' />
                    <ProductionUnitLinkedPiezo />
                </Row>
            ) : null,
            distributionUnit: () => this.props.distributionUnit.id ? <DistributionUnitPanel id={this.props.distributionUnit.id} uncheckedStations={this.state.uncheckedStations} setDataLoaded={this.setDataLoaded} /> : null,
            installation: () => null,
            hydrometry: () => this.getHydroChartPanel(),
            resource: () => this.props.resource.id ? <UnitChartPanel id={this.props.resource.id} uncheckedStations={this.state.uncheckedStations} setDataLoaded={this.setDataLoaded} fromStationType='RESOURCE' /> : null,
            default: () => null,
        })
        const piezoPanel = execByTypes(this.props.stationTypes, {
            quality: () => {
                const assocPiezo = this.props.associatedSites.filter(as => as.stationLinkedType === 1)
                return assocPiezo.length && canShowStationTypeData('QUALITO', 'PIEZO') ?
                    (
                        <div className='margin-top-1'>
                            <UnitPiezometryPanel
                                id={this.props.qualitometer.id}
                                setDataLoaded={() => { }}
                                uncheckedStations={this.state.uncheckedStations}
                                associations={this.props.associatedSites}
                                fromStationType='QUALITO'
                                dontShowEmptyCharts
                            />
                        </div>
                    ) : null
            },
            default: () => null,
        })

        const localisationStation = this.props.stationTypes.reduce((acc, type) => {
            if (acc) {
                return acc
            }
            const loc = getStation(this.props, type).localisation
            if (loc && loc.x && loc.y && loc.projection) {
                return {
                    station: getStation(this.props, type),
                    type,
                }
            }
            return null
        }, null)

        const panels = execByType(this.props.stationTypes[0], {
            quality: () => ({
                keyFigure: <StationDashboardKeyFigurePanel
                    id={this.props.id}
                    stationTypes={this.props.stationTypes}
                    location={this.props.location}
                />,
                chartsPanel: this.props.associatedSitesFetched ? <IndicatorsChart /> : null,
                linkedStations: <LinkedStationsPanel onReMount={this.props.onReMount} dashboardMode
                    changeUncheckedStations={tab => this.setState({ uncheckedStations: tab })}
                    station={this.props.qualitometer} hideTitle
                />,
            }),
            piezometry: () => ({
                keyFigure: <StationDashboardKeyFigurePanel
                    id={this.props.id}
                    stationTypes={this.props.stationTypes}
                    includesCardTitle={false}
                    location={this.props.location}
                />,
                linkedStations: <LinkedStationsPanel onReMount={this.props.onReMount} dashboardMode
                    station={this.props.piezometer} hideTitle
                />,
            }),
            pluviometry: () => ({
                keyFigure: <PluviometerStatisticPanel id={this.props.id} />,
                linkedStations: <LinkedStationsPanel onReMount={this.props.onReMount} dashboardMode
                    station={this.props.pluviometer} hideTitle
                />,
            }),
            hydrometry: () => ({
                keyFigure: (
                    <>
                        <HydoStatisticsPanel id={this.props.id} />
                        <LastObservationsPanel />
                    </>
                ),
                linkedStations: <LinkedStationsPanel onReMount={this.props.onReMount} dashboardMode
                    station={this.props.hydrometricStation} hideTitle
                />,
            }),
            productionUnit: () => {
                return {
                    keyFigure: null,
                }
            },
            distributionUnit: () => {
                return {
                    keyFigure: (
                        <Row>
                            <StationAssociatedTownsPanel
                                invertedHeaderStyle
                                station={this.props.distributionUnit}
                                readMode
                            />
                        </Row>
                    ),
                    linkedStations: <LinkedStationsPanel onReMount={this.props.onReMount} dashboardMode
                        station={this.props.distributionUnit} hideTitle
                    />,
                }
            },
            installation: () => {
                switch (this.props.installation.installationType) {
                    case INSTALLATION_TYPE.BOREHOLE:
                        return { dashboardComponent: <BoreholeDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} /> }
                    case INSTALLATION_TYPE.CAVITY:
                        return { dashboardComponent: <CavityDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} /> }
                    case INSTALLATION_TYPE.STEP:
                        return { dashboardComponent: <STEPDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} /> }
                    case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                        return { dashboardComponent: <IndustrialSiteDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} /> }
                    case INSTALLATION_TYPE.POLLUTED_SOIL:
                        return { dashboardComponent: <PollutedSoilDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} /> }
                    case INSTALLATION_TYPE.FLOW_OBSTRUCTION:
                    case INSTALLATION_TYPE.SLUICE:
                        return { dashboardComponent: <FlowObstructionDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} /> }
                    case INSTALLATION_TYPE.STRAIT:
                        return { dashboardComponent: <StraitDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} /> }
                    default:
                        return ({
                            dashboardComponent: <InstallationDashboardPanel stationType={this.props.stationTypes[0]} setDataLoaded={this.setDataLoaded} />,
                        })
                }
            },
            resource: () => {
                return {
                    keyFigure: null,
                    linkedStations: <LinkedStationsPanel
                        onReMount={this.props.onReMount}
                        dashboardMode
                        checkable
                        changeUncheckedStations={tab => this.setState({ uncheckedStations: tab })}
                        station={this.props.resource} withColors
                    />,
                }
            },
        })

        if (panels.dashboardComponent) {
            return panels.dashboardComponent
        }

        const mapPanel = (() => {
            if (this.props.stationTypes[0] !== 'distributionUnit') {
                return (<StationMapDashboardPanel
                    noMarkerTooltip
                    station={localisationStation ? localisationStation.station : getStation(this.props, this.props.stationTypes[0])}
                    type={localisationStation ? localisationStation.type : this.props.stationTypes[0]}
                    actionButtons={this.getActionsButtons(localisationStation)}
                    className='margin-bottom-1 no-margin-top'
                />)
            }
            return null
        })()

        const campaignByStationCard = execByType(this.props.stationTypes[0], {
            piezometry: () => this.getCampaignsCard(),
            default: () => null,
        })

        const indicatorsByStation = execByType(this.props.stationTypes[0], {
            productionUnit: () => {
                const { associatedSites } = this.props
                const associatedPiezos = associatedSites.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.piezometer).map(({ stationLinkedId }) => stationLinkedId)
                const associatedHydros = associatedSites.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry).map(({ stationLinkedId }) => stationLinkedId)
                return !!associatedSites.length && (
                    <div style={{ padding: '0.5rem 0' }}>
                        <ThresholdIndicators
                            piezoIds={associatedPiezos.length > 1 ? [associatedPiezos[0]] : associatedPiezos}
                            hydroIds={associatedHydros.length > 1 ? [associatedHydros[0]] : associatedHydros}
                        />
                    </div>
                )
            },
            default: () => null,
        })

        const { pictures, files, hydrometryObservations, filesFetched, picturesFetched } = this.props
        return (
            <>
                <div className='col s9 padding-top-1 no-padding-left' style={{ paddingBottom: '150px' }}>
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            <StationDescription id={this.props.id} stationTypes={this.props.stationTypes} noOwner />
                        </div>
                    </div>
                    {!!(panels.keyFigure || situationPlots) && !(this.props.stationTypes[0] === 'productionUnit' || this.props.stationTypes[0] === 'distributionUnit') && (
                        <AccordionMUI noUnmount round style={{ marginBottom: 10, marginTop: 10 }} expanded={this.state.expanded} onChange={this.setAccordion}>
                            <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>{i18n.overview}</AccordionSummaryMUI>
                            <AccordionDetailsMUI>
                                {!!panels.keyFigure && (
                                    <div className='margin-top-1'>
                                        {panels.keyFigure}
                                    </div>
                                )}
                                {!!situationPlots && (
                                    <div className='margin-top-1'>
                                        {situationPlots}
                                    </div>
                                )}
                            </AccordionDetailsMUI>
                        </AccordionMUI>
                    ) || (
                        <>
                            {!!panels.keyFigure && (
                                <div className='margin-top-1'>
                                    {panels.keyFigure}
                                </div>
                            )}
                            {!!situationPlots && (
                                <div className='margin-top-1'>
                                    {situationPlots}
                                </div>
                            )}
                        </>
                    )}
                    {!!panels.chartsPanel && (
                        <div className='margin-top-1'>
                            {panels.chartsPanel}
                        </div>
                    )}
                    {!!piezoPanel}
                </div>
                <div className='col s3 padding-left-0 padding-right-1 padding-top-1' style={{ marginBottom: 100 }}>
                    {this.state.dataLoaded ? (
                        <div>
                            {this.getAlertPanel(station)}
                            <HabilitationRequired habilitation={H_MAT_MODULE}>
                                <MaterielAssigned stationId={this.props.id} type={this.props.stationTypes[0]} />
                            </HabilitationRequired>
                            <LastEventPanel
                                id={this.props.id}
                                events={this.props.stationEvents.concat(this.props.productionUnitEvents)}
                                stationId={this.props.id}
                                stationType={this.props.stationTypes[0]}
                            />
                            {campaignByStationCard}
                            {indicatorsByStation}
                            {mapPanel}
                            {picturesFetched ? (
                                !!pictures?.length && <SmallPicturePanel element={'station'} pictures={pictures} />
                            ) : (
                                <Card round className='row text-align-center margin-bottom-1 padding-1' noMargin={false}>
                                    <ProgressBar indeterminate withMessage message={i18n.picturesLoading} />
                                </Card>
                            )}
                            {filesFetched ? (
                                !!files?.length && <FilePanel code={station.code} files={files} hideTitle onShowMore={this.onShowMore} />
                            ) : (
                                <Card round className='row text-align-center margin-bottom-1 padding-1' noMargin={false}>
                                    <ProgressBar indeterminate withMessage message={i18n.filesLoading} />
                                </Card>
                            )}
                            <div className='margin-top-1'>
                                {panels.linkedStations}
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        )
    }


    render() {
        const station = getStation(this.props, this.props.stationTypes[0])
        if (station.id) {
            return (
                <div className='row no-margin padding-left-2'>
                    {this.getFilePanel()}
                </div>
            )
        }
        return null
    }
}

StationDashboardApp.propTypes = getPropTypes(propsToFetch, {
    id: PropTypes.string.isRequired,
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    urlStationTypes: PropTypes.arrayOf(PropTypes.string),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    piezometers: arrayOf(DtoPiezometerLight),
    productionUnit: PropTypes.instanceOf(DtoProductionUnit),
    distributionUnit: PropTypes.instanceOf(DtoProductionUnit),
    qualitometer: PropTypes.instanceOf(DtoStation),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    qualityThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThresholds)),
    qualityIndicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityIndicators)),
    onReMount: PropTypes.func,
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    productionUnitEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitEvent)),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    piezometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    piezometryCampaignStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    resource: PropTypes.instanceOf(ResourceDto),
    fullScreen: PropTypes.bool,
    associatedSitesFetched: PropTypes.bool,
    filesFetched: PropTypes.bool,
    push: PropTypes.func,
    pluviometerStatistics: arrayOf(DtoPluviometerStats),
    piezometerStatistics: arrayOf(DtoMeasureStats),
    hydroStatistics: arrayOf(DtoHydroStats),
    hydrometryObservations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometryObservation)),
    installationTable: arrayOf(DtoInstallationTableLine),
    distributionUnitLink: PropTypes.string,
})

StationDashboardApp.defaultProps = {
    fullScreen: false,
}

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    productionUnit: store.ProductionUnitReducer.productionUnit,
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    qualitometer: store.QualityReducer.qualitometer,
    qualitometers: store.QualityReducer.qualitometersLight,
    piezometer: store.StationReducer.piezometer,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    pluviometers: store.PluviometryReducer.pluviometers,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometer: store.PluviometryReducer.pluviometer,
    pictures: store.StationReducer.pictures,
    files: store.StationReducer.files,
    units: store.UnitReducer.units,
    qualityThresholds: store.QualityReducer.qualityThresholds,
    qualityIndicators: store.QualityReducer.qualityIndicators,
    measures: store.PiezometerStationReducer.measures,
    piezometerThresholds: store.PiezometerStationReducer.piezometerThresholds,
    productionUnitEvents: store.ProductionUnitReducer.productionUnitEvents,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,
    piezometryCampaignStations: store.PiezometryReducer.piezometryCampaignStations,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    associatedSites: store.StationReducer.associatedSites,
    installationVisits: store.InstallationReducer.installationVisits,
    resource: store.ResourceReducer.resource,
    pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    hydroStatistics: store.HydrometryReducer.hydroStatistics,
    hydrometryObservations: store.HydrometryReducer.hydrometryObservations,
    installationTable: store.InstallationReducer.installationTable,
    industrialSiteTable: store.InstallationReducer.industrialSiteTable,
    boreholeTable: store.InstallationReducer.boreholeTable,
    STEPTable: store.InstallationReducer.STEPTable,
    tankTable: store.InstallationReducer.tankTable,
    distributionUnitLink: store.DistributionUnitReducer.distributionUnitLink,
})

const mapDispatchToProps = {
    push,
    forceFetch: SieauAction.forceFetch,
    fetchThresholds: QualityAction.fetchThresholds,
    fetchUnits: UnitAction.fetchUnits,
    setBackPath: HomeAction.setBackPath,
    fetch: SieauAction.fetch,
    fetchPiezometryCampaignsProgress: CampaignAction.fetchPiezometryCampaignsProgress,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    fetchHydrometryObservations: HydrometryAction.fetchHydrometryObservations,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationDashboardApp)
