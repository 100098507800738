

module.exports = {
    QUALITO_IMPORT_TYPE: {
        ADES_QUALITO: 'ADES_QUALITO',
        NAIADES: 'NAIADES',
        OROBNAT: 'OROBNAT',
        EMPTY: 'EMPTY',
    },
    JOB_TYPES: {
        ADES_QUALITO: 'wsAdesQualito',
        ADES_PIEZO: 'wsAdesPiezo',
        NAIADES: 'surfaceQualitometer',
        HYDRO: 'hydro',
        SYNOP: 'synop',
        OROBNAT: 'tapWater',
        ERA5: 'era5',
    },
}