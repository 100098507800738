import { difference, orderBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import SelectionTable from '../../../../../components/datatable/SelectionTable'
import Input from '../../../../../components/forms/Input'
import Select from '../../../../../components/forms/Select'
import DtoSelection from '../../../../../referencial/components/parameter/dto/DtoSelection'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import { substringText } from '../../../../../utils/StringUtil'


class CreateSelection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedParameters: [],
            parameters: props.parameters.map(({ code }) => code),
        }
    }

    deleteAll = () => {
        this.setState({
            selectedParameters: [],
            parameters: this.props.parameters.map(({ code }) => code),
        })
        this.props.onChange({ selection: { ...this.props.selection, parameters: [] } })
    }

    onAdd = ({ code: paramCode }) => {
        const parameters = this.state.parameters.filter(code => paramCode != code)
        const selectedParameters = [...this.state.selectedParameters, paramCode]
        this.setState({ parameters, selectedParameters })
        this.props.onChange({ selection: { ...this.props.selection, parameters: selectedParameters } })
    }

    onDelete = ({ code: paramCode }) => {
        const parameters = [...this.state.parameters, paramCode]
        const selectedParameters = this.state.selectedParameters.filter(code => code !== paramCode)
        this.setState({ parameters, selectedParameters })
        this.props.onChange({ selection: { ...this.props.selection, parameters: selectedParameters } })
    }

    openFile = e => {
        const reader = new FileReader()
        const [file] = e.target.files
        reader.onload = upload => {
            const split = upload.target.result.split(',')
            if (split[0].includes('text/csv') || split[0].includes('vnd.ms-excel')) {
                const buffer = Buffer.from(split[1], 'base64').toString()
                const parameterImported = buffer
                    .split('\n').map(line => line.split(';')[0])
                    .map(b => b.trim())
                    .map(code => code.replace(/(")/g, ''))

                const {
                    selectedParameters,
                    parameters,
                } = this.state
                const newParametersCode = uniq([...selectedParameters, ...parameterImported]).filter(param => !!this.props.parameters.find(({ code }) => param == code))
                const newListParameter = difference(parameters, parameterImported)

                this.setState({ selectedParameters: newParametersCode, parameters: newListParameter })
                this.props.onChange({ selection: { ...this.props.selection, parameters: newParametersCode } })
            } else {
                this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat)
            }
        }
        reader.readAsDataURL(file)
    }

    format = listParam => listParam.map(paramCode => {
        const { name = '' } = this.props.parameters.find(({ code }) => code === paramCode) || {}
        return {
            code: paramCode,
            name: substringText(name, 70),
        }
    })

    render = () => {
        const {
            selectedParameters,
            parameters,
        } = this.state
        const {
            selection,
            selections,
            onChange,
        } = this.props

        return (
            <>
                <div className='row no-margin padding-top-1'>
                    <Input
                        col={2}
                        title={i18n.code}
                        value={selection.code}
                        onChange={v => onChange({ selection: { ...selection, code: v } })}
                    />
                    <Input
                        col={5}
                        title={i18n.name}
                        value={selection.name}
                        onChange={v => onChange({ selection: { ...selection, name: v } })}
                    />
                    <Select
                        col={5}
                        label={i18n.parentSelection}
                        value={selection.parentCode}
                        options={selections}
                        onChange={v => onChange({ selection: { ...selection, parentCode: v } })}
                    />
                </div>

                <SelectionTable
                    maxHeight='50vh'

                    listData={orderBy(this.format(parameters), 'code')}
                    listHeaders={['code', 'name']}
                    listTitle={i18n.parameters}

                    selectedData={this.format(selectedParameters)}
                    selectedHeaders={['code', 'name']}
                    selectedTitle={i18n.selectedParameters}
                    selectedActions={[{
                        component: (
                            <label>
                                <input
                                    type='file'
                                    value=''
                                    accept='.csv, .CSV'
                                    onChange={this.openFile}
                                    style={{ display: 'None' }}
                                />
                                <i
                                    className={'material-icons clickable'}
                                >
                                    file_upload
                                </i>
                            </label>
                        ),
                    }]}

                    onAdd={this.onAdd}
                    onDelete={this.onDelete}
                    deleteAll={this.deleteAll}
                />
            </>
        )
    }
}

CreateSelection.propTypes = {
    selection: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        parentCode: PropTypes.string,
    }),
    onChange: PropTypes.func,
    selections: PropTypes.arrayOf(PropTypes.instanceOf(DtoSelection)),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),

    toastError: PropTypes.func,
}

const mapStateToProps = store => ({
    selections: store.ParameterReducer.selectionsWithParam,
    parameters: store.ParameterReducer.parameters,
})

const mapDispatchToProps = {
    toastError: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSelection)
