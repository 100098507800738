import { Button, Card, Dialog, Grid, Icon as IconMUI } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Icon from 'components/icon/Icon'
import { FileIconMUI } from 'components/styled/Buttons'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { push } from 'connected-react-router'
import { isNil } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import MultiContactsAutocomplete from 'referencial/components/contact/components/MultiContactsAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { disabledColor, mainWhite } from 'utils/constants/ColorTheme'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import { hasValue } from 'utils/NumberUtil'
import WaitAction from 'wait/WaitAction'
import Checkbox from '../../../../../components/forms/Checkbox'
import Input from '../../../../../components/forms/Input'
import HomeAction from '../../../../../home/actions/HomeAction'
import AdministrationAction from '../../../../actions/AdministrationAction'
import UserAction from '../../actions/UserAction'
import { KEY_ADMIN, KEY_CONSULTANT, KEY_CONTACT_CODE, KEY_LABO, KEY_METADATA } from '../../constants/UserConstants'
import UserDto from '../../dto/UserDto'
import AddUserModal from '../AddUserModal'
import UserHomePageModal from '../UserHomePageModal'
import UserPasswordPanel from '../UserPasswordPanel'
import { getSetting } from 'utils/SettingUtils'
import { usedUrl } from 'conf/basepath'

const space = '10px'

const LinkIcon = ({
    onClick = () => {},
    disabled = false,
    children,
}) => {
    return (
        <i
            className='material-icons small right'
            onClick={disabled ? undefined : onClick}
            style={{
                marginTop: '1rem',
                cursor: `${disabled ? 'default' : 'pointer'}`,
                color: `${disabled ? disabledColor : 'black'}`,
            }}
        >
            {children}
        </i>
    )
}

LinkIcon.propTypes = {
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.element,
}

const UserDetailForm = ({
    userLogin = '',
}) => {
    const {
        userProps,
        accountUser,
        contacts,
        contributors,
        profilePicture,
        profileLoginPicture,
        applicativeUsers,
        applicationSettings,
    } = useSelector(store => ({
        userProps: store.UserReducer.user,
        accountUser: store.AccountReducer.accountUser,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        profilePicture: store.AdministrationReducer.profilePicture,
        profileLoginPicture: store.AdministrationReducer.profileLoginPicture,
        applicativeUsers: store.UserReducer.applicativeUsers,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    const [user, setUser] = useState(new UserDto({}))
    const [password, setPassword] = useState('')
    const [passwordAgain, setPasswordAgain] = useState('')
    const [profilePictureToUpdate, setProfilePictureToUpdate] = useState({})
    const [pictureUrl, setPictureUrl] = useState('')
    const [isDuplicatePopinOpen, setIsDuplicatePopinOpen] = useState(false)
    const [dontSendMail, setDontSendMail] = useState(false)
    const [openHomeScreen, setOpenHomeScreen] = useState(false)
    const { value: isEditMode, setTrue: setIsEditMode, setFalse: setReadMode } = useBoolean(false)
    const [openUpdatePassword, setOpenUpdatePassword] = useState(false)
    const [openUpdateContact, setOpenUpdateContact] = useState(false)
    const [openInitPassword, setOpenInitPassword] = useState(false)
    const [urlPassword, setUrlPassword] = useState(usedUrl.endsWith('/') ? usedUrl.slice(0, -1) : usedUrl)
    const [digitVersion, setDigitVersion] = useState(false)

    const dispatch = useDispatch()
    const isValidEmail = email => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)

    const saveUser = (userToSave) => {
        const userToUpdate = hasValue(userToSave.contactCode) ? userToSave : { ...userToSave, contactCode: userProps.contactCode }
        dispatch(UserAction.updateUser(userToUpdate)).then(() => {
            dispatch(AdministrationAction.fetchPublicPicture(userToSave.login))
            dispatch(UserAction.fetchAllUsers())
            setReadMode()
        })
    }

    const checkUser = (userToCheck = user) => {
        if (!user.contactName) {
            dispatch(ToastrAction.error(i18n.thanksToEnterName))
        } else if (!user.contactEmail) {
            dispatch(ToastrAction.warning(i18n.thanksToEnterEmail))
        } else if (!isValidEmail(user.contactEmail)) {
            dispatch(ToastrAction.error(i18n.invalidEmail))
        } else {
            saveUser(userToCheck)
        }
    }

    const initPictureUrl = () => {
        const profilePictureToCheck = userLogin === accountUser.login ? profileLoginPicture : profilePicture
        if (profilePictureToUpdate && profilePictureToUpdate.content) {
            setPictureUrl(profilePictureToUpdate.content)
        } else if (profilePictureToCheck.name && userLogin) {
            const extension = profilePictureToCheck.name.substring(profilePictureToCheck.name.lastIndexOf('.'), profilePictureToCheck.name.length)
            if (extension.length > 0) {
                const name = `${userLogin}${extension}`
                dispatch(AdministrationAction.fetchPictureWithoutCache(name)).then(url => {
                    setPictureUrl(url)
                })
            }
        }
    }

    useEffect(() => {
        if (!applicativeUsers.length) {
            dispatch(UserAction.fetchApplicativeUsers())
        }
        dispatch(AdministrationAction.fetchProfilePicture(userLogin))
    }, [])

    useEffect(() => {
        initPictureUrl()
    }, [profilePicture.updateDate, profilePictureToUpdate, userLogin, profileLoginPicture.updateDate])

    const initUserContact = () => {
        const contact = contacts.find(c => c?.id == userProps?.contactCode)
        if (contact) {
            setUser({
                ...userProps,
                contactEmail: contact.email,
                contactName: contact.name,
                contactMobile: contact.mobile,
                contactTel: contact.phoneTel,
            })
        } else {
            setUser(userProps)
        }
    }

    useEffect(() => {
        initUserContact()
    }, [contacts, userProps])

    const contactsFormatted = useMemo(() => contacts.filter(c => c.title || c.name).map(c => ({ ...c, nameFormatted: `${c.title ? c.title : ''}${(c.title && c.name) ? ' - ' : ''}${c.name ? c.name : ''}` })), [contacts])

    const onChangeContact = (contactCode) => {
        const contact = contacts.find(c => c.id === contactCode)
        setUser({
            ...user,
            contactCode,
        })
        if (!isNil(contact)) {
            setOpenUpdateContact(true)
        }
    }

    const saveFile = () => {
        if (profilePictureToUpdate.content) {
            dispatch(AdministrationAction.addProfilePicture(profilePictureToUpdate)).then(() => {
                dispatch(AdministrationAction.fetchProfilePicture(userLogin))
            })
        }
    }

    const isConsultant = accountUser.consultant === '1'

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        const actions = {
            save: () => {
                saveFile()
                checkUser()
            },
            delete: () => {
                dispatch(UserAction.deleteUser(userLogin)).then(() => {
                    dispatch(UserAction.fetchAllUsers()).then(() => {
                        dispatch(push('/administration/user/'))
                    })
                })
            },
        }
        if (isEditMode) {
            return {
                ...actions,
                duplicate: () => setIsDuplicatePopinOpen(true),
                cancel: () => {
                    setReadMode()
                    initUserContact()
                },
            }
        }
        return {
            duplicate: () => setIsDuplicatePopinOpen(true),
            edit: () => setIsEditMode(),
        }
    }, [isConsultant, userProps, user, isEditMode, profilePictureToUpdate])

    const getUserContactObj = (event) => {
        const contact = contacts.find(c => c.id === event) || {}
        return {
            ...user,
            [KEY_CONTACT_CODE]: contact.id,
            contactName: contact.name,
            contactEmail: contact.email,
            contactTel: contact.phoneTel,
            contactMobile: contact.mobile,
        }
    }

    const onChangeUser = (attr, event, updateAction = false) => {
        const newUser = attr === KEY_CONTACT_CODE ? getUserContactObj(user, event) : {
            ...user,
            [attr]: event && event.target ? event.target.value : event,
        }

        if (attr === KEY_ADMIN || attr === KEY_METADATA || attr === KEY_LABO || attr === KEY_CONSULTANT) {
            if ((attr === KEY_ADMIN || attr === KEY_METADATA || attr === KEY_LABO) && event == '1') {
                newUser.consultant = '0'
            } else if (attr === KEY_CONSULTANT && event == '1') {
                newUser.isAdmin = '0'
                newUser.labo = '0'
            }
        }
        if (updateAction) {
            checkUser(newUser)
        }
        setUser(newUser)
    }

    const pictureComponent = useMemo(() => {
        if (pictureUrl?.length > 0) {
            return (
                <img
                    src={pictureUrl}
                    className='circle row hugeProfilePicture'
                    style={{ width: '13rem', objectFit: 'cover', marginBottom: 0, height: '13rem' }}
                />
            )
        }
        return (
            <i className='material-icons hugeProfilePicture responsive-img row' style={{ fontSize: '13rem', marginBottom: 0, height: '13rem' }}>account_circle</i>
        )
    }, [pictureUrl])

    const getCheckValue = e => e ? '1' : ''

    const disable = () => onChangeUser('endDate', moment().subtract(1, 'days').valueOf(), true)

    const resetPassword = () => {
        dispatch(HomeAction.resetPassword(user.login, false, urlPassword, digitVersion))
        setOpenInitPassword(false)
    }

    const launchUpdatePassword = () => {
        const regex = new RegExp(getSetting(applicationSettings, 'passwordPolicy'))
        if (password !== passwordAgain) {
            dispatch(ToastrAction.error(i18n.confirmPasswordError))
        } else if (!password) {
            dispatch(ToastrAction.error(i18n.invalidPassword))
        } else if (!regex.test(password)) {
            dispatch(ToastrAction.error(i18n.passwordPolicyError))
        } else {
            const newMdpEncoding = btoa(unescape(encodeURIComponent(password)))
            const newPassword = { password: newMdpEncoding }
            dispatch(HomeAction.changePasswordByAdmin(user.login, newPassword))
            setOpenUpdatePassword(!openUpdatePassword)
        }
    }

    const onUploadFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            const name = `${userLogin}${file.name.substring(file.name.lastIndexOf('.'), file.name.length)}`
            reader.onload = (e) => setProfilePictureToUpdate({ name, content: e.target.result })
            reader.readAsDataURL(event.target.files[0])
        }
    }

    const copyClipboard = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
        dispatch(ToastrAction.success(i18n.copyToClipboardSucess))
    }

    const onCloseDuplicatePopin = () => setIsDuplicatePopinOpen(false)

    const onDuplicate = (duplicateUser) => {
        dispatch(WaitAction.waitStart())
        dispatch(UserAction.duplicateUser(userLogin, duplicateUser, dontSendMail)).then((err) => {
            if (!err) {
                onCloseDuplicatePopin()
                dispatch(UserAction.fetchAllUsers()).then(() => {
                    dispatch(WaitAction.waitStop())
                    dispatch(push(`/administration/user/${duplicateUser.login}`))
                })
            }
        })
    }

    const tokenApplicativeUser = useMemo(() => applicativeUsers.find(a => a.login === user.login)?.token, [applicativeUsers, user.login])

    return (
        <>
            {user.connexionAttempts >= 5 && !user.isApplicative && (
                <Card
                    className='margin-bottom-1'
                    sx={{
                        borderRadius: '4px',
                        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
                        padding: '18px',
                        backgroundColor: 'red',
                    }}
                >
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item xs='auto' container alignItems='center'>
                            <Icon className='margin-right-2' style={{ color: 'white', fontSize: 35 }}>lock</Icon><span style={{ color: 'white', fontSize: 30 }}>{i18n.blockedAccount}</span>
                        </Grid>
                        <Grid item xs='auto'>
                            <Button
                                variant='contained'
                                startIcon={(<IconMUI>mail_lock</IconMUI>)}
                                onClick={() => setOpenInitPassword(true)}
                                disabled={!isEditMode}
                            >
                                {i18n.unlock}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            )}
            <Card
                sx={{
                    borderRadius: '4px',
                    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
                    padding: '18px',
                    backgroundColor: mainWhite,
                }}
            >
                <StyledFieldSet style={{ margin: '0px' }}>
                    <StyledLegend style={{ marginBottom: space }}>
                        {i18n.personalInformation}
                    </StyledLegend>
                    <Grid container>
                        <Grid container item xs={9} columnSpacing={1}>
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.name}
                                    value={user.contactName}
                                    onChange={value => onChangeUser('contactName', value)}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.email}
                                    value={user.contactEmail}
                                    onChange={value => onChangeUser('contactEmail', value)}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.tel}
                                    value={user.contactTel}
                                    onChange={value => onChangeUser('contactTel', value)}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.mobile}
                                    value={user.contactMobile}
                                    onChange={value => onChangeUser('contactMobile', value)}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                            <Grid container item xs={6} justifyContent={'space-between'}>
                                <Grid item xs={11}>
                                    <MultiContactsAutocomplete
                                        label={i18n.contact}
                                        options={contactsFormatted}
                                        keyValue='code'
                                        keyLabel='nameFormatted'
                                        onChange={onChangeContact}
                                        values={user?.contactCode}
                                        disabled={!isEditMode}
                                        additionalKeysFilter={[ 'title' ]}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {user.contactCode ? (
                                        <LinkIcon
                                            onClick={() => dispatch(push(`/referencial/contact/${user.contactCode}`))}
                                            disabled={!isEditMode}
                                        >
                                            launch
                                        </LinkIcon>
                                    ) : (
                                        <LinkIcon
                                            onClick={() => dispatch(push('/referencial/contact/new'))}
                                            disabled={!isEditMode}
                                        >
                                            add_box
                                        </LinkIcon>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} justifyContent={'space-between'}>
                                <Grid item xs={11}>
                                    <MultiContributorsAutocomplete
                                        options={contributors}
                                        values={(userLogin === user?.login) && user?.contributorCode}
                                        onChange={(contributor) => onChangeUser('contributorCode', contributor)}
                                        disabled={!isEditMode}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {user.contributorCode ? (
                                        <LinkIcon
                                            onClick={() => dispatch(push(`/referencial/contributor/${user.contributorCode}`))}
                                            disabled={!isEditMode}
                                        >
                                            launch
                                        </LinkIcon>
                                    ) : (
                                        <LinkIcon
                                            onClick={() => dispatch(push('/referencial/contributor/new'))}
                                            disabled={!isEditMode}
                                        >
                                            add_box
                                        </LinkIcon>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <SimpleDatePicker
                                    value={user.startDate}
                                    id='startDate'
                                    label={i18n.beginDate}
                                    onChange={(v) => onChangeUser('startDate', v)}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SimpleDatePicker
                                    value={user.endDate}
                                    id='endDate'
                                    label={i18n.endDate}
                                    min={user.startDate}
                                    onChange={(v) => onChangeUser('endDate', v)}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={3} sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {pictureComponent}
                            <FileIconMUI
                                style={{
                                    top: 10,
                                    right: '27%',
                                }}
                                tooltip={i18n.changePicture}
                                onChange={onUploadFile}
                                disabled={!isEditMode}
                            />
                        </Grid>
                        {user.isApplicative && tokenApplicativeUser && (
                            <Grid container item xs={12} alignItems='center'>
                                <Grid item xs={11.5}>
                                    <Input
                                        title={i18n.token}
                                        value={tokenApplicativeUser}
                                        disabled={{ disabled: true }}
                                    />
                                </Grid>
                                <Grid item xs={0.5}>
                                    <IconMUI
                                        style={{ margin: '0.7rem 0 0 1rem', cursor: 'pointer' }}
                                        onClick={() => copyClipboard(tokenApplicativeUser)}
                                        disabled={!isEditMode}
                                    >
                                        content_copy
                                    </IconMUI>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </StyledFieldSet>
                <StyledFieldSet style={{ marginTop: space }}>
                    <StyledLegend
                        style={{ marginTop: space }}
                    >
                        {i18n.roles}
                    </StyledLegend>
                    <Grid
                        container
                        spacing={3}
                        sx={{
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        }}
                    >
                        <Grid item>
                            <Checkbox
                                label={i18n.admin}
                                checked={user.isAdmin === '1'}
                                onChange={(e) => onChangeUser(
                                    KEY_ADMIN,
                                    getCheckValue(e),
                                )}
                                disabled={!isEditMode}
                            />
                        </Grid>
                        <Grid item>
                            <Checkbox
                                label={i18n.administrator}
                                checked={user.metadata === '1'}
                                onChange={(e) => onChangeUser(
                                    KEY_METADATA,
                                    getCheckValue(e),
                                )}
                                disabled={!isEditMode}
                            />
                        </Grid>
                        <Grid item>
                            <Checkbox
                                label={i18n.technicien}
                                checked={user.labo === '1'}
                                onChange={(e) => onChangeUser(
                                    KEY_LABO,
                                    getCheckValue(e),
                                )}
                                disabled={!isEditMode}
                            />
                        </Grid>
                        <Grid item>
                            <Checkbox
                                label={i18n.consultant}
                                checked={user.consultant === '1'}
                                onChange={(e) => onChangeUser(
                                    KEY_CONSULTANT,
                                    getCheckValue(e),
                                )}
                                disabled={!isEditMode}
                            />
                        </Grid>
                        <Grid item>
                            <Checkbox
                                label={i18n.usersApplication}
                                checked={user.isApplicative}
                                onChange={(e) => onChangeUser(
                                    'isApplicative',
                                    e,
                                )}
                                disabled={!isEditMode}
                            />
                        </Grid>
                        {userProps.connexionAttempts >= 5 && (
                            <Grid item>
                                <i
                                    className='material-icons small blue-text center-align tooltipped'
                                    data-tooltip={i18n.blockedUser}
                                >
                                    lock
                                </i>
                            </Grid>
                        )}
                    </Grid>
                </StyledFieldSet>
                <StyledFieldSet style={{ paddingBottom: '1.4em', marginTop: space }}>
                    <StyledLegend
                        style={{ marginTop: space }}
                    >
                        {i18n.actions}
                    </StyledLegend>
                    <Grid container>
                        <Grid
                            container
                            item
                            xs={9}
                            alignItems='center'
                            column={3}
                            spacing={1}
                        >
                            <Grid item xs={4}>
                                <Button
                                    style={{ width: '100%' }}
                                    variant='outlined'
                                    startIcon={(<IconMUI>vpn_key</IconMUI>)}
                                    onClick={() => setOpenUpdatePassword(!openUpdatePassword)}
                                    disabled={!isEditMode}
                                >
                                    {i18n.initializePassword}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    style={{ width: '100%' }}
                                    variant='outlined'
                                    startIcon={(<IconMUI>mail_lock</IconMUI>)}
                                    onClick={() => setOpenInitPassword(true)}
                                    disabled={!isEditMode}
                                >
                                    {i18n.returnLinkPassword}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    style={{ width: '100%' }}
                                    variant='outlined'
                                    startIcon={(<IconMUI>room_preferences</IconMUI>)}
                                    onClick={() => setOpenHomeScreen(true)}
                                    disabled={!isEditMode}
                                >
                                    {i18n.setHomepage}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item xs={3}
                            justifyContent='flex-end'
                            alignItems='center'
                            className='padding-left-1'
                        >
                            <Button
                                variant='contained'
                                startIcon={(<IconMUI>block</IconMUI>)}
                                onClick={disable}
                                disabled={!isEditMode}
                            >
                                {`${i18n.disable} ${i18n.user}`}
                            </Button>
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                <Dialog
                    onClose={() => setOpenInitPassword(false)}
                    fullWidth
                    maxWidth='md'
                    open={openInitPassword}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.returnLinkPassword}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpenInitPassword(false)} />
                    </DialogTitleMUI>
                    <DialogContentMUI className='padding-top-1 padding-bottom-1'>
                        <Input
                            title={i18n.urlInstance}
                            value={urlPassword}
                            onChange={setUrlPassword}
                            disabled={digitVersion}
                        />
                        <Checkbox
                            label={i18n.versionWithCode}
                            checked={digitVersion}
                            onChange={(e) => setDigitVersion(e)}
                        />
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button onClick={resetPassword} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </Dialog>
                <Dialog
                    onClose={() => setOpenUpdatePassword(false)}
                    fullWidth
                    maxWidth='lg'
                    open={openUpdatePassword}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.initializePassword}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpenUpdatePassword(false)} />
                    </DialogTitleMUI>
                    <DialogContentMUI className='padding-top-1 padding-bottom-0'>
                        <UserPasswordPanel
                            changePassword={(v) => setPassword(v)}
                            changePasswordAgain={(v) => setPasswordAgain(v)}
                        />
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button onClick={launchUpdatePassword} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </Dialog>
                <AddUserModal
                    addUser={onDuplicate}
                    isDuplicate
                    dontSendMail={dontSendMail}
                    onChangeSendMail={(v) => setDontSendMail(v)}
                    isOpen={isDuplicatePopinOpen}
                    onClose={() => onCloseDuplicatePopin()}
                />
                <UserHomePageModal
                    login={user?.login}
                    open={openHomeScreen}
                    setOpen={v => setOpenHomeScreen(v)}
                />
                <Dialog
                    onClose={() => setOpenUpdateContact(false)}
                    fullWidth
                    maxWidth='lg'
                    open={openUpdateContact}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.updateContributor}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpenUpdateContact(false)} />
                    </DialogTitleMUI>
                    <DialogContentMUI className='padding-top-2'>
                        <h4 style={{ textAlign: 'center' }}>{i18n.doYouWantUpdateContactWithForm}</h4>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        const contact = contacts.find(c => c.id === user.contactCode)
                                        setUser({
                                            ...user,
                                            contactName: contact?.name,
                                            contactEmail: contact?.email,
                                        })
                                        setOpenUpdateContact(false)
                                    }}
                                    variant='outlined'
                                    color='primary'
                                >
                                    {i18n.no}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        const contact = contacts.find(c => c.id === user.contactCode)
                                        dispatch(ContactAction.updateContact({ ...contact, name: user.contactName, email: user.contactEmail }))
                                        setOpenUpdateContact(false)
                                    }}
                                    variant='contained'
                                    color='primary'
                                >
                                    {i18n.yes}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActionsMUI>
                </Dialog>
            </Card>
        </>

    )
}

UserDetailForm.propTypes = {
    userLogin: PropTypes.string,
}

export default UserDetailForm
