/* eslint-disable camelcase */
/* eslint-disable indent */
import { Button, Card, Dialog, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import { orderBy } from 'lodash'
import DtoVariousMaterielSituation from 'materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoSamplingPointDecla from 'survey/dto/DtoSamplingPointDecla'
import ToastrAction from 'toastr/actions/ToastrAction'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { getDate } from 'utils/DateUtil'
import { formatMilliers, getSandreLabel } from 'utils/StringUtil'

const styles = () => ({
    card: {
        padding: '8px',
        height: '100%',
    },
    selected: {
        padding: '8px',
        height: '100%',
        backgroundColor: `${secondaryBlue} !important`,
        color: 'white !important',
    },
    item: {
        padding: '4px !important',
    },
})

const headersEstim = ['nullValue2', 'startDate', 'endDate', 'estimateM3']
const headersIndex = ['nullValue2', 'statementDate', 'nullValue', 'index']

const STEP_MATERIEL = 0
const STEP_TYPE = 1
const STEP_CHRONICLE = 2

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

class ModalIndex extends Component {
    constructor(props) {
        super(props)
        const pointId = props.point.idInstallation
        const link_equipments = props.declaration.link_declarationInstallation.find((p) => p.idInstallation === pointId).link_equipments || []
        const linkAssignments = link_equipments.filter((linkE) => linkE.assignmentRate).map((linkE) => {
            return {
                idMat: linkE.idMat,
                assignmentRate: linkE.assignmentRate,
            }
        })
        const allMaterialsIds = [...(props.pumpsId || []), ...(props.countersId || [])]
        this.state = {
            link_equipments,
            linkAssignments,
            link_chronicles: props.declaration.link_chronicles || [],
            link_pumps_maj: props.declaration.link_pumps || [],
            link_counters_maj: props.declaration.link_counters || [],
            selectedMaterial: allMaterialsIds === 1 ? allMaterialsIds[0] : props.selectedMaterial,
            consoType: props.consoType,
            step: props.selectedMaterial ? STEP_CHRONICLE : STEP_MATERIEL,
            errors: [],
            measureDate: null,
            endDate: null,
            value: null,
        }
    }

    componentDidMount() {
        const { link_equipments } = this.state
        const linkAssignments = link_equipments.filter((linkE) => linkE.assignmentRate).map((linkE) => {
            return {
                idMat: linkE.idMat,
                assignmentRate: linkE.assignmentRate,
            }
        })
        this.setState({ linkAssignments })
    }

    previousStep = () => {
        const { step } = this.state
        this.setState({ step: step - 1 })
    }

    nextStep = () => {
        const { step } = this.state
        if (this.checkStep(step)) {
            this.setState({ step: step + 1 })
        }
    }

    checkStep = (step) => {
        const { consoType, selectedMaterial, measureDate, endDate, value } = this.state
        const newErrors = []
        switch (step) {
            case STEP_MATERIEL:
                if (!selectedMaterial) {
                    this.props.error('Veuillez sélectionner un équipement')
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            case STEP_TYPE:
                if (consoType === TYPE_ESTIM) {
                    this.props.warning('Attention, estimations de m3 à saisir sur les matériels EP-XXXX (pompes) uniquement svp. \nSaisies sur les matériels DC-XXXX (compteurs) ne concernant que les relevés d\'index des compteurs d\'eau (volumétriques).')
                }
                this.setState({ errors: newErrors })
                return true
            case STEP_CHRONICLE:
                if (!measureDate) {
                    newErrors.push('measureDate')
                }
                if (isNaN(parseInt(value))) {
                    newErrors.push('value')
                }
                if (consoType === 1 && !endDate) {
                    newErrors.push('endDate')
                }
                if (newErrors.length) {
                    this.setState({ errors: newErrors })
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            default:
                this.setState({ errors: newErrors })
                return true
        }
    }

    setSelectedMat = (materiel) => {
        this.setState({
            selectedMaterial: materiel.id,
            consoType: materiel.materielType === 10 ? TYPE_INDEX : TYPE_ESTIM,
            measureDate: null,
            endDate: null,
            value: null,
        })
    }

    getPumpCard = (pompeInfos) => {
        const { sandreCodes, classes, variousMaterielTypes } = this.props
        const { linkAssignments, selectedMaterial } = this.state
        const pumpNature = variousMaterielTypes.find((t) => t.materielType === pompeInfos.materielType) || {}
        const pumpCategory = getSandreLabel(sandreCodes, 'MAT.MOBILITE', pompeInfos.mobilityCode)
        const pumpType = getSandreLabel(sandreCodes, 'POMPES.TYPE', pompeInfos.pumpType)
        const link = linkAssignments.find((linkA) => linkA.idMat === pompeInfos.id) || {}
        return (
            <Grid item xs={3} className='clickable' onClick={() => this.setSelectedMat(pompeInfos)}>
                <Card className={selectedMaterial === pompeInfos.id ? classes.selected : classes.card}>
                    <Grid container>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <span className='bold'>{i18n.id} : {pompeInfos.reference || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.nature} : </span><span>{pumpNature ? pumpNature.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.category} : </span><span>{pumpCategory}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.type} : </span><span>{pumpType}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.maxFlow} : </span><span>{pompeInfos.maxFlow ? `${pompeInfos.maxFlow} m3/h` : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.inUseFlow} : </span><span>{pompeInfos.operatingFlow ? `${pompeInfos.operatingFlow} m3/h` : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.assignmentRate} : </span><span>{link.assignmentRate ? `${link.assignmentRate} %` : ''}</span>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getPumps = () => {
        const {
            variousMateriels,
            declaration,
            pumpsId,
        } = this.props
        const { link_equipments, link_pumps_maj } = this.state
        return pumpsId.map((pumpId) => {
            const updatedMat = link_equipments.find((mLink) => mLink.idMat === pumpId && mLink.mode !== 'd')
            const pompeSituation = declaration.link_exploitationMateriel.find((m) => m.siteType === 8 && m.siteCode === pumpId)
            const pompeMat = variousMateriels.find((m) => m.id === pumpId)
            const pompe = pompeMat.pump || {}
            const pompeUpdated = link_pumps_maj.find((p) => p.id === pompeMat.id)
            const pompeInfos = {
                ...pompeSituation,
                ...pompeMat,
                ...pompe,
                ...pompeUpdated,
                assignmentRate: updatedMat ? updatedMat.assignmentRate : null,
            }
            return this.getPumpCard(pompeInfos)
        })
    }

    getCounterCard = (compteurInfos) => {
        const { classes, variousMaterielTypes } = this.props
        const { selectedMaterial } = this.state
        const counterType = variousMaterielTypes.find((t) => t.materielType === compteurInfos.materielType)
        return (
            <Grid item xs={3} className='clickable' onClick={() => this.setSelectedMat(compteurInfos)}>
                <Card className={selectedMaterial === compteurInfos.id ? classes.selected : classes.card}>
                    <Grid container>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <span className='bold'>{i18n.id} : {compteurInfos.reference || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.type} : </span><span>{counterType ? counterType.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.readingCoefficient} : </span><span>{compteurInfos.readingCoefficient || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.brand} : </span><span>{compteurInfos.brand || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.installationDate} : </span><span>{getDate(compteurInfos.installationDate) || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.revisionDate} : </span><span>{getDate(compteurInfos.revisionDate) || ''}</span>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getCounters = () => {
        const {
            variousMateriels,
            countersId,
        } = this.props
        const { link_counters_maj } = this.state
        return countersId.map((counterId) => {
            const counterMat = variousMateriels.find((c) => c.id === counterId) || {}
            const compteurInfos = {
                ...counterMat,
                ...(counterMat.counter || {}),
                ...link_counters_maj.find((p) => p.id === counterId),
            }
            return this.getCounterCard(compteurInfos)
        })
    }

    handleChange = (key, value) => {
        if (key === 'consoType') {
            this.setState({ measureDate: null, endDate: null, value: null })
        }
        this.setState({ [key]: value })
    }

    getChronicles = () => {
        const { link_chronicles, consoType, selectedMaterial } = this.state
        const { variousMateriels } = this.props
        const filteredChronicles = link_chronicles.filter((c) => c.idMat === selectedMaterial && c.mode !== 'd' && c.measureType === consoType)
        const data = orderBy(filteredChronicles, ['measureDate', 'endDate'], 'desc').map((chronicle) => {
            const values = consoType === 1 ? {
                startDate: {
                    value: getDate(chronicle.measureDate),
                },
                endDate: {
                    value: getDate(chronicle.endDate),
                },
                estimateM3: {
                    value: `${formatMilliers(chronicle.value) || 0} m3`,
                },
            } : {
                statementDate: {
                    value: getDate(chronicle.measureDate),
                },
                nullValue: {
                    value: '',
                },
                index: {
                    value: formatMilliers(chronicle.value) || 0,
                },
            }
            return {
                nullValue2: {
                    value: <Icon className='clickable' style={{ color: 'red' }} onClick={() => this.removeChronicle(chronicle.idChronicle)}>delete</Icon>,
                },
                ...values,
            }
        })
        const headers = consoType === 1 ? headersEstim : headersIndex
        const dataWithHeader = data.length > 0 ? [{ ...data[0], headers }, ...data.slice(1)] : []
        const reference = (variousMateriels.find((mat) => mat.id === selectedMaterial) || {}).reference
        return (
            <Table
                title={i18n.consos}
                data={dataWithHeader}
                paging
                nbPerPageLabel={nbPerPageLabelTiny}
                type={{ headers }}
                exportButtonOnHeader
                exportName={`${i18n.consos}_${reference}`}
                condensed
                sortable
                initialSort
                color
            />
        )
    }

    removeChronicle = (idChronicle) => {
        const { link_chronicles } = this.state
        const newChronicles = [
            ...link_chronicles.filter((c) => c.idChronicle !== idChronicle),
            {
                ...link_chronicles.find((c) => c.idChronicle === idChronicle),
                mode: 'd',
            },
        ]
        this.setState({ link_chronicles: newChronicles })
    }

    addChronicle = () => {
        const {
            measureDate,
            endDate,
            value,
            link_chronicles,
            selectedMaterial,
            consoType,
        } = this.state
        if (this.checkStep(STEP_CHRONICLE)) {
            const id = link_chronicles.length ? Math.max(...link_chronicles.map(c => c.idChronicle)) + 1 : 0
            this.setState({
                link_chronicles: [
                    ...link_chronicles,
                    {
                        matType: 'divers',
                        idMat: selectedMaterial,
                        measureType: consoType,
                        measureDate: new Date(measureDate).getTime(),
                        value: value || 0,
                        endDate: consoType === TYPE_ESTIM ? new Date(endDate).getTime() : null,
                        idChronicle: id,
                        new: true,
                    },
                ],
                measureDate: consoType === TYPE_ESTIM ? endDate : '',
                value: '',
                endDate: '',
            })
        }
    }

    getContent = (step) => {
        const { variousMateriels } = this.props
        const { consoType, measureDate, endDate, value, selectedMaterial } = this.state
        const matFind = variousMateriels.find((mat) => mat.id === selectedMaterial) || {}
        switch (step) {
            case STEP_MATERIEL:
                return (
                    <>
                        <h5>
                            {i18n.selectMaterial}
                        </h5>
                        <Grid container spacing={1} alignItems='stretch'>
                            {[...this.getPumps(), ...this.getCounters()]}
                        </Grid>
                    </>
                )
            case STEP_TYPE:
                return (
                    <Grid className='padding-top-1' container direction='column' spacing={1}>
                        {matFind.materielType !== 10 && (
                            <Grid item onClick={() => this.handleChange('consoType', TYPE_ESTIM)}>
                                <input className='sieau-input' type='radio' id='estimation' checked={consoType === TYPE_ESTIM} />
                                <label htmlFor='estimation' className='black-text'>{i18n.estimateEntryNoCounter}</label>
                            </Grid>
                        )}
                        <Grid item onClick={() => this.handleChange('consoType', TYPE_INDEX)}>
                            <input className='sieau-input' type='radio' id='index' checked={consoType === TYPE_INDEX} />
                            <label htmlFor='index' className='black-text'>{i18n.indexEntryCounter}</label>
                        </Grid>
                    </Grid>
                )
            case STEP_CHRONICLE:
                return (
                    <>
                        {consoType === TYPE_INDEX && (
                            <Input
                                col={12}
                                title={i18n.readingCoefficient}
                                value={matFind?.counter?.readingCoefficient || ''}
                                readMode
                            />
                        )}
                        <Grid container spacing={1} justifyContent='space-between' alignItems='center' className='margin-top-1 margin-bottom-1'>
                            <Grid item container xs={9} spacing={1}>
                                <Grid item xs={4}>
                                    <SimpleDatePicker
                                        id='startDate'
                                        label={consoType === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                                        value={measureDate}
                                        max={endDate}
                                        calendarPosition='right'
                                        onChange={(v) => this.handleChange('measureDate', v)}
                                    />
                                </Grid>
                                {consoType === TYPE_ESTIM && (
                                    <Grid item xs={4}>
                                        <SimpleDatePicker
                                            id='endDate'
                                            label={i18n.endDate}
                                            min={measureDate}
                                            value={endDate}
                                            calendarPosition='right'
                                            onChange={(v) => this.handleChange('endDate', v)}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={4}>
                                    <NumberField
                                        id='value'
                                        title={consoType === TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                                        value={value}
                                        onChange={(v) => this.handleChange('value', parseInt(v))}
                                        min={0}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant='contained' color='primary' onClick={this.addChronicle} style={{ width: '100%' }}>
                                    {i18n.add}
                                </Button>
                            </Grid>
                        </Grid>
                        {this.getChronicles()}
                    </>
                )
            default:
                return ''
        }
    }

    formatListChronicles = (chronicles) => {
        return chronicles.map((c) => {
            if (c.new) {
                return {
                    ...c,
                    idChronicle: null,
                }
            }
            return c
        })
    }

    onSave = () => {
        const { link_chronicles } = this.state
        const formattedChronicles = this.formatListChronicles(link_chronicles)
        this.props.onSave(formattedChronicles)
    }

    onCancel = () => {
        const { declaration } = this.props
        this.setState({
            link_chronicles: declaration.link_chronicles,
            measureDate: '',
            value: '',
            endDate: '',
        })
        this.props.onCancel()
    }

    render() {
        const { open, variousMateriels } = this.props
        const { step, selectedMaterial } = this.state
        const reference = step === STEP_CHRONICLE ? ` : ${(variousMateriels.find((mat) => mat.id === selectedMaterial) || {}).reference}` : ''

        return (
            <Dialog
                fullWidth
                maxWidth='md'
                style={{ margin: 'auto' }}
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.consosEntry}{reference}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI className='padding-1'>
                    {this.getContent(step)}
                </DialogContentMUI>
                <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={this.onCancel}
                    >
                        {i18n.cancel}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={this.previousStep}
                        disabled={step === STEP_MATERIEL}
                    >
                        {i18n.previous}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={this.nextStep}
                        disabled={step === STEP_CHRONICLE}
                    >
                        {i18n.next}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={this.onSave}
                        disabled={step !== STEP_CHRONICLE}
                    >
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

ModalIndex.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.object),
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    pumpsId: PropTypes.arrayOf(PropTypes.number),
    countersId: PropTypes.arrayOf(PropTypes.number),
    selectedMaterial: PropTypes.number,
    consoType: PropTypes.number,
    error: PropTypes.func,
    warning: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

const mapDispatchToProps = {
    error: ToastrAction.error,
    warning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalIndex))
