export default class DtoQualityThreshold {
    constructor(obj) {
        this.parameterCode = obj.parameterCode
        this.unit = obj.unit
        this.threshold1 = obj.threshold1
        this.threshold2 = obj.threshold2
        this.threshold3 = obj.threshold3
        this.threshold4 = obj.threshold4
        this.thresholdType = obj.thresholdType
        this.listType = obj.listType
        this.stationType = obj.stationType
        this.frType = obj.frType
        this.elementCode = obj.elementCode
        this.seasonality = obj.seasonality
        this.fishCode = obj.fishCode
        this.calciumClass = obj.calciumClass
        this.ph = obj.ph
        this.station = obj.station
        this.isFromChild = obj.isFromChild
    }
}

