import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'components/datatable/Table'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { groupBy, isUndefined, orderBy, round, uniqBy } from 'lodash'
import { getYear } from 'utils/DateUtil'
import { getLabelTruncate } from 'utils/StoreUtils'
import { calculateAverage, calculateGeometricAverage, calculateRangeInterquartile, calculateStandardDeviation, filterDetection, filterQuantification, filterResult, filterValid, searchP90Value, searchMinValue, searchMaxValue } from 'utils/AnalyseUtils'
import { substringText } from 'utils/StringUtil'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useListIndexed from 'utils/customHook/useListIndexed'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'
import DtoQualityThresholds from 'quality/dto/QualityThreshold/DtoQualityThresholds'
import { useRegroupPiezoDatas } from '../hook/usePiezoDatas'
import { useRegroupHydroDatas } from '../hook/useHydroDatas'
import { useRegroupPluvioDatas } from '../hook/usePluvioDatas'
import { getModelFileType } from 'utils/ExportDataUtil'
import ExportAction from 'export/actions/ExportAction'
import PcMonitoringRegroupExportModal from '../export/PcMonitoringRegroupExportModal'
import { getAnalyseByGroup, getFluxByGroup } from 'quality/utils/PcMonitoringUtil'
import useBoolean from 'utils/customHook/useBoolean'
import { ParameterGraphModal } from 'quality/components/qualityComponents/ParameterGraph'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import moment from 'moment'
import { HYDRO, PIEZO, PLUVIO } from 'quality/constants/ChartConstant'

const GraphModal = ({
    analysis = [],
    thresholds,

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    horizon = 'year',
    regrouping = 'max',

    graphArgument = {},
    groupEquivalences = false,

    isGraphOpen = false,
    closeGraph = () => { },
}) => {
    const {
        settings,
        piezometers,
        piezometryDataTypes,
        hydrometricStations,
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const piezometersIndex = useListIndexed(piezometers, 'id')
    const hydrometricStationsIndex = useListIndexed(hydrometricStations, 'id')
    const pluviometersIndex = useListIndexed(pluviometers, 'id')

    const filteredAnalysis = useMemo(() => {
        const {
            parameter,
            units = [],
            supports = [],
            fractions = [],
        } = graphArgument
        if (!parameter) {
            return []
        }
        return analysis
            .filter(a => a.parameter === parameter)
            .filter(a => !units.length ? isUndefined(a.unit) : units.includes(a.unit))
            .filter(a => !supports.length ? isUndefined(a.support) : supports.includes(a.support))
            .filter(a => !fractions.length ? isUndefined(a.fraction) : fractions.includes(a.fraction))
    }, [analysis, graphArgument])

    const formattedAnalysis = useMemo(() => {
        const analysisGroupByYear = groupBy(filteredAnalysis, a => horizon === 'year' ? getYear(a.sampleDate) : 'history')
        return Object.keys(analysisGroupByYear).map(year => getAnalyseByGroup(analysisGroupByYear[year], regrouping, settings))
    }, [filteredAnalysis, horizon, regrouping, settings])

    const dataTypePiezo = useMemo(() => [
        { id: 'piezometryChronic', label: i18n.chronic },
        ...getHardPiezoDataTypes(),
        ...piezometryDataTypes,
    ], [piezometryDataTypes])

    const piezoDatasFormatted = useMemo(() => {
        return piezoDatas.map(piezoData => {
            const piezo = piezometersIndex[piezoData.id]
            if (!piezo) {
                return null
            }
            return {
                name: piezo.name,
                unit: dataTypePiezo.find(t => t.id === piezoData.type)?.label || '',
                showSymbol: false,
                dataType: PIEZO,
                dataList: piezoData.measures.map(m => ({
                    date: m.date,
                    value: m.NGF ?? m.value,
                })),
            }
        }).filter(p => p?.dataList.length)
    }, [dataTypePiezo, piezoDatas, piezometersIndex])

    const hydroDatasFormatted = useMemo(() => {
        return hydroDatas.map(hydroData => {
            const hydro = hydrometricStationsIndex[hydroData.id]
            if (!hydro) {
                return null
            }
            return {
                name: hydro.name,
                unit: hydroData.type,
                showSymbol: false,
                dataType: HYDRO,
                dataList: hydroData.measures,
            }
        }).filter(h => h?.dataList.length)
    }, [hydroDatas, hydrometricStationsIndex])

    const pluvioDatasFormatted = useMemo(() => {
        return pluvioDatas.map(pluvioData => {
            const pluvio = pluviometersIndex[pluvioData.id]
            if (!pluvio) {
                return null
            }
            return {
                name: pluvio.name,
                unit: pluviometryDataTypes.find(t => t.id === pluvioData.type)?.label || '',
                dataType: PLUVIO,
                dataList: pluvioData.measures.map(m => ({ value: m.value, date: moment(m.date).add(pluvioData.offset ?? 0, 'day').valueOf() })),
                type: 'bar',
            }
        }).filter(p => p?.dataList.length)
    }, [pluvioDatas, pluviometersIndex, pluviometryDataTypes])

    return (
        <ParameterGraphModal
            isOpen={isGraphOpen}
            closeGraph={closeGraph}
            analysis={formattedAnalysis}
            thresholds={thresholds}
            additionalData={[...piezoDatasFormatted, ...hydroDatasFormatted, ...pluvioDatasFormatted]}

            graphOptions={{
                regroupAxis: groupEquivalences,
            }}
        />
    )
}

GraphModal.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysisLight, ...calculateThresholdResult */ })),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    horizon: PropTypes.oneOf(['operations', 'year', 'historic']),
    regrouping: PropTypes.oneOf(['max', 'moyenne', 'percentile']),

    groupEquivalences: PropTypes.bool,
    graphArgument: PropTypes.shape({
        parameter: PropTypes.string,
        units: PropTypes.arrayOf(PropTypes.string),
        supports: PropTypes.arrayOf(PropTypes.number),
        fractions: PropTypes.arrayOf(PropTypes.string),
    }),

    isGraphOpen: PropTypes.bool,
    closeGraph: PropTypes.func,
}

const ResultTable = ({
    analysis = [],
    threshold = {},

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    horizon = 'year',
    regrouping = 'max',

    groupEquivalences = false,
    displayAdvancedStatistics = false,

    openGraph = () => {},
}) => {
    const {
        parameters,
        units,
        fractions,
        supports,
        settings,
        parameterGroupUsage,
        thresholds,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        settings: store.AdministrationReducer.settings,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        thresholds: store.QualityReducer.thresholds,
    }), shallowEqual)

    const parametersIndex = useListIndexed(parameters, 'code')
    const unitsIndex = useListIndexed(units, 'code')
    const fractionsIndex = useListIndexed(fractions, 'id')
    const supportsIndex = useListIndexed(supports, 'id')

    const dates = useMemo(() => {
        if (horizon === 'year') {
            return orderBy(uniqBy(analysis, a => getYear(a.sampleDate)), 'sampleDate').map(a => `${getYear(a.sampleDate)}`)
        }
        return ['history']
    }, [horizon, analysis])

    const shouldCalculateFlow = hydroDatas.some(({ calculateFlux }) => calculateFlux)

    const piezoDatasFormatted = useRegroupPiezoDatas(piezoDatas, dates, regrouping)
    const {
        hydroDatasFormatted,
        hydroFluxData,
    } = useRegroupHydroDatas(hydroDatas, dates, regrouping)
    const pluvioDatasFormatted = useRegroupPluvioDatas(pluvioDatas, dates, regrouping)

    const formatUnitLabel = unit => {
        if (!unit) {
            return ''
        }
        if (shouldCalculateFlow) {
            return `${unit.symbol} * m3/s`
        }
        return `${unit.symbol} [${unit.code}]`
    }

    const data = useMemo(() => {
        const analysisGroupByParameter = groupBy(analysis, ({ parameter, unit, fraction, support }) => groupEquivalences ? parameter : `${parameter}#:#${unit}#:#${fraction}#:#${support}`)
        return Object.keys(analysisGroupByParameter).map(paramKey => {
            const analysisGroup = analysisGroupByParameter[paramKey].map(a => {
                const dateFormat = horizon === 'year' ? getYear(a.sampleDate) : 'history'
                const hydroValue = hydroFluxData.reduce((sumFlux, { measures, coeffFlux = 1 }) => {
                    const m = measures[dateFormat]
                    return m ? sumFlux + m.value * coeffFlux : sumFlux
                }, 0)
                const valueFlux = shouldCalculateFlow ? round(a.result * hydroValue, 5) : a.result
                return {
                    ...a,
                    valueFlux,
                }
            })

            const [parameterCode] = paramKey.split('#:#')
            const parameter = parametersIndex[parameterCode]
            const parameterLabel = parameter?.shortLabel || parameter?.name

            const analysisGroupByYear = groupBy(analysisGroup, a => horizon === 'year' ? getYear(a.sampleDate) : 'history')

            const valuesObj = Object.keys(analysisGroupByYear).reduce((acc, year) => {
                const analyse = shouldCalculateFlow ? getFluxByGroup(analysisGroupByYear[year], regrouping) : getAnalyseByGroup(analysisGroupByYear[year], regrouping, settings)
                if (isUndefined(analyse)) {
                    return acc
                }
                acc[year] = {
                    positionCell: 'right',
                    value: shouldCalculateFlow ? analyse.valueFlux : analyse.value,
                    sortValue: analyse.result,
                    classNameColor: analyse.color === 'white' && isUndefined(analyse.result) ? 'grey' : analyse.color,
                    setTooltip: () => (
                        <TooltipAnalyse
                            title={parameter?.shortLabelWithCode || ''}
                            analyse={analyse}
                            threshold={analyse.threshold}
                        />
                    ),
                    result: analyse.value, // use for substance
                    parameter: analyse.parameter, // use for substance
                    thresholdValue: analyse.thresholdValue, // use for substance
                }
                return acc
            }, {})

            const unitCodes = uniqBy(analysisGroup, 'unit').map(a => a.unit).filter(u => !isUndefined(u))
            const labelUnits = unitCodes.map(code => formatUnitLabel(unitsIndex[code]) || `<${code}>`).join(', ')

            const fractionCodes = uniqBy(analysisGroup, 'fraction').map(a => a.fraction).filter(u => !isUndefined(u))
            const labelFractions = fractionCodes.map(code => fractionsIndex[code]?.labelWithCode || `<${code}>`).join(', ')
            const labelTruncateFractions = fractionCodes.map(code => {
                const fration = fractionsIndex[code]
                return getLabelTruncate(fration?.name, fration?.code, 15)
            }).join(', ')

            const supportCodes = uniqBy(analysisGroup, 'support').map(a => a.support).filter(u => !isUndefined(u))
            const labelSupports = supportCodes.map(code => supportsIndex[code]?.labelWithCode || `<${code}>`).join(', ')
            const labelTruncateSupports = supportCodes.map(code => {
                const support = supportsIndex[code]
                return getLabelTruncate(support?.name, support?.code, 15)
            }).join(', ')

            const group = parameterGroupUsage.find(p => p.parameter === parameterCode)

            const validAnalysis = filterValid(filterResult(analysisGroup))

            const p90 = searchP90Value(validAnalysis)
            const min = searchMinValue(validAnalysis)
            const max = searchMaxValue(validAnalysis)
            const average = calculateAverage(validAnalysis, settings)
            const geometricAverage = calculateGeometricAverage(validAnalysis)
            const rangeInterquartile = calculateRangeInterquartile(validAnalysis)
            const standardDeviation = calculateStandardDeviation(validAnalysis)

            const nbAnalysis = validAnalysis.length
            const nbQuantification = filterQuantification(validAnalysis).length
            const nbDetection = filterDetection(validAnalysis).length
            const nbOverrun = validAnalysis.filter(a => !['blue', 'white'].includes(a.color)).length

            const overrunTx = nbAnalysis ? `${round(nbOverrun * 100 / nbAnalysis, 2)} %` : '0%'
            const quantTx = nbAnalysis ? `${round(nbQuantification * 100 / nbAnalysis, 3)} %` : '0%'
            const detectTx = nbAnalysis ? `${round(nbDetection * 100 / nbAnalysis, 3)} %` : '0%'

            return {
                ...valuesObj,
                parameter: {
                    value: shouldCalculateFlow ? `${i18n.fluxOf} ${parameterLabel}` : getLabelTruncate(parameterLabel, parameterCode, 20),
                    code: parameterCode,
                    className: parameter?.name ? 'tooltipped' : '',
                    tooltip: shouldCalculateFlow ? `${i18n.fluxOf} ${parameter?.name || ''}` : parameter?.name,
                },
                group: {
                    value: getLabelTruncate(group?.groupName, group?.groupCode, 15),
                    className: group?.labelWithCode ? 'tooltipped' : '',
                    tooltip: group?.labelWithCode,
                },
                unit: {
                    value: substringText(labelUnits, 26),
                    codes: unitCodes,
                    className: labelUnits ? 'tooltipped' : '',
                    tooltip: labelUnits,
                },
                fraction: {
                    value: substringText(labelTruncateFractions, 26),
                    codes: fractionCodes,
                    className: labelFractions ? 'tooltipped' : '',
                    tooltip: labelFractions,
                },
                support: {
                    value: substringText(labelTruncateSupports, 26),
                    codes: supportCodes,
                    className: labelSupports ? 'tooltipped' : '',
                    tooltip: labelSupports,
                },
                min: { value: min, classNameColor: 'grey', positionCell: 'right' },
                averageShort: { value: average, classNameColor: 'grey', positionCell: 'right' },
                max: { value: max, classNameColor: 'grey', positionCell: 'right' },
                percentile90: { value: p90, classNameColor: 'grey', positionCell: 'right' },
                geometricAverageShort: { value: geometricAverage, classNameColor: 'grey', positionCell: 'right' },
                rangeInterquartile: { value: rangeInterquartile, classNameColor: 'grey', positionCell: 'right' },
                standardDeviation: { value: standardDeviation, classNameColor: 'grey', positionCell: 'right' },
                overrunNb: { value: nbOverrun, classNameColor: 'grey', positionCell: 'right' },
                overrunTx: { value: overrunTx, classNameColor: 'grey', positionCell: 'right' },
                nbAnalysis: { value: nbAnalysis, classNameColor: 'grey', positionCell: 'right' },
                nbQuant: { value: nbQuantification, classNameColor: 'grey', positionCell: 'right' },
                nbDetect: { value: nbDetection, classNameColor: 'grey', positionCell: 'right' },
                quantTx: { value: quantTx, classNameColor: 'grey', positionCell: 'right' },
                detectTx: { value: detectTx, classNameColor: 'grey', positionCell: 'right' },
            }
        })
    }, [analysis, hydroFluxData, fractionsIndex, groupEquivalences, horizon, parameterGroupUsage, parametersIndex, regrouping, settings, shouldCalculateFlow, supportsIndex, unitsIndex])

    const orderedData = useMemo(() => {
        return orderBy(data, d => d.parameter.code)
    }, [data])

    const substances = useMemo(() => {
        if (!threshold) {
            return []
        }
        const thresholdFound = thresholds.find(t => `${t.code}` === threshold.thresholdCode)

        const analysisValues = dates.reduce((acc, key) => {
            const keyData = orderedData.map(d => d[key]).filter(d => d?.classNameColor && !['white', 'blue', 'grey'].includes(d.classNameColor))
            const [date] = key.split('#:#')
            const overrunedParameters = keyData.map(d => {
                return (
                    <span>
                        {`\u2022 ${parametersIndex[d.parameter]?.displayName}: ${d.result} - ${i18n.threshold}(${d.thresholdValue})`}
                        <br />
                    </span>
                )
            })
            acc[key] = {
                value: keyData.length,
                date,
                positionCell: 'right',
                classNameColor: overrunedParameters.length > 0 ? 'red' : 'grey',
                setTooltip: () => (
                    <div>
                        <span>
                            {
                                overrunedParameters.length > 0 ? (
                                    <>
                                        {`${i18n.overrunedParameters}: `}
                                        <br />
                                        {overrunedParameters}
                                    </>
                                ) : (
                                    <>
                                        {i18n.noOverrun}
                                        <br />
                                    </>
                                )
                            }
                        </span>
                    </div>
                ),
            }
            return acc
        }, {})
        const values = dates.map(key => analysisValues[key].value)
        const overrunNb = values.filter(v => v !== 0).length

        const overrunTx = values.length ? `${round(overrunNb * 100 / values.length, 2)} %` : '0%'
        return [{
            parameter: { value: thresholdFound?.name, classNameColor: 'grey' },
            group: { value: '', classNameColor: 'grey' },
            unit: { value: '', classNameColor: 'grey' },
            support: { value: '', classNameColor: 'grey' },
            fraction: { value: '', classNameColor: 'grey' },
            ...analysisValues,
            overrunTx: {
                value: overrunTx,
                classNameColor: 'grey',
                positionCell: 'right',
            },
            overrunNb: {
                value: overrunNb,
                classNameColor: 'grey',
                positionCell: 'right',
            },
        }]
    }, [dates, orderedData, parametersIndex, threshold, thresholds])

    const advancedStatisticsHeaders = displayAdvancedStatistics ? ['rangeInterquartile', 'standardDeviation', 'overrunNb', 'overrunTx'] : []
    const headers = [ 'group', 'parameter', 'unit', 'support', 'fraction', ...dates, 'min', 'averageShort', 'max', 'percentile90', 'geometricAverageShort', ...advancedStatisticsHeaders, 'nbDetect', 'nbQuant', 'nbAnalysis', 'detectTx', 'quantTx']

    return (
        <>
            <Table
                showTitle={false}
                data={[
                    ...substances,
                    ...piezoDatasFormatted,
                    ...pluvioDatasFormatted,
                    ...hydroDatasFormatted,
                    ...orderedData,
                ]}
                sortable
                color
                id='suiviPCTable'
                fixed={5}
                type={{ headers }}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabel}
                className='no-margin'
                noHightlight
                onClick={({ parameter, unit, support, fraction }) => {
                    if (isUndefined(parameter.code)) {
                        return
                    }
                    openGraph({
                        parameter: parameter.code,
                        units: unit.codes,
                        supports: support.codes,
                        fractions: fraction.codes,
                    })
                }}
            />
        </>
    )
}

ResultTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    threshold: PropTypes.instanceOf(DtoQualityThresholds),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    horizon: PropTypes.oneOf(['operations', 'year', 'historic']),
    regrouping: PropTypes.oneOf(['max', 'moyenne', 'percentile']),

    groupEquivalences: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,

    isExportModalOpen: PropTypes.bool,
    closeExportModal: PropTypes.func,

    openGraph: PropTypes.func,
}
// todo ajouter graph des données tableau, ajout piezo/hydro/pluvio pour max et p90 prend la mesure la plus proche et moyenne faire la moyenne
const PcMonitoringRegroupTable = ({
    analysis = [],
    threshold,

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    horizon = 'year',
    regrouping = 'max',

    groupEquivalences = false,
    displayAdvancedStatistics = false,

    isExportModalOpen = false,
    closeExportModal = () => { },
}) => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        typeEnvironmentModels,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const {
        value: isGraphOpen,
        setTrue: openGraph,
        setFalse: closeGraph,
    } = useBoolean(false)
    const [graphArgument, setGraphArgument] = useState({})

    const exportModel = useMemo(() => typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => dispatch(ExportAction.exportModel({
                    stationId: qualitometer.id.toString(),
                    stationCode: qualitometer.code,
                    stationType: qualitometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                })),
            }],
        }
    }), [dispatch, qualitometer.code, qualitometer.id, qualitometer.typeName, typeEnvironmentModels])

    return (
        <div style={{ paddingBottom: '100px' }}>
            <ResultTable
                analysis={analysis}
                threshold={threshold}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                horizon={horizon}
                regrouping={regrouping}

                groupEquivalences={groupEquivalences}
                displayAdvancedStatistics={displayAdvancedStatistics}

                openGraph={argument => {
                    setGraphArgument(argument)
                    openGraph()
                }}
            />
            <GraphModal
                isGraphOpen={isGraphOpen}
                closeGraph={closeGraph}

                analysis={analysis}
                thresholds={threshold?.thresholds}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                horizon={horizon}
                regrouping={regrouping}

                groupEquivalences={groupEquivalences}
                graphArgument={graphArgument}
            />
            <PcMonitoringRegroupExportModal
                analysis={analysis}
                threshold={threshold}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                horizon={horizon}
                regrouping={regrouping}

                groupEquivalences={groupEquivalences}
                exportModel={exportModel}

                isExportModalOpen={isExportModalOpen}
                closeExportModal={closeExportModal}
            />
        </div>
    )
}

PcMonitoringRegroupTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    threshold: PropTypes.instanceOf(DtoQualityThresholds),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    horizon: PropTypes.oneOf(['operations', 'year', 'historic']),
    regrouping: PropTypes.oneOf(['max', 'moyenne', 'percentile']),

    groupEquivalences: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,

    isExportModalOpen: PropTypes.bool,
    closeExportModal: PropTypes.func,
}

export default PcMonitoringRegroupTable