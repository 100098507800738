import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'
import { DOCUMENTS_EXTENSIONS } from '../../constants/AdministrationConstants'
import { MODELS_TYPES } from './ModelConstants'

const PopupAddModel = ({
    openDialog,
    stationType,
    changeStationType = () => {},
    onUploadFile = () => {},
    addModel = () => {},
    closeDialog = () => {},
}) => {
    return (
        <Dialog
            onClose={closeDialog}
            fullWidth
            maxWidth='lg'
            open={openDialog}
        >
            <DialogContent>
                <div className='row margin-bottom-2'>
                    <div className='card row no-margin'>
                        <div className='card-title activator'>
                            {i18n.addFile}
                        </div>
                    </div>
                    <div className='col s10 offset-s1 margin-top-2'>
                        <div className='row no-margin padding-top-3-px'>
                            <Row>
                                <Select
                                    col={10}
                                    label={i18n.modelTypeSelection}
                                    value={stationType}
                                    onChange={value => changeStationType(value)}
                                    keyLabel='name'
                                    keyvalue='code'
                                    options={MODELS_TYPES}
                                />
                            </Row>
                            <Row>
                                <div className='col s10 file-field input-field'>
                                    <div className='btn'>
                                        <span>{ i18n.fileToDownload }</span>
                                        <input
                                            accept={DOCUMENTS_EXTENSIONS}
                                            id='uploadFile'
                                            type='file'
                                            multiple
                                            onChange={value => onUploadFile(value)}
                                        />
                                    </div>
                                    <div className='file-path-wrapper'>
                                        <input className='file-path validate' type='text' />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog()} variant='outlined' >
                    {i18n.cancel}
                </Button>
                <Button onClick={() => addModel()} variant='contained' color='primary'>
                    {i18n.addModel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

PopupAddModel.propTypes = {
    openDialog: PropTypes.bool,
    stationType: PropTypes.number,
    changeStationType: PropTypes.func,
    onUploadFile: PropTypes.func,
    addModel: PropTypes.func,
    closeDialog: PropTypes.func,
}

export default PopupAddModel