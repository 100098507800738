/* eslint-disable consistent-return */
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { countBy, findIndex, groupBy, isNil, isNumber, isUndefined, keys, max, maxBy, min, minBy, orderBy, round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { AUTO, MONTH, WEEK, YEAR, THRESHOLD, AREA, NOTHING, getIntervalFormatter, PLUVIO, HYDRO, PIEZO, QUALITO, getYScale } from 'quality/constants/ChartConstant'
import React, { useEffect, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import {
    chartLine, chartSymbol, exportExcelIcon,
    exportPictureIcon, histogramIcon, inversePluvio, legendSymbol, lineIcon, logIcon, stackIcon, thresholdIcon,
} from './EChartUtils'
import ExportFileModal from 'components/modal/ExportFileModal'
import useBoolean from 'utils/customHook/useBoolean'

const DEFAULT_GRID_ID = 'defaultGrid'
const DEFAULT_YAXIS_ID = 'defaultYAxis'
const DEFAULT_XAXIS_ID = 'defaultXAxis'

const DEFAULT_GRID_RIGHT = 30
const DEFAULT_GRID_LEFT = 20

const DEFAULT_GRID_GAP = 50
const DEFAULT_AXIS_GAP = 70

const DEFAULT_GRAPH_HEIGHT = 400
const DEFAULT_HEADER_HEIGHT = 10
const DEFAULT_TITLE_HEIGHT = 40
const DEFAULT_FOOTER_HEIGHT = 60
const DEFAULT_DATAZOOM_HEIGHT = 35

const LINE = 'line'
const BAR = 'bar'
const ALL = 'all'

const LEFT = 'left'
const RIGHT = 'right'

const REGEX_BACKGROUND_COLOR = /background-color:[#\w]+;/

const ExportModal = ({
    isOpen = false,
    close = () => {},

    data = [],
    exportName = '',
}) => {
    const onExport = ext => {
        const exportData = data.flatMap(({ name, dataList, unit }) => {
            return dataList.map(d => ({
                date: { value: getDate(d.date), format: 'dd/MM/yyyy', cellType: 'date' },
                value: { value: d.value, format: '0.00000', cellType: 'number' },
                name,
                unit,
            }))
        })
        exportFile({
            data: exportData.length ? [
                {
                    ...exportData[0],
                    headers: ['name', 'date', 'value', 'unit'],
                },
                ...exportData.slice(1),
            ] : [],
            exportType: ext,
            titleFile: exportName,
        }, true)
    }
    return (
        <ExportFileModal
            open={isOpen}
            onClose={close}
            maxWidth='md'
            data={[
                {
                    name: i18n.resultsTable,
                    formats: [{
                        type: i18n.excelFile,
                        callback: () => onExport('xlsx'),
                    }, {
                        type: i18n.csvFile,
                        callback: () => onExport('csv'),
                    }],
                },
            ]}
        />
    )
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        unit: PropTypes.string,
        color: PropTypes.string,
        yAxisId: PropTypes.string,
        type: PropTypes.string,
        lineStyle: PropTypes.string,
        showSymbol: PropTypes.bool,
        connectNulls: PropTypes.bool,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number,
            value: PropTypes.number,
            marker: PropTypes.string,
            color: PropTypes.string,
            unit: PropTypes.string,
        })).isRequired,
    })).isRequired,
    exportName: PropTypes.string,
}

// Voir avec Damien pour faire des modifs si elles ne sont pas urgentes
// Le composant est complètement générique pour la qualité et est utilisé à plusieurs endroit, normalement il n'y a plus besoin de le modifier
// Toutes les valeurs par défaut ne doivent pas être modifier car cela peut entrainer des problèmes aux endroits ou le composant est déjà utilisé
// ? currently multiple xAxis on a same grid is not supported
// TODO simplify element position and calculate global height and position accordingly
const QualityChart = ({
    title = '',
    exportName,
    grids = [{}],
    xAxis = [{}],
    yAxis = [{}],
    series = [],
    thresholds = [],
    markLines = [],

    headerHeight = DEFAULT_HEADER_HEIGHT,
    footerHeight = DEFAULT_FOOTER_HEIGHT,
    componentHeight, // fixe value

    roundValue = 3,
    xAxisSpace = YEAR,
    maxXAxisSpace = AUTO,

    defaultDisplayMarker = true,
    defaultDisplayLine = true,
    defaultStateThreshold = THRESHOLD,

    withToolTypeLine = false,
    withToolTypeBar = false,
    withToolTypeStack = false,
    withToolLog = false,
    withToolThreshold = false,
    withToolLegend = false,
    withToolMarker = false,
    withToolLine = false,

    withArea = false,
    withDataZoom = false,
    withHidingGrid = false,
    withHidingYAxis = false,

    dataZoomPosition = {},
    toolboxPosition = {},
    legendPosition = {},

    tooltipFormatter,

    // setColors = () => {},
}) => {
    // use on mouse event
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)
    let selectedLegend = useRef({})

    useEffect(() => {
        selectedLegend.current = series.reduce((acc, s) => {
            acc[s.name] = selectedLegend.current[s.name] ?? true
            return acc
        }, {})
    }, [series])

    const [displayLog, setDisplayLog] = useState(false)
    const [displayMarker, setDisplayMarker] = useState(defaultDisplayMarker)
    const [displayLegend, setDisplayLegend] = useState(true)
    const [displayLine, setDisplayLine] = useState(defaultDisplayLine)
    const [stateThreshold, setStateThreshold] = useState(defaultStateThreshold)
    const [chartType, setChartType] = useState(LINE)
    const [stack, setStack] = useState()

    const { value: pluvioInverse, toggle: togglePluvioInverse } = useBoolean(true)

    const {
        value: isExportOpen,
        setTrue: openExport,
        setFalse: closeExport,
    } = useBoolean(false)

    // useEffect(() => {
    //     return echartRef.current?.getEchartsInstance().getModel().getSeries().filter(s => !isUndefined(s.option.uniqId)).map(s => {
    //         return {
    //             uniqId: s.option.uniqId,
    //             color: echartRef.current?.getEchartsInstance().getVisual({
    //                 seriesIndex: s.seriesIndex,
    //             }, 'color'),
    //         }
    //     })
    // }, [])

    const defaultTimestamp = moment().valueOf()

    const thresholdsWithValue = thresholds.filter(th => th.dataList?.length > 0)

    const allMinDate = series.filter(d => !d.excludeFromInterval).map(d => minBy(d.dataList, 'date')?.date ?? defaultTimestamp)
    const allMaxDate = series.filter(d => !d.excludeFromInterval).map(d => maxBy(d.dataList, 'date')?.date ?? defaultTimestamp)
    const thresholdGroupByYAxis = groupBy(thresholdsWithValue, t => t.yAxisId ?? DEFAULT_YAXIS_ID)
    const seriesGroupByYAxis = groupBy(series, t => t.yAxisId ?? DEFAULT_YAXIS_ID)

    const minDate = moment(min(allMinDate))
    const maxDate = moment(max(allMaxDate))
    const chartMinDate = minDate.startOf(xAxisSpace ?? YEAR)
    const chartMinDateTimestamp = chartMinDate.valueOf()
    const chartMaxDate = maxDate.endOf(xAxisSpace ?? YEAR)
    const chartMaxDateTimestamp = chartMaxDate.valueOf()

    const {
        formatter,
        interval,
    } = getIntervalFormatter(chartMinDate, chartMaxDate, maxXAxisSpace)
    // } = getAxisLabelInterval(chartMaxDate, chartMinDate)

    const groupYAxisByGrid = groupBy(yAxis, axis => axis.gridId ?? DEFAULT_GRID_ID)
    const countByGrid = keys(groupYAxisByGrid).map(key => countBy(groupYAxisByGrid[key], a => a.position ?? LEFT))
    const nbYAxisLeft = max(countByGrid.map(c => c[LEFT])) ?? 0
    const nbYAxisRight = max(countByGrid.map(c => c[RIGHT])) ?? 0

    const isGridDisplayed = (gridId) => {
        if (!withHidingGrid || !selectedLegend.current || !keys(selectedLegend.current).length) return true
        const seriesFound = groupYAxisByGrid[gridId].flatMap(axis => series.filter(s => s.yAxisId === axis.yAxisId))
        return seriesFound.some(s => isUndefined(selectedLegend.current[s.name]) || selectedLegend.current[s.name])
    }

    const isYAxisDisplay = (yAxisId) => {
        if (!withHidingYAxis || !selectedLegend.current || !keys(selectedLegend.current).length) return true
        const seriesFound = series.filter(s => s.yAxisId === yAxisId)
        return seriesFound.some(s => isUndefined(selectedLegend.current[s.name]) || selectedLegend.current[s.name])
    }

    // these functions are called in the option object as well as in the setOption in the mouseEvent
    const getLegend = () => ({
        top: 15 + (title ? DEFAULT_TITLE_HEIGHT : 0),
        left: 40,
        // adding boolan give number
        right: displayToolbox.current ? 30 * (3 + withToolTypeLine + withToolTypeBar + withToolTypeStack + withToolLog + withToolThreshold + withToolLegend + withToolMarker + withToolLine) + (parseInt(toolboxPosition.right ?? 35)) : 20,
        ...legendPosition,
        type: 'scroll',
        show: displayLegend,
        selected: selectedLegend.current,
    })

    const getToolbox = () => ({
        show: displayToolbox.current ?? false,
        top: 10 + (title ? DEFAULT_TITLE_HEIGHT : 0),
        right: 35,
        ...toolboxPosition,
        showTitle: false,
        itemSize: 18,
        tooltip: { // same as option.tooltip
            show: true,
            formatter: param => param.title,
        },
        feature: {
            myToolLine: {
                show: withToolTypeLine,
                title: i18n.lines,
                icon: lineIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                },
                iconStyle: {
                    borderColor: chartType === LINE ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolBar: {
                show: withToolTypeBar,
                title: i18n.histogram,
                icon: histogramIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(BAR)
                    setDisplayLog(false)
                },
                iconStyle: {
                    borderColor: chartType === BAR && !stack ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolStack: {
                show: withToolTypeStack,
                title: i18n.stack,
                icon: stackIcon,
                onclick: () => {
                    setStack(ALL)
                    setChartType(BAR)
                    setDisplayLog(false)
                },
                iconStyle: {
                    borderColor: chartType === BAR && stack === ALL ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolLog: {
                show: withToolLog,
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                    setDisplayLog(prevDisplayLog => !prevDisplayLog)
                },
                iconStyle: {
                    borderColor: displayLog ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolThreshold: {
                show: withToolThreshold && !!thresholdsWithValue.length,
                title: withArea ? i18n.thresholdAreaNothing : i18n.toggleThreshold,
                icon: thresholdIcon,
                onclick: () => setStateThreshold(prevStateThreshold => {
                    if (withArea) {
                        return (prevStateThreshold + 1) % 3
                    }
                    return prevStateThreshold === THRESHOLD ? NOTHING : THRESHOLD
                }),
                iconStyle: {
                    borderColor: stateThreshold !== NOTHING ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLegend: {
                show: withToolLegend,
                title: i18n.toggleLegend,
                icon: legendSymbol,
                onclick: () => setDisplayLegend(prevHiddenLegend => !prevHiddenLegend),
                iconStyle: {
                    borderColor: displayLegend ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleMarker: {
                show: withToolMarker,
                title: i18n.toggleMarker,
                icon: chartSymbol,
                onclick: () => setDisplayMarker(prevHiddenMarker => !prevHiddenMarker),
                iconStyle: {
                    borderColor: displayMarker ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLine: {
                show: withToolLine,
                title: i18n.toggleLine,
                icon: chartLine,
                onclick: () => setDisplayLine(prevHiddenLine => !prevHiddenLine),
                iconStyle: {
                    borderColor: displayLine ? '#4d93c9' : '#5c5c5c',
                },
            },

            // additionnal action
            myToolInversePluvio: {
                show: series.some(d => d.dataType?.startsWith(PLUVIO)),
                title: i18n.reversePluvioAxis,
                icon: inversePluvio,
                onclick: togglePluvioInverse,
                iconStyle: {
                    borderColor: pluvioInverse ? '#4d93c9' : '#5c5c5c',
                },
            },

            // default action
            saveAsImage: {
                show: true,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: exportName || title,
            },
            myToolExport: {
                show: true,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: openExport,
            },
            restore: {
                show: true,
                title: i18n.restore,
            },
        },
    })

    const calculateComponentHeight = () => grids.reduce((acc, { height = DEFAULT_GRAPH_HEIGHT, top = DEFAULT_GRID_GAP, gridId }) => {
        if (isGridDisplayed(gridId) && isNumber(height)) {
            return acc + height + top
        }
        return acc
    }, headerHeight + (title ? DEFAULT_TITLE_HEIGHT : 0) + footerHeight + (withDataZoom ? DEFAULT_DATAZOOM_HEIGHT : 0))

    const defaultTooltipFormatter = params => {
        const date = getDate(params[0].axisValue)
        const listLine = params.map(({ marker, seriesIndex, seriesName, value: [, result], data: { unit = '', lineColor } }) => {
            const color = lineColor ?? echartRef.current?.getEchartsInstance().getVisual({
                seriesIndex,
            }, 'color')
            const newMarker = marker.replace(REGEX_BACKGROUND_COLOR, `background-color:${color};`)
            return `<br/>${newMarker} ${seriesName}: ${result && round(result, roundValue)} ${unit}`
        })
        return `${date}${listLine.join('')}`
    }

    const getOptions = () => {
        let tmpGap = headerHeight + (title ? DEFAULT_TITLE_HEIGHT : 0)
        const gridList = grids.map(grid => {
            const {
                gridId = DEFAULT_GRID_ID,
                top = DEFAULT_GRID_GAP,
                bottom, // use only when componentHeight is define and height is set to undefined
                height = DEFAULT_GRAPH_HEIGHT, // can be set to 'auto' but componentHeight have to be define
                // right = DEFAULT_GRID_RIGHT, // desactivate to aline with dataZoom
                // left = DEFAULT_GRID_LEFT,
            } = grid

            const display = isGridDisplayed(gridId)

            const tmpTop = tmpGap + top
            const echartGrid = {
                show: display,
                top: tmpTop,
                bottom,
                height,
                right: (nbYAxisRight * DEFAULT_AXIS_GAP) + DEFAULT_GRID_RIGHT,
                left: (nbYAxisLeft * DEFAULT_AXIS_GAP) + DEFAULT_GRID_LEFT,
                gridId,
            }
            if (display) {
                tmpGap = tmpTop + height
            }
            return echartGrid
        })

        const xAxisList = xAxis.map(axis => {
            const {
                type = 'time',
                boundaryGap = true,
                axisLabel = {},
                axisLine = {},
                axisTick = {},
                splitLine = {},
                gridId = DEFAULT_GRID_ID,
                xAxisId = DEFAULT_XAXIS_ID,
            } = axis

            const display = isGridDisplayed(gridId)

            return {
                id: xAxisId,
                type,
                show: display,
                boundaryGap,
                axisLabel: {
                    formatter,
                    rotate: axisLabel.rotate ?? 50,
                    show: axisLabel.show ?? true,
                },
                splitLine: {
                    show: splitLine.show ?? true,
                },
                axisLine: {
                    show: axisLine.show ?? true,
                },
                axisTick: {
                    show: axisTick.show ?? true,
                },
                interval,
                // min: value => {
                //     const date = moment(value.min).startOf('year')
                //     return date.valueOf()
                // },
                // max: value => {
                //     const date = moment(value.max).endOf('year')
                //     return date.valueOf()
                // },
                min: chartMinDate.valueOf(),
                max: chartMaxDate.valueOf(),
                gridIndex: findIndex(gridList, grid => (grid.gridId ?? DEFAULT_GRID_ID) === gridId),
                // xAxisId, // not from echart, use to link serie to yAxis
            }
        })

        const yAxisList = keys(groupYAxisByGrid).flatMap(key => {
            // use to offset the axis on both side
            let indexLeft = -1
            let indexRight = -1

            return groupYAxisByGrid[key].map(axis => {
                const {
                    type = displayLog ? 'log' : 'value',
                    position = LEFT,
                    nameLocation = 'center',
                    nameGap = 45,
                    boundaryGap = true,
                    name,
                    dataType,
                    inverse,
                    min: minY,
                    max: maxY,
                    splitLine = {},
                    axisLine = {},
                    axisTick = {},
                    yAxisId = DEFAULT_YAXIS_ID,
                    gridId = DEFAULT_GRID_ID,
                } = axis

                const display = isYAxisDisplay(yAxisId)

                if (position === LEFT) {
                    indexLeft++
                }
                if (position === RIGHT) {
                    indexRight++
                }

                const minValue = min(seriesGroupByYAxis[yAxisId]?.map(d => minBy(d.dataList, 'value')?.value).filter(v => !isNil(v)))
                const maxValue = max(seriesGroupByYAxis[yAxisId]?.map(d => maxBy(d.dataList, 'value')?.value).filter(v => !isNil(v)))
                const usedMax = orderBy(thresholdGroupByYAxis[yAxisId]?.flatMap(th => th.dataList) ?? [], 'value').find(t => t.value > maxValue)?.value ?? maxValue
                // const usedMin = orderBy(thresholdGroupByYAxis[yAxisId], 'value', 'desc').find(t => t.value < value.min)?.value || value.min
                const scale = getYScale(minY ?? minValue, maxY ?? usedMax)
                return {
                    id: yAxisId,
                    type,
                    show: display,
                    position,
                    nameLocation,
                    nameGap,
                    boundaryGap,
                    offset: (position === LEFT ? indexLeft : indexRight) * 70,
                    name,
                    inverse: inverse ?? (dataType === PLUVIO ? pluvioInverse : false),
                    splitLine: {
                        show: splitLine.show ?? true,
                    },
                    axisLine: {
                        show: axisLine.show ?? true,
                    },
                    axisTick: {
                        show: axisTick.show ?? true,
                    },
                    axisLabel: {
                        formatter: v => v > 10 ? round(v) : round(v, 4),
                    },
                    interval: scale.interval,
                    max: () => {
                        if (displayLog) {
                            return
                        }
                        return maxY ?? scale.max
                    },
                    min: () => {
                        if (displayLog) {
                            return
                        }
                        if (chartType === BAR || stack) {
                            return 0
                        }
                        return minY ?? scale.min
                    },
                    gridIndex: findIndex(gridList, grid => (grid.gridId ?? DEFAULT_GRID_ID) === gridId),
                    // yAxisId, // not from echart, use to link serie to yAxis
                }
            })
        })

        const seriesList = series.map(serie => {
            const {
                name,
                unit,
                dataList,
                color,
                type = chartType,
                barWidth = 2,
                lineStyle = {},
                yAxisId = DEFAULT_YAXIS_ID,
                xAxisId = DEFAULT_XAXIS_ID,
                showSymbol = true,
                connectNulls = true,
                // uniqId,
            } = serie

            const logFiltered = displayLog ? dataList.filter(d => d.value > 0) : dataList
            const dateFiltered = logFiltered.filter(d => !isUndefined(d.value) && !isUndefined(d.date))
                .filter(d => chartMinDateTimestamp <= d.date && d.date <= chartMaxDateTimestamp)

            const dataOrdered = orderBy(dateFiltered, 'date').map(data => {
                const {
                    date,
                    value,
                    marker = 'circle',
                    color: markerColor,
                    unit: valueUnit,
                } = data

                return {
                    value: [date, value, data],
                    symbol: displayMarker ? (marker || 'circle') : 'none',
                    itemStyle: chartType !== BAR ? {
                        color: markerColor,
                    } : undefined,
                    unit: valueUnit ?? unit,
                    // lineColor: color, // used for tooltip
                }
            })

            return {
                // uniqId, // used to send the color to the parent
                type,
                data: dataOrdered,
                name,
                connectNulls,
                showSymbol,
                barWidth,
                // showAllSymbol: true,
                symbolSize: 6,
                lineStyle: {
                    opacity: lineStyle.opacity ?? (displayLine ? undefined : 0),
                    type: lineStyle.type,
                },
                color,
                stack: stack ? yAxisId : false,
                yAxisIndex: findIndex(yAxisList, axis => axis.id === yAxisId),
                xAxisIndex: findIndex(xAxisList, axis => axis.id === xAxisId),
            }
        })

        const thresholdSeries = stateThreshold === THRESHOLD ? thresholdsWithValue.filter(th => isYAxisDisplay(th.yAxisId)).map(threshold => {
            const {
                name = i18n.threshold,
                dataList = [],
                xAxisId = DEFAULT_XAXIS_ID,
                yAxisId = DEFAULT_YAXIS_ID,
            } = threshold

            return {
                type: LINE,
                yAxisIndex: findIndex(yAxisList, axis => axis.id === yAxisId),
                xAxisIndex: findIndex(xAxisList, axis => axis.id === xAxisId),
                markLine: {
                    symbol: 'none',
                    precision: '5',
                    // symbol: ['none', 'arrow'],
                    data: dataList.map(({ value, color = 'black', labelColor = 'black' }) => {
                        return {
                            label: {
                                position: 'middle',
                                formatter: d => `${name}: ${d.value}`,
                                color: labelColor,
                            },
                            lineStyle: {
                                color,
                            },
                            yAxis: value,
                        }
                    }),
                    lineStyle: {
                        type: 'dashed',
                        // opacity: stateThreshold === THRESHOLD ? 1 : 0,
                    },
                    silent: true,
                },
            }
        }) : []

        const markAreaSeries = stateThreshold === AREA ? thresholdsWithValue.filter(th => isYAxisDisplay(th.yAxisId)).map(threshold => {
            const {
                // name = i18n.threshold,
                dataList = [],
                colorList = [],
                xAxisId = DEFAULT_XAXIS_ID,
                yAxisId = DEFAULT_YAXIS_ID,
            } = threshold

            return {
                type: LINE,
                yAxisIndex: findIndex(yAxisList, axis => axis.id === yAxisId),
                xAxisIndex: findIndex(xAxisList, axis => axis.id === xAxisId),
                areaStyle: {
                    opacity: 0.5,
                },
                markArea: {
                    data: [
                        ...dataList.map(({ value }, i) => ({
                            0: {
                                yAxis: value,
                                itemStyle: {
                                    color: colorList[i] ?? 'white',
                                    opacity: 0.2,
                                },
                            },
                            1: { yAxis: i === 0 ? 0 : dataList[i - 1].value },
                        })),
                        {
                            0: {
                                yAxis: dataList[dataList.length-1].value,
                                itemStyle: {
                                    color: colorList[dataList.length] ?? 'white',
                                    opacity: 0.2,
                                },
                            },
                            // 1: { yAxis: max(Object.keys(groupMax).map(k => groupMax[k])) },
                            1: { yAxis: 'min' }, // max value :shrug:
                        },
                    ],
                },
            }
        }) : []

        const markLineSeries = markLines.filter(ml => isYAxisDisplay(ml.yAxisId)).map(line => {
            const {
                color = 'black',
                dataList = [],
                xAxisId = DEFAULT_XAXIS_ID,
                yAxisId = DEFAULT_YAXIS_ID,
            } = line
            return {
                type: LINE,
                yAxisIndex: findIndex(yAxisList, axis => axis.id === yAxisId),
                xAxisIndex: findIndex(xAxisList, axis => axis.id === xAxisId),
                color,
                markLine: {
                    symbol: 'none',
                    precision: '5',
                    // symbol: ['none', 'arrow'],
                    data: dataList.map(({ value, label = `${value}` }) => ({
                        yAxis: value,
                        label: {
                            formatter: label,
                            position: 'middle',
                        },
                    })),
                    silent: true,
                },
            }
        })

        return {
            title: {
                text: title,
                x: 'center', // ?
                top: 10,
            },
            series: [...thresholdSeries, ...markAreaSeries, ...markLineSeries, ...seriesList],
            grid: gridList,
            xAxis: xAxisList,
            yAxis: yAxisList,
            legend: getLegend(),
            tooltip: {
                show: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    snap: true,
                },
                formatter: tooltipFormatter ?? defaultTooltipFormatter,
            },
            dataZoom: withDataZoom ? [{
                bottom: footerHeight - (DEFAULT_DATAZOOM_HEIGHT + 15),
                height: DEFAULT_DATAZOOM_HEIGHT,
                ...dataZoomPosition,
                type: 'slider',
                right: (nbYAxisRight * DEFAULT_AXIS_GAP) + DEFAULT_GRID_RIGHT,
                left: (nbYAxisLeft * DEFAULT_AXIS_GAP) + DEFAULT_GRID_LEFT,
                handleSize: '80%',
                filterMode: 'none',
                xAxisIndex: xAxisList.map((_, i) => i),
                handleStyle: {
                    color: '#fff',
                    shadowBlur: 3,
                    shadowColor: 'rgba(0, 0, 0, 0.6)',
                    shadowOffsetX: 2,
                    shadowOffsetY: 2,
                },
            }] : [],
            toolbox: getToolbox(),
        }
    }

    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={getOptions()}
                notMerge
                lazyUpdate
                style={{ height: componentHeight ?? calculateComponentHeight() }}
                ref={e => {
                    echartRef.current = e
                }}
                onEvents={{
                    legendselectchanged: (legend) => {
                        selectedLegend.current = legend.selected
                        if (!isNil(echartRef.current)) {
                            const instance = echartRef.current.getEchartsInstance()
                            instance.setOption(getOptions(), true) // replaceMerge on v5
                            instance.getDom().style.height = componentHeight ?? calculateComponentHeight()
                        }
                    },
                }}
            />
            <ExportModal
                isOpen={isExportOpen}
                close={closeExport}
                data={series}
                exportName={exportName ?? title}
            />
        </div>
    )
}

QualityChart.propTypes = {
    title: PropTypes.string,
    exportName: PropTypes.string,
    grids: PropTypes.arrayOf(PropTypes.shape({
        gridId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // should be uniq
        top: PropTypes.number,
        bottom: PropTypes.number, // used only when componentHeight is define and height is set to undefined
        height: PropTypes.oneOfType([ // can be set to 'auto' but componentHeight have to be define
            PropTypes.number,
            PropTypes.oneOf(['auto']),
        ]),
        // right: PropTypes.number,
        // left: PropTypes.number,
    })).isRequired,
    xAxis: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(['value', 'time', 'category', 'log']),
        boundaryGap: PropTypes.bool,
        axisLabel: PropTypes.shape({
            show: PropTypes.bool,
        }),
        axisLine: PropTypes.shape({
            show: PropTypes.bool,
        }),
        axisTick: PropTypes.shape({
            show: PropTypes.bool,
        }),
        splitLine: PropTypes.shape({
            show: PropTypes.bool,
        }),

        gridId: PropTypes.string, // to link with the grid
        xAxisId: PropTypes.string, // should be uniq
    })).isRequired,
    yAxis: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(['value', 'time', 'category', 'log']),
        position: PropTypes.oneOf([LEFT, RIGHT]),
        nameLocation: PropTypes.oneOf(['start', 'middle', 'center', 'end']),
        nameGap: PropTypes.number,
        boundaryGap: PropTypes.bool,
        name: PropTypes.string,
        inverse: PropTypes.bool,
        min: PropTypes.number,
        max: PropTypes.number,
        splitLine: PropTypes.shape({
            show: PropTypes.bool,
        }),

        dataType: PropTypes.oneOf([PLUVIO, HYDRO, PIEZO, QUALITO]), // to inverse pluvio
        yAxisId: PropTypes.string, // should be uniq
        gridId: PropTypes.string, // to link with the grid
    })).isRequired,
    series: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        unit: PropTypes.string,
        color: PropTypes.string,
        type: PropTypes.oneOf(['line', 'bar']),
        lineStyle: PropTypes.shape({
            type: PropTypes.oneOf(['solid', 'dashed', 'dotted']),
            opacity: PropTypes.number,
        }),
        showSymbol: PropTypes.bool,
        connectNulls: PropTypes.bool,
        dataType: PropTypes.oneOf([PLUVIO, HYDRO, PIEZO, QUALITO]),
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
            marker: PropTypes.string,
            color: PropTypes.string,
            unit: PropTypes.string,
        })).isRequired,
        barWidth: PropTypes.number,

        excludeFromInterval: PropTypes.bool,

        xAxisId: PropTypes.string, // to link with the axis
        yAxisId: PropTypes.string, // to link with the axis
    })).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number,
            color: PropTypes.string,
            labelColor: PropTypes.string,
        })),
        colorList: PropTypes.arrayOf(PropTypes.string), // 1 more than dataList, used for markarea

        xAxisId: PropTypes.string, // to link with the axis
        yAxisId: PropTypes.string, // to link with the axis
    })),
    markLines: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        })),

        xAxisId: PropTypes.string, // to link with the axis
        yAxisId: PropTypes.string, // to link with the axis
    })),

    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
    componentHeight: PropTypes.number,

    roundValue: PropTypes.number,
    xAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
    maxXAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),

    defaultDisplayMarker: PropTypes.bool,
    defaultDisplayLine: PropTypes.bool,
    defaultStateThreshold: PropTypes.oneOf([THRESHOLD, AREA, NOTHING]),

    withToolTypeLine: PropTypes.bool,
    withToolTypeBar: PropTypes.bool,
    withToolTypeStack: PropTypes.bool,
    withToolLog: PropTypes.bool,
    withToolThreshold: PropTypes.bool,
    withToolLegend: PropTypes.bool,
    withToolMarker: PropTypes.bool,
    withToolLine: PropTypes.bool,

    withArea: PropTypes.bool,
    withDataZoom: PropTypes.bool,
    withHidingGrid: PropTypes.bool,
    withHidingYAxis: PropTypes.bool,

    dataZoomPosition: PropTypes.shape({
        bottom: PropTypes.number,
        top: PropTypes.number,
        height: PropTypes.number,
    }),
    toolboxPosition: PropTypes.shape({
        bottom: PropTypes.number,
        top: PropTypes.number,
        right: PropTypes.number,
    }),
    legendPosition: PropTypes.shape({
        bottom: PropTypes.number,
        top: PropTypes.number,
    }),

    tooltipFormatter: PropTypes.func,
}

export default QualityChart
export {
    LINE,
    BAR,
    ALL,
    LEFT,
    RIGHT,
}