import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { flatten, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import ParameterAction from '../../../../../referencial/components/parameter/actions/ParameterAction'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import SelectionWithParamDto from '../../../../../referencial/components/parameter/dto/SelectionWithParamDto'
import SelectSelection from '../SelectSelection'
import UpdateParameter from '../UpdateParameter'
import CreateSelection from './CreateSelection'


const LIST = 1
const UPDATE_PARAM = 2
const NEW_SELECTION = -1

class SelectionStepper extends Component {
    state = {
        step: LIST,
        selection: { listType: 0 },
    }

    prevStep = () => {
        if (this.state.step === LIST) {
            this.props.onClose()
        } else {
            this.setState({ step: LIST, selection: {} })
        }
    }

    nextStep = () => {
        if (this.state.step === UPDATE_PARAM) {
            this.props.onValidate()
        } else if (this.state.step === LIST) {
            this.setState({ step: UPDATE_PARAM })
        } else if (!this.props.selections.find(({ code }) => code === this.state.selection.code)) {
            this.props.createSelection(this.state.selection).then(() => {
                this.props.fetchSelectionsWithParam().then(() => {
                    this.onSelectSelection(this.state.selection.code)
                    this.setState({ step: LIST, selection: { listType: 0 } })
                })
            })
        } else {
            this.props.toastrWarning(i18n.codeAlreadyExists)
        }
    }

    onSelectSelection = (selectionCode, isSelected = false) => {
        const selectedSelections = isSelected ? this.props.selectedSelections.filter(code => code !== selectionCode) : [...this.props.selectedSelections, selectionCode]
        const selectionList = selectedSelections.map(sCode => this.props.selections.find(({ code }) => sCode === code)).filter(s => !!s)
        const parametersList = uniq(flatten(selectionList.map(({ parameters }) => parameters)))
        const selectedParameters = parametersList.map(paramCode => {
            const {
                code: parameterCode,
                unitReference1: unitCode,
            } = this.props.parameters.find(({ code }) => code === paramCode) || {}
            return { parameterCode, unitCode }
        }).filter(({ parameterCode }) => !!parameterCode)
        this.props.onChange({ selectedSelections, selectedParameters })
    }

    deleteAllSelections = () => this.props.onChange({ selectedSelections: [], selectedParameters: [] })

    addAllSelections = selectionsList => {
        const selectedSelections = [...this.props.selectedSelections, ...selectionsList]
        const selectionList = selectedSelections.map(sCode => this.props.selections.find(({ code }) => sCode === code)).filter(s => !!s)
        const parametersList = uniq(flatten(selectionList.map(({ parameters }) => parameters)))
        const selectedParameters = parametersList.map(paramCode => {
            const {
                code: parameterCode,
                unitReference1: unitCode,
            } = this.props.parameters.find(({ code }) => code === paramCode) || {}
            return { parameterCode, unitCode }
        }).filter(({ parameterCode }) => !!parameterCode)
        this.props.onChange({ selectedSelections, selectedParameters })
    }

    render = () => {
        const {
            step,
            selection,
        } = this.state
        const {
            isOpen,
            selectedSelections,
            selectedParameters,
            onClose,
            onChange,
        } = this.props

        return (
            <Dialog
                onClose={onClose}
                fullWidth
                maxWidth='xl'
                open={isOpen}
            >
                <DialogTitle>{step === NEW_SELECTION ? i18n.selectionCreate : `${i18n.step} ${step}: ${step === LIST ? i18n.parametersSelectionsSelection : i18n.parametersModification}`}</DialogTitle>
                <DialogContent>
                    <div style={{ height: '70vh' }}>
                        {
                            step === LIST && (
                                <SelectSelection
                                    selectedSelections={selectedSelections}
                                    onCreate={() => this.setState({ step: NEW_SELECTION, selection: { listType: 0 } })}
                                    onChange={this.onSelectSelection}
                                    addAll={this.addAllSelections}
                                    deleteAll={this.deleteAllSelections}
                                />
                            )
                        }
                        {
                            step === NEW_SELECTION && (
                                <CreateSelection
                                    selection={selection}
                                    onChange={obj => this.setState(obj)}
                                />
                            )
                        }
                        {
                            step === UPDATE_PARAM && (
                                <UpdateParameter
                                    selectedParameters={selectedParameters}
                                    onChange={onChange}
                                />
                            )
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.prevStep} variant='outlined'>
                        {step === LIST ? i18n.close : step === UPDATE_PARAM ? i18n.previous : i18n.cancel}
                    </Button>
                    <Button onClick={this.nextStep} variant='contained' color='primary'>
                        { step === UPDATE_PARAM ? i18n.validate : step === LIST ? i18n.next : i18n.add}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SelectionStepper.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onChange: PropTypes.func,

    selectedSelections: PropTypes.arrayOf(PropTypes.string),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),

    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    selections: PropTypes.arrayOf(PropTypes.instanceOf(SelectionWithParamDto)),

    createSelection: PropTypes.func,
    fetchSelectionsWithParam: PropTypes.func,
    toastrWarning: PropTypes.func,
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
    selections: store.ParameterReducer.selectionsWithParam,
})

const mapDispatchToProps = {
    createSelection: ParameterAction.createSelection,
    fetchSelectionsWithParam: ParameterAction.fetchSelectionsWithParam,
    toastrWarning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionStepper)
