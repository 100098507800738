import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLinks, getStationTitle } from '../../../utils/StationUtils'
import useTitle from '../../../utils/customHook/useTitle'
import HydrometryAction from '../../actions/HydrometryAction'
import { last, maxBy, minBy, range } from 'lodash'
import moment from 'moment/moment'
import HydroSuiviTable from './HydroSuiviTable'
import HydroSuiviCriterias from './HydroSuiviCriterias'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from 'connected-react-router'


const HydroSuiviTableApp = ({}) => {
    const {
        hydrometricStation,
        hydroStatistics,
        hydrometricStations,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [measures, setMeasures] = useState([])
    const [years, setYears] = useState([])
    const [filter, setFilter] = useState({ group: 'MAX', horizon: 'year' })
    const currentDataType = filter.dataType ?? (hydroStatistics.some(h => h.typeId === 4) ? 4 : (hydroStatistics[0]?.typeId || 4))

    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: getStationTitle(hydrometricStation),
        href: `station/hydrometry/${hydrometricStation.id}`,
    }, {
        title: i18n.followUpTable,
        href: `station/hydrometry/${hydrometricStation.id}/hydrometryFollowUp`,
    }], [])

    useActions(() => ({
        links: getLinks(hydrometricStation, this),
        arrowNav: getStationArrowNav('hydrometry', hydrometricStations, hydrometricStation.id, s => dispatch(push(`/station/hydrometry/${s.id}/hydrometryFollowUp`))),
    }), [hydrometricStation, hydrometricStations])

    const reloadMeasures = (tmpFilter, usedYear = filter.year) => {
        setDataLoaded(false)
        setFilter(tmpFilter)
        HydrometryAction.promiseHydroChronicMeasuresLight({
            stationId: hydrometricStation.id,
            dataType: tmpFilter.dataType ?? currentDataType,
            groupFunc: tmpFilter.group,
            ...(tmpFilter.horizon === 'year' ? {
                startDate: moment().year(usedYear).startOf('year').valueOf(),
                endDate: moment().year(usedYear).endOf('year').valueOf(),
            } : {}),
            chartMode: true,
        }).then(json => {
            const filteredStatus = tmpFilter.status ? json.filter(s => s.status === tmpFilter.status) : json
            const filteredQualification = tmpFilter.qualification ? filteredStatus.filter(s => s.qualification === tmpFilter.qualification) : filteredStatus
            setMeasures(filteredQualification)
            setDataLoaded(true)
        })
    }

    useEffect(() => {
        if (hydroStatistics.length) {
            const minDate = minBy(hydroStatistics, 'startDate')?.startDate
            const maxDate = maxBy(hydroStatistics, 'endDate')?.endDate
            const calculatedYears = range(parseInt(moment(minDate).year()), moment(maxDate).year() + 1).map(y => ({ code: y, name: y })).reverse()
            setYears(calculatedYears)
            reloadMeasures({ ...filter, year: calculatedYears[0].code }, calculatedYears[0].code)
        }
    }, [hydroStatistics])

    useEffect(() => {
        if (!hydroStatistics.length) {
            dispatch(HydrometryAction.fetchHydroStatistics(hydrometricStation.id)).then(res => {
                if (!res.length) {
                    setDataLoaded(true)
                }
            })
        }
    }, [])

    return (
        <div>
            { filter.year && <HydroSuiviCriterias filter={filter} hydroStatistics={hydroStatistics} reloadMeasures={reloadMeasures} years={years} /> }
            <div className='row padding-bottom-10'>
                <div className='col s12 m12'>
                    <HydroSuiviTable
                        measures={measures}
                        horizon={filter.horizon}
                        dataLoaded={dataLoaded}
                        minYear={last(years)?.code}
                        maxYear={years[0]?.code}
                        selectedYear={filter.year}
                    />
                </div>
            </div>
        </div>
    )
}


HydroSuiviTableApp.propTypes = {
}

export default HydroSuiviTableApp
