export default class DtoPrettyAnalysisLight {
    constructor(obj) {
        this.qualitometer = obj[0]
        this.id = obj[1]
        this.sample = obj[2]
        this.operation = obj[3]
        this.sampleDate = obj[4]
        this.analysisDate = obj[5]
        this.parameter = obj[6]
        this.unit = obj[7]
        this.result = obj[8]
        this.remark = obj[9]
        this.support = obj[10]
        this.localization = obj[11]
        this.lq = obj[12]
        this.ld = obj[13]
        this.saturationThreshold = obj[14]
        this.qualification = obj[15]
        this.status = obj[16]
        this.fraction = obj[17]
        this.labo = obj[18]
        this.jobExecutionId = obj[19]
        this.importDate = obj[20]
        this.updateDate = obj[21]
        this.updateLogin = obj[22]
        this.numSampling = obj[23] // Option[Int]
        this.value = obj[24] ?? '' // Option[String] = None,
        this.color = obj[25] ?? 'white' // Option[String] = None,
        this.thresholdState = obj[26] // Option[String] = None,
        this.thresholdValue = obj[27] // Option[Double] = None,
        this.conformity = obj[28] // Option[Boolean] = None,
        this.threshold1 = obj[29] // Option[Double] = None,
        this.threshold2 = obj[30] // Option[Double] = None,
        this.threshold3 = obj[31] // Option[Double] = None,
        this.threshold4 = obj[32] // Option[Double] = None
    }
}