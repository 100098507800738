import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSortedTableData } from '../../../components/datatable/TableUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PropTypes from 'prop-types'
import PerimeterFolderDto from '../../dto/PerimeterFolderDto'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import Select from '../../../components/forms/Select'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import { getFullDate } from '../../../utils/DateUtil'
import { push } from 'connected-react-router'
import { tempPerimetersFolder, tempPerimetersStates, tempPerimetersStatus } from '../../temp/TempPerimetersData'
import useTitle from '../../../utils/customHook/useTitle'
import PerimetersImportStepper from './components/PerimetersImportStepper'
import useActions from '../../../utils/customHook/useActions'
import {DialogContentMUI, DialogMUI, DialogTitleMUI} from "../../../components/styled/Dialog";
import Icon from "../../../components/icon/Icon";
import {StyledFieldSet} from "../../../components/StyledElements";

const PERIMETERS_FOLDER_HEADER = ['code', 'name', 'city', 'collectivity', 'perimeters', 'status', 'startDate', 'endDate', 'state']

const PerimetersFolderTable = ({
    perimetersFolder = [],
}) => {
    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.perimeters,
        href: 'perimeters',
    }], [])

    const {
        selectedSearchValues,
        cities,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        cities: store.CityReducer.cities,
    }))

    const perimetersFolderFormated = perimetersFolder.map(folder => {
        const city = cities.find(c => c.code === folder.city)

        return {
            ...folder,
            city: city?.name ?? i18n.unknown,
            perimeters: folder.perimeters?.length || 0,
            status: tempPerimetersStatus[folder.status] || '',
            startDate: getFullDate(folder.startDate),
            endDate: getFullDate(folder.endDate),
            state: tempPerimetersStates[folder.state] || '',
        }
    })

    const { folder: searchValues } = selectedSearchValues
    const initialSort = searchValues?.columnName ? { column: searchValues.columnName, sort: searchValues.sort } : null

    const stationsHash = stations => stations.map(s => s.code).join('')

    const onTableSort = (columnName, sort) => {
        const sortedStations = getSortedTableData(perimetersFolderFormated, { column: columnName, sort }, false)
        if (!searchValues || !searchValues.stations || stationsHash(searchValues.stations) !== stationsHash(sortedStations)) {
            dispatch(SieauAction.update('selectedSearchValues', 'perimetersFolder', { stations: sortedStations, columnName, sort }))
        }
    }

    return (
        <Grid item border={'2px solid #00359F'} borderRadius={'5px'}>
            <Table
                title={'Dossiers des périmètres'}
                data={perimetersFolderFormated}
                paging
                initialSort={initialSort}
                nbPerPageLabel={nbPerPageLabel}
                onSort={onTableSort}
                type={{ headers: PERIMETERS_FOLDER_HEADER }}
                onClick={f => dispatch(push(`/perimeters/perimeter/${f.code}`))}
                condensed
                sortable
                id='perimetersFolder_table'
                invertedHeaderStyle
            />
        </Grid>
    )
}

PerimetersFolderTable.propTypes = {
    perimetersFolder: PropTypes.arrayOf(PerimeterFolderDto),
}

const PerimetersDashboard = () => {
    const dispatch = useDispatch()

    const {
        selectedSearchValues,
        cities,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        cities: store.CityReducer.cities,
    }))

    const [searchValue, setSearchValue] = useState(selectedSearchValues.perimetersFolder?.searchValue || '')
    const [tmpSearch, setTmpSearch] = useState(searchValue)
    const [status, setStatus] = useState()
    const [state, setState] = useState()
    const [town, setTown] = useState()
    const [collectivity, setCollectivity] = useState()

    const [importStepperOpen, setImportStepperOpen] = useState(false)
    const [addPopupOpen, setAddPopupOpen] = useState(false)

    const onReset = () => {
        dispatch(AdministrationAction.setSelectedSearchValues('perimetersFolder', { searchValue: '' }))
        setStatus(undefined)
        setState(undefined)
        setTown(undefined)
        setCollectivity(undefined)
        setTmpSearch('')
        setSearchValue('')
    }

    useEffect(() => {
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
    }, [cities, dispatch])

    useActions(() => ({
        import: () => setImportStepperOpen(true),
        export: () => {},
        new: () => setAddPopupOpen(true),
    }), [])

    return (
        <>
            <PerimetersImportStepper open={importStepperOpen} setOpen={setImportStepperOpen}/>
            <DialogMUI
                fullWidth
                maxWidth='lg'
                open={ addPopupOpen }
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                        <Grid item>
                            {'Créer un dossier de périmètres'}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setAddPopupOpen(false)} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    <StyledFieldSet style={{ padding: '30px 50px' }}>
                        <Grid container>
                            <Grid container item paddingTop={'10px'}>
                                <Grid item width={'100%'}>
                                    <Input
                                        title={i18n.name}
                                        value={''}
                                        //onChange={(v) => setTmpSearch(v)}
                                        clearFunction
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={cities ? cities : []}
                                            label={i18n.towns}
                                            nullLabel='&nbsp;'
                                            value={''}
                                            noSort
                                            //onChange={setTown}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={[]}
                                            label={i18n.collectivity}
                                            nullLabel='&nbsp;'
                                            value={''}
                                            noSort
                                            //onChange={setCollectivity}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={Object.values(tempPerimetersStatus)?.map((c, index) => {
                                                return { code: index, name: c }
                                            })}
                                            label={i18n.status}
                                            nullLabel='&nbsp;'
                                            value={''}
                                            noSort
                                            //onChange={setStatus}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Select
                                            options={Object.values(tempPerimetersStates)?.map((c, index) => {
                                                return { code: index, name: c }
                                            })}
                                            label={i18n.state}
                                            nullLabel='&nbsp;'
                                            value={''}
                                            noSort
                                            //onChange={setState}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.startDate}
                                            value={''}
                                            //onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid item width={'100%'}>
                                        <Input
                                            title={i18n.endDate}
                                            value={''}
                                            //onChange={(v) => setTmpSearch(v)}
                                            clearFunction
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActions>
                    <Button onClick={() => setAddPopupOpen(false)} variant='outlined'>
                        { i18n.cancel }
                    </Button>
                    <Button onClick={() => {}} variant='contained' color='primary'>
                        { i18n.save }
                    </Button>
                </DialogActions>
            </DialogMUI>
            <Grid margin={'5px'}>
                <Grid container paddingBottom={'15px'}>
                    <Card round noMargin={false} cardStyle={{ width: '100%' }}>
                        <Grid container item alignItems='center'>
                            <Grid container item xs={'auto'} spacing={2} padding={'10px'} alignItems='center'>
                                <Grid item>
                                    <Input
                                        title={i18n.search}
                                        value={tmpSearch}
                                        onChange={(v) => setTmpSearch(v)}
                                        onEnterKeyPress={v => {
                                            setTmpSearch(v)
                                            setSearchValue(v)
                                            dispatch(AdministrationAction.setSelectedSearchValues('perimetersFolder', { searchValue: v }))
                                        }}
                                        clearFunction
                                        id='perimetersFolder_dashboard_searchBar'
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        options={Object.values(tempPerimetersStatus)?.map((c, index) => {
                                            return { code: index, name: c }
                                        })}
                                        label={i18n.status}
                                        nullLabel='&nbsp;'
                                        value={status}
                                        noSort
                                        onChange={setStatus}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        options={Object.values(tempPerimetersStates)?.map((c, index) => {
                                            return { code: index, name: c }
                                        })}
                                        label={i18n.state}
                                        nullLabel='&nbsp;'
                                        value={state}
                                        noSort
                                        onChange={setState}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        options={cities ? cities : []}
                                        label={i18n.towns}
                                        nullLabel='&nbsp;'
                                        value={town}
                                        noSort
                                        onChange={setTown}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        options={[]}
                                        label={i18n.collectivity}
                                        nullLabel='&nbsp;'
                                        value={collectivity}
                                        noSort
                                        onChange={setCollectivity}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs='auto' justifyContent={'flex-end'} padding={'10px'} spacing={2}>
                                <Grid container item xs={'auto'}>
                                    <Button variant='outlined' onClick={onReset}>
                                        {i18n.reinit}
                                    </Button>
                                </Grid>
                                <Grid container item xs={'auto'}>
                                    <Button variant='contained' onClick={() => {
                                        setTmpSearch(tmpSearch)
                                        dispatch(AdministrationAction.setSelectedSearchValues('perimetersFolder', { tmpSearch }))
                                    }}
                                    >
                                        {i18n.search}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid>
                    <PerimetersFolderTable perimetersFolder={tempPerimetersFolder}/>
                </Grid>
            </Grid>
        </>
    )
}

export default PerimetersDashboard