import AppStore from '../../../../../store/AppStore'
import HomeAction from '../../../../../home/actions/HomeAction'
import i18n from 'simple-react-i18n'
import { getStationTitle } from '../../../../../utils/StationUtils'
import { hasValue } from '../../../../../utils/NumberUtil'
import { getIconQualification } from '../../../../../utils/AnalyseUtils'
import { getFullDate } from '../../../../../utils/DateUtil'
import { getLabel, getObjectLabel } from '../../../../../utils/StoreUtils'
import React from 'react'
import moment from 'moment'
import { statusIcon } from '../../../../../utils/StatusUtil'
import { groupBy, orderBy, sumBy, last } from 'lodash'
import { Grid } from '@mui/material'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from '../../../../../components/styled/Accordions'
import Table from '../../../../../components/datatable/Table'
import { nbPerPageLabelShort } from '../../../../../referencial/constants/ReferencialConstants'
import { push } from 'connected-react-router'
import { exportModelFile, formatData, getModelFileType } from '../../../../../utils/ExportDataUtil'
import ExportFileModal from '../../../../../components/modal/ExportFileModal'
import { getQualifications, getStatuses } from '../../../../../utils/QualityUtils'
import ExportAction from '../../../../../export/actions/ExportAction'
import { searchAllCharacters } from '../../../../../utils/StringUtil'

const setTitle = (qualitometer) => {
    AppStore.dispatch(HomeAction.setTitle([{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: getStationTitle(qualitometer),
        href: `station/quality/${qualitometer.id}`,
    }, {
        title: i18n.operations,
        href: `station/quality/${qualitometer.id}/operation`,
    }]))
}

const getPdfIcon = (refLabo, pathPdf, setFilePopupParams) => {
    const foundFiles = pathPdf.split(';')
    return !!foundFiles.length && refLabo && (
        <div>
            <i className='clickable right material-icons font-size-16' onClick={e => {
                e.preventDefault()
                setFilePopupParams({ popupIsOpen: true, anchorEl: e.currentTarget, files: foundFiles.map(path => ({ url: path, name: last(path.split('/')) })) })
            }}
            >attach_file</i>
        </div>
    )
}

const getFormattedOperations = (operations, contributorsIndex, supportsIndex, files, qualitometer, setFilePopupParams) => {
    return operations.map(op => {
        const notValidated = (op.nbAnalysis || 0) - (op.nbAnalysisValidated || 0)
        const [icon, label] = (() => {
            if (notValidated > 0) {
                return [(
                    <div className='margin-right-1 valign-wrapper' style={{ fontSize: '0.70rem' }}>
                        <i className='material-icons tiny no-margin'>{getIconQualification(4)}</i>
                        <span>{notValidated}</span>
                    </div>
                ), `${notValidated} ${i18n.notValidated}`]
            }
            return [null, null]
        })()
        const onClick = {
            onClick: () => {
                AppStore.dispatch(push(`/station/quality/${qualitometer.id}/operation/${op.operationId}`))
            },
        }
        return {
            ...op,
            year: moment(op.date).year(),
            date: { value: getFullDate(op.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date', ...onClick },
            nbAnalysisExport: { value: op.nbAnalysis, format: '0', cellType: 'number' },
            nbAnalysis: icon ? {
                value: (
                    <div>
                        <span>
                            {op.nbAnalysis}
                        </span>
                        <div style={{ display: 'flex' }}>
                            {icon}
                        </div>
                    </div>
                ),
                tooltip: label,
                className: 'tooltipped',
                ...onClick,
            } : { value: op.nbAnalysis, ...onClick },
            support: { value: getObjectLabel(supportsIndex[op.support]), ...onClick },
            supportCode: { value: op.support, format: '0', cellType: 'number' },
            statusCode: { value: op.status, format: '0', cellType: 'number' },
            status: { value: getLabel(getStatuses(), op.status) },
            qualificationCode: { value: op.qualification, format: '0', cellType: 'number' },
            qualification: { value: getLabel(getQualifications(), op.status) },
            sampler: { value: getObjectLabel(contributorsIndex[op.sampler], 'mnemonique'), ...onClick },
            laboratory: { value: op.labo.length > 0 ? getObjectLabel(contributorsIndex[op.labo[0]], 'mnemonique') + (op.labo.length > 1 ? ', ...' : '') : '', ...onClick,
                setTooltip: op.labo.length > 1 ? () => op.labo.map(l => getObjectLabel(contributorsIndex[l], 'mnemonique')).join('\n') : undefined },
            producer: { value: getObjectLabel(contributorsIndex[op.producer], 'mnemonique'), ...onClick },
            samplePoint: { value: op.point, ...onClick },
            nullValue: { value: op.refLabo && op.pathPdf ? getPdfIcon(op.refLabo, op.pathPdf, setFilePopupParams) : null },
            qualificationTableColor: { value: statusIcon(op, 25, true), className: 'no-padding', ...onClick },
            dateOp: op.date,
        }
    })
}

const getFilteredAndFormattedOperations = (operations, filter, contributorsIndex, supportsIndex, files, qualitometer, setFilePopupParams) => {
    const withSupport = hasValue(filter.support) ? operations.filter(op => op.support === filter.support) : operations
    const withStatus = hasValue(filter.status) ? withSupport.filter(op => op.status === filter.status) : withSupport
    const withQualification = hasValue(filter.qualification) ? withStatus.filter(op => op.qualification === filter.qualification) : withStatus
    const withSamplers = (filter.samplers || []).length ? withQualification.filter(op => filter.samplers.includes(op.sampler)) : withQualification
    const withLabos = (filter.labos || []).length ? withSamplers.filter(op => filter.labos.includes(op.labo)) : withSamplers
    const withProducers = (filter.producers || []).length ? withLabos.filter(op => filter.producers.includes(op.producer)) : withLabos

    const formatted = getFormattedOperations(withProducers, contributorsIndex, supportsIndex, files, qualitometer, setFilePopupParams)

    const searchCharacters = searchAllCharacters(filter.searchValue)
    return hasValue(filter.searchValue) ? formatted.filter(op => searchAllCharacters(['date', 'support', 'sampler', 'laboratory', 'producer', 'samplePoint'].map(k => op[k].value).join('   ')).includes(searchCharacters)) : formatted
}

const getGroupedOperations = (formattedOperations, viewMode) => {
    const groups = viewMode === 'year' ? groupBy(formattedOperations, viewMode) : groupBy(formattedOperations, v => v[viewMode].value)
    return orderBy(Object.keys(groups), a => a, 'desc').map((key, i) => {
        const nbAnalysis = sumBy(groups[key], o => o.nbAnalysisExport?.value || 0)
        return (
            <Grid item xs={12} key={key}>
                <AccordionMUI defaultExpanded={i === 0}>
                    <AccordionSummaryMUI>
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid item xs={8}>
                                <span style={{ fontSize: '1.3rem' }}>
                                    {key}
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span style={{ fontSize: '1.3rem' }}>
                                    {`${groups[key].length} ${groups[key].length > 1 ? i18n.operations : i18n.operation}`}
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span style={{ fontSize: '1.3rem' }}>
                                    {`${nbAnalysis} ${nbAnalysis > 1 ? i18n.oneAnalysis : i18n.analysis}`}
                                </span>
                            </Grid>
                        </Grid>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI sx={{ padding: 0 }}>
                        <Table
                            data={orderBy(groups[key], 'dateOp', 'desc')}
                            sortable
                            color
                            showTitle={false}
                            paging
                            nbPerPageLabel={nbPerPageLabelShort}
                            type={{ headers: ['qualificationTableColor', 'date', 'nbAnalysis', 'support', 'sampler', 'laboratory', 'producer', 'samplePoint', 'nullValue'] }}
                        />
                    </AccordionDetailsMUI>
                </AccordionMUI>
            </Grid>

        )
    })
}

const getOperationExportModal = (operationsFormatted, setExportModalIsOpen, typeEnvironmentModels, qualitometer) => {
    const operationsExport = operationsFormatted.map(op => ({ ...op, nbAnalysis: op.nbAnalysisExport, qualificationTableColor: null }))
    if (operationsExport.length) {
        operationsExport[0].headers = ['date', 'nbAnalysis', 'qualificationCode', 'qualification', 'statusCode', 'status', 'supportCode', 'support', 'sampler', 'laboratory', 'producer', 'samplePoint']
    }

    const tableExport = [{
        name: i18n.resultsTable,
        formats: [{
            type: i18n.excelFile,
            callback: () => AppStore.dispatch(ExportAction.export(formatData(operationsExport), 'xlsx', `${qualitometer.code} - ${i18n.operations}`)),
        }, {
            type: i18n.csvFile,
            callback: () => AppStore.dispatch(ExportAction.export(formatData(operationsExport), 'csv', `${qualitometer.code} - ${i18n.operations}`)),
        }],
    }]
    const exportModel = typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: qualitometer.id.toString(),
                    stationCode: qualitometer.code,
                    stationType: qualitometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                }),
            }],
        }
    })
    return (
        <ExportFileModal
            open={true}
            onClose={() => setExportModalIsOpen(false)}
            data={[ ...tableExport, ...exportModel ]}
        />
    )
}

export { setTitle, getFilteredAndFormattedOperations, getGroupedOperations, getOperationExportModal }