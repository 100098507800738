import { uniq } from 'lodash'
import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { THRESHOLD_TYPE, getRemarks, getTabColorsBySize, getThresholdType } from 'utils/AnalyseUtils'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { Grid } from '@mui/material'
import { hasValue } from 'utils/NumberUtil'

const RemarkLegend = ({
    list = [],
    col = 3,
}) => {
    const {
        remarks,
    } = useSelector(store => ({
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)
    const remarksUsed = uniq(list.map(e => e.remark))
    const remarkList = remarksUsed.map(remark => remarks.find(r => r.code === `${remark}`)).filter(r => !!r)
    return (
        <StyledFieldSet /* style={{ height: '100%' }}*/>
            <StyledLegend>{i18n.remarkCodes}</StyledLegend>
            <Grid container justifyContent='left' columnSpacing={2}>
                {
                    remarkList.map(({ code, name }, i) => {
                        const remark = getRemarks(code)
                        return !!remark && (
                            <Grid item xs={col} key={`name-${i}`} >
                                <span className={'icon-color-blue'} style={{ paddingRight: 5 }}>{remark.logo}</span>
                                <span>{name}</span>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </StyledFieldSet>
    )
}

RemarkLegend.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        remark: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
    col: PropTypes.number,
}

const ThresholdLegend = ({
    threshold = {},
    col = 6,
}) => {
    const thresholdType = getThresholdType(threshold)

    const colorTab = useMemo(() => {
        if (!threshold) {
            return {}
        }
        const allColorTab = getTabColorsBySize()
        const nbThresholds = [1, 2, 3, 4].filter(nb => hasValue(threshold[`threshold${nb}`])).length + 1
        return allColorTab[nbThresholds]
    }, [threshold])

    const legends = useMemo(() => {
        if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
            return [
                { color: 'red', label: `<= ${threshold.threshold1} et >= ${threshold.threshold2}` },
                { color: 'blue', label: `> ${threshold.threshold1} et < ${threshold.threshold2}` },
                { color: 'black', label: i18n.thresholdNotApplicable },
            ]
        }
        return [
            {
                color: colorTab[0],
                label: `< ${threshold.threshold1}`,
            }, {
                color: colorTab[1],
                label: `>= ${threshold.threshold1}${hasValue(threshold.threshold2) ? ` et < ${threshold.threshold2}` : ''}`,
            }, {
                color: colorTab[2],
                label: `>= ${threshold.threshold2}${hasValue(threshold.threshold3) ? ` et < ${threshold.threshold3}` : ''}`,
            }, {
                color: colorTab[3],
                label: `>= ${threshold.threshold3}${hasValue(threshold.threshold4) ? ` et < ${threshold.threshold4}` : ''}`,
            }, {
                color: colorTab[4],
                label: `>= ${threshold.threshold4}`,
            }, {
                color: 'black',
                label: i18n.thresholdNotApplicable,
            },
        ].filter(l => !!l.color)
    }, [colorTab, threshold.threshold1, threshold.threshold2, threshold.threshold3, threshold.threshold4, thresholdType])

    return (
        <StyledFieldSet /* style={{ height: '100%' }}*/>
            <StyledLegend>{i18n.thresholds}</StyledLegend>
            <Grid container justifyContent='left' columnSpacing={2}>
                {
                    legends.map(({ color, label }, i) => {
                        return (
                            <Grid container item xs={col} key={`name-${i}`} columnSpacing={1} alignItems='center'>
                                <Grid item>
                                    <div style={{ width: 20, height: 15, backgroundColor: color, borderRadius: '5px' }} />
                                </Grid>
                                <Grid item>
                                    <span>{label}</span>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </StyledFieldSet>
    )
}

ThresholdLegend.propTypes = {
    threshold: PropTypes.shape({
        threshold1: PropTypes.number,
        threshold2: PropTypes.number,
        threshold3: PropTypes.number,
        threshold4: PropTypes.number,
    }),
    col: PropTypes.number,
}

export {
    RemarkLegend,
    ThresholdLegend,
}