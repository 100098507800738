import PerimeterFolderDto from '../dto/PerimeterFolderDto'
import PerimeterDto from '../dto/PerimeterDto'

const tempPerimetersStatus = {
    0: 'En cours',
    1: 'Validé',
}

const tempPerimetersStates = {
    0: 'Incomplet',
    1: 'Initialisé',
    2: 'Complet',
}

const tempPerimeterTypes = {
    0: 'Immédiat',
    1: 'Rapproché',
    2: 'Eloigné',
}

const tempPerimeterStates = {
    0: 'En cours',
    1: 'Terminé',
}

const tempPerimetersFolder = [
    new PerimeterFolderDto({
        code: 1,
        name: 'Gr14',
        city: '01002',
        collectivity: '',
        perimeters: [new PerimeterDto({
            code: 1,
            name: 'Test',
            type: 0,
            status: 0,
            parcels: 47,
            owners: 42,
            operators: 12,
            endDate: 1712148570697,
            startDate: 1712148570697,
            state: 0,
        }), new PerimeterDto({
            code: 2,
            name: 'Test 2',
            type: 2,
            status: 0,
            parcels: 47,
            owners: 42,
            operators: 12,
            endDate: 1712148570697,
            startDate: 1712148570697,
            state: 1,
        }), new PerimeterDto({
            code: 3,
            name: 'Test 3',
            type: 1,
            status: 0,
            parcels: 47,
            owners: 42,
            operators: 12,
            endDate: 1712148570697,
            startDate: 1712148570697,
            state: 2,
        })],
        status: 0,
        startDate: 1712148570697,
        endDate: 1712148570697,
        state: 1,
    }),
    new PerimeterFolderDto({
        code: 2,
        name: 'Test 2',
        city: '01011',
        collectivity: '',
        perimeters: [new PerimeterDto({
            code: 4,
            name: 'Test',
            type: 1,
            status: 0,
            parcels: 47,
            owners: 42,
            operators: 12,
            endDate: 1712148570697,
            startDate: 1712148570697,
            state: 0,
        }), new PerimeterDto({
            code: 5,
            name: 'Test 2',
            type: 1,
            status: 0,
            parcels: 47,
            owners: 42,
            operators: 12,
            endDate: 1712148570697,
            startDate: 1712148570697,
            state: 2,
        })],
        status: 1,
        startDate: 1712148570697,
        endDate: 1712148570697,
        state: 2,
    }),
]

const getStateColor = (state) => {
    switch (state) {
        case 0: return 'green'
        case 1: return 'yellow'
        case 2: return 'blue'
        default: return ''
    }
}

const tempPerimeterStation = {
    id: 3954,
    code: '07075X0071',
    name: 'La Combe des Brues',
    typeName: 'piezometry',
    nature: 6,
    piezometerNature: 6,
    altitude: 37.35,
    cote: 2,
    fieldMode: 2,
    townCode: '17172',
    contactCode: 11,
    designation: 'F',
    declarationTownCode: '17172',
    waterSourceType: '2',
    creationDate: 1262300400000,
    creation: 1262300400000,
    x: 364780,
    y: 2069700,
    projection: 5,
    localisation: { x: 364780, y: 2069700, z: 37.35, projection: 5 },
    link_accessibilities: [],
    link_altimetrySystems: [{ idStation: 3954, startDate: 1262300400000, natureCode: 1, altitude: 37.35, altimetrySystem: 0, productionMode: 4 }],
    link_aquifers: [{ idStation: 3954, aquiferCode: 9 }],
    link_arrangements: [],
    link_casings: [],
    link_contributors: [{ idStation: 3954, idContributor: 14, startDate: 1262300400000, contributorType: 4 }, { idStation: 3954, idContributor: 0, startDate: 1262300400000, contributorType: 10 }],
    link_despoliationModes: [],
    link_functions: [],
    link_geo: [{ idStation: 3954, ignMap: ' ', geologicalMap: ' ' }],
    link_hydroEntity: [], link_landmarks: [{ idStation: 3954, startDate: 1262300400000, height: 0, origin: 1, altimetrySystemNature: 1, altimetrySystemDate: 1262300400000 }],
    link_lithologicType: [],
    link_lithology: [], link_locations: [{ idStation: 3954, projection: 16, x: -0.67806202, y: 45.58705743, z: 37.35 }],
    link_measureMethod: [{ idStation: 3954, startDate: 1262300400000, method: 4, periodicityAcqui: 3 }],
    link_networks: [{ idStation: 3954, idNetwork: 2, startDate: 1262300400000 }],
    link_contacts: [],
    link_tempRefs: [],
    link_usages: [],
    link_watermasses: [{ idStation: 3954, watermassCode: '5075' }],
    link_work: [],
    controlErrors: [{ id: 1, date: 1028571618000, label: 'Altimetry system not found for some measures' }, { id: 2, date: 1028571618000, label: 'Landmarks not found for some measures' }, { id: 3, date: 1028571618000, label: 'Measure method not found for some measures' }],
    updateLogin: 'marius.ploteau@aquasys.fr',
    updateDate: 1680105828668 }

export {
    tempPerimetersStatus,
    tempPerimetersStates,
    tempPerimeterTypes,
    tempPerimeterStates,
    tempPerimetersFolder,
    getStateColor,
    tempPerimeterStation,
}