export default class CentralDto {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.id
        this.reference = obj.reference
        this.comment = obj.comment
        this.centralType = obj.centralType
        this.materielType = obj.centralType
        this.purchaseDate = obj.purchaseDate
        this.warrantyTime = obj.warrantyTime
        this.serialNumber = obj.serialNumber
        this.withdrawalDate = obj.withdrawalDate
        this.providerId = obj.providerId
        this.manufacturerId = obj.manufacturerId
        this.powerSupplyType = obj.powerSupplyType
        this.pileNumber = obj.pileNumber
        this.replacementDate = obj.replacementDate
        this.state = obj.state
        this.manufactureDate = obj.manufactureDate
        this.situationDate = obj.situationDate
        this.department = obj.department
        this.city = obj.city
        this.stationCode = obj.stationCode
        this.transmissionVolume = obj.transmissionVolume
        this.callFrequency = obj.callFrequency
        this.callHour = obj.callHour
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.administrator = obj.administrator
        this.telecomType = obj.telecomType
        this.desiccant = obj.desiccant
        this.networkCode = obj.networkCode
        this.supplyTime = obj.supplyTime
        this.isEquipment = obj.isEquipment
    }
}