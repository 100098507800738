import React, { useEffect, useState } from 'react'
import Input from '../../../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import SuperMultiAutocomplete from '../../../../../components/forms/SuperMultiAutocomplete'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../../utils/StatusUtil'
import MultiContributorsAutocomplete
    from '../../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import Card from '../../../../../components/card/Card'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useAbortController from '../../../../../utils/customHook/useAbortController'
import OperationAction from '../../../../actions/OperationAction'
import DtoOperationListSpecific from '../../dto/DtoOperationListSpecific'
import { omit, uniq } from 'lodash'
import { hasValue } from '../../../../../utils/NumberUtil'
import { Divider, Grid, Popover } from '@mui/material'
import * as util from './OperationsListUtil'
import SimpleTabList from '../../../../../components/list/SimpleTabList'
import MessageCard from '../../../../../components/card/MessageCard'
import ImportXmlQualityPopup from '../../../../../import/components/popup/ImportXmlQualityPopup'
import ProgressCard from '../../../../../components/card/ProgressCard'
import SupportAction from '../../../../../referencial/components/support/actions/SupportAction'
import PropTypes from 'prop-types'
import useActions from 'utils/customHook/useActions'
import { getLinks } from 'utils/StationUtils'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from 'connected-react-router'
import useBoolean from 'utils/customHook/useBoolean'
import OperationStepper from '../stepper/OperationStepper'

const OperationsListApp = ({
    id,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometer,
        qualitometers,
        contributorsIndex,
        supportsIndex,
        typeEnvironmentModels,
        files,
        accountUser,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometers,
        supportsIndex: store.SupportReducer.supportsIndex,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        files: store.StationReducer.files,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [operations, setOperations] = useState([])
    const [filter, setFilter] = useState({})
    const [tmpFilter, setTmpFilter] = useState({})
    const [contribIds, setContribIds] = useState({ samplers: null, labos: null, producers: null, supports: null })
    const [filePopupParams, setFilePopupParams] = useState({ popupIsOpen: false, anchorEl: undefined, files: [] })
    const [viewMode, setViewMode] = useState('year')
    const [exportModalIsOpen, setExportModalIsOpen] = useState(false)
    const [importModalIsOpen, setImportModalIsOpen] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const {
        value: isOperationPopupOpen,
        setTrue: openOperationPopup,
        setFalse: closeOperationPopup,
    } = useBoolean(false)

    const changeFilter = (changes) => setTmpFilter({ ...tmpFilter, ...changes })

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const loadOperations = () => {
        OperationAction.operationsListSpecific(id).then(json => {
            const operationsList = json.map(op => new DtoOperationListSpecific(op))
            setContribIds({
                samplers: uniq(operationsList.map(op => op.sampler)),
                labos: uniq(operationsList.map(op => op.labo)),
                producers: uniq(operationsList.map(op => op.producer)),
                supports: uniq(operationsList.map(op => op.support)),
            })
            setOperations(operationsList)
            if (!Object.keys(supportsIndex).length) {
                dispatch(SupportAction.fetchSupports())
            }
            setIsLoaded(true)
        })
    }

    useEffect(() => {
        initController()
        loadOperations()
        return () => {
            controllerRef.current.abort()
        }
    }, [])

    useActions(() => {
        const managerActions = accountUser.isAdmin === '1' || accountUser.metadata === '1' ? { calculate: operations.map(o => o.operationId), import: () => setImportModalIsOpen(true) } : {}
        const actions = {
            ...managerActions,
            new: openOperationPopup,
            links: getLinks(qualitometer),
            arrowNav: getStationArrowNav('quality', qualitometers, qualitometer.id, s => dispatch(push(`/station/quality/${s.id}/operation`))),
            exportList: [{
                onClick: () => setExportModalIsOpen(true),
                label: i18n.excel,
            }],
        }
        if (accountUser.metadata !== '1') {
            return omit(actions, ['new'])
        }
        return actions
    }, [accountUser, operations, qualitometer, qualitometers])

    const usedSamplers = (contribIds.samplers || []).map(c => contributorsIndex[c]).filter(c => hasValue(c))
    const usedLabos = (contribIds.labos || []).map(c => contributorsIndex[c]).filter(c => hasValue(c))
    const usedProducers = (contribIds.producers || []).map(c => contributorsIndex[c]).filter(c => hasValue(c))
    const usedSupports = (contribIds.supports || []).map(c => supportsIndex[c]).filter(c => hasValue(c))

    useEffect(() => {
        if (qualitometer && qualitometer?.id) {
            util.setTitle(qualitometer)
        }
    }, [qualitometer])

    const operationsFormatted = util.getFilteredAndFormattedOperations(operations, filter, contributorsIndex, supportsIndex, files, qualitometer, setFilePopupParams)

    const operationsGrouped = util.getGroupedOperations(operationsFormatted, viewMode)

    if (!isLoaded) {
        return <ProgressCard indeterminate />
    }

    return (
        <div>
            <Card maxWidth={1200} >
                <div className='row no-margin padding-top-1'>
                    <div className='row no-margin'>
                        <Input
                            col={3}
                            value={tmpFilter.searchValue}
                            title={i18n.search}
                            onChange={v => changeFilter({ searchValue: v })}
                        />
                        <SuperMultiAutocomplete
                            options={usedSupports}
                            label={i18n.support}
                            displayWithCode
                            col={3}
                            onChange={v => changeFilter({ support: v })}
                            values={tmpFilter.support}
                        />
                        <SuperMultiAutocomplete
                            col={3}
                            options={getStatusSelectOptions()}
                            label={i18n.status}
                            onChange={v => changeFilter({ status: v })}
                            values={tmpFilter.status}
                            noSort
                        />
                        <SuperMultiAutocomplete
                            col={3}
                            options={getQualificationSelectOptions()}
                            label={i18n.qualification}
                            onChange={v => changeFilter({ qualification: v })}
                            values={tmpFilter.qualification}
                            noSort
                        />
                    </div>
                    <div className='row no-margin'>
                        <MultiContributorsAutocomplete
                            col={3}
                            label={i18n.sampler}
                            options={usedSamplers}
                            onChange={v => changeFilter({ samplers: v })}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                        <MultiContributorsAutocomplete
                            col={3}
                            label={i18n.laboratory}
                            options={usedLabos}
                            onChange={v => changeFilter({ labos: v })}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                        <MultiContributorsAutocomplete
                            col={3}
                            label={i18n.producer}
                            options={usedProducers}
                            onChange={v => changeFilter({ producers: v })}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                    </div>
                    <div className='col s3 offset-s9 padding-bottom-1'>
                        <a className='col s12 btn' onClick={() => setFilter(tmpFilter)}>
                            {i18n.search}
                        </a>
                    </div>
                </div>
            </Card>
            <div style={{ paddingTop: '10px', width: '1200px', marginRight: 'auto', marginLeft: 'auto' }}>
                <SimpleTabList
                    defaultTab={'year'}
                    tabs={[
                        {
                            constant: 'year',
                            label: i18n.perYear,
                            icon: 'insert_invitation',
                        },
                        {
                            constant: 'producer',
                            label: i18n.byProducer,
                            icon: 'business_center',
                        },
                        {
                            constant: 'laboratory',
                            label: i18n.byLaboratory,
                            icon: 'colorize',
                        },
                    ]}
                >
                    {
                        tab => {
                            if (tab !== viewMode) {
                                setViewMode(tab)
                            }
                            if (!operationsFormatted.length) {
                                return (
                                    <MessageCard>{i18n.noOperationForTheseCriteria}</MessageCard>
                                )
                            }
                            return (
                                <div style={{ padding: '10' }}>
                                    <Grid container spacing={1}>
                                        { operationsGrouped }
                                    </Grid>
                                </div>
                            )
                        }
                    }
                </SimpleTabList>
            </div>
            <Popover
                open={filePopupParams.popupIsOpen}
                anchorEl={filePopupParams.anchorEl}
                onClose={() => setFilePopupParams({ popupIsOpen: false, anchorEl: undefined, files: [] })}
            >
                <div className='row no-margin' style={{ display: 'grid' }}>
                    {filePopupParams.files.map(file => (
                        <div className='col s12 clickable'>
                            <a href={file.url} target='_blank'><h6>{file.name}</h6></a>
                            <Divider />
                        </div>
                    ))}
                </div>
            </Popover>
            <ImportXmlQualityPopup
                callbackImport={loadOperations}
                stations={[qualitometer.code]}
                isOpen={importModalIsOpen}
                onClose={() => setImportModalIsOpen(false)}
            />
            <OperationStepper
                isOpen={isOperationPopupOpen}
                onClose={closeOperationPopup}
            />
            {exportModalIsOpen && util.getOperationExportModal(operationsFormatted, setExportModalIsOpen, typeEnvironmentModels, qualitometer)}
        </div>
    )
}

OperationsListApp.propTypes = {
    id: PropTypes.number,
}

export default OperationsListApp