import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import fetch from 'isomorphic-fetch'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import InstallationAction from '../../installation/actions/InstallationAction'
import LogAction from '../../log/actions/LogAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import QualityAction from '../../quality/actions/QualityAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import { STATION_TYPE_NAME } from '../../station/constants/StationConstants'
import { checkAuth, checkError, genericPromise, genericPromise2, getAuthorization, getJson, promiseAllProgress } from '../../utils/ActionUtils'
import { execByType } from '../../utils/StationUtils'
import {
    RECEIVE_ALL_PIEZOMETER_ACTIONS,
    RECEIVE_CAUSE_INTERVENTION,
    RECEIVE_DIAGNOSTICS_AND_SOLUTIONS,
    RECEIVE_EVENT,
    RECEIVE_EVENT_REPLACEMENT_ACTIONS,
    RECEIVE_HYDROMETRY_STATION_ALL_EVENTS,
    RECEIVE_INSTALLATION_ALL_EVENTS,
    RECEIVE_MONITORED_STATIONS_EVENTS,
    RECEIVE_PIEZOMETER_ACTIONS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS, RECEIVE_PIEZOMETER_SOLUTIONS, RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS, RECEIVE_PIEZOMETRY_ALL_EVENTS,
    RECEIVE_QUALITOMETRY_ALL_EVENTS,
    RECEIVE_STATIONS_EVENTS,
    RECEIVE_STATION_EVENTS,
    RECEIVE_TYPES_CATEGORIES,
    RESET_EVENT,
    RESET_STATION_EVENTS,
} from '../constants/EventsConstants'
import { every, flatten } from 'lodash'
import DtoInterventionEvent from 'piezometry/dto/DtoInterventionEvent'

const EventsAction = {
    receiveCategoryTypesSandre(typesCategories) {
        return { type: RECEIVE_TYPES_CATEGORIES, typesCategories }
    },
    fetchCategoryTypesSandre() {
        return (dispatch) => {
            return fetch(ApplicationConf.sandre.getField('CMS.TYPE'), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(EventsAction.receiveCategoryTypesSandre(json))
                })
        }
    },

    receiveEvent(event) {
        return { type: RECEIVE_EVENT, event }
    },

    resetEvent() {
        return { type: RESET_EVENT }
    },

    receiveStationEvents(events) {
        return { type: RECEIVE_STATION_EVENTS, events }
    },

    receiveStationsEvents(events) {
        return { type: RECEIVE_STATIONS_EVENTS, payload: events }
    },

    postFacebook: (type, stationId, eventNum) => dispatch => {
        const service = execByType(type, {
            quality: () => ApplicationConf.qualitometer.eventPostFacebook(stationId, eventNum),
            piezometry: () => ApplicationConf.piezometer.eventPostFacebook(stationId, eventNum),
        })
        return fetch(service, {
            method: 'POST',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = {}) => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.fbPublishError))
                } else {
                    dispatch(ToastrAction.info(i18n.fbPublishing))
                }
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fbPublishError} : ${e}`))
                dispatch(ToastrAction.error(i18n.fbPublishError))
            })
    },

    sendEventByMail: (type, stationId, eventId, emails) => dispatch => {
        const stationType = execByType(type, {
            quality: () => 'qualitometer',
            piezometry: () => 'piezometer',
            pluviometry: () => 'pluviometer',
            hydrometry: () => 'hydrometricStation',
            installation: () => 'installation',
            productionUnit: () => 'productionUnit',
        })
        return fetch(ApplicationConf.event.sendByMail(stationType, stationId, eventId), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(emails),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = {}) => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.mailNotSent))
                } else {
                    dispatch(ToastrAction.success(i18n.mailSent))
                }
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.mailNotSent} : ${e}`))
                dispatch(ToastrAction.error(i18n.mailNotSent))
            })
    },

    fetchStationEvents(type, id) {
        return (dispatch) => {
            const service = execByType(type, {
                quality: () => ApplicationConf.qualitometer.events(id),
                piezometry: () => ApplicationConf.piezometer.events(id),
                pluviometry: () => ApplicationConf.pluviometry.events(id),
                hydrometry: () => ApplicationConf.hydrometricStation.events(id),
                distributionUnit: () => ApplicationConf.distributionUnit.events(id),
            })
            return fetch(service, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(EventsAction.receiveStationEvents(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.events))
                })
        }
    },

    promiseStationsEvents(type, id, method, body) {
        return execByType(type, {
            quality: () => genericPromise(ApplicationConf.qualitometer.events(id), method, body),
            piezometry: () => genericPromise(ApplicationConf.piezometer.events(id), method, body),
            pluviometry: () => genericPromise(ApplicationConf.pluviometry.events(id), method, body),
            hydrometry: () => genericPromise(ApplicationConf.hydrometricStation.events(id), method, body),
            distributionUnit: () => genericPromise(ApplicationConf.distributionUnit.events(id), method, body),
            installation: () => genericPromise(ApplicationConf.installation.events(id), method, body),
        })
    },

    fetchStationsEvents(type, ids, progressCallback = () => {}) {
        return (dispatch) => {
            const promises = ids.map(id => EventsAction.promiseStationsEvents(type, id))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                return flatten(jsonResults)
            })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.events))
                })
        }
    },

    addEvents(type, events, progressCallback = () => {}) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            const promises = events.map(event => EventsAction.promiseStationsEvents(type, event.code, 'POST', event))
            return promiseAllProgress(promises, progressCallback)
                .then(jsonTab => {
                    if (every(jsonTab, result => result.insert === 1)) {
                        dispatch(ToastrAction.success(i18n.eventsSuccessfullyAdded))
                        dispatch(WaitAction.waitStop())
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.events} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.events))
                })
        }
    },

    resetStationEvents() {
        return { type: RESET_STATION_EVENTS }
    },

    fetchEvent(type, stationId, eventCode) {
        return dispatch => {
            const service = execByType(type, {
                quality: () => ApplicationConf.qualitometer.event(stationId, eventCode),
                piezometry: () => ApplicationConf.piezometer.event(stationId, eventCode),
                pluviometry: () => ApplicationConf.pluviometry.event(stationId, eventCode),
                hydrometry: () => ApplicationConf.hydrometricStation.event(stationId, eventCode),
                distributionUnit: () => ApplicationConf.distributionUnit.event(stationId, eventCode),
            })
            return fetch(service, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(EventsAction.receiveEvent(json))
                    if (type === STATION_TYPE_NAME.piezometry) {
                        dispatch(EventsAction.fetchPiezometerActions(json.code, json.number))
                    }
                })
        }
    },

    promiseGeneric: (fetchUrl) => fetchUrl ? fetch(fetchUrl, {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError) : [],

    receiveQualityAllEvents(qualitometryAllEvents) {
        return { type: RECEIVE_QUALITOMETRY_ALL_EVENTS, qualitometryAllEvents }
    },

    promiseQualityAllEvents() {
        return fetch(ApplicationConf.qualitometer.getAllEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchQualiyAllEvents() {
        return (dispatch) => {
            return EventsAction.promiseQualityAllEvents()
                .then(json => {
                    dispatch(EventsAction.receiveQualityAllEvents(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.qualityEvents + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.qualityEvents))
                })
        }
    },

    receivePiezometryAllEvents(piezometryAllEvents) {
        return { type: RECEIVE_PIEZOMETRY_ALL_EVENTS, piezometryAllEvents }
    },

    promisePiezometryAllEvents(eventTypes) {
        return fetch(ApplicationConf.piezometer.getAllEvents(eventTypes), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPiezometryAllEvents(eventTypes) {
        return (dispatch) => {
            return EventsAction.promisePiezometryAllEvents(eventTypes)
                .then(json => {
                    dispatch(EventsAction.receivePiezometryAllEvents(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.piezometryEvents + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.piezometryEvents))
                })
        }
    },
    getPiezometryInterventionEvents: (filters = {}) => {
        const eventTypes = filters.eventTypes?.length ? filters.eventTypes : ['G', 'M', 'P', 'S', 'C']
        return Promise.all(eventTypes.map(t => genericPromise2(ApplicationConf.piezometer.interventionEvents(), {
            method: 'POST',
            headers: getAuthorization(),
            body: { ...filters, eventTypes: [t] },
        }))).then(results => flatten(results).map(e => new DtoInterventionEvent(e)))
    },

    receiveHydrometryAllEvents(hydrometryStationAllEvents) {
        return { type: RECEIVE_HYDROMETRY_STATION_ALL_EVENTS, hydrometryStationAllEvents }
    },

    promiseHydrometryAllEvents() {
        return fetch(ApplicationConf.hydrometricStation.getAllEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchHydrometryAllEvents() {
        return (dispatch) => {
            return EventsAction.promiseHydrometryAllEvents()
                .then(json => {
                    dispatch(EventsAction.receiveHydrometryAllEvents(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.hydrometryEvents + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.hydrometryEvents))
                })
        }
    },

    receiveInstallationAllEvents(installationAllEvents) {
        return { type: RECEIVE_INSTALLATION_ALL_EVENTS, installationAllEvents }
    },

    promiseInstallationAllEvents() {
        return fetch(ApplicationConf.installation.getAllEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchInstallationAllEvents() {
        return (dispatch) => {
            return EventsAction.promiseInstallationAllEvents()
                .then(json => {
                    dispatch(EventsAction.receiveInstallationAllEvents(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.installationEvents + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.installationEvents))
                })
        }
    },

    addEvent(type, stationId, eventData, callback = () => {}) {
        return dispatch => {
            const service = execByType(type, {
                quality: () => ApplicationConf.qualitometer.events(stationId),
                piezometry: () => ApplicationConf.piezometer.events(stationId),
                pluviometry: () => ApplicationConf.pluviometry.events(stationId),
                hydrometry: () => ApplicationConf.hydrometricStation.events(stationId),
                distributionUnit: () => ApplicationConf.distributionUnit.events(stationId),
            })
            return fetch(service, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(eventData),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.eventId) {
                        dispatch(EventsAction.resetEvent())
                        dispatch(EventsAction.fetchEvent(type, stationId, json.eventId))
                        dispatch(EventsAction.fetchStationEvents(type, stationId))
                        dispatch(ToastrAction.success(i18n.eventAddSuccess))
                        callback(json.eventId)
                    } else {
                        dispatch(ToastrAction.error(i18n.addError + i18n.event))
                        dispatch(LogAction.logError(`${i18n.addError + i18n.event} : ${json}`))
                    }
                    return json.eventId
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.event))
                })
        }
    },

    updateEvent(type, stationId, eventCode, eventData, cb = () => {}) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            const service = execByType(type, {
                quality: () => ApplicationConf.qualitometer.event(stationId, eventCode),
                piezometry: () => ApplicationConf.piezometer.event(stationId, eventCode),
                pluviometry: () => ApplicationConf.pluviometry.event(stationId, eventCode),
                hydrometry: () => ApplicationConf.hydrometricStation.event(stationId, eventCode),
                distributionUnit: () => ApplicationConf.distributionUnit.event(stationId, eventCode),
            })
            return fetch(service, {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(eventData),
            })
                .then(checkAuth)
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    dispatch(EventsAction.resetEvent())
                    dispatch(EventsAction.fetchEvent(type, stationId, eventCode))
                    dispatch(EventsAction.fetchStationEvents(type, stationId))
                    dispatch(ToastrAction.success(i18n.eventUpdateSuccess))
                }).then(cb)
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.event))
                })
        }
    },

    deleteEvent(type, stationId, eventCode, cb = () => {}) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            const service = execByType(type, {
                quality: () => ApplicationConf.qualitometer.event(stationId, eventCode),
                piezometry: () => ApplicationConf.piezometer.event(stationId, eventCode),
                pluviometry: () => ApplicationConf.pluviometry.event(stationId, eventCode),
                hydrometry: () => ApplicationConf.hydrometricStation.event(stationId, eventCode),
                distributionUnit: () => ApplicationConf.distributionUnit.event(stationId, eventCode),
            })
            return fetch(service, {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    dispatch(EventsAction.resetEvent())
                    dispatch(EventsAction.fetchStationEvents(type, stationId))
                    dispatch(ToastrAction.success(i18n.eventDeleteSuccess))
                    cb()
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.event))
                })
        }
    },

    fetchMonitoredStations() {
        return dispatch => {
            return fetch(ApplicationConf.station.getMonitoredStations(), {
                method: 'GET',
                headers: getAuthorization(),
            }).then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(EventsAction.receiveMonitoredStationsEvents(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.monitoredStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.monitoredStations))
                })
        }
    },

    receiveMonitoredStationsEvents(stationEvents) {
        return { type: RECEIVE_MONITORED_STATIONS_EVENTS, stationEvents }
    },

    createPiezometryEventWithDiagnostic(stationId, eventWithDiagnostic) {
        return dispatch => {
            return fetch(ApplicationConf.piezometer.eventsWithDiagnostic(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(eventWithDiagnostic),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.eventId) {
                        dispatch(EventsAction.resetEvent())
                        dispatch(EventsAction.fetchEvent('piezometry', stationId, json.eventId))
                        dispatch(EventsAction.fetchStationEvents('piezometry', stationId))
                        dispatch(ToastrAction.success(i18n.eventAddSuccess))
                        return json.eventId
                    }
                    dispatch(ToastrAction.error(i18n.addError + i18n.event))
                    dispatch(LogAction.logError(`${i18n.addError + i18n.event} : ${json}`))
                    return 0
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.event))
                })
        }
    },

    updatePiezometryEventWithDiagnostic(stationId, eventId, eventWithDiagnostic) {
        return dispatch => {
            return fetch(ApplicationConf.piezometer.eventWithDiagnostic(eventId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(eventWithDiagnostic),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.eventId) {
                        dispatch(EventsAction.resetEvent())
                        dispatch(EventsAction.fetchEvent('piezometry', stationId, json.eventId))
                        dispatch(EventsAction.fetchStationEvents('piezometry', stationId))
                        dispatch(ToastrAction.success(i18n.eventUpdateSuccess))
                        return json.eventId
                    }
                    return 0
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.event))
                })
        }
    },

    receivePiezometerDiagnostics(piezometerDiagnostics) {
        return { type: RECEIVE_PIEZOMETER_DIAGNOSTICS, piezometerDiagnostics }
    },

    promisePiezometerDiagnostics: () => fetch(ApplicationConf.piezometer.diagnostic(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPiezometerDiagnostics: () => dispatch => EventsAction.promisePiezometerDiagnostics()
        .then((json = []) => {
            dispatch(EventsAction.receivePiezometerDiagnostics(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    receivePiezometerDiagnosticsLinkMaterials(piezometerDiagnosticsLinkMaterials) {
        return { type: RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS, piezometerDiagnosticsLinkMaterials }
    },

    promisePiezometerDiagnosticsLinkMaterials: () => fetch(ApplicationConf.piezometer.diagnosticLinkMaterial(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPiezometerDiagnosticsLinkMaterials() {
        return dispatch => fetch(ApplicationConf.piezometer.diagnosticLinkMaterial(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                dispatch(EventsAction.receivePiezometerDiagnosticsLinkMaterials(json))
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    receivePiezometerDiagnosticsLinkEventTypes(piezometerDiagnosticsLinkEventTypes) {
        return { type: RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES, piezometerDiagnosticsLinkEventTypes }
    },

    promisePiezometerDiagnosticsLinkEventTypes: () => fetch(ApplicationConf.piezometer.diagnosticLinkEventType(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPiezometerDiagnosticsLinkEventTypes: () => dispatch => EventsAction.promisePiezometerDiagnosticsLinkEventTypes()
        .then((json = []) => {
            dispatch(EventsAction.receivePiezometerDiagnosticsLinkEventTypes(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    receivePiezometerSolutions(piezometerSolutions) {
        return { type: RECEIVE_PIEZOMETER_SOLUTIONS, piezometerSolutions }
    },

    promisePiezometerSolutions: () => fetch(ApplicationConf.piezometer.solution(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPiezometerSolutions: () => dispatch => EventsAction.promisePiezometerSolutions()
        .then((json = []) => {
            dispatch(EventsAction.receivePiezometerSolutions(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    receivePiezometerSolutionsLinkProblems(piezometerSolutionsLinkProblems) {
        return { type: RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS, piezometerSolutionsLinkProblems }
    },

    promisePiezometerSolutionsLinkProblems: () => fetch(ApplicationConf.piezometer.solutionLinkProblem(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPiezometerSolutionsLinkProblems: () => dispatch => EventsAction.promisePiezometerSolutionsLinkProblems()
        .then((json = []) => {
            dispatch(EventsAction.receivePiezometerSolutionsLinkProblems(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    receivePiezometerActions(piezometerActions) {
        return { type: RECEIVE_PIEZOMETER_ACTIONS, piezometerActions }
    },

    promisePiezometerActions: (piezoId, eventId) => fetch(ApplicationConf.piezometer.diagnosticAction(piezoId, eventId), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPiezometerActions: (piezoId, eventId) => dispatch => EventsAction.promisePiezometerActions(piezoId, eventId)
        .then((json = []) => {
            dispatch(EventsAction.receivePiezometerActions(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    receiveAllPiezometerActions: piezometerActions => ({ type: RECEIVE_ALL_PIEZOMETER_ACTIONS, piezometerActions }),

    promiseAllPiezometerActions: piezoId => fetch(ApplicationConf.piezometer.diagnosticActions(piezoId), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchAllPiezometerActions: piezoId => dispatch => EventsAction.promiseAllPiezometerActions(piezoId)
        .then((json = []) => {
            dispatch(EventsAction.receiveAllPiezometerActions(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    promiseEventReplacementActions: (piezoId, date) => fetch(ApplicationConf.piezometer.eventReplacementActions(piezoId, date), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    receiveEventReplacementActions(eventPiezometerReplacementAction) {
        return { type: RECEIVE_EVENT_REPLACEMENT_ACTIONS, eventPiezometerReplacementAction }
    },

    fetchEventReplacementActions: (piezoId, date) => dispatch => EventsAction.promiseEventReplacementActions(piezoId, date)
        .then(json => {
            dispatch(EventsAction.receiveEventReplacementActions(json))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    receiveDiagnosticsAndSolutions(piezometerDiagnostics, piezometerSolutions, piezometerSolutionsLinkProblems, piezometerDiagnosticsLinkEventTypes, piezometerDiagnosticsLinkMaterials) {
        return {
            type: RECEIVE_DIAGNOSTICS_AND_SOLUTIONS,
            piezometerDiagnostics,
            piezometerSolutions,
            piezometerSolutionsLinkProblems,
            piezometerDiagnosticsLinkEventTypes,
            piezometerDiagnosticsLinkMaterials,
        }
    },

    receiveCauseIntervention: (payload) => ({ type: RECEIVE_CAUSE_INTERVENTION, payload }),

    fetchCauseIntervention: (piezoId, eventId) => dispatch => genericPromise2(ApplicationConf.piezometer.causeIntervention(piezoId, eventId))
        .then(json => dispatch(EventsAction.receiveCauseIntervention(json)))
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    createCauseIntervention: (piezoId, eventId, causeInterventions) => dispatch => genericPromise2(ApplicationConf.piezometer.causeIntervention(piezoId, eventId), { method: 'POST', body: causeInterventions })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.createError} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError))
        }),

    fetchDiagnosticsAndSolutions: (piezoId, eventDate) => dispatch => Promise.all([
        EventsAction.promisePiezometerDiagnostics(),
        EventsAction.promisePiezometerSolutions(),
        EventsAction.promisePiezometerSolutionsLinkProblems(),
        EventsAction.promisePiezometerDiagnosticsLinkEventTypes(),
        EventsAction.promisePiezometerDiagnosticsLinkMaterials(),
        EventsAction.promiseEventReplacementActions(piezoId, eventDate),
    ]).then(jsonTab => {
        dispatch(EventsAction.receiveDiagnosticsAndSolutions(jsonTab[0], jsonTab[1], jsonTab[2], jsonTab[3], jsonTab[4]))
        dispatch(EventsAction.receiveEventReplacementActions(jsonTab[5]))
    }),

    loadGlobalEventsApp(callback = () => {}, progressCallback = () => {}) {
        return function (dispatch) {
            return promiseAllProgress([
                EventsAction.promiseQualityAllEvents(),
                EventsAction.promisePiezometryAllEvents(),
                EventsAction.promiseHydrometryAllEvents(),
                EventsAction.promiseInstallationAllEvents(),
                QualityAction.promiseQualitometersLight(),
                PiezometryAction.promisePiezometersLight(),
                HydrometryAction.promiseHydrometricStations(),
                InstallationAction.promiseInstallations(),
                CityAction.promiseCities(),
                VariousMaterielAction.promiseVariousMateriels(),
            ], progressCallback).then(jsonTab => {
                dispatch(EventsAction.receiveQualityAllEvents(jsonTab[0]))
                dispatch(EventsAction.receivePiezometryAllEvents(jsonTab[1]))
                dispatch(EventsAction.receiveHydrometryAllEvents(jsonTab[2]))
                dispatch(EventsAction.receiveInstallationAllEvents(jsonTab[3]))
                dispatch(QualityActionConstant.receiveQualitometersLight(jsonTab[4]))
                dispatch(PiezometryActionConstant.receiveAllPiezometersLight(jsonTab[5]))
                dispatch(HydrometryActionConstant.receiveAllHydrometricStations(jsonTab[6]))
                dispatch(InstallationActionConstant.ALL_INSTALLATIONS(jsonTab[7]))
                dispatch(CityAction.receiveCities(jsonTab[8]))
                dispatch(VariousMaterielAction.receiveVariousMateriels(jsonTab[9]))
                callback()
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },
}

export default EventsAction
