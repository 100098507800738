import { pick, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from '../../../components/card/Card'
import ChartTabs from '../../../components/echart/ChartTabs'
import ProgressBar from '../../../components/progress/ProgressBar'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import StationAction from '../../../station/actions/StationAction'
import DtoMeasureStats from '../../../station/dto/piezometer/DtoMeasureStats'
import DtoPiezoThreshold from '../../../station/dto/piezometer/DtoPiezoThreshold'
import { getLocalStorageJson } from '../../../utils/FormUtils'
import { getHardPiezoDataTypes } from '../../../utils/PiezometryUtils'
import { arrayOf, instanceOf } from '../../../utils/StoreUtils'
import PiezometryAction from '../../actions/PiezometryAction'
import { HIDDEN_CHARTS, MEASURE_COTE } from '../../constants/PiezometryConstants'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'
import PiezometryExploitedChartPanel from './PiezometryExploitedChartPanel'
import DtoPiezometer from '../../dto/DtoPiezometer'
import { WhiteCard } from 'components/styled/Card'
import Row from 'components/react/Row'
import AppStore from '../../../store/AppStore'
import IAEauAction from '../../../iaeau/IAEauAction'

// TODO ATTENTION ! Composant réutilisé dans plusieurs écrans
class PiezometryExploitedPanel extends Component {
    constructor(props) {
        super(props)
        const storedHiddenCharts = getLocalStorageJson(HIDDEN_CHARTS) || {}
        this.state = {
            minDate: null,
            hiddenCharts: storedHiddenCharts,
            displayCote: props.displayCote || getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH, // cote de calcul de la hauteur d'eau affichée (Profondeur, NGF, sol, ...)
            dataLoaded: false,
            progress: 0,
            displayModes: getLocalStorageJson('PIEZO_TAB_DISPLAY_MODES') ?? { // différents modes d'affichage disponibles
                auto: true,
                brute: false,
                min: false,
                max: false,
                average: false,
                // ici, il y a aussi les checkbox des modèles (la clé est le nom du modèle)
            },
            predMeasures: [],
            selectedPred: undefined,
            predDate: undefined,
            predStats: [],
        }
    }

    componentDidMount() {
        this.props.fetchPiezometer(this.props.id)
        this.props.fetchPiezometerThresholds(this.props.id, MEASURE_COTE.NGF)
        this.props.fetchPiezoMeasuresStats(parseInt(this.props.id))
        if (this.props.piezometerThresholds.length) {
            this.setState({ piezometerThresholds: this.props.piezometerThresholds.filter(t => t.code === this.props.piezometer.code) })
        }
        if (!this.props.piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes()
        }
        this.props.fetchPiezometerChartOptions(this.props.id)
        AppStore.dispatch(IAEauAction.getModels('piezometry', parseInt(this.props.id)))
    }

    loadData = (dates) => {
        const piezoStat = this.props.piezometerStatistics.find(p => p.typeId === -1) || {}
        const fetchDates = { startDate: dates.minDate ? moment(dates.minDate).subtract(6, 'hours').valueOf() : undefined, endDate: dates.maxDate }
        const duration = piezoStat ? moment.duration(moment(fetchDates.endDate || moment().valueOf()).diff(fetchDates.startDate)) : null
        const autoDisplayMode = duration ? (duration.as('days') >= 91 ? 'MAX' : 'RAW') : 'MAX'
        const sampleDisplayMode = duration ? (duration.as('days') >= 91 ? 'SUM' : 'RAW') : 'SUM'

        const baseInput = { ...fetchDates, stationId: parseInt(this.props.id), displayCote: 0 }

        const modes = this.state.displayModes
        const piezoModes = uniq([
            modes.brute ? 'RAW' : (modes.auto ? autoDisplayMode : null),
            modes.max ? 'MAX' : null,
            modes.min ? 'MIN' : null,
            modes.average ? 'AVERAGE' : null,
        ].filter(m => !!m))
        const piezo = piezoModes.map(p => ({
            ...baseInput,
            displayCote: this.state.displayCote,
            groupFunc: p,
            dataType: -1,
        }))

        const sampleFound = this.props.piezometerStatistics.find(p => p.typeId === -2)
        const sample = !sampleFound ? [] : [{
            ...baseInput,
            dataType: -2,
            groupFunc: sampleDisplayMode,
        }]

        const other = this.props.piezometerStatistics.filter(p => p.typeId > 0).map(p => ({
            ...baseInput,
            dataType: p.typeId,
            groupFunc: autoDisplayMode,
            displayCote: p.isPiezo ? this.state.displayCote : null,
        }))

        const aditionnalData = this.props.noAdditionnalData ? [] : [...sample, ...other]

        this.setState(Object.assign({}, dates, { dataLoaded: false, progress: 0 }))

        this.props.loadPiezoChartMeasures([ ...piezo, ...aditionnalData], p => this.setState({ progress: p })).then(() => {
            this.setState({ dataLoaded: true })
            this.props.setDataLoaded()
        })
    }

    changeDates = (dates, forced) => {
        if (this.state.minDate !== dates.minDate || this.state.maxDate !== dates.maxDate || forced) {
            this.loadData(dates)
        }
    }

    getProgressBar = () => (
        <Card>
            <div className='padding-top-7 padding-left-2 padding-right-2 padding-bottom-2'>
                <ProgressBar progress={ this.state.progress } withMessage/>
            </div>
        </Card>
    )

    getPanel = () => {
        const picked = pick(this.state, ['minDate', 'maxDate', 'hiddenCharts', 'displayCote', 'displayModes', 'predMeasures', 'selectedPred', 'predStats'])
        return (
            <div className='side-component-content'>
                { this.state.dataLoaded && this.props.piezometer ? <PiezometryExploitedChartPanel {...picked} /> : this.getProgressBar() }
            </div>
        )
    }

    render() {
        if (this.props.piezometerStatistics.length) {
            return (
                <WhiteCard round noBoxShadow>
                    <Row className={this.state.fullScreen ? 'fullscreen-chart' : ''}>
                        <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                            <ChartTabs
                                onChangeDate={ (changes, forced) => this.changeDates(changes, forced) }
                                displayCote={this.props.displayCote}
                                depthMode={this.props.depthMode}
                                withPiezoCote
                                options={getHardPiezoDataTypes().concat(this.props.piezometryDataTypes)}
                                changeParent={ changes => this.setState(changes, () => this.loadData(pick(this.state, ['minDate', 'maxDate']))) }
                                changeParentSimple={ changes => this.setState(changes) }
                                withModels
                                id={this.props.id}
                                stationType='piezometry'
                                useFromSituation={this.props.useFromSituation}
                            />
                        </div>
                        <div className='col s12 row no-margin'>
                            {this.getPanel()}
                        </div>
                    </Row>
                </WhiteCard >
            )
        }
        return null
    }
}

PiezometryExploitedPanel.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
    }),
    id: PropTypes.string,
    piezometer: instanceOf(DtoPiezometer),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    setDataLoaded: PropTypes.func,
    piezometerStatistics: arrayOf(DtoMeasureStats),
    fetchPiezoMeasuresStats: PropTypes.func,
    fetchPiezometerChartOptions: PropTypes.func,
    fetchPiezometer: PropTypes.func,
    fetchPiezometryDataTypes: PropTypes.func,
    noAdditionnalData: PropTypes.bool, // permet de ne pas afficher les données complémentaires,
    displayCote: PropTypes.number, // mode d'affichage piézo par défaut
    depthMode: PropTypes.bool, // permet d'afficher uniquement le mode profondeur
    fetchPiezometerThresholds: PropTypes.func,
    showTitle: PropTypes.bool,
    loadPiezoChartMeasures: PropTypes.func,
    useFromSituation: PropTypes.bool,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometerThresholds: store.PiezometerStationReducer.piezometerThresholds,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
})

const mapDispatchToProps = {
    fetchPiezoMeasuresStats: PiezometerStationAction.fetchPiezoMeasuresStats,
    fetchPiezometer: StationAction.fetchPiezometer,
    loadPiezoChartMeasures: PiezometerStationAction.loadPiezoChartMeasures,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    fetchPiezometerChartOptions: PiezometerStationAction.fetchPiezometerChartOptions,
    fetchPiezometerThresholds: PiezometerStationAction.fetchPiezometerThresholds,
}

PiezometryExploitedPanel.defaultProps = {
    setDataLoaded: () => {},
    showTitle: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryExploitedPanel)
