import { Grid } from '@mui/material'
import AdministrationAction from 'administration/actions/AdministrationAction'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import ProgressCard from 'components/card/ProgressCard'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { PATH_DISTRIBUTION, PATH_DISTRIBUTION_OPTIONS } from 'home/constants/RouteConstants'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import Card from '../../../components/card/Card'
import DtoDataType from '../../../station/dto/DtoDataType'
import SieauParameterDto from '../../dto/SieauParameterDto'
import { SIEAU } from '../user/constants/StatisticConstants'
import { flatten, isNil, orderBy } from 'lodash'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import QualityAction from 'quality/actions/QualityAction'

const UDI_REFERENCE_THRESHOLD = 'UDI_REFERENCE_THRESHOLD'

const MultiThresholdSelect = ({
    title = '',
    values = [],
    setValues = () => { },
    disabled = false,
    thresholdType = '0',
}) => {
    const dispatch = useDispatch()
    const {
        thresholds,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
    }), shallowEqual)

    useEffect(() => {
        dispatch(QualityAction.fetchThresholds())
    }, [])


    const formatThresholds = useCallback((thresholdFiltered, dephValue, orderedThresholds) => {
        return flatten(thresholdFiltered.map(({ id, code, name }) => {
            const childrens = formatThresholds(orderedThresholds.filter(({ referenceCode }) => referenceCode == code), dephValue + 1, orderedThresholds)
            return [
                {
                    id,
                    code,
                    name,
                    elements: childrens.map(c => ({ id: c.id, code: c.code, name: c.name })),
                },
            ]
        }))
    }, [])

    const thresholdsFiltered = useMemo(() => thresholds.filter(th => (th.thresholdType || '0') === thresholdType), [thresholdType, thresholds])
    const thresholdsOrdered = useMemo(() => orderBy(thresholdsFiltered.filter(th => th.status == 1), 'name'), [thresholdsFiltered])
    const formatedThresholds = useMemo(() => formatThresholds(thresholdsOrdered.filter(({ referenceCode }) => !referenceCode), 0, thresholdsOrdered), [formatThresholds, thresholdsOrdered])

    const thresholdsFormatted = useMemo(() => thresholdsOrdered.map(c => ({ id: c.id, code: c.code, name: c.name })), [thresholdsOrdered])
    return (
        <SuperMultiAutocomplete
            label={title || i18n.threshold}
            options={thresholdsFormatted}
            optionsGroups={formatedThresholds}
            onChange={setValues}
            values={values}
            disabled={disabled}
            groupsCanBeSelected
            groupsAreValues
            multiple
            limit={3}
        />
    )
}

MultiThresholdSelect.propTypes = {
    title: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.number),
    setValues: PropTypes.func,
    disabled: PropTypes.bool,
    thresholdType: PropTypes.string,
}

const AdminUDIApp = () => {
    const {
        globalParameters,
        accountUser,
    } = useSelector(store => ({
        globalParameters: store.AdministrationReducer.globalParameters,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const defaultThresholds = useMemo(() => {
        const values = globalParameters.find(param => param.parameter === UDI_REFERENCE_THRESHOLD)?.value
        return !isNil(values) ? values.split(',').filter(v => !!v).map(v => parseInt(v)) : []
    }, [globalParameters])

    const [readMode, setReadMode] = useState(true)
    const [thresholds, setThresholds] = useState(defaultThresholds)

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.distributionUnit,
        href: PATH_DISTRIBUTION,
    }, {
        title: i18n.distributionUnitOptions,
        href: PATH_DISTRIBUTION_OPTIONS,
    }], [])

    const {
        isLoaded: dataIsLoaded,
        progress: dataProgress,
    } = useProgressDispatch(() => [
        dispatch(AdministrationAction.fetchGlobalParameters()),
    ], [])

    useEffect(() => {
        setThresholds(defaultThresholds)
    }, [defaultThresholds])

    const onCancel = () => {
        setThresholds(defaultThresholds)
        setReadMode(true)
    }

    const onSave = () => {
        const param = new GlobalParametersDto({
            parameter: UDI_REFERENCE_THRESHOLD,
            module: SIEAU,
            value: thresholds.join(','),
            updateDate: moment().valueOf(),
            updateLogin: accountUser.login,
        })
        dispatch(AdministrationAction.updateGlobalParameter(param)).then(json => {
            if (json > 0) {
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                dispatch(AdministrationAction.fetchGlobalParameters())
            }
        })
        setReadMode(true)
    }

    useActions(() => {
        if (readMode) {
            return {
                edit: () => setReadMode(false),
            }
        }
        return {
            save: onSave,
            cancel: onCancel,
        }
    }, [readMode, thresholds])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card title={i18n.stationsSetting} className='margin-left-1 margin-right-1' noMargin={false}>
                    {!dataIsLoaded ? (
                        <ProgressCard progress={dataProgress} className='padding-top-4' />
                    ) : (
                        <Grid container sx={{ padding: '0 10px 10px' }}>
                            <Grid item xs={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.qualityOptions}</StyledLegend>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <MultiThresholdSelect
                                                title={i18n.referenceThreshold}
                                                setValues={setThresholds}
                                                values={thresholds}
                                                disabled={readMode}
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                            </Grid>
                        </Grid>
                    )}
                </Card>
            </Grid>
        </Grid>
    )
}

AdminUDIApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    filteredDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDataType)),
    setTitle: PropTypes.func,
    updateDataTypesByProject: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    push: PropTypes.func,
    updateSieauParameters: PropTypes.func,
}

export default AdminUDIApp
