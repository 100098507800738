export default class DtoCmsModel {
    constructor(obj) {
        this.modelId = obj.modelId
        this.categoryId = obj.categoryId
        this.title = obj.title
        this.subTitle = obj.subTitle
        this.planification = obj.planification
        this.hourGeneration = obj.hourGeneration
        this.hourPublication = obj.hourPublication
        this.recipients = obj.recipients
        this.documentModel = obj.documentModel
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.loginMaj = obj.loginMaj
        this.dateMaj = obj.dateMaj
    }
}
