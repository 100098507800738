import { WhiteCard } from 'components/styled/Card'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import Table from 'components/datatable/Table'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import QualityAction from 'quality/actions/QualityAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useListIndexed from 'utils/customHook/useListIndexed'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { calculateThresholdResult, calculateValue, getLocalizationPicto, getThresholdLabel } from 'utils/AnalyseUtils'
import OperationAction from 'quality/actions/OperationAction'
import { flatten, isNil, orderBy } from 'lodash'
import Icon from 'components/icon/Icon'
import useBoolean from 'utils/customHook/useBoolean'
import { Popover } from '@mui/material'
import { getDate } from 'utils/DateUtil'
import { onChangeDate } from 'utils/FormUtils'
import Input from 'components/forms/Input'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'
import MessageCard from 'components/card/MessageCard'
import { exportFile } from 'utils/ExportDataUtil'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import OldOperationAction from 'quality/components/operation/actions/OperationAction'
import PropTypes from 'prop-types'
import useGlobalParameter from 'utils/customHook/useGlobalParameter'
import { listStringParser } from 'utils/customHook/useApplicationSetting'
import { SuperParameterGraphModal } from 'quality/components/qualityComponents/ParameterGraph'
import useSandreParameters from 'utils/customHook/useSandreParameters'
import { statusIcon } from 'utils/StatusUtil'

const UDI_REFERENCE_THRESHOLD = 'UDI_REFERENCE_THRESHOLD'

const SampleResultTable = ({
    analysis = [],
    thresholds = [],
}) => {
    const {
        parameters,
        units,
        thresholdsProps,
        qualityThresholds,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        thresholdsProps: store.QualityReducer.thresholds,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }))

    const parametersSandre = useSandreParameters()

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedUnits = useListIndexed(units, 'code')

    const { value: isOpen, setFalse: onClose, setTrue: onOpen } = useBoolean(false)
    const [selectedParameter, setSelectedParameter] = useState()
    const [selectedQualitometer, setSelectedQualitometer] = useState()

    const data = analysis.map(a => {
        const thresholdsResults = thresholds.reduce((acc, th, i) => {
            const threshold = qualityThresholds.find(qth => qth.thresholdCode === th)
            const result = calculateThresholdResult(a, threshold?.thresholds)
            const label = getThresholdLabel(result.thresholdIndice, result.threshold)
            acc[`threshold${i}`] = {
                value: label,
                classNameColor: result.color,
                setTooltip: () => (
                    <TooltipAnalyse
                        title={indexedParameters[a.parameter]?.displayLabel ?? ''}
                        analyse={{ ...a, ...result }}
                        threshold={result.threshold}
                    />
                ),
            }
            return acc
        }, {})
        return {
            nullValue: { value: statusIcon(a, 15, true), cellStyle: { width: '36px' } },
            place: { value: getLocalizationPicto(a.localization), cellStyle: { width: '60px' } },
            parameter: { value: indexedParameters[a.parameter]?.displayLabel },
            value: {
                value: parametersSandre[a?.parameter]?.find(ps => ps.code === a.result)?.name ?? calculateValue(a),
                setTooltip: () => (
                    <TooltipAnalyse
                        title={indexedParameters[a.parameter]?.displayLabel ?? ''}
                        analyse={a}
                    />
                ),
            },
            unit: { value: indexedUnits[a.unit]?.symbolWithCode },
            ...thresholdsResults,
            paramCode: a.parameter,
            qualitoCode: a.qualitometer,
        }
    })

    const thresholdsHeaders = thresholds.map((_, i) => `threshold${i}`)
    const thresholdsNameHeaders = thresholds.reduce((acc, th, i) => {
        const threshold = thresholdsProps.find(t => `${t.code}` === th)
        acc[`threshold${i}`] = threshold?.name ?? i18n.unknownThreshold
        return acc
    }, {})

    return (
        <>
            <Table
                showTitle={false}
                withCard={false}
                data={orderBy(data, a => a.parameter.value?.toLowerCase())}
                type={{ headers: ['nullValue', 'place', 'parameter', 'value', 'unit', ...thresholdsHeaders] }}
                customHeaders={thresholdsNameHeaders}
                nbPerPageLabel={nbPerPageLabel}
                onClick={({ paramCode, qualitoCode }) => {
                    setSelectedParameter(paramCode)
                    setSelectedQualitometer(qualitoCode)
                    onOpen()
                }}
                condensed
                sortable
                color
                paging
            />
            <SuperParameterGraphModal
                isOpen={isOpen}
                closeGraph={onClose}
                parameter={selectedParameter}
                qualitometer={selectedQualitometer}
            />
        </>
    )
}

SampleResultTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    thresholds: PropTypes.arrayOf(PropTypes.string),
}

const Calendar = ({
    setSelected = () => {},
    operations = [],
}) => {
    const {
        value: isOpen,
        toggle: toggleOpen,
    } = useBoolean(false)
    const anchorEl = useRef()

    const [date, setDate] = useState()

    useEffect(() => {
        if (isNil(date) || operations.length === 0) return

        const index = operations.findIndex(o => o.date <= date)
        setSelected(index === -1 ? undefined : index)
    }, [date, operations])

    return (
        <>
            <Icon
                tooltip={i18n.date}
                icon={'date_range'}
                ref={anchorEl}
                style={{ color: 'black' }}
                onClick={toggleOpen}
            />
            <Popover
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={toggleOpen}
            >
                <Input
                    value={getDate(date)}
                    onChange={v => onChangeDate(v, setDate)}
                    style={{ margin: 0, height: '2rem' }}
                    noInputFieldClass
                />
            </Popover>
        </>
    )
}

Calendar.propTypes = {
    setSelected: PropTypes.func,
    operations: PropTypes.arrayOf(PropTypes.shape({})),
}

const UDIQualityPanel = ({
    ids = [],
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        cities,
        parameters,
        units,
        thresholdsProps,
        qualityThresholds,
        remarks,
        fractions,
        supports,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        thresholdsProps: store.QualityReducer.thresholds,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        remarks: store.OperationReducer.remarks,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedUnits = useListIndexed(units, 'code')

    const thresholds = useGlobalParameter(UDI_REFERENCE_THRESHOLD, listStringParser)

    useEffect(() => {
        dispatch(QualityAction.fetchThresholds())
        dispatch(SupportAction.fetchSupports())
        dispatch(FractionAction.fetchFractions())
        dispatch(OldOperationAction.fetchRemarks())
    }, [dispatch])

    useEffect(() => {
        thresholds.forEach(code => dispatch(QualityAction.fetchQualityThresholds([{ code, thresholdType: '0' }])))
    }, [dispatch, thresholds])

    const [selected, setSelected] = useState() // index

    const [operations, setOperations] = useState([])
    useEffect(() => {
        Promise.all(ids.map(id => OperationAction.getOperations(id))).then((list = []) => {
            const listOperations = orderBy(flatten(list), ['date', 'id'], ['desc', 'asc'])
            setOperations(listOperations)
            if (listOperations.length) {
                setSelected(0)
            }
        })
    }, [ids])

    const [analysis, setAnalysis] = useState([])
    useEffect(() => {
        if (isNil(selected)) {
            setAnalysis([])
            return
        }
        const ope = operations[selected]
        const criteria = {
            lightMode: true,
            stations: [ope.qualitometer],
            operations: [ope.id],
        }
        AnalysisAction.getAnalysis(criteria).then((list = []) => {
            setAnalysis(list.map(a => new DtoAnalysisLight(a)))
        })
    }, [operations, selected])

    const title = useMemo(() => {
        const ope = operations[selected]
        if (isNil(ope)) return i18n.noOperationsDuringThisPeriod

        const qualitometer = qualitometers.find(q => q.id === ope?.qualitometer)
        const city = cities.find(c => c.code === qualitometer?.townCode)
        return `${qualitometer?.code ?? ''} ${city?.name ?? ''} ${getDate(ope?.date)}`
    }, [cities, operations, qualitometers, selected])

    const actions = [
        {
            iconName: 'file_download',
            tooltip: i18n.export,
            color: analysis.length === 0? 'gray' : 'black',
            onClick: () => {
                if (analysis.length === 0) return

                const formattedAnalysis = analysis.map(a => {
                    const thresholdsResults = thresholds.reduce((acc, th, i) => {
                        const threshold = qualityThresholds.find(qth => qth.thresholdCode === th)
                        const result = calculateThresholdResult(a, threshold?.thresholds)
                        acc[`threshold${i}`] = {
                            value: getThresholdLabel(result.thresholdIndice, result.threshold),
                            color: result.color,
                        }
                        return acc
                    }, {})
                    const remark = remarks.find(r => r.code === a.remark)
                    const fraction = fractions.find(f => f.id === a.fraction)
                    const support = supports.find(s => s.code === `${a.support}`)
                    return {
                        parameterCode: a.parameter,
                        parameter: indexedParameters[a.parameter]?.displayLabel,
                        value: calculateValue(a),
                        unitCode: a.unit,
                        unit: indexedUnits[a.unit]?.symbolWithCode,
                        remarkCode: { value: a.remark, format: '0', cellType: 'number' },
                        remark: remark?.name,
                        supportCode: { value: a.support, format: '0', cellType: 'number' },
                        support: support?.name,
                        fractionCode: { value: a.fraction, format: '0', cellType: 'number' },
                        fraction: fraction?.name,
                        ...thresholdsResults,
                    }
                })

                const thresholdsHeaders = thresholds.map((_, i) => `threshold${i}`)
                const thresholdsNameHeaders = thresholds.map(th => {
                    const threshold = thresholdsProps.find(t => `${t.code}` === th)
                    return threshold?.name ?? i18n.unknownThreshold
                })
                const baseHeaders = ['parameterCode', 'parameter', 'value', 'unitCode', 'unit', 'remarkCode', 'remark', 'supportCode', 'support', 'fractionCode', 'fraction']
                const headers = [...baseHeaders, ...thresholdsHeaders]
                const customHeaders = [...baseHeaders, ...thresholdsNameHeaders]
                const orderedAnalysis = orderBy(formattedAnalysis, 'parameter')
                const data = orderedAnalysis.length ? [{ ...orderedAnalysis[0], headers, customHeaders }, ...orderedAnalysis.slice(1)] : []
                exportFile({
                    data,
                    exportType: 'xlsx',
                    titleFile: title,
                })
            },
        },
    ]

    const previousDisabled = isNil(selected) || selected === operations.length - 1
    const nextDisabled = isNil(selected) || selected === 0

    const actionsComponents = [
        <Icon
            tooltip={i18n.previousOperation}
            icon={'chevron_left'}
            style={{ color: previousDisabled ? 'gray' : 'black' }}
            onClick={() => !previousDisabled && setSelected(p => p + 1)}
        />,
        <Icon
            tooltip={i18n.nextOperation}
            icon={'chevron_right'}
            style={{ color: nextDisabled ? 'gray' : 'black' }}
            onClick={() => !nextDisabled && setSelected(p => p - 1)}
        />,
        <Calendar
            setSelected={setSelected}
            operations={operations}
        />,
    ]


    return (
        <WhiteCard
            title={title}
            actions={actions}
            actionsComponents={actionsComponents}
            round
            noMargin={false}
            className='margin-bottom-1'
        >
            {
                analysis.length === 0 && (
                    <MessageCard>{i18n.noResults}</MessageCard>
                )
            }
            {
                analysis.length > 0 && (
                    <SampleResultTable
                        analysis={analysis}
                        thresholds={thresholds}
                    />
                )
            }
        </WhiteCard>
    )
}

UDIQualityPanel.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
}

export default UDIQualityPanel