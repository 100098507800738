module.exports = {
    SIEAU_TOKEN: 'sieau-token',
    MODEL_EXPORT: 0,
    CSV_EXPORT: 1,
    EXCEL_EXPORT: 2,
    EXPORT_TYPE_OPTIONS: [
        { value: 0, name: 'Modèles' },
        { value: 1, name: 'CSV' },
        { value: 2, name: 'Excel' },
    ],
    EXPORT_STATIONS_MEASURES: 'stationsMeasures',
    EXPORT_STATIONS_MODELS: 'stationsModels',
    EXPORT_TMP_QUALITY_REASEARCH: 'qualityResearch',
    EXPORT_JOB_STATUS: {
        WAITING: 1,
        IN_PROGRESS: 2,
        FINISHED: 3,
        ERROR: 4,
    },
}
