import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_PIEZO_OPTIONS } from '../../../account/constants/AccessRulesConstants'
import Card from '../../../components/card/Card'
import Checkbox from '../../../components/forms/Checkbox'
import Select from '../../../components/forms/Select'
import HomeAction from '../../../home/actions/HomeAction'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import NetworkAction from '../../../referencial/components/network/actions/NetworkAction'
import NetworkDto from '../../../referencial/components/network/dto/NetworkDto'
import StationAction from '../../../station/actions/StationAction'
import DtoDataType from '../../../station/dto/DtoDataType'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getSetting, getSettingInt } from '../../../utils/SettingUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import SieauParameterDto from '../../dto/SieauParameterDto'
import DataTypesTable from '../dataTypes/DataTypesTable'
import ShowAssociatedDataRuleTable from '../dataTypes/ShowAssociatedDataRuleTable'
import RadioButtons from '../../../components/forms/RadioButtons'
import { setActions } from '../../../components/ActionUtil'
import Button from '../../../components/forms/Button'
import FrequencyStatCalculationMethodPopup from './FrequencyStatCalculationMethodPopup'

class AdminPiezometerApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            applicationSettings: props.applicationSettings,
            readMode: true,
            dataLoaded: false,
            project: 'SIES',
            frequencyCalculationPopupIsOpen: false,
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PIEZO_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setTitle([{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: i18n.piezometryOptions,
            href: 'piezometry/piezometryOptions',
        }])
        if (!this.props.networks.length) {
            this.props.fetchNetworks()
        }
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        this.setDataTypes()
        setTimeout(() => this.setReadMode(), 500)
    }

    setDataTypes = () => {
        const { project } = this.state
        this.props.fetchDataTypesByProject(project).then(() => {
            const { filteredDataTypes } = this.props
            this.setState({ dataLoaded: true, dataTypes: filteredDataTypes })
        })
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        setActions({
            edit: () => this.setEditMode(),
        })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        setActions({
            save: () => this.onSave(),
            cancel: () => this.onCancel(),
        })
    }

    onCancel = () => {
        const { filteredDataTypes, applicationSettings } = this.props
        this.setState({ dataTypes: filteredDataTypes, applicationSettings })
        this.setReadMode()
    }

    onSave = () => {
        const { dataTypes, project } = this.state
        this.setState({ dataLoaded: false })
        const applicationSettings = this.state.applicationSettings.filter(e => e.value !== getSetting(this.props.applicationSettings, e.parameter))
        if (applicationSettings.length) {
            this.props.updateSieauParameters(applicationSettings)
        }
        this.props.updateDataTypesByProject(project, dataTypes).then(() => {
            this.setDataTypes()
        })
        this.setReadMode()
    }

    onChangeSetting = (setting, value) => {
        const stateObj = this.state.applicationSettings.filter(e => e.parameter !== setting)
        this.setState({ applicationSettings: [...stateObj, { parameter: setting, value: value ? value.toString() : value }] })
    }

    render() {
        const { applicationSettings, readMode, dataLoaded, dataTypes, project } = this.state
        const enveloppeCalculationOptions = [
            { value: 'false', label: i18n.calculateOnMaxData },
            { value: 'true', label: i18n.calculateOnRawData },
        ]
        const frequencyCalculationMethods = [
            { code: 'normal', label: 'Loi normale' },
            { code: 'trunc', label: 'Loi normale tronquée' },
            { code: 'percent', label: 'Pourcentage par rapport à la moyenne' },
            { code: 'noLaw', label: 'Sans loi statistique' },
        ]

        return (
            <div className='padding-1'>
                <Card title={ i18n.piezometryOptions }>
                    <div className='row no-margin padding-top-1'>
                        <Select disabled={readMode} col={ 5 } label={ i18n.synchNetwork } options={ this.props.networks } value={ getSettingInt(applicationSettings, 'adesPiezoSynchroNetwork') }
                            onChange={ v => this.onChangeSetting('adesPiezoSynchroNetwork', v) } keyValue='id' keyLabel='mnemonic'
                        />
                        <RadioButtons col={ 7 } title={ i18n.enveloppeStatCalculation } selected={getSetting(applicationSettings, 'CalculateEnveloppeOnRawData') || 'false'}
                            onChange={ v => this.onChangeSetting('CalculateEnveloppeOnRawData', v) } elements={ enveloppeCalculationOptions } disabled={readMode}
                        />
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <h5>Méthode de calcul des périodes de retour</h5>
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <p>Cette méthode sera utilisée pour calculer les périodes de retour à la fois sur les modules Piézométrie et Hydrométrie, pour les affichages sur les graphiques et les calculs des classes dans les cartes de situation.</p>
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1 valign-wrapper'>
                        <div className='col s3'>
                            <Button title='Changer de méthode de calcul' icon='edit' onClick={() => this.setState({ frequencyCalculationPopupIsOpen: true })} disabled={readMode}/>
                        </div>
                        <div className='col s9'>
                            <h6>Méthode actuelle : {getLabel(frequencyCalculationMethods, getSetting(applicationSettings, 'FrequencyStatMode') ?? 'normal')}</h6>
                        </div>
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <h5>{ i18n.validationScreenDefaultValues }</h5>
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Select disabled={readMode} col={ 4 } label={ i18n.producer } options={ this.props.contributors } value={ getSettingInt(applicationSettings, 'validationDefaultProducer') }
                            onChange={ v => this.onChangeSetting('validationDefaultProducer', v) } keyValue='id' keyLabel='mnemonique'
                        />
                        <Select disabled={readMode} col={ 4 } label={ i18n.administrator } options={ this.props.contributors } value={ getSettingInt(applicationSettings, 'validationDefaultManager') }
                            onChange={ v => this.onChangeSetting('validationDefaultManager', v) } keyValue='id' keyLabel='mnemonique'
                        />
                        <Select disabled={readMode} col={ 4 } label={ i18n.validator } options={ this.props.contributors } value={ getSetting(applicationSettings, 'validationDefaultValidator') }
                            onChange={ v => this.onChangeSetting('validationDefaultValidator', v) } keyValue='id' keyLabel='mnemonique'
                        />
                    </div>
                </Card>
                {dataLoaded && (
                    <DataTypesTable
                        project={project}
                        dataTypes={dataTypes}
                        readMode={readMode}
                        onChange={(data) => this.setState({ dataTypes: data })}
                    />
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={applicationSettings}
                        fromStationType='PIEZO'
                        toStationType='HYDRO'
                        onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                        readMode={readMode}
                    />
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={applicationSettings}
                        fromStationType='PIEZO'
                        toStationType='PLUVIO'
                        onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                        readMode={readMode}
                    />
                )}
                <div className='padding-top-9' />
                <FrequencyStatCalculationMethodPopup
                    selectedMode={getSetting(applicationSettings, 'FrequencyStatMode') ?? 'normal'}
                    changeMode={mode => this.onChangeSetting('FrequencyStatMode', mode)}
                    onClose={() => this.setState({ frequencyCalculationPopupIsOpen: false })}
                    isOpen={this.state.frequencyCalculationPopupIsOpen}
                />
            </div>
        )
    }
}

AdminPiezometerApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    filteredDataTypes: arrayOf(DtoDataType),
    networks: arrayOf(NetworkDto),
    contributors: arrayOf(ContributorDto),
    fetchNetworks: PropTypes.func,
    fetchContributors: PropTypes.func,
    setTitle: PropTypes.func,
    updateSieauParameters: PropTypes.func,
    updateDataTypesByProject: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    info: PropTypes.func,
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    networks: store.NetworkReducer.networks,
    contributors: store.ContributorReducer.contributors,
    filteredDataTypes: store.StationReducer.filteredDataTypes,
})

const mapDispatchToProps = {
    fetchNetworks: NetworkAction.fetchNetworks,
    fetchContributors: ContributorAction.fetchContributors,
    setTitle: HomeAction.setTitle,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    updateDataTypesByProject: StationAction.updateDataTypesByProject,
    fetchDataTypesByProject: StationAction.fetchDataTypesByProject,
    info: ToastrAction.info,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPiezometerApp)
