/* eslint-disable camelcase */
import { OBSERVATORY } from 'administration/components/user/constants/HabilitationConstants'
import DtoApplicationHabilitation from 'administration/components/user/dto/DtoApplicationHabilitation'
import DtoUserHabilitation from 'administration/components/user/dto/DtoUserHabilitation'
import moment from 'moment'
import { transform } from 'ol/proj'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoUser from '../../../administration/components/user/dto/DtoUser'
import NumberField from '../../../components/forms/NumberField'
import { getDefaultCoordinates } from '../../../utils/mapUtils/CoordinateUtils'
import OSM from '../../../utils/mapUtils/layers/OSM'
import OlMap from '../../../utils/mapUtils/Map'
import { getSetting } from '../../../utils/SettingUtils'
import { MAP_X_COORDINATE, MAP_Y_COORDINATE, MAP_ZOOM } from '../../constants/AccountSettingsConstants'
import DtoAccountSettings from '../../dto/DtoAccountSettings'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import UserAction from 'administration/components/user/actions/UserAction'
import ProgressBar from 'components/progress/ProgressBar'
import SieauParameterDto from 'administration/dto/SieauParameterDto'


class MapAccountUserPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            x: undefined,
            y: undefined,
            zoom: 8,
        }
    }

    checkUserHabs = () => {
        const { userHabilitations, accountUser } = this.props
        if (!userHabilitations.length) {
            this.props.fetchUserHabilitations(accountUser.login).then(() => {
                this.getValues()
            })
        } else {
            this.getValues()
        }
    }

    componentDidMount() {
        const { applicationHabilitations } = this.props
        if (!applicationHabilitations.length) {
            this.props.fetchApplicationHabilitations().then(() => {
                this.checkUserHabs()
            })
        } else {
            this.checkUserHabs()
        }
    }

    createParameter = (parameter, value) => {
        return new DtoAccountSettings({
            login: this.props.accountUser.login,
            parameter,
            updateDate: moment().valueOf(),
            value,
        })
    }

    changeMap = (value) => {
        const zoom = value.getView().getZoom()
        const point = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        const x_coordinate = point[0]
        const y_coordinate = point[1]
        const zoomParameter = this.createParameter(MAP_ZOOM, zoom)
        const xParameter = this.createParameter(MAP_X_COORDINATE, x_coordinate)
        const yParameter = this.createParameter(MAP_Y_COORDINATE, y_coordinate)
        const result = [xParameter, yParameter, zoomParameter]
        this.props.changeSettings(result)
    }

    getCoordinate = (parameter, defaultValue) => {
        const { applicationSettings } = this.props
        const paramValue = getSetting(applicationSettings, parameter)
        return parseFloat(paramValue) || defaultValue
    }

    setDefaultCoordinates = () => {
        const defaultCoordinate = getDefaultCoordinates()
        const x_coordinate = this.getCoordinate('defaultMapXCoordinate', defaultCoordinate[0])
        const y_coordinate = this.getCoordinate('defaultMapYCoordinate', defaultCoordinate[1])
        const zoom_coordinate = this.getCoordinate('defaultMapZoom', 9)
        this.setState({ x: x_coordinate, y: y_coordinate, zoom: zoom_coordinate })
    }

    getValues = () => {
        const { accountUserSettings, applicationHabilitations, userHabilitations } = this.props

        const x_user = getSetting(accountUserSettings, MAP_X_COORDINATE)
        const y_user = getSetting(accountUserSettings, MAP_Y_COORDINATE)
        const zoom_user = getSetting(accountUserSettings, MAP_ZOOM)

        if (!x_user || !y_user || !zoom_user) {
            const applicationHabilitationsFiltered = applicationHabilitations.filter(a => a.habilitationType === OBSERVATORY && a.parameter !== OBSERVATORY)
            const applicationHabilitationFinded = applicationHabilitationsFiltered.find(o => userHabilitations.some(hab => hab.habilitation === o.parameter))

            if (applicationHabilitationFinded) {
                this.props.fetchUserSettings(applicationHabilitationFinded.parameter, true).then(() => {
                    const { applicativeUserSettings } = this.props

                    const applicativeCoordinates = [
                        getSetting(applicativeUserSettings, MAP_X_COORDINATE),
                        getSetting(applicativeUserSettings, MAP_Y_COORDINATE),
                        getSetting(applicativeUserSettings, MAP_ZOOM),
                    ]

                    if (applicativeCoordinates[0] !== null && applicativeCoordinates[1] !== null && applicativeCoordinates[2] !== null) {
                        this.setState({ x: applicativeCoordinates[0], y: applicativeCoordinates[1], zoom: applicativeCoordinates[2] })
                    } else {
                        this.setDefaultCoordinates()
                    }
                })
            } else {
                this.setDefaultCoordinates()
            }
        } else {
            this.setState({ x: x_user, y: y_user, zoom: zoom_user })
        }
    }

    render() {
        const { x, y, zoom } = this.state
        return x && y && zoom ? (
            <div className='row no-margin'>
                <div className='col s12'>
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <NumberField col={ 4 } title={ i18n.longitude } value={ x } disabled/>
                            <NumberField col={ 4 } title={ i18n.latitude } value={ y } disabled/>
                            <NumberField col={ 4 } title={ i18n.zoom } value={ zoom } disabled/>
                        </div>
                    </div>
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <OlMap mapConf={ {
                                center: [x, y],
                                zoom,
                                size: 500,
                            } } layers={ [OSM()] }
                            onMoveEnd={ this.changeMap }
                            />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <ProgressBar indeterminate />
        )
    }
}

MapAccountUserPanel.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
    applicativeUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
    changeSettings: PropTypes.func,
    userHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserHabilitation)),
    applicationHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoApplicationHabilitation)),
    fetchUserSettings: PropTypes.func,
    fetchApplicationHabilitations: PropTypes.func,
    fetchUserHabilitations: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
}

const mapStateToProps = store => ({
    userHabilitations: store.UserReducer.userHabilitations,
    applicationHabilitations: store.UserReducer.applicationHabilitations,
    applicativeUserSettings: store.AdministrationReducer.applicativeUserSettings,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    fetchUserSettings: AdministrationAction.fetchUserSettings,
    fetchApplicationHabilitations: UserAction.fetchApplicationHabilitations,
    fetchUserHabilitations: UserAction.fetchUserHabilitations,
}

export default connect(mapStateToProps, mapDispatchToProps)(MapAccountUserPanel)
