import { Card, Grid } from '@mui/material'
import DtoHydrometryObservation from 'hydrometry/dto/DtoHydrometryObservation'
import PropTypes from 'prop-types'
import React from 'react'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { getDate, getFullDate, getHour } from 'utils/DateUtil'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import ColorfulCard from 'components/card/ColorfulCard'
import { getCardObservationColor } from 'utils/HydroUtils'
import Fancybox from 'components/fancybox/Fancybox'
import ApplicationConf from 'conf/ApplicationConf'
import { LIGHTGREY } from 'components/constants/ColorConstant'

const ObservationsCard = ({
    observation={},
}) => {
    const observationTypes = useSandreList(SANDRE.HYDRO_OBSERVATIONS)
    const observationModalites4 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_4)
    const observationModalites5 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_5)
    const updateDate = getDate(observation.updateDate)
    const updateHour = getHour(observation.updateDate)
    const observationDate = getFullDate(observation.observationDate)
    const observationType = observationTypes.find(sandreCode => sandreCode.code === observation.observationType)?.name
    const typeModalite = observation?.observationType === 1 ? observationModalites4 : observationModalites5
    const modalite = typeModalite.find(o => o.code === parseInt(observation?.observationCode))

    return (
        <ColorfulCard color={getCardObservationColor(modalite?.reference)}>
            <Card className='no-margin' style={{ borderRadius: '0px' }}>
                <Grid container>
                    <Grid item xs={observation?.pictureName ? 10 : 12}>
                        <h6 className='col s12 bold no-margin-bottom'>{ `${modalite?.name ?? i18n.unknown} ${observationType && `[${observationType}]`}` }</h6>
                        <h6 className='col s12'>{ `${observationDate} ${observation.comment ? ` - ${observation.comment}` : ''}` } </h6>
                    </Grid>
                    {observation?.pictureName && (
                        <Grid item xs={2} container className='padding-1' justifyContent='flex-end'>
                            <Fancybox>
                                <a href={ApplicationConf.picture.pictureCompressPath(observation?.pictureName)} data-fancybox='images' data-caption={observation?.pictureName}>
                                    <img src={ApplicationConf.picture.pictureCompressPath(observation?.pictureName)} style={{ maxWidth: '100%', maxHeight: '70px' }} />
                                </a>
                            </Fancybox>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{ backgroundColor: LIGHTGREY }}>
                        <p className='font-size-10 padding-left-1'>
                            { observation.updateLogin && `${i18n.writtenBy}  ${observation.updateLogin}` } { i18n.atDate } { updateDate } { i18n.atHour } { updateHour }
                        </p>
                    </Grid>
                </Grid>
            </Card>
        </ColorfulCard>
    )
}

ObservationsCard.propTypes = {
    observation: PropTypes.instanceOf(DtoHydrometryObservation),
}

export default ObservationsCard