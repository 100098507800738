import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import DtoTempRef from '../../../dto/DtoTempRef'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class TempRefModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tempRef: { ...props.tempRef, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoTempRef(this.state.tempRef))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ tempRef: { ...this.state.tempRef, ...changes } })

    render() {
        const { tempRef } = this.state
        return (
            <div>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={tempRef.startDate}
                        label={i18n.startDate}
                        onChange={v => this.onChangeElement({ startDate: v })}
                        max={tempRef.endDate}
                    />
                    <Input col={ 6 } title={ i18n.startHour } value={ getHour(tempRef.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: tempRef.endDate }, tempRef.startDate) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={tempRef.endDate}
                        label={i18n.endDate}
                        onChange={v => this.onChangeElement({ endDate: v })}
                        min={tempRef.startDate}
                    />
                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(tempRef.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: tempRef.startDate }, tempRef.endDate) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Select col={ 6 } options={ getSandreList(this.props.sandreCodes, SANDRE.REF_TEMPORELLES) } label={ i18n.tempRef }
                        value={ tempRef.tempRef } onChange={ v => this.onChangeElement({ tempRef: v }) }
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Textarea col={ 12 } title={ i18n.comment } value={ tempRef.comment } onChange={ v => this.onChangeElement({ comment: v }) }/>
                </div>
            </div>
        )
    }
}

TempRefModal.propTypes = {
    tempRef: PropTypes.instanceOf(DtoTempRef),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

TempRefModal.defaultProps = {
    tempRef: {},
}

export default connect(mapStateToProps)(TempRefModal)
