'use strict'
import { push } from 'connected-react-router'
import { maxBy, meanBy, minBy, range, round, zip } from 'lodash'
import moment from 'moment'
import DtoPiezometer from 'piezometry/dto/DtoPiezometer'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getStationArrowNav } from 'utils/ActionUtils'
import { getLinks } from 'utils/StationUtils'
import WaitAction from 'wait/WaitAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import Row from '../../../components/react/Row'
import ExportAction from '../../../export/actions/ExportAction'
import DtoPiezometerAdditionalMeasures from '../../../station/dto/piezometer/DtoPiezometerAdditionalMeasures'
import DtoPiezometryStationMeasure from '../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import { exportModelFile, formatData, getModelFileType } from '../../../utils/ExportDataUtil'
import { setModal } from '../../../utils/FormUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoPiezoMeasureLight from '../../dto/chart/DtoPiezoMeasureLight'
import { MONTHS } from '../suivi/constants/PiezometerSuiviConstants'
import DtoMonthValues from '../suivi/dto/DtoMonthValues'
import DtoYearValues from '../suivi/dto/DtoYearValues'
import { hasValue } from '../../../utils/NumberUtil'
import ProgressCard from 'components/card/ProgressCard'

class PiezometerSuiviTable extends ActionComponent {
    state = {
        dataLoaded: false,
        openExportModal: false,
    }

    componentDidUpdate() {
        if (!this.state.dataLoaded && this.getData().length > 0) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ dataLoaded: true })
        }
        this.setActions({
            exportList: [{
                onClick: () => this.setState({ openExportModal: true }),
                label: i18n.excel,
            }],
            links: getLinks(this.props.piezometer, this.props),
            arrowNav: getStationArrowNav('piezometry', this.props.piezometers, this.props.piezometer.id, s => this.props.push(`/station/piezometry/${s.id}/piezoSuiviTable`)),
        })
    }

    componentDidMount() {
        if (this.getData().length > 0) {
            $('#suiviTable').tableHeadFixer(
                {
                    head: true,
                    left: 1,
                    'z-index': 0,
                }
            )
            this.setState({ dataLoaded: true })
        }
        this.setActions({
            exportList: [{
                onClick: () => this.setState({ openExportModal: true }),
                label: i18n.excel,
            }],
        })
    }
    getHistoricData = () => {
        const { filter } = this.props
        const dateKey = this.getDataType().dateKey
        const allDaysByYear = range(moment().year(), moment(minBy(this.getData(), dateKey)[dateKey]).year() - 1).map(year => {
            return {
                year,
                data: range(1, 367).map(() => null),
            }
        })

        const filteredMeasures = this.getData().filter(m => (!filter.qualification || m.qualification == filter.qualification)
            && (!filter.state || m.status == filter.state)
        )

        filteredMeasures.map(measure => {
            const date = moment(measure[dateKey])
            const correspondingYearData = allDaysByYear.find(year => parseInt(year.year) === parseInt(date.year())).data
            const bisextileDate = date.year(2016)
            if (correspondingYearData[bisextileDate.dayOfYear() - 1] === null) {
                correspondingYearData[bisextileDate.dayOfYear() - 1] = measure.value
            } else {
                correspondingYearData[bisextileDate.dayOfYear() - 1] = this.getDataType().groupFunc([ correspondingYearData[bisextileDate.dayOfYear() - 1], measure.value])
            }
        })

        const coloredData = allDaysByYear.map(yearData => {
            const coloredYearData = yearData.data.map(value => {
                return { value: hasValue(value) ? value : undefined, color: 'white' }
            })
            const dataWithValues = coloredYearData.filter(data => data.value)
            if (this.maxFunc()(dataWithValues, 'value')) {
                const maxValue = this.maxFunc()(coloredYearData, 'value').value
                const minValue = this.minFunc()(coloredYearData, 'value').value
                coloredYearData.map(data => {
                    if (data.value === maxValue) {
                        data.color = '#F44336'
                    }
                    if (data.value === minValue) {
                        data.color = '#3465ff'
                    }
                    return data
                })
                coloredYearData.push({ value: minBy(dataWithValues, 'value').value, color: 'orange' })
                coloredYearData.push({ value: meanBy(dataWithValues, 'value'), color: 'orange' })
                coloredYearData.push({ value: maxBy(dataWithValues, 'value').value, color: 'orange' })
            } else {
                range(3).map(() => coloredYearData.push({ value: null, color: 'orange' }))
            }
            return coloredYearData
        })

        const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
            .map((days, index) => new DtoYearValues({ value: moment('2016-01-01').dayOfYear(index + 1).format('DD/MM'), color: 'grey' }, days))

        values[366] = {
            ...values[366],
            day: { value: i18n.min, color: 'grey' },
            min: { value: values[366].min.value, color: this.isMeasureData() ? '#F44336' : '#3465ff' },
            mean: null,
            max: null,
        }
        values[367] = {
            ...values[367],
            day: { value: i18n.mean, color: 'grey' },
            min: null,
            mean: { value: values[367].mean.value, color: 'orange' },
            max: null,
        }
        values[368] = {
            ...values[368],
            day: { value: i18n.max, color: 'grey' },
            min: null,
            mean: null,
            max: { value: values[368].max.value, color: this.isMeasureData() ? '#3465ff' : '#F44336' },
        }

        return {
            values,
            nbMeasures: filteredMeasures.length,
        }
    }

    getAnnualData = () => {
        const { filter } = this.props
        const dateKey = this.getDataType().dateKey
        const allDaysByMonth = MONTHS.map(() => {
            return range(1, 32).map(() => null)
        })
        const momentYear = filter.year ? moment(filter.year, 'YYYY') : moment()
        const filteredMeasures = this.getData().filter(m => (!filter.qualification || m.qualification == filter.qualification)
            && (!filter.state || m.status == filter.state)
            && momentYear.isSame(moment(m[dateKey]), 'year')
        )

        filteredMeasures.map(measure => {
            const date = moment(measure[dateKey])
            if (!allDaysByMonth[date.month()][date.date() - 1]) {
                allDaysByMonth[date.month()][date.date() - 1] = measure.value
            } else {
                allDaysByMonth[date.month()][date.date() - 1] = this.getDataType().groupFunc([ allDaysByMonth[date.month()][date.date() - 1], measure.value])
            }
        })

        const coloredData = allDaysByMonth.map(monthDays => {
            const coloredMonthDays = monthDays.map(value => {
                return { value: hasValue(value) ? value : undefined, color: 'white' }
            })
            const dataWithValues = coloredMonthDays.filter(data => data.value && hasValue(data.value))
            if (this.maxFunc()(dataWithValues, 'value')) {
                const maxValue = this.maxFunc()(coloredMonthDays, 'value').value
                const minValue = this.minFunc()(coloredMonthDays, 'value').value
                coloredMonthDays.map(data => {
                    if (data.value === maxValue) {
                        data.color = '#F44336'
                    }
                    if (data.value === minValue) {
                        data.color = '#3465ff'
                    }
                    return data
                })
                coloredMonthDays.push({ value: minBy(dataWithValues, 'value').value, color: 'orange' })
                coloredMonthDays.push({ value: meanBy(dataWithValues, 'value'), color: 'orange' })
                coloredMonthDays.push({ value: maxBy(dataWithValues, 'value').value, color: 'orange' })
            } else {
                range(3).map(() => coloredMonthDays.push({ value: null, color: 'orange' }))
            }
            return coloredMonthDays
        })

        const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
            .map((months, index) => new DtoMonthValues({ value: index + 1, color: 'grey' }, months))

        values[31] = {
            ...values[31],
            day: { value: i18n.min, color: 'grey' },
            min: { value: values[31].min.value, color: this.isMeasureData() ? '#F44336' : '#3465ff' },
            mean: null,
            max: null,
        }
        values[32] = {
            ...values[32],
            day: { value: i18n.mean, color: 'grey' },
            min: null,
            mean: { value: values[32].mean.value, color: 'orange' },
            max: null,
        }
        values[33] = {
            ...values[33],
            day: { value: i18n.max, color: 'grey' },
            min: null,
            mean: null,
            max: { value: values[33].max.value, color: this.isMeasureData() ? '#3465ff' : '#F44336' },
        }

        return {
            values,
            nbMeasures: filteredMeasures.length,
        }
    }

    getDataType = () => {
        return this.props.chronicTypes.find(t => t.code === this.props.filter.chronicType)
    }

    getData = () => {
        if (this.props.filter.chronicType === -2) {
            return this.props.samples
        }
        const dataType = this.getDataType()
        if (dataType.code.toString().includes('additional')) {
            const found = this.props.piezometerAdditionalMeasures.find(typeObj => typeObj.type === dataType.type)
            return found ? found.measures : []
        }
        return this.props[dataType.code]
    }

    isMeasureData = () => this.props.filter.chronicType.toString().toLowerCase().includes('measure')

    minFunc = () => this.isMeasureData() ? maxBy : minBy

    maxFunc = () => this.isMeasureData() ? minBy : maxBy

    onDisplayLegend = () => {
        setModal({
            title: i18n.legend,
            actions: (<div><a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a></div>),
            content: (
                <div>
                    <Row><span className={ 'red arrests-level-panel ' + 'red-text' }>O</span> { i18n.monthlyMin }</Row>
                    <Row><span className={ 'blue arrests-level-panel ' + 'blue-text' }>O</span> { i18n.monthlyMax }</Row>
                    <Row><span className={ 'orange arrests-level-panel ' + 'orange-text' }>O</span> { i18n.statistics }</Row>
                </div>
            ),
        })
    }

    exportData = (data, type) => {
        if (data.nbMeasures === 0) {
            return AppStore.dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const dataFormatted = formatData(data.values)
        return this.props.standardExport(dataFormatted, type, i18n.piezometrySuivi)
    }
    getExportModal = (data) => {
        const { openExportModal } = this.state
        const { typeEnvironmentModels: models } = this.props
        const tableExport = [{
            name: i18n.resultsTable,
            formats: [{
                type: i18n.csvFile,
                callback: () => this.exportData(data, 'csv'),
            },
            {
                type: i18n.excelFile,
                callback: () => this.exportData(data, 'xlsx'),
            }],
        }]
        const exportModel = models.map((model) => {
            const fileNameSplit = model.split('.')
            const name = fileNameSplit.slice(0, -1).join('')
            const ext = fileNameSplit[fileNameSplit.length - 1]
            return {
                name,
                formats: [{
                    type: getModelFileType(ext),
                    callback: () => exportModelFile({
                        stationId: this.props.piezometer.id.toString(),
                        stationCode: this.props.piezometer.code,
                        stationType: this.props.piezometer.typeName,
                        environmentModels: models,
                        filenameModelExport: model,
                    }),
                }],
            }
        })
        return openExportModal && (
            <ExportFileModal
                open={openExportModal}
                onClose={() => this.setState({ openExportModal: false })}
                data={[ ...tableExport, ...exportModel ]}
            />
        )
    }

    render() {
        const { dataLoaded, openExportModal } = this.state
        const { filter } = this.props
        if (dataLoaded) {
            if (filter.chronicType && this.getData().length !== 0) {
                const data = this.props.historic ? this.getHistoricData() : this.getAnnualData()
                if (data.nbMeasures === 0) {
                    return (
                        <div>
                            <Card className='padding-top-1 padding-bottom-1 padding-left-1'>
                                <h5>{i18n.noDataToDisplay}</h5>
                            </Card>
                            {openExportModal && this.getExportModal(data)}
                        </div>
                    )
                }
                return (
                    <div>
                        <Table color title={`${i18n.followUpTable} (${data.nbMeasures} ${i18n.measures})`}
                            data={data.values} sortable showNbElements={false}
                            type={data.values[0]} condensed id='suiviTable'
                            actions={[{ iconName: 'info', onClick: this.onDisplayLegend, tooltip: i18n.legend }]}
                        />
                        {openExportModal && this.getExportModal(data)}
                    </div>
                )
            }
            return (
                <div>
                    <Card className='padding-top-1 padding-bottom-1 padding-left-1'>
                        <h5>{i18n.noDataToDisplay}</h5>
                    </Card>
                    {openExportModal && this.getExportModal({})}
                </div>
            )
        }
        return <ProgressCard indeterminate withMessage />
    }
}

PiezometerSuiviTable.propTypes = {
    filter: PropTypes.shape({
        state: PropTypes.string,
        qualification: PropTypes.string,
    }),
    historic: PropTypes.boolean,
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoMeasureLight)),
    piezometerMeasureBrute: arrayOf(DtoPiezometryStationMeasure),
    piezometerMeasureMin: arrayOf(DtoPiezometryStationMeasure),
    samples: arrayOf(DtoPiezoMeasureLight),
    piezometerAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerAdditionalMeasures)),
    chronicTypes: arrayOf(PropTypes.shape({
        code: PropTypes.string,
        label: PropTypes.string,
        dateKey: PropTypes.string,
        groupFunc: PropTypes.func,
    })),
    toastrError: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    standardExport: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    push: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        samples: store.PiezometerStationReducer.samples,
        piezometerMeasureBrute: store.PiezometerStationReducer.piezometerMeasureBrute,
        piezometerMeasureMin: store.PiezometerStationReducer.piezometerMeasureMin,
        piezometerAdditionalMeasures: store.PiezometerStationReducer.piezometerAdditionalMeasures,
        waitStart: WaitAction.waitStart,
        waitStop: WaitAction.waitStop,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        piezometers: store.PiezometryReducer.piezometersLight,
    }
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
    standardExport: ExportAction.export,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometerSuiviTable)
