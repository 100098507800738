import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import CollapseTopBar from '../../../components/topBar/CollapseToBar'
import Card from '../../../components/card/Card'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import SelectedElementsModal from '../../../components/modal/SelectedElementsModal'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import DtoStationType from '../../../referencial/dto/DtoStationType'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import HydrometryGraphicChart from './HydrometryGraphicChart'
import DtoHydroMeasures from '../../dto/chronicMeasures/DtoHydroMeasures'
import { arrayOf, getComponentWithId, objectOf } from '../../../utils/StoreUtils'
import HydrometryAction from '../../actions/HydrometryAction'
import { every, keys, orderBy } from 'lodash'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import DtoFilter from '../../../station/dto/DtoFilter'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import { getHardHydroDataTypes } from '../../../utils/HydroUtils'
import Select from '../../../components/forms/Select'
import ProgressCard from '../../../components/card/ProgressCard'
import RadioButtons from '../../../components/forms/RadioButtons'
import SimpleOptionsToolPanel from 'piezometry/components/validation/tools/SimpleOptionsToolPanel'
import useTitle from 'utils/customHook/useTitle'
import { ALL, AVERAGE, MAX, MIN } from 'hydrometry/constants/HydrometryConstants'
import useActions from '../../../utils/customHook/useActions'
import useUpdateEffect from '../../../utils/customHook/useUpdateEffect'

const HydrometerCollapseFilter = ({
    defaultFilter = {},
    setFilter = () => {},
    onClickStations = () => {},
}) => {
    const {
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const groups = useMemo(() => [
        { value: ALL, label: i18n.allMeasures },
        { value: MAX, label: i18n.dailyMax },
        { value: MIN, label: i18n.dailyMin },
        { value: AVERAGE, label: i18n.dailyAverage },
    ], [])

    const evolutionModes = useMemo(() => [
        { value: 'normal', label: i18n.comparing },
        { value: 'relative', label: i18n.relativeEvolution },
        { value: 'percent', label: i18n.percent },
    ], [])

    const chronicOptions = useMemo(() => orderBy([ ...getHardHydroDataTypes(), ...hydrometryDataTypes ]), [hydrometryDataTypes])

    const [startDate, setStartDate] = useState(defaultFilter.startDate)
    const [endDate, setEndDate] = useState(defaultFilter.endDate)
    const [dataType, setDataType] = useState(defaultFilter.dataType)
    const [groupFunc, setGroupFunc] = useState(defaultFilter.groupFunc)
    const [displayMode, setDisplayMode] = useState(defaultFilter.displayMode)

    return (
        <CollapseTopBar open={true}>
            <Card greyContent>
                <div className='row no-margin padding-top-1'>
                    <SimpleDatePicker
                        col={3}
                        label={i18n.startDate}
                        onChange={setStartDate}
                        id='startDate'
                        value={startDate}
                        max={endDate}
                    />
                    <SimpleDatePicker
                        col={3}
                        label={i18n.endDate}
                        onChange={setEndDate}
                        id='endDate'
                        value={endDate}
                        min={startDate}
                    />
                    <div className='col s2 input-field'>
                        <label className='sieau-input select-label' >{i18n.selectStations}</label>
                        <i
                            className='material-icons blue-text clickable qualityGraphicsStationIcon tooltipped'
                            data-tooltip={i18n.stations}
                            onClick={onClickStations}
                        >
                            list
                        </i>
                    </div>
                </div>
                <div className='row no-margin padding-bottom-1 valign-wrapper'>
                    <Select
                        col={2}
                        label={i18n.chronic}
                        options={chronicOptions}
                        onChange={setDataType}
                        value={dataType}
                        noNullValue
                        noSort
                    />
                    <Select
                        col={2}
                        label={i18n.graphicType}
                        options={groups}
                        onChange={setGroupFunc}
                        value={groupFunc}
                        noNullValue
                        noSort
                    />
                    <RadioButtons
                        col={ 4 }
                        elements={evolutionModes}
                        selected={displayMode}
                        onChange={setDisplayMode}
                        title={i18n.displayMode}
                    />
                    <div className='col s4'>
                        <a
                            className='waves-effect waves-light btn right'
                            onClick={() => {
                                setFilter({
                                    startDate,
                                    endDate,
                                    dataType,
                                    groupFunc,
                                    displayMode,
                                })
                            }}
                        >
                            <i className='material-icons left'>search</i>{i18n.visualize}
                        </a>
                    </div>
                </div>
            </Card>
        </CollapseTopBar>
    )
}

HydrometerCollapseFilter.propTypes = {
    defaultFilter: PropTypes.shape({
        startDate: PropTypes.number,
        endDate: PropTypes.number,
        dataType: PropTypes.number,
        groupFunc: PropTypes.string,
        displayMode: PropTypes.string,
    }),
    setFilter: PropTypes.func,
    onClickStations: PropTypes.func,
}

const HydrometryGraphicApp = ({

}) => {
    const {
        hydrometricStations,
        hydroMeasures,
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydroMeasures: store.HydrometryReducer.hydroMeasures,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const defaultFilter = useMemo(() => ({
        startDate: moment().subtract(36, 'month').valueOf(),
        endDate: moment().valueOf(),
        threshold: null,
        graphicType: 'line',
        dataType: 4,
        groupFunc: 'MAX',
        displayMode: 'normal',
    }), [])

    const [filter, setFilter] = useState(defaultFilter)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedStations, setSelectedStations] = useState([])
    const [previousSelectedStations, setPreviousSelectedStations] = useState([])
    const [graphicOptions, setGraphicOptions] = useState({})

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: i18n.overlayGraphic,
        href: 'hydrometry/overlayGraphic',
    }], [])

    useEffect(() => {
        if (!hydrometricStations.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
    }, [])

    useActions(() => ({}), [])

    const getChart = () => {
        if (!dataLoaded && selectedStations.length > 0) {
            return <ProgressCard progress={progress} />
        } else if (dataLoaded) {
            return (
                <div className='row padding-1 relative no-overflow'>
                    <div className='col s9'>
                        <Card className='padding-top-3 padding-bottom-1 padding-left-1'>
                            <HydrometryGraphicChart
                                filter={filter}
                                graphicOptions={graphicOptions}
                            />
                        </Card>
                    </div>
                    <div className='col s3 no-margin'>
                        <SimpleOptionsToolPanel
                            updateState={setGraphicOptions}
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    useUpdateEffect(() => {
        const { startDate, endDate, dataType, groupFunc } = filter

        if (!startDate || !endDate) {
            dispatch(ToastrAction.error(i18n.noDateSelectedError))
        } else if (every(keys(selectedStations), c => !(selectedStations[c] || selectedStations[c] === 0))) {
            dispatch(ToastrAction.error(i18n.noStationSelectedError))
        } else {
            getComponentWithId('#collapse-top-bar').collapse()
            if (selectedStations.length) {
                setDataLoaded(false)
                setProgress(0)
                const hydroIds = selectedStations.map(s => s.id)
                const criterias = {
                    dataType,
                    groupFunc,
                    chartMode: true,
                    startDate,
                    endDate,
                }
                dispatch(HydrometryAction.loadHydroChronicsMeasures(criterias, hydroIds, setProgress)).then(() => setDataLoaded(true))
            }
        }
    }, [filter])

    const onValidate = () => {
        setIsOpen(false)
        setSelectedStations(previousSelectedStations)
    }

    const onChangeStations = (newSelectedStations) => {
        setPreviousSelectedStations(newSelectedStations)
    }

    if (dataLoaded && !hydroMeasures.length) {
        return (
            <div className='padding-top-1 padding-bottom-1 padding-left-2'>
                <Card className='padding-top-1 padding-bottom-1 padding-left-2'>
                    <h5>{i18n.noData}</h5>
                </Card>
            </div>
        )
    }

    return (
        <>
            <div className='col s12 padding-left-1 padding-right-1'>
                <div className='padding-left-2 padding-right-2'>
                    <HydrometerCollapseFilter
                        defaultFilter={defaultFilter}
                        setFilter={(newFilter) => setFilter({ ...filter, ...newFilter })}
                        onClickStations={() => {
                            setIsOpen(true)
                            setPreviousSelectedStations(selectedStations)
                        }}
                    />
                </div>
            </div>
            <div className='col s12'>
                {getChart()}
            </div>
            <Dialog
                onClose={() => {
                    setIsOpen(false)
                    setSelectedStations(previousSelectedStations)
                }}
                fullWidth
                maxWidth='lg'
                scroll='body'
                open={isOpen}
            >
                <DialogTitle>{i18n.selectStations}</DialogTitle>
                <DialogContent>
                    <SelectedElementsModal
                        selectedList={previousSelectedStations}
                        onChange={onChangeStations}
                        list={hydrometricStations}
                        elementType={STATION_TYPE_NAME.hydrometry}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClickCapture={() => {
                            setIsOpen(false)
                            setSelectedStations(previousSelectedStations)
                        }}
                    >
                        {i18n.close}
                    </Button>
                    <Button onClick={onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


HydrometryGraphicApp.propTypes = {
    stationTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationType)),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    hydrosIndex: objectOf(DtoHydrometricStation),
    filters: PropTypes.arrayOf(PropTypes.instanceOf(DtoFilter)),
    hydroMeasures: arrayOf(DtoHydroMeasures),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
}

export default HydrometryGraphicApp
