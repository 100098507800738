import { Button as ButtonMUI, ButtonGroup } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { MEASURE_COTE } from '../../piezometry/constants/PiezometryConstants'
import { getDate } from '../../utils/DateUtil'
import { getLocalStorageJson, onChangeDate, setLocalStorageJson } from '../../utils/FormUtils'
import { removeNullKeys } from '../../utils/StoreUtils'
import Button from '../forms/Button'
import Checkbox from '../forms/Checkbox'
import Input from '../forms/Input'
import Icon from '../icon/Icon'

const CHART_TABS_SUIVI = 'CHART_TABS_SUIVI'
const CIVIL_YEAR = 'CIVIL_YEAR'
const HYDRO_YEAR = 'HYDRO_YEAR'
const HISTO = 'HISTO'
const OTHER = 'OTHER'

class ChartTabsSuivi extends Component {
    constructor(props) {
        super()
        const previousSelected = getLocalStorageJson(CHART_TABS_SUIVI) || props.default || {}
        this.state = {
            active: previousSelected.active || CIVIL_YEAR,
            otherTabSelected: false,
            maxDate: previousSelected.maxDate || moment().valueOf(),
            minDate: previousSelected.minDate,
            otherMaxDate: previousSelected.maxDate || moment().valueOf(),
            otherMinDate: previousSelected.minDate,
            displayCote: getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH,
            open: false,
            initialOptions: [],
        }
        this.props = props
        if (previousSelected.active === HISTO) {
            this.setHisto()
        } else if (previousSelected.active === CIVIL_YEAR) {
            this.setCivilYear()
        } else if (previousSelected.active === HYDRO_YEAR) {
            this.setHydroYear()
        } else {
            props.onChangeDate({ minDate: previousSelected.minDate, maxDate: previousSelected.maxDate }, true)
        }
    }

    onSelectOtherTab = () => {
        const changes = { otherTabSelected: !this.state.otherTabSelected, settingsTabSelected: false }
        if (this.state.minDate) {
            if (this.state.maxDate) {
                const newChanges = Object.assign({}, changes, { minDate: this.state.minDate, maxDate: this.state.maxDate, active: OTHER })
                this.setState(newChanges)
                setLocalStorageJson(CHART_TABS_SUIVI, newChanges)
                this.props.onChangeDate({ minDate: newChanges.minDate, maxDate: newChanges.maxDate })
            } else {
                const newChanges = Object.assign({}, changes, { minDate: this.state.minDate, active: OTHER })
                this.setState(newChanges)
                setLocalStorageJson(CHART_TABS_SUIVI, newChanges)
                this.props.onChangeDate({ minDate: newChanges.minDate, maxDate: moment().valueOf() })
            }
        } else {
            this.setState(changes)
        }
    }

    onSubmitOtherDates = () => {
        const changes = { minDate: this.state.otherMinDate, maxDate: this.state.otherMaxDate }
        const customChanges = removeNullKeys(Object.assign({}, {
            minDate: changes.minDate ? moment(changes.minDate).hour(0).minute(0).second(0).valueOf() : null,
            maxDate: changes.maxDate ? moment(changes.maxDate).hour(23).minute(59).second(59).valueOf() : null,
        }))
        const newChanges = Object.assign({}, this.state, customChanges)
        if (newChanges.minDate && newChanges.maxDate) {
            const allChanges = Object.assign({}, newChanges, { active: OTHER })
            this.setState(allChanges)
            this.props.onChangeDate({ minDate: allChanges.minDate, maxDate: allChanges.maxDate, tab: OTHER })
        } else {
            this.setState(newChanges)
        }
    }

    setHisto = () => {
        const obj = { active: HISTO, minDate: null }
        this.setState(obj)
        setLocalStorageJson(CHART_TABS_SUIVI, obj)
        this.props.onChangeDate({ minDate: null, maxDate: moment().valueOf(), tab: HISTO })
    }

    setCivilYear = () => {
        const obj = { active: CIVIL_YEAR }
        this.setState(obj)
        setLocalStorageJson(CHART_TABS_SUIVI, obj)
        this.props.onChangeDate({ minDate: moment().startOf('year').valueOf(), maxDate: moment().endOf('year').valueOf(), tab: CIVIL_YEAR })
    }

    setHydroYear = () => {
        const obj = { active: HYDRO_YEAR }
        this.setState(obj)
        setLocalStorageJson(CHART_TABS_SUIVI, obj)
        const minDate = moment().month(9).year(moment().month() >= 9 ? moment().year() : moment().year() - 1)
        this.props.onChangeDate({ minDate: minDate.startOf('month').valueOf(), maxDate: minDate.add(1, 'year').month(9).startOf('month').valueOf(), tab: HYDRO_YEAR })
    }

    changeCote = v => {
        this.setState({ displayCote: v, settingsTabSelected: false })
        setLocalStorageJson('DISPLAY_COTE', v)
        this.props.changeParent({ displayCote: v, selectedPoint: null })
    }

    getDisplayCote = () => {
        const cote = (() => {
            switch (this.state.displayCote) {
                case MEASURE_COTE.DEPTH:
                    return { text: 'Profondeur', className: 'blue' }
                case MEASURE_COTE.NGF:
                    return { text: 'NGF', className: 'indigo darken-3' }
                case MEASURE_COTE.GROUND:
                    return { text: 'Sol', className: 'orange' }
                case MEASURE_COTE.LANDMARK:
                    return { text: 'Historique des repères', className: 'brown darken-1' }
                default:
                    return null
            }
        })()
        return (<span onClick={ () => this.setState({ settingsTabSelected: !this.state.settingsTabSelected, otherTabSelected: false }) } className={ `margin-left-1 blue arrests-level-panel cote-medal clickable white-text ${cote.className}` }>{ cote.text }</span>)
    }

    getActive = (value) => this.state.active === value ? 'contained' : 'outlined'

    render() {
        const buttons = [
            <ButtonMUI color='primary' style={{ top: 9, marginLeft: '0.75rem', borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} variant={ this.getActive(CIVIL_YEAR) } onClick={ this.setCivilYear }>{i18n.civilYear}</ButtonMUI>,
            <ButtonMUI color='primary' style={{ top: 9, borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} variant={ this.getActive(HYDRO_YEAR) } onClick={ this.setHydroYear }>{i18n.hydroYear}</ButtonMUI>,
            <ButtonMUI color='primary' style={{ top: 9, borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} variant={ this.getActive(HISTO) } onClick={ this.setHisto }>{i18n.histo}</ButtonMUI>,
            <span onClick={this.onSelectOtherTab}><ButtonMUI color='primary' style={{ marginLeft: '0.75rem', top: 9, borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} variant={ this.getActive(OTHER) } onClick={ this.onSelectOtherTab }>{i18n.other}</ButtonMUI></span>,
        ]

        return (
            <div className='absolute row no-margin no-padding z-index-10'>
                <div className='row no-margin'>
                    <ButtonGroup>
                        { buttons }
                    </ButtonGroup>
                    { this.props.withPiezoCote && this.state.displayCote && this.getDisplayCote() }
                    <div className={ this.state.otherTabSelected ? 'chartDates-dropdown' : 'hidden' }>
                        <div style={ { height: '10px' } }/>
                        <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1' style={ { width: '200px' } }>
                            <div className='collection-item row no-margin valign-wrapper' >
                                <Input col={ 12 } title={ i18n.startDate } value={ getDate(this.state.otherMinDate) } onChange={ v => onChangeDate(v, v2 => this.setState({ otherMinDate: v2 })) } />
                            </div>
                            <div className='collection-item row no-margin valign-wrapper padding-top-1'>
                                <Input col={ 12 } title={ i18n.endDate } value={ getDate(this.state.otherMaxDate) } onChange={ v => onChangeDate(v, v2 => this.setState({ otherMaxDate: v2 })) } />
                            </div>
                            <div className='row no-margin padding-top-1 padding-left-1 padding-right-1'>
                                <Button col={ 12 } onClick={ this.onSubmitOtherDates } title={ i18n.toLoad }/>
                            </div>
                        </div>

                    </div>
                    <div className={ this.state.settingsTabSelected ? 'chartDates-dropdown' : 'hidden' }>
                        <div style={ { height: '10px' } }/>
                        <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1' style={ { width: '200px' } }>
                            <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                <Checkbox onChange={ () => this.changeCote(MEASURE_COTE.DEPTH) } checked={ this.state.displayCote === MEASURE_COTE.DEPTH }/>
                                <span className={ 'blue arrests-level-panel white-text' }>Profondeur</span>
                            </div>
                            <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                <Checkbox onChange={ () => this.changeCote(MEASURE_COTE.NGF) } checked={ this.state.displayCote === MEASURE_COTE.NGF }/>
                                <span className={ 'indigo darken-3 arrests-level-panel white-text' }>NGF</span>
                            </div>
                            <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                <Checkbox onChange={ v => this.props.changeParent({ withBrutes: v }) } checked={ this.props.withBrutes }/>
                                <span className={ 'black arrests-level-panel black-text' }>OO</span>
                                <p className='no-margin'>Brutes</p>
                                <div style={ { 'padding-left': 5 } }/>
                                <Icon icon='warning' style={ { fontSize: 18, color: 'orange' } } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ChartTabsSuivi.propTypes = {
    onChangeDate: PropTypes.func,
    withPiezoCote: PropTypes.bool,
    changeParent: PropTypes.func,
    default: PropTypes.object,
    withBrutes: PropTypes.boolean
}

export default ChartTabsSuivi