import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { push } from 'connected-react-router'
import { flatten, isUndefined, some, sortBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import i18n from 'simple-react-i18n'
import TabList from 'components/list/TabList'
import { Grid } from '@mui/material'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import DashboardFilterPanel from 'station/components/dashboard/component/DashboardFilterPanel'
import DashboardAddStationDialog from 'station/components/dashboard/component/DashboardAddStationDialog'
import DashboardMapPanel from 'station/components/dashboard/component/DashboardMapPanel'
import DashboardStationsPanel from 'station/components/dashboard/component/DashboardStationsPanel'
import { MAP, SAMPLE_LIST, STATION_LIST, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import HomeAction from 'home/actions/HomeAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { getUserBookmarksByStationType } from 'utils/UserUtil'
import { findStationType, getBookmarks, getStationType } from 'utils/StationUtils'
import { getObjectLabel } from 'utils/StoreUtils'
import { getDate } from 'utils/DateUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'
import { getUser } from 'utils/SettingUtils'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import StationStatisticPanel from 'station/components/dashboard/component/keyfigure/StationStatisticPanel'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_PLUVIO_DASHBOARD } from 'account/constants/AccessRulesConstants'
import UserAction from 'administration/components/user/actions/UserAction'
import StationAction from 'station/actions/StationAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import QualityAction from 'quality/actions/QualityAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import StationsSampleListPanel from 'station/components/dashboard/StationsSampleListPanel'
import StationFilterFields from 'station/components/search/StationFilterFields'

const DEFAULT_FILTER = -1
const SEARCH_VALUE_HEADERS = ['number', 'code', 'name', 'city', 'SISEeaucode', 'creationDate', 'stationTypeLabel', 'operator', 'administrator', 'referent', 'department']
const PLUVIOMETRY_HEADERS = ['code', 'cityCode', 'cityLabel', 'name', 'creationDate', 'closeDate', 'administrator']
const stationType = STATION_TYPE_NAME.pluviometry

const PluviometryPanel = () => {
    const {
        citiesIndex,
        pluviometers,
        userBookmarks,
        selectedSearchValues,
        globalResearch,
        contributorLinks,
        contributorsIndex,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        pluviometers: store.PluviometryReducer.pluviometers,
        userBookmarks: store.UserReducer.userBookmarks,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        globalResearch: store.HomeReducer.globalResearch,
        contributorLinks: store.StationReducer.contributorLinks,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [panel, setPanel] = useState(STATION_LIST)
    const [filters, setFilters] = useState(() => {
        const {
            filter: filterCode = -1,
            filterResults = [],
            searchValue = '',
        } = selectedSearchValues.pluviometry || {}
        return {
            filter: filterCode,
            filterResults,
            searchValue: globalResearch || searchValue,
        }
    })
    const dispatch = useDispatch()

    useEffect(() => {
        if (globalResearch) {
            dispatch(HomeAction.updateGlobalResearch(''))
        }
    }, [])

    const pluviometersFormatted = useMemo(() => {
        if (pluviometers.length) {
            return sortBy(pluviometers, o => o.name ? o.name.toUpperCase() : '}').map(s => ({
                ...s,
                nullValue: getBookmarks(s.code, getUserBookmarksByStationType(userBookmarks, 'pluviometry', s.code)),
                city: { value: getObjectLabel(citiesIndex[s.townCode], 'labelWithCode') },
                name: s.name || '',
                cityCode: s.townCode,
                cityLabel: getObjectLabel(citiesIndex[s.townCode], 'name'),
                creationDate: getDate(s.creationDate),
                closeDate: getDate(s.closeDate),
                stationType: s.stationType ? getStationType(parseInt(s.stationType)).libelle : '',
                headers: PLUVIOMETRY_HEADERS,
            }))
        }
        return []
    }, [citiesIndex, pluviometers, userBookmarks])

    const containsSearchValue = useCallback((station) =>
        some(SEARCH_VALUE_HEADERS, prop => station[prop] ?
            searchAllCharacters(station[prop].toString()).includes(searchAllCharacters(filters.searchValue))
            : false)
    , [filters.searchValue])

    const data = useMemo(() => {
        dispatch(AdministrationAction.setSelectedSearchValues(stationType, { searchValue: filters.searchValue }))
        const searchFiltered = pluviometersFormatted.filter(s => containsSearchValue(s))

        const defaultResult = {
            title: i18n.watchpoints,
            type: { headers: ['nullValue', ...PLUVIOMETRY_HEADERS] },
        }

        const contributorFiltered = filters.referentIds?.length ? contributorLinks.filter(l => filters.referentIds.includes(l.idContributor)).map(l => searchFiltered.find(s => s.id === l.idStation)).filter(s => !!s) : searchFiltered

        if (hasValue(filters.filter) && filters.filter !== DEFAULT_FILTER && filters.filterResults) {
            return { ...defaultResult, stations: flatten(filters.filterResults.map(stationResult => contributorFiltered.find(station => station.id === stationResult.id) || [])) }
        }
        if (contributorFiltered.length) {
            return { ...defaultResult, stations: contributorFiltered }
        }
        return { ...defaultResult, stations: searchFiltered }
    }, [pluviometersFormatted, filters.filter, filters.filterResults, containsSearchValue, userBookmarks])

    useActions(() => {
        const defaultActions = {
            export: () => {
                return {
                    data,
                    exportType: 'xlsx',
                    titleFile: data.title,
                }
            },
        }
        const currentUser = getUser()
        const newActions = (currentUser.admin === '1' || currentUser.metadata === '1') ? {
            ...defaultActions,
            new: () => setOpen(true),
        } : defaultActions
        return newActions
    }, [data])

    const closeDialog = () => setOpen(false)

    const onValidate = (newElement) => {
        dispatch(PluviometryAction.createPluviometer(newElement, id => {
            closeDialog()
            dispatch(push(`/station/${stationType}/${id}/description`))
        }))
    }

    const pluvioReferents = uniq(contributorLinks.map(l => l.idContributor)).map(id => contributorsIndex[id]).filter(c => !isUndefined(c))

    return (
        <div style={{ marginRight: 5, paddingBottom: 100 }}>
            <Grid container spacing={2}>
                <Grid container item xs={12} justifyContent='flex-end' sx={{ paddingBottom: '1rem' }}>
                    <Grid item xs={3}>
                        <StationStatisticPanel stationType={stationType} round />
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <StationFilterFields
                        defaultFilter={filters}
                        onValidate={setFilters}
                        stationType={stationType}
                        stations={!isUndefined(data.stations) ? data.stations : pluviometersFormatted}
                        uniqReferents={pluvioReferents}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginTop: -60 }}>
                    <TabList
                        onChangeTab={setPanel}
                        tabs={[
                            {
                                value: STATION_LIST,
                                label: i18n.table,
                                icon: 'list',
                            },
                            {
                                value: MAP,
                                label: i18n.map,
                                icon: 'map',
                            },
                        ]}
                    >
                        {panel === STATION_LIST && <DashboardStationsPanel stationType={stationType} data={data} />}
                        {panel === MAP && <DashboardMapPanel stationType={stationType} data={data} />}
                        {panel === SAMPLE_LIST && <StationsSampleListPanel stations={data.stations} />}
                    </TabList>
                </Grid>
                <DashboardAddStationDialog
                    open={open}
                    closeDialog={closeDialog}
                    stations={pluviometers}
                    title={i18n.newPluviometer}
                    onValidate={onValidate}
                />
            </Grid>


        </div>

    )
}

PluviometryPanel.propTypes = {
    stationType: PropTypes.string,
}

const PluviometersDashboard = ({
}) => {
    const {
        cities,
        userBookmarks,
        contributorLinks,
        contributors,
        sandreCodes,
        status,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        userBookmarks: store.UserReducer.userBookmarks,
        contributorLinks: store.StationReducer.contributorLinks,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        status: store.QualityReducer.status,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!componentHasHabilitations(H_PLUVIO_DASHBOARD)) {
            dispatch(push('/unauthorized'))
        }
    }, [dispatch])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !userBookmarks.length ? [UserAction.fetchBookmarks] : []
        const findedStationType = findStationType(stationType)
        const promisesContributorsLinks = (!contributorLinks.length && findedStationType.code != 9) ? [...promises, () => StationAction.fetchAllContributors(findedStationType.code)] : promises
        const promisesContributors = !contributors.length ? [...promisesContributorsLinks, ContributorAction.fetchContributors] : promisesContributorsLinks
        const promisesStatus = !status.length ? [...promisesContributors, QualityAction.fetchStatus] : promisesContributors
        const promisesSandreCodes = !sandreCodes.length ? [...promisesStatus, ReferencialAction.fetchSandreCodes] : promisesStatus
        const promisesCities = !cities.length ? [...promisesSandreCodes, CityAction.fetchCities] : promisesSandreCodes
        return promisesCities.map(p => dispatch(p()))
    }, [])

    useTitle(() => [{
        title: i18n[stationType],
        href: stationType,
    }, {
        title: i18n.dashboard,
        href: stationType,
    }], [stationType])

    return isLoaded ? (
        <Grid container>
            <PluviometryPanel />
        </Grid>
    ) : (
        <Grid container sx={{ padding: '1rem' }}>
            <Grid item xs={12}>
                <ProgressCard progress={progress} withMessage />
            </Grid>
        </Grid>
    )
}

export default PluviometersDashboard
