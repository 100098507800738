import React, { Component } from 'react'
import { connect } from 'react-redux'
import { instanceOf } from '../../../utils/StoreUtils'
import { getFullDate } from '../../../utils/DateUtil'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import i18n from 'simple-react-i18n'
import AlertSitePanel from '../../../alerting/components/map/AlertSitePanel'
import { find, isEqual, isUndefined } from 'lodash'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import { getLastMeasuresFormat } from '../../../alerting/utils/MeasureUtil'
import moment from 'moment'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PropTypes from 'prop-types'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import Row from '../../react/Row'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoStation from '../../../station/dto/DtoStation'
import iconQualito from 'assets/pictures/markers/map_qualite.png'
import iconPiezo from 'assets/pictures/markers/map_piezo.png'
import iconPluvio from 'assets/pictures/markers/map_pluvio.png'
import iconHydro from 'assets/pictures/markers/map_hydro.png'
import iconInstallation from 'assets/pictures/markers/map_installation_defaut.png'
import iconUnite from 'assets/pictures/markers/map_unites.png'
import iconContact from 'assets/pictures/markers/map_contact.png'
import DtoMeasure from '../../../alerting/dto/alert/DtoMeasure'
import { hasValue } from '../../../utils/NumberUtil'
import { getIconType } from '../../../utils/mapUtils/SiteTypes'
import { INSTALLATION_TYPES } from '../../../installation/constants/InstallationConstants'
import { getLocalStorageJson } from '../../../utils/FormUtils'

class MapPopupStation extends Component {
    state = {
        dataLoaded: false,
        lastMeasureHistory: [],
        lastMeasure: [],
        message: i18n.clickToDisplayStationDashboard,
    }

    putDataIntoState = (json, lastMeasure) => {
        this.setState({
            lastMeasure,
            dataLoaded: true,
        })
        if (!find(this.state.lastMeasureHistory, m => m.id === this.props.station.id)) {
            if (lastMeasure.length) {
                this.setState({
                    lastMeasureHistory: [
                        ...this.state.lastMeasureHistory,
                        { id: this.props.station.id, lastMeasure: lastMeasure[0] },
                    ],
                })
            } else {
                this.setState({
                    lastMeasureHistory: [
                        ...this.state.lastMeasureHistory,
                        { id: this.props.station.id },
                    ],
                })
            }
        }
    }

    getCityPopulation = (station) => {
        const city = this.props.citiesIndex[station.townCode]
        if (city && city.population) {
            return `Population : ${city.population}`
        }
        return ''
    }

    fetchStationMeasure = () => {
        const alreadyFetchedMeasure = find(this.state.lastMeasureHistory, m => m.id === this.props.station.id)
        if (alreadyFetchedMeasure) {
            this.setState({ lastMeasure: alreadyFetchedMeasure.lastMeasure ? [alreadyFetchedMeasure.lastMeasure] : [], dataLoaded: true })
        } else {
            const criterias = { date: moment().valueOf(), nbDays: 30, dataType: -1 }
            switch (this.props.station.typeName) {
                case 'pluviometry':
                    PluviometryAction.promisePluviometerLastMeasures([this.props.station.id])
                        .then(json => {
                            const lastMeasure = getLastMeasuresFormat(this.props.station.id, json.map(m => new DtoMeasure(m)), 'pluviometry')
                            this.putDataIntoState(json, lastMeasure)
                        })
                    break
                case 'piezometry':
                    PiezometryAction.promisePiezometrySituation(criterias, [this.props.station.id], 'type')
                        .then(json => {
                            const lastMeasure = getLastMeasuresFormat(this.props.station.id, json, 'piezometry')
                            this.putDataIntoState(json, lastMeasure)
                        })
                    break
                case 'hydrometry':
                    HydrometryAction.promiseHydroLastMeasures([this.props.station.id], getLocalStorageJson('MAP_SITUATION_SELECTED')?.typeId || 4)
                        .then(json => {
                            const lastMeasure = getLastMeasuresFormat(this.props.station.id, json, 'hydrometry')
                            this.putDataIntoState(json, lastMeasure)
                        })
                    break
                default:
                    this.setState({ dataLoaded: true, lastMeasure: [], message: this.getCityPopulation(this.props.station) })
                    break
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.station, this.props.station)) {
            if (this.props.setStation) {
                this.props.setStation(this.props.station)
            }
            if (this.props.click) {
                this.setState({ dataLoaded: false, lastMeasure: [], message: this.getCityPopulation(this.props.station) })
                this.fetchStationMeasure()
            } else {
                this.setState({ lastMeasure: [], message: this.getCityPopulation(this.props.station) })
            }
        }
        if (prevProps.click !== this.props.click && isEqual(prevProps.station, this.props.station)) {
            this.setState({ dataLoaded: false, lastMeasure: [], message: this.getCityPopulation(this.props.station) })
            this.fetchStationMeasure()
        }
        $('ul.tabs').tabs()
    }

    componentDidMount() {
        if (this.props.click) {
            this.fetchStationMeasure()
        } else {
            this.setState({ dataLoaded: true, lastMeasure: [] })
        }
    }

    getLink = (station) => {
        if (station.typeName === 'contact' || station.typeName === 'contributor') {
            return `#/referencial/${station.typeName}/${station.id}`
        }
        return `#/station/${station.typeName}/${station.id}`
    }

    // React cannot trigger clicks from this component which is on the Map, you must use the EventListener in PopupContent.js
    render() {
        if (this.state.dataLoaded) {
            const station = this.props.station || {}
            const icon = (() => {
                if (station.markerIcon) {
                    return station.markerIcon
                }
                switch (station.typeName) {
                    case 'quality':
                        return iconQualito
                    case 'piezometry':
                        return iconPiezo
                    case 'pluviometry':
                        return iconPluvio
                    case 'hydrometry':
                        return iconHydro
                    case 'installation':
                        if (hasValue(station.installationType)) {
                            const found = INSTALLATION_TYPES.find(t => t.code === station.installationType)
                            return found ? getIconType({ type: `INSTALLATION_${found.key}` }) : iconInstallation
                        }
                        return iconInstallation
                    case 'productionUnit':
                        return iconUnite
                    case 'contact':
                    case 'contributor': return iconContact
                    default:
                        return null
                }
            })()
            if (station.popupContent) {
                return (
                    <div className='popupStation'>
                        {station.popupContent}
                    </div>
                )
            }
            if (station.popupContentWithIcon) {
                return (
                    <div className='row no-margin valign-wrapper popupStation'>
                        <div className='col s1 no-padding no-margin-left'>
                            {
                                icon?.startsWith('<svg') ? (
                                    <img className='responsive-img' src={`data:image/svg+xml;utf8,${icon}`} />
                                ) : (
                                    <img className='max-width-20 margin-right-1 responsive-img' src={icon} />
                                )
                            }
                        </div>
                        {station.popupContentWithIcon}
                    </div>
                )
            }
            if (this.state.lastMeasure && this.state.lastMeasure.length > 0) {
                const site = {
                    ...station,
                    lastMeasure: this.state.lastMeasure,
                    measureDate: this.state.lastMeasure[0] && moment(this.state.lastMeasure[0].measureDate),
                }
                return (
                    <div className='row no-margin valign-wrapper'>
                        <div className='col s2 no-padding no-margin-left'>
                            {
                                icon?.startsWith('<svg') ? (
                                    <img className='responsive-img' src={`data:image/svg+xml;utf8,${icon}`} />
                                ) : (
                                    <img className='max-width-20 margin-right-1 responsive-img' src={icon} />
                                )
                            }
                        </div>
                        <div className='col s10 no-padding'>
                            <a style={{ width: '100%' }} className='width-100'>
                                <AlertSitePanel
                                    site={site}
                                    measureDate={getFullDate(site.measureDate)}
                                    message={this.state.message}
                                    withoutIcon
                                    onClick={station.onClick}
                                    unit={station.unitSymbol}
                                />
                            </a>
                        </div>
                    </div>
                )
            }
            return (
                <div className='row no-margin valign-wrapper'>
                    <div className='col s2 no-padding no-margin-left'>
                        {
                            icon?.startsWith('<svg') ? (
                                <img className='responsive-img' src={`data:image/svg+xml;utf8,${icon}`} />
                            ) : (
                                <img className='max-width-20 margin-right-1 responsive-img' src={icon} />
                            )
                        }
                    </div>
                    {
                        station.typeName === 'cms' ? (
                            <div className='col s10 no-padding'>
                                <a href={`#/contents/${station.idCategory}/${station.id}`}>
                                    <Row>
                                        <p className='bold'>{station.title}</p>
                                    </Row>
                                    <Row>
                                        <p>{getFullDate(station.dateDebut)} {station.dateFin ? ` au ${getFullDate(station.dateFin)}` : ''}</p>
                                    </Row>
                                </a>
                            </div>
                        ) : (
                            <a style={{ width: '100%' }} href={isUndefined(station.onClick) ? this.getLink(station) : undefined}>
                                <AlertSitePanel
                                    site={station}
                                    measureDate={getFullDate(station.measureDate)}
                                    message={this.props.currentStation && (this.props.currentStation.id === station.id) ? '' : this.state.message}
                                    withoutIcon
                                    onClick={station.onClick}
                                    unit={station.unitSymbol}
                                />
                            </a>
                        )
                    }
                </div>
            )
        }
        return (
            <div>
                {i18n.progressLoading}
            </div>
        )
    }
}

MapPopupStation.propTypes = {
    station: instanceOf(DtoQualitometer),
    click: PropTypes.bool,
    setStation: PropTypes.func,
    citiesIndex: PropTypes.objectOf(CityDto),
    currentStation: PropTypes.instanceOf(DtoStation),
}

const mapStateToProps = store => ({
    citiesIndex: store.CityReducer.citiesIndex,
})

export default connect(mapStateToProps)(MapPopupStation)