import { createAsyncThunk } from '@reduxjs/toolkit'
import { H_AGRI_MODULE } from 'account/constants/AccessRulesConstants'
import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import { REJECTED, IDLE } from 'store/DataManagerConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from 'utils/ActionUtils'
import { isAuthorized } from 'utils/HabilitationUtil'

const AgriThunk = {
    fetchExploitationsLight: createAsyncThunk(
        'fetchExploitationsLight',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.agri.exploitationsLight(), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.exploitations} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.exploitations}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_AGRI_MODULE, state)) {
                    return false
                }
                const { exploitationsLightStatus } = state.DataManagerReducer.agri
                if (arg.forceFetch) {
                    return exploitationsLightStatus !== REJECTED
                }
                return exploitationsLightStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchSurveysWithStats: createAsyncThunk(
        'fetchSurveysWithStats',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.agri.surveysWithStats(), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.surveys} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.surveys}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_AGRI_MODULE, state)) {
                    return false
                }
                const { surveysWithStatsStatus } = state.DataManagerReducer.agri
                if (arg.forceFetch) {
                    return surveysWithStatsStatus !== REJECTED
                }
                return surveysWithStatsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),
}

export default AgriThunk