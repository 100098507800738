import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import React from 'react'

const colorObject = {
    gray: '#808080',
    darkblue: '#00008b',
    purple: '#800080',
    blue: '#0000ff',
    green: '#008000',
    yellow: '#ffff00',
    orange: '#ffa500',
    red: '#ff0000',
    black: '#000000',
    white: '#ffffff',
}

const formatColor = (color = 'white') => {
    if (color.startsWith('#')){
        return color.replace('#', '%23')
    }
    const rgbColor = colorObject[color] ?? color
    return rgbColor.replace('#', '%23')
}

const getPiezoMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_piezo-svg</title><g id="layer1"><path id="path630" fill="white" d="m30.9 57.8c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g585"><path id="path587" fill-rule="evenodd" fill="${colorFormatted}" d="m29.2 77.3c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12.1-6.1-6.5-8.5-9.6-10.6-14.1-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.6-5.1 5.3-8.3 9.2-9.9 12-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.8-2.8-0.7-8.6-0.4-11.3 0.5-6.2 2.1-11 6.9-13.2 13.2-1 2.7-1.2 8-0.5 11 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.1 8.4-0.5zm-14.2-26.1h-8.5v-2.5h11.1v5.4c0 2.9 0.1 5.4 0.3 5.4 0.1 0 0.4-0.3 0.6-0.5 0.4-0.4 0.5-0.4 0.9 0.1 0.6 0.9 1.8 0.9 2.4 0.1l0.4-0.7 0.6 0.7q1.1 1.2 2.2 0l0.6-0.7 0.5 0.7c0.5 0.8 1.8 0.8 2.4-0.1 0.4-0.5 0.5-0.5 0.8-0.1 0.3 0.2 0.6 0.5 0.7 0.5 0.2 0 0.3-2.5 0.3-5.4v-5.4h11.1v2.5h-8.6v22.8h-17.8z"/></g></g></svg>`
}
const getHydroMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_hydro-svg</title><g id="g-1"><g id="g25"><path id="ellipse-1" fill="white" d="m30.9 57.8c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g458"><path id="path466" fill-rule="evenodd" fill="${colorFormatted}" d="m15.4 40.9c0-3.1 0-5.7 0.1-5.7 0 0 0.8 0.3 1.6 0.7 0.8 0.3 2.5 0.7 3.8 0.8l2.4 0.2v-18.2h13.3v15.7h3.3c1.8 0 3.7 0.1 4.1 0.3l0.9 0.2v11.7h-29.5zm14.6-5.1c1.8-0.3 3.6-0.8 4-0.9l0.7-0.3v-6.9l-0.1-6.9h-9.2v0.8c0 0.7 0.1 0.8 1.3 0.9 1.3 0.1 1.4 0.1 1.3 0.9-0.1 0.8-0.2 0.9-1.4 1-1.2 0.1-1.4 0.2-1.4 0.8 0 0.8 0.1 0.8 2.5 0.8h2.6v1.9l-2.5 0.1c-2.3 0.1-2.4 0.1-2.5 0.8-0.1 0.6 0 0.7 1.3 0.7 1.4 0 1.5 0 1.5 0.9 0 0.9-0.1 1-1.4 1.1-1.2 0.1-1.3 0.1-1.3 0.9 0 0.7 0.1 0.8 1.3 0.8 1.3 0.1 1.4 0.2 1.3 1-0.1 0.8-0.2 0.9-1.4 0.9-1.1 0.1-1.4 0.3-1.4 0.7 0 0.7 0.2 0.9 0.9 0.8 0.3-0.1 2-0.4 3.9-0.8z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" d="m29.2 77.3c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12.1-6.1-6.5-8.5-9.6-10.6-14.1-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.6-5.1 5.3-8.3 9.2-9.9 12-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.8-2.8-0.7-8.6-0.4-11.3 0.5-6.2 2.1-11 6.9-13.2 13.2-1 2.7-1.2 8-0.5 11 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.1 8.4-0.5z"/></g></g></g></svg>`
}
const getHydroTideMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 52" width="40" height="52"><title>map_hydro-svg</title><g id="g-1"><g id="g25"><path id="ellipse-1" fill="white" d="m20 37.9c-9.2 0-16.6-7.7-16.6-17.2 0-9.6 7.4-17.3 16.6-17.3 9.3 0 16.7 7.7 16.7 17.3 0 9.5-7.4 17.2-16.7 17.2z"/><g id="Layer"><path id="Layer" fill-rule="evenodd" fill="${colorFormatted}" d="m17.3 10.2h5.7v14h4.3c3.7-0.2 4.1 0 2.7 0.7-1.1 0.6-2.1 0.6-2.9 0.2-0.7-0.4-1.8-0.4-2.9 0-1 0.4-2 0.5-2.2 0.3-0.7-0.7-7.7-0.7-9-0.2-0.7 0.3-1.8 0.2-2.7-0.3-1.5-0.7-1.1-0.9 2.7-0.7h4.3zm2.8 0.8h-2.1v13.1h2.1c2.3 0 2.9-1.5 0.9-2-1.2-0.2-1.2-0.3 0-0.4 0.7 0 1.3-0.3 1.3-0.7 0-0.4-0.6-0.9-1.3-1-1.2-0.3-1.2-0.4 0-0.4 0.7 0 1.3-0.4 1.3-0.7 0-0.4-0.6-0.9-1.3-1-1.2-0.3-1.2-0.4 0-0.5 0.7 0 1.3-0.4 1.3-0.7 0-0.5-0.6-0.8-1.3-0.8-1.2 0-1.2-0.1 0-0.9 1-0.7 1-0.8 0-1.7-1.2-0.8-1.2-0.9 0-0.9 0.7 0 1.3-0.4 1.3-0.7 0-0.5-1-0.7-2.2-0.7z"/><path id="Layer" fill-rule="evenodd" fill="${colorFormatted}" d="m13 13.1c1 0 2.8 3.4 2.1 3.9-0.4 0.2-0.7 1.7-0.7 3.2 0 2.3-0.2 2.8-1.4 2.8-1.2 0-1.5-0.5-1.5-2.8 0-1.5-0.2-3-0.7-3.2-0.4-0.3-0.3-1.2 0.5-2.2 0.6-1 1.4-1.7 1.7-1.7zm0 1.4c-0.4 0-0.7 1.8-0.7 4 0 2.4 0.2 3.7 0.7 3.5 0.4-0.3 0.7-2 0.7-4 0-2-0.3-3.5-0.7-3.5z"/>
    <path id="Layer" fill-rule="evenodd" fill="${colorFormatted}" d="m27.3 13.1c1.2 0 1.4 0.5 1.4 2.8 0 1.6 0.3 2.9 0.8 2.9 0.3 0 0.1 0.9-0.6 2.1-0.6 1.2-1.4 2.1-1.6 2.1-0.3 0-1-1-1.7-2.1-0.7-1.2-0.9-2.1-0.6-2.1 0.5 0 0.8-1.3 0.8-2.9 0-2.3 0.2-2.8 1.5-2.8zm0.7 4.6c0-2.2-0.4-3.9-0.7-3.9-0.4 0-0.8 1.7-0.8 3.9 0 2.2 0.4 3.9 0.8 3.9 0.3 0 0.7-1.7 0.7-3.9z"/><path id="Layer" fill="${colorFormatted}" d="m9.9 27.2c0.7 0.5 1.9 0.7 2.7 0.5 0.8-0.3 1.9-0.5 2.4-0.4 0.4 0 1.5 0 2.3 0 0.7 0 2 0 2.8 0 0.8 0 2.1 0 2.9 0 0.8 0 1.8 0 2.3 0 0.5-0.1 1.5 0.1 2.3 0.4 0.8 0.2 2 0 2.7-0.5 1-0.8-0.6-1-10.2-1-9.6 0-11.2 0.2-10.2 1z"/><path id="Layer" fill="${colorFormatted}" d="m9.2 28.7c1.3 1.5 19.9 1.5 21.6 0.2 1-0.9 1-1-0.3-0.5-0.8 0.3-1.9 0.5-2.3 0.4-1.2-0.1-14.9-0.1-16.1 0-0.5 0.1-1.6-0.1-2.3-0.4-1.2-0.5-1.3-0.5-0.6 0.3z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" d="m18.8 51.8c-0.3-0.2-1-1-1.5-1.9-1.3-2.2-2.8-4-7.2-8.6-4.4-4.7-6.1-6.9-7.6-10.1-1.7-3.5-2.1-5.5-2.1-10 0-4.3 0.3-5.9 1.9-9.2 2.5-5.4 7.1-9.2 12.8-10.7 2.4-0.6 6.8-0.7 9.1-0.1 10.1 2.4 16.5 11.8 15.3 22.7-0.7 6.3-3 10.4-9.8 17.5-3.6 3.8-5.9 6.6-7.1 8.6-1.2 2-2.3 2.5-3.8 1.8zm4.9-17.2c5.1-1.3 9.1-5.3 10.5-10.5 0.5-1.9 0.5-5.5 0-7.4-1.4-5.2-5.5-9.2-10.8-10.5-2-0.5-6.1-0.4-8.1 0.3-4.4 1.5-7.8 4.9-9.4 9.4-0.7 2-0.8 5.7-0.3 7.8 1.4 5.8 6.2 10.3 12.1 11.2 1.6 0.3 4.3 0.1 6-0.3z"/></g></g></g></svg>`
}
const getPluvioMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_pluvio-svg</title><g id="g-1"><path id="ellipse-1" fill="white" opacity="{6565651}" d="m30.9 57.2c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g428"><path id="path430" fill="${colorFormatted}" d="m35.2 48.2c-0.8-0.8-0.7-2.3 0.2-3.5 0.8-1.3 1.3-1.4 2.1-0.3 1 1.2 1.2 3 0.5 3.8-0.8 0.8-2.2 0.8-2.8 0zm-11.8-5c-1-1.2-0.5-3.9 1-5.3 0.6-0.5 0.7-0.5 1.5 0.4 1.4 1.5 1.7 3.8 0.6 5-0.7 0.6-2.5 0.6-3.1-0.1zm5.2-7.3c-8.5-0.2-8.8-0.3-9.8-1q-2-1.4-2-3.8c0-2.3 1.8-4.5 4-5 0.9-0.1 1.1-0.3 1.3-1.2 0.7-3.1 4.1-5.1 7-4 0.4 0.2 1 0 1.9-0.7 1.1-0.7 1.8-1 3.3-1.1 4.5-0.3 7.6 2.6 7.7 7.1 0 1.1 0.1 1.3 1.3 1.9 3.1 1.5 3.2 5.7 0.2 7.3-1.2 0.6-4 0.7-14.9 0.5z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" d="m29.2 76.7c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12-6.1-6.6-8.5-9.7-10.6-14.2-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.7-5.1 5.2-8.3 9.1-9.9 11.9-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.7-2.8-0.8-8.6-0.5-11.3 0.4-6.2 2.1-11 6.9-13.2 13.3-1 2.7-1.2 7.9-0.5 10.9 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.2 8.4-0.5z"/></g></g></svg>`
}
const getInstMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_installation-svg</title><g id="g-1"><path id="ellipse-1" fill="white" opacity="{6565651}" d="m30.9 57.2c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g428"><path id="Layer" fill="${colorFormatted}" d="m19 22.2c0 0.8 2 1.5 4.4 1.8l4.3 0.4 0.4 9 0.4 9.1h-4.8c-2.5 0-4.7 0.5-4.7 1.3 0 0.9 5 1.4 12.1 1.4 7.1 0 12.1-0.5 12.1-1.4 0-0.8-1.8-1.3-4-1.3h-4.1v-9.4-9.4h4.1c2.2 0 4-0.7 4-1.4 0-0.8-5-1.3-12.1-1.3-6.7 0-12.1 0.5-12.1 1.2z"/></g><g id="g-2"><path id="path-6" fillRule="evenodd" fill="${colorFormatted}" d="m29.2 76.7c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12-6.1-6.6-8.5-9.7-10.6-14.2-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.7-5.1 5.2-8.3 9.1-9.9 11.9-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.7-2.8-0.8-8.6-0.5-11.3 0.4-6.2 2.1-11 6.9-13.2 13.3-1 2.7-1.2 7.9-0.5 10.9 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.2 8.4-0.5z"/></g></g></svg>`
}

const getInstBoreholeMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_forage-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m26.5 2.1q39.8 2.4 32.2 41.2-11.2 19.3-27.9 34.1-12.8-13-25.1-27-15.9-35.9 20.8-48.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m49.4 22q-6.4 0-12.9 0 0.5 5.9-1.4 11.4-5.7 0-11.5 0 0-5.7 0-11.4-5.7 0-11.4 0 9.8-16.9 28.6-9.2 6.2 2.9 8.6 9.2z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m12.2 22q5.7 0 11.4 0 0 5.7 0 11.4 5.8 0 11.5 0 3.9-3.8 2.8-10 5.8 0 11.5 0-4.5 2.1-10 1.5 0 11.3 0 22.7-9.3 0-18.6 0 0-11.4 0-22.7-5.6 1-8.6-2.9z"/></g><g id="Layer"><path id="Layer" fill="white" d="m49.4 22q1.4 0 1.4 1.4-0.7 0-1.4 0-5.7 0-11.5 0 1.1 6.2-2.8 10 1.9-5.5 1.4-11.4 6.5 0 12.9 0z"/></g><g id="Layer"><path id="Layer" fill="white" d="m12.2 22q3 3.9 8.6 2.9 0 11.3 0 22.7 9.3 0 18.6 0 0-11.4 0-22.7 5.5 0.6 10-1.5 0.7 0 1.4 0 4.4 30.9-27.2 27.8-19.5-9.7-11.4-29.2z"/></g></svg>`
}

const getInstIndustrialSiteMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_site_industriel-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m26.5 2.1q39.8 2.4 32.2 41.2-11.2 19.3-27.9 34.1-12.8-13-25.1-27-15.9-35.9 20.8-48.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m49.4 41.9q-0.7 0-1.4 0 0.1-12.5-2.9-24.1-3.6 0-7.2 0-0.7 19.9-1.4 0-3.6 0-7.1 0 0.3 4.3-0.8 8.5-3.2-0.8-7.1 0-1.1-2-0.7-4.3-2.2 0-4.3 0-0.8 10.2-2.9 19.9-0.7 0-1.4 0-9.5-26 17.9-31.2 19.6 0.6 22.2 19.9-0.2 6-2.9 11.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m48 41.9q-17.2 0-34.4 0 2.1-9.7 2.9-19.9 2.1 0 4.3 0-0.4 2.3 0.7 4.3 3.9-0.8 7.1 0 1.1-4.2 0.8-8.5 3.5 0 7.1 0 0.7 19.9 1.4 0 3.6 0 7.2 0 3 11.6 2.9 24.1z"/></g><g id="Layer"><path id="Layer" fill="white" d="m20.8 32q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m27.9 32q2.4 0.2 2.9 2.8-0.7 1.4-1.4 2.9-2-2.6-1.5-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m12.2 41.9q0.7 0 1.4 0 17.2 0 34.4 0 0.7 0 1.4 0-0.7 2.2-2.9 2.9-16-2.2-32.9-1.5-1.4 0-1.4-1.4z"/></g><g id="Layer"><path id="Layer" fill="white" d="m13.6 43.3q16.9-0.7 32.9 1.5-6.3 7.4-16.4 7.1-10.6-0.1-16.5-8.6z"/></g></svg>`
}

const getInstStepMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_assainissement-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m26.5 2.1q39.8 2.4 32.2 41.2-11.2 19.3-27.9 34.1-12.8-13-25.1-27-15.9-35.9 20.8-48.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m23.6 10.7q33-1.8 26.5 29.8-12.5 17.6-32.2 7.8-13.9-12.6-5-29.1 4.5-5.6 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 24.9q-1.7 8.2 4.3 14.9-0.2 2.7-2.8 3.5-7.8-4-16.5-1.4-15.7 2.2-11.5-12.8 10.7-4.1 20.8-9.2 3.5-1.1 7.2-0.7 0.5 3.4-1.5 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m20.8 27.7q4.3-0.3 8.6 0.7-5 2.9 0 3.6 4-1.1 7.8-2.9 2.8 0.3 3.6 2.9-9.1 6.1-20 3.5-8.3-3.8 0-7.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m42.2 24.9q-3.8 2.6-8.6 2.8 0.7-7.5 8.6-7.1 0 2.1 0 4.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m37.9 36.2q5.6 0.1 4.3 4.3-3.6-0.8-4.3-4.3z"/></g></svg>`
}

const getInstFlowObstructionMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_obstacle-svg</title><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q36.7-2.3 29.3 32.7-12.6 19.3-33.6 9.2-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m33 0.4c17.8 0.9 28.5 12 29.5 30 0.9 13.5-1.9 20-14.3 33.8-5.5 6.1-10.8 12.6-11.6 14.3-2.2 4.9-7.3 4.2-11.3-1.4-1.7-2.4-6.6-8.3-10.8-12.8-11.4-12.5-14-18.4-14-32.1 0-9.5 0.6-12.5 3.4-17.5 3.6-6.4 9.6-11.5 15.8-13.5 2-0.8 8-1.1 13.3-0.8zm-23.3 36.6c4 14.8 21.9 20.7 35 11.6l5.2-3.4-35.8-0.9v-4.2c0-4.3 0-4.3 8.2-4.3 4.4 0 7.6-0.4 7.1-0.8-0.4-0.6-4.1-1-8-1-6.4 0-7-0.2-5.4-2.6 1.3-2.1 2-2.2 3.5-1 1.5 1.3 2.3 1.1 3.5-0.5 1-1.6 1.6-1.6 2.1-0.3 1 2.6 4.8 2.3 4.8-0.3 0-2.3-1.6-2.5-10.4-2.1-5.8 0.3-6.5-0.6-3.4-3.6 1.4-1.4 2.3-1.6 3.4-0.4 1.2 1.1 2.2 1.1 3.9-0.3 1.4-1.2 2.2-1.3 2.2-0.2 0 0.9 1 1.6 2.1 1.6 1.2 0 2.2-0.6 2.2-1.3 0-1.7-4.5-2.7-10.6-2.3-5.8 0.6-6.2 0.2-2.9-3.1 1.9-2.1 2.4-2.1 3.6-0.3 1.1 1.7 1.5 1.7 2.8-0.2 1.2-1.6 1.8-1.7 2.3-0.4 0.9 2.1 4.8 2.4 4.8 0.4 0-0.8 2.5-1.4 5.7-1.4h5.6l0.4 11.1c0.3 6.7 1 11.2 2 11.2 0.9 0 2.7 1.1 4.1 2.7 2.6 2.6 2.8 2.6 4.1-0.7 1.8-5.2 0.2-16.8-2.9-21.2-1.4-2-4.3-4.8-6.3-6.1-15.3-11.3-37.9 5.4-32.9 24.3z"/></g></svg>`
}

const getInstPollutedSoilMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_sols-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m22.2-0.7q7.9 0 15.7 0 17.7 5.5 24.4 22.7 0 10 0 19.9-3.9 9.1-10 17.1-1.5 0-1.5 1.4-9.9 8.4-15.7 19.9-3.6 0-7.2 0-7.6-12.9-19.3-22.7-5.9-8.9-9.3-18.5 0-7.8 0-15.7 2.7-8.1 7.9-15.6 6.6-6.3 15-8.5z"/></g><g id="Layer"><path id="Layer" fill="white" d="m23.6 7.8q19.7-2.8 29.4 14.2 4.3 33.4-29.4 30.6-22.7-11.7-12.1-34.8 5.2-6.3 12.1-10z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m46.5 26.3q-4.3 0.3-8.6-0.7 2.2-2.3 0.8-5-4.2 2.8-7.9 0 0.2-1.3 1.4-2.1 7.2-1.4 14.3 0 5.7 4.2 0 7.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m30.8 20.6q3.7 2.8 7.9 0 1.4 2.7-0.8 5 4.3 1 8.6 0.7-5 2.8-10 0-2.4-5-7.1-1.4-0.6-2.8 1.4-4.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m36.5 26.3q-1.9-0.3-2.9 1.4 5.8 3.6 11.5 7.1 2.7 7.8-5.7 10.7-14.4 5.4-26.5-3.6-1.4-3.5 0-7.1 8.2-5.2 16.5-9.9 4.7-3.6 7.1 1.4z"/></g><g id="Layer"><path id="Layer" fill="white" d="m26.5 34.8q5.3-0.9 8.6 2.9-6.1 5.2-12.9 0 2.4-1.5 4.3-2.9z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m52.3 59q2.8 1.4 0 0z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m50.8 60.4q0.7 0 1.5 0-0.4 1.4-1.5 0z"/></g></svg>`
}

const getInstFishingSpotMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_fishing_spot-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q11.5 0 22.9 0 14.5 6.6 20.1 21.3 0 12.1 0 24.2-8.9 14.9-22.2 27-3.1 4-5 8.5-4.3 0-8.6 0-13.7-17.1-27.2-34.1 0-13.5 0-27 6.5-13.6 20-19.9z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q36.7-2.3 29.3 32.7-12.6 19.3-33.6 9.2-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 19.2q0 1.4 0 2.8-3.3-1.2-6.5-2.8-1.5 12.1-3.6 24.1-1.1-13.4 1.5-27 5.4-0.9 8.6 2.9z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 19.2q9 2.6 12.8 11.4 15.5-6.7 5.8 7.1 1.7 3.3 1.4 7.1-9-2.3-2.9-9.3-7.1-0.9-9.3-7.8-3-4.1-7.8-5.7 0-1.4 0-2.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m42.2 29.1q4 1.4 1.5 4.3-1.9-1.8-1.5-4.3z"/></g></svg>`
}

const getInstCavityMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_cavity-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q11.5 0 22.9 0 14.5 6.6 20.1 21.3 0 12.1 0 24.2-8.9 14.9-22.2 27-3.1 4-5 8.5-4.3 0-8.6 0-13.7-17.1-27.2-34.1 0-13.5 0-27 6.5-13.6 20-19.9z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q36.7-2.3 29.3 32.7-12.6 19.3-33.6 9.2-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m29.4 14.9q3.6 1.5 4.2 5.7 3.2 1.6 6.5 2.8 4.2 10.3 5 21.4-4.5 0.6-8.6-1.5-5.7-11.3-11.4 0-4.1 2.1-8.6 1.5 0.3-10.3 5.7-18.5 1.4 2.1 2.9 4.3 2.4-7.8 4.3-15.7z"/></g></svg>`
}

const getInstSampleWorkMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_prelevements-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-20.8 20.6-40.1-1.4-8.9-22.7 12.2-34.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q5.7 0 11.4 0 0 2.1 0 4.3-5.7 0-11.4 0 4.6-1.8 10-2.2-5.4-0.4-10-2.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m15 23.4q5.8 0 11.5 0 0 12.1 0 24.2-1.4-6.8-8.6-8.5-2.2 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 2.1-4.8 1.5-9.9-5.5 0.6-10.1-1.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q4.6 1.7 10 2.1-5.4 0.4-10 2.2 0 4.9 0 9.9 5-1.4 10-0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.7 3.5-5.2-5.4-10.7 0-2 2.6-1.4 5.7-4.3 0-8.6 0 0-12.1 0-24.2-5.7 0-11.5 0 3.9-3.9 10.1-2.8 0-2.8 0-5.7 5 0 10 0 0 2.9 0 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 17.8q2.7 2.9 2.8 7.8-0.7 3.9-1.4 7.8-2.1-7.7-1.4-15.6z"/></g></svg>`
}

const getInstCaptureMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_captages-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q14.4-2.2 25.8 6.4 4.6 4.6 5 10.7-6.6-2.1-13.6-1.5 0 2.9 0 5.7 7.1 0 14.3 0-4.9 32.3-35.8 20.6-16-14-6.4-33.4 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m29.4 13.5q8.5 0.9 2.8 6.4 8.2 2.3 5 10.7-1.5 1.1-2.1 2.8 4.6 0.4 10 0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.8 3.5-5.3-5.4-10.8 0-2 2.6-1.4 5.7-5 0-10 0 0.3-7.3-7.2-8.5-2.1 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 0.5-6.1 0.7-12.8 5.1-1.7 2.2-5.7 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g></svg>`
}

const getInstLiftingStationMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_poste_relevage-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q40.6-2 27.9 35.5-13.6 15.6-32.2 6.4-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m13.6 17.8q9.3-1.4 16.5 4.2 0.9 12.6-12.2 10 0 3.5 0 7.1-2.1 0-4.3 0 0-10.7 0-21.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m33.6 17.8q9.4-1.4 16.5 4.2 1.9 7.3-5 10.7 1.6 3.2 4.3 5.7-7.8 1.7-7.2-6.4-2.1 0-4.3 0 0 3.5 0 7.1-2.1 0-4.3 0 0-10.7 0-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m19.3 20.6q8 0.1 5.8 7.1-3.4 2-7.2 1.4-0.4-4.5 1.4-8.5z"/></g><g id="Layer"><path id="Layer" fill="white" d="m39.4 20.6q8 0.1 5.7 7.1-3.3 2-7.2 1.4-0.3-4.5 1.5-8.5z"/></g></svg>`
}

const getInstPumpingMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_prelevements-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-20.8 20.6-40.1-1.4-8.9-22.7 12.2-34.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q5.7 0 11.4 0 0 2.1 0 4.3-5.7 0-11.4 0 4.6-1.8 10-2.2-5.4-0.4-10-2.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m15 23.4q5.8 0 11.5 0 0 12.1 0 24.2-1.4-6.8-8.6-8.5-2.2 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 2.1-4.8 1.5-9.9-5.5 0.6-10.1-1.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q4.6 1.7 10 2.1-5.4 0.4-10 2.2 0 4.9 0 9.9 5-1.4 10-0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.7 3.5-5.2-5.4-10.7 0-2 2.6-1.4 5.7-4.3 0-8.6 0 0-12.1 0-24.2-5.7 0-11.5 0 3.9-3.9 10.1-2.8 0-2.8 0-5.7 5 0 10 0 0 2.9 0 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 17.8q2.7 2.9 2.8 7.8-0.7 3.9-1.4 7.8-2.1-7.7-1.4-15.6z"/></g></svg>`
}

const getInstTreatmentMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_poste_traitement-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q40.6-2 27.9 35.5-13.6 15.6-32.2 6.4-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m13.6 17.8q9.3-1.4 16.5 4.2 0.9 12.6-12.2 10 0 3.5 0 7.1-2.1 0-4.3 0 0-10.7 0-21.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m33.6 17.8q6.6-0.7 12.9 1.4-1.6 1.7-3.5 2.8-1.1 8.5-0.8 17.1-2.1 0-4.3 0 0-9.3 0-18.5-3.6 0.5-4.3-2.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m19.3 20.6q8 0.1 5.8 7.1-3.4 2-7.2 1.4-0.4-4.5 1.4-8.5z"/></g></svg>`
}

const getInstTankMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_reservoir-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m22.2-0.7q7.9 0 15.7 0 17.7 5.5 24.4 22.7 0 10 0 19.9-3.9 9.1-10 17.1-1.5 0-1.5 1.4-9.9 8.4-15.7 19.9-3.6 0-7.2 0-7.6-12.9-19.3-22.7-5.9-8.9-9.3-18.5 0-7.8 0-15.7 2.7-8.1 7.9-15.6 6.6-6.3 15-8.5z"/></g><g id="Layer"><path id="Layer" fill="white" d="m23.6 7.8q32.7-2 29.4 29.9-11.5 23.2-35.1 12-15.7-13.5-6.4-31.9 5.2-6.3 12.1-10z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m39.4 41.9q0 2.9 0 5.7-8.6 0-17.2 0 2.4-8.1 0-15.6-1.3-6.1-2.9-12.1 14.8-16.5 21.5 4.3-0.4 6.7-2.9 12.7 0.1 3 1.5 5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m22.2 32q2.4 7.5 0 15.6 8.6 0 17.2 0 0-2.8 0-5.7 2 3 1.4 7.1-10 0-20 0 1.1-8.5 1.4-17z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m52.3 59q2.8 1.4 0 0z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m50.8 60.4q0.7 0 1.5 0-0.4 1.4-1.5 0z"/></g></svg>`
}

const getInstAepProductionMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_production_aep-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-15.7 17.1-35.1 4.3-12-14.4-3.5-31.3 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 13.5q8.2 0.3 16.4 0 7.2 2.8 0 5.7-3.5-2.9-6.4 0.7 8.1 2.3 5 10.7-5.9 2.3-12.2 0.7-1.3-1.6-2.1-3.6-2.3 2.4-5 4.3 0.4 1.2 1.4 2.1-11.8 5.3-6.5-6.4 4.9-3.8 10.1-2.8 1.3-3.9 5-5.7-3.5-1.6-7.2-0.7-1-0.9-1.4-2.2 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 23.4q3.6 0 7.2 0 0.2 7.3-7.2 5.7 0-2.8 0-5.7z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m16.5 37.7q9.1 6.4-1.5 8.5 0.8-4.3 1.5-8.5z"/></g></svg>`
}

const getInstAepDisinfectionMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_production_aep-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-15.7 17.1-35.1 4.3-12-14.4-3.5-31.3 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 13.5q8.2 0.3 16.4 0 7.2 2.8 0 5.7-3.5-2.9-6.4 0.7 8.1 2.3 5 10.7-5.9 2.3-12.2 0.7-1.3-1.6-2.1-3.6-2.3 2.4-5 4.3 0.4 1.2 1.4 2.1-11.8 5.3-6.5-6.4 4.9-3.8 10.1-2.8 1.3-3.9 5-5.7-3.5-1.6-7.2-0.7-1-0.9-1.4-2.2 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 23.4q3.6 0 7.2 0 0.2 7.3-7.2 5.7 0-2.8 0-5.7z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m16.5 37.7q9.1 6.4-1.5 8.5 0.8-4.3 1.5-8.5z"/></g></svg>`
}

const getInstAepOverpressureMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_production_aep-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-15.7 17.1-35.1 4.3-12-14.4-3.5-31.3 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 13.5q8.2 0.3 16.4 0 7.2 2.8 0 5.7-3.5-2.9-6.4 0.7 8.1 2.3 5 10.7-5.9 2.3-12.2 0.7-1.3-1.6-2.1-3.6-2.3 2.4-5 4.3 0.4 1.2 1.4 2.1-11.8 5.3-6.5-6.4 4.9-3.8 10.1-2.8 1.3-3.9 5-5.7-3.5-1.6-7.2-0.7-1-0.9-1.4-2.2 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 23.4q3.6 0 7.2 0 0.2 7.3-7.2 5.7 0-2.8 0-5.7z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m16.5 37.7q9.1 6.4-1.5 8.5 0.8-4.3 1.5-8.5z"/></g></svg>`
}

const getInstAgriPrelMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_prelevements-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-20.8 20.6-40.1-1.4-8.9-22.7 12.2-34.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q5.7 0 11.4 0 0 2.1 0 4.3-5.7 0-11.4 0 4.6-1.8 10-2.2-5.4-0.4-10-2.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m15 23.4q5.8 0 11.5 0 0 12.1 0 24.2-1.4-6.8-8.6-8.5-2.2 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 2.1-4.8 1.5-9.9-5.5 0.6-10.1-1.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q4.6 1.7 10 2.1-5.4 0.4-10 2.2 0 4.9 0 9.9 5-1.4 10-0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.7 3.5-5.2-5.4-10.7 0-2 2.6-1.4 5.7-4.3 0-8.6 0 0-12.1 0-24.2-5.7 0-11.5 0 3.9-3.9 10.1-2.8 0-2.8 0-5.7 5 0 10 0 0 2.9 0 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 17.8q2.7 2.9 2.8 7.8-0.7 3.9-1.4 7.8-2.1-7.7-1.4-15.6z"/></g></svg>`
}

const getInstMarkerByType = (instType, color) => {
    switch (instType) {
        case INSTALLATION_TYPE.BOREHOLE:
            return getInstBoreholeMarker(color)
        case INSTALLATION_TYPE.INDUSTRIAL_SITE:
            return getInstIndustrialSiteMarker(color)
        case INSTALLATION_TYPE.STEP:
            return getInstStepMarker(color)
        case INSTALLATION_TYPE.FLOW_OBSTRUCTION:
            return getInstFlowObstructionMarker(color)
        case INSTALLATION_TYPE.POLLUTED_SOIL:
            return getInstPollutedSoilMarker(color)
        case INSTALLATION_TYPE.FISHING_SPOT:
            return getInstFishingSpotMarker(color)
        case INSTALLATION_TYPE.CAVITY:
            return getInstCavityMarker(color)
        case INSTALLATION_TYPE.SAMPLE_WORK:
            return getInstSampleWorkMarker(color)
        case INSTALLATION_TYPE.CAPTURE:
            return getInstCaptureMarker(color)
        case INSTALLATION_TYPE.LIFTING_STATION:
            return getInstLiftingStationMarker(color)
        case INSTALLATION_TYPE.PUMPING:
            return getInstPumpingMarker(color)
        case INSTALLATION_TYPE.TREATMENT:
            return getInstTreatmentMarker(color)
        case INSTALLATION_TYPE.TANK:
            return getInstTankMarker(color)
        case INSTALLATION_TYPE.OTHERS:
            return getInstMarker(color)
        case INSTALLATION_TYPE.AEP_PRODUCTION:
            return getInstAepProductionMarker(color)
        case INSTALLATION_TYPE.AEP_DISINFECTION:
            return getInstAepDisinfectionMarker(color)
        case INSTALLATION_TYPE.AEP_OVERPRESSURE:
            return getInstAepOverpressureMarker(color)
        case INSTALLATION_TYPE.AGRI_PREL:
            return getInstAgriPrelMarker(color)
        case INSTALLATION_TYPE.INDUS_PREL:
            return getInstMarker(color)
        case INSTALLATION_TYPE.SECTOR_COUNT:
            return getInstMarker(color)
        case INSTALLATION_TYPE.DOM_SUB:
            return getInstMarker(color)
        case INSTALLATION_TYPE.ELEC_COUNTER:
            return getInstMarker(color)
        case INSTALLATION_TYPE.NETWORK:
            return getInstMarker(color)
        case INSTALLATION_TYPE.STRAIT:
            return getInstMarker(color)
        case INSTALLATION_TYPE.BRIDGE:
            return getInstMarker(color)
        case INSTALLATION_TYPE.SLUICE:
            return getInstMarker(color)
        default:
            return getInstMarker(color)
    }
}

const getUnitProdMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 65" width="50" height="65"><title>map_unit_prod-svg</title><g id="g-3"><path id="ellipse-2" fill="white" d="m25.4 47.1c-11.6 0-20.9-9.6-20.9-21.5 0-11.9 9.3-21.5 20.9-21.5 11.5 0 20.8 9.6 20.8 21.5 0 11.9-9.3 21.5-20.8 21.5z"/><path id="path-7" fill="${colorFormatted}" d=""/><path id="path-8" fill="${colorFormatted}" d=""/><path id="path-9" fill="${colorFormatted}" d=""/><path id="path-10" fill="${colorFormatted}" d=""/><path id="path-11" fill="${colorFormatted}" d=""/><g id="g-4"><path id="path-12" fill-rule="evenodd" fill="${colorFormatted}" d="m23.8 64.5c-0.4-0.2-1.2-1.3-1.9-2.3-1.6-2.8-3.5-5-8.9-10.8-5.5-5.8-7.6-8.6-9.6-12.6-2.1-4.4-2.6-6.9-2.6-12.5 0-5.4 0.4-7.4 2.4-11.5 3.1-6.7 8.8-11.5 16-13.4 3-0.8 8.4-0.8 11.4-0.1 12.6 3 20.6 14.8 19.1 28.3-0.9 7.9-3.7 13-12.3 22-4.5 4.7-7.4 8.1-8.9 10.7-1.4 2.5-2.9 3.1-4.7 2.2zm6.2-21.5c6.3-1.6 11.3-6.6 13.1-13.1 0.6-2.4 0.6-6.8 0-9.2-1.8-6.5-7-11.5-13.5-13.2-2.6-0.6-7.7-0.4-10.2 0.4-5.4 1.9-9.7 6.2-11.7 11.8-0.9 2.4-1.1 7.1-0.4 9.7 1.7 7.3 7.8 12.9 15.2 14.1 1.9 0.3 5.3 0.1 7.5-0.5z"/></g><path id="path97" fill="${colorFormatted}" d="m10.4 28.6v-4.6l3.1-2.8 2.5-2.3v1.3l-0.1 3.4 1.5-2.2 1.6-2.4v1.1l0.1 3.3h1.9l1.5-0.1c0.7-3.3 1.3-6.6 1.8-10h2.4 2.4v9.1h1.9c0.2-3.2 0.4-6 0.5-9.2h2.4 2.7q0.4 4.3 0.7 8.6l1 0.9 0.9 1v5.7 5.8h-14.4-14.4v-5.6z"/></g></svg>`
}

const getArrowUp = (color, jsx, width) => {
    const colorFormatted = formatColor(color)
    if (jsx) {
        return <svg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62 84' width={ width ?? '36' } height='53'><title>Hausse</title><g id='layer1'><path id='path630' fill={color} stroke='black' stroke-linejoin='round' d='M 31,0 0,84 31,66 62,84 Z'/></g></svg>
    }
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>arrow_up-svg</title><g id="layer1"><path id="path630" fill="${colorFormatted}" stroke="black" stroke-linejoin="round" d="M 31,0 0,84 31,66 62,84 Z"/></g></svg>`
}

const getArrowDown = (color, jsx, width) => {
    const colorFormatted = formatColor(color)
    if (jsx) {
        return <svg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62 84' width={ width ?? '36' } height='53'><title>Baisse</title><g id='layer1'><path id='path630' fill={color} stroke='black' stroke-linejoin='round' d='M 31,84 0,0 31,18 62,0 Z'/></g></svg>
        
    }
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_arrow_down-svg</title><g id="layer1"><path id="path630" fill="${colorFormatted}" stroke="black" stroke-linejoin="round" d="M 31,84 0,0 31,18 62,0 Z"/></g></svg>`
}

const getArrowRight = (color, jsx, width) => { // yes it's a square
    const colorFormatted = formatColor(color)
    if (jsx) {
        return <svg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' width={ width ?? '35' } height='35'><title>Stable</title><g id='layer1'><rect id='path630' fill={color} stroke='black' width='50' height='50' x='0' y='0' rx='10' ry='10'/></g></svg>
    }
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="35" height="35"><title>square-svg</title><g id="layer1"><rect id="path630" fill="${colorFormatted}" stroke="black" width="50" height="50" x="0" y="0" rx="10" ry="10"/></g></svg>`
}

const convertSvgToBase64 = svg => `image://data:image/svg+xml;base64,${window.btoa(svg.replaceAll('"%23', '"#'))}`

export {
    getPiezoMarker,
    getHydroMarker,
    getPluvioMarker,
    getInstMarker,
    getInstMarkerByType,
    getArrowUp,
    getArrowDown,
    getArrowRight,
    getHydroTideMarker,
    getUnitProdMarker,
    convertSvgToBase64,
}
