/* eslint-disable camelcase */
import DtoUserBookmark from 'administration/components/user/dto/DtoUserBookmark'
import { LOCAL_EXPLOITATIONS_FILTERS, emptyFilterExploitations } from 'agriAdministration/constants/AgriConstants'
import Checkbox from 'components/forms/Checkbox'
import Row from 'components/react/Row'
import { push } from 'connected-react-router'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import ExportAction from 'export/actions/ExportAction'
import { groupBy, keys, lowerCase, orderBy, uniq } from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { formatData } from 'utils/ExportDataUtil'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import ActionComponent from '../../components/ActionComponent'
import Table from '../../components/datatable/Table'
import ProgressCard from 'components/card/ProgressCard'
import HomeAction from '../../home/actions/HomeAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import ContributorItem from '../../referencial/components/contributor/dto/ContributorItem'
import { nbPerPageLabel } from '../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../referencial/dto/DtoSandreCode'
import { getLocalStorageJson, setLocalStorageJson, sieauTooltip } from '../../utils/FormUtils'
import { getLogin, getUser } from '../../utils/SettingUtils'
import { arrayOf } from '../../utils/StoreUtils'
import { formatPhone, getI18nTitleData, searchAllCharacters } from '../../utils/StringUtil'
import NewFolderModal from './modal/NewFolderModal'
import ExploitationsFilterPanel from './panels/ExploitationsFilterPanel'
import PropTypes from 'prop-types'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import TabList from 'components/list/TabList'
import { STATION_LIST } from 'station/constants/StationConstants'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import KeyFigurePanel from 'components/keyFigures/KeyFigurePanel'
import { Grid } from '@mui/material'
import CurrentSurveysPanel from 'survey/components/panels/CurrentSurveysPanel'

const headersTable = ['codification', 'sampler', 'city', 'structureType', 'nbPtsPrel', 'progression', 'domestic', 'agri', 'nonAgri', 'adjourned', 'shut']

const headersExport = ['updateDate', 'activityEndDate', 'codification', 'structureType', 'organism', 'civility', 'name', 'address', 'additionnalAddress', 'inseeCode',
    'postalCode', 'city', 'phoneTel', 'mobile', 'fax', 'email', 'comment', 'contributors', 'siret', 'pacage', 'octagri_aura', 'octagri_local', 'muse', 'agence',
    'domestic', 'agri', 'nonAgri', 'adjourned', 'shut']

const headersExportAgency = ['codification', 'structureType', 'civility', 'name', 'address', 'additionnalAddress', 'inseeCode',
    'postalCode', 'city', 'phoneTel', 'mobile', 'fax', 'email', 'siret', 'pacage', 'agence', 'advancement']

class ExploitationsListApp extends ActionComponent {
    constructor(props) {
        super(props)
        const localExploitationsFilters = getLocalStorageJson(LOCAL_EXPLOITATIONS_FILTERS) || emptyFilterExploitations
        this.state = {
            dataLoaded: false,
            filter: {
                ...localExploitationsFilters,
            },
            settings: {
                exportDate: moment().year(),
                startDate: null,
                endDate: null,
            },
            openModalNewFolder: false,
            progress: 0,
            data: [],
            tab: STATION_LIST,
        }
    }

    componentDidMount() {
        this.props.setTitle([{
            title: i18n.folders,
            href: 'dossiers',
        }, {
            title: i18n.dashboard,
            href: 'dossiers/dashboard',
        }])
        this.props.fetchExploitationsExportFullData().then(() => {
            const { exploitationsExportFullData } = this.props
            this.setState({ dataLoaded: true, data: this.getData(exploitationsExportFullData) }, () => {
                if (getUser().isAdmin === '1' || getUser().metadata === '1') {
                    this.setActions({
                        new: () => this.toggleModalNewFolder(),
                        exportChoice: this.getDataExport(),
                    })
                }
            })
        })
    }

    toggleModalNewFolder = () => {
        this.setState(({ openModalNewFolder }) => ({
            openModalNewFolder: !openModalNewFolder,
        }))
    }

    getFilteredData = (data) => {
        const { userBookmarks } = this.props
        const { filter } = this.state
        const { searchValue, structureTypes, city, managementUnit, subManagementUnit, watershed, filters, domestic,
            agri, nonAgri, adjourned, shut, sampleType, sampleNature, accompanyingAquiferCode, droughtSector, organism } = filter
        const login = getLogin()
        const favoritesFiltered = filters === 1 ? data.filter((e) => userBookmarks.find((b) => b.login === login && b.stationType === 10 && parseInt(b.identifiant) === e.idExploitation)) : data
        const cityFilteredData = city ? favoritesFiltered.filter((e) => (city === e.inseeCode) || e.link_points.find((p) => p.cityCode === city)) : favoritesFiltered
        const ugeFilteredData = managementUnit ? cityFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.managementCode === parseInt(managementUnit))) : cityFilteredData
        const subUgeFilteredData = subManagementUnit ? ugeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.subManagementCode === parseInt(subManagementUnit))) : ugeFilteredData
        const watershedFilteredData = watershed ? subUgeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.bvCode === parseInt(watershed))) : subUgeFilteredData
        const sampleTypeFilteredData = sampleType ? watershedFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.sampleType === parseInt(sampleType))) : watershedFilteredData
        const sampleNatureFilteredData = sampleNature ? sampleTypeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.sampleNature === parseInt(sampleNature))) : sampleTypeFilteredData
        const accompanyingAquiferCodeFilteredData = accompanyingAquiferCode ? sampleNatureFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.accompanyingAquiferCode === parseInt(accompanyingAquiferCode))) : sampleNatureFilteredData
        const droughtSectorFilteredData = droughtSector ? accompanyingAquiferCodeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.droughtSector === parseInt(droughtSector))) : accompanyingAquiferCodeFilteredData
        const structureFilteredData = structureTypes.length ? droughtSectorFilteredData.filter(({ structureCode }) => structureTypes.includes(structureCode)) : droughtSectorFilteredData
        const organismFilteredData = organism ? structureFilteredData.filter((e) => e.organism === organism) : structureFilteredData
        const checkBoxFilteredData = (() => {
            if ((domestic && agri && nonAgri && adjourned && shut) || (!domestic && !agri && !nonAgri && !adjourned && !shut)) {
                return organismFilteredData
            }
            return organismFilteredData.filter(d => (domestic && d.domestic.sortValue) || (agri && d.agri.sortValue) ||
            (nonAgri && d.nonAgri.sortValue) ||(adjourned && d.adjourned.sortValue) ||
            (shut && d.shut.sortValue)
            )
        })()
        const includesValue = searchAllCharacters(searchValue || '')
        return checkBoxFilteredData.filter(i => this.getHashMateriel(i).includes(lowerCase(searchValue)) || this.getHash(i).includes(includesValue))
    }

    getHashMateriel = (exploitation) => {
        return lowerCase(exploitation.link_points.reduce((acc, p) => `${acc ? `${acc}, ` : ''}${p.code ? `${p.code}, ` : ''}${p.materiels || ''}`, ''))
    }

    getHash = (exploitation) => {
        return searchAllCharacters([...headersExport, 'legalRepSampler', 'legalRepExploit', 'contactsExploit'].map(key => exploitation.exportData[key] || ''))
    }

    getAdvancementIcon = (lastStep, statusCode, year) => {
        if (lastStep === 5 && statusCode === 3) {
            return (<i className='material-icons' {...sieauTooltip(`${i18n.validated} (${i18n.survey} ${year})`, null, 'bottom')}>check_circle_outline</i>)
        } else if (lastStep === 5 && statusCode === 4) {
            const tooltip = `${i18n.validatedByAdmin} (${i18n.survey} ${year})`
            return (<i className='material-icons' {...sieauTooltip(tooltip, null, 'bottom')}>check_circle</i>)
        } else if (lastStep > 0 && lastStep < 5) {
            return (<i className='material-icons' {...sieauTooltip(`${i18n.inProgress} (${i18n.survey} ${year})`, null, 'bottom')}>play_circle_outline</i>)
        } else if (!lastStep && !statusCode) {
            return (<i className='material-icons' {...sieauTooltip(`${i18n.notStarted} (${i18n.survey} ${year})`, null, 'bottom')}>hourglass_full</i>)
        }
        return null
    }

    getExport = (data, headers, type, fileName) => {
        const orderedData = orderBy(data, 'codification')
        const dataWithHeader = orderedData.length > 0 ? [{ ...orderedData[0], headers }, ...orderedData.slice(1)] : []
        this.props.export(formatData(dataWithHeader), type, fileName)
    }

    getConsosExportContent = () => {
        const { settings } = this.state
        const { startDate, endDate } = settings
        return (
            <Row>
                <SimpleDatePicker
                    col={12}
                    label={i18n.startDate}
                    value={startDate}
                    onChange={d => this.setState({
                        settings: {
                            ...settings,
                            startDate: d,
                        },
                    })}
                    calendarPosition='left'
                />
                <SimpleDatePicker
                    col={12}
                    label={i18n.endDate}
                    value={endDate}
                    onChange={d => this.setState({
                        settings: {
                            ...settings,
                            endDate: d,
                        },
                    })}
                    calendarPosition='left'
                />
            </Row>
        )
    }

    getFullExportData = () => {
        const { exploitationsExportFullData, contributors } = this.props
        const { data } = this.state
        const filteredData = this.getFilteredData(data)
        return exploitationsExportFullData.filter((d) => filteredData.find((e) => e.idExploitation === d.idExploitation)).map((e) => {
            return {
                ...e,
                organism: e.organism ? contributors.find((c) => c.id === e.organism)?.name : '',
                contributors: e.contactsExploit,
                domestic: e.domestic ? i18n.yes : i18n.no,
                agri: e.agri ? i18n.yes : i18n.no,
                nonAgri: e.nonAgri ? i18n.yes : i18n.no,
                adjourned: !e.link_points.find((lp) => !lp.endDate || lp.endDate > moment().valueOf()) ? i18n.yes : i18n.no,
                shut: e.folderClosed ? i18n.yes : i18n.no,
            }
        })
    }

    getDataExport = () => {
        const { data, settings: { startDate, endDate } } = this.state
        return {
            exportChoices: [{
                name: i18n.exportFull,
                formats: [{
                    type: i18n.exportXLSX,
                    callback: () => this.getExport(this.getFullExportData(), headersExport, 'xlsx', `${i18n.folders}_${i18n.exportFull}`),
                },
                {
                    type: i18n.csv,
                    callback: () => this.getExport(this.getFullExportData(), headersExport, 'csv', `${i18n.folders}_${i18n.exportFull}`),
                }],
            }, {
                name: `${i18n.exportName} ${i18n.waterAgency}`,
                formats: [{
                    type: i18n.exportXLSX,
                    callback: () => this.getExport(this.getFilteredData(data), headersExportAgency, 'xlsx', `${i18n.folders}_${i18n.agencyExport}`),
                },
                {
                    type: i18n.csv,
                    callback: () => this.getExport(this.getFilteredData(data), headersExportAgency, 'csv', `${i18n.folders}_${i18n.agencyExport}`),
                }],
            }, {
                name: `${i18n.exportName} ${i18n.consos}`,
                settingsContent: this.getConsosExportContent(),
                formats: [{
                    type: i18n.exportXLSX,
                    callback: () => this.props.exportExploitationsConsos({ idsExploitations: this.getFilteredData(data).map((e) => e.idExploitation), startDate, endDate }, 'xlsx', `${i18n.folders}_${i18n.consos}`),
                },
                {
                    type: i18n.csv,
                    callback: () => this.props.exportExploitationsConsos({ idsExploitations: this.getFilteredData(data).map((e) => e.idExploitation), startDate, endDate }, 'csv', `${i18n.folders}_${i18n.consos}`),
                }],
            }],
        }
    }

    getModalNewFolder = () => {
        const { openModalNewFolder } = this.state
        return openModalNewFolder && (
            <NewFolderModal
                open={openModalNewFolder}
                onCancel={this.toggleModalNewFolder}
                onValidate={this.onValidateFolder}
            />
        )
    }

    onValidateFolder = (exploitation, intervenant) => {
        this.setState({ dataLoaded: false })
        this.props.createContributor(intervenant, 'noRedirection').then((intervenantId) => {
            if (intervenantId) {
                this.props.fetchContributors()
                const exploitationToCreate = {
                    ...exploitation,
                    operatorCode: intervenantId,
                }
                this.props.createExploitation(exploitationToCreate).then((idExploitation) => {
                    if (idExploitation) {
                        this.setState({ dataLoaded: true })
                        this.props.push(`/dossiers/${idExploitation}/dashboard`)
                    }
                })
            } else {
                this.setState({ dataLoaded: true })
            }
        })
        this.setState({ openModalNewFolder: false })
    }

    getData = (exploitations) => {
        return exploitations.map((e) => {
            const tooltip = `${i18n.email} : ${e.email || ''} \n${i18n.phoneTel} : ${formatPhone(e.phoneTel) || ''} \n${i18n.mobile} : ${formatPhone(e.mobile) || ''}`
            const adjourned = !e.link_points.find((lp) => !lp.endDate || lp.endDate > moment().valueOf())
            const advancement = (e.lastDeclarationStep * 100) / 4
            return {
                ...e,
                exportData: e,
                codification: { value: e.codification },
                structureType: { value: e.structureType },
                nbPtsPrel: { value: e.nbPtsPrel },
                sampler: {
                    value: (
                        <span
                            {...sieauTooltip(tooltip, null, 'bottom')}
                        >
                            {e.name}
                        </span>
                    ),
                    sortValue: e.name,
                },
                samplerName: { value: e.name },
                city: { value: `${e.city || ''} ${e.inseeCode ? `[${e.inseeCode}]` : ''}` },
                progression: {
                    value: e.lastDeclarationId && this.getAdvancementIcon(e.lastDeclarationStep, e.lastDeclarationStatus, e.lastDeclarationYear),
                    sortValue: e.lastDeclarationStatus,
                },
                advancement: { value: `${(e.lastDeclarationStep && (advancement > 100 ? 100 : advancement) || 0)} %` },
                domestic: {
                    value: e.domestic ? (
                        <Checkbox
                            checked
                            disabled
                        />
                    ) : false,
                    sortValue: e.domestic || false,
                },
                agri: {
                    value: e.agri ? (
                        <Checkbox
                            checked
                            disabled
                        />
                    ) : false,
                    sortValue: e.agri || false,
                },
                nonAgri: {
                    value: e.nonAgri ? (
                        <Checkbox
                            checked
                            disabled
                        />
                    ) : false,
                    sortValue: e.nonAgri || false,
                },
                adjourned: {
                    value: adjourned ? (
                        <Checkbox
                            checked
                            disabled
                        />
                    ) : false,
                    sortValue: adjourned,
                },
                shut: {
                    value: e.folderClosed ? (
                        <Checkbox
                            checked
                            disabled
                        />
                    ) : false,
                    sortValue: e.folderClosed || false,
                },
            }
        })
    }

    onValidateFilter = (filter) => {
        setLocalStorageJson(LOCAL_EXPLOITATIONS_FILTERS, filter)
        this.setState({ filter })
    }

    render() {
        const { dataLoaded, data, tab } = this.state
        const { contributorsIndex } = this.props

        const filteredData = dataLoaded ? this.getFilteredData(data) : []
        const groupedData = tab === 'referent' ? groupBy(filteredData, 'organism') : {}

        return (
            <div className='padding-top-1 padding-right-1 padding-left-1' style={{ paddingBottom: 60 }}>
                <Grid container className='padding-bottom-1' spacing={1}>
                    <Grid item xs={9}>
                        <CurrentSurveysPanel round style={{ marginTop: '0.5rem' }} />
                    </Grid>
                    <Grid item xs={3}>
                        <KeyFigurePanel
                            data={[{
                                title: i18n.folders,
                                value: data.length,
                            }, {
                                title: i18n.adjourneds,
                                value: data.filter(e => e.adjourned.sortValue).length,
                            }, {
                                title: i18n.shut,
                                value: data.filter(e => e.shut.sortValue).length,
                            }, {
                                title: i18n.pointsPrelevement,
                                value: uniq(data.flatMap(e => e.link_points.map((p) => p.idInstallation))).length,
                            }]} round
                        />
                    </Grid>
                </Grid>
                <ExploitationsFilterPanel onValidate={this.onValidateFilter} />
                <div className='margin-top-1'>
                    {dataLoaded ? (
                        <TabList
                            onChangeTab={(v) => this.setState({ tab: v })}
                            tabs={[
                                {
                                    value: STATION_LIST,
                                    label: i18n.table,
                                    icon: 'list',
                                },
                                {
                                    value: 'referent',
                                    label: i18n.organism,
                                    icon: 'person',
                                },
                            ]}
                        >
                            {tab === STATION_LIST && (
                                <Table
                                    title={filteredData.length > 1 ? i18n.folders : i18n.folder}
                                    data={filteredData}
                                    paging
                                    nbPerPageLabel={nbPerPageLabel}
                                    type={{ headers: headersTable }}
                                    onClick={s => this.props.push(`/dossiers/${s.idExploitation}/dashboard`)}
                                    customHeaders={{
                                        structureType: i18n.structure,
                                        nbPtsPrel: i18n.nbPts,
                                        folderClosed: i18n.shut,
                                        nonAgri: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nonAgriWrap}</span>,
                                    }}
                                    condensed
                                    sortable
                                    initialSort={{ column: 'codification' }}
                                    color
                                    round
                                    invertedHeaderStyle
                                />
                            )}
                            {tab === 'referent' && (
                                <div className='padding-left-1 padding-top-1 padding-right-1'>
                                    {keys(groupedData).map((organismId, i) => {
                                        const datas = groupedData[organismId]
                                        return (
                                            <AccordionMUI className='margin-bottom-1' defaultExpanded={i === 0}>
                                                <AccordionSummaryMUI>
                                                    <span style={{ fontSize: 18 }}>{organismId !== 'undefined' ? (contributorsIndex[organismId] || {}).name : i18n.unknown}</span>
                                                    <span style={{ fontSize: 18, fontWeight: 'normal' }}>&nbsp;{`(${datas.length} ${getI18nTitleData(i18n.element, i18n.elements, datas)})`}</span>
                                                </AccordionSummaryMUI>
                                                <AccordionDetailsMUI nopadding>
                                                    <Table
                                                        showTitle={false}
                                                        data={datas}
                                                        paging
                                                        nbPerPageLabel={nbPerPageLabel}
                                                        type={{ headers: headersTable }}
                                                        onClick={s => this.props.push(`/dossiers/${s.idExploitation}/dashboard`)}
                                                        customHeaders={{
                                                            structureType: i18n.structure,
                                                            nbPtsPrel: i18n.nbPts,
                                                            folderClosed: i18n.shut,
                                                            nonAgri: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nonAgriWrap}</span>,
                                                        }}
                                                        condensed
                                                        sortable
                                                        initialSort={{ column: 'codification' }}
                                                        color
                                                        round
                                                    />
                                                </AccordionDetailsMUI>
                                            </AccordionMUI>
                                        )
                                    })}
                                </div>
                            )}
                        </TabList>
                    ) : <ProgressCard indeterminate withMessage round />}
                </div>
                {this.getModalNewFolder()}
            </div>
        )
    }
}

ExploitationsListApp.propTypes = {
    exploitationsExportFullData: arrayOf(DtoExploitationExportFull),
    contributors: arrayOf(ContributorItem),
    contributorsIndex: PropTypes.objectOf(PropTypes.instanceOf(ContributorItem)),
    sandreCodes: arrayOf(DtoSandreCode),
    watersheds: arrayOf(WatershedDto),
    managementUnits: arrayOf(DtoManagementUnit),
    userBookmarks: arrayOf(DtoUserBookmark),
    push: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        contributors: store.ContributorReducer.contributors,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        watersheds: store.WatershedReducer.watersheds,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        userBookmarks: store.UserReducer.userBookmarks,
    }
}

const mapDispatchToProps = {
    push,
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    createExploitation: AgriAction.createExploitation,
    setTitle: HomeAction.setTitle,
    createContributor: ContributorAction.createContributor,
    fetchContributors: ContributorAction.fetchContributors,
    export: ExportAction.export,
    exportExploitationsConsos: ExportAction.exportExploitationsConsos,
    warning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationsListApp)
