import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import DtoAquifer from '../../../referencial/components/aquifers/dto/DtoAquifer'
import WatermassDto from '../../../referencial/components/watermass/dto/WatermassDto'
import WatershedDto from '../../../referencial/components/watershed/dto/WatershedDto'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import StationAccessibilitiesPanel from '../../../station/components/link/StationAccessibilitiesPanel'
import StationAltimetrySystemsPanel from '../../../station/components/link/StationAltimetrySystemsPanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import StationCasingPanel from '../../../station/components/link/StationCasingPanel'
import StationDespoliationPanel from '../../../station/components/link/StationDespoliationPanel'
import StationFunctionPanel from '../../../station/components/link/StationFunctionPanel'
import StationHydrogeologicalEntityPanel from '../../../station/components/link/StationHydrogeologicalEntityPanel'
import StationLandmarkPanel from '../../../station/components/link/StationLandmarkPanel'
import StationLithologyPanel from '../../../station/components/link/StationLithologyPanel'
import StationLocationPanel from '../../../station/components/link/StationLocationPanel'
import StationMeasureMethodPanel from '../../../station/components/link/StationMeasureMethodPanel'
import StationNetworkPanel from '../../../station/components/link/StationNetworkPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationTempRefPanel from '../../../station/components/link/StationTempRefPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationWatermassPanel from '../../../station/components/link/StationWatermassPanel'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import DtoPiezometer from '../../dto/DtoPiezometer'

class PiezometerDescriptionTablesPanel extends Component {
    state = {
        piezometer: { ...this.props.piezometer },
    }

    onChangeCheckReset = (linkName, valueName, valueToCheck) => {
        const { piezometer } = this.state
        const { onChangePiezometer } = this.props
        if (valueToCheck) {
            onChangePiezometer({ [linkName]: [{ idStation: piezometer.id, [valueName]: valueToCheck }] })
        } else {
            onChangePiezometer({ [linkName]: [] })
        }
    }

    render() {
        const {
            piezometer,
            onChangePiezometer,
            editMode,
            aquifers,
            watersheds,
            sandreCodes,
            onShowContact,
        } = this.props
        const params = {
            station: piezometer,
            onChange: onChangePiezometer,
            readMode: !editMode,
        }
        const mode = { readMode: !editMode, freezeOpti: true }
        const work = piezometer.link_work[0] || {}


        return (
            <div>
                <Row className='padding-top-1'>
                    <Card title={ i18n.context }>
                        <Row className='padding-1'>
                            <StationWatermassPanel { ...params } className='blue'/>
                        </Row>
                        <div className={ `row no-margin ${editMode && 'padding-top-1 padding-bottom-1' || ''}` } style={{ paddingLeft: '5px' }}>
                            <Select
                                col={ 12 }
                                value={ piezometer.link_aquifers[0] && piezometer.link_aquifers[0].aquiferCode }
                                label={ i18n.aquifer }
                                options={ aquifers }
                                displayWithCode
                                onChange={ v => this.onChangeCheckReset('link_aquifers', 'aquiferCode', v) }
                                { ...mode }
                            />
                            <Select
                                col={ 12 }
                                value={ piezometer.watershedCode }
                                label={ i18n.watershed }
                                options={ watersheds }
                                keyValue='id'
                                onChange={ v => onChangePiezometer({ watershedCode: v }) }
                                { ...mode }
                            />
                            <Select
                                col={ 12 }
                                value={ piezometer.link_lithologicType[0] && piezometer.link_lithologicType[0].lithologyType }
                                label={ i18n.lithologyType }
                                options={ getSandreList(sandreCodes, SANDRE.LITHOLOGY_TYPE) }
                                onChange={ v => onChangePiezometer({ link_lithologicType: [{ idStation: piezometer.id, lithologyType: v }] }) }
                                { ...mode }
                            />
                        </div>
                        <Row className='padding-1'>
                            <StationLithologyPanel { ...params } className='blue'/>
                        </Row>
                        <Row className='padding-1 no-padding-top'>
                            <StationHydrogeologicalEntityPanel { ...params } className='blue'/>
                        </Row>
                    </Card>
                </Row>
                <Row className='padding-top-1'>
                    <StationPiezoContributorPanel { ...params }/>
                </Row>
                <Card title={i18n.piezometer} className='margin-top-1' noMargin={false}>
                    <Row className='padding-1 no-padding-bottom'>
                        <StationAltimetrySystemsPanel { ...params } className='blue'/>
                    </Row>
                    <Row className='padding-1 no-padding-bottom'>
                        <StationLandmarkPanel {...params} className='blue' />
                    </Row>
                    <Row className='padding-1'>
                        <StationMeasureMethodPanel { ...params } className='blue'/>
                    </Row>
                </Card>
                <Row className='padding-top-1'>
                    <StationTempRefPanel { ...params }/>
                </Row>
                <Row className='padding-top-1'>
                    <StationNetworkPanel { ...params }/>
                </Row>
                <Row className='padding-top-1'>
                    <StationFunctionPanel { ...params }/>
                </Row>
                <Row className='padding-top-1'>
                    <StationUsagePanel { ...params } />
                </Row>
                <Row className='padding-top-1'>
                    <StationArrangementPanel { ...params }/>
                </Row>
                <Row className='padding-top-1'>
                    <StationAccessibilitiesPanel {...params} onShowContact={onShowContact}/>
                </Row>
                <Row className='padding-top-1'>
                    <StationDespoliationPanel { ...params }/>
                </Row>
                <Row className='padding-top-1'>
                    <Card title={ i18n.technicalCharacteristics }>
                        <Row className={ `${editMode && 'padding-top-1 padding-bottom-1' || ''}` } style={{ paddingLeft: '5px' }}>
                            <Row>
                                <Input
                                    col={ 6 }
                                    title={ i18n.stationType }
                                    value={ work.stationType }
                                    keyObj='stationType'
                                    changeObj={this.props.changePiezometerWork}
                                    { ...mode }
                                />
                                <Input
                                    col={ 6 }
                                    title={ i18n.phone }
                                    value={ work.phone }
                                    keyObj='phone'
                                    changeObj={this.props.changePiezometerWork}
                                    { ...mode }
                                />
                            </Row>
                            <Row>
                                <Input
                                    col={ 6 }
                                    title={ i18n.sensorType }
                                    value={ work.sensorType }
                                    keyObj='sensorType'
                                    changeObj={this.props.changePiezometerWork}
                                    { ...mode }
                                />
                                <Input
                                    col={ 6 }
                                    title={ i18n.serialNumber }
                                    value={ work.serialNumber }
                                    keyObj='serialNumber'
                                    changeObj={this.props.changePiezometerWork}
                                    { ...mode }
                                />
                            </Row>
                            <Row>
                                <NumberField
                                    col={ 6 }
                                    title={ i18n.workDepth }
                                    value={ work.workDepth }
                                    keyObj='workDepth'
                                    changeObj={this.props.changePiezometerWork}
                                    floatValue
                                    { ...mode }
                                />
                                <NumberField
                                    col={ 6 }
                                    title={ i18n.sensorDepth }
                                    value={ work.sensorDepth }
                                    keyObj='sensorDepth'
                                    changeObj={this.props.changePiezometerWork}
                                    floatValue
                                    { ...mode }
                                />
                            </Row>
                            <Row>
                                <Input
                                    col={ 6 }
                                    title={ i18n.internalDiameter }
                                    value={ work.internalDiameter }
                                    keyObj='internalDiameter'
                                    changeObj={this.props.changePiezometerWork}
                                    { ...mode }
                                />
                                <Input
                                    col={ 6 }
                                    title={ i18n.externalDiameter }
                                    value={ work.externalDiameter }
                                    keyObj='externalDiameter'
                                    changeObj={this.props.changePiezometerWork}
                                    { ...mode }
                                />
                            </Row>
                            <Row>
                                <Textarea
                                    col={ 12 }
                                    title={ i18n.comment }
                                    value={ work.comment }
                                    keyObj='comment'
                                    changeObj={this.props.changePiezometerWork}
                                    { ...mode }
                                />
                            </Row>
                            <Row className='padding-1' >
                                <StationCasingPanel { ...params } className='blue'/>
                            </Row>
                        </Row>
                    </Card>
                </Row>
                <Row className='padding-top-1'>
                    <StationLocationPanel { ...params }/>
                </Row>
            </div>
        )
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextState.piezometer.LAST_FORM !== this.state.piezometer.LAST_FORM && nextState.piezometer.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

PiezometerDescriptionTablesPanel.propTypes = {
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    watermasses: arrayOf(WatermassDto),
    aquifers: arrayOf(DtoAquifer),
    watersheds: arrayOf(WatershedDto),
    editMode: PropTypes.bool,
    onChangePiezometer: PropTypes.func,
    onShowContact: PropTypes.func,
    changePiezometerWork: PropTypes.func
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    watersheds: store.WatershedReducer.watersheds,
    watermasses: store.WatermassReducer.watermasses,
    aquifers: store.AquiferReducer.aquifers,
})

PiezometerDescriptionTablesPanel.defaultProps = {
    piezometer: {},
}

export default connect(mapStateToProps)(PiezometerDescriptionTablesPanel)
