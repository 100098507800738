export default class DtoPluvioMeasure {
    constructor(obj) {
        this.pluvioId = obj[0] // Int,
        this.dataType = obj[1] // Int,
        this.date = obj[2] // DateTime,
        this.initialValue = obj[3] // Option[Double],
        this.value = obj[4] // Option[Double],
        this.measureMode = obj[5] // Option[Int],
        this.status = obj[6] // Option[Int],
        this.qualification = obj[7] // Option[Int],
        this.nature = obj[8] // Option[String],
        this.initialPoint = obj[9] // Option[Int],
        this.validator = obj[10] // Option[Int],
        this.manager = obj[11] // Option[Int],
        this.producer = obj[12] // Option[Int],
        this.jobExecutionId = obj[13] // Option[Int],
        this.importDate = obj[14] // Option[DateTime],
        this.updateDate = obj[15] // Option[DateTime],
        this.updateLogin = obj[16] // Option[String]
    }
}