import React from 'react'
import { connect } from 'react-redux'
import { arrayOf, removeNullKeys } from '../../../../utils/StoreUtils'
import { MEASURE_COTE } from '../../../constants/PiezometryConstants'
import Card from '../../../../components/card/Card'
import ChartTabsSuivi from '../../../../components/echart/ChartTabsSuivi'
import i18n from 'simple-react-i18n'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisLabelInterval,
    setYOptionsPiezo,
    yAutomaticScale, yAutomaticScaleValues,
} from '../../../../components/echart/EChartUtils'
import EChart from '../../../../components/echart/EChart'
import Axis from '../../../../components/echart/Axis'
import moment from 'moment'
import { getDate, getFullDate } from '../../../../utils/DateUtil'
import { maxBy, orderBy, last, partition } from 'lodash'
import { hasValue, nFormatter, round } from '../../../../utils/NumberUtil'
import Line from '../../../../components/echart/series/Line'
import PropTypes from 'prop-types'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import { getLocalStorageJson } from '../../../../utils/FormUtils'
import { getDisplayCote, piezoMeasureIsValid } from '../../../../utils/PiezometryUtils'
import { exportFile } from '../../../../utils/ExportDataUtil'
import PiezoSuiviStatTab2 from './chartTabs2/PiezoSuiviStatTab2'
import PiezoSuiviPastYearsTab2 from './chartTabs2/PiezoSuiviPastYearsTab2'
import PiezoSuiviAssociationsTab2 from './chartTabs2/PiezoSuiviAssociationsTab2'
import PiezoSuiviThresholds2 from './chartTabs2/PiezoSuiviThresholds2'
import PiezoSuiviModelTab2 from './chartTabs2/PiezoSuiviModelTab2'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import OptionsToolPanel from '../../validation/tools/OptionsToolPanel'
import OldTabsSideBar from '../../../../components/navbar/OldTabsSideBar'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import DtoPiezometerChartOptions from '../../../../station/dto/piezometer/DtoPiezometerChartOptions'
import AppStore from '../../../../store/AppStore'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getLinks, getStationTitle } from '../../../../utils/StationUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_STATION_PIEZO_FOLLOW_UP } from '../../../../account/constants/AccessRulesConstants'
import DtoPiezoMeasureLight from '../../../dto/chart/DtoPiezoMeasureLight'
import DtoMeasureStats from '../../../../station/dto/piezometer/DtoMeasureStats'
import { getMeasureJplus } from '../../../../iaeau/utils/IAEauUtils'
import { getStationArrowNav } from 'utils/ActionUtils'
import ActionComponent from 'components/ActionComponent'
import { push } from 'connected-react-router'

const STATISTICS = 'statistics'
const PREVIOUSYEARS = 'previousYears'
const LINKEDSTATIONS = 'linkedStations'
const CHARTOPTIONS = 'chartOptions'
const THRESHOLDS = 'thresholds'
const MODELS = 'models'


class PiezoSuiviChart2 extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            displayCote: getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH, // mode d'affichage piézo
            tab: 'CIVIL_YEAR', // tab sélectionné en haut à gauche du graphique
            minDate: null, // date min du graphique
            maxDate: null, // date max du graphique,
            piezoSerie: [],
            statsSeries: [],
            yearSeries: [],
            associationsSeries: [],
            associationsAxis: [],
            modelsSeries: [],
            thresholds: [],
            landmarkValue: null, // sers à caluler la profondeur : depth = landmarkValue - NGF
            hasValidMeasures: null, // true si les données piézos contiennent au moins une mesure valide
            piezometerChartOptions: [],
            chartOptionsAreLoaded: false,
            modeDate: 'dailyDate',
            measures: [], // max measures
            bruteMeasures: [], // brute measures
            bruteSerie: [],
            withBrutes: false,
        }
        this.series = []
    }

    componentDidMount() {
        const { piezometer } = this.props
        this.setTitle(piezometer)
        this.props.fetchPiezoMeasuresStats(parseInt(piezometer.id))
        if (!componentHasHabilitations(H_STATION_PIEZO_FOLLOW_UP)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.getMaxMeasures()
        this.props.fetchPiezometerChartOptions(piezometer.id, () => this.setState({ piezometerChartOptions: this.props.piezometerChartOptions, chartOptionsAreLoaded: true }))
        this.setActions({
            links: getLinks(piezometer, this.props),
            arrowNav: getStationArrowNav('piezometry', this.props.piezometers, piezometer.id, s => this.props.push(`/station/piezometry/${s.id}/piezometricFollowUp`)),
        })
    }

    getMaxMeasures = () => {
        PiezometerStationAction.promisePiezoChartMeasures({
            stationId: this.props.piezometer.id,
            displayCote: MEASURE_COTE.NGF,
            groupFunc: 'MAX',
            dataType: -1,
        }).then(res => this.calculatePiezoSerie(res.map(m => ({ ...new DtoPiezoMeasureLight(m), NGF: m[1] })), 'measures', 'piezoSerie'))
    }

    getBrutesMeasures = () => PiezometerStationAction.promisePiezoChartMeasures({
        stationId: this.props.piezometer.id,
        displayCote: MEASURE_COTE.NGF,
        dataType: -1,
        startDate: this.state.minDate,
        endDate: this.state.maxDate,
    }).then(res => this.calculatePiezoSerie(res.map(m => new DtoPiezoMeasureLight(m)), 'bruteMeasures', 'bruteSerie'))

    setTitle = (station) => {
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: getStationTitle(station),
            href: `station/piezometry/${station.id}`,
        }, {
            title: i18n.piezometrySuivi,
            href: `station/piezometry/${station.id}/piezometricFollowUp`,
        }]))
    }

    calculatePiezoSerie = (measures, keyMeasures, keySerie) => {
        const { piezometer } = this.props
        // const measures = this.state.measures
        const hasValidMeasures = measures.some(m => piezoMeasureIsValid(m))

        const lastLandmark = maxBy(piezometer.link_landmarks, 'startDate')
        const lastRefAlti = lastLandmark ? piezometer.link_altimetrySystems.find(alt => alt.natureCode === lastLandmark.altimetrySystemNature && alt.startDate === lastLandmark.altimetrySystemDate) : null
        const landmarkValue = lastLandmark && lastRefAlti ? lastLandmark.height + lastRefAlti.altitude : null

        const unit = this.props.piezometerStatistics.find(t => t.typeId === -1)?.unit ?? ''
        const measuresEchart = orderBy(measures.flatMap(obj => {
            const depth = landmarkValue - obj.value
            const newObj = { ...obj, depth, NGF: obj.value }
            if (obj.initialPoint === 1) {
                return [
                    { value: [moment(newObj.date).subtract(1, 'second').valueOf(), null, newObj], isPiezo: true, unit },
                    { value: [newObj.date, this.state.displayCote === MEASURE_COTE.NGF ? newObj.NGF : newObj.depth, newObj], symbolSize: 5, isPiezo: true, unit },
                ]
            }
            return [{ value: [newObj.date, this.state.displayCote === MEASURE_COTE.NGF ? newObj.NGF : newObj.depth, newObj], symbolSize: 0, isPiezo: true, unit }]
        }), m => m.value[0])
        this.setState({ landmarkValue, hasValidMeasures, [keySerie]: [Line({
            data: measuresEchart,
            name: i18n.chronic + (this.state.withBrutes ? ` ${i18n.brute}` : ''),
            // markLine: getThresholdsMarkLine(piezometer, thresholds), // thresholds
            // markPoint: getEventMarkPoint(events, measures), // events
            connectNulls: false,
            showSymbol: false,
            yAxisIndex: 0,
            xAxisIndex: 0,
            color: '#3d72d2',
            serieId: 'measures',
            isPiezo: true,
        })],
        height: window.innerHeight - window.$('#chart').offset().top - 80,
        [keyMeasures]: measures,
        })
    }

    getTooltip() {
        return {
            trigger: 'axis',
            formatter: params => {
                const filtered = params.filter(p => !p.data.noTooltip && hasValue(p.value[1]) && p.seriesName !== i18n.events)
                if (!filtered.length) {
                    return ''
                }
                const isPeriod = params.some(p => p.data.isPeriod)
                const date = this.state.modeDate === 'allDate' ? getFullDate(moment(filtered[0].value[0])) : getDate(moment(filtered[0].value[0]))
                const paramsOrder = orderBy(filtered.map((o, idx) => {
                    return {
                        ...o,
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: (() => {
                            if (isPeriod && this.state.displayCote === MEASURE_COTE.DEPTH && hasValue(o.data.depthHackValue)) {
                                return o.data.depthHackValue
                            }
                            if (isPeriod && this.state.displayCote === MEASURE_COTE.NGF && o.seriesName.startsWith('>')) {
                                return filtered[idx - 1].data.realValue
                            }
                            return (o.data.realValue || o.value[1]) + (o.data.isPiezo && !hasValue(o.data.realValue) ? -1000 : 0)
                        })(),
                    }
                }), ['value', 'seriesName'], this.state.displayCote === MEASURE_COTE.NGF ? ['desc', 'desc'] : ['asc', 'desc'])
                const result = paramsOrder.map(o => {
                    return `${o.marker} ${o.seriesName} : ${o.value} ${o.data.unit ?? ''} ${getMeasureJplus(o.data?.value[2]?.measure)}`
                }).join('<br/>')
                const measureType = `${i18n.display} : ${getDisplayCote(this.state.displayCote)}`
                return `${date}<br />${result}<br />${measureType}`
            },
        }
    }

    changeCote = (displayCote) => {
        const key = displayCote === MEASURE_COTE.NGF ? 'NGF' : 'depth'
        const changeMeasure = m => ({ ...m, value: [m.value[0], m.value[1] !== null ? m.value[2][key] : null, m.value[2], m.value[3]] })
        this.series.forEach(s => {
            if (s.obj.isPiezo) {
                if (s.obj.isMultiBand) {
                    s.updateObj({ bands: orderBy(s.obj.initialBands, ['idx'], displayCote === MEASURE_COTE.NGF ? ['asc'] : ['desc']).map(b => ({
                        ...b,
                        data: b.data.map(d => changeMeasure(d)),
                        color: displayCote === MEASURE_COTE.NGF ? b.NGFColor : b.depthColor,
                    })) })
                } else {
                    s.updateObj({
                        data: s.obj.data.map(d => changeMeasure(d)),
                    })
                }
            }
        })
        this.setState({ displayCote })
    }

    getExportData = (chartMinDate, chartMaxDate) => {
        const data = this.series.flatMap(chart => {
            if (chart.obj?.bands) {
                return chart.obj.bands.flatMap(() => [ ...this.exportSerie(chart.obj.bands[1], chartMinDate, chartMaxDate), ...this.exportSerie(chart.obj.bands[0], chartMinDate, chartMaxDate)])
            }
            return this.exportSerie(chart.obj, chartMinDate, chartMaxDate)
        })

        if (data?.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }

        return data
    }

    exportSerie = (serie, chartMinDate, chartMaxDate) => serie.data.filter(d => d.value[0] >= chartMinDate && d.value[1] <= chartMaxDate).map(d => ({
        stationCode: { value: this.props.piezometer.code },
        stationName: { value: this.props.piezometer.name },
        date: { value: getFullDate(d.value[0]), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
        value: { value: round(d.value[1]), format: '0.00', cellType: 'number' },
        type: { value: serie.name },
    }))

    render() {
        const { piezometer } = this.props
        const { displayCote, landmarkValue, hasValidMeasures, tab } = this.state
        const chartMinDate = this.state.minDate || (this.state.measures[0]?.date) || moment().valueOf()
        const chartMaxDate = this.state.maxDate || moment().endOf('year').valueOf()
        const histoYears = this.state.measures.length ? moment(last(this.state.measures).date).year() - moment(this.state.measures[0].date).year() : 0
        const defaultOptions = {
            displayCote,
            landmarkValue,
            id: piezometer.id,
            changeParent: changes => this.setState(changes),
            tab,
            minDate: chartMinDate,
            maxDate: chartMaxDate,
        }
        const modeDate = [
            {
                code: 'allDate',
                name: 'DD/MM/YYYY hh:mm:ss',
            }, {
                code: 'dailyDate',
                name: 'DD/MM/YYYY',
            },
        ]
        const tabs = hasValidMeasures === null || !this.state.chartOptionsAreLoaded ? [] : [
            {
                icon: 'multiline_chart',
                id: STATISTICS,
                title: i18n.statistics,
                content: <PiezoSuiviStatTab2
                    {...defaultOptions}
                    hasValidMeasures={hasValidMeasures ?? false}
                    histoYears={histoYears}
                    piezometerChartOptions={this.state.piezometerChartOptions}
                />,
            },
            {
                icon: 'fast_rewind',
                id: PREVIOUSYEARS,
                title: i18n.previousYears,
                content: <PiezoSuiviPastYearsTab2
                    {...defaultOptions}
                    measures={this.state.measures}
                />,
            },
            {
                icon: 'list',
                id: LINKEDSTATIONS,
                title: `${i18n.associatedStations} ${i18n.and} ${i18n.additionalData}`,
                content: <PiezoSuiviAssociationsTab2
                    {...defaultOptions}
                    piezometer={piezometer}
                />,
            },
            {
                icon: 'aspect_ratio',
                constant: CHARTOPTIONS,
                title: i18n.chartOptions,
                content: (
                    <>
                        <OptionsToolPanel
                            displayCote={this.state.displayCote}
                            lastLandmark={this.state.landmarkValue}
                            id={ piezometer.id }
                            groundRefAlti={ null }
                            changeParent={changes => this.setState(changes)}
                            piezometerChartOptions={this.state.piezometerChartOptions}
                            dataType={ this.state.piezometerChartOptions.dataType || -1 }
                            yScale={ this.yScale }
                        />
                        <StyledFieldSet>
                            <StyledLegend>{ i18n.formatDate }</StyledLegend>
                            <RadioButtons selected={this.state.modeDate} onChange={v => this.setState({ modeDate: v })} elements={modeDate} />
                        </StyledFieldSet>
                    </>
                ),
            },
            {
                icon: 'report_problem',
                id: THRESHOLDS,
                title: i18n.thresholds,
                content: <PiezoSuiviThresholds2 {...defaultOptions} piezometer={piezometer} unit={this.props.piezometerStatistics.find(t => t.typeId === -1)?.unit} />,
            },
            {
                icon: 'extension',
                id: MODELS,
                title: i18n.predictData,
                content: <PiezoSuiviModelTab2 {...defaultOptions} piezometer={piezometer} measures={this.state.measures} />,
            },
        ]
        const [xAxis, yAxis] = [[], []]

        const axisLabelObj = getAxisLabelInterval(moment(chartMaxDate), moment(chartMinDate))

        const [associationsInFirstAxis, otherSeries] = partition(this.state.associationsSeries, s => s.obj.isFirstAxis)

        const otherAxisObj = {}
        const otherAxis = [
            ...this.state.associationsAxis,
        ].map((axis, idx) => {
            otherAxisObj[axis.name] = idx + 1
            const yScale = yAutomaticScaleValues([
                ...otherSeries.filter(s => s.obj.axisName === axis.name).flatMap(s => s.obj.data).flat().map(v => v.value[1]),
                ...otherSeries.filter(s => s.obj.axisName === axis.name).flatMap(s => s.obj.markLine?.data?.map(d => d.yAxis) || []),
            ])
            return Axis({
                type: 'value',
                nameLocation: 'middle',
                nameGap: 35,
                position: 'right',
                axisLabel: { formatter: nFormatter },
                offset: idx*60,
                splitNumber: 5,
                ...yScale,
                ...(removeNullKeys(axis)),
                name: `${axis.name} ${axis?.unit ? `[${axis?.unit}]` : ''}`,
                inverse: axis.isPluvio || (axis.isPiezo && this.state.displayCote === MEASURE_COTE.DEPTH),
            })
        })

        otherSeries.forEach(serie => {
            serie.updateObj({ yAxisIndex: otherAxisObj[serie.obj.axisName] })
        })

        const firstAxisSeries = [
            ...this.state[this.state.withBrutes ? 'bruteSerie' : 'piezoSerie'],
            ...this.state.statsSeries,
            ...this.state.yearSeries,
            ...associationsInFirstAxis,
            ...this.state.thresholds,
            ...this.state.modelsSeries,
        ]

        this.series = [
            ...firstAxisSeries,
            ...otherSeries,
        ]

        const option = this.state.piezometerChartOptions[0]

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            gridIndex: 0,
            showSplitLine: option && hasValue(option.displayXIntervalYear) ? option.displayXIntervalYear === '1' : true,
        }))
        const yScale = yAutomaticScale(firstAxisSeries.filter(s => s.obj.isPiezo && !s.obj.noYScale)
            .flatMap(s => s.obj.isMultiBand ? s.obj.bands.filter(b => !b.noYScale).flatMap(b => b.data) : s.obj.data))
        this.yScale = yScale

        const unit = this.props.piezometerStatistics.find(t => t.typeId === -1)?.unit
        yAxis.push(Axis(Object.assign({}, {
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.chronic} ${unit ? `[${unit}]` : ''}`,
            gridIndex: 0,
            nameGap: 40,
            showSplitLine: option && hasValue(option.displayYIntervalYear) ? option.displayYIntervalYear === '1' : true,
            inverse: this.state.displayCote ? this.state.displayCote !== MEASURE_COTE.NGF : false,
            isPiezo: true,
        },
        yScale,
        setYOptionsPiezo(option, -1, yScale, this.state.displayCote, landmarkValue, null),
        )))
        otherAxis.forEach(axis => yAxis.push(axis))
        const options = {
            customTitle: {
                text: `[${piezometer.code}]${piezometer.name ? ` - [${piezometer.name}]` : ''}`,
                top: '5%',
                left: 'center',
            },
            series: this.series,
            xAxis,
            yAxis,
            legend: {
                bottom: 45,
                show: true,
                // type: 'scroll',
                data: this.series.flatMap(s => s.obj.bands ? s.obj.bands : [s.obj]).filter(s => !s.noLegend)
                    .map(s => ({
                        name: s.name,
                        icon: ['fre', 'ENVELOPPE'].some(l => (s.code || '').includes(l)) ? 'roundRect' : 'circle',
                    })),
            },
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            tooltip: this.getTooltip(),
            setDataZoom: true,
            height: this.state.height,
            grid: {
                top: '10%',
                left: '5%',
                right: `${2.5*yAxis.length}%`,
                containLabel: true,
            },
            gridHeight: this.state.height - 90 - (parseInt(this.series.length / 3)*20),
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(chartMinDate, chartMaxDate),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                    },
                },
                right: 50,
            },
        }
        return (
            <div className='row relative no-overflow' style={{ margin: '0 6px' }}>
                <OldTabsSideBar tabs={ tabs }/>
                <div id='chart'><Card><div className='side-component-content'>
                    <div className='padding-left-2'>
                        <ChartTabsSuivi
                            onChangeDate={(changes, forced) => {
                                if (this.state.minDate !== changes.minDate || this.state.maxDate !== changes.maxDate || forced) {
                                    this.setState({ ...changes }, () => {
                                        if (this.state.withBrutes) {
                                            this.getBrutesMeasures()
                                        }
                                    })
                                }
                            }} withPiezoCote
                            changeParent={ obj => {
                                if (obj.displayCote) {
                                    this.changeCote(obj.displayCote)
                                } else {
                                    this.setState(obj)
                                    this.getBrutesMeasures()
                                }
                            } }
                            default={ { active: 'CIVIL_YEAR' } }
                            withBrutes={ this.state.withBrutes }
                        />
                    </div>
                    <EChart options={ options } id='piezometrySuiviChart' bandCorrection={ true }/>
                </div></Card></div>
            </div>
        )
    }
}

PiezoSuiviChart2.propTypes = {
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    fetchPiezometerChartOptions: PropTypes.func,
    piezometerChartOptions: arrayOf(DtoPiezometerChartOptions),
    piezometerStatistics: arrayOf(DtoMeasureStats),
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    piezometerChartOptions: store.PiezometerStationReducer.piezometerChartOptions,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    piezometers: store.PiezometryReducer.piezometersLight,
})

const mapDispatchToProps = {
    fetchPiezometerChartOptions: PiezometerStationAction.fetchPiezometerChartOptions,
    fetchPiezoMeasuresStats: PiezometerStationAction.fetchPiezoMeasuresStats,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezoSuiviChart2)