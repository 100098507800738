module.exports = {
    WATER_RESOURCE: 'WATER_RESOURCE',
    HYDROSYSTEMS_QUALITY: 'HYDROSYSTEMS_QUALITY',
    PRESSURE: 'PRESSURE',
    TERRITORY: 'TERRITORY',

    PIEZOMETRY: 'PIEZOMETRY',
    HYDROMETRY: 'HYDROMETRY',
    METEOROLOGY: 'METEOROLOGY',
    HYDROMORPHOLOGY: 'HYDROMORPHOLOGY',
    HYDROBIOLOGY: 'HYDROBIOLOGY',
    PHYSICO_CHEMISTRY: 'PHYSICO_CHEMISTRY',
    AGRICULTURE: 'AGRICULTURE',
    INDUSTRY: 'INDUSTRY',
    SERVICES: 'SERVICES',
    ADMINISTRATION: 'ADMINISTRATION',
    NATURE_AND_LANDSCAPE: 'NATURE_AND_LANDSCAPE',
    GROUND_AND_BASEMENT: 'GROUND_AND_BASEMENT',

    ALL_THEME: {
        PIEZOMETRY: { label: 'piezometry', color: '#1cbdc8', data: ['piezometers'] },
        HYDROMETRY: { label: 'hydrometry', color: '#1cbdc8', data: ['hydrometricStations'] },
        METEOROLOGY: { label: 'meteorology', color: '#1cbdc8', data: ['pluviometers'] },
        HYDROMORPHOLOGY: { label: 'hydromorphology', color: '#11858a', data: ['hydrometricStations', 'flowObstruction'] },
        HYDROBIOLOGY: { label: 'hydrobiology', color: '#11858a', data: ['surfaceQualitometer'] },
        PHYSICO_CHEMISTRY: { label: 'physicoChemistry', color: '#11858a', data: ['qualitometers'] },
        AGRICULTURE: { label: 'agriculture', color: '#768788', data: ['sampleWorks'] },
        INDUSTRY: { label: 'industry', color: '#768788', data: ['sampleWorks', 'industrialSites', 'pollutedSoils'] },
        SERVICES: { label: 'services', color: '#768788', data: ['sampleWorks', 'treatmentStation', 'capture'] },
        ADMINISTRATION: { label: 'administration', color: '#447a3c', data: [''] },
        NATURE_AND_LANDSCAPE: { label: 'natureAndLandscape', color: '#447a3c', data: [''] },
        GROUND_AND_BASEMENT: { label: 'groundAndBasement', color: '#447a3c', data: ['borehole', 'cavities'] },
    },
    PIEZOMETER: { label: 'piezometers', typeName: 'PIEZOMETER' },
    HYDROMETRIC_STATION: { label: 'hydrometricStations', typeName: 'HYDROMETRIC_STATION' },
    PLUVIOMETER: { label: 'pluviometers', typeName: 'PLUVIOMETER' },
    INSTALLATION_FLOW_OBSTRUCTION: { label: 'flowObstruction', typeName: 'INSTALLATION_FLOW_OBSTRUCTION' },
    QUALITOMETER_SURFACE: { label: 'surfaceQualitometer', typeName: 'QUALITOMETER_SURFACE' },
    QUALITOMETER: { label: 'qualitometers', typeName: 'QUALITOMETER' },
    INSTALLATION_SAMPLE_WORK: { label: 'sampleWorks', typeName: 'INSTALLATION_SAMPLE_WORK' },
    INSTALLATION_INDUSTRIAL_SITE: { label: 'industrialSites', typeName: 'INSTALLATION_INDUSTRIAL_SITE' },
    INSTALLATION_POLLUTED_SOIL: { label: 'pollutedSoils', typeName: 'INSTALLATION_POLLUTED_SOIL' },
    INSTALLATION_STEP: { label: 'STEP', typeName: 'INSTALLATION_STEP' },
    INSTALLATION_BOREHOLE: { label: 'borehole', typeName: 'INSTALLATION_BOREHOLE' },
    INSTALLATION_CAVITY: { label: 'cavities', typeName: 'INSTALLATION_CAVITY' },
    INSTALLATION_CAPTURE: { label: 'capture', typeName: 'INSTALLATION_CAPTURE' },
}
