import { countBy, findIndex, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import Other from '../../../../../components/actions/Other'
import Table from '../../../../../components/datatable/Table'
import DtoFile from '../../../../../components/file/dto/DtoFile'
import Input from '../../../../../components/forms/Input'
import Select from '../../../../../components/forms/Select'
import SelectionSelect from '../../../../../components/forms/specific/SelectionSelect'
import ThresholdSelect from '../../../../../components/forms/specific/ThresholdSelect'
import SliderPanel from '../../../../../components/slider/SliderPanel'
import CityDto from '../../../../../referencial/components/city/dto/CityDto'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import DtoStation from '../../../../../station/dto/DtoStation'
import AppStore from '../../../../../store/AppStore'
import {
    getLocalizationLabel,
    getLocalizationPicto,
    getNonConformSelectData,
    getResultFormat,
    getTabColorsBySize,
    getThreshold,
    calculateThresholdResult,
    getThresholdResult,
} from '../../../../../utils/AnalyseUtils'
import {
    arrayOf,
    getLabel,
    getLabelTruncate,
    getMapStateToProps,
    getObjectLabel,
    getPropTypes, instanceOf,
    objectOf
} from '../../../../../utils/StoreUtils'
import OperationAction from '../../actions/OperationAction'
import DtoOperation from '../../dto/DtoOperation'
import DtoRemark from '../../dto/DtoRemark'
import { searchAllCharacters } from 'utils/StringUtil'
import Row from '../../../../../components/react/Row'
import DtoQualityIndicators from '../../../../dto/QualityIndicator/DtoQualityIndicators'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../../../../referencial/components/unit/dto/UnitDto'
import { WhiteCard } from '../../../../../components/styled/Card'
import { getStatusOrQualificationPie } from '../../../../../utils/StatusUtil'
import OperationTreeMapChart from './OperationTreeMapChart'
import DtoPrettyAnalysisLight from '../../../dashboard/dto/DtoPrettyAnalysisLight'
import { push } from 'connected-react-router'
import DtoSample from '../../../../dto/DtoSample'
import { QualityIndices } from 'quality/components/dashboard/DashboardQualitySituationPanel'
import { SuperParameterGraphModal } from 'quality/components/qualityComponents/ParameterGraph'

const propsToFetch = {
    thresholds: false,
    qualityThresholds: false,
    qualitometer: false,
}

const tableHeaders = ['parameter', 'result', 'unit', 'place']

class OperationOverviewPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            conformity: null,
            selections: [],
            selection: -1,
            searchValue: '',
            isOpen: false,
            selectedParam: undefined,
            isOpenChartPopin: false
        }
    }

    componentDidMount() {
        this.setExport()
    }

    setExport = () => {
        const actions = {
            export: () => ({
                data: this.getExportData(),
                exportType: 'xlsx',
                titleFile: `${this.props.qualitometer.code} - ${i18n.operations}`,
            }),
            delete: () => {
                OperationAction.deleteOperation(this.props.qualitometer.id, this.props.operation, this.props.sample).then(() => AppStore.dispatch(push(`/station/quality/${this.props.qualitometer.id}/operation`)))
            },
            importFile: {
                onClick: this.props.uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
        }
        if (this.props.files.length) {
            actions.other = {
                other: (
                    <Other
                        className='clickable'
                        tooltip={i18n.operation}
                        icon='attach_file'
                        onClick={this.props.getOperationFiles}
                    />
                ),
            }
        }
        this.setActions(actions)
    }

    getPie = (statusOrQualification) => {
        const values = Object.entries(countBy(this.props.operationAnalysis, statusOrQualification)).map(([id, value]) => ({ id: parseInt(id), value }))
        return getStatusOrQualificationPie(statusOrQualification, values)
    }

    getExportData = () => {
        const currentThreshold = this.props.qualityThresholds.find(t => t.thresholdCode == this.props.threshold) || {}
        const conformity = getLabel(getNonConformSelectData(), this.state.conformity, 'conformity', 'value')
        const analysisObjects = this.props.operationAnalysis.map(a => ({
            ...a,
            ...calculateThresholdResult(a, currentThreshold.thresholds),
        }))
            .filter(obj => !this.state.conformity ? true : conformity.includes(obj.color))
            .filter(obj => this.state.selection == -1 ? true : this.state.selections.includes(obj.parameter))
        return orderBy(analysisObjects, [obj => findIndex(getTabColorsBySize()[6], c => c === obj.color), 'result'], ['', 'desc']).map(analyse => {
            const threshold = getThreshold(currentThreshold.thresholds, analyse.parameter, analyse.unit, analyse.qualitometer)
            const {
                thresholdIndice: levelThreshold,
            } = threshold ? getThresholdResult(threshold, analyse.result) : {}
            return {
                codeParameter: { value: analyse.parameter, format: '0', cellType: 'number' },
                parameter: getObjectLabel(this.props.parametersIndex[analyse.parameter]),
                result: { value: getResultFormat(analyse).replace('.', ','), color: analyse.color, textColor: analyse.textColor, cellType: 'right' },
                levelThreshold: { value: levelThreshold, format: '0', cellType: 'number' },
                valueThreshold: { value: levelThreshold ? threshold[`threshold${levelThreshold}`.replace('.', ',')] : null, format: '0.000', cellType: 'number' },
                codeUnit: { value: analyse.unit, cellType: 'right' },
                unit: getObjectLabel(this.props.unitsIndex[analyse.unit], 'symbol'),
                localizationCode: { value: analyse.localization, format: '0', cellType: 'number' },
                localization: getLocalizationLabel(analyse.localization),
                headers: [
                    'codeParameter',
                    'parameter',
                    'result',
                    'levelThreshold',
                    'valueThreshold',
                    'codeUnit',
                    'unit',
                    'localizationCode',
                    'localization',
                ],
            }
        })
    }

    getColoredResults = () => {
        const currentThreshold = this.props.qualityThresholds.find(t => t.thresholdCode == this.props.threshold) || {}
        const searchValue = searchAllCharacters(this.state.searchValue)
        if (currentThreshold && Object.keys(this.props.parametersIndex).length !== 0) {
            const conformity = getLabel(getNonConformSelectData(), this.state.conformity, 'conformity', 'value')
            const analysisObjects = this.props.operationAnalysis.map(a => ({
                ...a,
                ...calculateThresholdResult(a, currentThreshold.thresholds),
            }))
                .filter(obj => !this.state.conformity ? true : conformity.includes(obj.color))
                .filter(obj => this.state.selection == -1 ? true : this.state.selections.includes(obj.parameter))
            const results = orderBy(analysisObjects, [obj => findIndex(getTabColorsBySize()[6], c => c === obj.color), 'result'], ['', 'desc'])
                .map(analyse => {
                    const parameter = this.props.parametersIndex[analyse.parameter]
                    const parameterValue = parameter ? {
                        value: getLabelTruncate(parameter.shortLabel || parameter.name, analyse.parameter, 25),
                        className: 'tooltipped',
                        tooltip: parameter.name,
                    } : {
                        value: `<${analyse.parameter}>`,
                    }
                    const remark = getLabel(this.props.remarks, analyse.remarkCode)
                    const labelUnit = getObjectLabel(this.props.unitsIndex[analyse.unit], 'symbolWithCode')
                    return {
                        parameter: parameterValue,
                        paramCode: analyse.parameter,
                        result: {
                            value: analyse.value,
                            classNameColor: analyse.color,
                            setTooltip: () => (
                                <div>
                                    <span>
                                        {`${i18n.remark}: ${remark}`}
                                        <br />
                                    </span>
                                    {
                                        analyse.remarkCode !== '0' && analyse.color !== 'white' && !!analyse.threshold && (
                                            <SliderPanel
                                                data={{
                                                    title: parameter.name || `<${analyse.parameter}>`,
                                                    subtitle: '',
                                                    threshold: analyse.threshold,
                                                    value: analyse.value,
                                                }} store={AppStore} withThresholdLevels
                                            />
                                        )
                                    }
                                </div>
                            ),
                        },
                        place: { value: getLocalizationPicto(analyse.localization) },
                        localization: { value: getLocalizationLabel(analyse.localization) },
                        unitCode: analyse.unit,
                        unit: { value: analyse.unit ? labelUnit : '' },
                        searchValue: searchAllCharacters(`${analyse.parameter}${parameter?.name}${parameter?.shortLabel}${labelUnit}${analyse.result}`),
                    }
                }).filter(a => a.searchValue.includes(searchValue))
            return results
        }
        return null
    }

    onClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        const coloredResults = (() => {
            const results = this.getColoredResults()
            return (
                <div>
                    <WhiteCard>
                        <div className='padding-1'>
                            <div className='row no-margin'>
                                <ThresholdSelect
                                    col={6}
                                    onChange={this.props.onChangeThreshold}
                                    selected={this.props.threshold}
                                    nullLabel='&nbsp;'
                                />
                                <Select
                                    options={getNonConformSelectData()}
                                    label={i18n.conformity}
                                    col={6}
                                    nullLabel= {this.props.threshold ? i18n.everybody : ' '}
                                    onChange={(e) => this.setState({ conformity: e })}
                                    value={this.state.conformity}
                                    disabled = {!this.props.threshold}
                                />
                            </div>
                            <div className='row'>
                                <SelectionSelect
                                    col={6}
                                    onChange={(parameters, value) => this.setState({ selections: parameters, selection: value })}
                                />
                                <Input
                                    col={6}
                                    onChange={v => this.setState({ searchValue: v })}
                                    title={i18n.search}
                                    value={this.state.searchValue}
                                />
                            </div>
                        </div>
                    </WhiteCard>
                    <div className='padding-top-2'/>
                    {results && results.length ? (
                        <WhiteCard title={`${results.length} ${i18n.analysis}`}>
                            <div className='row'>
                                <Table
                                    condensed
                                    sortable
                                    color
                                    className='blueTableTitle'
                                    type={{ headers: tableHeaders }}
                                    onClick={({ paramCode }) => this.setState({ isOpenChartPopin: true, selectedParam: paramCode })}
                                    paging
                                    showTitle={false}
                                    nbPerPageLabel={nbPerPageLabel}
                                    data={results}
                                />
                            </div>
                        </WhiteCard>
                    ) : null}
                </div>
            )
        })()

        const thresholds = (this.props.qualityThresholds.find(t => t.thresholdCode == this.props.threshold) || {})?.thresholds || []

        const { isOpenChartPopin, selectedParam } = this.state
        return (
            <div className='row'>
                <div className='col s6 no-padding'>
                    <Row className='padding-1'>
                        {coloredResults}
                    </Row>
                </div>
                <div className='col s6 no-padding'>
                    <Row className='padding-1'>
                        <WhiteCard title={i18n.qualityIndicators} cardContentStyle={ { padding: '10px !important' } }>
                            <div className='padding-1'>
                                <Row>
                                    <div className='col s9'>
                                        <QualityIndices
                                            stationType={this.props.qualitometer.stationType}
                                            analysis={this.props.operationAnalysis}
                                        />
                                    </div>
                                    <div className='col s3'>
                                        <Row>
                                            {this.getPie('status')}
                                        </Row>
                                        <Row>
                                            {this.getPie('qualification')}
                                        </Row>
                                    </div>
                                </Row>
                            </div>
                        </WhiteCard>
                    </Row>
                    <Row className='padding-1'>
                        <WhiteCard title={'Codes remarques'}>
                            <OperationTreeMapChart analysis={this.props.operationAnalysis} />
                        </WhiteCard>
                    </Row>
                </div>
                <SuperParameterGraphModal
                    isOpen={isOpenChartPopin}
                    closeGraph={() => this.setState({ isOpenChartPopin: false })}

                    parameter={selectedParam}
                    qualitometer={parseInt(this.props.id)}
                    thresholds={thresholds}
                    graphOptions={{
                        regroupAxis: false,
                    }}
                />
            </div>
        )
    }
}

OperationOverviewPanel.propTypes = getPropTypes(propsToFetch, {
    id: PropTypes.string,
    threshold: PropTypes.number,
    qualitometer: PropTypes.instanceOf(DtoStation),
    onChangeThreshold: PropTypes.func,
    operationAnalysis: arrayOf(DtoPrettyAnalysisLight),
    height: PropTypes.number,
    operation: PropTypes.instanceOf(DtoOperation),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    remarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoRemark)),
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(CityDto)),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    getOperationFiles: PropTypes.func,
    uploadFile: PropTypes.func,
    deleteOperation: PropTypes.func,
    qualityIndicators: arrayOf(DtoQualityIndicators),
    parametersIndex: objectOf(ParameterDto),
    unitsIndex: objectOf(UnitDto),
    sample: instanceOf(DtoSample),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    operationAnalysis: store.OperationReducer.operationAnalysis,
    qualitometer: store.QualityReducer.qualitometer,
    operation: store.OperationReducer.operation,
    remarks: store.OperationReducer.remarks,
    citiesIndex: store.CityReducer.citiesIndex,
    operations: store.SuivipcReducer.operations,
    qualityIndicators: store.QualityReducer.qualityIndicators,
    parametersIndex: store.ParameterReducer.parametersIndex,
    unitsIndex: store.UnitReducer.unitsIndex,
})

const mapDispatchToProps = {
    deleteOperation: OperationAction.deleteOperation,
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationOverviewPanel)