import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SieauAction from '../../../../components/sieau/SieauAction'
import SubscriptionAction from '../actions/SubscriptionAction'
import SubscriptionPanel from './SubscriptionPanel'
import SubscriptionDto from '../dto/SubscriptionDto'
import i18n from 'simple-react-i18n'
import { isEqual, omit, orderBy } from 'lodash'
import SubscriptionTypeDto from '../dto/SubscriptionTypeDto'
import ToastrAction from 'toastr/actions/ToastrAction'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import ActionComponent from '../../../../components/ActionComponent'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import SubscriptionSituationDto from '../dto/SubscriptionSituationDto'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import SituationPanel from '../../situation/SituationPanel'
import { push } from 'connected-react-router'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import User from '../../../../account/dto/User'
import { getUser } from '../../../../utils/SettingUtils'
import SimSubscriptionTable from './SimSubscriptionTable'
import SimSubscriptionDto from '../../sim/dto/SimSubscriptionDto'
import { getDate } from '../../../../utils/DateUtil'
import SimDto from '../../sim/dto/SimDto'
import SimAction from '../../sim/actions/SimAction'
import DtoSimType from '../../sim/dto/DtoSimType'
import AppStore from 'store/AppStore'
import HomeAction from 'home/actions/HomeAction'
import { isSaveBlocked } from '../../../utils/MaterielUtils'
import DtoMaterielSettingRule from '../../../dto/DtoMaterielSettingRule'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_MAT_SUBSCRIPTION } from '../../../../account/constants/AccessRulesConstants'
import { SUBSCRIPTION_ID } from 'materiel/constants/MaterielConstants'

class SubscriptionApp extends ActionComponent {
    state = {
        subscription: {},
        isEditMode: false,
    }

    fetchSubscription = id => {
        this.props.fetchSubscription(id).then(json => {
            if (json.idSubscription) {
                this.props.fetchSubscriptionSituations(json.idSubscription)
                this.props.fetchSimsLink(json.idSubscription)
                this.setState({ subscription: new SubscriptionDto(json) })
            }
        })
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_SUBSCRIPTION)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.match.params.id !== 'new') {
            this.fetchSubscription(this.props.match.params.id)
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.subscriptions.length) {
            this.props.fetchSubscriptions()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        if (!this.props.subscriptionTypes.length) {
            this.props.fetchSubscriptionTypes()
        }
        if (!this.props.sims.length) {
            this.props.fetchSims(true)
        }
        if (!this.props.simTypes.length) {
            this.props.fetchSimTypes()
        }
        this.applyActions()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchSubscription(this.props.match.params.id)
        }
        if (!isEqual(prevProps.subscription, this.props.subscription) || !isEqual(prevProps.subscriptionTypes, this.props.subscriptionTypes)) {
            const {
                subscriptionType,
                numLine,
                id,
            } = this.props.subscription

            const title = numLine || id
            const type = (this.props.subscriptionTypes.find(t => t.code === parseInt(subscriptionType)) || {}).label || i18n.notDefined
            this.props.forceFetch('title', [
                {
                    title: i18n.materiels,
                    href: 'materiel',
                },
                {
                    title: i18n.subscriptions,
                    href: 'materiel/subscription',
                },
                {
                    title: type,
                    href: `materiel/subscription/${this.props.match.params.id}`,
                },
                {
                    title,
                    href: `materiel/subscription/${this.props.match.params.id}`,
                },
            ])
        }
        this.applyActions()
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.match.params.id === 'new' && (!this.props.subscription || !this.props.subscription.id)) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SUBSCRIPTION_ID)
                        if (isSaveBlocked(this.props.subscriptions, this.state.subscription, rules, 'subscriptionType')) return

                        this.props.createSubscription(this.state.subscription).then(id => {
                            if (id) {
                                const situation = {
                                    idSubscription: id,
                                    situationDate: this.state.subscription.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.createSubscriptionSituation(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push('/materiel/subscription')
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SUBSCRIPTION_ID)
                        if (isSaveBlocked(this.props.subscriptions, this.state.subscription, rules, 'subscriptionType')) return

                        const type = this.props.subscriptionTypes.find(({ id }) => id === this.state.subscription.subscriptionType)
                        const currentLink = this.props.simsLink.find(({ endDate }) => !endDate)
                        if (type?.label === 'RTC' && type?.label !== 'Ligne Numérique/Box' && currentLink) {
                            this.props.closeLastSimSubscription(currentLink.idSim).then(() => {
                                this.props.fetchSimsLink(this.state.subscription.id)
                            })
                        }
                        this.props.updateSubscription(this.state.subscription).then(success => success && this.changeEditMode(false))
                    },
                    cancel: () => {
                        this.setState({ subscription: this.props.subscription })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deleteSubscription(this.props.match.params.id)
                },
                replace: () => {
                    this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentWillUnmount = () => {
        this.props.resetSubscription()
        this.props.resetAllActions()
    }

    changeAdministrator = managerCode => this.setState(({ subscription }) => ({ subscription: { ...subscription, managerCode } }))

    changeSubscriptionValue = changes => this.setState({ subscription: { ...this.state.subscription, ...changes } })

    deleteSituation = id => this.props.deleteSubscriptionSituation(id, this.props.match.params.id)

    getMaterielNumber = ({ reference }) => reference || ''

    getSubscriptionsFormat = () => {
        const { subscriptions, subscriptionTypes } = this.props
        return subscriptions.map(subscription => {
            const label = (() => {
                const number = this.getMaterielNumber(subscription)
                if (subscription.subscriptionType) {
                    const type = subscriptionTypes.find(t => t.id === subscription.subscriptionType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: subscription.id,
                label,
            }
        })
    }

    onDeleteSimSubsciption = ({ idSim, idSubscription }) => {
        this.props.deleteSimSubscriptionLink(idSim, idSubscription).then(() => this.props.fetchSimsLink(this.props.match.params.id))
    }

    render() {
        const {
            sims,
            simTypes,
            simsLink,
            subscriptionTypes,
        } = this.props
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }

        const formatedLinks = orderBy(simsLink, [({ startDate }) => startDate ? startDate : 0, ({ endDate }) => endDate ? endDate : 0], ['desc', 'desc']).map(({ idSim, idSubscription, startDate, endDate, comment }) => {
            const sim = sims.find(({ id }) => idSim === id)
            if (sim) {
                const type = simTypes.find(({ id }) => id === sim.simType)
                return {
                    sim: `${type ? `${type.name}:` : ''} ${sim.serialNumber || ''}`,
                    startDate: getDate(startDate),
                    endDate: getDate(endDate),
                    comment,
                    idSim,
                    idSubscription,
                }
            }
            return {
                startDate: getDate(startDate),
                endDate: getDate(endDate),
                comment,
                idSim,
                idSubscription,
            }
        })

        const formatedSims = this.props.sims.filter(({ isEquipment }) => !isEquipment).map(({ serialNumber = '', id, simType }) => {
            const type = simTypes.find(({ code }) => code === simType)
            return {
                code: id,
                name: `${type ? `${type.name}:` : ''} ${serialNumber}`,
            }
        })

        const type = subscriptionTypes.find(({ id }) => id === this.state.subscription.subscriptionType)

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel materielSituation={this.props.subscriptionSituations} />
                                    </div>
                                    <div className='col s11'>
                                        <SubscriptionPanel
                                            id={this.props.match.params.id}
                                            subscription={this.state.subscription}
                                            onChange={this.changeSubscriptionValue}
                                            {...disabled}
                                        />
                                        {
                                            !!this.props.subscription.id && type?.label !== 'RTC' && type?.label !== 'Ligne Numérique/Box' && (
                                                <div className='col s12'>
                                                    <SimSubscriptionTable
                                                        links={formatedLinks}
                                                        editMode={this.state.isEditMode}
                                                        headers={['sim', 'startDate', 'endDate', 'comment']}
                                                        title={i18n.sims}
                                                        idKeyToRedirect={'idSim'}
                                                        redirectTo={'sim'}
                                                        associatedMateriels={formatedSims}
                                                        onDelete={this.onDeleteSimSubsciption}
                                                        onAdd={({ materielLink, startDate, endDate, comment }) => {
                                                            const simSubscription = {
                                                                idSubscription: this.props.subscription.id,
                                                                idSim: materielLink,
                                                                startDate,
                                                                endDate,
                                                                comment,
                                                            }
                                                            this.props.createSimSubscription(simSubscription).then(() =>
                                                                this.props.fetchSimsLink(this.props.match.params.id))
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                        <div className='col s12'>
                                            <SituationPanel
                                                materielSituation={this.props.subscriptionSituations}
                                                active={this.state.isEditMode}
                                                idMateriel={this.props.subscription.id}
                                                purchaseDate={this.props.subscription.purchaseDate}
                                                idKey={'idSubscription'}
                                                lastSituations={this.props.subscriptionsLastSituations}
                                                materials={this.getSubscriptionsFormat()}
                                                saveFunction={this.props.createSubscriptionSituation}
                                                deleteFunction={this.deleteSituation}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.props.subscription.updateDate}
                                                updateLogin={this.props.subscription.updateLogin}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.subscription.managerCode}
                                                        isActive={this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel materielSituation={this.props.subscriptionSituations} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SubscriptionApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    getLink: PropTypes.func,
    resetAllActions: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    subscription: PropTypes.instanceOf(SubscriptionDto),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    subscriptions: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionDto)),
    subscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionSituationDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    subscriptionSituations: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionSituationDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    simsLink: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
    simTypes: PropTypes.instanceOf(DtoSimType),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    subscription: store.SubscriptionReducer.subscription,
    subscriptionSituations: store.SubscriptionReducer.subscriptionSituations,
    materielStates: store.MaterielReducer.materielStates,
    contributors: store.ContributorReducer.contributors,
    accountUser: store.AccountReducer.accountUser,
    subscriptions: store.SubscriptionReducer.subscriptions,
    subscriptionsLastSituations: store.SubscriptionReducer.subscriptionsLastSituations,
    simsLink: store.SubscriptionReducer.simsLink,
    sims: store.SimReducer.sims,
    simTypes: store.SimReducer.simTypes,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProps = {
    push,
    fetchSims: SimAction.fetchSims,
    fetchSimTypes: SimAction.fetchSimTypes,
    fetchSubscriptionTypes: SubscriptionAction.fetchSubscriptionTypes,
    fetchSubscription: SubscriptionAction.fetchSubscription,
    forceFetch: SieauAction.forceFetch,
    createSubscription: SubscriptionAction.createSubscription,
    updateSubscription: SubscriptionAction.updateSubscription,
    deleteSubscription: SubscriptionAction.deleteSubscription,
    toastrInfo: ToastrAction.info,
    resetSubscription: SubscriptionAction.resetSubscription,
    deleteSubscriptionSituation: SubscriptionAction.deleteSubscriptionSituation,
    fetchSubscriptionSituations: SubscriptionAction.fetchSubscriptionSituations,
    createSubscriptionSituation: SubscriptionAction.createSubscriptionSituation,
    fetchSubscriptions: SubscriptionAction.fetchSubscriptions,
    fetchSimsLink: SubscriptionAction.fetchSimsLink,
    deleteSimSubscriptionLink: SubscriptionAction.deleteSimSubscriptionLink,
    createSimSubscription: SimAction.createSimSubscription,
    closeLastSimSubscription: SimAction.closeLastSimSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionApp)
