import { min, minBy, orderBy, sum, partition, isUndefined, round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../components/card/ProgressCard'
import Axis from '../../../components/echart/Axis'
import ChartTabs from '../../../components/echart/ChartTabs'
import EChart from '../../../components/echart/EChart'
import {
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    getAxisLabelInterval,
    getBarWidth,
    noDataSymbol,
} from '../../../components/echart/EChartUtils'
import Bar from '../../../components/echart/series/Bar'
import Line from '../../../components/echart/series/Line'
import Checkbox from '../../../components/forms/Checkbox'
import Icon from '../../../components/icon/Icon'
import {
    getColorFromPalettePluvio,
    getThresholdColor,
    getThresholdColorCode,
} from '../../../utils/ColorUtil'
import { getFullDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { hasBooleanValue, hasValue } from '../../../utils/NumberUtil'
import { arrayOf } from '../../../utils/StoreUtils'
import PluviometryAction from '../../actions/PluviometryAction'
import DtoPluviometerStats from '../../dto/DtoPluviometerStats'
import DtoPluvioMeasures from '../../dto/measures/DtoPluvioMeasures'
import PluviometerDto from '../../dto/PluviometerDto'
import AppStore from '../../../store/AppStore'
import Row from 'components/react/Row'
import { WhiteCard } from 'components/styled/Card'
import { StyledFieldSet, StyledLegend } from '../../../components/StyledElements'
import { Button, Grid } from '@mui/material'
import RadioButtons from '../../../components/forms/RadioButtons'
import Select from '../../../components/forms/Select'
import NumberField from '../../../components/forms/NumberField'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { tooltipDatePluvio } from '../../../utils/PluviometryUtils'
import { getLocalStorageJson, setLocalStorageJson } from '../../../utils/FormUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import PluviometerThresholdDto from '../../dto/PluviometerThresholdDto'

// TODO ATTENTION ! Composant réutilisé dans plusieurs écrans
const DAY = 'DAY'
const HOUR = 'HOUR'

const CUMUL_MAX = 'CUMUL_PERSO_MAX'
const CUMUL_MIN = 'CUMUL_PERSO_MIN'
const CUMUL_SUM = 'CUMUL_PERSO_SUM'
const CUMUL_AVERAGE = 'CUMUL_PERSO_AVERAGE'
class PluviometerChartPanel extends Component {
    constructor(props) {
        super(props)
        this.optionCumulSelected = 'CUMUL_PERSO_MAX'
        this.hoursCumul = 1
        this.state = {
            fullScreen: false,
            minDate: undefined,
            maxDate: moment().valueOf(),
            showGroupedDateDropdown: false,
            pluvioGrouped: 'SUM_AUTO',
            dataLoaded: false,
            progess: 0,
            chartTab: null,
            isEditMode: false,
            optionDayHourSelected: 'HOUR',
            pluvioEndDataArea: getLocalStorageJson('PLUVIO_END_DATA_AREA') ?? false,
        }
    }

    loadData = () => {
        const defaultGroupMode = this.state.minDate && this.state.minDate > moment(this.state.maxDate || moment().valueOf()).subtract('1', 'month').valueOf() ? 'all' : 'MAX'
        const inputs = this.props.pluviometerStatistics.map(stat => ({
            stationId: this.props.id,
            dataType: stat.typeId,
            groupFunc: this.state.pluvioGrouped.slice(0, 5) === 'CUMUL' ? this.state.pluvioGrouped : [-1, 1, 3].includes(stat.typeId) ? this.state.pluvioGrouped : defaultGroupMode,
            chartMode: true,
            startDate: this.state.minDate,
            endDate: this.state.maxDate,
        }))
        this.props.loadPluvioChronicMeasures(inputs, p => this.setState({ progress: p })).then(() => this.setState({ dataLoaded: true }))
    }

    componentDidMount() {
        AppStore.dispatch(PluviometryAction.fetchPluviometer(this.props.id))
        AppStore.dispatch(PluviometryAction.fetchPluviometerMeasuresStats(this.props.id))
        AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        AppStore.dispatch(PluviometryAction.fetchPluviometerThresholds(this.props.id))
    }

    getExportData = () => {
        const data = this.props.pluviometerStatistics.flatMap(stat => (this.props.pluvioMeasures.find(obj => obj.dataType === stat.typeId)?.measures || []).map(d => ({
            stationCode: { value: this.props.pluviometer.code },
            stationName: { value: this.props.pluviometer.name },
            date: { value: getFullDate(d.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: d.value, format: '0.00', cellType: 'number' },
            type: { value: stat.label },
        })))

        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }
        return data
    }

    getTooltip() {
        return {
            trigger: 'axis',
            formatter: params => {
                const date = tooltipDatePluvio(params[0].value[0], this.state.pluvioGrouped)
                const paramsOrder = orderBy(params, 'axisIndex', 'asc')
                const paramsFormat = paramsOrder.filter(o => hasValue(o.value[1]) && o.seriesName !== i18n.events).map(o => ({
                    marker: o.marker,
                    seriesName: o.seriesName,
                    value: o.value[1],
                    unit: o.value[2],
                }))
                const result = paramsFormat.map(o => `${o.marker} ${o.seriesName} : ${o.value} ${o.unit}`).join('<br/>')
                return `${date}<br />${result}`
            },
        }
    }

    createLine = (data, title, index, option = {}) => {
        return Line({
            name: title,
            serieId: title,
            xAxisIndex: index,
            yAxisIndex: index,
            data,
            connectNulls: false,
            showSymbol: false,
            ...option,
        })
    }

    getMinDate = () => {
        if (this.state.minDate) {
            return this.state.minDate
        }
        const allFirstMeasures = this.props.pluvioMeasures.flatMap(obj => obj.measures.length ? [obj.measures[0].date] : [])
        if (allFirstMeasures.length) {
            return min(allFirstMeasures)
        }
        return this.props.pluviometerStatistics.length ? minBy(this.props.pluviometerStatistics, 'startDate').startDate : moment().subtract(1, 'years').valueOf()
    }

    changeEndDataArea = () => {
        setLocalStorageJson('PLUVIO_END_DATA_AREA', !this.state.pluvioEndDataArea)
        this.setState({ pluvioEndDataArea: !this.state.pluvioEndDataArea })
    }

    addRain = (series, grids, xAxis, yAxis, gridsHeights, stat, chartMinDate, chartMaxDate) => {
        grids.push({
            top: sum(gridsHeights) + 10,
            right: '2%',
            height: 210,
            left: 60,
        })

        const thresholds = this.props.pluviometerThresholds.filter(t => !isUndefined(t.value) && t.dataType === '1').map(t => ({
            yAxis: t.value,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${t.value}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor || getThresholdColor(t.color),
                    type: 'dashed',
                },
            },
        }))

        const measuresObj = this.props.pluvioMeasures.find(obj => obj.dataType === 1)
        const dataType = this.props.pluviometryDataTypes.find(v => v.id === 1)
        const unit = dataType?.unit ? `[${dataType.unit}]` : ''
        const data = measuresObj?.measures.map(m => [m.date, m.value, unit]) || []

        const barWidth = getBarWidth(data.length)

        const axisLabelObj = getAxisLabelInterval(moment(chartMaxDate), moment(chartMinDate), barWidth)


        const markArea = this.state.pluvioEndDataArea && data.length ? {
            itemStyle: {
                color: 'rgba(128, 128, 128, 0.4)',
            },
            data: [
                [
                    {
                        xAxis: data[data.length-1][0],
                    },
                    {
                        xAxis: moment().valueOf(),
                    },
                ],
                [
                    {
                        xAxis: chartMinDate,
                    },
                    {
                        xAxis: data[0][0],
                    },
                ],
            ],
        } : {}

        series.push(Bar({
            name: stat.label,
            barWidth,
            yAxisIndex: grids.length - 1,
            xAxisIndex: grids.length - 1,
            data,
            markLine: { silent: false, data: thresholds },
            areaStyle: {},
            color: 'blue',
            boundaryGap: ['50%', '50%'],
            clip: false,
            barGap: '0%',
            markArea,
        }))

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            minInterval: axisLabelObj.interval,
            maxInterval: axisLabelObj.interval,
            gridIndex: grids.length - 1,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }))
        yAxis.push(Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${stat.label} ${unit}`,
            gridIndex: grids.length - 1,
            nameGap: 40,
            showSplitLine: true,
        }))
        gridsHeights.push(255)
    }

    addOther = (series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, stat, chartMinDate, chartMaxDate, color) => {
        grids.push({
            top: sum(gridsHeights),
            right: '2%',
            height: 150,
            left: 60,
        })

        const thresholds = this.props.pluviometerThresholds.filter(t => !isUndefined(t.value) && parseInt(t.dataType) === stat.typeId).map(t => ({
            yAxis: t.value,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${t.value}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor ?? getThresholdColorCode(t.color),
                    type: 'dashed',
                },
            },
        }))

        const dataType = this.props.pluviometryDataTypes.find(v => v.id === stat.typeId)
        const unit = dataType?.unit ? `[${dataType.unit}]` : ''
        const data = (this.props.pluvioMeasures.find(obj => obj.dataType === stat.typeId)?.measures || []).map(m => [m.date, m.value, unit])
        const barWidth = getBarWidth(data.length)
        const markArea = this.state.pluvioEndDataArea && data.length ? {
            itemStyle: {
                color: 'rgba(128, 128, 128, 0.4)',
            },
            data: [
                [
                    {
                        xAxis: data[data.length-1][0],
                    },
                    {
                        xAxis: moment().valueOf(),
                    },
                ],
                [
                    {
                        xAxis: chartMinDate,
                    },
                    {
                        xAxis: data[0][0],
                    },
                ],
            ],
        } : {}

        if (stat.typeId === -1) {
            const [superior, inferior] = partition(data, d => d[1] > 0)
            series.push(Bar({
                name: stat.label,
                barWidth,
                data: superior,
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                markLine: { silent: false, data: thresholds },
                areaStyle: {},
                color: '#6a37ff',
                boundaryGap: ['50%', '50%'],
                clip: false,
                barGap: '0%',
                serieId: `additional${stat.typeId}`,
                markArea,
            }))
            series.push(Bar({
                name: stat.label,
                barWidth,
                data: inferior,
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                areaStyle: {},
                color: '#ff3312',
                boundaryGap: ['50%', '50%'],
                clip: false,
                barGap: '0%',
                serieId: `additional${stat.typeId}`,
            }))
        } else if (stat.typeId === 3) {
            series.push(Bar({
                name: stat.label,
                barWidth,
                areaStyle: {},
                data,
                markLine: { silent: false, data: thresholds },
                color: '#009dbd',
                clip: false,
                barGap: '0%',
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                serieId: `additional${stat.typeId}`,
                markArea,
            }))
        } else {
            series.push(Line({
                name: stat.label,
                connectNulls: false,
                showSymbol: false,
                data,
                color,
                markLine: { silent: false, data: thresholds },
                yAxisIndex: grids.length - 1,
                xAxisIndex: grids.length - 1,
                serieId: `additional${stat.typeId}`,
                markArea,
            }))
        }

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            gridIndex: grids.length - 1,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }))
        yAxis.push(Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${stat.label} ${unit}`,
            gridIndex: grids.length - 1,
            nameGap: 40,
            showSplitLine: true,
        }))
        gridsHeights.push(190)
    }

    getChart = () => {
        if (!this.state.dataLoaded) {
            return <ProgressCard progress={this.state.progress} />
        }


        const minDate = this.getMinDate()
        const chartMinDate = this.state.chartTab === 'HISTO' ? moment(minDate).startOf('year').valueOf() : minDate
        const maxDate = this.state.maxDate || moment().valueOf()
        const chartMaxDate = this.state.chartTab === 'HISTO' ? moment(maxDate).add(1, 'year').startOf('year').valueOf() : maxDate
        const [series, grids, xAxis, yAxis, gridsHeights] = [[], [], [], [], [25]]
        const axisLabelObj = getAxisLabelInterval(moment(chartMaxDate), moment(chartMinDate))

        const stats = orderBy(this.props.pluviometerStatistics, 'typeId')
        const baseStats = stats.filter(s => s.typeId > 0 && s.typeId <= 3)
        const calulatedStats = stats.filter(s => s.typeId < 0)
        const otherStats = stats.filter(s => s.typeId > 3)
        const orderedStats = orderBy([...baseStats, ...calulatedStats, ...otherStats], ['order', 'typeId'], 'asc')

        orderedStats.filter(stat => !hasBooleanValue(stat.showData) || stat.showData).forEach((stat, idx) => {
            if (stat.typeId === 1) {
                this.addRain(series, grids, xAxis, yAxis, gridsHeights, stat, chartMinDate, chartMaxDate)
            } else {
                this.addOther(series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, stat, chartMinDate, chartMaxDate, stat.color || getColorFromPalettePluvio(idx))
            }
        })

        const options = {
            series,
            tooltip: this.getTooltip(),
            grid: grids,
            xAxis,
            yAxis,
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            setDataZoom: true,
            height: sum(gridsHeights) - 40,
            toolbox: {
                show: true,
                right: '35px',
                showTitle: false,
                itemSize: 18,
                tooltip: {
                    show: true,
                    position: 'bottom',
                },
                feature: {
                    restore: {
                        title: i18n.restore,
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    saveAsImage: {
                        title: i18n.export,
                        icon: exportPictureIcon,
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    myToolFullScreen: {
                        show: true,
                        title: i18n.fullScreen,
                        icon: fullScreenIcon,
                        onclick: () => this.setState({ fullScreen: !this.state.fullScreen }),
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                    myToolFullScreen2: {
                        show: true,
                        title: i18n.noData,
                        icon: noDataSymbol,
                        onclick: this.changeEndDataArea,
                        iconStyle: {
                            borderColor: '#5c5c5c',
                        },
                    },
                },
            },
            legend: null,
        }
        return (
            <EChart options={options} id='pluviometryDashboardChart' scrollable={this.state.fullScreen} />
        )
    }

    changeGroupedDate = (group) => {
        this.setState({ pluvioGrouped: group, showGroupedDateDropdown: false, dataLoaded: false }, this.loadData)
    }

    changeGroupedDatePerzonalize = () => {
        const day = `${this.optionCumulSelected}_${this.hoursCumul * 24}`
        const hour = `${this.optionCumulSelected}_${this.hoursCumul}`
        this.setState({
            pluvioGrouped: this.state.optionDayHourSelected === DAY ? day : hour,
            dataLoaded: false,
            showGroupedDateDropdown: false,
        }, this.loadData)
    }

    personalizedGrouping = () => {
        this.setState({ pluvioGrouped: 'CUMUL' })
    }

    groupedPluvioPanel = () => {
        const optionDayHour = [
            { value: HOUR, label: i18n.hour },
            { value: DAY, label: i18n.day },
        ]
        const optionCumul = [
            { value: CUMUL_MAX, label: i18n.max },
            { value: CUMUL_MIN, label: i18n.min },
            { value: CUMUL_SUM, label: i18n.sum },
            { value: CUMUL_AVERAGE, label: i18n.average },
        ]
        return (
            <div style={{ left: '460px', position: 'relative', width: '24px' }}>
                <Icon className='relative' tooltip={i18n.pluvioTotal} style={{ top: '4px', margin: '0 15px' }} icon={'settings'} onClick={() => this.setState({ showGroupedDateDropdown: !this.state.showGroupedDateDropdown })} />
                <div className={this.state.showGroupedDateDropdown ? 'chartDates-dropdown z-index-10' : 'hidden'} style={{ left: '35px' }}>
                    <div style={{ height: '10px' }} />
                    <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1  margin-top-1' style={{ width: '450px' }}>
                        <h5 className='center'>{ i18n.pluvioTotal }</h5>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_AUTO'} label={'Auto'} onChange={() => this.changeGroupedDate('SUM_AUTO') } />
                            <div style={ { 'padding-left': 5 } }/>
                            <Icon icon='info' style={{ fontSize: 18, color: 'grey' } }
                                tooltip={(
                                    <p className='no-margin'>Mode d'affichage par défaut.<br/>
                                          Les données de pluie sont cumulées en fonction de la période affichée.<br/>
                                        {'Si Période < 48 heures => Cumul horaire'}<br/>
                                        {'Si Période < 3 mois    => Cumul journalier'}<br/>
                                        {'Si Période < 1 an      => Cumul hebdomadaire'}<br/>
                                        {'Si Période < 5 ans     => Cumul mensuel'}<br/>
                                        {'Si Période > 5 ans     => Cumul annuel'}<br/>
                                    </p>
                                )}
                            />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'all'} label={`${i18n.allMeasures}`} onChange={() => this.changeGroupedDate('all') } />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_HOUR'} label={`${i18n.hourTotal}`} onChange={() => this.changeGroupedDate('SUM_HOUR') } />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_DAY'} label={`${i18n.dailyTotal}`} onChange={() => this.changeGroupedDate('SUM_DAY') } />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_WEEK'} label={`${i18n.weeklyTotal}`} onChange={() => this.changeGroupedDate('SUM_WEEK')}/>
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_DECADE'} label={`${i18n.decadeTotal}`} onChange={() => this.changeGroupedDate('SUM_DECADE')}/>
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_MONTH'} label={`${i18n.monthlyTotal}`} onChange={() => this.changeGroupedDate('SUM_MONTH')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1 padding-bottom-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_YEAR'} label={`${i18n.yearTotal}`} onChange={() => this.changeGroupedDate('SUM_YEAR')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped.slice(0, 5) === 'CUMUL'} disabled={this.state.pluvioGrouped.slice(0, 5) === 'CUMUL'} label={ i18n.pluvioTotalPersonalized } onChange={() => this.personalizedGrouping() } />
                        </div>
                        <div style={{ padding: '15px 15px' }}>
                            <StyledFieldSet>
                                <StyledLegend>{ i18n.pluvioTotalPersonalized }</StyledLegend>
                                <Grid container justifyContent='space-evenly' style={{ padding: '10px 10px' }} >
                                    <Grid item>
                                        <RadioButtons
                                            colOption={6}
                                            label={ i18n.modeDaysHours }
                                            elements={optionDayHour}
                                            selected={this.state.optionDayHourSelected}
                                            onChange={v => this.setState({ optionDayHourSelected: v },)}
                                            disabled={ !this.state.pluvioGrouped.slice(0, 5) === 'CUMUL' }
                                        />
                                    </Grid>
                                    <Grid container justifyContent='space-evenly' style={{ padding: '10px 10px' }} >
                                        <Select
                                            label={ i18n.modeCumul }
                                            options={optionCumul}
                                            value={this.optionCumulSelected}
                                            onChange={ v => this.optionCumulSelected = v }
                                            disabled={ !this.state.pluvioGrouped.slice(0, 5) === 'CUMUL' }

                                        />
                                        <NumberField
                                            title={ i18n.numberHoursDays }
                                            value={ this.hoursCumul }
                                            onChange={ v => this.hoursCumul = v }
                                            min={ 1 }
                                            disabled={ !this.state.pluvioGrouped.slice(0, 5) === 'CUMUL' }

                                        />
                                    </Grid>
                                    <Button
                                        onClick={ this.changeGroupedDatePerzonalize }
                                        variant='outlined'
                                        disabled={ !this.state.pluvioGrouped.slice(0, 5) === 'CUMUL' }
                                    >
                                        { i18n.calculate }
                                    </Button>
                                </Grid>
                            </StyledFieldSet>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (!this.props.pluviometerStatistics.length) {
            return null
        }
        return (
            <WhiteCard round noBoxShadow={this.props.noBoxShadow}>
                <Row className={this.state.fullScreen ? 'fullscreen-chart' : ''}>
                    <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                        <div>
                            <ChartTabs onChangeDate={dates => this.setState({ ...dates, dataLoaded: false }, this.loadData)}/>
                        </div>
                        { this.groupedPluvioPanel() }
                    </div>
                    <div className='col s12 row no-margin'>
                        {this.getChart()}
                    </div>
                </Row>
            </WhiteCard>
        )
    }
}

PluviometerChartPanel.propTypes = {
    id: PropTypes.number,
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    loadPluvioChronicMeasures: PropTypes.func,
    pluviometerStatistics: arrayOf(DtoPluviometerStats),
    pluvioMeasures: arrayOf(DtoPluvioMeasures),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    toastrWarning: PropTypes.func,
    noBoxShadow: PropTypes.bool,
    pluviometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerThresholdDto)),
}

const mapStateToProps = store => ({
    pluviometer: store.PluviometryReducer.pluviometer,
    pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    pluviometerThresholds: store.PluviometryReducer.pluviometerThresholds,
})

const mapDispatchToProps = {
    loadPluvioChronicMeasures: PluviometryAction.loadPluvioChronicMeasures,
    toastrWarning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(PluviometerChartPanel)