export default class DtoEquipment {
    constructor(obj) {
        this.id = obj.equipmentId
        this.equipmentType = obj.equipmentType
        this.materielType = obj.equipmentType
        this.centralId = obj.centralId
        this.centralIntegrated = obj.centralIntegrated
        this.sensorId = obj.sensorId
        this.sensorIntegrated = obj.sensorIntegrated
        this.simId = obj.simId
        this.simIntegrated = obj.simIntegrated
        this.telecomId = obj.telecomId
        this.telecomIntegrated = obj.telecomIntegrated
        this.powerSupplyId = obj.powerSupplyId
        this.powerSupplyIntegrated = obj.powerSupplyIntegrated
        this.variousMaterielId = obj.variousMaterielId
        this.variousMaterielIntegrated = obj.variousMaterielIntegrated
        this.comment = obj.comment
        this.purchaseDate = obj.purchaseDate
        this.warrantyTime = obj.warrantyTime
        this.manufactureDate = obj.manufactureDate
        this.providerId = obj.providerId
        this.serialNumber = obj.serialNumber
        this.reference = obj.reference
        this.administrator = obj.administrator
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.codeNetwork = obj.codeNetwork
    }
}
