import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import countryFlagEmoji from 'country-flag-emoji'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

/* Docs draft-js
    https://draftjs.org/
    https://jpuri.github.io/react-draft-wysiwyg/
    https://reactrocket.com/post/draft-js-persisting-content/
    https://github.com/facebook/draft-js/issues/2118
    https://www.npmjs.com/package/html-to-draftjs
*/

const RichTextEditor = ({
    readMode,
    disabled,
    obligatory,
    col,
    value,
    label,
    onChange,
}) => {
    const [id] = useState(uuidv4())
    const getEditorStateInit = () => {
        if (value) {
            const blocksFromHtml = htmlToDraft(value)
            const { contentBlocks, entityMap } = blocksFromHtml
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
            return EditorState.createWithContent(contentState)
        }
        return EditorState.createEmpty()
    }
    const [editorState, setEditorState] = useState(getEditorStateInit())

    const getDisplayCard = () => {
        // {renderHTML(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
        return (
            <div className='ignore-css' dangerouslySetInnerHTML={{ __html: value || '' }} />
        )
    }

    const onEditorStateChange = (editorStateUpdated) => {
        onChange(draftToHtml(convertToRaw(editorStateUpdated.getCurrentContent())))
        setEditorState(editorStateUpdated)
    }

    const getEditorForm = () => {
        return (
            <div className={`col s${col || 12}`}>
                <div className='input-field' style={{ backgroundColor: 'white' }}>
                    <div className='sieau-input form-control input-sm ' style={{ border: `1px solid ${disabled ? '#ddd' : '#7a7a7a'}`, borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>
                        <Editor
                            wrapperId={id}
                            toolbarClassName='toolbarClassName'
                            wrapperClassName='wrapperClassName'
                            editorClassName='editorClassName'
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            readOnly={disabled}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                                emoji: {
                                    // icon: 'flag',
                                    className: undefined,
                                    component: undefined,
                                    popupClassName: undefined,
                                    emojis: [
                                        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                        '✅', '❎', ...countryFlagEmoji.list.map((e) => e.emoji),
                                    ],
                                },
                            }}
                        />
                    </div>
                    <label htmlFor={id} style={ disabled ? {} : { color: 'rgb(22, 24, 50)' }}>
                        {label}
                        { obligatory && <span className='primary-color-text'>{ i18n.obligatoryField }</span> }
                    </label>
                </div>
            </div>
        )
    }

    if (readMode) {
        return getDisplayCard()
    }
    return getEditorForm()
}

RichTextEditor.propTypes = {
    readMode: PropTypes.bool,
    disabled: PropTypes.bool,
    obligatory: PropTypes.bool,
    col: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
}

export default RichTextEditor
