import moment from 'moment'
import 'moment/locale/fr'
import { groupBy, mean, orderBy, range, take } from 'lodash'
import { median, std } from 'mathjs'
import { norminv } from '../../../../utils/MathUtil'
import i18n from 'simple-react-i18n'
import { hasValue } from '../../../../utils/NumberUtil'

moment.locale('fr')

const frequencyColors = (nb) => {
    return orderBy(take([
        [15, 'blue', '#b8b8ff'],
        [4, 'red', '#ff9494'],
        [7, 'orange', '#ffe6b8'],
        [12, 'green', '#b8dcb8'],
        [16, 'darkblue', '#9494ce'],
        [8, 'yellow', '#ffffb8'],
        [13, 'darkgreen', '#94be94'],
        [6, 'brown', '#d29b9b'],
        [17, 'purple', '#dcb8dc'],
        [18, 'darkmagenta', '#ae66ad'],
        [10, 'white', '#ffffff'],
        [1, 'black', '#b8b8b8'],
        [11, 'lightgreen', '#e0fbe0'],
        [2, 'pink', '#ffc0cb66'],
        [14, 'lightblue', '#afe2f3'],
        [3, 'magenta', '#dfb8df'],
        [5, 'indianred', '#cd5c5c'],
        [9, 'lightyellow', '#ffffe0'],
    ], nb), v => v[0], ['desc']).map(v => v[2])
}

const getFrequencyStats = (histoYears, filteredPeriods) => { // ex: filteredPeriods = ['3','5','10'] pour récupérer que triennale, quinquennale et décennale
    const stats = [
        { code: 'fre_100_H', name: i18n.fre_100_H, color: '#c2002b', nb: 0.99, minYear: 100 },
        { code: 'fre_80_H', name: i18n.fre_80_H, color: '#aa05f3', nb: 0.9875, minYear: 80 },
        { code: 'fre_50_H', name: i18n.fre_50_H, color: '#8d5d35', nb: 0.98, minYear: 50 },
        { code: 'fre_20_H', name: i18n.fre_20_H, color: '#32928b', nb: 0.95, minYear: 20 },
        { code: 'fre_10_H', name: i18n.fre_10_H, color: '#661886', nb: 0.9, minYear: 10 },
        { code: 'fre_5_H', name: i18n.fre_5_H, color: '#0c5033', nb: 0.8, minYear: 5 },
        { code: 'fre_3_H', name: i18n.fre_3_H, color: '#10436a', nb: 0.667, minYear: 3 },
        { code: 'fre_3_S', name: i18n.fre_3_S, color: '#10436a', nb: 0.333, minYear: 3 },
        { code: 'fre_5_S', name: i18n.fre_5_S, color: '#0c5033', nb: 0.2, minYear: 5 },
        { code: 'fre_10_S', name: i18n.fre_10_S, color: '#661886', nb: 0.1, minYear: 10 },
        { code: 'fre_20_S', name: i18n.fre_20_S, color: '#32928b', nb: 0.05, minYear: 20 },
        { code: 'fre_50_S', name: i18n.fre_50_S, color: '#8d5d35', nb: 0.02, minYear: 50 },
        { code: 'fre_80_S', name: i18n.fre_80_S, color: '#aa05f3', nb: 0.0125, minYear: 80 },
        { code: 'fre_100_S', name: i18n.fre_100_S, color: '#c2002b', nb: 0.01, minYear: 100 },
    ].filter(s => !hasValue(histoYears) ? true : s.minYear <= histoYears)
    const statsFiltered = filteredPeriods ? stats.filter(s => filteredPeriods.includes(s.code.split('_')[1])) : stats
    const colors = frequencyColors(statsFiltered.length)
    return statsFiltered.map((s, idx) => ({ ...s, color: colors[idx] }))
}

const getFrequencyStatsSimple = (histoYears) => {
    const stats = [
        { code: 'fre_3', name: i18n.fre_3, color: '#10436a', nb: 0.333, minYear: 3 },
        { code: 'fre_5', name: i18n.fre_5, color: '#0c5033', nb: 0.2, minYear: 5 },
        { code: 'fre_10', name: i18n.fre_10, color: '#661886', nb: 0.1, minYear: 10 },
        { code: 'fre_20', name: i18n.fre_20, color: '#32928b', nb: 0.05, minYear: 20 },
        { code: 'fre_50', name: i18n.fre_50, color: '#8d5d35', nb: 0.02, minYear: 50 },
        { code: 'fre_80', name: i18n.fre_80, color: '#aa05f3', nb: 0.0125, minYear: 80 },
        { code: 'fre_100', name: i18n.fre_100, color: '#c2002b', nb: 0.01, minYear: 100 },
    ].filter(s => !hasValue(histoYears) ? true : s.minYear <= histoYears)

    const colors = frequencyColors(stats.length)
    return stats.map((s, idx) => ({ ...s, color: colors[idx] }))
}

const getAllStats = (statsSeries, group, years) => {
    const enveloppeAll = Object.keys(group).flatMap(week => {
        const values = group[week].map(m => m.NGF)
        const average = mean(values)
        const ecartType = std(values)
        const stats = [
            median(values),
            ...getFrequencyStats().map(fre => statsSeries[fre.code] ? norminv(fre.nb, average, ecartType) : null),
        ]
        return years.map(y => [
            moment().year(y).week(parseInt(week)).day(4).startOf('day').valueOf(),
            ...stats,
        ])
    })

    const groupDate = groupBy(enveloppeAll, v => v[0])
    return orderBy(Object.keys(groupDate).map(keyDate => {
        const values = groupDate[keyDate]
        if (values.length > 2) {
            return [
                values[0][0],
                mean(values.map(v => v[1])),
                ...getFrequencyStats().map((fre, idx) => statsSeries[fre.code] ? mean(values.map(v => v[idx + 2])) : null),
            ]
        }
        return values[0]
    }), v => v[0])
}

const getPiezoSuiviStats = (statsSeries, measures, tab, lastMeasure, chartMinDate, chartMaxDate) => {
    if (measures.length && lastMeasure && chartMinDate && chartMaxDate) {
        const years = range(moment(chartMinDate).year() - 1, moment(chartMaxDate).year() + 2)
        const firstOfYear = moment(lastMeasure.date).startOf('year').valueOf()
        const statsMeasures = measures.filter(m => m.date < firstOfYear)
        const group = groupBy(statsMeasures, m => {
            const week = moment(m.date).week()
            return week > 52 ? 52 : week
        })
        const allStats = getAllStats(statsSeries, group, years)
        return {
            enveloppe: [],
            average: [],
            mediane: allStats.map(v => [v[0], v[1]]),
            ...getFrequencyStats().reduce((acc, fre, idx) => ({ ...acc, [fre.code]: statsSeries[fre.code] ? allStats.map(v => [v[0], v[idx + 2]]) : null }), {}),
        }
    }
    return {}
}

export { getPiezoSuiviStats, getFrequencyStats, getFrequencyStatsSimple }