export default class PerimeterDto {
    constructor(obj) {
        this.code = obj.code
        this.name = obj.name
        this.type = obj.type
        this.status = obj.status
        this.parcels = obj.parcels
        this.owners = obj.owners
        this.operators = obj.operators
        this.endDate = obj.endDate ?? ''
        this.startDate = obj.startDate ?? ''
        this.state = obj.state
    }
}