import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import StationAction from 'station/actions/StationAction'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import DtoAssociatedStation from 'station/dto/DtoAssociatedStation'
import { canShowStationTypeData } from 'utils/SettingUtils'
import UnitPiezometryPanel from '../../productionUnit/components/UnitPiezometryPanel'
import DtoStation from '../../station/dto/DtoStation'
import UDIQualityPanel from 'productionUnit/components/UDIQualityPanel'

class DistributionUnitPanel extends Component {
    constructor(props) {
        super(props)
        this.state = { dataLoaded: false }
    }

    hasType = (type) => this.props.associatedSites.some(ass => ass.stationLinkedType === type)

    getUDIQualityPanel = () => {
        const ids = this.props.associatedSites.filter(ass => ass.stationLinkedType === 3).map(ass => ass.stationLinkedId)
        return ids.length > 0 && (<UDIQualityPanel ids={ids} />)
    }

    render() {
        return (
            <div>
                { this.hasType(STATION_TYPE_CONSTANT.PIEZOMETRY) && canShowStationTypeData(this.props.fromStationType, 'PIEZO') && <UnitPiezometryPanel {...this.props} associations={ this.props.associatedSites } /> }
                { this.hasType(STATION_TYPE_CONSTANT.QUALITY) && canShowStationTypeData(this.props.fromStationType, 'QUALITO') && this.getUDIQualityPanel()}
            </div>
        )
    }
}

DistributionUnitPanel.propTypes = {
    id: PropTypes.string,
    associatedSites: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
    uncheckedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    setDataLoaded: PropTypes.func,
    fetchAssociations: PropTypes.func,
    fromStationType: PropTypes.string,
    dontShowEmptyCharts: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        associatedSites: store.StationReducer.associatedSites,
    }
}

const mapDispatchToProps = {
    fetchAssociations: StationAction.fetchAssociatedSites,
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributionUnitPanel)