import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { PARAMETER_PANEL, STATION_PANEL } from '../../../campaign/constants/CampaignConstants'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { getParametersNature, getParametersType } from '../../../referencial/util/ReferencialUtils'
import { getStatusInformation } from '../../../referencial/util/StatusUtils'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import DtoQualitometerLight from '../../dto/DtoQualitometerLight'

class ImportCampagnePopup extends Component {
    state = {
        stationsImported: [],
        parametersImported: [],
        importStation: true,
        importParam: true,
        file: '',
        view: STATION_PANEL,
    }

    componentDidMount = () => {
        $('ul.tabs').tabs()
    }

    componentDidUpdate = () => {
        $('ul.tabs').tabs()
    }

    openFile = e => {
        const {
            importStation,
            importParam,
        } = this.state
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => {
            const split = upload.target.result.split(',')
            if (split[0].includes('text/csv') || split[0].includes('vnd.ms-excel')) {
                const buf = new Buffer(split[1], 'base64').toString()
                const { stations, parameters } = buf
                    .split('\n')
                    .map(line => line.replace(/(")/g, ''))
                    .map(line => line.split(';'))
                    .map(b => b.map(s => s.trim()))
                    .reduce((acc, cur) => {
                        if (importStation && importParam) {
                            acc.stations.push(cur[0])
                            acc.parameters.push(cur[1])
                        }
                        if (importStation && !importParam) {
                            acc.stations.push(cur[0])
                        }
                        if (!importStation && importParam) {
                            acc.parameters.push(cur[0])
                        }
                        return acc
                    }, { stations: [], parameters: [] })
                const stationsImported = stations.filter(s => !!s).map(stationCode => {
                    const station = this.props.qualitometers.find(({ code }) => stationCode === code)
                    if (station) {
                        return { id: station.id }
                    }
                    return null
                }).filter(s => !!s)
                const parametersImported = parameters.filter(p => !!p).map(paramCode => {
                    const param = this.props.parameters.find(({ code }) => paramCode == code)
                    if (param) {
                        return { parameterCode: paramCode, unitCode: param.unitReference1 }
                    }
                    return null
                }).filter(p => !!p)
                this.setState({ stationsImported, parametersImported, file: file.name })
            } else {
                this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat)
            }
        }
        reader.readAsDataURL(file)
    }

    onClose = () => {
        this.setState({
            stationsImported: [],
            parametersImported: [],
            importStation: true,
            importParam: true,
            file: '',
            view: STATION_PANEL,
        })
        this.props.onClose()
    }

    onImport = () => {
        const {
            importStation,
            importParam,
        } = this.state
        if (importStation) {
            this.props.onChangeStation(this.state.stationsImported)
        }
        if (importParam) {
            this.props.onChangeParameter(this.state.parametersImported)
        }
        this.onClose()
    }

    render = () => {
        const {
            isOpen,
            qualitometers,
            parameters,
            cities,
            units,
        } = this.props
        const {
            stationsImported,
            parametersImported,
            view,
            importStation,
            importParam,
            file,
        } = this.state
        const stationsFormat = stationsImported.map(station => {
            const {
                name,
                code,
                townCode,
            } = qualitometers.find(({ id }) => station.id === id)
            return {
                name: name && `[${code}] ${name}` || '',
                city: (cities.find(c => c.id === townCode) || {}).labelWithCode || '',
            }
        })

        const types = getParametersType()
        const natures = getParametersNature()

        const parametersFormat = parametersImported.map(param => {
            const {
                name,
                typeParam,
                nature,
            } = parameters.find(({ code }) => param.parameterCode === code)
            const unit = units.find(u => u.id === param.unitCode)
            return {
                name: name && `[${param.parameterCode}] ${name}` || '',
                type: types[typeParam] || '',
                nature: natures[nature] || '',
                status: status ? getStatusInformation(status) : i18n.validated,
                unit: unit ? `[${unit.code}] ${unit.symbol}` : '',
            }
        })

        const nbStation = stationsImported.length
        const stationTitle = `${getI18nTitleDataLength(i18n.station, i18n.stations, nbStation)} (${nbStation} ${getI18nTitleDataLength(i18n.selectedElement, i18n.selectedElements, nbStation)})`

        const nbParameter = parametersImported.length
        const parameterTitle = `${getI18nTitleDataLength(i18n.parameter, i18n.parameters, nbParameter)} (${nbParameter} ${getI18nTitleDataLength(i18n.selectedElement, i18n.selectedElements, nbParameter)})`

        return (
            <Dialog
                onClose={this.onClose}
                fullWidth
                maxWidth='lg'
                open={isOpen}
            >
                <DialogTitle>{i18n.manualImport}</DialogTitle>
                <DialogContent>
                    <div style={{ minHeight: '55vh' }}>
                        <div className='row no-margin valign-wrapper'>
                            <div className='col s3 no-padding btn file-field'>
                                <span>{i18n.importFile}</span>
                                <input
                                    type='file'
                                    accept='.csv, .CSV'
                                    onChange={this.openFile}
                                />
                            </div>
                            <div className='col s3'>
                                <label style={{ fontSize: '1em' }}>{file}</label>
                            </div>
                            <div className='col s6'>
                                <Checkbox
                                    col={6}
                                    label={i18n.importStations}
                                    checked={importStation}
                                    onChange={e => this.setState({ importStation: e })}
                                />
                                <Checkbox
                                    col={6}
                                    label={i18n.importParameters}
                                    checked={importParam}
                                    onChange={e => this.setState({ importParam: e })}
                                />
                            </div>
                        </div>
                        <ul className='tabs tabs-fixed-width'>
                            <li className='tab' onClick={() => view !== STATION_PANEL && this.setState({ view: STATION_PANEL })}>
                                <a>
                                    {stationTitle}
                                </a>
                            </li>
                            <li className='tab' onClick={() => view !== PARAMETER_PANEL && this.setState({ view: PARAMETER_PANEL })}>
                                <a>
                                    {parameterTitle}
                                </a>
                            </li>
                        </ul>
                        {
                            view === STATION_PANEL && (
                                <div className='row no-margin'>
                                    <Table
                                        showTitle={false}
                                        data={stationsFormat}
                                        type={{ headers: ['name', 'city'] }}
                                        coloredLine
                                        maxHeight='38%'
                                        sortable
                                        condensed
                                        paging
                                        nbPerPageLabel={nbPerPageLabel}
                                    />
                                </div>
                            )
                        }
                        {
                            view === PARAMETER_PANEL && (
                                <div className='row no-margin'>
                                    <Table
                                        showTitle={false}
                                        data={parametersFormat}
                                        type={{ headers: ['name', 'type', 'nature', 'status', 'unit'] }}
                                        coloredLine
                                        maxHeight='38%'
                                        sortable
                                        condensed
                                        paging
                                        nbPerPageLabel={nbPerPageLabel}
                                    />
                                </div>
                            )
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} variant='outlined' >
                        {i18n.close}
                    </Button>
                    <Button onClick={this.onImport} variant='contained' color='primary'>
                        {i18n.importLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ImportCampagnePopup.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeParameter: PropTypes.func,

    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),

    toastError: PropTypes.func,
}

const mapStateToProps = store => ({
    qualitometers: store.QualityReducer.qualitometersLight,
    parameters: store.ParameterReducer.parameters,
    cities: store.CityReducer.cities,
    units: store.UnitReducer.units,
})

const mapDispatchToProps = {
    toastError: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportCampagnePopup)
