import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import UserAction from '../../user/actions/UserAction'
import AccessApplicationChartPanel from '../../user/components/detailPanel/charts/AccessApplicationChartPanel'
import AccessChartPanel from '../../user/components/detailPanel/charts/AccessChartPanel'
import PlatformApplicationChartPanel from '../../user/components/detailPanel/charts/PlatformApplicationChartPanel'
import CmsStatisticsPanel from '../../user/components/detailPanel/statistic/CmsStatisticsPanel'
import DocumentsStatisticsPanel from '../../user/components/detailPanel/statistic/DocumentsStatisticsPanel'
import StationStatisticsPanel from '../../user/components/detailPanel/statistic/StationStatisticsPanel'
import StatisticsPanel from '../../user/components/detailPanel/statistic/StatisticsPanel'
import UsageStatisticsPanel from '../../user/components/detailPanel/statistic/UsageStatisticsPanel'
import DtoUserCmsStatistic from '../../user/dto/DtoUserCmsStatistic'
import DtoUserStationStatistic from '../../user/dto/DtoUserStationStatistic'
import DtoUserStatistic from '../../user/dto/DtoUserStatistic'
import DtoUserUsageStatistic from '../../user/dto/DtoUserUsageStatistic'
import StatsFilterPanel from './StatsFilterPanel'
import ProgressCard from 'components/card/ProgressCard'
import HomeAction from 'home/actions/HomeAction'
import { compact } from 'lodash'
import UserDto from 'administration/components/user/dto/UserDto'
import Card from 'components/card/Card'

class AdminStatsApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                startDate: null,
                endDate: null,
                users: [],
                usersContrib: [],
            },
            fetchAllUserStatistics: false,
            fetchAllUserCmsStatistic: false,
            fetchAllUserUsageStatistic: false,
            fetchAllUserStationStatistic: false,
        }
    }

    componentDidMount() {
        this.props.fetchAllUserStatistics().then(() => this.setState({ fetchAllUserStatistics: true }))
        this.props.fetchAllUserCmsStatistic().then(() => this.setState({ fetchAllUserCmsStatistic: true }))
        this.props.fetchAllUserUsageStatistic().then(() => this.setState({ fetchAllUserUsageStatistic: true }))
        this.props.fetchAllUserStationStatistic().then(() => this.setState({ fetchAllUserStationStatistic: true }))
        this.props.setTitle([{
            title: i18n.administration,
            href: 'administration',
        }, {
            title: i18n.statistics,
            href: 'administration/stats',
        }])
    }

    checkStat = (stat, filter) => {
        const { startDate, endDate, users, contributors, usersContrib } = filter
        return (!startDate || stat.statisticDate > startDate) && (!endDate || stat.statisticDate < endDate) && (!users?.length > 0 || users.includes(stat.login)) && (!contributors?.length > 0 || usersContrib.includes(stat.login))
    }

    getFilteredData = () => {
        const { filter } = this.state
        const { userAllStatistics, userAllUsageStatistics, userAllStationStatistics, userAllCmsStatistics } = this.props
        const userAllStatisticsFiltered = userAllStatistics.filter((s) => this.checkStat(s, filter))
        const userAllUsageStatisticsFiltered = userAllUsageStatistics.filter((s) => this.checkStat(s, filter))
        const userAllStationStatisticsFiltered = userAllStationStatistics.filter((s) => this.checkStat(s, filter))
        const userAllCmsStatisticsFiltered = userAllCmsStatistics.filter((s) => this.checkStat(s, filter))
        return { userAllStatisticsFiltered, userAllUsageStatisticsFiltered, userAllStationStatisticsFiltered, userAllCmsStatisticsFiltered }
    }

    onChangeFilter = (filter) => {
        const { users } = this.props
        const usersContrib = users.filter((u) => filter.contributors.includes(u?.contributorCode)).map((u) => u.login)
        this.setState({ filter: { ...filter, usersContrib } })
    }

    render() {
        const {
            fetchAllUserStatistics,
            fetchAllUserCmsStatistic,
            fetchAllUserUsageStatistic,
            fetchAllUserStationStatistic,
        } = this.state

        if (fetchAllUserStatistics && fetchAllUserCmsStatistic && fetchAllUserUsageStatistic && fetchAllUserStationStatistic) {
            const { userAllStatisticsFiltered, userAllUsageStatisticsFiltered, userAllStationStatisticsFiltered, userAllCmsStatisticsFiltered } = this.getFilteredData()
            return (
                <div className='row'>
                    <div className='col s9 margin-top-1'>
                        <StatsFilterPanel
                            onChange={obj => this.onChangeFilter(obj)}
                        />
                        <Card
                            className='margin-top-1'
                            noMargin={false}
                            title={(
                                <>
                                    <i className='material-icons no-display'>sort</i>
                                    {i18n.userStatisticsAdmin}
                                </>
                            )}
                        >
                            <div className='row no-margin card padding-bottom-1'>
                                <div className='padding-8-10'>
                                    <div className='col s12'>
                                        <div className='row no-margin'>
                                            <div className='col s8 no-padding'>
                                                <AccessChartPanel
                                                    userStatistics={userAllStatisticsFiltered}
                                                />
                                            </div>
                                            <div className='col s4 no-padding'>
                                                <StatisticsPanel
                                                    userStatistics={userAllStatisticsFiltered}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col s12 margin-top-1'>
                                        <UsageStatisticsPanel
                                            userUsageStatistics={userAllUsageStatisticsFiltered}
                                        />
                                    </div>
                                    <div className='col s12 margin-top-1'>
                                        <StationStatisticsPanel
                                            userStationStatistics={userAllStationStatisticsFiltered}
                                        />
                                    </div>
                                    <div className='col s12 margin-top-1'>
                                        <CmsStatisticsPanel
                                            userCmsStatistics={userAllCmsStatisticsFiltered}
                                        />
                                    </div>
                                    <div className='col s12 margin-top-1'>
                                        <DocumentsStatisticsPanel />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className='col s3 margin-top-1'>
                        <div className='row'>
                            <div className='col s12'>
                                <AccessApplicationChartPanel
                                    userStatistics={userAllStatisticsFiltered}
                                />
                            </div>
                            <div className='col s12 margin-top-1'>
                                <PlatformApplicationChartPanel
                                    userStatistics={userAllStatisticsFiltered.filter(({ eventType }) => eventType === 'PLATFORM')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        const fetchs = [fetchAllUserStatistics, fetchAllUserCmsStatistic, fetchAllUserUsageStatistic, fetchAllUserStationStatistic]
        const progress = (compact(fetchs).length / fetchs.length) * 100
        return (

            <div className='row padding-1'>
                <ProgressCard progress={progress} withMessage />
            </div>
        )
    }
}

AdminStatsApp.propTypes = {
    setTitle: PropTypes.func,
    fetchAllUserStatistics: PropTypes.func,
    fetchAllUserCmsStatistic: PropTypes.func,
    fetchAllUserUsageStatistic: PropTypes.func,
    fetchAllUserStationStatistic: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    userAllStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
    userAllCmsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserCmsStatistic)),
    userAllUsageStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserUsageStatistic)),
    userAllStationStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStationStatistic)),
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchAllUserStatistics: UserAction.fetchAllUserStatistics,
    fetchAllUserCmsStatistic: UserAction.fetchAllUserCmsStatistic,
    fetchAllUserUsageStatistic: UserAction.fetchAllUserUsageStatistic,
    fetchAllUserStationStatistic: UserAction.fetchAllUserStationStatistic,
}

const mapStateToProps = (store) => {
    return {
        users: store.UserReducer.users,
        userAllStatistics: store.UserReducer.userAllStatistics,
        userAllCmsStatistics: store.UserReducer.userAllCmsStatistics,
        userAllUsageStatistics: store.UserReducer.userAllUsageStatistics,
        userAllStationStatistics: store.UserReducer.userAllStationStatistics,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminStatsApp)
