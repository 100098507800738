import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import Table from '../../../../../components/datatable/Table'
import { getMapStateToProps, getPropTypes } from '../../../../../utils/StoreUtils'
import DtoBoreholeAquiferItem from '../dto/DtoBoreholeAquiferItem'

const storeProps = {
    aquifers: false,
}

class BoreholeAquiferDashboard extends ActionComponent {
    getDepth = (aquifer, index, aquifers) => {
        if (index === 0) {
            return `0-${aquifer.depth}${i18n.metersSymbol}`
        }
        return `${aquifers[index - 1].depth}-${aquifer.depth}${i18n.metersSymbol}`
    }

    getSummary = (allAquifers) => {
        const aquifers = allAquifers ? allAquifers.filter(a => a.order === 1) : null
        if (aquifers && aquifers.length) {
            const orderedAquifers = orderBy(aquifers, 'depth')
            const data = orderedAquifers.map((d, i) => new DtoBoreholeAquiferItem(Object.assign({}, d, {
                depth: this.getDepth(d, i, orderedAquifers),
            })))
            return (
                <Table data={ data } title={ i18n.stratigraphy } condensed sortable className='smallCard' showNbElements={ false }
                    type={ new DtoBoreholeAquiferItem({}) }
                />
            )
        }
        return null
    }

    render() {
        return this.getSummary(this.props.station.link_aquifers)
    }
}

BoreholeAquiferDashboard.propTypes = getPropTypes(storeProps, {
    station: PropTypes.object,
})

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(BoreholeAquiferDashboard)

